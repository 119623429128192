import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getUserInfoInLocalStorage } from "../../helper";

import {
  getCountPostTT,
  getLogFetchFb,
  getLogFetchTT,
  getUserStatisticTT,
} from "../../../../../api/dashboard";
import ContentSpread from "../CommonItem/ContentSpread";
import UserStatisticTable from "../CommonItem/UserStatisticTable";
import { LogHeader } from "../../CommonItem/LogHeader";
import { PLATFORM_NAME } from "../../constants";
import { Col } from "reactstrap";
import Content from "../../../../../layout/content/Content";
import { Block } from "../../../../../components/Component";
// import { LogHeader } from "../../DashboardNew/CommonItem/LogHeader";
// import {LogHeaderUser } from "../../DashboardNew/CommonItem/LogHeaderUser"

const TiktokStaff = ({
  channels,
  range,
  staffs,
  setLoadingStaffFilter,
  setLoadingChannelFilter,
  setLoadingRange,
  from,
  to,
}) => {
  const userInfor = getUserInfoInLocalStorage();
  const projectId = userInfor.last_project_active;

  const { t } = useTranslation();

  const [logTTData, setLogTTData] = useState({});

  const [chartData, setChartData] = useState([{}]);
  const [options, setOptions] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [tooltipTableOpen, setTableToolTipOpen] = useState([
    { isOpen: false, label: "" },
    { isOpen: false, label: "Tổng lượt xem của các video." },
    { isOpen: false, label: "Tổng số người xem video" },
    { isOpen: false, label: "Tổng số lượt Thích video" },
    { isOpen: false, label: "Tổng số Bình luận của video" },
    { isOpen: false, label: "Tổng số lần video được chia sẻ" },
    { isOpen: false, label: "Thời gian trung bình xem video" },
    { isOpen: false, label: "Tỷ lệ xem hết video" },
  ]);
  const headerUserStatistic = {
    user: t("employee"),
    view: t("views"),
    reach: t("channel_analytics_table.reach"),
    like: t("likes"),
    comment: t("comment"),
    share: t("share"),
    avg_watch_time: t("avg_watch_time"),
    full_video_watch_rate: t("full_video_watch_rate"),
  };

  const fetchData = async () => {
    const data = {
      params: {
        project_id: projectId,
        page: currentPage,
        user_ids: staffs,
        channel_ids: channels,
        from,
        to,
      },
    };
    setTableLoading(true);
    setIsLoading(true);
    try {
      getCountPostTT(data).then((response) => {
        if (response) {
          const _data = response.data;
          setChartData([
            {
              name: "",
              data: _data.dataSet.data,
            },
          ]);

          const options = {
            chart: {
              height: 345,
              type: "line",
              zoom: {
                enabled: false,
              },
              toolbar: {
                show: false,
              },
            },
            colors: ["#45cb85d9", "#4b38b3", "#007bff"],

            dataLabels: {
              enabled: false,
            },
            stroke: {
              width: [3, 4, 3],
              curve: "straight",
              dashArray: [0, 8, 5],
            },
            series: _data?.dataSet?.data || [],
            markers: {
              size: 0,

              hover: {
                sizeOffset: 6,
              },
            },
            xaxis: {
              categories: _data?.dataSet?.labels,
            },
            grid: {
              borderColor: "#f1f1f1",
            },
          };

          setOptions(options);
        } else {
          setChartData([{}]);
        }
        setIsLoading(false);
      });
      const dataTiktokRes = await getUserStatisticTT(data);
      if (dataTiktokRes) {
        const dataTT = dataTiktokRes.data;
        setData(dataTT);

        setListData(
          dataTT.data.map((item) => ({
            user: item?.user,
            view: item?.view,
            reach: item?.reach,
            like: item?.like,
            comment: item?.comment,
            share: item?.share,
            avg_watch_time: item.avg_watch_time,
            full_video_watch_rate: item.full_video_watch_rate,
          }))
        );
        setTableLoading(false);
      }
    } catch (error) {
      setTableLoading(false);
      console.log("userDBTable", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [from, to, currentPage, staffs, channels, projectId]);

  const getAPITiktok = (data) => {
    getLogFetchTT(data).then((responses) => {
      // Destructure the responses to get the data
      if (responses) {
        setLogTTData(responses.data);
      }

      setLoadingStaffFilter(false);
      setLoadingChannelFilter(false);
      setLoadingRange(false);
    });
  };

  useEffect(() => {
    const data = {
      params: {
        project_id: projectId,
        channel_ids: channels,
        user_ids: staffs,
        from,
        to,
      },
    };
    getAPITiktok(data);
  }, [channels, staffs, from, to, projectId]);

  return (
    <>
      <Col lg={12} className="px-8">
        <LogHeader
          cnt_channel_error={logTTData?.cnt_channel_error}
          cnt_channel_success={logTTData?.cnt_channel_success}
          last_time={logTTData?.last_time}
          currentTab={PLATFORM_NAME.TIKTOK}
        />
      </Col>
      <div style={{ padding: "18px 24px" }}>
        {/* <Block> */}
        <ContentSpread
          chartData={chartData}
          options={options}
          isLoading={isLoading}
        />
        {/* </Block>
        <Block> */}
        <UserStatisticTable
          setCurrentPage={setCurrentPage}
          headerUserStatistic={headerUserStatistic}
          tooltipTableOpen={tooltipTableOpen}
          tableLoading={tableLoading}
          listData={listData}
          data={data}
        />
        {/* </Block> */}
      </div>
    </>
  );
};

export default TiktokStaff;
