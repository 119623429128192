import React from "react";
import DefaultImage from "../../../src/So9/images/avatar/no-image.png";

const sizeImage = (size, width, height) => {
    if (size === "circle") {
        return {
            borderRadius: "50%",
            width: width,
            height: width
        }
    }
    else if (size === "square") {
        return {
            borderRadius: "4px",
            width: width,
            height: width
        }
    }
    else {
        return {
            width: width,
            height: height
        }
    }
}

const Img = ({ src, alt = "", width, height, size, style, defaultPicture, ...props }) => {
    const imgStyle = {
        objectFit: "cover",
        ...sizeImage(size, width, height),
        ...style
    }
    return (
        <img
            src={src}
            alt={alt}
            style={imgStyle}
            onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = defaultPicture || DefaultImage;
            }}
            {...props}
        />
    );
};
export default Img;
