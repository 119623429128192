import { useState } from "react";
import "./affiliate/affiliate.css"
import "./affiliate/affiliate-responsive.css"
import "./affiliate/custom-frontend.min.css"
import "./affiliate/elementor-icons.min.css"
import "./affiliate/responsive.css"
import backImg from "../../images/aff/back-item.png"
import signupIcon from "../../images/aff/signup.svg"
import shareIcon from "../../images/aff/share.svg"
import earnIcon from "../../images/aff/earn.svg"
import iconUp from "../../images/aff/chevron-up-solid.svg"
import iconDown from "../../images/aff/chevron-down-solid.svg"
import { Img } from "../../../components/Component";
import { Link ,NavLink} from 'react-router-dom';
const IntroIrisPage = () => {
    const [hidden, setHidden] = useState([false, false, false, false, false]);
    const toggleContent = (index) => {
        const newState = [...hidden];
        newState[index] = !hidden[index];
        setHidden(newState);
    }

    return (
        <div >
            <div id="content" className="site-content" style={{ marginTop: "100px", marginBottom: "60px" }}>

                <main id="main" className="site-main" style={{ width: "100%" }}>

                    <article id="post-3114" className="post-3114 page type-page status-publish hentry">

                        <div className="entry-content article-content">
                            <div data-elementor-type="wp-post" data-elementor-id="3114" className="elementor elementor-3114"
                                data-elementor-post-type="page">
                                <section
                                    className="elementor-section elementor-top-section elementor-element elementor-element-3cd373a7 elementor-section-stretched elementor-section-height-min-height elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
                                    data-id="3cd373a7" data-element_type="section"
                                    data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;}"
                                    style={{ width: "1519px", left: "0px" }}>
                                    <div className="elementor-container elementor-column-gap-default">
                                        <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-57640b5d"
                                            data-id="57640b5d" data-element_type="column">
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div className="elementor-element elementor-element-15b5e888 elementor-widget elementor-widget-heading"
                                                    data-id="15b5e888" data-element_type="widget"
                                                    data-widget_type="heading.default">
                                                    <div className="elementor-widget-container">
                                                        <h2 className="elementor-heading-title elementor-size-default">SO9
                                                            Affiliate program</h2>
                                                    </div>
                                                </div>
                                                <div className="elementor-element elementor-element-73158fd4 elementor-widget elementor-widget-heading"
                                                    data-id="73158fd4" data-element_type="widget"
                                                    data-widget_type="heading.default">
                                                    <div className="elementor-widget-container">
                                                        <h1 className="elementor-heading-title elementor-size-default">Chia sẻ giá trị &amp;
                                                            Nhận hoa hồng</h1>
                                                    </div>
                                                </div>
                                                <div className="elementor-element elementor-element-1a85c992 elementor-widget elementor-widget-text-editor"
                                                    data-id="1a85c992" data-element_type="widget"
                                                    data-widget_type="text-editor.default">
                                                    <div className="elementor-widget-container">
                                                        <p>Nhận ngay 10% không giới hạn toàn bộ đơn từ khách hàng trong
                                                            suốt 3 tháng kể từ ngày giới thiệu khách thành công.</p>
                                                    </div>
                                                </div>
                                                <div className="elementor-element elementor-element-206ae66e elementor-widget__width-auto elementor-widget-tablet__width-initial elementor-widget-mobile__width-auto elementor-widget elementor-widget-button"
                                                    data-id="206ae66e" data-element_type="widget"
                                                    data-widget_type="button.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-button-wrapper">
                                                            <Link className="elementor-button elementor-button-link elementor-size-md"
                                                               to="/user-profile-iris"
                                                                id="apply-now-button" rel="noopener">
                                                                <span className="elementor-button-content-wrapper">
                                                                    <span className="elementor-button-text">Đăng ký ngay</span>
                                                                </span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-1614f7a9"
                                            data-id="1614f7a9" data-element_type="column">
                                            <div className="elementor-widget-wrap">
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section
                                    className="elementor-section elementor-top-section elementor-element elementor-element-37135c57 elementor-section-stretched elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
                                    data-id="37135c57" data-element_type="section"
                                    data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;}"
                                    style={{ width: "1519px", left: "0px" }}>
                                    <div className="elementor-container elementor-column-gap-default">
                                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6235cf41"
                                            data-id="6235cf41" data-element_type="column">
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div className="elementor-element elementor-element-1665badf elementor-widget__width-inherit elementor-widget elementor-widget-heading"
                                                    data-id="1665badf" data-element_type="widget"
                                                    data-widget_type="heading.default">
                                                    <div className="elementor-widget-container">
                                                        <h2 className="elementor-heading-title elementor-size-default">Chia sẻ giá trị - Nhận hoa hồng!
                                                            <font color="“#29AB51;“"> CHỈ 3 BƯỚC</font>
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className="elementor-element elementor-element-2552fd65 elementor-widget__width-initial elementor-widget-tablet__width-inherit elementor-position-top elementor-widget elementor-widget-image-box animated fadeInUp"
                                                    data-id="2552fd65" data-element_type="widget"
                                                    data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}"
                                                    data-widget_type="image-box.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-image-box-wrapper">
                                                            <figure className="elementor-image-box-img">
                                                                <Img decoding="async"
                                                                    width="121" height="120"
                                                                    src={signupIcon}
                                                                    className="attachment-full size-full wp-image-107998"
                                                                    alt="Printify Affiliate Program Signup"
                                                                    title="Printify Affiliate Program Signup" /></figure>
                                                            <div className="elementor-image-box-content">
                                                                <h3 className="elementor-image-box-title">1. Đăng ký IRIS
                                                                </h3>
                                                                <p className="elementor-image-box-description">Điền đầy đủ thông tin chỉ mất 1 phút.
                                                                    SO9 sẽ phản hồi thông tin đăng ký của bạn trong 1 ngày.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-element elementor-element-46975038 elementor-widget__width-initial elementor-widget-tablet__width-inherit elementor-position-top elementor-widget elementor-widget-image-box animated fadeInUp"
                                                    data-id="46975038" data-element_type="widget"
                                                    data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;_animation_delay&quot;:500}"
                                                    data-widget_type="image-box.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-image-box-wrapper">
                                                            <figure className="elementor-image-box-img">
                                                                <Img decoding="async"
                                                                width="124" height="124"
                                                                src={shareIcon}
                                                                className="attachment-full size-full wp-image-107999"
                                                                alt="Printify Affiliate Program Share"
                                                                title="Printify Affiliate Program Share" /></figure>
                                                            <div className="elementor-image-box-content">
                                                                <h3 className="elementor-image-box-title">2. Chia sẻ </h3>
                                                                <p className="elementor-image-box-description">Chia sẻ Link giới thiệu của bạn lên các nền tảng mạng xã hội,
                                                                    blog, kênh Youtube,...</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-element elementor-element-7784394 elementor-widget__width-initial elementor-widget-tablet__width-inherit elementor-position-top elementor-widget elementor-widget-image-box animated fadeInUp"
                                                    data-id="7784394" data-element_type="widget"
                                                    data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;_animation_delay&quot;:1000}"
                                                    data-widget_type="image-box.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-image-box-wrapper">
                                                            <figure className="elementor-image-box-img">
                                                                <Img decoding="async"
                                                                width="123" height="122"
                                                                src={earnIcon}
                                                                className="attachment-full size-full wp-image-108000"
                                                                alt="Printify Affiliate Program Earn"
                                                                title="Printify Affiliate Program Earn" /></figure>
                                                            <div className="elementor-image-box-content">
                                                                <h3 className="elementor-image-box-title">3. Nhận hoa hồng </h3>
                                                                <p className="elementor-image-box-description">Những IRIS tích cực của chúng tôi nhận
                                                                    thu nhập trung bình 15 triệu / tháng. </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section
                                    className="elementor-section elementor-top-section elementor-element elementor-element-bfb5492 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="bfb5492" data-element_type="section"
                                    data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;stretch_section&quot;:&quot;section-stretched&quot;}"
                                    style={{ width: "1519px", left: "0px" }}>
                                    <div className="elementor-background-overlay"></div>
                                    <div className="elementor-container elementor-column-gap-default">
                                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3a496d9a"
                                            data-id="3a496d9a" data-element_type="column">
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div className="elementor-element elementor-element-2337e710 elementor-widget__width-inherit elementor-widget elementor-widget-heading"
                                                    data-id="2337e710" data-element_type="widget"
                                                    data-widget_type="heading.default">
                                                    <div className="elementor-widget-container">
                                                        <h2 className="elementor-heading-title elementor-size-default">
                                                            Lợi ích của SO9 IRIS</h2>
                                                    </div>
                                                </div>
                                                <section
                                                    className="elementor-section elementor-inner-section elementor-element elementor-element-6fc1250a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                    data-id="6fc1250a" data-element_type="section">
                                                    <div className="elementor-container elementor-column-gap-default">
                                                        <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-65abcfb1"
                                                            data-id="65abcfb1" data-element_type="column">
                                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                                <div className="elementor-element elementor-element-79c47b84 elementor-position-left elementor-mobile-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                                                                    data-id="79c47b84" data-element_type="widget"
                                                                    data-widget_type="icon-box.default">
                                                                    <div className="elementor-widget-container">
                                                                        <div className="elementor-icon-box-wrapper">
                                                                            <div className="elementor-icon-box-icon">
                                                                                <span
                                                                                    className="elementor-icon elementor-animation-">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                        width="20" height="21"
                                                                                        viewBox="0 0 20 21" fill="none">
                                                                                        <circle cx="10" cy="10.1812" r="9"
                                                                                            stroke="#77DF95"
                                                                                            stroke-width="2"></circle>
                                                                                        <path
                                                                                            d="M5 11.1812L8 14.1812L15 7.18115"
                                                                                            stroke="#77DF95"
                                                                                            stroke-width="2"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round"></path>
                                                                                    </svg> </span>
                                                                            </div>
                                                                            <div className="elementor-icon-box-content">
                                                                                <h3 className="elementor-icon-box-title">
                                                                                    <span>
                                                                                        10% Hoa hồng ghi nhận trong 90 ngày </span>
                                                                                </h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="elementor-element elementor-element-ced62d8 elementor-position-left elementor-mobile-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                                                                    data-id="ced62d8" data-element_type="widget"
                                                                    data-widget_type="icon-box.default">
                                                                    <div className="elementor-widget-container">
                                                                        <div className="elementor-icon-box-wrapper">
                                                                            <div className="elementor-icon-box-icon">
                                                                                <span
                                                                                    className="elementor-icon elementor-animation-">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                        width="20" height="21"
                                                                                        viewBox="0 0 20 21" fill="none">
                                                                                        <circle cx="10" cy="10.1812" r="9"
                                                                                            stroke="#18C75A"
                                                                                            stroke-width="2"></circle>
                                                                                        <path
                                                                                            d="M5 11.1812L8 14.1812L15 7.18115"
                                                                                            stroke="#18C75A"
                                                                                            stroke-width="2"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round"></path>
                                                                                    </svg> </span>
                                                                            </div>
                                                                            <div className="elementor-icon-box-content">
                                                                                <h3 className="elementor-icon-box-title">
                                                                                    <span>
                                                                                        Không giới hạn số tiền hoa hồng được nhận
                                                                                    </span>
                                                                                </h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="elementor-element elementor-element-67bad183 elementor-position-left elementor-mobile-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                                                                    data-id="67bad183" data-element_type="widget"
                                                                    data-widget_type="icon-box.default">
                                                                    <div className="elementor-widget-container">
                                                                        <div className="elementor-icon-box-wrapper">
                                                                            <div className="elementor-icon-box-icon">
                                                                                <span
                                                                                    className="elementor-icon elementor-animation-">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                        width="20" height="21"
                                                                                        viewBox="0 0 20 21" fill="none">
                                                                                        <circle cx="10" cy="10.1812" r="9"
                                                                                            stroke="#18C75A"
                                                                                            stroke-width="2"></circle>
                                                                                        <path
                                                                                            d="M5 11.1812L8 14.1812L15 7.18115"
                                                                                            stroke="#18C75A"
                                                                                            stroke-width="2"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round"></path>
                                                                                    </svg> </span>
                                                                            </div>
                                                                            <div className="elementor-icon-box-content">
                                                                                <h3 className="elementor-icon-box-title">
                                                                                    <span>
                                                                                        Thanh toán hàng tháng </span>
                                                                                </h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="elementor-element elementor-element-5cbf21ac elementor-position-left elementor-mobile-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                                                                    data-id="5cbf21ac" data-element_type="widget"
                                                                    data-widget_type="icon-box.default">
                                                                    <div className="elementor-widget-container">
                                                                        <div className="elementor-icon-box-wrapper">
                                                                            <div className="elementor-icon-box-icon">
                                                                                <span
                                                                                    className="elementor-icon elementor-animation-">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                        width="20" height="21"
                                                                                        viewBox="0 0 20 21" fill="none">
                                                                                        <circle cx="10" cy="10.1812" r="9"
                                                                                            stroke="#18C75A"
                                                                                            stroke-width="2"></circle>
                                                                                        <path
                                                                                            d="M5 11.1812L8 14.1812L15 7.18115"
                                                                                            stroke="#18C75A"
                                                                                            stroke-width="2"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round"></path>
                                                                                    </svg> </span>
                                                                            </div>
                                                                            <div className="elementor-icon-box-content">
                                                                                <h3 className="elementor-icon-box-title">
                                                                                    <span>
                                                                                        Nhận hỗ trợ chuyên biệt từ SO9 để hiểu về sản phẩm </span>
                                                                                </h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="elementor-element elementor-element-5faef537 elementor-position-left elementor-mobile-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                                                                    data-id="5faef537" data-element_type="widget"
                                                                    data-widget_type="icon-box.default">
                                                                    <div className="elementor-widget-container">
                                                                        <div className="elementor-icon-box-wrapper">
                                                                            <div className="elementor-icon-box-icon">
                                                                                <span
                                                                                    className="elementor-icon elementor-animation-">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                        width="20" height="21"
                                                                                        viewBox="0 0 20 21" fill="none">
                                                                                        <circle cx="10" cy="10.1812" r="9"
                                                                                            stroke="#18C75A"
                                                                                            stroke-width="2"></circle>
                                                                                        <path
                                                                                            d="M5 11.1812L8 14.1812L15 7.18115"
                                                                                            stroke="#18C75A"
                                                                                            stroke-width="2"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round"></path>
                                                                                    </svg> </span>
                                                                            </div>
                                                                            <div className="elementor-icon-box-content">
                                                                                <h3 className="elementor-icon-box-title">
                                                                                    <span>
                                                                                        Hoàn toàn không mất phí đăng ký </span>
                                                                                </h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-3b51cb3e"
                                                            data-id="3b51cb3e" data-element_type="column">
                                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                                <div className="elementor-element elementor-element-7a625acc elementor-hidden-tablet elementor-hidden-mobile elementor-widget elementor-widget-image"
                                                                    data-id="7a625acc" data-element_type="widget"
                                                                    data-widget_type="image.default">
                                                                    <div className="elementor-widget-container">
                                                                        <Img loading="lazy" decoding="async" width="732"
                                                                            height="801"
                                                                            src={backImg}
                                                                            className="attachment-large size-large wp-image-159632"
                                                                            alt="Affiliate 1"
                                                                            srcset="https://printify.com/wp-content/uploads/2023/03/Affiliate-Program-Benefits-Printify.png 732w, https://printify.com/wp-content/uploads/2023/03/Affiliate-Program-Benefits-Printify-274x300.png 274w, https://printify.com/wp-content/uploads/2023/03/Affiliate-Program-Benefits-Printify-150x164.png 150w"
                                                                            sizes="(max-width: 732px) 100vw, 732px"
                                                                            title="Affiliate Program Benefits Printify" />
                                                                    </div>
                                                                </div>
                                                                <div className="elementor-element elementor-element-337a4dcf elementor-position-left elementor-mobile-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                                                                    data-id="337a4dcf" data-element_type="widget"
                                                                    data-widget_type="icon-box.default">
                                                                    <div className="elementor-widget-container">
                                                                        <div className="elementor-icon-box-wrapper">
                                                                            <div className="elementor-icon-box-icon">
                                                                                <span
                                                                                    className="elementor-icon elementor-animation-">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                        width="20" height="21"
                                                                                        viewBox="0 0 20 21" fill="none">
                                                                                        <circle cx="10" cy="10.1812" r="9"
                                                                                            stroke="#18C75A"
                                                                                            stroke-width="2"></circle>
                                                                                        <path
                                                                                            d="M5 11.1812L8 14.1812L15 7.18115"
                                                                                            stroke="#18C75A"
                                                                                            stroke-width="2"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round"></path>
                                                                                    </svg> </span>
                                                                            </div>
                                                                            <div className="elementor-icon-box-content">
                                                                                <h3 className="elementor-icon-box-title">
                                                                                    <span>
                                                                                        30 ngày lưu cookies cho link chia sẻ </span>
                                                                                </h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="elementor-element elementor-element-ad2963c elementor-hidden-desktop elementor-widget elementor-widget-image"
                                                                    data-id="ad2963c" data-element_type="widget"
                                                                    data-widget_type="image.default">
                                                                    <div className="elementor-widget-container">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section
                                    className="elementor-section elementor-top-section elementor-element elementor-element-1cbf2cbf elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="1cbf2cbf" data-element_type="section"
                                    data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;}"
                                    style={{ width: "1519px", left: "0px" }}>
                                    <div className="elementor-background-overlay"></div>
                                    <div className="elementor-container elementor-column-gap-default">
                                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5b1a43a9"
                                            data-id="5b1a43a9" data-element_type="column">
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div className="elementor-element elementor-element-528ff2c9 elementor-widget__width-inherit elementor-widget elementor-widget-heading"
                                                    data-id="528ff2c9" data-element_type="widget"
                                                    data-widget_type="heading.default">
                                                    <div className="elementor-widget-container">
                                                        <h2 className="elementor-heading-title elementor-size-default">Câu hỏi thường gặp</h2>
                                                    </div>
                                                </div>
                                                <div className="elementor-element elementor-element-6980526d elementor-widget elementor-widget-toggle"
                                                    data-id="6980526d" data-element_type="widget"
                                                    data-widget_type="toggle.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-toggle">
                                                            <div className="elementor-toggle-item"

                                                            >
                                                                <h5 id="elementor-tab-title-1773"
                                                                    className="elementor-tab-title" data-tab="3" role="button"
                                                                    aria-controls="elementor-tab-content-1773"
                                                                    aria-expanded="false" tabIndex="-1"
                                                                    aria-selected="false"
                                                                    onClick={() => { toggleContent(0) }}
                                                                    style={{ borderBottom: hidden[0] && "0px" }}
                                                                >
                                                                    <span
                                                                        className="elementor-toggle-icon elementor-toggle-icon-right"
                                                                        aria-hidden="true">
                                                                        <span className="elementor-toggle-icon-closed">
                                                                        <img src={iconDown}></img></span>
                                                                        <span className="elementor-toggle-icon-opened">
                                                                        <img src={iconUp}></img></span>
                                                                    </span> 
                                                                    <a className="elementor-toggle-title" tabIndex="0">
                                                                        Thời gian đăng kí là bao lâu?
                                                                    </a>
                                                                </h5>

                                                                <div id="elementor-tab-content-1773"
                                                                    className="elementor-tab-content elementor-clearfix"
                                                                    data-tab="3" role="region"
                                                                    aria-labelledby="elementor-tab-title-1773"
                                                                    style={{ display: !hidden[0] ? "none" : "block", transition: "opacity 0.3s ease, visibility 0s linear 0.3s" }}
                                                                    hidden={!hidden[0] ? "hidden" : ""}

                                                                >
                                                                    <p><span style={{ fontWeight: "400" }}>
                                                                        Điền trong vòng 1 phút , đội ngũ SO9 sẽ duyệt đơn của bạn chậm nhất 1 ngày.
                                                                    </span></p>
                                                                </div>
                                                            </div>
                                                            <div className="elementor-toggle-item"

                                                            >
                                                                <h5 id="elementor-tab-title-1774"
                                                                    className="elementor-tab-title" data-tab="4" role="button"
                                                                    aria-controls="elementor-tab-content-1774"
                                                                    aria-expanded="false" tabIndex="-1"
                                                                    aria-selected="false"
                                                                    onClick={() => { toggleContent(1) }}
                                                                    style={{ borderBottom: hidden[1] && "0px" }}
                                                                >
                                                                   <span
                                                                        className="elementor-toggle-icon elementor-toggle-icon-right"
                                                                        aria-hidden="true">
                                                                        <span className="elementor-toggle-icon-closed">
                                                                        <img src={iconDown}></img></span>
                                                                        <span className="elementor-toggle-icon-opened">
                                                                        <img src={iconUp}></img></span>
                                                                    </span> 
                                                                    <a className="elementor-toggle-title" tabIndex="0">
                                                                        Link giới thiệu được lưu cookies trong bao lâu?

                                                                    </a>
                                                                </h5>

                                                                <div id="elementor-tab-content-1774"
                                                                    className="elementor-tab-content elementor-clearfix"
                                                                    data-tab="4" role="region"
                                                                    aria-labelledby="elementor-tab-title-1774"
                                                                    style={{ display: !hidden[1] ? "none" : "block", transition: "opacity 0.3s ease, visibility 0s linear 0.3s" }}
                                                                    hidden={!hidden[1] ? "hidden" : ""}
                                                                >
                                                                    <p><span style={{ fontWeight: "400" }}>
                                                                        Link được lưu trữ trong 30 ngày kể từ khi khách hàng Click vào link.
                                                                        Đồng nghĩa với việc khi có 1 khác hàng mới tạo tài khoản từ link của bạn
                                                                        trong 30 ngày từ khi họ click thì tài khoản đó sẽ được tính là bạn giới thiệu.
                                                                    </span></p>
                                                                </div>
                                                            </div>
                                                            <div className="elementor-toggle-item"

                                                            >
                                                                <h5 id="elementor-tab-title-1775"
                                                                    className="elementor-tab-title" data-tab="5" role="button"
                                                                    aria-controls="elementor-tab-content-1775"
                                                                    aria-expanded="false" tabIndex="-1"
                                                                    aria-selected="false"
                                                                    onClick={() => { toggleContent(2) }}
                                                                    style={{ borderBottom: hidden[2] && "0px" }}
                                                                >
                                                                   <span
                                                                        className="elementor-toggle-icon elementor-toggle-icon-right"
                                                                        aria-hidden="true">
                                                                        <span className="elementor-toggle-icon-closed">
                                                                        <img src={iconDown}></img></span>
                                                                        <span className="elementor-toggle-icon-opened">
                                                                        <img src={iconUp}></img></span>
                                                                    </span> 
                                                                    <a className="elementor-toggle-title" tabIndex="0">
                                                                        Hoa hồng được tính như thế nào?

                                                                    </a>
                                                                </h5>

                                                                <div id="elementor-tab-content-1775"
                                                                    className="elementor-tab-content elementor-clearfix"
                                                                    data-tab="5" role="region"
                                                                    aria-labelledby="elementor-tab-title-1775"
                                                                    style={{ display: !hidden[2] ? "none" : "block", transition: "opacity 0.3s ease, visibility 0s linear 0.3s" }}
                                                                    hidden={!hidden[2] ? "hidden" : ""}
                                                                >
                                                                    <p><span style={{ fontWeight: "400" }}>
                                                                        Khi 1 khách hàng được tính là bạn giới thiệu(tạo tài khoản trong thời gian lưu cookies)
                                                                        - thì trong 90 ngày tiếp theo kể từ khi tài khoản được tạo: Bất cứ đơn hàng nào phát sinh
                                                                        (đơn mới + gia hạn) đều được tính 10% hoa hồng đến bạn.
                                                                    </span></p>
                                                                </div>
                                                            </div>
                                                            <div className="elementor-toggle-item"

                                                            >
                                                                <h5 id="elementor-tab-title-1776"
                                                                    className="elementor-tab-title" data-tab="6" role="button"
                                                                    aria-controls="elementor-tab-content-1776"
                                                                    aria-expanded="false" tabIndex="-1"
                                                                    aria-selected="false"
                                                                    onClick={() => { toggleContent(3) }}
                                                                    style={{ borderBottom: hidden[3] && "0px" }}
                                                                >
                                                                  <span
                                                                        className="elementor-toggle-icon elementor-toggle-icon-right"
                                                                        aria-hidden="true">
                                                                        <span className="elementor-toggle-icon-closed">
                                                                        <img src={iconDown}></img></span>
                                                                        <span className="elementor-toggle-icon-opened">
                                                                        <img src={iconUp}></img></span>
                                                                    </span> 
                                                                    <a className="elementor-toggle-title" tabIndex="0">
                                                                        Thời gian thanh toán là khi nào?
                                                                    </a>
                                                                </h5>

                                                                <div id="elementor-tab-content-1776"
                                                                    className="elementor-tab-content elementor-clearfix"
                                                                    data-tab="6" role="region"
                                                                    aria-labelledby="elementor-tab-title-1776"
                                                                    style={{ display: !hidden[3] ? "none" : "block", transition: "opacity 0.3s ease, visibility 0s linear 0.3s" }}
                                                                    hidden={!hidden[3] ? "hidden" : ""}
                                                                >
                                                                    <p><span style={{ fontWeight: "400" }}>
                                                                        SO9 sẽ đối soát và thanh toán 1 lần vào ngày mùng 9 hàng tháng ,
                                                                        hoa hồng được trả cho các đơn hàng được giới thiệu thành công trong tháng trước đó.
                                                                        Toàn bộ quá trình có thể được tra cứu ở Mục "Lịch sử thanh toán".
                                                                    </span></p>
                                                                </div>
                                                            </div>
                                                            <div className="elementor-toggle-item"

                                                            >
                                                                <h5 id="elementor-tab-title-1777"
                                                                    className="elementor-tab-title" data-tab="7" role="button"
                                                                    aria-controls="elementor-tab-content-1777"
                                                                    aria-expanded="false" tabIndex="-1"
                                                                    aria-selected="false"
                                                                    onClick={() => { toggleContent(4) }}
                                                                    style={{ borderBottom: hidden[4] && "0px" }}
                                                                >
                                                                    <span
                                                                        className="elementor-toggle-icon elementor-toggle-icon-right"
                                                                        aria-hidden="true">
                                                                        <span className="elementor-toggle-icon-closed">
                                                                        <img src={iconDown}></img></span>
                                                                        <span className="elementor-toggle-icon-opened">
                                                                        <img src={iconUp}></img></span>
                                                                    </span> 
                                                                    <a className="elementor-toggle-title" tabIndex="0"

                                                                    >Có gì lưu ý đặc biệt không?</a>
                                                                </h5>

                                                                <div id="elementor-tab-content-1777"
                                                                    className={`elementor-tab-content elementor-clearfix`}
                                                                    data-tab="7" role="region"
                                                                    aria-labelledby="elementor-tab-title-1777"
                                                                    style={{ display: "block", maxHeight: !hidden[4] ? "0px" : "500px", transition: "height 3s" }}
                                                                    hidden={!hidden[4] ? "hidden" : ""}
                                                                >
                                                                    <p><span style={{ fontWeight: "400" }}>SO9 có quyền loại bạn ra khỏi trương trình hoặc không thanh toán nếu phat hiện hành vi gian lận.
                                                                        Toàn bộ thông tin nếu phát sinh sẽ được minh bạch và thông báo đến bạn.
                                                                    </span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>

                    </article>

                </main>


            </div>
        </div>
    )
}
export default IntroIrisPage;
