export const POST_STATUS_WAIT_SCHEDULED = -1;
export const POST_STATUS_PROCESSING_SCHEDULED_PENDING = -2;
export const POST_STATUS_PROCESSING_SCHEDULED_ERROR = -3;
export const POST_STATUS_PROJECT_EXPIRED = -4;
export const POST_STATUS_SCHEDULED = 1;
export const POST_STATUS_PUBLISH_SUCCESS = 2;
export const POST_STATUS_PUBLISH_ERROR = 0;
export const POST_STATUS_PROCESS = 3;
export const POST_STATUS_HIDE = 4;
export const POST_STATUS_REMOVE = 5;
export const POST_STATUS_DEFAULT = 999;
export const MEDIA_STATUS_ERROR_HAVING_URL = 1;
export const MEDIA_STATUS_ERROR_NOT_URL = 2;
export const STATUS_COMPRESS_ERROR = 3;
export const POST_STATUS_MEDIA_ERROR = "config.status_post.media_error";

export const ARR_POST_STATUS_POST = {
  "-1": "config.status_post.pre_schedule",
  "-2": "config.status_post.process_schedule",
  "-3": "config.status_post.auto_cheduling_error",
  "-4": "config.status_post.cancel_publish",
  "0": "config.status_post.channel_error",
  "1": "config.status_post.scheduled",
  "2": "config.status_post.publish_success",
  "3": "config.status_post.process",
  "4": "config.status_post.hide",
  "5": "config.status_post.remove",
  "999": "config.status_post.view_detail",
}