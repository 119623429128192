import {
  DURATION_VIDEO_BY_60_SECONDS,
  DURATION_VIDEO_BY_900_SECONDS,
  DURATION_VIDEO_BY_90_SECONDS,
  MEDIA_TYPE_IMAGE,
  PLATFORM_FACEBOOK,
  PLATFORM_FACEBOOKGROUP,
  PLATFORM_INSTAGRAM,
  PLATFORM_ORIGINAL,
  PLATFORM_PINTEREST,
  PLATFORM_THREADS,
  PLATFORM_TIKTOK,
  PLATFORM_WITH_60_SECOND_DURATION_VIDEO,
  PLATFORM_WITH_90_SECOND_DURATION_VIDEO,
  PLATFORM_WITH_UNLIMIT_900_SECOND_DURATION_VIDEO,
  PLATFORM_YOUTUBE,
  PLATFORM_ZALO,
} from "../So9/utils/Utils";

// import SQUARE_FACEBOOK from "../assets/images/brands/facebook-square.svg";
import SQUARE_FACEBOOK from "../So9/images/post/facebook-square.svg";
import SQUARE_TIKTOK from "../So9/images/post/tiktok-square.svg";
import SQUARE_YOUTUBE from "../So9/images/post/youtube-square.svg";
import SQUARE_PINTEREST from "../So9/images/post/pinterest-square.svg";
import SQUARE_INSTAGRAM from "../So9/images/post/instagram-square.svg";
import SQUARE_THREADS from "../So9/images/post/threads-square.svg";
import PlatformIconZalo from "../So9/images/post/zalo.png";
import PlatformIconOriginal from "../So9/images/post/default-icon-social-network.png";
import PlatformIconDefault from "../So9/images/post/default-icon-social-network.png";
import { uploadImage, uploadMultiImageBulk } from "../api/createPost";

export function validateEmail(value) {
  return new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i).test(value);
}

export function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function classifyChannelsWithUniqeDuration(channels) {
  let dataChannelWithDuration = [
    {
      duration: DURATION_VIDEO_BY_90_SECONDS,
      channels: [],
    },
    {
      duration: DURATION_VIDEO_BY_60_SECONDS,
      channels: [],
    },
    {
      duration: DURATION_VIDEO_BY_900_SECONDS,
      channels: [],
    },
  ];
  channels.forEach((channel, index) => {
    if (PLATFORM_WITH_60_SECOND_DURATION_VIDEO.includes(channel.platform)) {
      dataChannelWithDuration[1].channels.push(channel);
    } else if (
      PLATFORM_WITH_90_SECOND_DURATION_VIDEO.includes(channel.platform)
    ) {
      dataChannelWithDuration[0].channels.push(channel);
    } else if (
      PLATFORM_WITH_UNLIMIT_900_SECOND_DURATION_VIDEO.includes(channel.platform)
    ) {
      dataChannelWithDuration[2].channels.push(channel);
    }
  });
  return dataChannelWithDuration;
}

export const convertToFraction = (width, height) => {
  const delta = 0.002;
  if (Math.abs(width / height - 9 / 16) <= delta) {
    return `9:16`;
  }

  let a = width;
  let b = height;

  while (b !== 0) {
    const temp = b;
    b = a % b;
    a = temp;
  }
  return `${width / a}:${height / a}`;
};

export const convertToFractionInstaPlatform = (width, height) => {
  const delta = 0.002;
  if (Math.abs(width / height - 16 / 9) <= delta) {
    return `16:9`;
  }

  let a = width;
  let b = height;

  while (b !== 0) {
    const temp = b;
    b = a % b;
    a = temp;
  }
  return `${width / a}:${height / a}`;
};

export const convertDurationToMMSS = (durationMillis) => {
  const seconds = Math.floor(durationMillis / 1000);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const minutesStr = String(minutes).padStart(2, "0");
  const secondsStr = String(remainingSeconds).padStart(2, "0");

  return `${minutesStr}:${secondsStr}`;
};

export const loadVideo = (file) =>
  new Promise((resolve, reject) => {
    try {
      let video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = function () {
        resolve(this);
      };

      video.onerror = function () {
        reject("Invalid video. Please select a video file.");
      };

      video.src = window.URL.createObjectURL(file);
    } catch (e) {
      reject(e);
    }
  });

export const fetchUserInfoLogin = () => {
  const stringUserInfo = !!localStorage.getItem("userInfor")
    ? localStorage.getItem("userInfor")
    : "";
  return !!stringUserInfo
    ? JSON.parse(stringUserInfo)
    : (window.location.href = "/login");
};

export const getPlatformSquareIcon = (platform) => {
  switch (platform) {
    case PLATFORM_FACEBOOK:
      return SQUARE_FACEBOOK;
    // case "instagram":
    //   return PlatformIconInstagram;
    case PLATFORM_TIKTOK:
      return SQUARE_TIKTOK;
    case PLATFORM_ZALO:
      return PlatformIconZalo;
    case PLATFORM_FACEBOOKGROUP:
      return SQUARE_FACEBOOK;
    case PLATFORM_YOUTUBE:
      return SQUARE_YOUTUBE;
    case PLATFORM_ORIGINAL:
      return PlatformIconOriginal;
    case PLATFORM_INSTAGRAM:
      return SQUARE_INSTAGRAM;
    case PLATFORM_PINTEREST:
      return SQUARE_PINTEREST;
    case PLATFORM_THREADS:
      return SQUARE_THREADS;
    default:
      return PlatformIconDefault;
  }
};

export const POST_PLATFORM_LABEL = {
  [PLATFORM_FACEBOOK]: "Facebook",
  [PLATFORM_TIKTOK]: "Tiktok",
  [PLATFORM_FACEBOOKGROUP]: "Facebook Group",
  [PLATFORM_YOUTUBE]: "Youtube",
  [PLATFORM_ZALO]: "Zalo",
  [PLATFORM_INSTAGRAM]: "Instagram",
  [PLATFORM_PINTEREST]: "Pinterest",
  [PLATFORM_THREADS]: "Threads",
  [PLATFORM_ORIGINAL]: "Original",
};

export const fetchIconData = () => {
  const stringIconData = !!localStorage.getItem("IconData")
    ? localStorage.getItem("IconData")
    : "";
  return !!stringIconData ? JSON.parse(stringIconData) : false;
};

export const shuffleContent = (content) => {
  if (!content) {
    return "";
  }
  /**
   * text
   */
  content = shuffleTextPattern(content);

  /**
   * icon
   */
  content = shuffleIconPattern(content);
  return content;
};

const shuffleTextPattern = (text) => {
  const pattern = /#text\{(.*?)\}/g;
  let match;

  // Find all matches
  while ((match = pattern.exec(text)) !== null) {
    const info = {
      content: match[1],
      offset: match.index,
      length: match[0].length,
    };

    const values = info.content.split("|");
    const randValue = values[Math.floor(Math.random() * values.length)];
    text =
      text.substring(0, info.offset) +
      randValue +
      text.substring(info.offset + info.length);

    // Move to the next match
    pattern.lastIndex = info.offset + randValue.length;
  }

  return text;
};

const shuffleIconPattern = (text) => {
  const iconConfig = {
    R1: [
      "💖",
      "💝",
      "💔",
      "❣️",
      "💕",
      "💞",
      "💓",
      "💗",
      "💘",
      "💟",
      "💌",
      "💋",
      "👄",
      "♥️",
      "❤️",
      "💛",
      "💚",
      "💙",
      "💜",
      "🖤",
      "🎁",
      "⭐",
      "🌟",
      "✨",
      "⚡",
      "💥",
      "🔥",
      "🌞",
      "☀️",
      "⛰",
      "🍸",
      "🍾️",
      "🎯️",
      "🏆",
      "🌜",
      "🌕",
      "👉",
      "🎇",
      "🌠",
      "🎆",
      "🔑",
      "💡",
      "🔔",
      "📣",
      "📢",
      "🎐",
      "🛎",
      "🚀",
    ],
    R2: [
      "😀",
      "😄",
      "😆",
      "😂",
      "🤣",
      "😊",
      "☺️",
      "😉",
      "😍",
      "😘",
      "🤗",
      "😇",
      "😝",
      "😋",
      "🤠",
      "🤡",
      "🤖",
      "👦",
      "👧",
      "🙋",
      "💁",
      "🙆",
      "😻",
      "😸",
      "😹",
      "😽",
    ],
    R3: [
      "😺",
      "😸",
      "😹",
      "😻",
      "😼",
      "😽",
      "🙀",
      "😿",
      "😾",
      "🐱",
      "🐶",
      "🐰",
      "🐭",
      "🐹",
      "🦊",
      "🐻",
      "🐼",
      "🐨",
      "🐯",
      "🦁",
      "🐮",
      "🐗",
      "🐷",
      "🐽",
      "🐸",
      "🐵",
      "🙈",
      "🙉",
      "🙊",
      "🦍",
      "🐺",
      "🐑",
      "🐃",
      "🐌",
      "🐲",
      "🐉",
      "🐾",
      "🐂",
      "🐐",
      "🐏",
      "🐄",
      "🐴",
      "🦄",
      "🐎",
      "🐪",
      "🦌",
      "🐫",
      "🐘",
      "🦏",
      "🦅",
      "🐤",
      "🐊",
      "🐢",
      "🐠",
      "🐣",
      "🐥",
      "🐟",
      "🐡",
      "🐔",
      "🐓",
      "🐬",
      "🦈",
      "🦃",
      "🐦",
      "🐳",
      "🐋",
      "🦆",
      "🦇",
      "🦑",
      "🐙",
      "🦉",
      "🕊️",
      "🦐",
      "🐧",
      "🐚",
      "🐕",
      "🦀",
      "🐩",
      "🦂",
      "🐈",
      "🦎",
      "🐍",
      "🐇",
      "🐁",
      "🐛",
      "🐀",
      "🐜",
      "🕷️",
      "🐿",
      "🐒",
      "🕸️",
      "🐞",
      "🐖",
      "🐆",
      "🦋",
      "🐝",
      "🐅",
    ],
    R4: [
      "🌼",
      "🌸",
      "🌺",
      "🏵️",
      "🌻",
      "🌷",
      "🌹",
      "💐",
      "🌾",
      "🎋",
      "☘",
      "🍀",
      "🍃",
      "🍂",
      "🍁",
      "🌱",
      "🌿",
      "🎍",
      "🌵",
      "🌴",
      "🌳",
      "🌳",
      "🎄",
      "🌎",
      "🌍",
      "🌏",
      "🌜",
      "🌛",
      "🌕",
      "🌖",
      "🌗",
      "🌝",
      "🌙",
      "💫",
      "⭐",
      "🌟",
      "✨",
      "⚡",
      "🔥",
      "💥",
      "☄️",
      "🌞",
      "☀️",
      "🌈",
      "💦",
      "🌊",
      "🌪",
      "⛄",
      "☃️",
    ],
    R5: [
      "🚗",
      "🚕",
      "🚙",
      "🚌",
      "🚎",
      "🏎",
      "🚓",
      "🚐",
      "🚚",
      "🚛",
      "🚜",
      "🛴",
      "🚲",
      "🛵",
      "🛵",
      "🏍",
      "🚘",
      "🚖",
      "🚍",
      "🚔",
      "✈",
      "🚁",
      "🚀",
      "🛰",
      "🚡",
      "🚠",
      "🚟",
      "🚃",
      "🚋",
      "🚞",
      "🚝",
      "🚄",
      "🚅",
      "🚈",
      "🚂",
      "🚆",
      "🚊",
      "🚇",
      "🚉",
      "🛶",
      "⛵",
      "🛥",
      "🚤",
      "🚢",
      "⛴",
      "🛳",
      "⚓",
      "🚧",
      "🚦",
      "🏭",
      "🏠",
      "🏡",
      "🏘",
      "🏚",
      "🏢",
      "🏦",
      "🏪",
      "🏫",
      "🏩",
      "🏛",
      "⛪",
      "💒",
      "🕌",
      "🕍",
      "🕋",
      "🗼",
      "🗿",
      "🎠",
      "🎢",
      "⛲",
      "⛱",
      "🏖",
      "🏝",
      "🏕",
      "⛺",
      "⛰",
      "🏔",
      "🗻",
      "🌅",
      "🎑",
      "🌠",
      "🎇",
      "🏙",
      "🌇",
      "🌆",
      "🌃",
    ],
  };
  const pattern = /@icon\{(.*?)\}/g;
  let match;

  // Find all matches
  while ((match = pattern.exec(text)) !== null) {
    const info = {
      content: match[1],
      offset: match.index,
      length: match[0].length,
    };

    const icons = iconConfig[info.content] || [];
    const randIndex = Math.floor(Math.random() * icons.length);
    const randValue = icons[randIndex] || "";
    text =
      text.substring(0, info.offset) +
      randValue +
      text.substring(info.offset + info.length);

    // Move to the next match
    pattern.lastIndex = info.offset + randValue.length;
  }

  return text;
};

async function createFile(url) {
  const response = await fetch(url);
  const data = await response.blob();
  const contentType = response.headers.get("Content-Type");
  const mimeType = contentType.split("/")[1];
  return new File([data], `${Date.now().toString()}.${mimeType}`, {
    type: contentType,
  });
}

export const uploadMediaImageDrive = async (imageUrls) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));

  const handleUploadChunk = async (chunkImageArrayItem) => {
    const data = new FormData();

    for (const image of chunkImageArrayItem) {
      const file = await createFile(image);
      data.append("images", file);
    }

    data.append("projectId", userInfo.last_project_active);
    data.append("folder", "post");
    return uploadMultiImageBulk(data);
  };

  try {
    if (imageUrls?.length) {
      const arrayChunkAll = [];

      while (imageUrls.length) {
        const data = imageUrls.splice(0, 30);
        arrayChunkAll.push(data);
      }

      let listImages = [];

      const response = await Promise.allSettled(
        arrayChunkAll.map((chunkImageArrayItem) =>
          handleUploadChunk(chunkImageArrayItem)
        )
      );

      for (const item of response) {
        if (item.status === "fulfilled") {
          listImages = [...listImages, ...item.value.data];
        }
      }

      return listImages;
    }

    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};
