import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Col, FormGroup, ModalBody } from "reactstrap";
import { Button, Icon } from "../../../../components/Component";
import { LoadingItem } from "../../Dashboard_v2/CommonItem/LoadingItem";

export const KanbanGroupForm = ({
  toggle,
  group,
  handleCreateGroup,
  handleUpdateGroup,
}) => {
  const { t } = useTranslation();

  const [loadingCreateGroup, setLoadingCreateGroup] = useState(false);
  const [formData, setFormData] = useState({
    name: group ? group?.name : "",
    description: group ? group?.description : "",
  });

  const submitForm = async () => {
    setLoadingCreateGroup(true);

    try {
      group
        ? handleUpdateGroup(group?._id, formData)
        : handleCreateGroup(formData);
    } catch (e) {
      toggle();
      setLoadingCreateGroup(false);
      console.error(e);
    } finally {
      toggle();
      setLoadingCreateGroup(false);
    }
  };

  const { handleSubmit } = useForm();

  return (
    <ModalBody>
      <Button className="close" onClick={() => toggle()}>
        <Icon name="cross-sm"></Icon>
      </Button>
      <div className="p-2">
        <h5 className="title">
          {group
            ? `${t("task_management.form.update")}`
            : `${t("task_management.form.add")}`}{" "}
          {t("task_management.group")}
        </h5>
        <div className="mt-4">
          <form className="row gy-4" onSubmit={handleSubmit(submitForm)}>
            <Col className="col-12">
              <FormGroup>
                <label className="form-label">
                  {t("task_management.form.group_title")}
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder={t(
                    "task_management.placeholder.please_type_your_group_title"
                  )}
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      name: e.target.value,
                    })
                  }
                  required
                  className="form-control"
                />
              </FormGroup>
            </Col>
            <Col className="col-12">
              <FormGroup>
                <label className="form-label">
                  {t("task_management.form.description")}
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.description}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      description: e.target.value,
                    })
                  }
                  placeholder={t(
                    "task_management.placeholder.please_type_your_group_desc"
                  )}
                  // required
                  className="form-control"
                />
              </FormGroup>
            </Col>
            <Col className="col-12">
              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                <li>
                  <Button
                    className="btn d-flex justify-content-center align-center"
                    color="primary"
                    size="md"
                    type="submit"
                  >
                    {loadingCreateGroup ? (
                      <div
                        style={{
                          display: "flex",
                          marginLeft: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <LoadingItem
                          style={{
                            width: "16px",
                            height: "16px",
                            color: "#fff",
                          }}
                        />
                      </div>
                    ) : group ? (
                      t("task_management.form.edit_group")
                    ) : (
                      t("task_management.form.add_group")
                    )}
                  </Button>
                </li>
                <li>
                  <Button
                    type="button"
                    onClick={() => toggle()}
                    className="link link-light"
                  >
                    {t("task_management.form.cancel")}
                  </Button>
                </li>
              </ul>
            </Col>
          </form>
        </div>
      </div>
    </ModalBody>
  );
};
