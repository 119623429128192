import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  channels: [],
  channelsInformation: [],
  postsDeleted: {},
  errorConnectChannelMesssages:  [],
  channelInf: null,
};

export const channelSlice = createSlice({
  name: "channel",
  initialState,
  reducers: {
    setChannels(state, action) {
      state.channels = action.payload;
    },
    setChannelsInformation(state, action) {
      state.channelsInformation = action.payload;
    },
    setPostsDeleted(state, action) {
      state.postsDeleted = action.payload;
    },
    setErrorConnectChannelMesssages(state, action) {
      state.errorConnectChannelMesssages = action.payload
    },
    setChannelInf(state, action) {
      state.channelInf = action.payload
    }
  },
});

export const { setChannels, setPostsDeleted, setChannelsInformation, setErrorConnectChannelMesssages, setChannelInf } = channelSlice.actions;

export default channelSlice.reducer;
