import { createSlice } from "@reduxjs/toolkit";
// const initialState = {
//     isOpen : false ,
//   };
  
  export const intructionSlice = createSlice({
    name: "intructionBook",
    initialState : {
        isOpen : false,
        activeTab : "" ,
    },
    reducers: {
      setIsOpen(state, action) {
        state.isOpen = action.payload;
      },
      setActiveTab(state,action) {
        state.activeTab = action.payload;
      },
    },
  });
  
  export const { setIsOpen } = intructionSlice.actions;
  
  export default intructionSlice.reducer;