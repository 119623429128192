import { useState, useRef, useEffect } from "react"
import SimpleBar from "simplebar-react";
import Styles from "./index.module.scss"
import { getGuildeMenu } from "../../../api/news";
import { useLocation } from 'react-router-dom';
import AccoditionItem from "./AccoditionItem";
import { Img } from "../../../components/Component";
import { listWindowName } from "./ListNameByWindow";
import { useDispatch, useSelector } from 'react-redux';
import { setIsOpen } from "../../reducers/intructionSlice";
import { useTranslation } from "react-i18next";
const IntructionBook = ({ currentLanguage }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [cavasMode, setCavasMode] = useState("menu");
    const [menuData, setMenuData] = useState();
    const [subMenuData, setSubMenuData] = useState();
    const [contentData, setContentData] = useState();

    const simpleBarRef = useRef();
    const toggleRef = useRef();
    const location = useLocation();
    const dispatch = useDispatch();
    const isOpenCavas = useSelector((state) => state.intruction.isOpen);

    const setIsOpenCavas = (value) => {
        dispatch(setIsOpen(value));
    }
    const {t} = useTranslation();
    const openCavas = () => {
        if (!isLoading) {
            if (!isOpenCavas) {
                setIsOpenCavas(true);
            }
        }
    }

    const closeCavas = () => {
        setIsOpenCavas(false);
    }

    const backToMainMenu = () => {
        setCavasMode("menu")
    }

    const getMenuOption = async (language) => {
        setIsLoading(true);
        try {
            const menuRes = await getGuildeMenu({ params: { location: language } });
            if (menuRes.status) {
                setMenuData(menuRes.data)
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(true);
            console.log(error);
        }
    }

    const renderMenuSubItem = (subItem, menuName) => {
        return (
            <div className={`${Styles.subItem} d-flex justify-content-start align-items-center px-3`}
                key={`SubMenuItem${subItem.id}`}
            >
                <div className={`${Styles.subItemHeader} d-flex gap-2`}>
                    <Img src={subItem?.icon} className={`${Styles.subItemBlock} mb-0 rounded`}>
                    </Img>
                    <p
                        className="mb-0 text-truncate cursor-pointer"
                        style={{ maxWidth: "250px" }}
                        onClick={() => {
                            const newItem = subItem;
                            newItem.categories = menuName;
                            newItem.desc = replaceOembed(subItem);
                            setContentData(newItem);
                            setCavasMode("guilde")
                        }}
                    >
                        {subItem?.name}
                    </p>
                </div>
                <div className={`${Styles.subItemGuide} d-flex justify-content-start align-items-center gap-1 cursor-pointer`}
                    onClick={() => {
                        const newItem = subItem;
                        newItem.categories = menuName;
                        newItem.desc = replaceOembed(subItem);
                        setContentData(newItem);
                        setCavasMode("guilde")

                    }}
                >
                    <em className="icon ni ni-reports-alt"></em>
                    <p style={{ fontSize: 12 }}>
                        {subItem?.guides?.length} Guide
                    </p>
                </div>
            </div>
        )
    }

    const renderMenuItem = (menuItem) => {
        return (
            <>
                {menuItem && (
                    <div className={`${Styles.contentItem}`} key={`menuItem${menuItem?.id}`}>
                        <div className={`${Styles.contentItemHeader} px-3`}>
                            {menuItem?.name}
                        </div>
                        {menuItem.children && menuItem.children.length && (
                            menuItem.children.map((subItem) => {
                                return (
                                    <>
                                        {renderMenuSubItem(subItem, menuItem?.name)}
                                    </>
                                )
                            })
                        )}
                    </div>
                )}

            </>
        )
    }

    const renderCavasHeader = () => {

        return (
            <>
                <div className={`${Styles.sideBarHeader} w-100 d-flex justify-content-between px-3`}>

                    {cavasMode === "menu" ? (
                        <p className="mb-0 align-self-center">
                            {t('news.support.guidebook')}
                        </p>) : (
                        <em className="icon ni ni-back-ios align-self-center cursor-pointer fw-bold"
                            style={{ fontSize: "14px" }}
                            onClick={() => {
                                if (cavasMode === "temp") {
                                    backToMainMenu();
                                } else {
                                    checkCurrentUrl();
                                }
                            }}
                        ></em>
                    )
                    }
                    <em className="icon ni ni-cross align-self-center cursor-pointer"
                        onClick={() => {
                            closeCavas();
                        }}
                    ></em>
                </div>
            </>
        )
    }

    const renderMainMenu = () => {

        return (
            <>
                {menuData && menuData.length > 0 ?
                    (<>
                        {menuData.map((menuItem) => {
                            return (
                                <>
                                    {renderMenuItem(menuItem)}
                                </>)
                        })}
                    </>) : <h3 className="mt-3 mx-3">Không có dữ liệu</h3>
                }

            </>
        )
    }

    const renderTempMenuItem = (item) => {
        return (
            <>
                <div className="px-3">

                    <div className={`${Styles.tempMenuItem} d-flex gap-2 cursor-pointer`} key={`subMenuContent${item?.id}`}
                        onClick={() => {
                            const newItem = item;
                            // newItem.categories = menuName;
                            newItem.desc = replaceOembed(item);
                            setContentData(newItem);
                            setCavasMode("guilde")

                        }}
                    >
                        <div className={`${Styles.blockIcon}`}>
                            <div className={`${Styles.itemIcon} d-flex align-items-center`}>
                                {item.icon !== "" ?
                                    <Img src={item?.icon} className="rounded"></Img>
                                    :
                                    <em class="icon ni ni-arrow-left-circle"></em>
                                }


                            </div>
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-start">
                            <p className={`${Styles.guildeItemName} mb-1 text-truncate`}>
                                {item?.name}
                            </p>
                            <div style={{ fontSize: 12 }} className={`${Styles.subItemGuide} d-flex justify-content-start align-items-center gap-1 cursor-pointer`}
                            >
                                <em className="icon ni ni-reports-alt"></em>
                                {item.guides?.length} Guide
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )

    }

    const renderGuidesContent = () => {


        return (
            <>
                {contentData && contentData?.guides.length > 0 ? (
                    contentData.guides.map((guide) => {
                        return (
                            <>
                                <AccoditionItem
                                    item={guide}
                                />
                            </>
                        )
                    })
                ) : (<h3 className="mt-3 px-3">Không có hướng dẫn nào!</h3>)}
            </>
        );
    }

    const getWindowName = () => {
        const pathname = location.pathname;
        const WindowData = listWindowName.find((window) => window.link.includes(pathname))
        if (!WindowData?.name) {
            setCavasMode("menu");
        }
        return WindowData?.name;
    }
    const renderTempMenu = () => {
        return (
            <>
                {cavasMode !== "menu" && (
                    <>
                        <div className={`${Styles.contentTypeHeader} px-3`}>
                            <p className={`${Styles.contentTypeCategories}`}>
                                {cavasMode === "guilde" ? contentData?.categories : "Sổ hướng dẫn thông minh"}

                            </p>
                            <p className={`${Styles.contentType}`}>{cavasMode === "temp" ? getWindowName() : contentData?.name}</p>
                        </div>
                        <div className={`${Styles.cavasGuildeContent}`}>
                            {cavasMode === "temp" ? (
                                subMenuData?.length > 0 ? (
                                    <>
                                        {subMenuData?.map((item) => {
                                            return (
                                                <>
                                                    {renderTempMenuItem(item)}
                                                </>
                                            )
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <h3 className="mt-3 px-3">Không có hướng dẫn nào!</h3>
                                    </>
                                )

                            ) : (
                                <>
                                    {renderGuidesContent()}
                                </>)}
                        </div>
                    </>
                )}

            </>
        )
    }
    const renderCavasContent = () => {

        return (
            <>
                {cavasMode === "menu" && (
                    <>
                        {renderMainMenu()}
                    </>
                )}
                {cavasMode !== "menu" && (
                    <>
                        {renderTempMenu()}
                    </>
                )}
            </>
        )
    }

    const findElementsWithPathname = (links, pathName) => {
        const linksWithPathname = links.find(link => link.includes(pathName));
        return linksWithPathname;
    }
    const findElementByLink = (data, currentLink, listEle) => {
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (!element?.link) {
                if (element.children.length > 0) {
                    const foundElement = findElementByLink(element.children, currentLink, listEle);
                    if (foundElement) {
                        listEle.push(element);
                    }
                }
            } else {
                const LinkLog = findElementsWithPathname(element.link, currentLink);
                if (LinkLog) {
                    listEle.push(element);
                }
                if (element.children.length > 0) {
                    const foundElement = findElementByLink(element.children, currentLink, listEle);
                    if (foundElement) {
                        listEle.push(element);
                    }
                }
            }

        }
    }

    const checkCurrentUrl = () => {
        const pathname = location.pathname;
        const isSmart = getWindowName();
        setSubMenuData([]);
        const listEles = []
        if (isSmart) {
            setCavasMode("temp")
            if (pathname !== "/") {
                if (menuData) {
                    findElementByLink(menuData, pathname, listEles);
                    if (listEles.length > 0) {
                        setSubMenuData(listEles);
                    } else {
                        setCavasMode("menu")
                    }
                }
            } else {
                setCavasMode("menu");
            }
        }

    }

    const replaceOembed = (arrInput) => {
        let arrOutput = arrInput
        const oembedRegex = /<oembed[^>]*>/g;

        if (arrInput?.guides && arrInput?.guides.length > 0) {
            arrInput?.guides.map((guide, index) => {
                const oembedMatch = guide?.desc.match(oembedRegex);
                // If an oembed element was found, convert it to an iframe element
                if (oembedMatch) {
                    const oembedUrl = oembedMatch[0].match(/url="([^"]*)"/)[1];
                    const iframeElement = `<iframe src=${oembedUrl} action="embedview" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen
                   style="width:400px;"
            ></iframe>`;
                    const htmlString = guide?.desc.replace(oembedRegex, iframeElement);
                    arrOutput.guides[index].desc = htmlString;
                }
            })
        }
        return arrOutput;
    }


    const findScriptTags = (inputString) => {
        if (inputString) {
            const regex = /<script>.*?<\/script>/g;
            const matches = inputString.match(regex);
            const uniqueMatches = [...new Set(matches)];
            return uniqueMatches || [];
        }
        return [];
    };

    useEffect(() => {
        currentLanguage && getMenuOption(currentLanguage);

        const handleMouseDown = (event) => {
            if (simpleBarRef.current && simpleBarRef.current.contains(event.target)) {
                openCavas()
            } else {
                if (toggleRef.current && toggleRef.current.contains(event.target)) {
                    openCavas()
                } else {
                    if (event.target.closest('#SRLLightbox')) {
                        return
                    } else {
                        setIsOpenCavas(false);
                    }
                }
            }
        };
        document.addEventListener('mousedown', handleMouseDown);
        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
        };

    }, [currentLanguage]);

    useEffect(() => {
        checkCurrentUrl();
    }, [location])
    useEffect(() => {
        checkCurrentUrl();
    }, [menuData])

    useEffect(() => {
        if (contentData?.guides && contentData?.guides.length > 0) {
            contentData?.guides.map((guide) => {
                const listScript = findScriptTags(guide?.desc);
                if (listScript && listScript.length > 0) {
                    listScript.map((script) => {
                        const scriptElement = document.createElement('script');
                        scriptElement.innerHTML = script;
                        document.head.appendChild(scriptElement);
                    })
                }
            })
        }
    }, [contentData])
    return (
        <>
            <div className={`${Styles.buttonToggle} position-fixed cursor-pointer`}
                style={{
                    top: "50%", right: isOpenCavas ? "356px" : "-45px", transform: "translateY(-50%)",
                    transition: "all 0.7s ease 0s",
                    transform: "rotate(-90deg)"
                }}
            >
                <div className={`${Styles.roteToggleIcon}`}
                    ref={toggleRef}
                    onClick={() => {
                        setCavasMode("menu");
                        openCavas();
                    }}
                >
                    <em className="icon ni ni-copy d-block"></em>
                </div>
                <div className={`${Styles.roteToggleHeader} text-nowrap`}
                    data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
                    onClick={() => { openCavas() }}
                >
                    <em className={isOpenCavas ? "icon ni ni-downward-ios mr-1" : "icon ni ni-upword-ios mr-1"} ></em>
                    {t('news.support.guidebook')}
                </div>

            </div>
            <div
                className={`nk-demo-panel nk-demo-panel-2x toggle-slide toggle-slide-right toggle-screen-any p-0  ${isOpenCavas ? "content-active" : ""}`}
                style={{ maxWidth: "400px", minWidth: "400px" }}
                ref={simpleBarRef}
            >
                <SimpleBar style={{ maxHeight: "100%" }} className={`${Styles.documentWraper}`}
                >
                    <div className="w-100 m-0"
                        style={{
                            height: "8px",
                            backgroundColor: cavasMode === "menu" ? "rgba(0, 103, 198, 1)" : "rgba(255, 219, 56, 1)"
                        }}
                    >

                    </div>
                    <div className={`py-2 h-100 ${Styles['ck-content']}`}
                    >
                        {renderCavasHeader()}
                        <div className={`${Styles.sideBarContent} w-100 py-3 h-100`}>
                            {renderCavasContent()}
                        </div>

                    </div>
                </SimpleBar>
            </div>

        </>
    )
}
export default IntructionBook

// "<h3><span style="white-space:pre-wrap;">Chia VIA đăng bài giảm Spam</span></h3><p><span style="white-space:pre-wrap;">Khi bạn có từ 10 Fanpage trở lên, bạn hãy chia Tài khoản dùng để kết nối Fanpage vào SO9.&nbsp;</span></p><p><span style="white-space:pre-wrap;"><strong>Nguyên nhân:&nbsp;</strong></span></p><p>Nền tảng cũng có Rule hạn chế <strong>Số lượng bài đăng / Tài khoản</strong> (Chính sách cộng đồng của Meta). Ví dụ: 1 Tài khoản khi mỗi ngày đăng &gt;20 video liên tục trong nhiều ngày sẽ dần bị tính là spam (Số lượng này phụ thuộc vào từng tài khoản khác nhau). Do vậy, nếu bạn kết nối 1 Tài với 20 Fanpage, khi mỗi Fanpage đăng 1 video / ngày thì Tài khoản đó cũng sẽ được tính là đăng 20 bài.&nbsp;</p><p><strong>Giải pháp:</strong></p><p>Hệ thống SO9 cho phép bạn có thể kết nối Fanpage thông qua nhiều Tài khoản khác nhau, điều này giúp giảm <strong>Tần suất đăng / Tài khoản</strong>. Bảo vệ bạn khỏi vi phạm chính sách cộng đồng của Meta&nbsp;</p><figure class="media"><oembed url="https://www.youtube.com/watch?v=f4r4sFvY2YY&amp;list=PL5TqJiJIECYwF_sNFwFvjsqVmebuG5qVK&amp;index=1&amp;t=6s"></oembed></figure>"