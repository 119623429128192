import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Progress,
  Row,
} from "reactstrap";
import style from "../../../index.module.scss";
import { formatNumberAcronym } from "../../../../../../utils/Utils";

const TTPost = (props) => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(props?.data);
  }, [props?.data]);

  const sortedCountries = data?.countries?.sort((a, b) => b?.value - a?.value);

  return (
    <Row className="d-flex flex-column flex-md-row mb-4">
      <Col
        sm={12}
        md={4}
        className="w-100 d-flex flex-row flex-md-column mb-3 mb-md-0"
        style={{ gap: "16px" }}
      >
        <div className="w-100 h-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Lượt xem
              </CardTitle>
              <div className="d-flex flex-row align-items-center">
                <div className={`${style.titleCard}`}>
                  {data && formatNumberAcronym(data?.video_views?.value)}
                </div>
                {/* <div className="ml-1" style={{ color: "#10B981" }}>
                      {" "}
                      <Icon name={"arrow-up"}></Icon> 0%
                    </div> */}
              </div>
              <CardText
                style={{ whiteSpace: "nowrap" }}
                className={`${style.detailCard} mt-1`}
              >{`Trung bình: ${
                data ? data?.video_views?.avg_day : ""
              }/ngày`}</CardText>
            </CardBody>
          </Card>
        </div>
        <div className="w-100 h-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Bình luận
              </CardTitle>
              <div className="d-flex flex-row align-items-center">
                <div className={`${style.titleCard}`}>
                  {data && formatNumberAcronym(data?.comments?.value)}
                </div>
                {/* <div className="ml-1" style={{ color: "#10B981" }}>
                      {" "}
                      <Icon name={"arrow-up"}></Icon> 0%
                    </div> */}
              </div>
              <CardText
                style={{ whiteSpace: "nowrap" }}
                className={`${style.detailCard} mt-1`}
              >{`Trung bình: ${
                data ? data?.comments?.avg_day : ""
              }/ngày`}</CardText>
            </CardBody>
          </Card>
        </div>
      </Col>
      <Col
        sm={12}
        md={4}
        className="w-100 d-flex flex-row flex-md-column mb-3 mb-md-0"
        style={{ gap: "16px" }}
      >
        <div className="w-100 h-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Thích
              </CardTitle>
              <div className="d-flex flex-row align-items-center">
                <div className={`${style.titleCard}`}>
                  {data && formatNumberAcronym(data?.likes?.value)}
                </div>
                {/* <div className="ml-1" style={{ color: "#10B981" }}>
                      {" "}
                      <Icon name={"arrow-up"}></Icon> 0%
                    </div> */}
              </div>
              <CardText
                style={{ whiteSpace: "nowrap" }}
                className={`${style.detailCard} mt-1`}
              >{`Trung bình: ${
                data ? data?.likes?.avg_day : ""
              }/ngày`}</CardText>
            </CardBody>
          </Card>
        </div>
        <div className="w-100 h-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Chia sẻ
              </CardTitle>
              <div className="d-flex flex-row align-items-center">
                <div className={`${style.titleCard}`}>
                  {data && formatNumberAcronym(data?.shares?.value)}
                </div>
                {/* <div className="ml-1" style={{ color: "#10B981" }}>
                      {" "}
                      <Icon name={"arrow-up"}></Icon> 0%
                    </div> */}
              </div>
              <CardText
                style={{ whiteSpace: "nowrap" }}
                className={`${style.detailCard} mt-1`}
              >{`Trung bình: ${
                data ? data?.shares?.avg_day : ""
              }/ngày`}</CardText>
            </CardBody>
          </Card>
        </div>
      </Col>

      <Col sm={12} md={4} className="w-100 mb-3 mb-md-0">
        <Card className="h-100">
          <CardBody className="card-inner">
            <CardTitle tag="h5" className={`${style.headCard}`}>
              Quốc gia
            </CardTitle>
            <div
              className={`${style.headCard} d-flex flex-row align-items-center w-100 pb-2`}
              style={{
                fontSize: "0.75rem",
                borderBottom: "1px solid #D1D5DB",
              }}
            >
              <div className="w-25">Quốc gia</div>
              <div className="w-50">Follower</div>
              {/* <div className="w-25"></div> */}
              <div className="w-25 d-flex justify-content-end">%</div>
            </div>
            <div className="mt-2 d-flex flex-column" style={{ gap: "8px" }}>
              {data &&
                sortedCountries?.map((item, index) => {
                  const totalY = sortedCountries?.reduce(
                    (acc, obj) => acc + obj.value,
                    0
                  );
                  return (
                    <div
                      className={`${style.contentCard} d-flex flex-row align-items-center w-100`}
                      key={index}
                    >
                      <div className="w-25">{item?.source}</div>
                      <div className="w-15">
                        {formatNumberAcronym(item?.value)}
                      </div>
                      <div className="w-35">
                        <Progress
                          value={((item?.value * 100) / totalY).toFixed(0)}
                          barStyle={{ backgroundColor: "#2C4B94" }}
                          style={{
                            backgroundColor: "#F1F5FF",
                          }}
                        />
                      </div>
                      <div
                        className="w-25 d-flex justify-content-end"
                        style={{ fontWeight: "600" }}
                      >
                        {((item?.value * 100) / totalY).toFixed(1)}
                      </div>
                    </div>
                  );
                })}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default TTPost;
