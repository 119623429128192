import { Col, Input, Label, Row, UncontrolledTooltip } from "reactstrap";
import SQUARE_YOUTUBE from "../../../images/post/youtube-square.svg";
import { useEffect, useState } from "react";
import { Img, RSelect } from "../../../../components/Component";
import { fetchUserInfoLogin } from "../../../../libs/helper";
import { Getcategories, Getplaylists } from "../../../../api/createPost";
import { useTranslation } from "react-i18next";
import { CheckedBox } from "../../../../components/Component";

const YoutubeSetting = ({ formData, setFormData, channels, setChannels }) => {
  const { t } = useTranslation();
  const userInfor = fetchUserInfoLogin();
  const channel = channels?.channel?.find(
    (c) => c.platform === 5 && c.check === true
  );
  let youtubeChannels = channels?.channel?.filter(
    (c) => c.platform === 5 && c.check === true
  );
  const [categories, setCategories] = useState([]);
  const [renderList, setRenderList] = useState([]);
  const setType = (type) => {
    setFormData({ ...formData, type: type });
  };

  const statusOption = [
    { label: "Public", value: "Public" },
    { label: "Private", value: "Private" },
  ];

  const licenseOption = [
    { label: "Standard YouTube Licence", value: "youtube" },
    { label: "Creative Commons - Attribution", value: "creativeCommon" },
  ];

  useEffect(() => {
    if (categories.length === 0) {
      channel && loadCategories(channel._id);
    }
    youtubeChannels && fetchPlaylists();
    let youtube = channels?.channel?.filter(
      (c) => c.platform === 5 && c.check === true
    );
    setRenderList(youtube);
  }, [channels]);

  const loadCategories = async (idChannel) => {
    const data = {
      project_id: userInfor.last_project_active,
    };
    const response = await Getcategories({ channelId: idChannel, data: data });
    const listCategories = response.data.map((c) => ({
      value: c.id,
      label: c?.snippet?.title,
    }));
    setCategories(listCategories);
  };

  const loadPlaylist = async (idChannel) => {
    const channel = channels?.channel?.find((c) => c._id === idChannel);

    if (channel && channel?.listPlaylist) {
      return;
    }
    const data = {
      project_id: userInfor.last_project_active,
    };
    const response = await Getplaylists({ channelId: idChannel, data: data });
    const listCategories = response.data.map((c) => ({
      value: c.id,
      label: c?.snippet?.title,
    }));

    youtubeChannels = youtubeChannels.map((item) => {
      if (item._id === idChannel) {
        item.listPlaylist = listCategories;
      }
      return item;
    });

    setRenderList(youtubeChannels);
  };
  const fetchPlaylists = async () => {
    const promises = youtubeChannels.map((channel) =>
      loadPlaylist(channel._id)
    );
    const results = await Promise.allSettled(promises);

    results.forEach((result, index) => {
      if (result.status === "fulfilled") {

      } else {
        console.error(`Failed to fetch playlist for channelId${index + 1}`);
      }
    });
  };

  const handleSelectPlaylist = (e, item) => {
    let newData = [...channels?.channel];
    newData = newData.map((channel) => {
      if (channel._id === item._id) {
        channel.selectedPlaylist = e.value;
      }
      return channel;
    });
    setChannels({ ...channel, channel: newData });
  };

  return (
    <div className={`rounded  mt-3 p-2 color-border border-1`}>
      <div className="d-flex justify-content-between gap-3">
        <div className="d-flex align-items-center">
          <div
            className="d-flex justify-content-center align-items-center rounded position-relative"
            style={{
              background: "rgba(243,246,249,1)",
              width: "36px",
              height: "36px",
            }}
          >
            <Img src={SQUARE_YOUTUBE} height="16px"></Img>
          </div>
          <p className="fs-13 ms-2 mb-0" style={{ color: "#495057", fontWeight: 600 }}>
            {t('store_post.setting.youtube.title')}
          </p>
        </div>
      </div>
      <Row className="mt-2" style={{ marginLeft: "32px" }}>
        <Col md={12} sm={12} className="mt-2">
          <Row>
            <Col md={2}>
              <p className="fs-13 mb-0 text-[#495057]">
                {t('store_post.setting.youtube.type')}
              </p>
            </Col>
            <Col md={10}>
              <div className="d-flex justify-content-start">
                <div className="form-check mb-2 pl-0">
                  <Input
                    className="form-check-input"
                    onChange={() => setType("video")}
                    type="radio"
                    name="flexRadioYoutube"
                    id="flexRadioYoutube1"
                    defaultChecked
                  />
                  <Label className="form-check-label" for="flexRadioYoutube1">
                    Video
                  </Label>
                </div>
                <div className="form-check">
                  <Input
                    className="form-check-input"
                    onChange={() => setType("short")}
                    type="radio"
                    name="flexRadioYoutube"
                    id="flexRadioYoutube2"
                  />
                  <Label className="form-check-label" for="flexRadioYoutube2">
                    Short
                  </Label>
                </div>
              </div>

              {formData.type === "short" && (
                <div
                  className="p-1 w-50 rounded"
                  style={{ backgroundColor: "#fef7cd", color: "#b68103" }}
                >
                  <p className="fs-12 mb-0">
                    {t('store_post.setting.youtube.note_with_short')}
                  </p>
                </div>
              )}
            </Col>
          </Row>
        </Col>
        <Col md={12} sm={12} className="mt-2">
          <Row>
            <Col md={2} className="m-auto">
              <p className="d-flex fs-13 mb-0 text-[#495057]">
                {t('store_post.setting.youtube.video_title')}
              </p>
            </Col>
            <Col md={10}>
              <div className="rounded bg-[#F06548]">
                <Input
                  type="text"
                  className="border-0 hover:border-0 focus:border-0 bg-[#F3F6F9]"
                  value={formData.title}
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                  style={{
                    marginLeft: formData.title.length === 0 ? "4px" : "0px",
                  }}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={6} sm={12} className="mt-2">
          <Row>
            <Col md={4} className="m-auto">
              <p className="d-flex text-[#495057] fs-13 text-nowrap mb-0 align-items-center">
                {t('store_post.setting.youtube.category.title')}
              </p>
            </Col>
            <Col md={8}>
              <RSelect
                options={categories}
                onChange={(e) =>
                  setFormData({ ...formData, category: e.value })
                }
                placeholder={
                  <div className="text-[#526484]">
                    {t('store_post.setting.youtube.category.placeholder')}
                  </div>
                }
              ></RSelect>
            </Col>
          </Row>
        </Col>
        <Col md={6} sm={12} className="mt-2">
          <Row>
            <Col md={4} className="m-auto">
              <p className="d-flex text-[#495057] fs-13 text-nowrap mb-0 align-items-center">
                {t('store_post.setting.youtube.status')}
              </p>
            </Col>
            <Col md={8}>
              <RSelect
                options={statusOption}
                defaultValue={statusOption[0]}
                onChange={(e) => setFormData({ ...formData, status: e.value })}
                placeholder="Public"
              ></RSelect>
            </Col>
          </Row>
        </Col>
        <Col md={6} sm={12} className="mt-2">
          <Row>
            <Col md={4} className="m-auto">
              <p className="d-flex text-[#495057] fs-13 text-nowrap mb-0 align-items-center">
                {t('store_post.setting.youtube.video_tags.title')}
              </p>
            </Col>
            <Col md={8}>
              <Input
                value={formData.tags}
                onChange={(e) =>
                  setFormData({ ...formData, tags: e.target.value })
                }
                placeholder={t('store_post.setting.youtube.video_tags.placeholder')}
              ></Input>
            </Col>
          </Row>
        </Col>
        <Col md={6} sm={12} className="mt-2">
          <Row>
            <Col md={4} className="m-auto">
              <p className="d-flex text-[#495057] fs-13 text-nowrap mb-0 align-items-center">
                {t('store_post.setting.youtube.lincense')}
              </p>
            </Col>
            <Col md={8}>
              <RSelect
                options={licenseOption}
                defaultValue={licenseOption[0]}
                onChange={(e) => setFormData({ ...formData, license: e.value })}
                placeholder="Standard Youtube Licence"
              ></RSelect>
            </Col>
          </Row>
        </Col>
        {renderList &&
          renderList.map((item, key) => (
            <Col md={6} sm={12} className="mt-2">
              <Row>
                <Col md={4}>
                  <div
                    style={{ height: "36px", width: "36px" }}
                  >
                    <Img
                      src={item.picture}
                      style={{ height: "36px", width: "36px" }}
                      className="rounded-circle"
                      id={`channel-youtube-${key}`}
                    ></Img>

                    <UncontrolledTooltip
                      target={`channel-youtube-${key}`}
                      style={{
                        color: "#ffffff",
                      }}
                    >
                      {item.name}
                    </UncontrolledTooltip>


                  </div>
                </Col>
                <Col md={8}>
                  <RSelect
                    options={item.listPlaylist}
                    value={item.listPlaylist?.find(
                      (i) => i.value === item.selectedPlaylist
                    )}
                    onChange={(e) => handleSelectPlaylist(e, item)}
                    placeholder={
                      <div className="text-[#526484]">
                        {t('store_post.setting.youtube.placeholder_playlist')}
                      </div>
                    }
                  ></RSelect>
                </Col>
              </Row>
            </Col>
          ))}
        <Col md={12} sm={12} className="mt-4">
          <Row>
            <Col md={2}>
              <p className="d-flex text-[#495057] fs-13 text-nowrap mb-0 align-items-center">
                {t('store_post.setting.youtube.option.title')}
              </p>
            </Col>
            <Col md={10} className="d-flex justify-content-start">
              <div className="form-check mb-2 pl-0 flex-row flex items-center">
                <CheckedBox
                  checked={formData.embed}
                  onChange={() =>
                    setFormData({ ...formData, embed: !formData.embed })
                  }
                />
                <div
                  style={{
                    fontSize: 13,
                    marginLeft: 7,
                    color: "#212529",
                  }}
                >
                  {t('store_post.setting.youtube.option.embeddable')}
                </div>
              </div>
              <div className="form-check mb-2 flex-row flex items-center">
                <CheckedBox
                  checked={formData.notify}
                  onChange={() =>
                    setFormData({ ...formData, notify: !formData.notify })
                  }
                />
                <div
                  style={{
                    fontSize: 13,

                    marginLeft: 7,
                    color: "#212529",
                  }}
                >
                  {t('store_post.setting.youtube.option.notify_subscribers')}
                </div>
              </div>
              <div className="form-check mb-2 flex-row flex items-center">
                <CheckedBox
                  checked={formData.kids}
                  onChange={() =>
                    setFormData({ ...formData, kids: !formData.kids })
                  }
                />

                <div
                  style={{
                    fontSize: 13,
                    marginLeft: 7,
                    color: "#212529",
                  }}
                >
                  {t('store_post.setting.youtube.option.made_for_kids')}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default YoutubeSetting;
