import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";

import { getUserInfoInLocalStorage } from "../../helper";

import { GeneralItem } from "./GeneralItem";
import ImpressionStatistic from "./ImpressionStatistic";
import {
  getContentChart,
  getContentGeneral,
  getContentTopPost,
  getLogFetchFb,
  getContentStatistic,
} from "../../../../../api/dashboard";
import ContentStatisticTable from "../CommonItem/ContentStatisticTable";
import { TopPostColumn } from "../CommonItem/TopPostColumn";
import CHAT_ICON from "../Icon/chat.svg";
import CLICK_ICON from "../Icon/click.svg";
import defaultChannelIcon from "../Icon/facebook.svg";
import HEART_ICON from "../Icon/Heart.svg";
import LIKE_ICON from "../Icon/Like.svg";
import VIEW_ICON from "../Icon/view.svg";
import WOW_ICON from "../Icon/Wow.svg";
import { LogHeader } from "../../CommonItem/LogHeader";
import { PLATFORM_NAME } from "../../constants";
import Content from "../../../../../layout/content/Content";
import { Block } from "../../../../../components/Component";
const FacebookStaff = ({
  channels,
  range,
  staffs,
  setLoadingChannelFilter,
  setLoadingStaffFilter,
  setLoadingRange,
  from,
  to,
}) => {
  const { t } = useTranslation();
  const userInfor = getUserInfoInLocalStorage();
  const projectId = userInfor.last_project_active;

  const [logFbData, setLogFbData] = useState({});
  const [contentGeneralData, setContentGeneralData] = useState({});
  const [contentChartData, setContentChartData] = useState({});
  const [contentTopPostData, setContentTopPostData] = useState({});

  const [groupContent, setGroupContent] = useState([]);

  const [isLoadingContentGeneral, setIsLoadingContentGeneral] = useState(true);
  const [isLoadingContentChart, setIsLoadingContentChart] = useState(true);
  const [isLoadingContentTopPost, setIsLoadingContentTopPost] = useState(true);

  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [selectedOption, setSelectedOption] = useState({
    value: "post_impressions",
    label: "Impr",
  });
  const [sortType, setSortType] = useState({ value: "desc", label: "Desc." });
  const [tableLoading, setTableLoading] = useState(false);

  const [tooltipTableOpen, setTableToolTipOpen] = useState([
    { isOpen: false, label: "" },
    { isOpen: false, label: "Số lượt xem" },
    {
      isOpen: false,
      label:
        "Số người tương tác với bài viết: Được tính có thể bằng hành vi Click, Like, Bình luận, Share,....",
    },
    {
      isOpen: false,
      label: "Số lượt Click vào 1 vị trí bất kỳ trong bài viết",
    },
    { isOpen: false, label: "Số Bình luận" },
    { isOpen: false, label: "Số lượt Like" },
    { isOpen: false, label: "Số lượt Yêu thích" },
    { isOpen: false, label: "Số lượt Haha" },
  ]);
  const sortTikTokOptions = [
    { value: "post_impressions", label: "Impr" },
    { value: "post_engaged_users", label: "Engagement" },
    { value: "post_clicks", label: "Clicks" },
    { value: "post_reactions_like_total", label: "Like" },
    { value: "post_reactions_wow_total", label: "WoW" },
    { value: "number_comment", label: "Comment" },
    { value: "date", label: "Date" },
  ];
  const headerUserStatistic = {
    content: t("content"),
    post_impressions: t("channel_analytics_table.impression"),
    post_engaged_users: t("engagement"),
    post_clicks: t("AffClick"),
    number_comment: t("comment"),
    post_reactions_like_total: LIKE_ICON,
    post_reactions_love_total: HEART_ICON,
    post_reactions_wow_total: WOW_ICON,
  };

  const listIcon = [
    VIEW_ICON,
    CLICK_ICON,
    CHAT_ICON,
    LIKE_ICON,
    HEART_ICON,
    WOW_ICON,
  ];

  const listTooltip = [
    "Lượt xem video",
    "Số lượt Click vào 1 vị trí bất kỳ trong bài",
    "Số bình luận",
    "Số lượt Like",
    "Số lượt Yêu thích",
    "Số lượt WoW",
  ];

  const listValue = [
    "post_impressions",
    "post_clicks",
    "number_comment",
    "post_reactions_like_total",
    "post_reactions_love_total",
    "post_reactions_wow_total",
  ];
  const handleOpenPost = (e, id) => {
    e.stopPropagation();
    window.open(`https://www.facebook.com/${id}`, "_blank");
  };
  const getdataTable = async (data) => {
    if (!tableLoading) {
      setTableLoading(true);
      try {
        const dataRes = await getContentStatistic(data, "facebook");

        if (dataRes) {
          const dataFb = dataRes.data;
          setData(dataFb);
          setListData(
            dataFb.data.map((item) => ({
              content: item?.content,
              post_impressions: item?.post_impressions,
              post_engaged_users: item?.post_engaged_users,
              post_clicks: item?.post_clicks,
              post_negative_feedback: item?.post_negative_feedback,
              post_reactions_like_total: item?.post_reactions_like_total,
              post_reactions_love_total: item?.post_reactions_love_total,
              post_reactions_wow_total: item?.post_reactions_wow_total,
              media: item?.media,
              date: item?.date,
              channel: item?.channel,
              _post_id: item?.facebook_post_id,
              number_comment: item?.number_comment,
            }))
          );
          setTableLoading(false);
        }
      } catch (error) {
        console.log("errTableFB", error);
        setTableLoading(false);
      }
    }
  };
  const getAPIFacebook = (data) => {
    const { from, to, ...logFetchData } = data;
    setLoadingStaffFilter(true);
    setIsLoadingContentGeneral(true);
    setIsLoadingContentChart(true);
    setIsLoadingContentTopPost(true);
    Promise.allSettled([
      getLogFetchFb({ ...logFetchData }),
      getContentGeneral(data),
      getContentChart(data),
      getContentTopPost({ data: data, platform: "facebook" }),
    ]).then((responses) => {
      // Destructure the responses to get the data
      const [logFbRes, contentGeneralRes, contentChartRes, contentTopPostData] =
        responses;

      if (logFbRes.status === "fulfilled") {
        const logFb = logFbRes.value.data;
        setLogFbData(logFb);
      }
      if (contentGeneralRes.status === "fulfilled") {
        const contentGeneral = contentGeneralRes.value.data;
        setContentGeneralData(contentGeneral);
      }
      setIsLoadingContentGeneral(false);

      if (contentChartRes.status === "fulfilled") {
        const contentChart = contentChartRes.value.data;

        const dataChart = [
          {
            name: "",
            data: contentChart?.dataSet?.impression,
          },
        ];
        setContentChartData({ dataChart, labels: contentChart?.label });
      }
      setIsLoadingContentChart(false);

      if (contentTopPostData.status === "fulfilled") {
        const contentTopPost = contentTopPostData.value.data;

        const array = (contentTopPost?.top_photo || [])
          .concat(contentTopPost?.top_reel || [])
          .concat(contentTopPost?.top_video || [])
          .sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
          );

        setGroupContent(array);

        console.log("::::", array);
        setContentTopPostData(contentTopPost);
      }
      setIsLoadingContentTopPost(false);

      setLoadingChannelFilter(false);
      setLoadingStaffFilter(false);
      setLoadingRange(false);
    });
  };

  useEffect(() => {
    const data = {
      params: {
        project_id: projectId,
        channel_ids: channels,
        user_ids: staffs,
        from,
        to,
      },
    };
    getAPIFacebook(data);

    // if (currentPage === 1) {
    //   const data = {
    //     project_id: projectId,
    //     page: currentPage,
    //     user_ids: staffs,
    //     channel_ids: channels,
    //     order_by: selectedOption.value,
    //     arrange: sortType.value,
    //     from,
    //     to,
    //   };
    //   getdataTable(data);
    // } else {
    //   setCurrentPage(1);
    // }
  }, [range, from, to, channels, staffs]);

  useEffect(() => {
    const data = {
      params: {
        project_id: projectId,
        page: currentPage,
        user_ids: staffs,
        channel_ids: channels,
        order_by: selectedOption.value,
        arrange: sortType.value,
        from,
        to,
      },
    };
    getdataTable(data);
  }, [currentPage, from, to, selectedOption.value, sortType.value, from, to]);

  useEffect(() => {
    const data = {
      params: {
        project_id: projectId,
        page: currentPage,
        order_by: selectedOption.value,
        arrange: sortType.value,
        user_ids: staffs,
        channel_ids: channels,
        from,
        to,
      },
    };
    getdataTable(data);
  }, []);

  return (
    <>
      <Col lg={12} className="px-8">
        <LogHeader
          cnt_channel_error={logFbData?.cnt_channel_error}
          cnt_channel_success={logFbData?.cnt_channel_success}
          last_time={logFbData?.last_time}
          currentTab={PLATFORM_NAME.FACEBOOK}
        />
      </Col>
      <div
        style={{
          paddingTop: "14px",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <Row className="px-2">
          <GeneralItem
            label={t("views")}
            isLoading={isLoadingContentGeneral}
            data={contentGeneralData?.post_impressions ?? 0}
            classIcon="ri-eye-fill"
            backgroundColor="rgba(75, 56, 179, 0.18)"
            colorIcon="#4B38B3"
            tooltipLabel={t("general_overview.tooltip.content.facebook.views")}
            tooltipId={"about_views"}
          />

          <GeneralItem
            label={t("Click")}
            isLoading={isLoadingContentGeneral}
            data={contentGeneralData?.post_clicks ?? 0}
            classIcon="ri-cursor-fill"
            backgroundColor="rgba(75, 56, 179, 0.18)"
            colorIcon="#4B38B3"
            tooltipLabel={t("general_overview.tooltip.content.facebook.click")}
            tooltipId={"about_click"}
          />

          <GeneralItem
            label={t("comment")}
            isLoading={isLoadingContentGeneral}
            data={contentGeneralData?.number_comment ?? 0}
            classIcon="ri-message-2-line"
            backgroundColor="rgba(75, 56, 179, 0.18)"
            colorIcon="#4B38B3"
            tooltipLabel={t(
              "general_overview.tooltip.content.facebook.comment"
            )}
            tooltipId={"about_comment"}
          />

          <GeneralItem
            label={t("likes")}
            isLoading={isLoadingContentGeneral}
            data={contentGeneralData?.post_reactions_like_total ?? 0}
            classIcon="ri-thumb-up-fill"
            backgroundColor="rgba(41, 156, 219, 0.18)"
            colorIcon="#3577F1"
            tooltipLabel={t("general_overview.tooltip.content.facebook.likes")}
            tooltipId={"about_likes"}
          />

          <GeneralItem
            label={t("love")}
            isLoading={isLoadingContentGeneral}
            data={contentGeneralData?.post_reactions_love_total ?? 0}
            classIcon="ri-heart-3-fill"
            backgroundColor="rgba(240, 101, 72, 0.18)"
            colorIcon="#F06548"
            tooltipLabel={t("general_overview.tooltip.content.facebook.heart")}
            tooltipId={"about_heart"}
          />

          <GeneralItem
            label={t("haha")}
            isLoading={isLoadingContentGeneral}
            data={contentGeneralData?.post_reactions_wow_total ?? 0}
            classIcon="ri-emotion-line"
            backgroundColor="rgba(247, 184, 75, 0.18)"
            colorIcon="#F7B84B"
            tooltipLabel={t("general_overview.tooltip.content.facebook.wow")}
            tooltipId={"about_wow"}
          />
        </Row>

        <div style={{ paddingTop: "10px" }}>
          <ImpressionStatistic
            isLoading={isLoadingContentChart}
            dataChart={contentChartData.dataChart}
            labels={contentChartData.labels}
          ></ImpressionStatistic>
        </div>

        <div className="px-3 d-flex" style={{ paddingTop: "20px" }}>
          <Row>
            <TopPostColumn
              data={groupContent || []}
              isLoading={isLoadingContentTopPost}
            />
          </Row>
        </div>

        {/* <Block> */}
        <Col style={{ paddingTop: "10px" }}>
          <ContentStatisticTable
            listData={listData}
            sortTypeOptions={sortTikTokOptions}
            headerContentStatistic={headerUserStatistic}
            tooltipTableOpen={tooltipTableOpen}
            setTableToolTipOpen={setTableToolTipOpen}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            tableLoading={tableLoading}
            data={data}
            setCurrentPage={setCurrentPage}
            sortType={sortType}
            setSortType={setSortType}
            defaultChannelIcon={defaultChannelIcon}
            activeTab="facebook"
          />
        </Col>
        {/* </Block> */}
      </div>

      {/* <PreviewAltCard className="w-100"> */}
    </>
  );
};

export default FacebookStaff;
