import { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, Spinner } from "reactstrap";
import { Link, useHistory } from 'react-router-dom';

import style from "./index.module.scss";
import { Icon, Img } from "../../../components/Component";
import { getPlatformIcon } from "../../utils/Utils";
import NO_IMAGE from "../../images/news/top-post-empty.png";

import moment from "moment";
import { getNewsPostPerformance } from "../../../api/overview";
import FeaturedPostItem from "./FeaturePostItem";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { Trans } from "react-i18next";



const FeaturedPost = () => {

    const userInfor = JSON.parse(localStorage.getItem("userInfor"));
    const project = useSelector((state) => state.project.project);
    const history = useHistory();

    const [listPost, setListPost] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isDown, setIsDown] = useState(false);
    const [startX, setStartX] = useState(null);
    const [scrollLeft, setScrollLeft] = useState(null);


    const fetchListPost = async () => {
        setLoading(true);

        try {
            const data = {
                params: {
                    from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
                    to: moment().format('YYYY-MM-DD'),
                }
            }
            const res = await getNewsPostPerformance(userInfor?.last_project_active, data);
            if (res.status) {
                setListPost(res?.data);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }


    useEffect(() => {
        fetchListPost();
    }, []);

    //     const container = containerRef.current;

    //     const handleMouseDown = (e) => {
    //         isDown = true;
    //         startX = e.pageX - container.offsetLeft;
    //         scrollLeft = container.scrollLeft;
    //     };

    //     const handleMouseUp = () => {
    //         isDown = false;
    //     };

    //     const handleMouseMove = (e) => {
    //         if (!isDown) return;
    //         e.preventDefault();
    //         const x = e.pageX - container.offsetLeft;
    //         const walk = (x - startX) * 3; // Tốc độ cuộn (có thể thay đổi)
    //         container.scrollLeft = scrollLeft - walk;
    //     };

    //     container.addEventListener('mousedown', handleMouseDown);
    //     container.addEventListener('mouseup', handleMouseUp);
    //     container.addEventListener('mousemove', handleMouseMove);

    //     return () => {
    //         container.removeEventListener('mousedown', handleMouseDown);
    //         container.removeEventListener('mouseup', handleMouseUp);
    //         container.removeEventListener('mousemove', handleMouseMove);
    //     };
    // }, []);
    const renderAvatar = ({ image, platform }) => {
        return (
            <div className={`position-relative  ${style.postScheduleBlock__userAvatar}`} style={{ width: "32px", height: "32px" }}>
                <Img src={image ?? ""} className={`rounded`} height={"32px"}></Img>
                <Img src={getPlatformIcon(platform)} height={"12px"} className={`position-absolute`} style={{ bottom: "0px", right: "0px" }}></Img>
            </div>
        )
    }

    const renderListPost = () => {
        return (
            listPost?.length > 0 ?
                (listPost?.map((item) => (
                    <FeaturedPostItem item={item}></FeaturedPostItem>
                )))
                : (<>{renderEmpty()}</>)
        )
    }



    const renderEmpty = () => {
        return (
            <div className={`d-flex w-100 h-100  ${style.postEmptyBlock}`}>
                <div className={`${style.postEmptyBlock__skeleton}`}>
                    <Img src={NO_IMAGE} height={"186px"} width={"223px"}></Img>
                </div>

                <div className={`${style.postEmptyBlock__content}`}>
                    {project?.type === "business" ? (
                        <>
                            <p>{t("news.top_post.tilte_empty_content")}</p>
                            <hr></hr>

                            <span>{t("news.top_post.sub_empty_content_element_1")} <Link to="/admin/project/connect-channel" >{t("news.top_post.sub_empty_content_element_2")}</Link> {t("news.top_post.sub_empty_content_element_3")}.</span>
                        </>
                    ) : (
                        <><p>{t("news.top_post.tilte_project_not_business")}</p>
                            <hr></hr>
                            <span>{t("news.top_post.sub_project_not_business")}</span>
                        </>
                    )}

                </div>
            </div>
        )
    }



    const handleMouseDown = (e) => {
        setIsDown(true);
        setStartX(e.pageX);
        setScrollLeft(e.currentTarget.scrollLeft);
    };

    const handleMouseUp = () => {
        setIsDown(false);
    };

    const handleMouseMove = (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX;
        const walk = (x - startX) * 1; // Tốc độ cuộn (có thể thay đổi)
        e.currentTarget.scrollLeft = scrollLeft - walk;
    };


    return (
        <Fragment>
            <Card
                className={`${style.featuredPostBlock}`}
                style={{ flexGrow: "1", margin: 0, minHeight: "256px" }}
            >
                <div className={`d-flex justify-content-between align-items-center ${style.featuredPostBlock__header}`}>
                    <p className={` mb-0`}>{t('news.top_post.header')}</p>
                    <div onClick={() => { history.push("/home/dashboard/overview-enterprise/content") }} className="d-flex align-items-center cursor-pointer">
                        <Icon style={{ color: "#6F7782", fontSize: "12px" }} name="line-chart-up"></Icon>
                        <span className={`${style.postScheduleBlock__userName} fw-normal`} style={{ color: "#2F80ED", marginLeft: "8px" }}>{t('news.top_post.content_report')}</span>
                    </div>
                </div>



                {loading && (
                    <div className={`d-flex justify-content-center align-items-center w-100 h-100`}>
                        <Spinner></Spinner>
                    </div>
                )}
                <div onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                    className={`d-flex gap-2 w-100 h-100 ${style.scollbarCustom}`}
                    style={{ padding: "0 16px", overflowX: "auto" }}>
                    {!loading && renderListPost()}
                </div>




            </Card>
        </Fragment>
    )
}

export default FeaturedPost;