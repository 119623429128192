import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { VictoryTooltip } from "victory";
import {
  getEffectiveFacebook,
  getInforUserFacebook,
  getPostEngageFacebook,
  getReachFacebook,
  getSummaryAudienceYT,
  getSummaryPostAndEngagementYT,
  getSummaryTT,
  getSummaryVideoPerformanceYT,
} from "../../../../api/dashboard";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../../../components/Component";
import style from "../index.module.scss";
import OverviewEffect from "./Overview/Facebook/OverviewEffect";
import OverviewUser from "./Overview/Facebook/OverviewUser";
import PageReach from "./Overview/Facebook/PageReach";
import Post from "./Overview/Facebook/Post";
import YTOverviewEffect from "./Overview/Youtube/YTOverviewEffect";
import YTOverviewUser from "./Overview/Youtube/YTOverviewUser";
import YTPost from "./Overview/Youtube/YTPost";
import TTOverviewUser from "./Overview/TikTok/TTOverviewUser";
import TTPost from "./Overview/TikTok/TTPost";
import TTGoldTime from "./Overview/TikTok/TTGoldTime";
import _ from "lodash";

const Overview = (props) => {
  const { range, tab, channel, setLoading, setLoadingChannel, subTab, dashboardList, setDashboardList, updateTabDashboard, dashboardInit, isChangeFilter, setChangeFilter } = props;
  const userInfor = JSON.parse(localStorage.getItem('userInfor'));
  const projectId = userInfor.last_project_active;
  const [userData, setUserData] = useState({});
  const [postEngageData, setPostEngageData] = useState({});
  const [effectiveData, setEffectiveData] = useState({});
  const [reachData, setReachData] = useState({});

  const [audienceDataYT, setAudienceDataYT] = useState({});
  const [postEngageDataYT, setPostEngageDataYT] = useState({});
  const [videoPerDataYT, setVideoPerDataYT] = useState({});

  const [dataTiktok, setDataTiktok] = useState({});

  const getAPIFacebook = (data) => {
    Promise.all([
      getInforUserFacebook(data),
      getPostEngageFacebook(data),
      getEffectiveFacebook(data),
      getReachFacebook(data),
    ]).then((responses) => {
      // Destructure the responses to get the data
      const [userDataRes, postEngageDataRes, effectiveDataRes, reachDataRes] =
        responses;

      if (userDataRes.status) {
        const _userData = userDataRes.data;
        setUserData(_userData);
      }

      if (postEngageDataRes.status) {
        const _postEngageData = postEngageDataRes.data;
        setPostEngageData(_postEngageData);
      }

      if (effectiveDataRes.status) {
        const _effectiveData = effectiveDataRes.data;
        setEffectiveData(_effectiveData);
      }

      if (reachDataRes.status) {
        const _reachData = reachDataRes.data;
        setReachData(_reachData);
      }

      // Set loading to false after API calls are completed
      setLoadingChannel(false);
      setLoading(false);
    });
  };

  const getAPIYoutube = (data) => {
    Promise.all([
      getSummaryAudienceYT(data),
      getSummaryPostAndEngagementYT(data),
      getSummaryVideoPerformanceYT(data),
    ]).then((responses) => {
      // Destructure the responses to get the data
      const [audienceDataYTRes, postEngageDataYTRes, videoPerDataYTRes] =
        responses;

      if (audienceDataYTRes.status) {
        const _data = audienceDataYTRes.data;
        setAudienceDataYT(_data);
      }

      if (postEngageDataYTRes.status) {
        const _data = postEngageDataYTRes.data;
        setPostEngageDataYT(_data);
      }

      if (videoPerDataYTRes.status) {
        const _data = videoPerDataYTRes.data;
        setVideoPerDataYT(_data);
      }

      // Set loading to false after API calls are completed
      setLoadingChannel(false);
      setLoading(false);
    });
  };

  const getAPITiktok = (data) => {
    getSummaryTT(data).then((response) => {
      setDataTiktok(response.data);
      setLoadingChannel(false);
      setLoading(false);
    });
  };

  const handleGetDataStatistic = () => {
    const data = {
      params: {
        project_id: projectId,
        time: `${range} days`,
        channel_ids: channel,
      },
    };
    if (subTab === "1") {
      if (tab === "1") {
        getAPIFacebook(data);
      }
      if (tab === "3") {
        getAPIYoutube(data);
      }
      if (tab === "2") {
        getAPITiktok(data);
      }
    }
    setChangeFilter();
  }
  useEffect(() => {
    if (projectId && dashboardInit !== 0) {
      if (isChangeFilter) return;
      updateTabDashboard({
        platform: tab,
        subTab,
        channel,
        range
      });
      handleGetDataStatistic();
    }
  }, [channel, range]);

  useEffect(() => {
    if (projectId) {
      const subTabItem = dashboardList[tab].find(item => item.subTab === subTab);
      if (!subTabItem) { // nếu chưa có thì thêm vào redux và call api
        setDashboardList({
          platform: tab,
          subTab,
          channel,
          range
        });
        handleGetDataStatistic();
      } else if (!_.isEqual(subTabItem.channel, channel) || subTabItem.range !== range) {// nếu có item, check xem channel và range có thay đổi không, nếu thay đổi thì call api cập nhật channel và range
        updateTabDashboard({
          platform: tab,
          subTab,
          channel,
          range
        });
        handleGetDataStatistic();
      }
    }
  }, [tab, subTab]);

  return (
    <>
      {tab === "1" && (
        <>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className={`${style.tittle}`}>
                  Tổng quan người dùng
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <OverviewUser data={userData} />
          </Block>

          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className={`${style.tittle}`}>
                  Bài đăng & Tương tác
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <Post data={postEngageData} />
          </Block>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className={`${style.tittle}`}>
                  Tổng quan về hiệu quả
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <OverviewEffect data={effectiveData} />
          </Block>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className={`${style.tittle}`}>
                  Lượt tiếp cận Trang
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <PageReach data={reachData} />
          </Block>
        </>
      )}
      {tab === "3" && (
        <>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className={`${style.tittle}`}>
                  Tổng quan người dùng
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <YTOverviewUser data={audienceDataYT} />
          </Block>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className={`${style.tittle}`}>
                  Bài đăng & Tương tác
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <YTPost data={postEngageDataYT} />
          </Block>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className={`${style.tittle}`}>
                  Hiệu quả Video
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <YTOverviewEffect data={videoPerDataYT} />
          </Block>
        </>
      )}
      {tab === "2" && (
        <>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className={`${style.tittle}`}>
                  Tổng quan người dùng
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <TTOverviewUser data={dataTiktok} />
          </Block>

          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className={`${style.tittle}`}>
                  Hiệu quả Video
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <TTPost data={dataTiktok} />
          </Block>
          <section className="d-none d-md-block">
            <BlockHead size="sm">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle className={`${style.tittle}`}>
                    Khung giờ vàng
                  </BlockTitle>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>
            <Block>
              <TTGoldTime data={dataTiktok?.fan} />
            </Block>
          </section>
        </>
      )}
    </>
  );
};

export default Overview;
