import Facebook from "../../images/icons/Facebook.png";
import Youtube from "../../images/icons/youtube.png";
import tiktok from "../../images/icons/tiktok.png";
import zalo from "../../images/icons/zalo.png";
import instagramImage from "../../images/icons/instagram.png";
import pinterest from "../../images/icons/pinterest.png";
import threads from "../../images/icons/threads.svg";
import GuideS09 from "../../images/icons/GUIDESO9.png";
import style from "./index.module.scss";
import { Row, Col, Container, ModalFooter, Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import AuthFooter from "./AuthFooter";
import PageContainer from "../../layout/page-container/PageContainer";
import { accessNewUser } from "../../api/auth";
import { Modal, ModalBody } from "reactstrap";
import { URI } from "../../api/project";
import { loadListPageByFacebook } from "../../api/project";
import { pingServer } from "../../api/auth";
import { activeChannel } from "../../api/channel";
import { ToastContainer } from "react-toastify";
import { Block, Button } from "../../components/Component";
import useQuery from "../../hooks/useQuery";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toastBasic } from "../../libs/toasts";
import { IconArrowContinue } from "../../utils/icons";
import { useFirstRender } from "../../hooks/useFirstTimeRender";
import { useSelector } from "react-redux";
import ModalConnectPageChannel from "../../So9/components/Modal/ModalConnectedItem";
import { useDispatch } from "react-redux";
import { setShowGiftTrial } from "../../reducers/projectSlice";
import { getDomainApp } from "../../utils/Utils";

const ModalErrorConnectChannel = React.lazy(() =>
  import("../../So9/components/Modal/ModalErrorConnectChannel")
);
const ModalConnectYoutube = React.lazy(() =>
  import("../../So9/components/Modal/ModalConnectYoutube")
);

export default function ConnectPage({ match }) {
  const { t } = useTranslation();
  useFirstRender();

  const dispatch = useDispatch();
  const auth = localStorage.getItem("accessToken");
  const [isOpen, setisOpen] = useState(false);
  const [youtubeClick, setYoutubeClick] = useState(false);
  const [typeFacebookPlatform, setTypeFacebookplatform] = useState("");
  const [openModalListChannel, setOpenModalListChannel] = useState(false);
  const [listChannel, setListChannel] = useState([]);
  const [listChannelLoading, setListChannelLoading] = useState(false);
  const [listChannelSelected, setListChannelSelected] = useState([]);
  const query = useQuery();
  const typeOnboard = query.get("page_callback");
  const logged = query.get("logged");
  const currentPlatform = query.get("current_platform");
  const typeOfPlatform = query.get("type");
  const userLogin = JSON.parse(localStorage.getItem("userInfor"));
  const projectId = userLogin.last_project_active;
  const user_id = userLogin.id;
  const [loading, setLoading] = useState(false);
  const [disable, setIsDisable] = useState(false);
  const [isOpenModalGuideS09, setOpenModalGuideS09] = useState(false);
  const project = useSelector((state) => state.project.project);
  const channelOwned = useSelector(
    (state) => state.channel.channelsInformation
  );

  const platforms = [
    {
      id: "facebook",
      name: "Facebook",
      content: t("connectpage.platforms_Face"),
      image: Facebook,
    },
    {
      id: "tiktok",
      name: "Tiktok",
      content: t("connectpage.platforms_TikTok"),
      image: tiktok,
    },
    {
      id: "zalo",
      name: "Zalo",
      content: t("connectpage.platforms_Zalo"),
      image: zalo,
    },
    {
      id: "youtube",
      name: "Youtube",
      content: t("connectpage.platforms_Youtube"),
      image: Youtube,
    },
    {
      id: "instagram",
      name: "Instagram",
      content: t("connectpage.platforms_Ins"),
      image: instagramImage,
    },
    {
      id: "pinterest",
      name: "Pinterest",
      content: t("connectpage.platforms_Pinterest"),
      image: pinterest,
    },
    {
      id: "threads",
      name: "Threads",
      content: t("connectpage.platforms_Threads"),
      image: threads,
    },
  ];
  let title = "";
  let CommentsTitle = "";
  const pageCallback = match.params.id;
  switch (pageCallback) {
    case "publishing":
      title = t("connectpage.platforms_Pub_name");
      CommentsTitle = t("connectpage.platforms_Pub_content");
      break;
    case "analytics":
      title = t("connectpage.platforms_Ana_name");
      CommentsTitle = t("connectpage.platforms_Ana_content");
      break;
    case "engagement":
      title = t("connectpage.platforms_Eng_name");
      CommentsTitle = t("connectpage.platforms_Eng_content");
      break;
    case "customer360":
      title = t("connectpage.platforms_Cus_name");
      CommentsTitle = t("connectpage.platforms_Cus_content");
      break;
    case "connect_new":
      title = t("connectpage.connect_new_name");
      CommentsTitle = t("connectpage.connect_new_content");
      break;
    default:
  }
  const onSelectedPlatform = (platform) => {
    let url = "";
    switch (platform) {
      case "facebook":
        setisOpen(true);
        break;
      case "zalo":
        url =
          process.env.REACT_APP_ZALO_API +
          "api/v1" +
          URI.LOGIN_ZALO +
          "?project_id=" +
          projectId;
        window.location.replace(url);
        break;
      case "instagram":
        url =
          process.env.REACT_APP_URL_API +
          "api/v1" +
          URI.LOGIN_FACEBOOK +
          "?project_id=" +
          projectId +
          "&type=instagram&page_callback=connect_new" +
          "&domain=" +
          getDomainApp();
        window.location.replace(url);
        break;
      case "pinterest":
        url =
          `https://pinterest.so9.vn` +
          URI.LOGIN_PINTEREST +
          "?project_id=" +
          projectId +
          `&user_id=${user_id}&domain=${getDomainApp()}&page_callback=${getDomainApp()}/admin/project/connect-channel`;
        window.location.replace(url);
        break;
      case "tiktok":
        url = `https://tiktok.so9.vn/api/v1/tiktok/oauth?project_id=${projectId}&user_id=${user_id}&domain=${getDomainApp()}&page_callback=${getDomainApp()}/admin/project/connect-channel`;
        window.location.replace(url);
        break;
      case "youtube":
        setYoutubeClick(true);
        break;
      case "threads":
        url =
          process.env.REACT_APP_URL_API +
          "api/v1/threads-connector/login?project_id=" +
          projectId +
          "&domain=" +
          getDomainApp();
        window.location.replace(url);
        break;
      default:
    }
  };
  const connectFb = () => {
    setLoading(true);
    if (!typeFacebookPlatform) {
      toastBasic("Vui lòng chọn loại kênh kết nối");
      return;
    }
    const url =
      process.env.REACT_APP_URL_API + "api/v1" + URI.LOGIN_FACEBOOK + "?";
    const params =
      "project_id=" +
      userLogin.last_project_active +
      "&type=" +
      typeFacebookPlatform +
      "&page_callback=" +
      pageCallback +
      "&domain=" +
      getDomainApp();
    window.location.replace(url + params);
  };

  const actionActivingGroup = () => {
    if (
      parseInt(project.connection_limit) <
      parseInt(listChannelSelected.length) + parseInt(channelOwned.length)
    ) {
      toastBasic(
        "Đã đạt đến giới hạn kết nối. Vui lòng upgrade gói cước để tiếp tục kết nối"
      );
      setIsDisable(false);
      return;
    }
    setOpenModalGuideS09(true);
  };
  const actionActivingPage = async () => {
    setLoading(true);
    console.log("page", loading);
    let channels = listChannel.filter((item) => {
      return listChannelSelected.indexOf(item.id) >= 0;
    });
    try {
      const active = await activeChannel(userLogin.last_project_active, {
        channels,
        typeOfPlatform,
      });
      if (active.status) {
        setLoading(false);
        let url;
        switch (typeOnboard) {
          case "publishing":
            url = process.env.PUBLIC_URL + "/posts/create";
            break;
          case "analytics":
            url = "/";
            break;
          case "engagement":
            url = process.env.PUBLIC_URL + "/engagement";
            break;
          case "customer360":
            url = process.env.PUBLIC_URL + "/engagement";
            break;
          case "connect_new":
            url = process.env.PUBLIC_URL + "/admin/project/connect-channel";
            break;
          default:
        }
        window.location.replace(url);
      } else {
        setLoading(false);
        toastBasic();
        setIsDisable(false);
      }
    } catch (e) {
      setLoading(false);
      setIsDisable(false);
      setListChannelSelected([]);
      const checkDome = document.querySelectorAll(".checkbox-channel");
      if (checkDome) {
        checkDome.forEach((item) => {
          item.checked = false;
        });
      }
    }
  };

  const handleConnectChannelSelected = async () => {
    setLoading(true);
    if (!disable) {
      if (listChannelSelected.length === 0) {
        toastBasic("Vui lòng chọn kênh muốn kết nối");
        setLoading(false);
        setTimeout(() => {
          setIsDisable(false);
        }, 1500);
        return;
      }

      // Them cac kenh
      const updateNewUser = await accessNewUser();
      if (!updateNewUser.status) {
        setLoading(false);
        toastBasic("Đã có lỗi xảy ra. Vui lòng thử lại sau ít phút");
        setIsDisable(false);
      }

      // cập nhật is_new_user vào userInfor
      let newUserInfor = JSON.parse(localStorage.getItem("userInfor"));
      newUserInfor.is_new_user = 0;
      localStorage.setItem("userInfor", JSON.stringify(newUserInfor));

      if (typeOfPlatform === "group") {
        actionActivingGroup();
      } else {
        actionActivingPage();
      }
    }
  };
  let toggle = () => {
    setisOpen(!isOpen);
  };

  const toggleModalListChannel = () => {
    setOpenModalListChannel(!openModalListChannel);
  };

  const handleSelectPage = (pageId) => {
    const arrAllChannelIdOwn = channelOwned.map((channel) => channel._id);
    const arrChannelIdNotConnect = listChannelSelected.filter((channel) =>
      arrAllChannelIdOwn.includes(channel)
    );
    if (
      parseInt(project.connection_limit) <= parseInt(arrChannelIdNotConnect)
    ) {
      toastBasic(
        "Đã đạt đến giới hạn kết nối. Vui lòng upgrade gói cước để tiếp tục kết nối"
      );
      return;
    }
    const newList = listChannelSelected;
    if (newList.indexOf(pageId) >= 0) {
      newList.splice(newList.indexOf(pageId), 1);
    } else {
      newList.push(pageId);
    }
    setListChannelSelected(newList);
  };

  const connectFbGroup = () => {
    let url = "https://www.facebook.com/groups/feed/";
    window.open(url, "_blank");
  };
  const handleContinueActiveGroup = async () => {
    setLoading(true);
    let channels = listChannel.filter((item) => {
      return listChannelSelected.indexOf(item.id) >= 0;
    });
    try {
      const active = await activeChannel(userLogin.last_project_active, {
        channels,
        typeOfPlatform,
      });
      if (active.status) {
        setLoading(false);
        window.open("/", "_self");
      }
    } catch (e) {
      setLoading(false);
      setListChannelSelected([]);
      const checkDome = document.querySelectorAll(".checkbox-channel");
      if (checkDome) {
        checkDome.forEach((item) => {
          item.checked = false;
        });
      }
    }
  };
  useEffect(() => {
    if (!auth) {
      window.location.replace("/auth-login");
    }
    if (
      userLogin.is_new_user === 1 &&
      !logged &&
      !typeOfPlatform &&
      !typeOnboard
    ) {
      dispatch(setShowGiftTrial(true));
    }
    if (logged === "1") {
      setOpenModalListChannel(true);
      if (currentPlatform === "facebook") {
        setTypeFacebookplatform(typeOfPlatform);
        const params = {
          typeOfPlatform: typeOfPlatform,
        };
        setListChannelLoading(true);
        loadListPageByFacebook(userLogin.last_project_active, params)
          .then((res) => {
            if (res.status && res.data) {
              setListChannelLoading(false);
              if (typeOfPlatform !== "instagram") {
                setListChannel(res.data);
              } else {
                const existInstagramChannel = res.data.filter((item) => {
                  return (
                    typeof item.instagram_business_account !== "undefined" &&
                    item.instagram_business_account
                  );
                });
                if (existInstagramChannel.length > 0) {
                  const instaChannel = existInstagramChannel.map((item) => {
                    return {
                      id: item.instagram_business_account.id,
                      name: item.instagram_business_account.name,
                      picture: {
                        data: {
                          url: item.instagram_business_account
                            .profile_picture_url,
                        },
                      },
                    };
                  });
                  setListChannel(instaChannel);
                }
              }
            } else {
              setListChannelLoading(false);
            }
          })
          .catch((error) => {
            setListChannelLoading(false);
            console.log(error);
          });
      }
    }
  }, [logged]);

  onclose = () => {
    window.history.back();
  };

  const renderPlatform = (typeFacebookPlatform) => {
    switch (typeFacebookPlatform) {
      case "page":
        return <p className={style.Modalp}>Facebook Page</p>;
      case "group":
        return <p className={style.Modalp}>"Facebook Group</p>;
      case "instagram":
        return <p className={style.Modalp}>Instagram</p>;
      default:
        break;
    }
  };
  return (
    <>
      <PageContainer>
        <div style={{ display: (pageCallback === "connect_new") ? "block" : "none" }}>
          <div className="d-flex justify-content-end" >
            <Link to="/admin/project/connect-channel" className={`${style.buttonClose} text-white`} >{t('connectpage.close')}</Link>
          </div>
        </div>
        <Block className="nk-block-middle">
          <h4 className={style.TextTille}>{title}</h4>
          <h4 className={style.TextTille2}>{CommentsTitle}</h4>
          {
            <Container fluid>
              <Row>
                {platforms.map((item, index) => {
                  // if (item.id === "youtube" && projectId !== "628c7e9ca05a926caa09caa5") {
                  //   return <div key={index}></div>
                  // }
                  return (
                    <Col md="4" key={index}>
                      <div
                        className={`${style.cardPage} `}
                        onClick={() => onSelectedPlatform(item.id)}
                        role="button"
                        key={index}
                      >
                        <div className=" d-flex justify-content-center mt-5">
                          <img
                            className={style.modalSelectPlatformItemIcon}
                            src={item.image}
                            alt={item.name}
                          />
                        </div>
                        <h6 className={style.textCenter}>{item.name}</h6>
                        <p className={style.modalSelectPlatformItemName}>
                          {item.content}
                        </p>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          }
        </Block>
        <AuthFooter />
      </PageContainer>
      <ToastContainer />
      <Modal isOpen={isOpen} toggle={toggle} size={"lg"}>
        <ModalBody>
          <div className={`nk-modal`}>
            <h4 className="nk-modal-title">
              {t("connectpage.modal_face_title")}
            </h4>
            <Row style={{ backgroundColor: "#F5F5F5", padding: "24px" }}>
              <p className={style.ModalText}>
                {t("connectpage.modal_face_suggest")}
              </p>

              <div
                className={`${style.ModalInput} `}
                onClick={() => setTypeFacebookplatform("business")}
              >
                <div>
                  <input
                    type="radio"
                    className="custom-control custom-radio"
                    checked={typeFacebookPlatform === "business"}
                    onChange={() => setTypeFacebookplatform("business")}
                  />
                </div>
                <div >
                  <label className={style.ModalLabel}>{t('connectpage.modal_face_page_business_title')}</label>
                  <p className={style.Modalp}>{t('connectpage.modal_face_page_business_desc')}</p>
                </div>
              </div>

              <div
                className={`${style.ModalInput} mt-3`}
                onClick={() => setTypeFacebookplatform("page")}
              >
                <div>
                  <input
                    type="radio"
                    className="custom-control custom-radio"
                    checked={typeFacebookPlatform === "page"}
                    onChange={() => setTypeFacebookplatform("page")}
                  />
                </div>
                <div >
                  <label className={style.ModalLabel}>{t('connectpage.modal_face_page_directly_title')}</label>
                  <p className={style.Modalp} style={{ fontSize: '12px' }}>{t('connectpage.modal_face_page_directly_desc')}</p>
                </div>
              </div>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" onClick={() => setisOpen(false)}>
            {t("connectpage.modal_button_cancel")}
          </Button>
          <Button color="primary" type="button" onClick={() => connectFb()}>
            {loading ? (
              <>
                <Spinner size="sm" />
                <span> Loading... </span>
              </>
            ) : (
              t("connectpage.modal_button_connect")
            )}
          </Button>
        </ModalFooter>
      </Modal>
      {/* channels  */}
      <ModalConnectPageChannel
        openModalListChannel={openModalListChannel}
        toggleModalListChannel={toggleModalListChannel}
        listChannelLoading={listChannelLoading}
        renderPlatform={renderPlatform}
        handleSelectPage={handleSelectPage}
        setOpenModalListChannel={setOpenModalListChannel}
        loading={loading}
        setLoading={setLoading}
        setIsDisable={setIsDisable}
        handleConnectChannelSelected={handleConnectChannelSelected}
        listChannel={listChannel}
        typeFacebookPlatform={typeFacebookPlatform}
        project={project}
        disable={disable}
      />
      {/* Guide */}
      <Modal
        isOpen={isOpenModalGuideS09}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <div className={`nk-modal`}>
            <h4 className="nk-modal-title">
              {t("connectpage.modal_faceGroupGuide_title")}
            </h4>
            <Row
              style={{
                backgroundColor: "#F5F5F5",
                paddingTop: "24px",
                paddingLeft: "24px",
                paddingRight: "24px",
              }}
            >
              <p className={style.modalGuide}>
                {t("connectpage.modal_Guide_content")}
              </p>
              <p className={style.modalGuide}>
                {t("connectpage.modal_Guide_Link1")}{" "}
                <a href="https://so9.vn/bai-viet/cach-ket-noi-den-hoi-nhom-facebook-tren-so9">
                  <u style={{ color: "#2F80ED", fontWeight: 500 }}>
                    {t("connectpage.modal_Guide_Link2")}{" "}
                  </u>
                </a>
                {t("connectpage.modal_Guide_Link3")}
              </p>
              <div
                className="d-flex"
                style={{ width: "100%", justifyContent: "center" }}
              >
                <div>
                  <img
                    className={style.modalSelectGuideItemIcon}
                    src={GuideS09}
                    alt="GuideS09"
                  />
                </div>
              </div>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter style={{ width: "100%" }}>
          <div style={{ flex: 1 }}>
            <span
              style={{
                fontSize: "18px",
                lineHeight: "24px",
                textDecoration: "underline",
                color: "#828282",
                fontWeight: "500",
                cursor: "pointer",
              }}
              onClick={() => connectFbGroup()}
            >
              Đến trang quản trị nhóm
            </span>
          </div>
          <div
            style={{ flex: 1, cursor: "pointer" }}
            className={`d-flex gap-4`}
          >
            <div
              className={`d-flex`}
              style={{ justifyContent: "end", width: "100%" }}
              onClick={() => setOpenModalGuideS09(false)}
            >
              <div style={{ transform: "rotate(180deg)" }}>
                <IconArrowContinue />
              </div>
              <div
                style={{
                  color: "#2F80ED",
                  fontSize: "18px",
                  lineHeight: "24px",
                  fontWeight: 800,
                  marginLeft: "4px",
                }}
              >
                Quay lại
              </div>
            </div>
            {loading ? (
              <div
                className={`d-flex`}
                style={{ justifyContent: "end", width: "100%" }}
              >
                <Spinner size="md" />
              </div>
            ) : (
              <div
                className={`d-flex`}
                style={{ justifyContent: "end", width: "100%" }}
                onClick={handleContinueActiveGroup}
              >
                <div
                  style={{
                    color: "#2F80ED",
                    fontSize: "18px",
                    lineHeight: "24px",
                    fontWeight: 800,
                    marginRight: "4px",
                  }}
                >
                  Tiếp tục
                </div>
                <div>
                  <IconArrowContinue />
                </div>
              </div>
            )}
          </div>
        </ModalFooter>
      </Modal>

      <ModalErrorConnectChannel />
      <ModalConnectYoutube
        youtubeClick={youtubeClick}
        setYoutubeClick={() => setYoutubeClick(false)}
      />
    </>
  );
}
