import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Tooltip,
  UncontrolledDropdown,
} from "reactstrap";

import UPLOAD_ICON from "../../../images/post/upload.svg";

import BOT_ICON from "../../../images/post/gpt.svg";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import HashTags from "./HashTag";
import MentionComponent from "./MentionComponent";
import AiModal from "./AIModal";
import EditAltModal from "./EditAltModal";
import EditVideoTitle from "./EditVideoTitleModal";
import EditVideoThumb from "./EditVideoThumb";
import StatusList from "./StatusList";
import { fetchUserInfoLogin } from "../../../../libs/helper";
import { Img } from "../../../../components/Component";
import { getListHashTag } from "../../../../api/postNew";
import { RiImage2Fill, RiMovieLine, RiPriceTag3Line } from "@remixicon/react";
import SpinContent from "./SpinContent";
import SPIN_ICON from "../../../images/post/spin-content.svg";
import STATUS_ICON from "../../../images/post/status/status-icon.png";
import styles from "./content.module.scss";
import { RiCloseLine, RiPencilFill } from "@remixicon/react";
import THUMBNAIL_ERROR from "../../../images/thumbnail_error.png";

const ContentInput = ({
  onOpenModalCustomThumbnail,
  toggleLabel,
  value,
  onValueChange,
  selectedHashTag,
  setSelectedHashTag,
  setSelectedVideo,
  selectedVideo,
  selectedImages,
  setSelectedImages,
  isPinterest,
  pinData,
  setPinData,
  isTiktok,
  isVideo,
  selectedPlatforms, // [FACEBOOK, TIKKTOK, ...]
  selectedStatus,
  setSelectedStatus,
  isDisabledStatus,
  setIsDisabledStatus,
}) => {
  const { t } = useTranslation();
  const userInfor = fetchUserInfoLogin();
  const [listHashTag, setListHashTag] = useState([]);
  const [isOpenAi, setIsOpenAi] = useState(false);
  const [isOpenSpin, setIsOpenSpin] = useState(false);
  const [isOpenStatus, setIsOpenStatus] = useState(false);
  const [tooltipStatusOpen, setTooltipStatusOpen] = useState(false);

  const [refreshPreview, setRefreshPreview] = useState(true);

  const fetchListHashTag = async () => {
    try {
      const res = await getListHashTag(userInfor.last_project_active);
      if (res.status) {
        setListHashTag(res.data);
      } else {
        console.log(res.data, "lỗi hashtag");
      }
    } catch (error) {
      console.log(error, "lỗi hashtag");
    }
  };

  useEffect(() => {
    fetchListHashTag();
  }, []);

  const handleDropdownItemClick = () => {
    if (isVideo) {
      setRefreshPreview(false);
      setTimeout(() => {
        setSelectedImages([]);
        setRefreshPreview(true);
        const fileInput = document.getElementById("file-input");
        if (fileInput) {
          fileInput.click();
        }
      }, 500);
    } else {
      const fileInput = document.getElementById("file-input");
      if (fileInput) {
        fileInput.click();
      }
    }
  };

  const handleUploadVideoClick = () => {
    const fileInput = document.getElementById("video-input");
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleSelectAiContent = (value) => {
    onValueChange(value);
  };

  const toggleStatusFacebook = () => {
    setTooltipStatusOpen(!tooltipStatusOpen);
  };

  useEffect(() => {
    if (isDisabledStatus) {
      setIsOpenStatus(false);
    }
  }, [isDisabledStatus]);

  useEffect(() => {
    if (
      value.length > 130 ||
      selectedImages.length > 0 ||
      isVideo ||
      !selectedPlatforms.includes(1)
    ) {
      setIsDisabledStatus(true);
    } else {
      setIsDisabledStatus(false);
    }
  }, [value, selectedImages, isVideo, selectedPlatforms]);

  const isValidUrl = (urlString) => {
    var urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  };

  return (
    <div className="rounded color-border">
      <MentionComponent
        onMentionChange={onValueChange}
        mentionValue={value}
        selectedPlatforms={selectedPlatforms}
        className="rounded-top"
        style={{ resize: "none", height: "15rem", border: "none" }}
      />

      {isPinterest && (
        <div className="d-flex align-items-center w-100 gap-3 p-2 color-border border-0 border-t">
          <span className="fs-13 text-muted">
            {t("store_post.setting.pinterest.link")}
          </span>
          <div className="rounded bg-[#F06548] w-50">
            <Input
              className="border-0 hover:border-0 focus:border-0 bg-[#F3F6F9] px-2"
              style={{
                marginLeft: !isValidUrl(pinData.url) ? "4px" : "0px",
              }}
              value={pinData.url}
              onChange={(e) => setPinData({ ...pinData, url: e.target.value })}
              type="text"
              placeholder="e.g: https://www.example.com/xyz"
            />
          </div>
        </div>
      )}

      <div className="rounded-bottom p-2 color-border border-0 border-t">
        <div className="d-flex justify-content-between align-items-center gap-4">
          <div className="d-flex gap-2">
            <UncontrolledDropdown>
              <DropdownToggle className={styles.btnCustom}>
                <div
                  style={{
                    padding: "8px",
                    backgroundColor: "rgba(59, 79, 149, 0.09)",
                  }}
                  className="d-flex align-items-center justify-content-center rounded cursor-pointer "
                >
                  <Img src={UPLOAD_ICON} height="20px"></Img>
                </div>
              </DropdownToggle>

              <DropdownMenu>
                <DropdownItem>
                  <div
                    className="d-flex align-items-center gap-2"
                    onClick={handleDropdownItemClick}
                  >
                    <RiImage2Fill
                      size={16}
                      className="text-info "
                    ></RiImage2Fill>
                    <p className="mb-0 fs-13" style={{ color: "#495057" }}>
                      {t("store_post.upload.image")}
                    </p>
                  </div>
                </DropdownItem>
                <DropdownItem>
                  <div
                    className="d-flex align-items-center gap-2"
                    onClick={handleUploadVideoClick}
                  >
                    <RiMovieLine
                      size={16}
                      className="text-danger"
                    ></RiMovieLine>
                    <p className="mb-0 fs-13" style={{ color: "#495057" }}>
                      {t("store_post.upload.video.title")}
                    </p>
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

            <div
              style={{
                padding: "8px",
                backgroundColor: "rgba(53, 119,241, 0.09)",
              }}
              onClick={() => setIsOpenAi(!isOpenAi)}
              className="d-flex align-items-center justify-content-center rounded cursor-pointer "
            >
              <Img src={BOT_ICON} height="20px"></Img>
            </div>

            <div
              style={{
                padding: "8px",
                backgroundColor: "rgba(75, 56,179, 0.09)",
              }}
              onClick={() => setIsOpenSpin(!isOpenSpin)}
              className="d-flex align-items-center justify-content-center rounded cursor-pointer "
            >
              <Img src={SPIN_ICON} height="20px"></Img>
              <p className="ms-2 text-norrmal mb-0 text-body text-nowrap">
                {t("store_post.spin_content.title")}
              </p>
            </div>

            <SpinContent
              isOpen={isOpenSpin}
              setOpen={setIsOpenSpin}
              content={value}
              setContent={onValueChange}
            />
          </div>
          <div className="d-flex gap-2">
            {/**
               * <HashTags
              listHashTag={listHashTag}
              setListHashTag={setListHashTag}
              onMentionChange={onValueChange}
              mentionValue={value}
              selectedHashTag={selectedHashTag}
              setSelectedHashTag={setSelectedHashTag}
            />
               */}

            <StatusList
              isOpenStatus={isOpenStatus}
              isDisabledStatus={isDisabledStatus}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
            />

            <div
              id="warningStatusFacebookFeed"
              style={{
                backgroundColor: "rgba(75, 56,179, 0.09)",
                opacity: !isDisabledStatus ? "100%" : "60%",
                cursor: !isDisabledStatus ? "pointer" : "not-allowed",
              }}
              onClick={() =>
                !isDisabledStatus && setIsOpenStatus(!isOpenStatus)
              }
              className="d-flex align-items-center justify-content-center rounded cursor-pointer "
            >
              <Img src={STATUS_ICON} height="36px"></Img>
            </div>

            <Tooltip
              placement="top"
              isOpen={tooltipStatusOpen}
              target={`warningStatusFacebookFeed`}
              toggle={toggleStatusFacebook}
              style={{ width: "100%", textAlign: "start", fontSize: "11px" }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t("store_post.status.tooltip"),
                }}
              ></div>
            </Tooltip>

            <div
              onClick={toggleLabel}
              style={{
                padding: "8px",
                height: "36px",
                backgroundColor: "rgb(243, 246, 249)",
              }}
              className="d-flex align-items-center text-danger justify-content-center rounded cursor-pointer "
            >
              <RiPriceTag3Line size={16}></RiPriceTag3Line>

              <p className="ms-1 mb-0 fw-medium text-primary">
                {t("store_post.label.title")}
              </p>
            </div>
          </div>
        </div>

        {refreshPreview && (
          <ImagePreview
            selectedImages={selectedImages}
            setSelectedVideo={setSelectedVideo}
            setSelectedImages={setSelectedImages}
            selectedVideo={selectedVideo}
            isTiktok={isTiktok}
            isVideo={isVideo}
            selectedPlatforms={selectedPlatforms}
            onOpenModalCustomThumbnail={onOpenModalCustomThumbnail}
          />
        )}
        <AiModal
          show={isOpenAi}
          toggle={setIsOpenAi}
          handleSelectAiContent={handleSelectAiContent}
        />
      </div>
    </div>
  );
};

const ImagePreview = ({
  selectedImages,
  setSelectedVideo,
  setSelectedImages,
  selectedVideo,
  onOpenModalCustomThumbnail,
}) => {
  const [previewImages, setPreviewImages] = useState([]);
  const [isVideo, setIsVideo] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const fileInputRef = useRef(null);
  const fileVideoRef = useRef(null);

  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files || []);
    setSelectedImages((prev) => [...prev, ...selectedFiles]);
    setIsVideo(false);
    if (fileVideoRef.current) {
      setTimeout(() => {
        fileInputRef.current.value = null;
      }, 500);
    }
  };
  const handleVideoChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const dataURL = event.target?.result;
        const video = document.createElement("video");
        video.crossOrigin = "anonymous";
        video.preload = "metadata";
        video.onloadedmetadata = () => {
          const canvas = document.createElement("canvas");
          const timeInSeconds = 0; // Thời điểm tùy chỉnh (số giây)
          const seekTime = Math.min(timeInSeconds, video.duration);
          video.currentTime = seekTime;

          video.onseeked = () => {
            const width = video.videoWidth;
            const height = video.videoHeight;
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx?.drawImage(video, 0, 0, width, height);
            const thumbnailDataURL = canvas.toDataURL();
            setSelectedVideo([
              {
                data: selectedFile,
                thumb: thumbnailDataURL,
                caption: selectedFile.name,
                time: 0,
                name: selectedFile.name,
                size: selectedFile.size,
                type: selectedFile.type,
              },
            ]);
            setIsVideo(true);
          };
        };
        video.onerror = (error) => {
          setSelectedVideo([
            {
              data: selectedFile,
              thumb: THUMBNAIL_ERROR,
              caption: selectedFile.name,
              time: 0,
              name: selectedFile.name,
              size: selectedFile.size,
              type: selectedFile.type,
            },
          ]);
          setIsVideo(true);
        };
        video.src = dataURL;
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
      reader.readAsDataURL(selectedFile);
    }
    if (fileVideoRef.current) {
      setTimeout(() => {
        fileVideoRef.current.value = null;
      }, 500);
    }
  };

  const handleImageRemove = (index) => {
    setSelectedImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
    setPreviewImages((prevImages) => {
      setSelectedVideo([]);
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };

  return (
    <div
      className={`d-flex flex-wrap gap-3 ${
        ((selectedImages?.length > 0 && !isVideo) ||
          (selectedVideo?.length > 0 && isVideo)) &&
        "mt-3"
      }`}
    >
      <input
        type="file"
        ref={fileInputRef}
        accept="image/*"
        id="file-input"
        multiple
        onChange={handleImageChange}
        style={{ display: "none" }}
      />
      <input
        type="file"
        ref={fileVideoRef}
        accept="video/mp4,.mov"
        id="video-input"
        multiple={false}
        onChange={handleVideoChange}
        style={{ display: "none" }}
      />
      {selectedImages &&
        !isVideo &&
        selectedImages?.map((previewImage, index) => (
          <div
            className="position-relative rounded overflow-hidden bg-light d-flex align-items-center justify-content-center"
            key={previewImage + index}
          >
            <img
              src={`${URL.createObjectURL(previewImage)}`}
              alt={previewImage?.caption}
              style={{
                maxWidth: "90px",
                maxHeight: "90px",
                minHeight: "90px",
                minWidth: "90px",
              }}
              className="rounded"
            />
            <RiCloseLine
              size={12}
              className="d-flex align-items-center justify-content-center cursor-pointer rounded-circle bg-dark text-white position-absolute "
              style={{
                top: "4px",
                right: "4px",
                width: "14px",
                height: "14px",
              }}
              onClick={() => handleImageRemove(index)}
            ></RiCloseLine>
            <div
              className="position-absolute  bottom-0 w-100 px-1"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
            >
              <p className=" mb-0 text-white text-center  text-truncate">
                {previewImage?.caption}
              </p>
            </div>
            <div
              style={{
                bottom: "4px",
                right: "4px",
                width: "16px",
                height: "16px",
              }}
              onClick={() => setIsShow(true)}
              className="d-flex align-items-center justify-content-center cursor-pointer rounded-circle bg-white position-absolute"
            >
              <RiPencilFill className="text-info" size={10}></RiPencilFill>
            </div>
          </div>
        ))}
      <EditAltModal
        images={selectedImages}
        updateImages={setSelectedImages}
        isShow={isShow}
        onClose={() => setIsShow(false)}
      />
      {isVideo && selectedVideo.length > 0 && (
        <div className="d-flex justify-content-between align-items-center w-100 gap-3">
          <div className="position-relative rounded overflow-hidden bg-light d-flex align-items-center justify-content-center">
            <EditVideoTitle
              setVideo={setSelectedVideo}
              video={selectedVideo}
            ></EditVideoTitle>
          </div>

          <EditVideoThumb
            onOpenModalCustomThumbnail={onOpenModalCustomThumbnail}
          />
        </div>
      )}
    </div>
  );
};

export default ContentInput;
