import i18n from 'i18next';
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { getDefaultLanguage } from './utils/Utils';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            // translation file path
            loadPath: "/assets/locales/{{lng}}/{{ns}}.json"
        },
        fallbackLng: ["vi", "en"],
        lng: getDefaultLanguage(),
        ns: ["translation", "faq", "terms", "policy"],
        debug: false, //disable production
        interpolation: {
            escapeValue: false,
            formatSeparator: ",",
        },
        react: {
            wait: true,
            useSuspense: false
        },
        detection: {
            order: ['cookie', 'subdomain', 'localStorage', 'htmlTag', 'path'],
            caches: ['cookie']
        }
    });

export default i18n;
