import React from "react";
import Countdown from "react-countdown";
const ResendCountdown = ({ timeRemain, setTimeResendParent, userEmail }) => {
    const startDate = React.useRef(Date.now());
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            setTimeResendParent();
            let arrResendInfo = JSON.parse(localStorage.getItem('resendInfo'));
            const userResend = arrResendInfo.find((item) => item.email === userEmail);
            if (userResend) {
                arrResendInfo.splice(userResend.index, 1)
            }
            localStorage.setItem('resendInfo', JSON.stringify(arrResendInfo));
            return (
                <span>
                </span>
            );
        } else {
            return (
                <span>
                    Gửi lại sau {seconds}s
                </span>
            );
        }
    };
    return (
        <Countdown
            renderer={renderer}
            date={startDate.current + 59000 - timeRemain}
        />
    );
};
export default ResendCountdown;