import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import style from "../../../index.module.scss";
import Ratio from "../../../../../images/dashboard/img-ratio.png";
import { Icon } from "../../../../../../components/Component";
import ChartPine from "../ChartPine";
import moment from "moment";
import { formatNumberAcronym } from "../../../../../../utils/Utils";

const Post = (props) => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(props?.data);
  }, [props?.data]);

  const totalY = data?.rate_type?.reduce((sum, item) => sum + item.y, 0);
  return (
    <Row className="d-flex flex-column flex-md-row">
      <Col
        sm={12}
        md={4}
        className="w-100 d-flex flex-row flex-md-column mb-3 mb-md-0"
        style={{ gap: "16px" }}
      >
        <div className="w-100 h-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Tổng bài đăng
              </CardTitle>
              <div className="d-flex flex-row align-items-center">
                <div className={`${style.titleCard}`}>
                  {data && formatNumberAcronym(data?.total_post?.value)}
                </div>
                {/* <div className="ml-1" style={{ color: "#10B981" }}>
                      {" "}
                      <Icon name={"arrow-up"}></Icon> 0%
                    </div> */}
              </div>
              <CardText
                style={{ whiteSpace: "nowrap" }}
                className={`${style.detailCard} mt-1`}
              >{`Trung bình: ${
                data ? data?.total_post?.avg_day : ""
              }/ngày`}</CardText>
            </CardBody>
          </Card>
        </div>
        <div className="w-100 h-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Tổng tương tác
              </CardTitle>
              <div className="d-flex flex-row align-items-center">
                <div className={`${style.titleCard}`}>
                  {data && formatNumberAcronym(data?.total_engagement?.value)}
                </div>
                {/* <div className="ml-1" style={{ color: "#10B981" }}>
                      {" "}
                      <Icon name={"arrow-up"}></Icon> 0%
                    </div> */}
              </div>
              <CardText
                style={{ whiteSpace: "nowrap" }}
                className={`${style.detailCard} mt-1`}
              >{`Trung bình: ${
                data ? data?.total_engagement?.avg_day : ""
              }/ngày`}</CardText>
            </CardBody>
          </Card>
        </div>
      </Col>
      <Col sm={12} md={4} className="w-100 mb-3 mb-md-0">
        <Card className="h-100">
          <CardBody className="card-inner">
            <CardTitle tag="h5" className={`${style.headCard}`}>
              Bài đăng nổi bật
            </CardTitle>
            <CardText
              className={`${style.detailCard} mt-2 d-flex flex-row align-items-center`}
              style={{ margin: "0px" }}
            >
              <Icon name={"clock"} className="mr-1"></Icon>{" "}
              {data
                ? moment(data?.highlight_post?.media?.updated_at).format(
                    "DD MMM YYYY hh:mm A"
                  )
                : ""}
            </CardText>
            <div>
              <CardImg
                src={
                  // Check if media field is empty or has a valid URL
                  data?.highlight_post?.media?.url
                    ? data?.highlight_post?.media?.url?.endsWith(".mp4") // Check if URL is for a video
                      ? data?.highlight_post?.media?.thumb // Use thumb for video
                      : data?.highlight_post?.media?.url // Use URL for image
                    : Ratio // Default image if media field is empty
                }
                alt="img-ratio"
                height={200}
                className="mt-1"
                style={{
                  margin: "0px",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </div>

            <div>
              <CardText
                className={`${style.contentCard} mt-1 d-flex flex-row align-items-center pb-2`}
                style={{
                  borderBottom: "1px solid #D1D5DB",
                }}
              >
                <span className={`${style.truncatedText}`}>
                  {data ? data?.highlight_post?.title : ""}
                </span>
              </CardText>
            </div>
            <div className="d-flex flex-column mt-2" style={{ gap: "8px" }}>
              {/* <div
                className={`${style.contentCard} d-flex flex-row align-items-center justify-content-between`}
              >
                <div>Reactions</div>
                <div style={{ fontWeight: "600" }}>4</div>
              </div>
              <div
                className={`${style.contentCard} d-flex flex-row align-items-center justify-content-between`}
              >
                <div>Comments</div>
                <div style={{ fontWeight: "600" }}>1</div>
              </div>
              <div
                className={`${style.contentCard} d-flex flex-row align-items-center justify-content-between`}
              >
                <div>Shares</div>
                <div style={{ fontWeight: "600" }}>0</div>
              </div> */}
              <div
                className={`${style.contentCard} d-flex flex-row align-items-center justify-content-between`}
              >
                <div>Engagement</div>
                <div style={{ fontWeight: "600" }}>
                  {data?.highlight_post?.number_engage}
                </div>
              </div>
              {/* <div
                className={`${style.contentCard} d-flex flex-row align-items-center justify-content-between`}
              >
                <div>Engagement Rate</div>
                <div style={{ fontWeight: "600" }}>2%</div>
              </div> */}
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col sm={12} md={4} className="w-100 mb-3 mb-md-0">
        <Card className="h-100">
          <CardBody className="card-inner">
            <CardTitle tag="h5" className={`${style.headCard}`}>
              Tương tác theo loại bài đăng
            </CardTitle>

            <div className="d-flex flex-row justify-content-center mt-2">
              <svg viewBox="-150 0 600 300">
                <ChartPine data={data?.rate_type} info={"Bài đăng"} />
              </svg>
            </div>
            <div className="d-flex flex-column mt-4" style={{ gap: "8px" }}>
              {data?.rate_type?.map((item, index) => {
                const percentage = ((item.y / totalY) * 100).toFixed(1);
                return (
                  <div
                    className={`d-flex flex-row align-items-center justify-content-between`}
                    key={index}
                  >
                    <div className="d-flex flex-row align-items-center">
                      <div
                        className={`rounded-circle mr-1`}
                        style={{
                          height: "8px",
                          width: "8px",
                          backgroundColor: item.color,
                          flexShrink: "0",
                        }}
                      ></div>
                      <div className={`${style.contentCard}`}>{item.label}</div>
                    </div>
                    <div
                      className={`${style.contentCard} w-30 d-flex flex-row justify-content-between`}
                      style={{ fontWeight: "600" }}
                    >
                      <div>{item.y}</div>
                      <div>{percentage}%</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Post;
