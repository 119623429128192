import React, { useState } from "react";
import { RiErrorWarningLine, RiTriangleLine } from "@remixicon/react";
import IconArrow from "../../../../assets/images/icon/icon-arrow.svg";
import { Button, Spinner } from "reactstrap";
import moment from "moment";

import ContentInput from "./ContentInput";
import FirstComment from "./FirstComment";
import FacebookSetting from "./FacebookSetting";
import YoutubeSetting from "./YouTubeSetting";
import PinterestSetting from "./PinterestSetting";
import ScheduleSetting from "./ScheduleSetting";
import Schedule from "./Schedule";

import Carosel from "./Carosel";
import InstagramSetting from "./InstagramSetting";
import TiktokSetting from "./TiktokSetting";

import {
  PLATFORM_FACEBOOK,
  PLATFORM_INSTAGRAM,
  PLATFORM_PINTEREST,
  PLATFORM_THREADS,
  PLATFORM_TIKTOK,
  PLATFORM_YOUTUBE,
} from "../../../utils/Utils";
import styles from "../index.module.scss";
import "../styles.css";
import { useTranslation } from "react-i18next";

const PostSetting = ({
  isOpenLeftTab,
  setIsOpenLeftTab,
  error,
  warning,
  isPosting,
  handleSchedule,
  listChannel,
  scheduleData,
  setScheduleData,
  contentValue,
  setContentValue,
  selectedHashTag,
  setSelectedHashTag,
  setSelectedImages,
  selectedImages,
  selectedVideo,
  setSelectedVideo,
  isVideo,
  pinData,
  setPinData,
  selectedPlatforms,
  setListChannel,
  caroselSetting,
  setCaroselSetting,
  facebookSetting,
  setFacebookSetting,
  setInstagramSetting,
  instagramSetting,
  youtubeData,
  setYoutubeData,
  tiktokData,
  setTiktokData,
  setIsOpenSetting,
  isOpenSetting,
  uniquePlatformsData,
  toggleTab,
  onOpenModalCustomThumbnailTikTok,
  onOpenModalCustomThumbnail,
  setIsOpenRightTab,
  isOpenRightTab,
  setFirstCommentSetting,
  selectedStatus,
  setSelectedStatus,
  isDisabledStatus,
  setIsDisabledStatus,
}) => {
  const [errorDetail, setErrorDetail] = useState(false);
  const { t } = useTranslation();
  const renderSetting = () => {
    const faceChannels = listChannel?.channel.find(
      (item) => item.platform === PLATFORM_FACEBOOK && item.check === true
    );
    const instaChannels = listChannel?.channel.find(
      (item) => item.platform === PLATFORM_INSTAGRAM && item.check === true
    );
    const pinChannels = listChannel?.channel.find(
      (item) => item.platform === PLATFORM_PINTEREST && item.check === true
    );
    const youtubeChannels = listChannel?.channel.find(
      (item) => item.platform === PLATFORM_YOUTUBE && item.check === true
    );
    const tiktokChannels = listChannel?.channel.find(
      (item) => item.platform === PLATFORM_TIKTOK && item.check === true
    );
    const threadsChannels = listChannel?.channel.find(
      (item) => item.platform === PLATFORM_THREADS && item.check === true
    );

    const setPostType = (type) => {
      setScheduleData({ ...scheduleData, type: type });
    };

    const setTime = (type, value, value2 = undefined) => {
      switch (type) {
        case "date":
          setScheduleData({ ...scheduleData, date: value });
          break;
        case "time":
          setScheduleData({ ...scheduleData, time: value });
          break;
        case "timeStart":
          setScheduleData({ ...scheduleData, timeStart: value });
          break;
        case "timeEnd":
          setScheduleData({ ...scheduleData, timeEnd: value });
          break;
        case "date&time":
          setScheduleData({ ...scheduleData, date: value, time: value2 });
          break;
        default:
          break;
      }
    };

    const setDateTime = (value) => {
      setScheduleData({
        ...scheduleData,
        date: moment(value).format("YYYY-MM-DD"),
        time: moment(value).format("HH:mm"),
      });
    };

    return (
      <>
        <ContentInput
          toggleLabel={() => toggleTab("label")}
          value={contentValue}
          onValueChange={setContentValue}
          selectedHashTag={selectedHashTag}
          setSelectedHashTag={setSelectedHashTag}
          setSelectedImages={setSelectedImages}
          selectedImages={selectedImages}
          selectedVideo={selectedVideo}
          setSelectedVideo={setSelectedVideo}
          isPinterest={listChannel?.channel.find(
            (item) =>
              item.platform === PLATFORM_PINTEREST && item.check === true
          )}
          isTiktok={listChannel?.channel.find(
            (item) => item.platform === PLATFORM_TIKTOK && item.check === true
          )}
          isVideo={isVideo}
          pinData={pinData}
          selectedPlatforms={selectedPlatforms}
          setPinData={setPinData}
          onOpenModalCustomThumbnail={onOpenModalCustomThumbnail}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          isDisabledStatus={isDisabledStatus}
          setIsDisabledStatus={setIsDisabledStatus}
        />

        <Carosel
          listChannel={listChannel}
          setListChannel={setListChannel}
          caroselSetting={caroselSetting}
          setCaroselSetting={setCaroselSetting}
          previewImages={selectedImages}
          content={contentValue}
        />

        <FirstComment
          listChannel={listChannel}
          setListChannel={setListChannel}
          setFirstCommentSetting={setFirstCommentSetting}
        />

        {faceChannels && (
          <FacebookSetting
            setSetting={setFacebookSetting}
            type={facebookSetting}
            listImages={selectedImages}
            isVideo={isVideo}
          />
        )}

        {instaChannels && (
          <InstagramSetting
            setSetting={setInstagramSetting}
            type={instagramSetting}
            listImages={selectedImages}
            isVideo={isVideo}
          />
        )}

        {youtubeChannels && (
          <YoutubeSetting
            formData={youtubeData}
            setFormData={setYoutubeData}
            channels={listChannel}
            setChannels={setListChannel}
          />
        )}

        {tiktokChannels && (
          <TiktokSetting
            formData={tiktokData}
            setFormData={setTiktokData}
            selectedVideo={selectedVideo}
            isVideo={isVideo}
            onOpenModalCustomThumbnail={onOpenModalCustomThumbnailTikTok}
          />
        )}

        {pinChannels && (
          <PinterestSetting
            formData={pinData}
            setFormData={setPinData}
            channels={listChannel}
            setChannels={setListChannel}
          />
        )}

        {threadsChannels && (
          <></>
        )}

        <ScheduleSetting
          setIsOpenSetting={setIsOpenSetting}
          formData={scheduleData}
          setFormData={setScheduleData}
        />

        <Schedule
          isOpen={isOpenSetting}
          setOpen={setIsOpenSetting}
          data={scheduleData}
          setType={setPostType}
          setTime={setTime}
          setDateTime={setDateTime}
          channels={uniquePlatformsData}
          listChannel={listChannel}
          setListChannel={setListChannel}
        />
      </>
    );
  };

  return (
    <div
      style={{ width: "calc(100% - 288px - 420px)", minWidth: 500 }}
      className="d-flex flex-column flex-fill"
    >
      <header
        style={{
          padding: "15px 24px",
          backgroundColor: "#fbfcfc",
          position: "sticky",
          top: 75,
          zIndex: 2,
        }}
        class="color-border"
      >
        <div
          className="absolute mt-4 color-border border-[#CED4DA] flex items-center justify-center h-6 w-6 rounded-full"
          style={{ left: -15, top: -6, zIndex: 3, backgroundColor: "#fbfcfc" }}
          onClick={() => setIsOpenLeftTab(!isOpenLeftTab)}
        >
          <img
            src={IconArrow}
            alt="arrow"
            className={`cursor-pointer mx-auto ${
              isOpenLeftTab ? "rotate-180" : ""
            }`}
            fill={"#878A99"}
            width={16}
            height={16}
          />
        </div>
        <p
          className={"mb-0 mt-1.5"}
          style={{
            fontSize: "16.25px",
            fontWeight: "500",
            lineHeight: "19.67px",
          }}
        >
          {t("store_post.post_compose")}
        </p>
        <div
          className="absolute border border-[#CED4DA] flex items-center justify-center h-6 w-6 rounded-full"
          style={{
            marginRight: -24,
            top: 1,
            marginTop: 16,
            backgroundColor: "rgb(251, 252, 252)",
            right: 10,
            zIndex: 1,
          }}
          onClick={() => setIsOpenRightTab(!isOpenRightTab)}
        >
          <img
            src={IconArrow}
            alt="arrow"
            className={`cursor-pointer mx-auto ${
              isOpenRightTab ? "" : "rotate-180"
            }`}
            fill={"#878A99"}
            width={16}
            height={16}
          />
        </div>
      </header>
      <div
        className={
          "color-border border-2 border-top-0 border-bottom-0 pb-5 relative"
        }
        style={{
          padding: "18px 16px 0px 16px",
          flexGrow: 1,
          backgroundColor: "#ffffff",
          minHeight: "calc(100vh - 133px)",
          overflow: "auto",
        }}
      >
        {renderSetting()}

        <div className="absolute bottom-0 left-0 right-0">
          {errorDetail && (
            <div
              className="p-2"
              style={{
                backgroundColor: "#fbf5f6",
                border: "1px solid #fbf5f6",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                maxHeight: "10rem",
                overflow: "auto",
              }}
            >
              {error &&
                error.length > 0 &&
                error.map((item, index) => (
                  <div className="d-flex align-items-center gap-1">
                    <RiErrorWarningLine
                      size={20}
                      className="text-danger"
                    ></RiErrorWarningLine>
                    <p className="mb-0 fs-13 text-black">{item}</p>
                  </div>
                ))}

              {warning &&
                warning.length > 0 &&
                warning.map((item, index) => (
                  <div className="d-flex align-items-center gap-1">
                    <RiTriangleLine size={20} className="text-warning" />
                    <p className="mb-0 fs-13 text-black">{item}</p>
                  </div>
                ))}
            </div>
          )}
          <div
            className="d-flex justify-content-between align-items-center color-border border-bottom-0 p-2"
            style={{ background: "#fff" }}
          >
            <div className="d-flex align-items-center gap-3">
              {error && error.length > 0 && (
                <div
                  className="d-flex align-items-center gap-1 cursor-pointer"
                  id="errorShow"
                  onClick={() => setErrorDetail(!errorDetail)}
                >
                  <RiErrorWarningLine
                    size={20}
                    className="text-danger"
                  ></RiErrorWarningLine>
                  <p className="mb-0 fs-13 text-danger">
                    {t("store_post.error.title")} ({error.length})
                  </p>
                </div>
              )}
              {warning && warning.length > 0 && (
                <div
                  className="d-flex align-items-center gap-1 cursor-pointer"
                  id="warningShow"
                  onClick={() => setErrorDetail(!errorDetail)}
                >
                  <RiTriangleLine
                    size={20}
                    className="text-warning"
                  ></RiTriangleLine>
                  <p className="mb-0 fs-13 text-warning">
                    Warning ({warning.length})
                  </p>
                </div>
              )}
            </div>

            <div className="d-flex align-items-center gap-2">
              <Button
                className="text-white border-0"
                disabled={isPosting || error.length > 0}
                onClick={() => handleSchedule()}
                style={{ backgroundColor: "#0068e5" }}
              >
                {isPosting ? (
                  <Spinner size={"sm"}></Spinner>
                ) : (
                  t("store_post.button.post")
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostSetting;
