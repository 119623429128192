
import { Button, Modal, ModalBody } from "reactstrap";
import Warning from "../Image/waring.svg";
import { useTranslation } from "react-i18next";


const ConfimDelete = ({ ...props }) => {
  const { modalDelete, toggleDelete } = props;
  const { t } = useTranslation();
  return (
    <Modal isOpen={modalDelete} toggle={toggleDelete}>
      <ModalBody className="modal-body-lg text-center">
        <div className="nk-modal">
          <img src={Warning} alt='' />
          <h4 className="nk-modal-title">{t("components.model_confirm.tilte")}</h4>
          <div className="nk-modal-text">
            <p className="lead">{props.messeage}</p>
          </div>
          <div className="nk-modal-action mt-5">
            <Button
              style={{ marginRight: "28px", backgroundColor: "#BDBDBD", color: "#fff", border: '0px' }}
              size="lg"
              className="btn-mw"
              onClick={
                toggleDelete
              }
            >
              {t("components.model_confirm.cancel")}
            </Button>

            <Button
              style={{ marginRight: "28px", backgroundColor: "#6A2C62", color: "#fff", border: '0px' }}
              size="lg" className="btn-mw"
              onClick={props.handleDelete}
            >
              {t("components.model_confirm.agree")}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfimDelete;
