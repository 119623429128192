import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Col, Card, CardBody, CardHeader, Row } from "reactstrap";

import { LoadingItem } from "../../CommonItem/LoadingItem";
import { getProjectIdInLocalStorage } from "../../helper";
import { getDashboardAudience } from "../../../../../api/overview";

import style from "../../index.module.scss";

const ImpressionStatistic = (props) => {
  const [dataCharts, setDataCharts] = useState([]);
  const [optionsCustom, setOptionsCustom] = useState({});

  const { t } = useTranslation();
  const { channels, from, to } = props;
  const projectId = getProjectIdInLocalStorage();
  const [summary, setSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (projectId) {
      const data = {
        params: {
          project_id: projectId,
          from,
          to,
          channel_ids: channels,
        },
      };
      setIsLoading(true);
      getDashboardAudience(data).then((response) => {
        if (response) {
          const _data = response.data;
          setSummary(_data.summary);
          const dataSets = _data?.dataSet?.dataSets
            ?.filter((d) => d.label === "Current")
            .map((ds) => ({
              name: ds.label,
              data: ds.data,
            }));

          setDataCharts(dataSets);

          const options = {
            labels: [],
            chart: {
              height: 345,
              type: "line",
              zoom: {
                enabled: false,
              },
              toolbar: {
                show: false,
              },
            },
            colors: ["#45cb85d9", "#4b38b3", "#007bff"],

            dataLabels: {
              enabled: false,
            },
            stroke: {
              width: [3, 4, 3],
              curve: "straight",
              dashArray: [0, 8, 5],
            },
            series: dataSets || [],
            markers: {
              size: 0,
              hover: {
                sizeOffset: 6,
              },
            },
            xaxis: {
              categories: _data?.dataSet?.labels,
              tickAmount: 7,
            },
            grid: {
              borderColor: "#f1f1f1",
            },
          };

          setOptionsCustom(options);
        } else {
          setSummary([]);
          setDataCharts([]);
        }
        setIsLoading(false);
      });
    }
    return () => {
      setSummary([]);
      setDataCharts([]);
    };
  }, [from, to, projectId, channels]);
  return (
    <React.Fragment>
      {/* <Row> */}
      <Col xl={7}>
        <Card className="card-height-100">
          <CardHeader className="border-0 align-items-center bg-light-subtle d-flex bg-slate-50">
            <h4
              className="card-title mb-0 flex-grow-1"
              style={{ fontSize: "16px" }}
            >
              {t("overview.content_spread.summary.impressions")}
            </h4>
          </CardHeader>

          <CardHeader className="p-0 border-0 bg-light-subtle bg-slate-50">
            <Row className="g-0 text-center">
              {isLoading ? (
                <LoadingItem />
              ) : (
                summary?.map((summaryItem, index) => (
                  <Col key={index} xs={6} sm={3}>
                    <div
                      className={`p-3 border  border-start-0 ${style.borderDashed}`}
                    >
                      <h5 className="mb-1">
                        <span
                          className="counter-value"
                          data-target={summaryItem?.current}
                          style={{ fontSize: "16px" }}
                        >
                          {summaryItem?.current}
                        </span>
                      </h5>
                      <p
                        className="text-muted mb-0"
                        style={{ fontSize: "13px" }}
                      >
                        {t(
                          `overview.content_spread.summary.${summaryItem?.key}`
                        )}
                      </p>
                    </div>
                  </Col>
                ))
              )}
            </Row>
          </CardHeader>

          <CardBody className="p-0 pb-2">
            <div className="w-100">
              {isLoading ? (
                <div className="h-360">
                  <LoadingItem />
                </div>
              ) : (
                <ReactApexChart
                  dir="ltr"
                  options={optionsCustom}
                  series={dataCharts || []}
                  type="line"
                  height="360"
                  className="apex-charts"
                />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
      {/* </Row> */}
    </React.Fragment>
  );
};

export default ImpressionStatistic;
