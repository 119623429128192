import React, { useEffect, useRef, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { toastError, toastSuccess } from "../../../../libs/toasts";
import { createTask, deleteSectionTask } from "../../../../api/kanban";
import { Icon } from "../../../../components/Component";
import { LoadingItem } from "../../Dashboard_v2/CommonItem/LoadingItem";
import KanbanCardList from "./KanbanCardList";
import ConfimDelete from "../../../components/Modal/ModalConfimDelete";
import "../index.module.scss";
import "../styles.css";

const KanbanColumn = ({
  index,
  column,
  toggleTaskModal,
  toggleSectionModal,
  isLoadingSection,
  isLoadingTasks,
  activeGroup,
  initialData,
  setInitialData,
  type,
  orderBy,
  sort,
  staffs,
  isComplete,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const { t } = useTranslation();

  const inputRef = useRef(null);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteSection, setDeleteSection] = useState();
  const [fastTask, setFastTask] = useState(false);
  const [inputText, setInputText] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const formData = {
    title: "",
    section_id: column._id,
    group_id: activeGroup?._id,
    owner_id: userInfo.id,
    started_at: moment(Date.now()).unix(),
    // ended_at: moment(Date.now()).unix(),
  };

  useEffect(() => {
    if (fastTask && inputRef.current) {
      inputRef.current.focus();
    }
  }, [fastTask]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputText &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setFastTask(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputText]);

  const toggleDeleteModal = (item) => {
    setDeleteSection(item);
    setDeleteModal(!deleteModal);
  };

  const handleDelete = async (deleteSection) => {
    try {
      const res = await deleteSectionTask(
        userInfo.last_project_active,
        deleteSection._id
      );
      if (res?.status) {
        setInitialData((prevData) => {
          const updatedColumns = { ...prevData.columns };
          const defaultColumn = Object.keys(updatedColumns).find(
            (columnId) => updatedColumns[columnId].is_default === true
          );

          const tasksRemove = updatedColumns[deleteSection._id].tasks;

          updatedColumns[defaultColumn].tasks = [
            ...updatedColumns[defaultColumn].tasks,
            ...updatedColumns[deleteSection._id].tasks,
          ];

          updatedColumns[defaultColumn].cnt_task += tasksRemove.length;

          delete updatedColumns[deleteSection._id];

          const updatedOrder = prevData.columnOrder.filter(
            (columnId) => columnId !== deleteSection._id
          );

          const updatedTasks = { ...prevData.tasks };
          updatedColumns[defaultColumn].tasks.forEach((taskId) => {
            if (updatedTasks[taskId]) {
              updatedTasks[taskId].section_id = defaultColumn;
            }
          });

          return {
            ...prevData,
            columnOrder: updatedOrder,
            columns: updatedColumns,
            tasks: updatedTasks,
          };
        });

        toggleDeleteModal();
        toastSuccess(t("task_management.toast.delete_task_success"));
      }
    } catch (error) {
      console.log(error);
      toggleDeleteModal();
      toastError(t("task_management.toast.delete_task_success"));
    }
  };

  const mutateTask = async (updatedFormData) => {
    const projectId = userInfo.last_project_active;
    setIsLoading(true);
    try {
      const res = await createTask(projectId, updatedFormData);
      if (res?.status && res?.data) {
        const newTask = res.data;

        setInitialData((prevData) => {
          const updatedTasks = {
            ...prevData.tasks,
            [newTask._id]: newTask,
          };

          const updatedColumns = { ...prevData.columns };
          const columnId = newTask.section_id;
          if (updatedColumns[columnId]) {
            updatedColumns[columnId] = {
              ...updatedColumns[columnId],
              tasks: [...updatedColumns[columnId].tasks, newTask._id],
              cnt_task: updatedColumns[columnId].cnt_task + 1,
            };
          }

          return {
            ...prevData,
            tasks: updatedTasks,
            columns: updatedColumns,
          };
        });
        setFastTask(false);
        setIsLoading(false);
        setInputText(null);
        toastSuccess(t("task_management.toast.create_task_success"));
      }
    } catch (e) {
      setFastTask(false);
      setIsLoading(false);
      setInputText(null);
      toastError(t("task_management.toast.create_task_failed"));
      console.error(e);
    }
  };

  const handleCreateTask = (event) => {
    if (event.key === "Enter" && inputText) {
      const updatedFormData = {
        ...formData,
        title: event.target.value,
      };
      mutateTask(updatedFormData);
    }
  };

  return (
    <>
      {isLoadingSection ? (
        <div
          style={{
            display: "flex",
            width: "320px",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingItem />
        </div>
      ) : (
        <Draggable draggableId={column._id} key={column._id} index={index}>
          {(provided) => (
            <div
              className="kanban-board"
              ref={provided.innerRef}
              {...provided.draggableProps}
            >
              <div
                className="kanban-board-header"
                {...provided.dragHandleProps}
                style={{
                  borderTop: `3px solid ${column.color}`,
                }}
              >
                <div className="kanban-title-board">
                  <div className="kanban-title-content">
                    <h6 className="title title-line">
                      {column.name === ""
                        ? t("task_management.default_section")
                        : column.name?.length > 20
                        ? column.name.substring(0, 19) + "... "
                        : column.name}
                    </h6>
                    <span className="badge badge-pill badge-outline-light text-dark">
                      {column.cnt_task > 99 ? "99+" : column.cnt_task}
                    </span>
                  </div>
                  <div className="kanban-title-content">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        href="toggle"
                        onClick={(ev) => ev.preventDefault()}
                        className="dropdown-toggle btn btn-sm btn-icon btn-trigger mr-n1"
                      >
                        <Icon name="more-h"></Icon>
                      </DropdownToggle>
                      <DropdownMenu right>
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <DropdownItem
                              tag="a"
                              onClick={() => toggleSectionModal(column)}
                            >
                              <Icon name="edit"></Icon>
                              <span>{t("task_management.edit_section")}</span>
                            </DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              onClick={() => toggleDeleteModal(column)}
                              disabled={column.priority === 0 ? true : false}
                            >
                              <Icon name="trash"></Icon>
                              <span>{t("task_management.delete_section")}</span>
                            </DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              onClick={() => toggleTaskModal(column)}
                            >
                              <Icon name="plus-sm"></Icon>
                              <span>{t("task_management.add_task")}</span>
                            </DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </div>
              <Droppable droppableId={column._id} type="task">
                {(provided) => (
                  <div
                    className="kanban-drag"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <KanbanCardList
                      initialData={initialData}
                      setInitialData={setInitialData}
                      tasks={column.tasks}
                      column={column}
                      activeGroup={activeGroup}
                      isLoadingTasks={isLoadingTasks}
                      type={type}
                      orderBy={orderBy}
                      sort={sort}
                      staffs={staffs}
                      isComplete={isComplete}
                    />
                    <div
                      className="kanban-add-task btn-block cursor-pointer"
                      onClick={() => setFastTask(!fastTask)}
                    >
                      {isLoading ? (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <LoadingItem />
                        </div>
                      ) : !fastTask ? (
                        <div className="d-flex w-100 justify-content-center items-center cursor-pointer">
                          <Icon name="plus-sm"></Icon>
                          <span>
                            {column?.tasks?.cnt_task > 0
                              ? t("task_management.form.add_another")
                              : t("task_management.form.add")}{" "}
                            task
                          </span>
                        </div>
                      ) : (
                        <input
                          type="text"
                          className="newtask-input"
                          placeholder={t(
                            "task_management.placeholder.enter_your_task_title"
                          )}
                          ref={inputRef}
                          value={inputText}
                          onChange={(e) => setInputText(e.target.value)}
                          onKeyDown={handleCreateTask}
                        />
                      )}
                    </div>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          )}
        </Draggable>
      )}

      <ConfimDelete
        modalDelete={deleteModal}
        toggleDelete={toggleDeleteModal}
        messeage={t("task_management.message.all_task_to_no_section")}
        handleDelete={() => handleDelete(deleteSection)}
      />
    </>
  );
};

export default KanbanColumn;
