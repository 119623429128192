import { merge } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getDashboardFansOnline } from "../../../api/overview";
import BubbleChart from "../../components/Chart/BubbleChart";

const ReachByHour = (props) => {
  const { t } = useTranslation();
  const { range } = props;
  const project = useSelector((state) => state.project.project);
  const [chartData, setChartData] = useState({});
  const chartOptions = {
    legend: {
      display: false,
      labels: {
        boxWidth: 12,
        padding: 20,
        fontColor: "#6783b8",
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      enabled: true,
      backgroundColor: "#fff",
      borderColor: "#eff6ff",
      borderWidth: 2,
      titleFontSize: 13,
      titleFontColor: "#6783b8",
      titleMarginBottom: 6,
      bodyFontColor: "#9eaecf",
      bodyFontSize: 12,
      bodySpacing: 4,
      yPadding: 10,
      xPadding: 10,
      footerMarginTop: 0,
      displayColors: false,
      callbacks: {
        label: function (tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const item = dataset.data[tooltipItem.index];
          return `${item.time_range}: ${Intl.NumberFormat().format(item.value)}`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          display: true,
          offset: true,
          ticks: {
            max: 24,
            min: 0,
            stepSize: 6,
            fontSize: 12,
            fontColor: "#9eaecf",
            padding: 8,
            reverse: true,
          },
          gridLines: {
            color: "rgba(82, 100, 132, 0.2)",
            drawBorder: false,
            tickMarkLength: 0,
            zeroLineColor: "rgba(82, 100, 132,0.2)",
          },
          scaleLabel: {
            display: true,
            labelString: "HOUR",
            fontSize: 12,
            fontColor: "#9eaecf",
          },
        },
      ],
      xAxes: [
        {
          display: true,
          type: "category",
          labels: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
          offset: true,
          ticks: {
            max: "SUN",
            min: "MON",
            stepSize: 1,
            padding: 16,
            fontSize: 12,
            fontColor: "#9eaecf",
            source: "labels",
          },
          gridLines: {
            color: "transparent",
            tickMarkLength: 0,
            zeroLineColor: "transparent",
            offsetGridLines: true,
          },
        },
      ],
    },
  };
  useEffect(() => {
    if (project?._id) {
      const data = {
        params: {
          project_id: project?._id,
          time: `${range} days`,
        },
      };
      getDashboardFansOnline(data).then((response) => {
        const _data = response.data;
        if (_data) {
          const _chartFormatter = {
            backgroundColor: function (context) {
              const index = context?.dataIndex;
              const value = context?.dataset?.data?.[index];
              return value?.color || "#6576ff";
            },
          };
          const _chart = {
            datasets: [merge(_data, _chartFormatter)],
          };
          setChartData(_chart);
        }
      });
    }
    return () => {
      setChartData({});
    };
  }, [range, project?._id]);

  return (
    <>
      <div className="card-title-group align-start pb-3 g-2">
        <div className="card-title card-title-sm">
          <h6 className="title">{t("overview.reach_by_hour.title")}</h6>
          <p>{t("overview.reach_by_hour.subtitle")}</p>
        </div>
      </div>
      <div className="analytic-reach-ck">
        <BubbleChart data={chartData} options={chartOptions} />
      </div>
    </>
  );
};

export default ReachByHour;
