import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import io from "socket.io-client";
import { UserAvatar } from "../components/Component";
import { findUpper } from "../utils/Utils";
import "../App.css";

const CustomToast = ({ title, description, img, actorName }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "calc(100% - 14px)",
      }}
    >
      <div
        style={{
          width: "32px",
          height: "32px",
          borderRadius: "4px",
          marginRight: "10px",
        }}
      >
        {img ? (
          <img
            src={img}
            alt="icon"
            style={{ width: "32px", height: "32px", borderRadius: "4px" }}
          />
        ) : (
          <UserAvatar className="sm" text={findUpper(actorName)}></UserAvatar>
        )}
      </div>

      <div style={{ minHeight: "40px", width: "calc(100% - 32px)" }}>
        <h4 style={{ fontSize: "14px", fontWeight: "600" }}>{title}</h4>
        <p className="description-notification" style={{ fontSize: "12px" }}>
          {description}
        </p>
      </div>
    </div>
  );
};

const useWebSocket = () => {
  const socketRef = useRef();
  const userInfor = JSON.parse(localStorage.getItem("userInfor"));
  let eventListenName;
  if (userInfor) {
    eventListenName = `socket-message-for-team-${userInfor.last_project_active}-${userInfor.id}`;
  }

  const handleIncomingMessage = (data) => {
    if (data.group === 4) {
      if (data.type === 6) {
        toast(
          <CustomToast
            title={data.title}
            description={data.description}
            img={data?.image}
            actorName={data?.actor.name}
          />,
          {
            autoClose: 3000,
            onClick: () => {
              window.open(
                `/task-management?group_id=${data.attachment_task.group_id}&task_id=${data.attachment_task._id}`,
                "_blank"
              );
            },
          }
        );
      } else if (data.type === 7) {
        toast.info("event");
      }
    }
  };

  useEffect(() => {
    // Connect to the Socket.IO server
    // @ts-ignore
    socketRef.current = io(process.env.REACT_APP_WEB_SOCKET_URL, {
      transports: ["websocket"],
      withCredentials: true,
    });
    socketRef.current?.on(eventListenName, handleIncomingMessage);

    return () => {
      // Clean up the socket connection when component unmounts
      if (socketRef.current) {
        // @ts-ignore
        socketRef.current?.off(eventListenName, handleIncomingMessage);
        // @ts-ignore
        socketRef.current?.disconnect();
      }
    };
    // eslint-disable-next-line
  }, []);

  return null; // Custom hooks typically return some value or null
};

export default useWebSocket;
