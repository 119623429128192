import { API } from "../libs/client";

const CDN_URI = {
  UPLOAD_IMAGE: "/v1/upload/image",
  UPLOAD_IMAGE_BULK: "/v1/upload/image/bulk",
};

// Group Task
export const listGroupTask = async (projectId, data) => {
  const uri = `/projects/${projectId}/group-task`;
  const res = await API.get(uri, data);
  return res.data;
};

export const createGroupTask = async (projectId, data) => {
  const uri = `/projects/${projectId}/group-task`;
  const res = await API.post(uri, data);
  return res.data;
};

export const updateGroupTask = async (projectId, id, data) => {
  const uri = `/projects/${projectId}/group-task/${id}`;
  const res = await API.put(uri, data);
  return res.data;
};

export const deleteGroupTask = async (projectId, id, data) => {
  const uri = `/projects/${projectId}/group-task/${id}`;
  const res = await API.delete(uri, data);
  return res.data;
};

export const markGroupTask = async (projectId, id, data) => {
  const uri = `/projects/${projectId}/group-task/${id}/mark-select`;
  const res = await API.post(uri, data);
  return res.data;
};

// Section Task
export const listSectionTask = async (projectId, groupId, data) => {
  const uri = `/projects/${projectId}/section-task?group_id=${groupId}`;
  const res = await API.get(uri, data);
  return res.data;
};

export const createSectionTask = async (projectId, data) => {
  const uri = `/projects/${projectId}/section-task`;
  const res = await API.post(uri, data);
  return res.data;
};

export const updateSectionTask = async (projectId, id, data) => {
  const uri = `/projects/${projectId}/section-task/${id}`;
  const res = await API.put(uri, data);
  return res.data;
};

export const deleteSectionTask = async (projectId, id, data) => {
  const uri = `/projects/${projectId}/section-task/${id}`;
  const res = await API.delete(uri, data);
  return res.data;
};

export const dragSectionTask = async (projectId, data) => {
  const uri = `/projects/${projectId}/section-task/update/priority`;
  const res = await API.put(uri, data);
  return res.data;
};

// Task
export const listTask = async (
  projectId,
  groupId,
  sectionId,
  page,
  type,
  orderBy,
  sort,
  isComplete,
  filterParams,
  data
) => {
  let uri = `/projects/${projectId}/task?group_id=${groupId}&section_id=${sectionId}&page=${page}`;
  if (type !== "") {
    uri = uri + `&${type}=true`;
  }
  if (orderBy) {
    uri = uri + `&order_by=${orderBy}&arrange=${sort}`;
  }
  if (isComplete) {
    uri = uri + `&is_complete=${isComplete}`;
  }
  if (filterParams) {
    uri = uri + `&${filterParams}`;
  }
  const res = await API.get(uri, data);
  return res.data;
};

export const createTask = async (projectId, data) => {
  const uri = `/projects/${projectId}/task`;
  const res = await API.post(uri, data);
  return res.data;
};

export const updateTask = async (projectId, id, data) => {
  const uri = `/projects/${projectId}/task/${id}`;
  const res = await API.put(uri, data);
  return res.data;
};

export const markCompleteTask = async (projectId, id, completed) => {
  const uri = !!completed
    ? `/projects/${projectId}/task/${id}/mark-complete`
    : `/projects/${projectId}/task/${id}/mark-un-complete`;
  const res = await API.put(uri);
  return res.data;
};

export const storeChildrenTask = async (projectId, id, data) => {
  const uri = `/projects/${projectId}/task/${id}/store-child-task`;
  const res = await API.post(uri, data);
  return res.data;
};

export const detailTask = async (projectId, id, data) => {
  const uri = `/projects/${projectId}/task/${id}/detail`;
  const res = await API.get(uri, data);
  return res.data;
};

export const getListChilds = async (projectId, id, page, data) => {
  const uri = `/projects/${projectId}/task/${id}/get-list-child?page=${page}`;
  const res = await API.get(uri, data);
  return res.data;
};

export const deleteTask = async (projectId, id, data) => {
  const uri = `/projects/${projectId}/task/${id}`;
  const res = await API.delete(uri, data);
  return res.data;
};

// Comment Task
export const listCommentTask = async (projectId, id, page, data) => {
  const uri = `/projects/${projectId}/task/${id}/comment?page=${page}`;
  const res = await API.get(uri, data);
  return res.data;
};

export const createCommentTask = async (projectId, id, data) => {
  const uri = `/projects/${projectId}/task/${id}/comment`;
  const res = await API.post(uri, data);
  return res.data;
};

export const deleteCommentTask = async (projectId, taskId, id, data) => {
  const uri = `/projects/${projectId}/task/${taskId}/comment/${id}`;
  const res = await API.delete(uri, data);
  return res.data;
};

// Log
export const listLog = async (projectId, data) => {
  const uri = `/projects/${projectId}/task/comment`;
  const res = await API.get(uri, data);
  return res.data;
};

// Other
export const uploadImage = async (data) => {
  const res = await API.post(CDN_URI.UPLOAD_IMAGE, data, {
    baseURL: process.env.REACT_APP_CDN_API + "api",
  });
  return res.data;
};

export const uploadImageBulk = async (data) => {
  const res = await API.post(CDN_URI.UPLOAD_IMAGE_BULK, data, {
    baseURL: process.env.REACT_APP_CDN_API + "api",
  });
  return res.data;
};

export const getSharePost = async (projectId, id, data) => {
  const uri = `/shares/${id}/posts?project_id=${projectId}`;
  const res = await API.get(uri, data);
  return res.data;
};
