import { useTranslation } from "react-i18next";
import { Row, Col, Container, ModalFooter, Spinner } from "reactstrap";
import { upperFirstLetter } from "../../../utils/Utils";
import { Modal, ModalBody } from "reactstrap";
import {
    Button,
  } from "../../../components/Component";
import DefaultImage from "../../../So9/images/avatar/no-image.png";
import FlowerTrial from "../../../So9/images/flowerTrial.png"
import style from "../../../pages/auth/index.module.scss"
const ModalConnectPageChannel = ({
    openModalListChannel,
    toggleModalListChannel,
    listChannelLoading,
    renderPlatform,
    handleSelectPage,
    setOpenModalListChannel,
    loading,
    setLoading,
    setIsDisable,
    handleConnectChannelSelected,
    listChannel,
    typeFacebookPlatform,
    project,
    disable,
}) => {
    const {t} = useTranslation();

    return (
        <>
        <Modal isOpen={openModalListChannel} toggle={toggleModalListChannel}>
        <ModalBody >
          <div className={`nk-modal`}>
            <h4 className="nk-modal-title">{t('connectpage.modal_channels')}</h4>
            <Row style={{ backgroundColor: "#F5F5F5", minHeight: "140px", maxHeight: "300px", overflowX: "hidden", overflowY: "scroll" }}>
              <Col md="12">
                {
                  listChannelLoading ? (
                    <div className="w-100 h-full d-flex justify-content-center align-items-center">
                      <><Spinner size="lg" className="mr-3" />
                        <span> Loading... </span></>
                    </div>) : (
                    <>
                      {listChannel.length > 0 ? listChannel.map((channel, index) => {
                        return <div className="d-flex justify-between m-2 " key={index}>

                          <div className="d-flex align-center">
                            <div>
                              <img src={channel.picture.data.url || DefaultImage} alt="channels" className={style.imageChannels}></img>
                            </div>
                            <div>
                              <p className={style.ModalLabel}>{channel?.name || ""}</p>
                              {/* <p className={style.Modalp}>{typeFacebookPlatform === "page" ? "Facebook Page" : "Facebook Group"}</p> */}
                              {renderPlatform(typeFacebookPlatform)}
                            </div>
                          </div>
                          <div className={`d-flex align-center`}>
                            <input
                              type="checkbox"
                              name="channels"
                              className={`checkbox-channel`}
                              value={channel.id}
                              onChange={() => handleSelectPage(channel.id)}
                            ></input>
                          </div>
                        </div>
                      })
                        : (
                          <>
                            <div className="w-100 h-full d-flex justify-content-center align-items-center">
                              <h4>Không có kênh có thể kết nối </h4>
                            </div>
                          </>
                        )
                      }
                    </>
                  )


                }
              </Col>
            </Row>
          </div>
          <div style={{ display: "flex", marginTop: "12px", alignItems: "center" }}>
            <div style={{ flex: 1, display: "flex", alignItems: "start" }}>
              <img alt={""} src={FlowerTrial} width={"32px"} height={"32px"} />
              <div style={{ marginLeft: "8px" }}>
                <div style={{ fontSize: "18px", lineHeight: "22px", fontWeight: "800" }}>Gói {upperFirstLetter(project?.type)}</div>
                <div style={{ fontSize: "12px", lineHeight: "22px", fontWeight: "600", color: "#828282" }}>Kết nối tối đa <span style={{ color: "#333333" }}>{project?.connection_limit} kênh</span></div>
              </div>
            </div>
            <div style={{ flex: 1, display: "flex", justifyContent: "end" }}>
              <Button type="button" onClick={() => setOpenModalListChannel(false)}>
                {t('connectpage.modal_button_cancel')}
              </Button>
              <Button color="primary" disabled={disable} type="button"
                onClick={() => { setLoading(true); setIsDisable(true); handleConnectChannelSelected(); }}>
                {
                  loading ?
                    (
                      <><Spinner size="sm" />
                        <span> Loading... </span></>) :
                    (
                      t('connectpage.modal_button_connect')
                    )
                }
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
        </>
    )
}

export default ModalConnectPageChannel;