import en from "date-fns/locale/en-US";
import vi from "date-fns/locale/vi";
import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import Content from "../../../layout/content/Content";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { components } from "react-select";
import CurrencyInput from 'react-currency-input-field';
import {
  PostsmartSchedule,
  getChannels
} from "../../../api/createPost";
import {
  Card,
  FormGroup,
  FormText,
  Spinner
} from "reactstrap";
import {
  Block, Col, Icon, Row, RSelect, BlockTitle, BlockHead,
  BlockBetween,
  BlockHeadContent,
  Img
} from "../../../components/Component";
import {
  PLATFORM_TIKTOK,
  PLATFORM_YOUTUBE,
  getPlatformIcon,
  ALL_CHANNELS,
  DEFAULT_CHANNELS
} from "../../utils/Utils";
import style from "./index.module.scss";
import { setCollapseSidebarLeft } from "../../reducers/layoutSlice";
import { useDispatch } from "react-redux";
import { getUnsettingChannels } from "../../../api/channel";
import { optionContentType } from '../../constants/settingSM'
import { getListGroupChannels } from "../../../api/channel";
registerLocale("en", en);
registerLocale("vi", vi);

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const PostFast = ({ socket }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const { i18n, t } = useTranslation();
  let params = useQuery();
  const [Allchannels, setAllChannels] = useState([]);
  const [FavoriteChannels, setfavoriteChannel] = useState([]);
  const [mobileView, setMobileView] = useState();
  const [numberOfUnsettingChannel, setNumberOfUnsettingChannel] = useState();
  const dispatch = useDispatch();

  useEffect(async () => {
    const data = {
      params: {
        page: 1
      }
    }
    const unsettingChannelResponse = await getUnsettingChannels(userInfo.last_project_active, data);
    if (unsettingChannelResponse.status) {
      const { data, total } = unsettingChannelResponse.data;
      setNumberOfUnsettingChannel(total);
    } else {
      console.log("error");
    }
  }, []);

  function unique(arr) {
    var newArr = []
    for (var i = 0; i < arr.length; i++) {
      if (newArr.indexOf(arr[i]) === -1) {
        newArr.push(arr[i])
      }
    }
    return newArr
  }

  const viewChange = () => {
    if (window.innerWidth < 960) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };
  /*
      * Form Dynamic
      */

  const formFieldInitials = {
    channel: [],
    rowChannel: 6,
    content: {
      Reels: { num: 2, time: [{ start: 0, end: 0, limit: 1 }] },
      Video: { num: 2, time: [{ start: 0, end: 0, limit: 1 }] },
      Image: { num: 2, time: [{ start: 0, end: 0, limit: 1 }] }
    },
    isGroupChannel: "channel"
  }
  const [formValues, setFormValues] = useState([]);
  const addTimeFrame = (i, Type) => {
    let newFormValues = [...formValues];
    let newTimeFrame = { start: 0, end: 0, limit: 1 }
    switch (Type) {
      case "Reels":

        let CurTimeReel = [...newFormValues[i]["content"].Reels.time];
        newFormValues[i]["content"].Reels.time = [...CurTimeReel, newTimeFrame];
        newFormValues[i]["content"].Reels.num = newFormValues[i]["content"].Reels.num + 1;
        break;
      case "Video":

        let CurTimeVideo = [...newFormValues[i]["content"].Video.time];
        newFormValues[i]["content"].Video.time = [...CurTimeVideo, newTimeFrame];
        newFormValues[i]["content"].Video.num = newFormValues[i]["content"].Video.num + 1;
        break;
      case "Image":

        let CurTimeImage = [...newFormValues[i]["content"].Image.time];
        newFormValues[i]["content"].Image.time = [...CurTimeImage, newTimeFrame];
        newFormValues[i]["content"].Image.num = newFormValues[i]["content"].Image.num + 1;
        break;
      default:
    }
    newFormValues[i].rowChannel = newFormValues[i].rowChannel + 1;
    setFormValues(newFormValues);
  }

  let addFormFields = () => {
    setFormValues([...formValues, formFieldInitials])
  }
  const handleRSelectChannels = (frameCurrent) => {
    let Currentchannels = [...Allchannels];
    frameCurrent.map((farmeSetting, i) => {
      Currentchannels = Currentchannels.filter((c) => !farmeSetting.channel.map((e) => (e._id)).includes(c._id))
    })
    const OptionCurChannel = getOptionChannels(Currentchannels)
    setOptionChannels({ ...optionChannels, DEFAULT_CHANNELS: OptionCurChannel })
  }
  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    handleRSelectChannels(newFormValues);
  }

  const removeTimeFrame = (index, Type, i) => {
    let newFormValues = [...formValues];
    switch (Type) {
      case "Reels":
        newFormValues[index]["content"].Reels.time.splice(i, 1);
        newFormValues[index]["content"].Reels.num = newFormValues[index]["content"].Reels.num - 1;
        break;
      case "Video":
        newFormValues[index]["content"].Video.time.splice(i, 1);
        newFormValues[index]["content"].Video.num = newFormValues[index]["content"].Video.num - 1;
        break;
      case "Image":
        newFormValues[index]["content"].Image.time.splice(i, 1);
        newFormValues[index]["content"].Image.num = newFormValues[index]["content"].Image.num - 1;
        break;
      default:
    }
    newFormValues[index].rowChannel = newFormValues[index].rowChannel - 1;
    setFormValues(newFormValues);
  }
  const [InitialSettingChannel, setinitialSettingChannel] = useState([])
  const [modeChannelSelect, setModeChannelSelect] = useState(DEFAULT_CHANNELS)
  let handleFormSubmit = async (event) => {
    const projectId = userInfo.last_project_active;
    setIsUploading(() => true);
    const platformSpecial = [
      PLATFORM_TIKTOK,
      PLATFORM_YOUTUBE
    ]
    let data = { channels: [] };
    formValues.map((SettingChannel) => {
      const channelNomal = SettingChannel.channel.filter((c) => !platformSpecial.map((e) => (e)).includes(c.platform))
      const channelSpecial = SettingChannel.channel.filter((c) => platformSpecial.map((e) => (e)).includes(c.platform))
      if (channelNomal?.length > 0) {
        let setting = {
          ids: channelNomal.map((channel) => (channel._id)),
          setting: [
            {
              type: "video",
              times: SettingChannel.content.Video.time.map((time) => ({
                time: time.start.getHours() + "-" + time.end.getHours(),
                limit: parseInt(time.limit)
              }))
            },
            {
              type: "photo",
              times: SettingChannel.content.Image.time.map((time) => ({
                time: time.start.getHours() + "-" + time.end.getHours(),
                limit: parseInt(time.limit)
              }))
            },
            {
              type: "reel",
              times: SettingChannel.content.Reels.time.map((time) => ({
                time: time.start.getHours() + "-" + time.end.getHours(),
                limit: parseInt(time.limit)
              }))
            },

          ]
        }
        data.channels.push(setting);
      }
      if (channelSpecial?.length > 0) {
        let setting = {
          ids: channelSpecial.map((channel) => (channel._id)),
          setting: [
            {
              type: "video",
              times: SettingChannel.content.Video.time.map((time) => ({
                time: time.start.getHours() + "-" + time.end.getHours(),
                limit: parseInt(time.limit)
              }))
            },
            {
              type: "short",
              times: SettingChannel.content.Reels.time.map((time) => ({
                time: time.start.getHours() + "-" + time.end.getHours(),
                limit: parseInt(time.limit)
              }))
            },

          ]
        }
        data.channels.push(setting);
      }
    })
    data.channels.map((e, i) => {
      const newSetting = e.setting.filter((c) => (c.times.length > 0))
      data.channels[i].setting = [...newSetting];
    })
    await PostsmartSchedule(projectId, data).then(res => {
      if (res.status) {
        setIsUploading(() => false);
        // resetListProduct();
        setFormValues([]);
        setTimeout(() => {
          handleCancelSeeting();
        }, 3000);

        toast.success("Cập nhật lịch trình thông minh thành công!", {
          autoClose: 1000,
          position: "top-right",
        });
      }
      else {
        setIsUploading(() => false);
        toast.error("Cập nhật lịch trình thất bại!", {
          autoClose: 1000,
          position: "top-right",
        });
        console.log("error");
      }
    }).catch(error => {
      setIsUploading(() => false);
      toast.error("Cập nhật lịch trình thất bại!", {
        autoClose: 1000,
        position: "top-right",
      });
      console.log(error);
    });

  }
  /**
   * CHANNELS
   */
  const [optionChannels, setOptionChannels] = useState({
    ALL_CHANNELS: [],
    DEFAULT_CHANNELS: []
  });
  const [channels, setChannels] = useState([]);
  const optionChannelsEnd = useRef(false);
  const [listTopic, setListTopic] = useState([]);
  useEffect(() => {
    dispatch(setCollapseSidebarLeft(true));
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const getOptionChannels = (Currentchannels) => {
    const favoriteChannelIds = FavoriteChannels.map((c) => c._id);
    const favoriteChannels = Currentchannels.filter((c) => favoriteChannelIds.includes(c._id));
    const uniqueChannelPlatforms = [
      ...new Set(Currentchannels.filter((c) => !favoriteChannelIds.includes(c._id)).map((c) => c.platform)),
    ];

    const _optionChannels = [
      {
        label: "create_post.channel_favorite",
        options: favoriteChannels,
      },
      ...uniqueChannelPlatforms.map((channelPlatform) => ({
        label: `create_post.channel_${channelPlatform}`,
        options: Currentchannels.filter((c) => !favoriteChannelIds.includes(c._id) && c.platform === channelPlatform),
      })),
    ];
    return _optionChannels
  }
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfor"));
    if (userInfo.last_project_active && !optionChannelsEnd.current) {
      const projectId = userInfo.last_project_active;
      const data = {};
      const fetchData = async () => {
        return await getChannels(projectId, data);
      };
      fetchData()
        .then((response) => {
          if (response.status) {
            const { channel: channels, favorite_channel: _favoriteChannels } = response.data;
            setAllChannels([...channels]);
            setfavoriteChannel([..._favoriteChannels]);
            setFormValues([...formValues, formFieldInitials])
            const ListSetting = channels.filter((item) => (item.setting_distribute_time.length > 0))
            setinitialSettingChannel(ListSetting)
            let Currentchannels = [...channels];
            Currentchannels = Currentchannels.filter((c) => !Currentchannels.filter((item) => (item.setting_distribute_time.length > 0)).map((e) => (e._id)).includes(c._id))
            const OptionAllChannels = getOptionChannels(channels)
            const OptionDefaultChannels = getOptionChannels(Currentchannels)
            setOptionChannels(
              {
                ALL_CHANNELS: OptionAllChannels,
                DEFAULT_CHANNELS: OptionDefaultChannels
              })
            const presetChannelIds = params.getAll("channels");
            if (presetChannelIds.length > 0) {
              const _channels = channels.filter((channel) => presetChannelIds.includes(channel._id));
              setChannels(_channels);
            }
            optionChannelsEnd.current = true;
            const fetchData = async () => {
              return await getListGroupChannels(projectId);
            };
            fetchData()
              .then((groupChannelResponse) => {
                if (groupChannelResponse.status) {
                  let groupChannelData = groupChannelResponse.data.map((item, index) => {
                    const { channel_ids, name, _id } = item;
                    const listChannelItem = channel_ids.map(ids => channels.find(eleChannel => eleChannel._id === ids));
                    const listChannelItemFilter = listChannelItem.filter(item => item !== undefined);
                    return {
                      label: name,
                      value: _id,
                      channel: listChannelItemFilter
                    }
                  });
                  setListTopic(groupChannelData);
                }
              })
          }
        })
        .catch((error) => console.error(error));
    }
  }, [params]);

  const onChangeChannels = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i]["channel"] = e;
    const platformSpecial = [
      PLATFORM_TIKTOK,
      PLATFORM_YOUTUBE
    ]
    const channelNomal = e.filter((c) => !platformSpecial.map((e) => (e)).includes(c.platform))
    if (channelNomal.length === 0 && e.length > 0) {
      newFormValues[i]["content"].Image =
      {
        num: 1,
        time: [],
        isDisable: true
      }
      newFormValues[i]["content"].Video =
      {
        ...newFormValues[i]["content"].Video,
        time: newFormValues[i]["content"].Video.time.length > 0 ? newFormValues[i]["content"].Video.time : [{ start: 0, end: 0, limit: 1 }],
        num: newFormValues[i]["content"].Video.time.length > 0 ? newFormValues[i]["content"].Video.num : 2,
        isDisable: false
      }
      newFormValues[i]["content"].Reels =
      {
        ...newFormValues[i]["content"].Reels,
        time: newFormValues[i]["content"].Reels.time.length > 0 ? newFormValues[i]["content"].Reels.time : [{ start: 0, end: 0, limit: 1 }],
        num: newFormValues[i]["content"].Reels.time.length > 0 ? newFormValues[i]["content"].Reels.num : 2,
        isDisable: false
      }
      newFormValues[i].rowChannel = newFormValues[i]["content"].Image.num + newFormValues[i]["content"].Reels.num + newFormValues[i]["content"].Video.num

    }
    else {
      newFormValues[i]["content"].Image =
      {
        ...newFormValues[i]["content"].Image,
        time: newFormValues[i]["content"].Image.time.length > 0 ? newFormValues[i]["content"].Image.time : [{ start: 0, end: 0, limit: 1 }],
        num: newFormValues[i]["content"].Image.time.length > 0 ? newFormValues[i]["content"].Image.num : 2,
        isDisable: false
      }
      newFormValues[i]["content"].Video =
      {
        ...newFormValues[i]["content"].Video,
        time: newFormValues[i]["content"].Video.time.length > 0 ? newFormValues[i]["content"].Video.time : [{ start: 0, end: 0, limit: 1 }],
        num: newFormValues[i]["content"].Video.time.length > 0 ? newFormValues[i]["content"].Video.num : 2,
        isDisable: false
      }
      newFormValues[i]["content"].Reels =
      {
        ...newFormValues[i]["content"].Reels,
        time: newFormValues[i]["content"].Reels.time.length > 0 ? newFormValues[i]["content"].Reels.time : [{ start: 0, end: 0, limit: 1 }],
        num: newFormValues[i]["content"].Reels.time.length > 0 ? newFormValues[i]["content"].Reels.num : 2,
        isDisable: false
      }
      newFormValues[i].rowChannel = newFormValues[i]["content"].Image.num + newFormValues[i]["content"].Reels.num + newFormValues[i]["content"].Video.num
    }
    setFormValues(newFormValues);
    handleRSelectChannels(newFormValues);
  };

  const handleCancelSeeting = () => {
    let url = process.env.PUBLIC_URL + "/posts/settingchannel-list";
    window.history.pushState("", "", url);
    window.location.reload();
  }
  const MultiValueLabel = (props) => {
    const { data } = props,
      imgStyle = {
        flex: "0 0 14px",
        width: "14px",
        height: "14px",
        objectFit: "cover",
      },
      spanStyle = { paddingLeft: "7px" };
    return (
      <components.MultiValueLabel {...props}>
        <Img src={getPlatformIcon(data.platform)} alt={`entity-${data.platform}`} style={imgStyle} />
        <span style={spanStyle}>{data.name}</span>
      </components.MultiValueLabel>
    );
  };
  const GroupHeading = (props) => {
    const { data } = props;
    return <components.GroupHeading {...props}>{t(data.label)}</components.GroupHeading>;
  };
  const selectPlatformStyle = {
    multiValue: (base) => ({
      ...base,
      border: "1px solid #6A2C62",
      borderRadius: "100px !important",
      padding: "4px 11px",
      backgroundColor: "#f2f2f2 !important",
    }),
    multiValueLabel: (base) => ({
      ...base,
      display: "flex",
      alignItems: "center",
      padding: "0",
      paddingLeft: "0",
      fontSize: "12px",
      lineHeight: "1",
    }),
    multiValueRemove: (base) => ({
      ...base,
      marginLeft: "7px",
      padding: "0",
      lineHeight: "1",
    }),
  };
  const [isUploading, setIsUploading] = useState(false);

  const CustomScheduleSelector = forwardRef(({ value, onClick }, ref) => (
    <div className="btn border-gray-400" style={{ width: "91px", height: "37px", textAlign: "center" }} onClick={onClick} ref={ref} >
      {value}

    </div>
  ));

  const channelSelect = [
    { value: ALL_CHANNELS, label: "Toàn bộ kênh" },
    { value: DEFAULT_CHANNELS, label: "Có kênh chưa có lịch" },
  ]

  const CustomTimeInput = ({ value, onChange }) => (
    <input type="time" className="form-control" value={value} onChange={(e) => onChange(e.target.value)} />
  );

  const onClickLimitSetting = (index, i, Type, limit) => {
    let newFormValues = [...formValues];
    switch (Type) {
      case "Reels":
        newFormValues[index].content.Reels.time[i].limit = limit;
        break;
      case "Video":
        newFormValues[index].content.Video.time[i].limit = limit;
        break;
      case "Image":
        newFormValues[index].content.Image.time[i].limit = limit;
        break;
      default:
    }
    setFormValues(newFormValues);
  }
  const handleOptionChangeTypeChannel = (i, event) => {

    let newFormValues = [...formValues];
    newFormValues[i]["isGroupChannel"] = event.target.value;
    setFormValues(newFormValues)

  };

  /**
   * HANDLE SUBMIT
   */

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue
  } = useForm();

  const StyleInput = ({
    children,
    ...props
  }) => (
    <components.SingleValue {...props}>
      <div className={style.spanDropdown}>
        {children}
      </div>
    </components.SingleValue>
  );

  const CustomOption = ({ children, ...props }) => {
    const { data } = props,
      spanStyle = {
        fontSize: "12px",
        fontWeight: 500,
        color: "#526484",
        transition: "all 0.4s",
        lineHeight: "1.3rem"
      }
    return (
      <components.Option {...props}>
        <span style={spanStyle}>{data.label}</span>
      </components.Option>
    );
  };

  return (
    <React.Fragment>
      {/* <Head title={t('product.product')}></Head> */}
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>9 CALENDAR: Tự động đặt lịch thông minh</BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Card className="card-bordered" style={{ padding: "1rem 12px" }} >
          <Block>
            <Row className="g-gs">
              <Col md="12" className="d-flex justify-content-between">
                <div className="d-flex w-1/3">
                  <span className={`${style.createPostAllTitle} mr-2`}>Chế độ chọn kênh</span>
                  <div className="w-44">
                    <RSelect
                      options={channelSelect}
                      components={{
                        SingleValue: StyleInput,
                        Option: CustomOption,
                      }}
                      defaultValue={
                        channelSelect[1]
                      }
                      onChange={(e) => {
                        setModeChannelSelect(e.value)
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col md="12" style={{ overflow: "auto" }}>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                  <div className={`${mobileView ? style.overflow_scroll_x_table : ""} mb-4`}>
                    <table className={`table table-striped table-bordered table-reponsive postFastTable ${mobileView ? style.width_responsive_table : ""}`}>
                      <thead>
                        <tr>
                          <th style={{ width: "30%" }} className={`${style.headPost}`}>Chọn kênh</th>
                          <th style={{ width: "30%" }} className={`${style.headPost}`}>Loại nội dung</th>
                          <th style={{ width: "27%" }} className={`${style.headPost} text-center`}>Khung giờ</th>
                          <th style={{ width: "10%" }} className={`${style.headPost} text-center`}>Số lượng</th>
                          <th style={{ width: "3%" }} className={`${style.headPost} text-center`}>Thao tác</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formValues.map((element, index) => (
                          <>
                            <tr key={index} className="text-center">
                              <td rowSpan={`${element.rowChannel}`}>
                                <FormGroup>
                                  <div className="d-flex justify-content-between">
                                    <div>
                                      <label className="mr-2" style={{ color: "#000000" }}>
                                        <input
                                          name={`typechannel_${index}`}
                                          type="radio"
                                          value="channel"
                                          className="mr-1"
                                          checked={element.isGroupChannel === "channel"}
                                          onChange={(e) => handleOptionChangeTypeChannel(index, e)}
                                        />
                                        Kênh
                                      </label>

                                      <label style={{ color: "#000000" }}>
                                        <input
                                          name={`typechannel_${index}`}
                                          type="radio"
                                          value="topic"
                                          className="mr-1"
                                          checked={element.isGroupChannel === "topic"}
                                          onChange={(e) => handleOptionChangeTypeChannel(index, e)}
                                        />
                                        Nhóm kênh
                                      </label>
                                      {/* Selection box  */}
                                      {element.isGroupChannel === "topic" && (
                                        <div style={{ marginBottom: ".5rem" }}>
                                          <RSelect
                                            options={listTopic}
                                            placeholder="Chọn nhóm kênh"
                                            isMulti
                                            onChange={(e) => {
                                              let listChannelSelected = [];
                                              e.map((item) => {
                                                const listChannelItem = listTopic.find(
                                                  (i) => i.value === item.value
                                                )?.channel || [];
                                                listChannelSelected = [...listChannelSelected, ...listChannelItem]
                                              })
                                              listChannelSelected = unique(listChannelSelected)
                                              if (modeChannelSelect === DEFAULT_CHANNELS) {
                                                listChannelSelected = listChannelSelected.filter((c) => !listChannelSelected.filter((item) => (item.setting_distribute_time.length > 0)))

                                              }
                                              onChangeChannels(index, listChannelSelected);
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="form-control-wrap">
                                    <Controller
                                      name={`channel_${index}`}
                                      control={control}
                                      rules={element.channel.length > 0 ? { required: false } : { required: "Bạn cần chọn kênh cài đặt" }}
                                      render={({ field: { onBlur, onChange } }) => (
                                        <RSelect
                                          closeMenuOnSelect={false}
                                          components={{
                                            MultiValueLabel,
                                            GroupHeading,
                                          }}
                                          value={formValues[index].channel}
                                          isMulti
                                          getOptionLabel={(v) => v.name}
                                          getOptionValue={(v) => v._id}
                                          onChange={(e) => {
                                            onChange(e);
                                            onChangeChannels(index, e);
                                          }}
                                          className="customChannelRSelect"
                                          options={
                                            modeChannelSelect === DEFAULT_CHANNELS ?
                                              optionChannels.DEFAULT_CHANNELS : optionChannels.ALL_CHANNELS
                                          }
                                          styles={selectPlatformStyle}
                                          onBlur={onBlur}
                                        />
                                      )}
                                    />
                                  </div>
                                  {errors && errors[`channel_${index}`] && <FormText color="danger">{errors[`channel_${index}`]?.message}</FormText>}
                                </FormGroup>
                                <FormGroup style={{ textAlign: "start" }}>

                                  <Icon name="trash-fill" style={{ cursor: "pointer", fontSize: "20px", color: "red" }} onClick={() => {
                                    removeFormFields(index);
                                  }} />
                                  <span className={`${style.deleteTittle}`}>Xóa dòng</span>
                                </FormGroup>
                              </td>
                              <td className={`${style.typeContent}`} rowSpan={`${element.content.Reels.num}`}>
                                {optionContentType[0].label}
                              </td>
                              <td className={`${style.addTime} ${element.content.Reels.isDisable && style.disabled}`} colspan="3" onClick={() => addTimeFrame(index, "Reels")}>+ Thêm khung giờ</td>
                            </tr>
                            {element.content.Reels.time.map((e, i) => (
                              <tr>
                                <td>
                                  <div className="d-flex justify-between">
                                    <FormGroup>
                                      <Controller
                                        name={`timeReelsStart_${index}_${i}`}
                                        control={control}
                                        rules={{ required: "Chọn giờ start" }}
                                        render={({ field: { ref, ...field } }) => (
                                          <DatePicker
                                            {...field}
                                            selected={formValues[index].content.Reels.time[i].start ?? null}
                                            //showTimeInput
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={60}
                                            minTime={new Date(0, 0, 0, 0, 0)}
                                            maxTime={formValues[index].content.Reels.time[i].end ? new Date(0, 0, 0, formValues[index].content.Reels.time[i].end.getHours() - 1, 0) : new Date(0, 0, 0, 23, 0)}
                                            onChange={(date) => {
                                              field.onChange(date)
                                              let newFormValues = [...formValues];
                                              newFormValues[index].content.Reels.time[i].start = date;
                                              setFormValues(newFormValues);
                                            }}
                                            customInput={<CustomScheduleSelector />}
                                            customTimeInput={<CustomTimeInput />}
                                            dateFormat={"HH:mm"}
                                            locale={i18n.language}
                                          />
                                        )}
                                      />
                                      {errors && errors[`timeReelsStart_${index}_${i}`] && <FormText color="danger">{errors[`timeReelsStart_${index}_${i}`]?.message}</FormText>}
                                    </FormGroup>
                                    <FormGroup>
                                      <Controller
                                        name={`timeReelsEnd_${index}_${i}`}
                                        control={control}
                                        rules={{ required: "Chọn giờ end" }}
                                        render={({ field: { ref, ...field } }) => (
                                          <DatePicker
                                            {...field}
                                            selected={formValues[index].content.Reels.time[i].end ?? null}
                                            //showTimeInput
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={60}
                                            minTime={formValues[index].content.Reels.time[i].start ? new Date(0, 0, 0, formValues[index].content.Reels.time[i].start.getHours() + 1, 0) : new Date(0, 0, 0, 0, 0)}
                                            maxTime={new Date(0, 0, 0, 23, 0)}
                                            onChange={(date) => {
                                              field.onChange(date)
                                              let newFormValues = [...formValues];
                                              newFormValues[index].content.Reels.time[i].end = date;
                                              setFormValues(newFormValues);
                                            }}
                                            customInput={<CustomScheduleSelector />}
                                            customTimeInput={<CustomTimeInput />}
                                            dateFormat={"HH:mm"}
                                            locale={i18n.language}
                                          />
                                        )}
                                      />
                                      {errors && errors[`timeReelsEnd_${index}_${i}`] && <FormText color="danger">{errors[`timeReelsEnd_${index}_${i}`]?.message}</FormText>}
                                    </FormGroup>
                                  </div>
                                </td>
                                <td>
                                  <FormGroup>
                                    <Controller
                                      name={`inputReels_${index}_${i}`}
                                      control={control}
                                      rules={element.content.Reels.time[i].limit ? { required: false } : { required: "Chọn số bài" }}
                                      render={({ field: { ref, ...field } }) => (
                                        <CurrencyInput
                                          {...field}
                                          id="input-example"
                                          name="Reels"
                                          placeholder="Số Bài"
                                          allowDecimals={false}
                                          allowNegativeValue={false}
                                          value={formValues[index].content.Reels?.time[i].limit === "0" ? "1" : formValues[index].content.Reels?.time[i].limit}
                                          className="btn border-gray-400" style={{ width: "80px", height: "37px", textAlign: "center", marginRight: "60px" }}
                                          maxLength={4}
                                          step={1}
                                          onValueChange={(value, name) => {
                                            field.onChange(value)
                                            if (value === "0") {
                                              onClickLimitSetting(index, i, name, "1")
                                            }
                                            onClickLimitSetting(index, i, name, value)
                                          }}
                                        />
                                      )}
                                    />
                                    {errors && errors[`inputReels_${index}_${i}`] && <FormText color="danger">{errors[`inputReels_${index}_${i}`]?.message}</FormText>}
                                  </FormGroup>

                                </td>
                                <td className="text-center">
                                  {
                                    i !== 0 && <FormGroup>

                                      <Icon name="trash" style={{ cursor: "pointer", fontSize: "20px" }} onClick={() => {
                                        removeTimeFrame(index, "Reels", i);

                                      }} />
                                    </FormGroup>
                                  }

                                </td>
                              </tr>
                            ))}
                            <tr className="text-center">

                              <td className={`${style.typeContent}`} rowSpan={`${element.content.Video.num}`}>
                                {optionContentType[1].label}
                              </td>
                              <td className={`${style.addTime} ${element.content.Video.isDisable && style.disabled}`} colspan="3" onClick={() => addTimeFrame(index, "Video")}>+ Thêm khung giờ</td>
                            </tr>
                            {element.content.Video.time.map((e, i) => (
                              <tr>
                                <td>
                                  <div className="d-flex justify-between">
                                    <FormGroup>
                                      <Controller
                                        name={`timeVideoStart_${index}_${i}`}
                                        control={control}
                                        rules={{ required: "Chọn giờ start" }}
                                        render={({ field: { ref, ...field } }) => (
                                          <DatePicker
                                            {...field}
                                            selected={formValues[index].content.Video.time[i].start ?? null}
                                            //showTimeInput
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={60}
                                            minTime={new Date(0, 0, 0, 0, 0)}
                                            maxTime={formValues[index].content.Video.time[i].end ? new Date(0, 0, 0, formValues[index].content.Video.time[i].end.getHours() - 1, 0) : new Date(0, 0, 0, 23, 0)}
                                            onChange={(date) => {
                                              field.onChange(date)
                                              let newFormValues = [...formValues];
                                              newFormValues[index].content.Video.time[i].start = date;
                                              setFormValues(newFormValues);
                                            }}
                                            customInput={<CustomScheduleSelector />}
                                            customTimeInput={<CustomTimeInput />}
                                            dateFormat={"HH:mm"}
                                            locale={i18n.language}
                                          />
                                        )} />
                                      {errors && errors[`timeVideoStart_${index}_${i}`] && <FormText color="danger">{errors[`timeVideoStart_${index}_${i}`]?.message}</FormText>}
                                    </FormGroup>
                                    <FormGroup>
                                      <Controller
                                        name={`timeVideoEnd_${index}_${i}`}
                                        control={control}
                                        rules={{ required: "Chọn giờ end" }}
                                        render={({ field: { ref, ...field } }) => (
                                          <DatePicker
                                            {...field}
                                            selected={formValues[index].content.Video.time[i].end ?? null}
                                            //showTimeInput
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={60}
                                            minTime={formValues[index].content.Video.time[i].start ? new Date(0, 0, 0, formValues[index].content.Video.time[i].start.getHours() + 1, 0) : new Date(0, 0, 0, 0, 0)}
                                            maxTime={new Date(0, 0, 0, 23, 0)}
                                            onChange={(date) => {
                                              field.onChange(date)
                                              let newFormValues = [...formValues];
                                              newFormValues[index].content.Video.time[i].end = date;
                                              setFormValues(newFormValues);
                                            }}
                                            customInput={<CustomScheduleSelector />}
                                            customTimeInput={<CustomTimeInput />}
                                            dateFormat={"HH:mm"}
                                            locale={i18n.language}
                                          />
                                        )} />
                                      {errors && errors[`timeVideoEnd_${index}_${i}`] && <FormText color="danger">{errors[`timeVideoEnd_${index}_${i}`]?.message}</FormText>}
                                    </FormGroup>
                                  </div>
                                </td>

                                <td>
                                  <FormGroup>
                                    <Controller
                                      name={`inputVideo_${index}_${i}`}
                                      control={control}
                                      rules={element.content.Video.time[i].limit ? { required: false } : { required: "Chọn số bài" }}
                                      render={({ field: { ref, ...field } }) => (
                                        <CurrencyInput
                                          {...field}
                                          id="input-example"
                                          name="Video"
                                          placeholder="Số Bài"
                                          allowDecimals={false}
                                          allowNegativeValue={false}
                                          //defaultValue={1}
                                          value={formValues[index].content.Video?.time[i].limit === "0" ? "1" : formValues[index].content.Video?.time[i].limit}
                                          className="btn border-gray-400" style={{ width: "80px", height: "37px", textAlign: "center", marginRight: "60px" }}
                                          maxLength={4}
                                          step={1}
                                          onValueChange={(value, name) => {
                                            field.onChange(value)
                                            if (value === "0") {
                                              onClickLimitSetting(index, i, name, "1")
                                            }
                                            onClickLimitSetting(index, i, name, value)
                                          }}
                                        />
                                      )}
                                    />
                                    {errors && errors[`inputVideo_${index}_${i}`] && <FormText color="danger">{errors[`inputVideo_${index}_${i}`]?.message}</FormText>}
                                  </FormGroup>

                                </td>
                                <td className="text-center">
                                  {i !== 0 &&
                                    <FormGroup>

                                      <Icon name="trash" style={{ cursor: "pointer", fontSize: "20px" }} onClick={() => {
                                        removeTimeFrame(index, "Video", i);

                                      }} />
                                    </FormGroup>}

                                </td>
                              </tr>
                            ))}
                            <tr className="text-center">

                              <td className={`${style.typeContent}`} rowSpan={`${element.content.Image.num}`}>
                                {optionContentType[2].label}
                              </td>
                              <td className={`${style.addTime} ${element.content.Image.isDisable && style.disabled}`} colspan="3" onClick={() => addTimeFrame(index, "Image")}>+ Thêm khung giờ</td>
                            </tr>
                            {element.content.Image.time.map((e, i) => (
                              <tr>
                                <td>
                                  <div className="d-flex justify-between">
                                    <FormGroup>
                                      <Controller
                                        name={`timeImageStart_${index}_${i}`}
                                        control={control}
                                        rules={{ required: "Chọn giờ start" }}
                                        render={({ field: { ref, ...field } }) => (
                                          <DatePicker
                                            {...field}
                                            selected={formValues[index].content.Image.time[i].start ?? null}
                                            //showTimeInput
                                            showTimeSelect
                                            showTimeSelectOnly
                                            minTime={new Date(0, 0, 0, 0, 0)}
                                            maxTime={formValues[index].content.Image.time[i].end ? new Date(0, 0, 0, formValues[index].content.Image.time[i].end.getHours() - 1, 0) : new Date(0, 0, 0, 23, 0)}
                                            timeIntervals={60}
                                            onChange={(date) => {
                                              field.onChange(date)
                                              let newFormValues = [...formValues];
                                              newFormValues[index].content.Image.time[i].start = date;
                                              setFormValues(newFormValues);
                                            }}
                                            customInput={<CustomScheduleSelector />}
                                            customTimeInput={<CustomTimeInput />}
                                            dateFormat={"HH:mm"}
                                            locale={i18n.language}
                                          />
                                        )} />
                                      {errors && errors[`timeImageStart_${index}_${i}`] && <FormText color="danger">{errors[`timeImageStart_${index}_${i}`]?.message}</FormText>}
                                    </FormGroup>
                                    <FormGroup>
                                      <Controller
                                        name={`timeImageEnd_${index}_${i}`}
                                        control={control}
                                        rules={{ required: "Chọn giờ end" }}
                                        render={({ field: { ref, ...field } }) => (
                                          <DatePicker
                                            {...field}
                                            selected={formValues[index].content.Image.time[i].end ?? null}
                                            //showTimeInput

                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={60}
                                            minTime={formValues[index].content.Image.time[i].start ? new Date(0, 0, 0, formValues[index].content.Image.time[i].start.getHours() + 1, 0) : new Date(0, 0, 0, 0, 0)}
                                            maxTime={new Date(0, 0, 0, 23, 0)}
                                            onChange={(date) => {
                                              field.onChange(date)
                                              let newFormValues = [...formValues];
                                              newFormValues[index].content.Image.time[i].end = date;
                                              setFormValues(newFormValues);
                                            }}
                                            customInput={<CustomScheduleSelector />}
                                            customTimeInput={<CustomTimeInput />}
                                            dateFormat={"HH:mm"}
                                            locale={i18n.language}
                                          />
                                        )} />
                                      {errors && errors[`timeImageEnd_${index}_${i}`] && <FormText color="danger">{errors[`timeImageEnd_${index}_${i}`]?.message}</FormText>}
                                    </FormGroup>
                                  </div>
                                </td>
                                <td>
                                  <FormGroup>
                                    <Controller
                                      name={`inputImage_${index}_${i}`}
                                      control={control}
                                      rules={element.content.Image.time[i].limit ? { required: false } : { required: "Chọn số bài" }}
                                      render={({ field: { ref, ...field } }) => (
                                        <CurrencyInput
                                          {...field}
                                          id="input-example"
                                          name="Image"
                                          placeholder="Số Bài"
                                          allowDecimals={false}
                                          allowNegativeValue={false}
                                          //defaultValue={1}
                                          value={formValues[index].content.Image?.time[i].limit === "0" ? "1" : formValues[index].content.Image?.time[i].limit}
                                          className="btn border-gray-400" style={{ width: "80px", height: "37px", textAlign: "center", marginRight: "60px" }}
                                          maxLength={4}
                                          step={1}
                                          onValueChange={(value, name) => {
                                            field.onChange(value)
                                            if (value === "0") {
                                              onClickLimitSetting(index, i, name, "1")
                                            }
                                            onClickLimitSetting(index, i, name, value)
                                          }}
                                        />
                                      )}
                                    />
                                    {errors && errors[`inputImage_${index}_${i}`] && <FormText color="danger">{errors[`inputImage_${index}_${i}`]?.message}</FormText>}
                                  </FormGroup>

                                </td>
                                <td className="text-center">
                                  {i !== 0 && <FormGroup>

                                    <Icon name="trash" style={{ cursor: "pointer", fontSize: "20px" }} onClick={() => {
                                      removeTimeFrame(index, "Image", i);

                                    }} />
                                  </FormGroup>}

                                </td>
                              </tr>
                            ))}
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {/* <div className={`${style.setUp} mb-2`} >
                    <span>Số Kênh đã Setup: {numberOfUnsettingChannel || 0}/{Allchannels.length}</span>
                  </div> */}
                  <div className={`${style.noteTitle}`}>
                    <span>Lưu ý quan trọng: Nếu trang bạn chọn khi đăng bài chưa được cài đặt Lịch tự động thì không thể sử dụng smart schedule.</span>
                  </div>

                  <div className={`${style.utilBtn}`}>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        addFormFields();
                      }}
                      className={`${style.btnUtilAdd}`}>Thêm dòng</button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handleCancelSeeting();
                      }}
                      className={`${style.btnUtilCancel} `}>Hủy thay đổi</button>
                    <button
                      type="submit"
                      disabled={isUploading}
                      className={`${style.btnUtilPost}`}>
                      {
                        isUploading ? <Spinner size="sm" color="light" /> : <span>Lưu lại</span>
                      }
                    </button>
                  </div>
                </form>
              </Col>

            </Row>
          </Block>
        </Card>
      </Content>
    </React.Fragment>
  );
};
export default PostFast;