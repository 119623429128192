import React, { useMemo } from "react";
import {
  ARR_POST_STATUS_POST,
  MEDIA_STATUS_ERROR_HAVING_URL,
  MEDIA_STATUS_ERROR_NOT_URL,
  POST_STATUS_DEFAULT,
  POST_STATUS_HIDE,
  POST_STATUS_MEDIA_ERROR,
  POST_STATUS_PROCESS,
  POST_STATUS_PROCESSING_SCHEDULED_ERROR,
  POST_STATUS_PROCESSING_SCHEDULED_PENDING,
  POST_STATUS_PROJECT_EXPIRED,
  POST_STATUS_PUBLISH_ERROR,
  POST_STATUS_PUBLISH_SUCCESS,
  POST_STATUS_REMOVE,
  POST_STATUS_SCHEDULED,
  POST_STATUS_WAIT_SCHEDULED,
  STATUS_COMPRESS_ERROR,
} from "../../constants/posts";
import styles from "./PostDetail.module.scss";
import { useTranslation } from "react-i18next";

const Tag = ({ status }) => {
  const { t } = useTranslation();
  const statusTag = useMemo(() => {
    if (status === POST_STATUS_WAIT_SCHEDULED) return "wait";
    if (status === POST_STATUS_PROCESSING_SCHEDULED_PENDING)
      return "schedulePending";
    if (status === POST_STATUS_PROCESSING_SCHEDULED_ERROR)
      return "error";
    if (status === POST_STATUS_PROJECT_EXPIRED) return "expried";
    if (status === POST_STATUS_SCHEDULED) return "schedule";
    if (status === POST_STATUS_PUBLISH_SUCCESS) return "success";
    if (status === POST_STATUS_PUBLISH_ERROR) return "error";
    if (status === POST_STATUS_PROCESS) return "process";
    if (status === POST_STATUS_HIDE) return "hide";
    if (status === POST_STATUS_REMOVE) return "error";
    if (status === POST_STATUS_DEFAULT) return "default";
    if (status === MEDIA_STATUS_ERROR_HAVING_URL) return "error";
    if (status === MEDIA_STATUS_ERROR_NOT_URL) return "error";
    if (status === STATUS_COMPRESS_ERROR) return "error";
    if (status === POST_STATUS_MEDIA_ERROR) return "error";
  }, [status]);

  return (
    <div className={`${styles[statusTag]} ${styles.textStatus}`}>
      {t(ARR_POST_STATUS_POST[status])}
    </div>
  );
};

export default Tag;
