import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import style from "./index.module.scss";
import { Badge, Col, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { Icon } from "../../../components/Component";
import ModalEdit from "./ModalEdit";
import moment from "moment";
import { deleteCampaign, editCampaign } from "../../../api/campaign";
import { toastSuccess } from "../../../libs/toasts";

const Container = (props) => {
  const {
    data,
    toggleModal,
    modal,
    loading,
    refetch,
    toggleSuccess,
    setMessage,
  } = props;
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const [ldDel, setLdDel] = useState({});
  const [ldStatus, setLdStatus] = useState({});
  const [id, setId] = useState("");
  const [mobileView, setMobileView] = useState(null);

  const convertType = (type) => {
    switch (type) {
      case "video":
        return "Video thường";
      case "reel":
        return "Video Ngắn";
      case "photo":
        return "Bài ảnh";
      default:
        return type; // Giữ nguyên giá trị nếu không phù hợp với các trường hợp trên.
    }
  };
  const viewChange = () => {
    if (window.innerWidth < 1600) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };
  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []);

  useEffect(() => {
    setLdStatus(data.map((item) => item.status === 1));
  }, [data]);

  const handleDelete = async (item, index) => {
    setLdDel((ids) => ({
      ...ids,
      [index]: true,
    }));
    try {
      const res = await deleteCampaign(userInfo.last_project_active, item._id);
      if (res?.status) {
        toastSuccess("Xóa bài đăng thành công");
        refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLdDel((ids) => ({
        ...ids,
        [index]: false,
      }));
    }
  };

  const handleChangeStatus = async (item, index) => {
    setLdStatus((ids) => ({
      ...ids,
      [index]: !ldStatus[index],
    }));
    try {
      const res = await editCampaign(userInfo.last_project_active, item?._id, {
        status: item?.status ? 0 : 1,
      });
      if (res?.status) {
        setMessage("Cập nhật trạng thái thành công");
        refetch();
        toggleSuccess();
      } else {
        setLdStatus((ids) => ({
          ...ids,
          [index]: !ldStatus[index],
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  return (
    <Fragment>
      <table className={`table `}>
        <thead style={{ background: "#EBEAED" }}>
          <tr>
            <th
              style={{ width: "5%" }}
              className={`${style.headPost} text-center py-2`}
            ></th>
            <th style={{ width: "35%" }} className={`${style.headPost} py-2`}>
              Chiến dịch
            </th>
            <th style={{ width: "20%" }} className={`${style.headPost} py-2`}>
              Loại chiến dịch
            </th>
            <th style={{ width: "20%" }} className={`${style.headPost} py-2`}>
              Bài đăng
            </th>
            <th style={{ width: "10%" }} className={`${style.headPost} py-2 `}>
              Trang phân phối
            </th>

            <th
              style={{ width: "10%" }}
              className={`${style.headPost} py-2 text-center`}
            ></th>
          </tr>
        </thead>
        <tbody style={{ backgroundColor: "#fff" }}>
          {!loading &&
            data?.map((item, index) => (
              <tr
                key={index}
                style={{
                  fontSize: "12px",
                  cursor: "pointer",
                  transition: "background-color 0.1s ease",
                  color: !item?.status && "#9CA3AF",
                }}
                className={`${style.hoveredRow}`}
                // onClick={() => setDetailPost(post)}
              >
                <td className={` ${style.Td}`}>
                  <div className="preview-block">
                    <div className="custom-control custom-control-sm custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input form-control"
                        checked={ldStatus[index] || false}
                        onChange={() => handleChangeStatus(item, index)}
                        id={`check_${item?._id}`}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`check_${item?._id}`}
                      ></label>
                    </div>
                  </div>
                </td>
                <td
                  className={`${style.truncateCell} ${style.Td}`}
                  style={{
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    setId(item?._id);
                    toggleModal("edit");
                  }}
                >
                  <span>{item?.name}</span>
                  <div>
                    {ldStatus[index] ? (
                      <Badge color="success" pill>
                        <Icon name="dot" />
                        Active
                      </Badge>
                    ) : (
                      <Badge
                        color="light"
                        style={{
                          color: "#9CA3AF",
                        }}
                        pill
                      >
                        <Icon name="dot" />
                        Inactive
                      </Badge>
                    )}
                  </div>
                </td>
                <td
                  className={`${style.truncateCell} ${style.Td}`}
                  style={{
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    setId(item?._id);
                    toggleModal("edit");
                  }}
                >
                  {convertType(item?.type)}
                </td>
                <td
                  className={`${style.truncateCell} ${style.Td}`}
                  onClick={() => {
                    setId(item?._id);
                    toggleModal("edit");
                  }}
                  id="last_run"
                >
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {item?.post_info?.statistic?.total} Bài
                  </span>
                  <div>
                    Chạy gần nhất: {moment(item?.updated_at).format("HH:mm")}{" "}
                    {moment(item?.updated_at).format("DD/MM")}
                  </div>
                  <UncontrolledTooltip
                    autohide={false}
                    placement={"left"}
                    target={"last_run"}
                    innerClassName="tooltip_alert_driver"
                  >
                    <div className="p-2" style={{ textAlign: "start" }}>
                      Hệ thống tự động chia bài đăng lên các kênh trong chiến
                      dịch 1 lần / ngày vào thời điểm này. Khi chia hệ thống sẽ
                      kiểm tra danh sách bài hẹn giờ đăng trong ngày hôm đó của
                      kênh - Nếu chưa đủ bài sẽ đăng sẽ tự động lấy bài trong
                      kho nội dung để lên lịch theo Cài đặt lịch tự động của
                      bạn. Trong trường hợp bạn đã lên đủ bài hôm đó, chiến dịch
                      sẽ tự động nhận biết và không chia.
                    </div>
                  </UncontrolledTooltip>
                </td>
                <td
                  className={`${style.truncateCell} ${style.Td}`}
                  onClick={() => {
                    setId(item?._id);
                    toggleModal("edit");
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {item?.channel_info?.ids.length} Trang
                  </span>
                </td>
                <td
                  className={`${style.truncateCell} ${style.Td} text-center`}
                  style={{
                    fontWeight: "bold",
                  }}
                  // onClick={() => handleViewDetailPost({ item: post })}
                >
                  <div
                    className="d-flex justify-content-center"
                    style={{ gap: "1rem", fontSize: "1rem" }}
                  >
                    <Icon
                      name="edit"
                      onClick={() => {
                        setId(item?._id);
                        toggleModal("edit");
                      }}
                    />
                    {ldDel[index] ? (
                      <Spinner size="sm" />
                    ) : (
                      <Icon
                        name="trash"
                        onClick={() => handleDelete(item, index)}
                      />
                    )}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {loading && (
        <div className="d-flex justify-content-center w-100">
          <Spinner />
        </div>
      )}

      <ModalEdit
        modal={modal.edit}
        toggle={() => toggleModal("edit")}
        id={id}
        refetch={refetch}
        setMessage={setMessage}
        toggleSuccess={toggleSuccess}
      />
    </Fragment>
  );
};

export default Container;
