import React from "react";

import BlockDataCommonItem from "../../CommonItem/BlockDataCommonItem";

import { ChartType } from "../../constants";

const YoutubeOverview = ({ data, isLoading }) => {
  const dataChartView = [
    {
      name: "",
      data: data?.view_count?.dataSet?.map((d) => d?.y),
    },
  ];

  const dataChartLike = [
    {
      name: "",
      data: data?.like_count?.dataSet?.map((d) => d?.y),
    },
  ];

  const dataChartShare = [
    {
      name: "",
      data: data?.share_count?.dataSet?.map((d) => d?.y),
    },
  ];

  const dataChartComent = [
    {
      name: "",
      data: data?.comment_count?.dataSet?.map((d) => d?.y),
    },
  ];

  return (
    <>
      <BlockDataCommonItem
        isLoading={isLoading}
        title="Views"
        dataValue={data?.view_count?.value}
        hasChart={true}
        chartType={ChartType.LINE}
        dataChart={dataChartView}
        classIcon="ri-eye-fill"
        colorIcon="#4B38B3"
      />

      <BlockDataCommonItem
        isLoading={isLoading}
        title="Like"
        dataValue={data?.like_count?.value}
        hasChart={true}
        chartType={ChartType.LINE}
        dataChart={dataChartLike}
        classIcon="ri-heart-3-fill"
        colorIcon="#F06548"
        // tooltipLabel={`Số lượt tương tác = Like + Bình luận + Chia sẻ
        // Like: Tổng số lượt Thích video
        // Bình luận: Tổng Bình luận vào video
        // Share: Số lần video được chia sẻ
        // (Số Chia sẻ được hiển thị ở đây tương ứng với số lượt chia sẻ hiển thị trong tab
        // Dành cho bạn trên Ứng dụng điện thoại: Số này bao gồm cả lượt chia sẻ ra bên ngoài
        // hoặc chia sẻ nội bộ thông qua việc sử dụng nút "Chia sẻ cho bạn bè" trên tiktok.
        // Ví dụ: Nếu video được chia sẻ ra ngoài 30 lần và chia sẻ cho bạn bè trên ứng dụng tiktok 20 lần,
        // thì số liệu hiển thị ở đây là 50).`}
        // tooltipId={`PageReach_2`}
      />
      <BlockDataCommonItem
        isLoading={isLoading}
        title="Share"
        hasChart={true}
        dataValue={data?.share_count?.value}
        chartType={ChartType.LINE}
        dataChart={dataChartShare}
        pieChartLabelDefault={["Tự nhiên", "Trả phí"]}
        classIcon="ri-share-forward-fill"
        colorIcon="#F7B84B"
        // tooltipLabel={`Thời gian xem trung bình:
        // Thời gian này được tính bằng Trung bình thời gian xem các video được
        // đăng lên trong khoảng thời gian đang được lọc và các điều kiện khác nếu bạn thực hiện lọc.`}
        // tooltipId={`PageReach_3`}
      />

      <BlockDataCommonItem
        isLoading={isLoading}
        title="Comment"
        hasChart={true}
        dataValue={data?.comment_count?.value}
        chartType={ChartType.LINE}
        dataChart={dataChartComent}
        pieChartLabelDefault={["Tự nhiên", "Trả phí"]}
        classIcon="ri-message-2-line"
        colorIcon="#4B38B3"
      />
    </>
  );
};

export default YoutubeOverview;
