import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { Icon, Img, RSelect } from "../../../components/Component";
import style from "./index.module.scss";
import LogoDriver from "../../images/icons/logo-driver.png";
import { Controller, useForm } from "react-hook-form";
import {
  MAX_POST_UPLOAD,
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_VIDEO,
} from "../../utils/Utils";
import ContentOriginal from "../CreatePost/ContentOriginal";
import ModalDriveUpload from "../CreatePost/ModalDriveUpload";
// import ModalDriveUpload from "./ModalDriveUpload";

import { connectDrive, getMailDrive } from "../../../api/createPost";
import ModalWarning from "../../components/Modal/ModalWarning";
import MediaItem from "./MediaItem";
import ModalNoteDrive from "./ModalNoteDrive";
import { createTopic, createWarehouse } from "../../../api/warehouse";
import { LoadingItem } from "../Dashboard_v2/CommonItem/LoadingItem";
import { uploadMediaImageDrive } from "../../../libs/helper";
import { getDomainApp } from "../../../utils/Utils";

const ModalAdd = (props) => {
  const {
    toggleModal,
    modal,
    toggle,
    refetch,
    toggleSuccess,
    setMessage,
    optionTopic,
    refetchTopic,
  } = props;
  const optionMediaType = [
    {
      label: "Ảnh",
      value: "photo",
    },
    {
      label: "Video",
      value: "video",
    },
    {
      label: "Video Ngắn",
      value: "reel",
    },
  ];

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const initForm = {
    images: [],
    videos: [],
    topic: "",
    shuffle_content: [],
    content: "",
    is_content_warehouse: false,
    type: null,
  };
  const [formData, setFormData] = useState(initForm);
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const [countShuffleContent, setCountShuffleContent] = useState(1);
  const [modalDriveUpload, setModalDriveUpload] = useState(false);
  const [modalNoteDrive, setModalNoteDrive] = useState(false);
  const [warningTextProject, setWarningTextProject] = useState("");
  const [modalWarningProject, setModalWarningProject] = useState(false);
  const [driver, setDriver] = useState(null);
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(false);

  const getConfigDrive = async () => {
    const data = {};
    return await getMailDrive(userInfo.last_project_active, data).then(
      (resp) => {
        if (resp.status === true && resp.data) {
          setDriver(resp.data);
          return resp.data;
        } else {
          return {};
        }
      }
    );
  };

  const [zIndexParent, setZIndexParent] = useState();
  const [isLoadingFile, setIsLoadingFile] = useState(false);

  useEffect(() => {
    getConfigDrive();
  }, [userInfo.last_project_active]);
  const connectToDrive = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfor"));
    const projectId = userInfo.last_project_active;
    const domain = getDomainApp();
    const urlRedirect = `${domain}/posts/create?drive=true`;
    const data = {};
    connectDrive(projectId, urlRedirect, data).then((resp) => {
      if (resp.status) {
        window.location.href = resp.data;
      } else {
        setWarningTextProject(resp.message);
        setModalWarningProject(true);
      }
    });
  };
  const toggleWarningProject = () => {
    setModalWarningProject(!modalWarningProject);
  };
  const onFormConnectHandle = () => {
    if (driver !== null && driver?.length !== 0) {
      setModalDriveUpload(true);
    } else {
      setModalNoteDrive(true);
    }
  };

  useEffect(() => {
    if (modal) {
      setFormData(initForm);
      setMedia([]);
      setCountShuffleContent(1);
    }
  }, [modal]);

  useEffect(() => {
    setMedia([]);
    setFormData((prevFormData) => ({
      ...prevFormData,
      images: [],
      videos: [],
    }));
  }, [formData.type]);

  const handleFileUploadDriveMulti = (driveFiles) => {
    const newLinePost = driveFiles.map((fileItem, index) => {
      setValue(
        `media${Array.isArray(formData) ? "_" + formData.length + index : ""}`,
        fileItem
      );
      setMedia((prevMedia) => {
        if (formData.type === "video") {
          return [driveFiles[0]];
        } else {
          // Kiểm tra xem id đã tồn tại trong mảng prevMedia chưa
          const ids = prevMedia.map((item) => item.id);
          const filteredFiles = driveFiles.filter(
            (item) => !ids.includes(item.id)
          );

          // Thêm những file mới vào mảng prevMedia
          return [...prevMedia, ...filteredFiles];
        }
      });

      const newLine = fileItem;
      return newLine;
    });

    let newFormValues = [...formData.images, ...formData.videos];
    newFormValues = newFormValues.concat(newLinePost);
    if (formData.type === "photo") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        images: newFormValues,
        medias: driveFiles,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        videos: newFormValues,
        medias: driveFiles,
      }));
    }

    // cập nhật content
    newFormValues.forEach((item, index) => {
      setValue(`content_${index}`, item.content);
    });
  };

  // Hàm để thêm một phần tử vào mảng formData.shuffle_content
  const addShuffleContent = () => {
    setCountShuffleContent((prev) => prev + 1);
    setFormData((prevData) => ({
      ...prevData,
      shuffle_content: [...prevData.shuffle_content, ""],
    }));
  };

  // Hàm để xoá một phần tử từ mảng formData.shuffle_content tại vị trí index
  const removeShuffleContent = (index) => {
    setCountShuffleContent((prev) => prev - 1);
    setFormData((prevData) => {
      const updatedShuffleContent = [...prevData.shuffle_content];
      updatedShuffleContent.splice(index, 1);
      return {
        ...prevData,
        shuffle_content: updatedShuffleContent,
      };
    });
  };

  // Hàm để cập nhật nội dung cho từng phần tử trong formData.shuffle_content
  const handleShuffleContentChange = (index, value) => {
    setFormData((prevData) => {
      const updatedShuffleContent = [...prevData.shuffle_content];
      updatedShuffleContent[index] = value;
      setValue("shuffle_content", updatedShuffleContent);
      return {
        ...prevData,
        shuffle_content: updatedShuffleContent,
      };
    });
  };

  const deleteMedia = (id) => {
    setMedia((prev) => prev.filter((item) => item.id !== id));
    if (formData.type === "photo") {
      let currentImages = [];
      // Tạo một bản sao của currentImages và cập nhật các trường tương ứng với file
      currentImages = formData.images.filter(
        (item) => item.drive_file_id !== id
      );

      if (!currentImages.length) {
        setIsLoadingFile(false);
      }
      setValue("media", currentImages);
      setFormData((prev) => ({ ...prev, images: currentImages }));
    } else {
      setIsLoadingFile(false);
      setFormData((prev) => ({ ...prev, videos: [] }));
    }
  };

  const triggerCallEvent = async (inputValue) => {
    try {
      const res = await createTopic(userInfo.last_project_active, {
        name: inputValue,
      });
      if (res.status) {
        refetchTopic();
      }
    } catch (error) {
      console.log(error);
    }
  };
  let inputChangeTimeout;
  const handleInputChange = (inputValue, { action }) => {
    const lowerCasedInputValue = inputValue.toLowerCase();
    const lowerCasedOptions = optionTopic?.map((option) =>
      option.label.toLowerCase()
    );
    clearTimeout(inputChangeTimeout);
    if (
      action === "input-change" &&
      !lowerCasedOptions.some((option) => option.includes(lowerCasedInputValue))
    ) {
      inputChangeTimeout = setTimeout(() => {
        triggerCallEvent(inputValue);
      }, 4000);
    }
  };

  const onEditSubmit = async (data) => {
    setIsLoadingFile(false);
    if (!data.topic || !formData.shuffle_content || !data.type) {
      // Nếu trường topic rỗng, không tiến hành submit và hiển thị cảnh báo
      return;
    }

    setLoading(true);
    try {
      let data = [];

      const imageUrlInMedia = formData.medias
        .filter((post) => {
          return post.mimeType.includes("image");
        })
        .map((image) => image.thumbnailLink || "");
      const isGetThumbInStorage = localStorage?.getItem("isGetThumb");

      const images =
        isGetThumbInStorage == true
          ? await uploadMediaImageDrive(imageUrlInMedia)
          : [];

      for (
        let mediaIndex = 0;
        mediaIndex < formData.medias.length;
        mediaIndex++
      ) {
        let item = formData.medias[mediaIndex];
        if (item.mimeType.includes("image")) {
          // if (images[mediaIndex].status === "fulfilled") {
          data.push({
            type: MEDIA_TYPE_IMAGE,
            name:
              images[mediaIndex]?.value?.url ||
              "https://i.so9.vn/images/image-default.png",
            alt: item.name,
            caption: "",
            drive_file_id: item.id,
            drive_file_name: item.name || "",
            drive_folder: item.drive_folder || {},
            drive_file_size: item.sizeBytes.toString(),
            drive_email: item.drive_email,
          });
          // }
        } else if (item.mimeType.includes("video")) {
          data.push({
            type: MEDIA_TYPE_VIDEO,
            name: item.thumbnailLink,
            thumb: item.thumbnailLink,
            alt: item.name,
            previewImages: {
              name: item.thumbnailLink,
            },
            caption: "",
            drive_file_id: item.id,
            drive_file_name: item.name || "",
            drive_folder: item.drive_folder || {},
            drive_file_size: item.sizeBytes.toString(),
            drive_email: item.drive_email,
          });
        }
      }

      const params = {
        ...formData,
        images:
          formData.type === "photo"
            ? data.filter((img) => img.name)
            : undefined,
        videos: formData.type !== "photo" ? data : undefined,
        medias: undefined,
      };

      const res = await createWarehouse(userInfo.last_project_active, params);
      if (res?.status) {
        setMessage("Bạn đã tạo bài thành công");

        refetch();
        toggleSuccess();
      }
    } catch (error) {
      console.log(error);
    } finally {
      toggle();
      setLoading(false);
      reset();
    }
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      zIndex={zIndexParent}
      className="modal-dialog-centered"
    >
      <ModalHeader
        toggle={toggle}
        close={
          <button className="close" onClick={toggle}>
            <Icon name="cross" />
          </button>
        }
      >
        Tạo bài đăng
      </ModalHeader>
      <ModalBody>
        <Form className="gy-2" onSubmit={handleSubmit(onEditSubmit)}>
          <Row className="d-flex align-items-center">
            <Col md="3">Loại bài</Col>
            <Col md="9" className="pl-0">
              <FormGroup style={{ width: "40%" }}>
                <Controller
                  name="type"
                  control={control}
                  rules={{
                    required: "Bạn cần chọn loại bài",
                  }}
                  render={({ field: { onBlur, onChange } }) => (
                    <RSelect
                      style={{ with: "50%" }}
                      options={optionMediaType}
                      placeholder="Chọn loại bài"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          type: e.value,
                        });
                        setValue("type", e.value);
                      }}
                    />
                  )}
                />
                {errors.type && (
                  <FormText color="danger"> {errors[`type`]?.message}</FormText>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row className="d-flex align-items-center">
            <Col md="3">Media</Col>
            <Col md="9" className="pl-0">
              {media.length === 0 ? (
                <>
                  <Controller
                    name="media"
                    control={control}
                    rules={{
                      required: "Bạn cần chọn Media",
                    }}
                    render={({ field: { onBlur, onChange } }) => (
                      <>
                        {isLoadingFile ? (
                          <div className="w-100 d-lfex justify-content-center">
                            <LoadingItem />
                          </div>
                        ) : (
                          <Button
                            color="light"
                            disabled={formData.type ? false : true}
                            onClick={() => onFormConnectHandle()}
                          >
                            <Img
                              src={LogoDriver}
                              style={{ height: "1rem", width: "1rem" }}
                              className="mr-1"
                              alt="logo driver"
                            />
                            <p className="fw-medium">Google Drive</p>
                          </Button>
                        )}
                      </>
                    )}
                  />
                  {errors.media && (
                    <FormText color="danger">
                      {" "}
                      {errors[`media`]?.message}
                    </FormText>
                  )}
                </>
              ) : (
                <MediaItem
                  media={media}
                  mediaType={formData.type}
                  deleteMedia={deleteMedia}
                  onFormConnectHandle={onFormConnectHandle}
                />
              )}
            </Col>
          </Row>

          <Row className="d-flex align-items-center ">
            <Col md="3">Chủ đề</Col>
            <Col md="9" className="pl-0">
              <FormGroup style={{ width: "40%" }}>
                <Controller
                  name="topic"
                  control={control}
                  rules={{
                    required: "Bạn cần chọn chủ đề",
                  }}
                  render={({ field: { onBlur, onChange } }) => (
                    <RSelect
                      style={{ with: "50%" }}
                      options={optionTopic && optionTopic}
                      isDisabled={formData.type ? false : true}
                      placeholder={"Chọn chủ đề"}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          topic: e.value,
                        });
                        setValue("topic", e.value);
                      }}
                      onInputChange={handleInputChange}
                      noOptionsMessage={() => (
                        <>
                          <span>Enter topic and system auto create after 3s</span>
                        </>
                      )}
                    />
                  )}
                />
                {errors.topic && (
                  <FormText color="danger">
                    {" "}
                    {errors[`topic`]?.message}
                  </FormText>
                )}
              </FormGroup>
            </Col>
          </Row>
          <div
            style={{
              width: "100%",
              borderBottom: "2px solid #E5E7EB",
              padding: "0px !important",
            }}
          ></div>
          <Row style={{ maxHeight: "300px", overflow: "auto" }}>
            <Col md="12" className="mt-2">
              {Array.from({ length: countShuffleContent }, (_, index) => (
                <Row key={index}>
                  <Col md="3" className="pr-0">
                    Nội dung {index > 0 && index + 1}
                  </Col>
                  <Col md="9" className="pl-0">
                    <FormGroup>
                      <Controller
                        name="shuffle_content"
                        control={control}
                        rules={{
                          required: "Bạn cần nhập nội dung",
                        }}
                        render={({ field: { onBlur, onChange } }) => (
                          <>
                            <div
                              className="nk-reply-form"
                              style={{ margin: 0 }}
                            >
                              <ContentOriginal
                                disabled={formData.type ? false : true}
                                name={`shuffle_content_${index}`}
                                className="form-control"
                                placeholder="Nhập nội dung"
                                value={formData.shuffle_content[index]}
                                onChange={(e) =>
                                  handleShuffleContentChange(
                                    index,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="d-flex justify-content-end my-1">
                              {index > 0 && (
                                <Icon
                                  name="trash"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => removeShuffleContent(index)}
                                />
                              )}
                            </div>
                          </>
                        )}
                      />
                      {errors.shuffle_content && (
                        <FormText color="danger">
                          {" "}
                          {errors[`shuffle_content`]?.message}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
          <p
            style={{ color: "#0EA5E9", cursor: "pointer" }}
            onClick={addShuffleContent}
          >
            + Thêm biến thể nội dung
          </p>
          <div className={style.btnCreatePost}>
            <FormGroup className="d-flex justify-content-end">
              <div>
                <Button
                  color="light"
                  className="mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    toggle();
                  }}
                //   disabled={isUploading}
                >
                  <span style={{ textTransform: "uppercase" }}>Huỷ</span>
                </Button>
                <Button
                  type="submit"
                  style={{ background: "#2C4B94" }}
                  disabled={loading}
                >
                  <span style={{ textTransform: "uppercase", color: "#fff" }}>
                    {loading && <Spinner size="sm" />}Lưu lại
                  </span>
                </Button>
              </div>
            </FormGroup>
          </div>
        </Form>
      </ModalBody>
      <ModalWarning
        modalWarning={modalWarningProject}
        toggleWarning={toggleWarningProject}
        warningText={warningTextProject}
      />

      <ModalDriveUpload
        setIsLoadingFile={setIsLoadingFile}
        toggle={toggle}
        tabConnect={true}
        modalDriveUpload={modalDriveUpload}
        setModalDriveUpload={setModalDriveUpload}
        connectToDrive={connectToDrive}
        getConfigDrive={getConfigDrive}
        maxPost={MAX_POST_UPLOAD}
        handleFileUploadDrive={handleFileUploadDriveMulti}
        driver={driver}
        mediaType={formData.type}
        isMultiple={false}
        setZIndexParent={setZIndexParent}
        warehouseMediaType={formData.type}
        setMedia={setMedia}
        setValue={setValue}
        formValues={formData}
        setFormValues={setFormData}
      />

      <ModalNoteDrive
        modalNoteDrive={modalNoteDrive}
        setModalNoteDrive={setModalDriveUpload}
        connectToDrive={connectToDrive}
      />

      {/* Modal Select DriveUpload */}
    </Modal>
  );
};

export default ModalAdd;
