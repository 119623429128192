export const optionContentType = [
    {
      label: "Reels, Short (Fb, Insta, Youtube)",
      value: ""
    },
    {
      label: "Video (Youtube thường, Tiktok, FB Feed, Insta Feed)",
      value: ""
    },
    {
      label: "Bài Ảnh (FB Feed, Insta Feed)",
      value: ""
    }
  ]