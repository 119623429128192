import styles from "../index.module.scss";
import { useEffect, useState } from "react";
import {
  POST_PLATFORM_LABEL,
  getPlatformSquareIcon,
} from "../../../../libs/helper";
import { Img } from "../../../../components/Component";
import { RiArrowDownSLine, RiArrowUpSLine } from "@remixicon/react";
import { CheckedBox } from "../../../../components/Component";
import PlatformChannelItem from "./PlatformChannelItem";

const PlatformChannel = ({
  item,
  handleCheckPlatform,
  handleCheckboxChange,
  isCheckAll,
  isChangeOpen,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const handlOpenPlatform = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(isChangeOpen);
  }, [isChangeOpen]);

  return (
    <div className={"mt-3"} key={item?._id}>
      <div
        onClick={handlOpenPlatform}
        className={`${styles.leftMenuPlatformHeader} d-flex align-items-center justify-content-between cursor-pointer`}
      >
        <div className="d-flex align-items-center">
          <Img
            src={getPlatformSquareIcon(item?.platform)}
            style={{ height: "14px", width: "14px" }}
          />
          <p className="mb-0 text-body ms-2 fs-12 fw-medium">
            {POST_PLATFORM_LABEL[item.platform]}
          </p>
        </div>
        <div className="d-flex align-items-center">
          {isOpen ? (
            <RiArrowUpSLine
              className="me-2 text-muted"
              size={13}
              onClick={() => handlOpenPlatform()}
            />
          ) : (
            <RiArrowDownSLine
              className="me-2 text-muted"
              size={13}
              onClick={() => handlOpenPlatform()}
            />
          )}
          <CheckedBox
            checked={isCheckAll}
            onChange={(e) => {
              handleCheckPlatform(e, item.platform);
              e.stopPropagation();
            }}
          />
        </div>
      </div>
      {isOpen &&
        item?.data?.map((channelItem) => (
          <PlatformChannelItem
            handleCheckboxChange={handleCheckboxChange}
            item={channelItem}
          />
        ))}
    </div>
  );
};

export default PlatformChannel;
