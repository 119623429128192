import React from "react";
import { Img } from "../../../../components/Component";
import { getPlatformIcon } from "../../../utils/Utils";

const IconPlatform = ({ item, isHide, number }) => {
  const styleShape = item?.platform === 1 ? "rounded" : "rounded-circle";
  return (
    <div
      className={`${styleShape} bg-light position-relative`}
      style={{ height: "32px", width: "32px" }}
    >
      <Img
        src={item.picture}
        className={styleShape}
        style={{ height: "32px", width: "32px" }}
      />
      <Img
        className={`${styleShape} position-absolute border border-1 border-white`}
        style={{ bottom: "0px", right: "0px" }}
        src={getPlatformIcon(item.platform)}
        height={"14px"}
        width={`14px`}
      />
      {isHide && (
        <div
          class="absolute inset-0 justify-center items-center"
          style={{ backgroundColor: "rgba(0,0,0,0.6)" }}
        >
          <span className="text-white">+{number}</span>
        </div>
      )}
    </div>
  );
};

export default IconPlatform;
