import React from "react";
import IconAlter from "../../images/warehouse/alert_delete.svg";
import { Button, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";

const ModalDeleteMulti = (props) => {
  const { modal, handleDeleteMulti, loading, toggle } = props;
  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
      <ModalBody className="text-center p-4">
        <div
          className="d-flex align-items-center justify-content-center mb-3"
          style={{
            fontSize: "20px",
            fontWeight: "600",
            color: "#142243",
          }}
        >
          <img src={IconAlter} alt="alert" />
          Xóa hàng loạt nội dung trong kho
        </div>
        <p className="mb-2" style={{ color: "#333333" }}>
          Các chiến dịch đang chạy sẽ không còn lấy nội dung được xóa này để
          đăng hàng ngày. Các bài đã đăng thành công trước đó vẫn được giữ
          nguyên trên các Kênh.
        </p>
        <Button
          style={{ background: "#2C4B94", fontWeight: "400" }}
          disabled={loading}
          onClick={() => handleDeleteMulti()}
        >
          {loading ? (
            <>
              <Spinner size="sm" className="mr-2" />
              <span>Đang xoá</span>
            </>
          ) : (
            <span>Đã hiểu và tiếp tục</span>
          )}
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default ModalDeleteMulti;
