import { API } from "../libs/client";

export const getListWarehouse = async (projectId, data) => {
  const uri = `/projects/content-warehouse/origin-post?project_id=${projectId}`;
  const res = await API.get(uri, data);
  return res.data;
};

export const createWarehouse = async (projectId, data) => {
  const uri = `projects/content-warehouse/origin-post?project_id=${projectId}`;
  const res = await API.post(uri, data);
  return res.data;
};

export const editWarehouse = async (projectId, id, data) => {
  const uri = `/projects/content-warehouse/origin-post/${id}?project_id=${projectId}`;
  const res = await API.put(uri, data);
  return res.data;
};

export const editBulkWarehouse = async (data) => {
  const uri = `/projects/content-warehouse/origin-post/bulk/update`;
  const res = await API.put(uri, data);
  return res.data;
};

export const deleteWarehouse = async (projectId, id) => {
  const uri = `/projects/content-warehouse/origin-post/${id}?project_id=${projectId}`;
  const res = await API.delete(uri);
  return res.data;
};

// topic
export const getListTopic = async (projectId, data) => {
  const uri = `/projects/content-warehouse/post-topic?project_id=${projectId}`;
  const res = await API.get(uri, data);
  return res.data;
};

export const createTopic = async (projectId, data) => {
  const uri = `/projects/content-warehouse/post-topic?project_id=${projectId}`;
  const res = await API.post(uri, data);
  return res.data;
};
export const deleteTopic = async (data, id) => {
  const uri = `/projects/content-warehouse/post-topic/${id}`;
  const res = await API.delete(uri, data);
  return res.data;
};
export const editTopic = async (data, id) => {
  const uri = `/projects/content-warehouse/post-topic/${id}`;
  const res = await API.put(uri, data);
  return res.data;
};

export const contentWarehouse = async (projectId, data, platform) => {
  const uri = `/projects/content-warehouse/${platform}/posts?project_id=${projectId}`;
  const res = await API.get(uri, data);
  return res.data;
};

export const getPostWithFilter = async (projectId, data) => {
  const uri = `/projects/${projectId}/posts/filter`;
  const res = await API.get(uri, data);
  return res.data;
};

export const getCountActive = async (projectId, data) => {
  const uri = `/projects/content-warehouse/campaigns/cnt-active?project_id=${projectId}`;
  const res = await API.get(uri, data);
  return res.data;
};

export const storeWarehouseFast = async (data) => {
  const uri = `/projects/content-warehouse/origin-post/bulk`;
  const res = await API.post(uri, data);
  return res.data;
};

export const deleteWarehouseFast = async (data) => {
  const uri = `/projects/content-warehouse/origin-post/bulk`;
  const res = await API.delete(uri, {
    data: data,
  });
  return res.data;
};
