import style from "./index.module.scss";
const IntrolChatHeader = () => {
    return (
        <>
            <div className={style.headerWarper}>
                <div className={`${style.headerTitle}`}>
                    <p>
                        Quản trị tất cả các nền tảng Mạng xã hội, chỉ với
                    </p>
                    <span> một ứng dụng duy nhất!</span>
                </div>

                <p>Không hề đơn giản khi bạn muốn xây dựng & quản lý kênh truyền thông Mạng xã hội của bạn khi mà danh sách các nền tảng ngày càng dài: Facebook, Tiktok, Instagram,... </p>
                <div className={`${style.listInterview}`}>
                    <p>
                    SO9 mang tất cả các nền tảng về trong một nơi duy nhất để quản trị
                    </p>
                    <ul>
                        <li>Hẹn lịch bài đăng và đăng lên tất cả mọi nơi.</li>
                        <li style={{fontWeight:"700"}}>Trả lời toàn bộ tin nhắn, bình luận tại một nơi duy nhất.</li>
                        <li>Toàn bộ việc quản lý hiệu quả, nhân sự, nội dung đều có trên SO9.</li>
                    </ul>
                </div>

            </div>
        </>
    )
}
export default IntrolChatHeader