import { API } from "../libs/client";

const URI = {
  SEARCH_PEOPLE_FACEBOOK: "/searchPeople/facebook",
  SEARCH_PEOPLE_INSTAGRAM: "/searchPeople/instagram",
};

const CDN_URI = {
  UPLOAD_IMAGE: "/v1/upload/image",
  UPLOAD_VIDEO_INIT_METADATA: "/v1/upload/video/metadata",
  UPLOAD_VIDEO_PART: "/v1/upload/video/upload",
  UPLOAD_MULTI_IMAGE_BULK: "v1/upload/image/bulk",
};

export const getChannels = async (projectId, data = {}) => {
  data.status = 1;
  const uri = `/projects/${projectId}/channels/list`;
  const res = await API.get(uri, { params: data });
  return res.data;
};

export const getTags = async (projectId, data) => {
  const uri = `/projects/${projectId}/tags/list`;
  const res = await API.get(uri, data);
  return res.data;
};

export const searchPeopleFacebook = async (data) => {
  const res = await API.post(URI.SEARCH_PEOPLE_FACEBOOK, data);
  return res.data;
};

export const searchPeopleInstagram = async (data) => {
  const res = await API.post(URI.SEARCH_PEOPLE_INSTAGRAM, data);
  return res.data;
};

export const uploadImage = async (data) => {
  const res = await API.post(CDN_URI.UPLOAD_IMAGE, data, {
    baseURL: process.env.REACT_APP_CDN_API + "api",
  });
  return res.data;
};

export const uploadMultiImageBulk = async (data) => {
  const res = await API.post(CDN_URI.UPLOAD_MULTI_IMAGE_BULK, data, {
    baseURL: process.env.REACT_APP_CDN_API + "api",
  });
  return res.data;
};

export const uploadVideoInitMetadata = async (data) => {
  const res = await API.post(CDN_URI.UPLOAD_VIDEO_INIT_METADATA, data, {
    baseURL: process.env.REACT_APP_CDN_API + "api",
  });
  return res.data;
};

export const uploadVideoPart = async (data) => {
  const res = await API.post(CDN_URI.UPLOAD_VIDEO_PART, data, {
    baseURL: process.env.REACT_APP_CDN_API + "api",
  });
  return res.data;
};

export const getMedia = async (projectId, data) => {
  const uri = `/projects/${projectId}/media`;
  const res = await API.get(uri, data);
  return res.data;
};

export const storePost = async (projectId, data) => {
  const uri = `/projects/${projectId}/posts`;
  const res = await API.post(uri, data);
  return res.data;
};

export const storePostFast = async (projectId, data) => {
  const uri = `/projects/${projectId}/posts/bulk`;
  const res = await API.post(uri, data);
  return res.data;
};

export const editPost = async (projectId, data) => {
  const uri = `/projects/${projectId}/posts/edit`;
  const res = await API.post(uri, data);
  return res.data;
};

export const getListManagePost = async (projectId, data) => {
  const uri = `/projects/${projectId}/posts/list-table`;
  const res = await API.get(uri, data);
  return res.data;
};

export const getDetailNotificationStatus = async (
  projectId,
  postId,
  platformId
) => {
  const uri = `/projects/${projectId}/notifications/posts/${postId}/platform/${platformId}`;
  const res = await API.get(uri);
  return res.data;
};

export const deletePost = async (projectId, postId) => {
  const uri = `/projects/${projectId}/posts/${postId}`;
  const res = await API.delete(uri);
  return res.data;
};

export const deleteSocialPost = async (data) => {
  const uri = `/projects/social-post/remove`;
  const res = await API.post(uri, data);
  return res.data;
};

export const reupPost = async (data) => {
  const uri = `/projects/social-post/reup`;
  const res = await API.post(uri, data);
  return res.data;
};

export const editSocialPost = async (projectId, data) => {
  const uri = `/projects/${projectId}/posts/edit`;
  const res = await API.post(uri, data);
  return res.data;
};

export const getAllUser = async (data) => {
  const uri = `/projects/seeding/users`;
  const res = await API.get(uri, data);
  return res.data;
};

export const seedingComment = async (projectId, postId, data) => {
  const uri = `/projects/${projectId}/posts/${postId}/comment`;
  const res = await API.post(uri, data);
  return res.data;
};

export const PostsmartSchedule = async (projectId, data) => {
  const uri = `/projects/${projectId}/channels/smart-schedule/setting`;
  const res = await API.post(uri, data);
  return res.data;
};

export const DeletesmartSchedule = async (projectId, channelId) => {
  const uri = `/projects/${projectId}/channels/smart-schedule/${channelId}`;
  const res = await API.delete(uri);
  return res.data;
};
export const GetsmartSchedule = async (projectId, data) => {
  const uri = `/projects/${projectId}/channels/setting-smart-schedule`;
  const res = await API.get(uri, data);
  return res.data;
};

export const ResetsmartSchedule = async (projectId, data) => {
  const uri = `/projects/${projectId}/channels/reset-smart-schedule`;
  const res = await API.post(uri, data);
  return res.data;
};

export const getListUserSeeding = async (projectId, data) => {
  const uri = `/projects/${projectId}/channels/comment-seeding/get-list-channel-seeding`;
  const res = await API.post(uri, data);
  return res.data;
};

export const PostCommentSeeding = async (projectId, data) => {
  const uri = `/projects/${projectId}/channels/comment-seeding/post-comment`;
  const res = await API.post(uri, data);
  return res.data;
};

export const GetlistIcon = async () => {
  const uri = `/icon`;
  const res = await API.get(uri);
  return res.data;
};

export const retryMedia = async (projectId, data) => {
  const uri = `/projects/${projectId}/posts/retry-media`;
  const res = await API.post(uri, data);
  return res.data;
};
export const Getcategories = async ({ channelId, data }) => {
  const uri = `/projects/youtube/${channelId}/categories`;
  const res = await API.post(uri, data);
  return res.data;
};

export const Getplaylists = async ({ channelId, data }) => {
  const uri = `/projects/youtube/${channelId}/playlists`;
  const res = await API.post(uri, data);
  return res.data;
};

export const GetPinterestBoard = async ({ channelId, data }) => {
  let uri = `/projects/pinterest/${channelId}/list-board`;
  const res = await API.get(uri, data);
  return res.data;
};

export const createPinterestBoard = async ({ channelId, project_id, data }) => {
  const uri = `/projects/pinterest/${channelId}/create-board?project_id=${project_id}`;
  const res = await API.post(uri, data);
  return res.data;
};
//connect drive list
export const connectDrive = async (projectId, urlRedirect, data) => {
  const uri = `/projects/google-drive/url?project_id=${projectId}&url_redirect=${urlRedirect}`;
  const res = await API.get(uri, data);
  return res.data;
};

export const getAuthTokenDrive = async (projectId) => {
  const uri = `/projects/google-drive/access-token?project_id=${projectId}`;
  const res = await API.get(uri);
  return res.data;
};

export const getThumbnailsDrive = async (data) => {
  const uri = `/projects/google-drive/thumbnails`;
  const res = await API.post(uri, data);
  return res.data;
};

//list folder
export const getFolderDrive = async (projectId, page) => {
  let uri;
  if (page !== null) {
    uri = `/projects/google-drive/folders?project_id=${projectId}&pageToken=${page}`;
  } else {
    uri = `/projects/google-drive/folders?project_id=${projectId}`;
  }
  const res = await API.get(uri);
  return res.data;
};

//list folder
export const getFolderNestedDrive = async (projectId, folder) => {
  let uri = `/projects/google-drive/folders/${folder}/folders?project_id=${projectId}`;
  const res = await API.get(uri);
  return res.data;
};

//list file in folder
export const getFileDrive = async (projectId, folder, page) => {
  let uri;
  if (page !== null) {
    uri = `/projects/google-drive/folders/${folder}/files?project_id=${projectId}&pageToken=${page}`;
  } else {
    uri = `/projects/google-drive/folders/${folder}/files?project_id=${projectId}`;
  }
  const res = await API.get(uri);
  return res.data;
};

//list file in folder
export const getFolderInFolderDrive = async (projectId, folder, page) => {
  let uri;
  if (page !== null) {
    uri = `/projects/google-drive/folders/${folder}/folders?project_id=${projectId}&pageToken=${page}`;
  } else {
    uri = `/projects/google-drive/folders/${folder}/folders?project_id=${projectId}`;
  }
  const res = await API.get(uri);
  return res.data;
};

//get mail drive
export const getMailDrive = async (projectId, data) => {
  const uri = `/projects/google-drive/config?project_id=${projectId}`;
  const res = await API.get(uri, data);
  return res.data;
};

//get list drive connect
export const getListDriveConnect = async (projectId) => {
  const uri = `/projects/google-drive/accounts?project_id=${projectId}`;
  const res = await API.get(uri);
  return res.data;
};

// active drive
export const activeDrive = async (projectId, driveId, data) => {
  const uri = `/projects/google-drive/${driveId}/selected?project_id=${projectId}`;
  const res = await API.post(uri, data);
  return res.data;
};

export const deleteMultiPost = async (projectId, data) => {
  const uri = `/projects/${projectId}/posts/delete-many`;
  const res = await API.delete(uri, {
    data: data,
  });
  return res.data;
};

export const generateAiContent = async (data) => {
  const uri = `/projects/auto/generate-content`;
  const res = await API.post(uri, data);
  return res.data;
};
