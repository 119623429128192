import React, { useEffect, useMemo, useState } from "react";
import styles from "./PostDetail.module.scss";
import Tag from "./Tag";
import Comments from "./components/Comments";
import PlatformContent from "./components/PlatformContent";
import CheckList from "./components/CheckList";
import Topic from "./components/Topic";
import Analyics from "./components/Analyics";
import ApprovalSatus from "./components/ApprovalSatus";
import moment from "moment";
import { IconPost } from "../../../libs/icon";
import {
  POST_STATUS_PROCESSING_SCHEDULED_ERROR,
  POST_STATUS_PUBLISH_ERROR,
  POST_STATUS_PUBLISH_SUCCESS,
} from "../../constants/posts";
import {
  countComments,
  getComments,
  getDetailPost,
  shareListPost,
  getCheckList,
} from "../../../api/postDetail";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import ConfimDelete from "../../components/Modal/ModalConfimDelete";
import {
  deletePost,
  getDetailNotificationStatus,
  reupPost,
} from "../../../api/createPost";
import { toastError, toastSuccess } from "../../../libs/toasts";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import IconPlatform from "./components/IconPlatform";
import { TooltipComponent } from "../../../components/Component";
import { useSelector } from "react-redux";
import { getStaff } from "../../../api/dashboard";
import { useTranslation } from "react-i18next";
import { getDomainApp } from "../../../utils/Utils";

const PostDetail = ({
  closeModal,
  dataPostDetail,
  setOpenModalPostDetail,
  setListPostByFetch,
  listPostByFetch,
  refetchListPost,
}) => {
  const { t } = useTranslation();
  const [postDetail, setPostDetail] = useState({});
  const [numberActive, setNumberActive] = useState();
  const [listCommnents, setListCommnents] = useState();
  const [countComment, setCountComment] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [postDetailData, setpostDetailData] = useState();
  const [isLoadingPostDetail, setIsLoadingPostDetail] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [listTaskData, setListTaskData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");


  const { projectId, postId } = useParams();
  const project = useSelector((state) => state?.project?.project);
  const isBusiness = project?.type === "business";
  const history = useHistory();

  useEffect(() => {
    getListComments();
  }, [dataPostDetail, postId, projectId]);

  useEffect(() => {
    fetchApiCount();
  }, [dataPostDetail, postId, projectId]);

  useEffect(() => {
    if (postId && projectId) {
      getDataDetailPost();
    }
  }, [postId, projectId]);

  useEffect(() => {
    getListUser()
  }, [dataPostDetail, projectId])


  useEffect(() => {
    fetchApiCheckList();
  }, [dataPostDetail, postId, projectId]);

  useEffect(() => {
    if (postDetail && postDetail.status === POST_STATUS_PUBLISH_ERROR) {
      /**
       * fetch notification
       */
      getDetailNotificationStatus(postDetail.project_id, postDetail._id, postDetail.channel.platform_id).then((data) => {
        setErrorMessage(data.data.description);
      }).catch(e => {
        console.error(e)
      })
    } else if (postDetail && postDetail.status === POST_STATUS_PROCESSING_SCHEDULED_ERROR) {
      /**
       * vi: Quá trình lên lịch tự động bị lỗi. Vui lòng kiểm tra lại Cài đặt lịch của bạn - đảm bảo loại nội dung đăng đã được cài. Sau đó thực hiện Chạy lại lịch tự động.
       */
      setErrorMessage(t("message.automatic_scheduling_process_failed"));
    } else {
      setErrorMessage("");
    }
  }, [
    postDetail
  ])

  const listPlatform = useMemo(() => {
    const result = [];
    const channel =
      postId && projectId ? postDetailData?.channel : dataPostDetail?.channel;
    for (const platform in channel) {
      if (channel.hasOwnProperty(platform)) {
        channel[platform].forEach((post) => {
          post.platform = platform;
          post.media = dataPostDetail?.media || [];
          result.push(post);
        });
      }
    }
    return result;
  }, [dataPostDetail, postDetailData]);

  const handleOpenDropDown = () => {
    setOpenDropdown(!openDropdown);
  };

  const getListUser = () => {
    const project_id = projectId ? projectId : dataPostDetail?.project_id;
    getStaff(project_id, { status: 1 })
      .then((res) => console.log(res))
      .catch((erorr) => console.log(erorr));
  };

  const getListComments = async () => {
    const post_id = postId ? postId : (dataPostDetail?.post_id ? dataPostDetail.post_id : dataPostDetail?._id);
    const project_id = projectId ? projectId : dataPostDetail?.project_id;
    try {
      setIsLoading(true);
      if (post_id && project_id) {
        const res = await getComments(post_id, {
          project_id: project_id,
        });
        setListCommnents(res?.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchApiCount = async () => {
    try {
      setIsLoading(true);
      const post_id = postId ? postId : (dataPostDetail?.post_id ? dataPostDetail.post_id : dataPostDetail?._id);
      const project_id = projectId ? projectId : dataPostDetail?.project_id;
      if (post_id && project_id) {
        const res = await countComments(post_id, {
          project_id: project_id,
        });
        setCountComment(res.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDataDetailPost = async () => {
    try {
      if (postId && projectId) {
        setIsLoadingPostDetail(true);
        const res = await getDetailPost(postId, {
          project_id: projectId,
        });
        setIsLoadingPostDetail(false);
        setpostDetailData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchApiCheckList = () => {
    const post_id = postId ? postId : (dataPostDetail?.post_id ? dataPostDetail.post_id : dataPostDetail?._id);
    const project_id = projectId ? projectId : dataPostDetail?.project_id;
    if (post_id && project_id) {
      getCheckList(project_id, { post_id: post_id })
        .then((res) => {
          setListTaskData(res.data);
        })
        .catch((erorr) => {
          console.log(erorr);
        });
    }
  };

  useEffect(() => {
    setPostDetail(listPlatform[0]);
    setNumberActive(0);
  }, [listPlatform]);

  const handleActiveDetailPost = ({ item, number }) => {
    setPostDetail(item);
    setNumberActive(number);
  };

  const toggleDelete = () => {
    setModalDelete(!modalDelete);
  };

  const handleCopyText = () => {
    const post_id = postId ? postId : (dataPostDetail?.post_id ? dataPostDetail.post_id : dataPostDetail?._id);
    const project_id = projectId ? projectId : dataPostDetail?.project_id;

    const params = {
      type: 1,
      ids: [post_id],
    };
    shareListPost(project_id, params)
      .then((res) => {
        const domain = getDomainApp();
        const text = `${domain}/list-post-share/${res?.data?._id}?project_id=${project_id}`;
        navigator.clipboard.writeText(text);
        toastSuccess("Copied");
      })
      .catch((error) => {
        toastError("Copy Failed!");
      });
  };

  const handleDeletePost = async () => {
    const post_id = postId ? postId : (dataPostDetail?.post_id ? dataPostDetail.post_id : dataPostDetail?._id);
    const project_id = projectId ? projectId : dataPostDetail?.project_id;
    if (post_id && project_id) {
      const res = await deletePost(project_id, post_id);
      if (res.status) {
        toastSuccess(t("toart_alert.delele_post_success"));
        const listData = listPostByFetch?.filter(
          (value, _) => value._id !== post_id
        );
        if (postId && projectId) {
          history.push("/manage_posts");
        } else {
          setListPostByFetch(listData);
        }
      } else {
        setModalDelete(false);
        toastError(t("toart_alert.delele_post_faild"));
        return;
      }
      toggleDelete();
      setOpenModalPostDetail(false);
    }

    if (postId && projectId) {
      history.push("/manage_posts");
    }
  };

  const handleReupPost = (post) => {
    if (window.confirm(t("message.confirm_reup_post"))) {
      const data = {
        socialPostId: post?._id,
        channelType: post?.channel?.platform,
      };
      reupPost(data)
        .then((res) => {
          toastSuccess(t("toart_alert.re_post_success"));
          setOpenModalPostDetail(false);
          refetchListPost();
        })
        .catch((error) => {
          console.log(error);
          toastError(
            t("toart_alert.error_repost_try_again")
          );
          setOpenModalPostDetail(false);
        });
    }
  };

  const handleOpenPostId = (item) => {
    let postId;
    let postAuthor;
    let postUrl;
    switch (item?.channel?.platform) {
      case 1:
        postId = item.facebook_post_id;
        window.open(`https://www.facebook.com/${postId}`, "_blank");
        break;
      case 2:
        postUrl = item.share_url;
        window.open(`${postUrl}`, "_blank");
        break;
      case 3:
        postId = item.facebook_post_id;
        window.open(`https://www.facebook.com/${postId}`, "_blank");
        break;
      case 5:
        postId = item.youtube_video_id;
        window.open(`https://www.youtube.com/watch?v=${postId}`, "_blank");
        break;
      case 6:
        //  postId = getShortcodeFromTag(item.instagram_post_id.id);
        //  window.open(`https://www.instagram.com/p/${postId}`, "_blank");
        break;
      case 10:
          postAuthor = item.channel.name;
          postId = item.threads_post_id;
          window.open(`https://www.threads.net/@${postAuthor}/post/${postId}`, "_blank");
          break;
      default:
    }
  };

  const HeaderPostDetail = () => {
    return (
      <header className={`${styles.header}`}>
        <div className={`${styles.leftHeader}`}>
          <div className={styles.titleHeader}>
            <p className={`my-auto ${styles.title}`}>{t("post_detail.header.tilte")}</p>
            <Tag status={postDetail?.status} />
          </div>
        </div>
        <div className={styles.middleHeader}>
          <p className="my-auto">
            {moment(postDetail?.scheduled_at * 1000).format(
              "HH:mm, Do MMMM YYYY"
            )}{" "}
            <span>({t("post_detail.header.publish_date")})</span>
          </p>
        </div>
        <div className={`${styles.btnContainer}`}>
          {/* <button className={`${styles.btn} ${styles.btnEdit}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="16"
              height="16"
              fill="rgba(135,138,153,1)"
            >
              <path d="M12.8995 6.85453L17.1421 11.0972L7.24264 20.9967H3V16.754L12.8995 6.85453ZM14.3137 5.44032L16.435 3.319C16.8256 2.92848 17.4587 2.92848 17.8492 3.319L20.6777 6.14743C21.0682 6.53795 21.0682 7.17112 20.6777 7.56164L18.5563 9.68296L14.3137 5.44032Z"></path>
            </svg>
            <span>Edit</span>
          </button> */}
          <button
            className={`${styles.btn} ${styles.btnDuplicate}`}
            onClick={handleCopyText}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="16"
              height="16"
              fill="rgba(73,80,87,1)"
            >
              <path d="M13.0607 8.11097L14.4749 9.52518C17.2086 12.2589 17.2086 16.691 14.4749 19.4247L14.1214 19.7782C11.3877 22.5119 6.95555 22.5119 4.22188 19.7782C1.48821 17.0446 1.48821 12.6124 4.22188 9.87874L5.6361 11.293C3.68348 13.2456 3.68348 16.4114 5.6361 18.364C7.58872 20.3166 10.7545 20.3166 12.7072 18.364L13.0607 18.0105C15.0133 16.0578 15.0133 12.892 13.0607 10.9394L11.6465 9.52518L13.0607 8.11097ZM19.7782 14.1214L18.364 12.7072C20.3166 10.7545 20.3166 7.58872 18.364 5.6361C16.4114 3.68348 13.2456 3.68348 11.293 5.6361L10.9394 5.98965C8.98678 7.94227 8.98678 11.1081 10.9394 13.0607L12.3536 14.4749L10.9394 15.8891L9.52518 14.4749C6.79151 11.7413 6.79151 7.30911 9.52518 4.57544L9.87874 4.22188C12.6124 1.48821 17.0446 1.48821 19.7782 4.22188C22.5119 6.95555 22.5119 11.3877 19.7782 14.1214Z"></path>
            </svg>
            <span>{t("post_detail.header.share_link")}</span>
          </button>
          <Dropdown isOpen={openDropdown} toggle={handleOpenDropDown}>
            <DropdownToggle
              color="#fff"
              caret
              style={{
                border: "1px solid #ced4da",
                borderRadius: "4px",
                padding: "7px 8px",
                marginRight: "10px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="22"
                height="22"
                fill="rgba(135,138,153,1)"
              >
                <path d="M12 3C11.175 3 10.5 3.675 10.5 4.5C10.5 5.325 11.175 6 12 6C12.825 6 13.5 5.325 13.5 4.5C13.5 3.675 12.825 3 12 3ZM12 18C11.175 18 10.5 18.675 10.5 19.5C10.5 20.325 11.175 21 12 21C12.825 21 13.5 20.325 13.5 19.5C13.5 18.675 12.825 18 12 18ZM12 10.5C11.175 10.5 10.5 11.175 10.5 12C10.5 12.825 11.175 13.5 12 13.5C12.825 13.5 13.5 12.825 13.5 12C13.5 11.175 12.825 10.5 12 10.5Z"></path>
              </svg>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={handleCopyText}
                style={{ border: "none", outline: "none" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="16"
                  height="16"
                  fill="rgba(73,80,87,1)"
                >
                  <path d="M13.0607 8.11097L14.4749 9.52518C17.2086 12.2589 17.2086 16.691 14.4749 19.4247L14.1214 19.7782C11.3877 22.5119 6.95555 22.5119 4.22188 19.7782C1.48821 17.0446 1.48821 12.6124 4.22188 9.87874L5.6361 11.293C3.68348 13.2456 3.68348 16.4114 5.6361 18.364C7.58872 20.3166 10.7545 20.3166 12.7072 18.364L13.0607 18.0105C15.0133 16.0578 15.0133 12.892 13.0607 10.9394L11.6465 9.52518L13.0607 8.11097ZM19.7782 14.1214L18.364 12.7072C20.3166 10.7545 20.3166 7.58872 18.364 5.6361C16.4114 3.68348 13.2456 3.68348 11.293 5.6361L10.9394 5.98965C8.98678 7.94227 8.98678 11.1081 10.9394 13.0607L12.3536 14.4749L10.9394 15.8891L9.52518 14.4749C6.79151 11.7413 6.79151 7.30911 9.52518 4.57544L9.87874 4.22188C12.6124 1.48821 17.0446 1.48821 19.7782 4.22188C22.5119 6.95555 22.5119 11.3877 19.7782 14.1214Z"></path>
                </svg>
                <span className="pl-2">{t("post_detail.header.share_link")}</span>
              </DropdownItem>
              <DropdownItem
                style={{ border: "none", outline: "none" }}
                onClick={toggleDelete}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="16"
                  height="16"
                  fill="rgba(240,101,72,1)"
                >
                  <path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM9 11V17H11V11H9ZM13 11V17H15V11H13ZM9 4V6H15V4H9Z"></path>
                </svg>
                <span className="pl-2">{t("post_detail.header.delete_post")}</span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <div className={styles.closeBtnContainer}>
            <button
              onClick={() => {
                setOpenModalPostDetail(false);
              }}
              className={`${styles.btn} ${styles.btnClose}`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="16"
                height="16"
                fill="rgba(135,138,153,1)"
              >
                <path d="M10.5859 12L2.79297 4.20706L4.20718 2.79285L12.0001 10.5857L19.793 2.79285L21.2072 4.20706L13.4143 12L21.2072 19.7928L19.793 21.2071L12.0001 13.4142L4.20718 21.2071L2.79297 19.7928L10.5859 12Z"></path>
              </svg>
            </button>
          </div>
        </div>
      </header>
    );
  };

  const SideLeft = () => {
    return (
      <div className={styles.sideLeft}>
        <header>
          <h4 className={styles.titleHeader}>{t("post_detail.side_left.tilte")}</h4>
        </header>
        <main className={styles.mainSideLeft}>
          {!isLoadingPostDetail ? (
            listPlatform?.map((currentPage, index) => (
              <div
                className={`${styles.containerPage} ${numberActive === index
                  ? styles.activePage
                  : styles.notActivePage
                  }`}
                onClick={() => {
                  handleActiveDetailPost({ item: currentPage, number: index });
                }}
              >
                <div className={styles.infoPageContainer}>
                  <div className={styles.imgPage}>
                    <IconPlatform item={currentPage.channel} />
                  </div>
                  <div className={styles.infoPage}>
                    <div className={styles.name}>
                      {currentPage?.channel?.name}
                    </div>
                    <div className={styles.time}>
                      {moment(currentPage?.scheduled_at * 1000).format(
                        "HH:mm, Do MMMM YYYY"
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.statusPage}>
                  <TooltipComponent id="reup" text="Repost" />
                  {currentPage?.status === POST_STATUS_PUBLISH_ERROR && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="12"
                      height="12"
                      fill="rgba(53,119,241,1)"
                      style={{ marginRight: "4px" }}
                      id="reup"
                      onClick={() => {
                        handleReupPost(currentPage);
                      }}
                    >
                      <path d="M1.94607 9.31543C1.42353 9.14125 1.4194 8.86022 1.95682 8.68108L21.043 2.31901C21.5715 2.14285 21.8746 2.43866 21.7265 2.95694L16.2733 22.0432C16.1223 22.5716 15.8177 22.59 15.5944 22.0876L11.9999 14L17.9999 6.00005L9.99992 12L1.94607 9.31543Z"></path>
                    </svg>
                  )}
                  <div id="status">
                    <IconPost status={currentPage?.status} />
                  </div>
                  {/* <UncontrolledTooltip
                    autohide={false}
                    // placement={direction}
                    target="status"
                    style={{backgroundColor:"transparent"}}
                  >
                    <Tag status={currentPage?.status} />
                  </UncontrolledTooltip> */}
                  {/* <TooltipComponent
                    id="status"
                    text={<Tag status={currentPage?.status} />}
                  /> */}
                </div>
              </div>
            ))
          ) : (
            <div
              className={
                "d-flex align-items-center justify-content-center pb-2 pt-4"
              }
              style={{ width: "100%" }}
            >
              <Spinner
                size="lg"
                color="dark"
                style={{ width: "3rem", height: "3rem" }}
              />
            </div>
          )}
        </main>
      </div>
    );
  };

  const SideRight = () => {
    return (
      <div className={styles.sideRight}>
        <header>
          <h4 className={styles.titleHeader}>{t("post_detail.side_right.tilte")}</h4>
        </header>
        <main className={styles.mainSideRight}>
          <Comments
            listCommnents={listCommnents}
            count={countComment}
            dataPostDetail={
              postId && projectId ? postDetailData : dataPostDetail
            }
            getListComments={getListComments}
            fetchApiCount={fetchApiCount}
            isLoading={isLoading}
          />
          {/* <Noted /> */}
          <CheckList listTaskData={listTaskData} />
          <Topic id="topic" />
          <Analyics postDetail={postDetail} />
          <ApprovalSatus id="ApprovalSatus" />
        </main>
        {!isBusiness && (
          <div className={styles.business}>
            <h3>
              {t("post_detail.side_right.alert_only_avaliable_business")}
            </h3>
            <p>{t("post_detail.side_right.sub_alert_only_avaliable_business")}
            </p>
            <button
              onClick={() => {
                window.open("https://so9.vn/pricing/", "_blank")
              }}
            >
              <div className={styles.circle}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="16"
                  height="16"
                  fill="rgba(255,255,255,1)"
                >
                  <path d="M12.0006 18.26L4.94715 22.2082L6.52248 14.2799L0.587891 8.7918L8.61493 7.84006L12.0006 0.5L15.3862 7.84006L23.4132 8.7918L17.4787 14.2799L19.054 22.2082L12.0006 18.26Z"></path>
                </svg>
              </div>
              {t("post_detail.side_right.botton_upgrade")}
            </button>
          </div>
        )}
      </div>
    );
  };

  const MainPostDetail = () => {
    return (
      <div className={styles.bodyPostDetail}>
        <div className={styles.postPreview}>
          <p>
            {t("post_detail.main.tilte")}{" "}
            <div>
              <p className={styles.text1}>
                {t("post_detail.main.sub_title")}
                {postDetail?.status === POST_STATUS_PUBLISH_SUCCESS && !postDetail?.threads_post_id && (
                  <span
                    className={`d-flex align-items-center`}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleOpenPostId(postDetail)}
                  >
                    <p
                      className={`d-flex align-items-center px-1 ${styles.text2}`}
                    >
                      {t("post_detail.main.view_in_platform")}{" "}
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="16"
                      height="16"
                      fill="rgba(53,119,241,1)"
                    >
                      <path d="M10 6V8H5V19H16V14H18V20C18 20.5523 17.5523 21 17 21H4C3.44772 21 3 20.5523 3 20V7C3 6.44772 3.44772 6 4 6H10ZM21 3V11H19L18.9999 6.413L11.2071 14.2071L9.79289 12.7929L17.5849 5H13V3H21Z"></path>
                    </svg>
                  </span>
                )}
              </p>
            </div>
          </p>
        </div>
        {!!errorMessage && (
          <div className={styles.errorMsg}>
            <p className={styles.title}>{t("post_detail.main.error_message")}</p>
            <p className={styles.content} dangerouslySetInnerHTML={{ __html: errorMessage }}></p>
          </div>
        )}
        <div className={styles.contentPageContainer}>
          <PlatformContent
            isLoadingPostDetail={isLoadingPostDetail}
            postDetail={postDetail}
          />
          {postDetail?.status === POST_STATUS_PUBLISH_ERROR && (
            <button
              className={styles.btnRepost}
              onClick={() => {
                handleReupPost(postDetail);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="16"
                height="16"
                fill="rgba(255,255,255,1)"
              >
                <path d="M21.7267 2.95694L16.2734 22.0432C16.1225 22.5716 15.7979 22.5956 15.5563 22.1126L11 13L1.9229 9.36919C1.41322 9.16532 1.41953 8.86022 1.95695 8.68108L21.0432 2.31901C21.5716 2.14285 21.8747 2.43866 21.7267 2.95694ZM19.0353 5.09647L6.81221 9.17085L12.4488 11.4255L15.4895 17.5068L19.0353 5.09647Z"></path>
              </svg>
              <span>{t("post_detail.main.repost")}</span>
            </button>
          )}
        </div>
      </div>
    );
  };
  return (
    <div
      className={styles.PostDetail}
      style={{
        marginTop: postId && projectId ? "78px" : "",
        maxHeight: postId && projectId ? "100vh" : "100%",
        borderRadius: "30px",
      }}
    >
      <HeaderPostDetail />
      <div className={styles.mainPostDetail}>
        <SideLeft />
        <MainPostDetail />
        <SideRight />
        <ConfimDelete
          modalDelete={modalDelete}
          toggleDelete={toggleDelete}
          messeage={t("message.delete_post_will_data_not_display")}
          handleDelete={handleDeletePost}
        />
      </div>
    </div>
  );
};

export default PostDetail;
