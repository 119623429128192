import React, { useState } from "react";
import LogoDark from "../../images/logo-so9.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  Img,
  PreviewCard
} from "../../components/Component";
import { Alert, Card, Col, Container, FormGroup, Row, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import useCheckLogin from "../../hooks/useCheckLogin";
import style from "./Auth.module.scss"
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextFieldAuth } from "../../commons/TextField/TextFieldAuth";
import { validateEmail } from "../../libs/helper";
import { forgotPassword } from "../../api/auth";
import { useHistory } from "react-router";
import AuthSlider from "../../So9/components/AuthNew/authCarousel";
import styles from "../../So9/components/AuthNew/index.module.scss"
import send_Email from "../../So9/images/icons/email_send.png"


const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [errorVal, setError] = useState("");
  const history = useHistory();
  useCheckLogin();
  const { t } = useTranslation();

  Yup.addMethod(Yup.string, "isValidEmail", function (errorMessage) {
    return this.test('is-valid-email', errorMessage, function (value) {
      const { path, createError } = this;
      value = validateEmail(value);
      if (!value) {
        return createError(path, errorMessage);
      }
      return true;
    });
  });
  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('validate_field_email_required'))
      .isValidEmail(t('validate_field_email')),
  })

  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
    setValue
  } = useForm(
    {
      resolver: yupResolver(formSchema)
    }
  );
  const onFormSubmit = (formData) => {
    setLoading(true);
    forgotPassword(formData).then((res) => {
      if (res.status) {
        localStorage.removeItem('emailToChangePassword');
        history.replace('/reset-password-success');
      } else {
        setLoading(false);
        setError(res.message);
      }
    })
    return true;
  };

  return (
    <React.Fragment>
      <Head title="Forgot-Password" />
      <PageContainer>
        <div className={`${styles.auth_page_wrapper} ${styles.auth_bg_cover} py-5 d-flex justify-content-center align-items-center min-vh-100 `}>
          <div className={`${styles.bg_overlay}`}></div>
          <div className="auth-page-content overflow-hidden pt-lg-5">
            <Container style={{ maxWidth: "1100px" }}>
              <Row>
                <Col lg={12}>
                  <Card className="overflow-hidden card-bg-fill galaxy-border-none" >
                    <Row className="g-0">
                      <AuthSlider />

                      <Col lg={6}>

                        <div className="p-lg-5 p-4">
                          <div>
                            <h5 className="text-primary">{t("auth_forgot_password")}</h5>
                            <p className="text-muted">{t("new_auth.fogot_mess")}</p>
                          </div>

                          <div className="mt-2 text-center">
                            <i className="ri-mail-send-line display-5 text-success"></i>
                            <Img src={send_Email} width={"120px"} height={"120px"} ></Img>
                          </div>


                          <div className="alert border-0 alert-warning text-center mb-2 mx-2" role="alert">
                          {t("new_auth.fogot_header")}
                          </div>

                          <div className="mt-4">
                            {errorVal && (
                              <div className="mb-3">
                                <Alert color="danger" className="alert-icon">
                                  {" "}
                                  <Icon name="alert-circle" /> {errorVal}{" "}
                                </Alert>
                              </div>
                            )}
                            <form onSubmit={handleSubmit(onFormSubmit)}>
                              <FormGroup>
                                <TextFieldAuth
                                  field={'email'}
                                  current={watch('email')}
                                  register={register}
                                  setValue={setValue}
                                  isEmail={true}
                                  required={true}
                                  errors={errors.email}
                                  title={t('auth_email')}
                                  placeholder={t('auth_placeholder_email')} />
                              </FormGroup>
                              <FormGroup>
                                <Button size="lg" className={`btn-block`} color={"success"} type="submit">
                                  {loading ? <Spinner size="sm" color="light" /> : t('reset_password_button')}
                                </Button>
                              </FormGroup>
                            </form>
                          </div>

                          <div className="mt-5 text-center">
                            <p className="mb-0">{t("new_auth.know_pass")} <Link to="/auth-login" className={` ${styles.text_decoration} fw-semibold text-primary text-decoration-underline ml-1`}>{t("new_auth.click_here")}</Link> </p>
                          </div>
                        </div>

                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
            <div className="w-100 d-flex justify-content-center align-items-center mt-5 gap-4"  style={{position:"relative"}}>
              <Link to="/auths/terms" target="_blank" className={"text-muted"}>{t("new_auth.term_service")}</Link>
              <Link to="/auths/policy" target="_blank" className={"text-muted"}>{t("footer_privacy")}</Link>
            </div>
          </div>
        </div>
      </PageContainer>
    </React.Fragment>
  );
};
export default ForgotPassword;
