import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Select from "react-select"
import {Spinner} from "reactstrap"; 
import ContentAlt from "../../../layout/content/ContentAlt";
import Head from "../../../layout/head/Head";
import { Img, PaginationComponent } from "../../../components/Component";

import style from "./index.module.scss";
import {
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
} from "../../../components/Component";
import { Button, Modal, ModalBody } from "reactstrap";
import bg1 from "../../images/pricelist/bg-2.jpg";
import { Link, useParams } from "react-router-dom";
import qrCode from "../../images/PriceHistory/QR-code.png";
import FooterTrial from "../Overview/FooterTrial";
import {
  loadDetailSubscription,
  loadListBill,
  doSubscription
} from "../../../api/project";
import moment from "moment";
import {
  BILL_STATUS_CANCEL,
  BILL_STATUS_WAITING,
  BILL_STATUS_COMPLETE,
  BILL_STATUS_USER_CONFIRM_PAYMENT,
  imagesPriceSeeding,
} from "../../utils/Utils";
import "./index.css";
import card1 from "../../images/pricelist/card/card-hero-1.png";
import card2 from "../../images/pricelist/card/card-hero-2.png";
import card3 from "../../images/pricelist/card/card-hero-3.png";
import card4 from "../../images/pricelist/card/card-hero-4.png";
import card5 from "../../images/pricelist/card/card-hero-5.png";
import card6 from "../../images/pricelist/card/card-hero-6.png";

const images = {
  started: card1,
  growth: card2,
  pro: card3,
  pro_15: card3,
  pro_50: card3,
  business: card4,
  enterprise: card5,
  custom: card6,
  history: card1,
};

export default function Payment() {
  const type = (new URLSearchParams(window.location.search)).get("type") || "history";
  const category = (new URLSearchParams(window.location.search)).get("category") || "";
  const page = parseInt((new URLSearchParams(window.location.search)).get("page")) || 10;
  const months = parseInt((new URLSearchParams(window.location.search)).get("months")) || 1; // default
  const historyRef = useRef();
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const projectId = userInfo.last_project_active;
  const project = useSelector((state) => state.project.project);
  const [renderBills, setRenderBills] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    loadListBill(projectId)
      .then((res) => {
        setListBill(res);
      })
      .catch((ex) => {
        console.log(ex);
      });
  }, [projectId]);


  useEffect(() => {

    if (type === "transaction") {
      loadDetailSubscription(category, page, projectId, months)
        .then((res) => {
          setSubscription(res);

          findPrice();
          const option = res?.options?.map(duration => ({
            value: duration,
            label: `${duration} Tháng`,
          }));
          setDuration(option);
          setMonthSelected({ value: months, label: `${months} Tháng` });
          if (res?.qr_code) {
            setQrCodePayment(res?.qr_code)
            setLoading(false);
          }
        })
        .catch((ex) => {
          console.log(ex);
        });
    } else {
      historyRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, [type, category, page]);

  const [price, setPrice] = useState(0);

  const [detailSubscription, setSubscription] = useState();
  const [qrCodePayment, setQrCodePayment] = useState(qrCode);
  const [listBill, setListBill] = useState([]);
  const [duration, setDuration] = useState([]);
  const [monthSelected, setMonthSelected] = useState({});

  const [isVerifyTransfer, setVerifyTransfer] = useState({
    success: false,
    fail: false,
    loading: false,
  });

  const findPrice = () => {
    for (let key in detailSubscription?.info?.pricing) {
      if (detailSubscription?.info?.pricing.hasOwnProperty(key)) {
        const element = detailSubscription?.info?.pricing[key];
        if (element.duration === parseInt(monthSelected.value)) {
          setPrice(element.price_sale);
        }
      }
    }
  }
  // const handleSelectMonth = (e) => {
  //   setMonthSelected(e.target.value);
  //   if (detailSubscription) {
  //     setPrice(detailSubscription.info.pricing[e.target.value].price_sale || 0);
  //   }
  // };
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  useEffect(() => {
    sliceBills(listBill);
  }, [listBill]);
  const _handleTransfer = () => {
    setVerifyTransfer({ ...isVerifyTransfer, loading: true });
    // const feeSelected = detailSubscription.info.pricing[monthSelected];
    const dataCreateBill = {
      project_id: projectId,
      duration: monthSelected?.value,
      unit_duration: detailSubscription.unit,
      type: detailSubscription.info.type,
      price: price,
      page: detailSubscription.info.page,
    };
    try {
      doSubscription(dataCreateBill)
        .then((res) => {
          if (res.status) {
            setVerifyTransfer({
              ...isVerifyTransfer,
              success: true,
              loading: false,
            });
          } else {
            setVerifyTransfer({
              ...isVerifyTransfer,
              fail: true,
              loading: false,
            });
          }
        })
        .catch((e) => {
          setVerifyTransfer({
            ...isVerifyTransfer,
            fail: true,
            loading: false,
          });
        });
    } catch (e) {
      setVerifyTransfer({ ...isVerifyTransfer, fail: true, loading: false });
    }
  };
  const sliceBills = (list) => {
    let index = 0;
    let new_arr = [];
    while (index < list.length) {
      let sub_arr = list.slice(index, index + 4);
      new_arr.push(sub_arr);
      index += 4;
    }
    setRenderBills(new_arr);
  };
  useEffect(() => {
    setLoading(true);
    loadDetailSubscription(category, page, projectId, monthSelected.value)
        .then((res) => {
          setSubscription(res);
          findPrice();
          // setPrice(Math.ceil(res?.info?.pricing[monthSelected.value]?.price_sale / 1000) * 1000 || 0);
          const option = res?.options?.map(duration => ({
            value: duration,
            label: `${duration} Tháng`,
          }));
          setDuration(option);
          if (res?.qr_code) {
            setQrCodePayment(res?.qr_code)
          }
        })
        .catch((ex) => {
          console.log(ex);
        });
    findPrice();
  }, [monthSelected])
  useEffect(() => {
    if (detailSubscription?.qr_code) {
      setQrCodePayment(detailSubscription?.qr_code);
    }
    setLoading(false);
  }, [detailSubscription])

  const handleSelectChange = (selectedOption) => {
    setMonthSelected(selectedOption);

  };
  const _handleBillStatus = (status) => {
    switch (status) {
      case BILL_STATUS_CANCEL:
        return (
          <>
            <span
              className="status"
              // className="text-sm font-medium font-sans block"
              style={{ color: "#D75D4F" }}
            >
              Đã hủy
            </span>
            <Icon name="info" style={{ color: "#2F80ED" }}></Icon>
          </>
        );
      case BILL_STATUS_WAITING:
        return (
          <>
            <span
              className="status"
              // className="text-sm font-medium font-sans block"
              style={{ color: "#EBBF43" }}
            >
              Đang đối soát
            </span>
            <small>Thời gian tối đa 1 giờ</small>
          </>
        );
      case BILL_STATUS_COMPLETE:
        return (
          <>
            <span
              className="status"
              // className="text-sm font-medium font-sans block"
              style={{ color: "#069697" }}
            >
              Thành công
            </span>
          </>
        );
      case BILL_STATUS_USER_CONFIRM_PAYMENT:
        return (
          <>
            <span
              className="status"
              // className="text-sm font-medium font-sans block"
              style={{ color: "#6A2C62" }}
            >
              Đang chờ xác nhận
            </span>
          </>
        );
    }
  };

  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);
  const formatNumber = (num = "", split = ",") =>
    num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, split) : "";
  return (
    <React.Fragment>
      <Head title="History"></Head>
      <ContentAlt>
        <div className="flex flex-col w-full mt-[18px] sm:mt-4 md:mt-0">
          {/* 1 */}

          <div className="" style={{ backgroundImage: `url(${bg1})` }}>
            {/* Mobile */}

            {/* <div className="flex flex-col items-center gap-y-4 py-9 px-4 sm:hidden">

              <div className="flex flex-col items-center justify-center text-justify gap-y-2">
                <h2
                  className="text-[#6A2C62] font-sans 
                font-bold text-[33px] tracking-tighter"
                >
                  <span style={{ zIndex: 1, position: "relative" }}>
                    Quét mã hoặc Chuyển khoản theo cú pháp
                  </span>
                  <div
                    className={`${style.highlight} ${style.highlight__blue}`}
                  ></div>
                </h2>
                <h5 className="text-[#132243] font-sans font-semibold text-[16px] ">
                  Vui lòng quét mã hoặc chuyển khoản theo thông tin bên dưới,
                  với nội dung chuyển khoản:{" "}
                  <span style={{ color: "#FD1F6D", fontWeight: "bold" }}>
                    {project?.code || ""} {project?.name || ""}
                  </span>
                  . Bấm "Xác nhận đã chuyển khoản" sau khi hoàn tất, SO9 sẽ đối
                  soát và kích hoạt gói cước trong tối đa 1 giờ.
                </h5>
              </div>

     
              <div>
                <Img src={qrCode} alt="qr code" />
              </div>


              <div className="flex flex-col gap-y-4 items-start">
                <div className="flex gap-x-4 items-center justify-center">
                  <Img
                    className="m-0 p-1 bg-white h-[72px] w-[72px] object-contain"
                    src={images[id]}
                    alt="avt"
                  />
                  <div className="flex flex-col items-start gap-y-3 ">
                    <h2 className="text-2xl text-[#069697] font-sans font-bold ">
                      {detailSubscription?.name || "Unknown"}
                    </h2>
                    {detailSubscription &&
                      detailSubscription.options.length > 0 && (
                        <select
                          onChange={handleSelectMonth}
                          className="w-full text-center py-[3px] font-sans font-medium rounded border-none focus:outline-none"
                          name="months"
                        >
                          {detailSubscription.options.map((month) => {
                            return (
                              <option
                                className="font-sans font-medium"
                                value={month}
                                selected={month === 1}
                              >
                                {month} tháng
                              </option>
                            );
                          })}
                        </select>
                      )}
                  </div>
                </div>

                <div className="flex items-center gap-x-1">
                  <div className="flex flex-col gap-y-1">
                    <p className="font-sans m-0 text-[16px] text-[#132243] font-semibold tracking-tighter">
                      Ngân hàng:
                    </p>
                    <p className="font-sans m-0 text-[16px] text-[#132243] font-semibold tracking-tighter">
                      Số tài khoản:
                    </p>
                    <p className="font-sans m-0 text-[16px] text-[#132243] font-semibold">
                      Tên tài khoản:
                    </p>
                    <p className="font-sans m-0 text-[16px] text-[#132243] font-semibold tracking-tighter">
                      Số tiền:
                    </p>
                    <p className="font-sans m-0 text-[16px] text-[#132243] font-semibold tracking-tighter">
                      Nội dung chuyển khoản:
                    </p>
                  </div>
                  <div className="flex flex-col gap-y-1">
                    <p className="font-sans m-0 text-[16px] text-[#6A2C62] font-bold tracking-tighter">
                      VP Bank
                    </p>
                    <p className="font-sans m-0 text-[16px] text-[#6A2C62] font-bold tracking-tighter">
                      87681246
                    </p>
                    <p className="font-sans m-0 text-[16px] text-[#6A2C62] font-bold tracking-tighter">
                      VU NGOC KHANH
                    </p>
                    <p className="font-sans m-0 text-[16px] text-[#6A2C62] font-bold tracking-tighter">
                      {formatNumber(price) || 0} đ
                    </p>
                    <p className="font-sans m-0 text-[16px] text-[#6A2C62] font-bold tracking-tighter">
                      {project?.code || ""} {project?.name || ""}
                    </p>
                  </div>
                </div>
              </div>


              <div className="flex gap-x-1 items-center">

                <Link to={process.env.PUBLIC_URL + "/admin/project/pricing"}>
                  <button
                    className="bg-[#FD1F6D] px-2 h-[40px] sm:w-full rounded-[4px] inline-flex 
                items-center justify-center hover:opacity-90 border-none focus:outline-none"
                  >
                    <Icon name="arrow-left text-white" />
                    <span className="text-white text-[14px]">QUAY LẠI</span>
                  </button>
                </Link>
                <button
                  className="bg-[#27AE60] px-2 h-[40px] sm:w-full rounded-[4px] inline-flex 
                items-center flex-nowrap justify-center hover:opacity-90 border-none focus:outline-none"
                  onClick={() => _handleTransfer()}
                  disabled={isVerifyTransfer.loading || id === "history"}
                >
                  {isVerifyTransfer && isVerifyTransfer.loading ? (
                    <div
                      className="spinner-border"
                      role="status"
                      style={{
                        width: "16px",
                        height: "16px",
                      }}
                    ></div>
                  ) : (
                    <span className="text-white text-[14px]">
                      Xác nhận đã chuyển khoản
                    </span>
                  )}
                </button>
              </div>



              <p className="text-sm italic font-sans font-semibold text-[#828282] text-justify">
                Nếu gặp bất cứ khó khăn gì cần trợ giúp. Đừng lo hãy{" "}
                <a
                  className="underline underline-offset-1 text-[#2F80ED]"
                  href="https://www.facebook.com/messages/t/103638805810736/"
                  target="_blank"
                >
                  liên hệ với chúng tôi
                </a>
                , được hỗ trợ bạn là niềm vui của Nicer
              </p>
            </div>  */}

            {/* Tablet */}

            <div className="infor">
              {/* <div className="flex flex-col md:gap-y-4 py-6 px-5"> */}
              <Link to={process.env.PUBLIC_URL + "/admin/project/pricing"}>
                <button
                  className="button-back"
                //   className="bg-[#FD1F6D] px-5 h-[40px] rounded-[4px] inline-flex
                // items-center justify-center hover:opacity-90 border-none focus:outline-none"
                >
                  <Icon name="arrow-left text-white" />
                  <span className="text-white">QUAY LẠI</span>
                </button>
              </Link>
              <div className="infor-container">
                {/* <div className="flex items-center md:items-start gap-x-6 md:gap-x-14"> */}
                <div className="qr-container">
                  {/* <div className="flex flex-col gap-y-4"> */}
                  <div className="d-flex justify-content-center align-items-center " style={{width:"250px",height:"320px"}}>
                    {isLoading ? <Spinner size="lg" />: <Img src={qrCodePayment} alt="qr code" width={250} height={320} />} 
                  </div>
                  <button
                    className="button-confirm"
                    //   className="bg-[#27AE60] px-2 h-[40px] rounded inline-flex
                    // items-center flex-nowrap justify-center hover:opacity-90 border-none focus:outline-none"
                    onClick={() => _handleTransfer()}
                    disabled={isVerifyTransfer.loading || type !== "transaction"}
                  >
                    {isVerifyTransfer && isVerifyTransfer.loading ? (
                      <div
                        className="spinner-border"
                        role="status"
                        style={{
                          width: "16px",
                          height: "16px",
                        }}
                      ></div>
                    ) : (
                      <span style={{ color: "#ffffff" }}>
                        Xác nhận đã chuyển khoản
                      </span>
                    )}
                  </button>
                </div>

                <div className="infor-right">
                  {/* <div className="flex flex-col flex-1 gap-y-4 justify-between"> */}
                  <div className="infor-right-top">
                    {/* <div className="flex flex-col items-start gap-y-2"> */}
                    <h2
                      className="text-title"
                    // className="text-[#6A2C62] font-sans
                    // font-bold text-[33px] tracking-tighter"
                    >
                      <span style={{ zIndex: 1, position: "relative" }}>
                        Quét mã hoặc Chuyển khoản theo cú pháp
                      </span>
                      <div
                        className={`${style.highlight} ${style.highlight__blue}`}
                      ></div>
                    </h2>
                    <h5 className="h5-text">
                      {/* <h5 className="text-[#132243] leading-snug font-sans font-semibold text-base md:mr-24 "> */}
                      Vui lòng quét mã hoặc chuyển khoản theo thông tin bên
                      dưới, với nội dung chuyển khoản:{" "}
                      <span style={{ color: "#FD1F6D", fontWeight: "bold" }}>
                        {project?.code || ""} {project?.name || "" } SOC{category ==="pro" ? "PRO" : "BUS" }{page || "" }
                      </span>
                      . Bấm "Xác nhận đã chuyển khoản" sau khi hoàn tất, SO9 sẽ
                      đối soát và kích hoạt gói cước trong tối đa 1 giờ.
                    </h5>
                  </div>

                  <div className="detail">
                    {/* <div className="flex flex-col gap-y-4 items-start"> */}
                    <div className="detail-avt-container">
                      {/* <div className="flex gap-x-4 items-center justify-center"> */}
                      {type === "transaction" && (
                        <Img
                          className="avt"
                          // className="m-0 p-1 bg-white h-[72px] w-[72px] object-contain"
                          src={images[category]}
                          alt="avt"
                        />
                      )}
                      <div className="select-container">
                        {/* <div className="flex flex-col items-start justify-between gap-y-4"> */}
                        <h2 className="select-title">
                          {/* <h2 className="m-0 text-2xl text-[#069697] font-sans font-bold pr-2"> */}
                          {detailSubscription?.info.name || ""}

                        </h2>
                        {type === "transaction" && (
                          <Select
                            isSearchable={false}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={duration}
                            value={monthSelected}
                            onChange={handleSelectChange}

                          >

                          </Select>
                        )}


                        {/* {detailSubscription &&
                          detailSubscription.options.length > 0 && (
                            <select
                              onChange={handleSelectMonth}
                              className="select-sel"
                              name="months"
                            >
                              {detailSubscription.options.map((month) => {
                                return (
                                  <option
                                    // className="font-sans font-medium"
                                    style={{ fontWeight: 500 }}
                                    value={month}
                                    selected={month === 1}
                                  >
                                    {month} tháng
                                  </option>
                                );
                              })}
                            </select>
                          )} */}
                        <div></div>
                      </div>
                    </div>

                    <div className="detail-info">
                      {/* <div className="flex items-center gap-x-14"> */}
                      <div className="">
                        {/* <div className="flex flex-col gap-y-1"> */}
                        <p className="detail-info-text-left">
                          {/* <p className="font-sans m-0 text-[16px] text-[#132243] font-semibold tracking-tighter"> */}
                          Ngân hàng:
                        </p>
                        <p className="detail-info-text-left">
                          {/* <p className="font-sans m-0 text-[16px] text-[#132243] font-semibold tracking-tighter"> */}
                          Số tài khoản:
                        </p>
                        <p className="detail-info-text-left">
                          {/* <p className="font-sans m-0 text-[16px] text-[#132243] font-semibold tracking-tighter"> */}
                          Tên tài khoản:
                        </p>
                        <p className="detail-info-text-left">
                          {/* <p className="font-sans m-0 text-[16px] text-[#132243] font-semibold tracking-tighter"> */}
                          Số tiền:
                        </p>
                        <p className="detail-info-text-left">
                          {/* <p className="font-sans m-0 text-[16px] text-[#132243] font-semibold tracking-tighter"> */}
                          Nội dung chuyển khoản:
                        </p>
                      </div>
                      <div className="">
                        {/* <div className="flex flex-col gap-y-1"> */}
                        <p className="detail-info-text-right">
                          {/* <p className="font-sans m-0 text-[16px] text-[#6A2C62] font-bold tracking-tighter"> */}
                          VP Bank
                        </p>
                        <p className="detail-info-text-right">
                          {/* <p className="font-sans m-0 text-[16px] text-[#6A2C62] font-bold tracking-tighter"> */}
                          87681246
                        </p>
                        <p className="detail-info-text-right">
                          {/* <p className="font-sans m-0 text-[16px] text-[#6A2C62] font-bold tracking-tighter"> */}
                          VU NGOC KHANH
                        </p>
                        <p className="detail-info-text-right">
                          {/* <p className="font-sans m-0 text-[16px] text-[#6A2C62] font-bold tracking-tighter"> */}
                          {formatNumber(price) || 0} đ
                        </p>
                        <p className="detail-info-text-right">
                          {/* <p className="font-sans m-0 text-[16px] text-[#6A2C62] font-bold tracking-tighter"> */}
                          {project?.code || ""} {project?.name || "" } SOC{category ==="pro" ? "PRO" : "BUS" }{page || "" }
                        </p>
                      </div>
                    </div>

                    <p className="detail-text-bottom">
                      {/* <p className="m-0 text-sm italic font-sans font-semibold text-[#828282] text-justify"> */}
                      Nếu gặp bất cứ khó khăn gì cần trợ giúp. Đừng lo hãy{" "}
                      <a
                        style={{
                          textDecoration: "underline",
                          color: "color: #2F80ED",
                        }}
                        // className="underline underline-offset-1 text-[#2F80ED]"
                        href="https://www.facebook.com/messages/t/103638805810736/"
                        target="_blank"
                      >
                        liên hệ với chúng tôi
                      </a>
                      , được hỗ trợ bạn là niềm vui của Nicer
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 2 */}
          <div style={{ background: "#f5f6fa" }} id="history" ref={historyRef} >
            {/* Container */}
            <div className="table-container">
              {/* <div className="flex flex-col gap-y-6 px-5 py-4"> */}
              {/* Title */}
              <div className="table-container-title">
                {/* <div className="flex flex-col justify-center items-start gap-y-2"> */}
                <h2
                  className="text-title"
                //   className="text-[#6A2C62] font-sans
                // font-bold sm:font-bold text-[33px] tracking-tighter"
                >
                  <span style={{ zIndex: 1, position: "relative" }}>
                    Lịch sử thanh toán
                  </span>
                  <div
                    className={`${style.highlight} ${style.highlight__green}`}
                  ></div>
                </h2>
              </div>
              {/* Table */}
              <div>
                <DataTable
                  className="card-stretch"
                  style={{ minHeight: "300px" }}
                >
                  <DataTableBody compact>
                    <DataTableHead>
                      <DataTableRow size="sm">
                        <span className="table-text">
                          {/* <span className="text-sm font-medium font-sans text-[#6A2C62]"> */}
                          Ngày thanh toán
                        </span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <span className="table-text">
                          {/* <span className="text-sm font-medium font-sans text-[#6A2C62]"> */}
                          Mã thanh toán
                        </span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span className="table-text">
                          {/* <span className="text-sm font-medium font-sans text-[#6A2C62]"> */}
                          Loại hoá đơn
                        </span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span className="table-text">
                          {/* <span className="text-sm font-medium font-sans text-[#6A2C62]"> */}
                          Gói cước
                        </span>
                      </DataTableRow>
                      <DataTableRow size="sm">
                        <span className="table-text">
                          {/* <span className="text-sm font-medium font-sans text-[#6A2C62]"> */}
                          Thời hạn
                        </span>
                      </DataTableRow>
                      <DataTableRow size="sm">
                        <span className="table-text">
                          {/* <span className="text-sm font-medium font-sans text-[#6A2C62]"> */}
                          Số tiền
                        </span>
                      </DataTableRow>
                      <DataTableRow size="md" className={"text-center"}>
                        <span className="table-text">
                          {/* <span className="text-sm font-medium font-sans text-[#6A2C62]"> */}
                          Trạng thái
                        </span>
                      </DataTableRow>
                    </DataTableHead>
                    {/* Data */}
                    {renderBills[currentPage - 1]?.map((bill, index) => {
                      return (
                        <DataTableItem key={index}>
                          <DataTableRow size="sm">
                            <span className="table-text">
                              {/* <span className="text-sm font-medium font-sans text-[#6A2C62]"> */}
                              {moment(bill?.created_at).format("DD.MM.YYYY")}
                            </span>
                          </DataTableRow>
                          <DataTableRow size="lg">
                            <span className="table-text">
                              {/* <span className="text-sm font-medium font-sans text-[#6A2C62]"> */}
                              {bill._id || ""}
                            </span>
                          </DataTableRow>
                          <DataTableRow size="md">
                            <span className="table-text">
                              {bill.type === "project"
                                ? "Hóa đơn Gói dự án"
                                : "Hóa đơn Tài khoản seeding"}
                            </span>
                          </DataTableRow>
                          <DataTableRow size="md">
                            <span className="table-text">
                              {/* <span className="text-sm font-medium font-sans text-[#6A2C62]"> */}
                              {bill.type === "project" ? (
                                <Img
                                  src={images[bill.info.type]}
                                  style={{ width: "2rem", height:"2rem", marginRight: "5px", objectFit:"contain" }}
                                />
                              ) : (
                                <Img
                                  src={imagesPriceSeeding[bill.info.type]}
                                  style={{ width: "2rem", height:"2rem", marginRight: "5px" }}
                                />
                              )}
                              {bill.info.name || capitalize(bill.info.type)}
                            </span>
                          </DataTableRow>
                          <DataTableRow size="sm">
                            <span className="table-text">
                              {/* <span className="text-sm font-medium font-sans text-[#6A2C62]"> */}
                              {bill.info.duration || 1} tháng
                            </span>
                          </DataTableRow>
                          <DataTableRow size="sm">
                            <span className="table-text">
                              {/* <span className="text-sm font-medium font-sans text-[#6A2C62]"> */}
                              {bill.type === "project"
                                ? formatNumber(bill.info.total_amount) || 0
                                : formatNumber(bill.info.price_per_month) || 0}
                              đ
                            </span>
                          </DataTableRow>
                          <DataTableRow size="md" className={"text-center"}>
                            {_handleBillStatus(bill.status)}
                          </DataTableRow>
                        </DataTableItem>
                      );
                    })}
                  </DataTableBody>
                </DataTable>

                {listBill.length > 0 ? (
                  <div className="d-flex justify-content-center" style={{marginTop:"56px"}}> 
                    <PaginationComponent
                      noDown
                      itemPerPage={4}
                      totalItems={listBill?.length}
                      paginate={paginate}
                      currentPage={currentPage}
                    />

                  </div>

                ) : (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* footer */}
          <FooterTrial></FooterTrial>
        </div>
      </ContentAlt>
      {/* Transfer verifying success */}
      <Modal
        isOpen={isVerifyTransfer.success}
        toggle={() =>
          setVerifyTransfer({
            ...isVerifyTransfer,
            success: !isVerifyTransfer.success,
          })
        }
      >
        <ModalBody className="modal-body-lg text-center">
          <div className="nk-modal">
            <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-check bg-success"></Icon>
            <h4 className="nk-modal-title">Hoàn thành</h4>
            <div className="nk-modal-text">
              <div className="caption-text">
                Bạn đã xác nhận chuyển khoản thành công. SO9 sẽ đối soát và xác
                nhận đơn hàng của bạn trong tối đa 1 giờ!
              </div>
            </div>
            <div className="nk-modal-action">
              <Button
                color="light"
                size="lg"
                className="btn-mw"
                onClick={() => {
                  loadListBill(projectId)
                    .then((res) => {
                      setListBill(res);
                    })
                    .catch((ex) => {
                      console.log(ex);
                    });
                  setVerifyTransfer({ ...isVerifyTransfer, success: false });
                }}
              >
                OK
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* Transfer verifying fail */}
      <Modal
        isOpen={isVerifyTransfer.fail}
        toggle={() =>
          setVerifyTransfer({
            ...isVerifyTransfer,
            fail: !isVerifyTransfer.fail,
          })
        }
      >
        <ModalBody className="modal-body-lg text-center">
          <div className="nk-modal">
            <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></Icon>
            <h4 className="nk-modal-title">
              Lỗi không thể xác nhận chuyển khoản!
            </h4>
            <div className="nk-modal-text">
              <p className="lead">Bạn vui lòng thử lại!</p>
              <p className="text-soft">
                Nếu cần sự trợ giúp xin vui lòng liên hệ đến email
                so9@gmail.com.
              </p>
            </div>
            <div className="nk-modal-action mt-5">
              <Button
                color="light"
                size="lg"
                className="btn-mw"
                onClick={() => () =>
                  setVerifyTransfer({
                    ...isVerifyTransfer,
                    fail: !isVerifyTransfer.fail,
                  })}
              >
                Quay lại
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}