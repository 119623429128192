import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import { GetlistIcon } from '../../../../api/createPost';
import { fetchIconData } from '../../../../libs/helper';
import { Collapse } from 'reactstrap';
import { RiEmotionLine, RiTBoxLine } from '@remixicon/react';

const SpinContent = ({ isPost, defaultMode, onAppendContent }) => {

    const { t } = useTranslation();

    const [iconData, setIconData] = useState();
    const [isOpen, setIsOpen] = useState("");

    const toggleWithIcons = (id) => {
        if (isOpen !== id) {
            setIsOpen(id);
        } else {
            setIsOpen("0");
        }
    };


    const getIcon = async () => {
        try {
            const iconRes = await GetlistIcon();
            if (iconRes.status) {
                const data = {
                    value: iconRes.data,
                    expiration: Date.now()
                };
                localStorage.setItem('IconData', JSON.stringify(data));
                setIconData(iconRes.data);
            }
        } catch (error) {

        }
    }
    useEffect(() => {
        const storedIconData = fetchIconData();
        if (!storedIconData || storedIconData.expiration >= Date.now()) {
            getIcon();
        } else {
            setIconData(storedIconData.value)
        }
    }, [])

    return (
        <React.Fragment>

            <div className="accordion">
                <div className="accordion-item">
                    <div
                        className="accordion-head collapsed"
                        onClick={() => toggleWithIcons("1")}
                    >
                        <h6 className="title"><RiEmotionLine className='me-2'></RiEmotionLine>{t('store_post.spin_content.icon.title')}</h6>
                        <span className="accordion-icon"></span>
                    </div>
                    <Collapse
                        className="accordion-body"
                        isOpen={isOpen === "1" ? true : false}
                    >
                        <div className="accordion-inner">
                            <p className='fw-normal fs-13 text-muted'>{t('store_post.spin_content.icon.description')}</p>
                            {iconData && Object.keys(iconData).map((key) => {
                                const icons = iconData[key];
                                return (
                                    <div className='mb-3' key={`icon${key}`}>
                                        <div className='d-flex justify-content-between align-items-center mb-2'>
                                            <p className='text-secondary fw-normal mb-0'>{`@icon{${key}}`}</p>
                                            {isPost &&
                                                <div
                                                    className='bg-secondary px-3 py-1 rounded text-white cursor-pointer fs-13'
                                                    onClick={() => onAppendContent(`@icon{${key}}`)}
                                                >
                                                    {t('store_post.spin_content.icon.button.use')}
                                                </div>}
                                        </div>
                                        <div className='d-flex justify-content-start w-100 flex-wrap'>
                                            {icons.map((icon, index) => (
                                                <p className='m-0' key={`iconList${key}-${index}`}>{icon}</p>
                                            ))}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </Collapse>
                </div>
                <div className="accordion-item">
                    <div
                        className="accordion-head collapsed"
                        onClick={() => toggleWithIcons("2")}
                    >
                        <h6 className="title">
                            <RiTBoxLine className='me-2'></RiTBoxLine>  {t('store_post.spin_content.text.title')}
                        </h6>
                        <span className="accordion-icon"></span>
                    </div>
                    <Collapse
                        className="accordion-body"
                        isOpen={isOpen === "2" ? true : false}
                        style={{ fontSize: 13 }}
                    >
                        <div className="accordion-inner">
                            <div className='text-muted mb-0'>
                                {t('store_post.spin_content.text.description')}
                                <span className='text-danger'>|</span>.</div>
                            <div className='mb-0 text-muted mt-3'>
                                {t('store_post.spin_content.text.structure.title')}
                            </div>
                            <div className='d-flex justify-content-between align-items-center mb-2'>
                                <div className='text-muted mb-0'><span className='' style={{ color: "#3577F1" }}>{'#text{'}</span>choice 1<span className='text-danger'>|</span> choice 2<span className='text-danger'>|</span> choice 3<span className='text-danger'>|</span> etc<span className='' style={{ color: "#3577F1" }}>{'}'}</span></div>
                                {isPost &&
                                    <div
                                        className='bg-secondary px-3 py-1 rounded text-white cursor-pointer fs-13'
                                        onClick={() => onAppendContent(`#text{`)}
                                    >
                                        {t('store_post.spin_content.text.button.use')}
                                    </div>}
                            </div>
                            <div className='text-muted'> {t('store_post.spin_content.text.example')}</div>
                        </div>
                    </Collapse>
                </div>
            </div>

        </React.Fragment>
    );
};

export default SpinContent;