import React, { useEffect } from "react";
import menu from "./MenuData";
import Icon from "../../components/icon/Icon";
import classNames from "classnames";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IconChangeProject } from "../../libs/icon";
import { useSelector } from "react-redux";
import twStyle from "../../assets/scss/extend/tailwind-clone/index.module.scss"
import { URL_PATH } from "../../config/urlPath";

const MenuHeading = ({ heading, hasIcon, urlIcon }) => {
  const project = useSelector((state) => state.project.project);
  const { t } = useTranslation();
  return (
    <li className="nk-menu-heading">
      <div className={`${twStyle.flex} ${twStyle.itemsCenter}`}>
        <div className={`${twStyle.flex1} ${twStyle.truncate} ${twStyle.flex} ${twStyle.itemsCenter}`}>
          <div className={`overline-title text-primary-alt ${twStyle.textXs} ${twStyle.fontBold} ${twStyle.mr1}`}>{t(heading).toUpperCase()}</div>
          {
            heading === 'menu.workspace' && project?.name && (
              <div className={`overline-title ${twStyle.textXs} ${twStyle.fontBold}`} style={{ color: "#FD1F6D" }}> {project?.name?.toUpperCase() || ""}</div>
            )
          }
        </div>
        {
          hasIcon && (
            <div className={`ml-1 text-xs`}>
              <Link
                to={urlIcon}
              >
                <IconChangeProject />
              </Link>
            </div>
          )
        }
      </div>
    </li>
  );
};

const MenuItem = ({ icon, conversation, link, text, sub, newTab, sidebarToggle, mobileView, ...props }) => {
  const conversationUnread = useSelector((state) => state.conversation.conversationUnread);
  const { t } = useTranslation();
  let currentUrl;
  const userInfor = JSON.parse(localStorage.getItem('userInfor'));

  const conversationStyle = {
    fontWeight: "700",
    fontSize: "12px",
    color: "#FFFFFF",
    backgroundColor: "#FD1F6D",
    padding: "0px 6px",
    borderRadius: "100px"
  }

  const toggleActionSidebar = (e) => {
    if (!sub && !newTab && mobileView) {
      sidebarToggle(e);
    }
  };

  if (window.location.pathname !== undefined) {
    currentUrl = window.location.pathname;
  } else {
    currentUrl = null;
  }

  const menuHeight = (el) => {
    var totalHeight = [];
    for (var i = 0; i < el.length; i++) {
      var margin =
        parseInt(window.getComputedStyle(el[i]).marginTop.slice(0, -2)) +
        parseInt(window.getComputedStyle(el[i]).marginBottom.slice(0, -2));
      var padding =
        parseInt(window.getComputedStyle(el[i]).paddingTop.slice(0, -2)) +
        parseInt(window.getComputedStyle(el[i]).paddingBottom.slice(0, -2));
      var height = el[i].clientHeight + margin + padding;
      totalHeight.push(height);
    }
    totalHeight = totalHeight.reduce((sum, value) => (sum += value));
    return totalHeight;
  };

  const makeParentActive = (el, childHeight) => {
    let element = el.parentElement.parentElement.parentElement;
    let wrap = el.parentElement.parentElement;
    if (element.classList[0] === "nk-menu-item") {
      element.classList.add("active");
      const subMenuHeight = menuHeight(el.parentNode.children);
      wrap.style.height = subMenuHeight + childHeight - 50 + "px";
      makeParentActive(element);
    }
  };

  useEffect(() => {
    var element = document.getElementsByClassName("nk-menu-item active current-page");
    var arrayElement = [...element];

    arrayElement.forEach((dom) => {
      if (dom.parentElement.parentElement.parentElement.classList[0] === "nk-menu-item") {
        dom.parentElement.parentElement.parentElement.classList.add("active");
        const subMenuHeight = menuHeight(dom.parentNode.children);
        dom.parentElement.parentElement.style.height = subMenuHeight + "px";
        makeParentActive(dom.parentElement.parentElement.parentElement, subMenuHeight);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const menuToggle = (e) => {
    e.preventDefault();
    var self = e.target.closest(".nk-menu-toggle");
    var parent = self.parentElement;
    var subMenu = self.nextSibling;
    var subMenuItem = subMenu.childNodes;
    var parentSiblings = parent.parentElement.childNodes;
    var parentMenu = parent.closest(".nk-menu-wrap");
    //For Sub Menu Height
    var subMenuHeight = menuHeight(subMenuItem);
    // Get parent elements
    const getParents = (el, parentSelector) => {
      parentSelector = document.querySelector(".nk-menu");
      if (parentSelector === undefined) {
        parentSelector = document;
      }
      var parents = [];
      var p = el.parentNode;
      while (p !== parentSelector) {
        var o = p;
        parents.push(o);
        p = o.parentNode;
      }
      parents.push(parentSelector);
      return parents;
    };
    var parentMenus = getParents(self);
    if (!parent.classList.contains("active")) {
      // For Parent Siblings
      for (var j = 0; j < parentSiblings.length; j++) {
        parentSiblings[j].classList.remove("active");
        if (typeof parentSiblings[j].childNodes[1] !== "undefined") {
          parentSiblings[j].childNodes[1].style.height = 0;
        }
      }
      if (parentMenu !== null) {
        if (!parentMenu.classList.contains("sub-opened")) {
          parentMenu.classList.add("sub-opened");

          for (var l = 0; l < parentMenus.length; l++) {
            if (typeof parentMenus !== "undefined") {
              if (parentMenus[l].classList.contains("nk-menu-wrap")) {
                parentMenus[l].style.height = subMenuHeight + parentMenus[l].clientHeight + "px";
              }
            }
          }
        }
      }
      // For Current Element
      parent.classList.add("active");
      subMenu.style.height = subMenuHeight + "px";
    } else {
      parent.classList.remove("active");
      if (parentMenu !== null) {
        parentMenu.classList.remove("sub-opened");
        for (var k = 0; k < parentMenus.length; k++) {
          if (typeof parentMenus !== "undefined") {
            if (parentMenus[k].classList.contains("nk-menu-wrap")) {
              parentMenus[k].style.height = parentMenus[k].clientHeight - subMenuHeight + "px";
            }
          }
        }
      }
      subMenu.style.height = 0;
    }
  };

  const menuItemClass = classNames({
    "nk-menu-item": true,
    "has-sub": sub,
    "active current-page": currentUrl === process.env.PUBLIC_URL + link,
  });
  return (
    <li className={menuItemClass} onClick={(e) => toggleActionSidebar(e)}>
      {newTab ? (
        <Link
          to={`${userInfor.last_project_active !== '' ? process.env.PUBLIC_URL + link : process.env.PUBLIC_URL + URL_PATH.list_project}`}
          target="_blank"
          rel="noopener noreferrer"
          className="nk-menu-link"
        >
          {icon ? (
            <span className="nk-menu-icon">
              <Icon name={icon} />
            </span>
          ) : null}
          <span className="nk-menu-text" style={{ flexGrow: conversation && conversationUnread > 0 ? "0.5" : "1" }}>{t(text)}</span>
          {conversation && conversationUnread > 0 && (
            <span style={{ color: "red", padding: "5px", borderRadius: "50%" }}>
              {conversationUnread>99 ? "99+" : conversationUnread}
            </span>
          )}
        </Link>
      ) : (
        <NavLink
          to={`${userInfor.last_project_active !== '' ? process.env.PUBLIC_URL + link : process.env.PUBLIC_URL + URL_PATH.list_project}`}
          className={`nk-menu-link${sub ? " nk-menu-toggle" : ""}`}
          onClick={sub ? menuToggle : null}
        >
          {icon ? (
            <span className="nk-menu-icon">
              <Icon name={icon} />
            </span>
          ) : null}
          <span className="nk-menu-text" style={{ flexGrow: conversation && conversationUnread > 0 ? "0.5" : "1" }}>{t(text)}</span>
          {conversation && conversationUnread > 0 && (
            <span style={conversationStyle}>
              {conversationUnread>99 ? "99+" : conversationUnread}
            </span>
          )}
        </NavLink>
      )}
      {sub ? (
        <div className="nk-menu-wrap">
          <MenuSub sub={sub} sidebarToggle={sidebarToggle} mobileView={mobileView} />
        </div>
      ) : null}
    </li>
  );
};

const MenuSub = ({ icon, link, text, sub, sidebarToggle, mobileView, ...props }) => {
  return (
    <ul className="nk-menu-sub" style={props.style}>
      {sub.map((item) => (
        <MenuItem
          link={item.link}
          icon={item.icon}
          text={item.text}
          sub={item.subMenu}
          key={item.text}
          newTab={item.newTab}
          sidebarToggle={sidebarToggle}
          mobileView={mobileView}
        />
      ))}
    </ul>
  );
};

function openNewPage() {
  window.open('https://so9.vn/9reup/', '_blank');
}
const openHubPage = () => {
  window.open('https://chat.so9.vn/', '_blank');
}
const handleOpenHub = () => {
    const doc = document.getElementsByClassName("nk-sidebar-menu");
    const elements = doc[0].querySelectorAll('a[href="/engagement"]');
    elements.forEach((element) => {
      element.setAttribute('href', 'https://chat.so9.vn/');
      element.setAttribute('target', '_blank');
      const parentElement = element.parentElement;
      parentElement.addEventListener('click', openHubPage);
    });

}

const Menu = ({ sidebarToggle, mobileView }) => {
  useEffect(() => {
    // const menuElement = document.getElementsByClassName("nk-menu");
    handleOpenHub();
    const elements = document.querySelectorAll('a[href="/scan"]');
    elements.forEach((element) => {
      element.setAttribute('href', 'https://so9.vn/9reup/');
      element.setAttribute('target', '_blank');
      const parentElement = element.parentElement;
      parentElement.addEventListener('click', openNewPage);
    });
  }, []);


  return (
    <ul className="nk-menu">
      {menu.map((item) =>
        item.heading ? (
          <MenuHeading heading={item.heading} key={item.heading} hasIcon={item.hasIcon ?? false} urlIcon={item.hasIcon && item.link ? item.link : ''} />
        ) : (
          <MenuItem
            key={item.text}
            link={item.link}
            icon={item.icon}
            conversation={item.conversation || false}
            text={item.text}
            sub={item.subMenu}
            sidebarToggle={sidebarToggle}
            mobileView={mobileView}
          />
        )
      )}
    </ul>
  );
};

export default Menu;
