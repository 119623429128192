import axios from "axios";
import Auth from "../auth/auth";

function getAuthToken() {
  return window.localStorage.getItem("accessToken") ?? "";
}

const API = axios.create({
  baseURL: process.env.REACT_APP_URL_API + "api/v1",
});

API.interceptors.request.use((config) => {
  config.headers = {
    ...(config.headers ?? {}),
    Authorization: `Bearer ${getAuthToken()}`,
  };
  return { ...config };
});

let isRefreshing = false
let subscribers = []

function subscribeTokenRefresh(cb) {
  subscribers.push(cb)
}

function onRrefreshed(token) {
  subscribers.map(cb => cb(token))
}

API.interceptors.response.use(response => response, async error => {
  const { config, response: { status } } = error
  const originalConfig = error.config;

  // Access Token was expired
  const token = getAuthToken()
  if (status === 401 && !!token) {

    if (!isRefreshing) {
      isRefreshing = true;

      Auth.refreshToken().then(token => {
        error.config.headers['Authorization'] = 'Bearer ' + token;
        isRefreshing = false;
        onRrefreshed(token);
        subscribers = []
        return API(config);
      });
    }

    const requestSubscribers = new Promise(resolve => {
      subscribeTokenRefresh((token) => {
        originalConfig.headers = {}
        originalConfig.headers.Authorization = `Bearer ${token}`
        resolve(API(originalConfig))
      })
    })
    return requestSubscribers
  }
  if (status === 400) {
    return error.response;
  }
  return Promise.reject(error);
}
);

export { API };
