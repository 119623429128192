import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import {
  // Offcanvas,
  // OffcanvasHeader,
  // OffcanvasBody,
  Collapse,
  Input,
  Col,
  Label,
  Button,
  Spinner,
} from "reactstrap";
// import style from "../index.module.scss";
//redux

//import Constant

//SimpleBar
import SimpleBar from "simplebar-react";

const RightSidebar = ({
  channel,
  open,
  toggle,
  onClearFilter,
  onFilter,
  listChannel,
  groupChannel,
  handleListChange,
  handleGroupChange,
  loadingFilter,
  isEnterprise,
  staffSelected,
  staffs,
  handleStaffsChange,
  setOpenFilter,
}) => {
  const { t } = useTranslation();

  const [isGroup, setIsGroup] = useState(false);
  const [channelSelect, setChannelSelect] = useState(
    listChannel
      ?.filter((c) => channel?.includes(c?._id))
      ?.map((item) => ({
        value: item._id,
        label: item.name,
      })) || []
  );
  const [groupSelect, setGroupSelect] = useState();
  const [staffSelect, setStaffSelect] = useState();

  useEffect(() => {
    setChannelSelect(
      listChannel
        ?.filter((c) => channel?.includes(c?._id))
        ?.map((item) => ({
          value: item._id,
          label: item.name,
        })) || []
    );

    setGroupSelect(
      groupChannel
        ?.filter((c) => channel?.includes(c?._id))
        ?.map((item) => ({
          value: item._id,
          label: item.name,
          chanel_ids: item.channel_ids,
        })) || []
    );

    setStaffSelect(
      staffs
        ?.filter((c) => staffSelected?.includes(c?.id))
        ?.map((item) => ({
          value: item.id,
          label: item.name,
        })) || []
    );
  }, [open]);

  useEffect(() => {
    if (open) {
      document.body.classList.add("toggle-shown");
    } else {
      document.body.classList.remove("toggle-shown");
    }
  }, []);

  const handleClear = () => {
    setOpenFilter(false);
    onClearFilter();
    setChannelSelect([]);
    setGroupSelect([]);
    setStaffSelect([]);
  };
  const handleFilter = () => {
    setOpenFilter(false);
    onFilter("click");
    setChannelSelect([]);
    setGroupSelect([]);
    setStaffSelect([]);
  };

  const handleChannelChange = (selected) => {
    handleListChange(selected);
    setChannelSelect(selected);
  };
  const handleChangeGroup = (selected) => {
    handleGroupChange(selected);
    setGroupSelect(selected);
  };

  const handleChangeStaff = (selected) => {
    handleStaffsChange(selected);
    setStaffSelect(selected);
  };

  return (
    <div
    // className={` nk-demo-panel  toggle-slide toggle-slide-right toggle-screen-any p-0 ${
    //   open ? "content-active" : ""
    // }`}
    // style={{
    //   minWidth: "400px",
    //   maxWidth: "400px",
    // }}
    >
      <div style={{ height: "100%" }}>
        <div
          className="d-flex align-items-center justify-between bg-light bg-gradient p-3 offcanvas-header"
          toggle={toggle}
        >
          <span className="m-0 me-2 text-dark">
            {t("PostFilterSideBarHeader")}
          </span>

          <div className="cursor-pointer" onClick={() => setOpenFilter(false)}>
            <i className="ri-close-line font-bold"></i>
          </div>
        </div>
        <div className="p-0 h-full">
          {/* <SimpleBar style={{ height: "90%" }}> */}
          <div className="p-4 position-relative h-full">
            <div className="d-flex gap-4 justify-content-start mb-3 ">
              <h6 className="mb-0 text-muted text-uppercase align-self-center fs-13">
                {t("PostFilterSideBarChannelGroup")}
              </h6>
              <div className="form-check">
                <Input
                  className="form-check-input"
                  onClick={() => {
                    setIsGroup(false);
                  }}
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  defaultChecked
                />
                <Label className="form-check-label" for="flexRadioDefault1">
                  {t("PostFilterSideBarRadiosLabel")}
                </Label>
              </div>
              <div className="form-check">
                <Input
                  className="form-check-input"
                  onClick={() => {
                    setIsGroup(true);
                  }}
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                />
                <Label className="form-check-label" for="flexRadioDefault2">
                  {t("PostFilterSideBarRadiosGroup")}
                </Label>
              </div>
            </div>

            {!isGroup && (
              <Select
                placeholder={t("PostFilterSideBarChannelSelectHoder")}
                isMulti={true}
                options={listChannel?.map((item) => ({
                  value: item._id,
                  label: item.name,
                }))}
                closeMenuOnSelect={false}
                value={channelSelect}
                onChange={handleChannelChange}
              />
            )}
            {isGroup && (
              <Select
                placeholder={t("PostFilterSideBarGroupSelectHoder")}
                isMulti={true}
                options={groupChannel?.map((item) => ({
                  value: item._id,
                  label: item.name,
                  chanel_ids: item.channel_ids,
                }))}
                closeMenuOnSelect={false}
                value={groupSelect}
                onChange={handleChangeGroup}
              />
            )}
          </div>
          {isEnterprise && (
            <div className="p-4 position-relative h-100">
              <div className="d-flex gap-4 justify-content-start mb-3">
                <h6 className="mb-0  text-muted text-uppercase align-self-center fs-13">
                  {t("project_employee")}
                </h6>
              </div>

              <Select
                placeholder={"Choose employee"}
                isMulti={true}
                options={staffs?.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                closeMenuOnSelect={false}
                value={staffSelect}
                onChange={handleChangeStaff}
              />
            </div>
          )}

          <div
            className="position-absolute d-flex w-100 gap-2 px-2"
            style={{ bottom: "10px", height: "40px" }}
          >
            <Button
              type="button"
              onClick={() => handleClear()}
              className="btn btn-light w-50 d-flex justify-center align-items-center text-center"
            >
              {t("PostFilterSideBarClearFilterAction")}
            </Button>
            <Button
              type="button"
              onClick={() => handleFilter()}
              className="btn btn-success w-50 d-flex justify-center align-items-center text-center"
            >
              {t("PostFilterSideBarFilterAction")}{" "}
              {loadingFilter && (
                <Spinner className="ms-1" size={"sm"}></Spinner>
              )}
            </Button>
          </div>
          {/* </SimpleBar> */}
        </div>
      </div>
    </div>
  );
};

export default RightSidebar;
