import React, { forwardRef } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Icon } from "../../../../components/Component";
import "../styles.css";

const DateTimeInput = ({
  date,
  setDate,
  isForm,
  required,
  isBadge,
  isEndedBeforeToday,
  isIcon,
  popperPlacement,
  started_at,
  completed,
}) => {
  moment.locale("en");

  const isValidDate = (d) => moment(d).isValid();
  const validDate = isValidDate(date) ? date : null;

  const TimeFormInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className={`time-button`}
      type="button"
      style={{
        fontSize: "12px",
        backgroundColor: "#fff",
        width: "280px",
        textAlign: "start",
        display: "flex",
      }}
      onClick={onClick}
      ref={ref}
    >
      {value || (
        <Icon
          name="calendar"
          className={"action-button cursor-pointer"}
          style={{ fontSize: "16px", color: "#90959d" }}
        />
      )}
    </button>
  ));

  const TimeBadgeInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className={`time-button badge badge-dim ${
        completed
          ? !isEndedBeforeToday
            ? "badge-success"
            : "badge-danger"
          : ""
      }`}
      style={{ fontSize: "12px", height: "26px", fontWeight: "400" }}
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ));

  const TimeInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className={`time-button`}
      style={{ fontSize: "12px", height: "26px" }}
      onClick={onClick}
      ref={ref}
    >
      {value || (
        <Icon
          name="calendar"
          className={"action-button cursor-pointer"}
          style={{ fontSize: "16px" }}
        />
      )}
    </button>
  ));

  const TimeIconInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className={`time-button d-flex items-center`}
      style={{
        fontSize: "12px",
        height: "26px",
        color: "#90959D",
        backgroundColor: "transparent",
      }}
      onClick={onClick}
      ref={ref}
    >
      {value || (
        <Icon
          name="calendar"
          className={"action-button cursor-pointer"}
          style={{ fontSize: "16px" }}
        />
      )}
    </button>
  ));

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      <DatePicker
        selected={validDate}
        onChange={(date) => setDate(date)}
        showTimeInput
        customInput={
          isForm ? (
            <TimeFormInput />
          ) : isBadge ? (
            <TimeBadgeInput />
          ) : isIcon ? (
            <TimeIconInput />
          ) : (
            <TimeInput />
          )
        }
        required={required}
        dateFormat="MMM d h:mm aa"
        popperPlacement={popperPlacement}
        minDate={started_at / 1000}
      />
    </div>
  );
};

export default DateTimeInput;
