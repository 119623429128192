import React from "react";
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import Ratio from "../../../../../images/dashboard/img-ratio.png";
import { Icon } from "../../../../../../components/Component";
import style from "../../../index.module.scss";
import moment from "moment";

const YTTopVideo = ({ data }) => {
  return (
    <>
      <div className="w-100">
        <Row className="mb-4 ml-0 w-100">
          {data &&
            data?.map((item, index) => (
              <Col key={index} sm={12} md={3} className="p-0">
                <div className={`${style.borderCard}`}>
                  <Card className="h-100">
                    <CardBody className="">
                      <CardTitle tag="h5" className={`${style.headCard}`}>
                        Đăng qua SO9
                      </CardTitle>
                      <CardText
                        className={`${style.detailCard} mt-2 d-flex flex-row align-items-center`}
                        style={{ margin: "0px" }}
                      >
                        <Icon name={"clock"} className="mr-1"></Icon>{" "}
                        {data
                          ? moment(item?.publishedAt).format(
                              "DD MMM YYYY hh:mm A"
                            )
                          : ""}
                      </CardText>
                      <div>
                        <CardImg
                          src={
                            // Check if media field is empty or has a valid URL
                            item?.thumbnails
                              ? item?.thumbnails?.high?.url // Check if URL is for a video
                              : Ratio // Default image if media field is empty
                          }
                          alt="img-ratio"
                          height={200}
                          className="mt-1"
                          style={{
                            margin: "0px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div>
                        <CardText
                          className={`${style.contentCard} mt-1 d-flex flex-row align-items-center pb-2`}
                          style={{
                            borderBottom: "1px solid #D1D5DB",
                          }}
                        >
                          <span className={`${style.truncatedText}`}>
                            {item ? item?.title : ""}
                          </span>
                        </CardText>
                      </div>
                      <div
                        className="d-flex flex-column mt-2"
                        style={{ gap: "8px" }}
                      >
                        <div
                          className={`${style.contentCard} d-flex flex-row align-items-center justify-content-between`}
                        >
                          <div>Tổng lượt xem</div>
                          <div style={{ fontWeight: "600" }}>
                            {item?.viewCount}
                          </div>
                        </div>
                        <div
                          className={`${style.contentCard} d-flex flex-row align-items-center justify-content-between`}
                        >
                          <div>Tổng lượt thích</div>
                          <div style={{ fontWeight: "600" }}>
                            {item?.likeCount}
                          </div>
                        </div>
                        <div
                          className={`${style.contentCard} d-flex flex-row align-items-center justify-content-between`}
                        >
                          <div>Tổng lượt comment</div>
                          <div style={{ fontWeight: "600" }}>
                            {item?.commentCount}
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            ))}
        </Row>
      </div>
    </>
  );
};

export default YTTopVideo;
