import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import {
  Modal,
  ModalBody,
  FormGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Spinner,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  Button,
} from "../../../components/Component";
import { UncontrolledTooltip } from "reactstrap";
import { Tooltip } from "reactstrap";
import VietNamFlag from "../../../images/flags/vietnam.png";
import EnglishFlag from "../../../images/flags/english.png";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { updateProfile } from "../../../api/user";
import { TextFieldAuth } from "../../../commons/TextField/TextFieldAuth";
import { toast, ToastContainer } from "react-toastify";
import * as Yup from "yup";
const UserProfileRegularPage = ({ sm, updateSm }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const [copy] = useState(userInfo.uuid);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
  });
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => {
    navigator.clipboard.writeText(copy);
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 3000);
  };
  useEffect(() => {
    setFormData((prevState) => ({
      ...formData,
      name: userInfo.name,
      phone: userInfo.phone,
    }));
  }, []);
  const [modal, setModal] = useState(false);
  const handleChangeLanguage = (lng) => {
    i18next.changeLanguage(lng);
  };

  const renderLanguageTitle = () => {
    return i18next.language === "vi"
      ? t("footer_language_title_vie")
      : t("footer_language_title_eng");
  };
  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("validate_field_name_required"))
      .min(4, t("validate_field_name_character_required", { length: 4 })),
    phone: Yup.string().required(t("validate_field_phone_required")),
  });
  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: userInfo.name,
      phone: userInfo.phone,
    },
  });
  const onFormSubmit = (formData) => {
    setLoading(true);
    updateProfile(formData).then((res) => {
      if (res.status) {
        localStorage.removeItem("emailToChangePassword");
        localStorage.setItem("userInfor", JSON.stringify(res.data));
        toast.success(t("successful_update_profile"));
      } else {
        toast.error(res.message);
      }
      setLoading(false);
    });
    return true;
  };

  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">{t("personal_information")}</BlockTitle>
            <BlockDes>
              <p>
                Basic info, like your name and address, that you use on So9
                Platform.
              </p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${
                sm ? "active" : ""
              }`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">Basics</h6>
          </div>
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">{t("full_name")}</span>
              <span className="data-value">{userInfo.name}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">{t("display_name")}</span>
              <span className="data-value">{userInfo.name}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Email</span>
              <span className="data-value">{userInfo.email}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more disable">
                <Icon name="lock-alt"></Icon>
              </span>
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">{t("secret_code")}</span>
              <span className={`data-value`} id="uuid">
                {userInfo.uuid.length > 25
                  ? userInfo.uuid.slice(0, 25) + "..."
                  : userInfo.uuid}
              </span>
              <UncontrolledTooltip
                autohide={false}
                placement={"top"}
                target={"uuid"}
              >
                {userInfo.uuid}
              </UncontrolledTooltip>
            </div>
            {/* <button onClick={() => {navigator.clipboard.writeText(copy)}}>thu</button> */}
            <div className="data-col data-col-end" onClick={() => toggle()}>
              <span className="data-more">
                <Icon name="clipboard" id="copy"></Icon>
                <UncontrolledTooltip
                  autohide={false}
                  placement={"top"}
                  target={"copy"}
                >
                  copy
                </UncontrolledTooltip>
                <Tooltip
                  autohide={false}
                  placement="top"
                  isOpen={tooltipOpen}
                  target="copy"
                  trigger="click"
                >
                  copied
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">{t("phone_number")}</span>
              <span className="data-value text-soft">{userInfo.phone}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
        </div>
        <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">Preferences</h6>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">{t("language")}</span>
              <span className="data-value">{renderLanguageTitle()}</span>
            </div>
            <div className="data-col data-col-end">
              <UncontrolledDropdown direction="up">
                <DropdownToggle
                  color="transparent"
                  className=" has-indicator nav-link"
                >
                  <a
                    href="#language"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                    className="link link-primary"
                  >
                    {t("change_language")}
                  </a>
                </DropdownToggle>
                <DropdownMenu right className="dropdown-menu-sm">
                  <ul className="language-list">
                    <li>
                      <DropdownItem
                        onClick={() => handleChangeLanguage("vi")}
                        className="language-item"
                      >
                        <img
                          src={VietNamFlag}
                          alt=""
                          className="language-flag"
                        />
                        <span className="language-name">Việt Nam</span>
                      </DropdownItem>
                    </li>
                    <li>
                      <DropdownItem
                        onClick={() => handleChangeLanguage("en")}
                        className="language-item"
                      >
                        <img
                          src={EnglishFlag}
                          alt=""
                          className="language-flag"
                        />
                        <span className="language-name">English</span>
                      </DropdownItem>
                    </li>
                  </ul>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        </div>
      </Block>
      <ToastContainer />

      <Modal
        isOpen={modal}
        className="modal-dialog-centered"
        size="lg"
        toggle={() => setModal(false)}
      >
        <ModalBody>
          <a
            href="#dropdownitem"
            onClick={(ev) => {
              ev.preventDefault();
              setModal(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">{t("update_profile")}</h5>
            <ul className="nk-nav nav nav-tabs">
              <li className="nav-item">
                <a className={`nav-link active`} href="#personal">
                  {t("personal")}
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className={`tab-pane active`} id="personal">
                <form onSubmit={handleSubmit(onFormSubmit)}>
                  <Row className="gy-4">
                    <Col md="6">
                      <FormGroup>
                        <TextFieldAuth
                          field={"name"}
                          current={watch("name")}
                          register={register}
                          setValue={setValue}
                          required={true}
                          errors={errors.name}
                          title={t("auth_register_name")}
                          placeholder={t("auth_register_name_placeholder")}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <TextFieldAuth
                          field={"phone"}
                          current={watch("phone")}
                          register={register}
                          setValue={setValue}
                          errors={errors.phone}
                          title={t("auth_register_phone")}
                          placeholder={t("auth_register_phone_placeholder")}
                        />
                      </FormGroup>
                    </Col>

                    <Col size="12">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button
                            color="primary"
                            size="lg"
                            type={"submit"}
                            className={`btn-block`}
                          >
                            {loading ? (
                              <Spinner size="sm" color="light" />
                            ) : (
                              t("update")
                            )}
                          </Button>
                        </li>
                        <li>
                          <a
                            href="#dropdownitem"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setModal(false);
                            }}
                            className="link link-light"
                          >
                            {t("cancel")}
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default UserProfileRegularPage;
