import React from "react";
import PageContainer from "../../../layout/page-container/PageContainer";
import { Link } from "react-router-dom";
import { Block, BlockContent, Button } from "../../../components/Component";
import UpgradeZaloImage from "../../images/icons/upgrade_zalo.png"

const UpgradeZalo = () => {
  return (
    <PageContainer>
      <Block className="nk-block-middle wide-xs mx-auto">
        <BlockContent className="nk-error-ld text-center">
          <img src={UpgradeZaloImage} />
          <h3 className="nk-error-title mt-2">Zalo đã nâng cấp các gói dịch vụ</h3>
          <p className="nk-error-text">
            Bạn đang sử dụng gói dịch vụ cơ bản có giới hạn tính năng của Zalo. Vui lòng nâng cấp để tiếp tục trải nghiệm Zalo toàn diện hơn.
          </p>
          <div>
            <Link to={`/admin/project/connect-channel`}>
              <Button color="secondary" size="lg" className="mt-2 mr-3">
                Trở về
              </Button>
            </Link>
            <a href={`https://oa.zalo.me/home/pricing`}>
              <Button color="primary" size="lg" className="mt-2">
                Nâng cấp ngay
              </Button>
            </a>
          </div>
        </BlockContent>
      </Block>
    </PageContainer>
  );
};
export default UpgradeZalo;
