import { merge } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getDashboardUser } from "../../../api/overview";
import BarChart from "../../components/Chart/BarChart";
import SummaryItem from "./SummaryItem";

const Audience = (props) => {
  const { t } = useTranslation();
  const { range } = props;
  const project = useSelector((state) => state.project.project);
  const [summary, setSummary] = useState([]);
  const [chartData, setChartData] = useState({});
  const chartOptions = {
    legend: {
      display: false,
      labels: false,
    },
    maintainAspectRatio: false,
    tooltips: {
      enabled: true,
      backgroundColor: "#eff6ff",
      titleFontSize: 9,
      titleFontColor: "#6783b8",
      titleMarginBottom: 6,
      bodyFontColor: "#9eaecf",
      bodyFontSize: 9,
      bodySpacing: 4,
      yPadding: 6,
      xPadding: 6,
      footerMarginTop: 0,
      displayColors: false,
    },
    scales: {
      yAxes: [
        {
          display: true,
          ticks: {
            display: false,
            beginAtZero: false,
            fontSize: 12,
            fontColor: "#9eaecf",
            padding: 0,
            maxTicksLimit: 3,
          },
          gridLines: {
            color: "rgba(82, 100, 132, 0.2)",
            tickMarkLength: 0,
            zeroLineColor: "rgba(82, 100, 132, 0.2)",
          },
        },
      ],
      xAxes: [
        {
          display: true,
          ticks: {
            padding: 16,
            fontSize: 12,
            fontColor: "#9eaecf",
            source: "auto",
            maxTicksLimit: 1,
            maxRotation: 0,
          },
          gridLines: {
            color: "transparent",
            tickMarkLength: 0,
            zeroLineColor: "transparent",
            offsetGridLines: true,
          },
        },
      ],
    },
  };

  useEffect(() => {
    if (project?._id) {
      const data = {
        params: {
          project_id: project?._id,
          time: `${range} days`,
        },
      };
      getDashboardUser(data).then((response) => {
        const _data = response.data;
        setSummary(_data.summary);
        const _chartFormatter = [
          {
            color: "#9d72ff",
            barPercentage: 0.7,
            categoryPercentage: 0.7,
            backgroundColor: "rgba(157, 114, 255, 0.75)",
          },
        ];
        const _chart = {
          ..._data.dataSet,
          datasets: merge(_data.dataSet.dataSets, _chartFormatter),
        };
        setChartData(_chart);
      });
    }
    return () => {
      setSummary([]);
      setChartData({});
    };
  }, [range, project?._id]);

  return (
    <>
      <div className="card-title-group align-start pb-3 g-2">
        <div className="card-title card-title-sm">
          <h6 className="title">{t("overview.audience.title")}</h6>
          <p>{t("overview.audience.subtitle")}</p>
        </div>
      </div>
      <div className="analytic-au">
        <div className="analytic-data-group analytic-au-group g-3">
          {summary.map((summaryItem, index) => (
            <SummaryItem
              key={index}
              item={summaryItem}
              activeItem={index === 0}
              className="analytic-au-data"
              translatePrefix="overview.audience.summary_"
            />
          ))}
        </div>
        <div className="analytic-au-ck">
          <BarChart data={chartData} options={chartOptions} />
        </div>
      </div>
    </>
  );
};

export default Audience;
