import React, { useState } from "react";
import { RiHonourLine } from "@remixicon/react";
import { useTranslation } from "react-i18next";
import { LoadingItem } from "../../Dashboard_v2/CommonItem/LoadingItem";
import { Icon } from "../../../../components/Component";
import ConfimDelete from "../../../components/Modal/ModalConfimDelete";
import styles from "../index.module.scss";
import "../styles.css";

const LeftMenu = ({
  isOpenLeftTab,
  listGroup,
  isLoadingGroup,
  activeGroup,
  handleActiveGroup,
  toggleFilter,
  activeFilter,
  toggleGroupModal,
  handleDeleteGroup,
  isBusiness,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const { t } = useTranslation();

  const [openGroupAccess, setOpenGroupAccess] = useState(true);
  const [modalDelete, setModalDelete] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);

  const toggleDelete = () => {
    setModalDelete(!modalDelete);
  };

  const handleDelete = () => {
    handleDeleteGroup(deleteItem);
    toggleDelete();
  };

  return (
    <>
      <div
        className={`boxLeft ${isOpenLeftTab ? "open" : ""} ${
          !isBusiness ? "disabled-menu" : ""
        }`}
      >
        {isLoadingGroup && (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "calc(100vh - 72px + 56px)",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingItem />
          </div>
        )}
        {isOpenLeftTab && !isLoadingGroup && (
          <>
            <div
              className={`${styles.sideMenuHeader}`}
              style={{
                padding: "18px 12px",
                borderBottom: "1px solid #eff0f1",
              }}
            >
              <p className={`${styles.sideMenuHeader__text} mb-0`}>
                {t("task_management.title")}
              </p>
            </div>
            <div
              className={`${styles.leftMenuGroupFilter}`}
              style={{ cursor: !isBusiness ? "not-allowed" : "pointer" }}
            >
              <div className={`btn-wrap ${!isBusiness ? "disabled-menu" : ""}`}>
                <div
                  className="sider-btn"
                  onClick={() => {
                    activeFilter === "is_owner"
                      ? toggleFilter("all")
                      : toggleFilter("is_owner");
                  }}
                  style={{
                    color: activeFilter === "is_owner" ? "#3577F1" : "",
                  }}
                >
                  <Icon name="user"></Icon>
                  <span
                    style={{
                      color: activeFilter === "is_owner" ? "#3577F1" : "",
                      fontWeight: "600",
                    }}
                  >
                    {t("task_management.left_menu.owner")}
                  </span>
                </div>
              </div>
              <div className={`btn-wrap ${!isBusiness ? "disabled-menu" : ""}`}>
                <div
                  className="sider-btn"
                  onClick={() => {
                    activeFilter === "is_subcriber"
                      ? toggleFilter("all")
                      : toggleFilter("is_subcriber");
                  }}
                  style={{
                    color: activeFilter === "is_subcriber" ? "#3577F1" : "",
                  }}
                >
                  <Icon name="user-add"></Icon>
                  <span
                    style={{
                      color: activeFilter === "is_subcriber" ? "#3577F1" : "",
                      fontWeight: "600",
                    }}
                  >
                    {t("task_management.left_menu.subscribers")}
                  </span>
                </div>
              </div>
              <div
                className="sider-btn items-center"
                style={{
                  borderTop: "1px solid #eff0f1",
                  fontWeight: "600",
                  cursor: !isBusiness ? "not-allowed" : "pointer",
                }}
                onClick={() => setOpenGroupAccess(!openGroupAccess)}
              >
                <RiHonourLine size={14} className="text-muted cursor-pointer" />
                <span style={{ fontWeight: "600" }}>
                  {t("task_management.left_menu.group_tasks")}
                </span>
              </div>
              {openGroupAccess && (
                <>
                  {listGroup &&
                    listGroup?.map((item) => (
                      <div
                        className={`btn-wrap ${
                          !isBusiness ? "disabled-menu" : ""
                        }`}
                        key={item?._id}
                        style={{
                          backgroundColor:
                            activeGroup?._id === item?._id
                              ? "rgba(41, 156, 219, 0.05)"
                              : "",
                        }}
                      >
                        <div
                          className="sider-btn sider-btn-item justify-between item-group"
                          onClick={() => handleActiveGroup(item)}
                          style={{
                            color:
                              activeGroup?._id === item?._id
                                ? "#3577F1"
                                : "#212529",
                          }}
                        >
                          <div
                            className="d-flex gap-2 items-center h-100"
                            style={{
                              color:
                                activeGroup?._id === item?._id
                                  ? "#3577F1"
                                  : "#212529",
                            }}
                          >
                            <RiHonourLine
                              size={14}
                              className="text-muted cursor-pointer"
                              color={
                                activeGroup?._id === item?._id
                                  ? "#3577F1"
                                  : "#212529"
                              }
                            />
                            <div>{item?.name}</div>
                          </div>
                          <div
                            className="action-icons"
                            style={{
                              display: "flex",
                              gap: "2px",
                              alignItems: "center",
                            }}
                          >
                            <div
                              className="btn btn-xs btn-icon btn-trigger"
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleGroupModal(item);
                              }}
                              disabled={item?.user_id !== userInfo.id}
                              style={{
                                cursor:
                                  item?.user_id !== userInfo.id
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                            >
                              <Icon name="edit"></Icon>
                            </div>
                            <div
                              className="btn btn-xs btn-icon btn-trigger mr-0.5"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (activeGroup?._id !== item?._id) {
                                  setModalDelete(!modalDelete);
                                  setDeleteItem(item._id);
                                }
                              }}
                              disabled={
                                activeGroup?._id === item?._id ||
                                item?.user_id !== userInfo.id
                              }
                              style={{
                                cursor:
                                  activeGroup?._id === item?._id ||
                                  item?.user_id !== userInfo.id
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                            >
                              <Icon name="trash"></Icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  <div
                    className={`btn-wrap ${!isBusiness ? "disabled-menu" : ""}`}
                  >
                    <div
                      className="sider-btn sider-btn-item justify-between"
                      style={{ color: "#90959D" }}
                      onClick={() => toggleGroupModal()}
                    >
                      <span style={{ color: "#90959D" }}>
                        {t("task_management.left_menu.new_group")}
                      </span>
                      <div style={{ marginRight: "4px" }}>
                        <Icon name="plus"></Icon>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>

      <ConfimDelete
        modalDelete={modalDelete}
        toggleDelete={toggleDelete}
        messeage={""}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default LeftMenu;
