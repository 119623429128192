import React, { useEffect, useRef, useState } from "react";
import { RiAlignLeft } from "@remixicon/react";
import { useTranslation } from "react-i18next";
import TextEditor from "./TextEditor";
import "../styles.css";

const DetailDescription = ({
  description,
  handleUpdateTask,
  handleSaveDescription,
}) => {
  const { t } = useTranslation();
  const textEditorRef = useRef(null);

  const [isOpenTextEditor, setIsOpenTextEditor] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpenTextEditor &&
        textEditorRef.current &&
        !textEditorRef.current.contains(event.target)
      ) {
        setIsOpenTextEditor(false);
        const data = {
          description,
        };
        handleUpdateTask(data);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpenTextEditor, description]);

  return (
    <div
      style={{
        display: "flex",
        gap: "12px",
        alignItems: "start",
        width: "100%",
        maxHeight: isOpenTextEditor ? "600px" : "",
      }}
      ref={textEditorRef}
    >
      <div className={`d-flex`}>
        <RiAlignLeft size={16} className="text-muted cursor-pointer" />
      </div>
      {isOpenTextEditor ? (
        <TextEditor
          content={description}
          placeholder={t("task_management.detail.add_description")}
          onSave={handleSaveDescription}
          onChange={handleSaveDescription}
          onClose={() => setIsOpenTextEditor(false)}
        />
      ) : (
        <div
          className="description-container"
          style={{
            color:
              description === t("task_management.detail.add_description")
                ? "#90959D"
                : "#212529",
            textAlign: "start",
            minHeight: "24px",
            // maxHeight: "300px",
            overflow: "hidden",
            width: "100%",
            position: "relative",
            fontSize: "12px",
          }}
          onClick={() => setIsOpenTextEditor(true)}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        ></div>
      )}
    </div>
  );
};

export default DetailDescription;
