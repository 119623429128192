import React from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { loadListBill, loadSubscriptionUsed } from "../../../../api/project";
import { STATUS_BILL_FINISH } from "../../../../config/project";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import AddSubscription from "../../../components/Project/AddSubscription";
import ListBill from "../../../components/Project/ListBill";
const Subscription = () => {
  const project = useSelector((state) => state.project.project);
  const getListBill = async () => {
    if (project?._id) {
      return await loadListBill(project?._id);
    }
    return;
  };

  const getSubscriptionUsed = async () => {
    if (project?._id) {
      return await loadSubscriptionUsed(project?._id);
    }
    return;
  };

  const { data, isLoading } = useQuery(["getListBill", project], getListBill);
  const subscription_used = useQuery(
    ["getSubscriptionUsed", project],
    getSubscriptionUsed
  );

  return (
    <React.Fragment>
      <Head title="Transaction Lists - Basic"></Head>
      <Content>
        <div className={`mb-4`}>
          <AddSubscription subscription_used={subscription_used?.data?.data} />
        </div>

        <div>
          <ListBill bills={data} isLoading={isLoading} />
        </div>
      </Content>
    </React.Fragment>
  );
};

export default Subscription;
