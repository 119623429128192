import { useEffect, useState } from "react";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Img } from "../../../../components/Component";
import { RiCloseLine, RiPencilFill } from "@remixicon/react";


const EditVideoTitle = ({ video, setVideo }) => {
    const [isShow, setIsShow] = useState(false);
    const [value, setValue] = useState("");
    const [initialThumb, setInitialThumb] = useState();

    const onToggle = () => {
        setIsShow(!isShow);
    }

    useEffect(() => {
        setValue(video[0].caption);
        const getThumb =async () => {
            const blob = await fetch(video[0].thumb).then((res) =>
                res.blob()
            );
            setInitialThumb(URL.createObjectURL(blob));
        } 
        getThumb();
    }, [video])

    const onSave = () => {
        let data = [...video];
        data[0].caption = value;
        setVideo(data);
        onToggle();
    }

    const onDelete = () => {
        setVideo([]);
    }

    return (
        <>
            <img
                src={initialThumb}
                alt={video[0]?.name || "video"}
                style={{ maxWidth: '90px', maxHeight: '90px', minHeight: "90px", minWidth: "90px" }}
                className="rounded"
            />
            <RiCloseLine size={12}
            className="d-flex align-items-center justify-content-center cursor-pointer rounded-circle bg-dark text-white position-absolute"
            style={{ top: "4px", right: "4px", width: "14px", height: "14px" }}
            onClick={() => onDelete()}
            ></RiCloseLine>
            <div className="position-absolute  bottom-0 w-100 px-1">
                <p className=" mb-0 text-white text-center text-truncate">{video[0].caption}</p>

            </div>
            <div
                style={{ bottom: "4px", right: "4px", width: "16px", height: "16px" }}
                onClick={onToggle}
                className="d-flex align-items-center justify-content-center cursor-pointer rounded-circle bg-white position-absolute">
                <RiPencilFill className="text-info" size={10}></RiPencilFill>
            </div>

            <Modal isOpen={isShow} toggle={onToggle} centered size="lg">
                <ModalHeader toggle={onToggle}>
                    Add Title For Video File
                </ModalHeader>
                <ModalBody style={{ height: "fit-content" }} className="d-flex align-item-center gap-2">
                    <Img src={initialThumb} height={"200px"} width="200px"></Img>
                    <Input value={value} onChange={(e) => setValue(e.target.value)} type="textarea" style={{ minHeight: "200px" }}>

                    </Input>
                </ModalBody>

                <ModalFooter className="d-flex justify-content-end">
                    <Button onClick={() => onSave()}>Save</Button>
                </ModalFooter>

            </Modal>
        </>

    )

}

export default EditVideoTitle;