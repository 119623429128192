import IntrolChatContent from "../../components/IntrolChat/IntrolChatContent";
import Content1 from "../../images/IntrolChat/content1.png";
import Content2 from "../../images/IntrolChat/content2.png";
import Content3 from "../../images/IntrolChat/contentHub.png";
import Content4 from "../../images/IntrolChat/content3.png";
import Content5 from "../../images/IntrolChat/content4.png";
import IntrolChatFooter from "../../components/IntrolChat/IntrolChatFooter";
import IntrolChatHeader from "../../components/IntrolChat/IntrolChatHeader";
import IntrolChatDesc from "../../components/IntrolChat/IntrolChatDecriton";
const IntrolChatPage = () => {
    const ContentData = [
        {
            backColor: "rgba(254, 249, 237, 1)",
            index: "1",
            image: Content1,
            desc: "Với 9Hub Extension, SO9 HUB xóa bỏ mọi rào cản nhắn tin được quy định với Fanpage",
            header: "Nhắn tin không giới hạn",
            isWait: false,
            isButton: false,
        },
        {
            backColor: "rgba(228, 246, 250, 1)",
            index: "2",
            image: Content2,
            desc: "Giúp bạn tối ưu thời gian tương tác với khách hàng",
            header: "Mẫu câu trả lời nhanh",
            isWait: false,
            isButton: false,
        },
        {
            backColor: "rgba(254, 249, 237, 1)",
            index: "3",
            image: Content3,
            desc: "Extension hỗ trợ nhắn tin không giới hạn cho Fanpage.",
            header: "9Hub Extension",
            isWait: false,
            isButton: true,
        },
        {
            backColor: "rgba(228, 246, 250, 1)",
            index: "4",
            image: Content4,
            desc: "Hỗ trợ tương tác tự động với khách hàng mà không cần nhân sự",
            header: "Bot tự động",
            isWait: false,
            isButton: false,
        },
        {
            backColor: "rgba(254, 249, 237, 1)",
            index: "5",
            image: Content5,
            desc: "Tự động phân loại và quản lý các chiến dịch Remarketing tự động",
            header: "Auto Remarketing",
            isWait: true,
            isButton: false,
        }
    ]

    return (
        <>

            <div style={{ marginTop: "76px" }}>
                <IntrolChatHeader />
                <IntrolChatDesc/>
                {ContentData.map((content) => {
                    return (
                        <IntrolChatContent
                            backColor={content.backColor}
                            index={content.index}
                            image={content.image}
                            desc={content.desc}
                            isWait={content.isWait}
                            isButton={content.isButton}
                            header={content.header}
                        />
                    )
                })}
                <IntrolChatFooter />
            </div>

        </>
    )
}
export default IntrolChatPage;