import React from "react";
import { useTranslation } from "react-i18next";
import { toastSuccess } from "../../../../libs/toasts";
import { Icon } from "../../../../components/Component";
import "../styles.css";
import { getDomainApp } from "../../../../utils/Utils";

const DetailHeader = ({
  listDetail,
  setListDetail,
  setIsOpenDetail,
  handleComplete,
  handleDeleteItem,
  handleMarkChild,
  handleDeleteChild,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const { t } = useTranslation();

  const handleCopyLink = () => {
    const domain = getDomainApp();
    const text = `${domain}/task-management?group_id=${listDetail?.group_id}&task_id=${listDetail?._id}`;
    navigator.clipboard.writeText(text);
    toastSuccess(t("task_management.toast.copied"), {
      autoClose: 1000,
      limit: 3,
    });
  };

  return (
    <div className="header-detail">
      <div
        className={`mark-button ${
          listDetail?.is_complete ? "mark-success" : "mark-info"
        }`}
        onClick={() => {
          !listDetail.parent_id
            ? handleComplete(listDetail?._id)
            : handleMarkChild();

          setListDetail((prev) => ({
            ...prev,
            is_complete: !prev.is_complete,
          }));
        }}
      >
        <Icon
          name="check"
          className={"cursor-pointer"}
          style={{ fontSize: "16px" }}
        />
        <span className="mark-span">
          {listDetail?.is_complete
            ? t("task_management.detail.completed")
            : t("task_management.detail.mark_complete")}
        </span>
      </div>

      <div style={{ display: "flex", gap: "16px", color: "#9599AD" }}>
        <div onClick={() => handleCopyLink()}>
          <Icon
            name="share"
            className={"action-button cursor-pointer"}
            style={{ fontSize: "16px" }}
          />
        </div>
        <div
          onClick={() => {
            if (userInfo.id === listDetail?.owner_id) {
              if (listDetail.parent_id) {
                handleDeleteChild(listDetail);
              } else {
                handleDeleteItem(listDetail._id);
                setIsOpenDetail(false);
              }
            }
          }}
        >
          <Icon
            name="trash"
            className={"action-button cursor-pointer"}
            style={{
              fontSize: "16px",
              cursor:
                userInfo.id === listDetail?.owner_id
                  ? "pointer"
                  : "not-allowed",
            }}
          />
        </div>
        <div>
          <Icon
            name="cross"
            className={"action-button cursor-pointer"}
            style={{ fontSize: "16px" }}
            onClick={() => setIsOpenDetail(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailHeader;
