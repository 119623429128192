import { Col, Row } from "reactstrap";
import SQUARE_TIKTOK from "../../../images/post/tiktok-square.svg";
import { Img, RSelect } from "../../../../components/Component";
import { CheckedBox } from "../../../../components/Component";
import { RiPencilFill } from "@remixicon/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const TiktokSetting = ({
  onOpenModalCustomThumbnail,
  formData,
  setFormData,
  selectedVideo,
  isVideo,
}) => {
  const [iconVisible, setIconVisible] = useState(false);
  const statusOption = [
    { label: "Public To Everyone", value: "Public" },
    { label: "Self Only", value: "Private" },
    { label: "Mutual Follow Friends", value: "friend" },
  ];

  const { t } = useTranslation();
  const tiktokThumb = selectedVideo?.[0]?.tiktokThumb;
  return (
    <div className={`rounded  mt-3 p-2 color-border border-1`}>
      <div className="d-flex justify-content-between gap-3">
        <div className="d-flex align-items-center">
          <div
            className="d-flex justify-content-center align-items-center rounded position-relative"
            style={{
              background: "rgba(243,246,249,1)",
              width: "36px",
              height: "36px",
            }}
          >
            <Img src={SQUARE_TIKTOK} height="16px"></Img>
          </div>
          <p className="fs-13 ms-2 mb-0" style={{ color: "#495057", fontWeight: 600 }}>
            {t('store_post.setting.tiktok.title')}
          </p>
        </div>
      </div>

      <Row className="mt-2" style={{ marginLeft: "32px" }}>
        <Col md={12} sm={12} className="mt-2">
          <Row>
            <Col md={2} className="d-flex m-auto pr-0">
              <p className="text-body fs-13 align-items-center mb-0">
                {t('store_post.setting.tiktok.thumbnail')}
              </p>
            </Col>
            <Col md={10}>
              <div className="form-control-wrap">
                {tiktokThumb != null ? (
                  <div
                    className="h-9 w-9 rounded d-flex align-items-center justify-content-center"
                    onClick={onOpenModalCustomThumbnail}
                    onMouseOver={() => setIconVisible(true)}
                    onMouseLeave={() => setIconVisible(false)}
                    style={{
                      backgroundColor: iconVisible ? "rgba(0, 0, 0, 0.3)" : "",
                    }}
                  >
                    <Img
                      src={tiktokThumb}
                      className="h-full w-full object-contain rounded"
                    />
                    {iconVisible && (
                      <div className="d-flex w-4 h-4 align-items-center justify-content-center cursor-pointer rounded-circle bg-white position-absolute">
                        <RiPencilFill className="text-info" size={10} />
                      </div>
                    )}
                  </div>
                ) : (
                  <button
                    className="border-0 justify-center items-center text-[#878A99]"
                    style={{ height: 32, fontSize: 13 }}
                    onClick={onOpenModalCustomThumbnail}
                    disabled={!isVideo}
                  >
                    {t('store_post.setting.tiktok.select_frame')}
                  </button>
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={12} sm={12} className="mt-2">
          <Row>
            <Col md={2}>
              <p className="text-body fs-13 mb-0">
                {t('store_post.setting.tiktok.allow.title')}
              </p>
            </Col>
            <Col md={10} className="d-flex justify-content-start">
              <div className="form-check mb-2 pl-0 flex-row flex items-center">
                <CheckedBox
                  checked={formData.comment}
                  onChange={() =>
                    setFormData({ ...formData, comment: !formData.comment })
                  }
                />
                <div
                  style={{
                    fontSize: 13,

                    marginLeft: 7,
                    color: "#212529",
                  }}
                >
                  {t('store_post.setting.tiktok.allow.comment')}
                </div>
              </div>
              <div className="form-check mb-2 flex-row flex items-center">
                <CheckedBox
                  checked={formData.duet}
                  onChange={() =>
                    setFormData({ ...formData, duet: !formData.duet })
                  }
                />

                <div
                  style={{
                    fontSize: 13,

                    marginLeft: 7,
                    color: "#212529",
                  }}
                >
                  {t('store_post.setting.tiktok.allow.duet')}
                </div>
              </div>
              <div className="form-check mb-2 flex-row flex items-center">
                <CheckedBox
                  checked={formData.stitch}
                  onChange={() =>
                    setFormData({ ...formData, stitch: !formData.stitch })
                  }
                />

                <div
                  style={{
                    fontSize: 13,
                    marginLeft: 7,
                    color: "#212529",
                  }}
                >
                  {t('store_post.setting.tiktok.allow.stitch')}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <div
        className="d-flex justify-content-start gap-4 mt-3"
        style={{ marginLeft: "44px" }}
      >
        <p
          className="d-flex text-body fs-13 mb-0"
          style={{ fontWeight: "400" }}
        ></p>
      </div>
    </div>
  );
};

export default TiktokSetting;
