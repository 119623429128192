import React, { useState } from "react";
import { RiHonourLine } from "@remixicon/react";
import { useTranslation } from "react-i18next";
import { Icon } from "../../../../components/Component";
import UserSelect from "./UserSelect";
import SectionSelect from "./SectionSelect";
import "../styles.css";

const DetailDropdown = ({
  groupOptions,
  listDetail,
  staffOptions,
  newSection,
  handleSelectOwner,
  handleSelectGroup,
  sectionOptions,
  handleSelectSection,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const { t } = useTranslation();

  const [isOpenOwner, setIsOpenOwner] = useState(false);
  const [isOpenSection, setIsOpenSection] = useState(false);
  const [isOpenGroup, setIsOpenGroup] = useState(false);

  return (
    <>
      <div className="detail-item">
        <Icon name="user" style={{ color: "#90959D", fontSize: "16px" }} />

        <UserSelect
          isOpen={isOpenOwner}
          setIsOpen={setIsOpenOwner}
          data={listDetail?.owner}
          defaultValue={listDetail?.owner_id}
          options={staffOptions}
          handleSelect={handleSelectOwner}
          placeholder={t("task_management.placeholder.add_owner")}
          direction={"dropdown"}
          isMulti={false}
          disabled={userInfo.id !== listDetail?.owner_id}
        />
      </div>
      <div className="detail-item">
        <RiHonourLine size={16} className="text-muted cursor-pointer" />

        <SectionSelect
          isOpen={isOpenGroup}
          setIsOpen={setIsOpenGroup}
          defaultValue={listDetail?.group_id || groupOptions[0].value}
          options={groupOptions}
          handleSelect={handleSelectGroup}
          placeholder={t("task_management.form.search")}
          direction={"dropdown"}
        />

        <div className="vertical-divider" />

        <SectionSelect
          isOpen={isOpenSection}
          setIsOpen={setIsOpenSection}
          newSection={newSection}
          defaultValue={listDetail?.section_id}
          options={sectionOptions}
          handleSelect={handleSelectSection}
          placeholder={t("task_management.form.search")}
          direction={"dropdown"}
        />
      </div>
    </>
  );
};

export default DetailDropdown;
