import React from "react";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../../../components/Component";
import style from "../index.module.scss";
import Post from "./PostAndInteraction/Facebook/Post";
import GoldTime from "./PostAndInteraction/Facebook/GoldTime";
import Reach from "./PostAndInteraction/Facebook/Reach";
import TopPost from "./PostAndInteraction/Facebook/TopPost";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getEngageAndReachFacebook,
  getPostAndEngageFacebook,
  getPostAndEngageTopVideoYT,
  getPostAndEngageYT,
  getTimeGoldFacebook,
  getTopPostFacebook,
} from "../../../../api/dashboard";
import YTPost from "./PostAndInteraction/Youtube/YTPost";
import YTTopPost from "./PostAndInteraction/Youtube/YTTopPost";
import _ from "lodash";

const PostAndInteraction = (props) => {
  const { range, tab, channel, setLoading, setLoadingChannel, subTab, dashboardList, setDashboardList, updateTabDashboard, dashboardInit, isChangeFilter, setChangeFilter } = props;
  const userInfor = JSON.parse(localStorage.getItem('userInfor'));
  const projectId = userInfor.last_project_active;
  const [postAndEngageData, setPostAndEngageData] = useState({});
  const [topPostData, setTopPostData] = useState([]);
  const [timeGoldData, setTimeGoldData] = useState([]);
  const [engageAndReachData, setEngageAndReachData] = useState({});

  const [postAndEngageDataYT, setPostAndEngageDataYT] = useState({});
  const [topVideoDataYT, setTopVideoDataYT] = useState([]);

  const getAPIFacebook = (data) => {
    const promises = [
      getPostAndEngageFacebook(data),
      getTopPostFacebook(data),
      getTimeGoldFacebook(data),
      getEngageAndReachFacebook(data),
    ];
    Promise.all(promises).then((responses) => {
      // Extract the data from the responses
      const [
        postAndEngageResponse,
        topPostResponse,
        timeGoldResponse,
        engageAndReachResponse,
      ] = responses;

      // Check and set the data for each response
      if (postAndEngageResponse.status) {
        const postAndEngageData = postAndEngageResponse.data;
        setPostAndEngageData(postAndEngageData);
      }

      if (topPostResponse.status) {
        const topPostData = topPostResponse.data;
        setTopPostData(topPostData);
      }

      if (timeGoldResponse.status) {
        const timeGoldData = timeGoldResponse.data;
        setTimeGoldData(timeGoldData);
      }

      if (engageAndReachResponse.status) {
        const engageAndReachData = engageAndReachResponse.data;
        setEngageAndReachData(engageAndReachData);
      }
      setLoadingChannel(false);
      setLoading(false);
    });
  };

  const getAPIYoutube = (data) => {
    const promises = [
      getPostAndEngageYT(data),
      getPostAndEngageTopVideoYT(data),
    ];
    Promise.all(promises).then((responses) => {
      // Extract the data from the responses
      const [postAndEngageResponse, topVideoResponse] = responses;
      // Check and set the data for each response
      if (postAndEngageResponse.status) {
        const _data = postAndEngageResponse.data;
        setPostAndEngageDataYT(_data);
      }

      if (topVideoResponse.status) {
        const _data = topVideoResponse.data;
        setTopVideoDataYT(_data);
      }
      setLoadingChannel(false);
      setLoading(false);
    });
  };

  const handleGetDataStatistic = () => {
    const data = {
      params: {
        project_id: projectId,
        time: `${range} days`,
        channel_ids: channel,
      },
    };
    if (subTab === "2") {
      if (tab === "1") {
        getAPIFacebook(data);
      }
      if (tab === "3") {
        getAPIYoutube(data);
      }
    }
    setChangeFilter();
  }

  useEffect(() => {
    if (projectId && dashboardInit !== 0) {
      if (isChangeFilter) return;
      updateTabDashboard({
        platform: tab,
        subTab,
        channel,
        range
      });
      handleGetDataStatistic();
    }
  }, [channel, range]);

  useEffect(() => {
    if (projectId) {
      const subTabItem = dashboardList[tab].find(item => item.subTab === subTab);
      if (!subTabItem) { // nếu chưa có thì thêm vào redux và call api
        setDashboardList({
          platform: tab,
          subTab,
          channel,
          range
        });
        handleGetDataStatistic();
      } else if (!_.isEqual(subTabItem.channel, channel) || subTabItem.range !== range) {// nếu có item, check xem channel và range có thay đổi không, nếu thay đổi thì call api cập nhật channel và range
        updateTabDashboard({
          platform: tab,
          subTab,
          channel,
          range
        });
        handleGetDataStatistic();
      }
    }
  }, [tab, subTab]);

  return (
    <>
      {tab === "1" && (
        <>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className={`${style.tittle}`}>
                  Bài đăng & Tương tác
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <Post data={postAndEngageData} />
          </Block>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className={`${style.tittle}`}>
                  TOP bài đăng
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <TopPost data={topPostData} />
          </Block>
          <section className="d-none d-md-block">
            <BlockHead size="sm">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle className={`${style.tittle}`}>
                    Khung giờ vàng
                  </BlockTitle>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>
            <Block>
              <GoldTime data={timeGoldData} />
            </Block>
          </section>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className={`${style.tittle}`}>
                  Tương tác & Lượt tiếp cận
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <Reach data={engageAndReachData} />
          </Block>
        </>
      )}
      {tab === "3" && (
        <>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className={`${style.tittle}`}>
                  Số bài đăng & Tương tác
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <YTPost data={postAndEngageDataYT} />
          </Block>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className={`${style.tittle}`}>
                  Top video theo Tỷ lệ tương tác
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <YTTopPost data={topVideoDataYT} />
          </Block>
        </>
      )}
    </>
  );
};

export default PostAndInteraction;
