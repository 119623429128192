import React, { useState } from "react";
import UserAvatar from "../../../../components/user/UserAvatar";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import { useTranslation } from "react-i18next";
import { findUpper } from "../../../../utils/Utils";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

const User = () => {
  const [open, setOpen] = useState(false);
  const userAvatar = useSelector((state) => state.user.avatar);
  const toggle = () => setOpen((prevState) => !prevState);
  const userInfor = JSON.parse(localStorage.getItem("userInfor"));
  const { t } = useTranslation();
  const handleSignout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userInfor");
    Cookies.remove("accessToken");
    Cookies.remove("userInfor");
  };

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle">
          <UserAvatar
            image={userAvatar || userInfor?.avatar}
            text={findUpper(userInfor.name)}
            theme="primary"
          />
          <div className="user-info d-none d-md-block">
            {/*<div className="user-status">Administrator</div>*/}
            <div className="user-name dropdown-indicator">{userInfor.name}</div>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <UserAvatar
              image={userAvatar || userInfor?.avatar}
              text={findUpper(userInfor.name)}
              theme="primary"
            />
            <div className="user-info">
              <span className="lead-text">{userInfor.name}</span>
              <span className="sub-text">{userInfor.email}</span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <LinkItem
              link="/user-profile-regular"
              icon="user-alt"
              onClick={toggle}
            >
              {t("personal_information")}
            </LinkItem>
            <LinkItem
              link="/user-profile-setting"
              icon="setting-alt"
              onClick={toggle}
            >
              {t("security_setting")}
            </LinkItem>
            <LinkItem
              link="/user-profile-iris"
              icon="setting-alt"
              onClick={toggle}
            >
              Iris
            </LinkItem>
            <LinkItem link="/admin/project/list" icon="tranx" onClick={toggle}>
              {t("change_project")}
            </LinkItem>
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <a
              href={`${process.env.PUBLIC_URL}/auth-login`}
              onClick={handleSignout}
            >
              <Icon name="signout"></Icon>
              <span>Sign Out</span>
            </a>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
