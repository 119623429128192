import React, { useState , useCallback } from "react";
import {  Input } from "reactstrap";
// import { uploadImage } from "api/image";
import { ToastContainer, toast } from "react-toastify";
import {useDropzone} from 'react-dropzone'
import { fetchUserInfoLogin } from "../../../../libs/helper";
import { uploadImage } from "../../../../api/createPost";
const UploadImageInput = (props) => {
    const { folder, setSelectImage,id ,isDisplay } = props;


    const [imageLoading, setImageLoading] = useState(false);
    const [file, setFile] = useState();

    const handleFileInputChange = async (e) => {

        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];

            if (file) {
                uploadFileImage(file);
            }
        }
    };

    const uploadFileImage = async (fileMedia) => {
        const userInfor = fetchUserInfoLogin();
        setImageLoading(true);
        const data = new FormData();
        data.append("image", fileMedia);
        data.append("projectId", userInfor.last_project_active);
        data.append("folder", folder);

        

        uploadImage(data).then((res) => {
            if (res.status) {
                setSelectImage(res?.data?.url);
                setImageLoading(false);
            } else {
                console.log("fail");
            }
        }
        ).catch((err) => {
            setImageLoading(false);
            console.log(err);
            
            toast.error(`Có lỗi xảy ra. Vui lòng thử lại!`, {
                autoClose: 2000,
                position: "top-right",
            });
        }
        )
    }
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles) {
            uploadFileImage(acceptedFiles[0]);
        }
        
      }, [])
      const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: {'image/*': []}, maxFiles:1})

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <div className="">
                {/* <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <p>Drop the files here ...</p> :
                            <p>Drag 'n' drop some files here, or click to select files</p>
                    }
                </div> */}

                <Input className={`form-control ${isDisplay ? "" :"d-none"} `} id={id}  onChange={(e) => handleFileInputChange(e)}  type="file" accept="image/png, image/jpeg" />
            </div>
        </React.Fragment>
    );
};

export default UploadImageInput;

