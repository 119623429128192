import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  newsType: "plan",
  newsInfo: "Mini 3 tháng",
  tabSelectedNumber: "1",
  previousNews: {},
  collapseSidebarLeft: false
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setNews(state, action) {
      state.previousNews = {
        newsType: state.newsType,
        newsInfo: state.newsInfo,
      };
      state.newsType = action.payload.newsType;
      state.newsInfo = action.payload.newsInfo;
    },
    undoSetNews(state) {
      state.newsType = state.previousNews.newsType;
      state.newsInfo = state.previousNews.newsInfo;
    },
    setTabNumber(state, action) {
      if (action.payload.newsType) {
        state.newsType = action.payload.newsType
      }
      state.tabSelectedNumber = action.payload.tabSelectedNumber;
    },
    setCollapseSidebarLeft(state, action) {
      state.collapseSidebarLeft = action.payload
    },
  },
});
export const { setNews, undoSetNews, setTabNumber, setCollapseSidebarLeft } = layoutSlice.actions;

export default layoutSlice.reducer;
