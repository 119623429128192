import React, { useState } from "react";
import { Icon, Row, Col, Img } from "../../../components/Component";
import DefaultChannelAvatar from "../../images/avatar/default-channel-avatar.png";
import IconComment from "../../images/icons/preview-facebook-comment.svg";
import IconLike from "../../images/icons/preview-facebook-like.svg";
import IconShare from "../../images/icons/preview-facebook-share.svg";
import { CONTENT_LIMIT_SEE_MORE, MEDIA_TYPE_IMAGE, MEDIA_TYPE_VIDEO } from "../../utils/Utils";
import style from "./index.module.scss";
import "./_preview-facebook.scss";
import {
  getPlatformIconFromLabel
} from "../../utils/Utils";
const PreviewFacebook = ({ ...props }) => {

  const { channel, content, mediaItems, className } = props;
  const [openImage, setOpenImage] = useState(true)
  const displayContent =
    content?.length <= CONTENT_LIMIT_SEE_MORE ? (
      content
    ) : (
      <>
        {content?.slice(0, CONTENT_LIMIT_SEE_MORE)}
        <b>See more...</b>
      </>
    );

  const handleOpenPreviewFace = () => {
    setOpenImage(!openImage)
    const Elmchevron = document.getElementById(`chevron_previewFace`);
    const Elmcontent = document.getElementById("content_previewFace");
    if (!openImage) {
      Elmchevron.classList.add(`${style.openChevronDown}`)
      Elmchevron.classList.remove(`${style.openChevronUp}`)
      Elmcontent.classList.add(`${style.openContentDown}`)
      Elmcontent.classList.remove(`${style.openContentUp}`)
    }
    else {
      Elmchevron.classList.add(`${style.openChevronUp}`)
      Elmchevron.classList.remove(`${style.openChevronDown}`)
      Elmcontent.classList.add(`${style.openContentUp}`)
      Elmcontent.classList.remove(`${style.openContentDown}`)
    }
  }

  const renderLayoutMedia = () => {
    const itemImage = mediaItems.find((c) => (c.type === MEDIA_TYPE_IMAGE))
    if (itemImage) {
      switch (mediaItems.length) {
        case 1:
        case 2:
          return (
            <div className="imageWrapper">
              {mediaItems.map(e => (
                <Img src={e?.url} alt="something" className="image" />
              ))}
            </div>
          );
        case 3:
          return (
            <div className="imageWrapper">

              <Img src={mediaItems[0]?.url} alt="something" className="image mb-px" />
              <Row className="gx-1">
                <Col md={6}>
                  <Img src={mediaItems[1]?.url} alt="something" className="image h-full w-full" />
                </Col>
                <Col md={6}>
                  <Img src={mediaItems[2]?.url} alt="something" className="image h-full w-full" />
                </Col>
              </Row>
            </div>
          );
        case 4:
          return (
            <div className="imageWrapper">
              <Img src={mediaItems[0]?.url} alt="something" className="image mb-px" />
              <Row className="gx-1">
                <Col md={4}>
                  <Img src={mediaItems[1]?.url} alt="something" className="image h-full w-full" />
                </Col>
                <Col md={4}>
                  <Img src={mediaItems[2]?.url} alt="something" className="image h-full w-full" />
                </Col>
                <Col md={4}>
                  <Img src={mediaItems[3]?.url} alt="something" className="image h-full w-full" />
                </Col>
              </Row>
            </div>
          );
        default:
          return (
            <div className="imageWrapper">

              <Img src={mediaItems[0]?.url} alt="something" className="image mb-px" />
              <Row className="gx-1">
                <Col md={4}>
                  <Img src={mediaItems[1]?.url} alt="something" className="image h-full w-full" />
                </Col>
                <Col md={4}>
                  <Img src={mediaItems[2]?.url} alt="something" className="image h-full w-full" />
                </Col>
                <Col md={4}>
                  <div style={{
                    backgroundImage: `url(${mediaItems[3]?.url})`,
                    backgroundSize: "contain",
                    width: "100%",
                    height: "100%"
                  }}>
                    <div className="h-full w-full d-flex justify-center" style={{ background: 'rgba(0, 0, 0, 0.4)', }}>
                      <span className={style.numberImage}>+{mediaItems.length - 3}</span>
                    </div>
                  </div>

                </Col>
              </Row>
            </div>
          );
      }
    }
    else {
      const item = mediaItems.find((c) => (c.type === MEDIA_TYPE_VIDEO))
      return (
        <div className="imageWrapper">
          <Img src={item?.previewImages?.url} alt="something" className="image h-full w-full" />
          <div style={{ border: "1px solid #fff", borderRadius: "50%", left: "50%", top: "50%", position: "absolute", background: 'rgba(0, 0, 0, 0.5)', padding: "5px" }}>
            <svg style={{ color: "#fff" }} width="40" height="40" fill="currentColor" className="bi bi-play" viewBox="0 0 16 16">
              <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z" />
            </svg>
          </div>
        </div>
      );
    }
  }

  return (
    <div className={`previewFacebook ${className || ""}`}>
      <div className="d-flex align-items-stretch border-bottom">
        <div
          className="flex-fill p-2 fw-medium"
          style={{ color: "#333333", fontFamily: "Roboto" }}
        >
          <Img
            src={getPlatformIconFromLabel("facebook")}
            width= "16px"
            size="circle"
            style={{marginRight: "4px" }}
          />
          Facebook
        </div>
        <div id="chevron_previewFace" className={`card-tools p-2 ${style.openMedia}`} onClick={handleOpenPreviewFace}>
          <svg width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" /> </svg>
        </div>
      </div>
      <div id="content_previewFace" className={`previewFacebook`} style={{
        transition: "max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s",
        willChange: "max-height",
        position: "relative",
        overflow: "hidden"
      }}>
        <div className="header">
          <Img className="avatar" src={channel.picture || DefaultChannelAvatar} alt="avatar" />
          <div className="info">
            <div className="name">{channel.name}</div>
            <div className="time">
              1h · <Icon name="globe"></Icon>
            </div>
          </div>
          <div className="moreAction">
            <Icon name="more-h"></Icon>
          </div>
        </div>
        <div className="body">
          {content?.length > 0 && <div className="content">{displayContent}</div>}
          {mediaItems?.length > 0 &&
            renderLayoutMedia()}
        </div>
        <div className="action">
          <div className="buttonWrapper">
            <button>
              <Img src={IconLike} alt="like" />
              <span>Like</span>
            </button>
          </div>
          <div className="buttonWrapper">
            <button>
              <Img src={IconComment} alt="comment" />
              <span>Comment</span>
            </button>
          </div>
          <div className="buttonWrapper">
            <button>
              <Img src={IconShare} alt="share" />
              <span>Share</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewFacebook;