import React from 'react'
import { Icon } from "../../../components/Component";
import style from "./index.module.scss";
export default function InitialColumn({showCollapseSidebarAddChannel}) {
  return (
    <div className={`d-flex align-items-center justify-center p-2 w-1/5 h-screen ${style.addHeader}`}>
    <button
              className={`rounded-circle text-white bg-pink d-flex align-items-center justify-content-center border-0 ${style.emptyChannelAdd}`}
              onClick={showCollapseSidebarAddChannel}
            >
              <Icon name="plus" />
            </button>
            <span className={style.textAddChannel}>Thêm kênh theo dõi</span>
            </div>
  )
}
