import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from 'react-router-dom';
// import { useDispatch } from "react-redux";
import { Icon } from "../../../components/Component";
import ReFreshContext from './RefreshContext'
import Head from "../../../layout/head/Head";
import style from "./index.module.scss";
import { toast } from "react-toastify";
import ModalSelectChannelPlatform from "../../components/ConnectSocialNetwork/ModalSelectChannelPlatform";
import {
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Tooltip,
} from "reactstrap";
import ResearchPage from "./ResearchPage";
// import ResearchContent from "./ResearchContent/ResearchContent";
import EDIT_SVG from "../../images/post/edit.svg";
import CHANGE_PAGE_SVG from "../../images/post/change-page.svg";

const ResearchCompetitor = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("2");
  const [isSelectPage, setIsSelectPage] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [onHoverPage, setOnHoverPage] = useState(false);
  const [currentPage, setCurrentPage] = useState({});
  const [isRefresh, setIsRefresh] = useState(false);
  const history = useHistory();
  const location = useLocation();


  const handleHoverPage = () => {
    setOnHoverPage(!onHoverPage);
  };

  const handlePost = () => {
    setIsSelectPage(!isSelectPage);
  };
  const showModalAddFanPage = () => {
    setIsOpen(!isOpen);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  useEffect(() => {
    const getActiveTab = JSON.parse(localStorage.getItem("activeTab"));
    if (getActiveTab) {
      setActiveTab(getActiveTab);
    }
    const { action } = history;
   if (action==="PUSH") {
    setIsRefresh(true);
   }
  }, []);

  useEffect(() => {
    localStorage.setItem("activeTab", JSON.stringify(activeTab));
  }, [activeTab]);

  // check connect channel
  const [isOpenModalSelectPlatform, setIsOpenModalSelectPlatform] =
    useState(false);
  const toggleIsOpenModalSelectPlatform = () => {
    setIsOpenModalSelectPlatform((v) => !v);
  };
  return (
    <ReFreshContext.Provider value={isRefresh}>
      <Head title={t("market_trending")} />

      <div className={style.navHead}>
        <Nav tabs className={`mt-n3 ${style.navPost}`}>
          <div style={{ display: "flex" }}>
            {/* <NavItem className={`${style.navWrapper}`}>
              <NavLink
                tag="a"
                href="#tab"
                className={`${activeTab === "1" && style.active} ${
                  style.navItem
                }`}
                style={{ borderTopLeftRadius: "8px" }}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("1");
                }}
              >
                <Icon
                  name="text-a"
                  style={{
                    fontSize: "1em",
                    color: activeTab === "1" ? "#FD1F6D" : "#FFFFFF",
                  }}
                  className="mr-1"
                />{" "}
                Tìm nội dung
              </NavLink>
            </NavItem> */}
            <NavItem className={`${style.navWrapper}`}>
              <NavLink
                tag="a"
                href="#tab"
                className={`${activeTab === "2" && style.active} ${style.navItem
                  }`}
                style={{ borderTopRightRadius: "8px" }}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("2");
                }}
              >
                <Icon
                  name="layers-fill"
                  style={{
                    fontSize: "1em",
                    color: activeTab === "2" ? "#FD1F6D" : "#FFFFFF",
                  }}
                  className="mr-1"
                />{" "}
                Tìm page
              </NavLink>
            </NavItem>
          </div>

          <div className={style.modalUpdatePage}>
            <Button
              className={style.buttonEditPage}
              onClick={showModalAddFanPage}
            >
              <img
                src={EDIT_SVG}
                alt=""
                style={{
                  width: "14px",
                }}
              />
              <span
                style={{
                  paddingLeft: "5px",
                }}
              >
                {" "}
                HIỂN THỊ
              </span>
            </Button>

            <Button
              className={style.buttonEditPage}
              id="btnSelectPage"
              onMouseEnter={handleHoverPage}
              onClick={handlePost}
            >
              <img
                src={currentPage.picture || CHANGE_PAGE_SVG}
                alt=""
                style={{
                  width: "22px",
                }}
              />{" "}
              <span
                style={{
                  paddingLeft: "5px",
                }}
              >
                {" "}
                PAGE BÌNH LUẬN
              </span>
            </Button>
            <Tooltip
              placement="bottom"
              isOpen={onHoverPage}
              target={"btnSelectPage"}
              toggle={handleHoverPage}
            >
              {currentPage.name}
            </Tooltip>
          </div>
        </Nav>
      </div>
      <div style={{ marginTop: 130, zIndex: 2 }}>
        <TabContent activeTab={activeTab}>
          {/* <TabPane tabId="1">
            {" "}
            <ResearchContent />
          </TabPane> */}
          <TabPane tabId="2">
            <ResearchPage
              isOpen={isOpen}
              handlePost={handlePost}
              isSelectPage={isSelectPage}
              showModalAddFanPage={showModalAddFanPage}
              setCurrentPage={setCurrentPage}
            />
          </TabPane>
        </TabContent>
        <ModalSelectChannelPlatform
          isOpen={isOpenModalSelectPlatform}
          toggle={toggleIsOpenModalSelectPlatform}
          toast={toast}
        />
      </div>
    </ReFreshContext.Provider>
  );
};
export default ResearchCompetitor;
