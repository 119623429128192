import React, { useEffect, useRef, useState } from "react";
import { RiGitMergeLine } from "@remixicon/react";
import { useTranslation } from "react-i18next";
import { getListChilds, updateTask } from "../../../../api/kanban";
import { CheckedBox, Icon } from "../../../../components/Component";
import { LoadingItem } from "../../Dashboard_v2/CommonItem/LoadingItem";
import DateTimeInput from "./DateTimeInput";
import UserSelect from "./UserSelect";
import "../styles.css";
import InfiniteScroll from "react-infinite-scroller";
import { Spinner } from "reactstrap";

const DetailSubtask = ({
  listDetail,
  listChild,
  isCreateChild,
  setIsCreateChild,
  handleCompleteChild,
  handleTaskDetail,
  setParentTitle,
  isLoadingChild,
  createChild,
  childTitle,
  setChildTitle,
  setListChild,
  staffOptions,
  checkLoadMoreSubtaskFirst,
}) => {
  const { t } = useTranslation();
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));

  const addChildRef = useRef(null);
  const inputChildRef = useRef(null);
  const editInputRef = useRef(null);
  const loading = useRef(false);

  const [openStates, setOpenStates] = useState({});
  const [editStates, setEditStates] = useState({});
  const [tempTitle, setTempTitle] = useState({});
  const [createOwner, setCreateOwner] = useState({
    avatar: userInfo.avatar,
    label: userInfo.name,
    value: userInfo.id,
  });
  const [createEnded, setCreateEnded] = useState(undefined);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(2);
  const [isShowBtnLoadMore, setIsShowBtnLoadMore] = useState(false);

  useEffect(() => {
    setIsShowBtnLoadMore(checkLoadMoreSubtaskFirst);
  }, [checkLoadMoreSubtaskFirst]);

  const loadChild = () => {
    if (loading.current) return;
    loading.current = true;

    getListChilds(userInfo.last_project_active, listDetail._id, page)
      .then((response) => {
        const newTasksArray = response.data;

        setListChild((prev) => ({
          ...prev,
          data: [...prev.data, ...newTasksArray.data],
        }));

        if (newTasksArray.data.length >= 15) {
          setIsShowBtnLoadMore(true);
        }

        setHasMore(false);

        setPage((prevPage) => prevPage + 1);
      })
      .catch(() => {
        loading.current = false;
        setIsShowBtnLoadMore(false);
      })
      .finally(() => {
        loading.current = false;
        setIsShowBtnLoadMore(false);
      });
  };

  const handleOnPressLoadMore = () => {
    setHasMore(true);
  };

  const infiniteScrollLoader = (
    <div style={{ textAlign: "center", marginTop: "10%" }}>
      <Spinner color="primary" />
    </div>
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isCreateChild &&
        addChildRef.current &&
        !addChildRef.current.contains(event.target)
      ) {
        if (createEnded) {
          createChild(createOwner, createEnded);
          setCreateEnded(undefined);
        } else {
          createChild(createOwner);
        }
        setIsCreateChild(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isCreateChild, createEnded]);

  useEffect(() => {
    if (isCreateChild && inputChildRef.current) {
      inputChildRef.current.focus();
    }
  }, [isCreateChild]);

  useEffect(() => {
    if (Object.values(editStates).includes(true) && editInputRef.current) {
      editInputRef.current.focus();
    }
  }, [editStates]);

  const handleCreateChild = (event) => {
    if (event.key === "Enter" && childTitle) {
      createChild(createOwner, createEnded);

      setCreateEnded(undefined);
      setCreateOwner({
        avatar: userInfo.avatar,
        label: userInfo.name,
        value: userInfo.id,
      });
    }
  };

  const handleUpdateChildTask = async (id, data) => {
    try {
      await updateTask(userInfo.last_project_active, id, data);
      setIsCreateChild(false);
    } catch (error) {
      console.error("Error update task:", error);
    }
  };

  const handleDueChild = (date, id) => {
    const timestamp = new Date(date).getTime();

    const updatedListChild = listChild.data.map((item) => {
      if (item._id === id) {
        return {
          ...item,
          ended_at: timestamp / 1000,
        };
      }
      return item;
    });

    setListChild((prev) => ({
      ...prev,
      data: updatedListChild,
    }));

    handleUpdateChildTask(id, { ended_at: timestamp / 1000 });
  };

  const handleSelectOwner = ({ value, id }) => {
    const updatedListChild = listChild.data.map((item) => {
      if (item._id === id) {
        return {
          ...item,
          owner: value,
        };
      }
      return item;
    });

    setListChild((prev) => ({
      ...prev,
      data: updatedListChild,
    }));

    handleUpdateChildTask(id, { owner_id: value.value });
  };

  const toggleUserSelect = (index) => {
    setOpenStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const toggleEditState = (index, title) => {
    setEditStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
    setTempTitle((prevState) => ({
      ...prevState,
      [index]: title,
    }));
  };

  const handleUpdateTitle = (id, index, title) => {
    const updatedListChild = listChild.data.map((item) => {
      if (item._id === id) {
        return {
          ...item,
          title,
        };
      }
      return item;
    });

    setListChild((prev) => ({
      ...prev,
      data: updatedListChild,
    }));

    handleUpdateChildTask(id, { title });
    toggleEditState(index, title);
  };

  return (
    <div className="w-100">
      <div className="detail-item">
        <RiGitMergeLine size={16} className="text-muted cursor-pointer" />
        {listDetail?.cnt_children_task > 0 ? (
          <span style={{ color: "#909590", cursor: "pointer" }}>
            {listDetail?.cnt_children_task_complete} /{" "}
            {listDetail?.cnt_children_task}
          </span>
        ) : (
          <div
            className="item-button"
            style={{ fontSize: "12px" }}
            onClick={() => setIsCreateChild(!isCreateChild)}
          >
            {t("task_management.detail.add_sub_task")}
          </div>
        )}
      </div>
      <div
        style={{
          marginTop: "8px",
          display:
            !isCreateChild && listDetail?.cnt_children_task === 0 && "none",
        }}
      >
        <InfiniteScroll
          loadMore={loadChild}
          hasMore={hasMore}
          loader={listChild?.data?.length > 0 && infiniteScrollLoader}
          useWindow={false}
        >
          {listChild?.data?.length > 0 ? (
            listChild?.data?.map((item, index) => {
              return (
                <div className="subtask-item" key={item._id}>
                  <div
                    className="d-flex items-center"
                    style={{ gap: "12px", width: "100%" }}
                  >
                    <CheckedBox
                      width={16}
                      height={16}
                      checked={item.is_complete}
                      onChange={() => handleCompleteChild(item)}
                    />
                    {editStates[index] ? (
                      <input
                        className="subtask-input ml-1"
                        style={{
                          backgroundColor: "transparent",
                          width: "100%",
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                        type="text"
                        value={tempTitle[index]}
                        ref={editInputRef}
                        onChange={(e) =>
                          setTempTitle({
                            ...tempTitle,
                            [index]: e.target.value,
                          })
                        }
                        onBlur={() =>
                          handleUpdateTitle(item._id, index, tempTitle[index])
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter")
                            handleUpdateTitle(
                              item._id,
                              index,
                              tempTitle[index]
                            );
                        }}
                      />
                    ) : (
                      <span
                        style={{
                          textDecoration: `${
                            item.is_complete ? "line-through" : ""
                          }`,
                          cursor: "pointer",
                          width: "100%",
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                        onClick={() => toggleEditState(index, item.title)}
                      >
                        {item.title?.length > 50
                          ? item.title.substring(0, 49) + "... "
                          : item.title}
                      </span>
                    )}
                  </div>
                  <div
                    className={`items-center d-flex relative`}
                    style={{
                      justifyContent: "flex-end",
                      color: "#90959D",
                      gap: "12px",
                      marginRight: "8px",
                      width: "100%",
                    }}
                  >
                    <div
                      className={`
                  ${item.ended_at ? "d-flex" : "action-icons"}`}
                    >
                      <DateTimeInput
                        date={
                          item.ended_at ? new Date(item.ended_at * 1000) : null
                        }
                        setDate={(date) => {
                          handleDueChild(date, item._id);
                        }}
                        isIcon={true}
                        popperPlacement="left-start"
                        started_at={new Date(item.started_at * 1000)}
                      />
                    </div>

                    <div
                      className={`
                  ${item.owner_id ? "d-flex" : "action-icons"}`}
                    >
                      <UserSelect
                        isOpen={!!openStates[index]}
                        setIsOpen={() => toggleUserSelect(index)}
                        data={item.owner}
                        defaultValue={item?.owner_id}
                        options={staffOptions}
                        handleSelect={(value) => {
                          handleSelectOwner({ value, id: item._id });
                        }}
                        placeholder={t("task_management.placeholder.add_owner")}
                        direction={"dropleft"}
                        isMulti={false}
                        hideName={true}
                      />
                    </div>

                    <div
                      className="d-flex items-center"
                      onClick={() => {
                        setParentTitle(listDetail?.title);
                        handleTaskDetail(item._id);
                      }}
                    >
                      <Icon
                        name="arrow-right"
                        className={"action-button cursor-pointer"}
                        style={{ fontSize: "16px" }}
                      />
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div></div>
          )}
        </InfiniteScroll>
        {isShowBtnLoadMore && (
          <div
            onClick={() => handleOnPressLoadMore()}
            style={{ marginLeft: "28px" }}
            className="more-btn"
          >
            view more
          </div>
        )}
        {isCreateChild && (
          <div
            className="w-100 d-flex items-center justify-content-between"
            style={{
              height: "32px",
              backgroundColor: "#F5F6F7",
              padding: "8px 8px 8px 28px",
              borderRadius: "4px",
            }}
            ref={addChildRef}
          >
            <div
              className="d-flex items-center"
              style={{ gap: "12px", width: "60%" }}
            >
              <input
                type="checkbox"
                style={{
                  cursor: "pointer",
                }}
              />
              <input
                className="subtask-input w-100"
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                }}
                type="text"
                placeholder={t(
                  "task_management.placeholder.enter_to_craete_sub_task"
                )}
                value={childTitle}
                onChange={(e) => setChildTitle(e.target.value)}
                ref={inputChildRef}
                onKeyDown={handleCreateChild}
              />
            </div>
            <div
              style={{ color: "#909590", gap: "12px", width: "auto" }}
              className="d-flex items-center"
            >
              <DateTimeInput
                date={
                  createEnded !== undefined
                    ? new Date(createEnded * 1000)
                    : null
                }
                setDate={(date) => {
                  setCreateEnded(date.getTime() / 1000);
                }}
                started_at={new Date(listDetail?.started_at * 1000)}
                isIcon={true}
                popperPlacement="left-start"
              />

              <UserSelect
                isOpen={isOpenCreate}
                setIsOpen={() => setIsOpenCreate(!isOpenCreate)}
                data={createOwner}
                defaultValue={listDetail?.owner_id}
                options={staffOptions}
                handleSelect={(value) => {
                  setCreateOwner(value);
                }}
                placeholder={t("task_management.placeholder.add_owner")}
                direction={"dropleft"}
                isMulti={false}
                hideName={true}
              />
            </div>
          </div>
        )}
        {listDetail?.cnt_children_task > 0 &&
          (isLoadingChild ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "32px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoadingItem />
            </div>
          ) : (
            <div
              className="item-button"
              style={{
                padding: "8px 0 8px 28px",
              }}
              onClick={() => setIsCreateChild(!isCreateChild)}
            >
              <Icon
                name="plus"
                style={{
                  color: "#90959D",
                  fontSize: "16px",
                }}
              />
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                {t("task_management.detail.add_sub_task")}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DetailSubtask;
