import { Link } from "react-router-dom";
import { Button, Modal, ModalBody } from "reactstrap";
import { Icon } from "../../../components/Component";

const ModalAlertFailed = ({ ...props }) => {
  const { modalFailed, toggleFailed } = props;
  return (
    <Modal isOpen={modalFailed} toggle={toggleFailed}>
      <ModalBody className="modal-body-lg text-center">
        <div className="nk-modal">
          <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></Icon>
          <h4 className="nk-modal-title">Lỗi</h4>
          <div className="nk-modal-text">
            <div className="caption-text">
              Đã có lỗi xảy ra. Xin thử lại!
            </div>
          </div>
          <div className="nk-modal-action">
            <Button color="danger" size="lg" className="btn-mw" onClick={toggleFailed}>
              OK
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalAlertFailed;
