import { Link } from "react-router-dom";
import { Button, Modal, ModalBody } from "reactstrap";
import { Icon } from "../../../components/Component";

const ModalAlertSuccess = ({ ...props }) => {
  const { modalSuccess, toggleSuccess } = props;
  return (
    <Modal isOpen={modalSuccess} toggle={toggleSuccess}>
      <ModalBody className="modal-body-lg text-center">
        <div className="nk-modal">
          <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-check bg-success"></Icon>
          <h4 className="nk-modal-title">Hoàn thành</h4>
          <div className="nk-modal-text">
            <div className="caption-text">
              {props.textNotification}
            </div>
          </div>
          <div className="nk-modal-action">
            <Button color="primary" size="lg" className="btn-mw" onClick={props._handleSubmitModal}>
              OK
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalAlertSuccess;
