export const KEY_LOCAL_STORAGE_TOKEN = "user_token";
export const KEY_LOCAL_STORAGE_USER_INFORMATION = "user_information";

const getChartColorsArray = (colors) => {
  colors = JSON.parse(colors);
  return colors.map(function (value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(
        newValue
      );

      if (color.indexOf("#") !== -1) color = color.replace(" ", "");
      if (color) return color;
      else return newValue;
    } else {
      var val = value.split(",");
      if (val.length === 2) {
        var rgbaColor = getComputedStyle(
          document.documentElement
        ).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
};

export const renderNameField = (offsetWidth, name, maxWidth) => {
  return (offsetWidth || 0) < (maxWidth ?? 150) && name.length > 10
    ? name.substring(0, 10) + "..."
    : name;
};

export const getUserInfoInLocalStorage = () => {
  // const stringUserInfo =
  //   localStorage.getItem(KEY_LOCAL_STORAGE_USER_INFORMATION) || "";
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  return userInfo;
};

export const getProjectIdInLocalStorage = () => {
  // const stringUserInfo =
  //   localStorage.getItem(KEY_LOCAL_STORAGE_USER_INFORMATION) || "";
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  return userInfo.last_project_active;
};

export default getChartColorsArray;
