import React, { useState } from "react";
import styles from "../PostDetail.module.scss";
import { Card, CardBody, Collapse } from "reactstrap";
import CheckedBox from "../../../../components/CheckedBox";
import { useTranslation } from "react-i18next";

const CheckList = ({ listTaskData }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <div className={styles.itemSideRight}>
      <div className={styles.headerTasklist} onClick={toggle}>
        <div className={styles.headerLeft}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
            fill="rgba(75,56,179,1)"
          >
            <path d="M8.00008 6V9H5.00008V6H8.00008ZM3.00008 4V11H10.0001V4H3.00008ZM13.0001 4H21.0001V6H13.0001V4ZM13.0001 11H21.0001V13H13.0001V11ZM13.0001 18H21.0001V20H13.0001V18ZM10.7072 16.2071L9.29297 14.7929L6.00008 18.0858L4.20718 16.2929L2.79297 17.7071L6.00008 20.9142L10.7072 16.2071Z"></path>
          </svg>
          <span>{t("post_detail.side_right.check_list.tilte")}</span>
          {listTaskData?.count > 0 && (
            <span className={styles.count}>{listTaskData?.count}</span>
          )}
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="16"
          height="16"
          fill="currentColor"
        >
          <path d="M11.9999 13.1714L16.9497 8.22168L18.3639 9.63589L11.9999 15.9999L5.63599 9.63589L7.0502 8.22168L11.9999 13.1714Z"></path>
        </svg>
      </div>
      <Collapse isOpen={isOpen} style={{ visibility: "visible" }}>
        <Card>
          <CardBody
            className={styles.bodyTask}
            style={{ padding: "0", paddingTop: "12px" }}
          >
            <div className={styles.fillterTask}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="16"
                height="16"
                fill="rgba(53,119,241,1)"
              >
                <path d="M18.3638 15.5355L16.9496 14.1213L18.3638 12.7071C20.3164 10.7545 20.3164 7.58866 18.3638 5.63604C16.4112 3.68341 13.2453 3.68341 11.2927 5.63604L9.87849 7.05025L8.46428 5.63604L9.87849 4.22182C12.6122 1.48815 17.0443 1.48815 19.778 4.22182C22.5117 6.95549 22.5117 11.3876 19.778 14.1213L18.3638 15.5355ZM15.5353 18.364L14.1211 19.7782C11.3875 22.5118 6.95531 22.5118 4.22164 19.7782C1.48797 17.0445 1.48797 12.6123 4.22164 9.87868L5.63585 8.46446L7.05007 9.87868L5.63585 11.2929C3.68323 13.2455 3.68323 16.4113 5.63585 18.364C7.58847 20.3166 10.7543 20.3166 12.7069 18.364L14.1211 16.9497L15.5353 18.364ZM14.8282 7.75736L16.2425 9.17157L9.17139 16.2426L7.75717 14.8284L14.8282 7.75736Z"></path>
              </svg>
              <input placeholder={t("post_detail.side_right.check_list.link_task_placeholder")} />
            </div>
            <div className={styles.listTask}>
              {listTaskData?.tasks?.map((task, _) => (
                <div className={styles.itemTask}>
                  <div className={styles.leftTask}>
                    <CheckedBox checked={task?.is_complete} />
                    <p>{task.title}</p>
                  </div>
                  <div className={styles.rightTask}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="16"
                      height="16"
                      fill="currentColor"
                    >
                      <path d="M4 22C4 17.5817 7.58172 14 12 14C16.4183 14 20 17.5817 20 22H18C18 18.6863 15.3137 16 12 16C8.68629 16 6 18.6863 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"></path>
                    </svg>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.addTask}>
              <p>{t("post_detail.side_right.check_list.add_task")}</p>
            </div>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

export default CheckList;
