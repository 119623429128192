import React, { useReducer, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import { toast } from "react-toastify"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledDropdown
} from "reactstrap"
import { invite, removeMember } from "../../../../api/user"
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle, Button, DataTable,
  DataTableBody,
  DataTableHead, DataTableItem, DataTableRow, Icon
} from "../../../../components/Component"
import Content from "../../../../layout/content/Content"
import Head from "../../../../layout/head/Head"
import { upperFirstLetter } from "../../../../utils/Utils"
import { arrayStatus, STATUS_ACTIVE, STATUS_WAITING_VERIFICATION } from "../../../utils/Utils"
import "./toast.scss"
import ConfimDelete from "../../../components/Modal/ModalConfimDelete";
import { toastError, toastSuccess } from "../../../../libs/toasts";
const initModalData = {
  email: "",
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'edit_email':
      return { ...state, email: action.payload.trim() }
    case 'reset':
      return initModalData
    default:
      throw new Error()
  }
}

const UserListCompact = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const project = useSelector((state) => state.project.project)

  const [showModal, setShowModal] = useState(false)
  const [modalData, dispatch] = useReducer(reducer, initModalData)
  const [modalDelete, setModalDelete] = useState(false);
  const [idDeleteItem, setIdDeleteItem] = useState();
  const toggleDelete = () => {
    setModalDelete(!modalDelete);
  };

  const toggleOpenDeleteConfirm = (id) => {
    setIdDeleteItem(id);
    setModalDelete(true);
  };

  const toggleShowModal = () => {
    setShowModal(s => !s)
  }

  const editModalData = (key, value) => {
    dispatch({
      type: `edit_${key}`,
      payload: value
    })
  }

  const onInviteUser = async () => {
    const userAlreadyAdded = Object.keys(project.members).some(key => project.members[key].email === modalData.email)
    const validEmail = /^(([^<>()\[\]\\.,:\s@"]+(\.[^<>()\[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(modalData.email)
    if (!validEmail) {
      alert('Invalid Email!')
      return
    }
    if (!userAlreadyAdded) {
      try {
        const inviteData = {
          email: modalData.email,
          projectId: project?._id
        }
        invite(inviteData).then(res => {
          if (res.status) {
            toast.success(`Đã gửi lời mời đến email ${modalData.email}! Vui lòng chờ xác nhận!`, {
              autoClose: 1000,
              position: "top-right",
            });
          }
          else {
            toast.error(res.message, {
              autoClose: 1000,
              position: "top-right",
            });
          }
        }).catch(err => {
          toast.error(err, {
            autoClose: 1000,
            position: "top-right",
          });
        });
      } finally {
        setTimeout(() => {
          dispatch({
            type: 'reset'
          })
          toggleShowModal()
          history.go(0)
        }, 1500)
      }
    }
  }

  const onRemoveUser = async (id, e) => {
    e.stopPropagation();
    if (id) {
      const removeData = {
        userId: id,
        projectId: project?._id
      }
      try {
        const res = await removeMember(removeData);
        if (res && res.status) {
          setModalDelete(false);
          toastSuccess("Xóa thành viên thành công!", 1000);
        } else {
          setModalDelete(false);
          toastError("Xóa thành viên thất bại!", 1000);
        }
      } catch (error) {
        setModalDelete(false);
        toastError("Xóa thành viên thất bại!", 1000);
      }
    }
  }

  return (
    <>
      <Head title="User List - Compact"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Users Lists
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {project?.members?.length || 0} user(s).</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-primary`}
                  onClick={toggleShowModal}
                >
                  {t('add_user')}
                </Button>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <DataTable className="card-stretch">
            <DataTableBody compact>
              <DataTableHead>
                <DataTableRow className="nk-tb-col-check">
                  <div className="custom-control custom-control-sm custom-checkbox notext">
                    <input
                      type="checkbox"
                      className="custom-control-input form-control"
                      onChange={(e) => { }}
                      id="uid"
                    />
                    <label className="custom-control-label" htmlFor="uid"></label>
                  </div>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">{t('project_index_table')}</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">{t('project_staff_name')}</span>
                </DataTableRow>
                <DataTableRow size="sm">
                  <span className="sub-text">{t('project_staff_phone')}</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">{t('project_staff_email')}</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">{t('project_staff_role')}</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">{t('project_staff_status')}</span>
                </DataTableRow>
                <DataTableRow>
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {project?.members && Object.keys(project?.members).map((key, index) => (
                <DataTableItem key={project?.members[key].id}>
                  <DataTableRow size="md">
                  </DataTableRow>
                  <DataTableRow size="md">
                    <span>{index + 1}</span>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <span>{project?.members[key].name}</span>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <span>{project?.members[key].phone}</span>
                  </DataTableRow>
                  <DataTableRow size="sm">
                    <span>{project?.members[key].email}</span>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <span>{upperFirstLetter(project?.members[key].role)}</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span
                      className={`tb-status text-${project?.members[key].status === STATUS_ACTIVE
                        ? "success"
                        : project?.members[key].status === STATUS_WAITING_VERIFICATION
                          ? "warning"
                          : "danger"
                        }`}
                    >
                      {t(arrayStatus[project?.members[key].status])}
                    </span>
                  </DataTableRow>
                  <DataTableRow className="nk-tb-col-tools">
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <UncontrolledDropdown>
                          <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                            <Icon name="more-h"></Icon>
                          </DropdownToggle>
                          <DropdownMenu right className="dropdown-menu-xs">
                            <ul className="link-list-opt no-bdr">
                              {project?.members[key].role.toLowerCase().includes('member') && (
                                <li>
                                  <DropdownItem
                                    tag="button"
                                    onClick={() => toggleOpenDeleteConfirm(project?.members[key].id)}
                                  >
                                    <span>{t('add_user_choice_remove')}</span>
                                  </DropdownItem>
                                </li>
                              )}
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </li>
                    </ul>
                  </DataTableRow>
                </DataTableItem>
              ))}
            </DataTableBody>
          </DataTable>
        </Block>
      </Content>
      <Modal isOpen={showModal} toggle={toggleShowModal} className="modal-md">
        <ModalHeader toggle={toggleShowModal}>
          {t('add_user')}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label className="form-label" htmlFor="fv-email">
              {t('add_user_email')}
            </Label>
            <div className="form-control-wrap">
              <Input
                type="email"
                id="fv-email"
                className="form-control"
                value={modalData.email}
                onChange={(e) => editModalData('email', e.target.value)}
              />
            </div>
          </FormGroup>
          <FormGroup className="mt-2">
            <Button color="primary" onClick={onInviteUser}>
              {t('add_user')}
            </Button>
          </FormGroup>
        </ModalBody>
      </Modal>

      <ConfimDelete
        modalDelete={modalDelete}
        toggleDelete={toggleDelete}
        messeage={t("message.data_user_will_deleted_from_system")}
        handleDelete={e => onRemoveUser(idDeleteItem, e)}
      />
    </>
  )
}
export default UserListCompact
