import axios from "axios";
import { API } from "../libs/client";

/**
 * @param {string} projectId
 * @param {string} channelId
 * @param {{params: {page: number}}} data
 * @returns
 */
export const getPostsInChannel = async (projectId, channelId, data) => {
  const uri = `/projects/${projectId}/channels/${channelId}/posts`;
  const res = await API.get(uri, data);
  return res.data;
};

/**
 * @param {string} projectId
 * @param {string} channelId
 * @param {{params: {post_id: string}}} data
 * @returns
 */
export const getPostDetailInChannel = async (projectId, channelId, data) => {
  const uri = `/projects/${projectId}/channels/${channelId}/posts/detail`;
  const res = await API.get(uri, data);
  return res.data;
};

/**
 * @param {string} projectId
 * @param {string} channelId
 * @param {{params: {post_id: string, page: string}}} data
 * @returns
 */
export const getComments = async (projectId, channelId, data) => {
  const uri = `/projects/${projectId}/channels/${channelId}/posts/detail/comment`;
  const res = await API.get(uri, data);
  return res.data;
};

/**
 * @param {string} projectId
 * @param {string} channelId
 * @param {{social_network_id: string}} data
 * @returns
 */
export const likeComment = async (projectId, channelId, data) => {
  const uri = `/projects/${projectId}/channels/${channelId}/posts/detail/comment/like`;
  const res = await API.post(uri, data);
  return res.data;
};

/**
 * @param {string} projectId
 * @param {string} channelId
 * @param {{social_network_id: string}} data
 * @returns
 */
export const hideComment = async (projectId, channelId, data) => {
  const uri = `/projects/${projectId}/channels/${channelId}/posts/detail/comment/hide`;
  const res = await API.post(uri, data);
  return res.data;
};
export const hideContent = async (data) => {
  const uri = `/projects/social-post/hide`;
  const res = await API.post(uri, data);
  return res.data;
};
export const deleteContent = async (data) => {
  const uri = `/projects/social-post/remove`;
  const res = await API.post(uri, data);
  return res.data;
};

/**
 * @param {string} projectId
 * @param {string} channelId
 * @param {string} socialNetworkId
 * @returns
 */
export const deleteComment = async (projectId, channelId, socialNetworkId) => {
  const uri = `/projects/${projectId}/channels/${channelId}/posts/detail/comment/delete?social_network_id=${socialNetworkId}`;
  const res = await API.delete(uri);
  return res.data;
};

/**
 * @param {string} projectId
 * @param {string} channelId
 * @param {{social_network_id: string, content: string}} data
 * @returns
 */
export const createReply = async (projectId, channelId, data) => {
  const uri = `/projects/${projectId}/channels/${channelId}/posts/detail/comment/reply`;
  const res = await API.post(uri, data);
  return res.data;
};

/**
 * @param {string} projectId
 * @param {string} channelId
 * @returns
 */
export const deleteChannel = async (projectId, channelId) => {
  const uri = `/projects/${projectId}/channels/${channelId}`;
  const res = await API.delete(uri);
  return res.data;
};

/**
 * @param {string} projectId
 * @param {string} channelId
 * @returns
 */
export const deleteMultiChannel = async (projectId, data) => {
  const uri = `/projects/${projectId}/channels/delete-many`;
  const res = await API.delete(uri, {
    data: data,
  });
  return res.data;
};

/**
 * @param {string} projectId
 * @param {string} channelId
 * @returns
 */
export const activeChannel = async (projectId, params) => {
  const uri = `/projects/${projectId}/channels/active-channel`;
  const res = await API.post(uri, params);
  return res.data;
};
export const getErrorChannel = async (projectId) => {
  const uri = `/projects/${projectId}/channels/statistic-error`;
  const res = await API.get(uri);
  return res.data;
};

/**
 * @param {string} projectId
 * @param {object} params
 * @returns
 */
export const updatePriority = async (projectId, params) => {
  const uri = `/projects/${projectId}/channels/update-priority`;
  const res = await API.put(uri, params);
  return res.data;
};

export const postingComment = async (projectId, data) => {
  const uri = `/projects/${projectId}/channels/comment-seeding/group-seeding`;
  const res = await API.post(uri, data);
  return res.data;
};

export const getCommentSeedingPost = async (projectId, data) => {
  const uri = `/projects/${projectId}/channels/comment-seeding/group-seeding`;
  const res = await API.get(uri, data);
  return res.data;
};

export const deleteCommentSeeding = async ({ projectId, data }) => {
  const uri = `/projects/${projectId}/channels/comment-seeding/group-seeding`;
  const res = await API.delete(uri, {
    data: data,
  });
  return res.data;
};

export const getFrequencyStatisticByChannel = async (data) => {
  const uri = `/projects/dashboard/facebook/frequency-post-content-by-channel`;
  const res = await API.get(uri, data);
  return res.data;
};

export const getFrequencyStatisticByUser = async (data) => {
  const uri = `/projects/dashboard/facebook/frequency-post-content-by-user`;
  const res = await API.get(uri, data);
  return res.data;
};

// Delete Account in channel group facebook

export const deleteVia = async (projectId, channelId, data) => {
  const uri = `/projects/${projectId}/channels/${channelId}/remove-via`;
  const res = await API.delete(uri, {
    data: data,
  });
  return res.data;
};

export const changePost = async (projectId, channelId, data) => {
  const uri = `projects/${projectId}/channels/${channelId}/change-via`;
  const res = await API.put(uri, data);
  return res.data;
};

/**
 * Group channel
 *
 */
export const getListGroupChannels = async (projectId, data) => {
  const uri = `projects/${projectId}/group-channels/list`;
  const res = await API.get(uri, data);
  return res.data;
};
export const addGroupChannels = async (projectId, data) => {
  const uri = `projects/${projectId}/group-channels`;
  const res = await API.post(uri, data);
  return res.data;
};
export const updateGroupChannels = async (projectId, channelId, data) => {
  const uri = `projects/${projectId}/group-channels/${channelId}`;
  const res = await API.put(uri, data);
  return res.data;
};
export const deleteGroupChannels = async (projectId, channelId) => {
  const uri = `projects/${projectId}/group-channels/${channelId}`;
  const res = await API.delete(uri);
  return res.data;
};

export const getUnsettingChannels = async (projectId, data) => {
  const uri = `/projects/${projectId}/channels/unsetting-smart-schedule`;
  const res = await API.get(uri, data);
  return res.data;
};

//list channel smart-schedule
export const getChannelsSmartSchedule = async (projectId, data) => {
  const uri = `/projects/${projectId}/channels/smart-schedule/advance-setting`;
  const res = await API.get(uri, data);
  return res.data;
};

export const importExcelToGroupChannel = async (projectId, data) => {
  const uri = `/projects/${projectId}/group-channels/import`;
  const res = await API.post(uri, data);
  return res.data;
};
