import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  PreviewAltCard,
  Row
} from "../../../components/Component";
// import TrafficDougnut from "../../../components/partials/analytics/traffic-dougnut/TrafficDoughnut";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import styleConnect from "../../components/ConnectSocialNetwork/index.module.scss";
import ModalSelectChannelPlatform from "../../components/ConnectSocialNetwork/ModalSelectChannelPlatform";
import { IconConnectChannel } from "../../components/svgIcon";
import Audience from "./Audience";
import ChannelReport from "./ChannelReport";
import ContentSpread from "./ContentSpread";
import ReachByHour from "./ReachByHour";
import TopContent from "./TopContent";
import TrendingPost from "./TrendingPost";
const Overview = () => {
  const { t } = useTranslation();
  const ranges = [7, 14, 30, 60, 90];
  const [range, setRange] = useState(14);
  const [sm, updateSm] = useState(false);
  // check connect channel
  const dataListChannel = useSelector((state) => state.channel.channelsInformation);
  const [isOpenModalSelectPlatform, setIsOpenModalSelectPlatform] = useState(false);
  const userInformation = JSON.parse(localStorage.getItem("userInfor"));
  const toggleIsOpenModalSelectPlatform = () => {
    setIsOpenModalSelectPlatform((v) => !v);
  };
  
  // end check connect channel
  const onClick = (platform) => {
    let url = process.env.PUBLIC_URL + "/connect-page/" + platform;
    window.history.pushState("", "", url);
    window.location.reload();
    // pingServer().then(res => {
    //   if(res.success){
    //     let url = process.env.PUBLIC_URL+"/connect-page/" + platform;
    //     window.location.replace(url);
    //   }
    // })
  };
  return (
    <React.Fragment>
        <Head title="Analytics Dashboard" />
            <Content>
              {
                userInformation.last_project_active !== "" && dataListChannel && dataListChannel.length > 0 ?
                  <></> :
                  <div className={`${styleConnect.EmptyImage} ${styleConnect.ImageOpacity}`}>
                    <div>
                      <div className="text-center">
                        <img src={"/images/empty-connection.png"} width={287} alt="" />
                      </div>
                      <div>
                        <p className={`${styleConnect.ImageTitle}`}>Kết nối với tài khoản mạng xã hội để bắt đầu!</p>
                      </div>
                      <div className={`${styleConnect.ConnectArea}`}>
                        <div className={`${styleConnect.ButtonConnect}`} onClick={() => onClick("connect_new")}>
                          <div>
                            <IconConnectChannel />
                          </div>
                          <div>{t("project_connect_channel")}</div>
                        </div>
                      </div>
                    </div>
                  </div>
              }
              <div style={userInformation.last_project_active !== "" && dataListChannel && dataListChannel.length > 0 ? { opacity: "1" } : { opacity: "0.5" }}>
                <BlockHead size="sm">
                  <div className="nk-block-between">
                    <BlockHeadContent>
                      <BlockTitle page tag="h3">
                        {t("overview.title")}
                      </BlockTitle>
                      <BlockDes className="text-soft">
                        <p>{t("overview.subtitle")}</p>
                      </BlockDes>
                    </BlockHeadContent>
                    <BlockHeadContent>
                      <div className="toggle-wrap nk-block-tools-toggle">
                        <Button
                          className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                          onClick={() => updateSm(!sm)}
                        >
                          <Icon name="more-v"></Icon>
                        </Button>
                        <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                          <ul className="nk-block-tools g-3">
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="dropdown-toggle btn btn-white btn-dim btn-outline-light">
                                  <Icon className="d-none d-sm-inline" name="calender-date"></Icon>
                                  <span>
                                    <span className="d-none d-md-inline">{t("overview.range_text", { days: range })}</span>
                                  </span>
                                  <Icon className="dd-indc" name="chevron-right"></Icon>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <ul className="link-list-opt no-bdr">
                                    {ranges.map((r) => (
                                      <li key={r}>
                                        <DropdownItem
                                          href="#dropdownitem"
                                          onClick={() => {
                                            setRange(r);
                                          }}
                                        >
                                          {t("overview.range_text", { days: r })}
                                        </DropdownItem>
                                      </li>
                                    ))}
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                            <li className="nk-block-tools-opt">
                              <Button color="primary">
                                <Icon name="reports"></Icon>
                                <span>Reports</span>
                              </Button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </BlockHeadContent>
                  </div>
                </BlockHead>
                <Block>
                  <Row className="g-gs">
                    <Col lg="8">
                      <PreviewAltCard className="h-100">
                        <ContentSpread range={range} />
                      </PreviewAltCard>
                    </Col>
                    <Col lg="4">
                      <PreviewAltCard className="h-100">
                        <Audience range={range} />
                      </PreviewAltCard>
                    </Col>
                    <Col lg="6">
                      <PreviewAltCard className="h-100">
                        <ReachByHour range={range} />
                      </PreviewAltCard>
                    </Col>
                    <Col lg="6">
                      <PreviewAltCard className="h-100">
                        <TrendingPost range={range} />
                      </PreviewAltCard>
                    </Col>
                    {/* <Col lg="12">
                <Card className="card-bordered h-100">
                  <ChannelReport range={range} />
                </Card>
              </Col> */}
                    <Col lg="4">
                      <PreviewAltCard className="h-100">
                        <TopContent contentType="text" range={range} />
                      </PreviewAltCard>
                    </Col>
                    <Col lg="4">
                      <PreviewAltCard className="h-100">
                        <TopContent contentType="image" range={range} />
                      </PreviewAltCard>
                    </Col>
                    <Col lg="4">
                      <PreviewAltCard className="h-100">
                        <TopContent contentType="video" range={range} />
                      </PreviewAltCard>
                    </Col>
                  </Row>
                </Block>
              </div>
            </Content>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              pauseOnHover
            />
            <ModalSelectChannelPlatform
              isOpen={isOpenModalSelectPlatform}
              toggle={toggleIsOpenModalSelectPlatform}
              toast={toast}
            />
    </React.Fragment>
  );
};

export default Overview;
