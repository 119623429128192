import { API } from "../libs/client";

const PROJECT = "/projects";

export const URI = {
  LIST_PROJECT: "/profile/projects",
  CREATE_PROJECT: "/projects/create",
  EDIT_PROJECT: "/projects/{id}/edit",
  LIST_SUBCRIPTION: "/projects/subscription/list",
  DETAIL_SUBCRIPTION: "/projects/subscription/{type}",
  SUBCRIPTION_USED: "/projects/subscription/{id}/use",
  CREATE_SUBCRIPTION: "/projects/subscription/bill",
  LIST_BILL: "/projects/subscription/bill/list",
  LIST_CHANNEL_BY_PROJECT: "/projects/{id}/channels/list",
  LIST_CHANNEL_BY_PROJECT_PERMISSION: "/projects/{id}/channels/list-page-permission",
  LIST_POST_BY_PAGE: "/projects/{id}/channels/get-list-post-permission",
  LIST_NOTIFICATION_BY_PROJECT: "/projects/{id}/notifications",
  LOGIN_FACEBOOK: "/facebook-connector/login",
  LOGIN_FACEBOOK_GROUP: "/facebook-connector/login-group",
  LOGIN_ZALO: "/zalo/oauth",
  LOGIN_PINTEREST: "/pinterest/auth",
  LOGIN_TIKTOK: "/tiktok-connector/login",
  LIST_NOTIFICATION: "/projects/{id}/notifications",
  LIST_AD_ACCOUNT: "/external/ad-accounts/{id}",
  LIST_CAMPAIGN: "/external/campaigns/{id}",
  CHECK_AVAILABLE_GROUP_TOKEN: "/external/check-has-token-group",
  GET_GROUPS: "/external/facebook-group/list",
  POST_TO_GROUP: "/external/post-to-group",
  LIST_CHANNEL_BY_FACEBOOK: "/projects/{id}/facebook/list-page",
  LIST_SEEDING: "/projects/seeding/users/?project_id={id}",
  LIST_PRICE_SEEDING: "/projects/seeding/pricing",
  POST_TO_SEEDING: "/projects/seeding/bill",
  LIST_SUBCRIPTION_V1: "/projects/subscription/list?version=1",
  LIST_SUBCRIPTION_V2: "/projects/subscription/list?version=2",
  LIST_SUBCRIPTION_V3: "/projects/subscription/list?version=3",
};

export const checkHasGroupToken = async (projectId) => {
  const uri = URI.CHECK_AVAILABLE_GROUP_TOKEN + "?project_id=" + projectId;
  const res = await API.get(uri);
  return res.data.data;
};

export const postToGroup = async (data) => {
  const uri = URI.POST_TO_GROUP;
  const res = await API.post(uri, data);
  return res.data.data;
};

export const getProject = async (projectId) => {
  const uri = PROJECT + `/${projectId}/show`;
  const res = await API.get(uri);
  return res.data.data;
};

export const getAllProject = async (data) => {
  const uri = URI.LIST_PROJECT;
  const res = await API.get(uri, data);
  return res.data.data;
};

export const getGroups = async (data) => {
  const uri = URI.GET_GROUPS;
  const res = await API.post(uri, data);
  return res.data.data;
};

export const createProject = async (data) => {
  const uri = URI.CREATE_PROJECT;
  const res = await API.post(uri, data);
  return res.data;
};

export const editProject = async (id, data) => {
  const uri = URI.EDIT_PROJECT.replace("{id}", id);
  const res = await API.post(uri, data);
  return res.data;
};

export const loadListSubscription = async (data) => {
  const uri = URI.LIST_SUBCRIPTION;
  const res = await API.get(uri);
  return res.data.data;
};

export const loadDetailSubscription = async (type,page, project_id, month) => {
  const uri = URI.DETAIL_SUBCRIPTION.replace("{type}", type);
  const res = await API.get(uri, {
    params: {
      version:4,
      page,
      project_id,
      month
    },
  });
  return res.data.data;
};

export const doSubscription = async (data) => {
  const uri = URI.CREATE_SUBCRIPTION;
  const res = await API.post(uri, data);
  return res.data.data;
};

export const loadListBill = async (projectId) => {
  const uri = URI.LIST_BILL;
  const res = await API.get(uri, {
    params: {
      projectId: projectId,
    },
  });
  return res.data.data;
};

export const loadSubscriptionUsed = async (projectId) => {
  const uri = URI.SUBCRIPTION_USED.replace("{id}", projectId);
  const res = await API.get(uri);
  return res.data;
};

export const loadListChannel = async (projectId, params = {}) => {
  const uri = URI.LIST_CHANNEL_BY_PROJECT.replace("{id}", projectId);
  const res = await API.get(uri, { params });
  return res.data;
};

export const loadListChannelPermission = async (projectId) => {
  const uri = URI.LIST_CHANNEL_BY_PROJECT_PERMISSION.replace("{id}", projectId);
  const res = await API.get(uri);
  return res.data;
};
export const loadPostByPage = async (projectId, params) => {
  const uri = URI.LIST_POST_BY_PAGE.replace("{id}", projectId);
  const res = await API.post(uri, params);
  return res.data;
};

// export const loadListNotification = async (projectId, page) => {
//   const uri = URI.LIST_NOTIFICATION_BY_PROJECT.replace("{id}", projectId);
//   const res = await API.get(uri, {
//     params: {
//       page: page,
//     },
//   });
//   return res.data;
// };
export const loadListNotification = async (projectId, page) => {
  const uri = `/projects/${projectId}/notifications/publish`;
  const res = await API.get(uri, {
    params: {
      page: page,
    },
  });
  return res.data;
};

export const loadNotifyTeam = async (projectId, page) => {
  const uri = `/projects/${projectId}/user-notifications/team`;
  const res = await API.get(uri, {
    params: {
      page: page,
    },
  });
  return res.data;
};

export const loadListAdAccounts = async (projectId) => {
  const uri = URI.LIST_AD_ACCOUNT.replace("{id}", projectId);
  const res = await API.get(uri);
  return res.data;
};

export const loadListCampaigns = async (projectId, adAccountId) => {
  const uri = URI.LIST_CAMPAIGN.replace("{id}", projectId);
  const res = await API.get(uri, {
    params: {
      ad_account_id: adAccountId,
    },
  });
  return res.data;
};

export const loadListNotificationByProject = async (projectId) => {
  const uri = URI.LIST_NOTIFICATION.replace("{id}", projectId);
  const res = await API.get(uri);
  return res.data;
};

export const loadListPageByFacebook = async (projectId, params) => {
  const uri = URI.LIST_CHANNEL_BY_FACEBOOK.replace("{id}", projectId);
  const res = await API.get(uri, { params });
  return res.data;
};

// export const loadListSeeding = async (data) => {
//   const uri = URI.LIST_SEEDING;
//   const res = await API.get(uri);
//   return res.data.data;
// };

export const loadListSeeding = async (projectId) => {
  const uri = URI.LIST_SEEDING.replace("{id}", projectId);
  const res = await API.get(uri);
  return res.data.data;
};

export const loadListPriceSeeding = async () => {
  const uri = URI.LIST_PRICE_SEEDING;
  const res = await API.get(uri);
  return res.data.data;
};

export const postToSeeding = async (data) => {
  const uri = URI.POST_TO_SEEDING;
  const res = await API.post(uri, data);
  return res.data.data;
};

export const loadListSubscriptionV1 = async (data) => {
  const uri = URI.LIST_SUBCRIPTION_V1;
  const res = await API.get(uri);
  return res.data.data;
};

export const loadListSubscriptionV2 = async (data) => {
  const uri = URI.LIST_SUBCRIPTION_V2;
  const res = await API.get(uri);
  return res.data.data;
};

export const loadListSubscriptionV3 = async (data) => {
  const uri = URI.LIST_SUBCRIPTION_V3;
  const res = await API.get(uri);
  return res.data.data;
};
export const getTimeZone = async () => {
  const uri = "/time-zone";
  const res = await API.get(uri);
  return res.data.data;
};