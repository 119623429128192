import React, { useCallback, useEffect, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle, Spinner, Card } from "reactstrap";
import { Row, Col, RSelect, Button, Img, Icon } from "../../../components/Component";
import { components } from 'react-select';
import style from "./index.module.scss";
import { useDispatch } from "react-redux";
import { GetPinterestBoard, createPinterestBoard } from "../../../api/createPost"
import {
  setPinterestOption
} from "../../reducers/createPostSlice";
import pinterestAvatar from "../../images/icons/pinterest-avatar.svg";
import addBoard from "../../images/icons/add-board.svg";
import searchBoard from "../../images/icons/search-board.svg";
import { PLATFORM_PINTEREST, PLATFORM_YOUTUBE } from "../../utils/Utils";
import {isValidUrl} from "../../utils/Utils"
import { toastError, toastSuccess } from "../../../libs/toasts";
const PinterestOption = ({ channels, pinterestOption, valid , setValid}) => {
  const [pinterestChannel, setPinterestChannel] = useState([]);
  const dispatch = useDispatch();
  const [openDropdown, setOpenDropdown] = useState([]);
  const [boardOption, setBoardOption] = useState([]);
  const [boardForm, setBoardForm] = useState({ search: "", create: "" });
  const [boardLoading, setBoardLoading] = useState({ search: false, create: false });


  const userInfo = JSON.parse(localStorage.getItem("userInfor"));


  const [openOptionPinterest, setOpenOptionPinterest] = useState(true);
  const handleOpenOption = () => {
    setOpenOptionPinterest(!openOptionPinterest)
    const Elmchevron = document.getElementById(`chevron_optionPinterest`);
    const Elmcontent = document.getElementById(`content_optionPinterest`);
    if (!openOptionPinterest) {
      Elmchevron.classList.add(`${style.openChevronDown}`)
      Elmchevron.classList.remove(`${style.openChevronUp}`)
      Elmcontent.classList.add(`${style.openContentDown}`)
      Elmcontent.classList.remove(`${style.openContentUp}`)
    }
    else {
      Elmchevron.classList.add(`${style.openChevronUp}`)
      Elmchevron.classList.remove(`${style.openChevronDown}`)
      Elmcontent.classList.add(`${style.openContentUp}`)
      Elmcontent.classList.remove(`${style.openContentDown}`)
    }
  }

  const handleOpenDropDown = (e, index) => {
    e.stopPropagation();
    let open = [...openDropdown];
    if (open[index]) {
      open[index] = false;
    } else {
      open[index] = true;
    }
    setOpenDropdown(open);
    setBoardForm({ search: "", create: "" });
  };

  useEffect(() => {
    setPinterestChannel(channels?.filter(c => c.platform === PLATFORM_PINTEREST));
  }, [channels])




  const fetchListBoard = async ({ channelId, projectId, bookMark, count }) => {
    if (count === 5) {
      return [];
    }

    let data = {
      params: {
        project_id: projectId,
      }
    }

    if (bookMark) {
      data.params = { ...data.params, bookmark: bookMark }
    }

    try {
      const res = await GetPinterestBoard(channelId, data);
      if (res.status) {
        const newRes = [...res.data.items];
        let finalRes = [];
        if (res.data.bookmark) {
          finalRes = await fetchListBoard({ channelId, projectId, bookMark: res.data.bookmark, count: count + 1 });
        }
        return [...newRes, ...finalRes];
      } else {
        console.log("get board fail");
        return [];
      }
    } catch (error) {
      console.log("get board fail", error);
      return [];
    }
  };
  useEffect(() => {
    let newPinterestOption = pinterestOption.board;
    const newArray = newPinterestOption.filter(option => {
      return pinterestChannel.some(channel => channel._id === option.userId);
    });

    dispatch(setPinterestOption({ ...pinterestOption, board: newArray }));
    const fetchBoardData = async () => {
      if (!pinterestChannel) {
        return;
      };

      const fulfilledResults = await Promise.allSettled(pinterestChannel.map(async (channel) => {
        const channelId = channel?._id ?? null;
        const projectId = channel?.project_id ?? null;
        const data = await fetchListBoard({ channelId, projectId, bookmark: null, count: 1 });
        return data;
      }));

      const resolvedResults = fulfilledResults
        .filter(result => result.status === 'fulfilled')
        .map(result => result.value);

      setBoardOption(resolvedResults);
      // Tiếp tục xử lý kết quả nếu cần thiết
    };
    fetchBoardData();
  }, [pinterestChannel]);

  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onCreateBoard(pinterestOption.board[index].userId, index);
    }
  };
  const handleEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const onCreateBoard = (channelId, index) => {
    const data = {
      name: boardForm.create !== "" ? boardForm.create : "",
    }

    if (data.name.trim() === "") {

      return;
    }

    setBoardLoading({ ...boardLoading, search: true });



    createPinterestBoard({ project_id: userInfo?.last_project_active, channelId: channelId, data: data })
      .then((response) => {
        if (response.status) {
          let newBoardOption = boardOption;
          boardOption[index].push(response.data);
          setBoardOption([...newBoardOption]);
          toastSuccess("Bạn đã thêm board thành công!");
          setBoardForm({ ...boardForm, create: "" });
          setBoardLoading({ ...boardLoading, search: false });
        }
      }).catch((error) => {
        toastError(error.response.data.message);
        setBoardLoading({ ...boardLoading, search: false });
      });
  }

  const handleCreateBoard = (e, index) => {
    e.stopPropagation();
    e.preventDefault();
    onCreateBoard(pinterestOption.board[index].userId, index);
  }

  const handleSelectOption = (e, index, item) => {
    e.stopPropagation();
    const optionIndex = boardOption[index].findIndex(element => element.id === item.id);

    let newPinterestBoard = pinterestOption.board.length > 0 ? [...pinterestOption.board] : [];
    newPinterestBoard[index] = { ...boardOption[index][optionIndex], userId: pinterestChannel[index]._id };
    dispatch(setPinterestOption({ ...pinterestOption, board: newPinterestBoard }));
    handleOpenDropDown(e, index);
  }

  const validLinkText = () => {
    
    if (pinterestOption?.link === null || pinterestOption?.link?.trim().length === 0) {
      return "Bắt buộc điền";
    }

    if (!isValidUrl(pinterestOption?.link)) {
      return "Sai định dạng Link";
    }
  }



  return (
    <Card className={`card-bordered mb-2 ${((valid.link && (pinterestOption?.link === null || pinterestOption?.link?.trim().length === 0)) ||
      (valid.board && pinterestOption?.board?.length < pinterestChannel?.length)) ? "border-danger" : ""} `}>
      <div className="d-flex align-items-stretch border-bottom">
        <div
          className={`p-2 fw-medium ${((valid.link && (pinterestOption?.link === null || pinterestOption?.link?.trim().length === 0)) ||
            (valid.board && pinterestOption?.board?.length < pinterestChannel?.length)) ? "text-danger" : "text-dark"}`}
          style={{ color: "#333333", fontFamily: "Roboto" }}
          onClick={handleOpenOption}
        >
          Pinterest
        </div>
      </div>
      <div id={`content_optionPinterest`} style={{
        transition: "max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s",
        willChange: "max-height",
        position: "relative",
        overflow: "auto"
      }}>
        <div className={style.OptionYoutube}>
          <Row className={`pb-2 mb-2`}>
            <Col md="12">
              <label className={style.OptionYoutube__title}>Link Pinterest </label>
              {valid.link && (pinterestOption?.link === null || pinterestOption?.link?.trim().length === 0 || !isValidUrl(pinterestOption?.link))  && <span className="text-danger ms-1 fw-medium">({validLinkText()})</span>}
              <input
                className={`form-control ${valid.link && (pinterestOption?.link === null || pinterestOption?.link?.trim().length === 0) ? "border border-danger" : ""}`}
                onChange={(e) => {
                  let newPinterestOption = { ...pinterestOption, link: e.target.value }
                  if (valid.link && !isValidUrl(e.target.value)) {

                  }
                  dispatch(setPinterestOption(newPinterestOption));
                }}
                placeholder="Thêm Link đích của Pin" />
            </Col>
          </Row>
          <Row className={`pb-2 mb-2`}>
            <Col md="7">
              <label className={style.OptionYoutube__title}>Chọn Board </label>
              {(valid.board && pinterestOption?.board?.length < pinterestChannel?.length) && <span className="text-danger ms-1 fw-medium">(Bắt buộc điền)</span>}
              {boardLoading?.create ? <p className={style.pinterestLoading}>Loading...</p>
                :
                pinterestChannel?.map((channel, index) => (
                  <div key={channel?._id} className="d-flex mb-2" style={{ gap: "12px" }}>
                    <div style={{ position: "relative", cursor: "pointer" }} data-toggle="tooltip" data-placement="top" title={channel?.name}>
                      <Img src={channel?.picture} size="circle" height="32px" width="32px" />
                      <Img src={pinterestAvatar} height="12px" width="12px"
                        style={{ position: "absolute", bottom: "0", left: "0" }} />
                    </div>
                    <div style={{ flexGrow: "1" }}>
                      <Dropdown className="w-100 h-100" isOpen={openDropdown[index]} toggle={(e) => handleOpenDropDown(e, index)}>
                        <DropdownToggle
                          className={`dropdown-toggle ${(valid.board && !pinterestOption.board[index]) ? "border-danger" : ""} btn btn-white btn-dim btn-outline-light ${style.dropDownSelect} d-flex align-items-center justify-content-between`}
                          style={{
                            background: "#fff",
                            color: "#9CA3AF",
                            border: "1px solid",
                            borderColor: "#D9D9D9",
                            width: "100%"
                          }}
                        >
                          {pinterestOption?.board[index]?.name ?
                            <div className={style.selectToggle}>{pinterestOption?.board[index]?.name}</div>
                            :
                            <div className={style.defaultToggle}>Chọn Board</div>
                          }
                          <Icon
                            className="d-sm-inline"
                            style={{ color: "#AEBFE7", position: "absolute", right: "5px" }}
                            name="chevron-down"
                          >
                          </Icon>
                        </DropdownToggle>
                        <DropdownMenu positionFixed={true} className="px-2 my-2 py-2 rounded">
                          <div className="h-100 d-flex flex-column" style={{ gap: "8px" }}>
                            <div className={style.boardItems}>
                              <div
                                className={style.boardBttn}
                              >
                                <Img
                                  style={boardForm.search !== "" && { filter: "invert(51%) sepia(100%) saturate(7480%) hue-rotate(222deg) brightness(100%) contrast(107%)" }}
                                  src={searchBoard} height="18px" width="18px" />
                              </div>
                              <input
                                type="text"
                                value={boardForm.search}
                                onKeyDown={e => handleEnter(e)}
                                onChange={e => setBoardForm({ ...boardForm, search: e.target.value })}
                                className={style.boardInput}
                                placeholder="Tìm kiếm board"
                              />
                            </div>

                            <div className={`${style.boardItems}`}>
                              <div className={style.boardBttn} onClick={e => handleCreateBoard(e, index)}>
                                <Img
                                  style={boardForm.create !== "" && { filter: "invert(51%) sepia(100%) saturate(7480%) hue-rotate(222deg) brightness(100%) contrast(107%)" }}
                                  src={addBoard} height="18px" width="18px" />
                              </div>
                              <input
                                type="text"
                                value={boardForm.create}
                                onKeyDown={e => handleKeyDown(e, index)}
                                onChange={e => setBoardForm({ ...boardForm, create: e.target.value })}
                                className={style.boardInput}
                                placeholder="Tạo mới board"
                              />
                            </div>
                          </div>

                          {boardLoading.search ? <div className="w-100 mt-4 mb-4 d-flex justify-content-center align-items-center"><Spinner></Spinner></div>
                            : (
                              <div className={`mt-3 d-flex flex-column ${style.bgDropDown}`}>
                                {boardOption[index]?.filter(board => board?.name?.includes(boardForm.search))?.map((item) => (
                                  <div
                                    className="d-flex align-items-center"
                                    key={item?.id}
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => handleSelectOption(e, index, item)}
                                  >
                                    <div className={`mb-2 ${style.boardlistItems}`}>
                                      {item?.name}
                                    </div>
                                  </div>
                                ))}
                              </div>)}
                        </DropdownMenu>
                      </Dropdown>
                    </div>

                  </div>
                ))}


            </Col>
          </Row>
        </div>
      </div>
      <div id={`chevron_optionPinterest`} className={`card-tools position-absolute p-2 top-0 right-0 ${style.openMedia}`} onClick={handleOpenOption}>
        <svg width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" /> </svg>
      </div>
    </Card>
  );
};
export default PinterestOption;
