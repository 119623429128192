import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

//import Images
import { LoadingItem } from "../../CommonItem/LoadingItem";

const ImpressionSource = ({ data, isLoading }) => {
  const { t } = useTranslation();
  const arrColor = ["primary", "info", "success", "warning", "danger"];

  data = data?.map((d, index) => ({
    ...d,
    color: arrColor[index] || "bg-primary",
  }));
  return (
    <React.Fragment>
      <Col xl={3} md={6} className="mt-2">
        <Card className="card-height-100">
          <CardHeader className="align-items-center bg-slate-50 d-flex">
            <h4
              className="card-title mb-0 flex-grow-1"
              style={{ fontSize: "16px" }}
            >
              {t("impression_source")}
            </h4>
          </CardHeader>

          <CardBody>
            {isLoading ? (
              <LoadingItem />
            ) : (
              <div>
                <div>
                  <div className="progress progress-lg rounded-pill">
                    {data?.map((d) => (
                      <div
                        className={`progress-bar bg-${d?.color}`}
                        role="progressbar"
                        style={{ width: `${d.average_percentage}%` }}
                      ></div>
                    ))}
                  </div>
                </div>
                <div className="mt-3 pt-2">
                  {data?.map((imp) => (
                    <div className="d-flex mb-2">
                      <div className="flex-grow-1">
                        <p className="text-truncate text-muted fs-14 mb-0">
                          <i
                            className={`mdi mdi-circle align-middle text-${imp?.color} me-2`}
                          ></i>
                          {imp.source}
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <p className="mb-0">{imp.average_percentage} %</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default ImpressionSource;
