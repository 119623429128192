import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { Container, Col } from "reactstrap";

import { Facebook } from "./Facebook";
import Instagram from "./Instagram";
import TikTok from "./TikTok";
import Youtube from "./Youtube";
import HeaderDashboard from "../CommonItem/HeaderDashboard";
import { PLATFORM_NAME } from "../constants";
import { useLocation } from "react-router-dom";
import "remixicon/fonts/remixicon.css";
// import "bootstrap/dist/css/bootstrap.css";

const DashboardChannel = () => {
  document.title = "Dashboard | Velzon - React Admin & Dashboard Template";

  // const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const channelValue = params.get("channel_ids")?.split(",") || [];
  const fromValue = params.get("from");
  const toValue = params.get("to");

  const [selectedPlatForm, setSelectedPlatForm] = useState(
    PLATFORM_NAME.FACEBOOK
  );
  const [channels, setChannels] = useState(channelValue);
  const [loadingChannelFilter, setLoadingChannelFilter] = useState(false);
  const [loadingRange, setLoadingRange] = useState(false);
  const [from, setFrom] = useState(
    fromValue ?? moment().subtract(14, "days").format("YYYY-MM-DD")
  );
  const [to, setTo] = useState(toValue ?? moment().format("YYYY-MM-DD"));

  useEffect(() => {
    const getActiveTab = localStorage?.getItem("activeTabDashboard");
    if (getActiveTab) {
      setSelectedPlatForm(
        JSON.parse(getActiveTab)?.toUpperCase() || PLATFORM_NAME.FACEBOOK
      );
    } else {
      setSelectedPlatForm(PLATFORM_NAME.FACEBOOK);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "activeTabDashboard",
      JSON.stringify(selectedPlatForm)
    );
  }, [selectedPlatForm]);

  return (
    <React.Fragment>
      <div className="page-content" style={{ marginTop: "80px" }}>
        {/* <Container fluid> */}
        <Col lg={12}>
          <HeaderDashboard
            key="dahsboard"
            selectedPlatform={selectedPlatForm}
            setSelectedPlatForm={setSelectedPlatForm}
            channel={channels}
            setChannels={setChannels}
            loadingChannelFilter={loadingChannelFilter}
            setLoadingChannelFilter={setLoadingChannelFilter}
            loadingRange={loadingRange}
            setLoadingRange={setLoadingRange}
            setFrom={setFrom}
            setTo={setTo}
            from={from}
            to={to}
          />
        </Col>

        {selectedPlatForm === PLATFORM_NAME.FACEBOOK && (
          <Facebook
            channels={channels}
            setLoadingChannelFilter={setLoadingChannelFilter}
            setLoadingRange={setLoadingRange}
            from={from}
            to={to}
          />
        )}

        {selectedPlatForm === PLATFORM_NAME.TIKTOK && (
          <TikTok
            channels={channels}
            setLoadingChannelFilter={setLoadingChannelFilter}
            setLoadingRange={setLoadingRange}
            from={from}
            to={to}
          />
        )}

        {selectedPlatForm === PLATFORM_NAME.YOUTUBE && (
          <Youtube
            channels={channels}
            setLoadingChannelFilter={setLoadingChannelFilter}
            setLoadingRange={setLoadingRange}
            from={from}
            to={to}
          />
        )}

        {selectedPlatForm === PLATFORM_NAME.INSTAGRAM && (
          <Instagram
            channels={channels}
            setLoadingChannelFilter={setLoadingChannelFilter}
            setLoadingRange={setLoadingRange}
            from={from}
            to={to}
          />
        )}

        {selectedPlatForm === PLATFORM_NAME.THREADS && (
          <></>
        )}
        {/* </Container> */}
      </div>
    </React.Fragment>
  );
};

export default DashboardChannel;
