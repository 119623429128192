import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getReachDemographicFacebook,
  getReachIndexFacebook,
  getReachLocationFacebook,
  getVideoPerformanceTopVideoYT,
  getVideoPerformanceYT,
} from "../../../../api/dashboard";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../../../components/Component";
import style from "../index.module.scss";
import Access from "./Accessibility/Facebook/Access";
import Demographic from "./Accessibility/Facebook/Demographic";
import Region from "./Accessibility/Facebook/Region";
import YTTopVideo from "./Accessibility/Youtube/YTTopVideo";
import YTViewAnalytic from "./Accessibility/Youtube/YTViewAnalytic";
import _ from "lodash";

const Accessibility = (props) => {
  const { range, tab, channel, setLoading, setLoadingChannel, subTab, dashboardList, setDashboardList, updateTabDashboard, dashboardInit, isChangeFilter, setChangeFilter } = props;
  const userInfor = JSON.parse(localStorage.getItem('userInfor'));
  const projectId = userInfor.last_project_active;
  const [reachIndexData, setReachIndexData] = useState({});
  const [locationData, setLocationData] = useState({});
  const [demongraphicData, setDemongraphicData] = useState({});

  const [postAndEngageDataYT, setVideoPerformanceDataYT] = useState({});
  const [topVideoDataYT, setTopVideoDataYT] = useState([]);

  const getAPIFacebook = (data) => {
    const promises = [
      getReachIndexFacebook(data),
      getReachLocationFacebook(data),
      getReachDemographicFacebook(data),
    ];

    // Use Promise.all to wait for all promises to resolve
    Promise.all(promises).then((responses) => {
      // Extract the data from the responses
      const [reachIndexResponse, locationResponse, demographicResponse] =
        responses;

      // Check and set the data for each response
      if (reachIndexResponse.status) {
        const reachIndexData = reachIndexResponse.data;
        setReachIndexData(reachIndexData);
      }

      if (locationResponse.status) {
        const locationData = locationResponse.data;
        setLocationData(locationData);
      }

      if (demographicResponse.status) {
        const demographicData = demographicResponse.data;
        setDemongraphicData(demographicData);
      }
      setLoadingChannel(false);
      setLoading(false);
    });
  };

  const getAPIYoutube = (data) => {
    const promises = [
      getVideoPerformanceYT(data),
      getVideoPerformanceTopVideoYT(data),
    ];
    Promise.all(promises).then((responses) => {
      // Extract the data from the responses
      const [postAndEngageResponse, topVideoResponse] = responses;
      // Check and set the data for each response
      if (postAndEngageResponse.status) {
        const _data = postAndEngageResponse.data;
        setVideoPerformanceDataYT(_data);
      }

      if (topVideoResponse.status) {
        const _data = topVideoResponse.data;
        setTopVideoDataYT(_data);
      }
      setLoadingChannel(false);
      setLoading(false);
    });
  };

  const handleGetDataStatistic = () => {
    const data = {
      params: {
        project_id: projectId,
        time: `${range} days`,
        channel_ids: channel,
      },
    };
    if (subTab === "3") {
      if (tab === "1") {
        // Create an array of promises
        getAPIFacebook(data);
      }

      if (tab === "3") {
        getAPIYoutube(data);
      }
    }
    setChangeFilter();
  }

  useEffect(() => {
    if (projectId && dashboardInit !== 0) {
      if (isChangeFilter) return;
      updateTabDashboard({
        platform: tab,
        subTab,
        channel,
        range
      });
      handleGetDataStatistic();
    }
  }, [channel, range]);

  useEffect(() => {
    if (projectId) {
      const subTabItem = dashboardList[tab].find(item => item.subTab === subTab);
      if (!subTabItem) { // nếu chưa có thì thêm vào redux và call api
        setDashboardList({
          platform: tab,
          subTab,
          channel,
          range
        });
        handleGetDataStatistic();
      } else if (!_.isEqual(subTabItem.channel, channel) || subTabItem.range !== range) {// nếu có item, check xem channel và range có thay đổi không, nếu thay đổi thì call api cập nhật channel và range
        updateTabDashboard({
          platform: tab,
          subTab,
          channel,
          range
        });
        handleGetDataStatistic();
      }
    }
  }, [tab, subTab]);

  return (
    <>
      {tab === "1" && (
        <>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className={`${style.tittle}`}>
                  Khả năng tiếp cận
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <Access data={reachIndexData} />
          </Block>

          {/*       
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle className={`${style.tittle}`}>
              Khu vực địa lý
            </BlockTitle>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <Region />
      </Block> */}

          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className={`${style.tittle}`}>
                  Nhân khẩu học
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <Demographic data={demongraphicData} />
          </Block>
        </>
      )}
      {tab === "3" && (
        <>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className={`${style.tittle}`}>
                  Lượt xem & Số phút xem
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <YTViewAnalytic data={postAndEngageDataYT} />
          </Block>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className={`${style.tittle}`}>
                  Top Video theo Lượt xem
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <YTTopVideo data={topVideoDataYT} />
          </Block>
        </>
      )}
    </>
  );
};

export default Accessibility;
