import { RiVideoUploadLine } from "@remixicon/react";

const EditVideoThumb = ({ onOpenModalCustomThumbnail }) => {
  return (
    <div
      className="border rounded flex-column cursor-pointer d-flex justify-content-center align-items-center"
      onClick={onOpenModalCustomThumbnail}
      style={{
        maxWidth: "90px",
        maxHeight: "90px",
        minHeight: "90px",
        minWidth: "90px",
      }}
    >
      <RiVideoUploadLine size={20} className="text-muted"></RiVideoUploadLine>
      <span className="text-muted" style={{ fontSize: "9px" }}>
        Custom Thumbnail
      </span>
    </div>
  );
};

export default EditVideoThumb;
