import React from "react";
import styles from "../PostDetail.module.scss";
import { TooltipComponent } from "../../../../components/Component";
import { useTranslation } from "react-i18next";

const ApprovalSatus = () => {
  const { t } = useTranslation();
  return (
    <div id="ApprovalSatus" className={`${styles.itemSideRight} ${styles.isComming}`}>
      <div className={styles.headerItem}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="16"
          height="16"
          fill="rgba(247,184,75,1)"
        >
          <path d="M9 7.53861L15 21.5386L18.6594 13H23V11H17.3406L15 16.4614L9 2.46143L5.3406 11H1V13H6.6594L9 7.53861Z"></path>
        </svg>
        <span>{t("post_detail.side_right.approval.title")}</span>
      </div>
      <TooltipComponent id="ApprovalSatus" text={t("message.feature_under_construction")} />
    </div>
  );
};

export default ApprovalSatus;
