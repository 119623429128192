import React, { useState } from "react";
import { Img } from "../../../components/Component";
import { CONTENT_LIMIT_SEE_MORE } from "../../utils/Utils";
import style from "./index.module.scss";
import "./_preview-facebook.scss";
import {
  getPlatformIconFromLabel
} from "../../utils/Utils";
import itemHeart from "../../../images/icons/item_heart.png"
import itemComment from "../../../images/icons/item_comment.png"
import itemShare from "../../../images/icons/item_share.png"

const PreviewTiktok = ({ ...props }) => {
  const { channel, content, mediaItems, className } = props;
  const [openPreviewTikTok, setOpenPreviewTikTok] = useState(true)
  const displayContent =
    content?.length <= CONTENT_LIMIT_SEE_MORE ? (
      content
    ) : (
      <>
        {content?.slice(0, CONTENT_LIMIT_SEE_MORE)}
        <b>See more...</b>
      </>
    );

  const handleOpenPreviewTikTok = () => {
    setOpenPreviewTikTok(!openPreviewTikTok)
    const Elmchevron = document.getElementById(`chevron_previewTikTok`);
    const Elmcontent = document.getElementById("content_previewTikTok");
    if (!openPreviewTikTok) {
      Elmchevron.classList.add(`${style.openChevronDown}`)
      Elmchevron.classList.remove(`${style.openChevronUp}`)
      Elmcontent.classList.add(`${style.openContentDown}`)
      Elmcontent.classList.remove(`${style.openContentUp}`)
    }
    else {
      Elmchevron.classList.add(`${style.openChevronUp}`)
      Elmchevron.classList.remove(`${style.openChevronDown}`)
      Elmcontent.classList.add(`${style.openContentUp}`)
      Elmcontent.classList.remove(`${style.openContentDown}`)
    }
  }

  return (
    <div className={`previewFacebook ${className || ""}`}>
      <div className="d-flex align-items-stretch border-bottom">
        <div
          className="flex-fill p-2 fw-medium header"
          style={{ color: "#333333", fontFamily: "Roboto" }}
        >
          <Img
            src={getPlatformIconFromLabel("tiktok")}
            size="circle"
            width="16px"
            style={{ marginRight: "4px" }}
          />
          Tiktok
        </div>
        <div id="chevron_previewTikTok" className={`card-tools p-2 ${style.openMedia}`} onClick={handleOpenPreviewTikTok}>
          <svg width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" /> </svg>
        </div>
      </div>
      <div id="content_previewTikTok" style={{
        transition: "max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s",
        willChange: "max-height",
        position: "relative",
        overflow: "hidden"
      }}>
        {
          mediaItems.length > 0 &&
          <div className="imageWrapper">
            <div className={style.previewInstagram__frame9_16}>
              <Img
                src={mediaItems[0]?.previewImages.url}
                alt="something"
                style={{ objectFit: "cover", margin: "auto" }}
              />
              <div className="d-flex p-1 justify-between w-full absolute bottom-0 ">
                <div className={`info ${style.previewTiktok__name}`}>
                  <div >@{channel.name}</div>
                  {content?.length > 0 && <div>{displayContent}</div>}
                </div>
                <div className={style.previewTiktok__avarta}>
                  <Img
                    className="avatar"
                    src={channel.picture}
                    alt="avatar"
                    size="circle"
                    width="24px"
                  />
                </div>
              </div>
              <div className={style.previewTiktok__item}>
                <div className={style.item__avarta}>
                  <Img
                    className="avatar"
                    src={channel.picture}
                    alt="avatar"
                    size="circle"
                    width="34px"
                  />
                </div>
                <div className={style.item__interact}>
                  <Img
                    src={itemHeart}
                    alt="like"
                    height="32px"
                    width="32px"
                  />
                  <div>351.2k</div>
                </div>
                <div className={style.item__interact}>
                  <Img
                    src={itemComment}
                    alt="comment"
                    height="32px"
                    width="32px"
                  />
                  <div>6562</div>
                </div>
                <div className={style.item__interact}>
                  <svg color="white" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-bookmark-fill" viewBox="0 0 16 16">
                    <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z" />
                  </svg>
                  <div>19.3k</div>
                </div>
                <div className={style.item__interact}>
                  <Img
                    src={itemShare}
                    alt="share"
                    height="32px"
                    width="32px"
                  />
                  <div>16.2k</div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default PreviewTiktok;
