import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { LoadingItem } from "../CommonItem/LoadingItem";
import getChartColorsArray from "../helper";
import { numberWithCommas } from "../../DashboardNew/utils/utils";
export const TopCity = ({ data, isLoading, height }) => {
  const { t } = useTranslation();

  const series = [
    {
      // data: data?.map((city) => numberWithCommas(city?.value)),
      data: data?.map((city) => city?.value),
      name: "Sessions",
    },
  ];

  const barchartCountriesColors = getChartColorsArray(
    '["#4b38b3", "#45CB85", "#ffbe0b", "#f06548"]'
    // '["--vz-primary", "--vz-info", "--vz-danger", "--vz-warning"]'
  );
  const options = {
    chart: {
      type: "bar",
      height: height ?? 341,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
        distributed: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    colors: barchartCountriesColors,
    dataLabels: {
      enabled: true,
      offsetX: 32,
      style: {
        fontSize: "12px",
        fontWeight: 400,
        colors: ["#adb5bd"],
      },
    },

    legend: {
      show: false,
    },
    grid: {
      show: false,
    },
    xaxis: {
      categories: data?.map((city) => city?.source),
    },
  };
  return (
    <>
      {/* <Col xxl={6} xl={6}> */}
      <Card className={height ? "" : "card-height-100"}>
        <CardHeader className="align-items-center d-flex bg-slate-50">
          <h4
            className="card-title mb-0 flex-grow-1"
            style={{ fontSize: "16px" }}
          >
            {t("top_city")}
          </h4>
        </CardHeader>

        <CardBody>
          {isLoading ? (
            <LoadingItem />
          ) : (
            <>
              {data && (
                <div>
                  <ReactApexChart
                    dir="ltr"
                    options={options}
                    series={series}
                    type="bar"
                    height={height ?? "400"}
                    className="apex-charts"
                  />
                </div>
              )}
            </>
          )}
        </CardBody>
      </Card>
      {/* </Col> */}
    </>
  );
};
