import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

import {
  getLogFetchFb,
  getOverviewCustomerFb,
  getOverviewPageEffectiveFb,
  getOverviewPageReachFb,
  getReachDemographicFacebook,
  getTimeGoldFacebookNew,
} from "../../../../../api/dashboard";
import { getUserInfoInLocalStorage } from "../../helper";

import AudienceStatistic from "./AudienceStatistic";
import FrequencyTable from "./FrequencyTable";
import ImpressionStatistic from "./ImpressionStatistic";
import { OverviewEffect } from "./OverviewEffect";
import { OverviewUser } from "./OverviewUser";
import ViaTable from "./ViaTable";
import { AgeChartFb } from "../../Chart/AgeChart";
import { FanOnlineChart } from "../../Chart/FansOnlineChart";
import { GenderStatistic } from "../../Chart/GenderStatistic";
import { TopCity } from "../../Chart/TopCity";
import { LoadingItem } from "../../CommonItem/LoadingItem";
import { LogHeader } from "../../CommonItem/LogHeader";
import { PLATFORM_NAME } from "../../constants";
import Content from "../../../../../layout/content/Content";
import { Block } from "../../../../../components/Component";

export const Facebook = ({
  channels,
  setLoadingChannelFilter,
  setLoadingRange,
  from,
  to,
}) => {
  const { t } = useTranslation();
  const userInfor = getUserInfoInLocalStorage();
  const projectId = userInfor.last_project_active;

  const [isLoadingUserData, setIsLoadingUserData] = useState(true);
  const [isLoadingReachData, setIsLoadingReachData] = useState(true);
  const [isLoadingEffectiveData, setIsLoadingEffectiveData] = useState(true);
  const [isLoadingDemographicData, setIsLoadingDemographicData] =
    useState(true);

  const [isLoadingGoldTimeData, setIsLoadingGoldTimeData] = useState(true);

  const [timeGoldData, setTimeGoldData] = useState([]);
  const [demographicData, setDemographicData] = useState({});
  const [overviewCustomerData, setOverviewCustomerData] = useState({});
  const [overViewPageReachData, setOverViewPageReachData] = useState({});
  const [overviewPageEffectiveData, setOverviewPageEffectiveData] = useState(
    {}
  );
  const [logFbData, setLogFbData] = useState({});

  const getAPIFacebook = (data) => {
    setIsLoadingUserData(true);
    setIsLoadingReachData(true);
    setIsLoadingEffectiveData(true);
    setIsLoadingDemographicData(true);
    setIsLoadingGoldTimeData(true);
    Promise.allSettled([
      getTimeGoldFacebookNew(data),
      getReachDemographicFacebook(data),
      getOverviewCustomerFb(data),
      getOverviewPageReachFb(data),
      getOverviewPageEffectiveFb(data),
      getLogFetchFb(data),
    ]).then((responses) => {
      // Destructure the responses to get the data
      const [
        timeGoldResponse,
        demographicResponse,
        overviewCustomerRes,
        overViewPageReachRes,
        overviewPageEffectiveRes,
        logFbRes,
      ] = responses;

      if (timeGoldResponse.status === "fulfilled") {
        const timeGoldData = timeGoldResponse.value.data;
        setTimeGoldData(timeGoldData);
      }
      setIsLoadingGoldTimeData(false);

      if (demographicResponse.status === "fulfilled") {
        const demographicData = demographicResponse.value.data;
        setDemographicData(demographicData);
      }
      setIsLoadingDemographicData(false);

      if (overviewCustomerRes.status === "fulfilled") {
        const overviewCustomer = overviewCustomerRes.value.data;
        setOverviewCustomerData(overviewCustomer);
      }
      setIsLoadingUserData(false);

      if (overViewPageReachRes.status === "fulfilled") {
        const overViewPageReach = overViewPageReachRes.value.data;
        setOverViewPageReachData(overViewPageReach);
      }
      setIsLoadingReachData(false);

      if (overviewPageEffectiveRes.status === "fulfilled") {
        const overviewPageEffective = overviewPageEffectiveRes.value.data;
        setOverviewPageEffectiveData(overviewPageEffective);
      }
      setIsLoadingEffectiveData(false);

      if (logFbRes.status === "fulfilled") {
        const logFb = logFbRes.value.data;
        setLogFbData(logFb);
      }

      setLoadingChannelFilter(false);
      setLoadingRange(false);
    });
  };

  useEffect(() => {
    const data = {
      params: {
        project_id: projectId,
        channel_ids: channels,
        from,
        to,
      },
    };
    getAPIFacebook(data);
  }, [from, to, channels, projectId]);

  return (
    <>
      <Col lg={12} className="px-8">
        <LogHeader
          cnt_channel_error={logFbData?.cnt_channel_error}
          cnt_channel_success={logFbData?.cnt_channel_success}
          last_time={logFbData?.last_time}
          currentTab={PLATFORM_NAME.FACEBOOK}
        />
      </Col>
      <div style={{ paddingTop: "18px" }}>
        <Col lg={12}>
          <Row>
            <ImpressionStatistic from={from} to={to} channels={channels} />
            <AudienceStatistic from={from} to={to} channels={channels} />
          </Row>
        </Col>

        {/* <Block style> */}
        <Col lg={12}>
          <Row
            xl="5"
            lg="4"
            md="3"
            sm="2"
            style={{ padding: "14px 7px 7px 7px" }}
          >
            <OverviewUser
              data={overviewCustomerData}
              isLoading={isLoadingUserData}
            />
            <OverviewEffect
              dataEffect={overviewPageEffectiveData}
              isLoading={isLoadingEffectiveData}
              dataReach={overViewPageReachData}
              isLoadingReach={isLoadingReachData}
            />
          </Row>
        </Col>
        {/* </Block> */}
        {/* <Block> */}
        <Col lg={12} style={{ paddingTop: "10px" }}>
          <Row>
            <Col xxl={6} xl={6}>
              <TopCity
                data={
                  overViewPageReachData?.page_impressions_by_city_unique || []
                }
                isLoading={isLoadingReachData}
              />
            </Col>
            <FanOnlineChart
              series={timeGoldData}
              isLoading={isLoadingGoldTimeData}
            />
          </Row>
        </Col>
        {/* </Block> */}

        {/* <Block> */}
        <Col lg={12} style={{ paddingTop: "20px" }}>
          <Row>
            <Col xxl={6} xl={6}>
              <Card>
                <CardHeader className="bg-slate-50 align-items-center d-flex">
                  <h4
                    className="card-title mb-0 flex-grow-1"
                    style={{ fontSize: "16px" }}
                  >
                    {t("gender")}
                  </h4>
                </CardHeader>
                <CardBody>
                  {isLoadingDemographicData ? (
                    <LoadingItem />
                  ) : (
                    <GenderStatistic
                      data={demographicData?.gender}
                      dataColors='["--vz-primary", "--vz-light"]'
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col xxl={6} xl={6}>
              <AgeChartFb
                data={demographicData?.age}
                isLoading={isLoadingDemographicData}
              />
              {/* <AgeStatistic
              data={demographicData?.age}
              isLoading={isLoadingDemographicData}
            /> */}
            </Col>
          </Row>
        </Col>
        {/* </Block> */}

        {/* <Block> */}
        <Col lg={12} style={{ paddingTop: "20px" }}>
          <Card>
            <CardHeader className="bg-slate-50 align-items-center d-flex">
              <h4
                className="card-title mb-0 flex-grow-1"
                style={{ fontSize: "16px" }}
              >
                {t("channels_analytics")}
              </h4>
            </CardHeader>
            <CardBody>
              <FrequencyTable from={from} to={to} projectId={projectId} />
            </CardBody>
          </Card>
        </Col>
        {/* </Block> */}

        {/* <Block> */}
        <Col lg={12} style={{ paddingTop: "20px" }}>
          <Card>
            <CardHeader className="bg-slate-50 align-items-center d-flex">
              <h4
                className="card-title mb-0 flex-grow-1"
                style={{ fontSize: "16px" }}
              >
                {t("account_analytics")}
              </h4>
            </CardHeader>
            <CardBody>
              <ViaTable
                key={"via_fb_" + projectId}
                projectId={projectId}
                from={from}
                to={to}
              />{" "}
            </CardBody>
          </Card>
        </Col>
        {/* </Block> */}
      </div>
      {/* <Content> */}

      {/* </Content> */}
    </>
  );
};
