import React, { useState } from "react";
import { CONTENT_LIMIT_SEE_MORE } from "../../utils/Utils";
import style from "./index.module.scss";
import "./_preview-facebook.scss";
import { Img } from "../../../components/Component";
import {
  getPlatformIconFromLabel
} from "../../utils/Utils";
const PreviewYoutube = ({ ...props }) => {
  const { channel, content, mediaItems, className } = props;
  const [openPreviewYoutube, setOpenPreviewYoutube] = useState(true)
  const displayContent =
    content?.length <= CONTENT_LIMIT_SEE_MORE ? (
      content
    ) : (
      <>
        {content?.slice(0, CONTENT_LIMIT_SEE_MORE)}
        <b>See more...</b>
      </>
    );

    const handleOpenPreviewYoutube = () => {
      setOpenPreviewYoutube(!openPreviewYoutube)
      const Elmchevron = document.getElementById(`chevron_previewYoutube`);
      const Elmcontent = document.getElementById("content_previewYoutube");
      if (!openPreviewYoutube) {
        Elmchevron.classList.add(`${style.openChevronDown}`)
        Elmchevron.classList.remove(`${style.openChevronUp}`)
        Elmcontent.classList.add(`${style.openContentDown}`)
        Elmcontent.classList.remove(`${style.openContentUp}`)
      }
      else {
        Elmchevron.classList.add(`${style.openChevronUp}`)
        Elmchevron.classList.remove(`${style.openChevronDown}`)
        Elmcontent.classList.add(`${style.openContentUp}`)
        Elmcontent.classList.remove(`${style.openContentDown}`)
      }
    }
  
  return (
    <div className={`previewFacebook ${className || ""}`}>
      <div className="d-flex align-items-stretch border-bottom">
        <div
          className={`flex-fill p-2 fw-medium header ${style.previewYoutube}`}
        >
          <Img
            src={getPlatformIconFromLabel("youtube")}
            width= "16px"
            size="circle"
            style={{marginRight: "4px" }}
          />
          Youtube
        </div>
        <div id="chevron_previewYoutube" className={`card-tools p-2 ${style.openMedia}`} onClick={handleOpenPreviewYoutube}>
          <svg width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" /> </svg>
        </div>
      </div>
      <div id="content_previewYoutube" style={{
        transition: "max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s",
        willChange: "max-height",
        position: "relative",
        overflow: "hidden"
      }}>

        <div className={`body ${style.previewYoutube__aspectRatio}`}>

          {mediaItems?.length > 0 &&

            <Img src={mediaItems[0]?.previewImages.url} alt="something" className="image" />}
        </div>
        <div className="d-flex p-1">
          <div className="mr-1 mt-1">
            <Img 
            className="avatar" 
            src={channel.picture} 
            alt="avatar" 
            width= "34px"
            size="circle"
            />
          </div>
          <div className="content ">
            <div className="authorName font-bold" style={{ color: "#333333", fontFamily: "Roboto" }}>{channel.name}</div>
            {content?.length > 0 && <div className="content" style={{ color: "#333333", fontFamily: "Roboto" }}>{displayContent}</div>}
            <div className="authorName" style={{ fontSize: "10px", fontFamily: "Roboto" }}>{channel.name}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewYoutube;
