import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import WidgetsCharts from "../../Chart/WidgetsCharts";
import NO_DATA_DEFAULT from "../../Image/no_data_default.svg";
import NO_IMAGE from "../../Image/no_image_user.svg";
import { getViaFacebook } from "../../../../../api/dashboard";
import { renderNameField } from "../../helper";
import { numberWithCommas } from "../../../DashboardNew/utils/utils";
import { PaginationCustom } from "../../../../../components/Component";
import style from "../../index.module.scss";

const ViaTable = ({ projectId, from, to }) => {
  const { t } = useTranslation();
  const [viaData, setViaData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [isShowNodata, setIsShowNodata] = useState(-1);
  const refName = useRef();

  const headerVia = {
    user: t("via_table.account"),
    totalPostPerDay: t("via_table.total_post_per_day"),
    countPostReel: t("via_table.reels"),
    countPostVideo: t("via_table.watch"),
    countPostPhoto: t("via_table.photo"),
    countPostFeed: t("via_table.status"),
    "": "",
  };

  const handleHoverNoData = (id) => {
    setIsShowNodata(id);
  };

  const fetchData = async () => {
    const data = {
      params: {
        project_id: projectId,
        page: currentPage,
        from,
        to,
      },
    };

    const viaFacebookRes = await getViaFacebook(data);

    if (viaFacebookRes) {
      const viaFacebook = viaFacebookRes.data;
      setViaData(viaFacebook);

      console.log("viaFacebook: ", viaFacebook);
      setListData(
        viaFacebook?.data?.map((item) => ({
          user: item?.user?.name,
          totalPostPerDay: item?.total_post_per_day,
          countPostReel: item?.count_post_reel,
          countPostVideo: item?.count_post_video,
          countPostPhoto: item?.count_post_photo,
          countPostFeed: item?.count_post_feed,
          image: item?.user?.picture,
          dataSetFrequency: item?.dataSetFrequency,
        }))
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, [from, to, projectId, currentPage]);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  return (
    <>
      {/* <Card className="card-bordered"> */}
      <div className="card-inner-group">
        <div className="card-inner p-0 table-responsive">
          <table className="table table-hover">
            <thead
              style={{
                height: "50px",
                margin: 0,
                padding: 0,
              }}
            >
              <tr>
                {headerVia &&
                  Object.values(headerVia).map((field, index) => {
                    const styleTh =
                      field === t("via_table.account")
                        ? {
                          paddingBottom: "20px",
                        }
                        : {
                          paddingBottom: "20px",
                          textAlign: "end",
                        };
                    return (
                      <th
                        scope="col"
                        style={styleTh}
                        className={style.fontCommon}
                        key={`th_${index}`}
                      >
                        {" "}
                        <span style={{ fontSize: "14px" }}>{field}</span>
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {listData.length > 0
                ? listData.map((item, index) => {
                  return (
                    <tr key={index}>
                      {Object.keys(headerVia).map((fk, keyIndex) => {
                        if (fk === "user") {
                          return (
                            <td
                              key={`${fk}-${keyIndex}`}
                              style={{
                                height: "50px",
                                minWidth: "150px",
                                maxWidth: "200px",
                              }}
                            >
                              <div
                                style={{ display: "flex", maxHeight: "32px" }}
                              >
                                <img
                                  src={item.image ?? NO_IMAGE}
                                  alt=""
                                  width="32px"
                                  style={{ borderRadius: "50%" }}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = NO_IMAGE;
                                  }}
                                ></img>
                                <div
                                  ref={refName}
                                  style={{
                                    paddingTop: "8px",
                                    paddingLeft: "10px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    display: "block",
                                    textOverflow: "ellipsis",
                                  }}
                                  className="tb-sub"
                                >
                                  {item[`${fk}`]}
                                </div>
                              </div>
                            </td>
                          );
                        }
                        if (fk === "totalPostPerDay") {
                          const dataChart = [
                            {
                              name: "",
                              data: item?.dataSetFrequency?.map((d) =>
                                numberWithCommas(d.y)
                              ),
                            },
                          ];
                          return (
                            <td
                              key={`${fk}-${keyIndex}`}
                              style={{
                                height: "60px",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <div
                                style={{
                                  width: "150px",
                                  height: "50px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div className="traffic-channel-ck ml-auto">
                                  {item[`${fk}`] > 0 ? (
                                    <WidgetsCharts
                                      seriesData={dataChart}
                                      chartsColor="#45CB85"
                                      labelX={item?.dataSetFrequency?.map(
                                        (d) => d.x
                                      )}
                                      isInTable={true}
                                    />
                                  ) : (
                                    <div
                                      style={{
                                        paddingTop: "12px",
                                        position: "relative",
                                        cursor: "pointer",
                                      }}
                                      onMouseEnter={() =>
                                        handleHoverNoData(index)
                                      }
                                      onMouseLeave={() =>
                                        handleHoverNoData(-1)
                                      }
                                    >
                                      <div>
                                        <img src={NO_DATA_DEFAULT} alt="" />
                                      </div>
                                      {isShowNodata === index && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            justifyContent: "center",
                                            top: "10px",
                                            paddingLeft: "10px",
                                          }}
                                        >
                                          No data
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <span
                                  className="tb-sub"
                                  style={{
                                    paddingTop: "10px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {numberWithCommas(item[`${fk}`])}
                                </span>
                              </div>
                            </td>
                          );
                        }
                        return (
                          <td
                            key={`${fk}-${keyIndex}`}
                            style={{ height: "50px" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <span
                                style={{
                                  paddingTop: "4px",
                                  paddingLeft: "10px",
                                }}
                                className="tb-sub"
                              >
                                {item[`${fk}`]}
                              </span>
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
                : null}
            </tbody>
          </table>
          {/* <DataTableBody></DataTableBody> */}
          <div className="card-inner">
            {listData?.length > 0 && viaData ? (
              viaData.last_page > 1 && (
                <PaginationCustom
                  pageSize={viaData?.per_page || 9}
                  totalCount={viaData?.total || 0}
                  siblingCount={1}
                  onPageChange={handlePageChange}
                  currentPage={viaData?.current_page}
                />
              )
            ) : (
              <div className="text-center">
                <span className="text-silent">No page found</span>
              </div>
            )}
          </div>
        </div>
      </div >
      {/* </Card> */}
    </>
  );
};

export default ViaTable;
