import { useHistory } from "react-router";


export default function useCheckLogin() {
    const history = useHistory();
    const auth = localStorage.getItem("accessToken");
    const is_new_user = localStorage.getItem("is_new_user");
    if(auth && is_new_user=== "0"){
        history.replace('/news')
    }
}