import ReactApexChart from "react-apexcharts";
const WidgetsCharts = ({ seriesData, chartsColor, isInTable, labelX }) => {
  const areachartlitecoinColors = [chartsColor];
  const options = {
    xaxis: {
      categories: labelX ?? [],
      tickAmount: 5,
    },
    chart: {
      width: 130,
      height: 46,
      type: "area",
      sparkline: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 1.5,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [50, 100, 100, 100],
      },
    },
    colors: areachartlitecoinColors,
    tooltip: {
      enabled: true,
      shared: true,
      fixed: {
        position: "bottomLeft",
      },
    },
  };
  return (
    <>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={[...seriesData]}
        type="area"
        height={isInTable ? "35" : "65"}
        className="apex-charts"
      />
    </>
  );
};

export default WidgetsCharts;
