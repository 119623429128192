import moment from "moment";
import React, { useState } from "react";
import { Col, FormGroup, ModalBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toastError, toastSuccess } from "../../../../libs/toasts";
import { createTask } from "../../../../api/kanban";
import { Button, Icon, RSelect } from "../../../../components/Component";
import { LoadingItem } from "../../Dashboard_v2/CommonItem/LoadingItem";
import DateTimeInput from "./DateTimeInput";

const KanbanTaskForm = ({
  toggle,
  activeGroup,
  section,
  staffs,
  sections,
  setInitialData,
}) => {
  const { t } = useTranslation();
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));

  const sectionOptions = [];

  for (let [key, value] of Object.entries(sections)) {
    sectionOptions.push({
      value: key,
      label:
        value.name === ""
          ? t("task_management.default_section")
          : value.name?.length > 30
          ? value.name.substring(0, 29) + "... "
          : value.name,
    });
  }

  const [start, setStart] = useState(Date.now());
  const [end, setEnd] = useState(null);
  const [loadingCreateTask, setLoadingCreateTask] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    section_id: section ? section?._id : sectionOptions[0].value,
    group_id: activeGroup?._id,
    description: "",
    owner_id: userInfo.id,
    subcriber_ids: [userInfo.id],
    started_at: moment(Date.now()).unix(),
    // ended_at: moment(Date.now()).unix(),
  });

  const staffOptions = staffs?.map((item) => ({
    value: item.id,
    label:
      item.name?.length > 10 ? item.name.substring(0, 9) + "... " : item.name,
  }));

  const submitForm = async () => {
    const projectId = userInfo.last_project_active;

    setLoadingCreateTask(true);

    try {
      const res = await createTask(projectId, formData);
      if (res?.status && res?.data) {
        const newTask = res.data;

        setInitialData((prevData) => {
          const updatedTasks = {
            ...prevData.tasks,
            [newTask._id]: newTask,
          };

          const updatedColumns = { ...prevData.columns };
          const columnId = newTask.section_id;
          if (updatedColumns[columnId]) {
            updatedColumns[columnId] = {
              ...updatedColumns[columnId],
              tasks: [...updatedColumns[columnId].tasks, newTask._id],
              cnt_task: updatedColumns[columnId].cnt_task + 1,
            };
          }

          return {
            ...prevData,
            tasks: updatedTasks,
            columns: updatedColumns,
          };
        });
        toastSuccess(t("task_management.toast.create_task_success"));
      }
    } catch (e) {
      setLoadingCreateTask(false);
      toggle();
      toastError(t("task_management.toast.create_task_failed"));
      console.error(e);
    } finally {
      setLoadingCreateTask(false);
      toggle();
    }
  };

  const handleSetStartDate = (date) => {
    setStart(date);
    setFormData({
      ...formData,
      started_at: Math.floor(date.getTime() / 1000),
    });
  };

  const handleSetDueDate = (date) => {
    setEnd(date);
    setFormData({
      ...formData,
      ended_at: Math.floor(date.getTime() / 1000),
    });
  };

  const { handleSubmit } = useForm();
  return (
    <ModalBody>
      <>
        <Button className="close" onClick={() => toggle()}>
          <Icon name="cross-sm"></Icon>
        </Button>
        <div className="p-2">
          <h5 className="title">{t("task_management.add_task")}</h5>
          <div className="mt-4">
            <form className="row gy-4" onSubmit={handleSubmit(submitForm)}>
              <Col sm="6">
                <FormGroup>
                  <label className="form-label">
                    {t("task_management.form.task_title")}
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        title: e.target.value,
                      })
                    }
                    placeholder={t(
                      "task_management.placeholder.please_type_your_task_title"
                    )}
                    required
                    className="form-control"
                  />
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <label className="form-label">
                    {t("task_management.form.select_section")}
                  </label>
                  <RSelect
                    defaultValue={sectionOptions[0]}
                    options={sectionOptions}
                    placeholder={t(
                      "task_management.placeholder.select_a_section"
                    )}
                    onChange={(e) => {
                      setFormData({ ...formData, section_id: e.value });
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col className="col-12">
                <FormGroup>
                  <label className="form-label">
                    {t("task_management.form.task_description")}
                  </label>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        description: e.target.value,
                      })
                    }
                    placeholder={t(
                      "task_management.placeholder.please_type_your_group_desc"
                    )}
                    // required
                    className="form-control no-resize"
                  />
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <label className="form-label">
                    {t("task_management.form.task_owner")}
                  </label>
                  <RSelect
                    options={staffOptions}
                    defaultValue={staffOptions?.find(
                      (item) => item.value === userInfo.id
                    )}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        owner_id: e.value || userInfo.id,
                      })
                    }
                    required
                  />
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <label className="form-label">
                    {t("task_management.form.task_subscribers")}
                  </label>
                  <RSelect
                    options={staffOptions}
                    isMulti
                    defaultValue={staffOptions?.filter(
                      (item) => item.value === userInfo.id
                    )}
                    onChange={(selectedOptions) => {
                      const selectedValues = selectedOptions.map(
                        (option) => option.value
                      );
                      setFormData({
                        ...formData,
                        subcriber_ids: selectedValues,
                      });
                    }}
                    required
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup>
                  <label className="form-label">
                    {t("task_management.form.start_date")}
                  </label>
                  <div className="form-control date-picker d-flex items-center">
                    <DateTimeInput
                      date={start}
                      setDate={handleSetStartDate}
                      isForm
                      required
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <label className="form-label">
                    {t("task_management.form.due_date")}
                  </label>
                  <div className="form-control date-picker d-flex items-center">
                    <DateTimeInput
                      date={end}
                      setDate={handleSetDueDate}
                      isForm
                      // required
                      started_at={start}
                    />
                  </div>
                </FormGroup>
              </Col>

              <Col className="col-12">
                <ul className="d-flex justify-content-between align-center flex-wrap flex-sm-nowrap gx-4 gy-2 mt-3">
                  <li>
                    <Button
                      className="btn d-flex justify-content-center align-center"
                      color="primary"
                      size="md"
                      type="submit"
                    >
                      {!loadingCreateTask ? (
                        <p className="m-0">{t("task_management.add_task")}</p>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            marginLeft: "4px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <LoadingItem
                            style={{
                              width: "16px",
                              height: "16px",
                              color: "#fff",
                            }}
                          />
                        </div>
                      )}
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={() => toggle()}
                      className="link link-light"
                    >
                      {t("task_management.form.cancel")}
                    </Button>
                  </li>
                </ul>
              </Col>
            </form>
          </div>
        </div>
      </>
    </ModalBody>
  );
};

export default KanbanTaskForm;
