import React, { useState, useEffect } from "react";
import {
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Spinner,
  ModalFooter
} from "reactstrap";
import {
  Icon,
  Col,
  Button,
  RSelect,
  Img
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { bulkActionOptions } from "../../../utils/Utils";
import { createNewSheet, getListLinkGGs, getPageGGs, exportConfigGGs } from '../../../api/integrate';
import { ExportGGStatus, optionsDataExport } from './constant';
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import GGSheetIcon from "../../../assets/images/icon/ggsheet.png";

const EditModule = (props) => {
  const dataEdited = props.dataEdited;
  const { t } = useTranslation()
  bulkActionOptions[0].label = t('tags.delete')
  bulkActionOptions[1].label = t('tags.in_active')
  const [loading, setLoading] = useState(false)
  const [listTableGGs, setlistTableGGs] = useState([]);
  const [listTableGGsSelected, setlistTableGGsSelected] = useState(null);
  const [listPageGGs, setlistPageGGs] = useState([]);
  const [listPageGGsSelected, setlistPageGGsSelected] = useState(null);
  const [formExportData, setFormExportData] = useState({});
  const [dataExportSelected, setDataExportSelected] = useState(null);
  const [isValidDataExport, setValidDataExport] = useState(true);
  const [modalAddNewSheet, setModalAddNewSheet] = useState(false);
  const [pageSheetValid, setPageSheetValid] = useState(true);

  useEffect(() => {
    if (!dataEdited) return;
    const userInfo = JSON.parse(localStorage.getItem("userInfor"));
    const projectId = userInfo.last_project_active;
    const data = {
      params: {
        email: dataEdited.configs.account,
        project_id: projectId
      },
    };
    setLoading(true);
    getListLinkGGs(data).then((response) => {
      if (response.status) {
        const listTableGGsFormat = response.data.map((item) => {
          return {
            label: item.name,
            value: item.id
          }
        });
        setlistTableGGs(listTableGGsFormat);
        setlistTableGGsSelected(listTableGGsFormat.find(item => item.value === dataEdited.configs.google_sheet_id));
      }
      else if (!response.status && response.message === "invalid_grant") {
        props.onFormConnectHandle();
      }
    })
    setDataExportSelected(optionsDataExport.filter(item => item.value === dataEdited.object));
    getPageGGs(dataEdited.configs.google_sheet_id, data).then((res) => {
      if (res.status) {
        const listPageGGsFormat = res.data.map((item) => {
          return {
            label: item,
            value: item
          }
        });
        setlistPageGGs(listPageGGsFormat);
        setlistPageGGsSelected(listPageGGsFormat.find(item => item.value === dataEdited.configs.sheet_name) ?? null);
      }
      else if (!res.status && res.message === "invalid_grant") {
        props.onFormConnectHandle();
      }
    });
    setLoading(false);
  }, [dataEdited]);

  // onChange table GGs
  const onTableGGsChange = (e) => {
    setlistTableGGsSelected(e);
    const userInfo = JSON.parse(localStorage.getItem("userInfor"));
    const projectId = userInfo.last_project_active;
    const data = {
      params: {
        email: dataEdited.configs.account,
        project_id: projectId
      },
    };
    setLoading(true);
    getPageGGs(e.value, data).then((res) => {
      if (res.status) {
        const listPageGGsFormat = res.data.map((item) => {
          return {
            label: item,
            value: item
          }
        });
        setlistPageGGs(listPageGGsFormat);
        setlistPageGGsSelected(null);
        setLoading(false);
      }
      else if (!res.status && res.message === "invalid_grant") {
        props.onFormConnectHandle();
      }
    });

    setFormExportData({
      ...formExportData, configs: {
        ...formExportData.configs,
        google_sheet_id: e.value
      }
    });
  }

  const onFormCancel = () => {
    props.setVisible();
  };

  // add new sheet
  const _handleAddNewSheet = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfor"));
    const projectId = userInfo.last_project_active;
    const title = document.getElementById("sheet").value;
    let data = {
      project_id: projectId,
      email: dataEdited.configs.account,
      title: title
    }
    setLoading(true);
    createNewSheet(data).then(res => {
      if (res.status) {
        const dataLink = {
          params: {
            project_id: projectId,
            email: dataEdited.configs.account,
          }
        }
        getListLinkGGs(dataLink).then((response) => {
          if (response.status) {
            const listTableGGsFormat = response.data.map((item) => {
              return {
                label: item.name,
                value: item.id
              }
            });
            const tableGGsNew = listTableGGsFormat.find(item => item.label === title);
            setlistTableGGs(listTableGGsFormat);
            setlistTableGGsSelected(listTableGGsFormat.find(item => item.value === tableGGsNew.value));
            setlistPageGGsSelected(null);
            document.getElementById("sheet").value = "";
            setModalAddNewSheet(false);
            setLoading(false);
          }
          else if (!response.status && response.message === "invalid_grant") {
            props.onFormConnectHandle();
          }
        })
      }
      else if (!res.status && res.message === "invalid_grant") {
        props.onFormConnectHandle();
      }
    });
  }

  const validationForm = () => {
    if (!listPageGGsSelected) {
      setPageSheetValid(false);
      return false;
    }
    else {
      setPageSheetValid(true);
    }
    return true;
  }

  // function to submit form export data
  const onSubmitFormExportData = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfor"));
    const projectId = userInfo.last_project_active;
    const objectValue = dataExportSelected.map((item) => {
      return item.value;
    });
    if (validationForm()) {
      const formExportDataFormat = {
        ...formExportData,
        configs: {
          google_sheet_id: listTableGGsSelected.value,
          sheet_name: listPageGGsSelected.value,
          account: dataEdited.configs.account
        },
        object: objectValue,
        source: ExportGGStatus.SOURCE_GOOGLE_SHEET,
        status: ExportGGStatus.ACTIVE,
        project_id: projectId
      }
      if (!formExportDataFormat.object || formExportDataFormat.object.length === 0) {
        setValidDataExport(false);
      }
      else {
        const ExportLengthObject = formExportDataFormat.object.length;
        let counterObjectSubmit = 0;
        setValidDataExport(true);
        formExportDataFormat.object.map((objItem, index) => {
          let formExportDataFormatPerObject = {
            ...formExportDataFormat,
            object: objItem
          };
          const ObjectName = optionsDataExport.find(item => item.value === objItem);
          exportConfigGGs(formExportDataFormatPerObject).then((resp) => {
            if (resp.status) {
              counterObjectSubmit++;
              if (ExportLengthObject === index + 1) {
                props.setVisible();
                if (counterObjectSubmit === ExportLengthObject) {
                  toast.success("Cập nhật cấu hình thành công!", {
                    autoClose: 1000,
                  });
                }
                props.refreshDataExport();
              }
            }
            else if (!resp.status && resp.message === "invalid_grant") {
              props.onFormConnectHandle();
            }
            else {
              toast.error(`Cập nhật cấu hình ${ObjectName.label} thất bại!`, {
                autoClose: 2000,
              });
            }
          }).catch(e => {
            if (ExportLengthObject === index + 1) {
              props.setVisible();
              toast.error(`Cập nhật cấu hình ${ObjectName.label} thất bại!`, {
                autoClose: 2000,
              });
              props.refreshDataExport();
            }
            console.log(e);
          });
        });
      }
    }
  }

  return (
    <>
      {
        dataEdited &&
        <Modal isOpen={props.isVisible} toggle={() => props.setVisible()} className="modal-md">
          <ModalHeader toggle={() => props.setVisible()}>Quản lý cấu hình form</ModalHeader>
          <ModalBody>
            <Form className="row gy-4">
              <Col size="12">
                <FormGroup>
                  <label className="form-label" htmlFor="event-title" style={{ fontWeight: '600', fontSize: '14px', color: '#6A2C62' }}>
                    DATA XUẤT
                  </label>
                  <RSelect
                    options={
                      optionsDataExport
                    }
                    isMulti
                    value={dataExportSelected}
                    required
                    placeholder={"Chọn dữ liệu muốn kết nối"}
                    onChange={(e) => {
                      const dataSelected = e.map((item) => {
                        return item.value;
                      });
                      if (e.length > 0) {
                        setValidDataExport(true);
                      }
                      else {
                        setValidDataExport(false);
                      }
                      setDataExportSelected(e);
                      setFormExportData({ ...formExportData, object: dataSelected });
                    }}
                    closeMenuOnSelect={false}
                  />
                  {!isValidDataExport && <p style={{ color: "red", fontStyle: "italic" }}>Bạn cần chọn thông tin xuất</p>}
                </FormGroup>
                <FormGroup className="d-flex justify-content-between mb-0">
                  <label className="form-label" htmlFor="event-title" style={{ margin: 'auto 0', fontWeight: '600', fontSize: '14px', color: '#6A2C62' }}>
                    TÀI KHOẢN LIÊN KẾT (1)
                  </label>
                </FormGroup>
                <FormGroup>
                  <Img src={GGSheetIcon} className="img-fluid mr-1" style={{ width: "12px" }} />
                  <span>{dataEdited.configs.account}</span>
                </FormGroup>
                <FormGroup>
                  <label className="overline-title overline-title-alt" style={{ fontWeight: '600px', color: "#4F4F4F" }}>Chọn bảng tính</label>
                  <div className="d-flex">
                    <div style={{ width: "75%" }}>
                      <RSelect
                        options={listTableGGs}
                        value={listTableGGsSelected}
                        placeholder={"Chọn bảng tính có sẵn"}
                        onChange={onTableGGsChange}
                        closeMenuOnSelect={true}
                      />
                    </div>
                    <Button style={{ backgroundColor: '#F2F2F2', border: '1px solid #E0E0E0', borderRadius: "4px", width: "25%", display: "block" }} onClick={(e) => {
                      e.preventDefault();
                      setModalAddNewSheet(true);
                    }}>Thêm mới</Button>
                  </div>
                </FormGroup>
                <FormGroup>
                  <label className="overline-title overline-title-alt" style={{ fontWeight: '600px', color: "#4F4F4F" }}>Chọn trang tính {loading &&
                    <div className="text-center d-inline">
                      <Spinner color="dark" style={{ width: '1rem', height: '1rem' }} />
                    </div>}</label>
                  <RSelect
                    options={listPageGGs}
                    value={listPageGGsSelected}
                    isSearchablle={true}
                    placeholder={"Chọn trang tính có sẵn"}
                    onChange={(e) => {
                      setlistPageGGsSelected(e);
                      setPageSheetValid(true);
                      setFormExportData({
                        ...formExportData, configs: {
                          ...formExportData.configs,
                          sheet_name: e.value
                        }
                      });
                    }}
                    closeMenuOnSelect={true}
                  />
                  {!pageSheetValid && <p style={{ color: "red", fontStyle: "italic" }}>Bạn cần chọn trang tính!</p>}
                </FormGroup>
              </Col>
            </Form>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "space-between" }}>
            <div className="d-flex">
              <Icon name="question" style={{ lineHeight: "unset" }}></Icon>
              <p className="sub-text">Hướng dẫn tạo cấu hình Form <Link to="https://www.google.com.vn/drive/about.html">Tại đây</Link> </p>
            </div>
            <div>
              <Button style={{ backgroundColor: "#E0E0E0", color: "#FFFFFF", marginRight: "1rem", fontWeight: "500" }} onClick={(ev) => { ev.preventDefault(); onFormCancel(); }}>
                Hủy
              </Button>
              <Button style={{ backgroundColor: "#FD1F6D", color: "#FFFFFF", fontWeight: "500" }} onClick={(ev) => { ev.preventDefault(); onSubmitFormExportData(); }}>
                Cập nhật
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      }
      <Modal isOpen={modalAddNewSheet} toggle={() => setModalAddNewSheet(false)} className="modal-md">
        <ModalHeader toggle={() => setModalAddNewSheet(false)}>Thêm mới bảng tính</ModalHeader>
        <ModalBody>
          <Form className="row gy-4">
            <Col size="12">
              <FormGroup>
                <label className="form-label" htmlFor="event-title" style={{ fontWeight: '600', fontSize: '14px', color: '#6A2C62' }}>
                  TÊN BẢNG TÍNH
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="sheet"
                    name="sheet"
                    className="form-control"
                  />
                </div>
              </FormGroup>
            </Col>
          </Form>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "space-between" }}>
          <div>
            <Button style={{ backgroundColor: "#E0E0E0", color: "#FFFFFF", marginRight: "1rem", fontWeight: "500" }} onClick={(ev) => { ev.preventDefault(); setModalAddNewSheet(false); }}>
              Hủy
            </Button>
            <Button style={{ backgroundColor: "#FD1F6D", color: "#FFFFFF", fontWeight: "500" }} onClick={(ev) => { ev.preventDefault(); _handleAddNewSheet(); }}>
              {loading ? <Spinner size="sm" color="light" /> : "Thêm mới"}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default EditModule;
