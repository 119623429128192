import React, { useCallback, useState } from "react";
import { Button, FormGroup, FormText, UncontrolledTooltip } from "reactstrap";
import PostMode from "../../CreatePost/PostMode";
import SimpleBar from "simplebar-react";
import FlowerSVG from "../../../images/flower.svg";
import CloseIcon from "../../../images/close.png";
import style from "../index.module.scss";
import {
  MANUAL_POST,
  MEDIA_TYPE_IMAGE,
  getPlatformIcon,
  PLATFORM_LABEL_OPTION,
  PLATFORM_FACEBOOK,
} from "../../../utils/Utils";
// import So9 from "../../../images/so9.png";
import icon from "../../../images/icons/icon-random.svg";
import word from "../../../images/icons/word-random.svg";
import iconNotVideo from "../../../images/icons/icon-notVideo.svg";
import iconNotImage from "../../../images/icons/icon-notImage.svg";
import ContentOriginal from "../../Reels/ContentOriginal";
import { useDispatch, useSelector } from "react-redux";
import { RSelect } from "../../../../components/Component";
import { components } from "react-select";

import { setChannels } from "../../../reducers/createPostSlice";
import { Controller, useForm } from "react-hook-form";
import DefaultChannelAvatar from "../../../images/avatar/default-channel-avatar.png";
import { useTranslation } from "react-i18next";

const SideBarContent = ({ isShowSideBar, handleOpenSideBar, itemSelected }) => {
  const { i18n, t } = useTranslation();

  const [scheduledAt, setScheduledAt] = useState(new Date());
  const [timeSo9Smart, setTimeSo9Smart] = useState(new Date());
  const date = new Date();
  const [timeStart, setTimeStart] = useState(date.getHours());
  const [timeFinish, setTimeFinish] = useState(24);

  const [showScheduleSelectTime, setShowScheduleSelectTime] = useState(true);
  const [selectedOptionPost, setSelectedOptionPost] = useState(MANUAL_POST);

  const project = useSelector((state) => state.project.project);
  const channelOptions = useSelector((state) => state.channel.channels)?.filter(
    (e) => e.platform === PLATFORM_FACEBOOK || e.platform === PLATFORM_FACEBOOK
  );

  const channels = useSelector((state) => state.createPost.channels);
  const [isEditPost, setEditPost] = useState(false);

  const mediaItems = useSelector((state) => state.createPost.mediaItems);

  const MultiValueLabel = (props) => {
    const { data } = props,
      imgStyle = {
        flex: "0 0 14px",
        width: "14px",
        height: "14px",
        objectFit: "cover",
      },
      spanStyle = { paddingLeft: "7px" };
    return (
      <components.MultiValueLabel {...props}>
        <img
          src={getPlatformIcon(data.platform)}
          alt={`entity-${data.platform}`}
          style={imgStyle}
        />
        <span style={spanStyle}>{data.name}</span>
      </components.MultiValueLabel>
    );
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const dispatch = useDispatch();

  const toggleSaveScheduled = () => {
    setShowScheduleSelectTime(true);
    setScheduledAt(new Date());
    setTimeSo9Smart(new Date());
    setTimeFinish(24);
    setTimeStart(date.getHours());
  };
  const toggleSaveAndPost = () => {
    setShowScheduleSelectTime(false);
    setScheduledAt(null);
    setTimeSo9Smart(null);
    setTimeFinish(null);
    setTimeStart(null);
  };
  const handleScheduledAt = (date) => {
    setScheduledAt(date);
  };

  const handleChangeTimeStart = (timeStart) => {
    setTimeStart(timeStart.target.value);
  };

  const handleChangeTimeSmart = (date) => {
    setTimeSo9Smart(date);
  };

  const handleChangeTimeEnd = (timeEnd) => {
    setTimeFinish(timeEnd.target.value);
  };
  const handleOptionChangePost = (type) => {
    setSelectedOptionPost(type);
  };

  const onChangeChannels = useCallback(
    (e) => {
      dispatch(setChannels(e));
    },
    [dispatch]
  );

  function unique(arr) {
    var newArr = [];
    for (var i = 0; i < arr.length; i++) {
      if (newArr.indexOf(arr[i]) === -1) {
        newArr.push(arr[i]);
      }
    }
    return newArr;
  }

  const GroupHeading = (props) => {
    const { data } = props;
    return (
      <components.GroupHeading {...props}>
        {t(data.label)}
      </components.GroupHeading>
    );
  };

  const selectPlatformStyle = {
    multiValue: (base) => ({
      ...base,
      border: "1px solid #6A2C62",
      borderRadius: "100px !important",
      padding: "4px 11px",
      backgroundColor: "#f2f2f2 !important",
    }),
    multiValueLabel: (base) => ({
      ...base,
      display: "flex",
      alignItems: "center",
      padding: "0",
      paddingLeft: "0",
      fontSize: "12px",
      lineHeight: "1",
    }),
    menu: (styles) => ({ ...styles, maxHeight: "500px", overflow: "auto" }),
    multiValueRemove: (base) => ({
      ...base,
      marginLeft: "7px",
      padding: "0",
      lineHeight: "1",
    }),
  };

  const CustomOption = ({ children, isDisabled, ...props }) => {
    const { data } = props,
      imgStyle = {
        //marginTop:"3px",
        marginRight: "2px",
        width: "14px",
        height: "14px",
        objectFit: "cover",
        borderRadius: "50%",
      },
      avatarStyle = {
        flex: "0 0 14px",
        width: "32px",
        height: "32px",
        objectFit: "cover",
        borderRadius: "50%",
      },
      spanStyle = {
        marginLeft: "8px",
      },
      divStyle = isDisabled
        ? {
            border: "1px solid rgb(222, 225, 225)",
            padding: "8px",
            borderRadius: "6px",
            display: "flex",
            backgroundColor: "rgb(189, 189, 189)",
            opacity: "30%",
          }
        : {
            border: "1px solid rgb(222, 225, 225)",
            padding: "8px",
            borderRadius: "6px",
            display: "flex",
          };
    return (
      <components.Option {...props}>
        <div style={divStyle}>
          <img
            src={data.picture || DefaultChannelAvatar}
            alt={data.label}
            style={avatarStyle}
          />
          <div style={spanStyle}>
            <div className="d-flex">
              <img
                src={getPlatformIcon(data.platform)}
                alt={`entity-${data.platform}`}
                style={imgStyle}
              />
              <span
                style={{
                  color: "rgb(39, 51, 51)",
                  fontSize: "13px",
                  fontWeight: "500",
                  lineHeight: 1,
                  fontFamily: "Roboto",
                }}
              >
                {data.name}
              </span>
            </div>
            <span
              style={{
                color: "rgb(81, 94, 95)",
                fontSize: "12px",
                fontFamily: "Roboto",
              }}
            >
              {PLATFORM_LABEL_OPTION[data.platform]}
            </span>
          </div>
        </div>
      </components.Option>
    );
  };

  return (
    <div
      className={`nk-demo-panel nk-demo-panel-2x toggle-slide toggle-slide-right toggle-screen-any p-0 ${
        style.contentItem
      } ${isShowSideBar ? "content-active" : ""}  }`}
      style={{ zIndex: 1 }}
    >
      <div className={`${style.contentItem}`} onClick={handleOpenSideBar}></div>
      <SimpleBar style={{ height: "100%" }} className={`${style.SimpleBar}`}>
        <div
          className={`d-flex align-items-center justify-content-between ${style.headerSideBar}`}
        >
          <div className="">Sửa và Đăng lại</div>
          <img
            src={CloseIcon}
            alt={CloseIcon}
            className={`rounded ${style.channelContentHeaderImage}`}
            onClick={handleOpenSideBar}
            style={{
              //   opacity: item.status === STATUS_REMOVE ? "60%" : "100%",
              marginTop: "4px",
            }}
          />
        </div>
        <div
          className="d-flex w-full"
          style={{
            backgroundColor: "#ECFDF5",
            padding: "12px 20px",
          }}
        >
          <img
            src={FlowerSVG}
            alt={FlowerSVG}
            className={`rounded ${style.channelContentHeaderImage}`}
            style={{
              //   opacity: item.status === STATUS_REMOVE ? "60%" : "100%",
              marginTop: "4px",
            }}
          />
          <div className="ml-2">
            Để tôn trọng tác giả bài đăng, bạn hãy vui lòng ghi thêm nguồn vào
            bài đăng của mình khi đăng lại nội dung nhé
          </div>
        </div>
        <div className="p-2">
          <div
            className="d-flex mt-1 align-items-center p-2"
            style={{
              border: "1px solid #DFE2E9",
            }}
          >
            {/* <img
              src={FrofleSVG}
              alt={FrofleSVG}
              className={`rounded ${style.channelContentHeaderImage}`}
              style={{
                //   opacity: item.status === STATUS_REMOVE ? "60%" : "100%",
                marginTop: "4px",
              }}
            />
            <span className="ml-2 font-bold">Chọn kênh đăng</span> */}
            <FormGroup>
              <div
                className=" justify-content-between"
                style={{ width: "300px" }}
              >
                <Controller
                  name="channel"
                  control={control}
                  rules={{
                    required: isEditPost ? false : "Bạn cần chọn kênh",
                  }}
                  render={({ field: { onBlur, onChange } }) => (
                    <RSelect
                      closeMenuOnSelect={false}
                      components={{
                        MultiValueLabel,
                        GroupHeading,
                        Option: CustomOption,
                      }}
                      value={channels}
                      isMulti
                      getOptionLabel={(v) => v.name}
                      getOptionValue={(v) => v._id}
                      onChange={(e) => {
                        onChange(e);
                        onChangeChannels(e);
                      }}
                      options={
                        mediaItems.find((c) => c.type === MEDIA_TYPE_IMAGE)
                          ? channelOptions.map((e) =>
                              e.label === "create_post.channel_5"
                                ? {
                                    ...e,
                                    options: e.options.map(
                                      (channel) =>
                                        (channel = {
                                          ...channel,
                                          isDisabled: true,
                                        })
                                    ),
                                  }
                                : { ...e, options: e.options }
                            )
                          : channelOptions
                      }
                      styles={{ ...selectPlatformStyle, minWidth: "300px" }}
                      onBlur={onBlur}
                      placeholder="Chọn kênh đăng"
                    />
                  )}
                />
              </div>
              {errors?.channel && (
                <FormText color="danger">{errors.channel?.message}</FormText>
              )}
            </FormGroup>
          </div>
          <FormGroup className="mt-1">
            <div className="nk-reply-form" style={{ margin: 0 }}>
              <div className="">
                <div className="">
                  <ContentOriginal
                    // onKeyUp={(e) => {
                    //   let position = e.target.selectionStart;
                    //   if (positionContent.current !== position)
                    //     positionContent.current = position;
                    // }}
                    // onClick={(e) => {
                    //   let position = e.target.selectionStart;
                    //   if (positionContent.current !== position)
                    //     positionContent.current = position;
                    // }}
                    className="form-control"
                    placeholder="Nhập nội dung"
                    value={itemSelected && itemSelected.message}
                    // value={displayContent}
                    // onChange={(e, newValue, newPlainTextValue) => {
                    //   let position = newPlainTextValue.length;
                    //   if (positionContent.current !== position)
                    //     positionContent.current = position;
                    //   onChangeContent(
                    //     PLATFORM_LABEL[PLATFORM_ORIGINAL],
                    //     newPlainTextValue,
                    //     e.target.value
                    //   );
                    // }}
                  />
                </div>
                <div className="nk-reply-form-tools justify-start">
                  <div
                    id="i"
                    className="data-item p-0 mr-2"
                    // onClick={
                    //   mediaItems.find((c) => c.type === MEDIA_TYPE_VIDEO) ||
                    //   channels.find(
                    //     (c) => c.platform === PLATFORM_YOUTUBE
                    //   ) ||
                    //   channels.find((c) => c.platform === PLATFORM_TIKTOK)
                    //     ? null
                    //     : openImage
                    // }
                  >
                    <span className="data-more z-0 d-flex justify-around">
                      {/* {mediaItems.find(
                    (c) => c.type === MEDIA_TYPE_VIDEO
                  ) ||
                  channels.find(
                    (c) => c.platform === PLATFORM_YOUTUBE
                  ) ||
                  channels.find(
                    (c) => c.platform === PLATFORM_TIKTOK
                  ) ? ( */}
                      <img src={iconNotImage} alt="image" />
                      {/* ) : (
                    <img src={iconImage} alt="image" />
                  )} */}
                      <UncontrolledTooltip
                        autohide={false}
                        placement={"top"}
                        target={"i"}
                      >
                        Chọn ảnh
                      </UncontrolledTooltip>
                    </span>
                  </div>
                  <div
                    id="v"
                    className="data-item p-0 mr-2 border-b-0"
                    // onClick={
                    //   mediaItems.find(
                    //     (c) =>
                    //       c.type === MEDIA_TYPE_IMAGE ||
                    //       c.type === MEDIA_TYPE_VIDEO
                    //   )
                    //     ? null
                    //     : openVideo
                    // }
                    style={{ borderBottom: "0" }}
                  >
                    <span className="data-more z-0 d-flex justify-around">
                      {/* {mediaItems.find(
                    (c) =>
                      c.type === MEDIA_TYPE_IMAGE ||
                      c.type === MEDIA_TYPE_VIDEO
                  ) ? ( */}
                      <img src={iconNotVideo} alt="Video" />
                      {/* ) : (
                    <img src={iconVideo} alt="Video" />
                  )} */}
                      <UncontrolledTooltip
                        autohide={false}
                        placement={"top"}
                        target={"v"}
                      >
                        Chọn video
                      </UncontrolledTooltip>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </FormGroup>
          <FormGroup>
            <div className="d-flex">
              <div
                className={`${
                  // openIcon ?
                  style.btnloadIconActive
                  // : style.btnloadIcon
                } align-items-stretch d-flex`}
                //   onClick={() => {
                //     setOpenIcon(!openIcon);
                //     if (!openIcon) setOpenSuggestText(false);
                //   }}
              >
                <div className="m-auto">
                  <img
                    alt=""
                    src={icon}
                    //   style={
                    //     openIcon
                    //       ? { marginRight: "9px", color: "#6A2C62" }
                    //       : { marginRight: "9px" }
                    //   }
                  />
                  <span> Icon ngẫu nhiên</span>
                </div>
              </div>
              <div
                className={`${
                  // openSuggestText
                  //   ?
                  style.btnloadTextActive
                  //   : style.btnloadText
                } align-items-stretch d-flex`}
                //   onClick={() => {
                //     setOpenSuggestText(!openSuggestText);
                //     if (!openSuggestText) setOpenIcon(false);
                //   }}
              >
                <div className="m-auto">
                  <img
                    alt=""
                    src={word}
                    style={
                      // openIcon
                      //   ?
                      { marginRight: "9px", color: "#6A2C62" }
                      //   : { marginRight: "9px" }
                    }
                  />
                  <span>Nội dung ngẫu nhiên</span>
                </div>
              </div>
            </div>
          </FormGroup>
          <FormGroup>
            <PostMode
              toggleSaveScheduled={toggleSaveScheduled}
              toggleSaveAndPost={toggleSaveAndPost}
              showScheduleSelectTime={showScheduleSelectTime}
              handleOptionChangePost={handleOptionChangePost}
              handleScheduledAt={handleScheduledAt}
              handleChangeTimeStart={handleChangeTimeStart}
              handleChangeTimeSmart={handleChangeTimeSmart}
              handleChangeTimeEnd={handleChangeTimeEnd}
              scheduledAt={scheduledAt}
              timeStart={timeStart}
              timeSo9Smart={timeSo9Smart}
              timeFinish={timeFinish}
              selectedOptionPost={selectedOptionPost}
            />
          </FormGroup>
        </div>
        <div className={`${style.bottomSideBar} p-2`}>
          <Button className={`${style.btnSidebar}`}>Đăng lại</Button>
        </div>
      </SimpleBar>
    </div>
  );
};

export default SideBarContent;
