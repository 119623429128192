import React, {useEffect} from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { Card } from "reactstrap";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  PreviewCard,
} from "../../../../components/Component";
import { useTranslation } from "react-i18next";
import {GetSupport} from "../../../../libs/icon";
import PaymentMethodInfo from "../PaymentMethodInfo";
import {useSelector} from "react-redux";
import {formatNumber} from "../../../../utils/Utils";
import {useHistory} from "react-router";
import {ToastContainer} from "react-toastify";

const ConfirmPayment = ({ ...props }) => {
  const { t } = useTranslation()
  const history = useHistory();
  const billCreating = useSelector((state) => state.project.currentBill);

  useEffect(() => {
    if(Object.keys(billCreating).length === 0) {
      history.push('/admin/subscription/list');
    }
  },[])
  return (
    <React.Fragment>
      <Head title={t("project_payment")} />
      <Content>
        {
          Object.keys(billCreating).length !== 0 && (
            <div className="content-page wide-sm m-auto">
              <BlockHead size="lg" wide="xs" className="mx-auto">
                <BlockHeadContent className="text-center">
                  <div className="nk-block-head-sub">
                    {/* <span>FAQs</span> */}
                  </div>
                  <BlockTitle tag="h2" className="fw-normal">
                    {t("project_guildline_payment")}
                  </BlockTitle>
                  <BlockDes>
                    <span className={`text-base`}>{t('project_money_to_payment')}: </span>
                    <span className={`font-semibold text-base`}>{formatNumber(billCreating?.info.total_amount || 0)}</span>.&nbsp;
                    <span className={`text-base`}>{t('project_choose_one_method')}</span>
                  </BlockDes>
                </BlockHeadContent>
              </BlockHead>
              <Block>
                <Card className="card">
                  <PaymentMethodInfo currentBill={billCreating}  />
                </Card>
              </Block>
              <Block>
                <PreviewCard>
                  <div className="align-center flex-wrap flex-md-nowrap g-4">
                    <div className="nk-block-image w-120px flex-shrink-0">
                      <GetSupport/>
                    </div>
                    <BlockContent>
                      <div className="nk-block-content-head px-lg-4">
                        <h5>{t('project_help_payment')}</h5>
                        <p className="text-soft">
                          {t('project_help_payment_content')}
                        </p>
                      </div>
                    </BlockContent>
                    <BlockContent className="flex-shrink-0">
                      <Button color="white" outline className="btn-outline-primary btn-dim">
                        Get Support Now
                      </Button>
                    </BlockContent>
                  </div>
                </PreviewCard>
              </Block>
            </div>
          )
        }
        <ToastContainer />
      </Content>
    </React.Fragment>
  );
};

export default ConfirmPayment;
