import { createSlice } from "@reduxjs/toolkit";
import { uniqBy } from "lodash";
import {
  AUTO_POST,
  MANUAL_POST,
  WAREHOUSE_FB,
  WAREHOUSE_IG,
} from "../utils/Utils";

const managePostInitial = {
  [AUTO_POST]: 1,
  [MANUAL_POST]: 1,
  [WAREHOUSE_FB]: 1,
  [WAREHOUSE_IG]: 1,
};

const managePostDataInitial = {
  [AUTO_POST]: false,
  [MANUAL_POST]: false,
  [WAREHOUSE_FB]: false,
  [WAREHOUSE_IG]: false,
};

const initialState = {
  postTab: managePostInitial,
  postDataTab: managePostDataInitial,
  numberPost: {
    [AUTO_POST]: 0,
    [MANUAL_POST]: 0,
    [WAREHOUSE_FB]: 0,
    [WAREHOUSE_IG]: 0,
  },
  idPostMutilDelete: [],
};

export const managePostSlice = createSlice({
  name: "managePostTab",
  initialState,
  reducers: {
    setPostTabPage(state, action) {
      const { currentPage, tab } = action.payload;
      state.postTab = {
        ...state.postTab,
        [tab]: currentPage,
      };
    },
    setPostDataTab(state, action) {
      const { isNoPost, tab } = action.payload;
      state.postDataTab = {
        ...state.postDataTab,
        [tab]: isNoPost,
      };
    },
    setNumberPost(state, action) {
      const { total, tab } = action.payload;
      if (state.numberPost[tab] !== total) {
        state.numberPost = {
          ...state.numberPost,
          [tab]: total,
        };
      }
    },
    setIdPostMutilDelete(state, action) {
      state.idPostMutilDelete = action.payload;
    },
    resetPostTabPage(state) {
      state.postTab = managePostInitial;
    },
  },
});

export const {
  setPostTabPage,
  resetPostTabPage,
  setPostDataTab,
  setNumberPost,
  setIdPostMutilDelete,
} = managePostSlice.actions;

export default managePostSlice.reducer;
