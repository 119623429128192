import React, { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { toastError, toastSuccess } from "../../../../libs/toasts";
import { MEDIA_TYPE_IMAGE } from "../../../utils/Utils";
import {
  createCommentTask,
  deleteCommentTask,
  deleteTask,
  detailTask,
  getListChilds,
  getSharePost,
  listCommentTask,
  listGroupTask,
  listSectionTask,
  markCompleteTask,
  storeChildrenTask,
  updateTask,
  uploadImageBulk,
} from "../../../../api/kanban";
import { LoadingItem } from "../../Dashboard_v2/CommonItem/LoadingItem";
import DetailHeader from "./DetailHeader";
import DetailList from "./DetailList";
import CommentList from "./CommentList";
import CommentInput from "./CommentInput";
import SubcriberDropdown from "./SubcriberDropdown";
import "../styles.css";

const KanbanDetail = ({
  isOpenDetail,
  setIsOpenDetail,
  card,
  initialData,
  setInitialData,
  activeGroup,
  staffs,
  handleDeleteItem,
  handleComplete,
  handleTaskDetail,
  setTitleTask,
  setDescTask,
  setEndedTask,
  setStartedTask,
  startedTask,
  endedTask,
  setCntTask,
  setCntTaskComplete,
  setCntCmt,
  setOwnerTask,
  isEndedBeforeToday,
  completed,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const { t } = useTranslation();

  const simpleBarRef = useRef();
  const commentContainerRef = useRef(null);

  const [listDetail, setListDetail] = useState([]);
  const [listChild, setListChild] = useState([]);
  const [listComment, setListComment] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(null);
  const [dueDate, setDueDate] = useState(
    endedTask ? new Date(endedTask * 1000) : null
  );
  const [startDate, setStartDate] = useState(
    startedTask ? new Date(startedTask * 1000) : null
  );
  const [description, setDescription] = useState(
    t("task_management.detail.add_description")
  );
  const [isCreateChild, setIsCreateChild] = useState(false);
  const [isCreateContent, setIsCreateContent] = useState(false);
  const [childTitle, setChildTitle] = useState("");
  const [linkContent, setLinkContent] = useState("");
  const [selectedAttachment, setSelectedAttachment] = useState([]);
  const [isLoadingNewComment, setIsLoadingNewComment] = useState(false);
  const [commentImage, setCommentImage] = useState(null);
  const [commentFile, setCommentFile] = useState(null);
  const [commentText, setCommentText] = useState("");
  const [selectedSubItems, setSelectedSubItems] = useState([]);
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const [isLoadingChild, setIsLoadingChild] = useState(false);
  const [parentTitle, setParentTitle] = useState(null);
  const [optionGroup, setOptionGroup] = useState(null);
  const [sectionOptions, setSectionOptions] = useState(() => {
    const option = [];
    for (let [key, value] of Object.entries(initialData.columns)) {
      option.push({
        value: key,
        label: value.name,
      });
    }
    return option;
  });
  const [newSection, setNewSection] = useState(null);
  const [checkLoadMoreCommentFirst, setCheckLoadMoreCommentFirst] =
    useState(false);
  const [checkLoadMoreSubtaskFirst, setCheckLoadMoreSubtaskFirst] =
    useState(false);

  const getDetailTask = async () => {
    return await detailTask(userInfo.last_project_active, card);
  };

  let { data: dataDetail, isLoading: isLoadingDetail } = useQuery(
    [userInfo.last_project_active, card],
    getDetailTask
  );

  const detailData = dataDetail?.data;

  const getGroupData = async () => {
    return await listGroupTask(userInfo.last_project_active);
  };

  useEffect(() => {
    if (optionGroup) {
      const fetchSectionData = async () => {
        try {
          const sectionData = await listSectionTask(
            userInfo.last_project_active,
            optionGroup
          );
          const sections = sectionData?.data?.map((item) => ({
            value: item._id,
            label: item.name,
          }));

          setSectionOptions(sections);

          handleUpdateTask({
            group_id: optionGroup,
            section_id: sections[0].value,
          });

          setNewSection(sections[0].value);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchSectionData();
    }
  }, [optionGroup]);

  let { data: dataGroup, isLoading: isLoadingGroup } = useQuery(
    [userInfo.last_project_active],
    getGroupData,
    {
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  const groups = dataGroup?.data;

  useEffect(() => {
    setIsLoading(true);
    setListDetail(detailData);
    // if (detailData?.ended_at) {
    //   setDueDate(new Date(detailData?.ended_at * 1000));
    // }
    // setStartDate(new Date(detailData?.started_at * 1000));
    setIsLoading(false);
  }, [detailData]);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setIsLoading(true);

        const [childTaskData, commentData] = await Promise.all([
          getListChilds(userInfo.last_project_active, card, 1),
          listCommentTask(userInfo.last_project_active, card, 1),
        ]);
        setListChild(childTaskData.data);
        if (childTaskData.data.data.length >= 15) {
          setCheckLoadMoreSubtaskFirst(true);
        }

        if (commentData.data && commentData.data.length > 0) {
          const reversedData = [...commentData.data].reverse();
          setListComment(reversedData);
          if (reversedData.length >= 20) {
            setCheckLoadMoreCommentFirst(true);
          }
        } else {
          setListComment([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllData();
  }, [card]);

  useEffect(() => {
    setTitle(listDetail?.title);
    if (listDetail?.description && listDetail?.description !== "<p><br></p>") {
      setDescription(listDetail?.description);
    }
    setSelectedAttachment(listDetail?.attachments);
    setSelectedSubItems(listDetail?.subcriber_ids);
  }, [listDetail]);

  const groupOptions = groups?.map((item) => ({
    value: item._id,
    label: item.name,
  }));

  const staffOptions = staffs?.map((item) => ({
    value: item.id,
    label: item.name,
    avatar: item.avatar,
  }));

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpenDetail &&
        simpleBarRef.current &&
        !simpleBarRef.current.contains(event.target)
      ) {
        if (title !== listDetail.title) {
          setTitleTask(title);
          const data = {
            title,
          };
          handleUpdateTask(data);
          setInitialData((prev) => ({
            ...prev,
            tasks: {
              ...prev.tasks,
              [card]: {
                ...prev.tasks[card],
                title: title,
              },
            },
          }));
        }

        if (optionGroup || newSection) {
          setOptionGroup(null);
          setNewSection(null);
          setSectionOptions(() => {
            const option = [];
            for (let [key, value] of Object.entries(initialData.columns)) {
              option.push({
                value: key,
                label: value.name,
              });
            }
            return option;
          });

          const updatedColumns = {
            ...initialData.columns,
            [listDetail.section_id]: {
              ...initialData.columns[listDetail.section_id],
              cnt_task: initialData.columns[listDetail.section_id].cnt_task - 1,
              tasks: initialData.columns[listDetail.section_id].tasks.filter(
                (taskId) => taskId !== listDetail._id
              ),
            },
          };

          const updatedTasks = { ...initialData.tasks };
          delete updatedTasks[listDetail._id];

          setInitialData({
            ...initialData,
            columns: updatedColumns,
            tasks: updatedTasks,
          });
        }

        setIsOpenDetail(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    isOpenDetail,
    setIsOpenDetail,
    optionGroup,
    newSection,
    title,
    description,
  ]);

  const handleUpdateTask = async (data) => {
    if (listDetail?._id) {
      try {
        await updateTask(userInfo.last_project_active, listDetail._id, data);
      } catch (error) {
        console.error("Error update task:", error);
      }
    }
  };

  const handleTitleBlur = () => {
    setIsEditing(false);
    const data = {
      title,
    };
    handleUpdateTask(data);
    if (!listDetail.parent_id) {
      setTitleTask(title);
    }

    setListDetail((prev) => ({
      ...prev,
      title: title,
    }));

    setInitialData((prev) => ({
      ...prev,
      tasks: {
        ...prev.tasks,
        [card]: {
          ...prev.tasks[card],
          title: title,
        },
      },
    }));
  };

  const handleSelectOwner = (item) => {
    handleUpdateTask({ owner_id: item.value });
    setListDetail((prev) => ({
      ...prev,
      owner_id: item.value,
      owner: {
        ...item,
      },
    }));
    setOwnerTask(staffOptions.find((e) => e.value === item.value));
  };

  useEffect(() => {
    const timestampEnded = Math.round(moment(startDate).valueOf() / 1000);
    if (timestampEnded !== startedTask) {
      handleUpdateTask({ started_at: timestampEnded });
      if (!listDetail?.parent_id) {
        setStartedTask(timestampEnded);
      }
    }
  }, [startDate]);

  useEffect(() => {
    const timestampEnded = Math.round(moment(dueDate).valueOf() / 1000);

    if (timestampEnded !== endedTask && !isNaN(timestampEnded)) {
      handleUpdateTask({ ended_at: timestampEnded });
      if (!listDetail?.parent_id) {
        setEndedTask(timestampEnded);
      }
    }
  }, [dueDate]);

  const handleSaveDescription = (item) => {
    setDescription(item);
    if (!listDetail.parent_id) {
      setDescTask(item);
    }
  };

  const createChild = async (owner, ended_at) => {
    const data = {
      title: childTitle,
      group_id: activeGroup._id,
      section_id: listDetail?.section_id,
      owner_id: userInfo.id,
      started_at: Math.floor(Date.now() / 1000),
    };

    if (ended_at !== undefined) {
      data.ended_at = ended_at;
    }

    if (owner !== undefined) {
      data.owner_id = owner.value;
    }

    if (childTitle) {
      try {
        setIsLoadingChild(true);
        const res = await storeChildrenTask(
          userInfo.last_project_active,
          listDetail._id,
          data
        );

        const newChild = res.data;

        if (owner !== undefined) {
          newChild.owner = owner;
        }

        if (res?.status) {
          setCntTask(listDetail?.cnt_children_task + 1);
          setListDetail((prev) => ({
            ...prev,
            cnt_children_task: prev.cnt_children_task + 1,
          }));
          setChildTitle("");
          setListChild((prev) => ({
            ...prev,
            data: [...prev.data, newChild],
          }));
          setIsLoadingChild(false);
          toastSuccess(
            t("task_management.detail.toast.create_subtask_success")
          );
          setIsCreateChild(false);
        }
      } catch (error) {
        setIsLoadingChild(false);
        toastError(t("task_management.detail.toast.create_subtask_failed"));
        console.error("Error store task:", error);
      }
    }
  };

  const handleCreateLinkContent = async (event) => {
    if (event.key === "Enter") {
      setIsLoadingContent(true);
      const pattern = /list-post-share\/([a-f0-9]+)\?project_id=([a-f0-9]+)/;
      const match = linkContent.match(pattern);

      if (match) {
        const listPostShareId = match[1];
        const projectId = match[2];
        try {
          const res = await getSharePost(projectId, listPostShareId);
          if (res.data) {
            const listIds = res.data
              .filter((item) => item._id)
              .map((item) => item._id);

            const updateIds = [...listDetail?.post_ids, ...listIds];

            setListDetail((prev) => ({
              ...prev,
              post_ids: [...prev.post_ids, ...listIds],
              posts: [...prev.posts, ...res.data],
            }));

            handleUpdateTask({ post_ids: updateIds });
            setLinkContent("");
            setIsLoadingContent(false);
            toastSuccess(t("create_post_success"));
            setIsCreateContent(!isCreateContent);
          }
        } catch (error) {
          setIsLoadingContent(false);
          setLinkContent("");
          toastSuccess(t("create_post_failed"));
          console.error("Error store task:", error);
        }
      } else {
        toastError(t("task_management.detail.toast.invalid_link"));
        setLinkContent("");
        setIsLoadingContent(false);
      }
    }
  };

  const handleRemoveContent = (item) => {
    const updatePostIds = listDetail?.post_ids?.filter((id) => id !== item._id);
    const updatePosts = listDetail?.posts?.filter(
      (post) => post._id !== item._id
    );
    setListDetail((prev) => ({
      ...prev,
      post_ids: updatePostIds,
      posts: updatePosts,
    }));
    handleUpdateTask({ post_ids: updatePostIds });
    toastSuccess(t("task_management.detail.toast.remove_post_success"));
  };

  const handleCompleteChild = async (item) => {
    const projectId = userInfo.last_project_active;

    const isItemComplete = listChild?.data?.find(
      (child) => child._id === item._id
    )?.is_complete;

    isItemComplete
      ? setCntTaskComplete(listDetail?.cnt_children_task_complete - 1)
      : setCntTaskComplete(listDetail?.cnt_children_task_complete + 1);

    setListDetail((prev) => {
      const updatedCounter = item.is_complete
        ? prev.cnt_children_task_complete - 1
        : prev.cnt_children_task_complete + 1;

      return {
        ...prev,
        cnt_children_task_complete: updatedCounter,
      };
    });

    setListChild((prev) => ({
      ...prev,
      data: prev.data.map((child) =>
        child._id === item._id
          ? { ...child, is_complete: !child.is_complete }
          : child
      ),
    }));

    const message = item.is_complete
      ? t("task_management.toast.progress_updated")
      : t("task_management.toast.completed");
    toastSuccess(message);

    try {
      await markCompleteTask(projectId, item._id, !item.is_complete);
    } catch (error) {
      console.error(error);
      toastError(t("task_management.toast.error"));
    }
  };

  const handleDeleteChild = async (item) => {
    try {
      setIsLoading(true);
      const res = await deleteTask(userInfo.last_project_active, item._id);
      if (res?.status) {
        handleTaskDetail(item.parent_id);
        if (item.is_complete) {
          setCntTaskComplete((prev) => prev - 1);
        }
        setCntTask((prev) => prev - 1);
        setIsLoading(false);
        toastSuccess(t("task_management.toast.delete_task_success"));
      }
    } catch (error) {
      toastError(t("task_management.toast.delete_task_failed"));
      console.log(error);
    }
  };

  const uploadImageFile = async (files) => {
    const data = new FormData();

    if (Array.isArray(files)) {
      files.forEach((file) => {
        data.append("images", file);
      });
    } else {
      data.append("images", files);
    }

    data.append("projectId", userInfo.last_project_active);
    data.append("folder", "task");

    const response = await uploadImageBulk(data);
    const newMediaArray = response.data.map((item) => ({
      type: MEDIA_TYPE_IMAGE,
      name: item.value.name,
      url: item.value.url,
    }));

    return newMediaArray;
  };

  const handleAttachmentSelect = async (event) => {
    const files = event.target.files;
    const invalidFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();

      reader.onloadend = async () => {
        if (file.type && file.type.startsWith("image/")) {
          const newMediaArray = await uploadImageFile([file]);
          newMediaArray.forEach((newMedia) => {
            setListDetail((prev) => ({
              ...prev,
              attachments: [...prev.attachments, newMedia],
            }));
            handleUpdateTask({
              attachments: [...selectedAttachment, newMedia],
            });
            setSelectedAttachment((prev) => [...prev, newMedia]);
          });
        } else {
          invalidFiles.push(file.name);
        }
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }

    if (invalidFiles.length > 0) {
      alert(
        `Please select only image files. Invalid files: ${invalidFiles.join(
          ", "
        )}`
      );
    }
  };

  // useEffect(() => {
  //   if (commentContainerRef.current) {
  //     commentContainerRef.current.scrollTop =
  //       commentContainerRef.current.scrollHeight;
  //   }
  // }, [listComment]);

  const handleCommentSubmit = async () => {
    if (commentText.trim() === "") return;

    let newMedia;

    if (commentFile) {
      newMedia = await uploadImageFile(commentFile);
    }

    const data = {
      content: commentText,
      attachments: newMedia,
    };

    setCommentText("");
    setCommentFile(null);
    setCommentImage(null);

    try {
      setIsLoadingNewComment(true);
      const res = await createCommentTask(
        userInfo.last_project_active,
        listDetail._id,
        data
      );
      if (res?.status && res?.data) {
        const newComment = { ...res.data };

        const staff = staffs.find((staff) => staff.id === newComment.user_id);
        if (staff) {
          const { id, name, avatar } = staff;
          newComment.user = { id, name, avatar };
        }

        setListDetail((prev) => ({
          ...prev,
          cnt_comment: prev.cnt_comment + 1,
        }));
        setListComment([...listComment, newComment]);
      }
      if (!listDetail.parent_id) {
        setCntCmt(listDetail.cnt_comment + 1);
      }
      setCommentImage(null);
      setIsLoadingNewComment(false);
    } catch (error) {
      console.error("Error creating comment:", error);
    }
  };

  const handleCommentDelete = async (item) => {
    setIsLoadingNewComment(true);
    try {
      const res = await deleteCommentTask(
        userInfo.last_project_active,
        listDetail._id,
        item._id
      );
      if (res?.status) {
        setListComment(
          listComment.filter((comment) => comment._id !== item._id)
        );
        setListDetail((prev) => ({
          ...prev,
          cnt_comment: prev.cnt_comment - 1,
        }));
        setCntCmt(listDetail.cnt_comment - 1);
        setIsLoadingNewComment(false);
      }
    } catch (error) {
      setIsLoadingNewComment(false);
      console.error("Error deleting comment:", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setCommentImage(e.target.result);
        setCommentFile(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleMarkChild = async () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfor"));
    const projectId = userInfo.last_project_active;

    try {
      const res = await markCompleteTask(
        projectId,
        card,
        !listDetail.is_complete
      );
      if (res?.status) {
        const message = listDetail.is_complete
          ? t("task_management.toast.completed")
          : t("task_management.toast.completed");
        toastSuccess(message);
      }
    } catch (error) {
      console.error(error);
      toastError(t("task_management.toast.error"));
    }
  };

  const handleSubItemClick = (item) => {
    let updatedItems;

    if (selectedSubItems.includes(item.value)) {
      updatedItems = selectedSubItems.filter((value) => value !== item.value);
    } else {
      updatedItems = [...selectedSubItems, item.value];
    }

    setSelectedSubItems(updatedItems);

    const updatedSubcribers = staffOptions.filter((option) =>
      updatedItems.includes(option.value)
    );

    handleUpdateTask({ subcriber_ids: [...updatedItems] });
    setListDetail((prev) => ({
      ...prev,
      subcriber_ids: updatedItems,
      subcribers: updatedSubcribers,
    }));
  };

  const handleSelectSection = (item) => {
    if (optionGroup) {
      setNewSection(item.value);
      handleUpdateTask({ group_id: optionGroup, section_id: item.value });
    } else {
      handleUpdateTask({ section_id: item.value });
    }

    if (listDetail.section_id !== item.value) {
      const updatedColumns = {
        ...initialData.columns,
        [listDetail.section_id]: {
          ...initialData.columns[listDetail.section_id],
          cnt_task: initialData.columns[listDetail.section_id].cnt_task - 1,
          tasks: initialData.columns[listDetail.section_id].tasks.filter(
            (taskId) => taskId !== listDetail._id
          ),
        },
        [item.value]: {
          ...initialData.columns[item.value],
          cnt_task: initialData.columns[item.value].cnt_task + 1,
          tasks: [...initialData.columns[item.value].tasks, listDetail._id],
        },
      };

      const updatedTasks = {
        ...initialData.tasks,
        [listDetail._id]: {
          ...initialData.tasks[listDetail._id],
          section_id: item.value,
        },
      };

      setInitialData({
        ...initialData,
        columns: updatedColumns,
        tasks: updatedTasks,
      });
    }
  };

  const handleSelectGroup = (item) => {
    setListDetail((prev) => ({
      ...prev,
      group_id: item.value,
    }));
    setOptionGroup(item.value);
  };

  return (
    <div
      className={`nk-demo-panel nk-demo-panel-2x toggle-slide toggle-slide-right toggle-screen-any p-0 `}
      style={{
        width: "550px",
        transition: "all 0.3s ease-in-out",
        transform: isOpenDetail ? "translateX(0)" : "translateX(100%)",
      }}
      ref={simpleBarRef}
    >
      <SimpleBar className="h-100" style={{ maxHeight: "100%" }}>
        {isLoadingDetail || isLoading || isLoadingGroup ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingItem />
          </div>
        ) : (
          <div className="d-flex flex-column h-100 justify-content-between overflow-hidden">
            <DetailHeader
              listDetail={listDetail}
              setListDetail={setListDetail}
              setIsOpenDetail={setIsOpenDetail}
              handleComplete={handleComplete}
              handleDeleteItem={handleDeleteItem}
              handleMarkChild={handleMarkChild}
              handleDeleteChild={handleDeleteChild}
            />

            <div className="body-detail" ref={commentContainerRef}>
              <DetailList
                groupOptions={groupOptions}
                setTitleTask={setTitleTask}
                listDetail={listDetail}
                handleTaskDetail={handleTaskDetail}
                isEditing={isEditing}
                parentTitle={parentTitle}
                setParentTitle={setParentTitle}
                setIsEditing={setIsEditing}
                title={title}
                setTitle={setTitle}
                handleTitleBlur={handleTitleBlur}
                staffOptions={staffOptions}
                handleSelectOwner={handleSelectOwner}
                sectionOptions={sectionOptions}
                newSection={newSection}
                handleSelectGroup={handleSelectGroup}
                handleSelectSection={handleSelectSection}
                startDate={startDate}
                setStartDate={setStartDate}
                dueDate={dueDate}
                setDueDate={setDueDate}
                isEndedBeforeToday={isEndedBeforeToday}
                completed={completed}
                description={description}
                handleSaveDescription={handleSaveDescription}
                handleUpdateTask={handleUpdateTask}
                setIsCreateChild={setIsCreateChild}
                isCreateChild={isCreateChild}
                listChild={listChild}
                handleCompleteChild={handleCompleteChild}
                createChild={createChild}
                childTitle={childTitle}
                setChildTitle={setChildTitle}
                isLoadingChild={isLoadingChild}
                isCreateContent={isCreateContent}
                setIsCreateContent={setIsCreateContent}
                linkContent={linkContent}
                setLinkContent={setLinkContent}
                handleCreateLinkContent={handleCreateLinkContent}
                handleRemoveContent={handleRemoveContent}
                isLoadingContent={isLoadingContent}
                handleAttachmentSelect={handleAttachmentSelect}
                selectedAttachment={selectedAttachment}
                setSelectedAttachment={setSelectedAttachment}
                setListDetail={setListDetail}
                setListChild={setListChild}
                checkLoadMoreSubtaskFirst={checkLoadMoreSubtaskFirst}
              />

              <CommentList
                listDetail={listDetail}
                listComment={listComment}
                setListComment={setListComment}
                handleCommentDelete={handleCommentDelete}
                isLoadingNewComment={isLoadingNewComment}
                checkHaveLoadMoreFirst={checkLoadMoreCommentFirst}
              />
            </div>

            <div className="footer-detail">
              {commentImage && (
                <div
                  className="relative image-comment-wrap"
                  style={{
                    height: "100%",
                  }}
                >
                  <img
                    src={commentImage}
                    alt="comment"
                    style={{
                      cursor: "pointer",
                      width: "60px",
                      height: "60px",
                      borderRadius: "8px",
                    }}
                  />
                  <div
                    className="closecmt-img action-icons"
                    onClick={() => setCommentImage(null)}
                  >
                    x
                  </div>
                </div>
              )}

              <CommentInput
                handleCommentSubmit={handleCommentSubmit}
                handleFileChange={handleFileChange}
                commentText={commentText}
                setCommentText={setCommentText}
              />

              <SubcriberDropdown
                listDetail={listDetail}
                staffOptions={staffOptions}
                selectedSubItems={selectedSubItems}
                handleSubItemClick={handleSubItemClick}
              />
            </div>
          </div>
        )}
      </SimpleBar>
    </div>
  );
};

export default KanbanDetail;
