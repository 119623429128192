import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DefaultImage from "../../images/avatar/no-image.png";
import { getUnsettingChannels } from "../../../api/channel";

export default function ModalAlertCalendar({ openModalAlertCalendar, setOpenModalAlertCalendar }) {
  const { t } = useTranslation();
  const userInfor = JSON.parse(localStorage.getItem('userInfor'));
  const [items, setItems] = useState([]);
  const [isFinishedPage, setFinishedPage] = useState(false);
  const [page, setPage] = useState(1);
  const containerRef = useRef(null);

  const loadUnsettingChannels = async () => {
    if (!userInfor.last_project_active) return [];
    const { data } = await getUnsettingChannels(userInfor.last_project_active, { params: { page: page } });
    if (data?.data && data?.data.length === 0) {
      setFinishedPage(true);
    }
    setItems((prevItems) => [...prevItems, ...data?.data || []]);
  }
  const toggleAlertCalendar = () => {
    setOpenModalAlertCalendar(!openModalAlertCalendar);
  }

  useEffect(() => {
    // Fetch next set of items when page number changes
    loadUnsettingChannels();
  }, [page]);

  const handleScroll = () => {
    // Get container element
    const container = containerRef.current;

    // Check if user has scrolled to the bottom of the container
    if (
      (container.scrollTop + container.clientHeight >= container.scrollHeight) && !isFinishedPage
    ) {
      // Fetch next set of items
      setPage((prevPage) => prevPage + 1);
    }
  };
  return (
    <Modal size={"lg"} isOpen={openModalAlertCalendar} toggle={toggleAlertCalendar}>
      <ModalHeader>
        9 Calendar: Có trang chưa được cài đặt lịch
      </ModalHeader>
      <ModalBody >
        <div className={`nk-modal`} ref={containerRef} onScroll={handleScroll} style={{ height: "400px", overflowY: "auto" }}>
          <p>Để có thể sử dụng tính năng này, toàn bộ các trang bạn đang chọn sẽ phải được cài đặt khung giờ đăng trước khi thực hiện thao tác đăng bài. Vui lòng <a href={"#"} target={"_blank"}>cài đặt 9 Calendar</a>  cho các trang sau đây:</p>
          
          {
            items.map((item, index) => {
              return (
                <div className="mb-1" key={index}>
                  <img src={item?.picture || DefaultImage} style={{ width: "2rem", marginRight: ".5rem", borderRadius: "4px" }} alt="fanpage" />
                  <span className="font-weight-bold">{item?.name || ""}</span>
                </div>
              );
            })
          }
        </div>
      </ModalBody>
      <ModalFooter>
        <Button size={"sm"} color={"primary"} style={{ color: "#FFFFFF", padding: "9px 28px", backgroundColor: "#FD1F6D" }} onClick={toggleAlertCalendar}>Đồng ý</Button>
      </ModalFooter>
    </Modal>
  )
}