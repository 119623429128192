import React from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { Col, Card, CardBody, CardHeader } from "reactstrap";

import { LoadingItem } from "../../CommonItem/LoadingItem";

const ImpressionStatistic = (props) => {
  const { t } = useTranslation();
  const { dataChart, isLoading, labels } = props;

  const options = {
    chart: {
      height: 345,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#45cb85d9", "#4b38b3", "#007bff"],

    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 4, 3],
      curve: "straight",
      dashArray: [0, 8, 5],
    },
    series: dataChart || [],
    markers: {
      size: 0,

      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: labels,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  return (
    <React.Fragment>
      {/* <Row> */}
      <Col>
        <Card className="card-height-100">
          <CardHeader className="border-0 align-items-center d-flex bg-slate-50">
            <h4
              className="card-title mb-0 flex-grow-1"
              style={{ fontSize: "16px" }}
            >
              {t("overview.content_spread.summary.impressions")}
            </h4>
          </CardHeader>

          <CardBody className="p-0 pb-2">
            <div className="w-100">
              {isLoading ? (
                <LoadingItem />
              ) : (
                <ReactApexChart
                  dir="ltr"
                  options={options}
                  series={dataChart}
                  type="line"
                  height="345"
                  className="apex-charts"
                />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
      {/* </Row> */}
    </React.Fragment>
  );
};

export default ImpressionStatistic;
