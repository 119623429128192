import React from "react";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../../../components/Component";
import style from "../index.module.scss";

import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getAudienceYT,
  getEngageAndReachFacebook,
  getPostAndEngageFacebook,
  getTimeGoldFacebook,
  getTopPostFacebook,
} from "../../../../api/dashboard";

import YTDemographic from "./Audience/Youtube/YTDemographic";
import YTFollow from "./Audience/Youtube/YTFollow";

import _ from "lodash";

const Audience = (props) => {
  const { range, tab, channel, setLoading, setLoadingChannel, subTab, dashboardList, setDashboardList, updateTabDashboard, dashboardInit, isChangeFilter, setChangeFilter } = props;
  const userInfor = JSON.parse(localStorage.getItem('userInfor'));
  const projectId = userInfor.last_project_active;
  const [dataAudience, setDataAndience] = useState({});

  const getAPIYoutube = (data) => {
    getAudienceYT(data).then((response) => {
      if (response.status) {
        const _data = response.data;
        setDataAndience(_data);
      }
      setLoadingChannel(false);
      setLoading(false);
    });
  };

  const handleGetDataStatistic = () => {
    if (tab === "3" && subTab === "4") {
      if (projectId) {
        const data = {
          params: {
            project_id: projectId,
            time: `${range} days`,
            channel_ids: channel,
          },
        };
        getAPIYoutube(data);
      }
    }
    setChangeFilter();
  }

  useEffect(() => {
    if (projectId && dashboardInit !== 0) {
      if (isChangeFilter) return;
      updateTabDashboard({
        platform: tab,
        subTab,
        channel,
        range
      });
      handleGetDataStatistic();
    }
  }, [channel, range]);

  useEffect(() => {
    if (projectId) {
      const subTabItem = dashboardList[tab].find(item => item.subTab === subTab);
      if (!subTabItem) { // nếu chưa có thì thêm vào redux và call api
        setDashboardList({
          platform: tab,
          subTab,
          channel,
          range
        });
        handleGetDataStatistic();
      } else if (!_.isEqual(subTabItem.channel, channel) || subTabItem.range !== range) {// nếu có item, check xem channel và range có thay đổi không, nếu thay đổi thì call api cập nhật channel và range
        updateTabDashboard({
          platform: tab,
          subTab,
          channel,
          range
        });
        handleGetDataStatistic();
      }
    }
  }, [tab, subTab]);

  return (
    <>
      {tab === "3" && (
        <>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className={`${style.tittle}`}>
                  Nhân khẩu học
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <YTDemographic data={dataAudience} />
          </Block>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className={`${style.tittle}`}>
                  Lượt theo dõi
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <YTFollow data={dataAudience} />
          </Block>
        </>
      )}
    </>
  );
};

export default Audience;
