import {API} from "../libs/client";
import axios from "axios"
import Cookies from "js-cookie"
import { getDomainForCookie } from "../utils/Utils";
export default class Auth {
    static saveToken(token){
        window.localStorage.setItem('accessToken', token);
        const domain = getDomainForCookie();
        Cookies.set('accessToken', token, {domain, expires: 1})
    }

    static removeToken() {
        window.localStorage.removeItem('accessToken');
    }

    static async refreshToken(){
        if(!window.localStorage.getItem('accessToken')){
            return false;
        }
        // this.removeToken();
        try {
            const refreshRes = await axios.post(process.env.REACT_APP_URL_API + "api/v1/refresh",{},{
                headers: {'Authorization': 'Bearer ' + window.localStorage.getItem('accessToken')},
            });
            const newToken = refreshRes?.data?.data?.access_token;
            if(!newToken) {
                this.removeToken();
                window.location.href = '/auth-login';
            }
            this.saveToken(newToken);
            return true;
        }
        catch (e) {
            this.removeToken();
            window.location.href = '/auth-login';
        }
    }
}
