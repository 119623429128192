import React, {useCallback, useState} from 'react';
import {useTranslation} from "react-i18next";
import style from "../../pages/auth/Auth.module.scss"
import {Link} from "react-router-dom";
import {Icon} from "../../components/Component";

export function TextFieldAuth ({
   current,
   title,
   placeholder = '',
   errors,
   register,
   required = false,
   setValue,
   field,
  readonly = false
})
{
    const handleChange = useCallback((e) => {
        //
        let value = e.target.value;
        setValue(field, (value || ''))
    }, [current])

    return (
        <>
            <div className="form-label-group">
                <label className="form-label" htmlFor="default-01">
                    { title } {required && <span className={`${style.RequiredStar}`}>(*)</span>}
                </label>
            </div>
            <div className="form-control-wrap">
                <input
                    type="text"
                    placeholder={ placeholder }
                    readOnly={readonly}
                    className="form-control-lg form-control"
                    {...register(field)}
                    onChange={(e) => {
                        handleChange(e)
                    }}
                />                {errors && <span className="invalid">{errors.message}</span>}
            </div>
        </>
    )
}

export function PasswordFieldAuth ({
   current,
   title,
   placeholder = '',
   errors,
   register,
   setValue,
   field,
   isLoginPage,
   minLength = 0
}) {
    const {t} = useTranslation();
    const [passState, setPassState] = useState(false);
    const handleChange = useCallback((e) => {
        //
        let value = e.target.value;
        setValue(field, (value || ''))
    }, [current])

    return (
        <>
            <div className="form-label-group">
                <label className="form-label" htmlFor="password">
                    {title} <span className={`${style.RequiredStar}`}>(*)</span>
                </label>
                {isLoginPage && <Link className="link link-sm" to="auth-reset">
                    {t('auth_forgot_password')}
                </Link>}
            </div>
            <div className="form-control-wrap">
                <a
                    href="#password"
                    onClick={(ev) => {
                        ev.preventDefault();
                        setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                </a>
                <input
                    type={passState ? "text" : "password"}
                    {...register(field)}
                    placeholder={placeholder}
                    className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                    onChange={(e) => {
                        handleChange(e);
                    }}
                />
                {errors && <span className="invalid">{errors.message}</span>}
            </div>

        </>
    )
}

