import { Img } from "../../../../components/Component";
import { getPlatformIcon } from "../../../utils/Utils";
import { UncontrolledTooltip } from "reactstrap";
import { CheckedBox } from "../../../../components/Component";
import { Link } from "react-router-dom";
import { RiErrorWarningFill } from "@remixicon/react";

const PlatformChannelItem = ({ handleCheckboxChange, item }) => {
  const renderAvatar = (item) => {
    const styleShape = item?.platform === 1 ? "rounded" : "rounded-circle";
    return (
      <div
        className={`${styleShape} bg-light position-relative`}
        style={{ height: "32px", width: "32px" }}
      >
        <Img
          src={item.picture}
          className={styleShape}
          style={{ height: "32px", width: "32px" }}
        />
        <Img
          className={`${styleShape} position-absolute border border-1 border-white`}
          style={{ bottom: "0px", right: "0px" }}
          src={getPlatformIcon(item.platform)}
          height={"14px"}
          width={`14px`}
        />
      </div>
    );
  };
  return (
    <div
      className="d-flex align-items-center justify-content-between p-2.5 pl-4 cursor-pointer hover:bg-[#F4F5F6]"
      onClick={handleCheckboxChange.bind(null, item)}
    >
      <div className="d-flex align-items-center">
        {renderAvatar(item)}
        <div className="relative ml-2.5">
          <p
            className="mb-0 text-body fs-12 text-break text-truncate"
            style={{ maxWidth: "160px" }}
            id={"platform-name-" + item._id}
          >
            {item?.name}
          </p>
          {item?.name.length > 23 && (
            <UncontrolledTooltip
              target={"platform-name-" + item._id}
              style={{
                backgroundColor: "#ffffff",
                boxShadow:
                  "0 2px 8px 0 rgba(0, 0, 0, 0.3), 0 4px 20px rgba(0, 0, 0, 0.2)",
              }}
            >
              <p className="text-body fs-12">
                {item?.name}
              </p>
            </UncontrolledTooltip>
          )}
          <p className="mb-0 text-muted fs-10">Page</p>
        </div>
      </div>

      {!item.isError ? (
        <CheckedBox checked={!!item?.check} />
      ) : (
        <Link to={"/admin/project/connect-channel"}>
          <RiErrorWarningFill
            className="text-danger cursor-pointer"
            size={16}
            id={`errorchannel-${item._id}`}
          />
          <UncontrolledTooltip target={`errorchannel-${item._id}`}>
            Reconnect this channel to continue posting
          </UncontrolledTooltip>
        </Link>
      )}
    </div>
  );
};

export default PlatformChannelItem;
