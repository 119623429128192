import { RiCalendarView, RiCompassFill, RiDashboardLine, RiEditLine, RiFolderChart2Fill, RiFolderChartLine, RiGroupFill, RiGroupLine, RiLineChartLine, RiLinksLine, RiMegaphoneFill, RiMegaphoneLine, RiMessage2Line, RiPlaneFill, RiPlantFill, RiPlugFill, RiSettings3Line, RiSpyLine, RiTodoFill, RiWallet3Line } from "@remixicon/react";

const menu = {
  common: [
    {
      icon: <RiDashboardLine size={15} />,
      text: "menu.dashboard",
      link: "/news",
      active: false,
    },
    {
      icon: <RiEditLine size={15} />,
      text: "menu.compose.title",
      active: false,
      subMenu: [
        {
          text: "menu.compose.create",
          link: "/posts/compose",
        },
        {
          text: "menu.compose.bulk_create",
          link: "/posts/bulk",
        },
      ],
    },
    {
      icon: <RiCalendarView size={15} />,
      text: "menu.schedule_monitor.title",
      active: false,
      subMenu: [
        {
          text: "menu.schedule_monitor.list_view",
          link: "/manage_posts",
        },
        {
          text: "menu.schedule_monitor.calendar_view",
          link: "/calender/postChannel",
        },
      ],
    },
    {
      icon: <RiMessage2Line size={15} />,
      conversation: true,
      text: "menu.engagement",
      link: "/engagement",
    },
    {
      icon: <RiLineChartLine size={15} />,
      text: "menu.analytics",
      active: false,
      link: "/home/dashboard/overview",
    },
    {
      icon: <RiMegaphoneLine size={15} />,
      text: "menu.evergreen_content.title",
      active: false,
      subMenu: [
        {
          text: "menu.evergreen_content.content_library",
          link: "/warehouse",
        },
        {
          text: "menu.evergreen_content.evergreen_campaign",
          link: "/campaign",
        },
      ],
    },
    {
      icon: <RiSpyLine size={15} />,
      text: "menu.discover",
      link: "/research-competitor",
      active: false,
    },
  ],
  business: [
    // {
    //   icon: <RiPlantFill size={15} />,
    //   text: "menu.content_planner",
    //   link: "/",
    //   active: false
    // },
    {
      icon: <RiTodoFill size={15}/>,
      text: "menu.task_manager",
      link: "/task-management",
      active: false
    },
    {
      icon: <RiFolderChart2Fill size={15} />,
      text: "menu.guru_report.title",
      active: false,
      subMenu: [
        {
          text: "menu.guru_report.content",
          link: "/home/dashboard/overview-enterprise/content",
        },
        {
          text: "menu.guru_report.staff",
          link: "/home/dashboard/overview-enterprise/staff",
        }
      ],
    },
  ],
  setting: [
    {
      icon: <RiPlugFill size={15} />,
      text: "menu.connect_channels",
      link: "/admin/project/connect-channel",
    },
    {
      icon: <RiWallet3Line size={15} />,
      text: "menu.billing",
      link: "/admin/project/pricing",
    },
    {
      icon: <RiLinksLine size={15} />,
      text: "menu.integrations",
      link: "/integration",
    },
    {
      icon: <RiSettings3Line size={15} />,
      text: "menu.others.title",
      active: false,
      subMenu: [
        {
          text: "menu.others.auto_calendar",
          link: "/posts/settingchannel-list",
        },
        {
          text: "menu.others.group_channel",
          link: "/admin/project/group-channel",
        },
        // {
        //   text: "menu.others.topic",
        //   link: "/",
        // }
      ],
    },
    {
      icon: <RiGroupLine size={15} />,
      text: "menu.member",
      link: "/admin/project/users",
    },
    {
      icon: <RiLinksLine size={15} />,
      text: "menu.refer_earn",
      link: "/admin/iris",
    },
    {
      heading: "menu.workspace",
      hasIcon: true,
      link: "/admin/project/list",
    },
  ]
}
export default menu;
