import React, { useEffect, useMemo, useState } from "react";
import styles from "../PostDetail.module.scss";
import { Modal, ModalBody, Table } from "reactstrap";
import Tag from "../Tag";
import { getShareListPost } from "../../../../api/postDetail";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import IconPlatform from "./IconPlatform";
import PostDetail from "..";
import moment from "moment";
import LogoSo9 from "../../../../assets/images/icon/white-so9-logo.svg";
import { IconPost } from "../../../../libs/icon";
import { ARR_POST_STATUS_POST } from "../../../constants/posts";
import { useTranslation } from "react-i18next";

const ListSharePostDetail = () => {
  const { t } = useTranslation();
  const [dataListPostShare, setdataListPostShare] = useState();
  const { shareId } = useParams();
  const [projectId, setProjectId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [dataPostDetail, setDataPostDetail] = useState();

  useEffect(() => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramSearchProjectId = urlParams.get('project_id');
    if ((shareId, paramSearchProjectId)) {
      setProjectId(paramSearchProjectId);
      fetchApi(shareId, paramSearchProjectId);
    }
  }, [shareId]);

  const fetchApi = (shareId, projectId) => {
    getShareListPost(shareId, projectId)
      .then((res) => {
        if (res.status) {
          setdataListPostShare(res?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOpenModal = ({ item }) => {
    setOpenModal(!openModal);
    setDataPostDetail(item);
  };

  const ListIconPlatform = ({ listPlatforms }) => {
    if (listPlatforms?.length < 3) {
      return (
        <div className={`${styles.listplatform} ${styles.itemPostDetail}`}>
          {listPlatforms?.map((curPlatform, _) => (
            <IconPlatform item={curPlatform.channel} />
          ))}
        </div>
      );
    } else {
      return (
        <div className={`${styles.listplatform} ${styles.itemPostDetail}`}>
          {listPlatforms?.map((curPlatform, index) => {
            if (index < 3) {
              return <IconPlatform item={curPlatform.channel} />;
            } else if (index === 3) {
              return (
                <IconPlatform
                  item={curPlatform.channel}
                  isHide={listPlatforms.length - 4 <= 0 ? false : true}
                  number={listPlatforms.length - 4}
                />
              );
            } else return null;
          })}
        </div>
      );
    }
  };

  return (
    <div className={styles.listPost}>
      <header>
        <img src={LogoSo9} alt="" />
      </header>
      <main>
        <div className={styles.containerMain}>
          <Table className={styles.table}>
            <thead>
              <tr class="container">
                <th class="col-sm-5">{t("post_share.column.post")}</th>
                <th class="col-sm-2">{t("post_share.column.channel")}</th>
                <th class="col-sm-1">{t("post_share.column.status")}</th>
                <th class="col-sm-1">{t("post_share.column.post_type")}</th>
                <th class="col-sm-1">{t("post_share.column.create_by")}</th>
                <th class="col-sm-2">{t("post_share.column.create_at")}</th>
              </tr>
            </thead>
            <tbody>
              {dataListPostShare?.map((curPostDetail, index) => {
                const result = [];
                const channel = shareId && projectId && curPostDetail?.channel;
                for (const platform in channel) {
                  if (channel.hasOwnProperty(platform)) {
                    channel[platform].forEach((post) => {
                      post.platform = platform;
                      result.push(post);
                    });
                  }
                }
                return (
                  <tr
                    onClick={() => {
                      handleOpenModal({ item: curPostDetail });
                    }}
                  >
                    <td className={styles.post}>
                      <img
                        src={
                          curPostDetail?.media[0]?.thumb
                            ? curPostDetail?.media[0]?.thumb
                            : curPostDetail?.media[0]?.url
                        }
                        alt=""
                        className={styles.img}
                      ></img>
                      <p className={styles.title}>{curPostDetail?.content}</p>
                    </td>
                    <td>
                      <ListIconPlatform listPlatforms={result} />
                    </td>
                    <td>
                      <div
                        className={`${styles.status} ${styles.itemPostDetail}`}
                      >
                        <IconPost status={result[index]?.status} />
                        {<p>{t(ARR_POST_STATUS_POST[result[index]?.status])}</p>}
                      </div>
                    </td>
                    <td>
                      <div
                        className={`${styles.postType} ${styles.itemPostDetail}`}
                      >
                        <p>Short Video</p>
                      </div>
                    </td>
                    <td>
                      <div
                        className={`${styles.itemPostDetail} ${styles.createAt}`}
                      >
                        {curPostDetail?.user?.name}
                      </div>
                    </td>
                    <td>
                      <div
                        className={`${styles.itemPostDetail} ${styles.createAt}`}
                      >
                        {moment(curPostDetail?.created_at).format(
                          "HH:mm, Do MMMM YYYY"
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </main>
      <Modal
        size="lg"
        style={{
          maxWidth: "1300px",
          width: "100%",
        }}
        isOpen={openModal}
        toggle={handleOpenModal}
      >
        <ModalBody style={{ padding: "0px", borderRadius: "8px" }}>
          <PostDetail
            closeModal={handleOpenModal}
            dataPostDetail={dataPostDetail}
            setOpenModalPostDetail={setOpenModal}
            setListPostByFetch={setdataListPostShare}
            listPostByFetch={dataListPostShare}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ListSharePostDetail;
