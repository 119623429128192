import { Link } from "react-router-dom";
import { Button, Modal, ModalBody } from "reactstrap";
import { Icon } from "../../../components/Component";

const ModalWarning = ({ ...props }) => {
  const { modalWarning, toggleWarning, warningText, warningTitle, buttonTitle, btnQuit } = props;
  return (
    <Modal isOpen={modalWarning} toggle={toggleWarning}>
      <ModalBody className="modal-body-lg">
        <div className="nk-modal">
          <header className="text-center">
            <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-info bg-warning"></Icon>
            <h4 className="nk-modal-title">{warningTitle || "Cảnh báo"}</h4>
          </header>
          <div className="nk-modal-text">
            <div className="caption-text" dangerouslySetInnerHTML={{ __html: warningText || "" }}>
            </div>
          </div>
          <div className="nk-modal-action text-center">
            {
              btnQuit && <Button style={{ marginRight: 24, backgroundColor: "rgba(224, 224, 224, 1)", border: "none", color: "rgba(19, 34, 67, 1)" }} size="lg" className="btn-mw" onClick={() => { window.location.reload() }}>
                Thoát
              </Button>
            }
            <Button style={{ backgroundColor: "rgba(33, 150, 83, 1)", border: "none" }} size="lg" className="btn-mw" onClick={toggleWarning}>
              {buttonTitle ? buttonTitle : "OK"}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalWarning;
