import { useState, useRef, useEffect } from "react"
import SimpleBar from "simplebar-react";

import SPIN_ICON from "../../../images/post/spin-content.svg";
import Content from "./Content";
import { Img } from "../../../../components/Component";
import { RiCloseLine } from "@remixicon/react";
import { useTranslation } from "react-i18next";
const SpinContent = ({ isOpen, setOpen, content, setContent }) => {
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();
    const simpleBarRef = useRef();
    const toggleRef = useRef();


    const setIsOpenCavas = (value) => {
        setOpen(value);
    }
    const openCavas = () => {
        if (!isLoading) {
            if (!isOpen) {
                setIsOpenCavas(true);
            }
        }
    }

    const closeCavas = () => {
        setIsOpenCavas(false);
    }


    useEffect(() => {

        const handleMouseDown = (event) => {
            if (simpleBarRef.current && simpleBarRef.current.contains(event.target)) {
                openCavas()
            } else {
                if (toggleRef.current && toggleRef.current.contains(event.target)) {
                    openCavas()
                } else {
                    if (event.target.closest('#SRLLightbox')) {
                        return
                    } else {
                        setIsOpenCavas(false);
                    }
                }
            }
        };
        document.addEventListener('mousedown', handleMouseDown);
        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
        };

    }, []);

    const onAppendContent = (key) => {
        const updateContent = content + key;
        setContent(updateContent);
    }


    return (
        <>
            <div
                className={`nk-demo-panel nk-demo-panel-2x toggle-slide toggle-slide-right toggle-screen-any p-0  ${isOpen ? "content-active" : ""}`}
                style={{ maxWidth: "420px", minWidth: "420px" }}
                ref={simpleBarRef}
            >
                <SimpleBar className="h-100" style={{ maxHeight: "100%" }}
                >
                    <div className="d-flex justify-content-between align-items-center bg-light pt-3 pb-3 pl-2 pr-2">
                        <div
                            className="d-flex align-items-center justify-content-center cursor-pointer">
                            <Img src={SPIN_ICON} height="20px"></Img>
                            <p className="ms-2 text-norrmal mb-0 fs-18 text-body text-nowrap">
                                {t('store_post.spin_content.title')}
                            </p>
                        </div>

                        <RiCloseLine size={24} className="fs-24 text-muted cursor-pointer" onClick={() => setOpen(!isOpen)}></RiCloseLine>
                    </div>

                    <div className="pr-2 pl-2 pt-2">
                        <p className="fs-13 text-body mb-2">
                            {t('store_post.spin_content.description')}
                        </p>
                        <Content isPost={true} defaultMode="1" onAppendContent={onAppendContent}></Content>
                    </div>
                </SimpleBar>
            </div>

        </>
    )
}




export default SpinContent
