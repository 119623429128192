import { useRef } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Img } from "../../../../components/Component";
import { Row, Col } from "reactstrap";

const EditAltModal = ({ images, updateImages, isShow, onClose }) => {
  const values = useRef([]);

  const onSave = () => {
    let newData = images;
    let index = 0;
    for (const element of images) {
      newData[index].caption = values.current[index];
      index += 1;
    }
    updateImages(newData);
    onClose();
  };

  return (
    <Modal isOpen={isShow} toggle={onClose} centered size="lg">
      <ModalHeader toggle={onClose}>Add Media Description/ALT Text</ModalHeader>
      <ModalBody style={{ height: "fit-content" }} className="d-flex flex-col">
        <Row style={{ marginBottom: 12 }}>
          <Col md="2" className="text-bold text-lg">Ảnh</Col>
          <Col md="10" className="text-bold text-lg">Mô tả</Col>
        </Row>
        {images.map((item, index) => (
          <Row className="mt-4">
            <Col md="2">
              <Img
                src={`${URL.createObjectURL(item)}`}
                height={"60px"}
                width="60px"
              />
            </Col>
            <Col md="10">
              <input
                defaultValue={item?.caption}
                onChange={(e) => (values.current[index] = e.target.value)}
                type="textarea"
                className="text-left justify-start"
                style={{ height: 60, width: "90%", paddingLeft: 12 }}
              />
            </Col>
          </Row>
        ))}
      </ModalBody>

      <ModalFooter className="d-flex justify-content-end">
        <Button onClick={onSave}>Save</Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditAltModal;
