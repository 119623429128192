import { useEffect, useRef, useState } from "react";
// import { Img, PaginationCustom } from "../../../../components/Component";
import { useTranslation } from "react-i18next";
// import { Card } from "reactstrap";

// import { WPCharts } from "../../../../components/partials/charts/analytics/AnalyticsCharts";
// import { numberWithCommas } from "../utils/utils";
// import NO_DATA_DEFAULT from "../image/no_data_default.svg";

import WidgetsCharts from "../../Chart/WidgetsCharts";
import NO_DATA_DEFAULT from "../../Image/no_data_default.svg";
import { renderNameField } from "../../helper";
import { Tooltip } from "reactstrap";
import { getFrequencyFacebook } from "../../../../../api/dashboard";
import { numberWithCommas } from "../../../DashboardNew/utils/utils";
import React from "react";
import PaginationCustom from "../../../../../components/pagination/PaginationCustom";
import { Img } from "../../../../../components/Component";

const FrequencyTable = ({ from, to, projectId }) => {
  const { t } = useTranslation();
  const [frequencyData, setFrequencyData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [isShowNodata, setIsShowNodata] = useState(-1);
  const [tooltipOpen, setOpen] = useState("");
  const toggle = (id) => {
    if (tooltipOpen === id) {
      setOpen("id");
    } else {
      setOpen(id);
    }
  };
  const headerFrequency = {
    channel: t("channel_analytics_table.fan_page"),
    like: t("channel_analytics_table.like"),
    newLikes: t("channel_analytics_table.new_likes"),
    impr: t("channel_analytics_table.impression"),
    impression_uniq: t("channel_analytics_table.reach"),
    clicks: t("channel_analytics_table.clicks"),
    engage: t("channel_analytics_table.engage"),
    frequency: t("channel_analytics_table.frequency"),
    "": "",
  };

  const handleHoverNoData = (id) => {
    setIsShowNodata(id);
  };

  const fetchData = async () => {
    const data = {
      params: {
        project_id: projectId,
        from,
        to,
        page: currentPage,
      },
    };

    const frequencyFacebookRes = await getFrequencyFacebook(data);
    if (frequencyFacebookRes) {
      const frequencyFacebook = frequencyFacebookRes.data;
      setFrequencyData(frequencyFacebook);

      setListData(
        frequencyFacebook?.data?.map((item) => ({
          channel_id: item?.channel?.channel_id,
          channel: item?.channel?.name,
          like: item?.page_fans,
          newLikes: item?.page_fan_adds,
          impr: item?.page_impressions,
          impression_uniq: item?.page_impressions_unique,
          clicks: item?.page_views_total,
          engage: item?.page_post_engagements,
          frequency: item?.frequency,
          image: item?.channel?.image,
          dataSetImpression: item?.dataSetImpression,
          platform_id: item?.channel?.platform_id
        }))
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, [from, to, currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const elementRef = useRef(null);
  const [isResponsive, setIsResponsive] = useState(false);
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width } = entry.contentRect;
        // Perform actions based on the size changes

        if (width < 1200) {
          setIsResponsive(true);
        } else {
          setIsResponsive(false);
        }
      }
    });

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const openChannelNewTab = (channel) => {
    if(channel?.platform_id){
      window.open(`https://www.facebook.com/${channel?.platform_id}`, "_blank")
    }
  }

  return (
    <>
      {/* <Card className="card-bordered"> */}
      <div className="card-inner-group" ref={elementRef}>
        <div className="card-inner p-0 table-responsive">
          <table className="table table-hover">
            <thead
              style={{
                maxHeight: "60px",
                margin: 0,
                padding: 0,
              }}
            >
              <tr>
                {headerFrequency &&
                  Object.values(headerFrequency).map((field, index) => {
                    const styleTh =
                      field === t("channel_analytics_table.fan_page")
                        ? {
                          paddingBottom: "20px",
                        }
                        : {
                          paddingBottom: "20px",
                          textAlign: "end",
                          minWidth: field !== "" ? "100px" : undefined,
                        };
                    return (
                      <th scope="col" style={styleTh} key={index}>
                        {" "}
                        <span
                          className="frequencyTableHeader"
                          style={{ fontSize: "14px" }}
                        >
                          {field}
                        </span>
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {listData.length > 0 &&
                listData.map((item, index) => {
                  return (
                    <tr key={index}>
                      {Object.keys(headerFrequency).map((fk, keyIndex) => {
                        if (fk === "channel") {
                          return (
                            <td
                              key={`${index}-${keyIndex}`}
                              style={{
                                minWidth: "150px",
                                maxWidth: "200px",
                              }}
                            >
                              <div
                                key={index}
                                style={{ display: "flex", maxHeight: "32px" }}
                              >
                                <Img
                                  src={item?.image}
                                  alt=""
                                  width="32px"
                                  height="32px"
                                  style={{ borderRadius: "15%" }}
                                />
                                <div
                                  style={{
                                    paddingTop: "8px",
                                    paddingLeft: "10px",
                                    width: "100%",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    display: "block",
                                    textOverflow: "ellipsis",
                                    cursor: isResponsive
                                      ? "pointer"
                                      : undefined,
                                  }}
                                  className="tb-sub"
                                  id={`${fk + keyIndex + item["channel_id"]}`}
                                  onClick={() => openChannelNewTab(item)}
                                >
                                  {item[`${fk}`]}
                                </div>
                                {isResponsive && (
                                  <Tooltip
                                    placement="bottom"
                                    isOpen={tooltipOpen === item["channel_id"]}
                                    target={`${fk + keyIndex + item["channel_id"]
                                      }`}
                                    toggle={() => toggle(item["channel_id"])}
                                    style={{ width: "100%", textAlign: "left" }}
                                  >
                                    <div>{item[`${fk}`]}</div>
                                  </Tooltip>
                                )}
                              </div>
                            </td>
                          );
                        }
                        if (fk === "impr") {
                          const dataChart = [
                            {
                              name: "",
                              data: item?.dataSetImpression?.map((d) =>
                                numberWithCommas(d.y)
                              ),
                            },
                          ];

                          const labelX = item?.dataSetImpression?.map(
                            (d) => d.x
                          );

                          return (
                            <td
                              key={`${index}-${keyIndex}`}
                              style={{
                                // height: "60px",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <div
                                style={{
                                  width: "150px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div className="traffic-channel-ck ml-auto">
                                  {item[`${fk}`] > 0 ? (
                                    <WidgetsCharts
                                      labelX={labelX}
                                      seriesData={dataChart}
                                      chartsColor="#45CB85"
                                      isInTable={true}
                                    />
                                  ) : (
                                    <div
                                      style={{
                                        paddingTop: "12px",
                                        position: "relative",
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                      onMouseEnter={() =>
                                        handleHoverNoData(index)
                                      }
                                      onMouseLeave={() => handleHoverNoData(-1)}
                                    >
                                      <div style={{ maxHeight: "32px" }}>
                                        <Img src={NO_DATA_DEFAULT} alt="" />
                                      </div>
                                      {isShowNodata === index && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            justifyContent: "center",
                                            top: "10px",
                                            paddingLeft: "10px",
                                          }}
                                        >
                                          No data
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>

                                <span
                                  className="tb-sub"
                                  style={{
                                    paddingTop: "10px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {numberWithCommas(item[`${fk}`])}
                                </span>
                              </div>
                            </td>
                          );
                        }
                        return (
                          <td
                            key={`${index}-${keyIndex}`}
                          // style={{ height: "50px" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <span
                                style={{
                                  paddingTop: "10px",
                                }}
                                className="tb-sub"
                              >
                                {numberWithCommas(item[`${fk}`])}
                              </span>
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="card-inner mobile-paginate">
            {listData?.length > 0 && frequencyData ? (
              frequencyData?.last_page > 1 && (
                <PaginationCustom
                  pageSize={frequencyData?.per_page || 9}
                  totalCount={frequencyData?.total || 0}
                  siblingCount={1}
                  onPageChange={handlePageChange}
                  currentPage={frequencyData?.current_page}
                />
              )
            ) : (
              <div className="text-center">
                <span className="text-silent">No page found</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FrequencyTable;
