import React from "react";
import { useTranslation } from "react-i18next";

import BlockDataCommonItem from "../../CommonItem/BlockDataCommonItem";

import ImpressionSource from "./ImpressionSource";
import { ChartType } from "../../constants";

const OverviewContent = ({ data, isLoading }) => {
  const { t } = useTranslation();
  const dataChartView = [
    {
      name: "",
      data: data?.view?.dataSet?.map((d) => d?.y),
    },
  ];

  const dataChartAvgWatchTime = [
    {
      name: "",
      data: data?.avg_watch_time?.dataSet?.map((d) => d?.y),
    },
  ];

  const dataChartEngagements = [
    {
      name: "",
      data: data?.engagements?.dataSet?.map((d) => d?.y),
    },
  ];

  return (
    <>
      {" "}
      <BlockDataCommonItem
        isLoading={isLoading}
        title={t("views")}
        dataValue={data?.view?.value}
        hasChart={true}
        chartType={ChartType.LINE}
        dataChart={dataChartView}
        classIcon="ri-eye-fill"
        colorIcon="#4B38B3"
        tooltipLabel={t("general_overview.tooltip.content.tiktok.views")}
        tooltipId={"about_views"}
      />
      <BlockDataCommonItem
        isLoading={isLoading}
        title={t("comment")}
        dataValue={data?.engagements?.value}
        hasChart={true}
        chartType={ChartType.LINE}
        dataChart={dataChartEngagements}
        classIcon="ri-message-2-line"
        colorIcon="#4B38B3"
        tooltipLabel={t("general_overview.tooltip.content.tiktok.engagements")}
        tooltipId={"about_engagements"}
      />
      <BlockDataCommonItem
        isLoading={isLoading}
        title={t("avg_watch_time")}
        hasChart={true}
        dataValue={data?.avg_watch_time?.value}
        chartType={ChartType.LINE}
        dataChart={dataChartAvgWatchTime}
        classIcon="ri-timer-fill"
        colorIcon="#0AB39C"
        tooltipLabel={t(
          "general_overview.tooltip.content.tiktok.avg_watch_time"
        )}
        tooltipId={"about_avg_watch_time"}
      />
      <ImpressionSource data={data?.impression_sources} isLoading={isLoading} />
      {/* <BlockDataCommonItem
        isLoading={isLoading}
        title="Impression source"
        Col1="Source"
        Col2="Count"
        data={data?.impression_sources}
        tooltipLabel={`Nguồn lượt xem: 
        For You: Nguồn xem video từ mục "Dành cho bạn".
        Follow: Nguồn xem video từ mục "Theo dõi".
        Hashtag: Nguồn xem video từ các trang hashtag.
        Sound: Nguồn xem video từ trang gợi ý các video đang dùng bản nhạc cùng với video của bạn.
        Personal Profile: Nguồn xem video từ trang cá nhân. 
        Search: Nguồn xem video từ trang tìm kiếm.`}
        tooltipId={`PageReach_4`}
      /> */}
    </>
  );
};

export default OverviewContent;
