import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import style from "./index.module.scss";
import { Badge, Button, Col, Row, Spinner } from "reactstrap";
import { MEDIA_TYPE_IMAGE, MEDIA_TYPE_VIDEO } from "../../utils/Utils";
import { Icon, Img, PaginationCustom } from "../../../components/Component";
import ModalEdit from "./ModalEdit";
import { deleteWarehouse, getListWarehouse } from "../../../api/warehouse";
import { toastSuccess } from "../../../libs/toasts";

const Container = (props) => {
  const {
    data,
    toggleModal,
    modal,
    loading,
    refetch,
    toggleSuccess,
    setMessage,
    optionTopic,
    refetchTopic,
    setSelectIds,
    selectIds,
    isClearCheckbox,
    setIsClearCheckbox,
  } = props;
  const [ldDel, setLdDel] = useState({});
  const [row, setRow] = useState({});
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  // Khởi tạo một state để lưu trạng thái của các checkbox
  const [checkedItems, setCheckedItems] = useState({});
  const [mobileView, setMobileView] = useState(null);
  const viewChange = () => {
    if (window.innerWidth < 1600) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };
  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []);

  const handleDelete = async (item, index) => {
    setLdDel((ids) => ({
      ...ids,
      [index]: true,
    }));
    try {
      const res = await deleteWarehouse(userInfo.last_project_active, item._id);
      if (res?.status) {
        toastSuccess("Xóa bài đăng thành công");
        refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLdDel((ids) => ({
        ...ids,
        [index]: false,
      }));
    }
  };

  const handleCheckboxChange = (item) => {
    // Sao chép trạng thái hiện tại của các checkbox
    const newCheckedItems = { ...checkedItems };

    // Đảo ngược trạng thái của checkbox cho phần tử được chọn
    newCheckedItems[item._id] = !newCheckedItems[item._id];

    // Cập nhật trạng thái của các checkbox
    setCheckedItems(newCheckedItems);
    if (selectIds.includes(item._id)) {
      // Nếu đã chọn và đang bỏ chọn, loại bỏ item._id khỏi mảng selectIds
      setSelectIds((prevSelectIds) =>
        prevSelectIds.filter((id) => id !== item._id)
      );
    } else {
      // Nếu chưa chọn và đang chọn, thêm item._id vào mảng selectIds
      setSelectIds((prevSelectIds) => [...prevSelectIds, item._id]);
    }
  };

  useEffect(() => {
    if (isClearCheckbox) {
      setCheckedItems({});
      setIsClearCheckbox(false);
    }
  }, [isClearCheckbox]);

  return (
    <Fragment>
      <table className={`table `}>
        <thead style={{ background: "#EBEAED" }}>
          <tr>
            <th
              style={{ width: "3%" }}
              className={`${style.headPost} py-2 text-center`}
            >
              {/* <div className="custom-control custom-control-sm custom-checkbox notext">
                        <input
                          type="checkbox"
                          className="custom-control-input form-control"
                          id="uid_1"
                          // onChange={(e) => selectorCheck(e)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="uid_1"
                        ></label>
                      </div> */}
            </th>
            <th style={{ width: "10%" }} className={`${style.headPost} py-2`}>
              Mã bài
            </th>
            <th style={{ width: "15%" }} className={`${style.headPost} py-2`}>
              Media
            </th>
            <th style={{ width: "10%" }} className={`${style.headPost} py-2`}>
              Trạng thái
            </th>
            <th style={{ width: "10%" }} className={`${style.headPost} py-2 `}>
              Loại
            </th>
            <th style={{ width: "10%" }} className={`${style.headPost} py-2 `}>
              Chủ đề
            </th>
            <th style={{ width: "22%" }} className={`${style.headPost} py-2 `}>
              Biến thể nội dung
            </th>
            <th
              style={{ width: "10%" }}
              className={`${style.headPost} py-2`}
            ></th>
            <th
              style={{ width: "10%" }}
              className={`${style.headPost} py-2 text-center`}
            ></th>
          </tr>
        </thead>

        <tbody style={{ backgroundColor: "#fff" }}>
          {!loading &&
            data?.map((item, index) => {
              const isChecked = checkedItems[item._id];
              const selectedItem = optionTopic?.find(
                (option) => option.value === item?.topic
              );
              return (
                <tr
                  key={index}
                  style={{
                    fontSize: "12px",
                    cursor: "pointer",
                    transition: "background-color 0.1s ease",
                  }}
                  className={`${style.hoveredRow}`}
                >
                  {/* check */}
                  <td className={`${style.Td}`}>
                    <div className="custom-control custom-control-sm custom-checkbox notext">
                      <input
                        type="checkbox"
                        className="custom-control-input form-control"
                        checked={isChecked}
                        // defaultChecked={
                        //   idPostMutilDelete.find(
                        //     (c) => c.id === post._id
                        //   )?.check
                        // }
                        // id={post._id + "uid1"}
                        id={`check_${item?._id}`}
                        onChange={() => handleCheckboxChange(item)}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`check_${item?._id}`}
                      ></label>
                    </div>
                  </td>
                  {/*  */}
                  <td
                    className={`${style.truncateCell} ${style.Td}`}
                    style={{
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      setRow(item);
                      toggleModal("edit");
                    }}
                  >
                    {item?.code}
                  </td>
                  {/* media */}
                  <td
                    className={`${style.truncateCell} ${style.Td}`}
                    onClick={() => {
                      setRow(item);
                      toggleModal("edit");
                    }}
                  >
                    <div className="d-flex" style={{ gap: "6px" }}>
                      {item?.media?.length > 0 &&
                        item?.media?.slice(0, 2).map((mediaItem) => {
                          return (
                            <Img
                              key={mediaItem.id}
                              src={
                                mediaItem?.type === MEDIA_TYPE_IMAGE
                                  ? mediaItem?.name
                                  : mediaItem?.type === MEDIA_TYPE_VIDEO
                                  ? mediaItem?.thumb
                                  : null
                              }
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "4px",
                              }}
                              alt="media"
                            />
                          );
                        })}
                      {item.media.length > 2 && (
                        <div className={`${style.MoreImage}`}>
                          <div>{"+" + (item.media.length - 2)}</div>
                        </div>
                      )}
                    </div>
                  </td>
                  {/* error */}
                  <td
                    className={`${style.truncateCell} ${style.Td}`}
                    onClick={() => {
                      setRow(item);
                      toggleModal("edit");
                    }}
                  >
                    {!item?.status ? (
                      <div style={{ color: "#E11D48" }}>
                        <Icon name="alert" />
                        <span>Lỗi</span>
                      </div>
                    ) : (
                      <div style={{ color: "#10B981" }}>
                        <Icon name="check-circle-fill" />
                        <span>Hoạt động</span>
                      </div>
                    )}
                  </td>
                  {/*  type */}
                  <td
                    className={`${style.truncateCell} ${style.Td}`}
                    style={{
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      setRow(item);
                      toggleModal("edit");
                    }}
                  >
                    {item?.is_photo && "Ảnh"}
                    {item?.is_short_video && "Video Ngắn"}
                    {item?.is_normal_video && "Video Thường"}
                  </td>
                  {/*  title */}
                  <td
                    className={`${style.truncateCell} ${style.Td} `}
                    onClick={() => {
                      setRow(item);
                      toggleModal("edit");
                    }}
                  >
                    {selectedItem?.label && (
                      <div
                        className="w-90 d-flex justify-content-center py-1"
                        style={{
                          background: selectedItem?.color
                            ? selectedItem.color
                            : "#2C4B94",
                          borderRadius: "4px",
                          color: "#FFFFFF",
                          fontWeight: "400",
                        }}
                      >
                        {selectedItem?.label}
                      </div>
                    )}
                  </td>
                  {/*  content */}
                  <td
                    className={`${style.truncateCell} ${style.Td}`}
                    onClick={() => {
                      setRow(item);
                      toggleModal("edit");
                    }}
                  >
                    <span>{item?.content}</span>
                  </td>
                  {/* ShuffleContent */}
                  <td
                    className={` ${style.Td}`}
                    onClick={() => {
                      setRow(item);
                      toggleModal("edit");
                    }}
                  >
                    <Badge
                      pill
                      color="light"
                      style={{
                        background: "#F3F4F6",
                        color: "#2C4B94",
                        fontWeight: "400",
                      }}
                    >
                      +{item?.shuffle_content?.length} biến thể
                    </Badge>
                  </td>
                  {/*  action */}
                  <td
                    className={`${style.truncateCell} ${style.Td} text-center`}

                    // onClick={() => handleViewDetailPost({ item: post })}
                  >
                    <div
                      className="d-flex justify-content-center"
                      style={{ gap: "1rem", fontSize: "1rem" }}
                    >
                      <Icon
                        name="edit"
                        onClick={() => {
                          setRow(item);
                          toggleModal("edit");
                        }}
                      />
                      {ldDel[index] ? (
                        <Spinner size="sm" />
                      ) : (
                        <Icon
                          name="trash"
                          onClick={() => handleDelete(item, index)}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          {/* <tr key={index} className="text-center"></tr> */}
        </tbody>
      </table>
      {loading && (
        <div className="d-flex justify-content-center w-100">
          <Spinner />
        </div>
      )}

      <ModalEdit
        modal={modal.edit}
        toggle={() => toggleModal("edit")}
        data={row}
        toggleSuccess={toggleSuccess}
        setMessage={setMessage}
        refetch={refetch}
        optionTopic={optionTopic}
        refetchTopic={refetchTopic}
      />
    </Fragment>
  );
};

export default Container;
