import { useState, useEffect } from "react";
import { Tooltip, Col, Row, Input, Label } from "reactstrap";
import SQUARE_FACEBOOK from "../../../images/post/facebook-square.svg";
import { Img } from "../../../../components/Component";
import { useTranslation } from "react-i18next";

const FacebookSetting = ({ setSetting, type, listImages, isVideo }) => {
  const [tooltipStoryOpen, setTooltipStoryOpen] = useState(false);
  const [tooltipReelOpen, setTooltipReelOpen] = useState(false);
  const [isResetCaseTesting, setIsResetCaseTesting] = useState(false);
  const { t } = useTranslation();
  const toggleTooltipStory = () => {
    setTooltipStoryOpen(!tooltipStoryOpen);
  };

  const toggleTooltipReel = () => {
    setTooltipReelOpen(!tooltipReelOpen);
  };

  useEffect(() => {
    if (listImages.length === 0 || !isVideo) {
      setIsResetCaseTesting(true);
    }
    if (type === "feed") {
      return;
    }
    if (
      (type === "reel" && !isVideo) ||
      (type === "story" && listImages.length === 0 && !isVideo)
    ) {
      setSetting("feed");
      setIsResetCaseTesting(false);
    }
  }, [isVideo, setSetting, listImages, type]);

  return (
    <div className="rounded color-border mt-3 p-2">
      <div className="d-flex justify-content-between gap-3">
        <div className="d-flex align-items-center">
          <div
            className="d-flex justify-content-center align-items-center rounded  position-relative"
            style={{
              background: "rgba(243,246,249,1)",
              width: "36px",
              height: "36px",
            }}
          >
            <Img src={SQUARE_FACEBOOK} height="16px"></Img>
          </div>
          <p className="fs-13 ms-2 mb-0" style={{ color: "#495057", fontWeight: 600 }}>
            {t('store_post.setting.facebook.title')}
          </p>
        </div>
      </div>
      <Row className="mt-2" style={{ marginLeft: '32px' }}>
        <Col md={12} sm={12}>
          <Row>
            <Col md={2}>
              <p className="text-body fs-13 mb-0">
                {t('store_post.setting.facebook.type')}
              </p>
            </Col>
            <Col md={10} className="d-flex justify-content-start">
              <div className="form-check mb-2 pl-0">
                <Input
                  className="form-check-input"
                  onChange={() => setSetting("feed")}
                  type="radio"
                  name="flexRadioFacebook"
                  id="flexRadioFacebookFeed"
                  checked={type === "feed"}
                />
                <Label className="form-check-label" for="flexRadioFacebookFeed">
                  Feed
                </Label>
              </div>
              <div className="form-check" id="warningRadioFacebookReel">
                <Input
                  className="form-check-input"
                  disabled={!isVideo}
                  onChange={() => setSetting("reel")}
                  type="radio"
                  name="flexRadioFacebook"
                  id="flexRadioFacebookReel"
                  checked={type === "reel"}
                />
                <Label className="form-check-label" for="flexRadioFacebookReel">
                  Reel
                </Label>
              </div>
              <Tooltip
                placement="top"
                isOpen={!isVideo && isResetCaseTesting && tooltipReelOpen}
                target={`warningRadioFacebookReel`}
                toggle={toggleTooltipReel}
              >
                <div>
                  {t('store_post.setting.facebook.warning.reel')}
                </div>
              </Tooltip>
              <div className="form-check" id="warningRadioFacebookStory">
                <Input
                  className="form-check-input"
                  disabled={
                    (listImages.length === 0 || listImages.length >= 2) &&
                    !isVideo
                  }
                  onChange={() => setSetting("story")}
                  type="radio"
                  name="flexRadioFacebook"
                  checked={type === "story"}
                  id="flexRadioFacebookStory"
                />
                <Label
                  className="form-check-label"
                  for="flexRadioFacebookStory"
                >
                  Story
                </Label>
              </div>
              <Tooltip
                placement="top"
                isOpen={
                  (listImages.length === 0 || listImages.length >= 2) &&
                  !isVideo &&
                  isResetCaseTesting &&
                  tooltipStoryOpen
                }
                target={`warningRadioFacebookStory`}
                toggle={toggleTooltipStory}
              >
                <div>
                  {t('store_post.setting.facebook.warning.story')}
                </div>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col md={12} sm={12}>
          <Row>
            <Col md={2}></Col>
            <Col md={10}>
              {type === "reel" && (
                <div
                  className="p-1 w-50 rounded"
                  style={{ backgroundColor: "#fef7cd", color: "#b68103" }}
                >
                  <p className="fs-12 mb-1">
                    <span className="font-weight-bold">{t('store_post.setting.facebook.desc.reel.first_line')}</span>{" "}
                    {t('store_post.setting.facebook.desc.reel.second_line')} 
                  </p>
                </div>
              )}
              {type === "story" && (
                <div
                  className="p-1 w-50 rounded"
                  style={{ backgroundColor: "#fef7cd", color: "#b68103" }}
                >
                  <p className="fs-12 mb-1">
                    <span className="font-weight-bold">{t('store_post.setting.facebook.desc.story.first_line')}</span>{" "}
                    {t('store_post.setting.facebook.desc.story.second_line')}
                  </p>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default FacebookSetting;
