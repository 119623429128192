import { useState } from "react";
import { Img } from "../../../components/Component";
import errorImage from "../../images/news/non-image.png";
import style from "./index.module.scss";
import LIKE_ICON from "../../images/news/like-icon.svg";
import COMMENT_ICON from "../../images/news/comments.svg";
import VIEW_ICON from"../../images/news/view-icon.svg";
import moment from "moment";
import { getPlatformIcon } from "../../utils/Utils";

const FeaturedPostItem = ({ item }) => {
    const [isError, setIsError] = useState(false);

    const handleImageError = () => {
        setIsError(true);
    };



    const handleOpenPostId = (item) => {
        let postId;
        let postUrl;
        switch (item?.channel?.platform) {
          case 1:
            postId = item.facebook_post_id;
            window.open(`https://www.facebook.com/${postId}`, "_blank");
            break;
          case 2:
            postUrl = item.share_url;
            window.open(`${postUrl}`, "_blank");
            break;
          case 3:
            postId = item.facebook_post_id;
            window.open(`https://www.facebook.com/${postId}`, "_blank");
            break;
          case 5:
            postId = item.youtube_video_id;
            window.open(`https://www.youtube.com/watch?v=${postId}`, "_blank");
            break;
          case 6:
            //  postId = getShortcodeFromTag(item.instagram_post_id.id);
            //  window.open(`https://www.instagram.com/p/${postId}`, "_blank");
            break;
          default:
        }
      };

    const formatNumber = (number) => {
        if (number >= 1000000000) {
            return (number / 1000000000).toFixed(1) + 'b';
        } else if (number >= 1000000) {
            return (number / 1000000).toFixed(1) + 'm';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + 'k';
        }
        return number.toString();
    }

    const renderAvatar = ({ image, platform }) => {
        return (
            <div className={`position-relative  ${style.postScheduleBlock__userAvatar}`} style={{ width: "32px", height: "32px" }}>
                <Img src={image ?? ""} className={`rounded`} height={"32px"}></Img>
                <Img src={getPlatformIcon(platform)} height={"12px"} className={`position-absolute`} style={{ bottom: "0px", right: "0px" }}></Img>
            </div>
        )
    }


    const renderImage = () => {
        if (item?.media?.length > 0) {
            return (
                <>
                    {!isError ? (
                        <img src={item?.media[0]?.url} className="rounded" onError={handleImageError} height={"108px"} alt="Post Image" />
                    ) : (
                        <>
                            <img src={errorImage} className="rounded" height={"32px"} width={"32px"} alt="Error Image" />
                            <p className="mb-0" style={{ fontSize: "10px", lineHeight: "14px", color: "#BDBDBD", marginTop: "8px" }}>Ảnh không hỗ trợ xem trước</p>
                        </>
                    )}
                </>
            )
        } else return <></>
    }
    return (
        <div key={item?._id} className={`${style.featuredPostBlock__item} rounded border`}>
            <div  style={{ padding: "12px" }}>
                <div className={`d-flex`}>
                    {renderAvatar({ image: item?.channel?.picture, platform: item?.channel?.platform })}
                    <div className={`${style.featuredPostChannel} rounded`}>
                        <p className="text-truncate">{item?.channel?.name}</p>
                        <p className={style.createAt}>{moment(item?.create_at).format('HH:mm, DD/MM/YYYY')}</p>
                    </div>
                </div>
                <div onClick={(e)=>{e.stopPropagation(); handleOpenPostId(item)}} className={`cursor-pointer d-flex justify-content-center flex-column rounded align-items-center ${style.imageBlock} ${item?.media?.length === 0 && style.nonMedia}`}>
                    {renderImage()}
                    {item?.media?.length === 0 &&
                        <p className="text-truncate text-wrap w-100 h-100" style={{ margin: "0", padding: "0 8px", maxHeight: "72px" }}>
                            {item.content}
                        </p>
                    }
                </div>
                <div className={`d-flex`}>
                    <div className={`d-flex align-items-center ${style.featuredPostBlock__statitic}`}>
                        <Img src={LIKE_ICON} height={"12px"}></Img>
                        <p>{formatNumber(item?.statistics?.like)}</p>
                    </div>
                    <div className={`d-flex align-items-center ${style.featuredPostBlock__statitic}`}>
                        <Img src={COMMENT_ICON} height={"12px"}></Img>
                        <p>{formatNumber(item?.statistics?.comment)}</p>
                    </div>
                    <div className={`d-flex align-items-center ${style.featuredPostBlock__statitic}`}>
                        <Img src={VIEW_ICON} height={"12px"}></Img>
                        <p>{formatNumber(item?.statistics?.performance)}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FeaturedPostItem;