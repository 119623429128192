import React, { useEffect, useState, useRef } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";

import {
  Card,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalHeader,
  DropdownItem,
  Form,
  Spinner
} from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  Row,
  OverlineTitle,
  Sidebar,
  UserAvatar,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  RSelect,
  TooltipComponent,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import moment from "moment";
import { useHistory } from "react-router";
import { currentTime, findUpper, monthNames, todaysDate } from "../../../utils/Utils";
import { notes } from "./UserData";
import { useTranslation } from "react-i18next";
import { getCustomerById, createAdminNote, deleteAdminNote } from "../../../api/customer";
import { getOrdersByCustomerId } from "../../../api/customer";
import { OrderStatus } from "../order-management/constants";

const UserDetailsPage = ({ match }) => {
  const { t } = useTranslation()
  const classResend = 'red';
  const [provinceId, setProvinceId] = useState(0)
  const [data, setData] = useState([])
  const [province, setProvince] = useState([])
  const [district, setDistrict] = useState([])
  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false)
  const [callApi, setCallApi] = useState(true);
  const [errorNotice, setErrorNotice] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [editId, setEditedId] = useState();
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    address: "",
    product_name: "",
    quantity: 1,
    price_sale: "",
    order_items: []
  });
  const optionCustomerPage = useRef(1);
  const optionCustomerEnd = useRef(false);
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [orders, setOrders] = useState([])
  const [sideBar, setSidebar] = useState(false);
  const [user, setUser] = useState();
  const [noteData, setNoteData] = useState([]);
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [addNoteText, setAddNoteText] = useState("");
  const [tab, setTab] = useState({
    profile: true,
    order: false
  })

  const [deleteIsError, setDeleteIsError] = useState({
    id: "",
    message: ""
  })

  let total = 0
  const history = useHistory();
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = orders.slice(indexOfFirstItem, indexOfLastItem);
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfor"));
    const id = match.params.id;
    if (id !== undefined || null || "") {
      if (userInfo.last_project_active) {
        const projectId = userInfo.last_project_active;
        const fetchData = async () => {
          setLoading(true)
          const [orders, customer] = await Promise.all([
            await getOrdersByCustomerId(projectId, id, {
              params: {
                page: optionCustomerPage.current,
              }
            }),
            await getCustomerById(projectId, id)
          ])
          return { orders, customer }
        };
        fetchData().then((response) => {
          const data = response.customer.data
          setOrders(response.orders.data)
          setNoteData(buildNote(data.notes))
          // console.log(data)
          setUser(data)
        })
        setLoading(false)
      }
    }
    return () => { setUser({}) }
  }, []);

  // function to toggle sidebar
  const toggle = () => {
    setSidebar(!sideBar);
  };
  
  const getCountOrderByStatus = (orders) => {
    let cancelOrderCount = 0;
    let newOrderCount = 0;
    let completedOrderCount = 0;
    for (const item of orders) {
      if (item.status == OrderStatus.ORDER_STATUS_CANCEL) {
        cancelOrderCount += 1
      }
      if (item.status == OrderStatus.ORDER_STATUS_NEW) {
        newOrderCount += 1
      }
      if (item.status == OrderStatus.ORDER_STATUS_COMPLETED) {
        completedOrderCount += 1
      }
    }
    return {
      cancelOrderCount,
      newOrderCount,
      completedOrderCount
    }
  }

  // delete a note
  const deleteNote = (id) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfor"));
    const projectId = userInfo.last_project_active;
    const customerId = match.params.id;

    if (customerId !== undefined || null || "") {
      deleteAdminNote(projectId, customerId, id).then((resp) => {
        if (resp.status) {
          console.log(resp);
          let defaultNote = noteData;
          defaultNote = defaultNote.filter((item) => item.id !== id);
          setNoteData(defaultNote);
        }
        else {
          setDeleteIsError({
            id : id,
            message : resp.message,
          });
        }
      }).catch((e) => {
        setDeleteIsError({
          id : id,
          message : t("admin_note_delete_error"),
        });

      })

    }
    // let defaultNote = noteData;
    // defaultNote = defaultNote.filter((item) => item.id !== id);
    // setNoteData(defaultNote);
  };
  const onEditClick = (id) => {
    data.forEach((item) => {
      if (item._id === id) {
        setFormData({
          name: item.order_ship.name,
          phone: item.order_ship.phone,
          address: item.order_ship.address,
          price_sale: item.total_amount,
          order_items: item.orderItem,
          quantity: item.order_items[0].number_item,
        });
        setModal({ edit: true }, { add: false });
        setEditedId(id);
      }
    });
  };
  const buildNote = (notes) => {
    if (notes) {
      const noteArr = Object.keys(notes).map((key) => {
        let timeNote = new Date(notes[key].created_at.replace(/-/g, "/"));
        return {
          id: notes[key].id,
          text: notes[key].note,
          date: `${monthNames[timeNote.getMonth()]} ${timeNote.getDate()}, ${timeNote.getFullYear()}`,
          time: timeNote.toLocaleString('en-US', { hour: 'numeric',minute: '2-digit', hour12: true }),
          company: notes[key].creator.name ? notes[key].creator.name : '',
        };
      });
      return noteArr;
    }
    return [];
  }
  const submitNote = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfor"));
    const projectId = userInfo.last_project_active;
    const id = match.params.id;
    let submitData = {
      note: addNoteText
    }
    if (id !== undefined || null || "") {
      createAdminNote(projectId, id, submitData).then((resp) => {
        if (resp.status) {
          setNoteData(buildNote(resp.data.notes));
          setAddNoteModal(false);
          setAddNoteText("");
          setCallApi(true);
        }
        else {
          setAddNoteModal(true);
          setCallApi(false);
          setErrorNotice(resp.message);
        }
      }).catch((e) => {
        setAddNoteModal(true);
        setCallApi(false);
        setErrorNotice(t("customer.admin_note_error"));
      })
    }
  };
  const onSelectChange = (e, _id) => {
    let newData = data;
    let index = newData.findIndex((item) => item._id === _id);
    newData[index].checked = e.currentTarget.checked;
    setData([...newData]);
  };
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <React.Fragment>
      <Head title="Detail" ></Head>
      {user && (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  {t('customer.user')} / <strong className="text-primary small">{user.name}</strong>
                </BlockTitle>
                <BlockDes className="text-soft">
                  <ul className="list-inline">
                    <li>
                      User ID: <span className="text-base">{user._id}</span>
                    </li>
                  </ul>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <Button
                  color="light"
                  outline
                  className="bg-white d-none d-sm-inline-flex"
                  onClick={() => history.goBack()}
                >
                  <Icon name="arrow-left"></Icon>
                  <span>Back</span>
                </Button>
                <a
                  href="#back"
                  onClick={(ev) => {
                    ev.preventDefault();
                    history.goBack();
                  }}
                  className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                >
                  <Icon name="arrow-left"></Icon>
                </a>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <Card className="card-bordered">
              <div className="card-aside-wrap" id="user-detail-block">
                <div className="card-content">
                  <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                    <li className="nav-item">
                      <a
                        className={tab.profile ? "nav-link active" : "nav-link"}
                        href="#personal"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setTab({
                            profile: true,
                            order: false
                          })
                        }}
                      >
                        <Icon name="user-circle"></Icon>
                        <span>{t('customer.detail.personal_information')}</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={tab.order ? "nav-link active" : "nav-link"}
                        href="#orders"
                        onClick={(ev) => {
                          setTab({
                            profile: false,
                            order: true
                          })
                          ev.preventDefault();
                        }}
                      >
                        <Icon name="repeat"></Icon>
                        <span>{t('customer.order')}</span>
                      </a>
                    </li>
                    <li className="nav-item nav-item-trigger d-xxl-none">
                      <Button className={`toggle btn-icon btn-trigger ${sideBar && "active"}`} onClick={toggle}>
                        <Icon name="user-list-fill"></Icon>
                      </Button>
                    </li>
                  </ul>

                  {
                    tab.profile ?
                      <div className="card-inner">
                        <Block>
                          <BlockHead>
                            {/* <BlockTitle tag="h5">Personal Information</BlockTitle>
                            <p>Basic info, like your name and address, that you use on Nio Platform.</p> */}
                          </BlockHead>
                          <div className="profile-ud-list">
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">{t('customer.detail.fullname')}</span>
                                <span className="profile-ud-value">{user.name}</span>
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">{t('customer.detail.date_of_birth')}</span>
                                {/* <span className="profile-ud-value">{user.name}</span> */}
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">{t('customer.phone')}</span>
                                <span className="profile-ud-value">{user.phones[0]}</span>
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">{t('customer.email')}</span>
                                <span className="profile-ud-value">{user.emails ? user.emails[0] : user.emails}</span>
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">{t('tag')}</span>
                                <span className="profile-ud-value">{user.tags[0]}</span>
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">{t('customer.source')}</span>
                                <span className="profile-ud-value">{user.source}</span>
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">{t('customer.detail.province')}</span>
                                {/* <span className="profile-ud-value">{user.email}</span> */}
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">{t('customer.detail.district')}</span>
                                {/* <span className="profile-ud-value">{user.email}</span> */}
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">{t('customer.detail.address_detail')}</span>
                                <span className="profile-ud-value">{user.address}</span>
                              </div>
                            </div>
                          </div>
                        </Block>

                        <Block>
                          <BlockHead className="nk-block-head-line">
                            <BlockTitle tag="h6" className="overline-title text-base">
                              {t('customer.detail.other_additional_information')}
                            </BlockTitle>
                          </BlockHead>
                          <div className="profile-ud-list">
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">{t('customer.second_phone')}</span>
                                <span className="profile-ud-value">{user.phones[1]}</span>
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">{t('customer.second_email')}</span>
                                <span className="profile-ud-value">{user.emails ? user.emails[1] : user.emails}</span>
                              </div>
                            </div>
                          </div>
                        </Block>

                        <div className="nk-divider divider md"></div>
                        <Block>
                          <BlockHead size="sm">
                            <BlockBetween>
                              <BlockTitle tag="h5">Admin Note</BlockTitle>
                              <a
                                href="#addnote"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setAddNoteModal(true);
                                }}
                                className="link link-sm"
                              >
                                + Add Note
                              </a>
                            </BlockBetween>
                          </BlockHead>
                          <div className="bq-note">
                            {noteData.map((item) => (
                              <div className="bq-note-item" key={item.id}>
                                <div className="bq-note-text">
                                  <p>{item.text}</p>
                                </div>
                                <div className="bq-note-meta">
                                  <span className="bq-note-added">
                                    Added on <span className="date">{item.date}</span> at{" "}
                                    <span className="time">{item.time}</span>
                                  </span>
                                  <span className="bq-note-sep sep">|</span>
                                  <span className="bq-note-by">
                                    By <span>{item.company}</span>
                                  </span>
                                  <a
                                    href="#deletenote"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      deleteNote(item.id);
                                    }}
                                    className="link link-sm link-danger"
                                  >
                                    Delete Note  
                                  </a>
                                  <a style={{ color: classResend, paddingLeft:"5px" }}>{ deleteIsError && deleteIsError.id === item.id ? deleteIsError.message : ""}</a>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Block>
                      </div> :
                      <DataTable className="card-stretch">
                        <DataTableBody compact>
                          <DataTableHead>
                            <DataTableRow className="nk-tb-col-check">
                              <div className="custom-control custom-control-sm custom-checkbox notext">
                                <input
                                  type="checkbox"
                                  className="custom-control-input form-control"
                                  id="uid"
                                />
                                <label className="custom-control-label" htmlFor="uid"></label>
                              </div>
                            </DataTableRow>
                            <DataTableRow>
                              <span className="sub-text">{t('orders.order_id')}</span>
                            </DataTableRow>
                            <DataTableRow size="md">
                              <span className="sub-text">{t('orders.customer_name')}</span>
                            </DataTableRow>
                            <DataTableRow size="sm">
                              <span className="sub-text">{t('orders.created_at')}</span>
                            </DataTableRow>
                            <DataTableRow size="md">
                              <span className="sub-text">{t('orders.number_item')}</span>
                            </DataTableRow>
                            <DataTableRow size="lg">
                              <span className="sub-text">{t('orders.payment')}</span>
                            </DataTableRow>
                            <DataTableRow size="lg">
                              <span className="sub-text">{t('orders.shipping')}</span>
                            </DataTableRow>
                            <DataTableRow>
                              <span className="sub-text">{t('orders.order_status')}</span>
                            </DataTableRow>
                            <DataTableRow className="nk-tb-col-tools text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-xs btn-outline-light btn-icon dropdown-toggle">
                                  <Icon name="plus"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right className="dropdown-menu-xs">
                                  <ul className="link-tidy sm no-bdr">
                                    <li>
                                      <div className="custom-control custom-control-sm custom-checkbox">
                                        <input type="checkbox" className="custom-control-input form-control" id="bl" />
                                        <label className="custom-control-label" htmlFor="bl">
                                          Balance
                                        </label>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="custom-control custom-control-sm custom-checkbox">
                                        <input type="checkbox" className="custom-control-input form-control" id="ph" />
                                        <label className="custom-control-label" htmlFor="ph">
                                          Phone
                                        </label>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="custom-control custom-control-sm custom-checkbox">
                                        <input type="checkbox" className="custom-control-input form-control" id="vri" />
                                        <label className="custom-control-label" htmlFor="vri">
                                          Verified
                                        </label>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="custom-control custom-control-sm custom-checkbox">
                                        <input type="checkbox" className="custom-control-input form-control" id="st" />
                                        <label className="custom-control-label" htmlFor="st">
                                          Status
                                        </label>
                                      </div>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </DataTableRow>
                          </DataTableHead>
                          {currentItems.length > 0
                            ? currentItems.map((item) => {
                              return (
                                <DataTableItem key={item._id}>
                                  <DataTableRow className="nk-tb-col-check">
                                    <div className="custom-control custom-control-sm custom-checkbox notext">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input form-control"
                                        defaultChecked={item.checked}
                                        id={item._id}
                                        key={Math.random()}
                                        onChange={(e) => onSelectChange(e, item._id)}
                                      />
                                      <label className="custom-control-label" htmlFor={item._id}></label>
                                    </div>
                                  </DataTableRow>
                                  <DataTableRow>
                                    <Link to={`${process.env.PUBLIC_URL}/order-details/${item._id}`}>
                                      <div className="user-card">
                                        <div className="user-info">
                                          <span className="tb-lead" style={{
                                            display: "block",
                                            width: "80px",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis"
                                          }}>#{item._id} </span>
                                        </div>
                                      </div>
                                    </Link>
                                  </DataTableRow>
                                  <DataTableRow size="md">
                                    <span>{item.order_ship.name}</span>
                                  </DataTableRow>
                                  <DataTableRow size="sm">
                                    <span>{moment(item.created_at).format('L')}</span>
                                  </DataTableRow>
                                  <DataTableRow size="sm">
                                    <span>{
                                      item.order_items.map(e => {
                                        return +e.number_item
                                      })}</span>
                                  </DataTableRow>
                                  <DataTableRow size="md">
                                    <span>{item.total_amount}</span>
                                  </DataTableRow>
                                  <DataTableRow size="lg">
                                    <span>{item.address}</span>
                                  </DataTableRow>
                                  <DataTableRow size="lg">
                                    <span>{item.status}</span>
                                  </DataTableRow>
                                  <DataTableRow className="nk-tb-col-tools">
                                    <ul className="nk-tb-actions gx-1">
                                      <li className="nk-tb-action-hidden" onClick={() => onEditClick(item._id)}>
                                        <TooltipComponent
                                          tag="a"
                                          containerClassName="btn btn-trigger btn-icon"
                                          id={"edit" + item.id}
                                          icon="edit-alt-fill"
                                          direction="top"
                                          text="Edit"
                                        />
                                      </li>
                                      <li>
                                        <UncontrolledDropdown>
                                          <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                            <Icon name="more-h"></Icon>
                                          </DropdownToggle>
                                          <DropdownMenu right>
                                            <ul className="link-list-opt no-bdr">
                                              <li onClick={() => onEditClick(item._id)}>
                                                <DropdownItem
                                                  tag="a"
                                                  href="#edit"
                                                  onClick={(ev) => {
                                                    ev.preventDefault();
                                                  }}
                                                >
                                                  <Icon name="edit"></Icon>
                                                  <span>Edit</span>
                                                </DropdownItem>
                                              </li>

                                            </ul>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </li>
                                    </ul>
                                  </DataTableRow>
                                </DataTableItem>
                              );
                            })
                            : null}
                        </DataTableBody>
                        <div className="card-inner">
                          {currentItems.length > 0 ? (
                            <PaginationComponent
                              itemPerPage={itemPerPage}
                              totalItems={data.length}
                              paginate={paginate}
                              currentPage={currentPage}
                            />
                          ) : (
                            loading ?
                              (
                                <div className="text-center">
                                  <Spinner color="dark" />
                                </div>) :
                              (
                                <div className="text-center">
                                  <span>No data found</span>
                                </div>
                              )
                          )}
                        </div>
                      </DataTable>
                  }

                </div>

                <Modal
                  isOpen={addNoteModal}
                  toggle={() => setAddNoteModal(false)}
                  className="modal-dialog-centered"
                  size="lg"
                >
                  <ModalBody>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setAddNoteModal(false);
                        setAddNoteText("");
                      }}
                      className="close"
                    >
                      <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                      <h5 className="title">Add Admin Note</h5>
                      <div className="mt-4 mb-4">
                        <textarea
                          defaultValue={addNoteText}
                          className="form-control no-resize"
                          onChange={(e) => setAddNoteText(e.target.value)}
                        />
                        <h6 style={{ color: classResend, fontSize: '80%', paddingTop: "2px" }}>{callApi === true ? "" : errorNotice}</h6>
                      </div>

                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button color="primary" size="md" type="submit" onClick={submitNote}>
                            Add Note
                          </Button>
                        </li>
                        <li>
                          <Button onClick={() => setAddNoteModal(false)} className="link link-light">
                            Cancel
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </ModalBody>
                </Modal>

                <Sidebar toggleState={sideBar}>
                  <div className="card-inner">
                    <div className="user-card user-card-s2 mt-5 mt-xxl-0">
                      <UserAvatar className="lg" theme="primary" text={findUpper(user.name)} />
                      <div className="user-info">
                        <div className="badge badge-outline-light badge-pill ucap">{user.role}</div>
                        <h5>{user.name}</h5>
                        <span className="sub-text">{user.email}</span>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner card-inner-sm">
                    <ul className="btn-toolbar justify-center gx-1">
                      <li>
                        <Button
                          href="#tool"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon"
                        >
                          <Icon name="shield-off"></Icon>
                        </Button>
                      </li>
                      <li>
                        <Button
                          href="#mail"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon"
                        >
                          <Icon name="mail"></Icon>
                        </Button>
                      </li>
                      <li>
                        <Button
                          href="#download"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon"
                        >
                          <Icon name="download-cloud"></Icon>
                        </Button>
                      </li>
                      <li>
                        <Button
                          href="#bookmark"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon"
                        >
                          <Icon name="bookmark"></Icon>
                        </Button>
                      </li>
                      <li>
                        <Button
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon text-danger"
                        >
                          <Icon name="na"></Icon>
                        </Button>
                      </li>
                    </ul>
                  </div>
                  <div className="card-inner">
                    <div className="overline-title-alt mb-2">Giá trị trọn đời</div>
                    <div className="profile-balance">
                      <div className="profile-balance-group gx-4">
                        <div className="profile-balance-sub">
                          <div className="profile-balance-amount">
                            <div className="number">
                              {orders.forEach(item => {
                                total += item.total_amount
                              })} {total} <small className="currency currency-usd">VNĐ</small>
                            </div>
                          </div>
                          <div className="profile-balance-subtitle">Tổng giá trị đơn</div>
                        </div>
                        <div className="profile-balance-sub">
                          <span className="profile-balance-plus text-soft">
                            <Icon className="ni-plus"></Icon>
                          </span>
                          <div className="profile-balance-amount">
                            <div className="number">{orders.length}</div>
                          </div>
                          <div className="profile-balance-subtitle">Đơn hàng</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <Row className="text-center">
                      <Col size="4">
                        <div className="profile-stats">
                          <span className="amount">{getCountOrderByStatus(orders).completedOrderCount}</span>
                          <span className="sub-text">Đơn thành công</span>
                        </div>
                      </Col>
                      <Col size="4">
                        <div className="profile-stats">
                          <span className="amount">{getCountOrderByStatus(orders).newOrderCount}</span>
                          <span className="sub-text">Đơn đang xử lý</span>
                        </div>
                      </Col>
                      <Col size="4">
                        <div className="profile-stats">
                          <span className="amount">{getCountOrderByStatus(orders).cancelOrderCount}</span>
                          <span className="sub-text">Đơn hủy</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-inner">
                    <h6 className="overline-title-alt mb-2">Additional</h6>
                    <Row className="g-3">
                      <Col size="6">
                        <span className="sub-text">User ID:</span>
                        <span>UD003054</span>
                      </Col>
                      {/* <Col size="6">
                            <span className="sub-text">Last Login:</span>
                            <span>{user.lastLogin} 01:02 PM</span>
                          </Col>
                          <Col size="6">
                            <span className="sub-text">KYC Status:</span>
                            <span
                              className={`lead-text text-${user.kycStatus === "success"
                                  ? "success"
                                  : user.kycStatus === "pending"
                                    ? "info"
                                    : user.kycStatus === "warning"
                                      ? "warning"
                                      : "secondary"
                                }`}
                            >
                              {user.kycStatus.toUpperCase()}
                            </span>
                          </Col> */}
                      <Col size="6">
                        <span className="sub-text">Register At:</span>
                        <span>Nov 24, 2019</span>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-inner">
                    <OverlineTitle tag="h6" className="mb-3">
                      Tags
                    </OverlineTitle>
                    <ul className="g-1">
                      {
                        user.tags.map((item) => {
                          return (
                            <li key={item} className="btn-group">
                              <Button
                                color="light"
                                size="xs"
                                className="btn-dim"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                {item}
                              </Button>
                              <Button
                                color="light"
                                size="xs"
                                className="btn-icon btn-dim"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon className="ni-cross"></Icon>
                              </Button>
                            </li>
                          )
                        })
                      }
                      {/* <li className="btn-group">
                            <Button
                              color="light"
                              size="xs"
                              className="btn-dim"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              investor
                            </Button>
                            <Button
                              color="light"
                              size="xs"
                              className="btn-icon btn-dim"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <Icon className="ni-cross"></Icon>
                            </Button>
                          </li>
                          <li className="btn-group">
                            <Button
                              color="light"
                              size="xs"
                              className="btn-dim"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              support
                            </Button>
                            <Button
                              color="light"
                              size="xs"
                              className="btn-icon btn-dim"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <Icon className="ni-cross"></Icon>
                            </Button>
                          </li>
                          <li className="btn-group">
                            <Button
                              color="light"
                              size="xs"
                              className="btn-dim"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              another tag
                            </Button>
                            <Button
                              color="light"
                              size="xs"
                              className="btn-icon btn-dim"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <Icon className="ni-cross"></Icon>
                            </Button>
                          </li> */}
                    </ul>
                  </div>
                </Sidebar>
                {sideBar && <div className="toggle-overlay" onClick={() => toggle()}></div>}
              </div>
            </Card>
          </Block> :

          {/* <Block>
            <DataTable className="card-stretch">
              <DataTableBody compact>
                <DataTableHead>
                  <DataTableRow className="nk-tb-col-check">
                    <div className="custom-control custom-control-sm custom-checkbox notext">
                      <input
                        type="checkbox"
                        className="custom-control-input form-control"
                        id="uid"
                      />
                      <label className="custom-control-label" htmlFor="uid"></label>
                    </div>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text">{t('orders.order_id')}</span>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <span className="sub-text">{t('orders.customer_name')}</span>
                  </DataTableRow>
                  <DataTableRow size="sm">
                    <span className="sub-text">{t('orders.created_at')}</span>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <span className="sub-text">{t('orders.number_item')}</span>
                  </DataTableRow>
                  <DataTableRow size="lg">
                    <span className="sub-text">{t('orders.payment')}</span>
                  </DataTableRow>
                  <DataTableRow size="lg">
                    <span className="sub-text">{t('orders.shipping')}</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text">{t('orders.order_status')}</span>
                  </DataTableRow>
                  <DataTableRow className="nk-tb-col-tools text-right">
                    <UncontrolledDropdown>
                      <DropdownToggle tag="a" className="btn btn-xs btn-outline-light btn-icon dropdown-toggle">
                        <Icon name="plus"></Icon>
                      </DropdownToggle>
                      <DropdownMenu right className="dropdown-menu-xs">
                        <ul className="link-tidy sm no-bdr">
                          <li>
                            <div className="custom-control custom-control-sm custom-checkbox">
                              <input type="checkbox" className="custom-control-input form-control" id="bl" />
                              <label className="custom-control-label" htmlFor="bl">
                                Balance
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-control custom-control-sm custom-checkbox">
                              <input type="checkbox" className="custom-control-input form-control" id="ph" />
                              <label className="custom-control-label" htmlFor="ph">
                                Phone
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-control custom-control-sm custom-checkbox">
                              <input type="checkbox" className="custom-control-input form-control" id="vri" />
                              <label className="custom-control-label" htmlFor="vri">
                                Verified
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-control custom-control-sm custom-checkbox">
                              <input type="checkbox" className="custom-control-input form-control" id="st" />
                              <label className="custom-control-label" htmlFor="st">
                                Status
                              </label>
                            </div>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </DataTableRow>
                </DataTableHead>
                {currentItems.length > 0
                  ? currentItems.map((item) => {
                    return (
                      <DataTableItem key={item._id}>
                        <DataTableRow className="nk-tb-col-check">
                          <div className="custom-control custom-control-sm custom-checkbox notext">
                            <input
                              type="checkbox"
                              className="custom-control-input form-control"
                              defaultChecked={item.checked}
                              id={item._id}
                              key={Math.random()}
                              onChange={(e) => onSelectChange(e, item._id)}
                            />
                            <label className="custom-control-label" htmlFor={item._id}></label>
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <Link to={`${process.env.PUBLIC_URL}/order-details/${item._id}`}>
                            <div className="user-card">
                              <div className="user-info">
                                <span className="tb-lead" style={{
                                  display: "block",
                                  width: "80px",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis"
                                }}>#{item._id} </span>
                              </div>
                            </div>
                          </Link>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span>{item.order_ship.name}</span>
                        </DataTableRow>
                        <DataTableRow size="sm">
                          <span>{moment(item.created_at).format('L')}</span>
                        </DataTableRow>
                        <DataTableRow size="sm">
                          <span>{
                            item.order_items.map(e => {
                              return +e.number_item
                            })}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span>{item.total_amount}</span>
                        </DataTableRow>
                        <DataTableRow size="lg">
                          <span>{item.address}</span>
                        </DataTableRow>
                        <DataTableRow size="lg">
                          <span>{item.status}</span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools">
                          <ul className="nk-tb-actions gx-1">
                            <li className="nk-tb-action-hidden" onClick={() => onEditClick(item._id)}>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"edit" + item.id}
                                icon="edit-alt-fill"
                                direction="top"
                                text="Edit"
                              />
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <ul className="link-list-opt no-bdr">
                                    <li onClick={() => onEditClick(item._id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="edit"></Icon>
                                        <span>Edit</span>
                                      </DropdownItem>
                                    </li>

                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                  : null}
              </DataTableBody>
              <div className="card-inner">
                {currentItems.length > 0 ? (
                  <PaginationComponent
                    itemPerPage={itemPerPage}
                    totalItems={data.length}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                ) : (
                  loading ?
                    (
                      <div className="text-center">
                        <Spinner color="dark" />
                      </div>) :
                    (
                      <div className="text-center">
                        <span>No data found</span>
                      </div>
                    )
                )}
              </div>
            </DataTable>
          </Block> */}

        </Content>
      )}
    </React.Fragment>
  );
};
export default UserDetailsPage;
