import { useState } from "react";
import { Tooltip } from "reactstrap";
const TableToolTip = ({ tooltipId, tooltipLabel }) => {
  const [tooltipOpen, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!tooltipOpen);
  };
  return (
    <>
      {tooltipLabel && tooltipId && (
        <Tooltip
          placement="bottom"
          isOpen={tooltipOpen}
          target={`tableToolTip${tooltipId}`}
          toggle={toggle}
          style={{ width: "100%", textAlign: "left" }}
        >
          <div>{tooltipLabel}</div>
        </Tooltip>
      )}
    </>
  );
};
export default TableToolTip;
