import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Tooltip } from "reactstrap";

import { getFrequencyYT } from "../../../../../api/dashboard";

import WidgetsCharts from "../../Chart/WidgetsCharts";
import NO_DATA_DEFAULT from "../../Image/no_data_default.svg";
import NO_IMAGE from "../../Image/no_image_page.svg";
import { renderNameField } from "../../helper";
import { numberWithCommas } from "../../../DashboardNew/utils/utils";
import Img from "../../../../../components/img/Img";
import PaginationCustom from "../../../../../components/pagination/PaginationCustom";

const FrequencyYoutubeTable = ({ projectId, from, to }) => {
  const { t } = useTranslation();
  const [frequencyData, setFrequencyData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [isShowNodata, setIsShowNodata] = useState(-1);
  const [tooltipOpen, setOpen] = useState("");
  const toggle = (id) => {
    if (tooltipOpen === id) {
      setOpen("id");
    } else {
      setOpen(id);
    }
  };
  const headerFrequency = {
    channel: t("via_table.account"),
    subscriber: t("subscriber"),
    videos: t("videos"),
    views: t("views"),
    comments: t("comment"),
    frequency: t("channel_analytics_table.frequency"),
    "": "",
  };

  const handleHoverNoData = (id) => {
    setIsShowNodata(id);
  };

  const fetchData = async () => {
    const data = {
      params: {
        project_id: projectId,
        from,
        to,
        page: currentPage,
      },
    };

    const frequencyYoutube = await getFrequencyYT(data);
    if (frequencyYoutube) {
      const frequencyTiktok = frequencyYoutube.data;
      setFrequencyData(frequencyTiktok);

      setListData(
        frequencyTiktok.data.map((item) => ({
          channel_id: item?.channel?.channel_id,
          channel: item?.channel?.name,
          subscriber: item?.subcriber,
          videos: item?.videos,
          views: item?.views,
          comments: item?.comments,
          frequency: item?.frequency,
          image: item?.channel?.image,
          dataSetViews: item?.dataSetViews,
        }))
      );
    }
  };
  useEffect(() => {
    fetchData();
  }, [from, to, projectId, currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const elementRef = useRef(null);
  const [isResponsive, setIsResponsive] = useState(false);
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width } = entry.contentRect;
        // Perform actions based on the size changes

        if (width < 990) {
          setIsResponsive(true);
        } else {
          setIsResponsive(false);
        }
      }
    });

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <>
      <Card className="card-bordered" style={{ paddingTop: "10px" }}>
        <div className="card-inner-group" ref={elementRef}>
          <div className="card-inner p-0 table-responsive">
            <table className="table table-hover">
              <thead
                style={{
                  height: "60px",
                  margin: 0,
                  padding: 0,
                }}
              >
                <tr>
                  {headerFrequency &&
                    Object.values(headerFrequency).map((field, key) => {
                      const styleTh =
                        field === t("via_table.account")
                          ? {
                              paddingBottom: "20px",
                            }
                          : {
                              paddingBottom: "20px",
                              textAlign: "end",
                            };
                      return (
                        <th key={key} scope="col" style={styleTh}>
                          {" "}
                          <span
                            className="frequencyTableHeader"
                            style={{ fontSize: "14px" }}
                          >
                            {field}
                          </span>
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {listData.length > 0
                  ? listData.map((item, index) => {
                      return (
                        <tr key={index}>
                          {Object.keys(headerFrequency).map((fk, keyIndex) => {
                            if (fk === "channel") {
                              return (
                                <td
                                  key={`${fk}-${keyIndex}`}
                                  style={{
                                    height: "50px",
                                    minWidth: "150px",
                                    maxWidth: "200px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      maxHeight: "32px",
                                    }}
                                  >
                                    <Img
                                      src={item.image ?? NO_IMAGE}
                                      alt=""
                                      width="32px"
                                      height="32px"
                                      style={{ borderRadius: "15%" }}
                                      // onError={({ currentTarget }) => {
                                      //   currentTarget.onerror = null; // prevents looping
                                      //   currentTarget.src = NO_IMAGE;
                                      // }}
                                    ></Img>
                                    <div
                                      style={{
                                        paddingTop: "8px",
                                        paddingLeft: "10px",
                                        width: "100%",
                                        cursor: isResponsive
                                          ? "pointer"
                                          : undefined,
                                      }}
                                      className="tb-sub"
                                      id={`${
                                        fk + keyIndex + item["channel_id"]
                                      }`}
                                    >
                                      {isResponsive
                                        ? renderNameField(0, item[`${fk}`])
                                        : item[`${fk}`]}
                                    </div>
                                    {isResponsive && (
                                      <Tooltip
                                        placement="bottom"
                                        isOpen={
                                          tooltipOpen === item["channel_id"]
                                        }
                                        target={`${
                                          fk + keyIndex + item["channel_id"]
                                        }`}
                                        toggle={() =>
                                          toggle(item["channel_id"])
                                        }
                                        style={{
                                          width: "100%",
                                          textAlign: "left",
                                        }}
                                      >
                                        <div>{item[`${fk}`]}</div>
                                      </Tooltip>
                                    )}
                                  </div>
                                </td>
                              );
                            }
                            if (fk === "views") {
                              const dataChart = [
                                {
                                  name: "",
                                  data: item?.dataSetViews?.map((d) =>
                                    numberWithCommas(d.y)
                                  ),
                                },
                              ];

                              return (
                                <td
                                  key={`${fk}-${keyIndex}`}
                                  style={{
                                    // height: "50px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "150px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div className="traffic-channel-ck ml-auto">
                                      {item[`${fk}`] > 0 ? (
                                        <WidgetsCharts
                                          seriesData={dataChart}
                                          chartsColor="#45CB85"
                                          labelX={item?.dataSetViews?.map(
                                            (d) => d.x
                                          )}
                                          isInTable={true}
                                        />
                                      ) : (
                                        <div
                                          style={{
                                            paddingTop: "12px",
                                            position: "relative",
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                          onMouseEnter={() =>
                                            handleHoverNoData(index)
                                          }
                                          onMouseLeave={() =>
                                            handleHoverNoData(-1)
                                          }
                                        >
                                          <div>
                                            <Img
                                              style={{ width: "80px" }}
                                              src={NO_DATA_DEFAULT}
                                              alt=""
                                            />
                                          </div>
                                          {isShowNodata === index && (
                                            <div
                                              style={{
                                                position: "absolute",
                                                justifyContent: "center",
                                                top: "10px",
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              No data
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>

                                    <span
                                      className="tb-sub"
                                      style={{
                                        paddingTop: "10px",
                                        paddingLeft: "10px",
                                      }}
                                    >
                                      {numberWithCommas(item[`${fk}`])}
                                    </span>
                                  </div>
                                </td>
                              );
                            }
                            return (
                              <td
                                key={`${fk}-${keyIndex}`}
                                style={{ height: "50px" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <span
                                    style={{
                                      paddingTop: "10px",
                                    }}
                                    className="tb-sub"
                                  >
                                    {numberWithCommas(item[`${fk}`])}
                                  </span>
                                </div>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
            <div className="card-inner mobile-paginate">
              {listData?.length > 0 && frequencyData ? (
                frequencyData?.last_page > 1 && (
                  <PaginationCustom
                    pageSize={frequencyData?.per_page || 9}
                    totalCount={frequencyData?.total || 0}
                    siblingCount={1}
                    onPageChange={handlePageChange}
                    currentPage={frequencyData?.current_page}
                  />
                )
              ) : (
                <div className="text-center">
                  <span className="text-silent">No page found</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default FrequencyYoutubeTable;
