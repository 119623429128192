import React, { useEffect, useState } from "react";

// import ContentSpread from "../../DashboardNew/Facebook/ContentSpread";

import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";

import { getUserInfoInLocalStorage } from "../../helper";

import YoutubeOverview from "./OverviewContent";
import {
  getContentGeneralYoutube,
  getLogFetchTT,
  getContentTopPost,
  getContentStatistic,
  getLogFetchYT,
} from "../../../../../api/dashboard";
import ContentStatisticTable from "../CommonItem/ContentStatisticTable";
import { TopPostColumn } from "../CommonItem/TopPostColumn";
import defaultChannelIcon from "../Icon/youtube.svg";
import { LogHeader } from "../../CommonItem/LogHeader";
import { PLATFORM_NAME } from "../../constants";
import Content from "../../../../../layout/content/Content";
import { Block } from "../../../../../components/Component";
const YoutubeStaff = ({
  channels,
  range,
  staffs,
  setLoadingStaffFilter,
  setLoadingChannelFilter,
  setLoadingRange,
  from,
  to,
}) => {
  const userInfor = getUserInfoInLocalStorage();
  const projectId = userInfor.last_project_active;

  const { t } = useTranslation();

  const [logTTData, setLogTTData] = useState({});
  const [contentGeneralData, setContentGeneralData] = useState({});
  const [isLoadingGeneralYoutube, setIsLoadingGeneralYoutube] = useState(true);
  const [contentTopPostData, setContentTopPostData] = useState([]);
  const [isLoadingContentTopPost, setIsLoadingContentTopPost] = useState(true);

  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [selectedOption, setSelectedOption] = useState({
    value: "view_count",
    label: "View",
  });
  const [sortType, setSortType] = useState({ value: "desc", label: "Desc." });
  const [tableLoading, setTableLoading] = useState(false);

  const [tooltipTableOpen, setTableToolTipOpen] = useState([
    { isOpen: false, label: "" },
    { isOpen: false, label: "Lượt xem video" },
    { isOpen: false, label: "Lượt thích video" },
    { isOpen: false, label: "Lượt không thích video" },
    { isOpen: false, label: "Số Bình luận vào video" },
    { isOpen: false, label: "Lượt Chia sẻ video" },
    { isOpen: false, label: "Thời gian xem trung bình video(phút)" },
    { isOpen: false, label: "Tổng thời gian video được xem (phút)" },
  ]);
  const sortTikTokOptions = [
    { value: "view_count", label: "View" },
    { value: "like_count", label: "Like" },
    { value: "dislike_count", label: "Dislike" },
    { value: "comment_count", label: "Comment" },
    { value: "share_count", label: "Share" },
    { value: "average_view_duration", label: "AVG.Watch" },
    { value: "estimated_minutes_watched", label: "WT" },
  ];
  const headerUserStatistic = {
    content: t("content"),
    view_count: t("views"),
    like_count: t("likes"),
    dislike_count: t("dis_like"),
    comment_count: t("comment"),
    share_count: t("share"),
    average_view_duration: t("average_view_duration"),
    estimated_minutes_watched: t("estimated_minutes_watched"),
  };

  const handleOpenPost = (e, id) => {
    e.stopPropagation();
    window.open(`https://www.youtube.com/watch?v=${id}`, "_blank");
  };
  const getdataTable = async (data) => {
    if (!tableLoading) {
      setTableLoading(true);
      try {
        const dataRes = await getContentStatistic(data, "youtube");
        if (dataRes.status) {
          const dataFb = dataRes.data;
          setData(dataFb);
          setListData(
            dataFb.data.map((item) => ({
              content: item?.content,
              view_count: item?.view_count,
              like_count: item?.like_count,
              dislike_count: item?.dislike_count,
              comment_count: item?.comment_count,
              share_count: item?.share_count,
              average_view_duration: item?.average_view_duration,
              estimated_minutes_watched: item?.estimated_minutes_watched,
              channel: item?.channel,
              date: item?.date,
              media: item?.media,
              Yt_post_id: item?.youtube_video_id,
            }))
          );

          setTableLoading(false);
        }
      } catch (error) {
        console.log("errTableFB", error);
        setTableLoading(false);
      }
    }
  };
  const getAPITiktok = (data) => {
    setLoadingStaffFilter(true);
    setIsLoadingGeneralYoutube(true);
    setIsLoadingContentTopPost(true);
    Promise.allSettled([
      getLogFetchYT(data),
      getContentGeneralYoutube(data),
      getContentTopPost({ data: data, platform: "youtube" }),
    ]).then((responses) => {
      // Destructure the responses to get the data
      const [logTTRes, contentGeneralRes, contentTopPostData] = responses;

      if (logTTRes.status === "fulfilled") {
        const logTT = logTTRes.value.data;
        setLogTTData(logTT);
      }
      if (contentGeneralRes.status === "fulfilled") {
        const contentGeneral = contentGeneralRes.value.data;
        setContentGeneralData(contentGeneral);
      }
      if (contentTopPostData.status === "fulfilled") {
        const contentTopPost = contentTopPostData.value.data;
        setContentTopPostData(contentTopPost);
      }
      setIsLoadingGeneralYoutube(false);
      setLoadingStaffFilter(false);
      setLoadingChannelFilter(false);
      setLoadingRange(false);
      setIsLoadingContentTopPost(false);
    });
  };
  useEffect(() => {
    const data = {
      params: {
        project_id: projectId,
        channel_ids: channels,
        user_ids: staffs,
        from,
        to,
      },
    };
    getAPITiktok(data);

    // if (currentPage === 1) {
    //   const data = {
    //     project_id: projectId,
    //     page: currentPage,
    //     user_ids: staffs,
    //     channel_ids: channels,
    //     order_by: selectedOption.value,
    //     arrange: sortType.value,
    //     from,
    //     to,
    //   };
    //   getdataTable(data);
    // } else {
    //   setCurrentPage(1);
    // }
  }, [range, channels, staffs, from, to]);
  useEffect(() => {
    const data = {
      params: {
        project_id: projectId,
        page: currentPage,
        user_ids: staffs,
        channel_ids: channels,
        order_by: selectedOption.value,
        arrange: sortType.value,
        from,
        to,
      },
    };
    getdataTable(data);
  }, [currentPage, from, to, selectedOption.value, sortType.value, from, to]);

  return (
    <>
      <Col lg={12} className="px-8">
        <LogHeader
          cnt_channel_error={logTTData?.cnt_channel_error}
          cnt_channel_success={logTTData?.cnt_channel_success}
          last_time={logTTData?.last_time}
          currentTab={PLATFORM_NAME.YOUTUBE}
        />
      </Col>

      <div style={{ padding: "18px 24px" }}>
        {/* <Block> */}
        <Row>
          <YoutubeOverview
            isLoading={isLoadingGeneralYoutube}
            data={contentGeneralData}
          />
        </Row>
        {/* </Block> */}
        {/* <Block> */}
        <Row style={{ paddingTop: "18px " }} className="px-3">
          <TopPostColumn
            data={contentTopPostData}
            isLoading={isLoadingContentTopPost}
            platform="youtube"
          />
        </Row>
        {/* </Block> */}
        <div style={{ padding: "18px 0" }}>
          <ContentStatisticTable
            listData={listData}
            sortTypeOptions={sortTikTokOptions}
            headerContentStatistic={headerUserStatistic}
            tooltipTableOpen={tooltipTableOpen}
            setTableToolTipOpen={setTableToolTipOpen}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            tableLoading={tableLoading}
            data={data}
            setCurrentPage={setCurrentPage}
            sortType={sortType}
            setSortType={setSortType}
            defaultChannelIcon={defaultChannelIcon}
          />
        </div>
      </div>
    </>
  );
};

export default YoutubeStaff;
