import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import style from "./index.module.scss";
import ImgHero from "../../images/warehouse/hero.png";
import LogoYt from "../../images/dashboard/icon/type=youtube.png";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Img,
  PaginationCustom,
  RSelect,
} from "../../../components/Component";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Row,
  Spinner,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import Container from "./Container";
import {
  deleteWarehouseFast,
  editBulkWarehouse,
  getListTopic,
  getListWarehouse,
} from "../../../api/warehouse";
import ModalAdd from "./ModalAdd";
import ModalSuccess from "./ModalSuccess";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ModalDeleteMulti from "./ModalDeleteMulti";
import ModalEditMulti from "./ModalEditMulti";
import { useTranslation } from "react-i18next";

const optionMediaType = [
  { value: null, label: "Tất cả" },
  {
    label: "Ảnh",
    value: "photo",
  },
  {
    label: "Video",
    value: "video",
  },
  {
    label: "Video Ngắn",
    value: "short",
  },
];

const optionStatus = [
  { value: null, label: "Tất cả" },
  { value: 0, label: "Lỗi" },
  { value: 1, label: "Thành Công" },
];

const Warehouse = () => {
  const {t} = useTranslation();

  const [loading, setLoading] = useState(true);
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const [filter, setFilter] = useState({ topic: "", type: "", status: "" });
  const [message, setMessage] = useState("");
  const [optionTopic, setOptionTopic] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [selectIds, setSelectIds] = useState([]);
  const history = useHistory();
  const [textSearch, setTextSearch] = useState("");
  const [inputValueTextSearch, setInputValueTextSearch] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    add: false,
    success: false,
    deleteMulti: false,
    editMulti: false,
  });
  const [isClearCheckbox, setIsClearCheckbox] = useState(false);
  const toggleModal = (type) => {
    setModal((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  const loadData = async () => {
    return await getListWarehouse(userInfo.last_project_active, {
      params: {
        page: currentPage,
        ...filter,
        textSearch,
      },
    });
  };

  const loadTopic = async () => {
    return await getListTopic(userInfo.last_project_active, {});
  };

  let { data, isLoading, refetch } = useQuery(
    [currentPage, userInfo.last_project_active, filter, textSearch],
    loadData,
    {
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  const {
    data: topicData,
    isLoading: isTopicLoading,
    refetch: refetchTopic,
  } = useQuery([userInfo.last_project_active], loadTopic, {
    cacheTime: Infinity, // Có thể điều chỉnh theo yêu cầu của bạn
    refetchOnWindowFocus: false,
    staleTime: Infinity, // Có thể điều chỉnh theo yêu cầu của bạn
  });

  const handleDeleteMulti = async () => {
    if (selectIds.length === 0) {
      return;
    } else {
      setLoadingDelete(true);
      const postSubmit = {
        project_id: userInfo.last_project_active,
        post_ids: selectIds,
      };
      try {
        const response = await deleteWarehouseFast(postSubmit);
        if (response.status) {
          setMessage(`Xoá thành công ${selectIds.length} bài`);
          refetch();
          setLoadingDelete(false);
          toggleModal("deleteMulti");
          toggleModal("success");
          setSelectIds([]);
        }
      } catch (error) {
        setLoadingDelete(false);
        console.log(error);
      }
    }
  };

  const handleEditMulti = async (value) => {
    let data = {
      project_id: userInfo.last_project_active,
      post_ids: [],
      topic: null,
    };

    data.post_ids = selectIds;
    data.topic = value.topic;

    try {
      setLoadingEdit(true)
      const res = await editBulkWarehouse(data);
      if (res?.status) {
        refetch();
        setMessage(t("ware_house.message.update_successful"));
        toggleModal("editMulti");
        toggleModal("success");
        setSelectIds([]);
        setIsClearCheckbox(true);
        setLoadingEdit(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingEdit(false);
    } 
  };

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  useEffect(() => {
    setOptionTopic(
      topicData?.data?.map((item) => ({
        label: item?.name,
        value: item?._id,
        color: getRandomColor(),
      }))
    );
  }, [topicData?.data?.length]);

  useEffect(() => {
    if (data?.data?.total > 0) {
      setLoading(false);
    }
  }, [data?.data?.total]);

  const { handleSubmit, setValue } = useForm();
  const onFilterSubmit = (data) => {
    setFilter({ type: data.type, status: data.status, topic: data.topic });
  };

  const submitSearch = () => {
    setTextSearch(inputValueTextSearch);
  };

  const handleTextSearchChange = (e) => {
    setInputValueTextSearch(e.target.value);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <React.Fragment>
      <Head title="Kho bài đăng" />
      <div style={{ height: "75px" }}></div>
      <div className={`${style.notification}`}>
        <Row className="p-2 px-md-1 py-md-2 mx-0">
          <Col md="8" sm="12">
            <Form className="gy-2" onSubmit={handleSubmit(onFilterSubmit)}>
              <Row>
                <Col md="3" xs="6" className="mb-2 mb-md-0 px-1">
                  <div className="form-wrap">
                    <RSelect
                      options={optionTopic}
                      placeholder="Chủ đề"
                      onChange={(e) => setValue("topic", e.value)}
                    />
                  </div>
                </Col>
                <Col md="3" xs="6" className="mb-2 mb-md-0 px-1">
                  <div className="form-wrap">
                    <RSelect
                      options={optionMediaType}
                      placeholder="Loại bài đăng"
                      onChange={(e) => setValue("type", e.value)}
                    />
                  </div>
                </Col>
                <Col md="3" xs="9" className="mb-2 mb-md-0 px-1">
                  <div className="form-wrap">
                    <RSelect
                      options={optionStatus}
                      placeholder="Trạng thái"
                      onChange={(e) => setValue("status", e.value)}
                    />
                  </div>
                </Col>
                <Col
                  md="3"
                  xs="3"
                  className="d-flex justify-content-end d-md-block px-1 mb-2 mb-md-0"
                >
                  <Button style={{ background: "#1D3565" }}>
                    <span>LỌC</span>
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col md="4" sm="12">
            <div className="d-flex">
              <input
                type="text"
                className="form-control border-transparent form-focus-none mr-2"
                placeholder="Tìm nội dung, mã bài đăng "
                value={inputValueTextSearch}
                onChange={handleTextSearchChange}
              />
              <Button style={{ background: "#1D3565" }} onClick={submitSearch}>
                <span>TÌM KIẾM</span>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <div className="my-4 mx-3">
        <BlockHead size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                {data?.data?.data?.length > 0
                  ? `Số lượng bài: ${data?.data?.total}`
                  : "Quản lý bài đăng trong kho"}
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <div className="toggle-expand-content">
                  <ul className="nk-block-tools g-3">
                    {selectIds.length > 0 && (
                      <li>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            tag="a"
                            className="dropdown-toggle btn btn-white btn-outline-light"
                            style={{
                              borderRadius: "2.5rem",
                              background: "#1D3565",
                              color: "white",
                            }}
                          >
                            <Icon
                              className="d-none d-sm-inline"
                              name="more-v"
                            ></Icon>
                            <span>
                              <span className="d-none d-md-inline">
                                Thao tác
                              </span>
                            </span>
                          </DropdownToggle>
                          <DropdownMenu right>
                            <ul className="link-list-opt no-bdr">
                              <DropdownItem
                                onClick={() =>
                                  selectIds.length > 0 &&
                                  toggleModal("deleteMulti")
                                }
                              >
                                {" "}
                                Xóa hàng loạt
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  selectIds.length > 0 &&
                                  toggleModal("editMulti")
                                }
                              >
                                Đổi chủ đề hàng loạt
                              </DropdownItem>
                              <DropdownItem disabled id="content_change">
                                Đổi nội dung hàng loạt
                              </DropdownItem>
                              <UncontrolledTooltip
                                autohide={false}
                                placement={"top"}
                                target={"content_change"}
                              >
                                Đang phát triển
                              </UncontrolledTooltip>
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </li>
                    )}

                    <li className="nk-block-tools-opt" id="video">
                      <a
                        href="/"
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          borderRadius: "100%",
                          background: "white",
                          width: "2.2rem",
                          height: "2.2rem",
                          color: "white",
                          pointerEvents: "none",
                        }}
                      >
                        <Img
                          src={LogoYt}
                          alt="yt"
                          style={{ width: "1.5rem" }}
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <UncontrolledTooltip
                      autohide={false}
                      placement={"top"}
                      target={"video"}
                    >
                      Đang phát triển
                    </UncontrolledTooltip>
                    <li className="nk-block-tools-opt">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="dropdown-toggle btn btn-white btn-outline-light d-flex justify-content-center"
                          style={{
                            borderRadius: "100%",
                            background: "#45BEF4",
                            width: "2rem",
                            color: "white",
                          }}
                        >
                          <Icon
                            className="d-none d-sm-inline"
                            name="plus"
                          ></Icon>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem onClick={() => toggleModal("add")}>
                            Tạo mới
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              history.push("warehouse/fastCreate");
                            }}
                          >
                            Tạo hàng loạt
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>
        <Block>
          {isLoading ? (
            <div className="d-flex justify-content-center w-100">
              <Spinner />
            </div>
          ) : data?.data?.data?.length > 0 ? (
            <>
              <Container
                data={data?.data?.data}
                modal={modal}
                toggleModal={toggleModal}
                refetch={refetch}
                setMessage={setMessage}
                loading={isLoading}
                optionTopic={optionTopic}
                refetchTopic={refetchTopic}
                toggleSuccess={() => toggleModal("success")}
                setSelectIds={setSelectIds}
                selectIds={selectIds}
                isClearCheckbox={isClearCheckbox}
                setIsClearCheckbox={setIsClearCheckbox}
              />
              <div className={`mt-3 text-right`}>
                <PaginationCustom
                  pageSize={20}
                  totalCount={data?.data?.total || 0}
                  siblingCount={1}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                  className={`manage_post_pagination`}
                />
              </div>
            </>
          ) : (
            <>
              <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{ color: "#000" }}
              >
                <Img
                  src={ImgHero}
                  alt="hero"
                  style={{ width: "20%", marginTop: "3rem" }}
                />
                <div className="text-center mt-3">
                  <p style={{ fontWeight: "600" }}>
                    <b>BẠN CHƯA CÓ BÀI ĐĂNG NÀO TRONG KHO</b>
                  </p>
                  <p>
                    Thêm bài đăng vào kho nội dung & Lên chiến dịch. Hệ thống sẽ
                    tự động lên kịch bản đăng bài để đảm bảo hệ thống kênh{" "}
                    <br /> của bạn luôn có số lượng bài đăng hàng ngày.
                  </p>
                </div>
                <div>
                  <Card className="text-start mt-4">
                    <CardBody className="card-inner">
                      <CardTitle>
                        <b>Video hướng dẫn</b>
                      </CardTitle>
                      <Row>
                        <Col md="12">
                          <div className="ratio">
                            <iframe
                              style={{ width: "100%" }}
                              title="Video hướng dẫn"
                              src="https://www.youtube.com/embed/kQUstHl4xi8?list=PL5TqJiJIECYwF_sNFwFvjsqVmebuG5qVK"
                              allowFullscreen
                            ></iframe>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </>
          )}
        </Block>
      </div>
      <ModalAdd
        toggleModal={toggleModal}
        modal={modal.add}
        toggle={() => toggleModal("add")}
        toggleSuccess={() => toggleModal("success")}
        refetch={refetch}
        setMessage={setMessage}
        optionTopic={optionTopic}
        refetchTopic={refetchTopic}
      />
      <ModalSuccess
        modal={modal.success}
        message={message}
        toggle={() => toggleModal("success")}
      />
      <ModalDeleteMulti
        modal={modal.deleteMulti}
        handleDeleteMulti={handleDeleteMulti}
        loading={loadingDelete}
        toggle={() => toggleModal("deleteMulti")}
      />

      <ModalEditMulti
        modal={modal.editMulti}
        handleEditMulti={handleEditMulti}
        loading={loadingEdit}
        toggle={() => toggleModal("editMulti")}
        optionTopic={optionTopic}
        refetchTopic={refetchTopic}
      />
    </React.Fragment>
  );
};

export default Warehouse;
