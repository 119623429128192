import React from "react";
import { Card, CardBody, CardText, CardTitle, Progress } from "reactstrap";
import style from "../../../index.module.scss";

import { formatNumberAcronym } from "../../../../../../utils/Utils";
import ChartCircle from "../../ChartCircle";

const OverviewEffect = ({ data }) => {
  return (
    <div
      className="d-flex flex-column flex-md-row w-100"
      style={{ gap: "16px" }}
    >
      <div
        className="d-flex flex-column flex-md-row w-100"
        style={{ gap: "16px" }}
      >
        <div className="w-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Lượt xem trang
              </CardTitle>
              <div className="d-flex flex-row align-items-center">
                <div className={`${style.titleCard}`}>
                  {data && formatNumberAcronym(data?.page_views_total?.value)}
                </div>
              </div>
              <CardText className={`${style.detailCard} mt-1`}>{`Trung bình: ${
                data && data?.page_views_total?.avg_day
              }/ngày`}</CardText>
            </CardBody>
          </Card>
        </div>

        <div className="w-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Nguồn giới thiệu
              </CardTitle>
              <div
                className={`${style.headCard} d-flex flex-row align-items-center w-100 pb-2`}
                style={{
                  fontSize: "0.75rem",
                  borderBottom: "1px solid #D1D5DB",
                }}
              >
                <div className="w-25">URLs</div>
                <div className="w-25">Xem</div>
                <div className="w-25"></div>
                <div className="w-25 d-flex justify-content-end">%</div>
              </div>
              <div className="mt-2 d-flex flex-column" style={{ gap: "8px" }}>
                {data &&
                  data?.page_views_by_internal_referer?.map((item, index) => {
                    const totalY = data?.page_views_by_internal_referer?.reduce(
                      (acc, obj) => acc + obj.value,
                      0
                    );
                    return (
                      <div
                        className={`${style.contentCard} d-flex flex-row align-items-center w-100`}
                        key={index}
                      >
                        <div className="w-25">{item?.source}</div>
                        <div className="w-25">{item?.value}</div>
                        <div className="w-25">
                          <Progress
                            value={((item?.value * 100) / totalY).toFixed(0)}
                            barStyle={{ backgroundColor: "#2C4B94" }}
                            style={{
                              backgroundColor: "#F1F5FF",
                            }}
                          />
                        </div>
                        <div
                          className="w-25 d-flex justify-content-end"
                          style={{ fontWeight: "600" }}
                        >
                          {((item?.value * 100) / totalY).toFixed(1)}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
      <div
        className="d-flex flex-column flex-md-row w-100"
        style={{ gap: "16px" }}
      >
        <div className="w-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Lượt click nút kêu gọi
              </CardTitle>
              <div className="d-flex flex-row align-items-center">
                <div className={`${style.titleCard}`}>
                  {data
                    ? formatNumberAcronym(data?.page_total_actions?.value)
                    : "0"}
                </div>
              </div>
              <CardText className={`${style.detailCard} mt-1`}>{`Trung bình: ${
                data && data?.page_total_actions?.avg_day
              }/ngày`}</CardText>
            </CardBody>
          </Card>
        </div>

        <div className="w-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Loại phản hồi
              </CardTitle>
              <ChartCircle data={data?.rate_feedback} />
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default OverviewEffect;
