import FACEBOOK_PREVIEW_STATUS from "../../../images/post/facebook-preview-status.svg";
import FACEBOOK_PREVIEW_LIKE from "../../../images/post/facebook-preview-like.svg";
import FACEBOOK_PREVIEW_COMMENT from "../../../images/post/facebook-preview-comment.svg";
import FACEBOOK_PREVIEW_SHARE from "../../../images/post/facebook-preview-share.svg";
import INSTAGRAM_PREVIEW_STATUS from "../../../images/post/instagram-preview-status.svg";
import INSTAGRAM_PREVIEW_HEART from "../../../images/post/instagram-preview-heart.svg";
import INSTAGRAM_PREVIEW_SEND from "../../../images/post/instagram-preview-send.svg";
import INSTAGRAM_PREVIEW_COMMENT from "../../../images/post/instagram-preview-comment.svg";
import THREADS_PREVIEW_STATUS from "../../../images/post/threads-preview-status.png";
import THREADS_PREVIEW_REPOST from "../../../images/post/threads-preview-repost.svg";

import SQUARE_FACEBOOK from "../../../images/post/facebook-square.svg";
import SQUARE_YOUTUBE from "../../../images/post/youtube-square.svg";
import SQUARE_PINTEREST from "../../../images/post/pinterest-square.svg";
import SQUARE_INSTAGRAM from "../../../images/post/instagram-square.svg";
import SQUARE_THREADS from "../../../images/post/threads-square.svg";

import {
  PLATFORM_FACEBOOK,
  PLATFORM_INSTAGRAM,
  PLATFORM_PINTEREST,
  PLATFORM_THREADS,
  PLATFORM_YOUTUBE,
} from "../../../utils/Utils";
import PlayVideoIcon from "../../../images/post/play-video-icon.svg";
import { Img, ScrollIndicator } from "../../../../components/Component";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import moment from "moment";
import { shuffleContent } from "../../../../libs/helper";
import { statusFacebookBackground } from "../../../utils/status";
import Slider from "react-slick";

const formatContent = (content) => {
  return shuffleContent(content);
};

export const PreviewFaceBook = ({
  images,
  channels,
  isVideo,
  setting,
  content,
  carosel,
  video,
  selectedStatus,
  isDisabledStatus,
  status,
}) => {
  const faceChannels = channels?.channel.find(
    (item) => item.platform === PLATFORM_FACEBOOK && item.check === true
  );

  let previewStatus = null;

  if (status) {
    previewStatus = statusFacebookBackground.find((item) => item.id === status);
  }

  return (
    <>
      <div className="bg-white mb-3 rounded">
        <div className="pt-3">
          <div className="d-flex align-items-center px-3 justify-content-between">
            <div className="d-flex align-items-center">
              <div
                className={`rounded-circle bg-light position-relative`}
                style={{ height: "2rem", width: "2rem" }}
              >
                <Img
                  src={faceChannels?.picture}
                  className={`rounded-circle bg-light position-relative`}
                  style={{ height: "2rem", width: "2rem" }}
                />
              </div>
              <div className="ms-1 pl-2">
                <p
                  className="mb-0 text-body fs-12"
                  style={{ fontSize: "12px", color: "#212529" }}
                >
                  {faceChannels?.name}
                </p>
                <p
                  className="mb-0 text-muted fs-10"
                  style={{ fontSize: "10px", color: "#878A99" }}
                >
                  {moment(faceChannels?.created_at).format(
                    "HH:mm, Do MMMM YYYY"
                  )}
                </p>
              </div>
            </div>
            <Img
              src={SQUARE_FACEBOOK}
              style={{ height: "14px", width: "14px" }}
            />
          </div>
          {(status === undefined &&
            (selectedStatus === null || isDisabledStatus)) ||
          status === null ? (
            <p className="mt-2 fs-12 px-3 fw-normal text-dark text-break">
              {formatContent(content).substring(0, 120)}
              {formatContent(content).length > 120 && (
                <span className="text-[#878A99]">...More</span>
              )}
            </p>
          ) : (
            <div
              className="d-flex items-center justify-content-center status-wrap"
              style={{
                width: "100%",
                height: "228px",
                marginTop: "8px",
                padding: "12px",
                backgroundImage: `url(${
                  selectedStatus?.background || previewStatus?.background
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  textAlign: "center",
                  wordBreak: "break-word",
                  color: selectedStatus?.color || previewStatus?.color,
                }}
              >
                {formatContent(content).substring(0, 120)}
                {formatContent(content).length > 120 && (
                  <span className="text-[#878A99]">...More</span>
                )}
              </span>
            </div>
          )}

          {!isVideo && !carosel?.isOpen && images?.length > 0 && (
            <div
              className=" d-flex align-items-center flex-wrap justify-content-center mt-3 w-100 overflow-hidden"
              style={{ height: "auto" }}
            >
              {images && images.length === 1 && (
                <Img
                  src={
                    images[0]?.thumb || images[0]?.url
                      ? images[0]?.thumb || images[0]?.url
                      : URL.createObjectURL(images[0])
                  }
                  style={{
                    objectFit:
                      images[0]?.thumb || images[0]?.url ? "cover" : "fill",
                    height: "auto",
                    maxHeight: images[0]?.thumb || images[0]?.url ? 400 : 180,
                  }}
                  alt="preview"
                  className="w-100"
                />
              )}
              {images && images.length === 2 && (
                <>
                  <img
                    src={
                      images[0]?.thumb || images[0]?.url
                        ? images[0]?.thumb || images[0]?.url
                        : URL.createObjectURL(images[0])
                    }
                    style={{
                      objectFit:
                        images[0]?.thumb || images[0]?.url ? "cover" : "fill",
                      height: images[0]?.thumb || images[0]?.url ? 400 : 177,
                    }}
                    alt="preview"
                    className="w-50"
                  />
                  <img
                    src={
                      images[1]?.thumb || images[1]?.url
                        ? images[1]?.thumb || images[1]?.url
                        : URL.createObjectURL(images[1])
                    }
                    style={{
                      objectFit:
                        images[1]?.thumb || images[1]?.url ? "cover" : "fill",
                      height: images[1]?.thumb || images[1]?.url ? 400 : 177,
                    }}
                    alt="preview"
                    className="w-50"
                  />
                </>
              )}
              {images && images.length >= 3 && (
                <div class="container grid grid-cols-3 gap-0.5">
                  {images.map((item, index) => {
                    if (index < 3) {
                      return (
                        <div class="box w-full h-full overflow-hidden relative">
                          <Img
                            src={
                              item?.thumb || item?.url
                                ? item?.thumb || item?.url
                                : URL.createObjectURL(item)
                            }
                            style={{ aspectRatio: "1" }}
                            alt="preview"
                            class="object-cover"
                          />
                          {images.length > 3 && index === 2 && (
                            <div
                              class="absolute inset-0 justify-center items-center"
                              style={{ backgroundColor: "rgba(0,0,0,0.6)" }}
                            >
                              <span className="text-white">
                                +{images.length - 3}
                              </span>
                            </div>
                          )}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
            </div>
          )}
          {images && images.length > 1 && carosel.isOpen && (
            <div className=" d-flex align-items-center justify-content-center mt-3 w-100 overflow-hidden ">
              {images.slice(0, 2).map((item, index) => (
                <div className="position-relative w-50">
                  <Img
                    src={
                      item?.thumb || item?.url
                        ? item?.thumb || item?.url
                        : URL.createObjectURL(item)
                    }
                    style={{ objectFit: "fill", backgroundColor: "black" }}
                    width={"100%"}
                    height="177px"
                    alt={"image" + index}
                  ></Img>
                  <p className=" px-1 mt-1 mb-0 text-body fs-10 text-break text-truncate text-[##212529] font-medium">
                    {formatContent(carosel.data[index].title) ||
                      "this is title"}
                  </p>
                  <p className=" px-1 mb-0 text-muted fs-10 text-break text-truncate">
                    {formatContent(carosel.data[index].desc) ||
                      "this is subcription"}
                  </p>
                </div>
              ))}
            </div>
          )}
          {isVideo && (
            <Img src={video[0]?.thumb} style={{ maxHeight: "auto" }} />
          )}
          <div
            className="d-flex justify-content-between align-items-center border-bottom border-light"
            style={{ padding: "10px 12px" }}
          >
            <div className="d-flex gap-2 align-items-center">
              <Img height={"20px"} src={FACEBOOK_PREVIEW_STATUS}></Img>
              <span className="fs-13 text-body">369</span>
            </div>
            <span className="text-body fs-13">{"26 Comments   87 Shares"}</span>
          </div>
          <div className="d-flex justify-content-between align-items-center p-3">
            <div className="d-flex align-items-center gap-1">
              <Img src={FACEBOOK_PREVIEW_LIKE} height={"16px"}></Img>
              <span className="fs-13 fw-medium" style={{ color: "#495057" }}>
                Like
              </span>
            </div>
            <div className="d-flex align-items-center gap-1">
              <Img src={FACEBOOK_PREVIEW_COMMENT} height={"16px"}></Img>
              <span className="fs-13 fw-medium" style={{ color: "#495057" }}>
                Comment
              </span>
            </div>
            <div className="d-flex align-items-center gap-1">
              <Img src={FACEBOOK_PREVIEW_SHARE} height={"16px"}></Img>
              <span className="fs-13 fw-medium" style={{ color: "#495057" }}>
                Share
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const PreviewInstagram = ({
  images,
  channels,
  video,
  isVideo,
  setting,
  content,
  carosel,
}) => {
  const channel = channels?.channel.find(
    (item) => item.platform === PLATFORM_INSTAGRAM && item.check === true
  );
  const indicatorRef = useRef(null);
  return (
    <>
      <div className="bg-white mb-3 rounded ">
        <div className="pt-3">
          <div className="d-flex align-items-center px-3 justify-content-between">
            <div className="d-flex align-items-center">
              <div
                className={`rounded-circle bg-light position-relative`}
                style={{ height: "32px", width: "32px" }}
              ></div>
              <div className="ms-1 pl-2">
                <p
                  className="mb-0 text-body fs-12"
                  style={{ fontSize: "12px", color: "#212529" }}
                >
                  {channel?.name}
                </p>
                <p
                  className="mb-0 text-muted fs-10"
                  style={{ fontSize: "10px", color: "#878A99" }}
                >
                  Now
                </p>
              </div>
            </div>
            <Img
              src={SQUARE_INSTAGRAM}
              style={{ height: "14px", width: "14px" }}
            ></Img>
          </div>

          {isVideo ? (
            <Img
              class="mt-2"
              src={video[0]?.thumb}
              style={{ maxHeight: "auto" }}
            />
          ) : (
            <Swiper
              style={
                {
                  // width: "269",
                  // height: "200px",
                }
              }
              className="bg-[#395996] overflow-hidden items-center mt-3"
              onActiveIndexChange={(item) => {
                indicatorRef.current?.onSwipe(item?.activeIndex);
              }}
              spaceBetween={50}
              slidesPerView={1}
              pagination={{ clickable: true }}
            >
              {images.map((item, index) => {
                const imagePostDetail = item?.thumb || item?.url;
                return (
                  <SwiperSlide
                    key={index + "image"}
                    className="grid place-items-center text-center"
                  >
                    <Img
                      src={
                        imagePostDetail
                          ? imagePostDetail
                          : URL.createObjectURL(item)
                      }
                      style={{
                        width: imagePostDetail ? "100%" : "300px",
                        height: imagePostDetail ? "auto" : "200px",
                        objectFit: imagePostDetail ? "cover" : "none",
                        maxHeight: "450px",
                      }}
                      alt={"preview" + index}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
          {images?.length > 1 && images?.length <= 10 && (
            <ScrollIndicator ref={indicatorRef} dataLength={images?.length} />
          )}
          <div
            className="p-3"
            style={{ marginTop: images?.length > 1 ? -26 : 0 }}
          >
            <div className="d-flex justify-content-start align-items-center pb-2">
              <div className="d-flex align-items-center pr-1">
                <Img src={INSTAGRAM_PREVIEW_HEART} height={"16px"}></Img>
              </div>
              <div className="d-flex align-items-center pr-1">
                <Img src={INSTAGRAM_PREVIEW_COMMENT} height={"16px"}></Img>
              </div>
              <div className="d-flex align-items-center ">
                <Img src={INSTAGRAM_PREVIEW_SEND} height={"16px"}></Img>
              </div>
            </div>

            <div className="d-flex justify-content-start align-items-center pb-2">
              <Img height={"20px"} src={INSTAGRAM_PREVIEW_STATUS}></Img>
              <span className="fs-12 fw-bold text-body">
                Liked by KNE and 115 213 others
              </span>
            </div>
            <p className="mb-0 text-truncate text-nowrap fs-12 fw-normal text-dark">
              {formatContent(content)}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export const PreviewPinterest = ({ images, channels, content }) => {
  const channel = channels?.channel.find(
    (item) => item.platform === PLATFORM_PINTEREST && item.check === true
  );
  return (
    <>
      <div className="bg-white mb-3 rounded ">
        <div className="pt-3">
          <div className="d-flex align-items-center px-3 justify-content-end">
            <Img
              src={SQUARE_PINTEREST}
              style={{ height: "14px", width: "14px" }}
            ></Img>
          </div>

          {images && images.length > 0 && (
            <div
              className=" d-flex align-items-center justify-content-center mt-3 w-100 overflow-hidden"
              style={{ height: "210px", width: "100%" }}
            >
              <Img
                src={
                  images[0]?.thumb || images[0]?.url
                    ? images[0]?.thumb || images[0]?.url
                    : URL.createObjectURL(images[0])
                }
                width={"100%"}
                height="auto"
              ></Img>
            </div>
          )}

          <div className="p-3">
            <div className="d-flex align-items-center justify-content-between pb-3">
              <div className="d-flex align-items-center">
                <div
                  className={`rounded-circle bg-light position-relative`}
                  style={{ height: "32px", width: "32px" }}
                ></div>
                <div className="ms-1">
                  <p className="mb-0 text-body fs-12">{channel?.name}</p>
                  <p className="mb-0 text-muted fs-10">Now</p>
                </div>
              </div>
              <span
                className="fs-11 fw-normal text-body bg-light rounded-pill"
                style={{ padding: "5px 12px" }}
              >
                Follow
              </span>
            </div>
            <p className="mb-0 text-truncate text-nowrap fs-12 fw-normal text-dark">
              {formatContent(content)}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export const PreviewYouTube = ({ video, channels, content, images }) => {
  const channel = channels?.channel.find(
    (item) => item.platform === PLATFORM_YOUTUBE && item.check === true
  );

  return (
    <>
      <div className="bg-white mb-3 rounded ">
        <div className="pt-3">
          <div className="d-flex align-items-center px-3 justify-content-end">
            <Img
              src={SQUARE_YOUTUBE}
              style={{ height: "14px", width: "14px" }}
            />
          </div>
          <div
            className=" d-flex align-items-center justify-content-center mt-3 w-100 overflow-hidden bg-[#395996]"
            style={{ minHeight: "185px", maxHeight: "400px" }}
          >
            <Img
              src={
                video[0]?.thumb
                  ? video[0]?.thumb
                  : images?.[0]
                  ? URL.createObjectURL(images?.[0])
                  : null
              }
              style={{ maxHeight: "auto" }}
            />
          </div>

          <div className="p-3">
            <div className="d-flex align-items-center justify-content-between pb-3 ">
              <div className="d-flex justify-content-start align-items-center w-100">
                <div
                  className={`rounded-circle bg-light position-relative`}
                  style={{ height: "32px", width: "32px" }}
                ></div>
                <div className="ml-1.5" style={{ width: "85%" }}>
                  <p
                    className="mb-0 text-body text-nowrap text-truncate fs-12"
                    style={{ fontSize: "12px", color: "#212529" }}
                  >
                    {formatContent(content)}
                  </p>
                  <p
                    className="mb-0 text-muted no-wrap text-truncate fs-10"
                    style={{ fontSize: "10px", color: "#878A99" }}
                  >
                    {channel?.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const PreviewShort = ({ isVideo, video, content, images, title }) => {
  const renderImage = (src) => (
    <Img
      src={src}
      height={"297px"}
      width={"166px"}
      className={"bg-[#395996]"}
      style={{ borderRadius: "6px" }}
    />
  );

  return (
    <>
      <p style={{ fontSize: 11 }}>{title}</p>
      <div
        className="d-flex justify-content-center mb-3 rounded position-relative overflow-hidden"
        style={{ backgroundColor: "rgba(75, 56, 179, 0.2)", height: "296px" }}
      >
        {/* <image src={IconPlay} className="absolute" /> */}
        {isVideo
          ? renderImage(
              video?.[0]?.thumb ? video?.[0]?.thumb : video?.[0]?.tiktokThumb
            )
          : renderImage(
              images.length > 0
                ? images[0]?.thumb || images[0]?.url
                  ? images[0]?.thumb || images[0]?.url
                  : URL.createObjectURL(images[0])
                : null
            )}
        <div
          className="h-25 position-absolute w-100 bottom-0"
          style={{
            background:
              "linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))",
          }}
        />
        <div
          className="h-25 position-absolute w-100 top-0"
          style={{
            background:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))",
          }}
        />
        <div
          className="w-50  text-truncate mb-2 justify-content-center position-absolute bottom-0 fs-10 text-white"
          style={{ maxHeight: "24px" }}
        >
          {formatContent(content)}
        </div>
      </div>
    </>
  );
};

export const PreviewThreads = ({
  content,
  channels,
  images,
  isVideo,
  video,
  isManage,
}) => {
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(42);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.clientHeight - 8);
    }
    if (!isManage && images.length === 1) {
      setContentHeight(232);
    }
  }, [content, images, isVideo, video]);

  const channel = channels?.channel.find(
    (item) => item.platform === PLATFORM_THREADS && item.check === true
  );

  const formatThreads = (content) => {
    const lines = content.split("\n");
    let formattedContent = [];

    lines.forEach((line) => {
      const words = line.split(" ");
      let foundHashtag = false;

      words.forEach((word) => {
        if (word.startsWith("#") && !foundHashtag) {
          foundHashtag = true;
          formattedContent.push(
            <span className="mention">{word.substring(1)} </span>
          );
        } else if (word.startsWith("@")) {
          formattedContent.push(<span className="mention">{word} </span>);
        } else {
          formattedContent.push(word + " ");
        }
      });

      formattedContent.push(<br />);
    });

    return formattedContent;
  };

  const settings = {
    swipeToSlide: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
  };

  return (
    <>
      <div className="bg-white mb-3 rounded ">
        <div className="pt-3">
          <div className="d-flex align-items-center px-3 justify-content-between">
            <div className="d-flex align-items-center">
              <div
                className={`rounded-circle bg-light position-relative`}
                style={{ height: "2rem", width: "2rem" }}
              >
                <Img
                  src={channel?.picture}
                  className={`rounded-circle bg-light position-relative`}
                  style={{ height: "2rem", width: "2rem" }}
                />
              </div>
              <div className="ms-1 pl-1">
                <p
                  className="mb-0 text-body fs-12"
                  style={{ fontSize: "12px", color: "#212529" }}
                >
                  {channel?.name}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <p
                className="mb-0 mr-2 text-muted fs-10"
                style={{ fontSize: "10px", color: "#878A99" }}
              >
                Now
              </p>
              <Img
                src={SQUARE_THREADS}
                style={{ height: "14px", width: "14px" }}
              ></Img>
            </div>
          </div>
          <div className="px-3 pb-2">
            <div className=" d-flex h-100 items-center">
              <div
                className="mr-4 ml-3"
                style={{
                  height: `${contentHeight}px`,
                  borderRight: "2px solid #ccc",
                }}
              ></div>
              <div
                className="text-wrap"
                style={{
                  maxWidth: !isManage
                    ? images.length === 1
                      ? ""
                      : "255px"
                    : "400px",
                  width: !isManage && images.length === 1 ? "255px" : "",
                  wordWrap: "break-word",
                  whiteSpace: "pre-wrap",
                }}
                ref={contentRef}
              >
                <p className="mb-0 text-wrap fs-12 fw-normal text-dark">
                  {formatThreads(content)}
                </p>

                {images && images.length === 1 && (
                  <Img
                    src={
                      images[0]?.thumb || images[0]?.url
                        ? images[0]?.thumb || images[0]?.url
                        : URL.createObjectURL(images[0])
                    }
                    style={{
                      objectFit:
                        images[0]?.thumb || images[0]?.url ? "cover" : "fill",
                      height: "auto",
                      maxHeight: images[0]?.thumb || images[0]?.url ? 400 : 180,
                    }}
                    alt="preview"
                    className="w-100"
                  />
                )}

                {images && images.length === 2 && (
                  <div className="d-flex gap-2 justify-content-center h-100 w-100">
                    {images.slice(0, 2).map((image, index) => {
                      const imageUrl =
                        image?.thumb || image?.url || URL.createObjectURL(image);
                      const objectFit =
                        image?.thumb || image?.url ? "cover" : "fill";
                      const height = 177;

                      return (
                        <img
                          key={index}
                          src={imageUrl}
                          style={{ objectFit, height, width: "50%" }}
                          alt="preview"
                        />
                      );
                    })}
                  </div>
                )}

                {images && images.length > 2 && (
                  <Slider {...settings}>
                    {images.map((image, index) => (
                      <div key={index} className="mr-1">
                        <img
                          src={
                            image.thumb || image.url
                              ? image.thumb || image.url
                              : URL.createObjectURL(image)
                          }
                          style={{
                            objectFit:
                              image.thumb || image.url ? "cover" : "fill",
                            height: 177,
                          }}
                          alt="preview"
                        />
                      </div>
                    ))}
                  </Slider>
                )}

                {isVideo && (
                  <div className="relative">
                    <Img src={video[0]?.thumb} style={{ maxHeight: "auto" }} />
                    <img
                      src={PlayVideoIcon}
                      alt="play-icon"
                      style={{ position: "absolute", top: "40%", right: "40%" }}
                    />
                  </div>
                )}

                <div className="d-flex justify-content-start align-items-center py-2 ml-1">
                  <div className="d-flex align-items-center pr-1">
                    <Img
                      src={INSTAGRAM_PREVIEW_HEART}
                      height={"16px"}
                      width={"16px"}
                    ></Img>
                  </div>
                  <div className="d-flex align-items-center pr-1">
                    <Img
                      src={INSTAGRAM_PREVIEW_COMMENT}
                      height={"16px"}
                      width={"16px"}
                    ></Img>
                  </div>
                  <div className="d-flex align-items-center pr-1">
                    <Img
                      src={THREADS_PREVIEW_REPOST}
                      height={"16px"}
                      width={"16px"}
                    ></Img>
                  </div>
                  <div className="d-flex align-items-center ">
                    <Img
                      src={INSTAGRAM_PREVIEW_SEND}
                      height={"16px"}
                      width={"16px"}
                    ></Img>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-start align-items-center pb-2">
              <Img height={"20px"} src={THREADS_PREVIEW_STATUS}></Img>
              <span className="fs-12 text-body pl-2">
                2 replies / People liked your content
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
