import { Input, UncontrolledTooltip } from "reactstrap";

import { useEffect, useState } from "react";
import { getPlatformSquareIcon } from "../../../../libs/helper";
import { Img } from "../../../../components/Component";
import {
  PLATFORM_FACEBOOK,
  PLATFORM_FACEBOOKGROUP,
  PLATFORM_INSTAGRAM,
  PLATFORM_TIKTOK,
  PLATFORM_YOUTUBE,
} from "../../../utils/Utils";
import { RiQuestionAnswerLine } from "@remixicon/react";
import IconQuestion from "../../../../assets/images/icon/icon-question.svg";
import { useTranslation } from "react-i18next";

const FirstComment = ({ listChannel, setListChannel, setFirstCommentSetting }) => {

  const { t } = useTranslation();
  const channelData = listChannel?.channel?.filter(
    (item) =>
      (item.platform === PLATFORM_FACEBOOK ||
        item.platform === PLATFORM_FACEBOOKGROUP ||
        item.platform === PLATFORM_TIKTOK ||
        item.platform === PLATFORM_YOUTUBE) &&
      item.check === true
  );

  const [isDisable, setIsDisable] = useState(true);

  const handleChooseChannel = (id) => {
    const data = [...listChannel.channel];
    const newData = data.map((item) => {
      if (item._id === id) {
        item.commentCheck = !item.commentCheck;
      }
      return item;
    });

    setListChannel({
      ...listChannel,
      channel: newData,
    });
  };
  const handleChangeContent = (e) => {
    !isDisable && listChannel.channel.length && setFirstCommentSetting({
      isOpen: true,
      content: e.target.value
    })
  }

  return (
    <div className={`rounded p-2 ${!isDisable && "mt-3 color-border border-1"}`}>
      <div className="d-flex justify-content-between gap-3">
        <div className="d-flex align-items-center">
          <div
            className="d-flex justify-content-center align-items-center rounded position-relative"
            style={{
              background: "rgba(243,246,249,1)",
              minWidth: "36px",
              minHeight: "36px",
            }}
          >
            <RiQuestionAnswerLine
              size={16}
              className="rounded"
            ></RiQuestionAnswerLine>
          </div>
          <p
            className="fs-13 ms-2 mb-0 text-nowrap"
            style={{ color: "#495057" }}
          >
            {t('store_post.first_comment.title')}
          </p>
          <img
            src={IconQuestion}
            alt="link"
            id="firstCommentToolTip"
            className="cursor-pointer ms-2"
            style={{ marginRight: 25 }}
          />

          <UncontrolledTooltip
            target={"firstCommentToolTip"}
            style={{
              minWidth: "300px",
              backgroundColor: "#ffffff",
              boxShadow:
                "0 2px 8px 0 rgba(0, 0, 0, 0.3), 0 4px 20px rgba(0, 0, 0, 0.2)",
            }}
          >
            <div style={{ minWidth: "300px" }} className="text-dark fs-13">
              <p className="mb-0 text-left fs-13 text-dark">
                {t('store_post.first_comment.tooltip')}
              </p>
              <div className="d-flex align-items-center ml-1 my-1 fs-13">
                <Img
                  src={getPlatformSquareIcon(PLATFORM_FACEBOOK)}
                  className="mr-1"
                  height={"24px"}
                ></Img>
                Facebook
              </div>
              <div className="d-flex align-items-center my-1 ml-1 fs-13">
                <Img
                  src={getPlatformSquareIcon(PLATFORM_YOUTUBE)}
                  className="mr-1"
                  height={"24px"}
                ></Img>
                YouTube
              </div>
              <div className="d-flex align-items-center my-1 ml-1 fs-13">
                <Img
                  src={getPlatformSquareIcon(PLATFORM_TIKTOK)}
                  className="mr-1"
                  height={"24px"}
                ></Img>
                Tiktok
              </div>
            </div>
          </UncontrolledTooltip>
          <div
            class="custom-control-sm custom-switch ms-3"
            style={{ marginTop: 6 }}
          >
            <input
              class="custom-control-input"
              checked={!isDisable}
              onChange={() => setIsDisable(!isDisable)}
              type="checkbox"
              id="CommentSwitch"
            />
            <label
              className="custom-control-label switchControlCompose"
              for="CommentSwitch"
            ></label>
          </div>
        </div>
        {!isDisable && (
          <div className="d-flex align-items-center gap-2 flex-wrap">
            {channelData &&
              channelData?.map((item) => (
                <div
                  className={`rounded bg-light position-relative cursor-pointer overflow-hidden ${!item.commentCheck && "border border-2 border-success"
                    }`}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={item.name}
                  style={{ height: "32px", width: "32px" }}
                >
                  <Img
                    className="h-100 w-100 rounded"
                    onClick={() => handleChooseChannel(item._id)}
                    style={
                      item.commentCheck ? { filter: "grayscale(100%)" } : {}
                    }
                    src={item.picture}
                  ></Img>
                  <Img
                    className="position-absolute bottom-0 end-0"
                    style={
                      item.commentCheck ? { filter: "grayscale(100%)" } : {}
                    }
                    src={getPlatformSquareIcon(item.platform)}
                    height={"14px"}
                    width="14px"
                  ></Img>
                </div>
              ))}
          </div>
        )}
      </div>

      {!isDisable && (
        <Input
          className={"mt-2 border-0"}
          type="textarea"
          disabled={isDisable}
          style={{ height: "80px", backgroundColor: "rgba(251, 252, 252, 1)" }}
          placeholder={t('store_post.first_comment.placeholder')}
          onChange={(e) => handleChangeContent(e)}
        ></Input>
      )}
    </div>
  );
};

export default FirstComment;
