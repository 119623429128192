import React, { useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { RedirectAs404 } from "./utils/Utils";
import PrivateRoute from "./route/PrivateRoute";

import Layout from "./layout/Index";

import Error404Classic from "./pages/error/404-classic";
import Error404Modern from "./pages/error/404-modern";
import Error504Modern from "./pages/error/504-modern";
import Error504Classic from "./pages/error/504-classic";
import UpgradeZalo from "./So9/pages/ConnectChannel/upgrade_zalo";

import Faq from "./pages/others/Faq";
import Terms from "./pages/others/Terms";
import Policy from "./pages/others/Policy";

import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Success from "./pages/auth/Success";
import SuccessResetPassword from "./pages/auth/SuccessResetPassword";
import Verify from "./pages/auth/Verify";
import InvoicePrint from "./pages/pre-built/invoice/InvoicePrint";
import VerifyChangePassword from "./pages/auth/VerifyChangePassword";
import ChangePassword from "./pages/auth/ChangePassword";
import "react-toastify/dist/ReactToastify.css";
import { QueryClientProvider, QueryClient } from "react-query";
import Echo from "laravel-echo";
import { onReceiveWSChat as chatOnReceiveWSChat } from "./So9/reducers/chatSlice";
import {
  onReceiveWSChat as conversationOnReceiveWSChat,
  setConversationUnread,
} from "./So9/reducers/conversationSlice";
import { setErrorConnectChannelMesssages } from "./So9/reducers/channelSlice";
import { setIcon } from "./So9/reducers/iconSlice";
import {
  setChannelsInformation,
  setChannelInf,
} from "./So9/reducers/channelSlice";
import { setTimezone } from "./So9/reducers/timezoneSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { getProject, loadListChannel } from "./api/project";
import { getConversationUnread } from "./api/conversation";
import { GetlistIcon } from "./api/createPost";
import Welcome from "./pages/auth/welcome";
import ConnectPage from "./pages/auth/ConnectPage";
import SimpleReactLightbox from "simple-react-lightbox";
import { useFirstRender } from "./hooks/useFirstTimeRender";
import "./App.css";
import PostDetail from "./So9/pages/PostDetail";
import ListSharePostDetail from "./So9/pages/PostDetail/components/ListSharePostDetail";
import useWebSocket from "./hooks/useWebSocket";
import moment from "moment";
import "moment-timezone";

const App = () => {
  const queryClient = new QueryClient();
  const dispatch = useDispatch();
  const timezone = useSelector((state) => state.timezone.timezone);

  useFirstRender();
  useWebSocket();
  function initializeWebSocket() {
    const userInfor = JSON.parse(localStorage.getItem("userInfor"));
    window.io = require("socket.io-client");
    if (userInfor) {
      const urlSocket = process.env.REACT_APP_WS_URL;
      const crmSocket = new Echo({
        broadcaster: "socket.io",
        authEndpoint: "/broadcasting/auth",
        host: urlSocket,
        transports: ["websocket"],
        auth: {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        },
      });
      crmSocket
        .channel("conversation-common-" + userInfor.last_project_active)
        .listen(".user-chat", (e) => {
          if (e.data) {
            dispatch(chatOnReceiveWSChat(e.data));
            dispatch(conversationOnReceiveWSChat(e.data));
          }
        });
      crmSocket
        .channel("notify-project-" + userInfor.last_project_active)
        .listen(".upload-facebook-notification", (e) => {
          if (e.content) {
            if (e.status) {
              toast.success(e.content, {
                position: "top-center",
                autoClose: true,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: false,
              });
            } else {
              toast.error(e.content, {
                position: "top-center",
                autoClose: true,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: false,
              });
            }
          }
        });
      crmSocket
        .channel("conversation-common-" + userInfor.last_project_active)
        .listen(".convesationUnRead", (e) => {
          if (e.convesationUnRead) {
            dispatch(setConversationUnread(e.convesationUnRead));
          }
        });
      crmSocket
        .channel("channel-connect-error-" + userInfor.last_project_active)
        .listen(".message", (e) => {
          if (e.arrayMessage) {
            dispatch(setErrorConnectChannelMesssages(e.arrayMessage));
          }
        });
    }
  }
  useEffect(() => {
    GetlistIcon()
      .then((response) => {
        if (response.status) {
          dispatch(setIcon(response.data));
        }
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const userInformation = JSON.parse(localStorage.getItem("userInfor"));
    if (!userInformation || !userInformation.last_project_active) return;
    const accessToken = window.localStorage.getItem("accessToken") ?? "";
    const projectId = userInformation.last_project_active;
    if (accessToken) {
      fetchTimezone(projectId);
      fetchListChannels(projectId);
      fetchUnread(projectId);
    }
  }, []);

  useEffect(() => {
    const userInformation = JSON.parse(localStorage.getItem("userInfor"));
    const projectId = userInformation?.last_project_active;
    projectId && fetchTimezone(projectId);
  }, [timezone]);

  const fetchTimezone = async (projectId) => {
    getProject(projectId)
      .then((response) => {
        if (response.status) {
          moment.tz.setDefault(response.time_zone);
          dispatch(setTimezone(response.time_zone));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchListChannels = async (projectId) => {
    loadListChannel(projectId, {
      status: 1,
    })
      .then((response) => {
        if (response.status) {
          dispatch(setChannelsInformation(response.data.channel));
          if (response?.data?.channel?.length > 0) {
            dispatch(setChannelInf(response.data.channel));
          } else {
            dispatch(setChannelInf([]));
          }
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch(setChannelInf([]));
      });
  };

  const fetchUnread = async (projectId) => {
    getConversationUnread({
      params: {
        project_id: projectId,
      },
    })
      .then((response) => {
        if (response.status) {
          dispatch(setConversationUnread(response.data));
        }
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    initializeWebSocket();
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <SimpleReactLightbox>
        <Switch>
          {/* Auth Pages */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/auth-success`}
            component={Success}
          ></Route>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/reset-password-success`}
            component={SuccessResetPassword}
          ></Route>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/verify-email/:token`}
            component={Verify}
          ></Route>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/verify-change-password/:token`}
            component={VerifyChangePassword}
          ></Route>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/change-password`}
            component={ChangePassword}
          ></Route>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/auth-reset`}
            component={ForgotPassword}
          ></Route>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/auth-register`}
            component={Register}
          ></Route>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/auth-login`}
            component={Login}
          ></Route>

          {/* Print Pages */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/invoice-print/:id`}
            component={InvoicePrint}
          ></Route>

          {/* Onboarding Pages */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/welcome`}
            component={Welcome}
          ></Route>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/connect-page/:id`}
            component={ConnectPage}
          ></Route>
          {/* Helper pages */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/auths/terms`}
            component={Terms}
          ></Route>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/auths/policy`}
            component={Policy}
          ></Route>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/auths/faq`}
            component={Faq}
          ></Route>

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/invoice-print`}
            component={InvoicePrint}
          ></Route>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/:postId/post-detail/:projectId`}
            component={PostDetail}
          ></Route>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/list-post-share/:shareId`}
            component={ListSharePostDetail}
          ></Route>
          {/*Error Pages*/}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/errors/404-classic`}
            component={Error404Classic}
          ></Route>
          <Route
            exact
            path={`${process.env.PUBLIC_URL} /errors/504-modern`}
            component={Error504Modern}
          ></Route>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/errors/404-modern`}
            component={Error404Modern}
          ></Route>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/errors/504-classic`}
            component={Error504Classic}
          ></Route>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/errors/upgrade-zalo`}
            component={UpgradeZalo}
          ></Route>

          <PrivateRoute exact path="" component={Layout}></PrivateRoute>
          <Route component={RedirectAs404}></Route>
        </Switch>
      </SimpleReactLightbox>
      <ToastContainer />
    </QueryClientProvider>
  );
};
export default withRouter(App);
