import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import style from "./index.module.scss";
import { PaginationCustom } from "../../../components/Component";
import {Spinner} from "reactstrap"
import {getDetailByDate} from "../../../api/iris";
const order =
{
  "status": true,
  "data": {
    "current_page": 1,
    "data": [
      {
        "project_name": "ngockhanh.ftu50",
        "type": "pro",
        "connection": "1000",
        "amount": 200000,
        "revenue": 20000,
        "email": "",
        "phone": "",
        "hour_created": "15:01",
        "status": "2",
        "code": "MDH1"
      }
    ],
    "first_page_url": "http://localhost:8000/api/v1/affiliates/dashboard/detail?page=1",
    "from": 1,
    "last_page": 1,
    "last_page_url": "http://localhost:8000/api/v1/affiliates/dashboard/detail?page=1",
    "links": [
      {
        "url": null,
        "label": "&laquo; Previous",
        "active": false
      },
      {
        "url": "http://localhost:8000/api/v1/affiliates/dashboard/detail?page=1",
        "label": "1",
        "active": true
      },
      {
        "url": null,
        "label": "Next &raquo;",
        "active": false
      }
    ],
    "next_page_url": null,
    "path": "http://localhost:8000/api/v1/affiliates/dashboard/detail",
    "per_page": 10,
    "prev_page_url": null,
    "to": 1,
    "total": 1
  },
  "message": "success"
}

const ModalDetailByOrder = ({ ...props }) => {
  const [loading, setLoading] = useState(false);
  const [currentPageOrder, setCurrentPageOrder] = useState(1);
  const [curentModalData, setCurentModalData] = useState();
  const { isOpen, toggle, date } = props;
  const handlePageChange = (newPage) => {
    setCurrentPageOrder(newPage);
    setLoading(true);
  };

  const getCurentModalData = async () => {
    setLoading(true);
    try{
    const _data = await getDetailByDate({page:currentPageOrder,fromDate:date,toDate:date,type:"order"});
    if (_data?.status) {
      setCurentModalData(_data);
      setLoading(false);
    }
  } catch (err) {

    setLoading(false);
  }
}

useEffect(()=>{
    getCurentModalData();
    // setCurentModalData(order);
},[date])

useEffect(()=>{
  getCurentModalData();
},[currentPageOrder])
const formatCurrency = (value) => {
  return value && value.toLocaleString('vi-VN', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }) + ' đ';
};
  return (
    <Modal size="xl" isOpen={isOpen} toggle={toggle} style={{minWidth:"90%"}}>
      <ModalBody className="m-0 px-4 py-3" style={{minHeight:"500px"}}>
        <div className="px-2 mb-4" style={{ borderLeft: "4px solid #D53784" }}>
          <h6>THỐNG KÊ ĐƠN HÀNG ĐƯỢC TẠO NGÀY {date}</h6>
        </div>
        <div className="table-responsive">
          <table className={`table `} style={{ minHeight: "160px" }} >
          <thead style={{ background: "#F1F5FF" }}>
            <tr>
              <th
                style={{ width: "8%" }}
                className={`${style.headPost} text-left px-2`}
              >
                Mã đơn
              </th>
              <th
                style={{ width: "7%" }}
                className={`${style.headPost} text-left `}
              >
                Giờ tạo
              </th>
              <th
                style={{ width: "10%" }}
                className={`${style.headPost} text-left `}
              >
                DỰ ÁN
              </th>
              <th
                style={{ width: "10%" }}
                className={`${style.headPost} text-left `}
              >
                Email
              </th>
              <th
                style={{ width: "13%" }}
                className={`${style.headPost} text-left `}
              >
                Trạng thái
              </th>
              <th
                style={{ width: "8%" }}
                className={`${style.headPost} text-left `}
              >
                Gói cước
              </th>
              <th
                style={{ width: "8%" }}
                className={`${style.headPost}  text-right`}
              >
                Số Kênh
              </th>
              <th
                style={{ width: "12%" }}
                className={`${style.headPost}  text-right`}
              >
                Đơn giá
              </th>
              <th
                style={{ width: "15%" }}
                className={`${style.headPost}  text-right px-2`}
              >
                Thu nhập dự kiến
              </th>
            </tr>
          </thead>
          <tbody style={{ backgroundColor: "#fff", position: "relative" }}>
            {!loading ?
              (curentModalData ? 
                (curentModalData?.data?.data?.length ? 
                  (curentModalData?.data?.data?.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    fontSize: "12px",
                    cursor: "pointer",
                    transition: "background-color 0.1s ease",
                  }}
                  className={`${style.hoveredRow}`}
                >
                  <td className={`${style.truncateCell} ${style.Td} text-left`}>
                    {item.code}
                  </td>
                  <td className={`${style.truncateCell} ${style.Td} text-left`}>
                    {item.hour_created}
                  </td>
                  <td className={`${style.truncateCell} ${style.Td} text-left`}>
                    {item.project_name}
                  </td>
                  <td className={`${style.truncateCell} ${style.Td} text-left`}>
                    {item?.email === "" ? "-----" : item?.email}
                  </td>
                  <td
                    className={`${style.truncateCell} ${style.Td} text-left`}
                    style={{
                      color:
                        item.status === 2 ? ("#219653") :
                          (item.status === 1 ? ("#0EA5E9") :
                            (item.status === 0 ? ("#E11D48") :
                              (item.status === 3 ? ("#0EA5E9") :
                                (item.status === 4 ? "#E11D48" : ""))))
                    }}
                  >
                    {
                      item.status === 2 ? ("Hoàn thành") :
                        (item.status === 1 ? ("Gửi yêu cầu") :
                          (item.status === 0 ? ("Hủy") :
                            (item.status === 3 ? ("Chờ xác nhận") :
                              (item.status === 4 ? "Hoàn tiền" : ""))))
                    }
                  </td>
                  <td className={`${style.truncateCell} ${style.Td} text-left`}>
                    {item.type}
                  </td>

                  <td
                    className={`${style.truncateCell} ${style.Td} text-right`}
                  >
                    {item.connection}
                  </td>
                  <td
                    className={`${style.truncateCell} ${style.Td} text-right`}
                  >
                    {formatCurrency(item.amount)} 
                  </td>
                  <td
                    className={`${style.truncateCell} ${style.Td} text-right `}
                  >
                    {formatCurrency(item.revenue)} 
                  </td>
                </tr>
              ))) :
                (<>
                  <div style={{ position: "absolute", top: "30%", left: "45%" }}>
                    <p style={{ color: "#9CA3AF" }}>Chưa có dữ liệu</p>
                  </div>
                </>)) : (<>
                  <div style={{ position: "absolute", top: "30%", left: "45%" }}>
                    <p style={{ color: "#9CA3AF" }}>Chưa có dữ liệu</p>
                  </div>
                </>)
                 ) : (
                <>
                  <div style={{ position: "absolute", top: "30%", left: "45%" }}>
                    <Spinner size="32px" color="light"/>
                  </div>
                </>)}
          </tbody>
        </table>
        </div>
        
       <div className={`${style['pgnblock']}`}>
        {
          curentModalData ?
            (curentModalData?.data?.data?.length ? (
              <div className="mobile-paginate">
                 <PaginationCustom
                pageSize={curentModalData?.data?.per_page}
                totalCount={curentModalData?.data?.total || 0}
                siblingCount={1}
                onPageChange={handlePageChange}
                currentPage={currentPageOrder}
                className={`manage_post_pagination`}
              />
              </div>
             ) : (<></>)) : (<></>)
        }
      </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalDetailByOrder;
