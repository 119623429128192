import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSkip: false,
};

export const connectSlice = createSlice({
  name: "connectModal",
  initialState,
  reducers: {
    setIsSkip(state, action) {
      state.isSkip = action.payload;
    },
  },
});

export const { setIsSkip } = connectSlice.actions;

export default connectSlice.reducer;
