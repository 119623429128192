import { Icon, RSelect } from "../../../components/Component";
import ContentAlt from "../../../layout/content/ContentAlt";
import { IconConnectChannel } from "../../components/svgIcon";
import Column from "./Column";
import InitialColumn from "./InitialColumn";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import style from "./index.module.scss";
import styleConnect from "../../components/ConnectSocialNetwork/index.module.scss";
import { setCollapseSidebarLeft } from "../../reducers/layoutSlice";
import ModalAddPage from "./ModalAddPage";
import { getFollowingList } from "../../../api/researchPage";
import { setChannelFollows } from "../../reducers/researchCompetitorSlice";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getChannels } from "../../../api/createPost";

export default function ResearchPage({
  isOpen,
  handlePost,
  isSelectPage,
  showModalAddFanPage,
  setCurrentPage,
}) {
  const initSeeding = {
    user_seeding: null,
    scheduled_at: new Date(),
  };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project.project);
  const channelFollow = useSelector((state) => state.research.channels);
  const [loadSeeding, setLoadSeeding] = useState(false);
  const [ListUser, setListUser] = useState([]);
  const [formSeeding, setSeeding] = useState(initSeeding);

  const [valueSeedingSelected, setValueSeedingSelected] = useState(ListUser[0]);
  const [dataSelect, setDataSelect] = useState([]);

  const handleSeedingValueChange = (value) => {
    setValueSeedingSelected(ListUser.find((u) => u._id == value.value));
    console.log(value);
  };

  const selectBoxPageItem = (itemChannel) => {
    return {
      label: itemChannel?.name,
      value: itemChannel?._id,
      image: itemChannel?.picture,
    };
  };

  const defaultValueSelectPage = selectBoxPageItem(formSeeding.user_seeding);

  const getListUser = () => {
    setLoadSeeding(true);
    const data = {
      platform: 1,
    };
    const fetchData = async () => {
      return await getChannels(project._id, data);
    };
    fetchData().then((response) => {
      setListUser(response.data.channel);
      const dataChannelSelect = [];
      response.data.channel?.map((itemChannel, index) => {
        dataChannelSelect.push(selectBoxPageItem(itemChannel));
      });
      setDataSelect(dataChannelSelect);
      setLoadSeeding(false);
    });
    // }
  };

  useEffect(() => {
    setSeeding({ ...formSeeding, user_seeding: { ...ListUser[0] } });
    setCurrentPage({
      picture: valueSeedingSelected?.picture || ListUser[0]?.picture,
      name: valueSeedingSelected?.name || ListUser[0]?.name,
    });
  }, [ListUser]);

  useEffect(() => {
    dispatch(setCollapseSidebarLeft(true));
    const controller = new AbortController();
    if (project._id) {
      getFollowingList(project._id)
        .then((response) => {
          if (response.status) {
            dispatch(setChannelFollows(response.data));
          }
        })
        .catch((error) => console.error(error));

      getListUser();
    }
    return () => {
      controller.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project._id]);
  // const [isOpen, setIsOpen] = useState(false);
  const [collapseSidebarAddChannel, setCollapseSidebarAddChannel] =
    useState(false);

  // check connect channel
  const dataListChannel = useSelector(
    (state) => state.channel.channelsInformation
  );
  const userInformation = JSON.parse(localStorage.getItem("userInfor"));
  // end check connect channel

  const handleChannelUserSeeding = () => {
    setSeeding({
      ...formSeeding,
      user_seeding: valueSeedingSelected,
    });

    setCurrentPage({
      picture: valueSeedingSelected.picture || ListUser[0].picture,
      name: valueSeedingSelected.name || ListUser[0].name,
    });
  };

  const showCollapseSideBarContent = (props) => {
    if (collapseSidebarAddChannel) {
      setCollapseSidebarAddChannel(false);
    }
    document.body.classList.add("toggle-shown");
  };

  return (
    <>
      <ContentAlt>
        <>
          {userInformation.last_project_active !== "" &&
          dataListChannel &&
          dataListChannel.length > 0 ? (
            <></>
          ) : (
            <div
              className={`${styleConnect.EmptyImage} ${styleConnect.ImageOpacity}`}
            >
              <div>
                <div className="text-center">
                  <img
                    src={"/images/empty-connection.png"}
                    width={287}
                    alt=""
                  />
                </div>
                <div>
                  <p className={`${styleConnect.ImageTitle}`}>
                    Theo dõi kênh để bắt đầu!
                  </p>
                </div>
                <div className={`${styleConnect.ConnectArea}`}>
                  <div
                    className={`${styleConnect.ButtonConnect}`}
                    // onClick={}
                  >
                    <div>
                      <IconConnectChannel />
                    </div>
                    <div>{t("project_connect_channel")}</div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            style={
              userInformation.last_project_active !== "" &&
              dataListChannel &&
              dataListChannel.length > 0
                ? { opacity: "1" }
                : { opacity: "0.5" }
            }
          >
            {channelFollow && channelFollow.length > 0 ? (
              <>
                <div
                  className={`d-flex align-items-start ${style.channelBody}`}
                >
                  {channelFollow.map(
                    (channel) =>
                      channel.is_show && (
                        <Column
                          ListUser={ListUser}
                          setListUser={setListUser}
                          setLoadSeeding={setLoadSeeding}
                          loadSeeding={loadSeeding}
                          key={channel._id}
                          openSidebarContent={showCollapseSideBarContent}
                          channel={channel}
                          formSeeding={formSeeding}
                        />
                      )
                  )}
                </div>
              </>
            ) : (
              <div className="d-flex align-items-start">
                <InitialColumn showModalAddFanPage={showModalAddFanPage} />
              </div>
            )}

            {isOpen && (
              <ModalAddPage
                width="765px"
                height="549px"
                top="278px"
                left="324px"
                borderRadius="4px 4px 0px 0px"
                setIsOpen={showModalAddFanPage}
                channelFollow={channelFollow}
              />
            )}
          </div>

          <Modal isOpen={isSelectPage} toggle={handlePost}>
            <ModalHeader toggle={handlePost}>
              Chọn Fanpage đẩy bình luận
            </ModalHeader>
            <ModalBody>
              <div className={style.reactSelectPageComment}>
                <RSelect
                  options={dataSelect}
                  placeholder="Chọn kênh"
                  onChange={(e) => {
                    handleSeedingValueChange(e);
                  }}
                  defaultValue={defaultValueSelectPage}
                  formatOptionLabel={(itemChannel) => {
                    return (
                      <div>
                        <img
                          src={`${itemChannel?.image}`}
                          alt="avatar"
                          className={`rounded ${style.sidebarImageWrapperImage} ${style.reactSelectPageCommentItem}`}
                        />
                        {itemChannel?.label}
                      </div>
                    );
                  }}
                />
              </div>

              <div className={`${style.tips} mt-3`}>Mẹo mách bạn: </div>
              <div className={`${style.desTips} mt-2`}>
                Dùng Fanpage để lại bình luận hay trên các bài đăng đang được
                nhiều tương tác của Page khác là một cách hữu ích để tăng like
                cho Page.
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={handlePost}
                style={{
                  backgroundColor: "#9CA3AF",
                  outline: "none",
                }}
              >
                Hủy
              </Button>{" "}
              <Button
                onClick={() => {
                  handlePost();
                  handleChannelUserSeeding();
                }}
                style={{
                  backgroundColor: "#2C4B94",
                }}
              >
                Lưu lại
              </Button>
            </ModalFooter>
          </Modal>
        </>
      </ContentAlt>
    </>
  );
}
