import React from "react";
import { Card, CardBody, CardTitle, Col, Progress, Row } from "reactstrap";
import {
  VictoryArea,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryTheme,
} from "victory";
import {
  formatNumber,
  formatNumberAcronym,
} from "../../../../../../utils/Utils";

import style from "../../../index.module.scss";

const YTFollow = ({ data }) => {
  const totalNewSub = data?.new_sub?.reduce((total, item) => total + item.y, 0);
  const totalSub = data?.sub?.reduce((total, item) => total + item.y, 0);

  const dataSub = data?.sub?.map((item) => {
    const dateObj = new Date(item.x);
    const shortYear = dateObj.getFullYear().toString().slice(2);
    const month = (dateObj.getMonth() + 1).toString();
    const day = dateObj.getDate().toString();
    const formattedDate = `${day}/${month}`;
    return { x: formattedDate, y: item.y };
  });
  const dataNewSub = data?.new_sub?.map((item) => {
    const dateObj = new Date(item.x);
    const shortYear = dateObj.getFullYear().toString().slice(2);
    const month = (dateObj.getMonth() + 1).toString();
    const day = dateObj.getDate().toString();
    const formattedDate = `${day}/${month}`;
    return { x: formattedDate, y: item.y };
  });

  const maxSubY = dataSub?.reduce((maxY, item) => {
    return item.y > maxY ? item.y : maxY;
  }, 0);

  const maxNewSubY = dataNewSub?.reduce((maxY, item) => {
    return item.y > maxY ? item.y : maxY;
  }, 0);

  const lableX = dataSub?.map((item) => {
    return item.x;
  });
  const getCustomTickLabel = (tick, index) => {
    if (lableX?.length <= 15) {
      // Hiển thị toàn bộ label khi số lượng label ít hơn hoặc bằng 15
      return tick;
    } else {
      // Hiển thị label của phần tử đầu, giữa và cuối
      if (
        index === 0 ||
        index === Math.floor(lableX?.length / 2) ||
        index === lableX?.length - 1
      ) {
        return tick;
      } else {
        return "";
      }
    }
  };

  return (
    <Row>
      <Col xs={12} md={8}>
        <Card className="h-100">
          <CardBody className="card-inner w-100">
            <CardTitle tag="h5" className={`${style.headCard}`}>
              Tổng quan người theo dõi
            </CardTitle>
            <div className="w-100">
              <VictoryChart
                width={800}
                height={300}
                domain={{ y: [0, 1.05] }}
                padding={{ top: 5, left: 50, right: 50, bottom: 40 }}
              >
                <VictoryBar
                  data={dataSub && dataSub}
                  style={{
                    data: {
                      fill: "#2C4B94",
                    },
                  }}
                  y={(datum) => datum.y / maxSubY}
                />
                <VictoryAxis
                  dependentAxis={true}
                  tickValues={[0.25, 0.5, 0.75, 1]}
                  tickFormat={(t) => (t * maxSubY).toFixed(0)}
                  // tickValues={[0, 250, 500, 750, 1000]}
                  style={{
                    axis: {
                      stroke: "#2C4B94",
                      // transform: "translate(-5px, 0)",
                    },
                    ticks: {
                      stroke: "#2C4B94",
                      size: 5,
                      // transform: "translate(-5px, 0)",
                    },
                    tickLabels: {
                      fill: "#2C4B94",
                      fontSize: 12,
                      padding: 5,
                    },
                  }}
                />
                <VictoryArea
                  data={dataNewSub && dataNewSub}
                  y={(datum) => datum.y / maxNewSubY}
                  style={{
                    data: {
                      fill: "#F8C6D1",
                      fillOpacity: 0.3, // Điều chỉnh giá trị fillOpacity để làm màu fill trở nên mờ hơn
                      stroke: "#F8C6D1",
                      strokeWidth: 2,
                    },
                  }}
                />
                <VictoryAxis
                  dependentAxis={true}
                  orientation="right"
                  tickValues={[0.25, 0.5, 0.75, 1]}
                  tickFormat={(t) => (t * maxNewSubY).toFixed(0)}
                  // tickValues={[0, 250, 500, 750, 1000]}
                  style={{
                    axis: {
                      stroke: "#2C4B94",
                      // transform: "translate(-5px, 0)",
                    },
                    ticks: {
                      stroke: "#2C4B94",
                      size: 5,
                      // transform: "translate(-5px, 0)",
                    },
                    tickLabels: {
                      fill: "#2C4B94",
                      fontSize: 12,
                      padding: 5,
                    },
                  }}
                />
                <VictoryAxis
                  style={{
                    axis: {
                      stroke: "#2C4B94",
                      // transform: "translate(-5px, 0)",
                    },
                    ticks: { stroke: "#2C4B94", size: 5 },
                    tickLabels: { fill: "#2C4B94", fontSize: 12, padding: 5 },
                  }}
                  tickValues={lableX}
                  tickFormat={(tick, index) => getCustomTickLabel(tick, index)}
                  labelComponent={<VictoryLabel />}
                  domain={[0, dataSub?.length + 1]}
                />
              </VictoryChart>
              <div
                className="d-flex flex-row justify-content-center"
                style={{ gap: "16px" }}
              >
                <div className="d-flex flex-row align-items-center">
                  <div
                    className={`rounded-circle mr-1`}
                    style={{
                      height: "8px",
                      width: "8px",
                      backgroundColor: "#2C4B94",
                      flexShrink: "0",
                    }}
                  ></div>
                  <div className={`${style.contentCard}`}>
                    {formatNumber(totalNewSub)} Sub mới
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <div
                    className={`rounded-circle mr-1`}
                    style={{
                      height: "8px",
                      width: "8px",
                      backgroundColor: "#FFD9EB",
                      flexShrink: "0",
                    }}
                  ></div>
                  <div className={`${style.contentCard}`}>
                    {formatNumber(totalSub)} Tổng Sub
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xs={12} md={4}>
        <Card className="h-100">
          <CardBody className="card-inner">
            <CardTitle tag="h5" className={`${style.headCard}`}>
              Sub theo quốc gia
            </CardTitle>

            <div
              className={`${style.headCard} d-flex flex-row align-items-center w-100 pb-2`}
              style={{
                fontSize: "0.75rem",
                borderBottom: "1px solid #D1D5DB",
              }}
            >
              <div className="w-35">Quốc gia</div>
              <div className="w-40">Tương tác</div>

              <div className="w-25 d-flex justify-content-end">%</div>
            </div>
            <div className="mt-2 d-flex flex-column" style={{ gap: "8px" }}>
              {data ? (
                data?.country?.country?.map((item, index) => {
                  const totalY = data?.country?.country?.reduce(
                    (acc, obj) => acc + obj.value,
                    0
                  );
                  return (
                    <div
                      className={`${style.contentCard} d-flex flex-row align-items-center w-100`}
                      key={index}
                    >
                      <div className="w-35">{item?.source}</div>
                      <div className="w-15">
                        {formatNumberAcronym(item?.value)}
                      </div>
                      <div className="w-25">
                        <Progress
                          value={((item?.value * 100) / totalY).toFixed(0)}
                          barStyle={{ backgroundColor: "#2C4B94" }}
                          style={{
                            backgroundColor: "#F1F5FF",
                          }}
                        />
                      </div>
                      <div
                        className="w-25 d-flex justify-content-end"
                        style={{ fontWeight: "600" }}
                      >
                        {((item?.value * 100) / totalY).toFixed(1)}
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  <div
                    className={`${style.contentCard} d-flex flex-row align-items-center w-100`}
                  >
                    <div className="w-35">Country</div>
                    <div className="w-15">50</div>
                    <div className="w-25">
                      <Progress
                        value={50}
                        barStyle={{ backgroundColor: "#2C4B94" }}
                        style={{
                          backgroundColor: "#F1F5FF",
                        }}
                      />
                    </div>
                    <div
                      className="w-25 d-flex justify-content-end"
                      style={{ fontWeight: "600" }}
                    >
                      50
                    </div>
                  </div>
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default YTFollow;
