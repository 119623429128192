import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import io from "socket.io-client";
import ss from "socket.io-stream";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import LeftMenu from "./components/LeftMenu";
import RightMenu from "./components/RightMenu";
import PostSetting from "./components/PostSetting";
import ModalCustomThumbnail from "./components/ModalCustomThumbnail";
import Head from "../../../layout/head/Head";
import moment from "moment";
import {
  fetchUserInfoLogin,
  loadVideo,
  convertToFractionInstaPlatform,
  convertToFraction,
} from "../../../libs/helper";
import {
  PLATFORM_FACEBOOK,
  PLATFORM_INSTAGRAM,
  PLATFORM_PINTEREST,
  PLATFORM_TIKTOK,
  PLATFORM_YOUTUBE,
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_VIDEO,
  POST_SCHEDULE,
  POST_NOW,
  POST_AUTO,
  CONTENT_STATUS_ACTIVE,
  POST_TYPE_SCHEDULE_AUTO,
  POST_TYPE_SCHEDULE_SAME,
  POST_TYPE_SCHEDULE_CUSTOM,
  PLATFORM_THREADS,
} from "../../utils/Utils";
import {
  getListLabel,
  getPostCommentCnt,
  createPost,
  getAllGroupChannel,
  getAllChannel,
} from "../../../api/postNew";
import { uploadImage } from "../../../api/createPost";
import { useSelector } from "react-redux";
import { setCollapseSidebarLeft } from "../../reducers/layoutSlice";

import "./styles.css";

const DEFAULT_SETTING_FB_CAROUSEL = {
  isOpen: false,
  cta: "NO_BUTTON",
  data: [],
  url: "",
};
const CreatePostBeta = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project.project);
  const userInfor = fetchUserInfoLogin();
  const { postId } = useLocation();

  const socketInstance = useRef();
  const videoThumbMedia = useRef([]);
  const mediaUploadedAll = useRef([]);
  const statusPostRef = useRef(null);
  const mediaUploadImagePostTotal = useRef(0);

  const roundedTime = () => {
    const currentTime = moment();
    const minutes = currentTime.minutes();
    const roundedMinutes = Math.ceil(minutes / 30) * 30;
    const roundedTime = currentTime
      .clone()
      .minutes(roundedMinutes)
      .format("HH:mm");

    return roundedTime;
  };

  const [isOpenRightTab, setIsOpenRightTab] = useState(true);
  const [isOpenLeftTab, setIsOpenLeftTab] = useState(true);

  // modal custom thumbnail
  const [isShowModal, setIsShowModal] = useState(false);
  const [disableSelectImage, setDisableSelectImage] = useState(false);

  const [isPosting, setIsPosting] = useState(false);
  const [activeTab, setActiveTab] = useState("preview");
  const [listChannel, setListChannel] = useState();
  const [filterChannelList, setFilterChannelList] = useState();
  const [groupChannel, setGroupChannel] = useState();
  const [contentValue, setContentValue] = useState("");
  const [listLabel, setListLabel] = useState();
  const [selectedHashTag, setSelectedHashTag] = useState();
  const [commentCnt, setCommentCnt] = useState();
  const [isVideo, setIsVideo] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [failedModal, setFailedModal] = useState(false);
  const [facebookSetting, setFacebookSetting] = useState("feed");
  const [instagramSetting, setInstagramSetting] = useState("feed");
  const [firstCommentSetting, setFirstCommentSetting] = useState({
    isOpen: false,
    content: "",
  });
  const [caroselSetting, setCaroselSetting] = useState(
    DEFAULT_SETTING_FB_CAROUSEL
  );
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState([]);
  const [youtubeData, setYoutubeData] = useState({
    type: "",
    title: "",
    category: "",
    status: "",
    tags: "",
    license: "",
    embed: true,
    notify: true,
    kids: false,
    playlist: [],
  });
  const [pinData, setPinData] = useState({
    url: "",
    title: "",
  });
  const [tiktokData, setTiktokData] = useState({
    status: "",
    comment: true,
    duet: true,
    stitch: true,
  });
  const [scheduleData, setScheduleData] = useState({
    type: POST_TYPE_SCHEDULE_SAME,
    timeStart: roundedTime(),
    timeEnd: roundedTime(),
    date: moment(new Date()).format("YYYY-MM-DD"),
    time: moment(new Date()).format("HH:mm"),
    postTime: POST_NOW,
  });
  const [isOpenSetting, setIsOpenSetting] = useState(false);

  const [error, setError] = useState([]);
  const [warning, setWarning] = useState([]);

  const [channelLoading, setChannelLoading] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [isDisabledStatus, setIsDisabledStatus] = useState(false);

  let uniquePlatformsData = filterChannelList?.reduce((platformsData, item) => {
    const existingPlatformData = platformsData.find(
      (data) => data.platform === item.platform
    );
    if (existingPlatformData) {
      existingPlatformData.data.push(item);
    } else {
      platformsData.push({
        platform: item.platform,
        data: [item],
        isOpen: true,
      });
    }
    return platformsData;
  }, []);

  uniquePlatformsData?.sort((a, b) => a.platform - b.platform);

  const uniquePlatforms = new Set(
    listChannel?.channel
      .filter((item) => item.check === true)
      .map((item) => item.platform)
  );
  const selectedPlatforms = Array.from(uniquePlatforms);

  const fetchListChannel = async () => {
    setChannelLoading(true);

    const data = {
      params: {
        status: 1,
      },
    };
    try {
      const res = await getAllChannel({
        projectId: userInfor.last_project_active,
        data: data,
      });
      if (res.status) {
        setListChannel(res.data);
        setChannelLoading(false);
      } else {
        setChannelLoading(false);
      }
    } catch (e) {
      console.log(e);
      setChannelLoading(false);
    }
  };

  const fetchCommentCnt = async () => {
    try {
      const res = await getPostCommentCnt({
        projectId: userInfor.last_project_active,
        postId: postId,
      });
      if (res.status) {
        setCommentCnt(res.data);
      } else {
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchGroupChannel = async () => {
    try {
      const res = await getAllGroupChannel({
        projectId: userInfor.last_project_active,
      });
      if (res.status) {
        setGroupChannel(res.data);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchListLabel = async () => {
    try {
      const res = await getListLabel(userInfor.last_project_active);
      if (res.status) {
        setListLabel(res.data);
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    dispatch(setCollapseSidebarLeft(true));
    fetchListChannel();
    fetchGroupChannel();
    fetchListLabel();
    window.onscroll = null;

    if (postId) {
      fetchCommentCnt();
    }
  }, []);

  useEffect(() => {
    let data = [];
    selectedImages.forEach((item) => {
      data.push({
        image: item,
        url: caroselSetting.url,
        title: item.caption || item.name,
        desc: contentValue,
      });
    });
    setCaroselSetting({ ...caroselSetting, data: data });
    setIsVideo(false);
  }, [selectedImages]);

  useEffect(() => {
    if (selectedVideo.length > 0) {
      setIsVideo(true);
      setCaroselSetting(DEFAULT_SETTING_FB_CAROUSEL);
    } else {
      setIsVideo(false);
      setIsShowModal(false);
    }
  }, [selectedVideo]);

  useEffect(() => {
    async function checkValidPlatform() {
      let dataError = [];
      let dataWarning = [];

      let videoCheck =
        isVideo && !!selectedVideo[0].data
          ? await loadVideo(selectedVideo[0].data)
          : null;
      let ratioVideo = videoCheck
        ? convertToFraction(videoCheck.videoWidth, videoCheck.videoHeight)
        : null;

      if (caroselSetting.isOpen) {
        let count = 0;
        caroselSetting.data.forEach((item) => {
          if (item.url.trim().length === 0) {
            count++;
          }
        });
        if (count > 0) {
          dataError.push(t("store_post.error.required_link_carousel"));
        }
      }

      // FACEBOOK
      if (
        listChannel?.channel.find(
          (item) => item.platform === PLATFORM_FACEBOOK && item.check === true
        )
      ) {
        if (facebookSetting === "reel") {
          console.log("======", ratioVideo);
          if (!isVideo) {
            dataError.push(t("store_post.error.required_video_for_reel"));
          } else if (
            Math.ceil(videoCheck.duration) < 3 ||
            Math.ceil(videoCheck.duration) > 90
          ) {
            dataError.push(t('store_post.error.required_video_ratio_and_duration_for_reel'));
          } else if (ratioVideo && ratioVideo !== "9:16") {
            dataError.push(t('store_post.error.required_video_ratio_and_duration_for_reel'));
          }
        }

        if (facebookSetting === "story") {
          if (caroselSetting?.data.length > 1 || selectedVideo.length > 1) {
            dataError.push(t("store_post.error.required_media_story"));
          }
          if (selectedVideo.length === 0 && caroselSetting?.data.length === 0) {
            dataError.push(t("store_post.error.required_media_story"));
          }
          if (
            isVideo &&
            (Math.ceil(videoCheck.duration) < 3 ||
              Math.ceil(videoCheck.duration) > 90 ||
              ratioVideo !== "9:16")
          ) {
            dataError.push(
              t("store_post.error.required_video_ratio_and_duration_for_story")
            );
          }
        }
      }

      // INSTAGRAM
      if (
        listChannel?.channel.find(
          (item) => item.platform === PLATFORM_INSTAGRAM && item.check === true
        )
      ) {
        if (isVideo) {
          if (
            Math.ceil(videoCheck.duration) > 90 &&
            instagramSetting === "feed"
          ) {
            dataWarning.push(
              t("store_post.error.required_video_duration_instagram")
            );
          } else if (instagramSetting === "story") {
            if (Math.ceil(videoCheck.duration) > 60) {
              dataWarning.push(
                t(
                  "store_post.error.required_video_limit_duration_instagram_story"
                )
              );
            } else if (Math.ceil(videoCheck.duration) < 3) {
              dataWarning.push(
                t(
                  "store_post.error.required_video_min_duration_instagram_story"
                )
              );
            }
          } else if (instagramSetting === "reel") {
            if (Math.ceil(videoCheck.duration) > 90) {
              dataWarning.push(
                t(
                  "store_post.error.required_video_limit_duration_instagram_reel"
                )
              );
            } else if (Math.ceil(videoCheck.duration) < 3) {
              dataWarning.push(
                t("store_post.error.required_video_min_duration_instagram_reel")
              );
            }
          }
        }
      }

      // YOUTUBE
      if (
        listChannel?.channel.find(
          (item) => item.platform === PLATFORM_YOUTUBE && item.check === true
        )
      ) {
        if (selectedVideo.length === 0) {
          dataError.push(t("store_post.error.required_video_youtube"));
        }

        if (youtubeData.title.trim().length === 0) {
          dataError.push(t("store_post.error.required_video_title_youtube"));
        }

        if (isVideo && youtubeData.type === "short") {
          if (Math.ceil(videoCheck.duration) > 60) {
            dataWarning.push(
              t("store_post.error.required_video_limit_duration_youtube_short")
            );
          } else if (Math.ceil(videoCheck.duration) < 3) {
            dataWarning.push(
              t("store_post.error.required_video_min_duration_youtube_short")
            );
          }
        }
      }

      // TIKTOK
      if (
        listChannel?.channel.find(
          (item) => item.platform === PLATFORM_TIKTOK && item.check === true
        )
      ) {
        if (selectedVideo.length === 0) {
          dataError.push(t("store_post.error.required_video_tiktok"));
        }
        if (!isVideo && caroselSetting?.data.length > 0) {
          dataError.push(t("store_post.error.required_media_titkok"));
        }
      }

      if (
        listChannel?.channel.find(
          (item) => item.platform === PLATFORM_PINTEREST && item.check === true
        )
      ) {
        if (selectedImages.length === 0 && selectedVideo.length === 0) {
          dataError.push(t("store_post.error.required_media_pinterest"));
        }
        if (!isValidUrl(pinData.url)) {
          dataError.push(t("store_post.error.required_link_pinterest"));
        }
        if (pinData.title.trim().length === 0) {
          dataError.push(t("store_post.error.required_title_pinterest"));
        }

        let count = 0;
        const pins = listChannel?.channel.filter(
          (item) => item.platform === PLATFORM_PINTEREST && item.check === true
        );
        pins.forEach((item) => {
          if (typeof item.boardId !== "string") {
            count++;
          }
        });
        if (count > 0) {
          dataError.push(t("store_post.error.required_board_pinterest"));
        }
      }

      if (scheduleData.postTime === POST_SCHEDULE) {
        const schedule = listChannel?.channel.find(
          (item) => item.check === true && !item?.scheduleTime
        );
        if (scheduleData.type === POST_TYPE_SCHEDULE_CUSTOM && schedule?._id) {
          dataError.push(t("store_post.error.required_schedule"));
        }

        if (scheduleData.type === POST_TYPE_SCHEDULE_AUTO) {
          if (scheduleData.timeStart === scheduleData.timeEnd) {
            dataError.push(t("store_post.error.required_schedule"));
          }
        }
      }

      setError(dataError);
      setWarning(dataWarning);
    }
    checkValidPlatform();
  }, [
    facebookSetting,
    caroselSetting,
    youtubeData,
    tiktokData,
    firstCommentSetting,
    scheduleData,
    listChannel,
    pinData,
    selectedImages,
    selectedVideo,
    isVideo,
    instagramSetting,
  ]);

  const isValidUrl = (urlString) => {
    var urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  };

  const toggleTab = (tab) => {
    if (activeTab === tab) {
      setIsOpenRightTab(!isOpenRightTab);
    } else {
      setActiveTab(tab);
      if (isOpenRightTab === false) {
        setIsOpenRightTab(true);
      }
    }
  };

  const handleListenCompressVideo = (event) => {
    console.log({ event });
    if (event.status === false) {
      alert("Tải video lên không thành công. Vui lòng thử lại sau ít phút");
      videoThumbMedia.current = [];
      mediaUploadedAll.current = [];
      return;
    }

    const thumbOfVideo = videoThumbMedia.current.find(
      (item) => item.video_id === event.video_id
    );
    if (!thumbOfVideo) {
      setTimeout(() => {
        handleListenCompressVideo(event);
      }, 1000);
      return;
    }
    mediaUploadedAll.current.push({
      type: MEDIA_TYPE_VIDEO,
      name: event.data,
      previewImages: {
        name: thumbOfVideo.name,
      },
      caption: event.caption || "",
    });
    if (mediaUploadImagePostTotal.current === 1) {
      mediaUploadImagePostTotal.current -= 1;
      mediaUploadedAll.current = mediaUploadedAll.current.sort(
        (a, b) => a.position - b.position
      );
      actionCreatingPost(mediaUploadedAll.current);
    } else {
      mediaUploadImagePostTotal.current -= 1;
    }
  };

  const handleUploadImageData = (event) => {
    if (event.status === false) {
      alert("Tải ảnh lên không thành công. Vui lòng thử lại sau ít phút");
      mediaUploadImagePostTotal.current = 0;
      mediaUploadedAll.current = [];
      return;
    }
    mediaUploadedAll.current.push({
      type: MEDIA_TYPE_IMAGE,
      name: event.data,
      altText: event.data,
      caption: event.caption || "",
      position: event.position,
    });
    if (mediaUploadImagePostTotal.current === 1) {
      mediaUploadImagePostTotal.current -= 1;
      mediaUploadedAll.current = mediaUploadedAll.current.sort(
        (a, b) => a.position - b.position
      );
      actionCreatingPost(mediaUploadedAll.current);
    } else {
      mediaUploadImagePostTotal.current -= 1;
    }
  };

  const uploadMedia = async (fileMediaItem) => {
    console.log("fileMediaItem => ", fileMediaItem);
    const userInfo = JSON.parse(localStorage.getItem("userInfor"));
    const projectId = userInfo.last_project_active;
    socketInstance.current = await io(process.env.REACT_APP_CDN_API, {
      transports: ["websocket"],
      withCredentials: true,
    });

    await socketInstance.current.on(
      `map-video-response-${userInfo.last_project_active}-${userInfo.id}`,
      (data) => {
        handleListenCompressVideo(data);
      }
    );
    await socketInstance.current.on(
      `upload-image-response-${userInfo.last_project_active}-${userInfo.id}`,
      (data) => {
        handleUploadImageData(data);
      }
    );
    mediaUploadImagePostTotal.current = fileMediaItem.length;
    return Promise.all(
      fileMediaItem.map(async (fileMedia, index) => {
        const isImage = fileMedia.type.split("/")[0] === "image";
        if (isImage) {
          const stream = ss.createStream();
          ss(socketInstance.current).emit("upload-image", stream, {
            project_id: userInfo.last_project_active,
            user_id: userInfo.id,
            folder: "post",
            file_name: index + fileMedia.name,
            extention: fileMedia.name.split(".").pop(),
            file_size: fileMedia.size,
            caption: fileMedia.caption || "",
            position: index,
            scheduled_at: 0,
          });
          ss.createBlobReadStream(fileMedia, {
            highWaterMark: 1024 * 1024 * 1.5,
          }).pipe(stream);
        }
        const isVideo = fileMedia.type.split("/")[0] === "video";
        if (isVideo) {
          const isContainPlatformInsta = listChannel?.channel?.find(
            (item) => item.platform === PLATFORM_INSTAGRAM
          );
          const currentTime = moment();
          const realFileMedia = fileMedia.data;
          const video = await loadVideo(realFileMedia);
          const stream = ss.createStream();
          ss(socketInstance.current).emit("upload-video-v2", stream, {
            extention: "mp4",
            project_id: userInfo.last_project_active,
            user_id: userInfo.id,
            folder: "post",
            file_name: index + fileMedia.name,
            file_size: fileMedia.size,
            caption: fileMedia.caption || "",
            position: index,
            video_id: index + currentTime + fileMedia.name,
            is_short: false,
            ...(isContainPlatformInsta && {
              network: "Instagram",
              ratio: "16:9",
              ratio_current: convertToFractionInstaPlatform(
                video.videoWidth,
                video.videoHeight
              ),
              duration_current: Math.ceil(video.duration),
            }),
          });
          ss.createBlobReadStream(realFileMedia, {
            highWaterMark: 1024 * 1024 * 1.5,
          }).pipe(stream);

          const blob = await fetch(fileMedia.thumb).then((res) => res.blob());
          const data = new FormData();
          data.append("image", blob);
          data.append("projectId", projectId);
          data.append("folder", "post");
          const response = await uploadImage(data);
          const thumbnailInfo = response.data;
          videoThumbMedia.current.push({
            name: thumbnailInfo.name,
            url: thumbnailInfo.url,
            video_id: index + currentTime + fileMedia.name,
          });
        }
      })
    );
  };

  const handleSchedule = async () => {
    setIsPosting(true);
    statusPostRef.current = CONTENT_STATUS_ACTIVE;
    if (error.length > 0) {
      console.log("error => ", error);
      setIsPosting(false);
      return;
    }

    let selectedChannel = listChannel?.channel?.filter(
      (item) => item.check === true
    );

    if (selectedChannel?.length === 0) {
      alert("Vui lòng chọn kênh!");
      setIsPosting(false);
      return;
    }
    if (selectedImages.length > 0 || selectedVideo.length > 0) {
      let listMedia = selectedImages.concat(selectedVideo);
      await uploadMedia(listMedia);
    } else {
      actionCreatingPost([]);
    }
  };

  const actionCreatingPost = async (currentMedia) => {
    setIsPosting(true);
    let selectedChannel = listChannel?.channel?.filter(
      (item) => item.check === true
    );
    let data = {
      content: contentValue,
      channel_ids: selectedChannel?.map((c) => c._id),
      is_auto_scheduled_at: scheduleData.postTime === POST_AUTO,
      status: statusPostRef.current,
      images: currentMedia
        .filter((item) => item.type === MEDIA_TYPE_IMAGE)
        .map((item) => ({
          name: item.name,
          alt: item.altText ? item.altText : "",
          caption: item.caption || "",
        })),
      videos: currentMedia
        .filter((item) => item.type === MEDIA_TYPE_VIDEO)
        .map((item) => ({
          name: item.name,
          thumb: item.previewImages.name,
          caption: item.caption || "",
        })),
    };

    if (firstCommentSetting.isOpen) {
      const comments = listChannel?.channel.filter(
        (item) => item.check === true && item.commentCheck !== true
      );
      if (comments) {
        let commentData = comments.map((c) => c._id);
        data.first_comment = {
          channel_ids: commentData,
          message: firstCommentSetting.content,
        };
      }
    }
    const label = listLabel.find((item) => item.check === true);
    data.topic = label ? label._id : "";

    if (selectedHashTag) {
      data.group_hashtag = {
        id: selectedHashTag._id,
        option: selectedHashTag.number_random,
      };
    }

    const faceChannels = listChannel?.channel.filter(
      (item) => item.platform === PLATFORM_FACEBOOK && item.check === true
    );
    const instaChannels = listChannel?.channel.filter(
      (item) => item.platform === PLATFORM_INSTAGRAM && item.check === true
    );
    const pinChannels = listChannel?.channel.filter(
      (item) => item.platform === PLATFORM_PINTEREST && item.check === true
    );
    const youtubeChannels = listChannel?.channel.filter(
      (item) => item.platform === PLATFORM_YOUTUBE && item.check === true
    );
    const tiktokChannels = listChannel?.channel.filter(
      (item) => item.platform === PLATFORM_TIKTOK && item.check === true
    );
    const threadsChannels = listChannel?.channel.filter(
      (item) => item.platform === PLATFORM_THREADS && item.check === true
    );

    if (faceChannels && faceChannels.length > 0) {
      let faceData = {
        type: facebookSetting,
      };
      if (caroselSetting.isOpen) {
        const carosels = listChannel?.channel.filter(
          (item) =>
            item.check === true &&
            item.platform === PLATFORM_FACEBOOK &&
            item.isDisableCarosel !== true
        );
        if (carosels) {
          let caroselid = carosels.map((c) => c._id);
          let caroItems = caroselSetting.data.map((item, index) => {
            const caroItem = {
              cta: caroselSetting.cta,
              title: item.title,
              desc: item.desc,
              link: item.url,
              photo: {
                url: currentMedia?.[index]?.name,
              },
            };
            return caroItem;
          });
          let carroData = {
            channel_ids: caroselid,
            link: caroselSetting.url,
            items: caroItems,
          };
          faceData.facebook_carousel = carroData;
        }
      }

      if (selectedStatus) {
        faceData.text_format_preset_id = selectedStatus.id;
      }

      data.facebook_setting = faceData;
    }

    if (tiktokChannels && tiktokChannels.length > 0) {
      let tiktokD = {
        state_viewer: "",
        disable_comment: !tiktokData.comment,
        disable_duet: !tiktokData.duet,
        disable_stitch: !tiktokData.stitch,
        thumbnail_offset: selectedVideo[0].time * 1000 || 0,
      };
      data.tiktok_setting = tiktokD;
    }

    if (youtubeChannels && youtubeChannels.length > 0) {
      let filt = youtubeChannels.filter((c) => c.selectedPlaylist);
      let playlistData = [];
      if (filt) {
        playlistData = filt.map((c) => ({
          channel_id: c._id,
          id: c.selectedPlaylist,
        }));
      }

      let ytbDataSetting = {
        type: youtubeData.type === "short" ? "short" : "video",
        desc: youtubeData.title,
        tags: youtubeData.tags.split(","),
        category_id: youtubeData.category,
        visibility: youtubeData.status === "Private" ? "private" : "public",
        license:
          youtubeData.license === "creativeCommon"
            ? "creativeCommon"
            : "youtube",
        playlist: filt ? playlistData : [],
        option: {
          made_for_kids: youtubeData.kids,
          notify_subscribers: youtubeData.notify,
          embeddable: youtubeData.embed,
        },
      };
      data.youtube_setting = ytbDataSetting;
    }

    if (instaChannels && instaChannels.length > 0) {
      data.instagram_setting = {
        type: instagramSetting,
      };
    }

    if (pinChannels && pinChannels.length > 0) {
      let boardData = pinChannels.map((c) => ({
        channel_id: c._id,
        id: c.boardId,
      }));
      let pinterestData = {
        type: isVideo ? "video" : "photo",
        title: pinData.title,
        url: pinData.url,
        board: boardData,
      };
      data.pinterest_setting = pinterestData;
    }

    if(threadsChannels && threadsChannels.length > 0) {
      data.threads_setting = {}
    }

    if (
      scheduleData.postTime === POST_SCHEDULE &&
      scheduleData.type === POST_TYPE_SCHEDULE_AUTO
    ) {
      data.range_time_schedule = {
        date: moment(scheduleData.date).format("YYYY-MM-DD"),
        range_time: `${scheduleData.timeStart}-${scheduleData.timeEnd}`,
      };
    }
    if (scheduleData.postTime === POST_NOW) {
      data.scheduled_at = Math.floor(new Date().getTime() / 1000);
    }

    if (
      scheduleData.postTime === POST_SCHEDULE &&
      scheduleData.type === POST_TYPE_SCHEDULE_SAME
    ) {
      const date = new Date(`${scheduleData.date}T${scheduleData.time}:00`);
      data.scheduled_at = Math.floor(date.getTime() / 1000);
    }

    if (
      scheduleData.postTime === POST_SCHEDULE &&
      scheduleData.type === POST_TYPE_SCHEDULE_CUSTOM
    ) {
      const schedules = listChannel?.channel.filter(
        (item) => item.scheduleTime > moment() && item.check === true
      );
      let scheduleDta = schedules?.map((item) => ({
        channel_id: item._id,
        scheduled_at: Math.floor(item.scheduleTime.getTime() / 1000),
      }));

      data.schedule_custom = scheduleDta;
    }

    createPost({
      projectId: userInfor.last_project_active,
      data: data,
    })
      .then((postRes) => {
        if (postRes.status) {
          setSuccessModal(true);
        } else {
          setFailedModal(true);
        }
        setIsPosting(false);
      })
      .catch((error) => {
        setFailedModal(true);
        setIsPosting(false);
      });
  };

  const handleClickSuccess = () => {
    setSuccessModal(!successModal);
    window.location.href = "/manage_posts";
  };

  const onOpenModalCustomThumbnail = () => {
    setIsShowModal(true);
  };

  const onOpenModalCustomThumbnailAndDisableSelectImage = () => {
    setIsShowModal(true);
    setDisableSelectImage(true);
  };

  const onCloseModalCustomThumbnail = () => {
    setIsShowModal(false);
  };

  return (
    <React.Fragment>
      <Head title={t("store_post.head")} />
      <div
        className={`${styles.postContainer} ${styles.textWrapper} relative postWraper d-flex flex flex-nowrap ms-5`}
        style={{ marginTop: "76px" }}
      >
        <LeftMenu
          isOpenLeftTab={isOpenLeftTab}
          listChannel={listChannel}
          groupChannel={groupChannel}
          channelLoading={channelLoading}
          filterChannelList={filterChannelList}
          uniquePlatformsData={uniquePlatformsData}
          setListChannel={setListChannel}
          setFilterChannelList={setFilterChannelList}
        />

        <PostSetting
          listChannel={listChannel}
          error={error}
          warning={warning}
          isPosting={isPosting}
          handleSchedule={handleSchedule}
          scheduleData={scheduleData}
          setScheduleData={setScheduleData}
          isOpenLeftTab={isOpenLeftTab}
          setIsOpenLeftTab={setIsOpenLeftTab}
          contentValue={contentValue}
          setContentValue={setContentValue}
          selectedHashTag={selectedHashTag}
          setSelectedHashTag={setSelectedHashTag}
          setSelectedImages={setSelectedImages}
          selectedImages={selectedImages}
          selectedVideo={selectedVideo}
          setSelectedVideo={setSelectedVideo}
          isVideo={isVideo}
          pinData={pinData}
          setPinData={setPinData}
          selectedPlatforms={selectedPlatforms}
          setListChannel={setListChannel}
          caroselSetting={caroselSetting}
          setCaroselSetting={setCaroselSetting}
          facebookSetting={facebookSetting}
          setFacebookSetting={setFacebookSetting}
          setInstagramSetting={setInstagramSetting}
          instagramSetting={instagramSetting}
          youtubeData={youtubeData}
          setYoutubeData={setYoutubeData}
          tiktokData={tiktokData}
          setTiktokData={setTiktokData}
          setIsOpenSetting={setIsOpenSetting}
          isOpenSetting={isOpenSetting}
          uniquePlatformsData={uniquePlatformsData}
          toggleTab={toggleTab}
          onOpenModalCustomThumbnailTikTok={
            onOpenModalCustomThumbnailAndDisableSelectImage
          }
          onOpenModalCustomThumbnail={onOpenModalCustomThumbnail}
          setIsOpenRightTab={setIsOpenRightTab}
          isOpenRightTab={isOpenRightTab}
          setFirstCommentSetting={setFirstCommentSetting}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          isDisabledStatus={isDisabledStatus}
          setIsDisabledStatus={setIsDisabledStatus}
        />

        <RightMenu
          isOpenRightTab={isOpenRightTab}
          activeTab={activeTab}
          commentCnt={commentCnt}
          listChannel={listChannel}
          facebookSetting={facebookSetting}
          youtubeData={youtubeData}
          instagramSetting={instagramSetting}
          selectedImages={selectedImages}
          contentValue={contentValue}
          caroselSetting={caroselSetting}
          isVideo={isVideo}
          pinData={pinData}
          selectedVideo={selectedVideo}
          listLabel={listLabel}
          postId={postId}
          setListLabel={setListLabel}
          setIsOpenRightTab={setIsOpenRightTab}
          toggleTab={toggleTab}
          selectedStatus={selectedStatus}
          isDisabledStatus={isDisabledStatus}
        />
      </div>
      <SuccessModal
        openModal={successModal}
        setOpenModal={() => {
          setSuccessModal(!successModal);
        }}
        handleClick={() => handleClickSuccess()}
        text={"Posting schedule was successfully uploaded"}
      />

      <FailedModal
        openModal={failedModal}
        setOpenModal={() => {
          setFailedModal(!failedModal);
        }}
        handleClick={() => setFailedModal(false)}
        text={"Posting schedule was failed! Try again later!"}
      />
      {isOpenSetting && (
        <div
          className="absolute inset-x-0 inset-y-0 z-50"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
        />
      )}
      {isVideo && (
        <ModalCustomThumbnail
          isShowModal={isShowModal}
          disableSelectImage={disableSelectImage}
          setSelectedVideo={setSelectedVideo}
          selectedVideo={selectedVideo}
          selectedPlatforms={selectedPlatforms}
          onCloseModal={onCloseModalCustomThumbnail}
        />
      )}
    </React.Fragment>
  );
};
const SuccessModal = React.lazy(() => import("./components/SuccessModal"));
const FailedModal = React.lazy(() => import("./components/FailedModal"));

export default CreatePostBeta;
