import React from "react";

import BlockDataCommonItem from "../../CommonItem/BlockDataCommonItem";
import { ChartType } from "../../constants";
import { useTranslation } from "react-i18next";

const OverviewUserInstagram = ({ dataSummary, isLoadingSummary }) => {
  const { t } = useTranslation();
  const labelX = dataSummary?.followers?.dataSet?.map((d) => d?.x);
  const dataChartFollowers = [
    { name: "", data: dataSummary?.followers?.dataSet?.map((d) => d?.y) },
  ];

  const dataChartNewFollowers = [
    {
      name: "",
      data: dataSummary?.follower_count?.dataSet?.map((d) => d?.y),
    },
  ];

  const dataChartProfileViews = [
    {
      name: "",
      data: dataSummary?.profile_views?.dataSet?.map((d) => d?.y),
    },
  ];

  const dataChartImpressions = [
    {
      name: "",
      data: dataSummary?.impressions?.dataSet?.map((d) => d?.y),
    },
  ];

  const dataChartReach = [
    { name: "", data: dataSummary?.reach?.dataSet?.map((d) => d?.y) },
  ];

  const dataChartLinkClicks = [
    {
      name: "",
      data: dataSummary?.get_directions_clicks?.dataSet?.map((d) => d?.y),
    },
  ];

  const dataChartWebsiteClicks = [
    {
      name: "",
      data: dataSummary?.website_clicks?.dataSet?.map((d) => d?.y),
    },
  ];

  const dataChartPhoneCallClicks = [
    {
      name: "",
      data: dataSummary?.phone_call_clicks?.dataSet?.map((d) => d?.y),
    },
  ];

  const dataChartMessageClicks = [
    {
      name: "",
      data: dataSummary?.text_message_clicks?.dataSet?.map((d) => d?.y),
    },
  ];

  const dataChartEmailContacts = [
    {
      name: "",
      data: dataSummary?.email_contacts?.dataSet?.map((d) => d?.y),
    },
  ];

  const toolTipGender = (
    <span>
      Sự phân bổ giới tính của những người theo dõi
      <br /> <br />
      Không bao gồm dữ liệu của ngày hiện tại.
      <br /> <br />
      Không có sẵn cho những Người dùng Instagram có ít hơn 100 người theo dõi.
    </span>
  );

  const toolTipCountry = (
    <span>
      Quốc gia của những người theo dõi mà chúng tôi có dữ liệu nhân khẩu học.
      <br /> <br />
      1, Không bao gồm dữ liệu của ngày hiện tại. <br />
      2, Không có sẵn cho những Người dùng Instagram có ít hơn 100 người theo
      dõi. <br />
      3, Chỉ 45 quốc gia hàng đầu có giá trị cao nhất được trả về.
    </span>
  );

  return (
    <>
      <BlockDataCommonItem
        isLoading={isLoadingSummary}
        title={t("followers")}
        dataValue={dataSummary?.followers?.value}
        hasChart={true}
        chartType={ChartType.LINE}
        labelX={labelX}
        dataChart={dataChartFollowers}
        tooltipLabel={t("dashboard_instagram.tooltip.follower")}
        tooltipId={`OverviewUserInstagram_1`}
        classIcon="ri-user-follow-fill"
        colorIcon="#4B38B3"
      />
      <BlockDataCommonItem
        isLoading={isLoadingSummary}
        title={t("new_follower")}
        dataValue={dataSummary?.follower_count?.value}
        hasChart={true}
        labelX={labelX}
        chartType={ChartType.LINE}
        dataChart={dataChartNewFollowers}
        tooltipLabel={t("dashboard_instagram.tooltip.new_follower")}
        tooltipId={`OverviewUserInstagram_2`}
        classIcon="ri-user-follow-fill"
        colorIcon="#4B38B3"
      />
      <BlockDataCommonItem
        isLoading={isLoadingSummary}
        title={t("overview.content_spread.summary.impressions")}
        dataValue={dataSummary?.impressions?.value}
        hasChart={true}
        chartType={ChartType.LINE}
        labelX={labelX}
        dataChart={dataChartImpressions}
        tooltipLabel={t("dashboard_instagram.tooltip.impression")}
        tooltipId={`OverviewUserInstagram_3`}
        classIcon="ri-eye-fill"
        colorIcon="#4B38B3"
      />
      <BlockDataCommonItem
        isLoading={isLoadingSummary}
        title={t("channel_analytics_table.reach")}
        dataValue={dataSummary?.reach?.value}
        hasChart={true}
        chartType={ChartType.LINE}
        labelX={labelX}
        dataChart={dataChartReach}
        tooltipLabel={t("dashboard_instagram.tooltip.reach")}
        tooltipId={`OverviewUserInstagram_4`}
        classIcon="ri-user-received-fill"
        colorIcon="#3577F1"
      />

      <BlockDataCommonItem
        isLoading={isLoadingSummary}
        title={t("profile_view")}
        dataValue={dataSummary?.profile_views?.value}
        hasChart={true}
        chartType={ChartType.LINE}
        labelX={labelX}
        dataChart={dataChartProfileViews}
        tooltipLabel={t("dashboard_instagram.tooltip.profile_views")}
        tooltipId={`OverviewUserInstagram_5`}
        classIcon="ri-empathize-fill"
        colorIcon="#F06548"
      />

      <BlockDataCommonItem
        isLoading={isLoadingSummary}
        title={t("direction_click")}
        dataValue={dataSummary?.get_directions_clicks?.value}
        hasChart={true}
        chartType={ChartType.LINE}
        labelX={labelX}
        dataChart={dataChartLinkClicks}
        tooltipLabel={t("dashboard_instagram.tooltip.direction_click")}
        tooltipId={`OverviewUserInstagram_9`}
        classIcon="ri-map-pin-2-fill"
        colorIcon="#4B38B3"
      />
      <BlockDataCommonItem
        isLoading={isLoadingSummary}
        title={t("website_click")}
        dataValue={dataSummary?.website_clicks?.value}
        hasChart={true}
        chartType={ChartType.LINE}
        labelX={labelX}
        dataChart={dataChartWebsiteClicks}
        tooltipLabel={t("dashboard_instagram.tooltip.website_clicks")}
        tooltipId={`OverviewUserInstagram_10`}
        classIcon="ri-global-fill"
        colorIcon="#4B38B3"
      />

      <BlockDataCommonItem
        isLoading={isLoadingSummary}
        title={t("phone_call_clicks")}
        dataValue={dataSummary?.phone_call_clicks?.value}
        hasChart={true}
        chartType={ChartType.LINE}
        labelX={labelX}
        dataChart={dataChartPhoneCallClicks}
        tooltipLabel={t("dashboard_instagram.tooltip.phone_call_clicks")}
        tooltipId={`OverviewUserInstagram_11`}
        classIcon="ri-phone-fill"
        colorIcon="#F7B84B"
      />

      <BlockDataCommonItem
        isLoading={isLoadingSummary}
        title={t("message_click")}
        dataValue={dataSummary?.text_message_clicks?.value}
        hasChart={true}
        chartType={ChartType.LINE}
        labelX={labelX}
        dataChart={dataChartMessageClicks}
        tooltipLabel={t("dashboard_instagram.tooltip.text_message_clicks")}
        tooltipId={`OverviewUserInstagram_11`}
        classIcon="ri-message-2-line"
        colorIcon="#4B38B3"
      />

      <BlockDataCommonItem
        isLoading={isLoadingSummary}
        title={t("email_contact")}
        dataValue={dataSummary?.email_contacts?.value}
        hasChart={true}
        chartType={ChartType.LINE}
        labelX={labelX}
        dataChart={dataChartEmailContacts}
        tooltipLabel={t("dashboard_instagram.tooltip.email_contact")}
        tooltipId={`OverviewUserInstagram_11`}
        classIcon="ri-message-2-line"
        colorIcon="#4B38B3"
      />
    </>
  );
};

export default OverviewUserInstagram;
