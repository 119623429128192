import React, { useState } from "react";
import LogoDark from "../../images/logo-so9.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import ResendCountdown from "./ResendCountdown";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle } from "../../components/Component";
import { Link } from "react-router-dom";
import useCheckLogin from "../../hooks/useCheckLogin";
import {useTranslation} from "react-i18next";
import {resendEmail} from "../../api/auth";
import style from "./Auth.module.scss";
const Success = () => {
  const userInfoRegister = JSON.parse(localStorage.getItem('userInfoRegister'));
  useCheckLogin();
  const [timeRemain, setTimeRemain] = useState(0);
  const setTime=()=>{
    if (localStorage.getItem('resendInfo')) {
      const arrResendInfo = JSON.parse(localStorage.getItem("resendInfo"));
      if (arrResendInfo.length > 0) {
        const userResend = arrResendInfo.find((item) => item.email === userInfoRegister.email);
        if (userResend) {
          const timeStart = userResend.countdown;
          const timeRemain = Math.floor(Math.abs(Date.now() - timeStart));
          setTimeRemain(timeRemain);
          return false;
        }
        else {
          return true
        }
      }
    }
    else{
      return true
    }
  }
  const [reSend, setTimeResend] = useState(setTime);
  const onResend = () => {
    if (reSend) {
      resendEmail(userInfoRegister).then((res) => {
        if(res.status){
          if (localStorage.getItem('resendInfo')) {
            let arrResendInfo = JSON.parse(localStorage.getItem('resendInfo'));
            const dataInfo = {
              email: userInfoRegister.email,
              countdown: Date.now(),
            };
            arrResendInfo.push(dataInfo);
            localStorage.setItem('resendInfo', JSON.stringify(arrResendInfo));
          }
          else {
            let arrResendInfo = [];
            const dataInfo = {
              email: userInfoRegister.email,
              countdown: Date.now(),
            };
            arrResendInfo.push(dataInfo);
            localStorage.setItem('resendInfo', JSON.stringify(arrResendInfo));
          }
          setTimeResend(false);
          setTimeRemain(0);
        } else{
          setTimeResend(true);
        }
      }).catch((err) => {
        setTimeResend(true);
      })
    }
    
  }
  const {t} = useTranslation();
  return (
    <React.Fragment>
      <Head title="Success" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body">
          <div className="brand-logo pb-5">
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">Thank you for submitting form</BlockTitle>
              <BlockDes className="text-success">
                <p>{t('noti_success_register_and_send_email')} <span className={`${style.ResendButton} ${!reSend && style.waitResend}`} onClick={onResend}>{reSend ? t("auth_resend") : <ResendCountdown timeRemain={timeRemain} setTimeResendParent={() => setTimeResend(true)} userEmail={userInfoRegister.email}/>}</span></p> 
              </BlockDes>
            </BlockContent>
          </BlockHead>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Success;
