import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
export default function PrivateRouterTrial({ exact, component: Component, ...rest }) {
  const project = useSelector((state) => state.project.project);
  return (
    <Route
      exact={exact ? true : false}
      rest
      render={(props) =>
        (project?.type !== "free") ? (
          <Component {...props} {...rest}></Component>
        ) : (
          <Redirect to={`${process.env.PUBLIC_URL}/news`}></Redirect>
        )
      }
    ></Route>
  )
}

//export default PrivateRouteTrial;
