import { Fragment, useEffect, useState } from "react";
import { Button, Card, Spinner } from "reactstrap";
import { getNewsListChannelError, getNewsTopPostChannel } from "../../../api/overview";
import { useHistory } from 'react-router-dom';

import style from "./index.module.scss";
import { Icon, Img } from "../../../components/Component";
import { getPlatformIcon } from "../../utils/Utils";

import CHART_ICON from "../../images/news/chart-icon.svg";
import WARN_ICON from "../../images/news/warning-icon.svg";
import VIEW_ICON from "../../images/news/view-icon.svg";
import CHANNEL_EMPTY from "../../images/news/empty-channel.png";

import moment from "moment";
import { useSelector } from "react-redux";
import ModalCapChannel from "../../components/Modal/ModalLimitChannel";
import { t } from "i18next";





const ChannelConnect = () => {
    const history = useHistory();
    const userInfor = JSON.parse(localStorage.getItem("userInfor"));

    const [tab, setTab] = useState('effective');
    const [listChannel, setListChannel] = useState([]);
    const [listErrorChannel, setListErrorChannel] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isCapLimit, setIsCapLimit] = useState(false);

    const project = useSelector((state) => state.project.project);
    const channel = useSelector((state) => state.channel.channelInf);
    const fetchChannel = async () => {
        setLoading(true);

        try {
            const data = {
                params: {
                    from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
                    to: moment().format('YYYY-MM-DD'),
                    limit: 3
                }
            }
            const res = await getNewsTopPostChannel(userInfor?.last_project_active, data);
            if (res.status) {
                setListChannel(res?.data);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    const fetchErrorChannel = async () => {
        setLoading(true);
        try {
            const res = await getNewsListChannelError(userInfor?.last_project_active);
            if (res.status) {
                setListErrorChannel(res?.data);
                setLoading(false);
                if (res?.data?.length > 0) {
                    setTab('error');
                }
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchErrorChannel();
        fetchChannel();
    }, []);

    const renderAvatar = ({ image, platform }) => {
        return (
            <div className={`position-relative ${style.postScheduleBlock__userAvatar}`} style={{ width: "32px", height: "32px" }}>
                <Img src={image ?? ""} height={"32px"}></Img>
                <Img src={getPlatformIcon(platform)} height={"12px"} className={`position-absolute`} style={{ bottom: "0px", right: "0px" }}></Img>
            </div>
        )
    }

    const renderItem = (item) => {
        return (
            <div className={`d-flex border-top justify-content-between align-items-center`} style={{ padding: "8px 16px" }}>
                <div className="d-flex align-items-center">
                    {renderAvatar({ image: item.picture, platform: item.platform })}
                    <span className={`${style.postScheduleBlock__userName} fw-normal text-truncate`} style={{ maxWidth: "66px" }}>{item.name}</span>
                </div>
                {tab === "effective" ? renderView(item?.performance) : renderError()}
            </div>
        );
    }

    const renderListChannel = () => {
        return (
            <>
                {listChannel.length > 0 ?
                    (listChannel.map((item) => (
                        renderItem(item)
                    )))
                    : (<>{renderEmpty()}</>)}
                <div className={`d-flex border-top justify-content-between align-items-center`} style={{ padding: "12px 16px" }}>
                    <div onClick={() => { history.push("/home/dashboard/overview") }} className="d-flex align-items-center cursor-pointer">
                        <Icon style={{ color: "#6F7782", fontSize: "12px" }} name="reports"></Icon>
                        <span className={`${style.postScheduleBlock__userName} fw-normal`} style={{ color: "#2F80ED", marginLeft: "12px" }}>{t("news.channel.channel_report_analysis")}</span>
                    </div>
                </div>
            </>
        )
    }

    const renderListErrorChannel = () => {
        console.log("==renderListErrorChannel==");
        return (
            <>
                {listErrorChannel.length > 0 &&
                    (listErrorChannel.map((item) => (
                        renderItem(item)
                    )))}
                <div className={`d-flex border-top justify-content-between align-items-center`} style={{ padding: "12px 16px" }}>
                    <div onClick={() => {
                        if (project?.connection_limit > channel?.length) {
                            setIsCapLimit(false);
                            history.push('/admin/project/connect-channel')
                        } else {
                            setIsCapLimit(true);
                        }


                    }} className="d-flex align-items-center cursor-pointer">
                        <Icon style={{ color: "#6F7782", fontSize: "12px" }} name="network"></Icon>
                        <span className={`${style.postScheduleBlock__userName} fw-normal`} style={{ color: "#2F80ED", marginLeft: "12px" }}>{t("news.channel.connect")}</span>
                    </div>
                </div>

            </>
        )
    }

    const renderEmpty = () => {
        return (
            <div className={`d-flex w-100 h-100 flex-column justify-content-center align-items-center ${style.channelEmptyBlock}`}>
                <Img src={CHANNEL_EMPTY} height={"48px"}></Img>
                <span>{t("news.channel.sub_empty_channel")}</span>
                <Button onClick={() => {
                    if (project?.connection_limit > (channel?.length || 0)) {
                        setIsCapLimit(false);
                        history.push('/admin/project/connect-channel')
                    } else {
                        setIsCapLimit(true);
                    }
                }}>{t("news.channel.sub_botton_connect")}</Button>
            </div>
        )
    }

    const formatNumber = (number) => {
        if (number >= 1000000000) {
            return (number / 1000000000).toFixed(1) + 'b';
        } else if (number >= 1000000) {
            return (number / 1000000).toFixed(1) + 'm';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + 'k';
        }
        return number.toString();
    }
    const renderView = (view) => {
        return (
            <div className="d-flex align-items-center">
                {view >= 0 && <Img src={VIEW_ICON}></Img>}
                <span className={`${style.postScheduleBlock__userName}`} style={{ marginLeft: "4px" }}>{view === -1 ? t("news.channel.updating") : formatNumber(view)}</span>
            </div>
        )
    }

    const renderError = () => {
        console.log("==errro==");
        return (
            <div className={`d-flex align-items-center`}>
                <span className={`${style.postScheduleBlock__userName}`} style={{ color: "#EB5757" }}>{t('news.channel.connection_error')}</span>
                <span onClick={() => {
                    if (project?.connection_limit > channel?.length) {
                        setIsCapLimit(false);
                        history.push('/admin/project/connect-channel')
                    } else {
                        setIsCapLimit(true);
                    }


                }} className={`${style.postScheduleBlock__userName} cursor-pointer`} style={{ marginLeft: "16px", color: "#2F80ED" }}>+ {t('news.channel.reconnect')}</span>
            </div>
        )
    }

    const onChangeTab = (type) => {
        if (listErrorChannel.length === 0) {
            return;
        }
        setTab(type);
    }

    return (
        <Fragment>
            <Card className={`${style.channelConnectBlock}`} style={{ width: "336px", overflow: "auto" }}>
                <div className={`d-flex justify-content-between align-items-center ${style.channelConnectBlock__header}`}>
                    <p className={` mb-0`}>{t('news.channel.header')}</p>
                    {listChannel.length > 0 &&
                        <div className={`d-flex`} style={{ gap: "24px" }}>
                            <div onClick={() => onChangeTab('effective')} className={`d-flex cursor-pointer align-items-center`}>
                                <Img src={CHART_ICON} height={"16px"}></Img>
                                <span className={tab === "effective" && style.active}>{t('news.channel.effective')}</span>
                            </div>
                            <div onClick={() => onChangeTab('error')} className={`d-flex cursor-pointer align-items-center`}>
                                <Img src={WARN_ICON} height={"16px"}></Img>
                                <span className={tab === "error" && style.active}>{t('news.channel.error')}</span>
                            </div>
                        </div>}
                </div>
                {loading && (
                    <div className={`d-flex justify-content-center align-items-center w-100 h-100`}>
                        <Spinner></Spinner>
                    </div>
                )}
                {!loading && tab === "effective" && renderListChannel()}
                {!loading && tab === "error" && renderListErrorChannel()}
            </Card>
            <ModalCapChannel
                isOpen={isCapLimit}
                toggle={() => { setIsCapLimit(!isCapLimit) }}
                numberLimit={project?.connection_limit}
            />
        </Fragment>
    )
}

export default ChannelConnect;