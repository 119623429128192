import React from "react";
import { useTranslation } from "react-i18next";
import { Row } from "reactstrap";

import BlockDataCommonItem from "../../CommonItem/BlockDataCommonItem";
import { ChartType } from "../../constants";

const OverviewUserYoutube = ({ data, isLoading }) => {
  const { t } = useTranslation();
  const labelX = data?.videos?.dataSet?.map((d) => d?.x);
  const dataChartVideo = [
    {
      name: "",
      data: data?.videos?.dataSet?.map((d) => d?.y),
    },
  ];

  const dataChartViews = [
    {
      name: "",
      data: data?.views?.dataSet?.map((d) => d?.y),
    },
  ];

  const dataChartComment = [
    {
      name: "",
      data: data?.comment?.dataSet?.map((d) => d?.y),
    },
  ];

  return (
    <Row>
      <BlockDataCommonItem
        isLoading={isLoading}
        title={t("subscriber")}
        dataValue={data?.subscriber?.value}
        hasChart={false}
        classIcon="ri-notification-fill"
        colorIcon="#4B38B3"
      />
      <BlockDataCommonItem
        isLoading={isLoading}
        title={t("videos")}
        dataValue={data?.videos?.value}
        hasChart={true}
        labelX={labelX}
        chartType={ChartType.LINE}
        dataChart={dataChartVideo}
        classIcon="ri-folder-video-fill"
        colorIcon="#F06548"
      />
      <BlockDataCommonItem
        isLoading={isLoading}
        title={t("views")}
        dataValue={data?.views?.value}
        hasChart={true}
        labelX={labelX}
        chartType={ChartType.LINE}
        dataChart={dataChartViews}
        classIcon="ri-eye-fill"
        colorIcon="#4B38B3"
      />

      <BlockDataCommonItem
        isLoading={isLoading}
        title={t("Comment")}
        dataValue={data?.comment?.value}
        hasChart={true}
        chartType={ChartType.LINE}
        labelX={labelX}
        dataChart={dataChartComment}
        classIcon="ri-message-2-line"
        colorIcon="#4B38B3"
      />
    </Row>
  );
};

export default OverviewUserYoutube;
