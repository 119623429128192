import React, { useRef, useState } from "react";
import styles from "../PostDetail.module.scss";
import {
  addCommentWithoutAnonymous,
  deleteComment,
  editComment,
} from "../../../../api/postDetail";
import moment from "moment";
import { Card, CardBody, Collapse, Spinner } from "reactstrap";
import { uploadImage } from "../../../../api/createPost";
import { toastError } from "../../../../libs/toasts";
import ConfimDelete from "../../../components/Modal/ModalConfimDelete";
import { Mention, MentionsInput } from "react-mentions";
import { useSelector } from "react-redux";
import { Img } from "../../../../components/Component";
import { useTranslation } from "react-i18next";

const Comments = ({
  listCommnents,
  count,
  dataPostDetail,
  getListComments,
  fetchApiCount,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [openComment, setOpenComment] = useState(false);
  const [comment, setComment] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [commentId, setCommentId] = useState("");
  const [image, setImage] = useState("");
  const [loadingImage, setLoadingImage] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const positionContent = useRef(0);

  const listIcon = useSelector((state) => state.icon.Icons);
  const data =
    Object.keys(listIcon).length > 0
      ? Object.keys(listIcon)?.map((e) => ({
        id: e,
        display: "@icon{" + e + "}",
      }))
      : [];
  const text = [
    {
      id: "text{",
      display: "#text{",
    },
  ];

  const dataLocal = localStorage.getItem("userInfor");
  const infoUser = dataLocal ? JSON.parse(dataLocal) : undefined;
  const fileInputRef = useRef(null);

  const handleOpenCommnet = () => {
    setOpenComment(!openComment);
  };

  const ChangeText = (event) => {
    setComment(event.target.value);
    event.preventDefault();
  };

  const openLogin = () => {
    window.open("/auth-login", "_blank");
  };

  const handleAddComment = async () => {
    const payload = {
      project_id: dataPostDetail.project_id,
      image_url: image,
      message: comment,
    };
    if (comment !== "" || image !== "") {
      await addCommentWithoutAnonymous(dataPostDetail?._id, payload);
      getListComments();
      fetchApiCount();
      setComment("");
      setImage("");
    }
  };

  const handleEdit = (comment) => {
    setIsEdit(true);
    setComment(comment?.message);
    setCommentId(comment?.id);
    setImage(comment?.image_url);
  };

  const handleEditComment = async () => {
    const payload = {
      project_id: dataPostDetail.project_id,
      message: comment,
      image_url: image,
    };
    if (comment !== "") {
      await editComment(dataPostDetail?._id, commentId, payload);
      setIsEdit(false);
      getListComments();
      fetchApiCount();
      setComment("");
      setImage("");
      commentId("");
    }
  };

  const handleDeleteComment = async () => {
    await deleteComment(
      dataPostDetail?._id,
      commentId,
      dataPostDetail?.project_id
    );
    getListComments();
    fetchApiCount();
    setCommentId("");
  };

  const toggleDelete = (comment) => {
    setModalDelete(!modalDelete);
    setCommentId(comment?.id);
  };

  const uploadMediaSeeding = async (image) => {
    try {
      const data = new FormData();
      data.append("image", image);
      data.append("projectId", dataPostDetail.project_id);
      data.append("folder", "post");
      const response = await uploadImage(data);
      return response.data.url;
    } catch (e) {
      toastError(t("toart_alert.upload_image_error"));
      return "";
    }
  };

  const handleImageChange = async (e) => {
    const selectedFiles = Array.from(e.target.files || []);
    const fileImage = e.target.files;
    let imgSeeding = "";
    if (fileImage[0]) {
      setLoadingImage(true);
      const imgSeedingUrl = await uploadMediaSeeding(fileImage[0]);
      if (!imgSeedingUrl) {
        return;
      }
      imgSeeding = imgSeedingUrl;
      setLoadingImage(false);
      setImage(imgSeeding);
    }
  };

  const handleUploadFile = () => {
    const fileInput = document.getElementById("file-input");
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <div className={styles.commentContainer}>
      <div className={styles.headerComment} onClick={handleOpenCommnet}>
        <div className={styles.headerCommentLeft}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
            fill="rgba(240,187,64,1)"
          >
            <path d="M21 16L15.9968 21H3.99826C3.44694 21 3 20.5551 3 20.0066V3.9934C3 3.44476 3.44495 3 3.9934 3H20.0066C20.5552 3 21 3.44749 21 3.9985V16Z"></path>
          </svg>
          <span>{t("post_detail.side_right.comment.tilte")}</span>
          {count > 0 && <span className={styles.count}>{count}</span>}
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="16"
          height="16"
          fill="currentColor"
        >
          <path d="M11.9999 13.1714L16.9497 8.22168L18.3639 9.63589L11.9999 15.9999L5.63599 9.63589L7.0502 8.22168L11.9999 13.1714Z"></path>
        </svg>
      </div>
      <Collapse isOpen={openComment} style={{ visibility: "visible" }}>
        <Card>
          <CardBody style={{ padding: "0px" }}>
            <div className={styles.mainComments}>
              {isLoading ? (
                <div
                  className={"d-flex justify-center pb-2"}
                  style={{ width: "100%" }}
                >
                  <Spinner
                    size="lg"
                    color="dark"
                    style={{ width: "3rem", height: "3rem" }}
                  />
                </div>
              ) : (
                <div className={styles.listComments}>
                  {listCommnents?.data?.map((currentCommnet, _) => (
                    <div className={styles.currentCommnet}>
                      <div className={styles.infoUser}>
                        <Img
                          src={currentCommnet?.user?.avatar}
                          alt="img"
                          className={styles.avata}
                        />
                        <div className={`${styles.info} w-100`}>
                          <p className={styles.email}>
                            {currentCommnet?.user?.name}
                          </p>
                          <p className={styles.comment}>
                            {currentCommnet?.message}
                          </p>
                          {currentCommnet?.image_url ? (
                            <Img
                              src={currentCommnet?.image_url}
                              alt="img"
                              className={styles.img}
                            />
                          ) : (
                            ""
                          )}
                          <div className="d-flex align-items-center justify-content-between">
                            <p className={styles.time}>
                              {moment(
                                currentCommnet?.time_created * 1000
                              ).fromNow()}
                            </p>
                            {infoUser?.id === currentCommnet?.user.id && (
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="12"
                                  height="12"
                                  fill="rgba(135,138,153,1)"
                                  className={styles.editIcon}
                                  onClick={() => {
                                    handleEdit(currentCommnet);
                                  }}
                                >
                                  <path d="M12.8995 6.85453L17.1421 11.0972L7.24264 20.9967H3V16.754L12.8995 6.85453ZM14.3137 5.44032L16.435 3.319C16.8256 2.92848 17.4587 2.92848 17.8492 3.319L20.6777 6.14743C21.0682 6.53795 21.0682 7.17112 20.6777 7.56164L18.5563 9.68296L14.3137 5.44032Z"></path>
                                </svg>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="12"
                                  height="12"
                                  fill="rgba(240,101,72,1)"
                                  className={styles.iconBin}
                                  onClick={() => {
                                    toggleDelete(currentCommnet);
                                  }}
                                >
                                  <path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM9 11V17H11V11H9ZM13 11V17H15V11H13ZM9 4V6H15V4H9Z"></path>
                                </svg>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div className={styles.InputContainer}>
                <MentionsInput
                  onKeyUp={(e) => {
                    let position = e.target.selectionStart;
                    if (positionContent.current !== position)
                      positionContent.current = position;
                  }}
                  onClick={(e) => {
                    let position = e.target.selectionStart;
                    if (positionContent.current !== position)
                      positionContent.current = position;
                  }}
                  classNames={{
                    "form-control__control": "mt-0",
                    "form-control__input": "form-control",
                  }}
                  a11ySuggestionsListLabel={"Suggested mentions"}
                  // value={value?.message}
                  value={comment}
                  onChange={(e) => {
                    ChangeText(e);
                  }}
                  style={{ height: "160px" }}
                  placeholder={t("post_detail.side_right.comment.write_comment_placeholder")}
                // readOnly={isDisable}
                >
                  <Mention
                    //   className={style.titleMention}
                    data={data}
                    displayTransform={(username) => `@icon{${username}} `}
                  />
                  <Mention
                    trigger="#"
                    markup="#__id__"
                    data={text}
                    regex={/$(\S+)/}
                  />
                  <Mention
                    trigger="#text"
                    displayTransform={(username) => `#text{${username}}`}
                    markup="#text{__id__}"
                    data={[]}
                    regex={/#text{([^{}]*)}/}
                    //   className={style.titleMention}
                    appendSpaceOnAdd
                  />
                </MentionsInput>
                <input
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  id="file-input"
                  multiple
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
                {/* <div class="invalid-feedback">Write a comment...</div> */}
                <div className={styles.footerComment}>
                  <div className="w-100 text-left py-2x w-100">
                    {loadingImage ? (
                      <div className={styles.imageLoadingPush}>
                        <Spinner
                          size="lg"
                          color="dark"
                          style={{ width: "25px", height: "25px" }}
                        />
                      </div>
                    ) : (
                      image && (
                        <div className={styles.imagePushContainer}>
                          <Img
                            src={image}
                            alt="img"
                            className={styles.imagePushComment}
                          />
                          <div
                            className={styles.closeImage}
                            onClick={() => {
                              setImage("");
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="12"
                              height="12"
                              fill="rgba(255,255,255,1)"
                            >
                              <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path>
                            </svg>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="d-flex align-item-center justify-content-between">
                    {infoUser && Object?.keys(infoUser).length > 0 && (
                      <div className={styles.groupIcon}>
                        <div className={styles.icon}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            onClick={() => {
                              handleUploadFile();
                            }}
                          >
                            <path
                              d="M9.88578 5.17192L6.11451 8.94314C5.85416 9.20348 5.85416 9.62561 6.11451 9.88594C6.37486 10.1463 6.79698 10.1463 7.05731 9.88594L10.8286 6.11473C11.6096 5.33368 11.6096 4.06736 10.8286 3.2863C10.0475 2.50526 8.78118 2.50526 8.00011 3.2863L4.22889 7.05754C2.92715 8.35928 2.92715 10.4698 4.22889 11.7716C5.53064 13.0733 7.64118 13.0733 8.94291 11.7716L12.7142 8.00034L13.657 8.94314L9.88578 12.7144C8.06331 14.5368 5.10853 14.5368 3.28609 12.7144C1.46364 10.8919 1.46364 7.93721 3.28609 6.11473L7.05731 2.3435C8.35905 1.04175 10.4696 1.04175 11.7714 2.3435C13.0731 3.64524 13.0731 5.75579 11.7714 7.05754L8.00011 10.8288C7.21911 11.6098 5.95275 11.6098 5.17171 10.8288C4.39065 10.0477 4.39065 8.78141 5.17171 8.00034L8.94291 4.22912L9.88578 5.17192Z"
                              fill="#3577F1"
                            />
                          </svg>
                        </div>
                        <div className={styles.icon}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="16"
                            height="16"
                            fill="currentColor"
                          >
                            <path
                              d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C13.4702 20 14.8478 19.6034 16.0316 18.9114L15.0237 17.1835C14.1359 17.7026 13.1027 18 12 18C8.68629 18 6 15.3137 6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12V13C18 13.5523 17.5523 14 17 14C16.4477 14 16 13.5523 16 13V9H14.6458C13.9407 8.37764 13.0144 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C13.0465 16 13.9991 15.5982 14.7119 14.9404C15.2622 15.5886 16.0831 16 17 16C18.6569 16 20 14.6569 20 13V12ZM12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10Z"
                              fill="#3577F1"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    )}

                    <div
                      className={styles.btn}
                      //   onClick={isEdit ? handleEditComment : handleAddComment}
                      onClick={
                        infoUser && Object?.keys(infoUser).length > 0
                          ? isEdit
                            ? handleEditComment
                            : handleAddComment
                          : openLogin
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="16"
                        height="16"
                        fill="rgba(255,255,255,1)"
                      >
                        <path d="M21.7267 2.95694L16.2734 22.0432C16.1225 22.5716 15.7979 22.5956 15.5563 22.1126L11 13L1.9229 9.36919C1.41322 9.16532 1.41953 8.86022 1.95695 8.68108L21.0432 2.31901C21.5716 2.14285 21.8747 2.43866 21.7267 2.95694ZM19.0353 5.09647L6.81221 9.17085L12.4488 11.4255L15.4895 17.5068L19.0353 5.09647Z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Collapse>
      <ConfimDelete
        modalDelete={modalDelete}
        toggleDelete={toggleDelete}
        messeage={t("message.delete_post_will_data_not_display")}
        handleDelete={handleDeleteComment}
      />
    </div>
  );
};

export default Comments;
