import React, { useEffect, useState } from "react";
import styles from "./Integration.module.scss";
import { useTranslation } from "react-i18next";
import { Img } from "../../../components/Component";
import ggshetIcon from "../../images/ggsheeticon.svg";
import androidIcon from "../../images/android.svg";
import iosIcon from "../../images/ios.svg";
import hubIcon from "../../images/9hub_icon.svg";
import so9Icon from "../../images/iconSo9.svg";
import rechatIcon from "../../images/rechaticon.svg";
import { getListExportGGs } from "../../../api/integrate";
import { useHistory } from "react-router-dom";

const Integration = () => {
  const [listExportGGs, setListExportGGs] = useState([]);
  const history = useHistory();
  useEffect(() => {
    getListExportFromGGs();
  }, []);

  const getListExportFromGGs = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfor"));
    if (userInfo.last_project_active) {
      const projectId = userInfo.last_project_active;
      const data = {
        params: {
          project_id: projectId,
        },
      };
      getListExportGGs(data)
        .then((resp) => {
          if (resp.status) {
            setListExportGGs(resp.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const { t } = useTranslation();
  const data = [
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M10 2.66668H3.33333V13.3333H12.6667V5.33334H10V2.66668ZM2 1.99454C2 1.62938 2.29833 1.33334 2.66567 1.33334H10.6667L13.9998 4.66668L14 13.995C14 14.3659 13.7034 14.6667 13.3377 14.6667H2.66227C2.29651 14.6667 2 14.3631 2 14.0055V1.99454ZM8 7.33334V5.33334L10.6667 8.00001L8 10.6667V8.66668H5.33333V7.33334H8Z"
            fill="black"
          />
        </svg>
      ),
      title: t("Integrations.SynchronizeData.title"),
      componentIcon: [
        {
          icon: ggshetIcon,
          title: t("Integrations.SynchronizeData.titleGGsheet"),
          des: t("Integrations.SynchronizeData.desGGsheet"),
          btnName:
            listExportGGs?.length > 0
              ? t("Integrations.SynchronizeData.managerBtn")
              : t("Integrations.SynchronizeData.connectBtn"),
          link: t("Integrations.SynchronizeData.link"),
          color: "#2C4B94",
          notBlank: t("Integrations.SynchronizeData.notBlank"),
        },
      ],
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M6.90968 13.2218L8.19608 10.9937C8.13128 10.9979 8.06588 11 8.00001 11C6.86914 11 5.88438 10.3743 5.37314 9.45013L3.1964 5.6799C2.85698 6.38137 2.66668 7.16846 2.66668 7.99999C2.66668 10.5719 4.4871 12.7185 6.90968 13.2218ZM8.39314 13.3191C11.1551 13.1178 13.3333 10.8133 13.3333 7.99999C13.3333 7.41793 13.2401 6.85766 13.0677 6.33333H10.4948C10.8139 6.80999 11 7.38326 11 7.99999C11 8.56513 10.8437 9.09373 10.5721 9.54499L8.39314 13.3191ZM9.42894 8.85833L9.44341 8.83333C9.58548 8.58826 9.66668 8.30359 9.66668 7.99999C9.66668 7.07953 8.92048 6.33333 8.00001 6.33333C7.07954 6.33333 6.33334 7.07953 6.33334 7.99999C6.33334 8.29219 6.40854 8.56686 6.54064 8.80559L6.55664 8.83333C6.84461 9.33153 7.38314 9.66666 8.00001 9.66666C8.60654 9.66666 9.13741 9.34266 9.42894 8.85833ZM4.02349 4.4458L5.30901 6.67239C5.79882 5.68147 6.81981 4.99999 8.00001 4.99999H12.4102C11.4502 3.59148 9.83314 2.66666 8.00001 2.66666C6.4198 2.66666 5.0001 3.3539 4.02349 4.4458ZM8.00001 14.6667C4.31811 14.6667 1.33334 11.6819 1.33334 7.99999C1.33334 4.31809 4.31811 1.33333 8.00001 1.33333C11.6819 1.33333 14.6667 4.31809 14.6667 7.99999C14.6667 11.6819 11.6819 14.6667 8.00001 14.6667Z"
            fill="black"
          />
        </svg>
      ),
      title: t("Integrations.ChromeExtension.title"),
      componentIcon: [
        {
          icon: so9Icon,
          title: t("Integrations.ChromeExtension.Reup.title"),
          des: t("Integrations.ChromeExtension.Reup.des"),
          btnName: t("Integrations.ChromeExtension.Reup.btn"),
          link: t("Integrations.ChromeExtension.Reup.link"),
          color: "#3577F1",
        },
        {
          icon: rechatIcon,
          title: t("Integrations.ChromeExtension.Rechat.title"),
          des: t("Integrations.ChromeExtension.Rechat.des"),
          btnName: t("Integrations.ChromeExtension.Rechat.btn"),
          link: t("Integrations.ChromeExtension.Rechat.link"),
          color: "#3577F1",
        },
        {
          icon: hubIcon,
          title: t("Integrations.ChromeExtension.9Hub.title"),
          des: t("Integrations.ChromeExtension.9Hub.des"),
          btnName: t("Integrations.ChromeExtension.9Hub.btn"),
          link: t("Integrations.ChromeExtension.Rechat.link"),
          color: "#3577F1",
        },
      ],
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M6.90968 13.2218L8.19608 10.9937C8.13128 10.9979 8.06588 11 8.00001 11C6.86914 11 5.88438 10.3743 5.37314 9.45013L3.1964 5.6799C2.85698 6.38137 2.66668 7.16846 2.66668 7.99999C2.66668 10.5719 4.4871 12.7185 6.90968 13.2218ZM8.39314 13.3191C11.1551 13.1178 13.3333 10.8133 13.3333 7.99999C13.3333 7.41793 13.2401 6.85766 13.0677 6.33333H10.4948C10.8139 6.80999 11 7.38326 11 7.99999C11 8.56513 10.8437 9.09373 10.5721 9.54499L8.39314 13.3191ZM9.42894 8.85833L9.44341 8.83333C9.58548 8.58826 9.66668 8.30359 9.66668 7.99999C9.66668 7.07953 8.92048 6.33333 8.00001 6.33333C7.07954 6.33333 6.33334 7.07953 6.33334 7.99999C6.33334 8.29219 6.40854 8.56686 6.54064 8.80559L6.55664 8.83333C6.84461 9.33153 7.38314 9.66666 8.00001 9.66666C8.60654 9.66666 9.13741 9.34266 9.42894 8.85833ZM4.02349 4.4458L5.30901 6.67239C5.79882 5.68147 6.81981 4.99999 8.00001 4.99999H12.4102C11.4502 3.59148 9.83314 2.66666 8.00001 2.66666C6.4198 2.66666 5.0001 3.3539 4.02349 4.4458ZM8.00001 14.6667C4.31811 14.6667 1.33334 11.6819 1.33334 7.99999C1.33334 4.31809 4.31811 1.33333 8.00001 1.33333C11.6819 1.33333 14.6667 4.31809 14.6667 7.99999C14.6667 11.6819 11.6819 14.6667 8.00001 14.6667Z"
            fill="black"
          />
        </svg>
      ),
      title: t("Integrations.MobileApplication.title"),
      componentIcon: [
        {
          icon: iosIcon,
          title: t("Integrations.MobileApplication.IOS.title"),
          des: t("Integrations.MobileApplication.IOS.des"),
          btnName: t("Integrations.MobileApplication.IOS.btn"),
          link: t("Integrations.MobileApplication.IOS.link"),
          color: "#3577F1",
        },
        {
          icon: androidIcon,
          title: t("Integrations.MobileApplication.Android.title"),
          des: t("Integrations.MobileApplication.Android.des"),
          btnName: t("Integrations.MobileApplication.Android.btn"),
          link: t("Integrations.MobileApplication.IOS.link"),
          color: "#3577F1",
        },
      ],
    },
  ];

  const handleToLink = (link, notBlank) => {
    notBlank ? history.push(link) : window.open(link);
  };

  const BigComponent = ({ icon, name, data }) => {
    return (
      <div className={styles.BigComponent}>
        <div className={styles.header}>
          {icon}
          <p>{name}</p>
        </div>
        <div className={styles.main}>
          {data?.map((child, _) => (
            <ExtensionComponent
              icon={child.icon}
              name={child.title}
              des={child.des}
              btnName={child.btnName}
              link={child.link}
              color={child.color}
              notBlank={child.notBlank}
            />
          ))}
        </div>
      </div>
    );
  };

  const ExtensionComponent = ({
    icon,
    name,
    des,
    btnName,
    link,
    color,
    notBlank,
  }) => {
    return (
      <div className={styles.ExtensionComponent}>
        <div className={styles.headerComponent}>
          <Img src={icon} alt="icon" className={styles.img} />
          <p>
            {name} {notBlank ? <span style={{color:'red'}}>({listExportGGs?.length})</span> : ""}
          </p>
        </div>
        <div className={styles.mainComponent}>
          <p className={styles.des}>{des}</p>
          <button
            className={styles.btn}
            style={{ backgroundColor: color }}
            onClick={() => {
              handleToLink(link, notBlank);
            }}
          >
            {btnName}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.Integration}>
      {data?.map((item, _) => (
        <BigComponent
          icon={item.icon}
          name={item.title}
          data={item.componentIcon}
        />
      ))}
    </div>
  );
};

export default Integration;
