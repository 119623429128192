import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import "./create-post.scss";
import { Button, Modal, ModalBody, Spinner } from "reactstrap";
import { Icon, UserAvatar } from "../../../components/Component";
import {
  getListDriveConnect,
  activeDrive,
  getAuthTokenDrive,
  getThumbnailsDrive,
} from "../../../api/createPost";
import moment from "moment";
import useDrivePicker from "react-google-drive-picker";
import {
  GOOGLE_DRIVER_MIME_BY_TYPE,
  GOOGLE_DRIVER_MIME_TYPES,
  GOOGLE_DRIVER_SCOPES,
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_REEL,
  MEDIA_TYPE_VIDEO,
} from "../../utils/Utils";
import { uploadMediaImageDrive } from "../../../libs/helper";
import { toast } from "react-toastify";
import Switch from "react-switch";

const ModalDriveUpload = forwardRef((props, ref) => {
  const {
    modalDriveUpload,
    setModalDriveUpload,
    // handleFileUploadDrive,
    connectToDrive,
    getConfigDrive,
    maxPost,
    // handleOpenPicker,
    setModalMultiUpload,
    // driver,
    setZIndexParent,
    warehouseMediaType,
    setIsLoadingFile,
    setValue,
    setFormValues,
    formValues,
    isMulti,
    dataEditPostInWarehouse,
    setMedia,
    isWarehouse,
    indexCurrent,
    modalMultiUpload,
    setIsLoadingMediaInRow,
  } = props;
  const optionMediaType = [
    {
      label: "Ảnh",
      value: MEDIA_TYPE_IMAGE,
    },
    {
      label: "Video",
      value: MEDIA_TYPE_VIDEO,
    },
    {
      label: "Video Ngắn",
      value: MEDIA_TYPE_REEL,
    },
  ];

  const [listDrive, setListDrive] = useState([]);
  const [loadingListDrive, setLoadingListDrive] = useState(false);
  const [error, setError] = useState(false);
  const userInfor = JSON.parse(localStorage.getItem("userInfor"));

  const isGetThumbInStorage = localStorage?.getItem("isGetThumb");

  const [isGetThumb, setIsGetThumb] = useState(
    isGetThumbInStorage === null || isGetThumbInStorage === undefined
      ? true
      : isGetThumbInStorage == "true"
  );

  //choice file
  const [modalWarning, setModalWarning] = useState(false);

  const toggleWarning = () => {
    setModalWarning(!modalWarning);
  };

  const handleSettingGetThumb = () => {
    localStorage.setItem("isGetThumb", !isGetThumb);
    setIsGetThumb(!isGetThumb);
  };

  const handleListDrive = async () => {
    const projectId = userInfor.last_project_active;
    await getListDriveConnect(projectId)
      .then((res) => {
        setListDrive(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleActiveDrive = async (isSelected, idDrive) => {
    if (!isSelected) {
      // setLoadingListDrive(true);
      const projectId = userInfor.last_project_active;
      const data = {};
      try {
        await activeDrive(projectId, idDrive, data);
        await handleListDrive();

        setLoadingListDrive(false);
        handleOpenPicker(handleFileUploadDrive);
      } catch (error) {
        console.error(error);
      }
    } else {
      handleOpenPicker(handleFileUploadDrive);
    }
  };

  useEffect(() => {
    setLoadingListDrive(true);
    try {
      handleListDrive();
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingListDrive(false);
    }
  }, [userInfor.last_project_active]);

  const [openPicker] = useDrivePicker();

  const handleOpenPicker = async () => {
    if (setZIndexParent) setZIndexParent(1);
    setModalDriveUpload(false);
    if (setModalMultiUpload) {
      setModalMultiUpload(false);
    }

    const dataResponse = (
      await getAuthTokenDrive(userInfor.last_project_active)
    ).data;

    openPicker({
      clientId: process.env.REACT_APP_CLIENT_ID,
      developerKey: process.env.REACT_APP_DEVELOPER_KEY,
      viewId: "DOCS",
      viewMimeTypes: warehouseMediaType
        ? GOOGLE_DRIVER_MIME_BY_TYPE[warehouseMediaType]
        : GOOGLE_DRIVER_MIME_TYPES,
      supportDrives: true,
      multiselect: true,
      customScopes: GOOGLE_DRIVER_SCOPES,
      token: dataResponse,
      multiselect: true,
      appId: process.env.REACT_APP_APP_ID,
      setParentFolder: "root",
      setIncludeFolders: true,
      callbackFunction: async (data) => {
        if (data.action === "picked") {
          const idxCurrent = indexCurrent?.current;
          if (setIsLoadingMediaInRow) {
            setIsLoadingMediaInRow(indexCurrent?.current);
          }
          const driver = await getConfigDrive();

          if (!formValues[idxCurrent]) {
            setIsLoadingFile(true);
          }
          const driveFiles = data.docs.map((media) => ({
            name: media.name,
            mimeType: media.mimeType,
            drive_email: driver.token.email,
            ...media,
          }));

          let arrayIdParents = [];
          const driverFileClone = JSON.parse(JSON.stringify(driveFiles));

          const listIds = driverFileClone.map((data) => data.id);

          while (listIds.length) {
            const data = listIds.splice(0, 30);
            arrayIdParents.push(data);
          }

          let dataThumb;
          let listThumbnail = [];

          if (isGetThumb == true) {
            dataThumb = await Promise.allSettled(
              arrayIdParents.map((ids) =>
                getThumbnailsDrive({
                  project_id: userInfor.last_project_active,
                  ids: ids,
                })
              )
            );
            dataThumb
              .filter((item) => item.status === "fulfilled")
              .map((thumb) => {
                listThumbnail = [...listThumbnail, ...thumb.value.data];
              });
          }

          const driveFileFinal = driveFiles.map((file) => {
            return {
              ...file,
              drive_folder: {
                url: file.url,
                embedUrl: file.embedUrl,
              },
              thumbnailLink:
                isGetThumb == true
                  ? listThumbnail.find((thumb) => thumb.id === file.id)
                      ?.thumbnail
                  : "https://i.so9.vn/images/image-default.png",
            };
          });

          await handleFileUploadDrive(driver, driveFileFinal, idxCurrent);
        }
        if (setIsLoadingMediaInRow) {
          setIsLoadingMediaInRow(-1);
        }
        setIsLoadingFile(false);
      },
    });
  };

  const handleFileUploadDrive = async (driver, driveFiles, idxCurrent) => {
    if (isMulti) {
      const newLinePost = driveFiles.map((fileItem, index) => {
        setValue(`media_${formValues.length + index}`, fileItem);

        let descContent =
          fileItem.name.substring(0, fileItem.name.lastIndexOf(".")) || "";

        let typeMedia;

        if (fileItem.mimeType.split("/")[0] === "image") {
          typeMedia = optionMediaType[0].value;
        } else {
          if (fileItem.mimeType.split("/")[1] === "quicktime") {
            typeMedia = optionMediaType[2].value;
          } else {
            typeMedia = optionMediaType[1].value;
          }
        }

        return isWarehouse
          ? {
              topic: {},
              content: descContent,
              displayContent: descContent,
              shuffle_content: [fileItem],
              medias: [fileItem],
              mediasCloud: [],
              typeMedia: {
                value: typeMedia,
              },
              scheduledAt: null,
              drive_email: driver.token.email,
            }
          : {
              channel: [],
              content: descContent,
              displayContent: descContent,
              medias: [fileItem],
              mediasCloud: [],
              typeMedia: typeMedia,
              scheduledAt: null,
              typeChannel: "channel",
              comment_seeding: "",
              comment_seedings: [],
              display_comment_seeding: "",
              is_from_drive: true,
              drive_email: driver.token.email,
              comment_seeding_image: null,
            };
      });

      const typeMedia =
        idxCurrent !== undefined && !modalMultiUpload
          ? formValues[idxCurrent || 0]?.typeMedia?.value ||
            formValues[idxCurrent || 0]?.typeMedia
          : undefined;

      let newFormValues = [...formValues];
      if (typeMedia === MEDIA_TYPE_IMAGE) {
        const itemImage = driveFiles.filter(
          (file) => file.mimeType.split("/")[0] === "image"
        );
        if (!itemImage.length) {
          toast.error("Tệp tin chỉ có thể chứa 1 loại media là ảnh hoặc video");
        }
        let newArrMedia = newFormValues[idxCurrent || 0]["medias"];
        newArrMedia = newArrMedia.concat(itemImage);
        newFormValues[idxCurrent || 0]["medias"] = newArrMedia;
        setFormValues(newFormValues);
        setValue(`media_${idxCurrent}`, itemImage);
      } else if ([MEDIA_TYPE_VIDEO, MEDIA_TYPE_REEL].includes(typeMedia)) {
        let itemVideo = driveFiles.find(
          (file) => file.mimeType.split("/")[0] === "video"
        );
        if (itemVideo) {
          if (newFormValues[idxCurrent]?.medias?.length) {
            newFormValues = newFormValues.concat(newLinePost);
          } else if (newFormValues[idxCurrent]) {
            newFormValues[idxCurrent] = {
              ...newFormValues[idxCurrent],
              medias: newLinePost[0].medias,
              is_from_drive: newLinePost[0].is_from_drive,
              drive_email: driver.token.email,
              shuffle_content: newFormValues[idxCurrent]?.shuffle_content
                ?.length
                ? newFormValues[idxCurrent]?.shuffle_content
                : newLinePost[0].medias,
            };
          }

          // newFormValues[indexCurrent.current]["medias"] = [itemVideo];
          setFormValues(newFormValues);
          setValue(`media_${idxCurrent}`, newLinePost[0].medias);
        } else {
          toast.error("Tệp tin chỉ có thể chứa 1 loại media là ảnh hoặc video");
        }
      } else {
        newFormValues = newFormValues.concat(newLinePost);
        setFormValues(newFormValues);
      }

      // cập nhật content
      newFormValues.forEach((item, index) => {
        setValue(`content_${index}`, item.content);
      });
    } else {
      if (dataEditPostInWarehouse) {
        if (dataEditPostInWarehouse.is_photo) {
          setMedia((prevMedia) => {
            // Kiểm tra xem id đã tồn tại trong mảng prevMedia chưa
            const ids = prevMedia.map((item) => item.id);
            const filteredFiles = driveFiles.filter(
              (item) => !ids.includes(item.id)
            );

            // Thêm những file mới vào mảng prevMedia
            return [...prevMedia, ...filteredFiles];
          });

          // Lấy ra các images hiện tại từ formData
          const currentImages = formValues.images || [];

          const images = await uploadMediaImageDrive(
            driveFiles.map((file) => file.thumbnailLink || "")
          );

          const newFileImages = [];

          for (let i = 0; i < driveFiles.length; i++) {
            // if (images[i].status === "fulfilled") {
            newFileImages.push({
              type: MEDIA_TYPE_IMAGE,
              name:
                images[i]?.value?.url ||
                "https://i.so9.vn/images/image-default.png",
              alt: driveFiles[i].name,
              caption: "",
              drive_file_id: driveFiles[i].id,
              drive_file_name: driveFiles[i].name || "",
              drive_folder: driveFiles[i].drive_folder || {},
              drive_file_size: driveFiles[i].sizeBytes.toString(),
              drive_email: driver.token.email,
            });
            // }
          }

          // Tạo một bản sao của currentImages và cập nhật các trường tương ứng với file
          const updatedImages = [...currentImages, ...newFileImages];

          setValue(
            "media",
            updatedImages.filter((img) => img.name)
          );

          // Cập nhật formData với images đã cập nhật
          setFormValues((prevFormData) => ({
            ...prevFormData,
            images: updatedImages,
          }));
        } else {
          setMedia(driveFiles);
          // newFileImages.push({
          //   type: MEDIA_TYPE_IMAGE,
          //   name:
          //     images[i]?.value?.url ||
          //     "https://i.so9.vn/images/image-default.png",
          //   alt: driveFiles[i].name,
          //   caption: "",
          //   drive_file_id: driveFiles[i].id,
          //   drive_file_name: driveFiles[i].name || "",
          //   drive_folder: driveFiles[i].drive_folder || {},
          //   drive_file_size: driveFiles[i].sizeBytes.toString(),
          //   drive_email: driver.token.email,
          // });

          const newVideo = [
            {
              type: dataEditPostInWarehouse.is_normal_video
                ? MEDIA_TYPE_VIDEO
                : MEDIA_TYPE_REEL,
              name: driveFiles[0].thumbnailLink,
              thumb: driveFiles[0].thumbnailLink,
              // previewImages: {
              //   name: driveFiles[0].thumbnailLink,
              // },
              // alt: driveFiles[0].name,
              // caption: "",
              drive_file_id: driveFiles[0].id,
              drive_file_name: driveFiles[0].name || "",
              drive_folder: driveFiles[0].drive_folder || {},
              drive_file_size: driveFiles[0].sizeBytes.toString(),
              drive_email: driver.token.email,
            },
          ];

          setFormValues((prevFormData) => ({
            ...prevFormData,
            media: [driveFiles[0]?.id],
            videos: newVideo,
          }));
        }
      } else {
        const newLinePost = driveFiles.map((fileItem, index) => {
          setValue(
            `media${
              Array.isArray(formValues) ? "_" + formValues.length + index : ""
            }`,
            fileItem
          );
          setMedia((prevMedia) => {
            if (formValues.type === "video") {
              return [driveFiles[0]];
            } else {
              // Kiểm tra xem id đã tồn tại trong mảng prevMedia chưa
              const ids = prevMedia.map((item) => item.id);
              const filteredFiles = driveFiles.filter(
                (item) => !ids.includes(item.id)
              );

              // Thêm những file mới vào mảng prevMedia
              return [...prevMedia, ...filteredFiles];
            }
          });

          const newLine = fileItem;
          return newLine;
        });

        let newFormValues = [
          ...(formValues.images || []),
          ...(formValues.videos || []),
        ];
        newFormValues = newFormValues.concat(newLinePost);
        if (formValues.type === "photo") {
          setFormValues((prevFormData) => ({
            ...prevFormData,
            images: newFormValues,
            medias: driveFiles,
          }));
        } else {
          setFormValues((prevFormData) => ({
            ...prevFormData,
            videos: newFormValues,
            medias: driveFiles,
          }));
        }

        // cập nhật content
        newFormValues.forEach((item, index) => {
          setValue(`content_${index}`, item.content);
        });
      }
    }
  };

  useImperativeHandle(ref, () => ({
    async someFunction() {
      await handleActiveDrive(true, props.driver._id);
    },
  }));

  return (
    <>
      <Modal
        isOpen={modalDriveUpload}
        toggle={() => setModalDriveUpload(!modalDriveUpload)}
        className="modal-lg"
      >
        <div
          className="d-flex flex-row align-items-center justify-content-between py-3 px-4"
          style={{ backgroundColor: "#F6F6F6", width: "100%" }}
        >
          <div
            style={{ fontSize: "1.25rem", fontWeight: "500", color: "#142243" }}
          >
            GG Driver đã kết nối: {listDrive?.length}
          </div>
        </div>
        <ModalBody className="modal-body-lg p-3">
          <div>
            <h5 style={{ color: "#333333" }}>
              Bấm vào tài khoản bạn muốn truy cập vào Drive
            </h5>
            <div
              className="p-4"
              style={{
                overflowY: "auto",
                maxHeight: "450px",
                overflowX: "hidden",
                backgroundColor: "#F1F5FF",
              }}
            >
              {loadingListDrive ? (
                <div className="text-center w-100">
                  <Spinner size="md" />
                </div>
              ) : (
                listDrive.map((item, index) => (
                  <div className="d-flex justify-between">
                    <div
                      className={`d-flex flex-row align-items-start ${
                        listDrive.length - 1 === index ? "" : "mb-3"
                      } `}
                      key={index}
                    >
                      <UserAvatar
                        className="mr-2"
                        theme="primary"
                        image={item.token.avatar}
                      ></UserAvatar>
                      <div
                        style={{
                          color: error ? "#FF0000" : "#374151",
                        }}
                      >
                        <h6
                          style={{
                            color: error ? "#FF0000" : "#374151",
                          }}
                        >
                          {item.token.email}
                        </h6>

                        <div>
                          <Icon
                            name={error ? "alert-circle-fill" : "clock"}
                            className="mr-1"
                          />
                          {error
                            ? "Lỗi kết nối. Bấm vào để kết nối lại"
                            : moment
                                .unix(item.token.created)
                                .format("DD/MM/YYYY")}
                        </div>
                      </div>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        onClick={async () => {
                          await handleActiveDrive(item.is_selected, item._id);
                        }}
                      >
                        {" "}
                        Truy cập{" "}
                      </Button>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </ModalBody>
        <div
          className="d-flex flex-row align-items-center justify-content-between py-3 px-4"
          style={{ backgroundColor: "#F6F6F6" }}
        >
          <div className="w-100">
            <div className="d-flex  justify-content-between align-items-center">
              <Button
                onClick={() => {
                  connectToDrive();
                }}
                style={{
                  backgroundColor: "#2C4B94",
                  color: "#FFFFFF",
                  fontWeight: "500",
                  border: "0",
                }}
              >
                + Thêm mới Drive
              </Button>

              <div className="d-flex align-items-center">
                <Switch
                  onColor="#6576ff"
                  onChange={handleSettingGetThumb}
                  checked={isGetThumb}
                />{" "}
                <span className="p-sm-1">Lấy ảnh Thumb khi đăng</span>
              </div>
            </div>

            <div className="pt-2 font-weight-bold">
              Lưu ý khi chọn Media từ Google Drive
            </div>
            <div className="pt-1">
              <div>
                1. Không xóa File khỏi thư mục khi còn bài chưa được đăng
              </div>
              <div>
                2. Khi đăng Reels, Short thì các File phải đảm bảo theo tiêu
                chuẩn nền tảng (Xem tiêu chuẩn)
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ModalWarning
        modalWarning={modalWarning}
        toggleWarning={toggleWarning}
        warningText={`Mỗi lượt đăng được tối đa ${maxPost} bài. Vui lòng tách thành các lượt khác nhau để đăng!`}
      />
    </>
  );
});
const ModalWarning = React.lazy(() =>
  import("../../components/Modal/ModalWarning")
);
export default ModalDriveUpload;
