import React from "react";
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../helper";

const SalesForecastCharts = ({ dataColors, series }) => {
  const areachartSalesColors = getChartColorsArray(dataColors);

  var options = {
    chart: {
      type: "bar",
      height: 341,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "65%",
      },
    },
    stroke: {
      show: true,
      width: 5,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [""],
      axisTicks: {
        show: false,
        borderType: "solid",
        color: "#78909C",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value;
        },
      },
      tickAmount: 4,
      min: 0,
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      fontWeight: 500,
      offsetX: 0,
      offsetY: -14,
      itemMargin: {
        horizontal: 8,
        vertical: 0,
      },
      markers: {
        width: 10,
        height: 10,
      },
    },
    colors: areachartSalesColors,
  };
  return (
    <div style={{ maxHeight: "350px" }}>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        type="bar"
        height="341"
        className="apex-charts"
      />
    </div>
  );
};

const DealTypeCharts = ({ dataColors, series }) => {
  const dealTypeChartsColors = getChartColorsArray(dataColors);

  const options = {
    chart: {
      height: 341,
      type: "radar",
      dropShadow: {
        enabled: true,
        blur: 1,
        left: 1,
        top: 1,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 2,
    },
    fill: {
      opacity: 0.2,
    },
    legend: {
      show: true,
      fontWeight: 500,
      offsetX: 0,
      offsetY: -8,
      markers: {
        width: 8,
        height: 8,
        radius: 6,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
    },
    markers: {
      size: 0,
    },
    colors: dealTypeChartsColors,
    xaxis: {
      categories: ["2016", "2017", "2018", "2019", "2020", "2021"],
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        type="radar"
        height="341"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

const AudienceCharts = ({ series, options }) => {
  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        type="line"
        height="360"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export { SalesForecastCharts, DealTypeCharts, AudienceCharts };
