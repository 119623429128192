import Flatpickr from "react-flatpickr";
import {
  POST_PLATFORM_LABEL,
  getPlatformSquareIcon,
} from "../../../../libs/helper";
import { getColorPlatform, getPlatformIcon } from "../../../utils/Utils";
import { Icon, Img } from "../../../../components/Component";
import { useState, useRef } from "react";
import styles from "../index.module.scss";
import { Spinner } from "reactstrap";

const SelectDateTimeChannel = ({
  item,
  handleChangeTime,
  handleChangePlatformTime,
  isLastItem,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const fp = useRef(null);

  const handleOpenPlatform = () => {
    setIsOpen(!isOpen);
  };

  const onUpdateAllTime = () => {
    setIsUpdate(true);
    setTimeout(() => {
      setIsUpdate(false);
    }, 300);
  };

  const renderAvatar = (item) => {
    return (
      <div
        className={`rounded-circle bg-light position-relative`}
        style={{ height: "32px", width: "32px" }}
      >
        <Img
          src={item.picture}
          className={`rounded`}
          style={{ height: "32px", width: "32px" }}
        ></Img>
        <Img
          className="rounded-circle position-absolute"
          style={{ bottom: "0px", right: "0px" }}
          src={getPlatformIcon(item.platform)}
          height={"14px"}
          width={`14px`}
        ></Img>
      </div>
    );
  };

  return (
    <>
      <div
        style={{ marginBottom: isLastItem ? "5rem" : "0", marginTop: "1rem" }}
        key={item.platform}
      >
        <div
          className={`${styles.leftMenuPlatformHeader} d-flex rounded align-items-center py-2 justify-content-between cursor-pointer`}
          style={{ backgroundColor: getColorPlatform(item.platform) }}
        >
          <div
            className="d-flex align-items-center"
            // onClick={() => handlOpenPlatform()}
          >
            <Img
              src={getPlatformSquareIcon(item?.platform)}
              style={{ height: "14px", width: "14px" }}
            ></Img>
            <p className="mb-0 text-body ms-2 fs-12 fw-medium">
              {POST_PLATFORM_LABEL[item.platform]}
            </p>
          </div>
          <div className="d-flex align-items-center">
            <div className="position-relative d-flex">
              <Flatpickr
                ref={fp}
                className="form-control bg-light p-1 border-0 input_custom_time"
                style={{ width: "180px", opacity: "0" }}
                options={{
                  // inline: true,
                  enableTime: true,
                  // noCalendar: true,
                  altInput: true,
                  clickOpens: false,
                  allowInput: false,
                  minDate: new Date(),
                  time_24hr: true,
                }}
                // onOpen={() => {
                //   setIsOpen(false);
                // }}
                onClose={onUpdateAllTime}
                onChange={(e) => {
                  console.log('12321321=123123', e);
                  handleChangePlatformTime(item.platform, e);
                  e.stopPropagation();
                }}
              />
              <Icon
                name="calendar"
                className="pr-2 m-auto"
                style={{ fontSize: "1rem" }}
                onClick={() => {
                  if (!fp?.current?.flatpickr) return;
                  fp.current.flatpickr.open();
                }}
              />
            </div>
            <Icon
              name={isOpen ? "chevron-down" : "chevron-up"}
              style={{ fontSize: "1.5rem" }}
              onClick={handleOpenPlatform}
            />
          </div>
        </div>
        {isOpen &&
          item?.data
            ?.filter((c) => c.check === true)
            ?.map((channelItem) => (
              <div
                className="d-flex align-items-center justify-content-between"
                style={{ paddingTop: "16px" }}
              >
                <div className="d-flex align-items-center">
                  {renderAvatar(channelItem)}
                  <div className="ms-1 " style={{ width: "60px" }}>
                    <p className="mb-0 text-body fs-12 text-break text-truncate">
                      {channelItem?.name}
                    </p>
                    <p className="mb-0 text-muted fs-10">Page</p>
                  </div>
                </div>
                {isUpdate ? (
                  <Spinner size="sm" color="light" />
                ) : (
                  <div className="position-relative">
                    <Flatpickr
                      className="form-control p-1 border-0"
                      style={{ width: "150px" }}
                      options={{
                        // inline: true,
                        enableTime: true,
                        minDate: new Date(),
                        // noCalendar: true,
                        allowInput: false,
                        time_24hr: true,
                      }}
                      placeholder="Select Time"
                      value={channelItem?.scheduleTime}
                      onChange={(e) => handleChangeTime(channelItem._id, e)}
                    />
                  </div>
                )}
              </div>
            ))}
      </div>
    </>
  );
};

export default SelectDateTimeChannel;
