import React, { useEffect, useState } from "react";
import Pages from "../route/Index";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import Header from "./header/Header";
import classNames from "classnames";
import { useFirstRender } from "../hooks/useFirstTimeRender";
import { Icon } from "../components/Component";
import IntructionBook from "../So9/components/IntructionBook";
import ExperGuild from "../So9/components/ExperienceGuild";

import {
  Button,
  Popover,
  PopoverBody,
  UncontrolledTooltip,
  Tooltip,
} from "reactstrap";
import Float_Tutorial_So9 from "../images/icons/Float_Tutorial_So9.png";
import Float_Help_So9 from "../images/icons/Float_Help_So9.png";
import Float_Application_So9 from "../images/icons/Float_Application_So9.png";
import Float_CSKH_So9 from "../images/icons/Float_CSKH_So9.png";
import Icon_Apple from "../images/icons/ios.svg";
import Icon_Android from "../images/icons/google_play.svg";
import style from "./index.module.scss";
import ModalConnectPage from "../So9/components/Modal/ModalConnectPage";
import ModalExpied from "../So9/components/Modal/ExpiedAlertModal";
import { useTranslation } from "react-i18next";
import { getDomainApp, getUrlDocument } from "../utils/Utils";
const Layout = () => {
  //Sidebar

  useFirstRender();
  const [mobileView, setMobileView] = useState();
  const [visibility, setVisibility] = useState(false);
  const [tooltipFloatTutorialSo9, setTooltipFloatTutorialSo9] = useState(false);
  const [tooltipFloatHelpSo9, setTooltipFloatHelpSo9] = useState(false);
  const [tooltipFloatCSKHSo9, setTooltipFloatCSKHSo9] = useState(false);
  const [popOverFloatApplicationSo9, setPopOverFloatApplicationSo9] =
    useState(false);
  const [tooltipFloatApplicationSo9, setTooltipFloatApplicationSo9] =
    useState(false);

  const toggleTooltipFloatTutorialSo9 = () => {
    setTooltipFloatTutorialSo9(!tooltipFloatTutorialSo9);
  };
  const toggleTooltipFloatHelpSo9 = () => {
    setTooltipFloatHelpSo9(!tooltipFloatHelpSo9);
  };
  const toggleTooltipFloatCSKHSo9 = () => {
    setTooltipFloatCSKHSo9(!tooltipFloatCSKHSo9);
  };
  const togglePopOverFloatApplicationSo9 = () => {
    setTooltipFloatApplicationSo9(!tooltipFloatApplicationSo9);
    setPopOverFloatApplicationSo9(!popOverFloatApplicationSo9);
  };
  const toggleTooltipFloatApplicationSo9 = () => {
    setTooltipFloatApplicationSo9(!tooltipFloatApplicationSo9);
  };


  const [themeState] = useState({
    main: "default",
    sidebar: "dark",
    header: "white",
    skin: "light",
  });

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  useEffect(() => {
    viewChange();
  }, []);

  const toggleSidebar = (e) => {
    e.preventDefault();
    if (visibility === false) {
      setVisibility(true);
    } else {
      setVisibility(false);
    }
  };

  useEffect(() => {
    document.body.className = `nk-body bg-lighter npc-default has-sidebar no-touch nk-nio-theme ${themeState.skin === "dark" ? "dark-mode" : ""
      }`;
  }, [themeState.skin]);

  const viewChange = () => {
    if (window.innerWidth < 1200) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setVisibility(false);
    }
  };
  window.addEventListener("load", viewChange);
  window.addEventListener("resize", viewChange);

  const sidebarClass = classNames({
    "nk-sidebar-mobile": mobileView,
    "nk-sidebar-active": visibility && mobileView,
  });
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-PYZ6NNTJVG");
  }, []);
  return (
    <React.Fragment>
      <Head title="Loading">
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-PYZ6NNTJVG"
        ></script>
      </Head>
      <div className="nk-app-root">
        <div className="nk-main">
          <Sidebar
            sidebarToggle={toggleSidebar}
            fixed
            mobileView={mobileView}
            theme={themeState.sidebar}
            className={sidebarClass}
          />
          {visibility && mobileView && (
            <div className="nk-sidebar-overlay" onClick={toggleSidebar}></div>
          )}
          <div className="nk-wrap">
            <Header
              sidebarToggle={toggleSidebar}
              setVisibility={setVisibility}
              fixed
              theme={themeState.header}
            />
            <Pages />
            <div
              style={{ position: "fixed", right: "0px", top: "70%", zIndex: 3 }}
            >
              <div
                style={{ marginBottom: 1 }}
                id="tooltipFloatTutorialSo9"
                className={`${style.icon_wrapper}`}
              >
                <img
                  alt=""
                  src={Float_Tutorial_So9}
                  width={32}
                  onClick={() => {
                    const url = "https://www.youtube.com/@so9vn";
                    window.open(url, "_blank");
                  }}
                />
              </div>
              <UncontrolledTooltip
                placement="left"
                isOpen={tooltipFloatTutorialSo9}
                target="tooltipFloatTutorialSo9"
                toggle={toggleTooltipFloatTutorialSo9}
                delay={0}
              >
                Video hướng dẫn
              </UncontrolledTooltip>
              <div
                style={{ marginBottom: 1 }}
                id="tooltipFloatHelpSo9"
                className={`${style.icon_wrapper}`}
              >
                <img
                  alt=""
                  src={Float_Help_So9}
                  width={32}
                  onClick={() => {
                    const url = getUrlDocument();

                    window.open(
                      url,
                      "_blank"
                    );
                  }}
                />
              </div>
              <UncontrolledTooltip
                placement="left"
                isOpen={tooltipFloatHelpSo9}
                target="tooltipFloatHelpSo9"
                toggle={toggleTooltipFloatHelpSo9}
                delay={0}
              >
                Trợ giúp
              </UncontrolledTooltip>

              <div
                style={{ marginBottom: 1 }}
                id="popOverFloatApplicationSo9"
                className={`${style.icon_wrapper}`}
              >
                <img
                  alt=""
                  src={Float_Application_So9}
                  width={32}
                  onClick={togglePopOverFloatApplicationSo9}
                />
              </div>
              <Tooltip
                placement="left"
                target="popOverFloatApplicationSo9"
                isOpen={tooltipFloatApplicationSo9}
                toggle={toggleTooltipFloatApplicationSo9}
              >
                Ứng dụng di động
              </Tooltip>
              <Popover
                isOpen={popOverFloatApplicationSo9}
                toggle={togglePopOverFloatApplicationSo9}
                placement="left"
                target="popOverFloatApplicationSo9"
              >
                <PopoverBody>
                  <div
                    className="d-flex justify-content-end mb-2"
                    role="button"
                    onClick={() => {
                      togglePopOverFloatApplicationSo9();
                      setTooltipFloatApplicationSo9(false);
                    }}
                  >
                    <Icon name="cross" />
                  </div>
                  <div className="text-center">
                    <h6>Tải ứng dụng điện thoại</h6>
                    <div
                      className="d-flex flex-column px-2"
                      style={{ gap: "16px" }}
                    >
                      <Button
                        outline
                        color="light"
                        onClick={() => {
                          window.open(
                            "https://apps.apple.com/vn/app/so9/id6446020644",
                            "_blank"
                          );
                        }}
                      >
                        <div className="d-flex">
                          <img src={Icon_Apple} width={24} alt="Icon Apple" />
                          <div className="text-left ml-1">
                            <p className="mb-0">Download on the</p>
                            <h6>App Store</h6>
                          </div>
                        </div>
                      </Button>
                      <Button
                        outline
                        color="light"
                        onClick={() => {
                          window.open(
                            "https://play.google.com/store/apps/details?id=com.so9.vn&hl=en",
                            "_blank"
                          );
                        }}
                      >
                        <div className="d-flex">
                          <img src={Icon_Android} width={24} alt="Icon Android" />
                          <div className="text-left ml-1">
                            <p className="mb-0">Get it On</p>
                            <h6>Google Play</h6>
                          </div>
                        </div>
                      </Button>
                    </div>
                  </div>
                </PopoverBody>
              </Popover>

              <div className={`${style.icon_cskh}`} id="tooltipFloatCSKHSo9">
                <div style={{ position: "relative" }}>
                  <img
                    alt=""
                    src={Float_CSKH_So9}
                    width={32}
                    onClick={() => {
                      window.open(
                        "https://www.facebook.com/login.php?next=https%3A%2F%2Fwww.facebook.com%2Fmessages%2Ft%2F103638805810736%2F",
                        "_blank"
                      );
                    }}
                  />
                  <span className={`${style.online_tick}`}></span>
                </div>
              </div>
              <UncontrolledTooltip
                placement="left"
                isOpen={tooltipFloatCSKHSo9}
                target="tooltipFloatCSKHSo9"
                toggle={toggleTooltipFloatCSKHSo9}
                delay={0}
              >
                Chăm sóc KH
              </UncontrolledTooltip>
            </div>
            <IntructionBook currentLanguage={currentLanguage} />
            <ExperGuild currentLanguage={currentLanguage} />
          </div>
        </div>
        <ModalConnectPage />
      </div>
      <ModalExpied />
    </React.Fragment>
  );
};
export default Layout;
