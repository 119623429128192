export const PLATFORM_NAME = {
  FACEBOOK: "FACEBOOK",
  TIKTOK: "TIKTOK",
  INSTAGRAM: "INSTAGRAM",
  YOUTUBE: "YOUTUBE",
  THREADS: "THREADS"
};

export const ChartType = {
  PIE: "Pie",
  LINE: "line",
};
