import { Link } from "react-router-dom";
import { Button, Modal, ModalBody } from "reactstrap";
import { Icon } from "../../../components/Component";

const ModalSuccess = ({ ...props }) => {
  const { modal, toggle, message } = props;
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalBody className="modal-body-lg text-center">
        <div className="nk-modal">
          <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-check bg-success"></Icon>
          <h4 className="nk-modal-title">Hoàn thành</h4>
          <div className="nk-modal-text">
            <div className="caption-text">{message}</div>
          </div>
          <div className="nk-modal-action">
            <Button
              color="primary"
              size="lg"
              className="btn-mw"
              onClick={() => toggle()}
            >
              OK
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalSuccess;
