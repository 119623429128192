import {API} from "../libs/client";

export const login = async (data) => {
    const res = await API.post(`/login`, data);
    return res.data;
}

export const register = async (data) => {
    const res = await API.post(`/register`, data);
    return res.data;
}

export const verify = async (data, hash) => {
    const res = await API.post(`/users/verify/${hash}`, data);
    return res.data;
}

export const forgotPassword = async (data) => {
    const res = await API.post(`/forgotPassword/sendLink`, data);
    return res.data;
}

export const verifyChangePassword = async (data, hash) => {
    const res = await API.post(`/forgotPassword/verify/${hash}`, data);
    return res.data;
}

export const changePassword = async (data) => {
    const res = await API.post(`/forgotPassword/change`, data);
    return res.data;
}
export const resendEmail = async (data) => {
    const res = await API.post(`/users/verify/reSendLink`, data);
    return res.data;
}
export const accessNewUser = async () => {
    const res = await API.post(`/projects/users/is-new`);
    return res.data;
}
export const CreateSignin = async (data) => {
    const res = await API.post(`/projects/lead/register`, data);
    return res.data;
}
export const pingServer = async () => {
    const res = await API.get(`/ping`);
    return res.data;
}