import React from 'react'
import PostFastNewReel from "../Reels/PostFastNew";

const BulkReel = () => {
  return (
    <div style={{ marginTop: "75px" }}>
        <PostFastNewReel/>
    </div>
  )
}

export default BulkReel