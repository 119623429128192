import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { components } from "react-select";
import style from "./index.module.scss";
import { Icon, RSelect, Img } from "../../../components/Component";
import { getListTopic, getListWarehouse } from "../../../api/warehouse";
import { useQuery } from "react-query";
import { Controller, useForm } from "react-hook-form";

const ModalPost = (props) => {
  const { modal, toggle, setListPost, setValuePost, listPost } = props;
  const [checkbox1, setCheckbox1] = useState(true);
  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false);
  const [type, setType] = useState("all_post");
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState([]);
  const [optionPost, setOptionPost] = useState([]);
  const [optionTopic, setOptionTopic] = useState([]);
  const [formSpecial, setFormSpecial] = useState([]);
  const [formTopic, setFormTopic] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const loadData = async () => {
    return await getListWarehouse(userInfo.last_project_active, {
      params: {
        page: currentPage,
      },
    });
  };

  const loadTopic = async () => {
    return await getListTopic(userInfo.last_project_active, {});
  };

  let { data, isLoading, refetch } = useQuery(
    [currentPage, userInfo.last_project_active],
    loadData,
    {
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  const {
    data: topicData,
    isLoading: isTopicLoading,
    refetch: refetchTopic,
  } = useQuery([userInfo.last_project_active], loadTopic, {
    cacheTime: Infinity, // Có thể điều chỉnh theo yêu cầu của bạn
    refetchOnWindowFocus: false,
    staleTime: Infinity, // Có thể điều chỉnh theo yêu cầu của bạn
  });

  useEffect(() => {
    setOptionTopic(
      topicData?.data?.map((item) => ({
        label: item?.name,
        value: item?._id,
      }))
    );
  }, [topicData?.data?.length]);
  useEffect(() => {
    if (data?.data?.data) {
      setList(data?.data?.data);
      const newOptions = data?.data?.data?.map((item) => ({
        value: item._id,
        label: item.content,
        data: item,
      }));
      setOptionPost((prevOptions) => [...prevOptions, ...newOptions]);
    }
  }, [data?.data?.data?.length, currentPage]);

  useEffect(() => {
    if (modal && listPost) {
      if (listPost.type === "all_post") {
        handleCheckboxChange(1);
      } else if (listPost.type === "topic") {
        handleCheckboxChange(2);
        const matchingTopics = optionTopic.filter(
          (option) =>
            listPost.dataPost.includes(option.value) ||
            listPost.dataPost.some((item) => item.value === option.value)
        );
        setFormTopic(matchingTopics);
      } else if (listPost.type === "special_post") {
        const matchingPosts = optionPost.filter(
          (option) =>
            listPost.dataPost.includes(option.value) ||
            listPost.dataPost.some((item) => item.value === option.value)
        );
        setFormSpecial(matchingPosts);
        handleCheckboxChange(3);
      }
    }
  }, [modal, optionPost?.length, optionTopic?.length]);

  const handleScroll = () => {
    if (currentPage < data?.data?.last_page) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handleCheckboxChange = (checkboxNumber) => {
    if (checkboxNumber === 1) {
      setType("all_post");
      setCheckbox1(true);
      setCheckbox2(false);
      setCheckbox3(false);
    } else if (checkboxNumber === 2) {
      setType("topic");
      setCheckbox1(false);
      setCheckbox2(true);
      setCheckbox3(false);
    } else if (checkboxNumber === 3) {
      setType("special_post");
      setCheckbox1(false);
      setCheckbox2(false);
      setCheckbox3(true);
    }
  };

  const onChangeChannels = (e) => {
    setFormSpecial(e);
  };
  const onChangeTopic = (e) => {
    setFormTopic(e);
  };

  const MultiValueLabel = (props) => {
    const { data } = props,
      imgStyle = {
        flex: "0 0 14px",
        width: "14px",
        height: "14px",
        objectFit: "cover",
      },
      truncateCell = {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: "4rem",
        paddingLeft: "7px",
      };
    return (
      <components.MultiValueLabel {...props}>
        {/* <img
          src={getPlatformIcon(data.platform)}
          alt={`entity-${data.platform}`}
          style={imgStyle}
        /> */}
        <span style={truncateCell}>{data.label}</span>
      </components.MultiValueLabel>
    );
  };

  const GroupHeading = (props) => {
    const { data } = props;
    return (
      <components.GroupHeading {...props}>{data.label}</components.GroupHeading>
    );
  };
  const selectPlatformStyle = {
    multiValue: (base) => ({
      ...base,
      border: "1px solid #6A2C62",
      borderRadius: "100px !important",
      padding: "4px 11px",
      backgroundColor: "#f2f2f2 !important",
    }),
    multiValueLabel: (base) => ({
      ...base,
      display: "flex",
      alignItems: "center",
      padding: "0",
      paddingLeft: "0",
      fontSize: "12px",
      lineHeight: "1",
    }),
    multiValueRemove: (base) => ({
      ...base,
      marginLeft: "7px",
      padding: "0",
      lineHeight: "1",
    }),
  };

  const handleClickSetListPost = () => {
    if (type === "all_post") {
      setListPost({
        type: type,
        dataPost: [],
        total: data?.data?.total,
      });
    } else if (type === "topic") {
      setListPost({
        type: type,
        dataPost: formTopic.map((item) => item),
      });
    } else if (type === "special_post") {
      setListPost({
        type: type,
        dataPost: formSpecial.map((item) => item),
      });
    }
    setValuePost &&
      setValuePost("post_info", {
        type: type,
        dataPost: list,
      });
    toggle();
  };

  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
      <ModalHeader
        toggle={toggle}
        close={
          <button className="close" onClick={toggle}>
            <Icon name="cross" />
          </button>
        }
      >
        Chọn bài đăng
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md="12">
            <div
              className="custom-control custom-radio"
              style={{ opacity: checkbox1 ? 1 : 0.5 }}
            >
              <input
                type="radio"
                id="customRadio1"
                name="all_post"
                className="custom-control-input form-control"
                checked={checkbox1}
                onChange={() => handleCheckboxChange(1)}
              />
              <label className="custom-control-label" htmlFor="customRadio1">
                <p className="mb-1" style={{ fontWeight: "500" }}>
                  Tất cả bài trong kho
                </p>
                <span>Hệ thống lấy tối đa 200 bài / Chiến dịch</span>
              </label>
            </div>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md="12">
            <div
              className="custom-control custom-radio"
              style={{ opacity: checkbox2 ? 1 : 0.5 }}
            >
              <input
                type="radio"
                id="customRadio2"
                name="topic"
                className="custom-control-input form-control"
                checked={checkbox2}
                onChange={() => handleCheckboxChange(2)}
              />
              <label className="custom-control-label" htmlFor="customRadio2">
                <p className="mb-1" style={{ fontWeight: "500" }}>
                  Chọn theo chủ đề
                </p>
                <span>Hệ thống lấy tối đa 200 bài / Chiến dịch</span>
              </label>
            </div>
            <div className="ml-5 mt-1 mr-2">
              <Controller
                name={`topic`}
                control={control}
                render={({ field: { onBlur, onChange } }) => (
                  <RSelect
                    closeMenuOnSelect={false}
                    components={{
                      MultiValueLabel,
                      GroupHeading,
                    }}
                    value={formTopic}
                    isMulti
                    getOptionLabel={(v) => v.label}
                    getOptionValue={(v) => v.value}
                    onChange={(e) => {
                      onChange(e);
                      onChangeTopic(e);
                    }}
                    className="customChannelRSelect"
                    options={optionTopic}
                    styles={selectPlatformStyle}
                    onBlur={onBlur}
                    isDisabled={!checkbox2}
                  />
                )}
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md="12">
            <div className="d-flex flex-column">
              <div
                className="custom-control custom-radio"
                style={{ width: "100%", opacity: checkbox3 ? 1 : 0.5 }}
              >
                <input
                  type="radio"
                  id="customRadio3"
                  name="special_post"
                  className="custom-control-input form-control"
                  checked={checkbox3}
                  onChange={() => handleCheckboxChange(3)}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadio3"
                  style={{ width: "100%" }}
                >
                  <p className="mb-1" style={{ fontWeight: "500" }}>
                    Nhập ID bài đăng
                  </p>
                </label>
              </div>
              <div className="ml-5 mt-1 mr-2">
                <Controller
                  name={`post`}
                  control={control}
                  render={({ field: { onBlur, onChange } }) => (
                    <RSelect
                      closeMenuOnSelect={false}
                      components={{
                        MultiValueLabel,
                        GroupHeading,
                      }}
                      value={formSpecial}
                      isMulti
                      getOptionLabel={(v) => v.label}
                      getOptionValue={(v) => v.value}
                      onChange={(e) => {
                        onChange(e);
                        onChangeChannels(e);
                      }}
                      className="customChannelRSelect"
                      options={optionPost}
                      styles={selectPlatformStyle}
                      onBlur={onBlur}
                      isDisabled={!checkbox3}
                      onMenuScrollToBottom={(e) => handleScroll()}
                    />
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
        <div className={`${style.btnCreatePost} mt-4`}>
          <div className="d-flex justify-content-end">
            <Button
              color="light"
              className="mr-2"
              onClick={() => toggle()}
              //   disabled={isUploading}
            >
              <span style={{ textTransform: "uppercase" }}>Huỷ</span>
            </Button>
            <Button
              style={{ background: "#2C4B94" }}
              //   disabled={isUploading}
              onClick={() => handleClickSetListPost()}
            >
              <span style={{ textTransform: "uppercase", color: "#fff" }}>
                Lưu lại
              </span>
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalPost;
