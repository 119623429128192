import React, { useEffect, useState } from "react";
import { Card, Collapse } from "reactstrap";
import style from "../index.module.scss";
import { getListTask } from "../../../../api/dashboard";
import CheckedBox from "../../../../components/CheckedBox";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getDomainApp } from "../../../../utils/Utils";
import { Img } from "../../../../components/Component";
import noContentTask from "../../../../images/nocontentTask.svg";
import { useSelector } from "react-redux";

const ListSection = ({ title, dataList, index, handleGetLink }) => {
  const [openSection, setOpenSection] = useState(true);
  return (
    <div className={style.itemSection}>
      {/* <div
        className={style.headerSection}
        onClick={() => setOpenSection(!openSection)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <path d="M6 8L3 5H9L6 8Z" fill="black" />
        </svg>
        <span>{title}</span>
      </div> */}
      <div className={style.ListTask}>
        {dataList?.map((item, index) => (
          <ListTask
            title={item.name}
            nameGroup={title}
            dataList={item.tasks}
            index={index}
            handleGetLink={handleGetLink}
            idGroup={item.group_id}
          />
        ))}
      </div>
    </div>
  );
};

const ListTask = ({
  title,
  dataList,
  index,
  nameGroup,
  handleGetLink,
  idGroup,
}) => {
  const [openTask, setOpenTask] = useState(true);
  return (
    <div className={style.ItemTask}>
      <div className={style.headerTask} onClick={() => setOpenTask(!openTask)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <path d="M6 8L3 5H9L6 8Z" fill="black" />
        </svg>
        <span>
          {title} <span className={style.groupTask}>({nameGroup})</span>
        </span>
      </div>
      <Collapse
        isOpen={openTask}
        style={{ visibility: "visible" }}
        className={style.contentListTask}
      >
        {dataList?.map((item, index) => (
          <div
            className={style.listTask}
            key={index}
            onClick={() => {
              handleGetLink(idGroup, item._id);
            }}
          >
            <div className={style.leftHeader}>
              <CheckedBox checked={item?.is_complete} />
              <p>{item.title}</p>
            </div>
            <div className={style.rightHeader}>
              <div></div>
              <div className={style.time}>
                {moment(item?.time * 1000).fromNow()}
              </div>
            </div>
          </div>
        ))}
      </Collapse>
    </div>
  );
};
const TaskList = () => {
  const { t } = useTranslation();

  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const projectId = userInfo.last_project_active;
  const project = useSelector((state) => state?.project?.project);
  const isBusiness = project?.type === "business";

  const [openSideBar, setOpenSideBar] = useState(false);
  const [taskListData, setTaskListData] = useState();

  useEffect(() => {
    fetchDataTask();
  }, [projectId]);

  const fetchDataTask = () => {
    getListTask(projectId, {})
      .then((res) => {
        setTaskListData(res?.data);
      })
      .catch((error) => console.log(error));
  };

  const handleGetLink = (groupId, taskId) => {
    const domain = getDomainApp();
    const text = `${domain}/task-management?group_id=${groupId}&task_id=${taskId}`;
    window.open(text, "_blank");
  };

  return (
    <>
      <Card
        className={`${style.StaticStatus} ${style.card} `}
        style={{ marginTop: "0" }}
      >
        <div className={style.header}>
          <p>{t("news.task_manager")}</p>
        </div>
        <div className={`${style.main} ${style.mainTask}`}>
          {isBusiness ? (
            <>
              {taskListData?.length > 0 ? (
                taskListData?.map((task, index) => (
                  <ListSection
                    title={task.name}
                    dataList={task.sections}
                    index={index}
                    handleGetLink={handleGetLink}
                  />
                ))
              ) : (
                <div className={style.noContent}>
                  <Img
                    src={noContentTask}
                    alt="no content"
                    className={style.imgNoContent}
                  />
                  <p>{t("news.nocontent")}</p>
                </div>
              )}
            </>
          ) : (
            <div className={style.business} style={{ height: "100%" }}>
              <h3>
                {t("post_detail.side_right.alert_only_avaliable_business")}
              </h3>
              <p>
                {t("post_detail.side_right.sub_alert_only_avaliable_business")}
              </p>
              <button
                onClick={() => {
                  window.open("https://so9.vn/pricing/", "_blank");
                }}
              >
                <div className={style.circle}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                    fill="rgba(255,255,255,1)"
                  >
                    <path d="M12.0006 18.26L4.94715 22.2082L6.52248 14.2799L0.587891 8.7918L8.61493 7.84006L12.0006 0.5L15.3862 7.84006L23.4132 8.7918L17.4787 14.2799L19.054 22.2082L12.0006 18.26Z"></path>
                  </svg>
                </div>
                {t("post_detail.side_right.botton_upgrade")}
              </button>
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

export default TaskList;
