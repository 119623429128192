import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  channels: [],
};

export const researchSlice = createSlice({
  name: "research",
  initialState,
  reducers: {
    setChannelFollows(state, action) {
      state.channels = action.payload;
    },
    // setChannelsInformation(state, action) {
    //   state.channelsInformation = action.payload;
    // // },
    // setChannelUnFollow(state, action) {
    //   state.postsDeleted = action.payload;
    // },
    // setErrorConnectChannelMesssages(state, action) {
    //   state.errorConnectChannelMesssages = action.payload;
    // },
  },
});

export const { setChannelFollows } = researchSlice.actions;

export default researchSlice.reducer;
