import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Modal } from "reactstrap";
import { toastError, toastSuccess } from "../../../libs/toasts";
import { getProject } from "../../../api/project";
import {
  createGroupTask,
  deleteGroupTask,
  listGroupTask,
  listSectionTask,
  listTask,
  markGroupTask,
  updateGroupTask,
} from "../../../api/kanban";
import { getStaff } from "../../../api/dashboard";
import { KanbanGroupForm } from "./component/KanbanGroupForm";
import KanbanBoard from "./component/KanbanBoard";
import LeftMenu from "./component/LeftMenu";
import styles from "./index.module.scss";
import "./styles.css";
import { useDispatch } from "react-redux";
import { setCollapseSidebarLeft } from "../../reducers/layoutSlice";

const TaskManagement = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [isOpenLeftTab, setIsOpenLeftTab] = useState(true);
  const [activeGroup, setActiveGroup] = useState({});
  const [activeFilter, setActiveFilter] = useState("");
  const [type, setType] = useState("");
  const [orderBy, setOrderBy] = useState("priority");
  const [sort, setSort] = useState("asc");
  const [isComplete, setIsComplete] = useState("false");
  const [groupModal, setGroupModal] = useState(false);
  const [editGroup, setEditGroup] = useState();
  const [initialData, setInitialData] = useState({
    tasks: {},
    columns: {},
    columnOrder: [],
    groupData: [],
  });
  const [listGroup, setListGroup] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [groupIdParam, setGroupIdParam] = useState(null);
  const [filterParams, setFilterParams] = useState(null);
  const [isBusiness, setIsBusiness] = useState(true);

  useEffect(() => {
    dispatch(setCollapseSidebarLeft(true));
    setIsLoading(true);
    getProject(userInfo.last_project_active).then((project) => {
      if (Object.keys(project).length !== 0) {
        if (project.type === "business") {
          setIsBusiness(true);
        } else {
          setIsBusiness(false);
        }
      }
    });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchType = searchParams.get("type");
    const searchOrderBy = searchParams.get("order_by");
    const searchSort = searchParams.get("sort");
    const searchIsComplete = searchParams.get("is_complete");

    if (searchIsComplete === "true") {
      setActiveFilter("completed");
    } else if (searchIsComplete === "false") {
      setActiveFilter("ongoing");
    } else {
      setActiveFilter("");
    }

    if (searchType) setType(searchType);
    if (searchOrderBy) setOrderBy(searchOrderBy);
    if (searchSort) setSort(searchSort);
    if (searchIsComplete) setIsComplete(searchIsComplete);
  }, []);

  const getGroupData = async () => {
    return await listGroupTask(userInfo.last_project_active);
  };

  let { data: dataGroup, isLoading: isLoadingGroup } = useQuery(
    [userInfo.last_project_active],
    getGroupData,
    {
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  const groups = dataGroup?.data;

  useEffect(() => {
    setListGroup(groups);

    const url = new URL(window.location.href);
    const groupId = url.searchParams.get("group_id");
    setGroupIdParam(groupId);
  }, [groups]);

  useEffect(() => {
    if (groupIdParam) {
      setActiveGroup(listGroup?.find((group) => group._id === groupIdParam));
    } else {
      setActiveGroup(listGroup?.find((group) => !!group.is_selected));
    }
  }, [listGroup]);

  const handleDeleteGroup = (groupId) => {
    const newGroups = listGroup.filter((group) => group._id !== groupId);
    setListGroup(newGroups);
    setActiveGroup(listGroup[0]);
    deleteGroupTask(userInfo.last_project_active, groupId)
      .then((data) => {
        toastSuccess(t("task_management.toast.delete_group_success"));
      })
      .catch((error) => {
        console.error("group delete error", error);
        toastError(t("task_management.toast.delete_group_failed"));
      });
  };

  const handleUpdateGroup = (groupId, data) => {
    updateGroupTask(userInfo.last_project_active, groupId, data)
      .then((response) => {
        const newGroups = listGroup.map((group) => {
          if (group._id === groupId) {
            group = { ...group, ...data };
          }
          return group;
        });
        setListGroup(newGroups);
        toastSuccess(t("task_management.toast.update_group_success"));
      })
      .catch((error) => {
        toastError(t("task_management.toast.update_group_failed"));
      });
  };

  const handleCreateGroup = (data) => {
    createGroupTask(userInfo.last_project_active, data)
      .then((data) => {
        const group = data.data;
        listGroup.push(group);
        setListGroup(listGroup);
        setActiveGroup(group);
        toastSuccess(t("task_management.toast.create_group_success"));
      })
      .catch((error) => {
        toastError(t("task_management.toast.create_group_failed"));
      });
  };

  const getSectionData = async () => {
    if (activeGroup) {
      return await listSectionTask(
        userInfo.last_project_active,
        activeGroup._id
      );
    }
  };

  let {
    data: dataSection,
    isLoading: isLoadingSection,
    refetch: refetchSection,
    isRefetching: isRefetchingSection,
  } = useQuery(
    [userInfo.last_project_active, activeGroup?._id],
    getSectionData,
    {
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  const sections = dataSection?.data;

  const getTasksData = async (sectionId) => {
    return await listTask(
      userInfo.last_project_active,
      activeGroup?._id,
      sectionId,
      1,
      type,
      orderBy,
      sort,
      isComplete,
      filterParams
    );
  };

  const getAllTasksData = async () => {
    if (sections && sections?.length > 0) {
      const tasksPromises = sections?.map(async (section) => {
        const tasksData = await getTasksData(section?._id);
        return tasksData?.data;
      });
      const tasksDataArray = await Promise.all(tasksPromises);
      return tasksDataArray;
    }
    return [];
  };

  let {
    data: dataAllTask,
    isLoading: isLoadingTasks,
    refetch: refetchTasks,
    isRefetching,
  } = useQuery(
    [userInfo.last_project_active, activeGroup?._id, "allTasks"],
    getAllTasksData,
    {
      enabled: !!sections,
      onSettled: () => {
        setIsLoading(false);
      },
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    if (sections && dataAllTask) {
      let tasksData = [];
      for (const item of dataAllTask) {
        tasksData = [...tasksData, ...item.tasks];
      }

      const allTasks = dataAllTask ? tasksData : [];

      const tasks = allTasks?.reduce((acc, current) => {
        acc[current?._id] = {
          ...current,
        };
        return acc;
      }, {});

      const columns = sections?.reduce((acc, section, index) => {
        const taskArray = dataAllTask.find(
          (item) => item?.section_id === section?._id
        )?.tasks;
        const taskIds = taskArray?.map((item) => item._id);

        acc[section?._id] = {
          ...section,
          cnt_task: dataAllTask.find(
            (item) => item?.section_id === section?._id
          )?.cnt_task,
          tasks: taskIds,
        };
        return acc;
      }, {});

      const columnOrder = sections?.map((section) => section?._id) || [];

      setInitialData({
        tasks,
        columns,
        columnOrder,
        groupData: listGroup,
      });
    }
  }, [sections, dataAllTask, listGroup]);

  useEffect(() => {
    refetchTasks();
  }, [dataSection, activeFilter]);

  useEffect(() => {
    refetchSection();
  }, [activeGroup]);

  const handleActiveGroup = (item) => {
    setActiveGroup(item);
    markGroupTask(userInfo.last_project_active, item?._id);
  };

  const dataParam = {
    params: {
      project_id: userInfo.last_project_active,
      status: 1,
    },
  };

  const getStaffData = async () => {
    return await getStaff(userInfo.last_project_active, dataParam);
  };

  let { data: staffData } = useQuery(
    [userInfo.last_project_active, dataParam],
    getStaffData,
    {
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  const staffs = staffData?.data;

  const toggleFilter = (tab) => {
    setIsLoading(true);
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;

    if (activeFilter !== tab) {
      if (tab === "all") {
        searchParams.delete("filter");
        searchParams.delete("is_complete");
        searchParams.delete("order_by");
        searchParams.delete("sort");
        setType("");
        setIsComplete("");
        setOrderBy("");
        setSort("");
      } else if (["started_at", "ended_at", "time", "priority"].includes(tab)) {
        searchParams.set("order_by", tab);
        setOrderBy(tab);
        if (tab === "started_at" || tab === "time") {
          setSort("asc");
          searchParams.set("sort", "asc");
        } else if (tab === "ended_at") {
          setSort("desc");
          searchParams.set("sort", "desc");
        }
      } else if (["is_owner", "is_subcriber"].includes(tab)) {
        searchParams.set("filter", tab);
        setType(tab);
      } else if (tab === "ongoing") {
        searchParams.set("is_complete", "false");
        setIsComplete("false");
      } else if (tab === "completed") {
        searchParams.set("is_complete", "true");
        setIsComplete("true");
      }

      history.push(`${url.pathname}?${searchParams.toString()}`);
      setActiveFilter(tab);
    }
  };

  useEffect(() => {
    if (
      type !== undefined ||
      orderBy !== undefined ||
      sort ||
      isComplete ||
      filterParams
    ) {
      refetchSection();
      // refetchTasks();
    }
  }, [type, orderBy, sort, isComplete, filterParams, refetchSection]);

  const toggleGroupModal = (item) => {
    setEditGroup(item);
    setGroupModal(!groupModal);
  };

  return (
    <>
      <div
        className={`ms-5 ${styles.textWrapper}`}
        style={{ paddingTop: "72px", height: "100%", margin: 0 }}
      >
        <div
          className={`${styles.kContainer} h-100 d-flex flex flex-nowrap`}
          style={{ minHeight: "calc(100vh - 72px)" }}
        >
          <LeftMenu
            isOpenLeftTab={isOpenLeftTab}
            listGroup={listGroup}
            isLoadingGroup={isLoadingGroup}
            activeGroup={activeGroup}
            handleActiveGroup={handleActiveGroup}
            toggleFilter={toggleFilter}
            activeFilter={activeFilter}
            toggleGroupModal={toggleGroupModal}
            handleDeleteGroup={handleDeleteGroup}
            isBusiness={isBusiness}
          />
          <KanbanBoard
            isOpenLeftTab={isOpenLeftTab}
            setIsOpenLeftTab={setIsOpenLeftTab}
            toggleFilter={toggleFilter}
            isLoadingSection={
              isLoading ||
              isLoadingGroup ||
              isLoadingSection ||
              isLoadingTasks ||
              isRefetchingSection ||
              isRefetching
            }
            isLoadingTasks={isLoadingTasks}
            initialData={initialData}
            setInitialData={setInitialData}
            activeGroup={activeGroup}
            staffs={staffs}
            type={type}
            orderBy={orderBy}
            sort={sort}
            isComplete={isComplete}
            setFilterParams={setFilterParams}
            isBusiness={isBusiness}
            activeFilter={activeFilter}
          />
        </div>
      </div>

      <Modal size="lg" isOpen={groupModal} toggle={toggleGroupModal}>
        <KanbanGroupForm
          toggle={toggleGroupModal}
          group={editGroup}
          handleCreateGroup={handleCreateGroup}
          handleUpdateGroup={handleUpdateGroup}
        />
      </Modal>
    </>
  );
};

export default TaskManagement;
