import { createSlice } from "@reduxjs/toolkit";
import { uniqBy } from "lodash";

const dashboardInitial = {
  "1": [{
    "subTab": "1",
    "channel": [],
    "range": 14
  }],
  "2": [],
  "3": [],
  "4": []
}

const initialState = {
  dashboards: {
    "1": [],
    "2": [],
    "3": [],
    "4": [],
  },
  isInit: 0, // zero initial
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setTabDashboard(state, action) {
      const { platform, subTab, channel, range } = action.payload;
      state.dashboards = {
        ...state.dashboards,
        [platform]: uniqBy([...state.dashboards[platform], {
          subTab,
          channel,
          range
        }], "subTab")
      };
      state.isInit = state.isInit + 1
    },
    updateTabDashboard(state, action) {
      const { platform, subTab, channel, range } = action.payload;
      state.dashboards = {
        ...state.dashboards,
        [platform]: uniqBy(state.dashboards[platform].map(item =>
          item.subTab === subTab ? { ...item, channel, range } : item
        ), "subTab")
      };
      state.isInit = state.isInit + 1
    },
    resetDashboard(state) {
      state.dashboards = dashboardInitial;
      if (state.isInit !== 4) { // 4 tab
        state.isInit = 0;
      }
    }
  },
});

export const { setTabDashboard, resetDashboard, updateTabDashboard } = dashboardSlice.actions;

export default dashboardSlice.reducer;
