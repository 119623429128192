import _ from "lodash";
import React, { useEffect, useState } from "react";

import {
  getAudienceYTNew,
  getLogFetchYT,
  getSummaryYT,
} from "../../../../../api/dashboard";
// import { LogHeader } from "../CommonItem/LogHeader";
import { getUserInfoInLocalStorage } from "../../helper";

import Audience from "./Audience";
import FrequencyYoutubeTable from "./FrequencyYoutubeTable";
import OverviewUserYoutube from "./OverviewUserYoutube";
import { LogHeader } from "../../CommonItem/LogHeader";
import { PLATFORM_NAME } from "../../constants";
import Content from "../../../../../layout/content/Content";
import { Block } from "../../../../../components/Component";
import { Col } from "reactstrap";

const Youtube = ({
  channels,
  setLoadingChannelFilter,
  setLoadingRange,
  from,
  to,
}) => {
  const userInfor = getUserInfoInLocalStorage();
  const projectId = userInfor.last_project_active;

  const [summaryDataYt, setSummaryDataYt] = useState({});
  const [audienceDataYt, setAudienceDataYt] = useState({});
  const [isLoadingOverviewUser, setIsLoadingOverviewUser] = useState(true);
  const [isLoadingAudience, setIsLoadingAudience] = useState(true);
  const [logYTData, setLogYTData] = useState({});
  const getAPIYoutube = (data) => {
    setIsLoadingAudience(true);
    setIsLoadingOverviewUser(true);
    Promise.allSettled([
      getSummaryYT(data),
      getAudienceYTNew(data),
      getLogFetchYT(data),
    ]).then((responses) => {
      // Destructure the responses to get the data
      const [summaryDataYTRes, audienceYTRes, logYTRes] = responses;

      if (summaryDataYTRes.status === "fulfilled") {
        const _data = summaryDataYTRes.value.data;
        setSummaryDataYt(_data);
      }
      setIsLoadingOverviewUser(false);

      if (audienceYTRes.status === "fulfilled") {
        const _data = audienceYTRes.value.data;
        setAudienceDataYt(_data);
      }
      setIsLoadingAudience(false);

      if (logYTRes.status === "fulfilled") {
        const logYT = logYTRes.value.data;
        setLogYTData(logYT);
      }

      setLoadingChannelFilter(false);
      setLoadingRange(false);
    });
  };

  const handleGetDataStatistic = () => {
    const data = {
      params: {
        project_id: projectId,
        // time: `${range} days`,
        channel_ids: channels,
        from,
        to,
      },
    };

    getAPIYoutube(data);
  };

  useEffect(() => {
    handleGetDataStatistic();
  }, [from, to, channels]);

  return (
    <>
      <Col lg={12} className="px-8">
        <LogHeader
          cnt_channel_error={logYTData?.cnt_channel_error}
          cnt_channel_success={logYTData?.cnt_channel_success}
          last_time={logYTData?.last_time}
          currentTab={PLATFORM_NAME.YOUTUBE}
        />
      </Col>

      <div style={{ padding: "18px" }}>
        {/* <Block> */}
        <OverviewUserYoutube
          data={summaryDataYt}
          isLoading={isLoadingOverviewUser}
        />
        {/* </Block> */}
        {audienceDataYt && (
          // <Block>
          <Audience data={audienceDataYt} isLoading={isLoadingAudience} />
          // </Block>
        )}

        {/* <Block> */}
        <div style={{ paddingTop: "18px" }}>
          <FrequencyYoutubeTable projectId={projectId} from={from} to={to} />
        </div>
        {/* </Block> */}
      </div>
    </>
  );
};

export default Youtube;
