import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import {
  VictoryArea,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryTheme,
} from "victory";
import { formatNumber } from "../../../../../../utils/Utils";

import style from "../../../index.module.scss";

const YTPost = ({ data }) => {
  const totalEnagement = data?.enagements?.reduce(
    (total, item) => total + item.y,
    0
  );
  const totalPost = data?.post?.reduce((total, item) => total + item.y, 0);

  const dataPost = data?.post?.map((item) => {
    const dateObj = new Date(item.x);
    const shortYear = dateObj.getFullYear().toString().slice(2);
    const month = (dateObj.getMonth() + 1).toString();
    const day = dateObj.getDate().toString();
    const formattedDate = `${day}/${month}`;
    return { x: formattedDate, y: item.y };
  });
  const dataEnagement = data?.enagements?.map((item) => {
    const dateObj = new Date(item.x);
    const shortYear = dateObj.getFullYear().toString().slice(2);
    const month = (dateObj.getMonth() + 1).toString();
    const day = dateObj.getDate().toString();
    const formattedDate = `${day}/${month}`;
    return { x: formattedDate, y: item.y };
  });

  const maxPostY = dataPost?.reduce((maxY, item) => {
    return item.y > maxY ? item.y : maxY;
  }, 0);

  const maxEnagementY = dataEnagement?.reduce((maxY, item) => {
    return item.y > maxY ? item.y : maxY;
  }, 0);

  const lableX = dataPost?.map((item) => {
    return item.x;
  });
  const getCustomTickLabel = (tick, index) => {
    if (lableX?.length <= 15) {
      // Hiển thị toàn bộ label khi số lượng label ít hơn hoặc bằng 15
      return tick;
    } else {
      // Hiển thị label của phần tử đầu, giữa và cuối
      if (
        index === 0 ||
        index === Math.floor(lableX?.length / 2) ||
        index === lableX?.length - 1
      ) {
        return tick;
      } else {
        return "";
      }
    }
  };

  return (
    <Row>
      <Col xs={12} md={12}>
        <Card className="h-100">
          <CardBody
            className="card-inner w-100"
            // style={{ position: "absolute" }}
          >
            <CardTitle tag="h5" className={`${style.headCard}`}>
              Top post
            </CardTitle>
            <div
              className="w-100"
              // style={{ position: "relative", top: -55 }}
            >
              <VictoryChart
                width={800}
                height={300}
                domain={{ y: [0, 1.05] }}
                padding={{ top: 5, left: 50, right: 50, bottom: 35 }}
              >
                <VictoryBar
                  data={dataPost && dataPost}
                  style={{
                    data: {
                      fill: "#2C4B94",
                    },
                  }}
                  y={(datum) => datum.y / maxPostY}
                />

                <VictoryAxis
                  dependentAxis={true}
                  tickValues={[0.25, 0.5, 0.75, 1]}
                  tickFormat={(t) => (t * maxPostY).toFixed(0)}
                  // tickValues={[0, 250, 500, 750, 1000]}
                  style={{
                    axis: {
                      stroke: "#2C4B94",
                      // transform: "translate(-5px, 0)",
                    },
                    ticks: {
                      stroke: "#2C4B94",
                      size: 5,
                      // transform: "translate(-5px, 0)",
                    },
                    tickLabels: {
                      fill: "#2C4B94",
                      fontSize: 12,
                      padding: 5,
                    },
                  }}
                />

                <VictoryArea
                  data={dataPost && dataPost}
                  y={(datum) => datum.y / maxEnagementY}
                  style={{
                    data: {
                      fill: "#F8C6D1",
                      fillOpacity: 0.3, // Điều chỉnh giá trị fillOpacity để làm màu fill trở nên mờ hơn
                      stroke: "#F8C6D1",
                      strokeWidth: 2,
                    },
                  }}
                />
                <VictoryAxis
                  dependentAxis={true}
                  orientation="right"
                  tickValues={[0.25, 0.5, 0.75, 1]}
                  tickFormat={(t) => (t * maxEnagementY).toFixed(0)}
                  // tickValues={[0, 250, 500, 750, 1000]}
                  style={{
                    axis: {
                      stroke: "#2C4B94",
                      // transform: "translate(-5px, 0)",
                    },
                    ticks: {
                      stroke: "#2C4B94",
                      size: 5,
                      // transform: "translate(-5px, 0)",
                    },
                    tickLabels: {
                      fill: "#2C4B94",
                      fontSize: 12,
                      padding: 5,
                    },
                  }}
                />
                <VictoryAxis
                  style={{
                    axis: {
                      stroke: "#2C4B94",
                      // transform: "translate(-5px, 0)",
                    },
                    ticks: { stroke: "#2C4B94", size: 5 },
                    tickLabels: { fill: "#2C4B94", fontSize: 12, padding: 5 },
                  }}
                  tickValues={lableX}
                  tickFormat={(tick, index) => getCustomTickLabel(tick, index)}
                  labelComponent={<VictoryLabel />}
                  domain={[0, dataPost?.length + 1]}
                />
              </VictoryChart>
              <div
                className="d-flex flex-row justify-content-center"
                style={{ gap: "16px" }}
              >
                <div className="d-flex flex-row align-items-center">
                  <div
                    className={`rounded-circle mr-1`}
                    style={{
                      height: "8px",
                      width: "8px",
                      backgroundColor: "#2C4B94",
                      flexShrink: "0",
                    }}
                  ></div>
                  <div className={`${style.contentCard}`}>
                    {formatNumber(totalPost)} Bài
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <div
                    className={`rounded-circle mr-1`}
                    style={{
                      height: "8px",
                      width: "8px",
                      backgroundColor: "#FFD9EB",
                      flexShrink: "0",
                    }}
                  ></div>
                  <div className={`${style.contentCard}`}>
                    {formatNumber(totalEnagement)} Tương tác
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default YTPost;
