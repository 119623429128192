import React from "react";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";
import style from "../../../index.module.scss";
import { Icon } from "../../../../../../components/Component";
import ChartCircle from "../../ChartCircle";
import { formatNumberAcronym } from "../../../../../../utils/Utils";

const TTOverviewUser = ({ data }) => {
  return (
    <div
      className="d-flex flex-column flex-md-row w-100"
      style={{ gap: "16px" }}
    >
      <div className="d-flex flex-row  w-100" style={{ gap: "16px" }}>
        <div className="w-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Tổng lượt Theo dõi
              </CardTitle>
              <CardText className={`${style.titleCard}`}>
                {data && formatNumberAcronym(data?.follower?.value)}
              </CardText>
            </CardBody>
          </Card>
        </div>
        <div className="w-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Lượt Theo dõi mới
              </CardTitle>
              <div className="d-flex flex-row align-items-center">
                <div className={`${style.titleCard}`}>
                  {data && formatNumberAcronym(data?.new_follower?.value)}
                </div>
                {data?.new_follower?.percent_change > 0 ? (
                  <div className="ml-1" style={{ color: "#10B981" }}>
                    {" "}
                    <Icon name={"arrow-up"}></Icon>{" "}
                    {data ? data?.new_follower?.percent_change : 0}%
                  </div>
                ) : (
                  <div className="ml-1" style={{ color: "#e85347" }}>
                    {" "}
                    <Icon name={"arrow-down"}></Icon>{" "}
                    {data ? data?.new_follower?.percent_change : 0}%
                  </div>
                )}
              </div>
              <CardText className={`${style.detailCard} mt-1`}>{`Trung bình: ${
                data && data?.new_follower?.avg_day
              }/ngày`}</CardText>
            </CardBody>
          </Card>
        </div>
      </div>
      <div
        className="d-flex flex-column flex-md-row w-100"
        style={{ gap: "16px" }}
      >
        <div className="w-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Lượt xem hồ sơ
              </CardTitle>
              <div className="d-flex flex-row align-items-center">
                <div className={`${style.titleCard}`}>
                  {data && formatNumberAcronym(data?.profile_view?.value)}
                </div>
              </div>
              <CardText className={`${style.detailCard} mt-1`}>{`Trung bình: ${
                data && data?.profile_view?.avg_day
              }/ngày`}</CardText>
            </CardBody>
          </Card>
        </div>
        <div className="w-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Giới tính
              </CardTitle>
              <ChartCircle data={data?.genders} />
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default TTOverviewUser;
