import { useRef, useEffect } from "react";
import SimpleBar from "simplebar-react";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import "flatpickr/dist/themes/material_blue.css";
import { Button } from "reactstrap";
import SelectDateTimeChannel from "./SelectDateTimeChannel";
import { Icon, RSelect } from "../../../../components/Component";
import { useTranslation } from "react-i18next";

const Schedule = ({
  isOpen,
  setOpen,
  data,
  setType,
  setTime,
  setDateTime,
  channels,
  setListChannel,
  listChannel,
}) => {
  const { t } = useTranslation();
  const listHours = Array.from({ length: 24 }, (_, i) => {
    const hour = i.toString().padStart(2, "0");
    return { value: `${hour}`, label: `${hour}` };
  });

  const simpleBarRef = useRef();

  const setIsOpenCavas = (value) => {
    setOpen(value);
  };

  const handleChangeTime = (id, e) => {
    let data = [...listChannel.channel];
    data = data.map((item) => {
      if (item._id === id) {
        item.scheduleTime = e[0];
      }
      return item;
    });

    setListChannel({ ...listChannel, channel: data });
  };

  const handleChangePlatformTime = (platform, e) => {
    let data = [...listChannel.channel];
    data = data.map((item) => {
      if (item.platform === platform) {
        item.scheduleTime = e[0];
      }
      return item;
    });
    setListChannel({ ...listChannel, channel: data });
  };

  const renderListChannel = () =>
    channels?.map((item, index) => {
      if (item?.data?.filter((c) => c.check === true)?.length > 0) {
        return (
          <SelectDateTimeChannel
            item={item}
            handleChangeTime={handleChangeTime}
            handleChangePlatformTime={handleChangePlatformTime}
            isLastItem={channels?.length === index + 1}
          />
        );
      }
    });

  useEffect(() => {
    // const handleMouseDown = (event) => {
    //   if (simpleBarRef.current && simpleBarRef.current.contains(event.target)) {
    //     //
    //   } else {
    //     setIsOpenCavas(false);
    //   }
    // };
    // document.addEventListener("mousedown", handleMouseDown);
    // return () => {
    //   document.removeEventListener("mousedown", handleMouseDown);
    // };
  }, []);

  let hoursValid = listHours;
  if (moment().format("YYYY-MM-DD") === data.date) {
    const currentHour = new Date().getHours();
    hoursValid = listHours.filter((item) => item.value > currentHour);
  }

  const onChangeDateTime = (e) => {
    setDateTime(e[0]);
  };

  return (
    <>
      <div
        className={`nk-demo-panel nk-demo-panel-2x toggle-slide toggle-slide-right toggle-screen-any p-0  ${isOpen ? "content-active" : ""
          }`}
        style={{ maxWidth: "420px", minWidth: "420px" }}
        ref={simpleBarRef}
      >
        <SimpleBar className="h-100" style={{ maxHeight: "100%" }}>
          <div>
            <div
              className="d-flex justify-content-between p-3"
              style={{ borderBottom: "1px solid #FBFCFC" }}
            >
              <div className="d-flex">
                <Icon
                  name="calendar-alt-fill"
                  className={"mr-2"}
                  style={{ color: "#0AB39C", fontSize: "1.5rem" }}
                />
                <p style={{ fontWeight: "500", fontSize: "1rem" }}>Schedule</p>
              </div>
              <Icon
                name="cross"
                className={"cursor-pointer"}
                style={{ fontSize: "1.5rem" }}
                onClick={() => setIsOpenCavas(false)}
              />
            </div>
            <div className="d-flex flex-column h-100 justify-content-between p-0">
              <div>
                <div className="p-3 border-bottom justify-center">
                  <div
                    className="d-flex"
                    style={{ width: "360px", height: "46px" }}
                  >
                    <div
                      className={`flex-fill ${data.type === "same"
                        ? "bg-secondary text-schedule"
                        : "bg-light text-body"
                        } cursor-pointer rounded-start`}
                      onClick={() => setType("same")}
                    >
                      <p className="fs-13 text-center mb-0 mt-1">
                        {t('store_post.schedule.when.schedule.option.same_time.title')}
                      </p>
                      < p className="fs-12 text-center mb-0 fw-light" >
                        {t('store_post.schedule.when.schedule.option.same_time.description')}
                      </p>
                    </div>
                    <div
                      className={`flex-fill ${data.type === "random"
                        ? "bg-secondary text-schedule"
                        : "bg-light text-body"
                        } cursor-pointer`}
                      onClick={() => setType("random")}
                    >
                      <p className="fs-13 text-center mb-0 mt-1">
                        {t('store_post.schedule.when.schedule.option.random_time.title')}
                      </p>
                      <p className="fs-12 text-center mb-0 fw-light">
                        {t('store_post.schedule.when.schedule.option.random_time.description')}
                      </p>
                    </div>
                    <div
                      className={`flex-fill ${data.type === "custom"
                        ? "bg-secondary text-schedule"
                        : "bg-light text-body"
                        } cursor-pointer rounded-end`}
                      onClick={() => setType("custom")}
                    >
                      <p className="fs-13 text-center mb-0 mt-1">
                        {t('store_post.schedule.when.schedule.option.custom_time.title')}
                      </p>
                      <p className="fs-12 text-center mb-0 fw-light">
                        {t('store_post.schedule.when.schedule.option.custom_time.description')}
                      </p>
                    </div>
                  </div>
                </div>

                {data.type !== "custom" ? (
                  <div className="p-3">
                    <p className="mb-2 fs-13 text-dark">
                      {data.type === "same"
                        ? t('store_post.schedule.when.schedule.option.same_time.select')
                        : t('store_post.schedule.when.schedule.option.random_time.select')
                      }
                    </p>
                    <div className="mb-2">
                      {data.type === "same" ? (
                        <Flatpickr
                          key="same-time"
                          className="form-control d-none w-100"
                          options={{
                            inline: true,
                            enableTime: true,
                            allowInput: false,
                            time_24hr: true,
                          }}
                          onChange={onChangeDateTime}
                        />
                      ) : (
                        <Flatpickr
                          key="random-time"
                          className="form-control d-none w-100"
                          options={{
                            inline: true,
                            dateFormat: "Y-m-d",
                            allowInput: false,
                          }}
                          value={data.date}
                          onChange={(e) =>
                            setTime("date", moment(e[0]).format("YYYY-MM-DD"))
                          }
                        />
                      )}
                    </div>

                    <div className="mb-2 mt-10">
                      {data.type !== "same" && (
                        <div className="d-flex justify-content-center items-center">
                          <div className="mr-2">
                            {t('store_post.schedule.when.schedule.option.random_time.from')}
                          </div>
                          <div style={{ width: "4rem" }}>
                            <RSelect
                              options={hoursValid}
                              onChange={(e) => {
                                setTime("timeStart", e.value);
                              }}
                              defaultValue={hoursValid[0]}
                            />
                          </div>
                          <span className="align-center mx-1">
                            {t('store_post.schedule.when.schedule.option.random_time.to')}
                          </span>
                          <div style={{ width: "4rem" }}>
                            <RSelect
                              style={{ width: "4rem" }}
                              options={hoursValid}
                              onChange={(e) => {
                                setTime("timeEnd", e.value);
                              }}
                              defaultValue={hoursValid[0]}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {data.type === "same" && (
                      <div
                        className="p-2 rounded mt-5"
                        style={{ border: "1px solid #3577F1" }}
                      >
                        <p className="fs-13 mb-0" style={{ color: "#3577F1" }}>
                          {data.time} {data.date}
                        </p>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="p-3">
                    <p className="mb-2 fs-13 text-dark">
                      {t('store_post.schedule.when.schedule.option.custom_time.select')}
                    </p>
                    {renderListChannel()}
                  </div>
                )}
              </div>
              <div
                className="py-3 px-3 border-top w-100"
                style={{
                  position: "fixed",
                  bottom: "-6px",
                  right: "0",
                  backgroundColor: "#FBFCFC",
                  boxShadow: "0px 1px 0px 0px #CED4DA inset;",
                  textAlign: "end",
                }}
              >
                <Button
                  className="py-1 border-0"
                  style={{ backgroundColor: "#0068e5" }}
                  onClick={() => setIsOpenCavas(false)}
                >
                  {t('store_post.schedule.when.schedule.button.continue')}
                </Button>
              </div>
            </div>
          </div>
        </SimpleBar>
      </div>
    </>
  );
};

export default Schedule;
