import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  FormGroup,
  FormText,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { Icon, RSelect, Img } from "../../../components/Component";
import style from "./index.module.scss";
import { Controller, useForm } from "react-hook-form";
import { getChannels } from "../../../api/createPost";
import { getListGroupChannels } from "../../../api/channel";
import { components } from "react-select";
import {
  PLATFORM_FACEBOOK,
  PLATFORM_FACEBOOKGROUP,
  PLATFORM_INSTAGRAM,
  getPlatformIcon,
} from "../../utils/Utils";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import ModalWarningSM from "../../components/Modal/ModalWarningSM";

const ModalChannel = (props) => {
  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  const { modal, toggle, setListChannel, setValueChannel, listChannel } = props;
  let params = useQuery();
  const [typeChannel, setTypeChannel] = useState("channel");
  const { SingleValue, Option } = components;
  const { i18n, t } = useTranslation();
  const [listTopic, setListTopic] = useState([]);
  const [modalWarning, setModalWarning] = useState(false);
  const toggleWarning = () => {
    setModalWarning(!modalWarning);
  };

  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const optionChannelsEnd = useRef(false);
  const [formValues, setFormValues] = useState([]);
  const onChangeChannels = (e) => {
    setFormValues(e);
  };
  const handleOptionChangeTypeChannel = (event) => {
    onChangeChannels([]);
    setTypeChannel(event.target.value);
  };
  const [optionChannels, setOptionChannels] = useState([]);
  const [channels, setChannels] = useState([]);
  useEffect(async () => {
    if (userInfo.last_project_active && !optionChannelsEnd.current) {
      const projectId = userInfo.last_project_active;
      const data = {};
      const fetchData = async () => {
        return await getChannels(projectId, data);
      };
      const dataResponse = await fetchData();
      if (dataResponse.status) {
        const { channel: channels, favorite_channel: _favoriteChannels } =
          dataResponse.data;
        const favoriteChannelIds = _favoriteChannels.map((c) => c._id);
        const favoriteChannels = channels.filter((c) =>
          favoriteChannelIds.includes(c._id)
        );
        const uniqueChannelPlatforms = [
          PLATFORM_FACEBOOK,
          PLATFORM_FACEBOOKGROUP,
          PLATFORM_INSTAGRAM,
        ];

        const _optionChannels = [
          {
            label: "create_post.channel_favorite",
            options: favoriteChannels,
          },
          ...uniqueChannelPlatforms.map((channelPlatform) => ({
            label: `create_post.channel_${channelPlatform}`,
            options: channels.filter(
              (c) =>
                !favoriteChannelIds.includes(c._id) &&
                c.platform === channelPlatform
            ),
          })),
        ];
        setOptionChannels(_optionChannels);
        const presetChannelIds = params.getAll("channels");
        if (presetChannelIds.length > 0) {
          const _channels = channels.filter((channel) =>
            presetChannelIds.includes(channel._id)
          );
          setChannels(_channels);
        }
        optionChannelsEnd.current = true;
        const groupChannelResponse = await getListGroupChannels(projectId);
        if (groupChannelResponse.status) {
          const groupChannelData = groupChannelResponse.data.map(
            (item, index) => {
              const { channel_ids, color, name, _id } = item;
              const listChannelItem = channel_ids.map((ids) =>
                channels.find((eleChannel) => eleChannel._id === ids)
              );
              const listChannelItemFilter = listChannelItem.filter(
                (item) => item !== undefined
              );
              return {
                name: name,
                _id: _id,
                channel: listChannelItemFilter,
              };
            }
          );
          setListTopic(groupChannelData);
        }
      }
    } 
  }, [params]);

  useEffect(() => {
    if (modal && listChannel) {
      setTypeChannel(listChannel?.type);
      if (listChannel?.type === "channel") {
        setFormValues(
          optionChannels?.reduce((acc, channelGroup) => {
            // Lọc các kênh con có _id giống với id trong listChannel.dataChannel
            const filteredOptions = channelGroup?.options?.filter((option) =>
              listChannel?.dataChannel?.some(
                (item) => item.id === option._id || item._id === option._id
              )
            );

            // Nếu có phần tử trong filteredOptions, thêm vào mảng acc
            if (filteredOptions?.length > 0) {
              acc.push(...filteredOptions);
            }

            console.log(acc);

            return acc;
          }, [])
        );
      } else {
        const filteredOptions = listTopic?.filter((option) =>
          listChannel?.dataChannel?.some(
            (item) => item.id === option._id || item._id === option._id
          )
        );
        // Đặt giá trị mới cho formValues
        setFormValues(filteredOptions);
      }
    }
  }, [modal, optionChannels?.length, listTopic?.length]);

  const MultiValueLabel = (props) => {
    const { data } = props,
      imgStyle = {
        flex: "0 0 14px",
        width: "14px",
        height: "14px",
        objectFit: "cover",
      },
      spanStyle = { paddingLeft: "7px" };
    return (
      <components.MultiValueLabel {...props}>
        <Img
          src={getPlatformIcon(data.platform)}
          alt={`entity-${data.platform}`}
          style={imgStyle}
        />
        <span style={spanStyle}>{data.name}</span>
      </components.MultiValueLabel>
    );
  };
  const GroupHeading = (props) => {
    const { data } = props;
    return (
      <components.GroupHeading {...props}>
        {t(data.label)}
      </components.GroupHeading>
    );
  };
  const selectPlatformStyle = {
    multiValue: (base) => ({
      ...base,
      border: "1px solid #6A2C62",
      borderRadius: "100px !important",
      padding: "4px 11px",
      backgroundColor: "#f2f2f2 !important",
    }),
    multiValueLabel: (base) => ({
      ...base,
      display: "flex",
      alignItems: "center",
      padding: "0",
      paddingLeft: "0",
      fontSize: "12px",
      lineHeight: "1",
    }),
    multiValueRemove: (base) => ({
      ...base,
      marginLeft: "7px",
      padding: "0",
      lineHeight: "1",
    }),
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const handleChannel = () => {
    if (typeChannel === "channel") {
      const hasEmptySettingTime = formValues.some(
        (item) => item.setting_distribute_time.length === 0 && !item?.is_setting_advance
      );

      if (hasEmptySettingTime) {
        toggleWarning();
        return;
      }
    } else {
      const hasEmptySettingTime = formValues.some((item) =>
        item?.channel?.some((v) => v.setting_distribute_time.length === 0 && !v?.is_setting_advance) 
      );

      if (hasEmptySettingTime) {
        toggleWarning();
        return;
      }
    }

    setListChannel({
      type: typeChannel,
      dataChannel: formValues,
    });
    setValueChannel && setValueChannel("channel_info", formValues);
    toggle();
  };
  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
      <ModalHeader
        toggle={toggle}
        close={
          <button className="close" onClick={toggle}>
            <Icon name="cross" />
          </button>
        }
      >
        Chọn Trang đăng
      </ModalHeader>
      <ModalBody>
        <div>
          <label className="mr-2" style={{ color: "#000000" }}>
            <input
              name={`channel`}
              type="radio"
              value="channel"
              className="mr-1"
              checked={typeChannel === "channel"}
              onChange={(e) => handleOptionChangeTypeChannel(e)}
            />
            Kênh
          </label>

          <label style={{ color: "#000000" }}>
            <input
              name={`group_channel`}
              type="radio"
              className="mr-1"
              value="group_channel"
              checked={typeChannel === "group_channel"}
              onChange={(e) => handleOptionChangeTypeChannel(e)}
            />
            Nhóm kênh
          </label>

          {/* Selection box  */}
          {typeChannel === "group_channel" ? (
            <div style={{ marginBottom: ".5rem" }}>
              <Controller
                name={`group_channel`}
                control={control}
                render={({ field: { onBlur, onChange } }) => (
                  <RSelect
                    closeMenuOnSelect={false}
                    components={{
                      MultiValueLabel,
                      GroupHeading,
                    }}
                    value={formValues}
                    isMulti
                    getOptionLabel={(v) => v.name}
                    getOptionValue={(v) => v._id}
                    onChange={(e) => {
                      onChange(e);
                      onChangeChannels(e);
                    }}
                    className="customChannelRSelect"
                    options={listTopic}
                    styles={selectPlatformStyle}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>
          ) : (
            <FormGroup>
              <div className="form-control-wrap">
                <Controller
                  name={`channel`}
                  control={control}
                  rules={{
                    required: "Bạn cần chọn kênh đăng bài",
                  }}
                  render={({ field: { onBlur, onChange } }) => (
                    <RSelect
                      closeMenuOnSelect={false}
                      components={{
                        MultiValueLabel,
                        GroupHeading,
                      }}
                      value={formValues}
                      isMulti
                      getOptionLabel={(v) => v.name}
                      getOptionValue={(v) => v._id}
                      onChange={(e) => {
                        onChange(e);
                        onChangeChannels(e);
                      }}
                      className="customChannelRSelect"
                      options={optionChannels}
                      styles={selectPlatformStyle}
                      onBlur={onBlur}
                    />
                  )}
                />
              </div>
              {errors && errors[`channel`] && (
                <FormText color="danger">{errors[`channel`]?.message}</FormText>
              )}
            </FormGroup>
          )}
        </div>

        <div className={`${style.btnCreatePost} mt-4`}>
          <div className="d-flex justify-content-end">
            <Button
              color="light"
              className="mr-2"
              onClick={() => toggle()}
              //   disabled={isUploading}
            >
              <span style={{ textTransform: "uppercase" }}>Huỷ</span>
            </Button>
            <Button
              style={{ background: "#2C4B94" }}
              //   disabled={isUploading}
              onClick={() => handleChannel()}
            >
              {/* {isUploading ? (
                    <div
                      className="spinner-border"
                      role="status"
                      style={{
                        width: "16px",
                        height: "16px",
                      }}
                    ></div>
                  ) : (
                    <span style={{ textTransform: "uppercase", color: "#fff" }}>
                      Lưu lại
                    </span>
                  )} */}
              <span style={{ textTransform: "uppercase", color: "#fff" }}>
                Lưu lại
              </span>
            </Button>
          </div>
        </div>
      </ModalBody>
      <ModalWarningSM
        modalWarning={modalWarning}
        toggleWarning={toggleWarning}
        channels={
          typeChannel === "channel"
            ? formValues?.filter(
                (item) => item?.setting_distribute_time?.length === 0 && !item?.is_setting_advance
              )
            : formValues
                ?.filter((item) =>
                  item?.channel?.some(
                    (v) => v?.setting_distribute_time?.length === 0 && !v?.is_setting_advance
                  )
                )
                .map((item) =>
                  item?.channel?.filter(
                    (v) => v?.setting_distribute_time?.length === 0 && !item?.is_setting_advance
                  )
                )
                .flat()
        }
      />
    </Modal>
  );
};

export default ModalChannel;
