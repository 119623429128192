import React, { useState } from "react";
import { Card } from "reactstrap";
import { Row, Col, RSelect } from "../../../components/Component";
import style from "./index.module.scss";
import { useDispatch } from "react-redux";
import {
    setTiktokOption
} from "../../reducers/reelsSlice";
const TikTokVideoOption = ({ tiktokOption }) => {
    const dispatch = useDispatch();
    const Privacy = [
        { value: "public", label: "Công khai" },
        { value: "private", label: "Riêng tư" },
        { value: "unlisted", label: "Không công khai" },
    ]
    const ThumbOffset = [
        { value: true, label: "Cắt từ đầu video" },
        { value: false, label: "Cắt từ cuối video" },
    ]
    const [openOptionTikTok, setOpenOptionTikTok] = useState(true)
    const handleOpenOptionTikTok = () => {
        setOpenOptionTikTok(!openOptionTikTok)
        const Elmchevron = document.getElementById(`chevron_optionTikTok`);
        const Elmcontent = document.getElementById("content_optionTikTok");
        if (!openOptionTikTok) {
            Elmchevron.classList.add(`${style.openChevronDown}`)
            Elmchevron.classList.remove(`${style.openChevronUp}`)
            Elmcontent.classList.add(`${style.openContentDown}`)
            Elmcontent.classList.remove(`${style.openContentUp}`)
        }
        else {
            Elmchevron.classList.add(`${style.openChevronUp}`)
            Elmchevron.classList.remove(`${style.openChevronDown}`)
            Elmcontent.classList.add(`${style.openContentUp}`)
            Elmcontent.classList.remove(`${style.openContentDown}`)
        }
    }

    return (
        <Card className="card-bordered mb-2">
            <div className="d-flex align-items-stretch border-bottom">
                <div
                    className="p-2 fw-medium"
                    style={{ color: "#333333", fontFamily: "Roboto" }}
                    onClick={handleOpenOptionTikTok}
                >
                    Tùy chỉnh Tiktok
                </div>
            </div>
            <div id="content_optionTikTok" style={{
                transition: "max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s",
                willChange: "max-height",
                position: "relative",
                overflow: "auto"
            }}>
                <div className={style.OptionYoutube}>
                    <Row className={`pb-2 mb-2`}>
                        <Col md="6">
                            <label className={style.OptionYoutube__title}>Ai có thể xem video này?</label>
                            <RSelect
                                options={Privacy}
                                placeholder="Chọn người xem"
                                onChange={(e) => {
                                    tiktokOption = { ...tiktokOption, visibility: e.value }
                                    dispatch(setTiktokOption(tiktokOption));
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className={`pb-2 mb-2`}>
                        <Col md="3">
                            <div className="d-flex">
                                <input
                                    id="audien"
                                    name="audien"
                                    className="mr-2"
                                    type="checkbox" />
                                <span className={style.OptionYoutube__radio}>Comment</span>
                            </div>
                        </Col>
                        <Col md="3">
                            <div className="d-flex">
                                <input
                                    id="audien"
                                    name="audien"
                                    className="mr-2"
                                    type="checkbox" />
                                <span className={style.OptionYoutube__radio}>Duet</span>
                            </div>
                        </Col>
                        <Col md="3">
                            <div className="d-flex">
                                <input
                                    id="audien"
                                    name="audien"
                                    className="mr-2"
                                    type="checkbox" />
                                <span className={style.OptionYoutube__radio}>Stitch</span>
                            </div>
                        </Col>
                    </Row>
                    <Row className={`pb-2 mb-2`}>
                    <Col md="6">
                    <label className={style.OptionYoutube__title}>Chọn ảnh thumb cho video</label>
                        <div  className="d-flex justify-between"
                        onClick={
                            (e) => {
                                tiktokOption = { ...tiktokOption, thumbnail_offset: e.target.value === "true" ? true : false }
                                dispatch(setTiktokOption(tiktokOption));
                            }
                        }>
                                <div className="d-flex">
                                    <input
                                        value="true"
                                        id="Thumb"
                                        name="Thumb"
                                        className="mr-2"
                                        type="radio" />
                                    <span className={style.OptionYoutube__radio}>Khung hình đầu</span>
                                </div>


                                <div className="d-flex">
                                    <input
                                        value="false"
                                        id="Thumb"
                                        name="Thumb"
                                        className="mr-2"
                                        type="radio" />
                                    <span className={style.OptionYoutube__radio}>Khung hình cuối</span>
                                </div>
                        </div>
                    </Col>
                    </Row>
                </div>
            </div>
            <div id="chevron_optionTikTok" className={`card-tools position-absolute p-2 top-0 right-0 ${style.openMedia}`} onClick={handleOpenOptionTikTok}>
                <svg width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" /> </svg>
            </div>
        </Card>
    );
};
export default TikTokVideoOption;
