import { useTranslation } from "react-i18next";

import BlockDataCommonItem from "../../CommonItem/BlockDataCommonItem";
import { ChartType } from "../../constants";

export const OverviewUserTiktok = ({ data, isLoading }) => {
  const { t } = useTranslation();

  const labelX = data?.new_follower?.dataSet?.map((d) => d?.x);

  const dataChartNewFollowers = [
    {
      name: "",
      data: data?.new_follower?.dataSet?.map((d) => d?.y),
    },
  ];

  const dataChartProfileViews = [
    {
      name: "",
      data: data?.profile_view?.dataSet?.map((d) => d?.y),
    },
  ];

  return (
    <>
      <BlockDataCommonItem
        isLoading={isLoading}
        title={t("followers")}
        dataValue={data?.follower?.value}
        hasChart={false}
        classIcon="ri-user-follow-fill"
        colorIcon="#4B38B3"
        tooltipLabel={t("general_overview.tooltip.channel.tiktok.follower")}
        tooltipId={"about_follower"}
      />

      <BlockDataCommonItem
        isLoading={isLoading}
        title={t("new_follower")}
        dataValue={data?.new_follower?.value}
        hasChart={true}
        labelX={labelX}
        chartType={ChartType.LINE}
        dataChart={dataChartNewFollowers}
        classIcon="ri-user-follow-fill"
        colorIcon="#4B38B3"
        tooltipLabel={t("general_overview.tooltip.channel.tiktok.new_follower")}
        tooltipId={"about_new_follower"}
      />

      <BlockDataCommonItem
        isLoading={isLoading}
        title={t("page_profile_view")}
        dataValue={data?.profile_view?.value}
        hasChart={true}
        chartType={ChartType.LINE}
        labelX={labelX}
        dataChart={dataChartProfileViews}
        classIcon="ri-empathize-fill"
        colorIcon="#F06548"
        tooltipLabel={t(
          "general_overview.tooltip.channel.tiktok.page_profile_view"
        )}
        tooltipId={"about_page_profile_view"}
      />

      <BlockDataCommonItem
        isLoading={isLoading}
        title={t("gender")}
        hasChart={true}
        chartType={ChartType.PIE}
        dataChart={data?.genders}
        classIcon="ri-women-fill"
        colorIcon="#3577F1"
        tooltipLabel={t("general_overview.tooltip.channel.tiktok.gender")}
        tooltipId={"about_gender"}
      />
    </>
  );
};
