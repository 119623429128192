import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import twStyle from "../../../../assets/scss/extend/tailwind-clone/index.module.scss";
import style from "./index.module.scss";
const AddSubscription = ({ ...props }) => {
  const { t } = useTranslation();
  const { subscription_used } = props;
  let expiredAt = null;
  if (subscription_used) {
    expiredAt = moment(subscription_used.expired_at * 1000);
  }
  return (
    <>
      <div className={`${style.Title} ${twStyle.px2}`}>{t("project_subscription_using")}</div>
      <div className={`${twStyle.flex} ${twStyle.mt4} ${twStyle.px4} ${twStyle.py4} ${twStyle.bgWhite}`}>
        <div className={`${twStyle.flex} ${twStyle.flex1}`}>
          <div>
            <img alt="" src={"/images/diamond.png"} width={60} height={60} />
          </div>
          <div className={`${twStyle.ml4}`}>
            <div className={`${twStyle.textLg} ${twStyle.fontMedium}`} style={{ color: "#333333" }}>
              Gói {subscription_used?.name || "Mini"}
            </div>
            <div className={`${twStyle.textSm}`} style={{ color: "#6F7782" }}>
              {t("project_extension_date")}: {expiredAt?.format("DD.MM.YYYY")}
            </div>
            <div className={`${twStyle.mt3} ${twStyle.flex}`}>
              <div
                className={`${twStyle.px2} ${twStyle.py1} ${twStyle.textSm} ${twStyle.roundedLg}`}
                style={{ color: "#0395EE", background: "#E6F3FF" }}
              >
                {`${subscription_used?.connection_limit} ${t("project_page")}`}
              </div>
              <div
                style={{ color: "#C99100", background: "#FFF8E8" }}
                className={`${twStyle.ml2} ${twStyle.px2} ${twStyle.py1} ${twStyle.textSm} ${twStyle.roundedLg}`}
              >
                {`${subscription_used?.member_limit} ${t("project_employee_sub")}`}
              </div>
              <div
                className={`${twStyle.ml4} ${twStyle.textSm} ${twStyle.fontMedium} text-[#6A2C62] ${twStyle.flex} ${twStyle.itemsCenter} ${twStyle.cursorPointer}`}
              >
                {`+ ${t("project_buy_more")}`}
              </div>
            </div>
          </div>
        </div>
        <Link to={"/admin/subscription/list"}>
          <div
            className={`${twStyle.cursorPointer} ${twStyle.px4} ${twStyle.py2} ${twStyle.textLg} ${twStyle.textWhite} ${twStyle.fontSemiBold} ${twStyle.roundedLg}`}
            style={{ height: "fit-content", background: "#FD1F6D" }}
          >
            {t("project_renew").toUpperCase()}
          </div>
        </Link>
      </div>
    </>
  );
};

export default AddSubscription;
