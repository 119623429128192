import React, { useEffect, useState, useRef, Suspense, lazy } from "react";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
  Button,
  Row,
  ProjectCard,
  UserAvatar,
  Col,
  PaginationComponent,
  Img
} from "../../../../components/Component";
import { findUpper, getDomainForCookie } from "../../../../utils/Utils";
import { imagesPrice, capitalize } from "../../../utils/Utils";
import {
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import twStyle from "../../../../assets/scss/extend/tailwind-clone/index.module.scss";
import { getAllProject, getProject } from "../../../../api/project";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setProject } from "../../../../reducers/projectSlice";
import moment from "moment";
import { NUMBER_OF_TRIAL_DAY_PROJECT } from "../../../../config/project";
import { ToastContainer } from "react-toastify";
import { updateProfile } from "../../../../api/user";
import { toastBasic } from "../../../../libs/toasts";
import Cookies from "js-cookie";
import { Spinner } from "reactstrap";
import { getDomainApp } from "../../../../utils/Utils";

const CreateProject = lazy(() => import("./ModalProject/CreateProject"));
const UpdateProject = lazy(() => import("./ModalProject/UpdateProject"));
const ChangeSuccess = lazy(() => import("./ModalProject/ChangeSuccess"));
const WarningEmptyProject = lazy(() =>
  import("./ModalProject/WarningEmptyProject")
);
const ProjectCardPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sm, updateSm] = useState(false);
  const [modalForm, setModalForm] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const userInfor = JSON.parse(localStorage.getItem("userInfor"));
  const [modalEdit, setModalEdit] = useState(false);
  const [projectEdit, setProjectEdit] = useState({});
  const [modalWarningEmptyProject, setModalWarningEmptyProject] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isFilter, setIsFilter] = useState(false);
  const [filterID, setFilterID] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [fristLoading, setFirstLoading] = useState(true);
  const [isDebouncing, setIsDebouncing] = useState(false);
  const [preId, setPreId] = useState();
  const [preEmail, setPreEmail] = useState();
  const [loading, setLoading] = useState(false);
  const optionCustomerPage = useRef(1);

  const loadProject = async () => {
    if (!userInfor.last_project_active) return;
    const res = await getProject(userInfor.last_project_active);
    dispatch(setProject(res));
  };
  const handleOpenEditProject = (item) => {
    setProjectEdit(item);
    setModalEdit(true);
  };
  const loadAllProject = async (page) => {
    let data = {
      params: {
        page: page,
      },
    };

    if (filterEmail !== "" && filterID === "") {
      data = {
        params: {
          email: filterEmail,
          page: page,
        },
      };
    } else if (filterID !== "" && filterEmail === "") {
      data = {
        params: {
          filter_project_id: filterID,
          page: page,
        },
      };
    } else
      if (filterID !== "" && filterEmail !== "") {
        data = {
          params: {
            email: filterEmail,
            filter_project_id: filterID,
            page: page,
          },
        };
      }

    const fetchData = async () => {
      return await getAllProject(data);
    };
    fetchData().then((response) => {
      const result = response.data;
      const data = result;
      setData([...data]);
      setItemPerPage(response.per_page);
      setCurrentPage(response.current_page);
      setTotalItems(response.total);
    });
  };

  // const {
  //   data,
  // } = useQuery(['loadProject', {countAddPj,pageNumber}], loadAllProject, { refetchOnWindowFocus: false });

  const clickToSwitchProject = async (projectId) => {
    const data = {
      last_project_active: projectId,
    };
    try {
      const res = await updateProfile(data);
      if (res.status) {
        userInfor.last_project_active = projectId;
        localStorage.setItem("userInfor", JSON.stringify(userInfor));
        const domain = getDomainForCookie();
        Cookies.set('userInfor', JSON.stringify(userInfor), { domain, expires: 365 })

        setModalSuccess(true);
        //window.location.reload()
      } else {
        toastBasic(
          "Đã có lỗi xảy ra khi thay đổi dự án. Vui lòng thử lại sau ít phút!!!"
        );
      }
      return;
    } catch (e) {
      toastBasic(
        "Đã có lỗi xảy ra khi thay đổi dự án. Vui lòng thử lại sau ít phút!!!"
      );
      return;
    }
  };

  useEffect(() => {
    if (userInfor.last_project_active === "") {
      setModalWarningEmptyProject(true);
    }
    loadAllProject(optionCustomerPage.current);
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      setFirstLoading(false);
      setLoading(false);
    }
    setLoading(false);
  }, [data])
  const paginate = (pageNumber) => loadAllProject(pageNumber);

  const ShowFilter = () => {
    const userInfor = localStorage.getItem("userInfor");

    return (
      <>
        {isFilter ? (
          <div className="filter-container">
            <div className={`${twStyle.filter_block} d-flex`}>
              {JSON.parse(userInfor)?.id === 19 ?
                <input type="text" className={`${twStyle.filter_content}`} placeholder="Lọc theo Email"
                  onChange={(e) => { setFilterEmail(e.target.value) }}
                />
                : (<></>)}
              <input type="text" className={`${twStyle.filter_content}`} placeholder="Lọc theo ID"
                onChange={(e) => { setFilterID(e.target.value) }}
              />
              <button className={`${twStyle.filter_btn}`}
                onClick={(e, userInfor) => handleFilter(e, userInfor)}
                disabled={isDebouncing}
              >Lọc
                {loading ? <Spinner size="sm" color="light" style={{ marginLeft: "8px" }} /> : <></>}
              </button>
            </div>
          </div>) :
          (
            <></>
          )}
      </>
    )
  }
  const handleFilter = (e) => {
    e.preventDefault();
    if (!isDebouncing) {
      if (preId !== filterID || preEmail !== filterEmail) {
        if (filterEmail !== "" || filterID !== "") {
          setLoading(true);
          setIsDebouncing(true);
          loadAllProject();
          setTimeout(() => {
            setIsDebouncing(false);
          }, 2000);
          setPreId(filterID);
          setPreEmail(filterEmail);
        }
      }
      setPreId(filterID);
      setPreEmail(filterEmail);
    }
  }
  return (
    <React.Fragment>
      <Head title="Project Card"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page> Projects</BlockTitle>
              <BlockDes className="text-soft">
                You have total {totalItems} projects
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""
                    }`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div
                  className="toggle-expand-content"
                  style={{ display: sm ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li>
                      <UncontrolledDropdown onClick={() => { setIsFilter(!isFilter) }}>
                        <DropdownToggle
                          tag="a"
                          className="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                        >
                          <Icon
                            name="filter-alt"
                            className="d-none d-sm-inline"
                          ></Icon>
                          <span>Lọc</span>
                          {isFilter ? (
                            <Icon name="chevron-down" className="dd-indc"></Icon>
                          ) :
                            (<Icon name="chevron-right" className="dd-indc"></Icon>)}
                        </DropdownToggle>
                        {/* <DropdownMenu>
                          <DropdownItem>Filtered By ProjectID</DropdownItem>
                          <DropdownItem>Filtered By Email</DropdownItem>
                        
                        </DropdownMenu> */}
                      </UncontrolledDropdown>
                    </li>
                    <li className="nk-block-tools-opt">
                      <Button
                        color="primary"
                        onClick={() => setModalForm(true)}
                      >
                        <Icon name="plus"></Icon>
                        <span>{t("project_add_project")}</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        {ShowFilter()}
        <Block>
          {data.length > 0 ? (<Row className="g-gs">
            {data?.length > 0 &&
              data.map((item, idx) => {
                var expireAt = "";
                expireAt =
                  item.type === "trial"
                    ? moment(item.created_at).add(
                      NUMBER_OF_TRIAL_DAY_PROJECT,
                      "days"
                    )
                    : item.expired_at;
                const now = moment();
                const end = moment(item?.expired_at * 1000);
                var days = Math.round(moment.duration(end.diff(now)).asDays());
                days = days > 0 ? days : 0;
                // var days = expireAt > moment() ? moment.utc(moment().diff(expireAt, 'days')).days() : 0;
                var owner = "";
                item.members.forEach((mem) => {
                  if (mem.role === "owner") {
                    owner = mem.name;
                  }
                });
                return (
                  <React.Fragment key={item._id}>
                    <Col sm="6" lg="4" xxl="3" key={item._id}>
                      <ProjectCard>
                        <div className="project-head">
                          <a
                            href="#title"
                            onClick={(ev) => {
                              ev.preventDefault();
                            }}
                            className="project-title"
                          >
                            {/* <UserAvatar className="sq" theme={item.avatarClass} text={findUpper(item.name)} /> */}
                            <Img
                              src={imagesPrice[item.type]}
                              className="mr-2"
                              style={{ height: "3rem" }}
                            />

                            <div className="project-info">
                              <h6 className="title">{item.name}</h6>
                              <span className="sub-text">
                                Owner: <b>{owner}</b>
                              </span>
                            </div>
                          </a>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag="a"
                              className="dropdown-toggle btn btn-sm btn-icon btn-trigger mt-n1 mr-n1"
                            >
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu right>
                              <ul className="link-list-opt no-bdr">
                                <li onClick={() => handleOpenEditProject(item)}>
                                  <DropdownItem
                                    tag="a"
                                    href="#edit"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                    }}
                                  >
                                    <Icon name="edit"></Icon>
                                    <span>{t("edit_project")}</span>
                                  </DropdownItem>

                                </li>
                                <li onClick={() => window.open(`${getDomainApp()}/admin/project`)}>
                                  <DropdownItem
                                    tag="a"
                                    href="#show"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                    }}
                                  >
                                    <Icon name="eye"></Icon>
                                    <span>{t("show_project")}</span>
                                  </DropdownItem>
                                </li>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                        <div className="project-details">
                          <p>
                            {t("project_subscription_name")}:{" "}
                            <b>{capitalize(item.type)}</b>
                          </p>
                          <p>
                            {t("project_upgrade_deadline")}:{" "}
                            <b>
                              {moment(item.expired_at * 1000).format(
                                "DD.MM.YYYY"
                              ) || ""}
                            </b>
                          </p>
                        </div>
                        {/* <div className="project-progress">
                            <div className="project-progress-details">
                              <div className="project-progress-percent">
                                {90}%
                              </div>
                            </div>
                            <Progress
                              className="progress-pill progress-md bg-light"
                              value={90}
                            ></Progress>
                          </div> */}
                        <div className="project-meta">
                          <p>
                            <span style={{ color: "#c9c4c4" }}>Nhân sự: </span>
                            <b>{item.members.length}</b>
                          </p>
                          <p style={{ color: "#c9c4c4" }}>Còn lại</p>
                        </div>
                        <div className="project-meta">
                          <ul className="project-users g-1">
                            {item.members.slice(0, 2).map((item, idx) => {
                              return (
                                <li key={idx}>
                                  <UserAvatar
                                    className="sm"
                                    text={findUpper(item.name)}
                                    theme={item?.name}
                                    image={item?.image}
                                  />
                                </li>
                              );
                            })}
                            {item.members.length > 2 && (
                              <li>
                                <UserAvatar
                                  theme="light"
                                  className="sm"
                                  text={`+${item.members.length - 2}`}
                                />
                              </li>
                            )}
                          </ul>
                          <span
                            className={`badge badge-dim badge-${days > 10
                              ? "light"
                              : days <= 10 && days >= 2
                                ? "info"
                                : days === 1
                                  ? "warning"
                                  : days <= 0 && "danger"
                              }`}
                          >
                            <Icon name="clock"></Icon>
                            <span>
                              {days <= 0
                                ? "Expired"
                                : days === 1
                                  ? "Due Tomorrow"
                                  : days + " Days Left"}
                            </span>
                          </span>
                        </div>
                      </ProjectCard>
                      <div
                        key={item._id}
                        style={{ background: "#FD1F6D" }}
                        className={`text-center ${twStyle.mt1} ${twStyle.py2} ${twStyle.textWhite} ${twStyle.textWhite} ${twStyle.fontSemiBold} ${twStyle.roundedLg} ${twStyle.cursorPointer}`}
                        onClick={() => clickToSwitchProject(item._id)}
                      >
                        {t("project_select")}
                      </div>
                    </Col>
                  </React.Fragment>
                );
              })}
          </Row>) : (
            <>
              {fristLoading ? (<></>) : (<h3 style={{ textAlign: "center", marginTop: "20px" }}>Không tìm được kết quả!</h3>)}
            </>)
          }
          <ToastContainer />
        </Block>
        <Suspense fallback={<div>Loading...</div>}>
          {/* modal create project*/}
          <CreateProject
            view={modalForm}
            setView={setModalForm}
            onLoadProject={loadAllProject}
          />
          {/* modal change project success*/}
          <ChangeSuccess view={modalSuccess} setView={setModalSuccess} />
          {/* modal update project*/}
          <UpdateProject
            view={modalEdit}
            setView={setModalEdit}
            onLoadProject={loadAllProject}
            projectEdit={projectEdit}
            setProjectEdit={setProjectEdit}
          />
          {/* modal for empty project */}
          <WarningEmptyProject
            modalWarningEmptyProject={modalWarningEmptyProject}
            setModalWarningEmptyProject={setModalWarningEmptyProject}
          />
        </Suspense>
      </Content>
      {data.length > 0 ?
        (<div className="card-inner bg-white" style={{ paddingLeft: "54px" }}>
          {data.length > 0 && (
            <PaginationComponent
              itemPerPage={itemPerPage}
              totalItems={totalItems}
              paginate={paginate}
              currentPage={currentPage}
            />
          )}
        </div>) : (<></>)}
    </React.Fragment>
  );
};
export default ProjectCardPage;
