import React from "react";
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import Ratio from "../../../../../images/dashboard/img-ratio.png";
import { Icon } from "../../../../../../components/Component";
import style from "../../../index.module.scss";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";

const YTTopPost = ({ data }) => {
  const dataSample = [
    {
      title:
        "#IQ  \ud83d\udca1\ud83d\udc10#messi  #lionelmessi  #football  #barca  #footballskills  #bongda  #foryou  #viral  #fyp",
      media: {
        _id: "64bba476da8253492402f582",
        type: 2,
        name: "https://so9vn.s3.ap-southeast-1.amazonaws.com/63256ee52fc4b337f308d725/XzJtHRLc1mvIBSNm6jfvsGFF1690018934691.mp4",
        project_id: "63256ee52fc4b337f308d725",
        source: 1,
        thumb:
          "https://so9vn.s3.ap-southeast-1.amazonaws.com/63256ee52fc4b337f308d725/pfQnfA03VxZmdPe9wi4QGUqAS1690018934761.jpeg",
        message: "",
        media_is_available: true,
        status_compress: 3,
        updated_at: "2023-07-22T10:20:05.644000Z",
        created_at: "2023-07-22T09:42:14.886000Z",
        url: "https://so9vn.s3.ap-southeast-1.amazonaws.com/63256ee52fc4b337f308d725/XzJtHRLc1mvIBSNm6jfvsGFF1690018934691.mp4",
      },
      number_interact: 15,
    },
    {
      title:
        "D\u00c0NH CHO NEWBIE L\u00c0M ADS - \u0110\u1eccC HI\u1ec2U #2 CH\u1ec8 S\u1ed0 QUAN TR\u1eccNG B\u1eacC NH\u1ea4T TRONG FB ADS \r\n\r\nChi ti\u1ebft \u1edf t\u1eebng \u1ea3nh (Nh\u1edb save l\u1ea1i \u0111\u1ec3 \u0111\u1ecdc & \u00e1p d\u1ee5ng d\u1ea7n nh\u00e9 - v\u00ec n\u00f3 quan tr\u1ecdng cho ng\u01b0\u1eddi m\u1edbi)",
      media: {
        _id: "64b8f9a25998b4ac380e1133",
        type: 1,
        name: "https://so9vn.s3.ap-southeast-1.amazonaws.com/63256ee52fc4b337f308d725/YQ9HLeQKtFJm4DoSw1pWQyUjL1689844128437.jpeg",
        project_id: "63256ee52fc4b337f308d725",
        source: 1,
        alt: "",
        message: "",
        media_is_available: true,
        status_compress: 3,
        updated_at: "2023-07-20T09:08:50.619000Z",
        created_at: "2023-07-20T09:08:50.619000Z",
        url: "https://so9vn.s3.ap-southeast-1.amazonaws.com/63256ee52fc4b337f308d725/YQ9HLeQKtFJm4DoSw1pWQyUjL1689844128437.jpeg",
      },
      number_interact: 4,
    },
    {
      title:
        "M\u1ed9t s\u1ed1 tips c\u00f3 th\u1ec3 \u00e1p d\u1ee5ng \u0111\u1ec3 t\u0103ng l\u01b0\u1ee3ng reach.1 - Ch\u1ecdn khung gi\u1edd \u0111\u0103ng  *  Test th\u1eed khung gi\u1edd m\u00e0 kh\u00e1ch h\u00e0ng m\u1ee5c ti\u00eau hay t\u01b0\u01a1ng t\u00e1c. (V\u00ed d\u1ee5 t\u1ec7p M\u1eb9 b\u1ec9m s\u1eefa c\u00f3 th\u1ec3 test khung 1-3h s\u00e1ng s\u1ebd c\u00f3 nhi\u1ec1u b\u1ea5t ng\u1edd )\r\n\r\n  *  N\u1ebfu kh\u00f4ng \u0111\u01b0\u1ee3c tham kh\u1ea3o c\u00e1c khung gi\u1edd sau: 9h - 10h ; 11h30 - 13h10 ; 16h30 - 17h30 ; 19h30 -21h\r\n\r\n\r\n2 -  t\u01b0\u01a1ng t\u00e1c M\u1ed2I",
      media: {
        _id: "64b3b948ef149b1053045a22",
        type: 1,
        name: "https://so9vn.s3.ap-southeast-1.amazonaws.com/63256ee52fc4b337f308d725/BPHKa6UgEUPBt1Y7G6RRbzvns1689499975484.jpeg",
        project_id: "63256ee52fc4b337f308d725",
        source: 1,
        alt: "",
        message: "",
        media_is_available: true,
        status_compress: 3,
        updated_at: "2023-07-16T09:32:56.183000Z",
        created_at: "2023-07-16T09:32:56.183000Z",
        url: "https://so9vn.s3.ap-southeast-1.amazonaws.com/63256ee52fc4b337f308d725/BPHKa6UgEUPBt1Y7G6RRbzvns1689499975484.jpeg",
      },
      number_interact: 3,
    },
    {
      title:
        "Nh\u1eefng b\u00ed m\u1eadt v\u1ec1 YouTube tag b\u1ea1n \u0111\u00e3 bi\u1ebft hay ch\u01b0a ?\r\nYouTube tag l\u00e0 m\u1ed9t ph\u1ea7n quan tr\u1ecdng c\u1ee7a SEO YouTube. Ch\u00fang gi\u00fap YouTube hi\u1ec3u \u0111\u01b0\u1ee3c n\u1ed9i dung video c\u1ee7a b\u1ea1n v\u00e0 ph\u00e2n ph\u1ed1i n\u00f3 t\u1edbi nh\u1eefng ng\u01b0\u1eddi \u0111ang t\u00ecm ki\u1ebfm n\u1ed9i dung t\u01b0\u01a1ng t\u1ef1. S\u1eed d\u1ee5ng Youtube tab m\u1ed9t c\u00e1ch hi\u1ec7u qu\u1ea3 s\u1ebd gi\u00fap b\u1ea1n t\u0103ng hi\u1ec3n th\u1ecb v\u00e0 ti\u1ebfp c\u1eadn \u0111\u01b0\u1ee3c nhi\u1ec1u ng\u01b0\u1eddi h\u01a1n m\u00e0 kh\u00f4ng c\u1ea7n ph\u1ea3i \u0111\u1ea7u t\u01b0 qu\u1ea3ng c\u00e1o. Trong b\u00e0i vi\u1ebft n\u00e0y, ch\u00fang ta s\u1ebd c\u00f9ng t\u00ecm hi\u1ec3u Youtube tag l\u00e0 g\u00ec, c\u00e1ch th\u00eam Youtube tag, c\u00e1c ki\u1ec3m tra hi\u1ec7u qu\u1ea3 c\u1ee7a th\u1ebb v\u00e0 nh\u1eefng b\u00ed quy\u1ebft s\u1eed d\u1ee5ng Youtube tag 2022 nh\u00e9!",
      media: {
        _id: "64b3b949daf0357b340c4232",
        type: 1,
        name: "https://so9vn.s3.ap-southeast-1.amazonaws.com/63256ee52fc4b337f308d725/1yDNBNBmqhNXFniZxDQmbHMBT1689499975498.jpeg",
        project_id: "63256ee52fc4b337f308d725",
        source: 1,
        alt: "",
        message: "",
        media_is_available: true,
        status_compress: 3,
        updated_at: "2023-07-16T09:32:57.330000Z",
        created_at: "2023-07-16T09:32:57.330000Z",
        url: "https://so9vn.s3.ap-southeast-1.amazonaws.com/63256ee52fc4b337f308d725/1yDNBNBmqhNXFniZxDQmbHMBT1689499975498.jpeg",
      },
      number_interact: 3,
    },
    {
      title:
        "Nh\u1eefng b\u00ed m\u1eadt v\u1ec1 YouTube tag b\u1ea1n \u0111\u00e3 bi\u1ebft hay ch\u01b0a ?\r\nYouTube tag l\u00e0 m\u1ed9t ph\u1ea7n quan tr\u1ecdng c\u1ee7a SEO YouTube. Ch\u00fang gi\u00fap YouTube hi\u1ec3u \u0111\u01b0\u1ee3c n\u1ed9i dung video c\u1ee7a b\u1ea1n v\u00e0 ph\u00e2n ph\u1ed1i n\u00f3 t\u1edbi nh\u1eefng ng\u01b0\u1eddi \u0111ang t\u00ecm ki\u1ebfm n\u1ed9i dung t\u01b0\u01a1ng t\u1ef1. S\u1eed d\u1ee5ng Youtube tab m\u1ed9t c\u00e1ch hi\u1ec7u qu\u1ea3 s\u1ebd gi\u00fap b\u1ea1n t\u0103ng hi\u1ec3n th\u1ecb v\u00e0 ti\u1ebfp c\u1eadn \u0111\u01b0\u1ee3c nhi\u1ec1u ng\u01b0\u1eddi h\u01a1n m\u00e0 kh\u00f4ng c\u1ea7n ph\u1ea3i \u0111\u1ea7u t\u01b0 qu\u1ea3ng c\u00e1o. Trong b\u00e0i vi\u1ebft n\u00e0y, ch\u00fang ta s\u1ebd c\u00f9ng t\u00ecm hi\u1ec3u Youtube tag l\u00e0 g\u00ec, c\u00e1ch th\u00eam Youtube tag, c\u00e1c ki\u1ec3m tra hi\u1ec7u qu\u1ea3 c\u1ee7a th\u1ebb v\u00e0 nh\u1eefng b\u00ed quy\u1ebft s\u1eed d\u1ee5ng Youtube tag 2022 nh\u00e9!",
      media: {
        _id: "64b3b949daf0357b340c4235",
        type: 1,
        name: "https://so9vn.s3.ap-southeast-1.amazonaws.com/63256ee52fc4b337f308d725/1yDNBNBmqhNXFniZxDQmbHMBT1689499975498.jpeg",
        project_id: "63256ee52fc4b337f308d725",
        source: 1,
        alt: "",
        message: "",
        media_is_available: true,
        status_compress: 3,
        updated_at: "2023-07-16T09:32:57.330000Z",
        created_at: "2023-07-16T09:32:57.330000Z",
        url: "https://so9vn.s3.ap-southeast-1.amazonaws.com/63256ee52fc4b337f308d725/1yDNBNBmqhNXFniZxDQmbHMBT1689499975498.jpeg",
      },
      number_interact: 3,
    },
  ];
  return (
    <>
      <div className="w-100">
        <Row className="mb-4 ml-0 w-100">
          {data
            ? data?.map((item, index) => (
                <Col key={index} sm={12} md={3} className="p-0">
                  <div className={`${style.borderCard}`}>
                    <Card className="h-100">
                      <CardBody className="">
                        <CardTitle tag="h5" className={`${style.headCard}`}>
                          Đăng qua SO9
                        </CardTitle>
                        <CardText
                          className={`${style.detailCard} mt-2 d-flex flex-row align-items-center`}
                          style={{ margin: "0px" }}
                        >
                          <Icon name={"clock"} className="mr-1"></Icon>{" "}
                          {data
                            ? moment(item?.publishedAt).format(
                                "DD MMM YYYY hh:mm A"
                              )
                            : ""}
                        </CardText>
                        <div>
                          <CardImg
                            src={
                              // Check if media field is empty or has a valid URL
                              item?.thumbnails
                                ? item?.thumbnails?.high?.url // Check if URL is for a video
                                : Ratio // Default image if media field is empty
                            }
                            alt="img-ratio"
                            height={200}
                            className="mt-1"
                            style={{
                              margin: "0px",
                              width: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div>
                          <CardText
                            className={`${style.contentCard} mt-1 d-flex flex-row align-items-center pb-2`}
                            style={{
                              borderBottom: "1px solid #D1D5DB",
                            }}
                          >
                            <span className={`${style.truncatedText}`}>
                              {item ? item?.title : ""}
                            </span>
                          </CardText>
                        </div>
                        <div
                          className="d-flex flex-column mt-2"
                          style={{ gap: "8px" }}
                        >
                          <div
                            className={`${style.contentCard} d-flex flex-row align-items-center justify-content-between`}
                          >
                            <div>Tổng lượt xem</div>
                            <div style={{ fontWeight: "600" }}>
                              {item?.viewCount}
                            </div>
                          </div>
                          <div
                            className={`${style.contentCard} d-flex flex-row align-items-center justify-content-between`}
                          >
                            <div>Tổng lượt thích</div>
                            <div style={{ fontWeight: "600" }}>
                              {item?.likeCount}
                            </div>
                          </div>
                          <div
                            className={`${style.contentCard} d-flex flex-row align-items-center justify-content-between`}
                          >
                            <div>Tổng lượt comment</div>
                            <div style={{ fontWeight: "600" }}>
                              {item?.commentCount}
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              ))
            : dataSample.map((item, index) => (
                <Col key={index} sm={12} md={3} className="p-0">
                  <div className={`${style.borderCard}`}>
                    <Card className="h-100">
                      <CardBody className="">
                        <CardTitle tag="h5" className={`${style.headCard}`}>
                          Đăng qua SO9
                        </CardTitle>
                        <CardText
                          className={`${style.detailCard} mt-2 d-flex flex-row align-items-center`}
                          style={{ margin: "0px" }}
                        >
                          <Icon name={"clock"} className="mr-1"></Icon>{" "}
                          {dataSample
                            ? moment(item?.media?.updated_at).format(
                                "DD MMM YYYY hh:mm A"
                              )
                            : ""}
                        </CardText>
                        <div>
                          <CardImg
                            src={
                              // Check if media field is empty or has a valid URL
                              item?.media?.url
                                ? item?.media?.url?.endsWith(".mp4") // Check if URL is for a video
                                  ? item?.media?.thumb // Use thumb for video
                                  : item?.media?.url // Use URL for image
                                : Ratio // Default image if media field is empty
                            }
                            alt="img-ratio"
                            height={200}
                            className="mt-1"
                            style={{
                              margin: "0px",
                              width: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div>
                          <CardText
                            className={`${style.contentCard} mt-1 d-flex flex-row align-items-center pb-2`}
                            style={{
                              borderBottom: "1px solid #D1D5DB",
                            }}
                          >
                            <span className={`${style.truncatedText}`}>
                              {data ? item?.title : ""}
                            </span>
                          </CardText>
                        </div>
                        <div
                          className="d-flex flex-column mt-2"
                          style={{ gap: "8px" }}
                        >
                          <div
                            className={`${style.contentCard} d-flex flex-row align-items-center justify-content-between`}
                          >
                            <div>Reactions</div>
                            <div style={{ fontWeight: "600" }}>
                              {item?.number_interact}
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              ))}
        </Row>
      </div>
    </>
  );
};

export default YTTopPost;
