import React, { useState } from "react";
import { Icon, Img } from "../../../components/Component";
import IconComment from "../../images/icons/preview-instagram-comment.svg";
import IconLike from "../../images/icons/preview-instagram-like.svg";
import IconSave from "../../images/icons/preview-instagram-save.svg";
import IconShare from "../../images/icons/preview-instagram-share.svg";
import { CONTENT_LIMIT_SEE_MORE, MEDIA_TYPE_IMAGE, MEDIA_TYPE_VIDEO } from "../../utils/Utils";
import Slider from "react-slick";
import "./_preview-instagram.scss";
import style from "./index.module.scss";
import {
  getPlatformIconFromLabel
} from "../../utils/Utils";

const PreviewInstagram = ({ ...props }) => {
  const [openPreviewInsta, setOpenPreviewInsta] = useState(true)
  const handleOpenPreviewInsta = () => {
    setOpenPreviewInsta(!openPreviewInsta)
    const Elmchevron = document.getElementById(`chevron_previewInsta`);
    const Elmcontent = document.getElementById("content_previewInsta");
    if (!openPreviewInsta) {
      Elmchevron.classList.add(`${style.openChevronDown}`)
      Elmchevron.classList.remove(`${style.openChevronUp}`)
      Elmcontent.classList.add(`${style.openContentDown}`)
      Elmcontent.classList.remove(`${style.openContentUp}`)
    }
    else {
      Elmchevron.classList.add(`${style.openChevronUp}`)
      Elmchevron.classList.remove(`${style.openChevronDown}`)
      Elmcontent.classList.add(`${style.openContentUp}`)
      Elmcontent.classList.remove(`${style.openContentDown}`)
    }
  }
  const renderLayoutMedia = () => {
    const itemImage = mediaItems.find((c) => (c.type === MEDIA_TYPE_IMAGE))

    if (itemImage) {
      <>
        <Slider {...settings} className="mb-3">
          {
            mediaItems.map(e => (
              <div>
                <Img src={e.url} alt="" className="image" style={{ width: "100%", height: "250px" }}></Img>
              </div>
            ))
          }
        </Slider>
        <div className="action">
          <button className="button">
            <Img src={IconLike} alt="like" />
          </button>
          <button className="button">
            <Img src={IconComment} alt="comment" />
          </button>
          <button className="button">
            <Img src={IconShare} alt="share" />
          </button>
          <button className="button">
            <Img src={IconSave} alt="save" />
          </button>
        </div>
      </>
    }
    else {
      const item = mediaItems.find((c) => (c.type === MEDIA_TYPE_VIDEO))
      return (
        <>
          <div className="imageWrapper">
            <Img src={item?.previewImages?.url} alt="something" className="image h-full w-full" />
            <div style={{ left: "50%", top: "40%", position: "absolute" }}>
              <svg style={{ color: "#fff" }} width="64" height="64" fill="#fff" class="bi bi-play" viewBox="0 0 16 16">
                <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z" />
              </svg>
            </div>
          </div>
          <div className="action">
            <button className="button">
              <Img src={IconLike} alt="like" />
            </button>
            <button className="button">
              <Img src={IconComment} alt="comment" />
            </button>
          </div>
        </>
      );
    }
  }
  const { channel, content, mediaItems, className } = props;
  const displayContent =
    content?.length <= CONTENT_LIMIT_SEE_MORE ? (
      content
    ) : (
      <>
        {content?.slice(0, CONTENT_LIMIT_SEE_MORE)}...
        <span className="more">more</span>
      </>
    );
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div className={`previewInstagram ${className || ""}`}>
      <div className="d-flex align-items-stretch border-bottom">
        <div
          className="flex-fill p-2 fw-medium"
          style={{ color: "#333333", fontFamily: "Roboto" }}
        >
          <Img
            src={getPlatformIconFromLabel("instagram")}
            width= "16px"
            size="circle"
            style={{marginRight: "4px" }}
          />
          Instagram
        </div>
        <div id="chevron_previewInsta" className={`card-tools p-2 ${style.openMedia}`} onClick={handleOpenPreviewInsta}>
          <svg width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" /> </svg>
        </div>
      </div>
      <div id="content_previewInsta" className={`previewFacebook`} style={{
        transition: "max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s",
        willChange: "max-height",
        position: "relative",
        overflow: "hidden"
      }}>
        <div className="header" >
          <Img className="avatar" src={channel.picture} alt="avatar" />
          <div className="info">
            <div className="name">{channel.name}</div>
          </div>
          <div className="moreAction">
            <Icon name="more-h"></Icon>
          </div>
        </div>
        <div className="body">
          {mediaItems?.length > 0 &&
            renderLayoutMedia()}
          <div className="header px-2">
            <div className="content pt-2 pb-1">
              <span className="authorName font-bold">{channel.name}</span>
              &nbsp;{content && displayContent}
            </div>
            <div className="time pb-2" style={{ fontSize: "10px" }}>Jul 28</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewInstagram;
