import React, { useEffect, useState, useRef, forwardRef, Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  getPlatformIcon
} from "../../utils/Utils";
import moment from 'moment';
import { toastError,toastSuccess } from "../../../libs/toasts";
import { reupPost } from "../../../api/createPost";
import { Modal, ModalBody, Button, Spinner } from "reactstrap";
import style from "./index.module.scss";
import BugPost from "../../images/icons/BugPost.png";
import BrainBulb from "../../images/icons/brainBulb.png";
import DefaultImage from "../../images/avatar/no-image.png";
import { validPostTitle } from "../../utils/Utils";
export default function ModalViewStatuspost({ openModal, setOpenModal, post, items, setItems}) {
  const { i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!post) return;
    // console.log(post);
  }, [post]);

  const toggleModal = () => {
    setOpenModal(false)
  };

  const handleReupPost = async () => {
    setIsLoading(true);
    if (window.confirm("Bạn có chắc chắn muốn đăng lại bài này không?")) {
        const data = {
            socialPostId: post?.attachment_post?.id,
            channelType: post?.channel?.platform
        }
        try {
            const res = await reupPost(data)
            if (res.status) {
                toastSuccess("Đăng lại bài thành công! Vui lòng tải lại trang để kiểm tra bài đăng!")
                if (items=== undefined) {
                  setIsLoading(false);
                  setOpenModal(false);
                  return;
                }
                const newItems =  items.filter(item=> item.id !== post?.attachment_post?.id);
                setItems(newItems);
                setIsLoading(false);
                setOpenModal(false);
            }
        } catch (e) {
            toastError("Đã xảy ra lỗi khi đăng lại bài. Vui lòng thử lại sau ít phút")
            setIsLoading(false);
        }
    }
}

  return (
    <Fragment>
      {
        post &&
        <Modal size="sm" isOpen={openModal} toggle={toggleModal} style={{ borderRadius: "2px" }}>
          <ModalBody className="p-0">
            <div className={`${style.titleBugPost}`}>
              <img src={BugPost} alt="BugPost" />
              <span>{validPostTitle(post.title)}</span>
            </div>
            <div style={{ padding: ".5rem" }}>
              {/* Info channel */}
              {
                post?.channel && post?.channel?.name  &&
                <div className={`${style.bodyBugPost}`}>
                  <div className={`${style.postHeader}`}>
                    <img src={post?.channel?.picture || DefaultImage} />
                    <div>
                      <p className="font-weight-bold mb-0">{post.channel.name}</p>
                      <p> {moment(post.updated_at).format("DD/MM/YYYY ")} {moment(post.updated_at).format("HH:mm")}</p>
                    </div>
                  </div>
                  <img src={getPlatformIcon(post.channel.platform)} className={`${style.channelAvatar}`} />
                </div>
              }
              {/* Title */}
              {
                post?.description ?
                  <div className="mt-2">
                    <p>{post?.description}</p>
                  </div> :
                  <div className="mt-2">
                    <a href="https://www.facebook.com/messages/t/103638805810736" target="_blank">Liên hệ với đội ngũ hỗ trợ để được kiểm tra cụ thể lỗi!</a>
                  </div>
              }

              {/* Attachment post */}
              {
                post?.attachment_post && (post?.attachment_post?.media || post?.attachment_post?.content)  && (post?.attachment_post.length > 0 || Object.keys(post?.attachment_post).length > 0) &&
                <div className={`${style.attachmentPost} mt-2`}>
                  <img src={post?.attachment_post?.media || DefaultImage} />            
                  <p className="mb-0">{post?.attachment_post?.content}</p>
                </div>
              }
              {/* Trick */}
              {
                post?.attachment_link && (post?.attachment_link.length > 0 || Object.keys(post?.attachment_link).length > 0) &&
                <div className={`${style.trickPost} mt-2`}>
                  <img src={BrainBulb} alt="Trick" />
                  <a href={post?.attachment_link?.url || "#"} target="_blank" >{post?.attachment_link?.title || ""}</a>
                </div>
              }
              {/* Button  */}
              {
                post?.attachment_button && (post?.attachment_button.length > 0 || Object.keys(post?.attachment_button).length > 0) &&
                <Button className={`${style.btnUtilsPost} mt-2`}>{post?.attachment_button?.title || ""}</Button>
              }
                <Button disabled={isLoading} style={{gap:"4px"}} onClick={handleReupPost} className={`${style.btnUtilsPost} d-flex justify-content-center mt-2`}> 
                  Đăng lại  {isLoading && <Spinner size="sm"></Spinner>}
                </Button>
            </div>
          </ModalBody>
        </Modal>
      }
    </Fragment>

  )
}
const ReplyCreator = React.lazy(() => import("../../pages/Channel/ReplyCreator"));