import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { Icon, RSelect, Img } from "../../../components/Component";
import style from "./index.module.scss";
import { Controller, useForm } from "react-hook-form";
import {
  MEDIA_TYPE_IMAGE,
  getPlatformIcon,
} from "../../utils/Utils";
import ModalPost from "./ModalPost";
import ModalChannel from "./ModalChannel";
import { createCampaign } from "../../../api/campaign";

const initialDataForm = {
  name: "",
  type: "photo",
  status: 1,
  post_info: {
    type: "all_post",
    ids: [],
  },
  channel_info: {
    type: "channel",
    ids: [],
  },
};
const ModalAdd = ({ modal, toggle, refetch, toggleSuccess, setMessage }) => {
  const [formData, setFormData] = useState(initialDataForm);
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const [loading, setLoading] = useState(false);
  const [listPost, setListPost] = useState({});
  const [listChannel, setListChannel] = useState({});
  const [modalSub, setModalSub] = useState({
    post: false,
    channel: false,
  });
  const toggleModalSub = (type) => {
    setModalSub((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };
  const optionMediaType = [
    {
      label: "Ảnh",
      value: "photo",
    },
    {
      label: "Video Thường",
      value: "video",
    },
    {
      label: "Video Ngắn",
      value: "reel",
    },
  ];
  const optionStatus = [
    {
      label: "Hoạt động",
      value: 1,
    },
    {
      label: "Không hoạt động",
      value: 0,
    },
  ];
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  useEffect(() => {
    if (modal) {
      setListPost({});
      setListChannel([]);
      setFormData(initialDataForm);
    }
  }, [modal]);

  const onEditSubmit = async (data) => {
    if (!data.name || !data.post_info || !data.channel_info) {
      return;
    }

    setFormData((prevFormData) => {
      // Copy formData để tránh thay đổi trực tiếp vào state
      const updatedFormData = { ...prevFormData };

      // Đặt post_info.type bằng listPost.type
      updatedFormData.post_info.type = listPost.type;
      // Lấy mảng các _id từ listPost.dataPost
      const idsArrayPost = listPost.dataPost.map((item) => item?.value);

      // Đặt post_info.ids bằng mảng các _id
      updatedFormData.post_info.ids = idsArrayPost;

      const idsArrayChannel = listChannel?.dataChannel?.map((item) => item._id);

      updatedFormData.channel_info.ids = idsArrayChannel;
      updatedFormData.channel_info.type = listChannel.type;
      // Cập nhật formData với giá trị mới
      setFormData(updatedFormData);
    });
    setLoading(true);
    try {
      const res = await createCampaign(userInfo.last_project_active, formData);
      if (res?.status) {
        setMessage("Bạn đã tạo chiến dịch thành công");
        refetch();

        toggle();
        toggleSuccess();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalHeader
        toggle={toggle}
        close={
          <button className="close" onClick={toggle}>
            <Icon name="cross" />
          </button>
        }
      >
        Tạo chiến dịch
      </ModalHeader>
      <ModalBody>
        <Form className="gy-2" onSubmit={handleSubmit(onEditSubmit)}>
          <Row className="d-flex align-items-center">
            <Col md="3">Tên chiến dịch</Col>
            <Col md="9">
              <FormGroup>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: "Bạn phải nhập tên chiến dịch",
                  }}
                  render={({ field: { onBlur, onChange } }) => (
                    <input
                      className="form-control px-[8px]"
                      type="text"
                      name="name"
                      placeholder="Tên chiến dịch"
                      onChange={(e) => {
                        setValue("name", e.target.value);
                        setFormData({
                          ...formData,
                          name: e.target.value,
                        });
                      }}
                      // {...register("name", { required: "This field is required" })}
                    />
                  )}
                />
                {errors.name && (
                  <FormText color="danger"> {errors[`name`]?.message}</FormText>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md="3">Loại chiến dịch</Col>
            <Col md="9">
              <FormGroup style={{ width: "40%" }}>
                <Controller
                  name="type"
                  control={control}
                  render={({ field: { onBlur, onChange } }) => (
                    <RSelect
                      style={{ with: "50%" }}
                      options={optionMediaType}
                      defaultValue={optionMediaType[0]}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          type: e.value,
                        })
                      }
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="d-flex align-items-center ">
            <Col md="3">Trạng thái</Col>
            <Col md="9">
              <FormGroup style={{ width: "40%" }}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { onBlur, onChange } }) => (
                    <RSelect
                      style={{ with: "50%" }}
                      options={optionStatus}
                      defaultValue={optionStatus[0]}
                      placeholder={"Chọn trạng thái"}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          status: e.value,
                        })
                      }
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
          <div
            style={{
              width: "100%",
              borderBottom: "2px solid #E5E7EB",
              padding: "0px !important",
            }}
          ></div>
          <Row>
            <Col xs="12" md="6">
              <p>Danh sách bài đăng</p>
              {listPost?.dataPost?.length > 0 ||
              listPost?.type === "all_post" ? (
                <div style={{ maxHeight: "250px", overflow: "auto" }}>
                  <table className={`table table-reponsive postFastTable `}>
                    <thead style={{ background: "#F1F5FF" }}>
                      <tr>
                        <th
                          style={{ width: "90%" }}
                          className={`${style.headPost} px-2`}
                        >
                          Bài đăng chọn (
                          {listPost?.type === "all_post"
                            ? listPost?.total
                            : listPost?.dataPost?.length}
                          )
                        </th>
                        <th
                          style={{ width: "10%" }}
                          className={`${style.headPost} text-center`}
                        >
                          <Icon
                            style={{ color: "#0EA5E9", cursor: "pointer" }}
                            name="edit"
                            onClick={() => toggleModalSub("post")}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {listPost?.type !== "all_post" &&
                        listPost?.dataPost?.map((item, index) => (
                          <tr
                            key={index}
                            style={{ fontSize: "12px", cursor: "pointer" }}
                            // onClick={() => setDetailPost(post)}
                          >
                            <td
                              className={`${style.truncateCell} ${style.Td}`}
                              style={{
                                fontWeight: "bold",
                              }}
                              // onClick={() => handleViewDetailPost({ item: post })}
                            >
                              <div className="d-flex">
                                {listPost?.type === "special_post" && (
                                  <div
                                    style={{ position: "relative" }}
                                    className="mr-2"
                                  >
                                    <Img
                                      src={
                                        item?.data?.media[0]?.type ===
                                        MEDIA_TYPE_IMAGE
                                          ? item?.data?.media[0]?.name
                                          : item?.data?.media[0]?.thumb
                                      }
                                      alt="img"
                                      width="2rem"
                                      size="square"
                                      style={{
                                        background: "#D9D9D9",
                                      }}
                                    />
                                  </div>
                                )}
                                <div className="d-flex flex-column justify-content-center ">
                                  <span
                                    className={`${style.truncateCell}`}
                                    style={{ maxWidth: "12rem" }}
                                  >
                                    {item?.label}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                              }}
                              // onClick={() => handleViewDetailPost({ item: post })}
                            >
                              <Icon
                                name="trash"
                                onClick={() =>
                                  setListPost((prev) => {
                                    const updatedDataPost =
                                      prev?.dataPost.filter(
                                        (v) => v.value !== item.value
                                      );
                                    return {
                                      ...prev,
                                      dataPost: updatedDataPost,
                                    };
                                  })
                                }
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {listPost?.type === "all_post" && (
                    <div className="pl-2">
                      Đã chọn tất cả bài trong Kho nội dung (
                      {listPost?.type === "all_post" && listPost?.total})
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <Controller
                    name="post_info"
                    control={control}
                    rules={{
                      required: "Bạn phải chọn bài đăng",
                    }}
                    render={({ field: { onBlur, onChange } }) => (
                      <Button
                        color="info"
                        style={{ background: "#45BEF4" }}
                        onClick={() => toggleModalSub("post")}
                      >
                        Chọn bài đăng
                      </Button>
                    )}
                  />
                  {errors.post_info && (
                    <FormText color="danger">
                      {" "}
                      {errors[`post_info`]?.message}
                    </FormText>
                  )}
                </>
              )}
            </Col>
            <Col xs="12" md="6">
              <p>Kênh phân phối</p>
              {listChannel?.dataChannel?.length > 0 ? (
                <div style={{ maxHeight: "250px", overflow: "auto" }}>
                  <table className={`table table-reponsive postFastTable `}>
                    <thead style={{ background: "#F1F5FF" }}>
                      <tr>
                        <th
                          style={{ width: "90%" }}
                          className={`${style.headPost} px-2`}
                        >
                          {listChannel?.type === "channel"
                            ? `Kênh đang chọn (
                          ${listChannel?.dataChannel?.length})`
                            : `Nhóm Kênh đang chọn (
                          ${listChannel?.dataChannel?.length})`}
                        </th>
                        <th
                          style={{ width: "10%" }}
                          className={`${style.headPost} text-center`}
                        >
                          <Icon
                            style={{ color: "#0EA5E9", cursor: "pointer" }}
                            name="edit"
                            onClick={() => toggleModalSub("channel")}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {listChannel?.dataChannel?.map((item, index) => (
                        <tr
                          key={index}
                          style={{ fontSize: "12px", cursor: "pointer" }}
                          // onClick={() => setDetailPost(post)}
                        >
                          <td
                            className={`${style.truncateCell} ${style.Td}`}
                            style={{
                              fontWeight: "bold",
                            }}
                            // onClick={() => handleViewDetailPost({ item: post })}
                          >
                            {listChannel?.type === "channel" ? (
                              <div className="d-flex align-items-center">
                                <div style={{ position: "relative" }}>
                                  <Img
                                    src={item?.picture}
                                    alt="img"
                                    width="2rem"
                                    size="square"
                                    style={{
                                      background: "#D9D9D9",
                                    }}
                                  />
                                  <Img
                                    src={getPlatformIcon(item.platform)}
                                    alt="icon_platform"
                                    className="rounded-circle"
                                    style={{
                                      position: "absolute",
                                      bottom: 0,
                                      right: 0,
                                      height: "0.8rem",
                                      width: "0.8rem",
                                      objectFit: "cover",
                                    }}
                                  />
                                </div>
                                <div className="d-flex flex-column justify-content-center ml-2">
                                  <span>{item?.name}</span>
                                </div>
                              </div>
                            ) : (
                              <span>{item?.name}</span>
                            )}
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                            }}
                            // onClick={() => handleViewDetailPost({ item: post })}
                          >
                            <Icon
                              name="trash"
                              onClick={() =>
                                setListChannel((prev) => {
                                  const updatedDataChannel =
                                    prev.dataChannel.filter(
                                      (v) => v._id !== item._id
                                    );

                                  return {
                                    ...prev,
                                    dataChannel: updatedDataChannel,
                                  };
                                })
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <>
                  <Controller
                    name="channel_info"
                    control={control}
                    rules={{
                      required: "Bạn phải chọn kênh đăng",
                    }}
                    render={({ field: { onBlur, onChange } }) => (
                      <Button
                        color="info"
                        style={{ background: "#45BEF4" }}
                        onClick={() => toggleModalSub("channel")}
                      >
                        Chọn kênh đăng
                      </Button>
                    )}
                  />
                  {errors.channel_info && (
                    <FormText color="danger">
                      {" "}
                      {errors[`channel_info`]?.message}
                    </FormText>
                  )}
                </>
              )}
            </Col>
          </Row>
          <div className={style.btnCreatePost}>
            <FormGroup className="d-flex justify-content-end">
              <div>
                <Button
                  color="light"
                  className="mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    toggle();
                  }}
                  //   disabled={isUploading}
                >
                  <span style={{ textTransform: "uppercase" }}>Huỷ</span>
                </Button>
                <Button
                  type="submit"
                  style={{ background: "#2C4B94" }}
                  disabled={loading}
                >
                  <span style={{ textTransform: "uppercase", color: "#fff" }}>
                    {loading && <Spinner size="sm" />}Lưu lại
                  </span>
                </Button>
              </div>
            </FormGroup>
          </div>
        </Form>
      </ModalBody>
      <ModalPost
        modal={modalSub.post}
        toggle={() => toggleModalSub("post")}
        setValuePost={setValue}
        setListPost={setListPost}
      />
      <ModalChannel
        modal={modalSub.channel}
        toggle={() => toggleModalSub("channel")}
        setValueChannel={setValue}
        setListChannel={setListChannel}
      />
    </Modal>
  );
};

export default ModalAdd;
