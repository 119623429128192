import { API } from "../libs/client";

export const getIrisOverViewDb = async () => {
    const uri = "/affiliates/dashboard/overview";
    const res = await API.get(uri);
    return res.data;
  };

  export const getOverViewByDate = async ({fromTime,toTime}) => {
   const uri = `/affiliates/dashboard/overview?fromTime=${fromTime}&toTime=${toTime}`;
      const res = await API.get(uri);
      return res.data;
  };
export const getIrisDbDaily = async ({page,fromTime,toTime}) => {
  let uri = ``;
  if (fromTime && toTime ) {
    uri = `/affiliates/dashboard/daily?page=${page}&fromTime=${fromTime}&toTime=${toTime}`;
  }else uri = `/affiliates/dashboard/daily?page=${page}`;
    const res = await API.get(uri);
    return res.data;
  };

export const postIrisRegister = async (params) => {
    const uri = "affiliates/register";
    const res = await API.post(uri,params);
    return res.data;
  };


  export const getListBank = async () => {
    const uri = "bank";
    const res = await API.get(uri);
    return res.data;
  };

  export const updateIrisUser = async (params) => {
    const uri = "affiliates/update-info";
    const res = await API.put(uri,params);
    return res.data;
  };

  export const getDetailByDate = async ({page,fromDate,toDate,type}) => {
    const uri = `affiliates/dashboard/detail?page=${page}&fromDate=${fromDate}&toDate=${toDate}&type=${type}`;
    const res = await API.get(uri);
    return res.data;
  };
  export const updateAffClick = async (params) => {
    const uri = "affiliates/click";
    const res = await API.put(uri,params);
    return res.data;
  };
  export const getIrisUser = async () => {
    const uri = "affiliates/info";
    const res = await API.get(uri);
    return res.data;
  };
  export const getPaymentHistory = async (page) => {
    const uri = `affiliates/payment-history?page=${page}`;
    const res = await API.get(uri);
    return res.data;
  };

  export const getPaymentDetail = async ({page,id}) => {
    const uri = `affiliates/payment-history/${id}?page=${page}`;
    const res = await API.get(uri);
    return res.data;
  };
