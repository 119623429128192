import { Button, Modal, ModalBody } from "reactstrap"


import style from "./index.module.scss";
import { Icon, Img } from "../../../components/Component";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
const ModalCapChannel = ({ isOpen, toggle, numberLimit }) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} toggle={toggle}
      size="lg" centered
      className={style.modalwaper}
    >
      <ModalBody >
        <div className="nk-modal d-flex align-items-center" style={{ flexDirection: "column" }}>
          <Icon className="nk-modal-icon icon-circle icon-circle-xxl icon ni ni-alert bg-danger"></Icon>
          <h4 className="nk-modal-title">{t("modal_limit_connect.header")}</h4>
          <div className="nk-modal-text">
            <p className="lead text-center">
              {t("modal_limit_connect.mess").replace("{{channel}}", numberLimit)}
            </p>
          </div>
          <div className="nk-modal-action mt-2">
            <Button color="light" size="lg" className="btn-mw" onClick={() => {
              history.push("/admin/project/pricing");
            }} >
              {t("modal_limit_connect.btn_back")}
            </Button>
          </div>
        </div>
      </ModalBody>

    </Modal>
  )
}

export default ModalCapChannel;