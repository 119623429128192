import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "reactstrap";

const Accordion = ({ className, variation, ...props }) => {
  const [isOpen, setIsOpen] = useState("1");
  const { t } = useTranslation("faq")
  const toggleCollapse = (param) => {
    if (param === isOpen) {
      setIsOpen("0");
    } else {
      setIsOpen(param);
    }
  };

  return (
    <div className={[`accordion${variation ? " accordion-s" + variation : ""}${className ? " " + className : ""}`]}>
      <div className="accordion-item">
        <div className={[`accordion-head${isOpen !== "1" ? " collapsed" : ""}`]} onClick={() => toggleCollapse("1")}>
          <h6 className="title">{t("so9_mission")}</h6>
          <span className="accordion-icon"></span>
        </div>
        <Collapse className="accordion-body" isOpen={isOpen === "1" ? true : false}>
          <div className="accordion-inner">
            <p>
              {t("so9_mission_detail")}
            </p>
          </div>
        </Collapse>
      </div>
      <div className="accordion-item">
        <div className={[`accordion-head${isOpen !== "2" ? " collapsed" : ""}`]} onClick={() => toggleCollapse("2")}>
          <h6 className="title">{t("so9_service")}</h6>
          <span className="accordion-icon"></span>
        </div>
        <Collapse className="accordion-body" isOpen={isOpen === "2" ? true : false}>
          <div className="accordion-inner">
            <p>
              {t("so9_service_detail")}
            </p>
            <p> <b><i> {t("develop_potential_customers")}</i></b> </p>
            <ul className="list list-sm">
              <li> <b>{t("social_studio")}</b> {t("social_studio_define")}</li>
              <li> <b>{t("advertising_studio")}</b> {t("advertising_studio_define")}</li>
            </ul>
            <p> <b><i>{t("convert")}</i></b> </p>
            <ul className="list list-sm">
              <li> <b>{t("crm")}</b> {t("crm_define")}</li>
              <li> <b>{t("order")}:</b> {t("order_define")}</li>
            </ul>
            <p> <b><i>{t("running_a_business")}</i></b> </p>
            <ul className="list list-sm">
              <li> <b>{t("storage")}</b> {t("storage_define")}</li>
              <li> <b>{t("customer_360")}</b> {t("customer_360_define")}</li>
            </ul>
          </div>
        </Collapse>
      </div>
      <div className="accordion-item">
        <div className={[`accordion-head${isOpen !== "3" ? " collapsed" : ""}`]} onClick={() => toggleCollapse("3")}>
          <h6 className="title">{t("benefits_of_using_so9")}</h6>
          <span className="accordion-icon"></span>
        </div>
        <Collapse className="accordion-body" isOpen={isOpen === "3" ? true : false}>
          <div className="accordion-inner">
            <p>
              {t("benefits_content")}
            </p>
            <ul className="list list-sm">
              <li> {t("benefit_1")}</li>
              <li> {t("benefit_2")}</li>
              <li> {t("benefit_3")}</li>
              <li> {t("benefit_4")}</li>
            </ul>
          </div>
        </Collapse>
      </div>
      <div className="accordion-item">
        <div className={[`accordion-head${isOpen !== "4" ? " collapsed" : ""}`]} onClick={() => toggleCollapse("4")}>
          <h6 className="title">{t("packages_of_so9")}</h6>
          <span className="accordion-icon"></span>
        </div>
        <Collapse className="accordion-body" isOpen={isOpen === "4" ? true : false}>
          <div className="accordion-inner">
            <p>
              {t("packages_content")}
            </p>
            <ul className="list list-sm">
              <li> <b>{t("gold_pack")}</b> {t("gold_pack_detail")}</li>
              <li> <b>{t("ruby_pack")}</b> {t("ruby_pack_detail")}</li>
              <li> <b>{t("diamond_pack")}</b> {t("diamond_pack_detail")}</li>
              <li> <b>{t("custom_pack")}</b> {t("custom_pack_detail")}</li>
            </ul>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default Accordion;
