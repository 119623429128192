import { useRef, useEffect, useLayoutEffect } from 'react';
import {getProject} from "../api/project";
import {setProject} from "../reducers/projectSlice";
import {useDispatch} from "react-redux";

export function useFirstRender() {
  const userInfor = JSON.parse(localStorage.getItem('userInfor'));
  const dispatch = useDispatch();
  const loadProject = async () => {
    if(!userInfor?.last_project_active) return;
    const res = await getProject(userInfor.last_project_active);
    dispatch(setProject(res))
  }
  useEffect(() => {
    loadProject()
  }, []);
}