import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getUserInfoInLocalStorage } from "../../helper";

import {
  getCountPostYoutube,
  getLogFetchFb,
  getLogFetchYT,
  getUserStatisticYT,
} from "../../../../../api/dashboard";
import ContentSpread from "../CommonItem/ContentSpread";
import UserStatisticTable from "../CommonItem/UserStatisticTable";
import { LogHeader } from "../../CommonItem/LogHeader";
import { PLATFORM_NAME } from "../../constants";
import Content from "../../../../../layout/content/Content";
import { Block } from "../../../../../components/Component";
import { Col } from "reactstrap";

const YoutubeStaff = ({
  channels,
  staffs,
  setLoadingStaffFilter,
  setLoadingChannelFilter,
  setLoadingRange,
  from,
  to,
}) => {
  const { t } = useTranslation();
  const userInfor = getUserInfoInLocalStorage();
  const projectId = userInfor.last_project_active;

  const [chartData, setChartData] = useState([{}]);
  const [options, setOptions] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [logYTData, setLogYTData] = useState({});
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [tooltipTableOpen, setTableToolTipOpen] = useState([
    { isOpen: false, label: "" },
    { isOpen: false, label: "Tổng lượt xem của các video." },
    { isOpen: false, label: "Tổng số người xem video" },
    { isOpen: false, label: "Tổng số lượt Thích video" },
    { isOpen: false, label: "Tổng số Bình luận của video" },
    { isOpen: false, label: "Tổng số lần video được chia sẻ" },
    { isOpen: false, label: "Thời gian trung bình xem video" },
    { isOpen: false, label: "Tỷ lệ xem hết video" },
  ]);
  const headerUserStatistic = {
    user: t("employee"),
    view_count: t("views"),
    dislike_count: t("dis_like"),
    like_count: t("likes"),
    comment_count: t("comment"),
    share_count: t("share"),
    average_view_duration: t("avg_watch_time"),
    estimated_minutes_watched: t("estimated_minutes_watched"),
  };

  const getAPIYoutube = async (data) => {
    setTableLoading(true);
    setIsLoading(true);
    getCountPostYoutube(data).then((response) => {
      if (response) {
        const _data = response.data;
        setChartData([
          {
            name: "",
            data: _data.dataSet.data,
          },
        ]);

        const options = {
          chart: {
            height: 345,
            type: "line",
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          colors: ["#45cb85d9", "#4b38b3", "#007bff"],

          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: [3, 4, 3],
            curve: "straight",
            dashArray: [0, 8, 5],
          },
          series: _data?.dataSet?.data || [],
          markers: {
            size: 0,

            hover: {
              sizeOffset: 6,
            },
          },
          xaxis: {
            categories: _data?.dataSet?.labels,
          },
          grid: {
            borderColor: "#f1f1f1",
          },
        };

        setOptions(options);
      } else {
        setChartData([{}]);
      }
      setIsLoading(false);
    });

    const dataYTRes = await getUserStatisticYT(data);
    if (dataYTRes) {
      const dataTT = dataYTRes.data;
      setData(dataTT);

      setListData(
        dataTT.data.map((item) => ({
          user: item?.user,
          view_count: item?.view_count,
          dislike_count: item?.dislike_count,
          like_count: item?.like_count,
          comment_count: item?.comment_count,
          share_count: item?.share_count,
          average_view_duration: item.average_view_duration,
          estimated_minutes_watched: item.estimated_minutes_watched,
        }))
      );
      setTableLoading(false);
    }

    Promise.allSettled([getLogFetchYT(data)]).then((responses) => {
      // Destructure the responses to get the data
      const [logFbRes] = responses.data;

      if (logFbRes.status === "fulfilled") {
        const logFb = logFbRes.value.data;
        setLogYTData(logFb);
      }

      setLoadingStaffFilter(false);
      setLoadingChannelFilter(false);
      setLoadingRange(false);
    });
  };

  useEffect(() => {
    const data = {
      params: {
        project_id: projectId,
        channel_ids: channels,
        user_ids: staffs,
        from,
        to,
      },
    };
    getAPIYoutube(data);
  }, [from, to, channels, staffs, projectId]);

  return (
    <>
      <Col lg={12} className="px-8">
        <LogHeader
          cnt_channel_error={logYTData?.cnt_channel_error}
          cnt_channel_success={logYTData?.cnt_channel_success}
          last_time={logYTData?.last_time}
          currentTab={PLATFORM_NAME.YOUTUBE}
        />
      </Col>
      <div style={{ padding: "18px 24px" }}>
        {/* <Block> */}
        <ContentSpread
          chartData={chartData}
          options={options}
          isLoading={isLoading}
        />
        {/* </Block> */}
        {/* <Block> */}
        <UserStatisticTable
          setCurrentPage={setCurrentPage}
          headerUserStatistic={headerUserStatistic}
          tooltipTableOpen={tooltipTableOpen}
          tableLoading={tableLoading}
          listData={listData}
          data={data}
        />
        {/* </Block> */}
      </div>
    </>
  );
};

export default YoutubeStaff;
