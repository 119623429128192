import React, { useEffect, useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  UncontrolledDropdown,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import {
  RiArrowUpDownLine,
  RiFilterLine,
  RiListCheck3,
} from "@remixicon/react";
import { dragSectionTask, updateTask } from "../../../../api/kanban";
import { LoadingItem } from "../../Dashboard_v2/CommonItem/LoadingItem";
import FilterSelect from "./FilterSelect";
import KanbanSectionForm from "./KanbanSectionForm";
import KanbanTaskForm from "./KanbanTaskForm";
import KanbanColumn from "./KanbanColumn";
import { Button, Icon } from "../../../../components/Component";
import BusinessVideo from "../../../videos/business.mp4";
import IconArrow from "../../../../assets/images/icon/icon-arrow.svg";
import styles from "../index.module.scss";
import "../styles.css";

const KanbanBoard = ({
  isOpenLeftTab,
  setIsOpenLeftTab,
  toggleFilter,
  isLoadingSection,
  isLoadingTasks,
  initialData,
  setInitialData,
  activeGroup,
  activeFilter,
  staffs,
  type,
  orderBy,
  sort,
  isComplete,
  setFilterParams,
  isBusiness,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const { t } = useTranslation();

  const [sectionModal, setSectionModal] = useState(false);
  const [editSection, setEditSection] = useState();
  const [taskModal, setTaskModal] = useState(false);
  const [priority, setPriority] = useState();
  const [openFilterIndex, setOpenFilterIndex] = useState(null);
  const [filters, setFilters] = useState([{ type: "", value: null }]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const toggleSectionModal = (item) => {
    setEditSection(item);
    setSectionModal(!sectionModal);
  };

  const toggleTaskModal = (item) => {
    setEditSection(item);
    setTaskModal(!taskModal);
  };

  const handleOnDragEnd = async (result) => {
    const { source, destination, draggableId, type } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === "column") {
      const newColumnOrder = Array.from(initialData.columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      const priority_sections = newColumnOrder;

      const newState = {
        ...initialData,
        columnOrder: newColumnOrder,
      };
      setInitialData(newState);

      await dragSectionTask(userInfo.last_project_active, {
        priority_sections,
      });
      return;
    }

    const home = initialData.columns[source.droppableId];
    const foreign = initialData.columns[destination.droppableId];

    const section = destination.droppableId;
    const destinationTask = initialData.columns[section].tasks;
    let priority;
    let data;
    if (destinationTask.length === 0) {
      const nextPriority = initialData.tasks[draggableId].priority;
      priority = nextPriority;
      data = {
        section_id: destination.droppableId,
        priority: nextPriority,
      };
    } else {
      let prevItem;
      let nextItem;
      //same section
      if (home === foreign) {
        //keo tu duoi len
        if (destination.index < source.index) {
          prevItem = initialData.columns[section].tasks[destination.index - 1];
          nextItem = initialData.columns[section].tasks[destination.index];
        } else {
          //keo tu tren xuong
          prevItem = initialData.columns[section].tasks[destination.index];
          nextItem = initialData.columns[section].tasks[destination.index + 1];
        }
      } else {
        //diffrent section
        prevItem = initialData.columns[section].tasks[destination.index - 1];
        nextItem = initialData.columns[section].tasks[destination.index];
      }

      if (prevItem && nextItem) {
        const prevPriority = initialData.tasks[prevItem]?.priority;
        const nextPriority = initialData.tasks[nextItem]?.priority;
        priority = (prevPriority + nextPriority) / 2;
      } else if (nextItem) {
        const nextPriority = initialData.tasks[nextItem]?.priority;
        priority = (0 + nextPriority) / 2;
      } else {
        const prevPriority = initialData.tasks[prevItem].priority;
        priority = prevPriority + 1;
      }

      data = {
        section_id: destination.droppableId,
        priority,
      };
    }

    // check for same column
    const newState =
      home === foreign
        ? handleOnDragEndSameSection(
            home,
            source,
            destination,
            draggableId,
            priority
          )
        : handleOnDragEndDiffrentSection(
            home,
            foreign,
            source,
            destination,
            draggableId,
            priority
          );
    setInitialData(newState);

    if (data) {
      await updateTask(userInfo.last_project_active, draggableId, data);
    }
  };

  const handleOnDragEndSameSection = (
    home,
    source,
    destination,
    draggableId,
    priority
  ) => {
    const items = Array.from(home.tasks);
    items.splice(source.index, 1);
    items.splice(destination.index, 0, draggableId);

    const newItems = {
      ...home,
      tasks: items,
    };

    const newState = {
      ...initialData,
      columns: {
        ...initialData.columns,
        [home._id]: newItems,
      },
      tasks: {
        ...initialData.tasks,
        [initialData.tasks[draggableId]]: {
          ...initialData.tasks[draggableId],
          priority,
        },
      },
    };

    return newState;
  };

  const handleOnDragEndDiffrentSection = (
    home,
    foreign,
    source,
    destination,
    draggableId,
    priority
  ) => {
    const home_cnt = home.cnt_task;
    const items = Array.from(home.tasks);
    items.splice(source.index, 1);
    const newHome = {
      ...home,
      cnt_task: home_cnt - 1,
      tasks: items,
    };

    const foreign_cnt = foreign.cnt_task;
    const foreigntasks = Array.from(foreign.tasks);
    foreigntasks.splice(destination.index, 0, draggableId);
    const newForeign = {
      ...foreign,
      cnt_task: foreign_cnt + 1,
      tasks: foreigntasks,
    };

    const newState = {
      ...initialData,
      columns: {
        ...initialData.columns,
        [newHome._id]: newHome,
        [newForeign._id]: newForeign,
      },
      tasks: {
        ...initialData.tasks,
        [draggableId]: {
          ...initialData.tasks[draggableId],
          priority,
        },
      },
    };
    return newState;
  };

  useEffect(() => {
    setInitialData(initialData);
    if (
      initialData &&
      initialData.columns &&
      initialData.columnOrder.length > 0
    ) {
      const lastColumnId =
        initialData.columnOrder[initialData.columnOrder.length - 1];
      const lastColumn = initialData.columns[lastColumnId];
      setPriority(lastColumn.priority);
    }
  }, [initialData]);

  const staffOptions = staffs?.map((item) => ({
    value: item.id,
    label: item.name,
    avatar: item.avatar,
  }));

  const handleSelectOwner = (item, filterIndex) => {
    const updatedFilters = [...filters];
    updatedFilters[filterIndex].value = item;
    setFilters(updatedFilters);
  };

  const handleFilterChange = (type, filterIndex) => {
    const updatedFilters = [...filters];
    updatedFilters[filterIndex].type = type;
    if (type !== "Time") {
      updatedFilters[filterIndex].value = staffOptions[0];
    }
    setFilters(updatedFilters);
  };

  const addNewFilter = () => {
    setFilters([...filters, { type: "", value: null }]);
  };

  const setIsOpen = (index) => {
    setOpenFilterIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    const url = new URL(window.location.href);

    const changes = {};

    url.searchParams.delete("is_subscriber");
    url.searchParams.delete("subscriber_ids[]");
    url.searchParams.delete("is_owner");
    url.searchParams.delete("owner_ids[]");
    url.searchParams.delete("started_at[]");
    url.searchParams.delete("ended_at[]");

    filters.forEach((filter) => {
      if (filter.type === "Subscriber" && filter.value !== null) {
        url.searchParams.set("is_subscriber", "true");

        if (!changes["is_subscriber"]) {
          changes["is_subscriber"] = "true";
        }

        if (!changes["subscriber_ids"]) {
          changes["subscriber_ids"] = [];
        }

        const subscriberIds = Array.isArray(filter.value.value)
          ? filter.value.value
          : [filter.value.value];

        subscriberIds.forEach((id) => {
          url.searchParams.append("subscriber_ids[]", id.toString());
          changes["subscriber_ids"].push(id.toString());
        });
      } else if (filter.type === "Owner" && filter.value !== null) {
        url.searchParams.set("is_owner", "true");

        if (!changes["is_owner"]) {
          changes["is_owner"] = "true";
        }

        if (!changes["owner_ids"]) {
          changes["owner_ids"] = [];
        }

        const ownerIds = Array.isArray(filter.value.value)
          ? filter.value.value
          : [filter.value.value];

        ownerIds.forEach((id) => {
          url.searchParams.append("owner_ids[]", id.toString());
          changes["owner_ids"].push(id.toString());
        });
      } else if (filter.type === "Time" && startDate && endDate) {
        url.searchParams.append(
          "started_at[]",
          Math.floor(startDate.getTime() / 1000).toString()
        );
        url.searchParams.append(
          "ended_at[]",
          Math.floor(endDate.getTime() / 1000).toString()
        );

        changes["started_at"] = Math.floor(startDate.getTime() / 1000);
        changes["ended_at"] = Math.floor(endDate.getTime() / 1000);
      }
    });

    let queryString = "";

    Object.keys(changes).forEach((key) => {
      if (Array.isArray(changes[key])) {
        changes[key].forEach((value) => {
          queryString += `&${encodeURIComponent(key)}[]=${encodeURIComponent(
            value
          )}`;
        });
      } else {
        queryString += `&${encodeURIComponent(key)}=${encodeURIComponent(
          changes[key]
        )}`;
      }
    });

    queryString = queryString.length > 0 ? queryString.substring(1) : "";
    setFilterParams(queryString);

    window.history.pushState({}, "", url.toString());
  }, [filters, startDate, endDate]);

  return (
    <div
      className="d-flex flex-column flex-fill position-relative"
      id={"kanban-board"}
      style={{ width: "100%" }}
    >
      <div
        className="absolute color-border border-[#CED4DA] flex items-center justify-center h-6 w-6 rounded-full"
        style={{
          marginLeft: -12,
          marginTop: 16,
        }}
        onClick={() => setIsOpenLeftTab(!isOpenLeftTab)}
      >
        <img
          src={IconArrow}
          alt="arrow"
          className={`cursor-pointer mx-auto ${
            isOpenLeftTab ? "rotate-180" : ""
          }`}
          fill={"#878A99"}
          width={16}
          height={16}
        />
      </div>
      <div
        className={`${styles.sideMenuHeader} ${
          !isBusiness ? "disabled-menu" : ""
        }`}
        style={{
          padding: "18px 12px",
          borderBottom: "1px solid #eff0f1",
          backgroundColor: "#fbfcfc",
        }}
      >
        <ul
          style={{ paddingLeft: "12px" }}
          className="d-flex items-center gap-4"
        >
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                style={{
                  height: "30px",
                }}
                size="sm"
                tag="a"
                className="dropdown-toggle btn btn-white btn-dim btn-outline-light d-flex items-center"
                disabled={!isBusiness}
              >
                <RiListCheck3 size={16} className="text-muted cursor-pointer" />
                <span className="ml-2">
                  {activeFilter === "ongoing"
                    ? t("task_management.filter.on_going")
                    : activeFilter === "completed"
                    ? t("task_management.filter.completed")
                    : activeFilter === "all"
                    ? t("task_management.filter.all")
                    : t("task_management.filter.on_going")}
                </span>
              </DropdownToggle>
              <DropdownMenu left>
                <ul className="link-list-opt no-bdr">
                  <li className="cursor-pointer">
                    <DropdownItem
                      tag="a"
                      onClick={() => toggleFilter("ongoing")}
                    >
                      <span>{t("task_management.filter.on_going")}</span>
                    </DropdownItem>
                  </li>
                  <li className="cursor-pointer">
                    <DropdownItem
                      tag="a"
                      onClick={() => toggleFilter("completed")}
                    >
                      <span>{t("task_management.filter.completed")}</span>
                    </DropdownItem>
                  </li>
                  <li className="cursor-pointer">
                    <DropdownItem tag="a" onClick={() => toggleFilter("all")}>
                      <span>{t("task_management.filter.all")}</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                style={{ height: "30px" }}
                size="sm"
                tag="a"
                className="dropdown-toggle btn btn-white btn-dim btn-outline-light d-flex items-center"
                disabled={!isBusiness}
              >
                <RiFilterLine size={16} className="text-muted cursor-pointer" />
                <span className="ml-2">{`${t("task_management.filter.filter")}${
                  filters.length > 0 && filters[0].value !== null
                    ? ` (${filters.length})`
                    : ""
                }`}</span>
              </DropdownToggle>
              <DropdownMenu left>
                <div
                  style={{
                    width: "440px",
                    padding: "10px 20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{ width: "100%", height: "36px" }}
                    className="d-flex justify-content-between items-center"
                  >
                    <span style={{ fontWeight: "bold" }}>
                      {t("task_management.filter.filter2")}
                    </span>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setFilters([{ type: "", value: null }])}
                    >
                      {t("task_management.filter.clear")}
                    </span>
                  </div>
                  {filters.map((filter, index) => (
                    <div
                      key={index}
                      className="d-flex w-100 items-center"
                      style={{
                        justifyContent: "space-between",
                        margin: "8px 0",
                        gap: "16px",
                      }}
                    >
                      <div
                        className="d-flex items-center"
                        style={{ gap: "8px" }}
                      >
                        <UncontrolledDropdown>
                          <DropdownToggle
                            style={{ height: "30px" }}
                            size="sm"
                            tag="a"
                            className="dropdown-toggle btn btn-white btn-dim btn-outline-light d-flex items-center"
                          >
                            <span>
                              {filter.type === "Owner"
                                ? t("task_management.filter.owner")
                                : filter.type === "Subscriber"
                                ? t("task_management.filter.subscribers")
                                : filter.type === "Time"
                                ? t("task_management.filter.time")
                                : t("task_management.filter.filter2")}
                            </span>
                          </DropdownToggle>
                          <DropdownMenu
                            left
                            style={{ overflow: "visible !important" }}
                          >
                            <ul className="link-list-opt no-bdr">
                              <li className="cursor-pointer">
                                <DropdownItem
                                  tag="a"
                                  onClick={() =>
                                    handleFilterChange("Owner", index)
                                  }
                                >
                                  <span>
                                    {t("task_management.filter.owner")}
                                  </span>
                                </DropdownItem>
                              </li>
                              <li className="cursor-pointer">
                                <DropdownItem
                                  tag="a"
                                  onClick={() =>
                                    handleFilterChange("Subscriber", index)
                                  }
                                >
                                  <span>
                                    {t("task_management.filter.subscribers")}
                                  </span>
                                </DropdownItem>
                              </li>
                              <li className="cursor-pointer">
                                <DropdownItem
                                  tag="a"
                                  onClick={() =>
                                    handleFilterChange("Time", index)
                                  }
                                >
                                  <span>
                                    {t("task_management.filter.time")}
                                  </span>
                                </DropdownItem>
                              </li>
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        {filter.type === "Subscriber" ||
                        filter.type === "Owner" ? (
                          <FilterSelect
                            isOpen={openFilterIndex === index}
                            setIsOpen={() => setIsOpen(index)}
                            data={filter.value}
                            options={staffOptions}
                            handleSelect={(item) =>
                              handleSelectOwner(item, index)
                            }
                            placeholder={t(
                              "task_management.placeholder.add_owner"
                            )}
                            direction="dropdown"
                          />
                        ) : filter.type === "Time" ? (
                          <div>
                            <DatePicker
                              selected={startDate}
                              onChange={(update) => {
                                setDateRange(update);
                                setIsOpen(index);
                              }}
                              startDate={startDate}
                              endDate={endDate}
                              dateFormat="dd/MM/yyyy"
                              selectsRange
                              className="form-control date-picker"
                              placeholderText={t(
                                "task_management.placeholder.select_date_range"
                              )}
                            />
                          </div>
                        ) : null}
                      </div>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          const updatedFilters = filters.filter(
                            (_, i) => i !== index
                          );
                          setFilters(updatedFilters);
                        }}
                      >
                        x
                      </span>
                    </div>
                  ))}
                  <div style={{ marginTop: "12px" }}>
                    <Button color="primary" size="sm" onClick={addNewFilter}>
                      {t("task_management.filter.add_filter")}
                    </Button>
                  </div>
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>

          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                style={{ height: "30px" }}
                size="sm"
                tag="a"
                className="dropdown-toggle btn btn-white btn-dim btn-outline-light d-flex items-center"
                disabled={!isBusiness}
              >
                <RiArrowUpDownLine
                  size={16}
                  className="text-muted cursor-pointer"
                />
                <span className="ml-2">
                  {!orderBy
                    ? t("task_management.filter.sorted_by")
                    : t("task_management.filter.sorted_by_2")}{" "}
                  {orderBy === "started_at"
                    ? t("task_management.filter.started_at")
                    : orderBy === "ended_at"
                    ? t("task_management.filter.ended_at")
                    : orderBy === "time"
                    ? t("task_management.filter.created_at")
                    : orderBy === "priority"
                    ? t("task_management.filter.custom")
                    : ""}
                </span>
              </DropdownToggle>
              <DropdownMenu left>
                <ul className="link-list-opt no-bdr">
                  <li className="cursor-pointer">
                    <DropdownItem
                      tag="a"
                      onClick={() => toggleFilter("started_at")}
                    >
                      <span>{t("task_management.filter.started_at")}</span>
                    </DropdownItem>
                  </li>
                  <li className="cursor-pointer">
                    <DropdownItem
                      tag="a"
                      onClick={() => toggleFilter("ended_at")}
                    >
                      <span>{t("task_management.filter.ended_at")}</span>
                    </DropdownItem>
                  </li>
                  <li className="cursor-pointer">
                    <DropdownItem tag="a" onClick={() => toggleFilter("time")}>
                      <span>{t("task_management.filter.created_at")}</span>
                    </DropdownItem>
                  </li>
                  <li className="cursor-pointer">
                    <DropdownItem
                      tag="a"
                      onClick={() => toggleFilter("priority")}
                    >
                      <span>{t("task_management.filter.custom")}</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
          <li>
            <Button
              style={{ height: "30px" }}
              size="sm"
              color="light"
              outline
              className="btn-white"
              onClick={() => toggleTaskModal()}
            >
              <Icon name="plus" />
              <span>{t("task_management.add_task")}</span>
            </Button>
          </li>
        </ul>
      </div>
      <div
        className={`${styles.kanbanBoard__midContent} color-border border-left-0 border-top-0 border-bottom-0 d-flex items-center justify-content-center`}
        style={{
          backgroundColor: "#fff",
          height: "100%",
          padding: "24px 0 24px 24px",
        }}
      >
        {isBusiness ? (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            {isLoadingSection ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <LoadingItem />
              </div>
            ) : (
              <Droppable
                droppableId="all-columns"
                direction="horizontal"
                type="column"
              >
                {(provided) => (
                  <div
                    className="kanban-container"
                    id="kanban-container"
                    style={{
                      width: `${initialData.columnOrder.length * 320}px`,
                      padding: "0 8px",
                    }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {initialData.columnOrder.map((columnId, index) => {
                      const column = initialData.columns[columnId];
                      return (
                        <>
                          <KanbanColumn
                            key={index}
                            index={index}
                            column={column}
                            toggleTaskModal={toggleTaskModal}
                            toggleSectionModal={toggleSectionModal}
                            isLoadingSection={isLoadingSection}
                            isLoadingTasks={isLoadingTasks}
                            activeGroup={activeGroup}
                            initialData={initialData}
                            setInitialData={setInitialData}
                            type={type}
                            orderBy={orderBy}
                            sort={sort}
                            staffs={staffs}
                            isComplete={isComplete}
                          />
                        </>
                      );
                    })}
                    {provided.placeholder}
                    <div style={{ display: "flex" }}>
                      <Button
                        className="btn-kanban"
                        color="default"
                        onClick={() => toggleSectionModal()}
                      >
                        <Icon name="plus" />
                        <span>{t("task_management.add_section")}</span>
                      </Button>
                    </div>
                  </div>
                )}
              </Droppable>
            )}

            <Modal size="lg" isOpen={taskModal} toggle={toggleTaskModal}>
              <KanbanTaskForm
                toggle={toggleTaskModal}
                activeGroup={activeGroup}
                section={editSection}
                staffs={staffs}
                sections={initialData.columns}
                setInitialData={setInitialData}
              />
            </Modal>

            <Modal size="lg" isOpen={sectionModal} toggle={toggleSectionModal}>
              <KanbanSectionForm
                toggle={toggleSectionModal}
                activeGroup={activeGroup}
                section={editSection}
                prioritySize={priority ? priority : 1}
                setInitialData={setInitialData}
              />
            </Modal>
          </DragDropContext>
        ) : (
          <div
            className="d-flex w-100 h-100"
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <div className="h-50 w-50">
              <video
                src={BusinessVideo}
                className="h-100 w-100"
                type="video/mp4"
                autoPlay
                muted
                loop
              >
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="business">
              <h3>{t("task_management.add_on")}</h3>
              <p>{t("task_management.sub_alert_only_avaliable_business")}</p>
              <button
                onClick={() => {
                  window.open("https://so9.vn/pricing/", "_blank");
                }}
              >
                <div className="circle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                    fill="rgba(255,255,255,1)"
                  >
                    <path d="M12.0006 18.26L4.94715 22.2082L6.52248 14.2799L0.587891 8.7918L8.61493 7.84006L12.0006 0.5L15.3862 7.84006L23.4132 8.7918L17.4787 14.2799L19.054 22.2082L12.0006 18.26Z"></path>
                  </svg>
                </div>
                {t("task_management.botton_upgrade")}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default KanbanBoard;
