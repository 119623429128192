import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Container, Spinner } from "reactstrap";

import { getProject } from "../../../../api/project";
import { getProjectIdInLocalStorage } from "../helper";
// import BreadCrumb from "Components/Common/BreadCrumb";
import HeaderDashboard from "../CommonItem/HeaderDashboard";

import FacebookStaff from "./Facebook";
import TiktokStaff from "./TikTok";
import YoutubeStaff from "./Youtube";
import { PLATFORM_NAME } from "../constants";
import { useLocation } from "react-router-dom";
import ModalAlertBusiness from "../../../components/Modal/ModalCheckBusiness";

export const DashboardEnterprise = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const channelValue = params.get("channel_ids")?.split(",") || [];
  const fromValue =
    params.get("from") || moment().subtract(14, "days").format("YYYY-MM-DD");
  const toValue = params.get("to") || moment().format("YYYY-MM-DD");

  const staffsValue = params.get("staffs")?.split(",") || [];

  const [selectedPlatForm, setSelectedPlatForm] = useState(
    PLATFORM_NAME.FACEBOOK
  );
  const [range, setRange] = useState(14);
  const [channels, setChannels] = useState(channelValue);
  const [staffs, setStaffs] = useState(staffsValue);
  const [loadingChannelFilter, setLoadingChannelFilter] = useState(false);
  const [loadingStaffFilter, setLoadingStaffFilter] = useState(false);
  const [loadingRange, setLoadingRange] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);

  const [from, setFrom] = useState(fromValue);
  const [to, setTo] = useState(toValue);

  const projectId = getProjectIdInLocalStorage();
  useEffect(() => {
    const getActiveTab = localStorage?.getItem("activeTabDashboardEnterprise");
    if (getActiveTab) {
      setSelectedPlatForm(
        JSON.parse(getActiveTab)?.toUpperCase() || PLATFORM_NAME.FACEBOOK
      );
    } else {
      setSelectedPlatForm(PLATFORM_NAME.FACEBOOK);
    }
    getProject(projectId).then((project) => {
      if (Object.keys(project).length !== 0) {
        if (project?.type === "business") {
          setIsBusiness(true);
          setFirstLoading(false);
        } else {
          setFirstLoading(false);
        }
      }
    });
  }, [projectId]);
  useEffect(() => {
    localStorage.setItem(
      "activeTabDashboardEnterprise",
      JSON.stringify(selectedPlatForm)
    );
  }, [selectedPlatForm]);

  return (
    <div className="page-content" style={{ marginTop: "80px" }}>
      {/* <Container fluid> */}
      {/* <BreadCrumb title="Ecommerce" pageTitle="Dashboard" /> */}
      <Col lg={12}>
        <HeaderDashboard
          selectedPlatform={selectedPlatForm}
          setSelectedPlatForm={setSelectedPlatForm}
          range={range}
          setRange={setRange}
          channel={channels}
          setChannels={setChannels}
          loadingChannelFilter={loadingChannelFilter}
          setLoadingChannelFilter={setLoadingChannelFilter}
          loadingRange={loadingRange}
          setLoadingRange={setLoadingRange}
          isEnterprise={true}
          staffs={staffs}
          setStaffs={setStaffs}
          setLoadingStaffFilter={setLoadingStaffFilter}
          loadingStaffFilter={loadingStaffFilter}
          from={from}
          to={to}
          setFrom={setFrom}
          setTo={setTo}
        />
      </Col>

      {isBusiness ? (
        <>
          {selectedPlatForm === PLATFORM_NAME.FACEBOOK && (
            <FacebookStaff
              range={range}
              channels={channels}
              staffs={staffs}
              setLoadingStaffFilter={setLoadingStaffFilter}
              setLoadingChannelFilter={setLoadingChannelFilter}
              setLoadingRange={setLoadingRange}
              from={from}
              to={to}
            />
          )}

          {selectedPlatForm === PLATFORM_NAME.TIKTOK && (
            <TiktokStaff
              range={range}
              channels={channels}
              staffs={staffs}
              setLoadingStaffFilter={setLoadingStaffFilter}
              setLoadingChannelFilter={setLoadingChannelFilter}
              setLoadingRange={setLoadingRange}
              from={from}
              to={to}
            />
          )}

          {selectedPlatForm === PLATFORM_NAME.YOUTUBE && (
            <YoutubeStaff
              range={range}
              channels={channels}
              staffs={staffs}
              setLoadingStaffFilter={setLoadingStaffFilter}
              setLoadingChannelFilter={setLoadingChannelFilter}
              setLoadingRange={setLoadingRange}
              from={from}
              to={to}
            />
          )}
        </>
      ) : firstLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "100%", height: "500px" }}
        >
          <Spinner size="lg" color="primary"></Spinner>
        </div>
      ) : (
        <ModalAlertBusiness type="employee" />
      )}
      {/* </Container> */}
    </div>
  );
};
