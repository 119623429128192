import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Modal, ModalBody, Row, Spinner, Tooltip, UncontrolledTooltip } from "reactstrap"
import useQuery from "../../../hooks/useQuery";
import { useHistory, useLocation } from "react-router-dom";



import style from "./index.module.scss";
import { Icon, Img } from "../../../components/Component";
import DefaultImage from "../../../So9/images/avatar/no-image.png";
import FlowerTrial from "../../../So9/images/flowerTrial.png"

import FACEBOOK_IMAGE from "../../images/connectModal/facebook-image.png";
import INSTAGRAM_IMAGE from "../../images/connectModal/instagram-image.png";
import TIKTOK_IMAGE from "../../images/connectModal/tiktok-image.png";
import THEADS_IMAGE from "../../images/connectModal/threads-image.svg";
import YOUTUBE_IMAGE from "../../images/connectModal/youtube-image.png";
import ZALO_IMAGE from "../../images/connectModal/zalo-image.png";
import PIN_IMAGE from "../../images/connectModal/pinterest-image.png";

import COMMING_ICON from "../../images/connectModal/comming-soon.svg";
import FACE_ICON from "../../images/connectModal/tag-facebook.svg";
import TREND_ION from "../../images/connectModal/tag-trending.svg";
import ENGAE_ICON from "../../images/connectModal/tag-engae.svg";
import { URI, loadListChannel, loadListPageByFacebook } from "../../../api/project";
import { useTranslation } from "react-i18next";
import { getDefaultLanguage, getDomainApp, upperFirstLetter } from "../../../utils/Utils";
import { toastBasic } from "../../../libs/toasts";
import { accessNewUser } from "../../../api/auth";
import { activeChannel } from "../../../api/channel";
import moment from "moment";
import { setChannelInf, setChannelsInformation } from "../../reducers/channelSlice";
import { setIsSkip } from "../../reducers/connectChannelSlice";
import ModalConnectPageChannel from "./ModalConnectedItem";
import ModalCapChannel from "./ModalLimitChannel";

const ModalErrorConnectChannel = React.lazy(() => import("./ModalErrorConnectChannel"));
const ModalConnectPage = ({ forceOpenModal = false, setForceOpenModal, loggedConnect }) => {
    const { t } = useTranslation();
    const query = useQuery();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const auth = localStorage.getItem("accessToken");
    const typeOnboard = query.get('page_callback');
    // const logged = query.get('logged');
    const userLogin = JSON.parse(localStorage.getItem("userInfor"));
    const typeOfPlatform = query.get('type');
    const currentPlatform = query.get('current_platform');
    const channelOwned = useSelector((state) => state.channel.channelsInformation);
    const [modal, setModal] = useState(false);
    const defaultLanguage = getDefaultLanguage();
    const initData = defaultLanguage == "vi" ? [
        {
            platform: "Facebook",
            image: FACEBOOK_IMAGE,
            tag: { name: "Most Popular", icon: FACE_ICON },
            account_type: "Fanpage",
            support_feature: [{ name: "text" }, { name: "image" }, { name: "video" }, { name: "reels" }, { name: "analytics" }, { name: "engagement" }, { name: "stories" }],
            isOpen: false,
            same_platform: ["instagram", "youtube", "linkedin", "tiktok",],
            sub_same_platform: "Nội dung cũng phù để để đăng chéo",
            why: ["Tương tác với tệp đa dạng nhu cầu 2.8 Tỷ người dùng.", "Xây dựng cộng đồng trung thành với tần suất sử dụng cao.", "Nhận những Insight vô cùng hữu ích từ Báo cáo của SO9."]
        },
        {
            platform: "Instagram",
            tag: { name: "Trending", icon: TREND_ION },
            image: INSTAGRAM_IMAGE,
            account_type: "Business or Creator accounts",
            support_feature: [{ name: "image" }, { name: "video" }, { name: "reels" }, { name: "analytics" }, { name: "stories" }],
            isOpen: false,
            same_platform: ["pinterest", "tiktok", "youtube", "facebook"],
            loading: false,
            sub_same_platform: "Nội dung cũng phù để để đăng chéo",
            why: ["Kết nối với hàng tỷ người dùng hoạt động mỗi tháng", "130 triệu người dùng đang bấm vào các bài đăng mua sắm mỗi tháng.", "Tạo được đa dạng loại hình: Ảnh, Video, Reels, Stories (Sắp ra mắt)"]
        },
        {
            platform: "Tiktok",
            tag: { name: "Most Engaging", icon: ENGAE_ICON },
            image: TIKTOK_IMAGE,
            account_type: "Account or Business account",
            support_feature: [{ name: "video" }, { name: "analytics" }, { name: "engagement" }],
            isOpen: false,
            loading: false,
            sub_same_platform: "Nội dung cũng phù để để đăng chéo",
            same_platform: ["youtube", "instagram"],
            why: ["Khai thác giá trị từ 1 Tỷ người sử dụng năng động.", "Nền tảng siêu tương tác, với 6.92% Tỷ lệ tương tác trung bình.", "Ứng dụng được sử dụng trung bình 52 phút mỗi ngày."]
        },
        {
            platform: "Threads",
            image: THEADS_IMAGE,
            account_type: "Account",
            support_feature: [{ name: "text" }, { name: "video" }, { name: "image" }],
            isOpen: false,
            loading: false,
            sub_same_platform: "Nội dung cũng phù để để đăng chéo",
            same_platform: ["facebook", "instagram", "x (twitter)"],
            why: ["Tiếp cận nền tảng có 150 triệu người dùng và liên tục tăng trưởng.", "Xây dựng cộng đồng và gắn kết với khán giả của bạn.", "Tương tác với tin tức nóng hổi & cập nhật xu hướng trên nền tảng ưu tiên tương tác."]
        },
        {
            platform: "Youtube",
            account_type: "Channel",
            image: YOUTUBE_IMAGE,
            support_feature: [{ name: "video" }, { name: "short" }, { name: "analytics" }, { name: "engagement" }],
            isOpen: false,
            loading: false,
            sub_same_platform: "Nội dung cũng phù để để đăng chéo",
            same_platform: ["tiktok", "facebook", "instagram"],
            why: ["Tương tác với tệp 2 Tỷ khách hàng tiềm năng.", "Khai thác sức mạnh của công cụ tìm kiếm #2 thế giới (Sau Google).", "Khai thác hàng tỷ giờ xem video mỗi ngày của nền tảng."]
        },
        {
            platform: "Pinterest",
            account_type: "Business or Profile",
            image: PIN_IMAGE,
            support_feature: [{ name: "image" }, { name: "video" }],
            isOpen: false,
            loading: false,
            sub_same_platform: "Nội dung cũng phù để để đăng chéo",
            same_platform: ["facebook", "instagram"],
            why: ["Truyền cảm hưng & được 459 triệu người sử dụng mỗi ngày.", "Thúc đẩy lưu lượng cao người truy cập thẳng vào website của bạn.", "Tỷ lệ chuyển đổi cao với người tệp đang quan tâm sản phẩm."]
        },
        {
            platform: "Zalo",
            image: ZALO_IMAGE,
            loading: false,
            account_type: "Official Account",
            support_feature: [{ name: "engagement" }],
        },
    ] : [
        {
            platform: "Facebook",
            image: FACEBOOK_IMAGE,
            tag: { name: "Most Popular", icon: FACE_ICON },
            account_type: "Fanpage",
            support_feature: [{ name: "text" }, { name: "image" }, { name: "video" }, { name: "reels" }, { name: "analytics" }, { name: "engagement" }, { name: "stories" }],
            isOpen: false,
            sub_same_platform: "Facebook works well with",
            same_platform: ["instagram", "youtube", "linkedin", "tiktok",],
            why: ["Engage with a diverse 2.8 billion user base.", "Build a loyal community with high usage frequency.", "Get great insights in Buffer’s Analyze feature."]
        },
        {
            platform: "Instagram",
            tag: { name: "Trending", icon: TREND_ION },
            image: INSTAGRAM_IMAGE,
            account_type: "Business or Creator accounts",
            support_feature: [{ name: "image" }, { name: "video" }, { name: "reels" }, { name: "analytics" }, { name: "stories" }],
            isOpen: false,
            sub_same_platform: "Instagram works well with",
            same_platform: ["pinterest", "tiktok", "youtube", "facebook"],
            loading: false,
            why: ["Connect with billions of active users every month", "130 million users are clicking on shopping posts every month.", "Create a variety of types: Photos, Videos, Reels, Stories (Coming soon)"]
        },
        {
            platform: "Tiktok",
            tag: { name: "Most Engaging", icon: ENGAE_ICON },
            image: TIKTOK_IMAGE,
            account_type: "Account or Business account",
            support_feature: [{ name: "video" }, { name: "analytics" }, { name: "engagement" }],
            isOpen: false,
            loading: false,
            sub_same_platform: "Tiktok works well with",
            same_platform: ["youtube", "instagram"],
            why: ["Dive into a pool of 1 billion active users.", "Ride the wave of a 6.92% average engagement rate.", "Create content for TikTok’s highly engaged audience, who use the app for 52 minutes a day on average."]
        },
        {
            platform: "Threads",
            image: THEADS_IMAGE,
            account_type: "Account",
            support_feature: [{ name: "text" }, { name: "video" }, { name: "image" }],
            isOpen: false,
            loading: false,
            sub_same_platform: "Threads works well with",
            same_platform: ["facebook", "instagram", "x (twitter)"],
            why: ["Reach an ever-growing user base of more than 150 million people.", "Build your community and nurture authentic relationships with your audience.", "Engage in news and tap into trends on this conversation-first platform."]
        },
        {
            platform: "Youtube",
            account_type: "Channel",
            image: YOUTUBE_IMAGE,
            support_feature: [{ name: "video" }, { name: "short" }, { name: "analytics" }, { name: "engagement" }],
            isOpen: false,
            loading: false,
            sub_same_platform: "Youtube works well with",
            same_platform: ["tiktok", "facebook", "instagram"],
            why: ["Engage with a 2 billion-strong audience.", "Harness the power of the world's #2 search engine.", "Tap into a billion hours of daily video views."]
        },
        {
            platform: "Pinterest",
            account_type: "Business or Profile",
            image: PIN_IMAGE,
            support_feature: [{ name: "image" }, { name: "video" }],
            isOpen: false,
            loading: false,
            sub_same_platform: "Pinterest works well with",
            same_platform: ["facebook", "instagram"],
            why: ["Inspire and be seen by 482 million active users.", "Drive high referral traffic, straight to your site.", "Convert users with high purchase intent."]
        },
        {
            platform: "Zalo",
            image: ZALO_IMAGE,
            loading: false,
            account_type: "Official Account",
            support_feature: [{ name: "engagement" }],
        },
    ]

    const project = useSelector((state) => state.project.project);
    const channel = useSelector((state) => state.channel.channelInf);
    const isSkipModal = useSelector((state) => state.connectModal.isSkip);

    const [data, setData] = useState(initData);
    const [openModalListChannel, setOpenModalListChannel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disable, setIsDisable] = useState(false);
    const [listChannel, setListChannel] = useState([]);
    const [listChannelSelected, setListChannelSelected] = useState([]);
    const [typeFacebookPlatform, setTypeFacebookplatform] = useState('');
    const [isOpenModalGuideS09, setOpenModalGuideS09] = useState(false)
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [listChannelLoading, setListChannelLoading] = useState(false);
    const [isCapLimit, setIsCapLimit] = useState(false);
    const toggle = () => {
        setModal(!modal);
    }
    useEffect(() => {
        if (forceOpenModal) {
            setModal(forceOpenModal)
        } else {
            if (!isSkipModal) {
                if (channel === null) {
                    return;
                }

                const pathnameNotAllowed = [
                    "/admin/project/list",
                    "/admin/project/connect-channel",
                    "/connect-page/connect_new"
                ];

                if (pathnameNotAllowed.includes(location.pathname)) {
                    return;
                }

                const timeNow = moment(new Date()).unix();
                if (project && project?.expired_at <= timeNow) {
                    return;
                }

                if (channel?.length > 0) {
                    setModal(false);
                } else {
                    setModal(true);
                }
            }
        }


    }, [project, channel, forceOpenModal])


    useEffect(() => {
        if (!isSkipModal) {
            if (location.search.includes("isRegister=true")) {
                setModal(true);
            } else if (location.pathname.includes("/news")) {
                if (channel === null) {
                    return;
                }
                if (channel.length > 0) {
                    setModal(false);
                    return;
                }

                const pathnameNotAllowed = [
                    "/admin/project/list",
                    "/admin/project/connect-channel",
                    "/connect-page/connect_new"
                ];

                if (pathnameNotAllowed.includes(location.pathname)) {
                    return;
                }

                const timeNow = moment(new Date()).unix();
                if (project && project?.expired_at > timeNow) {

                }
                else {
                    return;
                }
                if (channel?.length > 0) {
                    setModal(false);
                } else {
                    setModal(true);
                }
            }
            else { return }
        }

    }, [location])

    const renderPlatform = (typeFacebookPlatform) => {
        switch (typeFacebookPlatform) {
            case "page":
                return (
                    <p className={style.Modalp}>Facebook Page</p>
                );
                break;
            case "group":
                return (
                    <p className={style.Modalp}>"Facebook Group</p>
                );
                break;
            case "instagram":
                return (
                    <p className={style.Modalp}>Instagram</p>
                );
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (!auth) {
            window.location.replace('/auth-login')
        }
        if (loggedConnect === "1") {
            setOpenModalListChannel(true)
            if (currentPlatform === "facebook") {
                setTypeFacebookplatform(typeOfPlatform)
                const params = {
                    typeOfPlatform: typeOfPlatform
                }
                setListChannelLoading(true);
                loadListPageByFacebook(userLogin.last_project_active, params).then(res => {
                    if (res.status && res.data) {
                        setListChannelLoading(false);
                        if (typeOfPlatform !== "instagram") {
                            setListChannel(res.data)
                        } else {
                            const existInstagramChannel = res.data.filter(item => {
                                return typeof item.instagram_business_account !== 'undefined' && item.instagram_business_account
                            })
                            if (existInstagramChannel.length > 0) {
                                const instaChannel = existInstagramChannel.map(item => {
                                    return {
                                        id: item.instagram_business_account.id,
                                        name: item.instagram_business_account.name,
                                        picture: {
                                            data: {
                                                url: item.instagram_business_account.profile_picture_url
                                            }
                                        }
                                    }
                                })
                                setListChannel(instaChannel)
                            }
                        }
                    } else {
                        setListChannelLoading(false);
                    }
                }).catch(
                    (error) => {
                        setListChannelLoading(false);
                        console.log(error);
                    }
                )
            }
        }
    }, [loggedConnect])

    const onSelectedPlatform = (platform, callback) => {
        const projectId = userLogin.last_project_active;
        const user_id = userLogin.id;
        let url = "";
        const pageCallBack = forceOpenModal ? 'connect-channel' : "news";
        switch (platform) {
            case "Facebook":
                url = process.env.REACT_APP_URL_API + "api/v1" + URI.LOGIN_FACEBOOK + "?";
                const params = "project_id=" + userLogin.last_project_active + "&type=page&page_callback=" + pageCallBack + "&domain=" + getDomainApp();
                window.location.href = url + params;
                break;
            case "Zalo":
                url = process.env.REACT_APP_ZALO_API + "api/v1" + URI.LOGIN_ZALO + "?project_id=" + projectId + "&page_callback=" + callback;
                window.location.href = url;
                break;
            case "Instagram":
                url = process.env.REACT_APP_URL_API + "api/v1" + URI.LOGIN_FACEBOOK + "?project_id=" + projectId + "&type=instagram&page_callback=" + pageCallBack + "&domain=" + getDomainApp();
                window.location.href = url;

                break;
            case "Pinterest":
                url = `https://pinterest.so9.vn` + URI.LOGIN_PINTEREST + "?project_id=" + projectId + `&user_id=${user_id}&page_callback=${callback}`;
                window.location.href = url;

                break;
            case "Tiktok":
                url = `https://tiktok.so9.vn/api/v1/tiktok/oauth?project_id=${projectId}&user_id=${user_id}&page_callback=${callback}`;
                window.location.href = url;

                break;
            case "Youtube":
                url = `https://youtube.so9.vn/youtube/auth?project_id=${projectId}&user_id=${user_id}&page_callback=${callback}`;
                window.location.href = url;

                break;
            case "Threads":
                url = process.env.REACT_APP_URL_API + "api/v1/threads-connector/login?project_id=" + projectId + "&domain=" + getDomainApp();
                window.location.href = url;

                break;
            default:
        }
    };


    const handleConnectPage = (item, index, e) => {
        e.stopPropagation();

        const { pathname, origin } = window.location;
        const url = `${origin}${pathname}`;
        if (project?.connection_limit > channel?.length) {
            onSelectedPlatform(item.platform, url);

            let newData = [...data];
            newData[index].loading = !newData[index].loading;
            setData(newData);
            setTimeout(() => {
                let newData = [...data];
                newData[index].loading = !newData[index].loading;
                setData(newData);
            }, 2000);
        } else {
            setIsCapLimit(true);
        }

    }

    const toggleModalListChannel = () => {
        setOpenModalListChannel(!openModalListChannel);
    }

    const handleOpen = (index) => {
        if (data[index].platform === "Zalo") {
            return;
        }
        let newData = [...data];
        newData[index].isOpen = !newData[index].isOpen;
        setData(newData);
    }
    const renderItems = (item, index) => {
        return (
            <>
                <div onClick={() => handleOpen(index)} className={`${style.item} row d-flex cursor-pointer rounded border align-items-center justify-content-between`}>
                    <div className={`${style.item__platform} col-4 d-flex align-items-center`}>
                        <Img src={item.image} height={"42px"} width={"42px"}></Img>
                        <div style={{ marginLeft: "11px" }}>
                            <div className="d-flex align-items-center">
                                <p className={`mb-0`}>{item.platform}</p>
                                {item.tag &&
                                    <div className={`d-flex align-items-center rounded ${style.enage}`}>
                                        {item.tag.icon && <Img src={item.tag.icon}></Img>}
                                        <p className="mb-0">{item.tag.name}</p>
                                    </div>}
                            </div>
                            <span>{item.account_type}</span>
                        </div>
                    </div>
                    <div className={`${style.item__support} col-6`}>
                        <p>  {t("components.modal_connect_page.supported")}</p>
                        <div className="d-flex align-items-center justify-content-start flex-wrap" style={{ gap: "2px" }}>
                            {item.support_feature.map((sup, index) => (
                                <div className={`${style.supportItems} rounded`} key={`supportFeature-${item.platform}-${index}`}>
                                    {sup.icon && <Img src={sup.icon}></Img>}
                                    {sup.name}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={`${style.item__action} col-2 d-flex align-items-center justify-content-between`}>
                        <Button onClick={(e) => handleConnectPage(item, index, e)} className="text-nowrap" style={{ backgroundColor: "#FD1F6D", border: "none" }}>
                            {t("components.modal_connect_page.connect")} {item.loading && <Spinner className="ms-1" size={"sm"}></Spinner>}</Button>
                        <div className="border" style={{ height: "38px", width: "1px" }}></div>
                        <div className="cursor-pointer" >
                            <Icon name={item.isOpen ? "upword-ios" : "downward-ios"}></Icon>
                        </div>
                    </div>


                    {item.isOpen &&
                        <div onClick={(e) => e.stopPropagation()} className={`w-100 border-top  ${style.acodionItems}`}>
                            <div className="d-flex" style={{ padding: "16px 16px 18px 16px" }}>
                                <div className="col-6">
                                    <p >{t("components.modal_connect_page.sub_why")} {item.platform} ?</p>
                                    {item.why.map((c) => (
                                        <div className={`d-flex align-items-center ${style.acodionItems__whyItems}`}>
                                            <Icon name={"check"}></Icon>
                                            {c}
                                        </div>
                                    ))}
                                </div>
                                <div className="col-5">
                                    <p >{item.sub_same_platform}</p>


                                    <div className="d-flex align-items-center justify-content-start flex-wrap" style={{ gap: "2px" }}>
                                        {item.same_platform.map((sup, index) => (
                                            <div className={`${style.acodionItems__supportItems} rounded`} key={`supporre-${item.platform}-${index}`}>
                                                {sup}
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </div>
                        </div>
                    }
                </div>

            </>
        )
    }

    const renderList = () => {
        return (
            data.map((item, index) => (
                renderItems(item, index)
            ))
        )

    }

    const handleConnectChannelSelected = async () => {
        if (!disable) {
            if (listChannelSelected.length === 0) {
                toastBasic(t("components.modal_connect_page.toast_alert.select_channel"))
                setLoading(false)
                setTimeout(() => {
                    setIsDisable(false);
                }, 1500);
                return;
            }

            // Them cac kenh
            const updateNewUser = await accessNewUser();
            if (!updateNewUser.status) {
                setLoading(false)
                toastBasic(t("components.modal_connect_page.toast_alert.error_try_again"))
                setIsDisable(false);
            }

            // cập nhật is_new_user vào userInfor
            let newUserInfor = JSON.parse(localStorage.getItem('userInfor'))
            newUserInfor.is_new_user = 0;
            localStorage.setItem('userInfor', JSON.stringify(newUserInfor));
            // fetchListChannels();

            if (typeOfPlatform === "group") {
                actionActivingGroup();
            } else {
                actionActivingPage();
            }
        }
    }

    const handleSelectPage = (pageId) => {
        const arrAllChannelIdOwn = channelOwned.map(channel => channel._id);
        const arrChannelIdNotConnect = listChannelSelected.filter(channel => arrAllChannelIdOwn.includes(channel))
        if (parseInt(project.connection_limit) <= parseInt(arrChannelIdNotConnect)) {
            toastBasic(t("components.modal_connect_page.toast_alert.error_limit_page"))
            return;
        }
        const newList = listChannelSelected;
        if (newList.indexOf(pageId) >= 0) {
            newList.splice(newList.indexOf(pageId), 1);
        } else {
            newList.push(pageId);
        }
        setListChannelSelected(newList);
    }

    const actionActivingPage = async () => {
        setLoading(true);
        let channels = listChannel.filter(item => {
            return listChannelSelected.indexOf(item.id) >= 0;
        });
        try {
            const active = await activeChannel(userLogin.last_project_active, {
                channels, typeOfPlatform
            })
            if (active.status) {
                setLoading(false)
                let url;
                switch (typeOnboard) {
                    case "publishing":
                        url = process.env.PUBLIC_URL + "/posts/create";
                        break;
                    case "analytics":
                        url = "/";
                        break;
                    case "engagement":
                        url = process.env.PUBLIC_URL + "/engagement";
                        break;
                    case "customer360":
                        url = process.env.PUBLIC_URL + "/engagement";
                        break;
                    case "connect_new":
                        url = process.env.PUBLIC_URL + "/admin/project/connect-channel";
                        break;
                    case "news":
                        url = process.env.PUBLIC_URL + "/news";
                        break;
                    default:
                        url = process.env.PUBLIC_URL + "/news";
                        break;
                }
                window.location.href = url;
            }
            else {
                setLoading(false)
                toastBasic()
                setIsDisable(false);
            }
        } catch (e) {
            setLoading(false)
            setIsDisable(false);
            setListChannelSelected([])
            const checkDome = document.querySelectorAll('.checkbox-channel')
            if (checkDome) {
                checkDome.forEach(item => {
                    item.checked = false
                })
            }
        }
    }

    const actionActivingGroup = () => {
        if (parseInt(project.connection_limit) < parseInt(listChannelSelected.length) + parseInt(channelOwned.length)) {
            toastBasic(t("components.modal_connect_page.toast_alert.error_limit_page"))
            setIsDisable(false);
            return;
        }
        setOpenModalGuideS09(true);
    }

    const handleSwapProject = () => {
        setModal(false);
        history.push("/admin/project/list");
    }

    const handleCoppyHiddenCode = () => {
        navigator.clipboard.writeText(userLogin?.uuid);
        setTooltipOpen(true);
        setTimeout(() => setTooltipOpen(false), 3000);
    }

    return (
        <>
        <Modal isOpen={modal} toggle={toggle} backdrop={"static"} size="xl">
            <ModalBody>
                <div className={`${style.connectModalContainer}`}>
                    <div className={`${style.connectModalContainer__header}`}>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <h3>{t("components.modal_connect_page.header")} </h3>
                                <Icon name="check-circle-fill"></Icon>
                            </div>

                            <div className="d-flex align-items-center">
                                <div onClick={handleCoppyHiddenCode} id="copy" className={`d-flex align-items-center cursor-pointer ${style.changeProject}`} style={{ marginRight: "36px" }}>
                                    <Icon name="files"></Icon>
                                    <p style={{ color: "#6A2C62" }} className={style.colorGray}>{t("components.modal_connect_page.secret_key")}</p>
                                </div>
                                <div onClick={handleSwapProject} className={`d-flex align-items-center cursor-pointer ${style.changeProject}`}>
                                    <Icon name="swap"></Icon>
                                    <p>{t("components.modal_connect_page.change_workspace")}</p>
                                </div>
                                <Tooltip
                                    autohide={false}
                                    placement="top"
                                    isOpen={tooltipOpen}
                                    target="copy"
                                    trigger="click"
                                >
                                    copied
                                </Tooltip>
                            </div>

                        </div>
                        <p>{t('components.modal_connect_page.sub_header')}</p>
                    </div>

                    <div className={`${style.connectModalContainer__content} d-flex flex-column overflow-auto`}>
                        {renderList()}
                    </div>
                    <div className="mt-3 d-flex justify-content-center align-items-center"
                        onClick={() => {
                            setModal(false);
                            dispatch(setIsSkip(true));
                            if (forceOpenModal) {
                                setForceOpenModal(false);
                            }
                        }}
                    >
                        <Button
                            color="primary"
                            outline
                        >
                            Skip & explore
                        </Button>
                    </div>
                </div>
            </ModalBody>

            <ModalErrorConnectChannel/>
            <ModalCapChannel
                isOpen={isCapLimit}
                toggle={() => { setIsCapLimit(!isCapLimit) }}
                numberLimit={project?.connection_limit}
            />
        </Modal>
        <ModalConnectPageChannel
        openModalListChannel={openModalListChannel}
        toggleModalListChannel={toggleModalListChannel}
        listChannelLoading={listChannelLoading}
        renderPlatform={renderPlatform}
        handleSelectPage={handleSelectPage}
        setOpenModalListChannel={setOpenModalListChannel}
        loading={loading}
        setLoading={setLoading}
        setIsDisable={setIsDisable}
        handleConnectChannelSelected={handleConnectChannelSelected}
        listChannel={listChannel}
        typeFacebookPlatform={typeFacebookPlatform}
        project={project}
        disable={disable}
    />
    </>
    )
}

export default ModalConnectPage;