import { createSlice } from "@reduxjs/toolkit";
import IconFacebookPage from "../images/icons/facebook-page.png";
import IconInstagram from "../images/icons/instagram.png";
import IconTiktok from "../images/icons/tiktok.png";
import { PLATFORM_FACEBOOK, PLATFORM_TIKTOK, PLATFORM_INSTAGRAM, PLATFORM_LABEL, getPlatformIcon, PLATFORM_ORIGINAL } from "../utils/Utils";
const initialState = {
  optionChannels: [],
  channels: [],
  platforms: [],
  mentions:[],
  displayContent:"",
  contentTabs: [
    {
      value: PLATFORM_ORIGINAL,
      label: PLATFORM_LABEL[PLATFORM_ORIGINAL],
      icon: getPlatformIcon(PLATFORM_ORIGINAL),
    },
  ],
  activeContentTab: PLATFORM_LABEL[PLATFORM_ORIGINAL],
  youtubeOption:{
    playlist_id: null,
    category_id:null,
    made_for_kids:null,
  },
  pinterestOption:{
    link: null,
    board:[{}]
  },
  tiktokOption:{
    visibility:"public",
    disable_comment:null,
    disable_duet:null,
    disable_stitch:null,
    thumbnail_offset:true,
  },
  contents: {
    original: "",
  },
  // contents: {
  //   original: EditorState.createEmpty() ,
  // },
  mediaItems: [],
  tags: [],
  tagsByPage: []
};
// const _addPastedContent = (editorState1, editorState2, LengthEditorOld) => {
//   const insertEditor = editorState1.getCurrentContent().getPlainText();
//   const newContent = Modifier.insertText(
//     editorState1.getCurrentContent(),
//     editorState1.getSelection(),
//     insertEditor.substring(LengthEditorOld)
//   );
//     EditorState.push(editorState1, newContent, "insert-characters")
// };
export const createPostSlice = createSlice({
  name: "createPost",
  initialState,
  reducers: {
    setOptionChannels: (state, action) => {
      state.optionChannels = action.payload;
    },
    setChannels: (state, action) => {
      state.channels = action.payload;
      state.platforms = [...new Set(action.payload.map((channel) => channel.platform))];
      state.contentTabs = [
        {
          value: PLATFORM_ORIGINAL,
          label: PLATFORM_LABEL[PLATFORM_ORIGINAL],
          icon: getPlatformIcon(PLATFORM_ORIGINAL),
        },
        ...state.platforms.map((platform) => ({
          value: platform,
          label: PLATFORM_LABEL[platform],
          icon: getPlatformIcon(platform),
        })),
      ];
      state.contents = state.platforms.reduce(
        (cur, v) => {
          cur[v] = state.contents[v] || state.contents.original;
          return cur;
        },
        { original: state.contents.original || "" }
      );
    },
    updateChannel: (state, action) => {
      const { index, data } = action.payload;
      state.channels = state.channels.map((m, i) => {
        if (i === index) {
          m = {
            ...m,
            ...data,
          };
        }
        return m;
      });
    },
    setActiveContentTab: (state, action) => {
      state.activeContentTab = action.payload;
    },
    setYoutubeOption: (state, action) => {
      state.youtubeOption = action.payload;
    },
    setPinterestOption: (state, action) => {
      state.pinterestOption= action.payload;
    },
    setTiktokOption: (state, action) => {
      state.tiktokOption = action.payload;
    },
    setContents: (state, action) => {
      const { platform, content, displayContent } = action.payload;
      state.displayContent = displayContent;
      const currentOriginalContent = state.contents.original;
      if (platform === PLATFORM_LABEL[PLATFORM_ORIGINAL]) {
        let newContents = { ...state.contents };
        for (let k in newContents) {
          let contentPlatform = newContents[k];
          contentPlatform = content + contentPlatform.substring(currentOriginalContent.length);
          newContents[k] = contentPlatform;
        }
        state.contents = newContents;
      } else {
        if (content.indexOf(currentOriginalContent) === 0) {
          state.contents = {
            ...state.contents,
            [platform]: content,
          };
        }
      }
    },

    // setContents: (state, action) => {
    //   const { platform, content } = action.payload;
    //   console.log(content);
    //   const currentOriginalContent = state.contents.original;
    //   if (platform === "original") {
    //     let newContents = { ...state.contents };
    //     for (let k in newContents) {
    //       let contentPlatform = newContents[k];
    //       contentPlatform = _addPastedContent(content,contentPlatform,currentOriginalContent)
    //       newContents[k] = contentPlatform;
    //     }
    //     state.contents = newContents;
    //   } else {
    //     if (content.indexOf(currentOriginalContent) === 0) {
    //       state.contents = {
    //         ...state.contents,
    //         [platform]: content,
    //       };
    //     }
    //   }
    // },
    appendMedia: (state, action) => {
      const mediaToAppend = action.payload;
      state.mediaItems = [...state.mediaItems, mediaToAppend];
    },
    removeMedia: (state, action) => {
      const mediaToRemoveIndex = action.payload;
      state.mediaItems = state.mediaItems.filter((_, i) => i !== mediaToRemoveIndex);
    },
    updateMedia: (state, action) => {
      const { index, data } = action.payload;
      state.mediaItems = state.mediaItems.map((m, i) => {
        if (i === index) {
          m = {
            ...m,
            ...data,
          };
        }
        return m;
      });
    },
    resetMedia: (state) => {
      state.mediaItems = [];
    },
    appendTag: (state, action) => {
      const tagToAppend = action.payload;
      state.tags = [...state.tags, tagToAppend];
    },
    removeTag: (state, action) => {
      const tagToRemove = action.payload;
      state.tags = state.tags.filter((v) => v.id !== tagToRemove.id);
    },
    resetTag: (state) => {
      state.tags = [];
    },
  },
});

export const {
  setOptionChannels,
  setChannels,
  updateChannel,
  setActiveContentTab,
  setYoutubeOption,
  setPinterestOption,
  setTiktokOption,
  setContents,
  appendMedia,
  removeMedia,
  updateMedia,
  resetMedia,
  appendOptionTags,
  appendTag,
  removeTag,
  resetTag,
} = createPostSlice.actions;

export default createPostSlice.reducer;
