import React, { useState, useEffect, useRef } from "react";
import Icon from "../icon/Icon";
import { Pagination, PaginationLink, PaginationItem } from "reactstrap";
import { usePagination, DOTS } from "./usePagination";
import { t } from "i18next";

const PaginationCustom = (props) => {
  const specificPageRef = useRef(null);
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  // if (currentPage === 0 || paginationRange.length < 2) {
  //   return null;
  // }

  const onNext = () => {
    onPageChange(currentPage + 1);
    specificPageRef.current.value = currentPage + 1;
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
    specificPageRef.current.value = currentPage - 1;
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // 👇 Get input value
      const tempPage = parseInt(e.target.value);
      if (tempPage >= 1 && tempPage <= lastPage) {
        onPageChange(tempPage);
      }
    }
  };

  let firstPage = paginationRange[0] || 1;
  let lastPage = paginationRange[paginationRange.length - 1] || 1;
  return (
    <Pagination aria-label="Page navigation example">
      <PaginationItem disabled={currentPage - 1 === 0 ? true : false}>
        <PaginationLink
          className="page-link-prev"
          onClick={(ev) => {
            ev.preventDefault();
            onPrevious();
          }}
          href="#prev"
        >
          <Icon name="chevrons-left" />
          <span>Prev</span>
        </PaginationLink>
      </PaginationItem>
      {paginationRange.map((pageNumber, index) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return (
            <PaginationItem key={index}>
              <PaginationLink
                tag="a"
                href="#pageitem"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                &#8230;
              </PaginationLink>
            </PaginationItem>
          );
        }

        return (
          <PaginationItem
            className={currentPage === pageNumber ? "active" : ""}
            key={index}
          >
            <PaginationLink
              tag="a"
              href="#pageitem"
              onClick={(ev) => {
                ev.preventDefault();
                onPageChange(pageNumber);
                specificPageRef.current.value = pageNumber;
              }}
            >
              {pageNumber}
            </PaginationLink>
          </PaginationItem>
        );
      })}

      <PaginationItem disabled={lastPage === currentPage}>
        <PaginationLink
          className="page-link-next"
          onClick={(ev) => {
            ev.preventDefault();
            onNext();
          }}
          href="#next"
        >
          <span>Next</span>
          <Icon name="chevrons-right" />
        </PaginationLink>
      </PaginationItem>
      <div className="ml-1" style={{ marginTop: "4px" }}>
        <label className="mr-1 mb-0">{t("go_to_page")}</label>
        <input
          type="number"
          className={className}
          // value={currentPage}
          ref={specificPageRef}
          defaultValue={currentPage}
          min={firstPage}
          max={lastPage}
          onKeyDown={handleKeyDown}
        />
      </div>
    </Pagination>
  );
};
export default PaginationCustom;
