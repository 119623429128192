import React, { useState } from "react";
import Comment from "./Comment";
import Task from "./Task";
import LabelSetting from "./Label";
import IconArrow from "../../../../assets/images/icon/icon-arrow.svg";
import IconTag from "../../../../assets/images/icon/icon-tag.svg";
import { RiAddLine, RiEyeLine, RiMessage3Line } from "@remixicon/react";
import {
  PreviewFaceBook,
  PreviewInstagram,
  PreviewPinterest,
  PreviewShort,
  PreviewThreads,
  PreviewYouTube,
} from "./Preview";
import {
  PLATFORM_FACEBOOK,
  PLATFORM_INSTAGRAM,
  PLATFORM_PINTEREST,
  PLATFORM_THREADS,
  PLATFORM_TIKTOK,
  PLATFORM_YOUTUBE,
} from "../../../utils/Utils";

import styles from "../index.module.scss";
import "../styles.css";
import { useTranslation } from "react-i18next";

const RightMenu = ({
  isOpenRightTab,
  setIsOpenRightTab,
  activeTab,
  commentCnt,
  listChannel,
  facebookSetting,
  youtubeData,
  instagramSetting,
  selectedImages,
  contentValue,
  caroselSetting,
  isVideo,
  pinData,
  selectedVideo,
  listLabel,
  setListLabel,
  postId,
  toggleTab,
  selectedStatus,
  isDisabledStatus,
}) => {
  const { t } = useTranslation();
  const [isLabelSetting, setIsLabelSetting] = useState(false);
  const renderPreview = () => {
    const faceChannels = listChannel?.channel.find(
      (item) => item.platform === PLATFORM_FACEBOOK && item.check === true
    );
    const instaChannels = listChannel?.channel.find(
      (item) => item.platform === PLATFORM_INSTAGRAM && item.check === true
    );
    const pinChannels = listChannel?.channel.find(
      (item) => item.platform === PLATFORM_PINTEREST && item.check === true
    );
    const youtubeChannels = listChannel?.channel.find(
      (item) => item.platform === PLATFORM_YOUTUBE && item.check === true
    );
    const tiktokChannels = listChannel?.channel.find(
      (item) => item.platform === PLATFORM_TIKTOK && item.check === true
    );
    const threadsChannels = listChannel?.channel.find(
      (item) => item.platform === PLATFORM_THREADS && item.check === true
    );

    let titlePreviewShort = "Tiktok";

    if (facebookSetting === "reel") {
      titlePreviewShort += " / Facebook Reels";
    }

    if (youtubeData.type === "short") {
      titlePreviewShort += " / Youtube Short";
    }

    if (instagramSetting === "reel") {
      titlePreviewShort += " / Insta reels";
    }

    return (
      <div>
        <p className="mb-4 text-center fs-11 text-muted fw-normal">
          {t("store_post.preview.warning")}
        </p>

        {faceChannels && (
          <PreviewFaceBook
            images={selectedImages}
            content={contentValue}
            channels={listChannel}
            carosel={caroselSetting}
            isVideo={isVideo}
            video={selectedVideo}
            selectedStatus={selectedStatus}
            isDisabledStatus={isDisabledStatus}
          />
        )}
        {instaChannels && (
          <PreviewInstagram
            images={selectedImages}
            video={selectedVideo}
            content={contentValue}
            channels={listChannel}
            isVideo={isVideo}
          />
        )}
        {pinChannels && selectedImages.length > 0 && (
          <PreviewPinterest
            images={selectedImages}
            content={pinData.title}
            channels={listChannel}
          />
        )}
        {youtubeChannels && youtubeData.type !== "short" && (
          <PreviewYouTube
            images={selectedImages}
            video={selectedVideo}
            content={youtubeData.title ?? "The title"}
            channels={listChannel}
          />
        )}

        {tiktokChannels && (
          <PreviewShort
            title={titlePreviewShort}
            isVideo={isVideo}
            video={selectedVideo}
            content={contentValue}
            images={selectedImages}
          />
        )}

        {threadsChannels && (
          <PreviewThreads
            content={contentValue}
            channels={listChannel}
            images={selectedImages}
            isVideo={isVideo}
            video={selectedVideo}
          />
        )}
      </div>
    );
  };

  const renderRightContentHeader = () => {
    switch (activeTab) {
      case "preview":
        return (
          <p className={`${styles.sideMenuHeader__text} mb-0 pt-0`}>
            {t("store_post.preview.title")}
          </p>
        );
      case "comment":
        return (
          <>
            <p className={`${styles.sideMenuHeader__text} mb-0`}>
              Comment {commentCnt ? `(${commentCnt})` : ""}
            </p>
          </>
        );
      case "task":
        return (
          <>
            <p className={`${styles.sideMenuHeader__text} mb-0`}>Task (1/8)</p>
          </>
        );
      case "label":
        return (
          <div className="d-flex align-items-center">
            <p className={`${styles.sideMenuHeader__text} mb-0`}>
              {t("store_post.label.title")}
            </p>
            {!isLabelSetting && (
              <div
                className="bg-secondary rounded ms-3 d-flex justify-content-center align-items-center cursor-pointer"
                style={{ width: "20px", height: "20px" }}
                onClick={() => setIsLabelSetting(!isLabelSetting)}
              >
                <RiAddLine
                  className="text-white"
                  size={18}
                  style={{ lineHeight: "normal" }}
                ></RiAddLine>
              </div>
            )}
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className={`boxRight ${isOpenRightTab ? "open" : ""}`}>
      <div className="flex flex-row">
        <div style={{ width: "calc(100% - 60px)", backgroundColor: "#fbfcfc" }}>
          {isOpenRightTab && (
            <div
              className={`${styles.sideMenuHeader} color-border border-b`}
              style={{ padding: 18, fontSize: 16 }}
            >
              {renderRightContentHeader()}
            </div>
          )}

          {isOpenRightTab && (
            <div
              className="p-3"
              style={{ height: "calc(100vh - 134px)", overflowY: "auto" }}
            >
              {activeTab === "preview" && renderPreview()}
              {activeTab === "comment" && postId && <Comment />}
              {activeTab === "task" && <Task />}
              {activeTab === "label" && (
                <LabelSetting
                  stype={{ width: 380 }}
                  isSetting={isLabelSetting}
                  toggleSetting={setIsLabelSetting}
                  listLabel={listLabel}
                  setListLabel={setListLabel}
                />
              )}
            </div>
          )}
        </div>
        <div className={`${styles.postContainer__rightMenu}`}>
          <div
            onClick={() => toggleTab("preview")}
            className={`${
              activeTab === "preview" && isOpenRightTab && "bg-secondary-subtle"
            } rounded d-flex align-items-center  cursor-pointer justify-content-center`}
            style={{ height: "35px", width: "35px" }}
          >
            <RiEyeLine
              size={24}
              className={`${
                activeTab === "preview" ? "text-secondary" : "text-muted"
              }`}
            />
          </div>
          {postId && (
            <div
              onClick={() => toggleTab("comment")}
              className={`${
                activeTab === "comment" &&
                isOpenRightTab &&
                "bg-secondary-subtle"
              } rounded d-flex align-items-center position-relative  cursor-pointer justify-content-center mt-3`}
              style={{ height: "35px", width: "35px" }}
            >
              <RiMessage3Line
                className={`${
                  activeTab === "comment" ? "text-secondary" : "text-muted"
                }`}
                size={24}
              />
              {commentCnt && commentCnt > 0 && (
                <p
                  className="text-center rounded-circle bg-danger fs-11 position-absolute top-0 end-0 text-white"
                  style={{ height: "16px", width: "16px" }}
                >
                  {commentCnt}
                </p>
              )}
            </div>
          )}
          <div
            onClick={() => toggleTab("label")}
            className={`${
              activeTab === "label" && isOpenRightTab && "bg-secondary-subtle"
            } rounded d-flex align-items-center cursor-pointer  justify-content-center mt-3`}
            style={{ height: "35px", width: "35px" }}
          >
            <img
              alt=""
              src={IconTag}
              className={`${
                activeTab === "label" ? "text-secondary" : "text-muted"
              }`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightMenu;
