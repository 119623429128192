import React, { useEffect, useState } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { Badge, Card, Spinner } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  RSelect,
} from "../../../../components/Component";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFirstRender } from "../../../../hooks/useFirstTimeRender";
import moment from "moment";
import { editProject, getTimeZone } from "../../../../api/project";
import { toast } from "react-toastify";

export default function Project() {
  useFirstRender()
  const userInfor = JSON.parse(localStorage.getItem('userInfor'));
  const [timeZoneList, setTimeZoneList] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const project = useSelector((state) => state.project.project);
  
 

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    // props.handleFile(fileUploaded);
  };

  const handleTimeZoneChange = (option) => {
    setSelectedOption(option);
    console.log("option",option);
  };

  const handleSaveTimeZone = (option) => {
    setLoading(true);
    let data = {
      time_zone : option?.value
    }

    editProject(userInfor?.last_project_active, data).then((res) => {
      if (res.status) {
        toast.success(t('project_update_successful'))
        setLoading(false);
      } else {
        setLoading(false);
      }
    }).catch((err) => {
      setLoading(false);
    })
  }

  const fetchListTimeZone = async () => {
    try {
      const timeRes = await getTimeZone();
      if (timeRes) {
        setTimeZoneList(formatData(timeRes));
      }
    } catch (error) {

    }
  }

  const formatData = (data) => {
    const formattedData = [];

    for (const key in data) {
      const obj = {
        value: key,
        label: data[key]
      };
      formattedData.push(obj);
    }

    return formattedData;
  }

  useEffect(() => {
    fetchListTimeZone();
  }, [])

  useEffect(() => {
    if (project?.time_zone && timeZoneList) {
        timeZoneList.map((zone)=>{
            if (zone?.value === project?.time_zone) {
              setSelectedOption(zone)
            }
        })
    } else {
      setSelectedOption({
        value: "Asia/Ho_Chi_Minh",
        label: "Vietnam (+07:00)"
    }) 
    } 
  }, [timeZoneList,project])
  return (
    <React.Fragment>
      <Head title="Project"></Head>
      {project && Object.keys(project).length > 0 && (
        <Content>
          <BlockHead size="sm">
            <BlockBetween className="g-3">
              <BlockHeadContent>
                <BlockTitle page>
                  {t('project_project')} / <strong className="text-primary small">{project.name.toUpperCase()}</strong>
                </BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <Link to={`${process.env.PUBLIC_URL}`}>
                  <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
                    <Icon name="arrow-left"></Icon>
                    <span>Back</span>
                  </Button>
                  <Button color="light" outline className="btn-icon bg-white d-inline-flex d-sm-none">
                    <Icon name="arrow-left"></Icon>
                  </Button>
                </Link>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <Row className="gy-5">
              <Col lg="5">
                <BlockHead>
                  <BlockHeadContent>
                    <BlockTitle tag="h5">{t('project_status')}</BlockTitle>
                  </BlockHeadContent>
                </BlockHead>
                <Card className="card-bordered">
                  <ul className="data-list is-compact">
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">{t('project_email')}</div>
                        <div className="data-value">{userInfor.email}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">{t('project_created_at')}</div>
                        <div className="data-value">{moment(project?.created_at).format("YYYY-MM-DD")}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">{t('status_project')}</div>
                        <div className="data-value">
                          <Badge
                            size="sm"
                            color={
                              project?.type === "Approved"
                                ? "outline-success"
                                : project?.type === "trial"
                                  ? "outline-info"
                                  : "outline-danger"
                            }
                            className="badge-dim"
                          >
                            {project?.type.toUpperCase()}
                          </Badge>
                        </div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">{t('project_expired_date')}</div>
                        <div className="data-value">{project.expire_at}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">{t('project_project_name')}</div>
                        <div className="data-value">{project?.name}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Múi giờ</div>
                        <div className="data-label">
                          {timeZoneList &&
                            <RSelect
                              options={timeZoneList}
                              value={selectedOption}
                              placeholder={"Chọn múi giờ của bạn"} 
                              onChange={handleTimeZoneChange}
                            />}
                        </div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col w-100 "
                     
                      >
                        <div className="d-flex justify-content-center align-items-center w-100 h-100">
                        <Button 
                        // onClick={handleClick} 
                        className={"justify-content-center align-items-center"}
                        color={"primary"}
                        style={{minWidth:"100px"}}
                        onClick={()=> {handleSaveTimeZone(selectedOption)}} 
                        >
                            Save
                            {loading && <Spinner  size={"sm"} className="ml-1"/>}
                          </Button>
                          </div>
                      </div>
                    </li>
                  </ul>
                </Card>
                <BlockHead>
                  <BlockHeadContent>
                    <BlockTitle tag="h5">{t('project_upload_document')}</BlockTitle>
                  </BlockHeadContent>
                </BlockHead>

                <Card className="card-bordered">
                  <ul className="data-list is-compact">
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">{t('project_business_registration_certificate')}</div>
                        <div className="data-value">
                          <Button onClick={handleClick} color={"primary"}>
                            Upload a file
                          </Button>
                          <input
                            type="file"
                            ref={hiddenFileInput}
                            onChange={handleChange}
                            style={{ display: 'none' }}
                          />
                        </div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">{t('project_business_front_id_card')}</div>
                        <div className="data-value">
                          <Button onClick={handleClick} color={"primary"}>
                            Upload a file
                          </Button>
                          <input
                            type="file"
                            ref={hiddenFileInput}
                            onChange={handleChange}
                            style={{ display: 'none' }}
                          />
                        </div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">{t('project_business_behind_id_card')}</div>
                        <div className="data-value">
                          <Button onClick={handleClick} color={"primary"}>
                            Upload a file
                          </Button>
                          <input
                            type="file"
                            ref={hiddenFileInput}
                            onChange={handleChange}
                            style={{ display: 'none' }}
                          />
                        </div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">{t('project_business_picture')}</div>
                        <div className="data-value">
                          <Button onClick={handleClick} color={"primary"}>
                            Upload a file
                          </Button>
                          <input
                            type="file"
                            ref={hiddenFileInput}
                            onChange={handleChange}
                            style={{ display: 'none' }}
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </Card>
              </Col>

              <Col lg="7">
                <BlockHead>
                  <BlockHeadContent>
                    <BlockTitle tag="h5">{t('project_information')}</BlockTitle>
                  </BlockHeadContent>
                </BlockHead>
                <Card className="card-bordered">
                  <ul className="data-list is-compact">
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">{t('project_owner_name')}</div>
                        <div className="data-value">{userInfor.name}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">{t('project_contact_email')}</div>
                        <div className="data-value">{userInfor.email}</div>
                      </div>
                    </li>
                    {/* <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">{t('project_phone_number')}</div>
                        <div className="data-value">{userInfor.phone}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">{t('project_date_of_birth')}</div>
                        <div className="data-value"></div>
                      </div>
                    </li> */}
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">{t('project_employee')}</div>
                        <div className="data-value">{project?.members.length}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">{t('project_connection')}</div>
                        <div className="data-value">{project.connection}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">{t('project_bill_information')}</div>
                        <div className="data-value"></div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">{t('project_last_activities')}</div>
                        <div className="data-value text-break"></div>
                      </div>
                    </li>
                  </ul>
                </Card>
              </Col>
            </Row>
          </Block>
        </Content>
      )
      }
    </React.Fragment>
  );
};
// export default Project;
