import React from 'react'
import { AUTO_POST, MANUAL_POST } from '../../utils/Utils';
import NoManualPost from "../../images/post/no_manual_post.png";
import NoAutoPost from "../../images/post/no_auto_post.png";
import { useTranslation } from 'react-i18next';

export default function NoPostAlert({ statusTypePost }) {
    const { t } = useTranslation();
    const manualPost = () => {
        return (
            <div className='text-center' style={{ margin: "auto", marginTop: "15vh" }}>
                <img src={NoManualPost} className='img-fluid' style={{ width: "180px" }} alt='No manual post' />
                <p className='font-weight-bold' style={{ fontSize: ".875rem", color: "#000000" }}>{t("components.no_post_alert.manual_post.line_1")}</p>
            </div>
        )
    }

    const autoPost = () => {
        return (
            <div className='text-center' style={{ maxWidth: "30rem", margin: "auto", marginTop: "15vh" }}>
                <img src={NoAutoPost} className='img-fluid' style={{ width: "180px" }} alt='No auto post' />
                <p style={{ fontSize: "1rem", color: "#000000" }} className='font-weight-bold'>{t("components.no_post_alert.auto_post.line_1")}</p>
                <p style={{ fontSize: ".875rem", color: "#000000" }}>{t("components.no_post_alert.auto_post.line_2")}</p>
                <a href='https://so9.gitbook.io/so9-help-center/dang-bai-qua-so9/lich-tu-dong' target='_blank'>{t("components.no_post_alert.auto_post.line_3")}</a>
            </div>
        )
    }

    return (
        <>
            {statusTypePost === AUTO_POST && autoPost()}
            {statusTypePost === MANUAL_POST && manualPost()}
        </>
    )
}
