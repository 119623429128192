import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import BOSS_IMG from "../Image/boss.jpg";
import { Img } from "../../../../components/Component";
import style from "../index.module.scss";
import { useTranslation } from "react-i18next";
export const ModalNotiUpcomingIns = ({
  isOpenUpComingIns,
  toggleModalUpcomingIns,
  setIsOpenUpComingIns,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        className={`${style.heightModal}`}
        style={{ maxHeight: "300px !important" }}
        isOpen={isOpenUpComingIns}
        toggle={() => {
          toggleModalUpcomingIns();
        }}
        centered
      >
        {/* <ModalHeader className="modal-title" /> */}

        <ModalBody className="text-center p-5">
          <Img className="rounded-circle" width="160px" src={BOSS_IMG} />
          {/* <i className="ri-thumb-down-line display-5 text-danger"></i> */}
          <div className="mt-4">
            <h4 className="mb-3">{t("upcoming_feature")}</h4>
            <p className="text-muted mb-4">{t("ins_upcoming_content")}</p>
            <div className="hstack gap-2 justify-content-center">
              <Button
                color="primary"
                onClick={() => setIsOpenUpComingIns(false)}
              >
                {t("TeamPopupClose")}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
