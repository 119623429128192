import en from "date-fns/locale/en-US";
import vi from "date-fns/locale/vi";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { registerLocale } from "react-datepicker";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  classifyChannelsWithUniqeDuration,
  convertToFraction,
  loadVideo,
} from "../../../libs/helper";
import DefaultPreview from "../CreatePost/DefaultPreview";
import PreviewInstagram from "./PreviewInstagram";
import PreviewYoutube from "./PreviewYoutube";
import PreviewTiktok from "./PreviewTiktok";
import icon from "../../images/icons/icon-random.svg";
import word from "../../images/icons/word-random.svg";
import THUMBNAIL_ERROR from "../../images/thumbnail_error.png"
import {
  Card,
  FormGroup,
  FormText,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Spinner,
  Tooltip
} from "reactstrap";
import SimpleBar from "simplebar-react";
import Select from 'react-select';
import {
  editPost,
  getTags,
  storePost,
  uploadImage,
} from "../../../api/createPost";
import { Icon, RSelect, Button, Img } from "../../../components/Component";
import { components } from "react-select";
import Head from "../../../layout/head/Head";
import {
  appendMedia,
  resetMedia,
  appendTag,
  removeMedia,
  removeTag,
  resetTag,
  setChannels,
  setContents,
  updateMedia,
} from "../../reducers/reelsSlice";
import {
  PLATFORM_YOUTUBE,
  PLATFORM_TIKTOK,
  PLATFORM_INSTAGRAM,
  CONTENT_STATUS_ACTIVE,
  PLATFORM_LABEL_OPTION,
  getPlatformIcon,
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_VIDEO,
  PLATFORM_FACEBOOK,
  MANUAL_POST,
  AUTO_POST,
  HEN_GIO_DANG_NGAU_NHIEN,
  PLATFORM_PINTEREST,
} from "../../utils/Utils";
import CloudMedia from "./CloudMedia";
import ContentOriginal from "./ContentOriginal";
import "./create-post.scss";
import MediaItemVideo from "./MediaItemVideo";
import ModalSuccess from "./ModalSuccess";
import PreviewFacebook from "./PreviewFacebook";
import PostMode from "../CreatePost/PostMode";
import YoutubeVideoOption from "./YoutubeVideoOption";
import TikTokVideoOption from "./TikTokVideoOption";
import { generateVideoThumbnails } from "../../utils/generateThumbnail";
import { toastError, toastWarning } from "../../../libs/toasts";
import style from "./index.module.scss";
import ss from "socket.io-stream";
import io from "socket.io-client";
import moment from "moment";
import iconVideo from "../../images/icons/post-fast-video.svg";
import iconNotVideo from "../../images/icons/post-fast-video.svg";
import iconSpin from "../../images/icons/post-fast-spin.svg";
import iconAi from "../../images/icons/post-fast-ai.svg";
import iconClose from "../../images/icons/close.svg";
import iconCoppy from "../../images/icons/icon-coppy.svg";
import { getListGroupChannels } from "../../../api/channel";
import { generateAiContent } from "../../../api/createPost";
import ModalAlertFailed from "../../components/Modal/ModalAlertFailed";
registerLocale("en", en);
registerLocale("vi", vi);
function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const PostMulti = () => {
  const positionContent = useRef(0);
  const project = useSelector((state) => state.project.project);
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const [isEditPost, setEditPost] = useState(false);
  const [isMultiMedia, setMultiMedia] = useState(false);
  const [numberChannelSuccess, setNumberChannelSuccess] = useState(0);
  const [editPostData, setEditPostData] = useState({});
  const [listMedia, setListMedia] = useState([]);
  const [listChannelsWithDuration, setListChannelsWithDuration] = useState([]);
  const [listTopic, setListTopic] = useState([]);
  const [typeChannel, setTypeChannel] = useState("channel");
  const [selectedOptionPost, setSelectedOptionPost] = useState(MANUAL_POST);
  const [modalWarning, setModalWarning] = useState(false);
  const [isAddImage, setWarningAddImage] = useState(false);
  const [openIcon, setOpenIcon] = useState(false);
  const [openSuggestText, setOpenSuggestText] = useState(false);
  const socketInstance = useRef();
  const [openAi, setOpenAi] = useState(false);
  const [loadingAi, setLoadingAi] = useState(false);
  const [aiCopyOpen, setAiCopyOpen] = useState(false);
  const [aiFormData, setAiFormData] = useState(
    {
      content: "",
      length: { label: "", value: "" },
      tone: { label: "", value: "" },
      hashtag: { label: "", value: "" },
      system: "",
    });
  const [defaultYoutubeOption, setDefaultYouTubeOption] = useState({
    title: "",
    description: "",
    visibility: "public",
  });
  const [modalFailed, setModalFailed] = useState(false);

  const handleOptionChangePost = (type) => {
    setSelectedOptionPost(type);
  };

  const toggleWarning = () => {
    setModalWarning(!modalWarning);
  };

  const [mobileView, setMobileView] = useState();
  let params = useQuery();
  const dataListChannel = useSelector(
    (state) => state.channel.channelsInformation
  );
  const lengthOption = [
    { label: "Ngắn (~100 - 150 ký tự)", value: "100 - 150" },
    { label: "Trung bình (~250 - 399 ký tự)", value: "250 - 399" },
    { label: "Dài (~500 - 550 Ký tự)", value: "500 - 550" },
  ]

  const toneOption = [
    { label: "Facebook", value: "Facebook" },
    { label: "Instagram", value: "Instagram" },
    { label: "Twitter", value: "Twitter" },
    { label: "Tiktok", value: "Tiktok" },
    { label: "Youtube", value: "Youtube" },
    { label: "Mặc định", value: "Mặc định" },
  ]

  const hashTagOption = [
    { label: "0", value: 0 },
    { label: "5", value: 5 },
    { label: "10", value: 10 },
    { label: "15", value: 15 },
    { label: "20", value: 20 },
  ]
  const listIcon = useSelector((state) => state.icon.Icons);
  /**
   * CHANNELS
   */
  useEffect(() => {
    const postData = JSON.parse(localStorage.getItem("postData"));
    if (postData) {
      setEditPostData(postData);
      localStorage.removeItem("postData");
      setEditPost(true);
      // set Content
      dispatch(
        setContents({
          platform: postData.platform,
          content: postData.post.content,
        })
      );
      // set Media
      dispatch(resetMedia());
      postData.post.media.map((item) => {
        dispatch(appendMedia(item));
      });
      // set Tag
      dispatch(resetTag());
      postData.post.tags.map((item) => {
        dispatch(appendTag(item));
      });
    }
  }, [dispatch, params]);

  useEffect(() => {
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []);
  const viewChange = () => {
    if (window.innerWidth < 960) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(async () => {
    if (!dataListChannel || dataListChannel.length === 0) {
      return;
    }
    const projectId = userInfo.last_project_active;
    const groupChannelResponse = await getListGroupChannels(projectId);
    if (groupChannelResponse.status) {
      const groupChannelData = groupChannelResponse.data.map((item, index) => {
        const { channel_ids, color, name, _id } = item;
        const listChannelItem = channel_ids.map((ids) =>
          dataListChannel.find((eleChannel) => eleChannel._id === ids)
        );
        const listChannelItemFilter = listChannelItem.filter(
          (item) => item !== undefined
        );
        return {
          label: name,
          value: _id,
          channel: listChannelItemFilter,
        };
      });
      setListTopic(groupChannelData);
    }
  }, [dataListChannel]);

  const channels = useSelector((state) => state.reels.channels);
  const onChangeChannels = useCallback(
    (e) => {
      dispatch(setChannels(e));
    },
    [dispatch]
  );
  const MultiValueLabel = (props) => {
    const { data } = props,
      imgStyle = {
        flex: "0 0 14px",
        width: "14px",
        height: "14px",
        objectFit: "cover",
      },
      spanStyle = { paddingLeft: "7px" };
    return (
      <components.MultiValueLabel {...props}>
        <Img
          src={getPlatformIcon(data.platform)}
          alt={`entity-${data.platform}`}
          style={imgStyle}
        />
        <span style={spanStyle}>{data.name}</span>
      </components.MultiValueLabel>
    );
  };
  const GroupHeading = (props) => {
    const { data } = props;
    return (
      <components.GroupHeading {...props}>
        {t(data.label)}
      </components.GroupHeading>
    );
  };
  const selectPlatformStyle = {
    multiValue: (base) => ({
      ...base,
      border: "1px solid #6A2C62",
      borderRadius: "100px !important",
      padding: "4px 11px",
      backgroundColor: "#f2f2f2 !important",
    }),
    multiValueLabel: (base) => ({
      ...base,
      display: "flex",
      alignItems: "center",
      padding: "0",
      paddingLeft: "0",
      fontSize: "12px",
      lineHeight: "1",
    }),
    multiValueRemove: (base) => ({
      ...base,
      marginLeft: "7px",
      padding: "0",
      lineHeight: "1",
    }),
  };

  /**
   * CONTENT TABS
   */

  /**
   * CONTENTS
   */
  const contents = useSelector((state) => state.reels.contents);
  const displayContent = useSelector((state) => state.reels.displayContent);
  const onChangeContent = useCallback(
    (platform, newContent, disContent) => {
      dispatch(
        setContents({
          platform,
          content: newContent,
          displayContent: disContent,
        })
      );
    },
    [dispatch]
  );

  /**
   * MEDIA
   */
  const mediaItems = useSelector((state) => state.reels.mediaItems);
  const [isUploading, setIsUploading] = useState(false);
  useEffect(() => {
    if (!mediaItems) return;
    setMultiMedia(mediaItems.length > 1);
  }, [mediaItems]);
  const onAppendMedia = useCallback(
    (newMedia) => {
      dispatch(appendMedia(newMedia));
    },
    [dispatch]
  );

  const onRemoveMedia = useCallback(
    (mediaToRemoveIndex) => {
      listMedia.splice(mediaToRemoveIndex, 1);
      setListMedia(listMedia);
      dispatch(removeMedia(mediaToRemoveIndex));
    },
    [dispatch]
  );
  const onUpdateMedia = useCallback(
    (index, data) => {
      dispatch(updateMedia({ index, data }));
    },
    [dispatch]
  );

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles[0].size > 1024000) {
      setIsStoringPost(true)
      setTimeout(() => {
        setIsStoringPost(false)
      }, 3000)
    }

    setWarningAddImage(false);
    const video = await loadVideo(acceptedFiles[0]);
    if (video.duration > 300) {
      toastWarning("Vui lòng chỉ tải lên video có thời lượng nhỏ hơn 5 phút");
      return;
    }
    if (listMedia.length === 1) {
      toastError("Vui lòng chỉ chọn 1 video để đằng lên thước phim");
      return;
    }
    const listAcceptedFiles = listMedia.concat(acceptedFiles);
    setListMedia(listAcceptedFiles);
    acceptedFiles.map(async (item, index) => {
      const isImage = item.type.split("/")[0] === "image";
      if (isImage) {
        const mediaItem = {
          type: MEDIA_TYPE_IMAGE,
          name: item.name,
          url: URL.createObjectURL(item),
          altText: item.name,
        };
        setIsUploading(() => false);
        dispatch(appendMedia(mediaItem));
      }
      const isVideo = item.type.split("/")[0] === "video";
      if (isVideo) {
        const previewImage = await generateVideoThumbnails(item, 1)
          .then(async (thumbnailArray) => {
            const blob = await fetch(thumbnailArray[0]).then((res) =>
              res.blob()
            );
            return {
              name: item.name,
              url: URL.createObjectURL(blob),
            };
          })
          .catch((err) => {
            return {
              name: item.name,
              url: 'https://i.so9.vn/images/image-default.png',
            };
          });
        const newMedia = {
          type: MEDIA_TYPE_VIDEO,
          name: item.name,
          url: item.url,
          altText: item.name,
          previewImages: previewImage,
          playbackUrl: URL.createObjectURL(item),
        };
        dispatch(appendMedia(newMedia));
      }
    });
  };


  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: ["video/mp4", "video/3gp", "video/m4v", ".mov"],
    multiple: true,
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: true,
    onDrop
  });

  const { ref: dropzoneRef, ...dropzoneProps } = getRootProps();
  const renderDropzoneOverlay = useMemo(() => {
    return (
      <Card
        className={`position-absolute w-100 h-100 align-items-center justify-content-center ${isDragActive ? "d-flex" : "d-none"
          }`}
        style={{
          background: "rgba(255, 236, 253, 0.69)",
          zIndex: "2",
        }}
      >
        <span className="fw-medium" style={{ fontSize: "24px" }}>
          {t("create_post.media_upload_from_local")}
        </span>
      </Card>
    );
  }, [t, isDragActive]);
  const renderMediaItem = (item, index) => {
    switch (item.type) {
      case MEDIA_TYPE_VIDEO:
        return (
          <MediaItemVideo
            item={item}
            channels={channels}
            setDefaultYouTubeOption={setDefaultYouTubeOption}
            defaultYoutubeOption={defaultYoutubeOption}
            isMultiMedia={isMultiMedia}
            onUpdateCaption={(caption) => onUpdateMedia(index, { caption })}
            removeMedia={() => onRemoveMedia(index)}
            update={(newData) => onUpdateMedia(index, newData)}
            key={`selectedFile-${index}`}
          />
        );
      default:
        return null;
    }
  };
  const handleAddText = () => {
    const position = positionContent.current;
    const positionMention =
      positionContent.current +
      (contents.original.slice(0, position).match(/@icon{R\d}/g)?.length * 6 ||
        0);
    //positionMention = positionMention + (contents.original.slice(0, position).match(/@text{}/g)?.length*7||0)
    const newContent =
      contents.original.slice(0, position) +
      "#text{" +
      contents.original.slice(position);
    const newdisContent =
      displayContent.slice(0, positionMention) +
      "#text{" +
      displayContent.slice(positionMention);
    dispatch(
      setContents({
        platform: "original",
        content: newContent,
        displayContent: newdisContent,
      })
    );
  };
  const handleAddIcon = (e) => {
    const position = positionContent.current;
    const positionMention =
      positionContent.current +
      (contents.original.slice(0, position).match(/@icon{R\d}/g)?.length * 6 ||
        0);
    //positionMention = positionMention + (contents.original.slice(0, position).match(/@text{}/g)?.length*7||0)
    const newContent =
      contents.original.slice(0, position) +
      "@icon{" +
      e +
      "} " +
      contents.original.slice(position);
    const newdisContent =
      displayContent.slice(0, positionMention) +
      "@[@icon{" +
      e +
      "}](" +
      e +
      ")" +
      displayContent.slice(positionMention);
    dispatch(
      setContents({
        platform: "original",
        content: newContent,
        displayContent: newdisContent,
      })
    );
  };
  const [isShowCloudMedia, setIsShowCloudMedia] = useState(false);
  const toggleIsShowCloudMedia = () => {
    setIsShowCloudMedia(!isShowCloudMedia);
  };

  /**
   * TAGS
   */
  const optionTagPage = useRef(1);
  const optionTagEnd = useRef(false);
  const [optionTags, setOptionTags] = useState([]);
  useEffect(() => {
    if (userInfo.last_project_active && !optionTagEnd.current) {
      const projectId = userInfo.last_project_active;
      const data = {
        params: {
          page: optionTagPage.current,
        },
      };
      const fetchData = async () => {
        return await getTags(projectId, data);
      };
      fetchData()
        .then((response) => {
          if (response.data && response.data.length > 0) {
            const newOptionTags = response.data.map((t) => ({
              id: t._id,
              name: t.name,
              color: t.color || "#888888",
            }));
            if (newOptionTags.length > 0) {
              setOptionTags((c) => [...c, ...newOptionTags]);
              optionTagPage.current++;
            } else {
              optionTagEnd.current = true;
            }
          }
        })
        .catch((error) => console.error(error));
    }
  }, [optionTags]);
  const tags = useSelector((state) => state.reels.tags);
  const onAppendTag = useCallback(
    (tagToAppend) => {
      dispatch(appendTag(tagToAppend));
    },
    [dispatch]
  );
  const onRemoveTag = useCallback(
    (tagToRemove) => {
      dispatch(removeTag(tagToRemove));
    },
    [dispatch]
  );

  /**
   * SAVE OPTIONS
   */
  const [scheduledAt, setScheduledAt] = useState(new Date());
  const [showScheduleSelectTime, setShowScheduleSelectTime] = useState(true);
  const [isStoringPost, setIsStoringPost] = useState(false);
  const [timeSo9Smart, setTimeSo9Smart] = useState(new Date());
  const date = new Date();
  const [timeStart, setTimeStart] = useState(date.getHours());
  const [timeFinish, setTimeFinish] = useState(24);

  const createPostText = () => {
    if (isStoringPost) {
      return <div className="spinner-border spinner-border-sm"></div>;
    }
    if (selectedOptionPost === AUTO_POST) {
      return "Tự động lên lịch";
    }
    if (showScheduleSelectTime) {
      return t("create_post.create_scheduled_post_btn");
    }
    if (isEditPost) {
      return t("create_post.save_post_btn");
    }
    return t("create_post.create_post_btn");
  };

  const videoThumbMedia = useRef([]);
  const mediaUploadedAll = useRef({});
  const mediaUploadVideoPostTotal = useRef(0);
  useEffect(() => {
    const classifyChannel = classifyChannelsWithUniqeDuration(channels);
    let newMediaUploadedAll = {};
    let newListChannelWithDuration = [];
    classifyChannel.forEach((item) => {
      if (item.channels.length > 0) {
        newMediaUploadedAll[item.duration] = [];
        newListChannelWithDuration.push(item);
      }
    });
    setListChannelsWithDuration(newListChannelWithDuration);
    mediaUploadedAll.current = newMediaUploadedAll;
  }, [channels]);

  const handleListenCompressVideo = (event) => {
    console.log({ event });
    if (event.status === false) {
      setNumberChannelSuccess(0);
      setIsUploading(() => false);
      alert("Tải video lên không thành công. Vui lòng thử lại sau ít phút");
      setIsStoringPost(false);
      videoThumbMedia.current = [];
      mediaUploadedAll.current = {};
      return;
    }

    const thumbOfVideo = videoThumbMedia.current.find(
      (item) => item.video_id === event.video_id
    );
    if (!thumbOfVideo) {
      setTimeout(() => {
        handleListenCompressVideo(event);
      }, 1000);
      return;
    }

    mediaUploadedAll.current[event.duration].push({
      type: MEDIA_TYPE_VIDEO,
      name: event.data,
      previewImages: {
        name: thumbOfVideo.name,
      },
      caption: event.caption || "",
      position: event.position,
    });
    if (mediaUploadVideoPostTotal.current === 1) {
      mediaUploadVideoPostTotal.current -= 1;
      for (const [key, value] of Object.entries(mediaUploadedAll.current)) {
        mediaUploadedAll.current[key] = value.sort(
          (a, b) => a.position - b.position
        );
      }
      // mediaUploadedAll.current = mediaUploadedAll.current.sort((a, b) => a.position - b.position)
      actionCreatingPost();
    } else {
      mediaUploadVideoPostTotal.current -= 1;
    }
  };

  const uploadMedia = async (fileMediaItem) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfor"));
    const projectId = userInfo.last_project_active;
    socketInstance.current = await io(process.env.REACT_APP_CDN_API, {
      transports: ["websocket"],
      withCredentials: true,
    });
    await socketInstance.current.on(
      `map-video-response-${userInfo.last_project_active}-${userInfo.id}`,
      (data) => {
        handleListenCompressVideo(data);
      }
    );
    mediaUploadVideoPostTotal.current =
      fileMediaItem.length * listChannelsWithDuration.length;
    listChannelsWithDuration.forEach((listChannel) => {
      return Promise.all(
        fileMediaItem.map(async (fileMedia, index) => {
          const mediaItem = mediaItems[index]; // đối sánh 2 dữ liệu như nhau
          const isVideo = fileMedia.type.split("/")[0] === "video";
          if (isVideo) {
            const video = await loadVideo(fileMedia);
            const currentTime = moment();
            const stream = ss.createStream();
            ss(socketInstance.current).emit("upload-video-v2", stream, {
              extention: "mp4",
              project_id: userInfo.last_project_active,
              user_id: userInfo.id,
              folder: "post",
              file_name: index + fileMedia.name,
              file_size: fileMedia.size,
              caption: mediaItem.caption || "",
              position: index,
              video_id:
                listChannel.duration + index + currentTime + fileMedia.name,
              is_short: fileMedia.duration == 900 ? false : true,
              duration: listChannel.duration,
              duration_current: Math.ceil(video.duration),
              width: video.videoWidth,
              height: video.videoHeight,
              ratio_current: convertToFraction(
                video.videoWidth,
                video.videoHeight
              ),
              ratio: "9:16",
            });
            ss.createBlobReadStream(fileMedia, {
              highWaterMark: 1024 * 1024 * 1.5,
            }).pipe(stream);

            await generateVideoThumbnails(fileMedia, 1)
              .then(async (thumbnailArray) => {
                const blob = await fetch(thumbnailArray[0]).then((res) =>
                  res.blob()
                );
                const data = new FormData();
                data.append("image", blob);
                data.append("projectId", projectId);
                data.append("folder", "post");
                project.type === "free"
                  ? data.append("addWatermark", true)
                  : data.append("addWatermark", false);
                const response = await uploadImage(data);
                const thumbnailInfo = response.data;
                videoThumbMedia.current.push({
                  name: thumbnailInfo.name,
                  url: thumbnailInfo.url,
                  video_id:
                    listChannel.duration + index + currentTime + fileMedia.name,
                });
              })
              .catch((err) => {
                videoThumbMedia.current.push({
                  name: "https://i.so9.vn/images/image-default.png",
                  url: "https://i.so9.vn/images/image-default.png",
                  video_id:
                    listChannel.duration + index + currentTime + fileMedia.name,
                });
              });
          }
        })
      );
    });
  };

  const getDuration = async (url) => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.muted = true;
      const source = document.createElement("source");
      source.src = url; //--> blob URL
      video.preload = "metadata";
      video.appendChild(source);
      video.onloadedmetadata = function () {
        resolve(video.duration);
      };
    });
  };
  const actionCreatingPost = async () => {
    let duration;
    if (mediaItems.find((c) => c.type === MEDIA_TYPE_VIDEO)) {
      duration = await getDuration(mediaItems[0]?.playbackUrl);
    }
    if (
      selectedOptionPost === AUTO_POST &&
      channels.find((item) => item.setting_distribute_time.length === 0 && !item?.is_setting_advance)
    ) {
      toggleWarning();
      setIsUploading(() => false);
      setIsStoringPost(false);
      return;
    }
    let statusCreatePost = listChannelsWithDuration.length;
    for (const [key, value] of Object.entries(listChannelsWithDuration)) {
      const currentMedia = mediaUploadedAll.current[value.duration];
      const channelData = [];
      value.channels.forEach((channel, key) => {
        let eachItem = {
          id: channel._id,
          content: contents[channel.platform],
          type: channel.platform,
          ...(channel.user_publish_id && {
            user_publish_id: channel.user_publish_id,
          }),
        };
        if (channel.platform === PLATFORM_YOUTUBE) {
          // youtube
          const YoutubeOption = { ...youtubeOption };
          eachItem.visibility = defaultYoutubeOption.visibility;
          if (defaultYoutubeOption.description) {
            eachItem.desc = defaultYoutubeOption.description;
          }
          Object.keys(YoutubeOption).forEach((key) => {
            if (YoutubeOption[key] === null) {
              delete YoutubeOption[key];
            }
          });
          eachItem = {
            ...eachItem,
            ...YoutubeOption,
          };
        }
        if (channel.platform === PLATFORM_TIKTOK) {
          // tiktok

          const TiktokOption = {
            ...tiktokOption,
            thumbnail_offset: tiktokOption.thumbnail_offset
              ? 0
              : parseInt(duration),
          };
          Object.keys(TiktokOption).forEach((key) => {
            if (TiktokOption[key] === null) {
              delete TiktokOption[key];
            }
          });
          eachItem = {
            ...eachItem,
            ...TiktokOption,
          };
        }
        channelData.push(eachItem);
      });
      let data = {
        channels: channelData,
        content: contents.original,
        is_short_video: true,
        images: [],
        videos: currentMedia
          .filter((item) => item.type === MEDIA_TYPE_VIDEO)
          .map((item) => ({
            name: item.name,
            thumb: item.previewImages.name,
            caption: item.caption || "",
            file_size: listMedia?.length ? listMedia[0].size.toString() : "0",
          })),
        tags: tags.map((tag) => tag.id),
        status: CONTENT_STATUS_ACTIVE,
        scheduled_at: scheduledAt
          ? Math.floor(new Date(scheduledAt).getTime() / 1000)
          : Math.floor(new Date().getTime() / 1000),

        range_time_schedule:
          selectedOptionPost === HEN_GIO_DANG_NGAU_NHIEN
            ? {
              date: moment(timeSo9Smart).format("YYYY-MM-DD"),
              range_time: `${timeStart}-${timeFinish}`,
            }
            : {},
      };
      const projectId = userInfo.last_project_active;
      setIsStoringPost(true);
      let response;
      if (selectedOptionPost === AUTO_POST) {
        data = {
          ...data,
          is_auto_scheduled_at: 1,
        };
      }
      if (isEditPost && Object.keys(editPostData).length > 0) {
        data = {
          ...data,
          _id: editPostData.post?._id,
        };
        response = await editPost(projectId, data);
      } else {
        if (selectedOptionPost === HEN_GIO_DANG_NGAU_NHIEN) {
          if (Number(timeStart) < Number(timeFinish)) {
            response = await storePost(projectId, data);
          } else {
            toggleFailed();
          }
        } else {
          response = await storePost(projectId, data);
        }
      }
      if (response.status) {
        statusCreatePost -= 1;
      }
    }
    if (statusCreatePost === 0) {
      setNumberChannelSuccess(channels.length || 0);
      toggleSuccess();
      dispatch(setChannels([]));
      onChangeContent(PLATFORM_FACEBOOK, "");
      onChangeContent("original", "");
      dispatch(resetMedia());
      setOptionTags([]);
      setListMedia([]);
      setIsUploading(() => false);
      setSelectedOptionPost(MANUAL_POST);
      videoThumbMedia.current = [];
      mediaUploadedAll.current = {};
      socketInstance.current.disconnect();
      socketInstance.current = null;
    } else {
      setNumberChannelSuccess(0);
      alert("Error");
    }
    setIsStoringPost(false);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const handleFormSubmit = async (e) => {
    if (isStoringPost || isUploading) {
      return;
    }
    if (listMedia.length === 0) {
      setWarningAddImage(true);
      return;
    }
    setIsUploading(() => true);
    await uploadMedia(listMedia);
  };

  const toggleFailed = () => {
    setModalFailed(!modalFailed);
  };

  const handleOpenAi = () => {
    const userInformation = JSON.parse(localStorage.getItem('userInfor'));
    const projectId = userInformation.last_project_active;
    if (true) {
      setOpenAi(!openAi);
    }
  }
  const handleCloseAi = () => {
    setAiFormData({
      system: "",
      content: "",
      length: { label: "", value: "" },
      tone: { label: "", value: "" },
      hashtag: { label: "", value: "" },
    })
    setOpenAi(!openAi);

  }

  const handleAiCreate = () => {
    if (aiFormData.content === "") {
      return;
    }
    setLoadingAi(true);

    const userInformation = JSON.parse(localStorage.getItem('userInfor'));
    const projectId = userInformation.last_project_active;
    let data = [];
    if (projectId) {
      let data = {
        project_id: projectId,
        messages: [
          {
            role: "user",
            content: `You are content marketer on social platform, write a ${aiFormData?.tone.value !== "" ? aiFormData?.tone.value : ""} post ${aiFormData?.length.value !== "" ? `with ${aiFormData.length.value} words ` : ""} about: "${aiFormData.content}" ${aiFormData.hashtag.value !== "" ? `, add ${aiFormData.hashtag.value} relevant hashtags` : ""}`
          }
        ]
      };
      if (aiFormData.system !== "") {
        data.messages.push({
          role: "system",
          content: aiFormData.system
        });
      }

      if (aiFormData.system !== "") {
        data.messages.push({
          role: "system",
          content: aiFormData.system
        });
      }

      generateAiContent(data)
        .then((res) => {
          if (res.status) {
            setAiFormData({ ...aiFormData, system: res.data.messages.join(" ") });
            setLoadingAi(false);
          } else {
            toastError("Có lỗi xảy ra! Vui lòng thử lại sau ít phút");
            setLoadingAi(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoadingAi(false);
          toastError("Có lỗi xảy ra! Vui lòng thử lại sau ít phút");
        });
    }
  }

  const onChangeAiForm = (e, type) => {
    switch (type) {
      case "length":
        setAiFormData({ ...aiFormData, length: e.target.value })
        break;
      case "content":
        setAiFormData({ ...aiFormData, content: e.target.value })
        break;
      case "tone":
        setAiFormData({ ...aiFormData, tone: e.target.value })
        break;
      case "hashtag":
        setAiFormData({ ...aiFormData, hashtag: e.target.value })
        break;
      default:
        break;
    }
  }

  const toggleSaveScheduled = () => {
    // if (showScheduleSelectTime) {
    //   setShowScheduleSelectTime(false);
    //   setScheduledAt(null);
    // } else {
    //   setShowScheduleSelectTime(true);
    //   setScheduledAt(new Date());
    // }
    setShowScheduleSelectTime(true);
    setScheduledAt(new Date());
    setTimeSo9Smart(new Date());
    setTimeFinish(24);
    setTimeStart(date.getHours());
  };

  const toggleSaveAndPost = () => {
    setShowScheduleSelectTime(false);
    setScheduledAt(null);
    setTimeSo9Smart(null);
    setTimeFinish(null);
    setTimeStart(null);
  };

  const handleChangeTimeStart = (timeStart) => {
    setTimeStart(timeStart.target.value);
  };

  const handleChangeTimeSmart = (date) => {
    setTimeSo9Smart(date);
  };

  const handleChangeTimeEnd = (timeEnd) => {
    setTimeFinish(timeEnd.target.value);
  };

  function unique(arr) {
    var newArr = [];
    for (var i = 0; i < arr.length; i++) {
      if (newArr.indexOf(arr[i]) === -1) {
        newArr.push(arr[i]);
      }
    }
    return newArr;
  }

  /**
   * PREVIEW
   */
  const renderPreview = (channels) => {
    const previewFacebook = channels.find(
      (c) => c.platform === PLATFORM_FACEBOOK
    );
    const previewInstagram = channels.find(
      (c) => c.platform === PLATFORM_INSTAGRAM
    );
    const previewYoutube = channels.find(
      (c) => c.platform === PLATFORM_YOUTUBE
    );
    const previewTiktok = channels.find((c) => c.platform === PLATFORM_TIKTOK);
    return (
      <>
        {previewFacebook && (
          <PreviewFacebook
            key={previewFacebook._id}
            channel={previewFacebook}
            content={contents[PLATFORM_FACEBOOK]}
            mediaItems={mediaItems}
            className="item"
          />
        )}
        {previewInstagram && (
          <PreviewInstagram
            key={previewInstagram._id}
            channel={previewInstagram}
            content={contents[PLATFORM_INSTAGRAM]}
            mediaItems={mediaItems}
            className="item"
          />
        )}
        {previewTiktok && (
          <PreviewTiktok
            key={previewTiktok._id}
            channel={previewTiktok}
            content={contents[PLATFORM_TIKTOK]}
            mediaItems={mediaItems}
            className="item"
          />
        )}
        {previewYoutube && (
          <PreviewYoutube
            key={previewYoutube._id}
            channel={previewYoutube}
            content={contents[PLATFORM_YOUTUBE]}
            mediaItems={mediaItems}
            className="item"
          />
        )}
      </>
    );
  };

  /**
   * MODAL
   */
  const [modalSuccess, setModalSuccess] = useState(false);
  const toggleSuccess = () => {
    setModalSuccess((prev) => !prev);
  };

  /**
   * TYPE CHANNEL
   */

  const handleOptionChangeTypeChannel = (event) => {
    onChangeChannels([]);
    setTypeChannel(event.target.value);
  };
  const CustomOption = ({ children, ...props }) => {
    const { data } = props,
      imgStyle = {
        //marginTop:"3px",
        marginRight: "2px",
        width: "14px",
        height: "14px",
        objectFit: "cover",
        borderRadius: "50%",
      },
      avatarStyle = {
        flex: "0 0 14px",
        width: "32px",
        height: "32px",
        objectFit: "cover",
        borderRadius: "50%",
      },
      spanStyle = {
        marginLeft: "8px",
      },
      divStyle = {
        border: "1px solid rgb(222, 225, 225)",
        padding: "8px",
        borderRadius: "6px",
        display: "flex",
      };
    return (
      <components.Option {...props}>
        <div style={divStyle}>
          <Img
            src={data.picture}
            alt={data.label}
            style={avatarStyle}
          />
          <div style={spanStyle}>
            <div className="d-flex">
              <Img
                src={getPlatformIcon(data.platform)}
                alt={`entity-${data.platform}`}
                style={imgStyle}
              />
              <span
                style={{
                  color: "rgb(39, 51, 51)",
                  fontSize: "13px",
                  fontWeight: "500",
                  lineHeight: 1,
                  fontFamily: "Roboto",
                }}
              >
                {data.name}
              </span>
            </div>
            <span
              style={{
                color: "rgb(81, 94, 95)",
                fontSize: "12px",
                fontFamily: "Roboto",
              }}
            >
              {PLATFORM_LABEL_OPTION[data.platform]}
            </span>
          </div>
        </div>
      </components.Option>
    );
  };
  const handleScheduledAt = (date) => {
    setScheduledAt(date);
  };

  const youtubeOption = useSelector((state) => state.reels.youtubeOption);
  const tiktokOption = useSelector((state) => state.reels.tiktokOption);
  const handlRemoveAiContent = (e) => {
    e.stopPropagation();
    setAiFormData({
      system: "",
      content: "",
      length: { label: "", value: "" },
      tone: { label: "", value: "" },
      hashtag: { label: "", value: "" },
    });
  }
  const toggleAi = () => {
    navigator.clipboard.writeText(aiFormData.system);
    setAiCopyOpen(true);
    setTimeout(() => setAiCopyOpen(false), 3000);
  };

  const handleSelectAiContent = () => {
    dispatch(
      setContents({
        platform: "original",
        content: aiFormData.system,
        displayContent: aiFormData.system,
      })
    );
    setAiFormData({
      content: "",
      length: { label: "", value: "" },
      tone: { label: "", value: "" },
      hashtag: { label: "", value: "" },
      system: ""
    });
    setOpenAi(false);
  }

  const renderAiAssitant = (type) => {
    if (!openAi || mobileView && type === "full") {
      return (<></>)
    } else
      if (aiFormData.system === "") {
        return (
          <div className={style.AssitantConatiner}>
            <div className={style.AssitantdArea}>
              <div className="d-flex justify-content-between" style={{ margin: "16px 16px 32px 16px" }}>
                <div className={style.aiItems} style={{ margin: "0" }} >
                  <Img
                    src={iconAi}
                    style={{ height: "24px" }}
                  />
                  <span>Trợ lý Chat GPT</span>

                </div>
                <span style={{ fontSize: "24px", lineHeight: "1", cursor: "pointer" }} onClick={handleCloseAi}>&times;</span>
              </div>
              <div className={style.assitantQuestions}>
                <p>Bạn muốn Chat GPT viết giúp bạn điều gì?</p>
                <div style={{
                  border: "1px solid #E5E7EB",
                  borderRadius: "4px",
                  overflow: "hidden"
                }}>
                  <ContentOriginal
                    value={aiFormData.content}
                    onChange={(e) => onChangeAiForm(e, "content")}
                    placeholder="Nhập nội dung tại đây"
                  />
                </div>
                {!loadingAi ?
                  (<div className="d-flex justify-content-between" style={{ marginTop: "16px", gap: "16px" }}>
                    <div className={style.aiSubInputContainer}>
                      <p>Độ dài</p>
                      <Select
                        className={style.aiSubInput}
                        classNamePrefix="react-select"
                        key="SelectBoxNumberPage"
                        value={aiFormData.length}
                        options={lengthOption}
                        placeholder=""
                        onChange={(selectedOption) => {
                          setAiFormData({ ...aiFormData, length: selectedOption });
                        }}
                        isSearchable={false}
                      />
                    </div>
                    <div className={style.aiSubInputContainer}>
                      <p>Tông giọng</p>
                      <Select
                        className={style.aiSubInput}
                        classNamePrefix="react-select"
                        key="SelectBoxNumberPage"
                        value={aiFormData.tone}
                        options={toneOption}
                        placeholder=""
                        onChange={(selectedOption) => {
                          setAiFormData({ ...aiFormData, tone: selectedOption });
                        }}
                        isSearchable={false}
                      />
                    </div>
                    <div className={style.aiSubInputContainer}>
                      <p>Hashtags</p>
                      <Select
                        className={style.aiSubInput}
                        classNamePrefix="react-select"
                        key="SelectBoxNumberPage"
                        value={aiFormData.hashtag}
                        options={hashTagOption}
                        placeholder=""
                        onChange={(selectedOption) => {
                          setAiFormData({ ...aiFormData, hashtag: selectedOption });
                        }}
                        isSearchable={false}
                      />
                    </div>
                  </div>) : (<div className={style.aiLoadingContent}>
                    <h2>Trợ lý đang tạo nội dung</h2>
                    <div className="d-flex justify-content-center align-items-center">
                      <Spinner className='mr-2'></Spinner>
                    </div>

                  </div>
                  )}
                {!loadingAi && (<div className="d-flex justify-content-end" style={{ marginTop: "24px" }}>
                  <Button onClick={handleAiCreate}
                    disabled={aiFormData.content === ""}
                    className={style.aiCreateButton}>Tạo nội dung</Button>
                </div>)}
              </div>
              <div className={style.assitantFooter}>
                <div className="d-flex" style={{ marginTop: "22px", marginLeft: "16px", marginBottom: "18px" }}>
                  <span> Các mẹo để viết câu lệnh tốt hơn.</span>
                  <a style={{ marginLeft: "4px" }}
                    target="_blank"
                    href="https://so9.vn/bai-viet/cong-thuc-tao-noi-dung-hieu-qua-hon-tu-chat-gpt/">Xem mẹo</a>
                </div>
              </div>
            </div>
          </div>
        )
      } else if (aiFormData.system !== "") {
        return (
          <div className={style.AssitantConatiner}>
            <div className={style.AssitantdArea}>
              <div className="d-flex justify-content-between" style={{ margin: "16px 16px 32px 16px" }}>
                <div className={style.aiItems} style={{ margin: "0" }} >
                  <Img
                    src={iconAi}
                    style={{ height: "24px" }}
                  />
                  <span >Trợ lý Chat GPT</span>

                </div>
                <span style={{ fontSize: "24px", lineHeight: "1", cursor: "pointer" }} onClick={handleCloseAi}>&times;</span>
              </div>
              <div className={style.assitantQuestions}>
                <p>Bạn muốn Chat GPT viết giúp bạn điều gì?</p>
                <div style={{
                  border: "1px solid #E5E7EB",
                  borderRadius: "4px",
                  overflow: "hidden"
                }}>

                  <div className={style.aiUserContentPreview}>
                    <span>{aiFormData.content}</span>
                    <Img onClick={handlRemoveAiContent} src={iconClose} height="24px" width="24px" />
                  </div>

                </div>
                {!loadingAi ?
                  (
                    <div className={style.aiSystemContentConatiner}>
                      <span>{aiFormData.system}</span>
                      <div className={style.aiSystemContentTools}>
                        <div style={{ cursor: "pointer" }}
                          className="d-flex justify-content-center align-items-center">
                          <Img onClick={toggleAi} id="aiCopy" src={iconCoppy} height="24px" width="24px" />
                          <Tooltip
                            autohide={false}
                            placement="top"
                            isOpen={aiCopyOpen}
                            target="aiCopy"
                            trigger="click"
                          >
                            copied
                          </Tooltip>
                        </div>

                        <div style={{ gap: "36px" }} className="d-flex justify-content-end">
                          <div style={{ gap: "8px", cursor: "pointer" }}
                            onClick={handleAiCreate}
                            className="d-flex justify-content-center align-items-center">
                            <Img className={style.aiPreviewSpin} src={iconSpin} height="24px" width="24px" />
                            <span style={{ fontWeight: "600", color: "#374151" }}>Tạo lại</span>
                          </div>
                          <Button onClick={handleSelectAiContent}>Chọn nội dung</Button>
                        </div>
                      </div>
                    </div>) : (<div className={style.aiLoadingContent}>
                      <h2>Trợ lý đang tạo nội dung</h2>
                      <div className="d-flex justify-content-center align-items-center">
                        <Spinner className='mr-2'></Spinner>
                      </div>

                    </div>
                  )}
              </div>
              <div className={style.assitantFooter}>
                <div className="d-flex" style={{ marginTop: "22px", marginLeft: "16px", marginBottom: "18px" }}>
                  <span> Các mẹo để viết câu lệnh tốt hơn.</span>
                  <a style={{ marginLeft: "4px" }}
                    target="_blank"
                    href="https://so9.vn/bai-viet/cong-thuc-tao-noi-dung-hieu-qua-hon-tu-chat-gpt/">Xem mẹo</a>
                </div>
              </div>
            </div>
          </div>
        )
      }

  }
  return (
    <>
      <Head title="Create A Post" />
      <div className="d-flex align-items-start">
        <div style={{ width: mobileView ? "100%" : "50%" }}>
          <form
            className="form-validate is-alter"
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <Card
              style={{ height: "100vh", overflow: "auto" }}
              className={` ${isDragActive ? "position-relative drag-active" : ""
                }`}
              innerRef={dropzoneRef}
              {...dropzoneProps}
            >
              {renderDropzoneOverlay}
              <div className="card-inner">
                <FormGroup>
                  <div className="d-flex justify-content-between">
                    <label className="form-label">
                      {t("Đăng thước phim lên")}
                    </label>
                    <div>
                      <label className="mr-2" style={{ color: "#000000" }}>
                        <input
                          name="typechannel"
                          type="radio"
                          value="channel"
                          className="mr-1"
                          checked={typeChannel === "channel"}
                          onChange={handleOptionChangeTypeChannel}
                        />
                        Kênh
                      </label>

                      <label style={{ color: "#000000" }}>
                        <input
                          name="typechannel"
                          type="radio"
                          value="topic"
                          className="mr-1"
                          checked={typeChannel === "topic"}
                          onChange={handleOptionChangeTypeChannel}
                        />
                        Nhóm kênh
                      </label>
                      {/* Selection box  */}
                    </div>
                  </div>
                  {typeChannel === "topic" && (
                    <div style={{ marginBottom: ".5rem", minWidth: "30%" }}>
                      <Controller
                        name={`topiclist`}
                        control={control}
                        render={({ field: { onBlur, onChange } }) => (
                          <RSelect
                            options={listTopic}
                            placeholder="Chọn nhóm kênh"
                            isMulti
                            onChange={(e) => {
                              let listChannelSelected = [];
                              e.map((item) => {
                                const listChannelItem =
                                  listTopic.find((i) => i.value === item.value)
                                    ?.channel || [];
                                listChannelSelected = [
                                  ...listChannelSelected,
                                  ...listChannelItem,
                                ];
                              });
                              listChannelSelected = unique(listChannelSelected);
                              setValue("channel", listChannelSelected);
                              onChangeChannels(listChannelSelected);
                            }}
                          />
                        )}
                      />
                    </div>
                  )}
                  <div className="form-control-wrap">
                    <Controller
                      name="channel"
                      control={control}
                      rules={{
                        required: isEditPost
                          ? false
                          : "This field is required.",
                      }}
                      render={({ field: { onBlur, onChange } }) => (
                        <RSelect
                          closeMenuOnSelect={false}
                          components={{
                            MultiValueLabel,
                            GroupHeading,
                            Option: CustomOption,
                          }}
                          value={channels}
                          isMulti
                          getOptionLabel={(v) => v.name}
                          getOptionValue={(v) => v._id}
                          onChange={(e) => {
                            onChange(e);
                            onChangeChannels(e);
                          }}
                          options={dataListChannel.filter((item) =>
                            [1, 2, 5, 6].includes(item.platform)
                          )}
                          styles={selectPlatformStyle}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  </div>
                  {errors?.channel && (
                    <FormText color="danger">
                      {errors.channel?.message}
                    </FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <div className="nk-reply-form" style={{ margin: 0 }}>
                    <div className="">
                      <div className="">
                        <ContentOriginal
                          onKeyUp={(e) => {
                            let position = e.target.selectionStart;
                            if (positionContent.current !== position)
                              positionContent.current = position;
                          }}
                          onClick={(e) => {
                            let position = e.target.selectionStart;
                            if (positionContent.current !== position)
                              positionContent.current = position;
                          }}
                          className="form-control"
                          value={displayContent}
                          onChange={(
                            e,
                            newValue,
                            newPlainTextValue,
                            mentions
                          ) => {
                            let position = newPlainTextValue.length;
                            if (positionContent.current !== position)
                              positionContent.current = position;
                            onChangeContent(
                              "original",
                              newPlainTextValue,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      <div className={`nk-reply-form-tools justify-start ${style.customFormTools}`}>

                        <ul className="nk-reply-form-actions g-1">
                          <div className="data-item p-0 mr-2" onClick={mediaItems.find(c => c.type === MEDIA_TYPE_VIDEO) ? null : open} style={{ borderBottom: "0" }}>
                            <span className="data-more z-0 d-flex justify-around" >
                              {
                                mediaItems.find(c => c.type === MEDIA_TYPE_VIDEO) ?
                                  <Img id="v" src={iconNotVideo} height="24px" width="24px" alt="Video" /> :
                                  <Img id="v" src={iconVideo} height="24px" width="24px" alt="Video" />
                              }
                              <UncontrolledTooltip autohide={false} placement={"top"} target={"v"}>
                                Chọn video
                              </UncontrolledTooltip>
                            </span>
                          </div>
                        </ul>
                        <div style={{ borderRight: "2px solid #E5E7EB", width: "2px", height: "24px" }}></div>
                        <UncontrolledDropdown >
                          <DropdownToggle caret
                            style={{
                              backgroundColor: "white",
                              display: "flex",
                              border: "0",
                              gap: "8px",
                              padding: "0",
                              marginLeft: "24px"
                            }}>
                            <Img
                              src={iconSpin}
                              style={{ height: "24px" }}
                            />
                            <span style={{ color: "#374151" }}>Spin</span>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem>
                              <FormGroup>
                                <div
                                  className={`align-items-stretch justify-content-start d-flex`}
                                  onClick={() => {
                                    setOpenIcon(!openIcon);
                                    if (!openIcon) setOpenSuggestText(false);
                                  }}
                                >

                                  <Img
                                    alt=""
                                    src={icon}
                                    height={"16px"}
                                  />
                                  <span style={{ marginLeft: "4px" }}> Icon ngẫu nhiên</span>

                                </div>
                              </FormGroup>
                            </DropdownItem>
                            <DropdownItem>
                              <FormGroup>
                                <div
                                  className={`align-items-stretch justify-content-start d-flex`}
                                  onClick={() => {
                                    setOpenSuggestText(!openSuggestText);
                                    if (!openSuggestText) setOpenIcon(false);
                                  }}
                                >

                                  <Img
                                    alt=""
                                    src={word}
                                    height={"16px"}
                                  />
                                  <span style={{ marginLeft: "4px" }}>Nội dung ngẫu nhiên</span>

                                </div>
                              </FormGroup>
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        <div className={style.aiAssitantBttn} onClick={handleOpenAi}>
                          <Img
                            src={iconAi}
                            style={{ height: "24px" }}
                          />
                          <span style={{ color: "#374151 !important" }}>Trợ lý Chat GPT</span>

                        </div>
                      </div>
                    </div>
                  </div>
                </FormGroup>
                {openSuggestText && (
                  <FormGroup>
                    <div
                      className={`form-control-wrap ${style.divIcon}`}
                      onClick={() => handleAddText()}
                    >
                      <span style={{ color: "#333333" }}>
                        Thêm trường giá trị ngẫu nhiên (ngăn cách bằng ký tự
                        “|”):
                      </span>
                      <br></br>
                      #text &#123; Giá trị 1|Giá trị 2|Giá trị 3|.....&#125;{" "}
                      <br></br>
                      <span style={{ color: "#4F4F4F" }}>
                        Ví dụ: Công cụ SO9 hỗ trợ{" "}
                      </span>
                      <span style={{ color: "#069697" }}>
                        #text&#123;reup đa kênh|đăng bài số lượng lớn|đăng đa
                        nền tảng|tải hàng loạt video&#125;
                      </span>
                      , giúp bạn tăng tối đa năng suất lao động{" "}
                      <span style={{ color: "#069697" }}>
                        #text&#123;;#marketing #so9|#mmo #so9&#125;
                      </span>
                      <br></br>
                      Chú ý: Nếu bạn chỉ nhập "#text&#123;&#125;" hệ thống sẽ
                      báo lỗi, phím tắt "#" = "#text&#123;"
                    </div>
                  </FormGroup>
                )}
                {openIcon &&
                  listIcon &&
                  Object.keys(listIcon)?.map((e) => (
                    <FormGroup>
                      <div
                        className={`form-control-wrap ${style.divIcon}`}
                        onClick={() => handleAddIcon(e)}
                      >
                        @icon&#123;{`${e}`}&#125; = {listIcon[e].toString()}
                      </div>
                    </FormGroup>
                  ))}
                {(mobileView && openAi) && renderAiAssitant("haft")}
                <FormGroup>
                  {mediaItems.map((f, i) => renderMediaItem(f, i))}
                  <div className="d-flex align-items-center justify-content-center">
                    <div>
                      <input {...getInputProps()} />
                    </div>
                  </div>
                  {isAddImage && (
                    <FormText color="danger">Bạn cần chọn video</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <PostMode
                    toggleSaveScheduled={toggleSaveScheduled}
                    toggleSaveAndPost={toggleSaveAndPost}
                    showScheduleSelectTime={showScheduleSelectTime}
                    handleOptionChangePost={handleOptionChangePost}
                    handleScheduledAt={handleScheduledAt}
                    scheduledAt={scheduledAt}
                    handleChangeTimeStart={handleChangeTimeStart}
                    handleChangeTimeSmart={handleChangeTimeSmart}
                    handleChangeTimeEnd={handleChangeTimeEnd}
                    timeStart={timeStart}
                    timeSo9Smart={timeSo9Smart}
                    timeFinish={timeFinish}
                    selectedOptionPost={selectedOptionPost}
                  />
                </FormGroup>
                {channels.find((c) => c.platform === PLATFORM_YOUTUBE) && (
                  <FormGroup>
                    <YoutubeVideoOption
                      channels={channels}
                      youtubeOption={youtubeOption}
                    />
                  </FormGroup>
                )}
                {channels.find((c) => c.platform === PLATFORM_TIKTOK) && (
                  <FormGroup>
                    <TikTokVideoOption tiktokOption={tiktokOption} />
                  </FormGroup>
                )}
              </div>
            </Card>
            <div className={style.btnCreatePost}>
              <FormGroup className="d-flex justify-content-end">
                <div>
                  <Button
                    className={`${style.btnUploadPostDropdown}`}
                    disabled={isUploading}
                  >
                    {isUploading ? (
                      <div
                        className="spinner-border"
                        role="status"
                        style={{
                          width: "16px",
                          height: "16px",
                        }}
                      ></div>
                    ) : (
                      <span
                        style={{ textTransform: "uppercase", color: "#fff" }}
                      >
                        {createPostText()}
                      </span>
                    )}
                  </Button>
                </div>
              </FormGroup>
            </div>
          </form>
        </div>
        {!mobileView && !openAi ? (
          <div style={{ width: "50%" }} className="align-self-stretch">
            <div className="position-relative d-flex w-100 h-100">
              <div className="position-absolute w-100 h-100">
                {isShowCloudMedia ? (
                  <CloudMedia
                    className={`h-100`}
                    mediaItems={mediaItems}
                    close={toggleIsShowCloudMedia}
                    addMedia={onAppendMedia}
                  />
                ) : (
                  <div className="previewWrapper h-100">
                    <SimpleBar style={{ height: "100%" }}>
                      <div className="mx-5 mb-4 mt-4">
                        <h2 style={{ fontSize: "16px" }}>Xem trước</h2>
                        <div className="d-flex">
                          {/* <div>
                            <Icon name="info-fill" className="mr-1"></Icon>
                          </div> */}
                          <span>
                            Xem trước gần đúng cách nội dung của bạn sẽ hiển thị
                            khi xuất bản. Qúa trình kiểm tra và cập nhật của
                            mạng xã hội có thể ảnh hưởng đến giao diện cuối
                            cùng. Báo cáo sự khác biệt mà bạn nhận thấy.
                          </span>
                        </div>
                        {channels.length > 0 ? (
                          renderPreview(channels)
                        ) : (
                          <DefaultPreview className="item" />
                        )}
                      </div>
                    </SimpleBar>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          renderAiAssitant("full")
        )}
      </div>
      <ModalSuccess
        modalSuccess={modalSuccess}
        toggleSuccess={toggleSuccess}
        numberChannel={numberChannelSuccess}
      />
      <ModalWarningSM
        modalWarning={modalWarning}
        toggleWarning={toggleWarning}
        channels={channels.filter(
          (item) => item.setting_distribute_time.length === 0 && !item?.is_setting_advance
        )}
      />
      <ModalAlertFailed modalFailed={modalFailed} toggleFailed={toggleFailed} />
    </>
  );
};
const ModalWarningSM = React.lazy(() =>
  import("../../components/Modal/ModalWarningSM")
);
export default PostMulti;
