import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Input, Spinner } from "reactstrap";
import { fetchUserInfoLogin } from "../../../../libs/helper";
import { createLabel, deleteLabel, editLabel } from "../../../../api/postNew";
import { RiCheckFill, RiDeleteBinLine, RiPencilFill } from "@remixicon/react";
import { CheckedBox } from "../../../../components/Component";
import { useTranslation } from "react-i18next";

const LabelSetting = ({
  isSetting,
  toggleSetting,
  listLabel,
  setListLabel,
}) => {
  const userInfor = fetchUserInfoLogin();

  const [selectedColor, setSelectedColor] = useState("#7FC170");
  const [labelName, setLabelName] = useState("");
  const [labelId, setLabelId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [createLoading, setCreateLoading] = useState(false);
  const { t } = useTranslation();
  const settingColor = [
    "#7FC170",
    "#78C4FA",
    "#ED6E68",
    "#F1A690",
    "#ED6A41",
    "#7E50E1",
    "#DEB15E",
    "#E9739F",
    "#4193EC",
    "#CE76E4",
    "#4D688D",
    "#377E8E",
    "#F19E38",
    "#3C4555",
    "#534C6F",
    "#8E5B26",
    "#204648",
    "#AABD3B",
    "#E73723",
    "#000000",
  ];

  const onCreateLabel = async (e) => {
    e.preventDefault();
    setCreateLoading(true);
    const data = {
      name: labelName,
      color: selectedColor,
    };
    try {
      const res = await createLabel({
        projectId: userInfor.last_project_active,
        data: data,
      });
      if (res.status) {
        toast.success(t('store_post.label.toast.create_success'));
        setCreateLoading(false);
        toggleSetting(!isSetting);
        setLabelName("");
        setSelectedColor("#7FC170");
        setListLabel([...listLabel, res.data]);
      } else {
        toast.error(t('store_post.label.toast.error_success'));
      }
    } catch (error) {
      toast.error(t('store_post.label.toast.error_success'));
    }
  };

  const toggleCheck = (id) => {
    // console.log(id);
    const newData = [...listLabel];
    newData.map((item) => {
      if (item._id === id) {
        item.check = !item.check;
      } else {
        item.check = false;
      }
      return item;
    });
    setListLabel(newData);
  };

  const handleDelete = async (e, id) => {
    e.stopPropagation();
    try {
      const res = await deleteLabel({
        projectId: userInfor.last_project_active,
        labelId: id,
      });
      if (res.status) {
        toast.success(t('store_post.label.toast.remove_success'));
        // fetchListLabel();
      } else {
        toast.error(t('store_post.label.toast.remove_error'));
      }
    } catch (error) {
      console.log(error, t('store_post.label.toast.remove_error'));
    }
  };

  const onEdit = async (id, e) => {
    e.preventDefault();
    setCreateLoading(true);
    const data = {
      name: labelName,
      color: selectedColor,
    };
    try {
      const res = await editLabel({
        projectId: userInfor.last_project_active,
        labelId: id,
        data: data,
      });
      if (res.status) {
        toast.success(t('store_post.label.toast.update_success'));
        toggleSetting(!isSetting);
        setLabelName("");
        setSelectedColor("#7FC170");
        //  fetchListLabel();
        setIsEdit(false);
        setCreateLoading(false);
      } else {
        toast.error(t('store_post.label.toast.update_error'));
        setCreateLoading(false);
      }
    } catch (error) {
      toast.error(t('store_post.label.toast.update_error'));
      setCreateLoading(false);
    }
  };

  const handleEdit = async (e, item) => {
    e.stopPropagation();
    setSelectedColor(item.color);
    setIsEdit(true);
    setLabelName(item.name);
    setLabelId(item._id);
    toggleSetting(!isSetting);
  };

  return (
    <>
      <div className="">
        {!isSetting ? (
          <div className="mt-3">
            {listLabel &&
              listLabel?.map((label) => (
                <LabelItem
                  item={label}
                  toggleCheck={toggleCheck}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                />
              ))}
          </div>
        ) : (
          <form
            className="mt-3 "
            onSubmit={
              isEdit ? (e) => onEdit(labelId, e) : (e) => onCreateLabel(e)
            }
          >
            <Input
              type="text"
              value={labelName}
              onChange={(e) => {
                setLabelName(e.target.value);
              }}
              placeholder={t('store_post.label.create.placeholder')}
              required
            ></Input>
            <div className="rounded mb-1 mt-3 bg-white py-2 px-1">
              <p className="fs-10 text-body mb-2" style={{ fontWeight: "400" }}>
                {t('store_post.label.create.color')}
              </p>
              <div className="d-flex flex-wrap gap-2 ">
                {settingColor.map((item) => (
                  <div
                    className="rounded cursor-pointer d-flex justify-content-center align-items-center"
                    onClick={() => setSelectedColor(item)}
                    style={{
                      height: "26px",
                      width: "26px",
                      backgroundColor: item,
                    }}
                  >
                    {selectedColor === item && (
                      <RiCheckFill className="text-white"></RiCheckFill>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="w-100 d-flex justify-content-end mt-3 gap-3">
              <Button
                type="button"
                color="light"
                onClick={(e) => {
                  e.preventDefault();
                  setIsEdit(false);
                  toggleSetting(!isSetting);
                }}
              >
                {t('store_post.label.create.button.cancel')}
              </Button>
              <Button type="submit">
                {isEdit ? t('store_post.label.create.button.edit') : t('store_post.label.create.button.create')}{" "}
                {createLoading && (
                  <Spinner size={"sm"} className="ms-1"></Spinner>
                )}
              </Button>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

const LabelItem = ({ item, toggleCheck, handleEdit, handleDelete }) => {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <div
      className={`px-1 py-1 justify-content-between align-items-center d-flex cursor-pointer rounded ${isHover ? "bg-dark-subtle" : ""
        }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => toggleCheck(item._id)}
      key={`label${item._id}`}
    >
      <div className="d-flex justify-content-start align-items-center text-break w-80">
        <div
          className="rounded"
          style={{ width: "24px", height: "24px", backgroundColor: item.color }}
        ></div>
        <p
          className="ms-2 mb-0 text-break text-truncate text-body fs-10"
          style={{ fontWeight: "400", width: "calc(100% - 24px)" }}
        >
          {item.name}
        </p>
      </div>
      <div className="d-flex gap-2 align-items-center">
        {isHover && (
          <>
            <RiPencilFill
              className="text-danger cursor-pointer"
              size={11}
              onClick={(e) => handleEdit(e, item)}
            ></RiPencilFill>
            <RiDeleteBinLine
              className="text-danger cursor-pointer"
              size={11}
              onClick={(e) => handleDelete(e, item._id)}
            ></RiDeleteBinLine>
          </>
        )}
        <CheckedBox checked={!!item.check} onChange={undefined} />
      </div>
    </div>
  );
};

export default LabelSetting;
