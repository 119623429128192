export const ExportGGStatus = {
  SOURCE_GOOGLE_SHEET: 1,
  SOURCE_API: 2,
  ACTIVE: 1,
  INACTIVE: 0
}

export const ObjectGGStatus = {
  OBJECT_CUSTOMER: 1,
  OBJECT_ORDER: 2,
  OBJECT_LEAD: 3,
  OBJECT_CHAT: 4,
  OBJECT_CHAT_SIMPLE: 5,
}

export const optionsDataExport = [
  {
    label: "Thông tin khách hàng",
    value: ObjectGGStatus.OBJECT_CUSTOMER
  },
  {
    label: "Thông tin đơn hàng",
    value: ObjectGGStatus.OBJECT_ORDER
  },
  {
    label: "Thông tin Lead",
    value: ObjectGGStatus.OBJECT_LEAD
  },
  {
    label: "Thông tin chat",
    value: ObjectGGStatus.OBJECT_CHAT
  },
  {
    label: "Thông tin chat (Đơn giản)",
    value: ObjectGGStatus.OBJECT_CHAT_SIMPLE
  },
];