import React, { Fragment } from "react";
import { useHistory } from "react-router";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import Logo from "../logo/Logo";
import News from "../news/News";
import User from "./dropdown/user/User";
import Notification from "../../So9/components/Notification";
import Frequency from "../frequency/Frequency";
import Timezone from "./dropdown/timezone/Timezone";

const Header = ({ fixed, theme, className, setVisibility, ...props }) => {
  const history = useHistory();
  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme === "white",
    [`is-${theme}`]: theme !== "white" && theme !== "light",
    [`${className}`]: className,
  });
  return (
    <Fragment>
      <div className={headerClass} style={{ padding: "0px" }}>
        <div className="container-fluid nk-header">
          <div className="nk-header-wrap">
            <div className="nk-menu-trigger d-xl-none ml-n1">
              <Toggle
                className="nk-nav-toggle nk-quick-nav-icon d-xl-none ml-n1"
                icon="menu"
                click={props.sidebarToggle}
              />
            </div>
            <div className="nk-header-brand d-xl-none">
              <Logo />
            </div>
            <div className="nk-header-news d-none d-xl-block">
              <News />
            </div>
            <div className="nk-header-tools">
              <ul className="nk-quick-nav">
              {/*  
              <li onClick={() => setVisibility(false)}>
                  <Timezone />
                </li>
              */}
                <li className="user-dropdown" onClick={() => setVisibility(false)}>
                  <User />
                </li>
                <li className="notification-dropdown mr-n1" onClick={() => setVisibility(false)}>
                  <Notification />
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="d-none d-xl-block" style={{ cursor: "pointer" }} onClick={() => {
          history.push("/analysis");
        }}>
          <Frequency />
        </div> */}
      </div>
    </Fragment>
  );
};
export default Header;
