import React, { useEffect, useState } from "react";
import {
  BlockDes,
  BlockTitle,
  Button,
  Col,
  Icon,
  Img
} from "../../../components/Component";
import Head from "../../../layout/head/Head";
import { Card, Container } from "reactstrap";
import bg1 from "../../images/pricelist/bg-3.png";
// import bg2 from "../../images/pricelist/bg-2.jpg";
import hr1 from "../../images/pricelist/hr-card.jpg";
import { ReactComponent as IconPen } from "../../images/pricelist/icon-hero/iconPen.svg";
import { ReactComponent as IconClock } from "../../images/pricelist/icon-hero/iconClock.svg";
// import { ReactComponent as IconI } from "../../images/pricelist/icon-hero/i.svg";
import { ReactComponent as IconCtrl } from "../../images/pricelist/icon-hero/Ctrl.svg";
import card1 from "../../images/pricelist/card/card-hero-1.png";
// import bgCard1 from "../../images/pricelist/card/bg-card-hero-1.png";
import card2 from "../../images/pricelist/card/card-hero-2.png";
// import bgCard2 from "../../images/pricelist/card/bg-card-hero-2.png";
import card3 from "../../images/pricelist/card/card-hero-3.png";
// import bgCard3 from "../../images/pricelist/card/bg-card-hero-3.png";
import card4 from "../../images/pricelist/card/card-hero-4.png";
// import bgCard4 from "../../images/pricelist/card/bg-card-hero-4.png";
import card5 from "../../images/pricelist/card/card-hero-5.png";
// import bgCard5 from "../../images/pricelist/card/bg-card-hero-5.png";
import card6 from "../../images/pricelist/card/card-hero-6.png";
// import bgCard6 from "../../images/pricelist/card/bg-card-hero-6.png";
import PricingOptionBox from "../../components/Pricing/PricingOptionBox";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setListPrice } from "../../../reducers/projectSlice";
import moment from "moment";
import FooterTrial from "../Overview/FooterTrial";
import { loadListSubscriptionV3 } from "../../../api/project";
import { getPricingData, getOptionsPricing } from "../../../api/pricing";
import "./index.css";

const images = {
  started: card1,
  growth: card2,
  pro: card3,
  business: card4,
  enterprise: card5,
  custom: card6,
  free: hr1,
};

export default function PriceList1() {
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project.project);
  const listPrice = useSelector((state) => state.project.listPrice);

  useEffect(() => {
    if (listPrice.length !== 0) return;
    loadListSubscriptionV3().then((res) => {
      dispatch(setListPrice(res));
    });
    getAllPricingData();
  }, []);

  // const formatCash = (num) => {
  //   if (num > 999 && num < 1000000) {
  //     return (num / 1000).toFixed() + "K"; // convert to K for number from > 1000 < 1 million
  //   } else if (num > 1000000) {
  //     return (num / 1000000).toFixed(3) + "K"; // convert to M for number from > 1 million
  //   } else if (num < 900) {
  //     return num; // if value < 1000, nothing to do
  //   }
  // };
  const formatNumber = (num = "", split = ",") =>
    num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, split) : "";
  const defaultPricePro = {
    id: 1,
    page: 10,
    price: 299000
  }
  const defaultPriceBus = {
    id: 1,
    page: 10,
    price: 1899000
  }
  const [pricePro, setPricePro] = useState(defaultPricePro)
  const [priceBus, setPriceBus] = useState(defaultPriceBus)
  const [priceCombo, setPriceCombo] = useState(defaultPricePro)
  const [socialData ,setSocialData] = useState([]);
  const [hubData ,setHubData] = useState([]);
  const [socialOptionData ,setSocialOptionData] = useState([]);
  const [hubOptionData ,setHubOptionData] = useState([]);
  const now = moment();
  const end = moment(project?.expired_at * 1000);
  const duration = moment.duration(end.diff(now));

  async function getAllPricingData() {

    const socialPromis = getPricingData({ type: "social" });
    const hubPromis = getPricingData({ type: "hub" });
    const hubOptionPromis = getOptionsPricing({ type: "hub" });
    const socialOptionPromis = getOptionsPricing({ type: "social" });


    const promises = [socialPromis, hubPromis, hubOptionPromis, socialOptionPromis];

    const results = await Promise.allSettled(promises);

    const responseData = promises.reduce((acc, promise, index) => {
      if (results[index].status === 'fulfilled') {
        acc[index] = results[index].value;
      } else {
        acc[index] = { error: results[index].reason };
      }
      return acc;

    }, []);

    const socialRes = responseData[0];
    const hubRes = responseData[1];
    const hubOptionRes = responseData[2];
    const socialOptionRes = responseData[3];

    results.map((res) => {
      if (res.status !== 'fulfilled') {
            setHubData(null);
            setHubOptionData(null);
            setSocialData(null);
            setSocialOptionData(null);
       
      }
    })


      setHubData(hubRes.data);
      setSocialData(socialRes.data);
      setHubOptionData(hubOptionRes.data);
      setSocialOptionData(socialOptionRes.data);

  }
  return (
    <React.Fragment>
      <Head title="Price List"></Head>
      <Container fluid className="p-0 m-0">
        <div style={{ height: "69px" }}></div>
        {/* 1 */}
        <div
          className="section1"
          style={{
            backgroundImage: `url(${bg1})`,
            backgroundColor: "#005B8E",
          }}
        >
          {/* card left */}
          <div className="section1__left">
            {/* img card */}

            <div>
              <Card className="card__container">
                <Img
                  style={{
                    width: "50%",
                    height: "auto",
                    objectFit: "cover",
                  }}
                  src={images[project?.type] || hr1}
                  alt="hr"
                />
                <div className="card__text">
                  <h5 className="card__text__name">{project?.name}</h5>
                  <p className="card__text_day">
                    {Math.round(duration.asDays()) < 0 ? 'Hết hạn' : 'Còn ' + Math.round(duration.asDays()) + ' ngày'}
                  </p>
                </div>
              </Card>
            </div>
            {/* infor card */}
            <div className="card__infor">
              <div>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#E0E0E0",
                  }}
                >
                  Tên dự án
                </p>

                <h5
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#F2F2F2",
                  }}
                >
                  {project?.name || ""}{" "}
                  <span style={{ marginLeft: "8px" }}>{<IconPen />}</span>
                </h5>
              </div>
              <div className="card__info__container">
                <div className="card__info__container__side">
                  <p className="card__info__container__text__left">
                    Số kênh kết nối:
                  </p>
                  <p className="card__info__container__text__left">
                    Thời hạn sử dụng:
                  </p>
                  <p className="card__info__container__text__left">
                    Nhân viên:
                  </p>
                </div>

                <div className="card__info__container__side">
                  <p className="card__info__container__text__right">
                    {project?.connection_limit || 0}
                  </p>
                  <p className="card__info__container__text__right">
                    {moment(project?.expired_at * 1000).format("DD.MM.YYYY") ||
                      ""}
                  </p>
                  <p className="card__info__container__text__right">
                    {project?.member_limit || 0}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* button right */}
          <div className="container__right">
            <Link
              to={
                process.env.PUBLIC_URL +
                "/admin/project/payment?type=history"
              }
            >
              <button className="button">
                <IconClock className="button__icon" />
                <span className="button__text">Lịch sử giao dịch</span>
              </button>
            </Link>
          </div>
        </div>
          
        <PricingOptionBox
          socialData={socialData}
          hubData={hubData}
          hubOptionData={hubOptionData}
          socialOptionData={socialOptionData}
        />


        <FooterTrial></FooterTrial>
      </Container>
    </React.Fragment>
  );
}