import axios from "axios";
import { API } from "../libs/client";

export const getCustomers = async (projectId, data) => {
  const uri = `/projects/${projectId}/customers/list`;
  const res = await API.get(uri, data);
  return res.data;
};

export const getCustomerById = async (projectId, id) => {
  const uri = `/projects/${projectId}/customers/${id}`;
  const res = await API.get(uri, id);
  return res.data;
};

export const createCustomer = async (projectId, data) => {
  const uri = `/projects/${projectId}/customers/create`;
  const res = await API.post(uri, data);
  return res.data;
};

export const editCustomer = async (projectId,id, data) => {
  const uri = `/projects/${projectId}/customers/${id}`;
  const res = await API.put(uri, data);
  return res.data;
};

export const getProvince = async () =>{
  const uri = `/provinces`;
  const res = await API.get(uri);
  return res.data;
}

export const getDistrict = async (provinceId) =>{
  const uri = `/provinces/${provinceId}/districts`;
  const res = await API.get(uri);
  return res.data;
}

export const getAllCustomerTag= async (projectId, data) => {
  const uri = `/projects/${projectId}/customers/tags/list`;
  const res = await API.get(uri, data);
  return res.data;
};

export const createCustomerTag= async (projectId, data) => {
  const uri = `/projects/${projectId}/customers/tags/create`;
  const res = await API.post(uri, data);
  return res.data;
};

export const createAdminNote = async (projectId, customerId, data) => {
  const uri = `/projects/${projectId}/customers/${customerId}/note`;
  const res = await API.post(uri, data);
  return res.data;
};

export const updateCustomerTag= async (projectId,id, data) => {
  const uri = `/projects/${projectId}/customers/tags/${id}`;
  const res = await API.put(uri, data);
  return res.data;
};

export const getOrdersByCustomerId= async (projectId, id, data) => {
  const uri = `/projects/${projectId}/customers/${id}/order`;
  const res = await API.get(uri, data);
  return res.data;
};

export const deleteAdminNote = async (projectId, customerId, id) => {
  const uri = `/projects/${projectId}/customers/${customerId}/note?idNote=${id}`;
  const res = await API.delete(uri);
  return res.data;
};

export const getHistoryInteract = async (projectId, id, data) => {
  const uri = `/projects/${projectId}/customers/${id}/conversations/history`;
  const res = await API.get(uri, data);
  return res.data;
};

export const createLead = async (data) => {
  const uri = `/projects/lead/create`;
  const res = await API.post(uri, data);
  return res.data;
};

export const getLead = async (data) => {
  const uri = `/projects/lead`;
  const res = await API.get(uri, data);
  return res.data;
};
