import { configureStore } from "@reduxjs/toolkit";
import { projectSlice } from "../reducers/projectSlice";
import { userSlice } from "../reducers/userSlice";
import { channelSlice } from "../So9/reducers/channelSlice";
import { dashboardSlice } from "../So9/reducers/dashboardSlice";
import { managePostSlice } from "../So9/reducers/managePostSlice";
import { iconSlice } from "../So9/reducers/iconSlice";
import { chatSlice } from "../So9/reducers/chatSlice";
import { conversationSlice } from "../So9/reducers/conversationSlice";
import { createPostSlice } from "../So9/reducers/createPostSlice";
import { reelsSlice } from "../So9/reducers/reelsSlice";
import { layoutSlice } from "../So9/reducers/layoutSlice";
import { warehouseSlice } from "../So9/reducers/warehouseSlice";
import { researchSlice } from "../So9/reducers/researchCompetitorSlice";
import { intructionSlice } from "../So9/reducers/intructionSlice";
import { guildeBookSlice } from "../So9/reducers/guildebookSlice";
import { connectSlice } from "../So9/reducers/connectChannelSlice";
import { timezoneSlice } from "../So9/reducers/timezoneSlice";
export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    createPost: createPostSlice.reducer,
    reels: reelsSlice.reducer,
    layout: layoutSlice.reducer,
    project: projectSlice.reducer,
    chat: chatSlice.reducer,
    conversation: conversationSlice.reducer,
    channel: channelSlice.reducer,
    dashboard: dashboardSlice.reducer,
    managePostTab: managePostSlice.reducer,
    icon: iconSlice.reducer,
    warehouseManage: warehouseSlice.reducer,
    research: researchSlice.reducer,
    intruction : intructionSlice.reducer,
    guilde : guildeBookSlice.reducer,
    connectModal : connectSlice.reducer,
    timezone: timezoneSlice.reducer,
  },
});
