import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import style from "./index.module.scss";
import { PaginationCustom } from "../../../components/Component";
import { Spinner } from "reactstrap"
import { getDetailByDate } from "../../../api/iris";
// const data =
// {
//   "status": true,
//   "data": {
//     "current_page": 1,
//     "data": [
//       {
//         "email": "ngockhanh.ftu50@gmail.com",
//         "phone": "0943889033",
//         "amount": 799000,
//         "name": "ngockhanh.ftu50",
//         "hour_created": "13:43",
//         "status": 1,
//         "type": "pro",
//         "connection": 100
//       }
//     ],
//     "first_page_url": "http://localhost:8000/api/v1/affiliates/dashboard/detail?page=1",
//     "from": 1,
//     "last_page": 1,
//     "last_page_url": "http://localhost:8000/api/v1/affiliates/dashboard/detail?page=1",
//     "links": [
//       {
//         "url": null,
//         "label": "&laquo; Previous",
//         "active": false
//       },
//       {
//         "url": "http://localhost:8000/api/v1/affiliates/dashboard/detail?page=1",
//         "label": "1",
//         "active": true
//       },
//       {
//         "url": null,
//         "label": "Next &raquo;",
//         "active": false
//       }
//     ],
//     "next_page_url": null,
//     "path": "http://localhost:8000/api/v1/affiliates/dashboard/detail",
//     "per_page": 10,
//     "prev_page_url": null,
//     "to": 1,
//     "total": 1
//   },
//   "message": "success"
// }

const ModalDetailByProject = ({ ...props }) => {
  const [loading, setLoading] = useState(false);
  const [currentPageProject, setCurrentPageProject] = useState(1);
  const [curentModalData, setCurentModalData] = useState();
  const { isOpen, toggle, date } = props;
  const handlePageChange = (newPage) => {
    setCurrentPageProject(newPage);
    setLoading(true);
  };

  const getCurentModalData = async () => {
    setLoading(true);
    try {
      if (date) {
        const _data = await getDetailByDate({ page: currentPageProject, fromDate: date, toDate: date, type: "project" });
        if (_data?.status) {
          setCurentModalData(_data);
          setLoading(false);
        }
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  useEffect(() => {
    getCurentModalData();
    // setCurentModalData(data);
  }, [date])

  useEffect(() => {
    getCurentModalData();
  }, [currentPageProject])
  const hidePhoneNumber = (phoneNumber) => {

    const firstThreeDigits = phoneNumber.slice(0, 3);
    const lastThreeDigits = phoneNumber.slice(-3);
    const middleDigits = phoneNumber.slice(3, -3).replace(/\d/g, 'x');
    const maskedNumber = firstThreeDigits + middleDigits + lastThreeDigits;
    return maskedNumber;
  }
  const formatCurrency = (value) => {
    return value && value.toLocaleString('vi-VN', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }) + ' đ';
  };
  return (
    <Modal size="xl" isOpen={isOpen} toggle={toggle} style={{minWidth:"90%"}}>
      <ModalBody className="m-0 px-4 py-3" style={{minHeight:"500px"}}>
        <div className="px-2 mb-4" style={{ borderLeft: "4px solid #D53784" }}>
          <h6>THỐNG KÊ DỰ ÁN ĐƯỢC TẠO TỪ LINK GIỚI THIỆU NGÀY{date}</h6>
        </div>
        <div className="table-responsive">
            <table className={`table `} style={{ minHeight: "160px" }}>
          <thead style={{ background: "#F1F5FF" }}>
            <tr>
              <th style={{ width: "12%" }} className={`${style.headPost} py-2`}>
                DỰ ÁN
              </th>
              <th
                style={{ width: "8%" }}
                className={`${style.headPost} py-2 text-right`}
              >
                Giờ tạo
              </th>
              <th
                style={{ width: "13%" }}
                className={`${style.headPost} py-2 text-right`}
              >
                Trạng thái
              </th>
              <th
                style={{ width: "15%" }}  
                className={`${style.headPost} py-2 text-right`}
              >
                Email
              </th>
              <th
                style={{ width: "15%" }}
                className={`${style.headPost} py-2 text-right`}
              >
                SĐT
              </th>
              <th
                style={{ width: "10%" }}
                className={`${style.headPost} py-2 text-right`}
              >
                Gói cước
              </th>
              <th
                style={{ width: "10%" }}
                className={`${style.headPost} py-2 text-right`}
              >
                Số Kênh
              </th>
            </tr>
          </thead>
          <tbody style={{ backgroundColor: "#fff", position: "relative" }}>
            {!loading ? (
              curentModalData ?
                (curentModalData?.data?.data?.length ?
                  (curentModalData.data.data.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        fontSize: "12px",
                        cursor: "pointer",
                        transition: "background-color 0.1s ease",
                      }}
                      className={`${style.hoveredRow}`}
                    >
                      <td className={`${style.truncateCell} ${style.Td} text-left`}>
                        {item.name}
                      </td>
                      <td
                        className={`${style.truncateCell} ${style.Td} text-right`}
                      >
                        {item.hour_created}
                      </td>
                      <td
                        className={`${style.truncateCell} ${style.Td} text-right`}
                        style={{ color: item.status ? "#219653" : "#E11D48" }}
                      >
                        {item.status ? "Hoạt động" : "Hết hạn"}
                      </td>
                      <td
                        className={`${style.truncateCell} ${style.Td} text-right`}
                      >
                        {item.email}
                      </td>
                      <td
                        className={`${style.truncateCell} ${style.Td} text-right`}
                      >
                        {item.phone}
                      </td>
                      <td
                        className={`${style.truncateCell} ${style.Td} text-right`}
                      >
                        {item.type}
                      </td>
                      <td
                        className={`${style.truncateCell} ${style.Td} text-right`}
                      >
                        {item.connection}
                      </td>
                    </tr>
                  ))) : (<>
                    <div style={{ position: "absolute", top: "30%", left: "45%" }}>
                      <p style={{ color: "#9CA3AF" }}>Chưa có dữ liệu</p>
                    </div>
                  </>)) : (<>
                    <div style={{ position: "absolute", top: "30%", left: "45%" }}>
                      <p style={{ color: "#9CA3AF" }}>Chưa có dữ liệu</p>
                    </div>
                  </>)

            ) :
              (<>
                <div style={{ position: "absolute", top: "30%", left: "45%" }}>
                  <Spinner size="32px" color="light" />
                </div>
              </>)
            }
          </tbody>
        </table>
        </div>
      
        <div  className={`${style['pgnblock']}`}>
        {
          curentModalData ?
            (curentModalData?.data?.data?.length ? (
              <div className="mobile-paginate">
              <PaginationCustom
                pageSize={curentModalData?.data?.per_page}
                totalCount={curentModalData?.data?.total || 0}
                siblingCount={1}
                onPageChange={handlePageChange}
                currentPage={currentPageProject}
                className={`manage_post_pagination`}
              />
              </div>
              
              ) : (<></>)) : (<></>)
        }
         </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalDetailByProject;
