import React from "react";
import { Col } from "reactstrap";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";
import styles from "./index.module.scss" ;
import { useTranslation } from "react-i18next";
// Import Images
import logoLight from "../../../images/socials/logo-light.png";

const AuthSlider = () => {
    const {t} = useTranslation();
    return (
        <React.Fragment>
            <Col lg={6}>
                <div className={`p-lg-5 p-4 ${styles.auth_one_bg} h-100`}>
                    <div className={`${styles.bg_overlay}`}></div>
                    <div className="position-relative h-100 d-flex flex-column">
                        <div className="mb-4">
                            <Link to="/news" className="d-block">
                                <img src={logoLight} alt="" height="32" />
                            </Link>
                        </div>
                        <div className="mt-auto">
                            <div className="mb-3">
                                {/* <i className="fa fa-quote-right display-4 text-success"></i> */}
                                <em class="icon ni ni-quote-left" style={{color: "rgba(69, 203, 133, 1)", fontSize:"50px"}}></em>
                            </div>

                            <Carousel showThumbs={false} autoPlay={true} showArrows={false} showStatus={false} infiniteLoop={true} className="slide" 
                            // id="qoutescarouselIndicators"
                            >
                                <div className={`${styles.text_carousel} carousel-inner text-center text-white-50 pb-5 `}>
                                    <div className="item">
                                        <p className="fs-15 fst-italic">"{t("new_auth.carosel1")}"</p>
                                    </div>
                                </div>
                                <div className={`${styles.text_carousel} carousel-inner text-center text-white-50 pb-5`}>
                                    <div className="item">
                                        <p className="fs-15 fst-italic">" {t("new_auth.carosel2")}"</p>
                                    </div>
                                </div>
                                <div className={`${styles.text_carousel} carousel-inner text-center text-white-50 pb-5`}>
                                    <div className="item">
                                        <p className="fs-15 fst-italic">" {t("new_auth.carosel3")} "</p>
                                    </div>
                                </div>
                            </Carousel>

                        </div>
                    </div>
                </div>
            </Col>
        </React.Fragment >
    );
};

export default AuthSlider;