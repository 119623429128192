import React, { useEffect, useState } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import styles from "./mention.module.scss";
import { fetchIconData } from '../../../../libs/helper';
import { GetlistIcon } from '../../../../api/createPost';

const MentionComponent = ({ onMentionChange, mentionValue,selectedPlatforms, ...props }) => {

  const handleInputChange = (event) => {
    onMentionChange(event.target.value);
  };

  const [iconData, setIconData] = useState();

  const getIcon = async () => {
    try {
      const iconRes = await GetlistIcon();
      if (iconRes.status) {
        const data = {
          value: iconRes.data,
          expiration: Date.now()
        };
        localStorage.setItem('IconData', JSON.stringify(data));
        setIconData(iconRes.data);
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    const storedIconData = fetchIconData();
    if (!storedIconData || storedIconData.expiration >= Date.now()) {
      getIcon();
    } else {
      setIconData(storedIconData.value)
    }
  }, [])

  const data = (iconData && Object.keys(iconData))?.map((e) => {
    return {
      id: e,
      display: `@icon{${e}}`,
    }
  }) || [];


  const text = [
    {
      id: "text{",
      display: "#text{",
    },
  ]
  const defaultStyle = {
    input: {
      border: "none",
      outline: "none",
      padding: "1rem",
      overflow: "auto!important",
    },
    control: {
      backgroundColor: '#fff',
      fontSize: 16,
      color: "red"
      // fontWeight: 'normal',
    },
    "&multiLine": {
      control: {
        fontFamily: 'Roboto',
        minHeight: 60,
      },
      highlighter: {
        minHeight: "200px",
        // marginTop: "0.75rem",
        padding: "0.65625rem 1rem 0.4375rem",
        fontSize: "0.8125rem",
        lineHeight: "1.25rem",
      },
      input: {
        minHeight: "200px",
        fontSize: "0.8125rem",
      },
    },
    '&singleLine': {
      display: 'inline-block',
      width: 180,

      highlighter: {
        padding: 1,
        border: '2px inset transparent',
      },
      input: {
        padding: 1,
        border: '2px inset',
      },
    },
    suggestions: {
      list: {
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.15)',
        fontSize: 16,
      },
      item: {
        padding: "8px 16px",
        "&focused": {
          backgroundColor: "#e8ecee",
        },
      },
    },
  };

  return (
    <MentionsInput {...props} value={mentionValue} onChange={handleInputChange} style={defaultStyle}
      classNames={{
        "form-control__control": "mt-0",
        "form-control__input": "form-control rounded-none border-0",
      }}
      maxLength={selectedPlatforms.includes(10) ? 500 : undefined}
    >
      <Mention trigger="@" markup="@icon{__id__}" data={data} displayTransform={(icon) => `@icon{${icon}} `} />
      <Mention trigger="#" markup="#__id__" data={text} regex={/$(\S+)/} />
    </MentionsInput>
  );
};

export default MentionComponent;