import React from "react";
import { Spinner } from "reactstrap"; 

function Loading({size}) {
    return ( 
    <div className="loading d-flex justify-content-center ms-3" >
        <Spinner style={{ width: size, height: size }}></Spinner>
        </div>
    

     );
}

export default Loading;