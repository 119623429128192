import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Modal, ModalBody, ModalHeader } from "reactstrap";
import style from "../index.module.scss";
import { Img } from "../../../../components/Component";

export const ModalChannelError = ({ data, setIsOpen }) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal centered size="lg" isOpen={true}>
        <ModalHeader className="modal-title" id="myLargeModalLabel">
          <div className="d-flex align-items-center">
            <div
              style={{
                width: "32px",
                height: "32px",
                background: "#F065482E",
                borderRadius: 9999,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i
                className="ri-error-warning-line"
                style={{ color: "#F06548" }}
              ></i>
            </div>

            <div style={{ paddingLeft: "20px" }}>
              {t("channel_error.header")}
            </div>
          </div>
        </ModalHeader>
        <ModalHeader>
          <h6>{t("channel_error.sub_header")}</h6>
        </ModalHeader>
        {/* <ModalHeader></ModalHeader> */}

        <ModalBody>{ChannelErrorModal(data || [])}</ModalBody>
        <div className="modal-footer" style={{ justifyContent: "center" }}>
          <Button
            className="justify-center align-middle"
            style={{ width: "150px" }}
            onClick={() => setIsOpen(false)}
            color="primary"
          >
            {t("channel_error.close")}
          </Button>
        </div>
      </Modal>
    </>
  );
};

const ChannelErrorModal = (data) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Col>
        <div className={`table-responsive table-card ${style.scrollTable}`}>
          <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
            <thead
              className="text-muted table-light"
              style={{ position: "sticky", top: "0" }}
            >
              <tr>
                <th scope="col">{t("channel_error.fanpage")}</th>
                <th scope="col">{t("channel_error.status")}</th>
                <th scope="col">{t("channel_error.last_updated")}</th>
              </tr>
            </thead>
            <tbody>
              {(data || []).map((item, key) => (
                <tr key={key}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 mr-2">
                        <Img
                          src={item.picture}
                          alt=""
                          className="avatar-xs rounded"
                          width={45}
                          height={45}
                        />
                      </div>
                      <div className="flex-grow-1">{item.name}</div>
                    </div>
                  </td>
                  <td>
                    {" "}
                    <span className="text-danger">
                      {item.is_error ? "Fail" : "Success"}
                    </span>
                  </td>
                  <td>
                    <span>{item.last_update}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default ChannelErrorModal;
