import React from "react";
import { VictoryPie } from "victory";
import style from "../index.module.scss";

const ChartCircle = ({ data }) => {
  const color = data?.map((item) => {
    return item?.color;
  });
  return (
    <>
      <div className="d-flex flex-row align-items-center">
        <div
          className={`${style.titleCard}`}
          style={{
            height: "4.5rem",
            width: "4.5rem",
            flexShrink: "0",
          }}
        >
          {data && (
            <VictoryPie
              data={data}
              colorScale={data ? color : ["#2C4B94", "#AEBFE7"]}
              style={{ labels: { display: "none" } }}
            />
          )}
        </div>
        <div className="ml-2" style={{ color: "#374151" }}>
          {data &&
            data?.map((item) => {
              const totalY = data?.reduce((acc, obj) => acc + obj.y, 0);
              return (
                <span key={item?.x}>
                  {!isNaN(((item?.y / totalY) * 100).toFixed(0)) &&
                    ((item?.y / totalY) * 100).toFixed(0)}{" "}
                  % {item?.label} <br />
                </span>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default ChartCircle;
