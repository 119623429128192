import React, { useState } from "react";
import { CONTENT_LIMIT_SEE_MORE } from "../../utils/Utils";
import style from "./index.module.scss";
import "./_preview-facebook.scss";
import { Img } from "../../../components/Component";
import {
  getPlatformIconFromLabel
} from "../../utils/Utils";
import itemHeart from "../../../images/icons/item_heart.png"
import itemComment from "../../../images/icons/item_comment.png"
import itemShare from "../../../images/icons/item_share.png"

const PreviewTiktok = ({ ...props }) => {
  const { channel, content, mediaItems, className } = props;
  const [openPreviewTikTok, setOpenPreviewTikTok] = useState(true)
  const displayContent =
    content?.length <= CONTENT_LIMIT_SEE_MORE ? (
      content
    ) : (
      <>
        {content?.slice(0, CONTENT_LIMIT_SEE_MORE)}
        <b>See more...</b>
      </>
    );
  const handleOpenPreviewTikTok = () => {
    setOpenPreviewTikTok(!openPreviewTikTok)
    const Elmchevron = document.getElementById(`chevron_previewTikTok`);
    const Elmcontent = document.getElementById("content_previewTikTok");
    if (!openPreviewTikTok) {
      Elmchevron.classList.add(`${style.openChevronDown}`)
      Elmchevron.classList.remove(`${style.openChevronUp}`)
      Elmcontent.classList.add(`${style.openContentDown}`)
      Elmcontent.classList.remove(`${style.openContentUp}`)
    }
    else {
      Elmchevron.classList.add(`${style.openChevronUp}`)
      Elmchevron.classList.remove(`${style.openChevronDown}`)
      Elmcontent.classList.add(`${style.openContentUp}`)
      Elmcontent.classList.remove(`${style.openContentDown}`)
    }
  }

  return (
    <div className={`previewFacebook ${className || ""}`}>
      <div className="d-flex align-items-stretch border-bottom">
        <div
          className="flex-fill p-2 fw-medium header"
          style={{ color: "#333333", fontFamily: "Roboto" }}
        >
          <Img
            src={getPlatformIconFromLabel("tiktok")}
            width= "16px"
            size="circle"
            style={{marginRight: "4px" }}
          />
          Tiktok
        </div>
        <div id="chevron_previewTikTok" className={`card-tools p-2 ${style.openMedia}`} onClick={handleOpenPreviewTikTok}>
          <svg width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" /> </svg>
        </div>
      </div>
      <div id="content_previewTikTok" style={{
        transition: "max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s",
        willChange: "max-height",
        position: "relative",
        overflow: "hidden"
      }}>
        {
          mediaItems.length > 0 &&
          <div className="imageWrapper">
            <div style={{
              backgroundImage: `url(${mediaItems[0]?.previewImages.url})`,
              backgroundSize: "cover", aspectRatio: "9/16"
            }}>
              <div style={{ border: "2px solid #fff", borderRadius: "50%", left: "50%", top: "50%", position: "absolute", background: 'rgba(0, 0, 0, 0.5)', padding: "10px" }}>
                <svg style={{ color: "#fff" }} width="40" height="40" fill="currentColor" class="bi bi-play" viewBox="0 0 16 16">
                  <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z" />
                </svg>
              </div>
              <div className="d-flex p-1 justify-between w-full absolute bottom-0 ">

                <div className="info">
                  <div className={style.previewTiktok__name}>@{channel.name}</div>
                  {content?.length > 0 && <div style={{ fontWeight: "500", fontFamily: "Roboto" }}>{displayContent}</div>}
                </div>
                <div className={style.previewTiktok__avarta}>
                  <Img className="avatar" src={channel.picture} alt="avatar" style={{ width: "24px", height: "24px", borderRadius: "50%" }} />
                </div>
              </div>
              <div className={style.previewTiktok__item}>
                <div className={style.item__avarta}>
                  <Img className="avatar" src={channel.picture} alt="avatar" style={{ width: "34px", height: "34px", borderRadius: "50%" }} />
                </div>
                <div className="mb-2">

                  <Img src={itemHeart} alt="like" style={{ width: "32px", height: "32px" }} />


                </div>
                <div className="mb-2">
                  <Img src={itemComment} alt="comment" style={{ width: "32px", height: "32px" }} />

                </div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-fill" viewBox="0 0 16 16">
                    <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z" />
                  </svg>
                </div>
                <div className="mb-2">

                  <Img src={itemShare} alt="share" style={{ width: "32px", height: "32px" }} />

                </div>
              </div>
            </div>
          </div>
        }

      </div>
    </div>
  );
};

export default PreviewTiktok;
