import { useState } from "react";
import { Button } from "reactstrap";
import Styles from "./index.module.scss"
import { SRLWrapper } from "simple-react-lightbox";
const AccoditionItem = ({item}) => {
    const [isOpenAcc, setIsOpenAcc] = useState(false);

    const options = {
        buttons: {
          showDownloadButton: false,
        }
    }
    return (
        <>
            <div className={` ${Styles.accoditionItem}`} key={item.id}>
                <div onClick={()=>{setIsOpenAcc(!isOpenAcc)}} className={`${Styles.accoditionHeader} cursor-pointer d-flex justify-content-between align-items-center`}>
                    <p className="mb-0 text-truncate "
                    
                    >{item?.title}</p>
                    <em class=  {!isOpenAcc ? "icon ni ni-downward-ios cursor-pointer" : "icon ni ni-upword-ios cursor-pointer" }            
                        onClick={(e) => {  
                            setIsOpenAcc(!isOpenAcc) 
                        }}
                    ></em>
                </div>
                <SRLWrapper
                    options={options}
                >
                <div className={`${Styles.accoditionContent} ${isOpenAcc ? Styles.openAccodition : Styles.closeAccodition}`}
                    dangerouslySetInnerHTML={{ __html: item.desc }}
                >
                </div>
                 </SRLWrapper>

                {isOpenAcc &&
                    (
                        <div className={`${Styles.accoditionFooter} d-flex justify-content-between align-items-center p-3`}>

                            <p className="mb-0 cursor-pointer"
                            onClick={()=>{setIsOpenAcc(false)}}
                            >
                                Đóng lại
                            </p>
                            {item.cta.title && (
                                <a href={item.cta.url} target="_blank">
                                <Button
                                    color="primary"
                                >
                                    {item.cta.title}
                                </Button>
                            </a>
                            )}
                           
                        </div>
                    )
                }

            </div>
        </>
    );
}

export default AccoditionItem;