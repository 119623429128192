import { Card, CardBody, Col, Row, Tooltip } from "reactstrap";

import { LoadingItem } from "./LoadingItem";
import DonutChart from "../Chart/DonutChart";
import WidgetsCharts from "../Chart/WidgetsCharts";
import { ChartType } from "../constants";
import { useState } from "react";
import { formatNumberAcronym } from "../../../../utils/Utils";
import { numberWithCommas } from "../../DashboardNew/utils/utils";
import "remixicon/fonts/remixicon.css";

const BlockDataCommonItem = ({
  isLoading,
  title,
  dataValue,
  hasChart,
  chartType,
  dataChart,
  tooltipLabel,
  tooltipId,
  classIcon,
  colorIcon,
  labelX = [],
}) => {
  const [tooltipOpen, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!tooltipOpen);
  };

  //   {name: "",
  //   data: data?.videos?.dataSet?.map((d: any) => d?.y),
  // },

  const data = dataChart?.map((d) => ({
    name: d?.name,
    data: d?.data?.map((i) => numberWithCommas(i)),
  }));

  return (
    <>
      <Col style={{ padding: "10px" }}>
        <Card className="h-100">
          <CardBody className="card-inner">
            <div
              className="d-flex align-items-center cursor-pointer"
              id={`about_${tooltipId}`}
            >
              <div
                style={{
                  width: "32px",
                  height: "32px",
                  background: "#F3F6F9",
                  borderRadius: 9999,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i
                  className={classIcon}
                  style={{ color: colorIcon, fontSize: "16px" }}
                ></i>
              </div>
              <h6
                className="ml-2 mb-0 fs-12 flex-shrink-1"
                style={{ fontSize: "13px" }}
              >
                {title}
              </h6>
            </div>
            {tooltipLabel && (
              <Tooltip
                placement="bottom"
                isOpen={tooltipOpen}
                target={`about_${tooltipId}`}
                toggle={toggle}
                style={{ width: "100%", textAlign: "left" }}
              >
                <div dangerouslySetInnerHTML={{ __html: tooltipLabel }}>
                  {/* {tooltipLabel} */}
                </div>
              </Tooltip>
            )}

            <Row className="align-items-end g-0">
              {isLoading ? (
                <LoadingItem />
              ) : (
                <>
                  <Col
                    xs={6}
                    style={{
                      display: "flex",
                      minHeight: "65px",
                      paddingLeft: "5px",
                      alignItems: "center",
                    }}
                  >
                    {chartType !== ChartType.PIE && (
                      <h3 className="mb-1 mt-4">
                        {formatNumberAcronym(dataValue)}
                      </h3>
                    )}

                    {hasChart && chartType === ChartType.PIE && (
                      <h6 style={{ lineHeight: "18px", fontSize: "11px" }}>
                        {dataChart?.map((d) => (
                          <div>{`${d?.x}. ${d?.label} : ${d?.y}`}</div>
                        ))}
                      </h6>
                      // <div>
                      //   {dataChart?.map((d: any) => (
                      //     <div>{`${d?.x}. ${d?.label} : ${d?.y}`}</div>
                      //   ))}
                      // </div>
                    )}
                  </Col>

                  <Col xs={6}>
                    <div className="apex-charts crypto-widget" dir="ltr">
                      {hasChart && chartType === ChartType.LINE && (
                        <WidgetsCharts
                          labelX={labelX}
                          seriesData={data}
                          chartsColor="#45CB85"
                        />
                      )}

                      {hasChart && chartType === ChartType.PIE && (
                        <DonutChart
                          dataColors='["#4b38b3", "#45CB85", "#ffbe0b", "#f3f6f9", "#fff", "#45CB85", "#ffbe0b"]'
                          dataChart={dataChart}
                        />
                      )}
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default BlockDataCommonItem;
