import React, {useEffect} from "react";
import LogoDark from "../../images/logo-so9.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle } from "../../components/Component";
import { useParams } from "react-router-dom";
import useCheckLogin from "../../hooks/useCheckLogin";
import { useState} from "react";
import {verifyChangePassword} from "../../api/auth";
import useQuery from "../../hooks/useQuery";
import {useTranslation} from "react-i18next";
import { useHistory } from "react-router";

const VerifyChangePassword = () => {
  const history = useHistory();
  useCheckLogin();
  const {t} = useTranslation();
  const {token} = useParams();
  const [verifying, setVerifying] = useState(0);

  const noti = t('text_verifying_change_password');
  const [textVerify, setTextVerify] = useState(noti);
  const query = useQuery();
  const email = query.get('email')

  useEffect(() => {
    const data = {
      email : email
    }
    verifyChangePassword(data, token).then((res) => {
      if(res?.status){
        setVerifying(1);
        setTextVerify(t('text_verified_change_password'));
        localStorage.setItem('emailToChangePassword', email);
        setTimeout(() => {
          history.replace('/change-password');
        }, 2000)
      }
      else{
        setVerifying(2);
        setTextVerify(t('text_verified_change_password_failed'));
      }
    });
  },[])

  return (
    <React.Fragment>
      <Head title="Verify" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body text-center">
          <div className="brand-logo pb-3">
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </div>
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h5">{textVerify}</BlockTitle>

              {verifying !== 2 && <BlockDes className={`text-center`}>
                <div className="spinner-border text-center text-success" style={{width: "3rem", height: "3rem"}} role="status"><span
                    className="sr-only">Loading...</span></div>
              </BlockDes>}


            </BlockContent>
          </BlockHead>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default VerifyChangePassword;
