import React, { useState, forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import iconToolTip from "../../images/icons/icon-tooltip.svg";
import { Card, FormGroup, Input, Tooltip, UncontrolledTooltip } from "reactstrap";
import { Icon, NSComponent, RSelect, Img } from "../../../components/Component";
import style from "./index.module.scss";
import { components } from "react-select";
import {
  HEN_GIO_DANG,
  DANG_NGAY,
  LICH_TU_DONG,
  MANUAL_POST,
  AUTO_POST,
  HEN_GIO_DANG_NGAU_NHIEN,
} from "../../utils/Utils";
const PostMode = ({
  handleOptionChangePost,
  toggleSaveScheduled,
  toggleSaveAndPost,
  showScheduleSelectTime,
  handleScheduledAt,
  scheduledAt,
  handleChangeTimeStart,
  handleChangeTimeEnd,
  timeStart,
  timeFinish,
  selectedOptionPost,
  handleChangeTimeSmart,
  timeSo9Smart,
}) => {
  const { i18n } = useTranslation();
  const typePost = [
    { value: DANG_NGAY, label: "Đăng ngay" },
    { value: HEN_GIO_DANG, label: "Hẹn giờ đăng" },
    { value: LICH_TU_DONG, label: "Lịch tự động So9" },
    { value: HEN_GIO_DANG_NGAU_NHIEN, label: "Hẹn giờ đăng ngẫu nhiên" },
  ];


  const [openPostMode, setOpenPostMode] = useState(true);
  const [errorMessTimeStart, setErrorMessTimeStart] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [postModeToolTip, setPostModeTooltip] = useState("Bài đăng được hẹn lịch theo thời gian bạn chọn");

  useEffect(() => {
    if (Number(timeStart) > Number(timeFinish)) {
      setErrorMessTimeStart(
        "Thời gian bắt đầu không được lớn hơn thời gian kết thúc"
      );
    } else {
      setErrorMessTimeStart("");
    }
  }, [timeStart, timeFinish]);

  const handelChevronPostMode = () => {
    setOpenPostMode(!openPostMode);
    const Elmchevron = document.getElementById("chevron_postMode");
    const Elmcontent = document.getElementById("content_postMode");
    if (!openPostMode) {
      Elmchevron.classList.add(`${style.openChevronDown}`);
      Elmchevron.classList.remove(`${style.openChevronUp}`);
      Elmcontent.classList.add(`${style.openContentDown}`);
      Elmcontent.classList.remove(`${style.openContentUp}`);
    } else {
      Elmchevron.classList.add(`${style.openChevronUp}`);
      Elmchevron.classList.remove(`${style.openChevronDown}`);
      Elmcontent.classList.add(`${style.openContentUp}`);
      Elmcontent.classList.remove(`${style.openContentDown}`);
    }
  };
  const StyleInput = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <div className={style.spanDropdown}>{children}</div>
    </components.SingleValue>
  );

  const CustomOption = ({ children, ...props }) => {
    const { data } = props,
      spanStyle = {
        fontSize: "12px",
        fontWeight: 500,
        color: "#526484",
        transition: "all 0.4s",
        lineHeight: "1.3rem",
      };
    return (
      <components.Option {...props}>
        <span style={spanStyle}>{data.label}</span>
      </components.Option>
    );
  };
  const handlePostMode = (r) => {
    if (r.value === HEN_GIO_DANG) {
      handleOptionChangePost(MANUAL_POST);
      toggleSaveScheduled();
      setPostModeTooltip("Bài đăng được hẹn lịch theo thời gian bạn chọn");

      setOpenPostMode(true);
      const Elmchevron = document.getElementById("chevron_postMode");
      const Elmcontent = document.getElementById("content_postMode");
      Elmchevron.classList.add(`${style.openChevronDown}`);
      Elmchevron.classList.remove(`${style.openChevronUp}`);
      Elmcontent.classList.add(`${style.openContentDown}`);
      Elmcontent.classList.remove(`${style.openContentUp}`);
    }
    if (r.value === DANG_NGAY) {
      handleOptionChangePost(MANUAL_POST);
      setPostModeTooltip(`Bài đăng ảnh sẽ được đẩy lên nền tảng ngay lập tức. 
      Riêng Bài đăng video hệ thống SO9 sẽ mất 5-10 phút để xử lý video trước 
      khi đăng do đó thời gian có thể bị lùi lại một chút`);
      toggleSaveAndPost();
    }
    if (r.value === LICH_TU_DONG) {
      handleOptionChangePost(AUTO_POST);
      setPostModeTooltip(`Bài đăng sẽ được hệ thống tự động đặt lịch dựa trên các cài đặt lịch tự động của bạn.`);
      toggleSaveAndPost();
    }
    if (r.value === HEN_GIO_DANG_NGAU_NHIEN) {
      handleOptionChangePost(HEN_GIO_DANG_NGAU_NHIEN);
      setPostModeTooltip(`Bài đăng được lựa chọn đăng ngẫu nhiên đăng lên trong khoảng giờ bạn chọn. 
      Phù hợp khi bạn đăng cùng 1 nội dung lên nhiều kênh, hệ thống sẽ chọn ngẫu nhiên giờ khi đăng 
      lên các kênh - Giảm tình trạng 1 nội dung đăng lên nhiều kênh cùng 1 lúc, điều này khiến nội dung dễ bị flop hơn.`);
      setOpenPostMode(true);
      toggleSaveScheduled();

      const Elmchevron = document.getElementById("chevron_postMode");
      const Elmcontent = document.getElementById("content_postMode");
      Elmchevron.classList.add(`${style.openChevronDown}`);
      Elmchevron.classList.remove(`${style.openChevronUp}`);
      Elmcontent.classList.add(`${style.openContentDown}`);
      Elmcontent.classList.remove(`${style.openContentUp}`);
    }
  };
  const CustomScheduleSelector = forwardRef(({ value, onClick }, ref) => (
    <div className="btn border-gray-400" onClick={onClick} ref={ref}>
      <Icon name="calender-date"></Icon>
      &nbsp;{value}
    </div>
  ));

  const CustomTimeScheduleSelector = forwardRef(({ value, onClick }, ref) => (
    <div className="btn border-gray-400" onClick={onClick} ref={ref}>
      {value ? value : "00:00"}
    </div>
  ));

  const CustomTimeInput = ({ value, onChange }) => (
    <input
      type="time"
      className="form-control"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  };
  return (
    <Card className="card-bordered mb-2">
      <div className="d-flex align-items-stretch border-bottom">
        <div
          className={`p-2 fw-medium ${style.postModeSubTitle}`}
          onClick={openPostMode}
        >
          Chế độ đăng:
        </div>
        <div
          className={`form-control-wrap ${style.Head__typepost} my-auto w-1/4`}
        >
          <RSelect
            options={typePost}
            components={{
              SingleValue: StyleInput,
              Option: CustomOption,
            }}
            defaultValue={typePost[1]}
            onChange={(e) => {
              handlePostMode(e);
            }}
          />
        </div>
        <div className="d-flex justify-content-center align-items-center"
          id="postModeTooltip"
          style={{ marginLeft: "10px", cursor: "pointer" }}>
          <Img src={iconToolTip} height="18px" width="18px" />
        </div>
        <Tooltip
          placement={"top"}
          isOpen={tooltipOpen}
          autohide={false}
          toggle={toggle}
          style={{ width: "100%", minWidth: "360px", textAlign: "left" }}
          target={"postModeTooltip"}
        >
          {postModeToolTip}
          {selectedOptionPost === AUTO_POST &&
            (<a style={{ marginLeft: "4px" }} href="https://www.youtube.com/watch?v=w2SplQ8OtAI" target="_blank">tại đây</a>)
          }
        </Tooltip>
      </div>
      <div
        id="content_postMode"
        style={{
          display: showScheduleSelectTime ? "block" : "none",
          transition: "max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s",
          willChange: "max-height",
          position: "relative",
        }}
      >
        <div
          style={
            openPostMode
              ? { display: "flex", padding: "16px" }
              : { display: "none" }
          }
        >
          {selectedOptionPost === MANUAL_POST ? (
            <>
              <FormGroup className="mr-2">
                <span className={style.textDate}>Ngày</span>
                <div className={style.datePicker}>
                  <DatePicker
                    selected={scheduledAt}
                    onChange={(date) => {
                      handleScheduledAt((prevDateTime) => {
                        if (prevDateTime) {
                          const newDateTime = new Date(date);
                          newDateTime.setHours(prevDateTime.getHours());
                          newDateTime.setMinutes(prevDateTime.getMinutes());
                          return newDateTime;
                        }
                        return date;
                      });
                    }}
                    customInput={<CustomScheduleSelector />}
                    customTimeInput={<CustomTimeInput />}
                    dateFormat={"eeeeee, dd/MM/yy"}
                    locale={i18n.language}
                  />
                </div>

              </FormGroup>
              <FormGroup>
                <span className={style.textDate}>Giờ</span>
                <div className={style.datePicker}>
                  <DatePicker
                    selected={scheduledAt}
                    onChange={(time) => {
                      handleScheduledAt((prevDateTime) => {
                        if (prevDateTime) {
                          const newDateTime = new Date(prevDateTime);
                          newDateTime.setHours(time.getHours());
                          newDateTime.setMinutes(time.getMinutes());
                          return newDateTime;
                        }
                        return time;
                      });
                    }}
                    customInput={<CustomTimeScheduleSelector />}
                    customTimeInput={<CustomTimeInput />}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className="form-control date-picker"
                    locale={i18n.language}
                  />
                </div>

              </FormGroup>
            </>
          ) : (
            ""
          )}
          {selectedOptionPost === HEN_GIO_DANG_NGAU_NHIEN ? (
            <>
              <FormGroup className="mr-2">
                <span className={style.textDate}>Ngày</span>
                <div className={style.datePicker}>
                  <DatePicker
                    selected={timeSo9Smart}
                    onChange={(date) => {
                      handleChangeTimeSmart((prevDateTime) => {
                        if (prevDateTime) {
                          const newDateTime = new Date(date);
                          newDateTime.setHours(prevDateTime.getHours());
                          newDateTime.setMinutes(prevDateTime.getMinutes());
                          return newDateTime;
                        }
                        return date;
                      });
                    }}
                    customInput={<CustomScheduleSelector />}
                    customTimeInput={<CustomTimeInput />}
                    dateFormat={"eeeeee, dd/MM/yy"}
                    locale={i18n.language}
                  />
                </div>

              </FormGroup>
              <FormGroup className="mr-2">
                <span className={style.textDate}>Từ</span>
                <Input
                  style={{ marginTop: "8px" }}
                  type="select"
                  name="select"
                  onChange={handleChangeTimeStart}
                  value={timeStart}
                >
                  {[...Array(24)]?.map((value, index) => (
                    <option value={index}>{index}h</option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <span className={style.textDate}>Đến</span>
                {/* <Input
                  type="number"
                  value={timeFinish}
                  min={0}
                  max={24}
                  onChange={handleChangeTimeEnd}
                /> */}
                <Input
                  style={{ marginTop: "8px" }}
                  type="select"
                  name="select"
                  onChange={handleChangeTimeEnd}
                  value={timeFinish}
                >
                  {[...Array(24)]?.map((value, index) => (
                    <option value={index}>{index}h</option>
                  ))}
                </Input>
              </FormGroup>
              <div className={style.errorMessage}>{errorMessTimeStart}</div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div
        style={{
          visibility: showScheduleSelectTime ? "visible" : "hidden",
          top: 0,
          right: 0,
        }}
        id="chevron_postMode"
        className={`card-tools position-absolute p-2 ${style.openPostMode}`}
        onClick={handelChevronPostMode}
      >
        <svg
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-chevron-down"
          viewBox="0 0 16 16"
        >
          {" "}
          <path
            fillRule="evenodd"
            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
          />{" "}
        </svg>
      </div>
    </Card>
  );
};
export default PostMode;
