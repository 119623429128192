import { API } from "../libs/client";

export const getListCampaign = async (projectId, data) => {
  const uri = `/projects/content-warehouse/campaigns?project_id=${projectId}`;
  const res = await API.get(uri, data);
  return res.data;
};

export const showCampaign = async (projectId, id, data) => {
  const uri = `/projects/content-warehouse/campaigns/${id}?project_id=${projectId}`;
  const res = await API.get(uri, data);
  return res.data;
};

export const createCampaign = async (projectId, data) => {
  const uri = `projects/content-warehouse/campaigns?project_id=${projectId}`;
  const res = await API.post(uri, data);
  return res.data;
};

export const editCampaign = async (projectId, id, data) => {
  const uri = `/projects/content-warehouse/campaigns/${id}?project_id=${projectId}`;
  const res = await API.put(uri, data);
  return res.data;
};

export const deleteCampaign = async (projectId, id) => {
  const uri = `/projects/content-warehouse/campaigns/${id}?project_id=${projectId}`;
  const res = await API.delete(uri);
  return res.data;
};
