import moment from "moment";
import React, { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  UncontrolledDropdown,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { RiChat2Line, RiGitMergeLine } from "@remixicon/react";
import { toastError, toastSuccess } from "../../../../libs/toasts";
import { findUpper } from "../../../utils/Utils";
import { deleteTask, markCompleteTask } from "../../../../api/kanban";
import { CheckedBox, Icon, UserAvatar } from "../../../../components/Component";
import ConfimDelete from "../../../components/Modal/ModalConfimDelete";
import KanbanDetail from "./KanbanDetail";
import { getRandomTheme } from "./KanbanSectionForm";
import DatePicker from "react-datepicker";

const KanbanCard = ({
  index,
  card,
  initialData,
  setInitialData,
  staffs,
  activeGroup,
  isComplete,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const { t } = useTranslation();

  const {
    _id,
    title,
    description,
    owner,
    started_at,
    ended_at,
    is_complete,
    completed_at,
    cnt_comment,
    posts,
    cnt_children_task,
    cnt_children_task_complete,
    section_id,
    owner_id,
  } = card;

  moment.locale("en");

  const [completed, setCompleted] = useState(is_complete);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [taskDetail, setTaskDetail] = useState();
  const [titleTask, setTitleTask] = useState(title);
  const [descTask, setDescTask] = useState(description);
  const [ownerTask, setOwnerTask] = useState(owner);
  const [startedTask, setStartedTask] = useState(started_at);
  const [endedTask, setEndedTask] = useState(ended_at);
  const [cntTask, setCntTask] = useState(cnt_children_task);
  const [cntTaskComplete, setCntTaskComplete] = useState(
    cnt_children_task_complete
  );
  const [cntCmt, setCntCmt] = useState(cnt_comment);
  const [modalDelete, setModalDelete] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [open, setOpen] = useState(false);
  const [taskId, setTaskId] = useState(null);

  useEffect(() => {
    const url = new URL(window.location.href);
    const taskIdParam = url.searchParams.get("task_id");
    if (taskIdParam) {
      setTaskId(taskIdParam);
    }
  }, []);

  useEffect(() => {
    if (taskId === _id) {
      handleTaskDetail(taskId);
    }
  }, [taskId]);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const toggleDelete = () => {
    setModalDelete(!modalDelete);
  };

  const handleDeleteItem = async (item) => {
    try {
      const res = await deleteTask(userInfo.last_project_active, item);
      if (res?.status) {
        setInitialData((prevData) => {
          const updatedTasks = { ...prevData.tasks };
          delete updatedTasks[item];

          const updatedColumns = { ...prevData.columns };
          for (const columnId in updatedColumns) {
            if (updatedColumns[columnId].tasks.includes(item)) {
              updatedColumns[columnId].tasks = updatedColumns[
                columnId
              ].tasks.filter((task) => task !== item);
              updatedColumns[columnId].cnt_task -= 1;
            }
          }

          return {
            ...prevData,
            tasks: updatedTasks,
            columns: updatedColumns,
          };
        });
        toastSuccess(t("task_management.toast.delete_task_success"));
      }
    } catch (error) {
      toastError(t("task_management.toast.delete_task_failed"));
      console.log(error);
    }
  };

  const isEndedBeforeToday =
    !!completed_at && endedTask
      ? moment.unix(endedTask).isBefore(moment.unix(completed_at), "day")
      : moment.unix(endedTask).isBefore(moment(), "day");

  const handleComplete = async (item) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfor"));
    const projectId = userInfo.last_project_active;

    if (!isComplete) {
      setInitialData((prevData) => {
        const updatedTasks = { ...prevData.tasks };

        if (updatedTasks[item]) {
          updatedTasks[item].is_complete = !updatedTasks[item].is_complete;
        }

        return {
          ...prevData,
          tasks: updatedTasks,
        };
      });
      setCompleted(!completed);
      const message = completed
        ? t("task_management.toast.progress_updated")
        : t("task_management.toast.completed");
      toastSuccess(message);
    } else {
      setInitialData((prevData) => {
        const updatedTasks = { ...prevData.tasks };
        delete updatedTasks[item];

        const updatedColumns = { ...prevData.columns };
        for (const columnId in updatedColumns) {
          if (updatedColumns[columnId].tasks.includes(item)) {
            updatedColumns[columnId].tasks = updatedColumns[
              columnId
            ].tasks.filter((task) => task !== item);
            updatedColumns[columnId].cnt_task -= 1;
          }
        }

        return {
          ...prevData,
          tasks: updatedTasks,
          columns: updatedColumns,
        };
      });

      const message = completed
        ? t("task_management.toast.progress_updated")
        : t("task_management.toast.completed");
      toastSuccess(message);
    }

    try {
      await markCompleteTask(projectId, _id, !completed);
    } catch (error) {
      console.error(error);
      toastError(t("task_management.toast.error"));
    }
  };

  const handleTaskDetail = (_id) => {
    setTaskDetail(_id);
    setIsOpenDetail(true);
  };

  const percentage =
    cntTaskComplete === 0 ? 0 : (cntTaskComplete / cntTask) * 100;

  const handleDelete = () => {
    handleDeleteItem(deleteItem);
    toggleDelete();
  };

  return (
    <>
      <Draggable draggableId={_id} key={_id} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="mb-2"
          >
            <div
              className="kanban-item"
              style={{ cursor: "pointer" }}
              onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                handleTaskDetail(_id);
              }}
            >
              <>
                <div
                  className="kanban-item-title mb-2 relative"
                  style={{ height: "100%" }}
                >
                  <div
                    className="d-flex items-start input-checkbox"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <h6
                      className="title-card"
                      style={{
                        width: "100%",
                        lineHeight: "1.5",
                        textDecorationLine: completed ? "line-through" : "none",
                        textDecorationStyle: completed ? "solid" : "none",
                        height: "100%",
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                    >
                      <CheckedBox
                        classNameParent="d-inline-flex mr-1"
                        width={14}
                        height={14}
                        checked={completed}
                        onChange={(ev) => {
                          ev.preventDefault();
                          ev.stopPropagation();
                          handleComplete(_id);
                        }}
                      />
                      <span style={{ position: "relative", top: "-2px" }}>
                        {titleTask?.charAt(0).toUpperCase() +
                          titleTask?.slice(1)}
                      </span>
                    </h6>
                  </div>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      tag="a"
                      href="toggle"
                      onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                      }}
                      className="dropdown-toggle btn btn-xs btn-icon btn-trigger mr-n1 action-icons absolute top-0 right-2"
                      style={{
                        border: "1px solid #ccc",
                        backgroundColor: "#fff",
                        borderRadius: "100%",
                      }}
                    >
                      <Icon name="more-v"></Icon>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <ul className="link-list-opt no-bdr">
                        <li>
                          <DropdownItem
                            tag="a"
                            disabled={userInfo.id !== owner_id ? true : false}
                            onClick={(ev) => {
                              ev.preventDefault();
                              ev.stopPropagation();
                              setModalDelete(!modalDelete);
                              setDeleteItem(_id);
                            }}
                          >
                            <Icon name="trash"></Icon>
                            <span>{t("task_management.delete_task")}</span>
                          </DropdownItem>
                        </li>
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>

                {cntTask > 0 && (
                  <div className="kanban-item-tags">
                    <div className="project-progress" style={{ width: "100%" }}>
                      <div
                        className="project-progress-task"
                        style={{ width: "100%" }}
                      >
                        <RiGitMergeLine
                          size={16}
                          className="text-muted cursor-pointer mr-1"
                        />
                        <span>
                          {cntTaskComplete || 0} / {cntTask}
                        </span>

                        <Progress
                          className="progress-md ml-1"
                          style={{ width: "30%" }}
                          color="info"
                          value={percentage.toFixed(0)}
                        ></Progress>
                      </div>
                    </div>
                  </div>
                )}

                <div className="d-flex justify-content-between items-center">
                  <div className="d-flex items-center h-100 gap-2">
                    {endedTask ? (
                      <div
                        className="d-flex items-center h-100"
                        style={{
                          color: completed_at
                            ? !isEndedBeforeToday
                              ? "#229E4D"
                              : "#E85351"
                            : "#90959D",
                        }}
                      >
                        <Icon
                          name="calendar"
                          style={{ fontSize: "12px" }}
                          className="mr-1"
                        ></Icon>

                        <span>
                          Due: {moment.unix(endedTask).format("MMM D")}
                        </span>
                      </div>
                    ) : (
                      <div
                        className="d-flex items-center h-100 action-icons"
                        style={{ color: "#90959D" }}
                      >
                        <Icon
                          name="calendar"
                          style={{ fontSize: "12px" }}
                          className="mr-1"
                        ></Icon>
                      </div>
                    )}
                    {cntCmt > 0 ? (
                      <div className="d-flex items-center h-100">
                        <RiChat2Line
                          size={12}
                          className="text-muted cursor-pointer"
                        />
                        <span className="ml-1" style={{ color: "#90959D" }}>
                          {cntCmt}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <Dropdown isOpen={open} toggle={toggleOpen}>
                    <DropdownToggle
                      tag="a"
                      href="#toggle"
                      className="dropdown-toggle"
                      onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                      }}
                    >
                      <div className="user-avatar-group">
                        <UserAvatar
                          className="xs"
                          image={ownerTask?.avatar}
                          text={findUpper(ownerTask?.name || ownerTask?.label)}
                          // theme={getRandomTheme()}
                        ></UserAvatar>
                      </div>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <ul className="link-list-opt no-bdr p-3 g-2">
                        <li>
                          <div className="user-card" onClick={toggleOpen}>
                            <UserAvatar
                              className="sm"
                              image={ownerTask?.avatar}
                              text={findUpper(
                                ownerTask?.name || ownerTask?.label
                              )}
                              // theme={getRandomTheme()}
                            ></UserAvatar>
                            <div className="user-name">
                              <span className="tb-lead">
                                {ownerTask?.name || ownerTask?.label}
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </>
            </div>
          </div>
        )}
      </Draggable>

      {isOpenDetail && (
        <KanbanDetail
          isOpenDetail={isOpenDetail}
          initialData={initialData}
          setInitialData={setInitialData}
          setIsOpenDetail={setIsOpenDetail}
          card={taskDetail}
          staffs={staffs}
          completed={completed}
          handleDeleteItem={handleDeleteItem}
          handleComplete={handleComplete}
          activeGroup={activeGroup}
          handleTaskDetail={handleTaskDetail}
          setTitleTask={setTitleTask}
          setDescTask={setDescTask}
          setEndedTask={setEndedTask}
          setStartedTask={setStartedTask}
          startedTask={startedTask}
          endedTask={endedTask}
          setOwnerTask={setOwnerTask}
          setCntTaskComplete={setCntTaskComplete}
          setCntTask={setCntTask}
          setCntCmt={setCntCmt}
          isEndedBeforeToday={isEndedBeforeToday}
        />
      )}

      <ConfimDelete
        modalDelete={modalDelete}
        toggleDelete={toggleDelete}
        messeage={""}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default KanbanCard;
