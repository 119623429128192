
export function IconErrorConnectChannel() {
  return ;
}

export function IconArrowContinue(){
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5837_10024)">
      <path d="M12 13H4V11H12V4L20 12L12 20V13Z" fill="#2F80ED"/>
    </g>
    <defs>
      <clipPath id="clip0_5837_10024">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
}