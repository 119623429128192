import React, { useState } from 'react'
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import LogoTrial from "../../images/icons/Logo-trial.png"
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Row,
  Col,
  Button,
  Img
} from "../../../components/Component";
import Rectangle from "../../images/icons/Rectangle.svg"
import Rectangle_purple from "../../images/icons/Rectangle_purple.svg"
import Rectangle_purple_long from "../../images/icons/Rectangle_purple_long.svg"
import Rectangle_green from "../../images/icons/Rectangle_green.svg"
import Rectangle_Blue from "../../images/icons/Rectangle_Blue.svg"
import Rectangle_Yellow from "../../images/icons/Rectangle_Yellow.svg"
import iconAnalysis from "../../images/icons/icon_analysis.svg"
import iconCaculation from "../../images/icons/icon_caculation.svg"
import iconClock from "../../images/icons/icon_clock.svg"
import iconCommunicate from "../../images/icons/icon_communicate_phone.svg"
import iconCustumer360 from "../../images/icons/icon_custumer360.svg"
import iconLead from "../../images/icons/icon_lead.svg"
import iconMess from "../../images/icons/icon_mess.svg"
import iconPost from "../../images/icons/icon_post.svg"
import iconRobot from "../../images/icons/icon_robot.svg"
import iconSchedule from "../../images/icons/icon_schedule.svg"
import iconSmart from "../../images/icons/icon_smart.svg"
import iconUser from "../../images/icons/icon_user.svg"
import iconTopology from "../../images/icons/icon_topology.svg"


import YoutubeReup from "../../images/icons/Youtube_Reup.png"
import YoutubePost from "../../images/icons/Youtube_Post.png"
import YoutubeHub from "../../images/icons/Youtube_Hub.png"
import YoutubeGuard from "../../images/icons/Youtube_Guard.png"
import YoutubeAdmin from "../../images/icons/Youtube_Admin.png"
import style from "./index.module.scss"
import FooterTrial from './FooterTrial';
export default function TrialIndex() {
  const [openModalReup, setopenModalReup] = useState(false)
  const [openModalPost, setopenModalPost] = useState(false)
  const [openModalAdmin, setopenModalAdmin] = useState(false)
  const [openModalGuard, setopenModalGuard] = useState(false)
  const [openModalHub, setopenModalHub] = useState(false)
  const [sidebar, setSideBar] = useState(true);
  const toggleSidebar = () => {
    setSideBar(!sidebar);
  };
  return (
    <React.Fragment>
      <Head title="Trang chủ dùng thử"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent className={style.Trial__head__title}>
              <Img src={Rectangle} alt="" className={style.Trial__head__img}/>
              <BlockTitle tag="h3" page className={style.Trial__head__text}>
                Xin chào!
              </BlockTitle>
              <BlockDes className="text-soft">
                <p className={style.Trial__head__content}>
                  SO9 đã giúp hơn <span>1.200</span> cá nhân,
                  doanh nghiệp tối ưu <span>90% thời gian</span> quản lý và nuôi dưỡng các tài khoản Mạng xã hội.
                  Thật vinh dự khi được trở thành người đồng hành trên con đường thành công của bạn!</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <Img src={LogoTrial} />
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block style={{ marginBottom: "40px" }}>
      
          <div className={style.Trial__body__card}>
            <Row>
              <Col md="8">
                <div style={{paddingBottom:"2%"}}>
                  <Img src={Rectangle_Yellow} />
                  <h4 className={style.card__text}>Quét bài đăng & Reup tự động</h4>
                </div>
                <div className={style.card__item}>
                  <Img src={iconCommunicate} className={style.card__img}/>
                  <span className={style.card__content}>
                    Sao chép Bài đăng, Video từ: <span className={style.title}>Tiktok</span>, <span className={style.title}>Instagram</span>, <span className={style.title}>Facebook</span> với 1 click chuột.
                  </span>
                </div>
                <div className={style.card__item}>
                  <Img src={iconSchedule} className={style.card__img}/>
                  <span className={style.card__content}>
                    Hẹn giờ & Reup lên: <span className={style.title}>Facebook</span> (Reels, Group), <span className={style.title}>Tiktok</span>, <span className={style.title}>Youtube</span> (Short)
                  </span>
                </div>
                <div className={style.card__item}>
                  <Img src={iconMess} className={style.card__img}/>
                  <span className={style.card__content}>
                    Chức năng nhóm kênh giúp bạn thao tác reup lên hàng trăm trang dễ dàng.
                  </span>
                </div>
                
                <Button className={style.card__button__scan}
                  onClick={() => {
                    window.history.pushState("", "", process.env.PUBLIC_URL + "/scan")
                    window.location.reload();
                  }}>
                  Tìm hiểu thêm</Button>
              </Col>
              <Col md="4" className={style.card__video}>
                <div className={style.card__videoBg} onClick={()=>{setopenModalReup(true)}}>
                  {/* <iframe title='youtube' src="https://www.youtube.com/embed/b-9fply17XQ" allowFullScreen >
                  </iframe> */}
                  <Img alt='' src={YoutubeReup} width={316} height={178}/>
                </div>
              </Col>
            </Row>
          </div>
          <div className={style.Trial__body__card}>
            <Row>
              <Col md="8">
                <div style={{paddingBottom:"2%"}}>
                  <Img src={Rectangle_Blue} />
                  <h4 className={style.card__text}>Đăng bài siêu tốc hàng trăm kênh</h4>
                </div>
                <div className={style.card__item}>
                  <Img src={iconClock} alt="" className={style.card__img}/>
                  <span className={style.card__content}>
                    Đăng & Hẹn giờ đăng nội dung lên hàng trăm Fanpage, Hội nhóm, Tiktok, Youtube.
                  </span>
                </div>
                <div className={style.card__item}>
                  <Img src={iconSchedule} className={style.card__img}/>
                  <span className={style.card__content}>
                    Đăng bài nhiều ảnh có kèm theo Caption vào từng ảnh (Giúp tăng tương tác).
                  </span>
                </div>
                <div className={style.card__item}>
                  <Img src={iconPost} className={style.card__img}/>
                  <span className={style.card__content}>
                    Đăng bài vào hội nhóm với hàng trăm tài khoản khác nhau.
                  </span>
                </div>
                <div>
                <Button className={style.card__button__post}
                  onClick={() => {
                    let url = process.env.PUBLIC_URL + "/posts/create";
                    window.history.pushState("", "", url)
                    window.location.reload();
                  }}>
                  Bắt đầu đăng
                </Button>
                </div>
              </Col>
              <Col md="4" className={style.card__video}>
                <div className={style.card__videoBg} onClick={()=>{setopenModalPost(true)}}>
                  {/* <iframe title='youtube' src="https://www.youtube.com/embed/310NiPZLZ54" allowFullScreen >
                  </iframe> */}
                  <Img src={YoutubePost} width={316} height={178}/>
                </div>
              </Col>
            </Row>
          </div>
          <div className={style.Trial__body__card}>
            <Row>
              <Col md="8">
                <div style={{paddingBottom:"2%"}}>
                      <Img src={Rectangle_purple_long} />
                      <h4 className={style.card__text}>
                        9 Guard: Công cụ bảo vệ kênh tự động
                      </h4>
                </div>
                <div className={style.card__item}>
                  <Img src={iconRobot} className={style.card__img}/>
                  <span className={style.card__content}>
                    API Chính thức của nền tảng. Đảm bảo 100% không bị checkpoint là bot như các phương thức giả lập
                  </span>
                </div>
                <div className={style.card__item}>
                  <Img src={iconTopology} className={style.card__img}/>
                  <span className={style.card__content}>
                    Cơ chế phân bổ tài khoản đăng giảm xác suất spam
                  </span>
                </div>
                <div className={style.card__item}>
                  <Img src={iconSmart} className={style.card__img}/>
                  <span className={style.card__content}>
                    Ứng dụng thông minh: Nhận cảnh báo thông tin bài đăng ở mọi lúc mọi nơi
                  </span>
                </div>
                <Button className={style.card__button__QT}
                  onClick={() => {
                    let url = "https://so9.vn/bai-viet/meo-giam-vi-pham-spam-cho-fanpage-va-tai-khoan-tren-facebook";
                    //window.history.pushState("", "", url);
                    //window.location.assign(url);
                    window.open(url, "_blank");
                  }}>
                  Trải nghiệm ngay</Button>
              </Col>
              <Col md="4" className={style.card__video}>
                <div className={style.card__videoBg} onClick={()=>{setopenModalGuard(true)}}>
                  {/* <iframe title='youtube' src="https://www.youtube.com/embed/nu6pzZPpfnc" allowFullScreen >
                  </iframe> */}
                  <Img src={YoutubeGuard} width={316} height={178}/>
                </div>
              </Col>
            </Row>
          </div>
          <div className={style.Trial__body__card}>
            <Row>
              <Col md="8">
                <div style={{paddingBottom:"2%"}}>
                  <Img src={Rectangle_green} />
                  <h4 className={style.card__text}>Hub trả lời tương tác</h4>
                </div>
                <div className={style.card__item}>
                  <Img src={iconCommunicate} className={style.card__img}/>
                  <span className={style.card__content}>
                    Bình luận, Chat tại hàng trăm Trang đổ về một nơi duy nhất
                  </span>
                </div>
                <div className={style.card__item}>
                  <Img src={iconCustumer360} className={style.card__img}/>
                  <span className={style.card__content}>
                    Customer 360 , Chấm điểm khách hàng tự động
                  </span>
                </div>
                <div className={style.card__item}>
                  <Img src={iconLead} className={style.card__img}/>
                  <span className={style.card__content}>
                    Tạo Lead, Tạo đơn hàng & Kết nối với các hệ thống CRM của bạn
                  </span>
                </div>
                <Button className={style.card__button__hub}
                  onClick={() => {
                    window.history.pushState("", "", process.env.PUBLIC_URL + "/engagement")
                    window.location.reload();
                  }}>
                  Trải nghiệm ngay</Button>
              </Col>
              <Col md="4" className={style.card__video}>
                <div className={style.card__videoBg} onClick={()=>{setopenModalHub(true)}}>
                  {/* <iframe title='youtube' src="https://www.youtube.com/embed/JnhSFUgjldE" allowFullScreen >
                  </iframe> */}
                  <Img src={YoutubeHub} width={316} height={178}/>
                </div>
              </Col>
            </Row>
          </div>
          <div className={style.Trial__body__card}>
            <Row>
              <Col md="8">
                <div style={{paddingBottom:"2%"}}>
                  <Img src={Rectangle_purple} />
                  <h4 className={style.card__text}>Quản trị hiệu quả</h4>
                </div>
                <div className={style.card__item}>
                  <Img src={iconAnalysis} className={style.card__img}/>
                  <span className={style.card__content}>
                    Quản lý hiệu quả tiếp cận của tất cả các kênh
                  </span>
                </div>
                <div className={style.card__item}>
                  <Img src={iconUser} className={style.card__img}/>
                  <span className={style.card__content}>
                    Quản lý năng suất làm việc và hiệu quả theo từng nhân viên
                  </span>
                </div>
                <div className={style.card__item}>
                  <Img src={iconCaculation} className={style.card__img}/>
                  <span className={style.card__content}>
                    Quản lý hiệu quả theo loại nội dung, thời gian đăng
                  </span>
                </div>
                <Button className={style.card__button__QT}
                  onClick={() => {
                    window.history.pushState("", "", process.env.PUBLIC_URL + "/analysis")
                    window.location.reload();
                  }}>
                  Trải nghiệm ngay</Button>
              </Col>
              <Col md="4" className={style.card__video}>
                <div className={style.card__videoBg} onClick={()=>{setopenModalAdmin(true)}}>
                  {/* <iframe title='youtube' src="https://www.youtube.com/embed/NrYCPmxrGmk" allowFullScreen >
                  </iframe> */}
                  <Img src={YoutubeAdmin} width={316} height={178}/>
                </div>
              </Col>
            </Row>
          </div>
        </Block>

      </Content>
      <FooterTrial></FooterTrial>
      <ModalOpenYoutubeReup
        isOpen={openModalReup}
        onFormCancel={setopenModalReup}
      />
      <ModalOpenYoutubeAdmin
        isOpen={openModalAdmin}
        onFormCancel={setopenModalAdmin}
      />
      <ModalOpenYoutubeGuard
        isOpen={openModalGuard}
        onFormCancel={setopenModalGuard}
      />
      <ModalOpenYoutubeHub
        isOpen={openModalHub}
        onFormCancel={setopenModalHub}
      />
      <ModalOpenYoutubePost
        isOpen={openModalPost}
        onFormCancel={setopenModalPost}
      />
    </React.Fragment>
  )
}
const ModalOpenYoutubeReup = React.lazy(() => import("../../components/Modal/ModalOpenYoutubeReup"))
const ModalOpenYoutubePost = React.lazy(() => import("../../components/Modal/ModalOpenYoutubePost"))
const ModalOpenYoutubeHub = React.lazy(() => import("../../components/Modal/ModalOpenYoutubeHub"))
const ModalOpenYoutubeAdmin = React.lazy(() => import("../../components/Modal/ModalOpenYoutubeAdmin"))
const ModalOpenYoutubeGuard = React.lazy(() => import("../../components/Modal/ModalOpenYoutubeGuard"))
