import React, { useState } from "react";
import { MEDIA_TYPE_IMAGE, MEDIA_TYPE_VIDEO } from "../../utils/Utils";

const CloudMediaItem = ({ ...props }) => {
  const [isHover, setIsHover] = useState(false);
  const isUsed = props.isUsed;
  const media = props.media;
  const onMouseEnter = () => {
    setIsHover(true);
  };
  const onMouseLeave = () => {
    setIsHover(false);
  };
  const onClick = () => {
    props.onAdd && props.onAdd();
  };
  const renderMedia = () => {
    if (media.type === MEDIA_TYPE_IMAGE) {
      return <img src={media.url} alt="" />;
    }
    if (media.type === MEDIA_TYPE_VIDEO) {
      return <video controls preload="metadata" src={media.url}></video>;
    }
    return null;
  };
  return (
    <div className={`media-item`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {renderMedia()}
      {isUsed && (
        <div className="media-item-overlay-used d-flex align-items-center justify-content-center fw-medium fs-12px text-white">
          Đã thêm
        </div>
      )}
      {!isUsed && isHover && (
        <div className="media-item-overlay-add btn btn-primary" onClick={onClick}>
          Thêm
        </div>
      )}
    </div>
  );
};

export default CloudMediaItem;
