import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import UserProfileRegularPage from "./UserProfileRegular";
import UserProfileSettingPage from "./UserProfileSetting";
import { Route, Switch, Link } from "react-router-dom";
import { Icon, UserAvatar } from "../../../components/Component";
import { findUpper } from "../../../utils/Utils";
import { toastError, toastSuccess } from "../../../libs/toasts";
import { updateProfile } from "../../../api/user";
import { updateAvatarUser } from "../../../reducers/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import {
  Card,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { uploadImage } from "../../../api/createPost";
import UserProfileIrisPage from "./UserProfileIris";
import UserProfileIrisDetail from "./UserProfileIrisDetail";

const UserProfileLayout = () => {
  const dispatch = useDispatch();
  const userAvatar = useSelector((state) => state.user.avatar);
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [loading, setLoading] = useState(false);
  const userInfor = JSON.parse(localStorage.getItem("userInfor"));
  const projectId = userInfor.last_project_active;
  const { t } = useTranslation();
  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document
      .getElementsByClassName("nk-header")[0]
      .addEventListener("click", function () {
        updateSm(false);
      });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  /**
   * Upload avatar
   */
  const onDrop = async (acceptedFiles) => {
    setLoading(true);
    try {
      const data = new FormData();
      data.append("image", acceptedFiles[0]);
      data.append("projectId", projectId);
      data.append("folder", "user");
      const response = await uploadImage(data);
      const urlAvatar = response?.data?.url || null;
      if (urlAvatar) {
        updateProfile({
          avatar: urlAvatar,
        }).then((res) => {
          if (res.status) {
            localStorage.setItem("userInfor", JSON.stringify(res.data));
            dispatch(updateAvatarUser({ avatar: urlAvatar }));
            setLoading(false);
            toastSuccess("Cập nhật avatar thành công!");
          } else {
            dispatch(updateAvatarUser({ avatar: userInfor?.avatar }));
            setLoading(false);
            toastError("Upload avatar thất bại");
          }
        });
      }
    } catch (e) {
      dispatch(updateAvatarUser({ avatar: userInfor?.avatar }));
      setLoading(false);
      toastError("Upload avatar thất bại");
      return null;
    }
  };

  let { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: ["image/*"],
    multiple: false,
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: true,
    onDrop,
  });
  const { ref: dropzoneRef, ...dropzoneProps } = getRootProps();

  return (
    <React.Fragment>
      <Content>
        <Card
          className="card-bordered"
          innerRef={dropzoneRef}
          {...dropzoneProps}
        >
          <input {...getInputProps()} />
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${sm ? "content-active" : ""
                }`}
            >
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="user-card">
                    <UserAvatar
                      image={userAvatar || userInfor?.avatar}
                      imageAlt={findUpper(userInfor.name)}
                      text={findUpper(userInfor.name)}
                      theme="primary"
                    />
                    <div className="user-info">
                      <div className="d-flex">
                        <span className="lead-text">{userInfor.name}</span>
                        {loading && (
                          <Spinner
                            size={sm}
                            color="dark"
                            style={{ width: "1rem", height: "1rem" }}
                            className="ml-1"
                          />
                        )}
                      </div>
                      <span className="sub-text">{userInfor.email}</span>
                    </div>
                    <div className="user-action">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="btn btn-icon btn-trigger mr-n2"
                        >
                          <Icon name="more-v"></Icon>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  open();
                                }}
                              >
                                <Icon name="camera-fill"></Icon>
                                <span>Change Photo</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="edit-fill"></Icon>
                                <span>Update Profile</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>

                <div className="card-inner p-0">
                  <ul className="link-list-menu">
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`${process.env.PUBLIC_URL}/user-profile-regular`}
                        className={
                          window.location.pathname ===
                            `${process.env.PUBLIC_URL}/user-profile-regular`
                            ? "active"
                            : ""
                        }
                      >
                        <Icon name="user-fill-c"></Icon>
                        <span>{t("personal_information")}</span>
                      </Link>
                    </li>
                    {/*<li onClick={() => updateSm(false)}>*/}
                    {/*  <Link*/}
                    {/*    to={`${process.env.PUBLIC_URL}/user-profile-notification`}*/}
                    {/*    className={*/}
                    {/*      window.location.pathname === `${process.env.PUBLIC_URL}/user-profile-notification`*/}
                    {/*        ? "active"*/}
                    {/*        : ""*/}
                    {/*    }*/}
                    {/*  >*/}
                    {/*    <Icon name="bell-fill"></Icon>*/}
                    {/*    <span>Notification</span>*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    {/*<li onClick={() => updateSm(false)}>*/}
                    {/*  <Link*/}
                    {/*    to={`${process.env.PUBLIC_URL}/user-profile-activity`}*/}
                    {/*    className={*/}
                    {/*      window.location.pathname === `${process.env.PUBLIC_URL}/user-profile-activity` ? "active" : ""*/}
                    {/*    }*/}
                    {/*  >*/}
                    {/*    <Icon name="activity-round-fill"></Icon>*/}
                    {/*    <span>Account Activity</span>*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`${process.env.PUBLIC_URL}/user-profile-setting`}
                        className={
                          window.location.pathname ===
                            `${process.env.PUBLIC_URL}/user-profile-setting`
                            ? "active"
                            : ""
                        }
                      >
                        <Icon name="lock-alt-fill"></Icon>
                        <span>{t("security_setting")}</span>
                      </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`${process.env.PUBLIC_URL}/user-profile-iris`}
                        className={
                          window.location.pathname ===
                            `${process.env.PUBLIC_URL}/user-profile-iris`
                            ? "active"
                            : ""
                        }
                      >
                        <Icon name="user-fill-c"></Icon>
                        <span>Iris</span>
                      </Link>
                    </li>
                    
                  </ul>
                </div>
              </div>
            </div>
            <div
              className={
                window.location.pathname !==
                `${process.env.PUBLIC_URL}/user-profile-iris` &&
                "card-inner card-inner-lg"
              }
              style={{ width: "100%" }}
            >
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => updateSm(!sm)}
                ></div>
              )}
              <Switch>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/user-profile-regular`}
                  render={() => (
                    <UserProfileRegularPage updateSm={updateSm} sm={sm} />
                  )}
                ></Route>
                {/*<Route*/}
                {/*  exact*/}
                {/*  path={`${process.env.PUBLIC_URL}/user-profile-notification`}*/}
                {/*  render={() => <UserProfileNotificationPage updateSm={updateSm} sm={sm} />}*/}
                {/*></Route>*/}
                {/*<Route*/}
                {/*  exact*/}
                {/*  path={`${process.env.PUBLIC_URL}/user-profile-activity`}*/}
                {/*  render={() => <UserProfileActivityPage updateSm={updateSm} sm={sm} />}*/}
                {/*></Route>*/}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/user-profile-setting`}
                  render={() => (
                    <UserProfileSettingPage updateSm={updateSm} sm={sm} />
                  )}
                ></Route>
               
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/user-profile-iris`}
                  render={() => (
                    <UserProfileIrisDetail updateSm={updateSm} sm={sm} />
                  )}
                ></Route>
              </Switch>
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default UserProfileLayout;
