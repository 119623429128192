import React, { Fragment, forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import {
    Card,
    Spinner,
    FormGroup,
    FormText,
    Button,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    UncontrolledTooltip,
} from "reactstrap";
import style from "./index.module.scss";
import { Block, Col, Icon, Row, RSelect, Img, PaginationComponent } from "../../../components/Component";
import { components } from "react-select";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
    getChannels
} from "../../../api/createPost";
import {
    PLATFORM_LABEL_OPTION,
    getPlatformIcon
} from "../../utils/Utils";
import { getListGroupChannels, addGroupChannels, updateGroupChannels, deleteGroupChannels, importExcelToGroupChannel } from '../../../api/channel';
import ModalAlertSuccess from '../../components/Modal/ModalAlertSuccess';
import ModalAlertFailed from '../../components/Modal/ModalAlertFailed';
import ModalWarning from '../../components/Modal/ModalWarning';
import ConfimDelete from '../../components/Modal/ModalConfimDelete';
import { toast } from 'react-toastify';

export default function GroupChannel() {
    const history = useHistory();
    const { i18n, t } = useTranslation();
    const userInfo = JSON.parse(localStorage.getItem('userInfor'));
    const fileUrl = 'https://i.so9.vn/imports/create_group_channel.xlsx';
    const projectId = userInfo.last_project_active;
    const [mobileView, setMobileView] = useState();
    const [modalSuccess, setModalSuccess] = useState(false);
    const [modalFailed, setModalFailed] = useState(false);
    const [isEditCurrent, setEditCurrent] = useState(false);
    const [isAddCurrent, setAddCurrent] = useState(false);
    const [isDeleteCurrent, setDeleteCurrent] = useState(false);
    const [modalWarning, setModalWarning] = useState(false);
    const [gettingData, setGettingData] = useState(false);
    const [textNotification, setTextNotification] = useState("");
    const indexEditChannel = useRef(null);
    const [loading, setLoading] = useState(false)
    const [listChannels, setListChannels] = useState([])
    const [numberChannel, setNumberChannel] = useState(0)
    const [modalDelete, setModalDelete] = useState(false);
    const [excelLoading, setExcelLoading] = useState(false);
    const exportRef = useRef();
    const [indexDelete, setIndexDelete] = useState();
    const toggleDelete = () => {
        setModalDelete(!modalDelete);
    };
    const toggleOpenDeleteConfirm = (index) => {
        setModalDelete(true);
        setIndexDelete(index);
    };
    const toggleSuccess = () => {
        setModalDelete(false);
        setModalSuccess(!modalSuccess);
    };
    const toggleFailed = () => {
        setModalDelete(false);
        setModalFailed(!modalFailed);
    };
    const toggleWarning = () => {
        setModalWarning(!modalWarning);
    };

    const [formValues, setFormValues] = useState([
        // {
        //     groupchannel: "Hello",
        //     color: "#36c827",
        //     channel: [],
        //     isEdit: false
        // }
    ]);
    const [isUploading, setIsUploading] = useState(false);
    const pageNum = parseInt((new URLSearchParams(window.location.search)).get("page")) || 1;
    const [currentPage, setCurrentPage] = useState(pageNum);
    const [totalItems, setTotalItems] = useState(0)
    const [itemPerPage, setItemPerPage] = useState(10);
    const colorsList = [
        { label: "1", value: "#FFFFFF" },
        { label: "2", value: "#5084fb" },
        { label: "3", value: "#03d6b8" },
        { label: "4", value: "#03d6ba" },
        { label: "5", value: "#36c827" },
        { label: "6", value: "#b4d603" },
        { label: "7", value: "#fff258" },
        { label: "8", value: "#ff8901" },
        { label: "9", value: "#f54c47" },
        { label: "10", value: "#f14daa" },
        { label: "11", value: "#803df5" }
    ];
    const formFieldInitials = {
        groupchannel: "",
        color: colorsList[1].value,
        channel: [],
        isEdit: true
    };
    let addFormFields = () => {
        setFormValues([...formValues, formFieldInitials])
    }
    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);
    }
    const handleChangeGroupChannel = (i, e) => {
        if (e.target.value !== "") {
            let newFormValues = [...formValues];
            newFormValues[i][e.target.name] = e.target.value;
            setFormValues(newFormValues);
        }
    }
    const ColorOption = props => {
        return (
            <components.Option {...props}>
                <div
                    style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                        backgroundColor: props.data.value
                    }}
                />
            </components.Option>
        );
    };
    const addNumberChannel = ({ ...props }) => (
        <div style={{
            backgroundColor: 'white', border: '1px solid #dbdfea',
            padding: '5px',
            color: "rgb(81, 94, 95)",
            fontSize: "12px",
            fontFamily: "Roboto",
            textAlign: "start"
        }}>
            <span>Đã chọn ({numberChannel})</span>
            <components.Control {...props} />
        </div>
    );
    const ColorValue = ({ children, ...props }) => {
        return (
            <components.SingleValue {...props} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div
                    style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                        backgroundColor: props.data.value,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                />
            </components.SingleValue>
        );
    };

    const renderChannelIcon = (item, type) => {
        const flatFormStyle = {
            width: "8px",
            height: "8px",
            borderRadius: "4px",
            position: "absolute",
            bottom: type === "select" ? "0px" : "-2px",
            right: "0px",
        }
        return (
            <>
                <div style={{ width: "16px", height: "16px", position: "relative" }}>
                    <Img src={item.picture}
                        style={{ width: "16px", height: "16px", borderRadius: "4px" }} />
                    <Img
                        src={getPlatformIcon(item.platform)}
                        style={flatFormStyle}
                    />
                </div>
            </>
        )
    }

    /**
   * CHANNELS
   */
    const [optionChannels, setOptionChannels] = useState([]);
    const optionChannelsEnd = useRef(false);
    useEffect(() => {
        window.addEventListener("load", viewChange);
        window.addEventListener("resize", viewChange);
        return () => {
            window.removeEventListener("resize", viewChange);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(async () => {
        if (userInfo.last_project_active && !optionChannelsEnd.current) {
            setGettingData(true);
            const data = {};
            const fetchData = async () => {
                return await getChannels(projectId, data);
            };
            const dataResponse = await fetchData();
            if (dataResponse.status) {
                const { channel: channels, favorite_channel: _favoriteChannels } = dataResponse.data;
                setListChannels(channels)
                const favoriteChannelIds = _favoriteChannels.map((c) => c._id);
                const favoriteChannels = channels.filter((c) => favoriteChannelIds.includes(c._id));
                const uniqueChannelPlatforms = [
                    ...new Set(channels.filter((c) => !favoriteChannelIds.includes(c._id)).map((c) => c.platform)),
                ];

                const _optionChannels = [
                    {
                        label: "create_post.channel_favorite",
                        options: favoriteChannels,
                    },
                    ...uniqueChannelPlatforms.map((channelPlatform) => ({
                        label: `create_post.channel_${channelPlatform}`,
                        options: channels.filter((c) => !favoriteChannelIds.includes(c._id) && c.platform === channelPlatform),
                    })),
                ];
                setOptionChannels(_optionChannels);
                // const presetChannelIds = params.getAll("channels");
                // if (presetChannelIds.length > 0) {
                //     const _channels = channels.filter((channel) => presetChannelIds.includes(channel._id));
                //     setChannels(_channels);
                // }
                optionChannelsEnd.current = true;
                await listGroupChannels(1, channels);
            }
        }
    }, []);

    const listGroupChannels = async (numberPage = 1, channels = []) => {
        setLoading(true);
        const data = {
            params: {
                page: numberPage,
                v: 2,
            }
        }
        const groupChannelResponse = await getListGroupChannels(projectId, data);
        if (groupChannelResponse.status) {
            const result = groupChannelResponse.data;
            const groupChannelData = result.data?.map((item, index) => {
                const { channel_ids, color, name, _id } = item;
                let listChannelItem = [];
                if (Array.isArray(channel_ids)) {
                    listChannelItem = channel_ids?.map(ids => channels.find(eleChannel => eleChannel._id === ids));
                } else {
                    for (const key in channel_ids) {
                        const ids = channel_ids[key];
                        if (channels.find(eleChannel => eleChannel._id === ids)) {
                            listChannelItem.push(channels.find(eleChannel => eleChannel._id === ids));
                        }
                    }
                }
                const listChannelItemFilter = listChannelItem?.filter(item => item !== undefined);
                return {
                    _id,
                    groupchannel: name,
                    color,
                    channel: listChannelItemFilter,
                    isEdit: false
                }
            });
            setItemPerPage(result.per_page)
            setCurrentPage(result.current_page)
            let url = `${window.location.pathname}?page=${result.current_page}`
            history.push(url);
            setTotalItems(result.total)
            setLoading(false)
            setFormValues(groupChannelData);
            setGettingData(false);
        }
    }
    const onChangeChannels = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i]["channel"] = e;
        setFormValues(newFormValues);
        setNumberChannel(e.length)
    };

    const CustomOption = ({ children, isDisabled, ...props }) => {
        const { data } = props

        return (
            <components.Option {...props}>
                <div className='d-flex'>
                    {renderChannelIcon(data)}
                    <span className='ml-2'>{data?.name} ({data?.priority})</span>
                </div>
            </components.Option>
        );
    };



    const MultiValueLabel = (props) => {
        const { data } = props,
            imgStyle = {
                flex: "0 0 14px",
                width: "14px",
                height: "14px",
                objectFit: "cover",
            },
            spanStyle = { paddingLeft: "7px" };
        return (
            <components.MultiValueLabel {...props}>
                {renderChannelIcon(data, "select")}
                <span style={spanStyle}>{data.name} ({data.priority})</span>
            </components.MultiValueLabel>
        );
    };
    const GroupHeading = (props) => {
        const { data } = props;
        return <components.GroupHeading {...props}>{t(data.label)}</components.GroupHeading>;
    };
    const selectPlatformStyle = {
        multiValue: (base) => ({
            ...base,
            border: "1px solid #6A2C62",
            borderRadius: "100px !important",
            padding: "4px 11px",
            backgroundColor: "#f2f2f2 !important",
        }),
        multiValueLabel: (base) => ({
            ...base,
            display: "flex",
            alignItems: "center",
            padding: "0",
            paddingLeft: "0",
            fontSize: "12px",
            lineHeight: "1",
        }),
        multiValueRemove: (base) => ({
            ...base,
            marginLeft: "7px",
            padding: "0",
            lineHeight: "1",
        }),
    };
    const viewChange = () => {
        if (window.innerWidth < 960) {
            setMobileView(true);
        } else {
            setMobileView(false);
        }
    };
    const paginate = (pageNumber) => {
        if (!isEditCurrent && !isAddCurrent) {
            listGroupChannels(pageNumber, listChannels)
        }
    };
    /**
     * HANDLE ROW
     */
    const handleEditGroupChannel = (i) => {
        if (!isEditCurrent && !isAddCurrent) {
            let newFormValues = [...formValues];
            setValue(`groupchannel_${i}`, formValues[i]["groupchannel"]);
            setValue(`channel_${i}`, formValues[i]["channel"]);
            setValue(`color_${i}`, formValues[i]["color"]);
            setEditCurrent(true);
            indexEditChannel.current = i;
            newFormValues[i]["isEdit"] = true;
            setFormValues(newFormValues);
            setNumberChannel(formValues[i]["channel"]?.length || 0)
        }
    }
    const handleDeleteGroupChannel = async (i, e) => {
        e.stopPropagation();
        setDeleteCurrent(true);
        const rowGroupChanneDelete = formValues[i];
        const response = await deleteGroupChannels(projectId, rowGroupChanneDelete._id);
        if (response && response.status) {
            removeFormFields(i);
            setTextNotification("Bạn đã xóa thành công nhóm kênh!");
            toggleSuccess();
        } else {
            setDeleteCurrent(false);
            toggleFailed();
        }
    }
    /**
  * HANDLE SUBMIT
  */
    let handleFormSubmit = async (event) => {
        if (isAddCurrent) {
            setIsUploading(true);
            const groupDataFilter = formValues.filter(item => item.isEdit);
            const groupData = groupDataFilter.map((item, index) => {
                return {
                    name: item.groupchannel,
                    color: item.color,
                    channel_ids: item.channel.map(ele => ele._id)
                }
            });
            const response = await addGroupChannels(projectId, { groups: groupData });
            setIsUploading(false);
            if (response && response.status) {
                setTextNotification("Bạn đã thêm thành công nhóm kênh!");
                toggleSuccess();
            } else {
                toggleFailed();
            }
        }
        if (isEditCurrent) {
            setIsUploading(true);
            const rowGroupChannelEdit = formValues[indexEditChannel.current];
            const dataBody = {
                name: rowGroupChannelEdit.groupchannel,
                color: rowGroupChannelEdit.color,
                channel_ids: rowGroupChannelEdit.channel.map(ele => ele._id)
            };
            const response = await updateGroupChannels(projectId, rowGroupChannelEdit._id, dataBody);
            setIsUploading(false);
            if (response && response.status) {
                setTextNotification("Bạn đã cập nhật thành công nhóm kênh!");
                toggleSuccess();
            } else {
                toggleFailed();
            }
        }
    }

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue
    } = useForm();

    const uploadFileExcel = async (fileExcel) => {
        setExcelLoading(true);
        const data = new FormData();
        data.append("file", fileExcel);

        importExcelToGroupChannel(userInfo.last_project_active, data).then((res) => {
            if (res.status) {
                setExcelLoading(false);
                toast.success(`Nhập dữ liệu thành công!`, {
                    autoClose: 2000,
                    position: "top-right",
                });
                listGroupChannels(currentPage, listChannels);
            } else {
                console.log("fail");
                toast.error(`Có lỗi xảy ra. Vui lòng thử lại!`, {
                    autoClose: 2000,
                    position: "top-right",
                });
            }
        }
        ).catch((err) => {
            setExcelLoading(false);
            toast.error(`Có lỗi xảy ra. Vui lòng thử lại!`, {
                autoClose: 2000,
                position: "top-right",
            });
        }
        )
        exportRef.current.value = null;
    }

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile !== undefined) {
            uploadFileExcel(selectedFile);
        }
    };

    const handleClickImport = () => {
        exportRef.current.click();
    }

    const downloadFile = () => {
        const anchor = document.createElement('a');
        anchor.href = fileUrl;
        anchor.download = 'Create_GroupChannel';
        anchor.click();
    };
    console.log(formValues);
    return (
        <Fragment>
            <Head title={"Quản lý nhóm kênh"}></Head>
            <Content>
                <Card className="card-bordered" style={{ padding: "1rem 12px" }}>
                    <Block>
                        <Row className="g-gs">
                            <Col md="12" className="d-flex justify-content-between">
                                <span className={`${style.createPostAllTitle}`}>Cấu hình Nhóm kênh</span>
                                <div className='d-flex gap-2'>
                                    {gettingData && <Spinner size="md" color="dark" className='ml-1' />}
                                    <Button color='info' className='py-3' onClick={downloadFile}>
                                        <Icon className={"mr-1"} name="download-cloud"></Icon>  Tải Demo
                                    </Button>
                                    <Button color="warning" className='py-3' onClick={handleClickImport}>
                                        Import Excel  {excelLoading ? <Spinner className='ml-1' size={"sm"}></Spinner> :
                                            <Icon id="importExcelTooltip" className={"ml-1"} name="info"></Icon>
                                        }
                                    </Button>
                                    {!excelLoading &&
                                        <UncontrolledTooltip target="importExcelTooltip">
                                            Tính năng chỉ hỗ trợ nền tảng Facebook, Tiktok
                                        </UncontrolledTooltip>
                                    }
                                </div>
                                <input
                                    className='d-none'
                                    ref={exportRef}
                                    type="file"
                                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    onChange={handleFileChange} />
                            </Col>
                            <Col md="12" style={{ overflow: "auto" }}>
                                <form onSubmit={handleSubmit(handleFormSubmit)}>
                                    <div className={`${mobileView ? style.overflow_scroll_x_table : ""}`}>
                                        <table className={`table table-striped table-bordered table-reponsive postFastTable ${mobileView ? style.width_responsive_table : ""}`}>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "25%" }} className={`${style.headPost}`}>Nhóm kênh</th>
                                                    <th style={{ width: "10%" }} className={`${style.headPost} text-center`}>Màu sắc</th>
                                                    <th style={{ width: "65%" }} className={`${style.headPost} text-center`}>Trang</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!loading && formValues.map((element, index) => {
                                                    if (element.isEdit) {
                                                        return (
                                                            <tr key={index} className="text-center">
                                                                <td>
                                                                    <FormGroup>
                                                                        <div className="form-control-wrap">
                                                                            <Controller
                                                                                name={`groupchannel_${index}`}
                                                                                control={control}
                                                                                rules={{ required: "Bạn cần nhập tên nhóm kênh" }}
                                                                                render={({ field: { onBlur, onChange } }) => (
                                                                                    <textarea
                                                                                        name="groupchannel"
                                                                                        defaultValue={element.groupchannel}
                                                                                        style={{ width: "100%", borderColor: "#dbdfea" }}
                                                                                        onChange={onChange}
                                                                                        onBlur={e => {
                                                                                            handleChangeGroupChannel(index, e);
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </div>
                                                                        {errors && errors[`groupchannel_${index}`] && <FormText color="danger">{errors[`groupchannel_${index}`]?.message}</FormText>}
                                                                    </FormGroup>
                                                                </td>
                                                                <td>
                                                                    <FormGroup>
                                                                        <Controller
                                                                            name={`color_${index}`}
                                                                            control={control}
                                                                            render={({ field: { onBlur, onChange } }) => (
                                                                                <RSelect
                                                                                    options={colorsList}
                                                                                    components={{ Option: ColorOption, SingleValue: ColorValue }}
                                                                                    defaultValue={
                                                                                        colorsList.find(itemCol => itemCol.value === element.color)
                                                                                        || colorsList[1]
                                                                                    }
                                                                                    styles={{
                                                                                        // styles for the selected value container
                                                                                        valueContainer: (provided) => ({
                                                                                            ...provided,
                                                                                            justifyContent: "center",
                                                                                        }),
                                                                                        option: (provided) => ({
                                                                                            ...provided,
                                                                                            display: "flex",
                                                                                            justifyContent: "center",
                                                                                            textAlign: "center",
                                                                                            "&:hover": {
                                                                                                display: "flex",
                                                                                                justifyContent: "center",
                                                                                                textAlign: "center",
                                                                                            }
                                                                                        }),
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        let newFormValues = [...formValues];
                                                                                        newFormValues[index]["color"] = e.value;
                                                                                        setFormValues(newFormValues);
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                        {errors && errors[`color_${index}`] && <FormText color="danger">{errors[`color_${index}`]?.message}</FormText>}
                                                                    </FormGroup>
                                                                </td>
                                                                <td>
                                                                    <FormGroup>
                                                                        <div className="form-control-wrap">
                                                                            <Controller
                                                                                name={`channel_${index}`}
                                                                                control={control}
                                                                                rules={{ required: "Bạn cần chọn trang" }}
                                                                                render={({ field: { onBlur, onChange } }) => (
                                                                                    <RSelect
                                                                                        closeMenuOnSelect={false}
                                                                                        components={{
                                                                                            Control: addNumberChannel,
                                                                                            MultiValueLabel,
                                                                                            GroupHeading,
                                                                                            Option: CustomOption,
                                                                                        }}
                                                                                        value={element.channel}
                                                                                        isMulti
                                                                                        getOptionLabel={(v) => v.name+" ("+v.priority+")"}
                                                                                        getOptionValue={(v) => v._id}
                                                                                        onChange={(e) => {
                                                                                            onChange(e);
                                                                                            onChangeChannels(index, e);
                                                                                        }}
                                                                                        className="customChannelRSelect"
                                                                                        options={optionChannels}
                                                                                        styles={selectPlatformStyle}
                                                                                        onBlur={onBlur}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </div>
                                                                        {errors && errors[`channel_${index}`] && <FormText color="danger">{errors[`channel_${index}`]?.message}</FormText>}
                                                                    </FormGroup>
                                                                </td>
                                                            </tr>
                                                        )
                                                    } else {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <p>{element.groupchannel}</p>
                                                                    <div className='d-flex'>
                                                                        <Icon name="edit-fill" style={{ cursor: "pointer", fontSize: "1rem" }} onClick={() => handleEditGroupChannel(index)}></Icon>
                                                                        <Icon name="trash" style={{ cursor: "pointer", fontSize: "1rem" }} className={"ml-1"} onClick={() => toggleOpenDeleteConfirm(index)}></Icon>
                                                                    </div>
                                                                </td>
                                                                <td className='text-center'>
                                                                    <span className={`${style.colorLabel}`} style={{ backgroundColor: `${element.color}` }}></span>
                                                                </td>
                                                                <td>
                                                                    {/* <label className='mb-1'>Facebook Fanpage</label> */}
                                                                    <ul className='list-unstyled'>
                                                                        {
                                                                            element?.channel && element?.channel.map((eleChannel, ind) => {
                                                                                return (
                                                                                    <li className={`${style.page_wrapper}`} key={ind}>
                                                                                        {renderChannelIcon(eleChannel)}
                                                                                        <span>{eleChannel?.name} ({eleChannel?.priority})</span>
                                                                                    </li>
                                                                                )

                                                                            })

                                                                        }
                                                                        {/* {
                                                                               element?.channel &&<span style={{color: "rgb(81, 94, 95)", 
                                                                               fontSize: "12px", 
                                                                               fontFamily: "Roboto",}} >({element?.channel.length||0} Channel)</span>
                                                                        } */}
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={`${style.utilBtn}`}>
                                        {
                                            !isEditCurrent ? <>
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setAddCurrent(true);
                                                        addFormFields();
                                                    }}
                                                    className={`${style.btnUtilAdd}`}>Thêm dòng</button>
                                                <button
                                                    type="submit"
                                                    disabled={isUploading}
                                                    className={`${style.btnUtilPost}`}>
                                                    {
                                                        isUploading ? <Spinner size="sm" color="light" /> : <span>Lưu lại</span>
                                                    }
                                                </button>
                                            </>
                                                : <>
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            let newFormValues = [...formValues];
                                                            newFormValues[indexEditChannel.current]["isEdit"] = false;
                                                            setFormValues(newFormValues);
                                                            setEditCurrent(false);
                                                        }}
                                                        className={`${style.btnUtilAdd}`}>Hủy</button>
                                                    <button
                                                        type="submit"
                                                        disabled={isUploading}
                                                        className={`${style.btnUtilPost}`}>
                                                        {
                                                            isUploading ? <Spinner size="sm" color="light" /> : <span>Cập nhật</span>
                                                        }
                                                    </button>
                                                </>
                                        }
                                    </div>
                                    <div className="card-inner">


                                        {
                                            loading ? (
                                                <div className="text-center">
                                                    <Spinner color="dark" />
                                                </div>) : (
                                                formValues.length > 0 ? (
                                                    <PaginationComponent
                                                        itemPerPage={itemPerPage}
                                                        totalItems={totalItems}
                                                        paginate={paginate}
                                                        currentPage={currentPage}
                                                    />
                                                ) : (
                                                    <div className="text-center">
                                                        <span>No data found</span>
                                                    </div>
                                                )
                                            )
                                        }
                                        {/* {formValues.length > 0 ? (
                                            <PaginationComponent
                                                itemPerPage={itemPerPage}
                                                totalItems={totalItems}
                                                paginate={paginate}
                                                currentPage={currentPage}
                                            />
                                        ) : (
                                            loading ?
                                                (
                                                    <div className="text-center">
                                                        <Spinner color="dark" />
                                                    </div>) :
                                                (
                                                    <div className="text-center">
                                                        <span>No data found</span>
                                                    </div>
                                                )
                                        )} */}
                                    </div>
                                </form>
                            </Col>
                        </Row>
                    </Block>
                </Card>
            </Content>
            <ModalAlertSuccess modalSuccess={modalSuccess} toggleSuccess={toggleSuccess} textNotification={textNotification} _handleSubmitModal={() => {
                if (!isDeleteCurrent) {
                    window.location.reload();
                } else {
                    toggleSuccess();
                }
                setDeleteCurrent(false);
            }} />
            <ConfimDelete
                modalDelete={modalDelete}
                toggleDelete={toggleDelete}
                messeage={t("message.data_group_channel_will_deleted_from_system")}
                handleDelete={e => handleDeleteGroupChannel(indexDelete, e)}
            />
            <ModalAlertFailed modalFailed={modalFailed} toggleFailed={toggleFailed} />
            {/* <ModalWarning modalWarning={modalWarning} toggleWarning={toggleWarning} warningText={`Bạn có chắc chắc muốn xóa nhóm kênh này không?`} /> */}
        </Fragment>
    )
}
