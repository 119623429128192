// import Head from "../../../layout/head/Head";
// import FacebookStaff from "./Facebook/Index";
// import TiktokStaff from "./Tiktok/Index";
// import YoutubeStaff from "./Youtube/Index";
// import ModalAlertBusiness from "../../components/Modal/ModalCheckBusiness";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Spinner } from "reactstrap";

import { getProject } from "../../../../api/project";
import { getProjectIdInLocalStorage } from "../helper";
// import BreadCrumb from "Components/Common/BreadCrumb";

import FacebookStaff from "./Facebook";
import TiktokStaff from "./TikTok";
import YoutubeStaff from "./Youtube/Index";
import HeaderDashboard from "../CommonItem/HeaderDashboard";
import { PLATFORM_NAME } from "../constants";
import { useLocation } from "react-router-dom";
import "remixicon/fonts/remixicon.css";
import ModalAlertBusiness from "../../../components/Modal/ModalCheckBusiness";

export const DashboardContent = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const channelValue = params.get("channel_ids")?.split(",") || [];
  const fromValue = params.get("from");
  const toValue = params.get("to");

  const staffsValue = params.get("staffs")?.split(",") || [];

  const [selectedPlatForm, setSelectedPlatForm] = useState("");
  const [range, setRange] = useState(14);
  const [channels, setChannels] = useState(channelValue);
  const [staffs, setStaffs] = useState(staffsValue);
  const [loadingChannelFilter, setLoadingChannelFilter] = useState(false);
  const [loadingStaffFilter, setLoadingStaffFilter] = useState(false);
  const [loadingRange, setLoadingRange] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const projectId = getProjectIdInLocalStorage();
  const [isBusiness, setIsBusiness] = useState(false);

  const [from, setFrom] = useState(
    fromValue ?? moment().subtract(14, "days").format("YYYY-MM-DD")
  );
  const [to, setTo] = useState(toValue ?? moment().format("YYYY-MM-DD"));
  useEffect(() => {
    const getActiveTab = localStorage?.getItem(
      "activeTabDashboardEnterpriseContent"
    );
    if (getActiveTab) {
      setSelectedPlatForm(
        JSON.parse(getActiveTab)?.toUpperCase() || PLATFORM_NAME.FACEBOOK
      );
    } else {
      setSelectedPlatForm(PLATFORM_NAME.FACEBOOK);
    }

    getProject(projectId).then((project) => {
      if (Object.keys(project).length !== 0) {
        if (project?.type === "business") {
          setIsBusiness(true);
          setFirstLoading(false);
        } else {
          setFirstLoading(false);
        }
      }
    });
  }, [projectId]);

  useEffect(() => {
    localStorage.setItem(
      "activeTabDashboardEnterpriseContent",
      JSON.stringify(selectedPlatForm)
    );
  }, [selectedPlatForm]);

  return (
    <div className="page-content" style={{ marginTop: "80px" }}>
      {/* <Container fluid> */}
      {/* <BreadCrumb title="Ecommerce" pageTitle="Dashboard" /> */}
      <Col lg={12}>
        <HeaderDashboard
          selectedPlatform={selectedPlatForm}
          setSelectedPlatForm={setSelectedPlatForm}
          range={range}
          setRange={setRange}
          channel={channels}
          setChannels={setChannels}
          loadingChannelFilter={loadingChannelFilter}
          setLoadingChannelFilter={setLoadingChannelFilter}
          loadingRange={loadingRange}
          setLoadingRange={setLoadingRange}
          isEnterprise={true}
          staffs={staffs}
          setStaffs={setStaffs}
          setLoadingStaffFilter={setLoadingStaffFilter}
          loadingStaffFilter={loadingStaffFilter}
          from={from}
          to={to}
          setFrom={setFrom}
          setTo={setTo}
        />
      </Col>
      {isBusiness ? (
        <>
          {selectedPlatForm === PLATFORM_NAME.FACEBOOK && (
            <FacebookStaff
              range={range}
              channels={channels}
              staffs={staffs}
              from={from}
              to={to}
              setLoadingChannelFilter={setLoadingChannelFilter}
              setLoadingStaffFilter={setLoadingStaffFilter}
              setLoadingRange={setLoadingRange}
            />
          )}
          {selectedPlatForm === PLATFORM_NAME.TIKTOK && (
            <TiktokStaff
              range={range}
              channels={channels}
              staffs={staffs}
              from={from}
              to={to}
              setLoadingChannelFilter={setLoadingChannelFilter}
              setLoadingStaffFilter={setLoadingStaffFilter}
              setLoadingRange={setLoadingRange}
            />
          )}
          {selectedPlatForm === PLATFORM_NAME.YOUTUBE && (
            <YoutubeStaff
              range={range}
              channels={channels}
              staffs={staffs}
              from={from}
              to={to}
              setLoadingChannelFilter={setLoadingChannelFilter}
              setLoadingStaffFilter={setLoadingStaffFilter}
              setLoadingRange={setLoadingRange}
            />
          )}
        </>
      ) : firstLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "100%", height: "500px" }}
        >
          <Spinner size="lg" color="primary"></Spinner>
        </div>
      ) : (
        <ModalAlertBusiness type="content" />
      )}
      {/* </Container> */}
    </div>
  );
};
