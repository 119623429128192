import React from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Block, BlockDes, BlockHead, BlockHeadContent, BlockTitle, PreviewCard } from "../../components/Component";
import { useTranslation } from "react-i18next";

const Terms = ({ ...props }) => {
  const { t } = useTranslation("terms", "policy")
  return (
    <React.Fragment>
      <Head title={t("welcome_terms")} />
      <Content>
        <div className="content-page wide-md m-auto">
          <BlockHead size="lg" wide="xs" className="mx-auto">
            <BlockHeadContent className="text-center">
              <BlockTitle tag="h2" className="fw-normal">
                {t("welcome_terms")}
              </BlockTitle>
              <BlockDes>
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>
          <Block>
            <PreviewCard className="card-bordered">
              <div className="entry">

                <h4>{t("1_so9")} </h4>
                <p>{t("1_so9_info")}</p>
                <p> <b>{t("1_name_and_address")}</b></p>
                <p>{t("name")}{t("1_company_name", { ns: "policy" })}</p>
                <p>{t("1_office", { ns: "policy" })}{t("1_company_address", { ns: "policy" })}</p>
                <p> <b>{t("1_phone_and_mail")}</b> </p>
                <p>{t("1_telephone", { ns: "policy" })}{t("1_phone_number", { ns: "policy" })} </p>
                <p> {t("support")}{t("1_email", { ns: "policy" })}</p>
                <h4>{t("2_definitions")}</h4>
                <p>
                  <b>{t("2_so9")}</b> {t("2_so9_define")}
                </p>
                {/* <ul className="list list-sm" > */}
                <p> <b>{t("2_social_hub")}</b> {t("2_social_hub_define")}</p>
                <p> <b>{t("2_customer")}</b> {t("2_customer_define")}</p>
                <p> <b>{t("2_user_account")}</b>
                  <ul className="list">
                    <li> <b>{t("2_trial_status")}</b> {t("2_trial_status_define", { trial: t("trial") })}</li>
                    <li> <b>{t("2_active_status")}</b> {t("2_active_status_define", { active: t("active") })}</li>
                    <li> <b>{t("2_pause_status")}</b> {t("2_pause_status_define", { pause: t("pause") })}</li>
                  </ul>
                </p>
                {/* </ul> */}
                <h4> {t("3_user_account")} </h4>
                <p>
                  {t("3_user_account_responsibility_of_so9")}
                </p>
                <p>
                  {t("3_create_user_account")}
                </p>
                <ul className="list">
                  <li>
                    {t("3_account_terms_of_use_1")}
                  </li>
                  <li>
                    {t("3_account_terms_of_use_2")}
                  </li>
                  <li>
                    {t("3_account_terms_of_use_3")}
                  </li>
                  <li>
                    {t("3_account_terms_of_use_4")}
                  </li>
                </ul>

                <h4> {t("4_security")}</h4>
                <p>
                  {t("4_security_1")}
                </p>
                <p>
                  {t("4_security_2")}
                </p>
                <p>
                  {t("4_security_3")}
                </p>
                <p>
                  {t("4_security_4")}
                </p>
                <h4>{t("5_customer_data_and_the_responsibilities_of_the_parties")}</h4>
                <p>
                  {t("5_customer_data")}
                </p>
                <p>
                  {t("5_responsibility_of_customer")}
                </p>
                <p>
                  {t("5_responsibility_of_so9")}
                </p>
                <ul className="list ">
                  <li>
                    {t("5_responsibility_of_so9_1")}
                  </li>
                  <li>
                    {t("5_responsibility_of_so9_2")}
                  </li><li>
                    {t("5_responsibility_of_so9_3")}
                  </li><li>
                    {t("5_responsibility_of_so9_4")}
                  </li><li>
                    {t("5_responsibility_of_so9_5")}
                  </li>
                </ul>
                <p>
                  <b>
                    <i>
                      {t("5_note")}
                    </i>
                  </b>
                </p>
                <h4> {t("6_third_platforms_collect_user_data")}</h4>
                <p>
                  {t("6_third_platforms_so9_use")}
                </p>

                <p>
                  {t("6_note")}
                </p>

                <h4>{t("7_update_new_features")}</h4>
                <p>
                  {t("7_three_case")}
                </p>

                <p>
                  <b>{t("7_case_1_update_new_features")}</b>
                </p>
                <p>
                  {t("7_case_1_update_new_features_content_1")}
                </p>

                <p>
                  {t("7_case_1_update_new_features_content_2")}
                </p>

                <p>
                  {t("7_case_1_update_new_features_content_3")}
                </p>

                <p>
                  <b>{t("7_case_2_update_fix_bug")}</b>
                </p>

                <p>
                  {t("7_case_2_update_fix_bug_content_1")}
                </p>

                <p>
                  {t("7_case_2_update_fix_bug_content_2")}
                </p>

                <p>
                  {t("7_case_2_update_fix_bug_content_3")}
                </p>

                <p>
                  <b>{t("7_case_3_update_server")}</b>
                </p>

                <p>
                  {t("7_case_3_update_server_content_1")}
                </p>

                <p>
                  {t("7_case_3_update_server_content_2")}
                </p>

                <p>
                  {t("7_case_3_update_server_content_3")}
                </p>

                <h4>{t("8_data_loss")}</h4>
                <p>
                  {t("8_data_loss_and_responsibility_of_so9_1")}
                </p>

                <p>
                  {t("8_data_loss_and_responsibility_of_so9_2")}
                </p>

                <h4>{t("9_payment_and_tracing")}</h4>
                <p>
                  {t("9_payment_and_tracing_content_1")}
                </p>

                <p>
                  {t("9_payment_and_tracing_content_2")}
                </p>

                <h4> {t("10_end_contract")}</h4>
                <p>
                  {t("10_end_contact_case")}
                </p>
                <ul className="list">
                  <li> {t("10_end_contact_case_1")}</li>
                  <li> {t("10_end_contact_case_2")}</li>
                  <li> {t("10_end_contact_case_3")}</li>
                  <li> {t("10_end_contact_case_4")}</li>
                </ul>

                <h4>{t("11_terms_of_warranty_and_limitation_of_liability")}</h4>
                <p>
                  {t("11_terms_of_warranty_1")}
                </p>

                <p>
                  {t("11_terms_of_warranty_2")}
                </p>
                <p>
                  {t("11_limitation_of_liability")}
                </p>
                <p>
                  {t("11_limitation_of_liability_list")}
                </p>
                <ul className="list">
                  <li>
                    {t("11_limitation_of_liability_list_1")}
                  </li>
                  <li>
                    {t("11_limitation_of_liability_list_2")}
                  </li>
                  <li>
                    {t("11_limitation_of_liability_list_3")}
                  </li>
                  <li>
                    {t("11_limitation_of_liability_list_4")}
                  </li>
                  <li>
                    {t("11_limitation_of_liability_list_5")}
                  </li>
                </ul>
                <h4>{t("12_majeure")}</h4>
                <p>
                  {t("12_majeure_list")}
                </p>

                <ul className="list">
                  <li>
                    {t("12_majeure_list_1")}
                  </li>
                  <li>
                    {t("12_majeure_list_2")}
                  </li>
                  <li>
                    {t("12_majeure_list_3")}
                  </li>
                </ul>
                <h4> {t("13_dispute_resolution")}</h4>
                <p>
                  {t("13_solution_1")}
                </p>

                <p>
                  {t("13_solution_2")}
                </p>
              </div>
            </PreviewCard>
          </Block>
        </div>
      </Content>
    </React.Fragment>
  );
};

export default Terms;
