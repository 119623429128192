import en from "date-fns/locale/en-US";
import vi from "date-fns/locale/vi";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { registerLocale } from "react-datepicker";
import { useDropzone } from "react-dropzone";
import { Controller, set, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import iconImage from "../../images/icons/post-fast-img.svg";
import iconVideo from "../../images/icons/post-fast-video.svg";
import iconNotVideo from "../../images/icons/post-fast-video.svg";
import iconNotImage from "../../images/icons/post-fast-img.svg";
import iconSpin from "../../images/icons/post-fast-spin.svg";
import iconAi from "../../images/icons/post-fast-ai.svg";
import iconClose from "../../images/icons/close.svg";
import iconCoppy from "../../images/icons/icon-coppy.svg";
import icon from "../../images/icons/icon-random.svg";
import word from "../../images/icons/word-random.svg";
import {
  Card,
  FormGroup,
  FormText,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip,
  Spinner
} from "reactstrap";
import SimpleBar from "simplebar-react";
import {
  editPost,
  getChannels,
  getTags,
  storePost,
  uploadImage,
  generateAiContent
} from "../../../api/createPost";
import { Icon, RSelect, Button, Img } from "../../../components/Component";
import { components } from "react-select";
import Head from "../../../layout/head/Head";
import {
  appendMedia,
  appendTag,
  removeMedia,
  removeTag,
  resetMedia,
  resetTag,
  setChannels,
  setContents,
  setOptionChannels,
  updateChannel,
  updateMedia,
} from "../../reducers/createPostSlice";
import {
  CONTENT_STATUS_ACTIVE,
  getPlatformIcon,
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_VIDEO,
  PLATFORM_FACEBOOK,
  PLATFORM_TIKTOK,
  PLATFORM_FACEBOOKGROUP,
  PLATFORM_YOUTUBE,
  PLATFORM_INSTAGRAM,
  PLATFORM_LABEL,
  PLATFORM_LABEL_OPTION,
  PLATFORM_ORIGINAL,
  MANUAL_POST,
  AUTO_POST,
  HEN_GIO_DANG_NGAU_NHIEN,
  PLATFORM_PINTEREST,
  isValidUrl,
} from "../../utils/Utils";
import CloudMedia from "./CloudMedia";
import ContentOriginal from "./ContentOriginal";
import "./create-post.scss";
import MediaItemImage from "./MediaItemImage";
import MediaItemVideo from "./MediaItemVideo";
import PostMode from "./PostMode";
import ModeFaceGroup from "./ModeFaceGroup";
import ModalSuccess from "./ModalSuccess";
import PreviewFacebook from "./PreviewFacebook";
import PreviewInstagram from "./PreviewInstagram";
import PreviewYoutube from "./PreviewYoutube";
import PreviewTiktok from "./PreviewTiktok";
import DefaultPreview from "./DefaultPreview";
import YoutubeVideoOption from "./YoutubeVideoOption";
import PinterestOption from "./PinterestOption";
import TikTokVideoOption from "./TikTokVideoOption";
import { generateVideoThumbnails } from "../../utils/generateThumbnail";
import style from "./index.module.scss";
import ss from "socket.io-stream";
import io from "socket.io-client";
import Select from 'react-select';
import moment from "moment";
import { getListGroupChannels } from "../../../api/channel";
import {
  convertToFractionInstaPlatform,
  loadVideo,
} from "../../../libs/helper";
import ModalAlertFailed from "../../components/Modal/ModalAlertFailed";
import { toastError } from "../../../libs/toasts";
registerLocale("en", en);
registerLocale("vi", vi);

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const PostMulti = () => {
  const project = useSelector((state) => state.project.project);
  const listIcon = useSelector((state) => state.icon.Icons);
  const { SingleValue, Option } = components;
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const [isEditPost, setEditPost] = useState(false);
  const [isMultiMedia, setMultiMedia] = useState(false);
  const [numberChannelSuccess, setNumberChannelSuccess] = useState(0);
  const [editPostData, setEditPostData] = useState({});
  const [listMedia, setListMedia] = useState([]);
  const [listTopic, setListTopic] = useState([]);
  const [pinEmpty, setPinEmpty] = useState({ link: false, board: false });
  const [typeChannel, setTypeChannel] = useState("channel");
  const [selectedOptionPost, setSelectedOptionPost] = useState(MANUAL_POST);
  const [modalWarning, setModalWarning] = useState(false);
  const [modalFailed, setModalFailed] = useState(false);
  const [isAddImage, setWarningAddImage] = useState(false);
  const [openIcon, setOpenIcon] = useState(false);
  const [openSuggestText, setOpenSuggestText] = useState(false);
  const [openAi, setOpenAi] = useState(false);
  const [loadingAi, setLoadingAi] = useState(false);
  const [aiCopyOpen, setAiCopyOpen] = useState(false);
  const [aiFormData, setAiFormData] = useState(
    {
      content: "",
      length: { label: "", value: "" },
      tone: { label: "", value: "" },
      hashtag: { label: "", value: "" },
      system: "",
    });
  const socketInstance = useRef();
  const [list, setList] = useState([]);
  const handleOptionChangePost = (type) => {
    setSelectedOptionPost(type);
  };
  const [mobileView, setMobileView] = useState();
  let params = useQuery();
  const positionContent = useRef(0);
  const toggleWarning = () => {
    setModalWarning(!modalWarning);
  };

  /**
   * CHANNELS
   */
  const optionChannels = useSelector(
    (state) => state.createPost.optionChannels
  );
  const optionChannelsEnd = useRef(false);

  const youtubeOption = useSelector((state) => state.createPost.youtubeOption);
  const pinterestOption = useSelector((state) => state.createPost.pinterestOption);
  const tiktokOption = useSelector((state) => state.createPost.tiktokOption);

  const lengthOption = [
    { label: "Ngắn (~100 - 150 ký tự)", value: "100 - 150" },
    { label: "Trung bình (~250 - 399 ký tự)", value: "250 - 399" },
    { label: "Dài (~500 - 550 Ký tự)", value: "500 - 550" },
  ]

  const toneOption = [
    { label: "Facebook", value: "Facebook" },
    { label: "Instagram", value: "Instagram" },
    { label: "Twitter", value: "Twitter" },
    { label: "Tiktok", value: "Tiktok" },
    { label: "Youtube", value: "Youtube" },
    { label: "Mặc định", value: "Mặc định" },
  ]

  const hashTagOption = [
    { label: "0", value: 0 },
    { label: "5", value: 5 },
    { label: "10", value: 10 },
    { label: "15", value: 15 },
    { label: "20", value: 20 },
  ]

  useEffect(async () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfor"));
    const postData = JSON.parse(localStorage.getItem("postData"));
    if (postData) {
      setEditPostData(postData);
      localStorage.removeItem("postData");
      setEditPost(true);
      // set Content
      dispatch(
        setContents({
          platform: postData.platform,
          content: postData.post.content,
        })
      );
      // set Media
      dispatch(resetMedia());
      postData.post.media.map((item) => {
        dispatch(appendMedia(item));
      });
      // set Tag
      dispatch(resetTag());
      postData.post.tags.map((item) => {
        dispatch(appendTag(item));
      });
    }
    if (userInfo.last_project_active && !optionChannelsEnd.current) {
      const projectId = userInfo.last_project_active;
      const data = {};
      const fetchData = async () => {
        return await getChannels(projectId, data);
      };
      const dataResponse = await fetchData();
      if (dataResponse.status) {
        const { channel: channels, favorite_channel: _favoriteChannels } =
          dataResponse.data;
        const favoriteChannelIds = _favoriteChannels.map((c) => c._id);
        const favoriteChannels = channels.filter((c) =>
          favoriteChannelIds.includes(c._id)
        );
        const uniqueChannelPlatforms = [
          ...new Set(
            channels
              .filter((c) => !favoriteChannelIds.includes(c._id))
              .map((c) => c.platform)
          ),
        ];

        const _optionChannels = [
          {
            label: "create_post.channel_favorite",
            options: favoriteChannels,
          },
          ...uniqueChannelPlatforms.map((channelPlatform) => ({
            label: `create_post.channel_${channelPlatform}`,
            options: channels.filter(
              (c) =>
                !favoriteChannelIds.includes(c._id) &&
                c.platform === channelPlatform &&
                c.platform !== 2
            ),
          })),
        ];

        dispatch(setOptionChannels(_optionChannels));
        if (postData) {
          const postChannel = channels.filter((c) =>
            postData.channelId.includes(c._id)
          );
          dispatch(setChannels(postChannel));
        } else {
          const presetChannelIds = params.getAll("channels");
          if (presetChannelIds.length > 0) {
            const _channels = channels.filter((channel) =>
              presetChannelIds.includes(channel._id)
            );
            dispatch(setChannels(_channels));
          }
        }
        optionChannelsEnd.current = true;
        const groupChannelResponse = await getListGroupChannels(projectId);
        if (groupChannelResponse.status) {
          const groupChannelData = groupChannelResponse.data.map(
            (item, index) => {
              const { channel_ids, color, name, _id } = item;
              const listChannelItem = channel_ids.map((ids) =>
                channels.find((eleChannel) => eleChannel._id === ids)
              );
              const listChannelItemFilter = listChannelItem.filter(
                (item) => item !== undefined
              );
              return {
                label: name,
                value: _id,
                channel: listChannelItemFilter,
              };
            }
          );
          setListTopic(groupChannelData);
        }
      }
    }
  }, [dispatch, params]);

  const channels = useSelector((state) => state.createPost.channels);
  const onChangeChannels = useCallback(
    (e) => {
      dispatch(setChannels(e));
    },
    [dispatch]
  );
  const onUpdateChannel = useCallback(
    (index, data) => {
      dispatch(updateChannel({ index, data }));
    },
    [dispatch]
  );
  const MultiValueLabel = (props) => {
    const { data } = props,
      imgStyle = {
        flex: "0 0 14px",
        width: "14px",
        height: "14px",
        objectFit: "cover",
      },
      spanStyle = { paddingLeft: "7px" };
    return (
      <components.MultiValueLabel {...props}>
        <Img
          src={getPlatformIcon(data.platform)}
          alt={`entity-${data.platform}`}
          style={imgStyle}
        />
        <span style={spanStyle}>{data.name}</span>
      </components.MultiValueLabel>
    );
  };
  const GroupHeading = (props) => {
    const { data } = props;
    return (
      <components.GroupHeading {...props}>
        {t(data.label)}
      </components.GroupHeading>
    );
  };
  const CustomOption = ({ children, isDisabled, ...props }) => {
    const { data } = props,
      imgStyle = {
        //marginTop:"3px",
        marginRight: "2px",
        width: "14px",
        height: "14px",
        objectFit: "cover",
        borderRadius: "50%",
      },
      avatarStyle = {
        flex: "0 0 14px",
        width: "32px",
        height: "32px",
        objectFit: "cover",
        borderRadius: "50%",
      },
      spanStyle = {
        marginLeft: "8px",
      },
      divStyle = isDisabled
        ? {
          border: "1px solid rgb(222, 225, 225)",
          padding: "8px",
          borderRadius: "6px",
          display: "flex",
          backgroundColor: "rgb(189, 189, 189)",
          opacity: "30%",
        }
        : {
          border: "1px solid rgb(222, 225, 225)",
          padding: "8px",
          borderRadius: "6px",
          display: "flex",
        };
    return (
      <components.Option {...props}>
        <div style={divStyle}>
          <Img
            src={data.picture}
            alt={data.label}
            style={avatarStyle}
          />
          <div style={spanStyle}>
            <div className="d-flex">
              <Img
                src={getPlatformIcon(data.platform)}
                alt={`entity-${data.platform}`}
                style={imgStyle}
              />
              <span
                style={{
                  color: "rgb(39, 51, 51)",
                  fontSize: "13px",
                  fontWeight: "500",
                  lineHeight: 1,
                  fontFamily: "Roboto",
                }}
              >
                {data.name}
              </span>
            </div>
            <span
              style={{
                color: "rgb(81, 94, 95)",
                fontSize: "12px",
                fontFamily: "Roboto",
              }}
            >
              {PLATFORM_LABEL_OPTION[data.platform]}
            </span>
          </div>
        </div>
      </components.Option>
    );
  };

  const selectPlatformStyle = {
    multiValue: (base) => ({
      ...base,
      border: "1px solid #6A2C62",
      borderRadius: "100px !important",
      padding: "4px 11px",
      backgroundColor: "#f2f2f2 !important",
    }),
    multiValueLabel: (base) => ({
      ...base,
      display: "flex",
      alignItems: "center",
      padding: "0",
      paddingLeft: "0",
      fontSize: "12px",
      lineHeight: "1",
    }),
    menu: (styles) => ({ ...styles, maxHeight: "500px", overflow: "auto" }),
    multiValueRemove: (base) => ({
      ...base,
      marginLeft: "7px",
      padding: "0",
      lineHeight: "1",
    }),
  };

  /**
   * CONTENTS
   */
  const contents = useSelector((state) => state.createPost.contents);
  const displayContent = useSelector(
    (state) => state.createPost.displayContent
  );
  const onChangeContent = useCallback(
    (platform, newContent, disContent) => {
      dispatch(
        setContents({
          platform,
          content: newContent,
          displayContent: disContent,
        })
      );
    },
    [dispatch]
  );

  /**
   * MEDIA
   */
  const mediaItems = useSelector((state) => state.createPost.mediaItems);
  const [isUploading, setIsUploading] = useState(false);
  useEffect(() => {
    if (!mediaItems) return;
    setMultiMedia(mediaItems.length > 1);
  }, [mediaItems]);
  const onAppendMedia = useCallback(
    (newMedia) => {
      dispatch(appendMedia(newMedia));
    },
    [dispatch]
  );

  const onRemoveMedia = (mediaToRemoveIndex) => {
    listMedia.splice(mediaToRemoveIndex, 1);
    setListMedia(listMedia);
    dispatch(removeMedia(mediaToRemoveIndex));
  };

  const onUpdateMedia = useCallback(
    (index, data) => {
      dispatch(updateMedia({ index, data }));
    },
    [dispatch]
  );

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles[0].size > 1024000) {
      setIsStoringPost(true)
      setTimeout(() => {
        setIsStoringPost(false)
      }, 3000)
    }
    setWarningAddImage(false);
    const listAcceptedFiles = listMedia.concat(acceptedFiles);
    setListMedia(listAcceptedFiles);
    acceptedFiles.map(async (item, index) => {
      const isImage = item.type.split("/")[0] === "image";
      if (isImage) {
        const mediaItem = {
          type: MEDIA_TYPE_IMAGE,
          name: item.name,
          url: URL.createObjectURL(item),
          altText: item.name,
        };
        setIsUploading(() => false);
        dispatch(appendMedia(mediaItem));
      }
      const isVideo = item.type.split("/")[0] === "video";
      if (isVideo) {
        // thêm vào title trong option youtube
        const newDataYoutube = defaultYoutubeOption;
        newDataYoutube.title = item.name;
        setDefaultYouTubeOption(newDataYoutube);

        // preview image
        const previewImage = await generateVideoThumbnails(item, 1)
          .then(async (thumbnailArray) => {
            const blob = await fetch(thumbnailArray[0]).then((res) =>
              res.blob()
            );
            return {
              name: item.name,
              url: URL.createObjectURL(blob),
            };
          })
          .catch((err) => {
            return {
              name: item.name,
              url: 'https://i.so9.vn/images/image-default.png',
            };
          });
        const newMedia = {
          type: MEDIA_TYPE_VIDEO,
          name: item.name,
          url: item.url,
          altText: item.name,
          previewImages: previewImage,
          playbackUrl: URL.createObjectURL(item),
        };
        dispatch(appendMedia(newMedia));
      }
    });
  };

  const {
    getRootProps: getRootImageProps,
    getInputProps: getInputImageProps,
    isDragActive: isDragImageActive,
    open: openImage,
  } = useDropzone({
    accept: ["image/*"],
    multiple: true,
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: true,
    onDrop,
  });

  const {
    getRootProps: getRootVideoProps,
    getInputProps: getInputVideoProps,
    open: openVideo,
  } = useDropzone({
    accept: ["video/mp4", ".mov"],
    multiple: false,
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: true,
    onDrop,
  });
  const { ref: dropzoneRef, ...dropzoneProps } = getRootImageProps();
  const renderDropzoneOverlay = useMemo(() => {
    return (
      <Card
        className={`position-absolute w-100 h-100 align-items-center justify-content-center ${isDragImageActive ? "d-flex" : "d-none"
          }`}
        style={{
          background: "rgba(255, 236, 253, 0.69)",
          zIndex: "2",
        }}
      >
        <span className="fw-medium" style={{ fontSize: "24px" }}>
          {t("create_post.media_upload_from_local")}
        </span>
      </Card>
    );
  }, [t, isDragImageActive]);
  const renderMediaItem = (item, index) => {
    return (
      <MediaItemVideo
        item={item}
        channels={channels}
        setDefaultYouTubeOption={setDefaultYouTubeOption}
        defaultYoutubeOption={defaultYoutubeOption}
        isMultiMedia={isMultiMedia}
        onUpdateCaption={(caption) => onUpdateMedia(index, { caption })}
        removeMedia={() => onRemoveMedia(index)}
        update={(newData) => onUpdateMedia(index, newData)}
        key={`selectedFile-${index}`}
      />
    );
  };
  const [isShowCloudMedia, setIsShowCloudMedia] = useState(false);
  const toggleIsShowCloudMedia = () => {
    setIsShowCloudMedia(!isShowCloudMedia);
  };

  /**
   * TAGS
   */
  const optionTagPage = useRef(1);
  const optionTagEnd = useRef(false);
  const [optionTags, setOptionTags] = useState([]);
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfor"));
    if (userInfo.last_project_active && !optionTagEnd.current) {
      const projectId = userInfo.last_project_active;
      const data = {
        params: {
          page: optionTagPage.current,
        },
      };
      const fetchData = async () => {
        return await getTags(projectId, data);
      };
      fetchData()
        .then((response) => {
          if (response.data && response.data.length > 0) {
            const newOptionTags = response.data.map((t) => ({
              id: t._id,
              name: t.name,
              color: t.color || "#888888",
            }));
            if (newOptionTags.length > 0) {
              setOptionTags((c) => [...c, ...newOptionTags]);
              optionTagPage.current++;
            } else {
              optionTagEnd.current = true;
            }
          }
        })
        .catch((error) => console.error(error));
    }
  }, [optionTags]);
  const tags = useSelector((state) => state.createPost.tags);
  const onAppendTag = useCallback(
    (tagToAppend) => {
      dispatch(appendTag(tagToAppend));
    },
    [dispatch]
  );
  const onRemoveTag = useCallback(
    (tagToRemove) => {
      dispatch(removeTag(tagToRemove));
    },
    [dispatch]
  );
  const renderOptionTag = (tag) => {
    const { id, name, color } = tag;
    const checked = tags.find((l) => l.id === id) !== undefined;
    const styleText = {
      borderRadius: "4px",
      backgroundColor: color,
      padding: "4px",
      color: "#fff",
    };
    return (
      <li key={`optionLabel-${id}`}>
        <div className="custom-control custom-control-sm custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id={`optionLabel-${id}`}
            onChange={() => {
              checked ? onRemoveTag(tag) : onAppendTag(tag);
            }}
            checked={checked}
          />
          <label className="custom-control-label" htmlFor={`optionLabel-${id}`}>
            <span style={styleText}>{name}</span>
          </label>
        </div>
      </li>
    );
  };

  /**
   * SAVE OPTIONS
   */
  const [scheduledAt, setScheduledAt] = useState(new Date());
  const [showScheduleSelectTime, setShowScheduleSelectTime] = useState(true);
  const [isStoringPost, setIsStoringPost] = useState(false);
  const [timeSo9Smart, setTimeSo9Smart] = useState(new Date());
  const date = new Date();
  const [timeStart, setTimeStart] = useState(date.getHours());
  const [timeFinish, setTimeFinish] = useState(24);
  const createPostText = () => {
    if (selectedOptionPost === AUTO_POST) {
      return "Tự động lên lịch";
    }
    if (isStoringPost) {
      return <div className="spinner-border spinner-border-sm"></div>;
    }
    if (showScheduleSelectTime) {
      return t("create_post.create_scheduled_post_btn");
    }
    if (isEditPost) {
      return t("create_post.save_post_btn");
    }
    return t("create_post.create_post_btn");
  };

  const videoThumbMedia = useRef([]);
  const mediaUploadedAll = useRef([]);
  const mediaUploadImagePostTotal = useRef(0);

  const handleListenCompressVideo = (event) => {
    console.log({ event });
    if (event.status === false) {
      setNumberChannelSuccess(0);
      setIsUploading(() => false);
      alert("Tải video lên không thành công. Vui lòng thử lại sau ít phút");
      setIsStoringPost(false);
      videoThumbMedia.current = [];
      mediaUploadedAll.current = [];
      return;
    }

    const thumbOfVideo = videoThumbMedia.current.find(
      (item) => item.video_id === event.video_id
    );
    if (!thumbOfVideo) {
      setTimeout(() => {
        handleListenCompressVideo(event);
      }, 1000);
      return;
    }
    mediaUploadedAll.current.push({
      type: MEDIA_TYPE_VIDEO,
      name: event.data,
      previewImages: {
        name: thumbOfVideo.name,
      },
      caption: event.caption || "",
    });
    if (mediaUploadImagePostTotal.current === 1) {
      mediaUploadImagePostTotal.current -= 1;
      mediaUploadedAll.current = mediaUploadedAll.current.sort(
        (a, b) => a.position - b.position
      );
      actionCreatingPost(mediaUploadedAll.current);
    } else {
      mediaUploadImagePostTotal.current -= 1;
    }
  };

  const handleUploadImageData = (event) => {
    if (event.status === false) {
      setNumberChannelSuccess(0);
      setIsUploading(() => false);
      alert("Tải ảnh lên không thành công. Vui lòng thử lại sau ít phút");
      setIsStoringPost(false);
      mediaUploadImagePostTotal.current = 0;
      mediaUploadedAll.current = [];
      return;
    }
    mediaUploadedAll.current.push({
      type: MEDIA_TYPE_IMAGE,
      name: event.data,
      altText: event.data,
      caption: event.caption || "",
      position: event.position,
    });
    if (mediaUploadImagePostTotal.current === 1) {
      mediaUploadImagePostTotal.current -= 1;
      mediaUploadedAll.current = mediaUploadedAll.current.sort(
        (a, b) => a.position - b.position
      );
      actionCreatingPost(mediaUploadedAll.current);
    } else {
      mediaUploadImagePostTotal.current -= 1;
    }
  };

  const uploadMedia = async (fileMediaItem) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfor"));
    const projectId = userInfo.last_project_active;
    socketInstance.current = await io(process.env.REACT_APP_CDN_API, {
      transports: ["websocket"],
      withCredentials: true,
    });

    await socketInstance.current.on(
      `map-video-response-${userInfo.last_project_active}-${userInfo.id}`,
      (data) => {
        handleListenCompressVideo(data);
      }
    );
    await socketInstance.current.on(
      `upload-image-response-${userInfo.last_project_active}-${userInfo.id}`,
      (data) => {
        handleUploadImageData(data);
      }
    );
    mediaUploadImagePostTotal.current = fileMediaItem.length;
    return Promise.all(
      fileMediaItem.map(async (fileMedia, index) => {
        const mediaItem = mediaItems[index]; // đối sánh 2 dữ liệu như nhau
        console.log(fileMediaItem);
        const isImage = fileMedia.type.split("/")[0] === "image";
        if (isImage) {
          const stream = ss.createStream();
          ss(socketInstance.current).emit("upload-image", stream, {
            project_id: userInfo.last_project_active,
            user_id: userInfo.id,
            folder: "post",
            file_name: index + fileMedia.name,
            extention: fileMedia.name.split(".").pop(),
            file_size: fileMedia.size,
            caption: mediaItem.caption || "",
            position: index,
            scheduled_at:
              selectedOptionPost === AUTO_POST
                ? 0
                : scheduledAt
                  ? Math.floor(new Date(scheduledAt).getTime() / 1000)
                  : Math.floor(new Date().getTime() / 1000),
          });
          ss.createBlobReadStream(fileMedia, {
            highWaterMark: 1024 * 1024 * 1.5,
          }).pipe(stream);
        }
        const isVideo = fileMedia.type.split("/")[0] === "video";
        if (isVideo) {
          const isContainPlatformInsta = channels.find(
            (item) => item.platform === PLATFORM_INSTAGRAM
          );
          const currentTime = moment();
          const video = await loadVideo(fileMedia);
          const stream = ss.createStream();
          ss(socketInstance.current).emit("upload-video-v2", stream, {
            extention: "mp4",
            project_id: userInfo.last_project_active,
            user_id: userInfo.id,
            folder: "post",
            file_name: index + fileMedia.name,
            file_size: fileMedia.size,
            caption: mediaItem.caption || "",
            position: index,
            video_id: index + currentTime + fileMedia.name,
            is_short: false,
            ...(isContainPlatformInsta && {
              network: "Instagram",
              ratio: "16:9",
              ratio_current: convertToFractionInstaPlatform(
                video.videoWidth,
                video.videoHeight
              ),
              duration_current: Math.ceil(video.duration),
            }),
          });
          ss.createBlobReadStream(fileMedia, {
            highWaterMark: 1024 * 1024 * 1.5,
          }).pipe(stream);
          await generateVideoThumbnails(fileMedia, 1)
            .then(async (thumbnailArray) => {
              const blob = await fetch(thumbnailArray[0]).then((res) =>
                res.blob()
              );
              const data = new FormData();
              data.append("image", blob);
              data.append("projectId", projectId);
              data.append("folder", "post");
              project.type === "free"
                ? data.append("addWatermark", true)
                : data.append("addWatermark", false);
              const response = await uploadImage(data);
              const thumbnailInfo = response.data;
              videoThumbMedia.current.push({
                name: thumbnailInfo.name,
                url: thumbnailInfo.url,
                video_id: index + currentTime + fileMedia.name,
              });
            })
            .catch((err) => {
              console.error(err);
              videoThumbMedia.current.push({
                name: "https://i.so9.vn/images/image-default.png",
                url: "https://i.so9.vn/images/image-default.png",
                video_id: index + currentTime + fileMedia.name,
              });
            });
        }
      })
    );
  };
  const getDuration = async (url) => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.muted = true;
      const source = document.createElement("source");
      source.src = url; //--> blob URL
      video.preload = "metadata";
      video.appendChild(source);
      video.onloadedmetadata = function () {
        resolve(video.duration);
      };
    });
  };

  const actionCreatingPost = async (currentMedia) => {
    let duration;
    if (mediaItems.find((c) => c.type === MEDIA_TYPE_VIDEO)) {
      duration = await getDuration(mediaItems[0]?.playbackUrl);
    }
    if (
      selectedOptionPost === AUTO_POST &&
      channels.find((item) => item.setting_distribute_time.length === 0)
    ) {
      toggleWarning();
      setIsUploading(() => false);
      setIsStoringPost(false);
      return;
    }
    const channelData = [];
    channels.forEach((channel, key) => {
      let eachItem = {
        id: channel._id,
        content: contents[channel.platform],
        type: channel.platform,
        ...(channel.user_publish_id && {
          user_publish_id: channel.user_publish_id,
        }),
      };
      if (channel.platform === PLATFORM_YOUTUBE) {
        // youtube
        //eachItem.content = defaultYoutubeOption.description ?? defaultYoutubeOption.title;
        eachItem.visibility = defaultYoutubeOption.visibility;
        if (defaultYoutubeOption.description) {
          eachItem.desc = defaultYoutubeOption.description;
        }
        const YoutubeOption = { ...youtubeOption };
        Object.keys(YoutubeOption).forEach((key) => {
          if (YoutubeOption[key] === null) {
            delete YoutubeOption[key];
          }
        });
        eachItem = {
          ...eachItem,
          ...YoutubeOption,
        };
      }
      if (channel.platform === PLATFORM_PINTEREST) {
        // pinterest
        const PinterestOption = { ...pinterestOption };
        Object.keys(PinterestOption).forEach((key) => {
          if (PinterestOption[key] === null) {
            delete PinterestOption[key];
          }
        });
        eachItem = {
          ...eachItem,
          link: PinterestOption.link || "",
          board_id: PinterestOption.board.find(item => item.userId === channel._id)?.id || "",
        };
      }
      if (channel.platform === PLATFORM_TIKTOK) {
        // tiktok
        const TiktokOption = {
          ...tiktokOption,
          thumbnail_offset: tiktokOption.thumbnail_offset
            ? 0
            : parseInt(duration),
        };
        Object.keys(TiktokOption).forEach((key) => {
          if (TiktokOption[key] === null) {
            delete TiktokOption[key];
          }
        });
        eachItem = {
          ...eachItem,
          ...TiktokOption,
        };
      }
      channelData.push(eachItem);
    });
    let data = {
      channels: channelData,
      content: contents.original,
      images: currentMedia
        .filter((item) => item.type === MEDIA_TYPE_IMAGE)
        .map((item, index) => ({
          name: item.name,
          alt: item.altText ? item.altText : "",
          caption: item.caption || "",
          file_size: listMedia?.length ? listMedia[index].size.toString() : "0",
        })),
      videos: currentMedia
        .filter((item) => item.type === MEDIA_TYPE_VIDEO)
        .map((item, index) => ({
          name: item.name,
          thumb: item.previewImages.name,
          caption: item.caption || "",
          file_size: listMedia?.length ? listMedia[index].size.toString() : "0",
        })),
      tags: tags.map((tag) => tag.id),
      status: CONTENT_STATUS_ACTIVE,
      scheduled_at:
        // selectedOptionPost === MANUAL_POST &&
        scheduledAt
          ? Math.floor(new Date(scheduledAt).getTime() / 1000)
          : Math.floor(new Date().getTime() / 1000),
      range_time_schedule:
        selectedOptionPost === HEN_GIO_DANG_NGAU_NHIEN
          ? {
            date: moment(timeSo9Smart).format("YYYY-MM-DD"),
            range_time: `${timeStart}-${timeFinish}`,
          }
          : {},
    };


    console.log(data);
    const projectId = userInfo.last_project_active;
    setIsStoringPost(true);
    let response;
    if (selectedOptionPost === AUTO_POST) {
      data = {
        ...data,
        is_auto_scheduled_at: 1,
      };
    }

    if (isEditPost && Object.keys(editPostData).length > 0) {
      data = {
        ...data,
        _id: editPostData.post?._id,
      };
      response = await editPost(projectId, data);
    } else {
      if (selectedOptionPost === HEN_GIO_DANG_NGAU_NHIEN) {
        if (Number(timeStart) < Number(timeFinish)) {
          response = await storePost(projectId, data);
        } else {
          toggleFailed();
        }
      } else {
        response = await storePost(projectId, data);
      }
    }
    if (response.status) {
      setNumberChannelSuccess(channels.length || 0);
      toggleSuccess();
      dispatch(setChannels([]));
      onChangeContent(PLATFORM_FACEBOOK, "");
      onChangeContent(PLATFORM_LABEL[PLATFORM_ORIGINAL], "");
      dispatch(resetMedia());
      setOptionTags([]);
      setListMedia([]);
      setIsUploading(() => false);
      setSelectedOptionPost(MANUAL_POST);
      mediaUploadedAll.current = [];
      socketInstance.current.disconnect();
      socketInstance.current = null;
    } else {
      setNumberChannelSuccess(0);
      alert("Error");
      // socket.disconnect();
    }
    setIsStoringPost(false);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const handleFormSubmit = async (e) => {
    if (isStoringPost || isUploading) {
      return;
    }
    const pinChannel = channels?.filter((c) => c.platform === PLATFORM_PINTEREST);

    if (pinChannel.length > 0) {
      if (pinterestOption?.link === null || pinterestOption?.link?.trim().length === 0 || !isValidUrl(pinterestOption?.link)) {
        setPinEmpty({ ...pinEmpty, link: true });
        return;
      }

      if (pinterestOption?.board?.length < pinChannel?.length) {
        setPinEmpty({ ...pinEmpty, board: true });
        return;
      }
    }


    if (listMedia.length === 0 && !contents?.original) {
      setWarningAddImage(true);
      return;
    }
    setIsUploading(() => true);

    if (listMedia.length > 0) {
      const pushMedia = await uploadMedia(listMedia);
    } else {
      actionCreatingPost([]);
    }
  };

  const toggleSaveScheduled = () => {
    // if (showScheduleSelectTime) {
    // //   setShowScheduleSelectTime(false);
    //   setScheduledAt(null);
    //   setTimeSo9Smart(null);
    //   setTimeFinish(null);
    //   setTimeStart(null);
    // } else {
    setShowScheduleSelectTime(true);
    setScheduledAt(new Date());
    setTimeSo9Smart(new Date());
    setTimeFinish(24);
    setTimeStart(date.getHours());
    // }
  };

  const toggleSaveAndPost = () => {
    setShowScheduleSelectTime(false);
    setScheduledAt(null);
    setTimeSo9Smart(null);
    setTimeFinish(null);
    setTimeStart(null);
  };

  const toggleFailed = () => {
    setModalFailed(!modalFailed);
  };

  /**
   * PREVIEW
   */
  const renderPreview = (channels) => {
    const previewFacebook = channels.find(
      (c) =>
        c.platform === PLATFORM_FACEBOOK ||
        c.platform === PLATFORM_FACEBOOKGROUP
    );
    const previewInstagram = channels.find(
      (c) => c.platform === PLATFORM_INSTAGRAM
    );
    const previewYoutube = channels.find(
      (c) => c.platform === PLATFORM_YOUTUBE
    );
    const previewTiktok = channels.find((c) => c.platform === PLATFORM_TIKTOK);
    return (
      <>
        {previewFacebook && (
          <PreviewFacebook
            key={previewFacebook._id}
            channel={previewFacebook}
            content={contents[PLATFORM_FACEBOOK]}
            mediaItems={mediaItems}
            className="item"
          />
        )}
        {previewInstagram && (
          <PreviewInstagram
            key={previewInstagram._id}
            channel={previewInstagram}
            content={contents[PLATFORM_INSTAGRAM]}
            mediaItems={mediaItems}
            className="item"
          />
        )}
        {previewTiktok && (
          <PreviewTiktok
            key={previewTiktok._id}
            channel={previewTiktok}
            content={contents[PLATFORM_TIKTOK]}
            mediaItems={mediaItems}
            className="item"
          />
        )}
        {previewYoutube && (
          <PreviewYoutube
            key={previewYoutube._id}
            channel={previewYoutube}
            content={contents[PLATFORM_YOUTUBE]}
            mediaItems={mediaItems}
            className="item"
          />
        )}
      </>
    );
  };

  /**
   * MODAL
   */
  const [copy] = useState(userInfo.uuid);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleSuccess = () => {
    setModalSuccess((prev) => !prev);
  };

  /**
   * MODAL YOUTUBE OPTION
   */
  const [isOpenModalYoutube, setIsOpenModalYoutube] = useState(false);
  const [defaultYoutubeOption, setDefaultYouTubeOption] = useState({
    title: "",
    description: "",
    visibility: "public",
  });
  function unique(arr) {
    var newArr = [];
    for (var i = 0; i < arr.length; i++) {
      if (newArr.indexOf(arr[i]) === -1) {
        newArr.push(arr[i]);
      }
    }
    return newArr;
  }
  const handleAddText = () => {
    const position = positionContent.current;
    const positionMention =
      positionContent.current +
      (contents.original.slice(0, position).match(/@icon{R\d}/g)?.length * 6 ||
        0);
    //positionMention = positionMention + (contents.original.slice(0, position).match(/@text{}/g)?.length*7||0)
    const newContent =
      contents.original.slice(0, position) +
      "#text{" +
      contents.original.slice(position);
    const newdisContent =
      displayContent.slice(0, positionMention) +
      "#text{" +
      displayContent.slice(positionMention);
    dispatch(
      setContents({
        platform: "original",
        content: newContent,
        displayContent: newdisContent,
      })
    );
    const newDataYoutube = defaultYoutubeOption;
    newDataYoutube.description = newContent;
    setDefaultYouTubeOption(newDataYoutube);
  };
  const handleAddIcon = (e) => {
    const position = positionContent.current;
    const positionMention =
      positionContent.current +
      (contents.original.slice(0, position).match(/@icon{R\d}/g)?.length * 6 ||
        0);
    //positionMention = positionMention + (contents.original.slice(0, position).match(/@text{}/g)?.length*7||0)
    const newContent =
      contents.original.slice(0, position) +
      "@icon{" +
      e +
      "} " +
      contents.original.slice(position);
    const newdisContent =
      displayContent.slice(0, positionMention) +
      "@[@icon{" +
      e +
      "}](" +
      e +
      ")" +
      displayContent.slice(positionMention);
    dispatch(
      setContents({
        platform: "original",
        content: newContent,
        displayContent: newdisContent,
      })
    );
    const newDataYoutube = defaultYoutubeOption;
    newDataYoutube.description = newContent;
    setDefaultYouTubeOption(newDataYoutube);
  };
  const toggle = () => {
    navigator.clipboard.writeText(copy);
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 3000);
  };
  const handleScheduledAt = (date) => {
    setScheduledAt(date);
  };

  const handleChangeTimeStart = (timeStart) => {
    setTimeStart(timeStart.target.value);
  };
  const handleOpenAi = () => {
    const userInformation = JSON.parse(localStorage.getItem('userInfor'));
    const projectId = userInformation.last_project_active;
    if (true) {
      setOpenAi(!openAi);
    }
  }
  const handleCloseAi = () => {
    setAiFormData({
      system: "",
      content: "",
      length: { label: "", value: "" },
      tone: { label: "", value: "" },
      hashtag: { label: "", value: "" },
    })
    setOpenAi(!openAi);

  }

  const handleAiCreate = () => {
    if (aiFormData.content === "") {
      return;
    }
    setLoadingAi(true);

    const userInformation = JSON.parse(localStorage.getItem('userInfor'));
    const projectId = userInformation.last_project_active;
    let data = [];
    if (projectId) {
      let data = {
        project_id: projectId,
        messages: [
          {
            role: "user",
            content: `You are content marketer on social platform, write a ${aiFormData?.tone.value !== "" ? aiFormData?.tone.value : ""} post ${aiFormData?.length.value !== "" ? `with ${aiFormData.length.value} words ` : ""} about: "${aiFormData.content}" ${aiFormData.hashtag.value !== "" ? `, add ${aiFormData.hashtag.value} relevant hashtags` : ""}`
          }
        ]
      };
      if (aiFormData.system !== "") {
        data.messages.push({
          role: "system",
          content: aiFormData.system
        });
      }

      generateAiContent(data)
        .then((res) => {
          if (res.status) {
            setAiFormData({ ...aiFormData, system: res.data.messages.join(" ") });
            setLoadingAi(false);
          } else {
            toastError("Có lỗi xảy ra! Vui lòng thử lại sau ít phút");
            setLoadingAi(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoadingAi(false);
          toastError("Có lỗi xảy ra! Vui lòng thử lại sau ít phút");
        });
    }
  }

  const onChangeAiForm = (e, type) => {
    switch (type) {
      case "length":
        setAiFormData({ ...aiFormData, length: e.target.value })
        break;
      case "content":
        setAiFormData({ ...aiFormData, content: e.target.value })
        break;
      case "tone":
        setAiFormData({ ...aiFormData, tone: e.target.value })
        break;
      case "hashtag":
        setAiFormData({ ...aiFormData, hashtag: e.target.value })
        break;
      default:
        break;
    }
  }
  const handleChangeTimeSmart = (date) => {
    setTimeSo9Smart(date);
  };

  const handleChangeTimeEnd = (timeEnd) => {
    setTimeFinish(timeEnd.target.value);
  };
  /**
   *
   *  User post option
   */
  // ** Event custom
  const [selectedUserPostOption, setSelectedUserPostOption] = useState({});
  const viewChange = () => {
    if (window.innerWidth < 960) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };
  useEffect(() => {
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []);

  /**
   * TYPE CHANNEL
   */
  const loadPostsInChannel = () => {
    setList([1, 2, 3, 4, 5, 6, 7, 8, 9]);
  };
  const handleOptionChangeTypeChannel = (event) => {
    onChangeChannels([]);
    setTypeChannel(event.target.value);
  };

  const handlRemoveAiContent = (e) => {
    e.stopPropagation();
    setAiFormData({
      system: "",
      content: "",
      length: { label: "", value: "" },
      tone: { label: "", value: "" },
      hashtag: { label: "", value: "" },
    });
  }
  const toggleAi = () => {
    navigator.clipboard.writeText(aiFormData.system);
    setAiCopyOpen(true);
    setTimeout(() => setAiCopyOpen(false), 3000);
  };

  const handleSelectAiContent = () => {
    dispatch(
      setContents({
        platform: "original",
        content: aiFormData.system,
        displayContent: aiFormData.system,
      })
    );
    setAiFormData({
      content: "",
      length: { label: "", value: "" },
      tone: { label: "", value: "" },
      hashtag: { label: "", value: "" },
      system: ""
    });
    setOpenAi(false);
  }
  const renderAiAssitant = (type) => {
    if (!openAi || mobileView && type === "full") {
      return (<></>)
    } else
      if (aiFormData.system === "") {
        return (
          <div className={style.AssitantConatiner}>
            <div className={style.AssitantdArea}>
              <div className="d-flex justify-content-between" style={{ margin: "16px 16px 32px 16px" }}>
                <div className={style.aiItems} style={{ margin: "0" }} >
                  <Img
                    src={iconAi}
                    style={{ height: "24px" }}
                  />
                  <span>Trợ lý Chat GPT</span>

                </div>
                <span style={{ fontSize: "24px", lineHeight: "1", cursor: "pointer" }} onClick={handleCloseAi}>&times;</span>
              </div>
              <div className={style.assitantQuestions}>
                <p>Bạn muốn Chat GPT viết giúp bạn điều gì?</p>
                <div style={{
                  border: "1px solid #E5E7EB",
                  borderRadius: "4px",
                  overflow: "hidden"
                }}>
                  <ContentOriginal
                    value={aiFormData.content}
                    onChange={(e) => onChangeAiForm(e, "content")}
                    placeholder="Nhập nội dung tại đây"
                  />
                </div>
                {!loadingAi ?
                  (<div className="d-flex justify-content-between" style={{ marginTop: "16px", gap: "16px" }}>
                    <div className={style.aiSubInputContainer}>
                      <p>Độ dài</p>
                      <Select
                        className={style.aiSubInput}
                        classNamePrefix="react-select"
                        key="SelectBoxNumberPage"
                        value={aiFormData.length}
                        options={lengthOption}
                        placeholder=""
                        onChange={(selectedOption) => {
                          setAiFormData({ ...aiFormData, length: selectedOption });
                        }}
                        isSearchable={false}
                      />
                    </div>
                    <div className={style.aiSubInputContainer}>
                      <p>Tông giọng</p>
                      <Select
                        className={style.aiSubInput}
                        classNamePrefix="react-select"
                        key="SelectBoxNumberPage"
                        value={aiFormData.tone}
                        options={toneOption}
                        placeholder=""
                        onChange={(selectedOption) => {
                          setAiFormData({ ...aiFormData, tone: selectedOption });
                        }}
                        isSearchable={false}
                      />
                    </div>
                    <div className={style.aiSubInputContainer}>
                      <p>Hashtags</p>
                      <Select
                        className={style.aiSubInput}
                        classNamePrefix="react-select"
                        key="SelectBoxNumberPage"
                        value={aiFormData.hashtag}
                        options={hashTagOption}
                        placeholder=""
                        onChange={(selectedOption) => {
                          setAiFormData({ ...aiFormData, hashtag: selectedOption });
                        }}
                        isSearchable={false}
                      />
                    </div>
                  </div>) : (<div className={style.aiLoadingContent}>
                    <h2>Trợ lý đang tạo nội dung</h2>
                    <div className="d-flex justify-content-center align-items-center">
                      <Spinner className='mr-2'></Spinner>
                    </div>

                  </div>
                  )}
                {!loadingAi && (<div className="d-flex justify-content-end" style={{ marginTop: "24px" }}>
                  <Button onClick={handleAiCreate}
                    disabled={aiFormData.content === ""}
                    className={style.aiCreateButton}>Tạo nội dung</Button>
                </div>)}
              </div>
              <div className={style.assitantFooter}>
                <div className="d-flex" style={{ marginTop: "22px", marginLeft: "16px", marginBottom: "18px" }}>
                  <span> Các mẹo để viết câu lệnh tốt hơn.</span>
                  <a style={{ marginLeft: "4px" }}
                    target="_blank"
                    href="https://so9.vn/bai-viet/cong-thuc-tao-noi-dung-hieu-qua-hon-tu-chat-gpt/">Xem mẹo</a>
                </div>
              </div>
            </div>
          </div>
        )
      } else if (aiFormData.system !== "") {
        return (
          <div className={style.AssitantConatiner}>
            <div className={style.AssitantdArea}>
              <div className="d-flex justify-content-between" style={{ margin: "16px 16px 32px 16px" }}>
                <div className={style.aiItems} style={{ margin: "0" }} >
                  <Img
                    src={iconAi}
                    style={{ height: "24px" }}
                  />
                  <span >Trợ lý Chat GPT</span>

                </div>
                <span style={{ fontSize: "24px", lineHeight: "1", cursor: "pointer" }} onClick={handleCloseAi}>&times;</span>
              </div>
              <div className={style.assitantQuestions}>
                <p>Bạn muốn Chat GPT viết giúp bạn điều gì?</p>
                <div style={{
                  border: "1px solid #E5E7EB",
                  borderRadius: "4px",
                  overflow: "hidden"
                }}>

                  <div className={style.aiUserContentPreview}>
                    <span>{aiFormData.content}</span>
                    <Img onClick={handlRemoveAiContent} src={iconClose} height="24px" width="24px" />
                  </div>

                </div>
                {!loadingAi ?
                  (
                    <div className={style.aiSystemContentConatiner}>
                      <span>{aiFormData.system}</span>
                      <div className={style.aiSystemContentTools}>
                        <div style={{ cursor: "pointer" }}
                          className="d-flex justify-content-center align-items-center">
                          <Img onClick={toggleAi} id="aiCopy" src={iconCoppy} height="24px" width="24px" />
                          <Tooltip
                            autohide={false}
                            placement="top"
                            isOpen={aiCopyOpen}
                            target="aiCopy"
                            trigger="click"
                          >
                            copied
                          </Tooltip>
                        </div>

                        <div style={{ gap: "36px" }} className="d-flex justify-content-end">
                          <div style={{ gap: "8px", cursor: "pointer" }}
                            onClick={handleAiCreate}
                            className="d-flex justify-content-center align-items-center">
                            <Img className={style.aiPreviewSpin} src={iconSpin} height="24px" width="24px" />
                            <span style={{ fontWeight: "600", color: "#374151" }}>Tạo lại</span>
                          </div>
                          <Button onClick={handleSelectAiContent}>Chọn nội dung</Button>
                        </div>
                      </div>
                    </div>) : (<div className={style.aiLoadingContent}>
                      <h2>Trợ lý đang tạo nội dung</h2>
                      <div className="d-flex justify-content-center align-items-center">
                        <Spinner className='mr-2'></Spinner>
                      </div>

                    </div>
                  )}
              </div>
              <div className={style.assitantFooter}>
                <div className="d-flex" style={{ marginTop: "22px", marginLeft: "16px", marginBottom: "18px" }}>
                  <span> Các mẹo để viết câu lệnh tốt hơn.</span>
                  <a style={{ marginLeft: "4px" }}
                    target="_blank"
                    href="https://so9.vn/bai-viet/cong-thuc-tao-noi-dung-hieu-qua-hon-tu-chat-gpt/">Xem mẹo</a>
                </div>
              </div>
            </div>
          </div>
        )
      }

  }
  return (
    <>
      <Head title="Create A Post" />
      <div className="d-flex align-items-start">
        <div style={{ width: mobileView ? "100%" : "50%" }}>
          <form
            className="form-validate is-alter"
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <Card
              style={{ height: "100vh", overflow: "auto" }}
              className={` ${isDragImageActive ? "position-relative drag-active" : ""
                }`}
              innerRef={dropzoneRef}
              {...dropzoneProps}
            >
              {renderDropzoneOverlay}
              <div className="card-inner">
                <FormGroup>
                  <div className="d-flex justify-content-between">
                    <div style={{ width: "100%" }}>
                      <label className="mr-2" style={{ color: "#000000" }}>
                        <input
                          name="typechannel"
                          type="radio"
                          value="channel"
                          className="mr-1"
                          checked={typeChannel === "channel"}
                          onChange={handleOptionChangeTypeChannel}
                        />
                        Kênh
                      </label>

                      <label style={{ color: "#000000" }}>
                        <input
                          name="typechannel"
                          type="radio"
                          value="topic"
                          className="mr-1"
                          checked={typeChannel === "topic"}
                          onChange={handleOptionChangeTypeChannel}
                        />
                        Nhóm kênh
                      </label>
                      {/* Selection box  */}
                      {typeChannel === "topic" && (
                        <div style={{ marginBottom: ".5rem" }}>
                          <Controller
                            name={`topiclist`}
                            control={control}
                            render={({ field: { onBlur, onChange } }) => (
                              <RSelect
                                options={listTopic}
                                placeholder="Chọn nhóm kênh"
                                isMulti
                                onChange={(e) => {
                                  let listChannelSelected = [];
                                  e.map((item) => {
                                    const listChannelItem =
                                      listTopic.find(
                                        (i) => i.value === item.value
                                      )?.channel || [];
                                    listChannelSelected = [
                                      ...listChannelSelected,
                                      ...listChannelItem,
                                    ];
                                  });
                                  listChannelSelected =
                                    unique(listChannelSelected);
                                  setValue("channel", listChannelSelected);
                                  onChangeChannels(listChannelSelected);
                                }}
                              />
                            )}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-control-wrap">
                    <Controller
                      name="channel"
                      control={control}
                      rules={{
                        required: isEditPost ? false : "Bạn cần chọn kênh",
                      }}
                      render={({ field: { onBlur, onChange } }) => (
                        <RSelect
                          closeMenuOnSelect={false}
                          components={{
                            MultiValueLabel,
                            GroupHeading,
                            Option: CustomOption,
                          }}
                          value={channels}
                          isMulti
                          getOptionLabel={(v) => v.name}
                          getOptionValue={(v) => v._id}
                          onChange={(e) => {
                            onChange(e);
                            onChangeChannels(e);
                          }}
                          options={
                            mediaItems.find((c) => c.type === MEDIA_TYPE_IMAGE)
                              ? optionChannels.map((e) =>
                                e.label === "create_post.channel_5"
                                  ? {
                                    ...e,
                                    options: e.options.map(
                                      (channel) =>
                                      (channel = {
                                        ...channel,
                                        isDisabled: true,
                                      })
                                    ),
                                  }
                                  : { ...e, options: e.options }
                              )
                              : optionChannels
                          }
                          styles={selectPlatformStyle}
                          onBlur={onBlur}
                          placeholder="Chọn kênh đăng"
                        />
                      )}
                    />
                  </div>
                  {errors?.channel && (
                    <FormText color="danger">
                      {errors.channel?.message}
                    </FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <div className="nk-reply-form" style={{ margin: 0 }}>
                    <div className="">
                      <div className="">
                        <ContentOriginal
                          onKeyUp={(e) => {
                            let position = e.target.selectionStart;
                            if (positionContent.current !== position)
                              positionContent.current = position;
                          }}
                          onClick={(e) => {
                            let position = e.target.selectionStart;
                            if (positionContent.current !== position)
                              positionContent.current = position;
                          }}
                          className="form-control"
                          placeholder="Nhập nội dung"
                          value={displayContent}
                          onChange={(e, newValue, newPlainTextValue) => {
                            let position = newPlainTextValue.length;
                            if (positionContent.current !== position)
                              positionContent.current = position;
                            onChangeContent(
                              PLATFORM_LABEL[PLATFORM_ORIGINAL],
                              newPlainTextValue,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      <div className={`nk-reply-form-tools justify-start ${style.customFormTools}`}>
                        <div
                          id="i"
                          className="data-item p-0 mr-3"
                          onClick={
                            mediaItems.find(
                              (c) => c.type === MEDIA_TYPE_VIDEO
                            ) ||
                              channels.find(
                                (c) => c.platform === PLATFORM_YOUTUBE
                              ) ||
                              channels.find((c) => c.platform === PLATFORM_TIKTOK)
                              ? null
                              : openImage
                          }
                        >
                          <span className="z-0 d-flex justify-around">
                            {mediaItems.find(
                              (c) => c.type === MEDIA_TYPE_VIDEO
                            ) ||
                              channels.find(
                                (c) => c.platform === PLATFORM_YOUTUBE
                              ) ||
                              channels.find(
                                (c) => c.platform === PLATFORM_TIKTOK
                              ) ? (
                              <Img style={{ height: "24px" }} src={iconNotImage} alt="image" />
                            ) : (
                              <Img style={{ height: "24px" }} src={iconImage} alt="image" />
                            )}
                            <UncontrolledTooltip
                              autohide={false}
                              placement={"top"}
                              target={"i"}
                            >
                              Chọn ảnh
                            </UncontrolledTooltip>
                          </span>
                        </div>
                        <div
                          id="v"
                          className="data-item p-0 mr-4 border-b-0"
                          onClick={
                            mediaItems.find(
                              (c) =>
                                c.type === MEDIA_TYPE_IMAGE ||
                                c.type === MEDIA_TYPE_VIDEO
                            )
                              ? null
                              : openVideo
                          }
                          style={{ borderBottom: "0" }}
                        >
                          <span className=" z-0 d-flex justify-around">
                            {mediaItems.find(
                              (c) =>
                                c.type === MEDIA_TYPE_IMAGE ||
                                c.type === MEDIA_TYPE_VIDEO
                            ) ? (
                              <Img style={{ height: "24px" }} src={iconNotVideo} alt="Video" />
                            ) : (
                              <Img style={{ height: "24px" }} src={iconVideo} alt="Video" />
                            )}
                            <UncontrolledTooltip
                              autohide={false}
                              placement={"top"}
                              target={"v"}
                            >
                              Chọn video
                            </UncontrolledTooltip>
                          </span>
                        </div>
                        <div style={{ borderRight: "2px solid #E5E7EB", width: "2px", height: "24px" }}></div>
                        <UncontrolledDropdown >
                          <DropdownToggle caret
                            style={{
                              backgroundColor: "white",
                              display: "flex",
                              border: "0",
                              gap: "8px",
                              padding: "0",
                              marginLeft: "24px"
                            }}>
                            <Img
                              src={iconSpin}
                              style={{ height: "24px" }}
                            />
                            <span style={{ color: "#374151" }}>Spin</span>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem>
                              <FormGroup>
                                <div
                                  className={`align-items-stretch justify-content-start d-flex`}
                                  onClick={() => {
                                    setOpenIcon(!openIcon);
                                    if (!openIcon) setOpenSuggestText(false);
                                  }}
                                >

                                  <Img
                                    alt=""
                                    src={icon}
                                    height={"16px"}
                                  />
                                  <span style={{ marginLeft: "4px" }}> Icon ngẫu nhiên</span>

                                </div>
                              </FormGroup>
                            </DropdownItem>
                            <DropdownItem>
                              <FormGroup>
                                <div
                                  className={`align-items-stretch justify-content-start d-flex`}
                                  onClick={() => {
                                    setOpenSuggestText(!openSuggestText);
                                    if (!openSuggestText) setOpenIcon(false);
                                  }}
                                >

                                  <Img
                                    alt=""
                                    src={word}
                                    height={"16px"}
                                  />
                                  <span style={{ marginLeft: "4px" }}>Nội dung ngẫu nhiên</span>

                                </div>
                              </FormGroup>
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>

                        <div className={style.aiAssitantBttn} onClick={handleOpenAi}>
                          <Img
                            src={iconAi}
                            style={{ height: "24px" }}
                          />
                          <span style={{ color: "#374151 !important" }}>Trợ lý Chat GPT</span>

                        </div>
                      </div>
                    </div>
                  </div>
                </FormGroup>

                {openSuggestText && (
                  <FormGroup>
                    <div
                      className={`form-control-wrap ${style.divIcon}`}
                      onClick={() => handleAddText()}
                    >
                      <span style={{ color: "#333333" }}>
                        Thêm trường giá trị ngẫu nhiên (ngăn cách bằng ký tự
                        “|”):
                      </span>
                      <br></br>
                      #text &#123; Giá trị 1|Giá trị 2|Giá trị 3|.....&#125;{" "}
                      <br></br>
                      <span style={{ color: "#4F4F4F" }}>
                        Ví dụ: Công cụ SO9 hỗ trợ{" "}
                      </span>
                      <span style={{ color: "#069697" }}>
                        #text&#123;reup đa kênh|đăng bài số lượng lớn|đăng đa
                        nền tảng|tải hàng loạt video&#125;
                      </span>
                      , giúp bạn tăng tối đa năng suất lao động{" "}
                      <span style={{ color: "#069697" }}>
                        #text&#123;;#marketing #so9|#mmo #so9&#125;
                      </span>
                      <br></br>
                      Chú ý: Nếu bạn chỉ nhập "#text&#123;&#125;" hệ thống sẽ
                      báo lỗi, phím tắt "#" = "#text&#123;"
                    </div>
                  </FormGroup>
                )}
                {openIcon &&
                  listIcon &&
                  Object.keys(listIcon)?.map((e) => (
                    <FormGroup>
                      <div
                        className={`form-control-wrap ${style.divIcon}`}
                        onClick={() => handleAddIcon(e)}
                      >
                        @icon&#123;{`${e}`}&#125; = {listIcon[e].toString()}
                      </div>
                    </FormGroup>
                  ))}
                <FormGroup>
                  {mediaItems.filter((c) => c.type === MEDIA_TYPE_IMAGE)
                    .length > 0 && (
                      <MediaItemImage
                        //item={item}
                        addMedia={openImage}
                        mediaItems={mediaItems}
                        isMultiMedia={isMultiMedia}
                        onUpdateCaption={(index, caption) =>
                          onUpdateMedia(index, { caption })
                        }
                        removeMedia={(index) => onRemoveMedia(index)}
                        update={(index, altText) =>
                          onUpdateMedia(index, { altText })
                        }
                      //key={`selectedFile-${index}`}
                      />
                    )}
                  {mediaItems.map(
                    (f, i) =>
                      f.type === MEDIA_TYPE_VIDEO && renderMediaItem(f, i)
                  )}
                  <div className="d-flex align-items-center justify-content-center">
                    <div>
                      <input {...getInputImageProps()} />
                    </div>
                  </div>
                  <div {...getRootVideoProps()}>
                    <input {...getInputVideoProps()} />
                  </div>
                  {isAddImage && (
                    <FormText color="danger">
                      Bạn cần chọn ảnh hoặc video hoặc thêm nội dung bài đăng
                    </FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  {(mobileView && openAi) && renderAiAssitant("haft")}
                </FormGroup>
                {channels.find(
                  (c) => c.platform === PLATFORM_FACEBOOKGROUP
                ) && (
                    <FormGroup>
                      <ModeFaceGroup
                        channels={channels}
                        setSelectedUserPostOption={setSelectedUserPostOption}
                        selectedUserPostOption={selectedUserPostOption}
                        onUpdateChannel={onUpdateChannel}
                      />
                    </FormGroup>
                  )}
                <FormGroup>
                  <PostMode
                    toggleSaveScheduled={toggleSaveScheduled}
                    toggleSaveAndPost={toggleSaveAndPost}
                    showScheduleSelectTime={showScheduleSelectTime}
                    handleOptionChangePost={handleOptionChangePost}
                    handleScheduledAt={handleScheduledAt}
                    handleChangeTimeStart={handleChangeTimeStart}
                    handleChangeTimeSmart={handleChangeTimeSmart}
                    handleChangeTimeEnd={handleChangeTimeEnd}
                    scheduledAt={scheduledAt}
                    timeStart={timeStart}
                    timeSo9Smart={timeSo9Smart}
                    timeFinish={timeFinish}
                    selectedOptionPost={selectedOptionPost}
                  />
                </FormGroup>
                {channels.find((c) => c.platform === PLATFORM_YOUTUBE) && (
                  <FormGroup>
                    <YoutubeVideoOption
                      channels={channels}
                      youtubeOption={youtubeOption}
                      defaultYoutubeOption={defaultYoutubeOption}
                      setDefaultYouTubeOption={setDefaultYouTubeOption}
                    />
                  </FormGroup>
                )}
                {channels.find((c) => c.platform === PLATFORM_PINTEREST) && (
                  <FormGroup>
                    <PinterestOption
                      channels={channels}
                      pinterestOption={pinterestOption}
                      valid={pinEmpty}
                    />
                  </FormGroup>
                )}

                {channels.find((c) => c.platform === PLATFORM_TIKTOK) && (
                  <FormGroup>
                    <TikTokVideoOption tiktokOption={tiktokOption} />
                  </FormGroup>
                )}
              </div>
            </Card>
            <div className={style.btnCreatePost}>
              <FormGroup className="d-flex justify-content-end">
                <div>
                  <Button
                    className={`${style.btnUploadPostDropdown}`}
                    disabled={isUploading}
                  >
                    {isUploading ? (
                      <div
                        className="spinner-border"
                        role="status"
                        style={{
                          width: "16px",
                          height: "16px",
                        }}
                      ></div>
                    ) : (
                      <span
                        style={{ textTransform: "uppercase", color: "#fff" }}
                      >
                        {createPostText()}
                      </span>
                    )}
                  </Button>
                </div>
              </FormGroup>
            </div>
          </form>
        </div>
        {!mobileView && !openAi ? (
          <div style={{ width: "50%" }} className="align-self-stretch">
            <div className="position-relative d-flex w-100 h-100">
              <div className="position-absolute w-100 h-100">
                {isShowCloudMedia ? (
                  <CloudMedia
                    className={`h-100`}
                    mediaItems={mediaItems}
                    close={toggleIsShowCloudMedia}
                    addMedia={onAppendMedia}
                  />
                ) : (
                  <div className="previewWrapper h-100">
                    <SimpleBar style={{ height: "100%" }}>
                      <div className="mx-5 mb-4 mt-4">
                        <h2 style={{ fontSize: "16px" }}>Xem trước</h2>
                        <div className="d-flex">
                          {/* <div>
                            <Icon name="info-fill" className="mr-1"></Icon>
                          </div> */}
                          <span>
                            Xem trước gần đúng cách nội dung của bạn sẽ hiển thị
                            khi xuất bản. Qúa trình kiểm tra và cập nhật của
                            mạng xã hội có thể ảnh hưởng đến giao diện cuối
                            cùng. Báo cáo sự khác biệt mà bạn nhận thấy.
                          </span>
                        </div>
                        {channels.length > 0 ? (
                          renderPreview(channels)
                        ) : (
                          <DefaultPreview className="item" />
                        )}
                      </div>
                    </SimpleBar>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          renderAiAssitant("full")
        )}


      </div>
      <ModalYoutubeOption
        isShowModal={isOpenModalYoutube}
        setIsShowModal={setIsOpenModalYoutube}
        defaultYoutubeOption={defaultYoutubeOption}
        setDefaultYouTubeOption={setDefaultYouTubeOption}
      />
      <ModalSuccess
        modalSuccess={modalSuccess}
        toggleSuccess={toggleSuccess}
        numberChannel={numberChannelSuccess}
      />
      <ModalWarningSM
        modalWarning={modalWarning}
        toggleWarning={toggleWarning}
        channels={channels.filter(
          (item) => item.setting_distribute_time.length === 0 && !item?.is_setting_advance
        )}
      />
      <ModalAlertFailed modalFailed={modalFailed} toggleFailed={toggleFailed} />
    </>
  );
};
const ModalYoutubeOption = React.lazy(() =>
  import("./dialog/ModalYoutubeOption")
);
const ModalWarningSM = React.lazy(() =>
  import("../../components/Modal/ModalWarningSM")
);

export default PostMulti;
