import { Link } from "react-router-dom";
import { Button, Modal, ModalBody } from "reactstrap";
import { Icon } from "../../../components/Component";

const CreatePostModalSuccess = ({ ...props }) => {
  const { modalSuccess, toggleSuccess, numberChannel } = props;
  return (
    <Modal isOpen={modalSuccess} toggle={toggleSuccess}>
      <ModalBody className="modal-body-lg text-center">
        <div className="nk-modal">
          <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-check bg-success"></Icon>
          <h4 className="nk-modal-title">Hoàn thành</h4>
          <div className="nk-modal-text">
            <div className="caption-text">
              Bạn đã đăng bài thành công lên <strong>{numberChannel}</strong> kênh
            </div>
            {/* <span className="sub-text-sm">
              Learn when you reciveve bitcoin in your wallet.{" "}
              <a href="#link" onClick={(ev) => ev.preventDefault()}>
                {" "}
                Click here
              </a>
            </span> */}
          </div>
          <div className="nk-modal-action">
            <Link to={`${process.env.PUBLIC_URL}/manage_posts`}>
              <Button color="primary" size="lg" className="btn-mw">
                OK
              </Button>
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CreatePostModalSuccess;
