import React, { useState } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-so9.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Spinner, FormGroup, Alert, Container, Row, Col, Card } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TextFieldAuth, PasswordFieldAuth } from "../../commons/TextField/TextFieldAuth";
import style from "./Auth.module.scss"
import styles from "../../So9/components/AuthNew/index.module.scss"
import { validateEmail } from "../../libs/helper";
import { register as registerSo9 } from "../../api/auth";
import useCheckLogin from "../../hooks/useCheckLogin";
import useQuery from "../../hooks/useQuery";
import Cookies from "js-cookie";
import { useFormik } from "formik";
import AuthSlider from "../../So9/components/AuthNew/authCarousel";
import MessSucces from "../../So9/components/AuthNew/MessSuccess";
const Register = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [errorVal, setError] = useState("");
  const [success, setSucces] = useState(false);
  const [registerData, setRegisterData] = useState();
  const { t } = useTranslation();
  useCheckLogin();
  const query = useQuery();
  const tokenInvite = query.get('tokenInvite')
  const emailInvite = query.get('emailInvite')
  const utmSource = query.get('utm_source')
  const utmMedium = query.get('utm_medium')
  const utmCampaign = query.get('utm_campaign')

  Yup.addMethod(Yup.string, "isValidEmail", function (errorMessage) {
    return this.test('is-valid-email', errorMessage, function (value) {
      const { path, createError } = this;
      value = validateEmail(value);
      if (!value) {
        return createError(path, errorMessage);
      }
      return true;
    });
  });
  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('validate_field_name_required'))
      .min(4, t('validate_field_name_character_required', { length: 4 })),
    email: Yup.string()
      .required(t('validate_field_email_required'))
      .isValidEmail(t('validate_field_email')),
    password: Yup.string()
      .required(t('validate_field_password_required'))
      .min(6, t('validate_field_password_character_required', { length: 6 })),
    passwordConfirmation: Yup.string()
      .required(t('validate_field_re_password_required'))
      .oneOf([Yup.ref('password')], t('validate_field_password_confirm_not_match')),
  })

  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
    setValue
  } = useForm(
    {
      resolver: yupResolver(formSchema),
      defaultValues: {
        'email': emailInvite ?? ''
      }
    }
  );

  const handleFormSubmit = (formData) => {
    const refCookies = Cookies.get('refCode');
    setLoading(true);
    if (tokenInvite) formData.tokenInvite = tokenInvite;
    if (utmSource) formData.utm_source = utmSource;
    if (utmCampaign) formData.utm_campaign = utmCampaign;
    if (utmMedium) formData.utm_medium = utmMedium;
    if (refCookies) {
      formData.ref_code = refCookies
    }
    registerSo9(formData).then((res) => {
      if (res.status) {
        setRegisterData(res);
        setSucces(true);
      } else {
        setError(res.message);
        setLoading(false);
        setSucces(false);
      }
    }).catch((err) => {
      setError(err?.response?.data?.message);
      setLoading(false);
      setSucces(false);
    })
  };
  return (
    <React.Fragment>
      <Head title={t('auth_register')} />
      <PageContainer>

        <div className={`${styles.auth_page_wrapper} ${styles.auth_bg_cover} py-5 d-flex justify-content-center align-items-center min-vh-100 `}>
          <div className={`${styles.bg_overlay}`}></div>
          <div className="auth-page-content overflow-hidden pt-lg-5">
            <Container style={{ maxWidth: "1100px" }}>
              <Row>
                <Col lg={12}>
                  <Card className="overflow-hidden card-bg-fill galaxy-border-none" >
                    <Row className="g-0">
                      <AuthSlider />

                      <Col lg={6}>
                        {!success ? (
                          <div className="p-lg-5 p-4">
                            <div>
                              <h5 className="text-primary">{t("new_auth.register_header")}</h5>
                              <p className="text-muted">{t("new_auth.register_mess")}</p>
                            </div>

                            <div className="mt-4">
                              {errorVal && (
                                <div className="mb-3">
                                  <Alert color="danger" className="alert-icon">
                                    {" "}
                                    <Icon name="alert-circle" /> {errorVal}{" "}
                                  </Alert>
                                </div>
                              )}
                              <form onSubmit={handleSubmit(handleFormSubmit)}>
                                <FormGroup>
                                  <TextFieldAuth
                                    field={'phone'}
                                    current={watch('phone')}
                                    register={register}
                                    setValue={setValue}
                                    errors={errors.phone}
                                    title={t('auth_register_phone')}
                                    placeholder={t('auth_register_phone_placeholder')} />
                                </FormGroup>
                                <FormGroup>
                                  <TextFieldAuth
                                    field={'name'}
                                    current={watch('name')}
                                    register={register}
                                    setValue={setValue}
                                    required={true}
                                    errors={errors.name}
                                    title={t('auth_register_name')}
                                    placeholder={t('auth_register_name_placeholder')} />
                                </FormGroup>
                                <FormGroup>
                                  <TextFieldAuth
                                    field={'email'}
                                    current={watch('email')}
                                    register={register}
                                    setValue={setValue}
                                    required={true}
                                    errors={errors.email}
                                    isEmail={true}
                                    readonly={emailInvite && validateEmail(emailInvite) ? emailInvite : false}
                                    title={t('auth_register_email')}
                                    placeholder={t('auth_register_email_placeholder')} />
                                </FormGroup>
                                <FormGroup>
                                  <PasswordFieldAuth
                                    field={'password'}
                                    current={watch('password')}
                                    register={register}
                                    setValue={setValue}
                                    required={true}
                                    errors={errors.password}
                                    title={t('auth_register_password')}
                                    minLength={4}
                                    placeholder={t('auth_register_password_placeholder')} />
                                </FormGroup>

                                <FormGroup>
                                  <PasswordFieldAuth
                                    field={'passwordConfirmation'}
                                    current={watch('passwordConfirmation')}
                                    register={register}
                                    setValue={setValue}
                                    required={true}
                                    errors={errors.passwordConfirmation}
                                    title={t('auth_register_password_confirm')}
                                    placeholder={t('auth_register_password_confirm_placeholder')} />
                                </FormGroup>

                                <div className="mb-4">
                                  <p className="mb-0 fs-12 text-muted font-italic" >{t('new_auth.register_term')}<Link to="/auths/terms" target={"_blank"} className={` ${styles.text_decoration} text-primary text-decoration-underline fst-normal fw-semibold ml-1`}> {t('new_auth.term_user')}</Link></p>
                                </div>
                                <FormGroup>
                                  <Button color="success"
                                    // className={`btn-block ${style.LoginButton}`} 
                                    className="w-100 d-flex justify-content-center align-items-center"
                                    type="submit">
                                    {loading ? <Spinner size="sm" color="light" /> : t('auth_register_button')}
                                  </Button>
                                </FormGroup>
                              </form>
                            </div>

                            <div className="mt-5 text-center">
                              <p className="mb-0">{t("auth_register_already_have_account")}<Link to="/auth-login" className={` ${styles.text_decoration} fw-semibold text-primary text-decoration-underline ml-1`}> {t("auth_sign_in_button")}</Link> </p>
                            </div>
                          </div>
                        ) : (<MessSucces
                          message={t("new_auth.register_succes_mess")}
                          registerData={registerData}
                        />)}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
            <div className="w-100 d-flex justify-content-center align-items-center mt-5 gap-4" style={{ position: "relative" }}>
              <Link to="/auths/terms" target="_blank" className={"text-muted"}>{t("new_auth.term_service")}</Link>
              <Link to="/auths/policy" target="_blank" className={"text-muted"}>{t("footer_privacy")}</Link>
            </div>
          </div>
        </div>
      </PageContainer>
    </React.Fragment>
  );
};
export default Register;
