import React, { useState } from "react";
import { Img } from "../../../components/Component";
import IconComment from "../../images/icons/preview-instagram-comment-reels.svg";
import IconLike from "../../images/icons/preview-instagram-like-reels.svg";
import IconShare from "../../images/icons/preview-instagram-share-reels.svg";
import IconArrowUpRight from "../../images/icons/icon-arrow-up-right.svg"
import { CONTENT_LIMIT_SEE_MORE } from "../../utils/Utils";
import "./_preview-instagram.scss";
import style from "./index.module.scss";
import {
  getPlatformIconFromLabel
} from "../../utils/Utils";

const PreviewInstagram = ({ ...props }) => {
  const [openPreviewInsta, setOpenPreviewInsta] = useState(true)

  const handleOpenPreviewInsta = () => {
    setOpenPreviewInsta(!openPreviewInsta)
    const Elmchevron = document.getElementById(`chevron_previewInsta`);
    const Elmcontent = document.getElementById("content_previewInsta");
    if (!openPreviewInsta) {
      Elmchevron.classList.add(`${style.openChevronDown}`)
      Elmchevron.classList.remove(`${style.openChevronUp}`)
      Elmcontent.classList.add(`${style.openContentDown}`)
      Elmcontent.classList.remove(`${style.openContentUp}`)
    }
    else {
      Elmchevron.classList.add(`${style.openChevronUp}`)
      Elmchevron.classList.remove(`${style.openChevronDown}`)
      Elmcontent.classList.add(`${style.openContentUp}`)
      Elmcontent.classList.remove(`${style.openContentDown}`)
    }
  }

  const { channel, content, mediaItems, className } = props;
  const displayContent =
    content?.length <= CONTENT_LIMIT_SEE_MORE ? (
      content
    ) : (
      <>
        {content?.slice(0, CONTENT_LIMIT_SEE_MORE)}...
        <span className="more">more</span>
      </>
    );
  return (
    <div className={`previewInstagram ${className || ""}`}>
      <div className="d-flex align-items-stretch border-bottom">
        <div
          className="flex-fill p-2 fw-medium"
          style={{ color: "#333333", fontFamily: "Roboto" }}
        >
          <Img
            src={getPlatformIconFromLabel("instagram")}
            size="circle"
            width="16px"
            style={{ marginRight: "4px" }}
          />
          Instagram
        </div>
        <div id="chevron_previewInsta" className={`card-tools p-2 ${style.openMedia}`} onClick={handleOpenPreviewInsta}>
          <svg width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" /> </svg>
        </div>
      </div>
      <div id="content_previewInsta" style={{
        transition: "max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s",
        willChange: "max-height",
        position: "relative",
        overflow: "hidden"
      }}>
        {
          mediaItems.length > 0 &&
          <div className="imageWrapper">
            <div className={style.previewInstagram__frame9_16}>
              <Img src={mediaItems[0]?.previewImages.url} alt="something" style={{ objectFit: "cover", margin: "auto" }} />
              <div className="d-flex p-1 justify-between w-full absolute bottom-0 ">
                <div className={`info ${style.previewInstagram__name}`}>
                  <div className={style.item__avarta}>
                    <Img
                      className="avatar"
                      src={channel.picture}
                      alt="avatar"
                      size="circle"
                      width="24px"
                      style={{ marginRight: "4px" }}
                    />
                    {channel.name}
                  </div>
                  {content?.length > 0 && <div>{displayContent}</div>}
                  <Img src={IconArrowUpRight} /> {channel.name} . Original Audio
                </div>
                <div className={style.previewInstagram__avarta}>
                  <Img
                    className="avatar"
                    src={channel.picture}
                    alt="avatar"
                    size="square"
                    width="32px"
                  />
                </div>
              </div>
              <div className={style.previewInstagram__item}>
                <div className={style.item__interact}>
                  <Img
                    src={IconLike}
                    alt="like"
                    size="square"
                    width="32px"
                  />
                  <div>351.2k</div>
                </div>
                <div className={style.item__interact}>
                  <Img
                    src={IconComment}
                    alt="comment"
                    size="square"
                    width="32px"
                  />
                  <div>6562</div>
                </div>
                <div className={style.item__interact}>
                  <Img
                    src={IconShare}
                    alt="share"
                    size="square"
                    width="32px"
                  />
                  <div>16.2k</div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default PreviewInstagram;
