import React, { useEffect, useRef, useState } from "react";
import { Button, Spinner } from "reactstrap";
import { Icon } from "../../../components/Component";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import style from "./index.module.scss";
import { MEDIA_TYPE_IMAGE } from "../../utils/Utils";
import { uploadImage } from "../../../api/createPost";
import {
  followPage,
  getFollowingList,
  searchPage,
  unFollowPage,
} from "../../../api/researchPage";
import _ from "lodash";
import BLUE_VERIFIED_ICON from "../../../images/icons/blue_verified.svg";
import { BLUE_VERIFIED } from "../../utils/Utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { setChannelFollows } from "../../reducers/researchCompetitorSlice";
import { Tooltip } from "reactstrap";

const TransferList = ({ showModalAddFanPage, channelFollow }) => {
  const [txSearch, setTxSearch] = useState("");

  const [nextPage, setNextPage] = useState("");
  const [tooltipOpen, setOpen] = useState(-1);

  const toggle = (id) => {
    setOpen(id);
  };
  const isScrollToEnd = useRef(false);
  const [leftList, setLeftList] = useState([]);
  const [rightList, setRightList] = useState(channelFollow);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const projectId = userInfo.last_project_active;
  useEffect(() => {
    if (projectId) {
      searchPage(projectId, txSearch).then((resp) => {
        if (resp.status) {
          const newList = resp.data?.data || resp.data;
          setNextPage(resp.data?.paging?.cursors?.after || "");
          setLeftList(newList);
        }
        setIsLoading(false);
      });
    }
  }, [txSearch]);

  async function createFile(url) {
    const response = await fetch(url);
    const data = await response.blob();
    const contentType = response.headers.get("Content-Type");
    const mimeType = contentType.split("/")[1];
    return new File([data], `${Date.now().toString()}.${mimeType}`, {
      type: contentType,
    });
  }

  const handleShowPage = (item) => {
    if (rightList.filter((i) => i.is_show).length >= 9 && !item.is_show) {
      toast.error("Không thể show quá 9 page");
      return;
    }
    const newState = !item.is_show;
    const newItem = { ...item, is_show: newState };
    const rightListUpdate = rightList.map((v) => {
      if ((v._id && v._id === item._id) || (v.id && v.id === item.id)) {
        return newItem;
      }
      return v;
    });
    setRightList([...rightListUpdate]);
  };

  const uploadMedia = async (imageUrl) => {
    try {
      if (imageUrl) {
        const file = await createFile(imageUrl);

        const data = new FormData();
        data.append("image", file);
        data.append("projectId", projectId);
        data.append("folder", "channels");
        const response = await uploadImage(data);
        const { name, url } = response.data;
        const newMedia = {
          type: MEDIA_TYPE_IMAGE,
          name: name,
          url: url,
          altText: name,
        };
        return newMedia;
      }

      return null;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const handleSaveChannels = async (data) => {
    setIsSaving(true);
    const newChannelFollows = [];
    for (const [index, item] of data.entries()) {
      newChannelFollows.push({
        id: item.id || item.platform_id,
        name: item.name,
        avatar: item.avatar ?? (await uploadMedia(item.picture.url)).url,
        platform: 1,
        is_show: item.is_show,
        verification_status: [BLUE_VERIFIED, true].includes(
          item.verification_status
        ),
        position: index + 1,
      });
      // }
    }

    if (newChannelFollows.length) {
      const data = { project_id: projectId, channels: newChannelFollows };
      followPage(data).then((resp) => {
        if (resp.status) {
          // show toast
          getFollowingList(projectId).then((resp) =>
            dispatch(setChannelFollows(resp.data))
          );
          showModalAddFanPage();
          toast.success("Đã cập nhật theo dõi kênh thành công");
        }
        setIsSaving(false);
      });
    }
  };

  const handleUnFollowPage = async (pageId, name) => {
    unFollowPage(projectId, pageId).then((resp) => {
      if (resp.status) {
        toast.success(`Đã hủy theo dõi kênh ${name}`);
        getFollowingList(projectId).then((resp) =>
          dispatch(setChannelFollows(resp.data))
        );
      }
    });
  };

  const [hoverIndex, setHoverIndex] = useState(-1);
  const handleMouseEnter = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(-1);
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(rightList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setRightList(items);
  }

  const handleOnclickTrash = (item) => {
    removeRightItem(item);
    item._id && handleUnFollowPage(item._id || item.id, item.name);
  };

  const transferToRight = (itemSelected) => {
    itemSelected.is_show = true;
    const updatedRightList = [itemSelected, ...rightList].map((item, index) => {
      return index > 8 && item?.is_show ? { ...item, is_show: false } : item;
    });

    if (updatedRightList.length > 30) {
      toast.error("Chỉ được thêm tối đa 30 Fanpage vào danh sách theo dõi");
      return;
    }

    const updatedLeftList = leftList.filter(
      (item) => !(itemSelected.id === item.id ?? itemSelected._id === item._id)
    );

    setRightList(updatedRightList);
    setLeftList(updatedLeftList);
  };

  const removeRightItem = (itemSelected) => {
    const updatedRightList = rightList.filter((item) => {
      if (item?.id) {
        return !(item.id === itemSelected.id);
      } else {
        return !(item._id === itemSelected._id);
      }
    });

    if (itemSelected?.is_show && rightList.length > 9) {
      const itemIndex = updatedRightList.findIndex((e) => !e?.is_show);
      Object.assign(updatedRightList[itemIndex], { is_show: true });
    }
    setRightList(updatedRightList);
  };

  const onScroll = async (event) => {
    const element = event.target;
    if (
      Math.abs(
        element.scrollHeight - element.scrollTop - element.clientHeight
      ) < 15 &&
      isScrollToEnd.current === false
    ) {
      if (nextPage !== "") {
        isScrollToEnd.current = true;
        await searchPage(projectId, txSearch, nextPage).then((resp) => {
          if (resp.status) {
            const newList = resp.data?.data || resp.data;
            setNextPage(resp.data?.paging?.cursors?.after || "");
            setLeftList([...leftList, ...newList]);
          }
        });
        isScrollToEnd.current = false;
      }
    }
  };

  return (
    <>
      <div className={`${style.trackingSetting}`}>
        <div className={`${style.searchPage} mr-2`}>
          <div className={`${style.headerResearch}`}>Tìm kiếm trang</div>
          <div className={`${style.bodySearch}`} onScroll={onScroll}>
            <div
              className="form-control-wrap mt-2 text-center mb-2"
              style={{ padding: "1px", justifyContent: "center" }}
            >
              <input
                className={`${style.inputSearchPage} rounded`}
                type="text"
                id="tx-search"
                name="search"
                onChange={_.debounce((e) => {
                  setIsLoading(true);
                  setTxSearch(e.target.value);
                }, 500)}
                placeholder="Nhập theo tên, id, link trang"
              />
            </div>

            {leftList.length > 0 &&
              !isLoading &&
              txSearch &&
              leftList.map((item, i) => (
                <div
                  style={{
                    borderBottom: "1px solid #F3F4F6",
                    background: hoverIndex === i && "#F1F5FF",
                    display: "block",
                    minWidth: "200px",
                  }}
                  className="d-flex"
                  onMouseEnter={() => handleMouseEnter(item.id)}
                  onMouseLeave={() => handleMouseLeave()}
                >
                  <li className={style.pageItemSearchList} key={item.id}>
                    <div style={{ justifyContent: "center" }}>
                      <img
                        className={style.pageItemSearchListImg}
                        alt=""
                        src={item.picture?.url || item.avatar}
                      ></img>
                    </div>

                    <div className={style.pageItemSearchListName}>
                      <p className={style.pageItemSearchListNameChild}>
                        {item.name}
                      </p>
                    </div>

                    {item.verification_status === BLUE_VERIFIED && (
                      <div
                        style={{
                          paddingLeft: "2%",
                        }}
                      >
                        <img
                          alt=""
                          className={style.imgBlueVerification}
                          src={BLUE_VERIFIED_ICON}
                        ></img>
                      </div>
                    )}
                  </li>
                  {hoverIndex === item.id && (
                    <div className="d-flex align-items-center mr-2">
                      <Button
                        disabled={
                          rightList.some(
                            ({ platform_id, id }) =>
                              hoverIndex === platform_id ||
                              (id && hoverIndex === id)
                          )
                            ? true
                            : false
                        }
                        color="primary"
                        size="sm"
                        onClick={() => {
                          transferToRight(item);
                        }}
                      >
                        Chọn
                      </Button>
                    </div>
                  )}
                </div>
              ))}
            {isLoading && (
              <div className={style.loadingSearchPage}>
                <Spinner color="primary" />
              </div>
            )}
            {isScrollToEnd.current && (
              <div style={{ textAlign: "center" }}>
                <Spinner color="primary" />
              </div>
            )}
          </div>
        </div>
        <div className={`${style.searchPage} mr-2`}>
          <div className={`${style.headerResearch}`}>
            Danh sách Fanpage đang theo dõi ({rightList.length})
          </div>
          <div className={`${style.bodySearch}`}>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="rightList">
                {(provided) => (
                  <ul {...provided.droppableProps} ref={provided.innerRef}>
                    {rightList.map((item, index) => {
                      return (
                        <Draggable
                          key={item._id || item.id}
                          draggableId={item._id || item.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              style={{
                                display: "flex",
                                borderBottom: "1px solid #F3F4F6",
                                background:
                                  hoverIndex === index + "right" && "#F1F5FF",
                              }}
                              onMouseEnter={() =>
                                handleMouseEnter(index + "right")
                              }
                              onMouseLeave={() => handleMouseLeave()}
                            >
                              <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  className={
                                    style.followingChannelListInModalEditPage
                                  }
                                >
                                  <div className={style.channelItemSwapIcon}>
                                    {hoverIndex === index + "right" && (
                                      <Icon name="swap"></Icon>
                                    )}
                                  </div>

                                  <div
                                    className={style.channelItemAvatar}
                                    onClick={() => handleShowPage(item)}
                                  >
                                    <div className={style.imgAvatarChild}>
                                      {item.is_show === true ? (
                                        <img
                                          alt=""
                                          src={item.avatar || item.picture.url}
                                        ></img>
                                      ) : (
                                        <div>
                                          <div
                                            id={`eyeOff${item._id || item.id}`}
                                            onMouseLeave={() => toggle(null)}
                                            onClick={() => toggle(null)}
                                          >
                                            <Icon name="eye-off-fill"></Icon>
                                          </div>
                                          <Tooltip
                                            style={{
                                              maxWidth: "100%",
                                            }}
                                            placement="bottom"
                                            isOpen={
                                              tooltipOpen ===
                                              `eyeOff${item._id || item.id}`
                                            }
                                            target={`eyeOff${
                                              item._id || item.id
                                            }`}
                                            toggle={() =>
                                              toggle(
                                                `eyeOff${item._id || item.id}`
                                              )
                                            }
                                          >
                                            Màn hình theo dõi sẽ hiển thị 9
                                            trang đầu tiên, nếu muốn xem trang
                                            này hãy chuyển lên vị trí trên và
                                            bấm lưu lại
                                          </Tooltip>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className={style.channelItemName}>
                                    <p className={style.channelItemNameChild}>
                                      {item.name}
                                    </p>
                                  </div>

                                  {[BLUE_VERIFIED, true].includes(
                                    item.verification_status
                                  ) && (
                                    <div
                                      style={{
                                        paddingLeft: "2%",
                                      }}
                                    >
                                      <img
                                        alt=""
                                        className={style.imgBlueVerification}
                                        src={BLUE_VERIFIED_ICON}
                                      ></img>
                                    </div>
                                  )}
                                </div>
                              </li>
                              <div className={style.trashInModalEditPage}>
                                {hoverIndex === index + "right" && (
                                  <Icon
                                    name="trash"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleOnclickTrash(item)}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </div>
      <div
        className={`d-flex justify-content-end mt-4 align-items-center ${style.btnInModalEditPage}`}
      >
        <Button className={style.btnCancel} onClick={showModalAddFanPage}>
          Hủy
        </Button>
        <div>
          <Button
            className={style.btnSave}
            type="button"
            onClick={() => handleSaveChannels(rightList)}
          >
            {isSaving ? <Spinner size="sm"></Spinner> : "Lưu lại"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default TransferList;
