import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({ content, placeholder, onChange, onSave }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(null);
  const quillRef = useRef(null);

  useEffect(() => {
    if (content !== t("task_management.detail.add_description")) {
      setValue(content);
    }
  }, []);

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["blockquote", "link", "image"],
    ],
  };

  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "blockquote",
    "link",
    "image",
  ];

  const handleChange = (content, delta, source, editor) => {
    const text = editor.getText();
    if (text.length > 500) {
      const quill = quillRef.current.getEditor();
      const excessLength = text.length - 500;
      quill.deleteText(500, excessLength);
      return;
    }
    setValue(content);
    onChange(content);
    onSave(content);
  };

  return (
    <div className="text-editor-container" style={{ width: "100%" }}>
      <ReactQuill
        ref={quillRef}
        style={{
          width: "100%",
          maxHeight: "600px",
          overflowY: "hidden",
          backgroundColor: "#fff",
        }}
        theme="snow"
        value={value}
        modules={modules}
        formats={formats}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default TextEditor;
