import React from "react";
import DetailTitle from "./DetailTitle";
import DetailDropdown from "./DetailDropdown";
import DetailDateSelect from "./DetailDateSelect";
import DetailDescription from "./DetailDescription";
import DetailSubtask from "./DetailSubtask";
import DetailContent from "./DetailContent";
import DetailAttachment from "./DetailAttachment";
import "../styles.css";

const DetailList = ({
  groupOptions,
  listDetail,
  completed,
  handleTaskDetail,
  isEditing,
  setIsEditing,
  parentTitle,
  setParentTitle,
  title,
  setTitle,
  handleTitleBlur,
  staffOptions,
  handleSelectOwner,
  sectionOptions,
  newSection,
  handleSelectGroup,
  handleSelectSection,
  startDate,
  setStartDate,
  dueDate,
  setDueDate,
  isEndedBeforeToday,
  description,
  handleSaveDescription,
  handleUpdateTask,
  setIsCreateChild,
  isCreateChild,
  listChild,
  handleCompleteChild,
  createChild,
  childTitle,
  setChildTitle,
  isLoadingChild,
  isCreateContent,
  setIsCreateContent,
  linkContent,
  setLinkContent,
  handleCreateLinkContent,
  handleRemoveContent,
  isLoadingContent,
  handleAttachmentSelect,
  selectedAttachment,
  setSelectedAttachment,
  setListDetail,
  setListChild,
  checkLoadMoreSubtaskFirst,
}) => {
  return (
    <div className="body-detail-content">
      <DetailTitle
        parentTitle={parentTitle}
        parent_id={listDetail?.parent_id}
        handleTaskDetail={handleTaskDetail}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        title={title}
        setTitle={setTitle}
        handleTitleBlur={handleTitleBlur}
      />

      <DetailDropdown
        groupOptions={groupOptions}
        listDetail={listDetail}
        staffOptions={staffOptions}
        handleSelectOwner={handleSelectOwner}
        sectionOptions={sectionOptions}
        newSection={newSection}
        handleSelectGroup={handleSelectGroup}
        handleSelectSection={handleSelectSection}
      />

      <DetailDateSelect
        isEndedBeforeToday={isEndedBeforeToday}
        completed={completed}
        startDate={startDate}
        setStartDate={setStartDate}
        dueDate={dueDate}
        setDueDate={setDueDate}
      />

      <DetailDescription
        description={description}
        handleUpdateTask={handleUpdateTask}
        handleSaveDescription={handleSaveDescription}
      />

      {!listDetail?.parent && (
        <DetailSubtask
          listDetail={listDetail}
          listChild={listChild}
          isCreateChild={isCreateChild}
          setIsCreateChild={setIsCreateChild}
          handleCompleteChild={handleCompleteChild}
          handleTaskDetail={handleTaskDetail}
          isLoadingChild={isLoadingChild}
          createChild={createChild}
          childTitle={childTitle}
          setChildTitle={setChildTitle}
          setListChild={setListChild}
          staffOptions={staffOptions}
          setParentTitle={setParentTitle}
          checkLoadMoreSubtaskFirst={checkLoadMoreSubtaskFirst}
        />
      )}

      <DetailContent
        isCreateContent={isCreateContent}
        setIsCreateContent={setIsCreateContent}
        posts={listDetail?.posts}
        isLoadingContent={isLoadingContent}
        linkContent={linkContent}
        setLinkContent={setLinkContent}
        handleCreateLinkContent={handleCreateLinkContent}
        handleRemoveContent={handleRemoveContent}
      />

      <DetailAttachment
        data={listDetail?.attachments}
        setListDetail={setListDetail}
        handleAttachmentSelect={handleAttachmentSelect}
        selectedAttachment={selectedAttachment}
        setSelectedAttachment={setSelectedAttachment}
        handleUpdateTask={handleUpdateTask}
      />
    </div>
  );
};

export default DetailList;
