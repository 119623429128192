import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Icon, UserAvatar } from "../../../../components/Component";
import { LoadingItem } from "../../Dashboard_v2/CommonItem/LoadingItem";
import "../styles.css";
import InfiniteScroll from "react-infinite-scroller";
import { Spinner } from "reactstrap";
import { listCommentTask } from "../../../../api/kanban";
import { RiMoreLine } from "@remixicon/react";
import { findUpper } from "../../../utils/Utils";

const CommentList = ({
  listDetail,
  listComment,
  setListComment,
  handleCommentDelete,
  isLoadingNewComment,
  checkHaveLoadMoreFirst,
}) => {
  const { t } = useTranslation();
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const loading = useRef(false);
  const [hasMore, setHasMore] = useState(false);
  const [isShowBtnLoadMore, setIsShowBtnLoadMore] = useState(false);
  const [isShowIconLoading, setIsShowIconLoading] = useState(false);
  const [page, setPage] = useState(2);

  useEffect(() => {
    setIsShowBtnLoadMore(checkHaveLoadMoreFirst);
  }, [checkHaveLoadMoreFirst]);

  const loadChild = () => {
    if (loading.current) return;
    loading.current = true;

    listCommentTask(userInfo.last_project_active, listDetail?._id, page)
      .then((response) => {
        const newTasksArray = response.data.reverse();
        setListComment((prev) => [...newTasksArray, ...prev]);
        setHasMore(false);
        if (newTasksArray.length >= 20) {
          setIsShowBtnLoadMore(true);
        } else {
          setIsShowBtnLoadMore(false);
        }
        setPage((prevPage) => prevPage + 1);
        setIsShowIconLoading(false);
      })
      .catch(() => {
        loading.current = false;
        setIsShowBtnLoadMore(false);
        setIsShowIconLoading(false);
      })
      .finally(() => {
        loading.current = false;
        setIsShowBtnLoadMore(false);
        setIsShowIconLoading(false);
      });
  };

  const handleOnPressLoadMore = () => {
    setIsShowIconLoading(true);
    setHasMore(true);
  };

  return (
    <div
      className="body-detail-content"
      style={{
        padding: "16px 0px",
        borderTop: "4px solid #ececec",
      }}
    >
      <div
        style={{
          width: "100%",
          minHeight: "300px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          className="detail-title"
          style={{ fontWeight: "500", padding: "0px 16px" }}
        >
          {t("task_management.detail.comment")}
        </div>

        {isShowBtnLoadMore && (
          <div
            onClick={() => handleOnPressLoadMore()}
            className="more-btn"
            style={{ color: "#3b82f6", padding: "0px 16px" }}
          >
            <RiMoreLine size={16} className="text-muted cursor-pointer" />
            <span style={{ marginLeft: "10px" }}>Earlier comment</span>
            {isShowIconLoading && (
              <Spinner
                style={{ width: 14, height: 14, marginLeft: 6 }}
                color="primary"
              />
            )}
          </div>
        )}

        <InfiniteScroll
          loadMore={loadChild}
          hasMore={hasMore}
          useWindow={false}
          isReverse
        >
          <div className="d-flex flex-column" style={{ padding: "8px 0px" }}>
            {listComment?.length > 0 ? (
              listComment?.map((comment, index) => (
                <div key={index} className="comment-container">
                  <div style={{ marginTop: "4px" }}>
                    <UserAvatar
                      className="xs"
                      image={comment?.user?.avatar}
                      text={findUpper(
                        comment?.user?.name || comment?.user?.label
                      )}
                    ></UserAvatar>
                  </div>
                  <div className="d-flex justify-content-between items-start w-100 comment-item">
                    <div
                      style={{ margin: "0 12px" }}
                      className="d-flex flex-column text-align-center"
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                        }}
                      >
                        <span className="comment-title">
                          {comment?.user?.name}
                        </span>
                        <span className="comment-sub">
                          {moment.unix(comment?.time).format("MMM D, h:mm")}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                        }}
                      >
                        <span className="comment-sub">{comment?.content}</span>
                        {comment?.attachments?.length > 0 && (
                          <img
                            className="comment-image"
                            src={comment?.attachments[0]?.url}
                            alt="comment"
                            width={120}
                          />
                        )}
                      </div>
                    </div>
                    {userInfo.id === comment?.user?.id && (
                      <div
                        className="action-icons"
                        style={{ color: "#9599AD", marginTop: "8px" }}
                        onClick={() => handleCommentDelete(comment)}
                      >
                        <Icon
                          name="trash"
                          className={"action-button cursor-pointer"}
                          style={{ fontSize: "16px" }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div></div>
            )}
          </div>
        </InfiniteScroll>

        {isLoadingNewComment && (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "10vh",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingItem />
          </div>
        )}
      </div>
    </div>
  );
};

export default CommentList;
