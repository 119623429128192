import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import Icon from "../../components/icon/Icon";
import {
  Button,

} from "../../components/Component";
import IconLessTrial from "../../../src/images/icons/icon_less_trial.svg"
import twStyle from "../../../src/assets/scss/extend/tailwind-clone/index.module.scss"
import { setNews, setTabNumber } from "../../So9/reducers/layoutSlice";
import { useEffect } from "react";
import moment from "moment";
import { imagesPrice } from "../../So9/utils/Utils";
import { getDomainApp } from "../../utils/Utils";

const News = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const newsType = useSelector((state) => state.layout.newsType);
  const newsInfo = useSelector((state) => state.layout.newsInfo);
  const [isTrial, setIstrial] = useState({});
  const tabSelectedNumber = useSelector((state) => state.layout.tabSelectedNumber);
  if (!window.location.pathname.includes("integrateds")) {
    dispatch(setNews({ newsType: "plan", newsInfo: "Mini 3 tháng" }));
  }
  const project = useSelector((state) => state.project.project);
  useEffect(() => {
    if (!project || Object.keys(project).length === 0) return;
    const now = moment();
    const end = moment(project.expired_at * 1000);
    //  const end =  moment().subtract(2, 'hours');
    const duration = moment.duration(end.diff(now));

    setIstrial({ "Trial": project.type === "free" ? 1 : 0, "Expired": duration > 0 ? Math.round(duration.asDays()) : 0 })
  }, [project]);
  const capitalize = s => s && s[0].toUpperCase() + s.slice(1);

  const formatEndDate = () => {
    const now = moment();
    const end = moment(project.expired_at * 1000);
    // const end =  moment().subtract(2, 'hours');
    const duration = moment.duration(end.diff(now));
    const days = duration.asDays();
    const hours = duration.hours();
    const minutes = duration.minutes();

    if (days >= 1) {
      return ` ${t("date_expried_has")} ${Math.ceil(days)} ${t("date_expried_date")}`
    } else {
      if (hours >= 0 && minutes >= 0) {
        return `${t("date_expried_has")} ${hours} ${t("date_expried_hour")} ${minutes} ${t("date_expried_minus")}`;
      } else {
        return ` ${t("date_expried_true")}`
      }
    }

  }
  const news = () => {
    switch (newsType) {
      case "plan":
        return (
          <div className={`nk-news-list ${twStyle.flex} ${twStyle.itemsCenter}`}>
            {
              isTrial.Trial === 1 && isTrial.Expired > 0 ?
                <>
                  <div className={`${twStyle.mr1}`}>
                    <img src={imagesPrice[project?.type || "free"]} style={{ height: "24px" }} alt=""></img>
                  </div>
                  <span className={`${twStyle.textSm} ${twStyle.fontBold}`} style={{ color: "#828282" }}>
                    {/* {t("project_new_using_trial")} <span style={{ color: "#FD1F6D" }}>{isTrial.Expired} ngày</span> */}

                    {t("project_new_using_trial")} <span style={{ color: "#FD1F6D" }}>{formatEndDate()}</span>
                  </span>
                  <Button className={twStyle.Button} onClick={() => {
                    let url = process.env.PUBLIC_URL + "/admin/project/pricing";
                    window.history.pushState("", "", url)
                    window.location.reload();
                  }}>{t('common.upgrade_plan')}</Button>
                  {/* <img className={twStyle.ButtonSupport} src={ButtonSupport} alt=""
                    onClick={() => { window.open("https://www.facebook.com/messages/t/103638805810736/", "_blank") }}></img> */}
                </> : <></>
            }
            {
              isTrial.Trial === 1 && isTrial.Expired === 0 ?
                <>
                  <div className={`${twStyle.mr1}`}>
                    <img src={IconLessTrial} alt=""></img>
                  </div>
                  <span className={`${twStyle.textSm} ${twStyle.fontBold}`} style={{ color: "#FD1F6D" }}>
                    {t("project_new_using_LessTrial")}
                  </span>
                  <Button className={twStyle.Button} onClick={() => {
                    let url = process.env.PUBLIC_URL + "/admin/project/pricing";
                    window.history.pushState("", "", url)
                    window.location.reload();
                  }}>{t('common.upgrade_plan')}</Button>
                  {/* <img className={twStyle.ButtonSupport} src={ButtonSupport} alt=""
                    onClick={() => { window.open("https://www.facebook.com/login.php?next=https%3A%2F%2Fwww.facebook.com%2Fmessages%2Ft%2F103638805810736%2F") }}></img> */}
                </>
                : <></>
            }
            {
              isTrial.Trial === 0 ?
                <>
                  <div className={`${twStyle.mr1}`}>
                    {/* <IconTrial /> */}
                    <img src={imagesPrice[project?.type || "free"]} style={{ height: "24px" }} alt=""></img>
                  </div>
                  <span className={`${twStyle.textSm} ${twStyle.fontBold}`} style={{ color: "#828282" }}>
                    {capitalize(project?.type || "free")}: <span style={{ color: "#FD1F6D", fontWeight: "bold" }}>{formatEndDate()}</span>
                  </span>
                  <Button className={twStyle.Button} onClick={() => {
                    let url = process.env.PUBLIC_URL + "/admin/project/pricing";
                    window.history.pushState("", "", url)
                    window.location.reload();
                  }}>{t('common.upgrade_plan')}</Button>
                  {/* <img className={twStyle.ButtonSupport} src={ButtonSupport} alt=""
                    onClick={() => { window.open("https://www.facebook.com/login.php?next=https%3A%2F%2Fwww.facebook.com%2Fmessages%2Ft%2F103638805810736%2F") }}></img> */}
                </> : <></>
            }
            {/* <div style={{borderRadius: "8px", marginLeft: "64px",background: "#FFF500", padding: "4px 8px", cursor: "pointer", fontSize: "16px", fontWeight: 500, display: "flex", alignItems: "center", gap: "4px"}} onClick={() => {
              const url = "https://www.notion.so/vukhanh/SO9-So-nice-for-you-2e97fdc305b04dfeb3936b93968dd7d8?pvs=4";
              window.open(url, '_blank')
            }}>
              <div><img src={Handbook}/> </div>
              <div>Bí kíp</div>
            </div> */}
          </div>
        );
      case "engagementRate":
        return (
          <div className="nk-news-list">
            <b>{t("news.text_engagement_rate", { rate: newsInfo })}</b>
            &nbsp;{t("news.text_view_report")}:
            <a href="https://www.google.com">
              <Icon name="external" />
            </a>
          </div>
        );
      case "taskIntegrated":
        return (
          <Nav tabs className="nav-tabs-s2">
            <NavItem>
              <NavLink
                tag="a"
                href="#tab"
                className={classnames({ active: tabSelectedNumber === "1" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  dispatch(setTabNumber({ tabSelectedNumber: "1" }));
                }}
              >
                Tích hợp
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag="a"
                href="#tab"
                className={classnames({ active: tabSelectedNumber === "3" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  dispatch(setTabNumber({ tabSelectedNumber: "3" }));
                }}
              >
                Mã Tracking
              </NavLink>
            </NavItem>
          </Nav>
        );
      default:
        return null;
    }
  };
  return <>{news()}</>;
};

export default News;
