import React from "react";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";
import style from "../../../index.module.scss";
import { Icon } from "../../../../../../components/Component";
import ChartCircle from "../../ChartCircle";
import { formatNumberAcronym } from "../../../../../../utils/Utils";

const YTOverviewUser = ({ data }) => {
  return (
    <div
      className="d-flex flex-column flex-md-row w-100"
      style={{ gap: "1.75rem" }}
    >
      <div className="w-100">
        <Card className="h-100">
          <CardBody className="card-inner">
            <CardTitle tag="h5" className={`${style.headCard}`}>
              Tổng lượt Sub
            </CardTitle>
            <CardText className={`${style.titleCard}`}>
              {data ? formatNumberAcronym(data?.sub?.value) : "0"}
            </CardText>
          </CardBody>
        </Card>
      </div>
      <div className="w-100">
        <Card className="h-100">
          <CardBody className="card-inner">
            <CardTitle tag="h5" className={`${style.headCard}`}>
              Lượt Sub mới
            </CardTitle>
            <div className="d-flex flex-row align-items-center">
              <div className={`${style.titleCard}`}>
                {data ? formatNumberAcronym(data?.new_sub?.value) : "0"}
              </div>
            </div>
            <CardText className={`${style.detailCard} mt-1`}>{`Trung bình: ${
              data ? data?.new_sub?.avg_day : "0"
            }/ngày`}</CardText>
          </CardBody>
        </Card>
      </div>

      <div className="w-100">
        <Card className="h-100">
          <CardBody className="card-inner">
            <CardTitle tag="h5" className={`${style.headCard}`}>
              Lượt Sub mất
            </CardTitle>
            <div className="d-flex flex-row align-items-center">
              <div className={`${style.titleCard}`}>
                {data ? formatNumberAcronym(data?.lost_lost?.value) : "0"}
              </div>
            </div>
            <CardText className={`${style.detailCard} mt-1`}>{`Trung bình: ${
              data ? data?.lost_lost?.avg_day : "0"
            }/ngày`}</CardText>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default YTOverviewUser;
