import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../../../components/block/Block";
import { Col, Icon, Row } from "../../../../components/Component";
import {
  Badge,
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  UncontrolledDropdown,
} from "reactstrap";
import {
  STATUS_BILL_SEND_REQUEST,
  STATUS_BILL_CUSTOMER_CONFIRM_PAYMENT,
  STATUS_BILL_FINISH,
  MAP_STATUS_BILL_TO_TEXT,
} from "../../../../config/project";
import { formatNumber } from "../../../../utils/Utils";

export default function ListBill(props) {
  const { t } = useTranslation();
  const [viewModal, setViewModal] = useState(false);
  const [detailBill, setDetailBill] = useState({});
  const billCreating = useSelector((state) => state.project.currentBill);
  const handleOpenDetail = (item) => {
    setViewModal(true);
    setDetailBill(item);
  };

  const onCloseModal = () => {
    setDetailBill({});
  };
  useEffect(() => {
    setViewModal(Object.keys(detailBill).length > 0);
  }, [detailBill]);

  useEffect(() => {
    setDetailBill(billCreating);
  }, []);

  const { bills, isLoading } = props;

  return (
    <>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle page>{t("project_transaction_history")}</BlockTitle>
            <BlockDes className="text-soft">
              <p>You have total {bills ? bills.length : 0} orders.</p>
            </BlockDes>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      {!isLoading && (
        <Block>
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h5 className="title">{t("project_all_orders")}</h5>
                  </div>
                </div>
              </div>
              <div className="card-inner p-0">
                <table className="table table-tranx">
                  <thead>
                    <tr className="tb-tnx-head">
                      <th className="tb-tnx-id">
                        <span className="">{t("project_bill_id")}</span>
                      </th>
                      <th className="tb-tnx-id">
                        <span className="">{t("project_created_at")}</span>
                      </th>
                      <th className="tb-tnx-id">
                        <span className="">{t("balance")}</span>
                      </th>
                      <th className="tb-tnx-id">
                        <span className="">{t("project_status_bill")}</span>
                      </th>
                      <th className="tb-tnx-id">
                        <span className="">&nbsp;</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {bills && bills.length > 0
                      ? bills.map((item) => {
                          return (
                            <tr key={item._id} className="tb-tnx-item cursor-pointer">
                              <td className="tb-tnx-id">
                                <a
                                  href="#ref"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                >
                                  <span>{item._id.substring(item._id.length - 5, item._id.length)}</span>
                                </a>
                              </td>
                              <td className="tb-tnx-id">
                                <span className="title">{item.created_at}</span>
                              </td>
                              <td className="tb-tnx-id">
                                <span className="title">{formatNumber(item.info.total_amount)} đ</span>
                              </td>
                              <td className="tb-tnx-id">
                                <span className="title">
                                  <span
                                    className={`badge badge-dot badge-${
                                      item.status === STATUS_BILL_FINISH
                                        ? "success"
                                        : item.status === STATUS_BILL_SEND_REQUEST
                                        ? "warning"
                                        : item.status === STATUS_BILL_CUSTOMER_CONFIRM_PAYMENT
                                        ? "info"
                                        : "danger"
                                    }`}
                                  >
                                    {t(MAP_STATUS_BILL_TO_TEXT[item.status])}
                                  </span>
                                </span>
                              </td>
                              <td className="tb-tnx-action">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <ul className="link-list-plain">
                                      <li onClick={() => handleOpenDetail(item)}>
                                        <DropdownItem
                                          tag="a"
                                          href="#view"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                        >
                                          {t("project_bill_detail")}
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </Card>
        </Block>
      )}
      <Modal isOpen={viewModal} toggle={onCloseModal} className="modal-dialog-centered" size="lg">
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              setViewModal(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="nk-modal-head">
            <h4 className="nk-modal-title title">
              Transaction <small className="text-primary"></small>
            </h4>
          </div>
          {Object.keys(detailBill).length > 0 && (
            <div className="nk-tnx-details mt-sm-3">
              <Row className="gy-3">
                <Col lg={6}>
                  <span className="sub-text">{t("project_bill_id")}</span>
                  <span className="caption-text">
                    {detailBill._id.substring(detailBill._id.length - 5, detailBill._id.length)}
                  </span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">{t("project_page_number")}</span>
                  <span className="caption-text text-break">{detailBill.info.connection}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">{t("project_status_bill")}</span>
                  <Badge
                    color={
                      detailBill.status === STATUS_BILL_FINISH
                        ? "success"
                        : detailBill.status === STATUS_BILL_SEND_REQUEST
                        ? "warning"
                        : detailBill.status === STATUS_BILL_CUSTOMER_CONFIRM_PAYMENT
                        ? "info"
                        : "danger"
                    }
                    size="md"
                  >
                    {t(MAP_STATUS_BILL_TO_TEXT[detailBill.status])}
                  </Badge>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">{t("project_employee_number")}</span>
                  <span className="caption-text text-break">{detailBill.info.employee}</span>
                </Col>

                <Col lg={6}>
                  <span className="sub-text">{t("project_created_at")}</span>
                  <span className="caption-text"> {detailBill.created_at}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">{t("project_total")}</span>
                  <span className="caption-text text-break">{formatNumber(detailBill.info.total_amount)} đ</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">{t("project_expire")}</span>
                  <span className="caption-text text-break">
                    {detailBill.info.duration + " " + t("project_" + detailBill.info.unit_duration)}
                  </span>
                </Col>
              </Row>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
}
