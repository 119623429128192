import { uniqBy } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useSelector } from "react-redux";
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Tooltip,
} from "reactstrap";
import { Icon } from "../../../components/Component";
import style from "./index.module.scss";
import { useVisibilityHook } from "react-observer-api";
import ColumnContent from "./ColumnContent";
import { getFeedOfPage } from "../../../api/researchPage";
import BLUE_VERIFIED_ICON from "../../../images/icons/blue_verified.svg";
import SideBarContent from "./ResearchContent/SideBarContent";
import So9 from "../../images/competi.png";
import { EXTENSION_URL } from "./constants/constants";

const LazyLoadWrapper = ({ children, style, className }) => {
  const { setElement, isVisible } = useVisibilityHook({
    threshold: 0.5,
    rootMargin: "100px",
    always: false,
  });

  return (
    <div ref={setElement} style={style} className={className}>
      {isVisible && <>{children}</>}
    </div>
  );
};

const Column = ({ ...props }) => {
  const { channel, openSidebarContent, ListUser, loadSeeding, formSeeding } =
    props;

  const projectId = useSelector((state) => state.project.project._id);
  const [items, setItems] = useState([]);
  const [after, setAfter] = useState("");

  const [tooltipOpen, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!tooltipOpen);
  };

  const [hasMore, setHasMore] = useState(true);
  const loading = useRef(false);
  const aborter = useRef(null);
  const loaduserSeeding = useRef(true);
  const [isShowSideBar, setIsShowSideBar] = useState(false);
  const [itemSelected, setItemSelected] = useState({});
  const [isExtension, setIsExtension] = useState(false);

  const handleOpenSideBar = (item) => {
    setIsShowSideBar(!isShowSideBar);
    setItemSelected(item);
  };
  useEffect(() => {
    return () => {
      aborter.current?.abort();
    };
  }, []);

  useEffect(() => {
    loadPostsInChannel();
  }, []);

  const handleOpenDownExtension = () => {
    setIsExtension(!isExtension);
  };

  const loadPostsInChannel = () => {
    if (!aborter.current) {
      aborter.current = new AbortController();
    }
    if (loading.current) return;
    loading.current = true;
    getFeedOfPage(projectId, channel.is_show && channel._id, after)
      .then((response) => {
        const { data } = response?.data;

        const newAfter = response?.data.paging?.cursors.after;
        if (newAfter && newAfter !== after) {
          setAfter(newAfter);
        } else {
          setAfter("");
        }
        data && setItems((current) => uniqBy([...current, ...data], "id"));

        if (data.length === 0) {
          setHasMore(false);
        }
      })
      .catch(() => { })
      .finally(() => {
        loading.current = false;
      });
  };

  const reset = () => {
    setItems([]);
  };

  const resetItems = () => {
    setAfter("");
    reset();
  };

  const infiniteScrollLoader = (
    <div style={{ textAlign: "center", marginTop: "10%" }}>
      <Spinner color="primary" />
    </div>
  );

  return (
    <LazyLoadWrapper className={style.column}>
      <div
        className={`d-flex align-items-center p-2 ${style.channelHeader}`}
        style={{ zIndex: 0 }}
      >
        <img
          className={style.channelHeaderPlatform}
          // src={getPlatformIcon(channel.platform)}
          src={channel.avatar}
          alt=""
        />
        <div className={`mx-1`}>
          <div className={`d-flex align-items-center`}>
            <span className={`fw-medium ${style.channelHeaderInfoName}`}>
              {channel.name}
            </span>
            {false && (
              <Icon name="alert-circle-fill text-danger" className="fs-18px" />
            )}
          </div>
          {/* <div>{channel.number_follower}</div> */}
        </div>
        {channel.verification_status && (
          <div>
            <img
              alt=""
              className={style.imgBlueVerification}
              src={BLUE_VERIFIED_ICON}
            ></img>
          </div>
        )}
        <ButtonGroup size="sm" className="ml-auto">
          <Button color="light" id={`btnReload${channel._id}`} className="p-1">
            <Icon name="reload" onClick={resetItems}></Icon>
          </Button>
        </ButtonGroup>
        <Tooltip
          placement="bottom"
          isOpen={tooltipOpen}
          target={`btnReload${channel._id}`}
          toggle={toggle}
        >
          Tải lại trang
        </Tooltip>
      </div>
      <div
        id={`scrollableDiv${channel._id}`}
        className={style.channelWrapper}
        style={{ height: "1000px", overflow: "auto", padding: 0 }}
      >
        <InfiniteScroll
          loadMore={loadPostsInChannel}
          hasMore={hasMore}
          loader={items.length > 0 && infiniteScrollLoader}
          useWindow={false}
        >
          {items.length > 0 ? (
            <>
              {items.map((item, index) => (
                <>
                  <ColumnContent
                    key={item._id}
                    data={item}
                    channel={channel}
                    isLoadListUser={loaduserSeeding}
                    ListUser={ListUser}
                    loadSeeding={loadSeeding}
                    handleOpenSideBar={() => handleOpenSideBar(item)}
                    clickContent={(params) => {
                      openSidebarContent?.(params);
                    }}
                    className={index > 0 ? "mt-0" : ""}
                    formSeeding={formSeeding}
                    projectId={projectId}
                    handleOpenDownExtension={handleOpenDownExtension}
                  />

                  <h6 className={style.overline}></h6>
                </>
              ))}
            </>
          ) : (
            <div style={{ textAlign: "center", marginTop: "30%" }}>
              <Spinner color="primary" />
            </div>
          )}
        </InfiniteScroll>

        <Modal isOpen={isExtension} toggle={handleOpenDownExtension}>
          <ModalHeader toggle={handleOpenDownExtension}>
            9 Extension
          </ModalHeader>
          <ModalBody className={`${style.modalNotify}`}>
            <img className={style.imgDefault} src={So9} alt={So9} />
            <div className={`${style.desNotify} mt-1`}>
              Để sử dụng tính năng này vui lòng Tải lại trang
            </div>
            <div className={`${style.advice} mt-2 w-full`}>
              <span className={`${style.tips} mb-3`}>
                Các bước thực hiện khi bạn muốn clone
              </span>
              <ul className="list">
                <li className={`${style.listItem}`}>
                  <span style={{ marginRight: "3px", fontWeight: "700", color: "#1f2937" }}>
                    Bước 1:
                  </span>
                  Vào trang phân tích đối thủ
                </li>
                <li className={`${style.listItem}`}>
                  <span style={{ marginRight: "3px", fontWeight: "700", color: "#1f2937" }}>
                    Bước 2:
                  </span>
                  Bấm Tải lại trang để kích hoạt tính năng, hệ thống sẽ kết nối với
                  <a style={{ margin: "0 4px" }} href={EXTENSION_URL} target="_blank">
                    Extension</a> ở lần tải lại thứ 2.
                </li>
                <li className={`${style.listItem}`}>
                  <span style={{ marginRight: "3px", fontWeight: "700", color: "#1f2937" }}>
                    Bước 3:
                  </span>
                  Cuộn chuột & Bắt đầu bấm Đăng lại. Sau đó kiểm tra các bài đã chọn trên
                  <a style={{ margin: "0 4px" }} href={EXTENSION_URL} target="_blank">
                    Extension</a>và thực hiện lên lịch.
                </li>
              </ul>
            </div>
          </ModalBody>
          <ModalFooter className="d-flex align-items-center justify-content-center">
            <Button
              className={style.modalNotifyFooter}
              onClick={() => {
                window.location.replace(window.location.href);
              }}
            >
              Tải lại trang
            </Button>
          </ModalFooter>
        </Modal>

        <SideBarContent
          isShowSideBar={isShowSideBar}
          handleOpenSideBar={handleOpenSideBar}
          itemSelected={itemSelected}
        />
      </div>
    </LazyLoadWrapper>
  );
};

export default Column;
