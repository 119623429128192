import { merge } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getDashboardHotPost } from "../../../api/overview";
import ScatterChart from "../../components/Chart/ScatterChart";
import moment from "moment";

const TrendingPost = (props) => {
  const { t } = useTranslation();
  const { range } = props;
  const project = useSelector((state) => state.project.project);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({
    legend: {
      display: false,
      labels: {
        boxWidth: 12,
        padding: 20,
        fontColor: "#6783b8",
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      enabled: true,
      backgroundColor: "#fff",
      borderColor: "#eff6ff",
      borderWidth: 2,
      titleFontSize: 13,
      titleFontColor: "#6783b8",
      titleMarginBottom: 6,
      bodyFontColor: "#9eaecf",
      bodyFontSize: 12,
      bodySpacing: 4,
      yPadding: 10,
      xPadding: 10,
      footerMarginTop: 0,
      displayColors: true,
      callbacks: {
        label: function (tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const item = dataset.data[tooltipItem.index];
          return `${item.label}: ${Intl.NumberFormat().format(item.y)}`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          display: true,
          ticks: {
            display: false,
            beginAtZero: false,
            fontSize: 12,
            fontColor: "#9eaecf",
            padding: 0,
            maxTicksLimit: 3,
          },
          gridLines: {
            color: "rgba(82, 100, 132, 0.2)",
            tickMarkLength: 0,
            zeroLineColor: "rgba(82, 100, 132, 0.2)",
          },
        },
      ],
      xAxes: [
        {
          display: true,
          ticks: {
            padding: 16,
            fontSize: 12,
            fontColor: "#9eaecf",
            source: "auto",
            maxTicksLimit: 1,
            maxRotation: 0,
            callback: function (value, index, values) {
              return moment.unix(value).format("DD.MM");
            },
          },
          gridLines: {
            color: "transparent",
            tickMarkLength: 0,
            zeroLineColor: "transparent",
            offsetGridLines: true,
          },
        },
      ],
    },
  });

  useEffect(() => {
    if (project?._id) {
      const data = {
        params: {
          project_id: project?._id,
          time: `${range} days`,
        },
      };
      getDashboardHotPost(data).then((response) => {
        const _data = response.data;
        if (response) {
          const _chartFormatter = {
            backgroundColor: function (context) {
              const index = context?.dataIndex;
              const value = context?.dataset?.data?.[index];
              return value?.color || "#6576ff";
            },
            borderColor: function (context) {
              const index = context?.dataIndex;
              const value = context?.dataset?.data?.[index];
              return value?.color || "#6576ff";
            },
          };
          const _chart = {
            datasets: [merge(_data.data, _chartFormatter)],
          };
          setChartOptions((options) => {
            const _newOptions = merge(options, {
              scales: {
                xAxes: [
                  {
                    ticks: _data.labels,
                  },
                ],
              },
            });
            return _newOptions;
          });
          setChartData(_chart);
        }
      });
    }
    return () => {
      setChartData({});
    };
  }, [range, project?._id]);

  return (
    <>
      <div className="card-title-group align-start pb-3 g-2">
        <div className="card-title card-title-sm">
          <h6 className="title">{t("overview.trending_post.title")}</h6>
          <p>{t("overview.trending_post.subtitle")}</p>
        </div>
      </div>
      <div className="analytic-trending-post-ck">
        <ScatterChart data={chartData} options={chartOptions} />
      </div>
    </>
  );
};

export default TrendingPost;
