import React from "react";
import {
  POST_STATUS_HIDE,
  POST_STATUS_PROCESS,
  POST_STATUS_PUBLISH_ERROR,
  POST_STATUS_PUBLISH_SUCCESS, POST_STATUS_REMOVE,
  POST_STATUS_SCHEDULED,
  POST_STATUS_PROCESSING_SCHEDULED_PENDING,
  POST_STATUS_PROCESSING_SCHEDULED_ERROR,
  POST_STATUS_PROJECT_EXPIRED
} from "../So9/constants/posts";

export function IconTrial() {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" fill="white" />
    <path d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM9.5 9L7 11.5L12 16.5L17 11.5L14.5 9H9.5Z" fill="#FD1F6D" />
  </svg>
}

export function IconChangeProject() {
  return <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.99935 12.8332C3.7776 12.8332 1.16602 10.2216 1.16602 6.99984C1.16602 3.77809 3.7776 1.1665 6.99935 1.1665C10.2211 1.1665 12.8327 3.77809 12.8327 6.99984C12.8327 10.2216 10.2211 12.8332 6.99935 12.8332ZM6.99935 11.6665C8.23703 11.6665 9.42401 11.1748 10.2992 10.2997C11.1744 9.4245 11.666 8.23751 11.666 6.99984C11.666 5.76216 11.1744 4.57518 10.2992 3.70001C9.42401 2.82484 8.23703 2.33317 6.99935 2.33317C5.76167 2.33317 4.57469 2.82484 3.69952 3.70001C2.82435 4.57518 2.33268 5.76216 2.33268 6.99984C2.33268 8.23751 2.82435 9.4245 3.69952 10.2997C4.57469 11.1748 5.76167 11.6665 6.99935 11.6665ZM4.08268 7.58317H9.33268V8.74984H6.99935V10.4998L4.08268 7.58317ZM6.99935 5.24984V3.49984L9.91602 6.4165H4.66602V5.24984H6.99935Z" fill="white" />
  </svg>
}
export function PlusObject() {
  return <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.125 26.125V11.875H30.875V26.125H45.125V30.875H30.875V45.125H26.125V30.875H11.875V26.125H26.125Z" fill="#3A4961" />
  </svg>
}

export function GetSupport() {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 118">
    <path
      fill="#f6faff"
      d="M8.916 93.745C-.318 78.153-2.164 57.569 2.382 39.578 7.155 20.69 19.045 8.451 35.162 3.32 46.609-.324 58.716-.669 70.456.845 84.683 2.68 99.57 7.694 108.892 20.408c10.03 13.679 12.071 34.71 10.747 52.054-1.173 15.359-7.441 27.489-19.231 34.494-10.689 6.351-22.92 8.733-34.715 10.331-16.181 2.192-34.195-.336-47.6-12.281a47.243 47.243 0 01-9.177-11.261z"
    ></path>
    <rect width="84" height="50" x="18" y="32" fill="#fff" rx="4" ry="4"></rect>
    <rect width="20" height="12" x="26" y="44" fill="#e5effe" rx="1" ry="1"></rect>
    <rect width="20" height="12" x="50" y="44" fill="#e5effe" rx="1" ry="1"></rect>
    <rect width="20" height="12" x="74" y="44" fill="#e5effe" rx="1" ry="1"></rect>
    <rect width="20" height="12" x="38" y="60" fill="#e5effe" rx="1" ry="1"></rect>
    <rect width="20" height="12" x="62" y="60" fill="#e5effe" rx="1" ry="1"></rect>
    <path
      fill="#798bff"
      d="M98 31H22a5.006 5.006 0 00-5 5v42a5.006 5.006 0 005 5h30v8h-7a2 2 0 00-2 2v4a2 2 0 002 2h28a2 2 0 002-2v-4a2 2 0 00-2-2h-7v-8h32a5.006 5.006 0 005-5V36a5.006 5.006 0 00-5-5zM73 93v4H45v-4zm-9-2H54v-8h10zm37-13a3 3 0 01-3 3H22a3 3 0 01-3-3V36a3 3 0 013-3h76a3 3 0 013 3z"
    ></path>
    <path
      fill="#6576ff"
      d="M61.444 40H40.111L33 47.143V18.7a3.632 3.632 0 013.556-3.7h24.888A3.632 3.632 0 0165 18.7v17.6a3.632 3.632 0 01-3.556 3.7z"
    ></path>
    <path
      fill="none"
      stroke="#6576ff"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M61.444 40H40.111L33 47.143V18.7a3.632 3.632 0 013.556-3.7h24.888A3.632 3.632 0 0165 18.7v17.6a3.632 3.632 0 01-3.556 3.7z"
    ></path>
    <path
      fill="none"
      stroke="#fffffe"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M40 22L57 22"
    ></path>
    <path
      fill="none"
      stroke="#fffffe"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M40 27L57 27"
    ></path>
    <path
      fill="none"
      stroke="#fffffe"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M40 32L50 32"
    ></path>
    <path
      fill="none"
      stroke="#9cabff"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M30.5 87.5L30.5 91.5"
    ></path>
    <path
      fill="none"
      stroke="#9cabff"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M28.5 89.5L32.5 89.5"
    ></path>
    <path
      fill="none"
      stroke="#9cabff"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M79.5 22.5L79.5 26.5"
    ></path>
    <path
      fill="none"
      stroke="#9cabff"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M77.5 24.5L81.5 24.5"
    ></path>
    <circle cx="90.5" cy="97.5" r="3" fill="none" stroke="#9cabff" strokeMiterlimit="10"></circle>
    <circle cx="24" cy="23" r="2.5" fill="none" stroke="#9cabff" strokeMiterlimit="10"></circle>
  </svg>
}

export function IconPost({ status }) {
  switch (status) {
    case POST_STATUS_SCHEDULED:
      return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6591_2646)">
          <path d="M8.809 2.984L9.5355 2.2575L10.2425 2.9645L9.516 3.691C10.2339 4.58958 10.5805 5.72894 10.4845 6.87507C10.3886 8.02119 9.8575 9.0871 9.00024 9.85386C8.14299 10.6206 7.02468 11.03 5.87499 10.998C4.7253 10.966 3.6315 10.495 2.81823 9.68176C2.00497 8.8685 1.53396 7.7747 1.50196 6.62501C1.46995 5.47532 1.87937 4.35701 2.64614 3.49976C3.4129 2.6425 4.4788 2.11138 5.62493 2.01546C6.77106 1.91954 7.91042 2.26612 8.809 2.984ZM5.5 4V7H6.5V4H5.5ZM4 0.5H8V1.5H4V0.5Z" fill="#6A2C62" />
        </g>
        <defs>
          <clipPath id="clip0_6591_2646">
            <rect width="12" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    case POST_STATUS_PROCESSING_SCHEDULED_PENDING:
      return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6591_2646)">
          <path d="M8.809 2.984L9.5355 2.2575L10.2425 2.9645L9.516 3.691C10.2339 4.58958 10.5805 5.72894 10.4845 6.87507C10.3886 8.02119 9.8575 9.0871 9.00024 9.85386C8.14299 10.6206 7.02468 11.03 5.87499 10.998C4.7253 10.966 3.6315 10.495 2.81823 9.68176C2.00497 8.8685 1.53396 7.7747 1.50196 6.62501C1.46995 5.47532 1.87937 4.35701 2.64614 3.49976C3.4129 2.6425 4.4788 2.11138 5.62493 2.01546C6.77106 1.91954 7.91042 2.26612 8.809 2.984ZM5.5 4V7H6.5V4H5.5ZM4 0.5H8V1.5H4V0.5Z" fill="#219653" />
        </g>
        <defs>
          <clipPath id="clip0_6591_2646">
            <rect width="12" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    case POST_STATUS_PROCESSING_SCHEDULED_ERROR:
      return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6591_2646)">
          <path d="M8.809 2.984L9.5355 2.2575L10.2425 2.9645L9.516 3.691C10.2339 4.58958 10.5805 5.72894 10.4845 6.87507C10.3886 8.02119 9.8575 9.0871 9.00024 9.85386C8.14299 10.6206 7.02468 11.03 5.87499 10.998C4.7253 10.966 3.6315 10.495 2.81823 9.68176C2.00497 8.8685 1.53396 7.7747 1.50196 6.62501C1.46995 5.47532 1.87937 4.35701 2.64614 3.49976C3.4129 2.6425 4.4788 2.11138 5.62493 2.01546C6.77106 1.91954 7.91042 2.26612 8.809 2.984ZM5.5 4V7H6.5V4H5.5ZM4 0.5H8V1.5H4V0.5Z" fill="#EB5757" />
        </g>
        <defs>
          <clipPath id="clip0_6591_2646">
            <rect width="12" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    case POST_STATUS_PUBLISH_SUCCESS:
      return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6591_2642)">
          <path d="M6 11C3.2385 11 1 8.7615 1 6C1 3.2385 3.2385 1 6 1C8.7615 1 11 3.2385 11 6C11 8.7615 8.7615 11 6 11ZM5.5015 8L9.0365 4.4645L8.3295 3.7575L5.5015 6.586L4.087 5.1715L3.38 5.8785L5.5015 8Z" fill="#6ADDAF" />
        </g>
        <defs>
          <clipPath id="clip0_6591_2642">
            <rect width="12" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    case POST_STATUS_PUBLISH_ERROR:
      return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6591_2364)">
          <path d="M8 1L10.5 3.5V10.504C10.4999 10.6356 10.4475 10.7618 10.3544 10.8548C10.2613 10.9478 10.1351 11 10.0035 11H1.9965C1.86519 10.9991 1.73951 10.9465 1.64661 10.8537C1.55371 10.7609 1.50105 10.6353 1.5 10.504V1.496C1.5 1.222 1.7225 1 1.9965 1H8ZM5.5 7.5V8.5H6.5V7.5H5.5ZM5.5 3.5V6.5H6.5V3.5H5.5Z" fill="#D75D4F" />
        </g>
        <defs>
          <clipPath id="clip0_6591_2364">
            <rect width="12" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    case POST_STATUS_PROCESS:
      return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6618_3871)">
          <path d="M1 2.75V1.9965C1.00092 1.86519 1.05345 1.73951 1.14626 1.64661C1.23906 1.55371 1.36469 1.50105 1.496 1.5H10.504C10.778 1.5 11 1.7225 11 1.9965V10.0035C10.9991 10.1348 10.9465 10.2605 10.8537 10.3534C10.7609 10.4463 10.6353 10.499 10.504 10.5H1.496C1.36441 10.4999 1.23825 10.4475 1.14524 10.3544C1.05224 10.2613 1 10.1351 1 10.0035V9.5H10V3.65L6 7.25L1 2.75ZM0 5H2.5V6H0V5ZM0 7.5H4V8.5H0V7.5Z" fill="#6A2C62" />
        </g>
        <defs>
          <clipPath id="clip0_6618_3871">
            <rect width="12" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    case POST_STATUS_HIDE:
      return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6591_2663)">
          <path d="M10 3.5V10.5C10 10.6326 9.94732 10.7598 9.85355 10.8536C9.75979 10.9473 9.63261 11 9.5 11H2.5C2.36739 11 2.24021 10.9473 2.14645 10.8536C2.05268 10.7598 2 10.6326 2 10.5V3.5H1V2.5H11V3.5H10ZM5.5 5V8.5H6.5V5H5.5ZM3.5 1H8.5V2H3.5V1Z" fill="#333333" />
        </g>
        <defs>
          <clipPath id="clip0_6591_2663">
            <rect width="12" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    case POST_STATUS_REMOVE:
      return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6591_2663)">
          <path d="M10 3.5V10.5C10 10.6326 9.94732 10.7598 9.85355 10.8536C9.75979 10.9473 9.63261 11 9.5 11H2.5C2.36739 11 2.24021 10.9473 2.14645 10.8536C2.05268 10.7598 2 10.6326 2 10.5V3.5H1V2.5H11V3.5H10ZM5.5 5V8.5H6.5V5H5.5ZM3.5 1H8.5V2H3.5V1Z" fill="#333333" />
        </g>
        <defs>
          <clipPath id="clip0_6591_2663">
            <rect width="12" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    case POST_STATUS_PROJECT_EXPIRED:
      return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 11C3.23857 11 1 8.7614 1 6C1 3.23857 3.23857 1 6 1C8.7614 1 11 3.23857 11 6C11 8.7614 8.7614 11 6 11ZM3.5 5.5V6.5H8.5V5.5H3.5Z" fill="#D75D4F" />
      </svg>
    default:
      return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6616_3861)">
          <path d="M6 11C3.2385 11 1 8.7615 1 6C1 3.2385 3.2385 1 6 1C8.7615 1 11 3.2385 11 6C11 8.7615 8.7615 11 6 11ZM5.5 7.5V8.5H6.5V7.5H5.5ZM6.5 6.6775C6.90183 6.55639 7.2468 6.29498 7.47208 5.94087C7.69735 5.58677 7.78795 5.16353 7.72737 4.74823C7.66678 4.33294 7.45905 3.95322 7.14201 3.67823C6.82496 3.40324 6.41969 3.25127 6 3.25C5.59543 3.24997 5.20333 3.39008 4.8904 3.6465C4.57746 3.90292 4.36301 4.25982 4.2835 4.6565L5.2645 4.853C5.29234 4.71372 5.35916 4.5852 5.45718 4.48241C5.55521 4.37962 5.68041 4.30677 5.81821 4.27235C5.95602 4.23793 6.10077 4.24335 6.23561 4.28798C6.37046 4.33261 6.48986 4.41462 6.57992 4.52446C6.66998 4.63429 6.727 4.76745 6.74434 4.90842C6.76168 5.0494 6.73864 5.1924 6.67789 5.32079C6.61713 5.44918 6.52117 5.55768 6.40116 5.63366C6.28116 5.70965 6.14204 5.74999 6 5.75C5.86739 5.75 5.74021 5.80268 5.64645 5.89645C5.55268 5.99021 5.5 6.11739 5.5 6.25V7H6.5V6.6775Z" fill="#EBBF43" />
        </g>
        <defs>
          <clipPath id="clip0_6616_3861">
            <rect width="12" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
  }
}

export function IconEyePost() {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6591_2680)">
      <path d="M0.787331 8C1.414 4.58667 4.40533 2 8 2C11.5947 2 14.5853 4.58667 15.2127 8C14.586 11.4133 11.5947 14 8 14C4.40533 14 1.41466 11.4133 0.787331 8ZM8 11.3333C8.88405 11.3333 9.7319 10.9821 10.357 10.357C10.9821 9.7319 11.3333 8.88406 11.3333 8C11.3333 7.11595 10.9821 6.2681 10.357 5.64298C9.7319 5.01786 8.88405 4.66667 8 4.66667C7.11594 4.66667 6.2681 5.01786 5.64297 5.64298C5.01785 6.2681 4.66666 7.11595 4.66666 8C4.66666 8.88406 5.01785 9.7319 5.64297 10.357C6.2681 10.9821 7.11594 11.3333 8 11.3333ZM8 10C7.46956 10 6.96086 9.78929 6.58578 9.41421C6.21071 9.03914 6 8.53043 6 8C6 7.46957 6.21071 6.96086 6.58578 6.58579C6.96086 6.21071 7.46956 6 8 6C8.53043 6 9.03914 6.21071 9.41421 6.58579C9.78928 6.96086 10 7.46957 10 8C10 8.53043 9.78928 9.03914 9.41421 9.41421C9.03914 9.78929 8.53043 10 8 10Z" fill="#6F7782" />
    </g>
    <defs>
      <clipPath id="clip0_6591_2680">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
}

export function IconTrashPost() {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6591_2677)">
      <path d="M13.3333 4.66658V13.9999C13.3333 14.1767 13.2631 14.3463 13.1381 14.4713C13.013 14.5963 12.8435 14.6666 12.6667 14.6666H3.33333C3.15652 14.6666 2.98695 14.5963 2.86192 14.4713C2.7369 14.3463 2.66666 14.1767 2.66666 13.9999V4.66658H1.33333V3.33325H14.6667V4.66658H13.3333ZM7.33333 6.66658V11.3333H8.66666V6.66658H7.33333ZM4.66666 1.33325H11.3333V2.66659H4.66666V1.33325Z" fill="#6F7782" />
    </g>
    <defs>
      <clipPath id="clip0_6591_2677">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
}

export function IconEditPost() {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6591_3123)">
      <path d="M6.162 12.6667H14V14.0001H2V11.1714L8.6 4.57139L11.428 7.40072L6.16133 12.6667H6.162ZM9.542 3.62939L10.9567 2.21472C11.0817 2.08974 11.2512 2.01953 11.428 2.01953C11.6048 2.01953 11.7743 2.08974 11.8993 2.21472L13.7853 4.10072C13.9103 4.22574 13.9805 4.39528 13.9805 4.57206C13.9805 4.74883 13.9103 4.91837 13.7853 5.04339L12.3707 6.45739L9.54267 3.62939H9.542Z" fill="#6F7782" />
    </g>
    <defs>
      <clipPath id="clip0_6591_3123">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
}
export function IconChat() {
  return <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.30325 10.6667L0.333252 13V0.666667C0.333252 0.489856 0.40349 0.320286 0.528514 0.195262C0.653538 0.0702379 0.823108 0 0.999919 0H12.9999C13.1767 0 13.3463 0.0702379 13.4713 0.195262C13.5963 0.320286 13.6666 0.489856 13.6666 0.666667V10C13.6666 10.1768 13.5963 10.3464 13.4713 10.4714C13.3463 10.5964 13.1767 10.6667 12.9999 10.6667H3.30325ZM6.99992 4.66667H4.33325V6H6.99992V8L9.66658 5.33333L6.99992 2.66667V4.66667Z" fill="#6F7782" />
  </svg>
}

export function IconChatBorder() {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer" }}>
    <g id="Frame">
      <path id="Vector" d="M4.30271 12.6667L1.33301 15V2.66667C1.33301 2.29848 1.63149 2 1.99967 2H13.9997C14.3679 2 14.6663 2.29848 14.6663 2.66667V12C14.6663 12.3682 14.3679 12.6667 13.9997 12.6667H4.30271ZM2.66634 12.2567L3.84155 11.3333H13.333V3.33333H2.66634V12.2567ZM7.99967 6.66667V4.66667L10.6663 7.33333L7.99967 10V8H5.33301V6.66667H7.99967Z" fill="#6F7782" />
    </g>
  </svg>

}
export function IconInfoPost() {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16ZM7.2 7.2V12H8.8V7.2H7.2ZM7.2 4V5.6H8.8V4H7.2Z" fill="#2F80ED" />
  </svg>
}

export function IconReload() {
  return <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.33333 0V3C13.0153 3 16 5.98467 16 9.66667C16 9.84867 15.9933 10.0287 15.9787 10.2067C15.0033 8.35733 13.092 7.07933 10.8753 7.00333L10.6667 7H9.33333V10L4 5L9.33333 0ZM5.33333 0V1.82467L1.94667 5L5.33267 8.174L5.33333 10L0 5L5.33333 0Z" fill="#6A2C62" />
  </svg>
}

export function IconLeaf() {
  return <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6591_3802)">
      <path d="M8.09667 16.3753C7.82483 17.1033 7.595 17.7753 7.38967 18.4554C8.50967 17.6423 9.84083 17.1266 11.3773 16.9341C14.3092 16.5678 16.9143 14.6323 18.2327 12.1998L16.534 10.5023L18.1825 8.85142L19.3492 7.68359C19.8508 7.18192 20.4167 6.25559 21.0152 4.92092C14.49 5.93242 10.4942 9.92825 8.0955 16.3753H8.09667ZM19.8333 10.5011L21 11.6666C19.8333 15.1666 16.3333 18.6666 11.6667 19.2499C8.55283 19.6396 6.608 21.7781 5.831 25.6666H3.5C4.66667 18.6666 7 2.33325 24.5 2.33325C23.3333 5.82975 22.169 8.16192 21.0035 9.32975L19.8333 10.5011Z" fill="#FD1F6D" />
    </g>
    <defs>
      <clipPath id="clip0_6591_3802">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
}

export function IconClose() {
  return <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6591_3798)">
      <path d="M13.9987 12.349L19.7737 6.57397L21.4234 8.22364L15.6484 13.9986L21.4234 19.7736L19.7737 21.4233L13.9987 15.6483L8.22375 21.4233L6.57408 19.7736L12.3491 13.9986L6.57408 8.22364L8.22375 6.57397L13.9987 12.349Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_6591_3798">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
}

export function IconPlus() {
  return <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.67857 5.67857V0.75H7.32143V5.67857H12.25V7.32143H7.32143V12.25H5.67857V7.32143H0.75V5.67857H5.67857Z" fill="#6A2C62" />
  </svg>
}

export function IconDelMedia() {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6693_1453)">
      <g filter="url(#filter0_d_6693_1453)">
        <path d="M10 18.3332C5.39752 18.3332 1.66669 14.6023 1.66669 9.99984C1.66669 5.39734 5.39752 1.6665 10 1.6665C14.6025 1.6665 18.3334 5.39734 18.3334 9.99984C18.3334 14.6023 14.6025 18.3332 10 18.3332ZM10 8.8215L7.64335 6.464L6.46419 7.64317L8.82169 9.99984L6.46419 12.3565L7.64335 13.5357L10 11.1782L12.3567 13.5357L13.5359 12.3565L11.1784 9.99984L13.5359 7.64317L12.3567 6.464L10 8.8215Z" fill="#FF0000" />
      </g>
    </g>
    <defs>
      <filter id="filter0_d_6693_1453" x="-2.33331" y="-2.3335" width="24.6667" height="24.6665" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6693_1453" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6693_1453" result="shape" />
      </filter>
      <clipPath id="clip0_6693_1453">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
}