import { API } from "../libs/client";

export const getAllTag = async (projectId, data) => {
  const uri = `projects/${projectId}/tags/list`;
  const res = await API.get(uri, data);
  return res.data;
};

export const createTag = async (projectId, data) => {
  const uri = `projects/${projectId}/tags`;
  const res = await API.post(uri, data);
  return res.data;
};

export const updateTag = async (projectId, id, data) => {
  const uri = `projects/${projectId}/tags/${id}`;
  const res = await API.put(uri, data);
  return res.data;
};
