import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { Icon, Img, RSelect } from "../../../components/Component";
import style from "./index.module.scss";
import LogoDriver from "../../images/icons/logo-driver.png";

import { Controller, useForm } from "react-hook-form";
import { MAX_POST_UPLOAD } from "../../utils/Utils";
import ModalDriveUpload from "../CreatePost/ModalDriveUpload";
import { connectDrive, getMailDrive } from "../../../api/createPost";
import ModalWarning from "../../components/Modal/ModalWarning";
import MediaItem from "./MediaItem";
import ModalNoteDrive from "./ModalNoteDrive";
import { createTopic, editWarehouse } from "../../../api/warehouse";
import { getDomainApp } from "../../../utils/Utils";

const ModalEdit = (props) => {
  const {
    modal,
    toggle,
    refetch,
    toggleSuccess,
    data,
    setMessage,
    optionTopic,
    refetchTopic,
  } = props;
  const optionMediaType = [
    {
      label: "Ảnh",
      value: "photo",
    },
    {
      label: "Video",
      value: "video",
    },
    {
      label: "Video Ngắn",
      value: "reel",
    },
  ];
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const initForm = {
    media: [],
    shuffle_content: [],
    content: "",
    images: [],
    videos: [],
  };
  const [formData, setFormData] = useState(initForm);
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const [countShuffleContent, setCountShuffleContent] = useState(1);
  const [modalDriveUpload, setModalDriveUpload] = useState(false);
  const [modalNoteDrive, setModalNoteDrive] = useState(false);
  const [warningTextProject, setWarningTextProject] = useState("");
  const [modalWarningProject, setModalWarningProject] = useState(false);
  const [driver, setDriver] = useState(null);
  const [media, setMedia] = useState([]);
  const [type, setType] = useState({});
  const [loading, setLoading] = useState(false);

  const [zIndexParent, setZIndexParent] = useState();

  const [isLoadingFile, setIsLoadingFile] = useState();

  const getConfigDrive = async () => {
    const data = {};
    return await getMailDrive(userInfo.last_project_active, data).then(
      (resp) => {
        if (resp.status === true && resp.data) {
          setDriver(resp.data);
          return resp.data;
        } else {
          return {};
        }
      }
    );
  };

  useEffect(() => {
    setMedia([]);
    setFormData(initForm);
    if (modal) {
      setCountShuffleContent(data?.shuffle_content?.length);
      setValue("shuffle_content", data?.shuffle_content);
      setFormData((prev) => ({
        ...prev,
        content: data?.content,
        shuffle_content: data?.shuffle_content,
      }));
      setValue("topic", data?.topic);
      setValue("media", data?.media);
      let fileMedia;
      let ids;
      if (data?.is_photo) {
        setType(optionMediaType[0]);
        fileMedia = data?.media?.map((item) => ({
          thumbnailLink: item?.name,
          id: item?.id,
        }));
        ids = data?.media?.map((item) => item?.id);
      }
      if (data?.is_normal_video || data?.is_short_video) {
        setType(
          data?.is_normal_video ? optionMediaType[1] : optionMediaType[2]
        );

        fileMedia = data?.media?.map((item) => ({
          thumbnailLink: item?.thumb,
          id: item?.id,
        }));
        ids = data?.media?.map((item) => item?.id);
      }
      setMedia(fileMedia);
      setFormData((prevFormData) => ({
        ...prevFormData,
        media: ids,
      }));
    }
  }, [modal]);

  useEffect(() => {
    getConfigDrive();
  }, [userInfo.last_project_active]);
  const connectToDrive = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfor"));
    const projectId = userInfo.last_project_active;
    const domain = getDomainApp();
    const urlRedirect = `${domain}/posts/create?drive=true`;
    const data = {};
    connectDrive(projectId, urlRedirect, data).then((resp) => {
      if (resp.status) {
        window.location.href = resp.data;
      } else {
        setWarningTextProject(resp.message);
        setModalWarningProject(true);
      }
    });
  };
  const toggleWarningProject = () => {
    setModalWarningProject(!modalWarningProject);
  };
  const onFormConnectHandle = () => {
    if (driver !== null && driver?.length !== 0) {
      setModalDriveUpload(true);
    } else {
      setModalNoteDrive(true);
    }
  };

  // Hàm để thêm một phần tử vào mảng formData.shuffle_content
  const addShuffleContent = () => {
    setCountShuffleContent((prev) => prev + 1);
    setFormData((prevData) => ({
      ...prevData,
      shuffle_content: [...prevData.shuffle_content, ""],
    }));
  };

  // Hàm để xoá một phần tử từ mảng formData.shuffle_content tại vị trí index
  const removeShuffleContent = (index) => {
    setCountShuffleContent((prev) => prev - 1);
    setFormData((prevData) => {
      const updatedShuffleContent = [...prevData.shuffle_content];
      updatedShuffleContent.splice(index, 1);
      return {
        ...prevData,
        shuffle_content: updatedShuffleContent,
      };
    });
  };

  // Hàm để cập nhật nội dung cho từng phần tử trong formData.shuffle_content
  const handleShuffleContentChange = (index, value) => {
    setFormData((prevData) => {
      const updatedShuffleContent = [...prevData.shuffle_content];
      updatedShuffleContent[index] = value;
      setValue("shuffle_content", updatedShuffleContent);
      return {
        ...prevData,
        shuffle_content: updatedShuffleContent,
      };
    });
  };

  const triggerCallEvent = async (inputValue) => {
    // Implement your custom logic here to trigger the call event.

    try {
      const res = await createTopic(userInfo.last_project_active, {
        name: inputValue,
      });
      if (res.status) {
        refetchTopic();
      }
    } catch (error) {
      console.log(error);
    }
    // console.log("Triggering call event...", inputValue);
    // You can make an API call or perform any other action here.
  };
  let inputChangeTimeout;
  const handleInputChange = (inputValue, { action }) => {
    // action is a string that indicates the type of action taken (e.g., 'input-change', 'menu-close').
    // When 'input-change' occurs and no options match the input value, trigger your custom event.
    const lowerCasedInputValue = inputValue.toLowerCase();
    const lowerCasedOptions = optionTopic?.map((option) =>
      option.label.toLowerCase()
    );
    clearTimeout(inputChangeTimeout);
    if (
      action === "input-change" &&
      !lowerCasedOptions.some((option) => option.includes(lowerCasedInputValue))
    ) {
      inputChangeTimeout = setTimeout(() => {
        triggerCallEvent(inputValue);
      }, 1000);
    }
  };

  const onEditSubmit = async (formvalue) => {
    if (
      formvalue.shuffle_content.length === 1 &&
      formvalue.shuffle_content[0] === ""
    ) {
      // Nếu trường topic rỗng, không tiến hành submit và hiển thị cảnh báo
      return setValue("shuffle_content", "");
    }
    setLoading(true);
    try {
      const requestData = {};

      if (formData.topic !== "") {
        requestData.topic = formData.topic;
      }

      // if (formData.media.length !== 0) {
      //   requestData.media = formData.media;
      // }

      if (formData.images.length === 0 && formData.videos.length === 0) {
        requestData.media = formData.media;
      }

      if (formData.images.length !== 0) {
        requestData.images = formData.images;
      }

      if (formData.videos.length !== 0) {
        requestData.videos = formData.videos;
      }

      if (formData.shuffle_content.length !== 0) {
        requestData.content = formData.shuffle_content[0];
        requestData.shuffle_content = formData.shuffle_content;
      }

      const res = await editWarehouse(
        userInfo.last_project_active,
        data?._id,
        requestData
      );
      if (res?.status) {
        refetch();
        setMessage("Cập nhật thành công");
        toggle();
        toggleSuccess();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const deleteMedia = (id) => {
    setMedia((prev) => prev.filter((item) => item.id !== id));

    let mediaCurrent = formData.media.filter((item) => item !== id);
    setValue("media", mediaCurrent);

    let update = { media: mediaCurrent };

    if (data?.is_photo) {
      let imageCurrent = formData.images.filter((item) => item.id !== id);
      update.images = imageCurrent;
    } else {
      let videoCurrent = formData.videos.filter((item) => item.id !== id);
      update.videos = videoCurrent;
    }

    setFormData((prev) => ({
      ...prev,
      ...update,
    }));
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      zIndex={zIndexParent}
      className="modal-dialog-centered"
    >
      <ModalHeader
        toggle={toggle}
        close={
          <button className="close" onClick={toggle}>
            <Icon name="cross" />
          </button>
        }
      >
        Sửa bài đăng
      </ModalHeader>
      <ModalBody>
        <Form className="gy-2" onSubmit={handleSubmit(onEditSubmit)}>
          <Row className="d-flex align-items-center">
            <Col md="3">Media</Col>
            <Col md="9" className="pl-0">
              {media?.length === 0 ? (
                <>
                  <Controller
                    name="media"
                    control={control}
                    rules={{
                      required: "Bạn cần chọn Media",
                    }}
                    render={({ field: { onBlur, onChange } }) => (
                      <Button
                        color="light"
                        onClick={() => onFormConnectHandle()}
                      >
                        <Img
                          src={LogoDriver}
                          style={{ height: "1rem", width: "1rem" }}
                          className="mr-1"
                          alt="logo driver"
                        />
                        <p className="fw-medium">Google Drive</p>
                      </Button>
                    )}
                  />
                  {errors.media && (
                    <FormText color="danger">
                      {" "}
                      {errors[`media`]?.message}
                    </FormText>
                  )}
                </>
              ) : (
                <MediaItem
                  action="edit"
                  media={media}
                  mediaType={type.value}
                  deleteMedia={deleteMedia}
                  onFormConnectHandle={onFormConnectHandle}
                />
              )}
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md="3">Loại bài</Col>
            <Col md="9" className="pl-0">
              <FormGroup style={{ width: "40%" }}>
                <Controller
                  name="type"
                  control={control}
                  render={({ field: { onBlur, onChange } }) => (
                    <RSelect
                      style={{ with: "50%" }}
                      options={optionMediaType}
                      value={type}
                      isDisabled={true}
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="d-flex align-items-center ">
            <Col md="3">Chủ đề</Col>
            <Col md="9" className="pl-0">
              <FormGroup style={{ width: "40%" }}>
                <Controller
                  name="topic"
                  control={control}
                  rules={{
                    required: "Bạn cần chọn chủ đề",
                  }}
                  render={({ field: { onBlur, onChange } }) => (
                    <RSelect
                      style={{ with: "50%" }}
                      options={optionTopic}
                      placeholder={"Chọn chủ đề"}
                      defaultValue={optionTopic.filter(
                        (item) => item.value === data?.topic
                      )}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          topic: e.value,
                        });
                        setValue("topic", e.value);
                      }}
                      onInputChange={handleInputChange}
                      noOptionsMessage={() => (
                        <>
                          <Spinner size="sm" className="mr-2" />
                          <span>Đang tạo ...</span>
                        </>
                      )}
                    />
                  )}
                />
                {errors.topic && (
                  <FormText color="danger">
                    {" "}
                    {errors[`topic`]?.message}
                  </FormText>
                )}
              </FormGroup>
            </Col>
          </Row>
          <div
            style={{
              width: "100%",
              borderBottom: "2px solid #E5E7EB",
              padding: "0px !important",
            }}
          ></div>
          <Row style={{ maxHeight: "300px", overflow: "auto" }}>
            <Col md="12" className="mt-2">
              {Array.from({ length: countShuffleContent }, (_, index) => (
                <Row key={index}>
                  <Col md="3">Nội dung {index > 0 && index + 1}</Col>
                  <Col md="9" className="pl-0">
                    <FormGroup>
                      <Controller
                        name="shuffle_content"
                        control={control}
                        rules={{
                          required: "Bạn cần nhập nội dung",
                        }}
                        render={({ field: { onBlur, onChange } }) => (
                          <>
                            <div
                              className="nk-reply-form"
                              style={{ margin: 0 }}
                            >
                              <textarea
                                type="area"
                                name={`shuffle_content_${index}`}
                                className="form-control"
                                placeholder="Nhập nội dung"
                                value={formData?.shuffle_content[index] || ""}
                                onChange={(e) =>
                                  handleShuffleContentChange(
                                    index,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="d-flex justify-content-end my-1">
                              {index > 0 && (
                                <Icon
                                  name="trash"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => removeShuffleContent(index)}
                                />
                              )}
                            </div>
                          </>
                        )}
                      />
                      {formData.shuffle_content[index] === "" && (
                        <FormText color="danger">
                          Bạn cần nhập nội dung
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
          <p
            style={{ color: "#0EA5E9", cursor: "pointer" }}
            onClick={addShuffleContent}
          >
            + Thêm biến thể nội dung
          </p>
          <div className={style.btnCreatePost}>
            <FormGroup className="d-flex justify-content-end">
              <div>
                <Button
                  color="light"
                  className="mr-2"
                  onClick={(e) => {
                    e.preventDefault();

                    toggle();
                  }}
                  //   disabled={isUploading}
                >
                  <span style={{ textTransform: "uppercase" }}>Huỷ</span>
                </Button>
                <Button
                  type="submit"
                  style={{ background: "#2C4B94" }}
                  disabled={loading}
                >
                  <span style={{ textTransform: "uppercase", color: "#fff" }}>
                    {loading && <Spinner size="sm" />}Lưu lại
                  </span>
                </Button>
              </div>
            </FormGroup>
          </div>
        </Form>
      </ModalBody>
      <ModalWarning
        modalWarning={modalWarningProject}
        toggleWarning={toggleWarningProject}
        warningText={warningTextProject}
      />
      <ModalDriveUpload
        setIsLoadingFile={setIsLoadingFile}
        toggle={toggle}
        tabConnect={true}
        modalDriveUpload={modalDriveUpload}
        setModalDriveUpload={setModalDriveUpload}
        connectToDrive={connectToDrive}
        getConfigDrive={getConfigDrive}
        maxPost={MAX_POST_UPLOAD}
        driver={driver}
        mediaType={formData.type}
        isMultiple={false}
        setZIndexParent={setZIndexParent}
        warehouseMediaType={type.value}
        setValue={setValue}
        setFormValues={setFormData}
        formValues={formData}
        isEditPostInWarehouse={true}
        setMedia={setMedia}
        dataEditPostInWarehouse={data}
      />

      <ModalNoteDrive
        modalNoteDrive={modalNoteDrive}
        setModalNoteDrive={setModalDriveUpload}
        connectToDrive={connectToDrive}
      />

      {/* Modal Select DriveUpload */}
    </Modal>
  );
};

export default ModalEdit;
