import React from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Block, BlockDes, BlockHead, BlockHeadContent, BlockTitle, PreviewCard } from "../../components/Component";
import { useTranslation } from "react-i18next";

const Policy = ({ ...props }) => {

  const { t } = useTranslation("policy")

  return (
    <React.Fragment>
      <Head title={t("welcome_policy")} />
      <Content>
        <div className="content-page wide-md m-auto">
          <BlockHead size="lg" wide="xs" className="mx-auto">
            <BlockHeadContent className="text-center">
              <BlockTitle tag="h2" className="fw-normal">
                {t("welcome_policy")}
              </BlockTitle>
              <BlockDes>
                <p className="lead">
                  {t("general_intro_content")}
                </p>
                {/* <p className="text-soft ff-italic">Last Update: Nov 12, 2019</p> */}
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>
          <Block>
            <PreviewCard className="card-bordered">
              <div className="entry">
                {/* <h3>Terms and Conditions</h3>
                <p>
                  We’ve decided to make these Terms available under a <strong>Creative Commons</strong> license. You can
                  grab a copy of these Terms and other legal documents on Github. You’re more than welcome to copy them,
                  adapt them, and repurpose them for your own use.
                </p> */}
                <h4>{t("intro_body_title")}</h4>
                <p>
                  {t("intro_body_content")}
                </p>

                <h4>{t("1_information")} </h4>
                <p>{t("1_information_content")}</p>
                <ul className="list list-sm list-checked">
                  <li>{t("1_information_content_1")}</li>
                  <li>{t("1_information_content_2")}</li>
                  <li>{t("1_information_content_3")}</li>
                  <li>{t("1_information_content_4")}</li>
                </ul>
                {/* <h4>Your Content</h4> */}
                <p>
                  {t("1_contact_company")}
                </p>
                <p>
                  {t("1_company_name")}
                </p>
                <p>
                  <b> {t("1_office")}</b>  {t("1_company_address")}
                </p>
                <p>
                  <b>{t("1_telephone")}</b> {t("1_phone_number")}
                </p>
                <h4>{t("2_define")}</h4>
                <p>
                  {t("2_explanation_of_definitions")}
                </p>
                <ul className="list list-sm">
                  <li> <b>{t("2_customer")}</b>{t("2_customer_define")}</li>
                  <li> <b>{t("2_customer_data")}</b>{t("2_customer_data_define")}</li>
                  <li> <b>{t("2_personal_data")}</b>{t("2_personal_data_define")}</li>
                  <li> <b>{t("2_public_area")}</b>{t("2_restricted_area_define")}</li>
                  <li> <b>{t("2_restricted_area")}</b>{t("2_customer_define")}</li>
                  <li> <b>{t("2_user")}</b>{t("2_user_define")}</li>
                  <li> <b>{t("2_guest")}</b>{t("2_guest_define")}</li>

                </ul>
                <h4>{t("3_protect_and_store")}</h4>
                <p>
                  {t("3_protect_and_store_content")}
                </p>
                <h4>{t("4_information_to_So9")}</h4>
                <h6>{t("4.1_information_to_So9")}</h6>
                <p>
                  {t("4.1_title")}
                </p>
                <u>
                  {t("4.1A_Infor_to_provide")}
                </u>
                <p>
                  <b>{t("1_Register_acc")}</b> {t("1_Register_acc_content")}
                </p>
                <p>
                  <b>{t("1_Payment_process")}</b> {t("1_Payment_process_content")}
                </p>
                <p>
                  <b>{t("1_Testimonials")}</b> {t("1_Testimonials_content")}
                </p>
                <p>
                  <b>{t("1_Authentication")}</b> {t("1_Authentication_content")} <u>contact@so9.vn</u>
                </p>
                <p>
                  <b>{t("1_Interaction_SO9")}</b> {t("1_Interaction_SO9_content")}
                </p>
                <u>
                  {t("4.1B_Infor_to_provide")}
                </u>
                <p>
                  <b>{t("1_Infor_Chomre")}</b> {t("1_Infor_Chomre_content")}
                </p>
                <p>
                  <b>{t("1_Infor_Cookie")}</b> {t("1_Infor_Cookie_content")}
                </p>
                <p>
                  <b>{t("1_Infor_Log")}</b> {t("1_Infor_Log_content")}
                </p>
                <u>
                  {t("4.1C_Infor_to_provide")}
                </u>
                <p>
                  <b>{t("1_Sign_in")}</b> {t("1_Sign_in_content")}
                </p>
                <p>
                  <b>{t("1_Introduce")}</b> {t("1_Introduce_content")}
                </p>
                <p>
                  <b>{t("1_Infor_partner")}</b> {t("1_Infor_partner_content")}
                </p>
                <p>
                  <b>{t("1_Infor_social")}</b> {t("1_Infor_social_content")}
                </p>
                <h6>{t("4.2_Purpose_to_Infor")}</h6>
                <p>
                  {t("4.2_tittle")}
                </p>
                <ul className="list list-sm">
                  <li>{t("4__storage_purposes_1")}</li>
                  <li>{t("4__storage_purposes_2")}</li>
                  <li>{t("4__storage_purposes_3")}</li>
                  <li>{t("4__storage_purposes_4")}</li>
                  <li>{t("4__storage_purposes_5")}</li>
                  <li>{t("4__storage_purposes_6")}</li>
                  <li>{t("4__storage_purposes_7")}</li>
                  <li>{t("4__storage_purposes_8")}</li>
                  <li>{t("4__storage_purposes_9")}</li>
                  <li>{t("4__storage_purposes_10")}</li>
                </ul>
                <h6>{t("4.3_Legal_grounds")}</h6>
                <p>
                  <i>{t("1_Base_So9")}</i> {t("1_Base_So9_content")}
                </p>
                <p>
                  <i>{t("1_agree")}</i> {t("1_agree_content")}
                </p>
                <p>
                  <i>{t("1_Legal")}</i> {t("1_Legal_content")}
                </p>
                <h6>{t("4.4_Your_choice")}</h6>
                
                <p>
                  <i>{t("1_Refuse_to_infor")}</i> {t("1_Refuse_to_infor_content")}
                </p>
                <p>
                  <i>{t("1_off_cookie")}</i> {t("1_off_cookie_content")}
                </p>
                <p>
                  <i>{t("1_Infor_option")}</i> {t("1_Infor_option_content")}
                </p>
                <h6>{t("4.5_share_infor")}</h6>
                <p>
                  {t("4.5_title")}
                </p>
                <h6>{t("4.6_Your_rights")}</h6>
                <p>
                  {t("4.6_title")}
                </p>
                <p>
                  <i>{t("1_Access_rights")}</i> {t("1_Access_rights_content")}
                </p>
                <p>
                  <i>{t("1_rectification")}</i> {t("1_rectification_content")}
                </p>
                <p>
                  <i>{t("1_Right_to_delete")}</i> {t("1_Right_to_delete_content")}
                </p>
                <p>
                  <i>{t("1_claim_process")}</i> {t("1_claim_process_content")}
                </p>
                
                <p>
                  <i>{t("1_claim_data")}</i> {t("1_claim_data_content")}
                </p>
                <p>
                  <i>{t("1_claim_counter")}</i> {t("1_claim_counter_content")}
                </p>
                <p>
                  <i>{t("1_claim_complain")}</i> {t("1_claim_complain_content")}
                </p>
                <h6>{t("4.7_save_infor")}</h6>
                <p>
                  {t("4.7_title")}
                </p>
                
                <h4>{t("5_info_So9_handle")}</h4>
                <p>
                  <u>{t("5_Commission_So9")}</u>
                </p>
                <p>
                  <b>{t("1_infor_service")}</b> {t("1_infor_service_content")}
                </p>
                <p>
                  <b>{t("1_infor_mobie")}</b> {t("1_infor_mobie_content")}
                </p>
                <p>{t("1_infor_notice")}</p>
                <p><u>{t("5__storage_purposes_1")}</u></p>
                <p>Chúng tôi nhận ra rằng bạn sở hữu dữ liệu dịch vụ của mình. Chúng tôi cung cấp cho bạn toàn quyền kiểm soát dữ liệu dịch vụ của mình bằng cách cung cấp cho bạn khả năng (i) truy cập dữ liệu dịch vụ của bạn, (ii) chia sẻ dữ liệu dịch vụ của bạn thông qua tích hợp bên thứ ba được hỗ trợ và (iii) yêu cầu xuất hoặc xóa dữ liệu dịch vụ của bạn.</p>
                <p><u>{t("5__storage_purposes_2")}</u></p>
                <p>Chúng tôi xử lý dữ liệu dịch vụ của bạn khi bạn cung cấp hướng dẫn cho chúng tôi thông qua các module khác nhau trong dịch vụ của chúng tôi. Ví dụ: khi bạn tạo hóa đơn bằng dịch vụ lập hóa đơn của chúng tôi, thông tin như tên và địa chỉ của khách hàng của bạn sẽ được sử dụng để tạo hóa đơn; và khi bạn sử dụng dịch vụ quản lý chiến dịch của chúng tôi để tiếp thị qua email, địa chỉ email của những người trong danh sách gửi thư của bạn sẽ được sử dụng để gửi email.</p>
                <p><u>{t("5__storage_purposes_3")}</u></p>
                <p>Nếu bạn đã bật thông báo trên máy tính để bàn và ứng dụng dành cho thiết bị di động của chúng tôi, chúng tôi sẽ đẩy thông báo qua nhà cung cấp thông báo đẩy như Dịch vụ thông báo đẩy của Apple, Nhắn tin qua đám mây của Google hoặc Dịch vụ thông báo đẩy của Windows. Bạn có thể quản lý tùy chọn thông báo đẩy của mình hoặc tắt các thông báo này bằng cách tắt thông báo trong ứng dụng hoặc cài đặt thiết bị.</p>
                <p><u>{t("5__storage_purposes_4")}</u></p>
                <p>
                  <b>{t("1_Group_So9")}</b> {t("1_Group_So9_content")}
                </p>
                <p>
                  <b>{t("1_Integration")}</b> {t("1_Integration_content")}
                </p>
                <p><u>{t("5__storage_purposes_5")}</u></p>
                <p>
                Chúng tôi giữ dữ liệu trong tài khoản của bạn miễn là bạn chọn sử dụng Dịch vụ SO9. 
                Khi bạn chấm dứt tài khoản người dùng SO9 của mình, dữ liệu của bạn cuối cùng sẽ bị xóa 
                khỏi cơ sở dữ liệu đang hoạt động trong lần dọn dẹp tiếp theo diễn ra 6 tháng một lần.
                 Dữ liệu bị xóa khỏi cơ sở dữ liệu đang hoạt động sẽ bị xóa khỏi bản sao lưu sau 3 tháng.
                </p>
                <p><u>{t("5__storage_purposes_6")}</u></p>
                <p>Nếu bạn đến từ Khu vực Kinh tế Châu Âu và bạn tin rằng chúng tôi lưu trữ, sử dụng hoặc xử lý thông tin của bạn 
                  thay mặt cho một trong những khách hàng của chúng tôi, vui lòng liên hệ với khách hàng nếu bạn muốn truy cập, 
                  chỉnh sửa, xóa, hạn chế hoặc phản đối việc xử lý, hoặc xuất dữ liệu cá nhân của bạn. 
                  Chúng tôi sẽ hỗ trợ khách hàng của mình trong việc đáp ứng yêu cầu của bạn trong một khung thời gian hợp lý.</p>
                <h4>{t("6_user_need_to_do")}</h4>
                <p>
                  {t("6_job_1")}
                </p>
                <p>
                  {t("6_job_2")}
                </p>
                <p>
                  {t("6_job_3")}
                </p>
                <p>
                  {t("6_job_4")}
                </p>
                <p>
                  {t("6_job_5")}
                </p>

                <h4> {t("7_organization_is_accessed")}</h4>
                <p>
                  {t("7_case_definiton")}
                </p>
                <ul className="list list-sm">
                  <li>{t("7_case_1")}</li>
                  <li>{t("7_case_2")}</li>
                  <li>{t("7_case_3")}</li>
                  <li>{t("7_case_4")}</li>
                  <li>{t("7_case_5")}</li>
                  <li>{t("7_case_6")}</li>
                </ul>
                <h4>{t("8_resolve_complaints")}</h4>
                <p>
                  {t("8_solution", { email: t("1_email"), hotline: t("1_phone_number") })}
                </p>
                <p>
                  {t("1_company_name")}
                </p>
                <p>
                  <b> {t("1_office")}</b>  {t("1_company_address")}
                </p>
                <p>
                  <b>{t("1_telephone")}</b> {t("1_phone_number")}
                </p>
                <p>
                  <i>
                    {t("8_resolve_complaints_note")}
                  </i>
                </p>
              </div>
            </PreviewCard>
          </Block>
        </div>
      </Content>
    </React.Fragment>
  );
};

export default Policy;
