import { useTranslation } from "react-i18next";

import BlockDataCommonItem from "../../CommonItem/BlockDataCommonItem";
import { ChartType } from "../../constants";
export const OverviewEffect = ({
  dataEffect,
  isLoading,
  dataReach,
  isLoadingReach,
}) => {
  const { t } = useTranslation();
  const labelX = dataEffect?.page_views_total?.dataSet?.map((d) => d?.x) || [];
  const dataChartPageView = [
    {
      name: "Page Profile Views",
      data: dataEffect?.page_views_total?.dataSet?.map((d) => d?.y) || [],
    },
  ];

  const dataChartPagePageAction = [
    {
      name: "Page Click",
      data: dataEffect?.page_total_actions?.dataSet?.map((d) => d?.y) || [],
    },
  ];

  //reach
  const dataChartPostImpression = [
    {
      name: "Post View",
      data: dataReach?.post_impression?.dataSet?.map((d) => d?.y) || [],
    },
  ];

  const dataChartPageImpressionUnique = [
    {
      name: "Page Impression",
      data: dataReach?.page_impression?.dataSet?.map((d) => d?.y) || [],
    },
  ];

  return (
    // <Row>
    <>
      <BlockDataCommonItem
        isLoading={isLoading}
        title={t("page_profile_view")}
        dataValue={dataEffect?.page_views_total?.value}
        hasChart={true}
        chartType={ChartType.LINE}
        dataChart={dataChartPageView}
        labelX={labelX}
        classIcon="ri-empathize-fill"
        colorIcon="#F06548"
        tooltipLabel={t(
          "general_overview.tooltip.channel.facebook.page_profile_view"
        )}
        tooltipId={"about_page_profile_view"}
      />

      <BlockDataCommonItem
        isLoading={isLoading}
        title={t("page_click")}
        dataValue={dataEffect?.page_total_actions?.value}
        hasChart={true}
        chartType={ChartType.LINE}
        dataChart={dataChartPagePageAction}
        labelX={labelX}
        classIcon="ri-cursor-fill"
        colorIcon="#F7B84B"
        tooltipLabel={t("general_overview.tooltip.channel.facebook.page_click")}
        tooltipId={"about_page_click"}
      />

      <BlockDataCommonItem
        isLoading={isLoading}
        title={t("feedback")}
        hasChart={true}
        chartType={ChartType.PIE}
        dataChart={dataEffect?.rate_feedback}
        classIcon="ri-error-warning-fill"
        colorIcon="#299CDB"
        tooltipLabel={t("general_overview.tooltip.channel.facebook.feedback")}
        tooltipId={"about_feedback"}
      />

      {/* 3 */}

      <BlockDataCommonItem
        isLoading={isLoadingReach}
        title={t("page_reach")}
        dataValue={dataReach?.post_impression?.value}
        hasChart={true}
        chartType={ChartType.LINE}
        dataChart={dataChartPostImpression}
        labelX={labelX}
        classIcon="ri-user-received-fill"
        colorIcon="#3577F1"
        tooltipLabel={t("general_overview.tooltip.channel.facebook.page_reach")}
        tooltipId={"about_page_reach"}
      />

      <BlockDataCommonItem
        isLoading={isLoadingReach}
        title={t("page_impression")}
        dataValue={dataReach?.page_impression?.value}
        hasChart={true}
        chartType={ChartType.LINE}
        dataChart={dataChartPageImpressionUnique}
        labelX={labelX}
        classIcon="ri-eye-fill"
        colorIcon="#4B38B3"
        tooltipLabel={t(
          "general_overview.tooltip.channel.facebook.page_impression"
        )}
        tooltipId={"about_page_impression"}
      />

      <BlockDataCommonItem
        isLoading={isLoadingReach}
        title={t("reach_source")}
        hasChart={true}
        chartType={ChartType.PIE}
        dataChart={dataReach?.rate_impression}
        classIcon="ri-radar-fill"
        colorIcon="#0AB39C"
        tooltipLabel={t(
          "general_overview.tooltip.channel.facebook.rate_impression"
        )}
        tooltipId={"about_rate_impression"}
      />
    </>

    // </Row>
  );
};
