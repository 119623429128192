import React from "react";
import { Mention, MentionsInput } from "react-mentions";
import style from "./index.module.scss";
import {  useSelector } from "react-redux";
const ContentOriginal = ({ ...props }) => {
  const listIcon = useSelector((state) => state.icon.Icons);
  const data = Object.keys(listIcon)?.map((e)=>(
    {
      id: e,
      display: "@icon{"+e+"}",
    }
  ))
 
    
  
  const text = [
    {
      id: "text{",
      display: "#text{",
    },
  ]
  const defaultStyle = {
    control: {
      backgroundColor: '#fff',
      fontSize: 16,
color:"red"
      // fontWeight: 'normal',
    },
    "&multiLine": {
      control: {
        fontFamily: 'Roboto',
        minHeight: 60,
      },
      highlighter: {
        minHeight: "130px",
        // marginTop: "0.75rem",
        padding: "0.65625rem 1rem 0.4375rem",
        fontSize: "0.8125rem",
        lineHeight: "1.25rem",
      },
      input: {
        minHeight: "80px",
        fontSize: "0.8125rem",
      },
    },
    '&singleLine': {
      display: 'inline-block',
      width: 180,
  
      highlighter: {
        padding: 1,
        border: '2px inset transparent',
      },
      input: {
        padding: 1,
        border: '2px inset',
      },
    },
    suggestions: {
      list: {
        backgroundColor: 'white',
            border: '1px solid rgba(0,0,0,0.15)',
            fontSize: 16,
      },
      item: {
        padding: "8px 16px",
        "&focused": {
          backgroundColor: "#e8ecee",
        },
      },
    },
  };
  const { innerRef: ref, ...otherProps } = props;
  const asyncTag = (query, callback) => {
    if (query.length) {
      const x = new Promise((resolve) => {
        resolve([
          {
            id: query,
            display: query,
          },
        ]);
      });
      x.then(callback);
    }
  };
  return (
    <>
      <MentionsInput
        ref={ref}
        {...otherProps}
        style={defaultStyle}
       classNames={{
          "form-control__control": "mt-0",
          "form-control__input": "form-control rounded-none border-0",
        }}
        a11ySuggestionsListLabel={'Suggested mentions'}
      >
        {/* <Mention
          trigger="#"
          markup="#__id__"
          data={asyncTag}
          className="bg-info-dim"
          displayTransform={(id) => {
            return `#${id}`;
          }}
          regex={"/#([A-Za-z0-9]+)/"}
        /> */}
        <Mention className={style.titleMention} data={data} displayTransform={(username) => `@icon{${username}} `}/>
        <Mention trigger="#" markup="#__id__" data={text} regex={/$(\S+)/} />
        <Mention
          trigger="#text"
          displayTransform={(username) => `#text{${username}}`}
          markup="#text{__id__}"
          data={[]}
          regex={/#text{([^{}]*)}/}
          className={style.titleMention}
          appendSpaceOnAdd
        />
      </MentionsInput>
    </>
  );
};

export default ContentOriginal;
