import React from 'react'
import { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import {
    Block,
    BlockHead,
    BlockTitle,
    BlockBetween,
    BlockHeadContent,
    BlockDes,
    Icon,
    Row,
    Col,
    Button,
    DataTableHead,
    DataTableBody,
    DataTableRow,
    DataTableItem,
    PaginationComponent,
} from "../../../components/Component";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import style from "./index.module.scss";
import { getDashboardChannelReport } from "../../../api/overview";
import { getDashboardEmployeeReport } from "../../../api/overview";
import { Card, DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, Badge, Spinner } from "reactstrap";
import ReportFrequencyByChannel from './ReportFrequencyByChannel';
import ReportFrequencyByUser from './ReportFrequencyByUser';
export default function ReportFacebook() {
    const { t } = useTranslation();
    const ranges = [7, 14, 30, 60, 90];
    const [rangePage, setRangePage] = useState(7);
    const [rangeGroup, setRangeGroup] = useState(7)
    const [reportDataPage, setReportDataPage] = useState([]);
    const [reportDataGroup, setReportDataGroup] = useState([]);
    const [reportDataEmp, setReportDataEmp] = useState([]);
    const [loadingChannelReport, setLoadingChannelReport] = useState(false);
    const [loadingEmployeeReport, setLoadingEmployeeReport] = useState(false);
    const project = useSelector((state) => state.project.project);
    useEffect(() => {
        setLoadingChannelReport(true);
        if (project?._id) {
            const data = {
                params: {
                    project_id: project?._id,
                    time: `${rangePage} days`,
                },
            };
            getDashboardChannelReport(data).then((response) => {
                setReportDataPage(response.data || []);
                setLoadingChannelReport(false);
            });
        }
    }, [rangePage, project?._id]);
    useEffect(() => {
        setLoadingChannelReport(true);
        if (project?._id) {
            const data = {
                params: {
                    project_id: project?._id,
                    time: `${rangeGroup} days`,
                },
            };
            getDashboardChannelReport(data).then((response) => {
                setReportDataPage(response.data || []);
                setLoadingChannelReport(false);
            });
        }
    }, [rangeGroup, project?._id]);

    useEffect(() => {
        setLoadingEmployeeReport(false);
        if (project?._id) {
            const data = {
                params: {
                    project_id: project?._id,
                    time: `${rangeGroup} days`,
                },
            };
            getDashboardEmployeeReport(data).then((response) => {
                setReportDataEmp(response.data || []);
                setLoadingEmployeeReport(false);
            });
        }
    }, [rangeGroup, project?._id]);
    const formatNumber = (num = "", split = ".") =>
        num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, split) : 0
    return (
        <React.Fragment>
            <Head title="Phân tích"></Head>
            {/* New box */}
            <ReportFrequencyByUser ranges={ranges} />
            <ReportFrequencyByChannel ranges={ranges} />
            {/* Old box */}
            <BlockHead size="sm">
                <BlockBetween>
                    <BlockHeadContent>
                        <BlockTitle className={`${style.tittle}`}>Hiệu quả trang</BlockTitle>
                    </BlockHeadContent>
                    <BlockHeadContent>
                        <div className="toggle-wrap nk-block-tools-toggle">
                            <a
                                href="#more"
                                className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    //updateSm(!sm);
                                }}
                            >
                                <Icon name="more-v"></Icon>
                            </a>
                            <div className="toggle-expand-content">
                                <ul className="nk-block-tools g-3">

                                    <li>
                                        <UncontrolledDropdown>
                                            <DropdownToggle tag="a" className="dropdown-toggle btn btn-white btn-dim btn-outline-light">
                                                <Icon className="d-none d-sm-inline" name="calender-date"></Icon>
                                                <span>
                                                    <span className="d-none d-md-inline">{t("overview.range_text", { days: rangePage })}</span>
                                                </span>
                                                <Icon className="dd-indc" name="chevron-right"></Icon>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <ul className="link-list-opt no-bdr">
                                                    {ranges.map((r) => (
                                                        <li key={r}>
                                                            <DropdownItem
                                                                href="#dropdownitem"
                                                                onClick={() => {
                                                                    setRangePage(r);
                                                                }}
                                                            >
                                                                {t("overview.range_text", { days: r })}
                                                            </DropdownItem>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </BlockHeadContent>
                </BlockBetween>
            </BlockHead>
            <Block>
                <Card className="card-bordered">
                    <div className="card-inner-group">
                        <div className="card-inner p-0">
                            <DataTableBody>
                                <DataTableHead>
                                    <DataTableRow size="sm">
                                        <span>Trang</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span>Bài đăng</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span>Lượt tiếp cận</span>
                                    </DataTableRow>
                                    <DataTableRow size="md">
                                        <span>Người tiếp cận</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span>Lượt thích</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span>Bình luận</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span>Theo dõi mới</span>
                                    </DataTableRow>
                                </DataTableHead>
                                {!loadingChannelReport &&
                                    reportDataPage.filter(item => item.platform === 1).map((item) => {
                                        return (
                                            <DataTableItem key={item.id}>
                                                <DataTableRow className="no-border">
                                                    <div className="icon-text">
                                                        <img className="tb-image mr-2" src={item.image} alt="channel" />
                                                        {/* <Icon className={`text-${item.theme}`} name="globe"></Icon> */}
                                                        <span className="tb-lead">{item.name}</span>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow className="no-border">
                                                    <span className="tb-sub tb-amount">
                                                        <span>{formatNumber(item.report_new_posts)}</span>
                                                    </span>
                                                </DataTableRow>
                                                <DataTableRow className="no-border">
                                                    <span className="tb-sub tb-amount">
                                                        <span>{formatNumber(item.report_impressions)}</span>
                                                    </span>
                                                </DataTableRow>
                                                <DataTableRow className="no-border">
                                                    <span className="tb-sub tb-amount">
                                                        <span>{formatNumber(item.report_impressions_unique)}</span>
                                                    </span>
                                                </DataTableRow>
                                                <DataTableRow className="no-border">
                                                    <span className="tb-sub tb-amount">
                                                        <span>{formatNumber(item.total_like)}</span>
                                                    </span>
                                                </DataTableRow>
                                                <DataTableRow className="no-border">
                                                    <span className="tb-sub tb-amount">
                                                        <span>{formatNumber(item.total_comment)}</span>
                                                    </span>
                                                </DataTableRow>
                                                <DataTableRow className="no-border">
                                                    <span className="tb-sub tb-amount">
                                                        <span>{formatNumber(item.report_fan_adds)}</span>

                                                    </span>
                                                </DataTableRow>
                                            </DataTableItem>
                                        );
                                    })}
                            </DataTableBody>
                        </div>
                        {
                            loadingChannelReport && <div className="card-inner p-0 d-flex justify-content-center">
                                <Spinner size="md" className='m-1' />
                            </div>
                        }
                    </div>
                </Card>
            </Block>
            <BlockHead size="sm">
                <BlockBetween>
                    <BlockHeadContent>
                        <BlockTitle className={`${style.tittle}`}>Báo cáo Nhân Viên</BlockTitle>
                    </BlockHeadContent>
                    <BlockHeadContent>
                        <div className="toggle-wrap nk-block-tools-toggle">
                            <a
                                href="#more"
                                className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    //updateSm(!sm);
                                }}
                            >
                                <Icon name="more-v"></Icon>
                            </a>
                            <div className="toggle-expand-content">
                                <ul className="nk-block-tools g-3">

                                    <li>
                                        <UncontrolledDropdown>
                                            <DropdownToggle tag="a" className="dropdown-toggle btn btn-white btn-dim btn-outline-light">
                                                <Icon className="d-none d-sm-inline" name="activity-round"></Icon>
                                                <span>
                                                    <span className="d-none d-md-inline">{t("overview.range_text", { days: rangeGroup })}</span>
                                                </span>
                                                <Icon className="dd-indc" name="chevron-right"></Icon>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <ul className="link-list-opt no-bdr">
                                                    {ranges.map((r) => (
                                                        <li key={r}>
                                                            <DropdownItem
                                                                href="#dropdownitem"
                                                                onClick={() => {
                                                                    setRangeGroup(r);
                                                                }}
                                                            >
                                                                {t("overview.range_text", { days: r })}
                                                            </DropdownItem>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </li>
                                    <li>
                                        <UncontrolledDropdown>
                                            <DropdownToggle tag="a" className="dropdown-toggle btn btn-white btn-dim btn-outline-light">
                                                <Icon className="d-none d-sm-inline" name="calender-date"></Icon>
                                                <span>
                                                    <span className="d-none d-md-inline">{t("overview.range_text", { days: rangeGroup })}</span>
                                                </span>
                                                <Icon className="dd-indc" name="chevron-right"></Icon>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <ul className="link-list-opt no-bdr">
                                                    {ranges.map((r) => (
                                                        <li key={r}>
                                                            <DropdownItem
                                                                href="#dropdownitem"
                                                                onClick={() => {
                                                                    setRangeGroup(r);
                                                                }}
                                                            >
                                                                {t("overview.range_text", { days: r })}
                                                            </DropdownItem>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </BlockHeadContent>
                </BlockBetween>
            </BlockHead>
            <Block>
                <Card className="card-bordered">
                    <div className="card-inner-group">
                        <div className="card-inner p-0">
                            <DataTableBody>
                                <DataTableHead>
                                    <DataTableRow size="sm">
                                        <span>Nhân viên</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span>Bài đăng</span>
                                    </DataTableRow>
                                    <DataTableRow size="md">
                                        <span>Người tiếp cận</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span>Lượt thích</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span>Bình luận</span>
                                    </DataTableRow>
                                </DataTableHead>
                                {!loadingEmployeeReport && reportDataEmp.map((item) => {
                                    return (
                                        <DataTableItem key={item.id}>
                                            <DataTableRow className="no-border">
                                                <div className="icon-text">

                                                    <span className="tb-lead">{item.name}</span>
                                                </div>
                                            </DataTableRow>
                                            <DataTableRow className="no-border">
                                                <span className="tb-sub tb-amount">
                                                    <span>{formatNumber(item.number_post)}</span>
                                                </span>
                                            </DataTableRow>
                                            <DataTableRow className="no-border">
                                                <span className="tb-sub tb-amount">
                                                    <span>{formatNumber(item.impression_unique)}</span>
                                                </span>
                                            </DataTableRow>
                                            <DataTableRow className="no-border">
                                                <span className="tb-sub tb-amount">
                                                    <span>{formatNumber(item.total_like)}</span>
                                                </span>
                                            </DataTableRow>
                                            <DataTableRow className="no-border">
                                                <span className="tb-sub tb-amount">
                                                    <span>{formatNumber(item.total_comment)}</span>
                                                </span>
                                            </DataTableRow>
                                        </DataTableItem>
                                    );
                                })}
                            </DataTableBody>
                        </div>
                        {
                            loadingEmployeeReport && <div className="card-inner p-0 d-flex justify-content-center">
                                <Spinner size="md" className='m-1' />
                            </div>
                        }
                    </div>
                </Card>
            </Block>
        </React.Fragment>
    )
}
