import { Card, CardBody, Col, Tooltip } from "reactstrap";

import { LoadingItem } from "../../CommonItem/LoadingItem";
import { useState } from "react";

export const GeneralItem = ({
  data,
  isLoading,
  label,
  classIcon,
  backgroundColor,
  colorIcon,
  tooltipLabel,
  tooltipId,
}) => {
  const [tooltipOpen, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!tooltipOpen);
  };
  return (
    <Col xl={3} md={6} className="py-2">
      <Card className="card-animate">
        <div className="position-absolute start-0" style={{ zIndex: "0" }}>
          <svg
            version="1.2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 120"
            width="200"
            height="120"
          >
            <path
              id="Shape 8"
              style={{ opacity: ".05", fill: "#299cdb" }}
              d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
            />
          </svg>
        </div>
        <CardBody>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1 overflow-hidden">
              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                {" "}
                {label}
              </p>
              {isLoading ? (
                <LoadingItem />
              ) : (
                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                  <span className="counter-value" data-target={data}>
                    {data}
                  </span>
                </h4>
              )}
            </div>
            <div
              className="flex-shrink-0"
              style={{
                cursor: "pointer",
              }}
              id={`about_${tooltipId}`}
            >
              <div
                style={{
                  width: "48px",
                  height: "48px",
                  background: backgroundColor,
                  borderRadius: 9999,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i className={classIcon} style={{ color: colorIcon }}></i>
              </div>
              {tooltipLabel && (
                <Tooltip
                  placement="bottom"
                  isOpen={tooltipOpen}
                  target={`about_${tooltipId}`}
                  toggle={toggle}
                  style={{ width: "100%", textAlign: "left" }}
                >
                  <div>{tooltipLabel}</div>
                </Tooltip>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};
