import { API } from "../libs/client";
export const getPricingData = async ({type}) => {
    try {
      const response = await API.get(`pricing/${type}`);
      return(response.data); 
    } catch (error) {
      console.log(error);
        return null;
    }
  };
  export const getOptionsPricing = async ({type}) => {
    try {
      const response = await API.get(`pricing/${type}/customize`);
      return(response.data); 
    } catch (error) {
      console.log(error);
        return null;
    }
  };