import React from "react";

import checkboxB from "../../images/icons/checkboxB-icon.png";
import checkboxG from "../../images/icons/checkboxG-icon.png";
import pricingBusiness from "../../images/icons/pricing-business.png";
const SocialFeature = () => {
    return (
        <div className="pricing-feature-area">
            <p className="pricing-feature-title">Các tính năng chính của SO9 SOCIAL</p>
            <div className="grid-container">
                <div className="grid-item d-flex">
                <img className="grid-item-checkboxicon" src={checkboxB}></img>
                    <div className="content">
                        <p> Hẹn Giờ đăng </p> <span>bài lên các nền tảng: Facebook, Tiktok, Insta, Youtube</span>
                    </div>
                </div>
                <div className="grid-item d-flex">
                <img className="grid-item-checkboxicon" src={checkboxB}></img>
                    <div className="content">
                        <p> 9Reup:</p> <span> Dẫn nguồn nội dung từ các nền tảng về đăng lại.</span>
                        <a href="https://so9.vn/9reup/" target="_blank">(Xem thêm)</a>
                    </div>
                </div>
                <div className="grid-item d-flex">
                <img className="grid-item-checkboxicon" src={checkboxB}></img>
                    <div className="content">
                        <p> Lịch tự động:</p> <span> Tự lên lịch hàng trăm Kênh theo cấu hình đặt sẵn.</span>
                    </div>
                </div>
                <div className="grid-item d-flex">
                <img className="grid-item-checkboxicon" src={checkboxB}></img>
                    <div className="content">
                        <p>Spin nội dung: </p> <span> Tự động chọn ngẫu nhiên nội dung, icon cho mỗi bài đăng.</span>
                    </div>
                </div>
                <div className="grid-item d-flex">
                <img className="grid-item-checkboxicon" src={checkboxB}></img>
                    <div className="content">
                        <p> Bình luận tự động:  </p> <span> Tự cấu hình kịch bản bình luận cho bài đăng hẹn giờ.</span>
                    </div>
                </div>
                <div className="grid-item d-flex">
                <img className="grid-item-checkboxicon" src={checkboxB}></img>
                    <div className="content">
                        <p> Seeding hội nhóm: </p> <span> Thêm nhiều VIA hẹn giờ đăng nội dung vào Group của mình</span>
                    </div>
                </div>
                <div className="grid-item d-flex">
                <img className="grid-item-checkboxicon" src={checkboxB}></img>
                    <div className="content">
                        <p>Chia VIA: </p> <span> Hệ thống đăng bài qua các VIA khác nhau giảm spam cho VIA.</span>
                    </div>
                </div>
                <div className="grid-item d-flex">
                <img className="grid-item-checkboxicon" src={checkboxB}></img>
                    <div className="content">
                        <span>Quản lý bình luận, tin nhắn </span>   <p> (Bản basic) </p>
                    </div>
                </div>
                <div className="grid-item d-flex">
                <img className="grid-item-checkboxicon" src={checkboxB}></img>
                    <div className="content">
                        <p> SO9 App: </p> <span> Ứng dụng cảnh báo khi VIA, bài đăng hoặc Kênh bị lỗi.</span>
                    </div>
                </div>
                <div className="grid-item d-flex">
                <img className="grid-item-checkboxicon" src={checkboxB}></img>
                    <div className="content">
                        <p> Báo cáo hiệu quả kênh: </p> <span> Lượt tiếp cận, tương tác, tệp khách hàng,....</span>
                    </div>
                </div>
                <div className="grid-item-bg grid-item d-flex ">
                <img className="grid-item-checkboxicon" src={checkboxG}></img>
                    <div className="content">
                        <p> Báo cáo nhân viên: </p> <span> Theo dõi năng suất, hiệu quả nhân sự.</span>
                    </div>
                    <img src={pricingBusiness} />
                    <div className="d-flex justify-content-center pricing-business">BUSSINESS</div>
                </div>
                <div className="grid-item-bg grid-item d-flex ">
                    <img className="grid-item-checkboxicon" src={checkboxG}></img>
                    <div className="content">
                        <p> Báo cáo nội dung: </p> <span> Theo dõi hiệu quả từng bài đăng trên nền tảng.</span>
                    </div>
                    <img src={pricingBusiness} />
                    <div className="d-flex justify-content-center pricing-business">BUSSINESS</div>
                </div>
            </div>

        </div>
    );

};

export default SocialFeature;
