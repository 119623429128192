import React, { useState, useEffect } from "react";
import Head from "../../../layout/head/Head";
import { useHistory } from "react-router";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setPostTabPage,
  resetPostTabPage,
  setPostDataTab,
  setNumberPost,
} from "../../reducers/managePostSlice";
import Icon from "../../../components/icon/Icon";
import style from "./index.module.scss";
import "./create-post.scss";
import IconWarning from "../../images/icons/IconWarning.svg";
import { getErrorChannel } from "../../../api/channel";
import { setCollapseSidebarLeft } from "../../reducers/layoutSlice";
import {
  AUTO_POST,
  MANUAL_POST,
  WAREHOUSE_FB,
  WAREHOUSE_IG,
} from "../../utils/Utils";
import ManagePost from "./ManagePost";
import CalendarSVG from "../../images/calendar.svg";
import EditSVG from "../../images/edit.svg";
import SearchIcon from "../../images/SearchIcon.svg";
import { Link } from "react-router-dom";
import { t } from "i18next";

export default function ManagePostDashboard() {
  const dispatch = useDispatch();
  const managePostTab = useSelector((state) => state.managePostTab);
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(MANUAL_POST);
  const [numChannels, setNumChannels] = useState(0);
  const userInformation = JSON.parse(localStorage.getItem("userInfor"));
  const [listPostByFetch, setListPostByFetch] = useState([]);
  const [isHiddenSearch, setIsHiddenSearch] = useState(true);
  const [isSinglePlatform, setIsSinglePlatform] = useState(false);
  const [showTopRightOption, setShowTopRightOption] = useState(false);
  const [openModalDeletePost, setOpenModalDeletePost] = useState(false);
  const [modalReloadSM, setModalReloadSM] = useState(false);
  const projectId = userInformation.last_project_active;
  useEffect(() => {
    if (window.location.href.includes("warehouse_fb")) {
      setActiveTab(WAREHOUSE_FB);
    } else if (window.location.href.includes("warehouse_ig")) {
      setActiveTab(WAREHOUSE_IG);
    } else if (window.location.href.includes("auto")) {
      setActiveTab(AUTO_POST);
    } else {
      setActiveTab(MANUAL_POST);
    }
    const pageNum =
      parseInt(new URLSearchParams(window.location.search).get("page")) || 1;
    dispatch(setCollapseSidebarLeft(true));
    for (const element of document.getElementsByClassName(
      "manage_post_pagination"
    )) {
      element.value = pageNum;
    }
    dispatch(
      setPostTabPage({
        tab: activeTab,
        currentPage: pageNum,
      })
    );
  }, [activeTab]);
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setListPostByFetch([]);
      history.push(
        `${window.location.pathname}?page=1${(tab === MANUAL_POST && "&type=manual") ||
        (tab === AUTO_POST && "&type=auto") ||
        (tab === WAREHOUSE_FB && "&type=warehouse_fb") ||
        (tab === WAREHOUSE_IG && "&type=warehouse_ig")
        }`
      );
      dispatch(resetPostTabPage());
      setActiveTab(tab);
      for (const element of document.getElementsByClassName(
        "manage_post_pagination"
      )) {
        element.value = 1;
      }
    }
  };
  useEffect(() => {
    getChannelError(projectId);
  }, [projectId]);

  useEffect(() => {
    if (activeTab === WAREHOUSE_FB || activeTab === WAREHOUSE_IG) {
      setIsSinglePlatform(true);
    }
  }, [activeTab]);

  const getChannelError = (projectId) => {
    const fetchData = async () => {
      return await getErrorChannel(projectId);
    };
    fetchData().then((response) => {
      const data = response.data;
      setNumChannels(data);
    });
  };

  const handleShowSearch = () => {
    setIsHiddenSearch(!isHiddenSearch);
  };

  const handleDeleteMultiPost = () => {
    setOpenModalDeletePost(true);
  };

  const toggleReloadSM = () => {
    //clearInterval()
    setModalReloadSM(!modalReloadSM);
  };

  const renderFilter = () => {
    return (
      <>
        {activeTab !== MANUAL_POST && activeTab !== AUTO_POST ? (
          ""
        ) : (
          <div className="d-flex mr-2" style={{ gap: "12px" }}>
            <NavItem className={`${style.navWrapper}`}>
              <Button className={style.btnSearch} onClick={handleShowSearch}>
                {!isHiddenSearch ? (
                  <div className={style.iconContainer}>
                    <img
                      src={SearchIcon}
                      alt={SearchIcon}
                      className={`rounded ${style.iconBtn}`}
                    />
                    <div className={style.searchIcon}></div>
                  </div>
                ) : (
                  <img
                    src={SearchIcon}
                    alt={SearchIcon}
                    className={`rounded ${style.iconBtn}`}
                  />
                )}
                <span>{t("manage_post.navigation.filter")}</span>
              </Button>
            </NavItem>
            <NavItem className={`${style.navWrapper}`}>
              <div className={`${style.btnSearch} ${style.bgSearch}`}>
                <Link
                  to={`${process.env.PUBLIC_URL}/calender/postChannel`}
                  className="link"
                  target="_blank"
                >
                  <img
                    src={CalendarSVG}
                    alt={CalendarSVG}
                    className={`rounded ${style.iconBtn}`}
                  />
                  <span className={style.textSearch}>{t("manage_post.navigation.calendar_view")}</span>
                </Link>
              </div>
            </NavItem>
            <NavItem className={`${style.navWrapper}`}>
              <Dropdown
                className={`${style.btnSearch} `}
                style={{ padding: "7px" }}
                isOpen={showTopRightOption}
                toggle={() => setShowTopRightOption(!showTopRightOption)}
              >
                <DropdownToggle
                  tag="span"
                  onClick={() => setShowTopRightOption(!showTopRightOption)}
                  data-toggle="dropdown"
                  aria-expanded={showTopRightOption}
                >
                  <div>
                    <div className="m-auto">
                      <Icon
                        style={{ marginRight: "5px" }}
                        name="edit-alt-fill"
                        role="button"
                      ></Icon>
                      {t("manage_post.navigation.option.title")}
                    </div>
                  </div>
                </DropdownToggle>
                <DropdownMenu className={style.DropdownContainer} style={{ marginRight: "18px" }}>
                  <DropdownItem onClick={() => handleDeleteMultiPost()} className={style.dropdownElement}>
                    <Icon name="trash"></Icon> <span> {t("manage_post.navigation.option.delete_post_by_channel")}</span>
                  </DropdownItem>
                  <DropdownItem onClick={() => toggleReloadSM()} className={style.dropdownElement}>
                    <Icon name="box"></Icon> <span>{t("manage_post.navigation.option.re_run_auto_calendar")}</span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </NavItem>
          </div >
        )
        }
      </>
    );
  };

  return (
    <React.Fragment>
      <Head title={t("manage_post.header")}></Head>
      <div
        style={{
          margin: "65px 1rem 2rem 1rem",
          position: "relative",
          padding: "24px 5px 0px 5px",
        }}
      >
        <Nav tabs className={`${style.manage_navPost}`}>
          <div className="d-flex ml-1">
            <NavItem className={`${style.navWrapper} ml-2`}>
              <NavLink
                tag="a"
                href="#tab"
                className={`${activeTab === MANUAL_POST && style.active} ${style.navItem
                  }`}
                style={{ borderTopLeftRadius: "8px" }}
                onClick={(ev) => {
                  ev.preventDefault();
                  setIsSinglePlatform(false);
                  toggle(MANUAL_POST);
                }}
              >
                {t("manage_post.navigation.scheduled")}{" "}
                {`(${managePostTab.numberPost[MANUAL_POST] || 0})`}
              </NavLink>
            </NavItem>
            <NavItem className={`${style.navWrapper}`}>
              <NavLink
                tag="a"
                href="#tab"
                className={`${activeTab === AUTO_POST && style.active} ${style.navItem
                  }`}
                style={{ borderTopRightRadius: "8px" }}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle(AUTO_POST);
                  setIsSinglePlatform(false);
                }}
              >
                {t("manage_post.navigation.queue")}{" "}
                {`(${managePostTab.numberPost[AUTO_POST] || 0})`}
              </NavLink>
            </NavItem>
            <NavItem className={`${style.navWrapper}`}>
              <NavLink
                tag="a"
                href="#tab"
                className={`${(activeTab === WAREHOUSE_FB || activeTab === WAREHOUSE_IG) &&
                  style.active
                  } ${style.navItem}`}
                style={{ borderTopRightRadius: "8px" }}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle(WAREHOUSE_FB);
                  setIsSinglePlatform(true);
                }}
              >
                {t("manage_post.navigation.campaign")}
              </NavLink>
            </NavItem>
          </div>
          {renderFilter()}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={MANUAL_POST} className="postWrapper">
            <ManagePost
              activeTab={activeTab}
              tabId={MANUAL_POST}
              managePostTab={managePostTab}
              setPostTabPage={(data) => dispatch(setPostTabPage(data))}
              setPostDataTab={(data) => dispatch(setPostDataTab(data))}
              setNumberPost={(data) => dispatch(setNumberPost(data))}
              listPostByFetch={listPostByFetch}
              setListPostByFetch={setListPostByFetch}
              isHiddenSearch={isHiddenSearch}
              isSinglePlatform={isSinglePlatform}
              setIsSinglePlatform={setIsSinglePlatform}
            />
          </TabPane>
          <TabPane tabId={AUTO_POST} className="postWrapper">
            <ManagePost
              activeTab={activeTab}
              tabId={AUTO_POST}
              managePostTab={managePostTab}
              setPostTabPage={(data) => dispatch(setPostTabPage(data))}
              setPostDataTab={(data) => dispatch(setPostDataTab(data))}
              setNumberPost={(data) => dispatch(setNumberPost(data))}
              listPostByFetch={listPostByFetch}
              setListPostByFetch={setListPostByFetch}
              isHiddenSearch={isHiddenSearch}
              isSinglePlatform={isSinglePlatform}
              setIsSinglePlatform={setIsSinglePlatform}
            />
          </TabPane>
          <TabPane
            tabId={activeTab === WAREHOUSE_FB ? WAREHOUSE_FB : WAREHOUSE_IG}
            className="postWrapper"
          >
            <ManagePost
              activeTab={activeTab}
              tabId={activeTab === WAREHOUSE_FB ? WAREHOUSE_FB : WAREHOUSE_IG}
              managePostTab={managePostTab}
              toggle={toggle}
              setPostTabPage={(data) => dispatch(setPostTabPage(data))}
              setPostDataTab={(data) => dispatch(setPostDataTab(data))}
              setNumberPost={(data) => dispatch(setNumberPost(data))}
              listPostByFetch={listPostByFetch}
              setListPostByFetch={setListPostByFetch}
              isHiddenSearch={isHiddenSearch}
              isSinglePlatform={isSinglePlatform}
              setIsSinglePlatform={setIsSinglePlatform}
            />
          </TabPane>
        </TabContent>
        <ModalDeleteMultiPost
          openModal={openModalDeletePost}
          setOpenModal={setOpenModalDeletePost}
        //   refetch={ListPostCalendar}
        />
        <ModalReloadSM
          modalReloadSM={modalReloadSM}
          setModalReloadSM={setModalReloadSM}
        ></ModalReloadSM>
      </div>
    </React.Fragment>
  );
}
const ModalDeleteMultiPost = React.lazy(() =>
  import("../../components/Calender/ModalDeleteMutilPost")
);
const ModalReloadSM = React.lazy(() =>
  import("../../components/Modal/ModalReloadSM")
);
