import React from 'react'
import { useSelector } from 'react-redux'
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockTitle,
  BlockBetween,
  BlockHeadContent,
  Icon,
  Button,
  DataTable,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  UserAvatar,
  TooltipComponent,
  Img
} from "../../../components/Component";
import { Card, DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, ModalHeader } from "reactstrap";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import style from "../ConnectChannel/connect.module.scss";
import bigSquaredFacebook from "../../images/icons/big-squared-facebook.svg";
import bigSquaredTiktok from "../../images/icons/big-squared-tiktok.svg";
import style1 from "./index.module.scss";
import { useState } from 'react';

export default function ManagementACC() {
  const dataListChannel = useSelector((state) => state.channel.channelsInformation);
  const [opennewsToken, setOpennewsToken] = useState(true)
  const toggleModalListChannel = () => { }
  return (
    <React.Fragment>
      <Head title={"t('product.product')"}></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>Quản trị ACC nền tảng</BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card className="card-bordered">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                <DataTable className="card-stretch">
                  <div className="card-inner position-relative card-tools-toggle">
                    <div className="card-title-group" style={{ justifyContent: "flex-end" }}>
                      <div className="card-tools mr-n1">
                        <ul className="btn-toolbar gx-1" >
                          <li>
                            <Button
                              className="toggle d-none d-md-inline-flex"
                              style={{ color: "#FFFFFF", backgroundColor: "#FD1F6D" }}
                              onClick={() => {
                                //clickCreateAcc();
                              }}
                            >
                              <Icon name="plus-circle-fill"></Icon>
                              <span>Liên kết mới</span>
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <DataTableBody>
                    <DataTableHead>
                      <DataTableRow className="nk-tb-col-check">
                        <div className="custom-control custom-control-sm custom-checkbox notext">
                          <input
                            type="checkbox"
                            className="custom-control-input form-control"
                            id="uid_1"
                          //onChange={(e) => selectorCheck(e)}
                          />
                          <label className="custom-control-label" htmlFor="uid_1"></label>
                        </div>
                      </DataTableRow>
                      <DataTableRow className={`${style.test}`}>
                        <span>Tên tài khoản liên kết</span>
                      </DataTableRow>
                      <DataTableRow>
                        <span>Loại Acc</span>
                      </DataTableRow>
                      <DataTableRow size="sm">
                        <span>Kết nối token</span>
                      </DataTableRow>
                      <DataTableRow className="nk-tb-actions">
                        <span>Thao tác</span>
                      </DataTableRow>
                    </DataTableHead>
                    {dataListChannel &&
                      dataListChannel.map((item, key) => {
                        return (
                          <DataTableItem key={item._id}>
                            <DataTableRow className="nk-tb-col-check">
                              <div className="custom-control custom-control-sm custom-checkbox notext">
                                <input
                                  type="checkbox"
                                  className="custom-control-input form-control"
                                  defaultChecked={item.check}
                                  id={item._id + "uid1"}
                                  key={Math.random()}
                                //onChange={(e) => onSelectChange(e, item._id)}
                                />
                                <label className="custom-control-label" htmlFor={item._id + "uid1"}></label>
                              </div>
                            </DataTableRow>
                            <DataTableRow style={{ width: 0 }}>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <div>
                                  <UserAvatar theme="primary" image={item.picture}></UserAvatar>
                                </div>
                                <div style={{ marginLeft: "4px" }} className={`${style.line}`}>
                                  {item.name}
                                </div>
                              </div>
                            </DataTableRow>
                            <DataTableRow >
                              <div className="icon-text">
                                <Img
                                  className="tb-image mr-2"
                                  src={bigSquaredTiktok}
                                  alt="channel"
                                  width="16px"
                                  height="16px"
                                />
                                {/* <Icon className={`text-${item.theme}`} name="globe"></Icon> */}
                                <span className="tb-lead">Facebook</span>
                              </div>
                            </DataTableRow>
                            <DataTableRow >
                              <div className="icon-text">
                                <Img
                                  className={`tb-image mr-2 ${style1.connectToken}`}
                                  src={bigSquaredFacebook}
                                  alt="channel"
                                  width="24px"
                                  height="24px"
                                />
                                {/* <Icon className={`text-${item.theme}`} name="globe"></Icon> */}
                                <Img
                                  className={`tb-image mr-2 ${style1.connectToken}`}
                                  src={bigSquaredFacebook}
                                  alt="channel"
                                  width="24px"
                                  height="24px"
                                />
                                <Img
                                  className={`tb-image mr-2 ${style1.connectToken}`}
                                  src={bigSquaredFacebook}
                                  alt="channel"
                                  width="24px"
                                  height="24px"
                                />
                                <Img
                                  className={`tb-image mr-2 ${style1.connectToken}`}
                                  src={bigSquaredFacebook}
                                  alt="channel"
                                  width="24px"
                                  height="24px"
                                />
                              </div>
                            </DataTableRow>

                            <DataTableRow className="nk-tb-col-tools">
                              <ul className="list-inline nk-tb-actions gx-1 my-n1">
                                <li className="mr-n1">
                                  <TooltipComponent
                                    tag="a"
                                    containerClassName="btn btn-trigger btn-icon"
                                    id={"edit" + item.id}
                                    icon="repeat"
                                    direction="right-start"
                                    text="Remove"
                                  />
                                </li>
                                <li className="mr-n1">
                                  <UncontrolledDropdown>
                                    <DropdownToggle
                                      tag="a"
                                      href="#more"
                                      onClick={(ev) => ev.preventDefault()}
                                      className="dropdown-toggle btn btn-icon btn-trigger"
                                    >
                                      <Icon name="more-h"></Icon>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <ul className="link-list-opt no-bdr">
                                        <li>
                                          <DropdownItem
                                            tag="a"
                                          // onClick={(ev) => {
                                          //   ev.preventDefault();
                                          //   onEditClick(item._id);

                                          // }}
                                          >
                                            <Icon name="eye"></Icon>
                                            <span>Sửa </span>
                                          </DropdownItem>
                                        </li>
                                        <li>
                                          <DropdownItem
                                            tag="a"
                                            href="#remove"
                                          // onClick={(ev) => {
                                          //   ev.preventDefault();
                                          //   DeleteProduct(item._id);
                                          // }}
                                          >
                                            <Icon name="trash"></Icon>
                                            <span>Xóa</span>
                                          </DropdownItem>
                                        </li>
                                      </ul>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </li>
                              </ul>
                            </DataTableRow>
                          </DataTableItem>
                        );
                      })}
                  </DataTableBody>
                  <div className="card-inner">
                    {/* {data.length > 0 ? (
                    <PaginationComponent
                      itemPerPage={itemPerPage}
                      totalItems={totalItems}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  ) : (
                    false ?
                      (
                        <div className="text-center">
                          <Spinner color="dark" />
                        </div>) :
                      (
                        <div className="text-center">
                          <span>No product found</span>
                        </div>
                      )
                  )} */}
                  </div>
                </DataTable>
              </div>
            </div>
          </Card>
        </Block>
      </Content>
      <Modal isOpen={opennewsToken} toggle={toggleModalListChannel} class="modal-dialog">
        <ModalHeader>
          <h4 className="nk-modal-title">Làm mới token</h4>
        </ModalHeader>
        <ModalBody >
          <div className={`nk-modal`}>
            <div className='text-center'>
              <p className={style.Modalp}>Hãy đảm bảo trình duyệt của bạn đang đăng nhập tài khoản dưới đây để cấp lại token</p>

              <Img className={`${style1.avatar} ${style1.middleImage} mb-1`} src={bigSquaredFacebook} alt="channel" />
              <p className={style1.NameAvatar}>Khánh Ngọc</p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className='d-flex'>
            <p className={style1.ModalSupport}><a href='#'>Hướng dẫn sửa sự cố token</a> của tài khoản liên kết</p>
            <Button type="button" className={`${style1.buttonCancel} mr-1 text-white`} onClick={() => { setOpennewsToken(false) }}>
              Hủy
            </Button>
            <Button type="submit" className={`${style1.buttonContinue} text-white`}>
              Tiếp tục
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}
