import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { getDomainForCookie } from '../../../utils/Utils';
const MessSucces = ({ message, loginData, registerData }) => {
    const { t } = useTranslation()

    const setDataRegister = async (data) => {
        localStorage.setItem("accessToken", registerData.data.access_token);
        localStorage.setItem("is_new_user", registerData.data.is_new_user);
        localStorage.setItem("userInfor", JSON.stringify(registerData.data));
        const domain = getDomainForCookie();
        Cookies.set('accessToken', registerData.data.access_token, { domain, expires: 1 })
        Cookies.set('userInfor', JSON.stringify(registerData.data), { domain, expires: 365 })
    }

    const setDataLogin = async (data) => {
        localStorage.setItem('userInfor', JSON.stringify(data));
        localStorage.setItem('accessToken', data.access_token);
    }


    return (
        <React.Fragment>
            <div className="p-lg-5 p-4 text-center">
                <div className="avatar-lg mx-auto mt-2" style={{ width: "6rem", height: "6rem" }}>
                    <div className="w-100 h-100 avatar-title bg-light text-success display-3 rounded-circle d-flex justify-content-center align-items-center ">
                        <em class="icon ni ni-check-circle-fill" style={{ fontSize: "50px" }}></em>
                    </div>
                </div>
                <div className="mt-4 pt-2">
                    <h4>{t("new_auth.well_done")}</h4>
                    <p className="text-muted mx-4">{message}</p>
                    <div className="mt-4" onClick={() => {
                        if (loginData) {
                            setDataLogin(loginData);
                        } else if (registerData) {
                            setDataRegister(registerData)
                        }
                    }}>
                        <p className="btn btn-success w-100  d-flex justify-content-center align-items-center"
                            onClick={() => {
                                console.log("check chanle link");
                                if (registerData) {
                                    window.location.replace("/news?isRegister=true")
                                } else if (loginData) {
                                    window.location.replace("/news?isChanglePass=true")
                                }
                                else { window.location.replace("/news") }
                            }}
                        >{t("new_auth.btn_goto_app")}</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default MessSucces;