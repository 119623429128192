import ICON_1 from "../images/post/status/icon/icon_1.svg";
import ICON_2 from "../images/post/status/icon/icon_2.svg";
import ICON_3 from "../images/post/status/icon/icon_3.svg";
import ICON_4 from "../images/post/status/icon/icon_4.svg";
import ICON_5 from "../images/post/status/icon/icon_5.svg";
import ICON_6 from "../images/post/status/icon/icon_6.svg";
import ICON_7 from "../images/post/status/icon/icon_7.svg";
import ICON_8 from "../images/post/status/icon/icon_8.svg";
import ICON_9 from "../images/post/status/icon/icon_9.svg";
import ICON_10 from "../images/post/status/icon/icon_10.svg";
import ICON_11 from "../images/post/status/icon/icon_11.svg";
import ICON_12 from "../images/post/status/icon/icon_12.svg";
import ICON_13 from "../images/post/status/icon/icon_13.svg";
import ICON_14 from "../images/post/status/icon/icon_14.svg";
import ICON_15 from "../images/post/status/icon/icon_15.svg";
import ICON_16 from "../images/post/status/icon/icon_16.svg";
import ICON_17 from "../images/post/status/icon/icon_17.svg";
import ICON_18 from "../images/post/status/icon/icon_18.svg";
import ICON_19 from "../images/post/status/icon/icon_19.svg";
import ICON_20 from "../images/post/status/icon/icon_20.svg";
import ICON_21 from "../images/post/status/icon/icon_21.svg";
import ICON_22 from "../images/post/status/icon/icon_22.svg";
import ICON_23 from "../images/post/status/icon/icon_23.svg";
import ICON_24 from "../images/post/status/icon/icon_24.svg";
import ICON_25 from "../images/post/status/icon/icon_25.svg";
import ICON_26 from "../images/post/status/icon/icon_26.svg";
import ICON_27 from "../images/post/status/icon/icon_27.svg";
import ICON_28 from "../images/post/status/icon/icon_28.svg";
import ICON_29 from "../images/post/status/icon/icon_29.svg";
import ICON_30 from "../images/post/status/icon/icon_30.svg";
import ICON_31 from "../images/post/status/icon/icon_31.svg";
import ICON_32 from "../images/post/status/icon/icon_32.svg";
import ICON_33 from "../images/post/status/icon/icon_33.svg";
import ICON_34 from "../images/post/status/icon/icon_34.svg";
import ICON_35 from "../images/post/status/icon/icon_35.svg";
import ICON_36 from "../images/post/status/icon/icon_36.svg";
import ICON_37 from "../images/post/status/icon/icon_37.svg";
import ICON_38 from "../images/post/status/icon/icon_38.svg";
import ICON_39 from "../images/post/status/icon/icon_39.svg";
import ICON_40 from "../images/post/status/icon/icon_40.svg";
import ICON_41 from "../images/post/status/icon/icon_41.svg";
import ICON_42 from "../images/post/status/icon/icon_42.svg";

import BACKGROUND_1 from "../images/post/status/background/background_1.jpg";
import BACKGROUND_2 from "../images/post/status/background/background_2.jpg";
import BACKGROUND_3 from "../images/post/status/background/background_3.jpg";
import BACKGROUND_4 from "../images/post/status/background/background_4.jpg";
import BACKGROUND_5 from "../images/post/status/background/background_5.jpg";
import BACKGROUND_6 from "../images/post/status/background/background_6.jpg";
import BACKGROUND_7 from "../images/post/status/background/background_7.jpg";
import BACKGROUND_8 from "../images/post/status/background/background_8.jpg";
import BACKGROUND_9 from "../images/post/status/background/background_9.jpg";
import BACKGROUND_10 from "../images/post/status/background/background_10.jpg";
import BACKGROUND_11 from "../images/post/status/background/background_11.jpg";
import BACKGROUND_12 from "../images/post/status/background/background_12.jpg";
import BACKGROUND_13 from "../images/post/status/background/background_13.jpg";
import BACKGROUND_14 from "../images/post/status/background/background_14.jpg";
import BACKGROUND_15 from "../images/post/status/background/background_15.jpg";
import BACKGROUND_16 from "../images/post/status/background/background_16.jpg";
import BACKGROUND_17 from "../images/post/status/background/background_17.jpg";
import BACKGROUND_18 from "../images/post/status/background/background_18.jpg";
import BACKGROUND_19 from "../images/post/status/background/background_19.jpg";
import BACKGROUND_20 from "../images/post/status/background/background_20.jpg";
import BACKGROUND_21 from "../images/post/status/background/background_21.jpg";
import BACKGROUND_22 from "../images/post/status/background/background_22.jpg";
import BACKGROUND_23 from "../images/post/status/background/background_23.jpg";
import BACKGROUND_24 from "../images/post/status/background/background_24.jpg";
import BACKGROUND_25 from "../images/post/status/background/background_25.jpg";
import BACKGROUND_26 from "../images/post/status/background/background_26.jpg";
import BACKGROUND_27 from "../images/post/status/background/background_27.jpg";
import BACKGROUND_28 from "../images/post/status/background/background_28.jpg";
import BACKGROUND_29 from "../images/post/status/background/background_29.jpg";
import BACKGROUND_30 from "../images/post/status/background/background_30.jpg";
import BACKGROUND_31 from "../images/post/status/background/background_31.jpg";
import BACKGROUND_32 from "../images/post/status/background/background_32.jpg";
import BACKGROUND_33 from "../images/post/status/background/background_33.jpg";
import BACKGROUND_34 from "../images/post/status/background/background_34.jpg";
import BACKGROUND_35 from "../images/post/status/background/background_35.jpg";
import BACKGROUND_36 from "../images/post/status/background/background_36.jpg";
import BACKGROUND_37 from "../images/post/status/background/background_37.jpg";
import BACKGROUND_38 from "../images/post/status/background/background_38.jpg";
import BACKGROUND_39 from "../images/post/status/background/background_39.jpg";
import BACKGROUND_40 from "../images/post/status/background/background_40.jpg";
import BACKGROUND_41 from "../images/post/status/background/background_41.jpg";
import BACKGROUND_42 from "../images/post/status/background/background_42.jpg";

export const statusFacebookBackground = [
  {
    id: "248623902401250",
    icon: ICON_1,
    background: BACKGROUND_1,
    color: "#495057",
  },
  {
    id: "2193627793985415",
    icon: ICON_2,
    background: BACKGROUND_2,
    color: "#495057",
  },
  {
    id: "1868855943417360",
    icon: ICON_3,
    background: BACKGROUND_3,
    color: "#495057",
  },
  {
    id: "191761991491375",
    icon: ICON_4,
    background: BACKGROUND_4,
    color: "#495057",
  },
  {
    id: "200521337465306",
    icon: ICON_5,
    background: BACKGROUND_5,
    color: "#495057",
  },
  {
    id: "618093735238824",
    icon: ICON_6,
    background: BACKGROUND_6,
    color: "#FFFFFF",
  },
  {
    id: "206513879997925",
    icon: ICON_7,
    background: BACKGROUND_7,
    color: "#495057",
  },
  {
    id: "197865920864520",
    icon: ICON_8,
    background: BACKGROUND_8,
    color: "#495057",
  },
  {
    id: "338976169966519",
    icon: ICON_9,
    background: BACKGROUND_9,
    color: "#495057",
  },
  {
    id: "203233500491412",
    icon: ICON_10,
    background: BACKGROUND_10,
    color: "#FFFFFF",
  },
  {
    id: "1731809666854300",
    icon: ICON_11,
    background: BACKGROUND_11,
    color: "#495057",
  },
  {
    id: "1787111484677952",
    icon: ICON_12,
    background: BACKGROUND_12,
    color: "#FFFFFF",
  },
  {
    id: "1821844087883360",
    icon: ICON_13,
    background: BACKGROUND_13,
    color: "#495057",
  },
  {
    id: "1591191980917023",
    icon: ICON_14,
    background: BACKGROUND_14,
    color: "#495057",
  },
  {
    id: "1547619665336597",
    icon: ICON_15,
    background: BACKGROUND_15,
    color: "#495057",
  },
  {
    id: "158866551466946",
    icon: ICON_16,
    background: BACKGROUND_16,
    color: "#495057",
  },
  {
    id: "198600220781867",
    icon: ICON_17,
    background: BACKGROUND_17,
    color: "#FFFFFF",
  },
  {
    id: "762009070855346",
    icon: ICON_18,
    background: BACKGROUND_18,
    color: "#495057",
  },
  {
    id: "1942494936063467",
    icon: ICON_19,
    background: BACKGROUND_19,
    color: "#FFFFFF",
  },
  {
    id: "821827291359870",
    icon: ICON_20,
    background: BACKGROUND_20,
    color: "#495057",
  },
  {
    id: "459210954494131",
    icon: ICON_21,
    background: BACKGROUND_21,
    color: "#495057",
  },
  {
    id: "1192421050883530",
    icon: ICON_22,
    background: BACKGROUND_22,
    color: "#FFFFFF",
  },
  {
    id: "1097326783944263",
    icon: ICON_23,
    background: BACKGROUND_23,
    color: "#495057",
  },
  {
    id: "241539443758219",
    icon: ICON_24,
    background: BACKGROUND_24,
    color: "#495057",
  },
  {
    id: "1903718606535395",
    icon: ICON_25,
    background: BACKGROUND_25,
    color: "#FFFFFF",
  },
  {
    id: "2046306532386635",
    icon: ICON_26,
    background: BACKGROUND_26,
    color: "#495057",
  },
  {
    id: "168024350558664",
    icon: ICON_27,
    background: BACKGROUND_27,
    color: "#495057",
  },
  {
    id: "1871233843174039",
    icon: ICON_28,
    background: BACKGROUND_28,
    color: "#495057",
  },
  {
    id: "301029513638534",
    icon: ICON_29,
    background: BACKGROUND_29,
    color: "#FFFFFF",
  },
  {
    id: "145893972683590",
    icon: ICON_30,
    background: BACKGROUND_30,
    color: "#FFFFFF",
  },
  {
    id: "1211819908949985",
    icon: ICON_31,
    background: BACKGROUND_31,
    color: "#FFFFFF",
  },
  {
    id: "1903207336376192",
    icon: ICON_32,
    background: BACKGROUND_32,
    color: "#FFFFFF",
  },
  {
    id: "528808880819990",
    icon: ICON_33,
    background: BACKGROUND_33,
    color: "#FFFFFF",
  },
  {
    id: "2441724612753694",
    icon: ICON_34,
    background: BACKGROUND_34,
    color: "#495057",
  },
  {
    id: "476759302845916",
    icon: ICON_35,
    background: BACKGROUND_35,
    color: "#FFFFFF",
  },
  {
    id: "323371698179784",
    icon: ICON_36,
    background: BACKGROUND_36,
    color: "#FFFFFF",
  },
  {
    id: "541056033292169",
    icon: ICON_37,
    background: BACKGROUND_37,
    color: "#FFFFFF",
  },
  {
    id: "143093446467972",
    icon: ICON_38,
    background: BACKGROUND_38,
    color: "#FFFFFF",
  },
  {
    id: "146487026137131",
    icon: ICON_39,
    background: BACKGROUND_39,
    color: "#FFFFFF",
  },
  {
    id: "217321755510854",
    icon: ICON_40,
    background: BACKGROUND_40,
    color: "#495057",
  },
  {
    id: "161409924510923",
    icon: ICON_41,
    background: BACKGROUND_41,
    color: "#FFFFFF",
  },
  {
    id: "1974886472751579",
    icon: ICON_42,
    background: BACKGROUND_42,
    color: "#FFFFFF",
  },
];
