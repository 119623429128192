import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Controller, get, set, useForm } from "react-hook-form";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import Select from "react-select"
import Dropzone from 'react-dropzone';
import * as Yup from "yup";
import { uploadImage } from "../../../api/createPost";
import UserProfileIris from "./UserProfileIris";
import { postIrisRegister, getListBank, getIrisUser, updateIrisUser } from "../../../api/iris";
import bg1 from "../../../images/iris/user/background.png";
import cardIcon from "../../../images/iris/user/card.svg";
import paymentIcon from "../../../images/iris/user/card_payment.svg";
import qr from "../../../images/iris/user/qr.svg";
import IconCheck from "../../../images/iris/user/check.png";
import IconLink from "../../../images/iris/user/link_more.svg";
import IconCopy from "../../../images/iris/user/copy.svg";
import IconAnalystic from "../../../images/iris/user/analystic.svg";
import iconDelete from "../../../So9/images/icons/icon_delete.svg"

import Head from "../../../layout/head/Head";
import styles from "./index.module.scss";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../components/Component";
import {
  Button,
  ButtonGroup,
  Form,
  FormGroup,
  FormText,
  UncontrolledTooltip,
  Tooltip,
} from "reactstrap";

const initForm = {
  email: "",
  phoneNumber: "",
  bankName: "",
  bankNo: "",
  bankAccount: "",
  checkPolicy: false,
};

const UserProfileIrisDetail = ({ sm, updateSm }) => {
  const [loading, setLoading] = useState(false);
  const [isDisable, setDisable] = useState(true);
  const userInfor = JSON.parse(localStorage.getItem('userInfor'));
  const [link, setLink] = useState("https://so9.vn/iris/xYSadsD");
  const [formData, setFormData] = useState(initForm);
  const [defaultData, setDefaultData] = useState();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [listBank, setListBank] = useState([]);
  const [imageUrl, setImageUrl] = useState();
  const [selected, setSelected] = useState();
  const [policy, setPolicy] = useState(false);
  const [isRegis, setIsRegis] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();


  const [selectedFile, setSelectedFile] = useState(null);

  const handleDrop = (acceptedFiles) => {

    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      uploadFileImage(file);
    }
  };
  const handleClear = (event) => {
    event.stopPropagation();
    setSelectedFile(null);
    setImageUrl(null);
    setDisable(true);
  };

  const uploadFileImage = async (fileMedia) => {
    setImageLoading(true);
    const data = new FormData();
    data.append("image", fileMedia);
    data.append("projectId", userInfor.last_project_active);
    data.append("folder", "affiliate");
    uploadImage(data).then((res) => {
      if (res.status) {
        setImageUrl(res?.data?.url);
        setSelectedFile(fileMedia);
        setDisable(false);
        setImageLoading(false);
      } else {
        console.log("fail");
      }
    }
    ).catch((err) => {
      setImageLoading(false);
      toast.error(`Có lỗi xảy ra. Vui lòng thử lại!`, {
        autoClose: 2000,
        position: "top-right",
      });
    }
    )

  }

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();


  const getData = async () => {
    setUserLoading(true);
    const bankPromise = getListBank();
    const userPromise = getIrisUser();
    Promise.allSettled([bankPromise, userPromise])
      .then(results => {
        results.forEach((result, index) => {
          if (result.status === 'fulfilled') {
            if (typeof result.value.data === "object" && !Array.isArray(result.value.data)) {
              const data = {
                email: result.value.data.email,
                phone: result.value.data.phone,
                bank_name: result.value.data.bank_name,
                bank_account: result.value.data.bank_account,
                bank_no: result.value.data.bank_no,
                qr_code_payment: result.value.data.qr_code_payment,
              }
              setDefaultData(data);
              setIsRegis(true);
              setUserLoading(false);
            } else if (Array.isArray(result.value.data)) {
              const formattedData = result.value.data.map(bank => ({
                value: bank.brand_name,
                label: `${bank.name} ${bank.brand_name}`
              }));
              setListBank(formattedData);
              setUserLoading(false);
            }

          } else if (result.status === 'rejected') {
            console.log(`API ${index + 1} bị từ chối:`, result.reason.message);
          }
        });
      });
  }
  useEffect(() => {
    getData();
  }, [])

  // useEffect(() => {
  //   const data = formData;
  //   delete data.checkPolicy;
  //   const areAllFieldsEmpty = Object.values(data).every(value => value === '');
  //   if (areAllFieldsEmpty && !selectedFile) {

  //     setDisable(true);
  //   } else {
  //     setDisable(false);
  //   }
  // }, [formData])
  const updateUser = async (params) => {
    setLoading(true);
    // setFormData(initForm);
    // setLoading(false);
    // setSelectedFile(null);
    // setImageUrl(null);
    updateIrisUser(params).then((res) => {
      if (res.status) {
        console.log(res);
        toast.success("Cập nhật thông tin thành công!", {
          autoClose: 1000,
          position: "top-right",
        });
        setFormData(initForm);
        setLoading(false);
        setSelectedFile(null);
        setImageUrl(null);
        setSelected(null);
        getData();
        setDisable(true);
        setPolicy(false);
      } else {
        toast.error(`Cập nhật thất bại! ${res?.message}`, {
          autoClose: 3000,
          position: "top-right",
        });
        setLoading(false);
      }
    }
    ).catch((err) => {
      if (err.response.status === 422) {
        toast.error(`${err?.response?.data?.message}`, {
          autoClose: 3000,
          position: "top-right",
        });
      } else {
        toast.error(`Có lỗi xảy ra!`, {
          autoClose: 3000,
          position: "top-right",
        });
      }
      setLoading(false);
    })
  }
  const onSubmit = (data) => {
    if (isDisable) {
      setDisable(false);
    } else if (!policy) {
      setErrorMessage("Bạn cần đồng ý!");
      const delay = setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return;
    } else {
      if (
        formData.email === "" &&
        formData.phoneNumber === "" &&
        formData.bankName === "" &&
        formData.bankNo === "" &&
        !imageUrl &&
        formData.bankAccount === "" 
        // !policy
      ) {
        setLoading(true);
        const delay = setTimeout(() => {
          setLoading(false);
        }, 1000);
        toast.warn(`Bạn cần điền thông tin cần cập nhật`, {
          autoClose: 1000,
          position: "top-right",
        });
        return;
      } else if (imageUrl) {
        const data = {
          email: formData.email,
          phone: formData.phoneNumber,
          bank_name: formData.bankName,
          bank_account: formData.bankAccount,
          bank_no: formData.bankNo,
          qr_code_payment: imageUrl,
        }
        const params = {};

        for (const key in data) {
          if (data[key] !== null && data[key] !== "") {
            params[key] = data[key];
          }
        }
        updateUser(params);
      } else if (!imageUrl) {
        const data = {
          email: formData.email,
          phone: formData.phoneNumber,
          bank_name: formData.bankName,
          bank_account: formData.bankAccount,
          bank_no: formData.bankNo,
        }
        const params = {};

        for (const key in data) {
          if (data[key] !== null && data[key] !== "") {
            params[key] = data[key];
          }
        }

        updateUser(params);
      }

    }




  };

  const renderPreviewImage = () => {
    return (
      !imageLoading ? (<div className="d-flex justify-content-center align-items-center"
        style={{ position: "relative" }}>
        <img
          src={URL.createObjectURL(selectedFile)}
          alt="Selected"
          style={{ width: '200px', maxHeight: "300px" }}
        />
        <img src={iconDelete}
          className={`${styles['custom-item__button--delete']}`}
          onClick={handleClear} alt="delete"></img>
      </div>) : (<Spinner size="32px" color="light" style={{ position: "absolute", top: "30%", left: "47%" }} />)
    );
  }
  const renderProfile = () => {
    return (
      <>
        <div className={styles['header-area']} >
          <div className={styles['header-container']} style={{ padding: "20px" }}>
            <img src={cardIcon}
              style={{
                width: "16px",
                height: "16px",
                objectFit: "scale-down"
              }} alt="notification" />
            <div className={styles["header-container__textfield"]} >
              <p style={{ fontWeight: "600", fontSize: "16px", lineHeight: "24px", marginBottom: "6px" }}>
                Thông tin thanh toán của bạn</p>
              <p style={{ fontWeight: "400", fontSize: "14px", lineHeight: "20px", marginBottom: "0px" }}>
                Khoản tiền hoa hồng được thanh toán tới bạn vào ngày 09 tháng kế tiếp.
                Bạn sẽ nhận được 10% cho tất cả các đơn hàng đã được khách hàng thanh toán thành công thỏa mãn điều kiện:
              </p>
              <ul style={{ listStyleType: "disc" }}>
                <li style={{ marginLeft: "20px" }}>
                  Đơn hàng đã được thành công và kích hoạt sử dụng trong tháng
                </li>
                <li style={{ marginLeft: "20px" }}>
                  Đơn hàng được phát sinh trong 90 ngày kể từ khi dự án bạn giới thiệu được tạo
                </li>
              </ul>


            </div>
            <div className={styles['header-container__card-payment']}>
              <div className={`${styles['header-container__card-payment__header']}`} >
                <p style={{ marginTop: "15px", color: "#121212", fontWeight: "500", fontSize: "15px" }}>
                  {defaultData?.bank_name}
                </p>
                <img style={{ height: "16px", width: "16px" }} src={paymentIcon} />
              </div>
              <div className={`${styles['header-container__card-payment__content']}`}>
                <p style={{
                  color: "#C7C7C7", fontSize: "14px", fontWeight: "500",
                  marginBottom: "3px", marginTop: "12px", lineHeight: "16px"
                }}>
                  {defaultData?.bank_no}
                </p>
                <p style={{
                  color: "#9D9D9D", fontSize: "10px",
                  fontWeight: "500", lineHeight: "13px"
                }}>
                  {defaultData?.bank_account}
                </p>
              </div>
            </div>
          </div>
        </div>

        <Block className={"px-4"}>
          <div className="nk-data data-list">

            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className={`${styles['custom-item']} data-item px-0`}>
                <div className="data-col">
                  <span className={styles['data-label']}>
                    Số điện thoại liên hệ
                    <span style={{ color: "#E11D48" }}>*</span>
                  </span>

                  <FormGroup className={styles['form-group']}>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field: { onBlur, onChange } }) => (
                        <input
                          className="form-control px-[16px]"
                          disabled={isDisable}
                          type="number"
                          name="phoneNumber"
                          value={formData.phoneNumber}
                          placeholder={defaultData?.phone}
                          onChange={(e) => {
                            setDisable(false);
                            setValue("phoneNumber", e.target.value);
                            setFormData({
                              ...formData,
                              phoneNumber: e.target.value,
                            });
                          }}
                        />
                      )}
                    />
                    {errors.phoneNumber && (
                      <FormText color="danger">
                        {" "}
                        {errors[`phoneNumber`]?.message}
                      </FormText>
                    )}
                  </FormGroup>

                </div>
                {/* <div className="data-col data-col-end"> */}
                {/* <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span> */}
                {/* </div> */}
              </div>
              <div className={`${styles['custom-item']} data-item px-0`}>
                <div className="data-col">
                  <span className={styles['data-label']}>
                    Email liên hệ<span style={{ color: "#E11D48" }}>*</span>
                  </span>

                  <FormGroup className={styles['form-group']}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field: { onBlur, onChange } }) => (
                        <input
                          className="form-control px-[16px]"
                          disabled={isDisable}
                          type="email"
                          name="email"
                          value={formData.email}
                          placeholder={defaultData?.email}
                          onChange={(e) => {
                            setDisable(false);
                            setValue("email", e.target.value);
                            setFormData({
                              ...formData,
                              email: e.target.value,
                            });
                          }}
                        />
                      )}
                    />
                    {errors.email && (
                      <FormText color="danger">
                        {" "}
                        {errors[`email`]?.message}
                      </FormText>
                    )}
                  </FormGroup>

                </div>
                {/* <div className="data-col data-col-end"> */}
                {/* <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span> */}
                {/* </div> */}
              </div>
              <div className={`${styles['custom-item']} data-item px-0`}>
                <div className="data-col">
                  <span className={styles['data-label']}>
                    Ngân hàng của bạn<span style={{ color: "#E11D48" }}>*</span>
                  </span>

                  <FormGroup className={styles['form-group']}>
                    <Controller
                      name="bankName"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field: { onBlur, onChange } }) => (
                        <Select
                          classNamePrefix="react-select"
                          isDisabled={isDisable}
                          styles={{
                            placeholder: (baseStyles) => ({
                              ...baseStyles,
                              opacity: "0.5",
                              color: "#3c4d62 !important",
                            }),
                          }}
                          name="bankName"
                          placeholder={defaultData?.bank_name}
                          options={listBank}
                          value={selected}
                          onChange={(selectedOption) => {
                            setDisable(false);
                            setValue("bankName", selectedOption?.value);
                            setSelected(selectedOption);
                            setFormData({
                              ...formData,
                              bankName: selectedOption?.value,
                            });
                          }}
                        />
                      )}
                    />
                    {errors.bankName && (
                      <FormText color="danger">
                        {errors[`bankName`]?.message}
                      </FormText>
                    )}
                  </FormGroup>

                </div>
                {/* <div className="data-col data-col-end"> */}
                {/* <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span> */}
                {/* </div> */}
              </div>
              <div className={`${styles['custom-item']} data-item px-0`}>
                <div className="data-col">
                  <span className={styles['data-label']}>
                    Số tài khoản<span style={{ color: "#E11D48" }}>*</span>
                  </span>

                  <FormGroup className={styles['form-group']}>
                    <Controller
                      name="bankNo"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field: { onBlur, onChange } }) => (
                        <input
                          className="form-control px-[16px]"
                          type="number"
                          name="bankNo"
                          disabled={isDisable}
                          placeholder={defaultData?.bank_no}
                          value={formData.bankNo}
                          onChange={(e) => {
                            setDisable(false);
                            setValue("bankNo", e.target.value);
                            setFormData({
                              ...formData,
                              bankNo: e.target.value,
                            });
                          }}
                        />
                      )}
                    />
                    {errors.bankNo && (
                      <FormText color="danger">
                        {" "}
                        {errors[`bankNo`]?.message}
                      </FormText>
                    )}
                  </FormGroup>

                </div>
                {/* <div className="data-col data-col-end"> */}
                {/* <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span> */}
                {/* </div> */}
              </div>
              <div className={`${styles['custom-item']} data-item px-0`}>
                <div className="data-col">
                  <span className={styles['data-label']}>
                    Tên tài khoản<span style={{ color: "#E11D48" }}>*</span>
                  </span>

                  <FormGroup className={styles['form-group']}>
                    <Controller
                      name="bankAccount"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field: { onBlur, onChange } }) => (
                        <input
                          className="form-control px-[16px]"
                          type="text"
                          disabled={isDisable}
                          name="bankAccount"
                          placeholder={defaultData?.bank_account}
                          value={formData.bankAccount}
                          onChange={(e) => {
                            setDisable(false);
                            setValue("bankAccount", e.target.value);
                            setFormData({
                              ...formData,
                              bankAccount: e.target.value,
                            });
                          }}
                        />
                      )}
                    />
                    {errors.bankAccount && (
                      <FormText color="danger">
                        {" "}
                        {errors[`bankAccount`]?.message}
                      </FormText>
                    )}
                  </FormGroup>

                </div>
                {/* <div className="data-col data-col-end"> */}
                {/* <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span> */}
                {/* </div> */}
              </div>
              <div className={`${styles['custom-item']} data-item px-0`}>
                <div className="data-col">
                  <span className={styles['data-label']}>QR tài khoản ngân hàng</span>
                  <Dropzone onDrop={handleDrop} accept="image/*" disabled={isDisable} multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {selectedFile ? (
                          renderPreviewImage()
                        ) : (
                          <div className="d-flex justify-content-center align-items-center" style={{ width: "135px", height: "40px", borderRadius: "4px", backgroundColor: "#F3F4F6", color: "#2F80ED" }}>
                            <img src={qr} style={{ marginRight: "8px" }} />   Tải ảnh QR
                          </div>
                        )}
                      </div>
                    )}
                  </Dropzone>
                </div>
                {/* <div className="data-col data-col-end"> */}
                {/* <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span> */}
                {/* </div> */}
              </div>

              <div className={styles['verify-container']}>
                <div className="custom-control custom-control-sm custom-checkbox mt-2 ">
                  <input
                    type="checkbox"
                    className="custom-control-input form-control"
                    id="policy"
                    // checked={formData.checkPolicy}
                    checked={policy}
                    onChange={(e) => {
                      setPolicy(!policy);
                      setFormData({
                        ...formData,
                        checkPolicy: e.target.checked,
                      })
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="policy"
                    style={{ color: "#111827" }}
                  >
                    Tôi cam kết rằng thông tin được gửi ở trên là đúng sự thật!
                  </label>
                </div>
                <p className="text-danger"
                  style={{ fontSize: "12px", marginBottom: "0px", marginLeft: "28px" }}>{errorMessage}</p>
                <div className="mt-5">
                  {!isDisable ?
                    <button
                      type="submit"
                      className={`${styles['button--submit']}`}
                      disabled={loading}
                    >
                      {isDisable ? "Cập nhật" : "Lưu"}   {loading && <Spinner size="sm" color="light" />}
                    </button>
                    :
                    <button
                      type="submit"
                      className={`${styles['button--disable']}`}
                    // disabled={true}
                    >
                      {isDisable ? "Cập nhật" : "Lưu"}  {loading && <Spinner size="sm" color="light" />}
                    </button>
                  }


                </div>

              </div>


            </Form>
          </div>
        </Block>
      </>
    );
  }
  return (
    <React.Fragment>
      <Head title="Iris - Profile"></Head>
      {isRegis ? renderProfile() :
        (
          !userLoading ?
            // <div className="d-flex justify-content-center align-items-center"
            //   style={{ flexDirection: "column" }}>
            //   <span>--- Bạn chưa tham gia chương trình SO9 Iris. Vui lòng hoàn thành
            //     <Link to={`${process.env.PUBLIC_URL}/user-profile-iris`}
            //     > Đăng ký </Link> để có thể sử dụng dịch vụ này ---</span>

            // </div> 
            (<UserProfileIris
              listBank={listBank}
              setLogin={setIsRegis}
              setListBank={getData}
            />)
            : (<Spinner size="32px" color="light" style={{ position: "absolute", top: "30%", left: "60%" }} />)
        )}


    </React.Fragment>
  );
};

export default UserProfileIrisDetail;
