import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { IconPost } from "../../../libs/icon";
import Timeline from 'react-calendar-timeline'
import SimpleBar from "simplebar-react";
import IconAutoPost from "../../images/icons/icon_auto.svg";
import {
    TimelineHeaders,
    SidebarHeader,
    DateHeader,
    TimelineMarkers,
    CustomMarker,
    CursorMarker,
} from 'react-calendar-timeline'
import Head from "../../../layout/head/Head";
import { getChannels, getDetailNotificationStatus, getListUserSeeding, reupPost } from "../../../api/createPost";
import { getListChannel } from "../../../api/calender";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { setCollapseSidebarLeft } from "../../reducers/layoutSlice";
import { components } from 'react-select';
import style from "./index.module.scss";
import {
    getPlatformIcon,
} from "../../utils/Utils";
import {
    setOptionChannels,
    setChannels
} from "../../reducers/createPostSlice";
import { DropdownItem, Dropdown, DropdownMenu, DropdownToggle, Container, Spinner, UncontrolledTooltip, Modal, ModalBody } from "reactstrap";
import {
    Block,
    RSelect,
    Icon,
    Button,
    // PaginationComponent,
    PaginationCustom,
    Img
} from "../../../components/Component";
import { MEDIA_TYPE_VIDEO } from '../../utils/Utils';
import { POST_STATUS_PUBLISH_ERROR, STATUS_COMPRESS_ERROR, POST_STATUS_PUBLISH_SUCCESS } from '../../constants/posts';
import { AsyncPaginate } from "react-select-async-paginate";
// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'
import MediaErrorIcon from "../../images/icons/media_error.jpg";
import { getListGroupChannels } from '../../../api/channel';
import { toastError, toastSuccess } from "../../../libs/toasts";
import { debounce } from 'lodash';
import PostDetail from '../PostDetail';
import { getPostOrignWithPostPlatform } from '../../../api/postDetail';
export default function CalendaVer2() {
    const history = useHistory();
    const pageNum = parseInt((new URLSearchParams(window.location.search)).get("page")) || 1;
    const search = ((new URLSearchParams(window.location.search)).getAll("channel_ids[]")) || [];
    const type = ((new URLSearchParams(window.location.search)).get("post_type")) || "";
    const [ListUser, setListUser] = useState([])
    const [loadSeeding, setLoadSeeding] = useState(false);
    const [channelSelect, setChannelSelect] = useState([])
    const [currentPage, setCurrentPage] = useState(pageNum);
    const [totalItems, setTotalItems] = useState(0)
    const [collapseSidebarContent, setCollapseSidebarContent] = useState(false);
    const [openModalViewStatus, setOpenModalViewStatus] = useState(false);
    const [openModalDeletePost, setOpenModalDeletePost] = useState(false);
    const [channelPostDetail, setChannelPostDetail] = useState();
    const [collapseSidebar, setCollapseSidebar] = useState(false);
    const [listProdRSelected, setListProdRSelected] = useState([]);
    const [listProduct, setListProduct] = useState([]);
    const [Allchannels, setAllChannel] = useState([]);
    const { i18n, t } = useTranslation();
    const [showTopRightOption, setShowTopRightOption] = useState(false);
    const today = Date.now();
    const userInfor = JSON.parse(localStorage.getItem('userInfor'));
    const dispatch = useDispatch();
    const optionChannels = useSelector((state) => state.createPost.optionChannels);
    const optionChannelsEnd = useRef(false);
    const projectId = userInfor.last_project_active;

    const [items, setItems] = useState([]);
    const [TypePost, setTypePost] = useState({ key: type, name: "Tất cả" });
    const [groups, setGroups] = useState([]);
    const simpleBarRef = useRef();
    const [isLoading, setLoading] = useState(false);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [modalReloadSM, setModalReloadSM] = useState(false);
    const [time, setTime] = useState({
        start: parseInt(moment().add(-12, 'hour').format("x")),
        end: parseInt(moment().add(12, 'hour').format("x"))
    });
    const [rangeTime, setRangeTime] = useState({
        start: parseInt(moment().add(-6, 'day').format("x")),
        end: parseInt(moment().add(14, 'day').format("x"))
    });
    const [openModalPostDetail, setOpenModalPostDetail] = useState(false);
    const [detailPost, setDetailPost] = useState(null);
    const [listPostByFetch, setListPostByFetch] = useState([]);

    const [PreTime, setPreTime] = useState(rangeTime);
    const twoMonthsAgo = parseInt(moment().subtract(2, 'months').format("x"));
    const sixMonthsLater = parseInt(moment().add(6, 'months').format("x"));

    const handleOpenModalPostDetail = ({ item }) => {
        setOpenModalPostDetail(!openModalPostDetail);
        setDetailPost(item);
    };

    const [sidebarContentProps, setSidebarContentProps] = useState({
        postId: null,
        platform_id: null,
        //facebookPostId: null,
        platform: null,
        channelId: null,
    });
    const paginate = (pageNumber) => {
        const FilterChannels = [];
        channelSelect.map(item => {
            FilterChannels.push(item._id);
        });
        ListPostCalendar(pageNumber, FilterChannels);
    };
    let timeBar = 0;
    const MultiValueLabel = (props) => {
        const { data } = props,
            imgStyle = {
                flex: "0 0 14px",
                width: "14px",
                height: "14px",
                objectFit: "cover",
            },
            spanStyle = { paddingLeft: "7px" };
        return (
            <components.MultiValueLabel {...props}>
                <Img src={getPlatformIcon(data.platform)} alt={`entity-${data.platform}`} style={imgStyle} />
                <span style={spanStyle}>{data.name}</span>
            </components.MultiValueLabel>
        );
    };
    const GroupHeading = (props) => {
        const { data } = props;
        return <components.GroupHeading {...props}>{t(data.label)}</components.GroupHeading>;
    };
    const handleReupPost = async (post) => {
        if (window.confirm("Bạn có chắc chắn muốn đăng lại bài này không?")) {
            const data = {
                socialPostId: post?._id,
                channelType: post?.channel?.platform
            }
            try {
                const res = await reupPost(data)
                if (res.status) {
                    toastSuccess("Đăng lại bài thành công")
                    ListPostCalendar();
                }
            } catch (e) {
                toastError("Đã xảy ra lỗi khi đăng lại bài. Vui lòng thử lại sau ít phút")
            }
        }
    }
    const hideCollapse = () => {
        setCollapseSidebar(false);
        if (collapseSidebarContent) {
            setCollapseSidebarContent(false);
            setSidebarContentProps({
                postId: null,
                platform_id: null,
                platform: null,
                channelId: null,
            });
        }
        document.body.classList.remove("toggle-shown");
    };

    const onChangeChannels = (e) => {
        setChannelSelect(e);
    };

    const selectPlatformStyle = {
        multiValue: (base) => ({
            ...base,
            border: "1px solid #6A2C62",
            borderRadius: "100px !important",
            padding: "4px 11px",
            backgroundColor: "#f2f2f2 !important",
        }),
        multiValueLabel: (base) => ({
            ...base,
            display: "flex",
            alignItems: "center",
            padding: "0",
            paddingLeft: "0",
            fontSize: "12px",
            lineHeight: "1",
        }),
        multiValueRemove: (base) => ({
            ...base,
            marginLeft: "7px",
            padding: "0",
            lineHeight: "1",
        }),
    };
    let params = useQuery();
    useEffect(() => {
        ListPostCalendar(currentPage, search);
        const userInfo = JSON.parse(localStorage.getItem("userInfor"));
        const postData = JSON.parse(localStorage.getItem("postData"));
        if (userInfo.last_project_active && !optionChannelsEnd.current) {
            const projectId = userInfo.last_project_active;
            const data = {};
            const fetchData = async () => {
                return await getChannels(projectId, data);
            };
            fetchData()
                .then((response) => {
                    if (response.status) {
                        const { channel: channels, favorite_channel: _favoriteChannels } = response.data;
                        const channelSelectCurrent = channels.filter((c) => search.includes(c._id));
                        setAllChannel(channels);
                        setChannelSelect(channelSelectCurrent)
                        const favoriteChannelIds = _favoriteChannels.map((c) => c._id);
                        const favoriteChannels = channels.filter((c) => favoriteChannelIds.includes(c._id));
                        const uniqueChannelPlatforms = [
                            ...new Set(channels.filter((c) => !favoriteChannelIds.includes(c._id)).map((c) => c.platform)),
                        ];

                        const _optionChannels = [
                            {
                                label: "create_post.channel_favorite",
                                options: favoriteChannels,
                            },
                            ...uniqueChannelPlatforms.map((channelPlatform) => ({
                                label: `create_post.channel_${channelPlatform}`,
                                options: channels.filter((c) => !favoriteChannelIds.includes(c._id) && c.platform === channelPlatform),
                            })),
                        ];
                        dispatch(setOptionChannels(_optionChannels));
                        if (postData) {
                            const postChannel = channels.filter((c) => postData.channelId.includes(c._id));
                            dispatch(setChannels(postChannel));
                        } else {
                            const presetChannelIds = params.getAll("channels");
                            if (presetChannelIds.length > 0) {
                                const _channels = channels.filter((channel) => presetChannelIds.includes(channel._id));
                                dispatch(setChannels(_channels));
                            }
                        }
                        optionChannelsEnd.current = true;
                    }
                })
                .catch((error) => console.error(error));
        }
    }, [projectId]);
    const ListPostCalendar = (numberPage = 1, channel = []) => {
        setItems([]);
        setLoading(true);
        let Groups = [];
        let Items = [];
        const data = {
            params: {
                channel_ids: channel,
                post_type: TypePost.key,
                page: numberPage,
                startDate: formatTimestamp(rangeTime.start),
                endDate: formatTimestamp(rangeTime.end),
            }
        }
        dispatch(setCollapseSidebarLeft(true));
        getListChannel(projectId, data)
            .then((response) => {
                if (response.status) {
                    const result = response.data
                    setLoading(false)
                    setItemPerPage(result.per_page)
                    setCurrentPage(result.current_page)
                    let url = `${window.location.pathname}?page=${result.current_page}`
                    channel.map((e) => {
                        url = url + `&channel_ids[]=${e}`
                    })
                    history.push(url + `&post_type=${TypePost.key}`);
                    setTotalItems(result.total)

                    result.data?.map((channel, index) => {

                        Groups.push({
                            id: channel.id,
                            title: channel.name,
                            platform: channel.platform,
                            picture: channel.picture,
                            //rightTitle:`${index<5?`Trang ${index+1}`:""}`
                        })
                        channel.posts?.map((post) => {
                            if (post.status !== 4 && post.status !== 5)
                                post.start_time = post.start_time * 1000;
                            post.end_time = post.end_time * 1000;
                            Items.push(post);
                        })
                    })
                    setGroups(Groups);
                    setItems(Items);
                }
            })
            .catch((error) => console.error(error));
        //setLoading(false)
    };

    const FilterChannel = () => {
        const FilterChannels = [];
        channelSelect.map(item => {
            FilterChannels.push(item._id);
        });
        ListPostCalendar(1, FilterChannels);
    }
    const loadGroupChannels = async (search, prevOptions, { page }) => {
        const data = {
            params: {
                page: page,
                v: 2,
            }
        }
        const groupChannelResponse = await getListGroupChannels(projectId, data);
        if (groupChannelResponse.status) {
            const result = groupChannelResponse.data;
            let productLst = listProduct;
            productLst = productLst.concat(result.data);
            //let productLst_N = {...productLst, number_item: 1};
            setListProduct(productLst);
            const listProductOptions = result.data?.map(e => {
                return {
                    value: e._id,
                    label: e.name
                }
            })
            const hasMore = result.data?.length > 0;
            return {
                options: listProductOptions,
                hasMore,
                additional: {
                    page: page + 1,
                },
            };
        }

    }
    const handleItemMove = (itemId, dragTime, newGroupOrder) => {
        const group = groups[newGroupOrder];

        var newItems = items.map(item =>
            item.id === itemId
                ? Object.assign({}, item, {
                    start: dragTime,
                    end: dragTime + (item.end - item.start),
                    group: group.id
                })
                : item
        )
        setItems([...newItems])
    };
    const groupRenderer = ({ group, isRightSidebar }) => {
        return (
            <div id={"id" + group.id.replace(/[^0-9]/g,)} className={`${(group.title.length > 20) ? style.line : ""} custom-group ${style.group}`}>
                <div style={{ width: "2rem", height: "2rem", position: "relative", marginRight: ".5rem", borderRadius: "5px", marginTop: "4px" }}>
                    <Img style={{ width: "2rem", height: "2rem", position: "absolute" }} src={group?.picture} alt="" />
                    <img alt="" src={getPlatformIcon(group?.platform)} style={{ width: "1rem", height: "1rem", position: "absolute", bottom: "0", right: "0" }} />
                    <span className="title ml-10">{group?.title.slice(0, 20)}{group.title.length > 20 && "..."}</span>
                    <UncontrolledTooltip
                        autohide={false} placement={"top"} target={"id" + group.id.replace(/[^0-9]/g,)}
                    >
                        {group.title}
                    </UncontrolledTooltip>
                </div>
            </div>)
    }
    const checkMediaStatus = (post) => {
        const mediaErrorFlag = post && post.media && post.media.type === MEDIA_TYPE_VIDEO && post.media.media_is_available === false && post.media.status_compress === STATUS_COMPRESS_ERROR;
        return mediaErrorFlag;
    }
    const itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
        const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
        const backgroundColor = itemContext.selected ? (itemContext.dragging ? "red" : item.selectedBgColor) : item.bgColor;
        const borderColor = itemContext.resizing ? "red" : item.color;
        const mediaErrorFlag = checkMediaStatus(item) && item.status !== POST_STATUS_PUBLISH_SUCCESS;
        return (
            <div
                {...getItemProps({
                    style: {
                        backgroundColor,
                        color: item.color,
                        borderColor,
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderRadius: 4,
                        borderLeftWidth: itemContext.selected ? 3 : 1,
                        borderRightWidth: itemContext.selected ? 3 : 1,
                        paddingLeft: 3
                    },
                    onMouseDown: () => {
                        handleViewPost(item)
                    }
                })}
                className="d-flex"
            >
                {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
                <div style={{ marginRight: "2px", margintop: "-2px" }}>
                    {
                        mediaErrorFlag ? <Img src={MediaErrorIcon} style={{ width: "12px", height: "12px" }} alt="error image" /> :
                            <IconPost status={item.status} />
                    }
                </div>
                <div
                    style={{
                        height: itemContext.dimensions.height,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "80%",
                    }}
                >
                    {itemContext.title}
                </div>
                {item.isAutoScheduledAt && <>
                    <Img id={"id" + item.id.replace(/[^0-9]/g,)} src={IconAutoPost} alt='' style={{ width: "16px", height: "16px", marginTop: "-5px", position: "absolute", right: -5 }} />
                    <UncontrolledTooltip
                        autohide={false} placement={"top"} target={"id" + item.id.replace(/[^0-9]/g,)}
                    >
                        Bài đăng này được hẹn giờ bằng Lịch tự động
                    </UncontrolledTooltip>
                </>}


                {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
            </div>
        );
    };

    const handleItemResize = (itemId, time, edge) => {
        var newItems = items.map(item =>
            item.id === itemId
                ? Object.assign({}, item, {
                    start_time: edge === "left" ? time : item.start_time,
                    end_time: edge === "left" ? item.end_time : time
                })
                : item
        )
        setItems([...newItems]);
    };
    const stylesProds = {
        control: base => ({
            ...base,
            fontSize: "12px",
            display: "flex"
        }),
        menu: base => ({
            ...base,
            fontSize: "12px",

        })
    };

    const Control = ({ children, ...props }) => {
        const styleControl = { cursor: 'pointer', marginLeft: "10px" };
        return (
            <components.Control {...props}>
                <Icon name="search" style={styleControl}></Icon>
                {children}
            </components.Control>
        );
    };
    const handleViewStatusPost = (post, platform) => {
        const { id, platform_id } = post;
        getDetailNotificationStatus(projectId, id, platform_id).then(res => {
            const postItem = { attachment_post: { id: post.id }, channel: { platform: platform } };
            setChannelPostDetail(res.status ? (res.data || postItem) : {});
            setOpenModalViewStatus(true);
        }).catch(err => {
            const postItem = { attachment_post: { id: post.id }, channel: { platform: platform } };
            setChannelPostDetail({ attachment_post: { id: post.id }, channel: { platform: platform } });
            setOpenModalViewStatus(true);
            console.log(err);
        });
    }
    const handleDeleteMultiPost = () => {
        setOpenModalDeletePost(true);
    }
    const handleOpenPostId = (item) => {
        let postId;
        switch (item?.channel?.platform) {
            case 1:
                postId = item.facebook_post_id
                window.open(`https://www.facebook.com/${postId}`, "_blank");
                break;
            case 2:
                postId = item.facebook_post_id
                break;
            case 3:
                postId = item.facebook_post_id
                break;
            case 5:
                postId = item.youtube_video_id
                window.open(`https://www.youtube.com/watch?v=${postId}`, "_blank");
                break;
            default:
        }
    }

    const getListUser = (postCalendar) => {

        setLoadSeeding(true);
        const data = {
            channel_id: postCalendar.group
        };
        const fetchData = async () => {
            return await getListUserSeeding(projectId, data);
        };
        fetchData().then((response) => {
            setListUser(response.data)
            setLoadSeeding(false)
        })


    }

    const toggleReloadSM = () => {
        setModalReloadSM(!modalReloadSM)
    }

    const handleViewPost = (post) => {

        const item = groups.find(item => item?.id === post?.group);
        if (post.status === POST_STATUS_PUBLISH_ERROR) {
            handleViewStatusPost(post, item.platform);
        } else {
            getListUser(post)
            // const initChannel = {
            //     postId: post.id,
            //     platform_id: post.platform_id,
            //     platform: item.platform,
            //     channelId: post.group,
            // }
            // setSidebarContentProps(initChannel);
            // setCollapseSidebarContent(true);
            // setCollapseSidebar(true);
            getPostOrignWithPostPlatform(post.project_id, post.post_id).then((res) => {
                console.log("===", res.data);
                res.data && setDetailPost(res.data);
                setOpenModalPostDetail(!openModalPostDetail)
            }).catch((err) => {
                console.log("fetch post origin error", err);
                setOpenModalPostDetail(!openModalPostDetail)
            })

        }
    }

    const cacuDate = ({ startDate, endDate }) => {
        const date1 = moment(startDate);
        const date2 = moment(endDate);
        const daysDiff = Math.ceil(date2.diff(date1, 'days', true));
        return daysDiff;
    }

    const onMouseUpCalenda = () => {
        if (time.start < rangeTime.start) {
            debounceSetRange({ start: time.start, end: rangeTime.end });
        }
        if (time.end > rangeTime.end) {
            debounceSetRange({ start: rangeTime.start, end: time.end });
        }

    }

    const debounceSetRange = debounce((value) => {
        setRangeTime(value);
    }, 2000);

    const handleChangeTime = (visibleTimeStart, visibleTimeEnd) => {
        if (visibleTimeStart >= twoMonthsAgo && visibleTimeEnd <= sixMonthsLater) {
            setTime({ start: visibleTimeStart, end: visibleTimeEnd })
        }
    };

    const handleBoundChange = () => {
        if (time.start < rangeTime.start) {
            debounceSetRange({ start: time.start, end: rangeTime.end });
        }
        if (time.end > rangeTime.end) {
            debounceSetRange({ start: rangeTime.start, end: time.end });
        }
    }

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }

    const getMoreItems = async (numberPage, channel) => {
        const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
        const diffStart = cacuDate({ startDate: rangeTime.start, endDate: PreTime.start });
        const diffEnd = cacuDate({ startDate: PreTime.end, endDate: rangeTime.end });
        if (diffStart >= 1) {
            let promisArr = [];
            let Items = [];
            let data = {};
            for (let index = 0; index < Math.ceil(diffStart / 20); index++) {
                data = {
                    params: {
                        channel_ids: channel,
                        post_type: TypePost.key,
                        page: numberPage,
                        startDate: formatTimestamp(rangeTime.start + index * (20 * oneDayInMilliseconds)),
                        endDate: (rangeTime.start + (index + 1) * (20 * oneDayInMilliseconds)) < PreTime.start
                            ? formatTimestamp(rangeTime.start + (index + 1) * (20 * oneDayInMilliseconds))
                            : formatTimestamp(PreTime.start),
                    }
                }
                const elePromiss = getListChannel(projectId, data);
                promisArr.push(elePromiss);

            }

            if (promisArr.length > 0) {
                Promise.allSettled(promisArr).then((results) => {
                    results.forEach((result) => {
                        if (result.status === "fulfilled") {
                            result?.value?.data?.data?.map((channel, index) => {
                                channel.posts?.map((post) => {
                                    if (post.status !== 4 && post.status !== 5)
                                        post.start_time = post.start_time * 1000;
                                    post.end_time = post.end_time * 1000;
                                    Items.push(post);
                                })
                            })
                        }
                    }
                    )
                    setItems([...items, ...Items]);
                    Items = [];
                    setPreTime(rangeTime);
                }
                ).catch((error) => {
                    console.log("Loadmore", error);
                    setPreTime(rangeTime);
                });
            }
            promisArr = [];
        }
        if (diffEnd >= 1) {
            let promisArr = [];
            let Items = [];
            let data = {};
            for (let index = 0; index < Math.ceil(diffEnd / 20); index++) {
                data = {
                    params: {
                        channel_ids: channel,
                        post_type: TypePost.key,
                        page: numberPage,
                        startDate: formatTimestamp(PreTime.end + index * (20 * oneDayInMilliseconds)),
                        endDate: (PreTime.end + (index + 1) * (20 * oneDayInMilliseconds)) < rangeTime.end
                            ? formatTimestamp(PreTime.end + (index + 1) * (20 * oneDayInMilliseconds))
                            : formatTimestamp(rangeTime.end),
                    }
                }
                const elePromiss = getListChannel(projectId, data);
                promisArr.push(elePromiss);
            }

            if (promisArr.length > 0) {
                Promise.allSettled(promisArr).then((results) => {
                    results.forEach((result) => {
                        if (result.status === "fulfilled") {
                            result?.value?.data?.data?.map((channel, index) => {
                                channel.posts?.map((post) => {
                                    if (post.status !== 4 && post.status !== 5)
                                        post.start_time = post.start_time * 1000;
                                    post.end_time = post.end_time * 1000;
                                    Items.push(post);
                                })
                            })
                        }
                    }
                    )
                    setItems([...items, ...Items]);
                    setPreTime(rangeTime);
                    Items = [];
                }
                ).catch((error) => {
                    console.log("Loadmore", error);
                    setPreTime(rangeTime);
                });
            }
            promisArr = [];
        }

    }
    useEffect(() => {
        getMoreItems(pageNum, []);
    }, [rangeTime])
    return (
        <React.Fragment>
            <Head title={t("calendar.title")}></Head>
            <Block className="nk-block">
                <Container fluid>
                    <div style={{ height: "69px" }}></div>
                    <div className="d-flex justify-between w-full">
                        <div className="d-flex w-4/5">
                            <div className={`form-control-wrap ${style.Head__typechannel}`}>
                                <RSelect
                                    closeMenuOnSelect={false}
                                    components={{
                                        MultiValueLabel,
                                        GroupHeading,
                                    }}
                                    placeholder={t("calendar.filter.select_channel")}
                                    value={channelSelect}
                                    isMulti
                                    getOptionLabel={(v) => v.name}
                                    getOptionValue={(v) => v._id}
                                    onChange={(e) => {
                                        //onChange(e);
                                        onChangeChannels(e);
                                    }}
                                    options={optionChannels}
                                    styles={selectPlatformStyle}
                                />
                            </div>
                            <div className={`form-control-wrap ${style.Head__typepost}`}>
                                <ul>
                                    <li>

                                        <AsyncPaginate
                                            defaultOptions
                                            value={listProdRSelected}
                                            loadOptions={loadGroupChannels}
                                            isMulti
                                            placeholder={t("calendar.filter.select_group_channel")}
                                            closeMenuOnSelect={true}
                                            additional={{
                                                page: 1,
                                            }}
                                            styles={stylesProds}
                                            components={{ Control }}
                                            onChange={(e) => {
                                                let channelSelect = [];
                                                let channelSelectUnique = [];
                                                setListProdRSelected(e);
                                                e.map(item => {
                                                    const SelectedItem = listProduct.find(tg => tg._id === item.value);
                                                    let channelSelectCurrent = SelectedItem.channel_ids;
                                                    channelSelect = channelSelect.concat(channelSelectCurrent);
                                                    //let channelSelectCurrent = Allchannels.filter((c) => search.includes(c._id));
                                                });
                                                channelSelect.map(e => {
                                                    if (!channelSelectUnique.includes(e))
                                                        channelSelectUnique.push(e)
                                                })
                                                let channelSelectCurrent = Allchannels.filter((c) => channelSelectUnique.includes(c._id));
                                                setChannelSelect(channelSelectCurrent)

                                            }}
                                        />
                                    </li>
                                </ul>

                            </div>
                            <Button className={style.button} onClick={() => FilterChannel()}>
                                {isLoading && <Spinner size="sm"></Spinner>}
                                <Icon name="filter"></Icon>
                            </Button>
                        </div>
                        <div className={`form-control-wrap ${style.Head__option}`}>
                            <Dropdown isOpen={showTopRightOption} toggle={() => setShowTopRightOption(!showTopRightOption)}>
                                <DropdownToggle
                                    tag="span"
                                    onClick={() => setShowTopRightOption(!showTopRightOption)}
                                    data-toggle="dropdown"
                                    aria-expanded={showTopRightOption}
                                >
                                    <div className={style.Head__divOption}><div className='m-auto'><Icon style={{ marginRight: "5px" }} name="edit-alt-fill" role="button"></Icon>{t("calendar.option.title")}</div></div>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem onClick={() => handleDeleteMultiPost()}>
                                        <Icon name="trash"></Icon> <span className={style.Head__textOption}>{t("calendar.option.delete_post_by_channel")}</span>
                                    </DropdownItem>
                                    <DropdownItem onClick={() => toggleReloadSM()}>
                                        <Icon name="box"></Icon> <span className={style.Head__textOption}>{t("calendar.option.re_run_auto_calendar")}</span>
                                    </DropdownItem>

                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="d-flex justify-start mb-2">
                        <div className='d-flex justify-start mr-4'>
                            <div className={style.Head__common}></div>
                            <span className={style.title}>{t("calendar.type_post.text_post")}</span>
                        </div>
                        <div className='d-flex justify-start mr-4'>
                            <div className={style.Head__Image}></div>
                            <span className={style.title}> {t("calendar.type_post.image_post")}</span>
                        </div>
                        <div className='d-flex justify-start mr-4'>
                            <div className={style.Head__video}></div>
                            <span className={style.title}>{t("calendar.type_post.normal_video_post")}</span>
                        </div>
                        <div className='d-flex justify-start mr-4'>
                            <div className={style.Head__reels}></div>
                            <span className={style.title}> {t("calendar.type_post.short_form_video_post")}</span>
                        </div>
                    </div>
                    {groups && groups.length > 0 &&
                        <div style={{ position: "sticky", zIndex: "0" }} onMouseUp={onMouseUpCalenda}
                        >
                            <Timeline
                                groups={groups}
                                items={items}
                                itemsSorted
                                itemTouchSendsClick={false}
                                stackItems
                                itemHeightRatio={0.75}
                                showCursorLine
                                canMove={false}
                                canResize={false}
                                defaultTimeStart={moment().add(-12, 'hour')}
                                defaultTimeEnd={moment().add(12, 'hour')}
                                itemRenderer={itemRenderer}
                                onItemMove={handleItemMove}
                                onItemResize={handleItemResize}
                                groupRenderer={groupRenderer}
                                sidebarWidth={200}
                                lineHeight={40}
                                visibleTimeStart={time.start}
                                visibleTimeEnd={time.end}
                                onTimeChange={handleChangeTime}
                                onBoundsChange={handleBoundChange}
                            // onZoom={handleZoom}
                            >

                                <TimelineHeaders

                                    className="sticky"
                                >
                                    {/* right sidebar */}
                                    <SidebarHeader variant="left" headerData={{ someData: 'extra' }}>
                                        {({ getRootProps, data }) => {
                                            return <div
                                                //  {...getRootProps()}
                                                style={{ backgroundColor: '#6F94CD', width: 200, padding: 'auto' }}
                                            > </div>
                                        }}
                                    </SidebarHeader>

                                    <DateHeader unit="primaryHeader" style={{ backgroundColor: '#6F94CD', fontWeight: 'bold', border: 'none', minHeight: '8vh', textTransform: 'capitalize' }} />
                                    <DateHeader style={{ color: '#8AA228', fontWeight: 'bold', minHeight: '7vh', textTransform: 'capitalize' }} />
                                    <CursorMarker style={{ backgroundColor: '#6F94CD' }} />
                                </TimelineHeaders>
                                <TimelineMarkers>
                                    <CustomMarker date={today} />
                                    <CustomMarker date={today}>
                                        {(ele) => {
                                            timeBar = ele.styles.left;
                                            const customStyles = {
                                                ...ele.styles,
                                                backgroundColor: "#6F94CD",
                                                width: "2px",
                                                zIndex: "999",
                                            };
                                            return (
                                                <div style={customStyles}>
                                                    <div
                                                        style={{
                                                            background: "#fff",
                                                            color: "#000",
                                                            width: 75,
                                                            height: 30,
                                                            fontSize: 10,
                                                            borderRadius: 5,
                                                            zIndex: 85,
                                                            border: "1px solid",
                                                            overflow: "hidden",
                                                            textAlign: "center",
                                                            padding: "6px",
                                                            marginLeft: "-36px",
                                                            marginTop: "6px"
                                                        }}
                                                    >
                                                        {
                                                            new Date().toLocaleDateString("en-US", { month: 'short', year: 'numeric', day: 'numeric' })
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        }}
                                    </CustomMarker>
                                </TimelineMarkers>
                            </Timeline>
                        </div>
                    }
                </Container>
            </Block>
            <div className="card-inner">
                <PaginationCustom
                    pageSize={itemPerPage}
                    totalCount={totalItems || 0}
                    siblingCount={1}
                    onPageChange={paginate}
                    currentPage={currentPage}
                    className={`manage_post_pagination`}
                />

            </div>
            <div
                className={`nk-demo-panel nk-demo-panel-2x toggle-slide toggle-slide-right toggle-screen-any p-0 ${style.contentItem
                    } ${collapseSidebarContent ? "content-active" : ""}`}
            >
                <SimpleBar style={{ maxHeight: "100%" }} ref={simpleBarRef}>
                    <SidebarInfoPost
                        handleReupPost={handleReupPost}
                        hideCollapse={hideCollapse}
                        simpleBarRef={simpleBarRef} {...sidebarContentProps}
                        refetch={ListPostCalendar}
                        ListUser={ListUser}
                        loadSeeding={loadSeeding}
                        handleOpenPostId={handleOpenPostId} />
                </SimpleBar>

            </div>
            {collapseSidebar && <div className="toggle-overlay" onClick={() => hideCollapse()}></div>}
            <ModalDeleteMultiPost
                openModal={openModalDeletePost}
                setOpenModal={setOpenModalDeletePost}
                refetch={ListPostCalendar}
            />

            <ModalViewStatusPost
                openModal={openModalViewStatus}
                setOpenModal={setOpenModalViewStatus}
                post={channelPostDetail}
                items={items}
                setItems={setItems}
            />
            <ModalReloadSM modalReloadSM={modalReloadSM} setModalReloadSM={setModalReloadSM}></ModalReloadSM>


            <Modal
                size="xl"
                style={{
                    maxWidth: "1300px",
                    width: "80%",
                }}
                isOpen={openModalPostDetail}
                toggle={handleOpenModalPostDetail}
            >
                <ModalBody style={{ padding: "0px", borderRadius: "8px" }}>
                    <PostDetail
                        closeModal={handleOpenModalPostDetail}
                        dataPostDetail={detailPost}
                        setOpenModalPostDetail={setOpenModalPostDetail}
                        setListPostByFetch={setListPostByFetch}
                        listPostByFetch={listPostByFetch}
                        refetchListPost={() => { }}
                    />
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}
const ModalReloadSM = React.lazy(() => import("../../components/Modal/ModalReloadSM"));
const SidebarInfoPost = React.lazy(() => import("../Channel/SidebarContent"));
const ModalViewStatusPost = React.lazy(() => import("../../components/Post/ModalViewStatusPost"));
const ModalDeleteMultiPost = React.lazy(() => import("../../components/Calender/ModalDeleteMutilPost"))