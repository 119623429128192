import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { uniqBy } from "lodash";
import { getConversation, confirmRead } from "../../api/conversation";
import moment from "moment";
const initialState = {
  conversationList: [],
  conversationPage: 1,
  conversationUnread: 0,
  conversationLoaded: false,
  selectedId: null,
};

export const onReceiveWSChat = createAsyncThunk(
  "conversation/onReceiveWSChat",
  async (newChat, { getState, dispatch }) => {
    const state = getState();
    const isNewChatInAConversationInList = state.conversation.conversationList.some(
      (conversation) => conversation._id === newChat.conversation_id
    );
    if (isNewChatInAConversationInList) {
      dispatch(updateConversationOnNewChat(newChat));
      return;
    }
    const params = { conversationId: newChat.conversation_id, projectId: newChat.project_id };
    dispatch(fetchOneConversation(params));
  }
);

export const fetchOneConversation = createAsyncThunk(
  "conversation/fetchOneConversation",
  async ({ conversationId, projectId }) => {
    const response = await getConversation(conversationId, {
      params: { project_id: projectId },
    });
    return response.data;
  }
);

export const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    setConversationList(state, action) {
      state.conversationList = [...action.payload];
    },
    appendConversationList(state, action) {
      const merged = uniqBy([...state.conversationList, ...action.payload], "_id");
      state.conversationList = merged;
    },
    setSelectedId(state, action) {
      state.selectedId = action.payload;
    },
    incrementConversationPage(state) {
      state.conversationPage = state.conversationPage + 1;
    },
    setConversationUnread(state, action) {
      state.conversationUnread = action.payload;
    },
    setConversationPage(state) {
      state.conversationPage = 1;
    },
    setConversationLoaded(state, action) {
      state.conversationLoaded = action.payload;
    },
    updateConversationOnNewChat(state, action) {
      const newChat = action.payload;
      state.conversationList = state.conversationList.map((conversation) => {
        if (conversation._id === newChat.conversation_id) {
          /** the last chat can be the parent or the last child */
          let actualLastChat = newChat;
          if (newChat?.children?.length > 0) {
            actualLastChat = newChat.children[newChat.children.length - 1];
          }
          const lastChat = {
            id: actualLastChat._id,
            content: actualLastChat.content,
            image: actualLastChat.image,
            from_customer: actualLastChat.from_customer,
            sender: { ...actualLastChat.sender },
          };
          /** update last_chat */
          conversation.last_chat = lastChat;
          /** update last_user_care if chat is NOT from customer */
          if (actualLastChat.from_customer === 0) {
            conversation.last_user_care = { ...actualLastChat.sender };
          }
          /** update number_new_chat if conversation is NOT being selected */
          if (conversation._id !== state.selectedId) {
            conversation.number_new_chat = conversation.number_new_chat + 1;
          }
          /** update is_read if conversation is NOT being read */
          if (actualLastChat.is_read === 0 && conversation._id === state.selectedId) {
            confirmRead(conversation._id, { project_id: conversation.project_id }).then((response) => {
              if (response.status) {
                conversation.is_read = 1;
                state.conversationUnread = state.conversationUnread + conversation.number_new_chat;
              }
            })
              .catch((error) => {
                console.log(error);
              });
          }
          if (actualLastChat.is_read === 0 && conversation._id !== state.selectedId) {
            conversation.is_read = 0;
          }
        }
        return conversation;
      });
    },
    updateConversationOnConfirmRead(state, action) {
      const conversationId = action.payload;
      state.conversationList = state.conversationList.map((conversation) => {
        if (conversation._id === conversationId) {
          /** update is_read */
          conversation.is_read = 1;
          /** update conversation unread */
          state.conversationUnread = state.conversationUnread - conversation.number_new_chat;
          /** update number_new_chat */
          conversation.number_new_chat = 0;
          /** update updated_at */
          conversation.updated_at = new Date().toISOString();
        }
        return conversation;
      });
    },
    updateConversationNote(state, action) {
      console.log(Math.round(moment.now() / 1000))
      const conversationId = action.payload.id;
      state.conversationList = state.conversationList.map((conversation) => {
        if (conversation._id === conversationId) {
          let conversationNote = conversation?.note || []
          conversationNote.unshift({
            note: action.payload.note,
            time: Math.round(moment.now() / 1000)
          })
          conversation.note = conversationNote;
        }
        return conversation;
      });
    },
    updateConversationOnNewComment(state, action) { },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOneConversation.fulfilled, (state, action) => {
      state.conversationList.unshift(action.payload);
    });
  },
});

export const {
  setConversationList,
  appendConversationList,
  setSelectedId,
  incrementConversationPage,
  setConversationUnread,
  setConversationPage,
  setConversationLoaded,
  updateConversationOnNewChat,
  updateConversationOnConfirmRead,
  updateConversationOnNewComment,
  updateConversationNote
} = conversationSlice.actions;
export default conversationSlice.reducer;
