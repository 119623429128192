import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getDashboardChannelReport } from "../../../api/overview";
import { Icon } from "../../../components/Component";
import { DataTableHead, DataTableItem, DataTableRow } from "../../../components/table/DataTable";

const ChannelReport = (props) => {
  const { t } = useTranslation();
  const { range } = props;
  const project = useSelector((state) => state.project.project);
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    console.log(project?._id)
    if (project?._id) {
      const data = {
        params: {
          project_id: project?._id,
          time: `${range} days`,
        },
      };
      getDashboardChannelReport(data).then((response) => {
        if (response.status) {
          setReportData(response.data);
          console.log(response.data)
        }
        else {
          setReportData([]);
        }
      });
    }
    return () => {
      setReportData([]);
    };
  }, [range, project?._id]);

  return (
    <>
      <div className="card-inner mb-n2">
        <div className="card-title-group">
          <div className="card-title card-title-sm">
            <h6 className="title">{t('overview.channel_report.title')}</h6>
          </div>
        </div>
      </div>
      <div className="nk-tb-list is-loose">
        <DataTableHead>
          <DataTableRow className="no-border">
            <span className="tb-lead">{t('overview.channel_report.table_rows.channel')}</span>
          </DataTableRow>
          <DataTableRow className="no-border">
            <span className="tb-lead">{t('overview.channel_report.table_rows.fans')}</span>
          </DataTableRow>
          <DataTableRow className="no-border">
            <span className="tb-lead">{t('overview.channel_report.table_rows.new_posts')}</span>
          </DataTableRow>
          <DataTableRow className="no-border">
            <span className="tb-lead">{t('overview.channel_report.table_rows.impressions')}</span>
          </DataTableRow>
          <DataTableRow className="no-border">
            <span className="tb-lead">{t('overview.channel_report.table_rows.engage_rate')}</span>
          </DataTableRow>
          <DataTableRow className="no-border">
            <span className="tb-lead">{t('overview.channel_report.table_rows.growth')}</span>
          </DataTableRow>
        </DataTableHead>
        {reportData.map((item) => {
          return (
            <DataTableItem key={item.id}>
              <DataTableRow className="no-border">
                <div className="icon-text">
                  <img className="tb-image mr-2" src={item.image} alt="channel" />
                  {/* <Icon className={`text-${item.theme}`} name="globe"></Icon> */}
                  <span className="tb-lead">{item.name}</span>
                </div>
              </DataTableRow>
              <DataTableRow className="no-border">
                <span className="tb-sub tb-amount">
                  <span>{item.report_fans}</span>
                </span>
              </DataTableRow>
              <DataTableRow className="no-border">
                <span className="tb-sub tb-amount">
                  <span>{item.report_new_posts}</span>
                </span>
              </DataTableRow>
              <DataTableRow className="no-border">
                <span className="tb-sub tb-amount">
                  <span>{item.report_impressions}</span>
                </span>
              </DataTableRow>
              <DataTableRow className="no-border">
                <span className="tb-sub tb-amount">
                  <span>{item.report_engage_rate}</span>
                </span>
              </DataTableRow>
              <DataTableRow className="no-border">
                <span className="tb-sub tb-amount">
                  <span>{item.report_growth}%</span>
                  {item.report_growth > 0 && <Icon name="arrow-up" className="text-success" />}
                  {item.report_growth < 0 && <Icon name="arrow-down" className="text-danger" />}
                </span>
              </DataTableRow>
            </DataTableItem>
          );
        })}
      </div>
    </>
  );
};
export default ChannelReport;
