import { API } from "../libs/client";

export const updateProfile = async (data) => {
    const res = await API.put(`/profile`, data);
    return res.data;
}
export const changePassword = async (data) => {
    const res = await API.post(`/profile/change-pass`, data);
    return res.data;
}

export const invite = async (data) => {
    const res = await API.post('/projects/members/invite', data);
    return res.data;
}

export const removeMember = async (data) => {
    const res = await API.delete('/projects/members/removeMember', {
        data: data
    });
    return res.data;
}