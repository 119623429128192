import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Spinner } from "reactstrap";
import style from "../index.module.scss";
import { getListNotify } from "../../../../api/dashboard";
import moment from "moment";
import { shuffleContent } from "../../../../libs/helper";
import { Img, UserAvatar } from "../../../../components/Component";
import { findUpper, validPostTitle } from "../../../utils/Utils";
import ModalViewStatuspost from "../../../components/Post/ModalViewStatusPost";
import { useTranslation } from "react-i18next";
import noContentNotify from "../../../../images/noNotify.png";
import { getDomainApp } from "../../../../utils/Utils";

const Notification = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const projectId = userInfo.last_project_active;
  const { t } = useTranslation();

  const [dataNotify, setDataNotify] = useState([]);
  const [isMore, setIsMore] = useState(false);
  const [openModalViewStatus, setOpenModalViewStatus] = useState(false);
  const [notificationCurrent, setNotificationCurrent] = useState();
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    fetchDataNotify();
  }, [projectId, page]);

  const formatContent = (content) => {
    return shuffleContent(content);
  };

  const NotificationItem = ({ notification, handleRedirect }) => {
    return (
      <div
        className={style.containerItemNotify}
        id={notification?._id}
        style={{ cursor: "pointer" }}
        onClick={() => {
          handleRedirect(notification);
          setNotificationCurrent(notification);
        }}
      >
        {notification?.channel?.picture || notification?.image ? (
          <Img
            src={notification?.channel?.picture || notification?.image}
            className={style.imgPost}
            alt="image"
          />
        ) : notification?.actor ? (
          <UserAvatar
            className="sm"
            text={findUpper(notification?.actor.name)}
          ></UserAvatar>
        ) : (
          <Img src="/" className={style.imgPost} alt="image" />
        )}

        <div className={style.content}>
          <div className={style.text}>
            {validPostTitle(notification?.title)}
          </div>
          <div className={style.description}>{notification?.description}</div>
          <div className={style.time}>
            {moment(notification?.updated_at).format("HH:mm DD-MM-YYYY")}
          </div>
        </div>
      </div>
    );
  };

  const fetchDataNotify = () => {
    getListNotify(projectId, page, {})
      .then((res) => {
        if (res.status) {
          setDataNotify((prev) => [...prev, ...res?.data?.data]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSeeMore = () => {
    setIsMore(!isMore);
  };

  const handleScroll = () => {
    const container = containerRef.current;

    if (
      container.scrollTop + container.clientHeight >=
      container.scrollHeight - 5
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleRedirect = (data) => {
    const domain = getDomainApp();
    if (data?.type === 6) {
      window.open(
        `${domain}/task-management?group_id=${data.attachment_task.group_id}&task_id=${data.attachment_task._id}, "_blank`
      );
    } else {
      setOpenModalViewStatus(!openModalViewStatus);
    }
  };

  return (
    <>
      <div
        className={`${style.StaticStatus}  ${style.card} `}
        ref={containerRef}
        onScroll={handleScroll}
      >
        <div className={style.header}>
          <p>{t("news.Notify")}</p>
        </div>
        <div className={`${style.main} ${style.mainNotify}`}>
          {dataNotify && dataNotify.length > 0 ? (
            dataNotify?.map((notify, _) => (
              <NotificationItem
                notification={notify}
                handleRedirect={handleRedirect}
              />
            ))
          ) : (
            <div className={style.noContent}>
              <Img
                src={noContentNotify}
                alt="no content"
                className={style.imgNoContent}
              />
            </div>
          )}
        </div>
        <ModalViewStatuspost
          openModal={openModalViewStatus}
          setOpenModal={setOpenModalViewStatus}
          post={notificationCurrent}
        />
      </div>
    </>
  );
};

export default Notification;
