import React, { useEffect, useState } from "react";
import ReactSlider from "react-slider";
import { Card, CardBody, CardTitle } from "reactstrap";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryTooltip,
} from "victory";
import style from "../../../index.module.scss";
import "../index.css";

const GoldTime = ({ data }) => {
  const [dataChart, setDataChart] = useState([]);
  const [maxLabel, setMaxLabel] = useState(0);
  const [minLabel, setMinLabel] = useState(0);
  const [sliderValues, setSliderValues] = useState([0, 0]);

  useEffect(() => {
    setDataChart(
      data?.map((item) => ({
        x: item?.x + 1,
        y: item?.y - 7,
        label: item?.label,
      }))
    );
    const labels = data?.map((item) => item.label);
    if (labels) {
      setMinLabel(Math.min(...labels));
      setMaxLabel(Math.max(...labels));
    }
  }, [data]);

  useEffect(() => {
    setSliderValues([minLabel, maxLabel]);
  }, [minLabel, maxLabel]);

  const segmentLength = Math.ceil((maxLabel - minLabel) / 7);

  const handleSliderChange = (values) => {
    setSliderValues(values);
  };

  const filteredData = dataChart?.filter(
    (data) => data.label >= sliderValues[0] && data.label <= sliderValues[1]
  );

  const getColor = (label) => {
    if (label >= 0 && label <= segmentLength) {
      return "#cffafe";
    }
    if (label > segmentLength && label <= segmentLength * 2) {
      return "#a5f3fc";
    }
    if (label > 2 * segmentLength && label <= segmentLength * 3) {
      return "#67e8f9";
    }
    if (label > 3 * segmentLength && label <= segmentLength * 4) {
      return "#22d3ee";
    }
    if (label > 4 * segmentLength && label <= segmentLength * 5) {
      return "#06b6d4";
    }
    if (label > 5 * segmentLength && label <= segmentLength * 6) {
      return "#0891b2";
    }
    if (label > 6 * segmentLength) {
      return "#0e7490";
    }
    // switch ((label / segmentLength).toFixed(0)) {

    //   case 4:
    //     return "#";
    //   case 5:
    //     return "#";
    //   case 6:
    //     return "#";
    //   case 7:
    //     return "#";
    //   default:
    //     return "#ecfeff";
    // }
  };
  return (
    <>
      <Card className="mb-4">
        <CardBody className="card-inner">
          <CardTitle tag="h5" className={`${style.headCard}`}>
            Thời gian những Follower của bạn online nhiều nhất
          </CardTitle>

          <VictoryChart
            domainPadding={27}
            maxDomain={{ y: 0 }}
            width={1350}
            height={350}
            padding={{ bottom: 20, top: 30, left: 30, right: 30}}
          >
            <VictoryAxis
              style={{
                axis: { stroke: "none" },
                tickLabels: { fill: "#6B7280", fontSize: 12, padding: 8 },
              }}
              tickValues={[
                "12am",
                "1am",
                "2am",
                "3am",
                "4am",
                "5am",
                "6am",
                "7am",
                "8am",
                "9am",
                "10am",
                "11am",
                "12pm",
                "1pm",
                "2pm",
                "3pm",
                "4pm",
                "5pm",
                "6pm",
                "7pm",
                "8pm",
                "9pm",
                "10pm",
                "11pm",
              ]}
            />
            <VictoryAxis
              dependentAxis={true}
              tickValues={[0, -1, -2, -3, -4, -5, -6]}
              tickFormat={(tick) => {
                switch (tick) {
                  case 0:
                    return "Sun";
                  case -1:
                    return "Mon";
                  case -2:
                    return "Tue";
                  case -3:
                    return "Wed";
                  case -4:
                    return "Thu";
                  case -5:
                    return "Fri";
                  case -6:
                    return "Sat";
                  default:
                    return tick;
                }
              }}
              style={{
                axis: { stroke: "none" },
                tickLabels: {
                  fill: "#6B7280",
                  fontSize: 12,
                  padding: 8,

                  dominantBaseline: "hanging",
                },
              }}
              tickLabelComponent={<VictoryLabel dy={10} />}
            />

            <VictoryBar
              style={{
                data: {
                  fill: ({ datum }) => getColor(datum.label),
                },
                labels: {
                  fill: "black",
                  fontSize: "12",
                },
              }}
              // labelComponent={<VictoryLabel dy={-22} />}
              labelComponent={
                <VictoryTooltip
                  flyoutStyle={{ display: "none" }}
                  orientation="top"
                  pointerLength={0}
                  style={{ fill: "white", fontSize: "12" }}
                  dy={-6}
                />
              }
              cornerRadius={{ top: 6, bottom: 6 }}
              data={filteredData}
              barWidth={52}
              y0={(d) => d.y + 1}
            />
          </VictoryChart>
          <div className="d-flex flex-row justify-content-center w-100 mb-5 ">
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="example-thumb"
              trackClassName="example-track"
              min={minLabel}
              max={maxLabel}
              value={sliderValues}
              onChange={handleSliderChange}
              step={segmentLength}
              renderThumb={(props, state) => (
                <div {...props}>
                  <span>{state.valueNow}</span>
                </div>
              )}
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default GoldTime;
