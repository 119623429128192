import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Head from "../../../layout/head/Head";
import {
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import {
  Block,
  Icon,
  Row,
  Col,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  RSelect,
  Img
} from "../../../components/Component";
import { Link } from "react-router-dom";
import Select from 'react-select'
import { useForm } from "react-hook-form";
import { bulkActionOptions } from "../../../utils/Utils";
import { updateTag } from '../../../api/tag';
import { getUrlOAuth, getListExportGGs, exportConfigGGs } from '../../../api/integrate';
import { useTranslation } from "react-i18next";
import { updateCustomerTag } from "../../../api/customer";
import { toast } from "react-toastify";
import { ExportGGStatus, optionsDataExport } from './constant';
import ConfigModule from "./ConfigModule";
import EditModule from "./EditModule";
import PRGSmartPass from "../../../assets/images/icon/PRGSmartPass.png";
import GGSheetIcon from "../../../assets/images/icon/ggsheet.png";
import { hashQueryString } from "../../utils/Utils";

const IntegrateList = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  bulkActionOptions[0].label = t('tags.delete');
  bulkActionOptions[1].label = t('integrate.change_status');
  const account_gg_auth = (new URLSearchParams(window.location.search)).get("email") || "";
  const pageNum = parseInt((new URLSearchParams(window.location.search)).get("page")) || 1;
  const [data, setData] = useState([])
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    add: false,
    new: false,
    disconnect: false
  });
  const [dataEdited, setDataEdited] = useState();
  const [editId, setEditedId] = useState();
  const [formData, setFormData] = useState({
    name: "",
    category: 1,
    status: 1,
  });
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(pageNum);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [listExportGGs, setListExportGGs] = useState();
  const optionCustomerEnd = useRef(false);
  const [createAccLink, setCreateAccLink] = useState({
    create: false,
  });
  const [modalConnectSuccess, setModalConnectSuccess] = useState(account_gg_auth && account_gg_auth !== "" ? true : false)
  const toggleModalConnectSuccess = () => setModalConnectSuccess(!modalConnectSuccess);
  const [modalConnectFail, setModalConnectFail] = useState(false)
  const toggleModalConnectFail = () => setModalConnectFail(!modalConnectFail);

  //get Data export
  const getListExportFromGGs = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfor"));
    if (userInfo.last_project_active && !optionCustomerEnd.current) {
      const projectId = userInfo.last_project_active;
      const data = {
        params: {
          project_id: projectId
        },
      };
      getListExportGGs(data).then((resp) => {
        if (resp.status) {
          setListExportGGs(resp.data);
        }
        else if (!resp.status && resp.message === "invalid_grant") {
          onFormConnectHandle();
        }
      });
    }
  }
  useEffect(() => {
    getListExportFromGGs();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to change the selected property of an item
  const onSelectChange = (e, _id) => {
    let newData = data;
    let index = newData.findIndex((item) => item._id === _id);
    newData[index].checked = e.currentTarget.checked;
    setData([...newData]);
  };

  // function to set the action to be taken in table header
  const onActionText = (e) => {
    setActionText(e.value);
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name: ""
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setCreateAccLink({ create: false });
    resetForm();
  };

  // submit function to update a new item
  const onEditSubmit = (submitData) => {
    const { name } = submitData;
    let submittedData;
    let newitems = data;
    newitems.forEach((item) => {
      if (item._id === editId) {
        let submittedData = {
          _id: item._id,
          name: name
        };
        const userInfo = JSON.parse(localStorage.getItem("userInfor"));
        const projectId = userInfo.last_project_active;
        if (item.type == 1) {
          updateCustomerTag(projectId, editId, submittedData).then((resp) => {
            submittedData.type = item.type
            submittedData.color = item.color
            const data = newitems.map(e => (e._id === editId ? Object.assign(e, submittedData) : e))
            setData([...data]);
            resetForm();
            setModal({ edit: false }, { add: false });
          }).catch(e => console.log(e))
        } else {
          updateTag(projectId, editId, submittedData).then((resp) => {
            const data = newitems.map(e => (e._id === editId ? Object.assign(e, submittedData) : e))
            setData([...data]);
            resetForm();
            setModal({ edit: false }, { add: false });
          }).catch(e => console.log(e))
        }
      }
    });
    let index = newitems.findIndex((item) => item.id === editId);
    newitems[index] = submittedData;
    setModal({ edit: false });
  };

  // function which fires on applying selected action
  const onActionClick = (e) => {
    if (actionText === "suspend") {
      let newData = data.map((item) => {
        if (item.checked === true) item.status = "Suspend";
        return item;
      });
      setData([...newData]);
    } else if (actionText === "delete") {
      let newData;
      newData = data.filter((item) => item.checked !== true);
      setData([...newData]);
    }
  };

  const clickCreateAcc = (e) => {
    setCreateAccLink({
      create: true,
    })
  }
  // function which selects all the items
  const selectorCheck = (e) => {
    let newData;
    newData = data.map((item) => {
      item.checked = e.currentTarget.checked;
      return item;
    });
    setData([...newData]);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (account_gg_auth && account_gg_auth !== "") {
      history.push(`${window.location.pathname}?email=${account_gg_auth}&page=${pageNumber}`);
    } else {
      history.push(`${window.location.pathname}?page=${pageNumber}`);
    }
  };

  // handle modal connect
  const onFormConnectHandle = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfor"));
    const projectId = userInfo.last_project_active;
    const dataSubmit = {
      params: {
        project_id: projectId
      },
    };

    getUrlOAuth(dataSubmit).then((resp) => {
      if (resp.status) {
        setCreateAccLink({ create: false });
        // createPopupWindow(resp.data, 650, 550);
        window.location.href = resp.data;
      }
    });
  }
  const listTypeAccount = [
    {
      value: 1,
      text: 'Google Sheet',
      icon: <Img src={GGSheetIcon} className="img-fluid" style={{ width: "12px" }} />
    },
    {
      value: 2,
      text: 'Mail',
      icon: <Icon name="mail"></Icon>
    },
    {
      value: 3,
      text: 'Facebook',
      icon: <Icon name="facebook-f"></Icon>
    }
  ];

  const [selectedOption, setSelectedOption] = useState({
    value: 1,
    text: 'Google Sheet',
    icon: <Img src={GGSheetIcon} className="img-fluid" style={{ width: "12px" }} />
  });

  // handle onChange event of the dropdown
  const handleChange = e => {
    setSelectedOption(e);
  }
  const NewIcon = () => {
    return <div className="form-icon form-icon-right">
      <Icon name="search"></Icon>
    </div>;
  };

  return (
    <React.Fragment>
      <Head title={t('tags.tag_integrated')}></Head>
      <Block>
        <DataTable className="card-stretch">
          <div className="card-inner position-relative card-tools-toggle">
            <div className="card-title-group">
              <div className="card-tools">
                <div className="form-inline flex-nowrap gx-3">
                  <div className="form-wrap">
                    <RSelect
                      options={bulkActionOptions}
                      className="w-130px"
                      placeholder="Chọn thao tác"
                      onChange={(e) => onActionText(e)}
                    />
                  </div>
                  <div className="btn-wrap">
                    <span className="d-none d-md-block">
                      <Button
                        disabled={actionText !== "" ? false : true}
                        color="light"
                        outline
                        className="btn-dim"
                        onClick={(e) => onActionClick(e)}
                      >
                        Áp dụng
                      </Button>
                    </span>
                    <span className="d-md-none">
                      <Button
                        color="light"
                        outline
                        disabled={actionText !== "" ? false : true}
                        className="btn-dim  btn-icon"
                        onClick={(e) => onActionClick(e)}
                      >
                        <Icon name="arrow-right"></Icon>
                      </Button>
                    </span>
                  </div>
                </div>
              </div>
              <div className="card-tools mr-n1">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <li>
                    <Button
                      className="toggle d-none d-md-inline-flex"
                      style={{ color: "#FFFFFF", backgroundColor: "#FD1F6D" }}
                      onClick={() => {
                        clickCreateAcc();
                      }}
                    >
                      <Icon name="plus"></Icon>
                      <span>{t('integrate.add_integrate')}</span>
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
              <div className="card-body">
                <div className="search-content">
                  <Button
                    className="search-back btn-icon toggle-search active"
                    onClick={() => {
                      setSearchText("");
                      toggle();
                    }}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                  <input
                    type="text"
                    className="border-transparent form-focus-none form-control"
                    placeholder="Search by user or email"
                    value={onSearchText}
                    onChange={(e) => onFilterChange(e)}
                  />
                  <Button className="search-submit btn-icon">
                    <Icon name="search"></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <DataTableBody compact>
            <DataTableHead>
              <DataTableRow className="nk-tb-col-check">
                <div className="custom-control custom-control-sm custom-checkbox notext">
                  <input
                    type="checkbox"
                    className="custom-control-input form-control"
                    onChange={(e) => selectorCheck(e)}
                    id="uid"
                  />
                  <label className="custom-control-label" htmlFor="uid"></label>
                </div>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text"> {t('integrate.integrate_name')}</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text"> {t('integrate.type_account')}</span>
              </DataTableRow>
              <DataTableRow size="lg">
                <span className="sub-text"> {t('integrate.object_export')}</span>
              </DataTableRow>
              <DataTableRow size="lg">
                <span className="sub-text"> {t('integrate.sheet_name')}</span>
              </DataTableRow>
              <DataTableRow size="lg">
                <span className="sub-text"> {t('integrate.active')}</span>
              </DataTableRow>
              <DataTableRow size="lg">
                <span className="sub-text"> {t('integrate.action')}</span>
              </DataTableRow>
            </DataTableHead>
            {
              listExportGGs && listExportGGs.length > 0 &&
              listExportGGs.map((item) => {
                return (
                  <DataTableItem key={item._id}>
                    <DataTableRow className="nk-tb-col-check">
                      <div className="custom-control custom-control-sm custom-checkbox notext">
                        <input
                          type="checkbox"
                          className="custom-control-input form-control"
                          // defaultChecked={item.checked}
                          id={item._id}
                          key={Math.random()}
                          onChange={(e) => onSelectChange(e, item._id)}
                        />
                        <label className="custom-control-label" htmlFor={item._id}></label>
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <b>{item.configs.account}</b>
                    </DataTableRow>
                    <DataTableRow>
                      <div>
                        <Img src={GGSheetIcon} className="img-fluid" style={{ width: "12px" }} />
                        <b>Google sheet</b>
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <div>
                        <b>{optionsDataExport.find(opt => opt.value === item.object).label}</b>
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <div>
                        <b>{item.configs.sheet_name}</b>
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <span
                        className={`tb-status text-${item.status && item.status === 1 ? "success" : "danger"}`}
                      >
                        {item.status && item.status === 1 ? "Active" : "Inactive"}
                      </span>
                    </DataTableRow>
                    <DataTableRow>
                      <ul className="list-inline">
                        <li style={{ marginRight: '0px' }}>
                          <Button onClick={() => {
                            setModal({ edit: true });
                            setDataEdited(item);
                          }}>
                            <Icon name="edit"></Icon>
                          </Button>
                        </li>
                        <li>
                          <Button onClick={() => {
                            setModal({ disconnect: true });
                            setDataEdited(item);
                          }}>
                            <Icon name="repeat"></Icon>
                          </Button>
                        </li>
                      </ul>
                    </DataTableRow>
                  </DataTableItem>
                );
              })
            }
          </DataTableBody>
          {
            listExportGGs && listExportGGs.length > 0 &&
            <div className="card-inner">
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={listExportGGs.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          }
          {
            (!listExportGGs || listExportGGs.length === 0) &&
            <div className="text-center" style={{ marginBottom: "10rem", marginTop: "3rem" }}>
              <Img src={PRGSmartPass} />
              <p style={{ fontSize: "24px", lineHeight: "50px" }}>Bạn chưa liên kết với tài khoản nào</p>
              <Button
                className="toggle d-none d-md-inline-flex"
                style={{ color: "#FFFFFF", backgroundColor: "#FD1F6D" }}
                onClick={() => {
                  clickCreateAcc();
                }}
              >
                <Icon name="plus"></Icon>
                <span>{t('integrate.add_integrate')}</span>
              </Button>
            </div>
          }
        </DataTable>
      </Block>
      <Modal isOpen={createAccLink.create} toggle={() => setCreateAccLink({ create: false })} size={'lg'}>
        <ModalHeader toggle={() => setCreateAccLink({ create: false })}>Customer Info</ModalHeader>
        <ModalBody>
          <Row className="gx-4 gy-3">
            <Col size="12">
              <FormGroup>
                <label className="form-label" htmlFor="event-title">
                  Loại tài khoản
                </label>
                <FormGroup>
                  <div className="form-control-wrap">
                    <Select
                      className={`react-select-container ${props.className ? props.className : ""}`}
                      classNamePrefix="react-select"
                      value={selectedOption}
                      options={listTypeAccount}
                      onChange={handleChange}
                      getOptionLabel={e => (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {e.icon}
                          <span style={{ marginLeft: 5 }}>{e.text}</span>
                        </div>
                      )}
                      arrowrenderer={NewIcon}
                    />
                  </div>
                </FormGroup>
                <div
                  className="m-2 d-flex text-center align-items-center justify-content-center"
                  style={{
                    width: "552px",
                    height: "52px",
                  }}
                >
                  <span className="fs-12px text-secondary mb-2" > Vui lòng bấm "Tiếp tục" <br />để đăng nhập vào tài khoản Google </span>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "flex-start" }}>
          <div className="d-flex" style={{ flex: 1 }}>
            <Icon name="question" style={{ lineHeight: "unset" }}></Icon>
            <p className="sub-text">Hướng dẫn liên kết tài khoản <Link to="https://www.google.com.vn/drive/about.html">Google Sheets</Link> </p>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div style={{
              backgroundColor: "#E0E0E0",
              color: "#BDBDBD",
              marginRight: "1rem",
              fontWeight: "500",
              borderRadius: "100px",
              border: "1px solid #E0E0E0",
              padding: "9px 28px",
              cursor: "pointer"
            }} onClick={(ev) => { ev.preventDefault(); onFormCancel(); }}>
              Bỏ qua
            </div>
            <div style={{
              backgroundColor: "#FFFFFF",
              color: "#4285F4",
              fontWeight: "500",
              borderRadius: "100px",
              border: "1px solid #E0E0E0",
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)",
              display: "flex",
              alignItems: "center",
              gap: "6px",
              padding: "9px 12px",
              cursor: "pointer"
            }} onClick={(ev) => { ev.preventDefault(); onFormConnectHandle(); }}>
              <div><Img src={"/images/logo-drive.png"} width={16} alt="" /></div>
              <div style={{ display: "flex", alignItems: "center" }}>
                Kết nối với&nbsp;<b>Google Drive</b>
              </div>
            </div>
          </div>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalConnectSuccess} toggle={toggleModalConnectSuccess}>
        <ModalBody className="modal-body-lg text-center">
          <div className="nk-modal">
            <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-check bg-success"></Icon>
            <h4 className="nk-modal-title">Chúc mừng bạn!</h4>
            <div className="nk-modal-text">
              <div className="caption-text">
                Bạn đã kết nối thành công!
              </div>
            </div>
            <div className="nk-modal-action">
              <Button color="primary" size="lg" className="btn-mw" onClick={() => {
                toggleModalConnectSuccess();
                setModal({ new: true });
              }}>
                OK
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalConnectFail} toggle={toggleModalConnectFail}>
        <ModalBody className="modal-body-lg text-center">
          <div className="nk-modal">
            <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></Icon>
            <h4 className="nk-modal-title">Lỗi không thể tạo tài khoản liên kết!</h4>
            <div className="nk-modal-text">
              <p className="lead">
                Bạn vui lòng thử lại liên kết!
              </p>
              <p className="text-soft">Nếu cần sự trợ giúp xin vui lòng liên hệ đến email so9@gmail.com.</p>
            </div>
            <div className="nk-modal-action mt-5">
              <Button color="light" size="lg" className="btn-mw" onClick={toggleModalConnectFail}>
                Quay lại
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal.disconnect} toggle={() => setModal({ disconnect: false })}>
        <ModalBody className="modal-body-lg text-center">
          <div className="nk-modal">
            <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-question bg-warning"></Icon>
            <h4 className="nk-modal-title">Bạn có chắc chắn muốn {dataEdited && dataEdited.status === ExportGGStatus.ACTIVE && "ngắt "} kết nối không?</h4>
            <div className="nk-modal-action">
              <Button color="light" size="lg" style={{ marginRight: '1rem' }} className="btn-mw" onClick={() => {
                setModal({ disconnect: false });
              }}>
                Hủy
              </Button>
              <Button color="primary" size="lg" className="btn-mw" onClick={() => {
                setModal({ disconnect: false });
                if (dataEdited) {
                  const dataEditedFormat = {
                    ...dataEdited,
                    status: dataEdited.status === ExportGGStatus.ACTIVE ? ExportGGStatus.INACTIVE : ExportGGStatus.ACTIVE
                  }
                  exportConfigGGs(dataEditedFormat).then((resp) => {
                    if (resp.status) {
                      toast.success("Cập nhật trạng thái thành công!", {
                        autoClose: 1000,
                      });
                      getListExportFromGGs();
                    }
                    else if (!resp.status && resp.message === "invalid_grant") {
                      onFormConnectHandle();
                    }
                    else {
                      toast.error("Cập nhật trạng thái thất bại!", {
                        autoClose: 1000,
                      });
                    }
                  }).catch(e => {
                    toast.error("Cập nhật trạng thái thất bại!", {
                      autoClose: 1000,
                    });
                    console.log(e);
                  })
                }
              }}>
                Tiếp tục
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ConfigModule isVisible={modal.new} setVisible={() => setModal({ new: false })} account_gg_auth={account_gg_auth} refreshDataExport={() => getListExportFromGGs()} redirectLogin={() => onFormConnectHandle()} />
      <EditModule isVisible={modal.edit} setVisible={() => setModal({ edit: false })} dataEdited={dataEdited} refreshDataExport={() => getListExportFromGGs()} redirectLogin={() => onFormConnectHandle()} />
    </React.Fragment >
  );
};
export default IntegrateList;
