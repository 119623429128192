import React from "react";
import VietNamFlag from "../../images/flags/vietnam.png";
import EnglishFlag from "../../images/flags/english.png";
import { Row, Col } from "../../components/Component";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Link } from "react-router-dom";
import i18next from "i18next";
import {useTranslation} from "react-i18next"

const AuthFooter = () => {
  const {t} = useTranslation();
  const handleChangeLanguage = (lng) => {
    i18next.changeLanguage(lng);
    window.location.reload();
  };
  const renderLanguageTitle = () => {
    return i18next.language === "vi" ? t('footer_language_title_vie') : t('footer_language_title_eng');
  };

  return (
    <div className="nk-footer nk-auth-footer-full">
      <div className="container wide-lg">
        <Row className="g-3">
          <Col lg={6} className="order-lg-last">
            <ul className="nav nav-sm justify-content-center justify-content-lg-end">
              <li className="nav-item">
                <Link className="nav-link" target="_blank" to={`${process.env.PUBLIC_URL}/auths/terms`}>
                    { i18next.language === "vi" ? t('footer_term') : t('footer_term') + " & " + t('footer_condition') }

                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" target="_blank" to={`${process.env.PUBLIC_URL}/auths/policy`}>
                    {t('footer_privacy')}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" target="_blank" to={`${process.env.PUBLIC_URL}/auths/faq`}>
                    {t('footer_help')}
                </Link>
              </li>
              <li className="nav-item ">
                <UncontrolledDropdown direction="up">
                  <DropdownToggle
                    color="transparent"
                    className="dropdown-toggle dropdown-indicator has-indicator nav-link"
                  >
                    <span>{renderLanguageTitle()}</span>
                  </DropdownToggle>
                  <DropdownMenu right className="dropdown-menu-sm">
                    <ul className="language-list">
                      <li>
                        <DropdownItem
                          onClick={() => handleChangeLanguage("vi")}
                          className="language-item"
                        >
                          <img src={VietNamFlag} alt="" className="language-flag" />
                          <span className="language-name">Việt Nam</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          onClick={() => handleChangeLanguage("en")}
                          className="language-item"
                        >
                          <img src={EnglishFlag} alt="" className="language-flag" />
                          <span className="language-name">English</span>
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          </Col>
          <Col lg="6">
            <div className="nk-block-content text-center text-lg-left">
              <p className="text-soft">&copy; {t('footer_copy_right')}</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default AuthFooter;
