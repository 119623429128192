import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon, UserAvatar } from "../../../../components/Component";
import { findUpper } from "../../../utils/Utils";
import { getRandomTheme } from "./KanbanSectionForm";
import "../styles.css";

const UserSelect = ({
  isOpen,
  setIsOpen,
  direction,
  data,
  defaultValue, // single
  options,
  handleSelect,
  placeholder,
  selectedItems, // multi []
  isMulti,
  hideName,
  disabled,
}) => {
  const { t } = useTranslation();

  const selectRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const handleLeaveOutside = (event) => {
      if (
        isOpen &&
        selectRef.current &&
        !selectRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleLeaveOutside);

    return () => {
      document.removeEventListener("mousedown", handleLeaveOutside);
    };
  }, [isOpen]);

  const toggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const option = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const Single = () => {
    return (
      <div className="user-avatar-group d-flex items-center">
        <UserAvatar
          key={data?.name || data?.label}
          className="xs"
          text={findUpper(data?.name || data?.label)}
          image={data?.avatar}
          // theme={getRandomTheme()}
        ></UserAvatar>
        {!hideName && (
          <span
            className="ml-2"
            style={{ fontWeight: "400", fontSize: "12px" }}
          >
            {data?.name || data?.label}
          </span>
        )}
      </div>
    );
  };

  const Multi = () => {
    return (
      <Icon
        name="plus"
        className={"action-button cursor-pointer"}
        style={{ fontSize: "16px" }}
      />
    );
  };

  return (
    <div
      ref={selectRef}
      className={direction}
      style={{ minWidth: !hideName ? "90px" : "0" }}
    >
      <div
        className="dropdown-toggle drop-item"
        style={{
          paddingRight: !hideName && !isMulti ? "8px" : "0px",
          borderRadius: "100px",
          backgroundColor: "#f4f4f4",
        }}
        id="dropdownMenuButton"
        aria-haspopup="true"
        aria-expanded={isOpen}
        onClick={() => {
          !disabled && toggle();
        }}
      >
        {!isMulti ? <Single /> : <Multi />}
      </div>
      <div
        className={`dropdown-menu ${isOpen ? " show" : ""}`}
        aria-labelledby="dropdownMenuButton"
        style={{ width: "250px", marginTop: "12px" }}
      >
        <div style={{ padding: "16px" }}>
          <input
            type="text"
            className="form-control"
            style={{
              border: "1px solid #ccc",
              borderRadius: "8px",
            }}
            placeholder={placeholder}
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <div style={{ height: "250px", overflowY: "auto" }}>
          {option.length > 0 ? (
            option.map((item) => (
              <div
                className="dropdown-item d-flex items-center"
                style={{
                  cursor: "pointer",
                  justifyContent: "space-between",
                  color:
                    defaultValue === item.value ||
                    selectedItems?.includes(item.value)
                      ? "#6576ff"
                      : "#212529",
                }}
                value={item.value}
                onClick={() => {
                  !disabled && handleSelect(item);
                }}
              >
                <div className="d-flex items-center">
                  <UserAvatar
                    className="xs"
                    image={item.avatar}
                    text={findUpper(item.label)}
                    // theme={getRandomTheme()}
                  ></UserAvatar>
                  <span className="ml-2">{item.label}</span>
                </div>
                {selectedItems?.includes(item.value) && (
                  <Icon
                    name="check"
                    className={"cursor-pointer"}
                    style={{ fontSize: "16px" }}
                  />
                )}
                {!isMulti && defaultValue === item.value && (
                  <Icon
                    name="check"
                    className={"cursor-pointer"}
                    style={{ fontSize: "16px" }}
                  />
                )}
              </div>
            ))
          ) : (
            <div
              className="dropdown-item"
              style={{
                height: "36px",
                padding: "10px",
                textAlign: "center",
              }}
            >
              <span style={{ fontSize: "12px", color: "#90959D" }}>
                {t("task_management.detail.no_matching_result")}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserSelect;
