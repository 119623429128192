import React, { useState } from "react";
import DefaultChannelAvatar from "../../images/avatar/default-channel-avatar.png";
import "./_preview-facebook.scss";
const DefaultPreview = ({ ...props }) => {
  const {className } = props;
  const Frame = {
    height: "24px",
    borderRadius: "8px",
    backgroundColor: "#dee1e1"
  }
  const avatar = {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    backgroundColor: "#dee1e1"
  }
  const image = {
    //width: "300px",
    height: "200px",
    borderRadius: "8px",
    backgroundColor: "#dee1e1"
  }
  return (
    <div className={`previewFacebook ${className || ""}`}>
          
      <div className="header pb-3">
        <div style={avatar}></div>
        <div className="info w-full">
          <div className="name w-1/4 mb-3" style={Frame}></div>
          <div className="time w-full" style={Frame}></div>
          <div className="name w-full" style={Frame}></div>
          <div className="time w-2/3 mb-3" style={Frame}></div>
          <div className="name w-full" style={image}></div>
        </div>
      </div>
      </div>
   
  );
};

export default DefaultPreview;
