import { uniqBy } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
} from "reactstrap";
import { getPostsInChannel } from "../../../api/channel";
import { Icon, Img } from "../../../components/Component";
import {
  getPlatformIcon,
  STATUS_REMOVE
} from "../../utils/Utils";
import style from "./index.module.scss";
import { useVisibilityHook } from "react-observer-api";
import ColumnContent from "./ColumnContent";
import {
  getListUserSeeding,
} from "../../../api/createPost";
const LazyLoadWrapper = ({ children, style, className }) => {
  const { setElement, isVisible } = useVisibilityHook({
    threshold: 0.5,
    rootMargin: "100px",
    always: false,
  });

  return (
    <div ref={setElement} style={style} className={className}>
      {isVisible && <>{children}</>}
    </div>
  );
};

const Column = ({ ...props }) => {
  const { t } = useTranslation();
  const { channel, openSidebarContent, ListUser, setListUser, setLoadSeeding, loadSeeding } = props;

  const projectId = useSelector((state) => state.project.project?._id);
  const postsDeleted = useSelector((state) => state.channel.postsDeleted);
  const [items, setItems] = useState([]);
  //const [ListUser, setListUser] = useState([])

  const page = useRef(1);
  const hasMore = useRef(true);
  const loading = useRef(false);
  const aborter = useRef(null);
  const loaduserSeeding = useRef(true)

  const getListUser = () => {
    if (loaduserSeeding.current) {
      setLoadSeeding(true);
      const data = {
        channel_id: channel._id
      };
      const fetchData = async () => {
        return await getListUserSeeding(projectId, data);
      };
      fetchData().then((response) => {
        setListUser(response.data)
        setLoadSeeding(false)
      })
    }

  }
  useEffect(() => {
    return () => {
      aborter.current?.abort();
    };
  }, []);
  useEffect(() => {
    if (Object.keys(postsDeleted).length > 0) {
      let newData = items;
      let index = newData.findIndex((item) => item._id === postsDeleted._id);
      newData[index].status = STATUS_REMOVE;
      setItems(newData);
    }
  }, [postsDeleted]);
  const loadPostsInChannel = () => {
    if (!aborter.current) {
      aborter.current = new AbortController();
    }
    if (loading.current) return;
    loading.current = true;
    const data = {
      params: {
        page: page.current,
      },
      signal: aborter.current.signal,
    };
    getPostsInChannel(projectId, channel._id, data)
      .then((response) => {
        const { data } = response;
        data && setItems((current) => uniqBy([...current, ...data], "_id"));
        if (data.length === 0) {
          hasMore.current = false;
        }
        page.current++;
      })
      .catch(() => { })
      .finally(() => {
        loading.current = false;
      });
  };

  const reset = () => {
    page.current = 1;
    hasMore.current = true;
    setItems([]);
  };

  const resetItems = () => {
    reset();
  };

  return (
    <LazyLoadWrapper className={style.column} >
      <div className={`d-flex align-items-center p-2 ${style.channelHeader}`}>
        <Img className={style.channelHeaderPlatform} src={getPlatformIcon(channel.platform)} />
        <div className={`mx-1`}>
          <div className={`d-flex align-items-center`}>
            <span className={`fw-medium ${style.channelHeaderInfoName}`}>{channel.name}</span>
            {false && <Icon name="alert-circle-fill text-danger" className="fs-18px" />}
          </div>
          {/* <div>{channel.number_follower}</div> */}
        </div>
        <ButtonGroup size="sm" className="ml-auto">
          <Button color="light" className="p-1">
            <Icon name="reload" onClick={resetItems}></Icon>
          </Button>
        </ButtonGroup>
      </div>
      <div id={`scrollableDiv${channel._id}`} className={style.channelWrapper} style={{ height: "100vh", overflow: "auto", padding: 0 }}>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadPostsInChannel}
          hasMore={hasMore.current}
          scrollableTarget={`scrollableDiv${channel._id}`}
        >
          {items.length > 0 ? (
            <>
              {items.map((item, index) => (
                <>
                  <ColumnContent
                    key={item._id}
                    data={item}
                    channel={channel}
                    isLoadListUser={loaduserSeeding}
                    getListUser={getListUser}
                    ListUser={ListUser}
                    loadSeeding={loadSeeding}
                    clickContent={(params) => {
                      //console.log(openSidebarContent)
                      openSidebarContent?.(params);

                    }}
                    className={index > 0 ? "mt-0" : ""}
                  />

                  <h6 className={style.overline}>
                  </h6>

                </>
              ))}
            </>
          ) : (
            <Card className="card-bordered">
              <CardBody className="p-2 text-center">
                <div>{t("channel_page.text_no_content")}</div>
                <Link
                  to={{
                    pathname: "../posts/create",
                    search: "?channels=1",
                  }}
                >
                  {t("channel_page.text_go_to_create_content_page")}
                </Link>
              </CardBody>
            </Card>
          )}
        </InfiniteScroll>
      </div>

    </LazyLoadWrapper>
  );
};



export default Column;
