import { Card } from "reactstrap";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Fragment } from "react";
import NewsDiscover from "./NewsDiscover";
import TodayPostSchedule from "./TodayPostSchedule";
import ChannelConnect from "./ChannelConnect";
import FeaturedPost from "./FeaturedPost";
import { useTranslation } from "react-i18next";
import FeatureTask from "./FeatureTask";



const News = () => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    return (
        <Fragment>
            <Head title="Bảng tin" />
            <Content>
                <NewsDiscover currentLanguage={currentLanguage} />

                <div className="d-flex flex-column justify-content-center flex-md-row gap-4 flex-nowrap" style={{ height: "fit-content", marginTop: "16px" }}>
                    <ChannelConnect />
                    <FeaturedPost />
                </div>
                
                <FeatureTask/>

                <TodayPostSchedule />


            </Content>
        </Fragment>
    );
}

export default News;