import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { RiAttachment2 } from "@remixicon/react";
import { Icon } from "../../../../components/Component";
import "../styles.css";

const DetailAttachment = ({
  data,
  setListDetail,
  handleAttachmentSelect,
  selectedAttachment,
  setSelectedAttachment,
  handleUpdateTask,
}) => {
  const { t } = useTranslation();
  const attachmentRef = useRef(null);

  const handleRemoveAttachment = (index) => {
    const newAttachments = selectedAttachment.filter((_, i) => i !== index);
    setSelectedAttachment(newAttachments);
    setListDetail((prev) => ({
      ...prev,
      attachments: newAttachments,
    }));
    handleUpdateTask({ attachments: newAttachments });
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div className="d-flex items-center">
        <div>
          <RiAttachment2 size={16} className="text-muted cursor-pointer" />
        </div>
        {data?.length > 0 ? (
          <div className="item-button w-100" style={{ marginLeft: "10px" }}>
            {data?.length} {t("task_management.detail.attachments")}
          </div>
        ) : (
          <div
            className="item-button"
            style={{ marginLeft: "10px", fontSize: "12px", fontWeight: "400" }}
            id="attachmentButton"
            onClick={() => attachmentRef.current.click()}
          >
            {t("task_management.detail.add_attachments")}
          </div>
        )}

        <input
          type="file"
          accept="image/*"
          id="attachmentInput"
          ref={attachmentRef}
          style={{ display: "none" }}
          onChange={handleAttachmentSelect}
          multiple
        />
      </div>

      {selectedAttachment?.length > 0 && (
        <>
          <div
            className="d-flex gap-2"
            style={{ flexWrap: "wrap", height: "auto" }}
          >
            {selectedAttachment.map((attachment, index) => (
              <div
                key={index}
                className="relative attachment-img"
                style={{
                  paddingLeft: "28px",
                  marginTop: "8px",
                  height: "100%",
                }}
              >
                <img
                  src={attachment.url}
                  alt="attachment"
                  style={{
                    cursor: "pointer",
                    width: "60px",
                    height: "60px",
                    borderRadius: "8px",
                  }}
                />
                <div
                  className="close-img action-icons"
                  onClick={() => handleRemoveAttachment(index)}
                >
                  x
                </div>
              </div>
            ))}
          </div>
          <div
            className="item-button mt-2"
            style={{
              marginLeft: "28px",
              width: "150px",
              fontSize: "12px",
              fontWeight: "400",
            }}
            id="attachmentButton"
            onClick={() => attachmentRef.current.click()}
          >
            <Icon name="plus" style={{ color: "#90959D", fontSize: "16px" }} />
            {t("task_management.detail.add_attachments")}
          </div>
        </>
      )}
    </div>
  );
};

export default DetailAttachment;
