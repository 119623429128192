import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "reactstrap";
import {toast, ToastContainer} from "react-toastify";
import {useHistory} from "react-router";

const PaymentMethodInfo = ({ currentBill, variation, className }) => {
  const [isOpen, setIsOpen] = useState("1");
  const userInfor = JSON.parse(localStorage.getItem('userInfor'));
  const history = useHistory();

  const { t } = useTranslation()
  const toggleCollapse = (param) => {
    if (param === isOpen) {
      setIsOpen("0");
    } else {
      setIsOpen(param);
    }
  };
  
  const redirectToListSubscription = () => {
    history.push('/admin/subscription');
  }
  
  const copyTextTransferMoney = () => {
    const text = userInfor.email + ' ' + currentBill?._id;
    navigator.clipboard.writeText(text);
    toast.success(t('copy_text_successful'), {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  return (
    <div className={[`accordion${variation ? " accordion-s" + variation : ""}${className ? " " + className : ""}`]}>
      <div className="accordion-item">
        <div className={[`accordion-head${isOpen !== "1" ? " collapsed" : ""}`]} onClick={() => toggleCollapse("1")}>
          <h6 className="title">{t("project_payment_method_transfer")}</h6>
          <span className="accordion-icon"></span>
        </div>
        <Collapse className="accordion-body" isOpen={isOpen === "1" ? true : false}>
          <div className={`px-4 py-3`}>
            <div className={`text-sm`}>
              <span>Ngân hàng Việt Nam Thịnh Vượng</span>&nbsp;
              <span className={`font-semibold`}>(VP Bank)</span>
            </div>
            <div className={`text-sm mt-2`}>
              <span>Chủ tài khoản:</span>&nbsp;
              <span className={`font-semibold`}>Vũ Ngọc Khánh</span>
            </div>
            <div className={`text-sm mt-2`}>
              <span>Số tài khoản:</span>&nbsp;
              <span className={`font-semibold`}>87681246</span>
            </div>
            <div className={`text-sm flex justify-between items-center mt-2`}>
              <div>
                <span>Nội dung chuyển khoản:</span><br/>
                <span className={`font-semibold`}>Email đăng nhập + 123123 (ID project)</span>&nbsp;
                <span className={`text-xs text-blue-400 cursor-pointer underline`} onClick={copyTextTransferMoney}>Copy</span>
              </div>
              <div className={`bg-[#DFE2E9] px-2 py-1 cursor-pointer rounded-lg font-semibold`} onClick={redirectToListSubscription}>
                {t('project_confirm_transfer')}
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="accordion-item">
        <div className={[`accordion-head${isOpen !== "2" ? " collapsed" : ""}`]} onClick={() => toggleCollapse("2")}>
          <h6 className="title">{t("project_payment_method_momo")}</h6>
          <span className="accordion-icon"></span>
        </div>
        <Collapse className="accordion-body" isOpen={isOpen === "2" ? true : false}>
          <div className={`font-semibold text-base px-4 py-3`}>{t('project_coming_soon')}</div>
        </Collapse>
      </div>
      <div className="accordion-item">
        <div className={[`accordion-head${isOpen !== "3" ? " collapsed" : ""}`]} onClick={() => toggleCollapse("3")}>
          <h6 className="title">{t("project_payment_method_qr_napas")}</h6>
          <span className="accordion-icon"></span>
        </div>
        <Collapse className="accordion-body" isOpen={isOpen === "3" ? true : false}>
          <div className={`font-semibold text-base px-4 py-3`}>{t('project_coming_soon')}</div>
        </Collapse>
      </div>
      <ToastContainer/>
    </div>
  );
};

export default PaymentMethodInfo;
