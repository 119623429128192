import React, { useEffect, useState } from "react";
import style from "../index.module.scss";
import { getStatusStatic } from "../../../../api/dashboard";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { IconPost } from "../../../../libs/icon";
import Tag from "../../PostDetail/Tag";
import { ARR_POST_STATUS_POST } from "../../../constants/posts";
import { Card } from "reactstrap";

const StaticStatus = () => {
  const { t } = useTranslation();

  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const projectId = userInfo.last_project_active;
  const [series, setSeries] = useState([30, 40, 10]);
  const [label, setLabel] = useState([2, 1, 0]);
  const [fakeData, setFakeData] = useState(true);
  const [listColor, setListColor] = useState(["#f8f8f8", "#f3f2f2", "#ebebeb"]);
  useEffect(() => {
    fetchDataStatus();
  }, [projectId]);

  const fetchDataStatus = () => {
    getStatusStatic(projectId, moment().format("YYYY-MM-DD"), {})
      .then((res) => {
        if (res.status && res?.data) {
          const data = res.data?.map((item, _) => item.y);
          const labelData = res.data?.map((item) => item.status);
          const color = res.data?.map((item) => item.color);
          setSeries(data);
          setLabel(labelData);
          setListColor(color);
          setFakeData(false);
        }
      })
      .catch((error) => console.log(error));
  };

  const options = {
    chart: {
      type: "donut",
      background: "#fff",
    },
    labels: label.map((item) => t(ARR_POST_STATUS_POST[item])),
    dataLabels: {
      enabled: false,
    },
    colors: listColor,
    legend: {
      position: "bottom",
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 150,
          },
          legend: {
            position: "bottom",
            show: false,
          },
        },
      },
    ],
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val) {
          return fakeData ? "-" : val;
        },
      },
    },
  };

  return (
    <>
      <Card className={`${style.StaticStatus}  ${style.card} `}>
        <div className={style.header}>
          <p>{t("news.post_public")}</p>
        </div>
        <div className={`${style.main} ${style.chartMain}`}>
          <div id="chart" className={style.chart}>
            <ReactApexChart options={options} series={series} type="donut" />
          </div>
          <div className={style.labelStatus}>
            {label.map((item, index) => (
              <div className={style.itemLabel}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                >
                  <IconPost status={item} />
                  <Tag status={item} />
                </div>
                <div>{fakeData ? "-" : series[index]}</div>
              </div>
            ))}
          </div>
        </div>
      </Card>
    </>
  );
};

export default StaticStatus;
