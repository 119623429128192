import React, { useState, useEffect } from "react";
import Head from "../../../layout/head/Head";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Icon from "../../../components/icon/Icon";
import PostMulti from "./PostMulti";
import style from "./index.module.scss";
import "./create-post.scss";
import { getErrorChannel } from "../../../api/channel";
import PostFastNew from "../Reels/PostFastNew";
// import PostFastUpdate from "../Reels/PostFastUpdate";
import { useDispatch } from "react-redux";
import { setCollapseSidebarLeft } from "../../reducers/layoutSlice";

export default function Reels() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("1");
  const [numChannels, setNumChannels] = useState(0);
  const listProjectException = [
    "63256ee52fc4b337f308d725",
    "628c7e9ca05a926caa09caa5",
  ];
  const userInformation = JSON.parse(localStorage.getItem("userInfor"));
  const projectId = userInformation.last_project_active;
  useEffect(() => {
    dispatch(setCollapseSidebarLeft(true));
    if (window.location.href.includes("drive=true")) {
      setActiveTab("2");
    }
    // const socketInstance = io(process.env.REACT_APP_CDN_API, {
    //   transports: ["websocket"],
    //   withCredentials: true,
    // });
    // setSocket(socketInstance);
    // return () => {
    //   if(socket){
    //     socket.disconnect();
    //   }
    // };
  }, []);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  useEffect(() => {
    getChannelError(projectId);
  }, [projectId]);
  const getChannelError = (projectId) => {
    const fetchData = async () => {
      return await getErrorChannel(projectId);
    };
    fetchData().then((response) => {
      const data = response.data;
      setNumChannels(data);
    });
  };

  return (
    <React.Fragment>
      <Head title={"Create A Post"}></Head>
      <div
        style={{
          marginTop: "65px",
          position: "relative",
          padding: "24px 5px 0px 5px",
        }}
      >
        <Nav tabs className={`mt-n3 ${style.navPost}`}>
          <NavItem className={`${style.navWrapper}`}>
            <NavLink
              tag="a"
              href="#tab"
              className={`${activeTab === "1" && style.active} ${
                style.navItem
              }`}
              style={{ borderTopLeftRadius: "8px" }}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("1");
              }}
            >
              <Icon
                name="layout-fill1"
                style={{
                  fontSize: "1em",
                  color: activeTab === "1" ? "#FD1F6D" : "#FAFF00",
                }}
                className="mr-1"
              />{" "}
              Đăng lên trang
            </NavLink>
          </NavItem>
          <NavItem className={`${style.navWrapper}`}>
            <NavLink
              tag="a"
              href="#tab"
              className={`${activeTab === "2" && style.active} ${
                style.navItem
              }`}
              style={{ borderTopRightRadius: "8px" }}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("2");
              }}
            >
              <Icon
                name="spark-fill"
                style={{
                  fontSize: "1em",
                  color: activeTab === "2" ? "#FD1F6D" : "#FAFF00",
                }}
                className="mr-1"
              />{" "}
              Đăng hàng loạt
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} style={{ marginTop: "0px" }}>
          <TabPane tabId="1" className="postWrapper">
            <PostMulti />
          </TabPane>
          <TabPane tabId="2">
            <PostFastNew />
            {/* {
            (listProjectException.includes(projectId) || true) ? <PostFastNew /> : <PostFastUpdate />
          } */}
          </TabPane>
        </TabContent>
      </div>
      {/*<ModalMaintenance/>*/}
    </React.Fragment>
  );
}
