import { Fragment, useEffect, useState } from "react";
import { Button, Card, Spinner, Table } from "reactstrap";

import { getPlatformIcon } from "../../utils/Utils";
import style from "./index.module.scss";
import { Icon, Img, PaginationCustom } from "../../../components/Component";
import moment from "moment";
import { setIsOpen } from "../../reducers/intructionSlice";

import { getNewsPostInDays } from "../../../api/overview";
import { Link, useHistory } from 'react-router-dom';

import SUPPORTIMAGE from "../../images/news/support.png";
import SO9_ICON from "../../images/news/application.png";
import CH_PLAY from "../../images/news/ggplay.png";
import APP_STORE from "../../images/news/appstore.png";
import VIDEO_ICON from "../../images/news/video-icon.svg";
import REEL_ICON from "../../images/news/revert-icon.svg";
import IMAGE_ICON from "../../images/news/image-icon.svg";
import SUCCESS_ICON from "../../images/news/success-icon.svg";
import SCHEDULE_ICON from "../../images/news/schedule-icon.svg";
import ERROR_ICON from "../../images/news/error-icon.svg";
import EMPTY_IMAGE from "../../images/news/empty-today-post.png";
import ModalSortingChannel from "../../components/Modal/ModalSortingChannel";
import { useDispatch } from "react-redux";
import { t } from "i18next";





const TodayPostSchedule = () => {
    const history = useHistory();
    const dispach = useDispatch();

    const userInfor = JSON.parse(localStorage.getItem("userInfor"));

    const [currentPage, setCurrentPage] = useState(1);
    const [listPost, setListPost] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openModalSortingChannel, setOpenModalSortingChannel] = useState(false);


    const fetchPost = async (page) => {
        setLoading(true);
        try {
            const data = {
                params: {
                    date: moment().format('YYYY-MM-DD'),
                    page: page,
                    limit: 10
                }
            }
            const res = await getNewsPostInDays(userInfor.last_project_active, data);
            if (res?.status) {
                setListPost(res?.data);
                setCurrentPage(page);
                setLoading(false);

            } else {
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }


    useEffect(() => {
        fetchPost(1);
    }, [])


    const renderListPost = () => {
        return (
            listPost?.data?.length > 0 &&
            listPost.data.map(item => (
                renderTableItem(item)
            ))
        )
    }

    const handleCreatePost = () => {
        history.push('/posts/create');
    };
    const renderEmpty = () => {
        if (!loading) {
            return (
                <>
                    <div className={`${style.postScheduleBlock__header} text-nowrap d-flex align-items-center`}>
                        <p className="mb-0" style={{ marginTop: "16px" }}>{t("news.calendar.header")}</p>
                    </div>

                    <div className={`d-flex w-100 h-100 justify-content-center align-items-center`}>
                        <Img src={EMPTY_IMAGE} height={"120px"}></Img>
                        <div className={`${style.postScheduleBlock__empty}`}>
                            <p>{t("news.calendar.create_your_first_content")}</p>
                            <span>{t("news.calendar.sub_create_your_first_content")}</span>
                            <Button onClick={handleCreatePost}>{t("news.calendar.sub_botton_create_first_content")}</Button>
                        </div>
                    </div>
                </>
            )
        }
    }

    const paginate = (page) => {
        fetchPost(page);
    }


    const handleOpenBook = () => {
        dispach(setIsOpen(true));
    }

    const renderSupport = () => {
        return (
            <Card className={`${style.supportBlock}`} style={{ height: "194px" }}>
                <div className="p-3">
                    <p className={`text-uppercase ${style.supportBlock__header}`}>{t("news.support.header")}</p>
                    <p className={`mb-0 ${style.supportBlock__subHead}`}>{t("news.support.sub_header")}</p>
                    <div className={`d-flex justify-content-center cursor-pointer align-items-center border rounded my-3 ${style.supportBlock__hepler}`}
                        onClick={() => {
                            window.open(
                                "https://www.facebook.com/login.php?next=https%3A%2F%2Fwww.facebook.com%2Fmessages%2Ft%2F103638805810736%2F",
                                "_blank"
                            );
                        }}
                    >
                        <Img src={SUPPORTIMAGE} height={"24px"}></Img>
                        <span>{t("news.support.text_support_image")}</span>
                    </div>
                    <div className={`d-flex align-items-center ${style.supportBlock__contact}`}>
                        <Icon name={"call-fill"}></Icon>
                        <p className="mb-0">Hotline:</p>
                        <span className="ml-1"> 0943.889.033</span>
                    </div>
                    <div onClick={handleOpenBook} className={`d-flex cursor-pointer align-items-center ${style.supportBlock__guide}`}>
                        <Icon name={"book-fill"}></Icon>
                        <span >{t('news.support.guidebook')}</span>
                    </div>
                </div>

            </Card>
        )
    }

    const renderApplication = () => {
        return (
            <Card className={`${style.supportBlock}`} style={{ height: "186px", margin: "0" }}>
                <div className="p-3">
                    <p className={`text-uppercase ${style.supportBlock__header}`}>{t("news.application.header")}</p>
                    <p className={`mb-0 ${style.supportBlock__subHead}`}>{t("news.application.sub_header")}</p>
                    <div className={`d-flex mt-3`} style={{ marginBottom: '12px' }}>
                        <Img src={SO9_ICON} height={"51px"} width={"51px"}></Img>
                        <ul style={{ listStyleType: "disc", marginLeft: "auto" }}>
                            <li>{t("news.application.feature.first_line")}</li>
                            <li>{t("news.application.feature.second_line")}</li>
                        </ul>
                    </div>
                    <div className="d-flex justify-content-end cursor-pointer" style={{ gap: "8px" }}>

                        <Img onClick={() => window.open("https://apps.apple.com/vn/app/so9/id6446020644")} src={APP_STORE} height={"36px"}></Img>
                        <Img onClick={() => window.open("https://play.google.com/store/apps/details?id=com.so9.vn&hl=en")} src={CH_PLAY} height={"36px"}></Img>
                    </div>
                </div>

            </Card>
        )
    }

    const renderAvatar = ({ image, platform }) => {
        return (
            <div className={`position-relative ${style.postScheduleBlock__userAvatar}`} style={{ width: "32px", height: "32px" }}>
                <Img src={image ?? ""} height={"32px"}></Img>
                <Img src={getPlatformIcon(platform)} height={"12px"} className={`position-absolute`} style={{ bottom: "0px", right: "0px" }}></Img>
            </div>
        )
    }

    const getStatusIcon = (status) => {
        switch (status) {
            case 1:
                return SCHEDULE_ICON;
            case 2:
                return SUCCESS_ICON;
            case 0:
                return ERROR_ICON;
            default:
                return "";
        }
    }

    const renderStatusCol = (listStatus, type) => {
        if (listStatus?.length > 0) {
            return (
                <div className="d-flex align-items-center" style={{ gap: "16px", width: "fit-content" }}>
                    {listStatus?.map((item) => (
                        <div className="d-flex align-items-center">
                            <Img src={getStatusIcon(item.status)} height={"12px"}></Img>
                            <span className={`ml-1 ${style.postScheduleBlock__userName}`}>{item.count}</span>
                        </div>
                    ))}
                </div>
            )
        }
        return (
            <Link to={(type !== "short") ? "/posts/create" : "/posts/reel/create"} className={`d-flex align-items-center cursor-pointer ${style.postScheduleBlock__post}`}>
                {t('news.calendar.botton_create_post')}
            </Link>
        )
    }

    const renderTableItem = (item) => {
        return (
            <tr style={{ padding: "8px 0" }}>
                <td style={{ paddingLeft: "0px" }}>
                    <div className="d-flex align-items-center">
                        {renderAvatar({ image: item.picture, platform: item.platform })}
                        <span className={`${style.postScheduleBlock__userName} text-truncate`}>{item.name ?? "ăsdawdawawdawdawdawdawdawd"}</span>
                    </div>
                </td>
                <td>{renderStatusCol(item?.list_status_posts?.photo, "photo")}</td>
                <td>{renderStatusCol(!item?.list_status_posts?.reel ? item?.list_status_post?.reel : item?.list_status_post?.short, "short")}</td>
                <td>{renderStatusCol(item?.list_status_posts?.video, "video")}</td>
            </tr>
        )
    }
    return (
        <Fragment>
            <div className={`d-flex gap-4 flex-nowrap  ${style.postScheduleBlock}`}>
                <Card style={{ flexGrow: "1", padding: "0 16px" }} >
                    {!listPost?.data || listPost?.data?.length === 0 ? renderEmpty() :
                        (
                            <div className={`${style.tablePost} table-responsive`} style={{ minHeight: "70px" }}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th style={{ paddingLeft: "0px", minWidth: "245px" }}>
                                                <div className={`${style.postScheduleBlock__header} d-flex align-items-center`}>
                                                    <p className="mb-0  text-nowrap">{t("news.calendar.header")}</p>
                                                    <span className="cursor-pointer" onClick={() => setOpenModalSortingChannel(true)}><Icon name="swap-v"></Icon> {t("news.calendar.button_order")}</span>
                                                </div>
                                            </th>
                                            <th>
                                                <div className={`${style.postScheduleBlock__colHeader}`}>
                                                    <Img src={IMAGE_ICON} height={"16px"}></Img>
                                                    <span>{t("news.calendar.text_post_image")}</span>
                                                </div>
                                            </th>
                                            <th>
                                                <div className={`${style.postScheduleBlock__colHeader}`}>
                                                    <Img src={REEL_ICON} height={"16px"}></Img>
                                                    <span>{t("news.calendar.text_post_short")}</span>
                                                </div>

                                            </th>
                                            <th>
                                                <div className={`${style.postScheduleBlock__colHeader}`}>
                                                    <Img src={VIDEO_ICON} height={"16px"}></Img>
                                                    <span>{t("news.calendar.text_post_video")}</span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!loading && renderListPost()}
                                    </tbody>

                                </Table>

                                {!loading && listPost?.data?.length > 0 &&
                                    <div className="mobile-paginate d-flex justify-content-between align-items-center" style={{ marginTop: "31px", marginBottom: "12px" }}>
                                        {listPost?.total > 10 &&
                                            <PaginationCustom
                                                pageSize={listPost?.per_page}
                                                totalCount={listPost?.total}
                                                siblingCount={1}
                                                onPageChange={paginate}
                                                currentPage={currentPage}
                                            />}
                                        <div onClick={() => { history.push("/calender/postChannel") }} className={`d-flex align-items-center cursor-pointer ${listPost?.total > 10 && "ml-4"}`}>
                                            <Icon style={{ color: "#2F80ED", fontSize: "12px" }} name="calendar-check"></Icon>
                                            <span className={`${style.postScheduleBlock__userName} fw-normal text-nowrap`} style={{ color: "#2F80ED", marginLeft: "8px" }}>{t("news.calendar.view_calender")}</span>
                                        </div>
                                    </div>}
                            </div>
                        )}
                    {loading &&
                        <div className={`d-flex justify-content-center align-items-center w-100 h-100`}>
                            <Spinner></Spinner>
                        </div>
                    }
                </Card>

                <div className={`d-flex flex-column justify-content-center gap-4 ${style.helperBlock}`} style={{ width: "336px", height: "fit-content" }}>
                    {renderSupport()}
                    {renderApplication()}
                </div>
            </div>
            <ModalSortingChannel openModal={openModalSortingChannel} setOpenModal={() => setOpenModalSortingChannel(false)} />
        </Fragment>
    )
}

export default TodayPostSchedule;