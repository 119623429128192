import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Spinner } from "reactstrap";

// import { numberWithCommas } from "common/Utils";
// import Img from "Components/Common/img/Img";
// import PaginationCustom from "Components/Common/pagination/PaginationCustom";
// import TableToolTip from "pages/Dashboard/ContentDashboard/CommonItem/TableToolTop";

import NO_IMAGE from "../../Image/no_image_page.svg";
import styles from "../index.module.scss";
import { numberWithCommas } from "../../../DashboardNew/utils/utils";
import { Img, PaginationCustom } from "../../../../../components/Component";
import TableToolTip from "../../ContentDashboard/CommonItem/TableToolTop";
const UserStatisticTable = ({
  setCurrentPage,
  headerUserStatistic,
  tooltipTableOpen,
  tableLoading,
  listData,
  data,
}) => {
  const { t } = useTranslation();
  const [isShowNodata, setIsShowNodata] = useState(-1);

  const handleHoverNoData = (id) => {
    setIsShowNodata(id);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  return (
    <>
      <Card className="card-bordered">
        <div className="card-inner-group" style={{ minHeight: "250px" }}>
          <div className="card-inner p-0">
            <div className="table-responsive" style={{ minHeight: "250px" }}>
              <div
                className="table-header d-flex justify-content-between align-items-center bg-slate-50"
                style={{ height: "60px" }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: "16px",
                    margin: "12px 0px 12px 24px",
                    whiteSpace: "nowrap",
                    minWidth: "fit-content",
                    // color: "#111827",
                  }}
                >
                  {t("employee")}
                </p>
                {/* <div className="d-flex"
                style={{ margin: "0px 24px 0px 0px" }}
              >
                {renderDropdown()}
                {renderSorting()}
              </div> */}
              </div>

              {tableLoading && (
                <div
                  style={{
                    width: "100%",
                    minHeight: "80px",
                    position: "absolute",
                  }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Spinner size={["lg"]} color="primary"></Spinner>
                </div>
              )}
              <table className="table table-hover">
                <thead
                  style={{
                    height: "60px",
                    margin: 0,
                    padding: 0,
                    // backgroundColor: "#EBEAED",
                  }}
                >
                  <tr>
                    {headerUserStatistic &&
                      Object.values(headerUserStatistic).map((field, index) => {
                        const styleTh =
                          field === t("employee")
                            ? {
                                paddingBottom: "20px",
                                width: "25%",
                                whiteSpace: "nowrap",
                                minWidth: "200px",
                              }
                            : {
                                paddingBottom: "20px",
                                textAlign: "end",
                                width: "10%",
                                whiteSpace: "nowrap",
                                minWidth: "150px",
                              };
                        return (
                          <th
                            key={`th_${index}`}
                            scope="col"
                            id={`th_${index}`}
                            style={styleTh}
                          >
                            <span
                              style={{ fontSize: "14px" }}
                              className={`${styles.tooltipHover}`}
                              id={`tableToolTip${index}`}
                            >
                              {field}
                            </span>
                            <TableToolTip
                              tooltipId={index}
                              tooltipLabel={tooltipTableOpen[index].label}
                            />
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {listData.length > 0
                    ? listData.map((item, index) => {
                        return (
                          <tr key={`tr_${index}`}>
                            {Object.keys(headerUserStatistic).map(
                              (fk, tdIndex) => {
                                if (fk === "user") {
                                  return (
                                    <td
                                      key={`td_${fk}_${tdIndex}`}
                                      style={{ height: "50px", width: "25%" }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <Img
                                          src={item?.user?.avatar ?? NO_IMAGE}
                                          alt=""
                                          width="32px"
                                          style={{ borderRadius: "15%" }}
                                          defaultPicture={NO_IMAGE}
                                        ></Img>
                                        <span
                                          style={{
                                            margin: "8px 0px 0px 10px",
                                          }}
                                          className="tb-sub"
                                        >
                                          {item[fk]?.name}
                                        </span>
                                      </div>
                                    </td>
                                  );
                                } else if (fk !== "") {
                                  return (
                                    <td
                                      key={`td_${fk}_${tdIndex}`}
                                      style={{
                                        height: "50px",
                                        width: "13%",
                                        // paddingTop:"0px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          // width: "150px",
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <span
                                          className="tb-sub"
                                          style={{
                                            paddingTop: "10px",
                                            paddingLeft: "20%",
                                          }}
                                        >
                                          {/* {numberWithCommas(item[`${fk}`]?.value)} */}
                                        </span>
                                        <div
                                          className="traffic-channel-ck ml-auto"
                                          style={{
                                            textAlign: "end",
                                            paddingTop: "8px",
                                            paddingLeft: "20%",
                                            // marginTop: "6px"
                                          }}
                                        >
                                          {numberWithCommas(
                                            item[`${fk}`]?.value
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                  );
                                }
                              }
                            )}
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>

              <div className="card-inner">
                {!tableLoading ? (
                  listData?.length > 0 && data ? (
                    data?.last_page > 1 && (
                      <div className="mobile-paginate">
                        <PaginationCustom
                          pageSize={data?.per_page || 9}
                          totalCount={data?.total || 0}
                          siblingCount={1}
                          onPageChange={handlePageChange}
                          currentPage={data?.current_page}
                        />
                      </div>
                    )
                  ) : (
                    <div className="text-center">
                      <span className="text-silent">No page found</span>
                    </div>
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default UserStatisticTable;
