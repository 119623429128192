import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Progress,
  Row,
} from "reactstrap";
import style from "../../../index.module.scss";
import Ratio from "../../../../../images/dashboard/img-ratio.png";
import { Icon } from "../../../../../../components/Component";
import ChartPine from "../ChartPine";
import moment from "moment";
import { formatNumberAcronym } from "../../../../../../utils/Utils";

const YTOverviewEffect = (props) => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(props?.data);
  }, [props?.data]);

  const totalY = data?.rate_type?.reduce((sum, item) => sum + item.y, 0);
  return (
    <Row className="d-flex flex-column flex-md-row">
      <Col
        sm={12}
        md={4}
        className="w-100 d-flex flex-row flex-md-column mb-3 mb-md-0"
        style={{ gap: "16px" }}
      >
        <div className="w-100 h-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Tổng lượt xem
              </CardTitle>
              <div className="d-flex flex-row align-items-center">
                <div className={`${style.titleCard}`}>
                  {data ? formatNumberAcronym(data?.total_view?.value) : 0}
                </div>
                {/* <div className="ml-1" style={{ color: "#10B981" }}>
                      {" "}
                      <Icon name={"arrow-up"}></Icon> 0%
                    </div> */}
              </div>
              <CardText
                style={{ whiteSpace: "nowrap" }}
                className={`${style.detailCard} mt-1`}
              >{`Trung bình: ${
                data ? data?.total_view?.avg_day : 0
              }/ngày`}</CardText>
            </CardBody>
          </Card>
        </div>
        <div className="w-100 h-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Số phút xem
              </CardTitle>
              <div className="d-flex flex-row align-items-center">
                <div className={`${style.titleCard}`}>
                  {data ? formatNumberAcronym(data?.minutes_watched?.value) : 0}
                </div>
                {/* <div className="ml-1" style={{ color: "#10B981" }}>
                      {" "}
                      <Icon name={"arrow-up"}></Icon> 0%
                    </div> */}
              </div>
              <CardText
                style={{ whiteSpace: "nowrap" }}
                className={`${style.detailCard} mt-1`}
              >{`Trung bình: ${
                data ? data?.minutes_watched?.avg_day : 0
              }/ngày`}</CardText>
            </CardBody>
          </Card>
        </div>
      </Col>
      <Col sm={12} md={4} className="w-100 mb-3 mb-md-0">
        <Card className="h-100">
          <CardBody className="card-inner">
            <CardTitle tag="h5" className={`${style.headCard}`}>
              Bài đăng nổi bật
            </CardTitle>
            <CardText
              className={`${style.detailCard} mt-2 d-flex flex-row align-items-center`}
              style={{ margin: "0px" }}
            >
              <Icon name={"clock"} className="mr-1"></Icon>{" "}
              {data
                ? moment(data?.highlight_video?.publishedAt).format(
                    "DD MMM YYYY hh:mm A"
                  )
                : "17 Feb 2023 10:10 AM"}
            </CardText>
            <div>
              <CardImg
                src={
                  // Check if media field is empty or has a valid URL
                  data?.highlight_video?.thumbnails
                    ? data?.highlight_video?.thumbnails?.high?.url // Check if URL is for a video
                    : Ratio // Default image if media field is empty
                }
                alt="img-ratio"
                height={200}
                className="mt-1"
                style={{
                  margin: "0px",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </div>

            <div>
              <CardText
                className={`${style.contentCard} mt-1 d-flex flex-row align-items-center pb-2`}
                style={{
                  borderBottom: "1px solid #D1D5DB",
                }}
              >
                <span className={`${style.truncatedText}`}>
                  {data ? data?.highlight_video?.title : ""}
                </span>
              </CardText>
            </div>
            <div className="d-flex flex-column mt-2" style={{ gap: "8px" }}>
              <div
                className={`${style.contentCard} d-flex flex-row align-items-center justify-content-between`}
              >
                <div>Tổng lượt xem</div>
                <div style={{ fontWeight: "600" }}>
                  {data ? data?.highlight_video?.viewCount : 0}
                </div>
              </div>
              <div
                className={`${style.contentCard} d-flex flex-row align-items-center justify-content-between`}
              >
                <div>Tổng lượt thích</div>
                <div style={{ fontWeight: "600" }}>
                  {data ? data?.highlight_video?.likeCount : 0}
                </div>
              </div>
              <div
                className={`${style.contentCard} d-flex flex-row align-items-center justify-content-between`}
              >
                <div>Tổng lượt comment</div>
                <div style={{ fontWeight: "600" }}>
                  {data ? data?.highlight_video?.commentCount : 0}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col sm={12} md={4} className="w-100 mb-3 mb-md-0">
        <Card className="h-100">
          <CardBody className="card-inner">
            <CardTitle tag="h5" className={`${style.headCard}`}>
              Từ khóa tìm kiếm nhiều nhất
            </CardTitle>

            <div
              className={`${style.headCard} d-flex flex-row align-items-center w-100 pb-2`}
              style={{
                fontSize: "0.75rem",
                borderBottom: "1px solid #D1D5DB",
              }}
            >
              <div className="w-35">Từ khóa</div>
              <div className="w-40">Số lượt tìm</div>
              {/* <div className="w-25"></div> */}
              <div className="w-25 d-flex justify-content-end">%</div>
            </div>
            <div className="mt-2 d-flex flex-column" style={{ gap: "8px" }}>
              {data ? (
                data?.page_impressions_by_city_unique?.map((item, index) => {
                  const totalY = data?.page_impressions_by_city_unique?.reduce(
                    (acc, obj) => acc + obj.value,
                    0
                  );
                  return (
                    <div
                      className={`${style.contentCard} d-flex flex-row align-items-center w-100`}
                      key={index}
                    >
                      <div className="w-35">
                        {item?.source
                          ?.substring(0, item?.source?.indexOf(","))
                          .trim()}
                      </div>
                      <div className="w-15">
                        {formatNumberAcronym(item?.value)}
                      </div>
                      <div className="w-25">
                        <Progress
                          value={((item?.value * 100) / totalY).toFixed(0)}
                          barStyle={{ backgroundColor: "#2C4B94" }}
                          style={{
                            backgroundColor: "#F1F5FF",
                          }}
                        />
                      </div>
                      <div
                        className="w-25 d-flex justify-content-end"
                        style={{ fontWeight: "600" }}
                      >
                        {((item?.value * 100) / totalY).toFixed(1)}
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  <div
                    className={`${style.contentCard} d-flex flex-row align-items-center w-100`}
                  >
                    <div className="w-35">kw</div>
                    <div className="w-15">50</div>
                    <div className="w-25">
                      <Progress
                        value={50}
                        barStyle={{ backgroundColor: "#2C4B94" }}
                        style={{
                          backgroundColor: "#F1F5FF",
                        }}
                      />
                    </div>
                    <div
                      className="w-25 d-flex justify-content-end"
                      style={{ fontWeight: "600" }}
                    >
                      50
                    </div>
                  </div>
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default YTOverviewEffect;
