import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Icon } from "../../../components/Component";
import Transferlist from "./TransferList.js";

const ModalAddPage = ({ setIsOpen, channelFollow }) => {
  return (
    <Modal isOpen={true} style={{ maxWidth: "765px", width: "100%" }} size="lg">
      <ModalHeader
        close={
          <button className="close" onClick={setIsOpen}>
            <Icon name="cross" />
          </button>
        }
      >
        Cài đặt trang theo dõi
      </ModalHeader>

      <ModalBody>
        <div className="p-2">
          <Transferlist
            showModalAddFanPage={setIsOpen}
            channelFollow={channelFollow}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalAddPage;
