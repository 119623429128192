import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import bigSquaredFacebook from "../../images/icons/big-squared-facebook.svg";
import bigSquaredFacebookGroupLight from "../../images/icons/big-squared-facebook-group-light.svg";
import bigSquaredInstagram from "../../images/icons/big-squared-instagram.svg";
import bigSquaredTiktok from "../../images/icons/big-squared-tiktok.svg";
import zalo from "../../images/icons/zalo.svg";
import Shopee from "../../../images/icons/Shoppe.png";
import Youtube from "../../../images/icons/youtube.png"
import style from "./index.module.scss";
import { URI } from "../../../api/project";

const platforms = [
  [
    {
      id: "facebook",
      name: "Facebook",
      image: bigSquaredFacebook,
    },
    {
      id: "facebookgroup",
      name: "Facebook Group",
      image: bigSquaredFacebookGroupLight,
    },
    {
      id: "tiktok",
      name: "Tiktok",
      image: bigSquaredTiktok,
    },
  ],
  [
    {
      id: "instagram",
      name: "Instagram",
      image: bigSquaredInstagram,
    },
    {
      id: "zalo",
      name: "Zalo",
      image: zalo,
    },
    {
      id: "shopee",
      name: "Shopee",
      image: Shopee,
    },
  ],
  [
    {
      id: "youtube",
      name: "Youtube",
      image: Youtube,
    }
  ],
];
const ModalSelectChannelPlatform = (props) => {
  const { t } = useTranslation();
  const { isOpen, toggle, toast } = props;
  const project = useSelector((state) => state.project.project);
  const onClick = (platform) => {
    if (!project?._id) {
      toast?.warning(t("Vui lòng chọn dự án để có thể kết nối các fanpage"));
      return;
    }
    let url = "";
    switch (platform) {
      case "facebook":
        url = process.env.REACT_APP_URL_API + "api/v1" + URI.LOGIN_FACEBOOK + "?project_id=" + project?._id;
        break;
      case "facebookgroup":
        url =
          process.env.REACT_APP_URL_API + "api/v1" + URI.LOGIN_FACEBOOK + "?project_id=" + project?._id + "&type=group";
        break;
      case "instagram":
        break;
      case "zalo":
        url = process.env.REACT_APP_ZALO_API + "api/v1" + URI.LOGIN_ZALO + "?project_id=" + project?._id;
        // url = "/admin/project/connect-channel";
        break;
      case "tiktok":
        url = process.env.REACT_APP_URL_API + "api/v1" + URI.LOGIN_TIKTOK + "?project_id=" + project?._id;
        break;
      case "youtube":
        url = "https://youtube.so9.vn/youtube/auth?project_id=" + project?._id;
        break;
      default:
    }
    window.location.replace(url);
  };
  return (
    <>
      <Modal size="lg" isOpen={isOpen} toggle={toggle}>
        <ModalBody className="modal-body-lg text-center">
          <div className="nk-modal">
            <h4 className="nk-modal-title">Chọn nền tảng bạn muốn kết nối kênh</h4>
            {platforms.map((grItem, index) => (
              <div className="d-flex justify-content-between mb-4" key={index}>
                {grItem.map((p) => (
                  <div className={style.modalSelectPlatformItem} key={p.id} onClick={() => onClick(p.id)} role="button">
                    <img className={style.modalSelectPlatformItemImage} src={p.image} alt={p.id} />
                    <span className={style.modalSelectPlatformItemName}>{p.name}</span>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ModalSelectChannelPlatform;
