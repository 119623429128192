import React, { useState } from "react";
import { Img } from "../../../components/Component";
import { CONTENT_LIMIT_SEE_MORE } from "../../utils/Utils";
import IconDisLike from "../../images/icons/icon-disLike.svg";
import IconLike from "../../images/icons/icon-like.svg";
import IconComment from "../../images/icons/icon-comment-yt.svg"
import itemShare from "../../../images/icons/item_share.png"
import IconArrowRepeat from "../../images/icons/icon-arrow-repeat.svg"
import style from "./index.module.scss";
import "./_preview-facebook.scss";
import {
  getPlatformIconFromLabel
} from "../../utils/Utils";
const PreviewYoutube = ({ ...props }) => {
  const { channel, content, mediaItems, className } = props;
  const [openPreviewYoutube, setOpenPreviewYoutube] = useState(true)
  const displayContent =
    content?.length <= CONTENT_LIMIT_SEE_MORE ? (
      content
    ) : (
      <>
        {content?.slice(0, CONTENT_LIMIT_SEE_MORE)}
        <b>See more...</b>
      </>
    );

  const handleOpenPreviewYoutube = () => {
    setOpenPreviewYoutube(!openPreviewYoutube)
    const Elmchevron = document.getElementById(`chevron_previewYoutube`);
    const Elmcontent = document.getElementById("content_previewYoutube");
    if (!openPreviewYoutube) {
      Elmchevron.classList.add(`${style.openChevronDown}`)
      Elmchevron.classList.remove(`${style.openChevronUp}`)
      Elmcontent.classList.add(`${style.openContentDown}`)
      Elmcontent.classList.remove(`${style.openContentUp}`)
    }
    else {
      Elmchevron.classList.add(`${style.openChevronUp}`)
      Elmchevron.classList.remove(`${style.openChevronDown}`)
      Elmcontent.classList.add(`${style.openContentUp}`)
      Elmcontent.classList.remove(`${style.openContentDown}`)
    }
  }
  return (
    <div className={`previewFacebook ${className || ""}`}>
      <div className="d-flex align-items-stretch border-bottom">
        <div
          className="flex-fill p-2 fw-medium header"
          style={{ color: "#333333", fontFamily: "Roboto" }}
        >
          <Img
            src={getPlatformIconFromLabel("youtube")}
            size="circle"
            width="16px"
            style={{ marginRight: "4px" }}
          />
          Youtube
        </div>
        <div id="chevron_previewYoutube" className={`card-tools p-2 ${style.openMedia}`} onClick={handleOpenPreviewYoutube}>
          <svg width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" /> </svg>
        </div>
      </div>
      <div id="content_previewYoutube" style={{
        transition: "max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s",
        willChange: "max-height",
        position: "relative",
        overflow: "hidden"
      }}>
        {
          mediaItems.length > 0 &&
          <div className="imageWrapper">
            <div className={style.previewInstagram__frame9_16}>
              <Img src={mediaItems[0]?.previewImages.url} alt="something" style={{ objectFit: "cover", margin: "auto" }} />
              <div className="d-flex p-1 justify-between w-full absolute bottom-0 ">
                <div className={`info ${style.previewYoutube__name}`}>
                  <div className={style.item__avarta}>
                    <Img
                      className="avatar"
                      src={channel.picture}
                      alt="avatar"
                      size="circle"
                      width="24px"
                      style={{ marginRight: "4px" }}
                    />
                    @{channel.name}
                  </div>
                  {content?.length > 0 && <div>{displayContent}</div>}
                </div>
                <div className={style.previewYoutube__avarta}>
                  <Img
                    className="avatar"
                    src={channel.picture}
                    alt="avatar"
                    size="square"
                    width="32px"
                  />
                </div>
              </div>
              <div className={style.previewYoutube__item}>
                <div className={style.item__interact}>
                  <Img src={IconLike} alt="like" />
                  <div>66 N</div>
                </div>
                <div className={style.item__interact}>
                  <Img src={IconDisLike} alt="comment" />
                  <div>2</div>
                </div>
                <div className={style.item__interact}>
                  <Img src={IconComment} alt="comment" />
                  <div>19.3k</div>
                </div>
                <div className={style.item__interact}>
                  <Img src={itemShare} alt="share" style={{ width: "32px", height: "32px" }} />
                  <div style={{ fontSize: "11px" }}>Chia sẻ</div>
                </div>
                <div className={style.item__interact}>
                  <Img src={IconArrowRepeat} alt="share" />
                  <div style={{ fontSize: "11px" }}>Remix</div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default PreviewYoutube;
