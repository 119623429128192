import React, { Fragment } from "react";
import { Card, Container, Row, UncontrolledTooltip } from "reactstrap";
import { Col, Icon, Img } from "../../../components/Component";
import style from "./index.module.scss";
import iconDelete from "../../images/icons/icon_delete.svg";
import iconEdit from "../../images/icons/icon_edit.svg";
import iconAdd from "../../images/icons/icon_add.svg";

const MediaItem = (props) => {
  const { media, onFormConnectHandle, deleteMedia, mediaType, action } = props;
  const mediaInfoWrapperStyle = {
    transition: "max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s",
    willChange: "max-height",
    position: "relative",
    overflow: "hidden",
    gap: "8px",
  };
  const imgStyle = {
    flex: "0 0 68px",
    borderRadius: "4px",
    objectFit: "cover",
    margin: "auto",
    width: "100%",
    height: "100%",
  };

  return (
    <Fragment>
      <Row style={{ maxHeight: "200px", overflow: "auto" }}>
        {media.map((item, index) => {
          return (
            <Col md="2" className={`${style.Col5} mb-2`} key={index}>
              <div
                style={{
                  width: "3rem",
                  height: "3rem",
                  objectFit: "cover",
                  position: "relative",
                }}
              >
                <Img
                  src={item?.thumbnailLink}
                  style={imgStyle}
                  alt={item?.name}
                />
                {/* {action === "edit" && mediaType !== "photo" ? (
                  <></>
                ) : ( */}
                <div
                  id="delete"
                  onClick={() => deleteMedia(item.id)}
                  style={{
                    borderRadius: "4px",
                    backgroundColor: "#2C4B94",
                    color: "#FFFFFF",
                    position: "absolute",
                    top: 0,
                    height: "1rem",
                    width: "1rem",
                    right: 0,
                  }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Icon name="cross" style={{ cursor: "pointer" }} />
                  <UncontrolledTooltip
                    autohide={false}
                    placement={"top"}
                    target={"delete"}
                  >
                    {mediaType === "photo" ? "Xóa ảnh" : "Xoá video"}
                  </UncontrolledTooltip>
                </div>
                {/* )} */}
              </div>
            </Col>
          );
        })}
        {mediaType !== "photo" ? (
          <></>
        ) : (
          <>
            <Col md="2" className={style.Col5}>
              <Card
                className="card-bordered dashed text-center d-flex align-items-stretch"
                style={{ width: "3rem", height: "3rem" }}
                onClick={() => onFormConnectHandle()}
              >
                <div
                  className={`d-flex align-items-center justify-content-center ${style.addImage}`}
                >
                  <Img src={iconAdd} alt="add" role="button" className="mt-2" />
                </div>
              </Card>
            </Col>
          </>
        )}
      </Row>
    </Fragment>
  );
};

export default MediaItem;
