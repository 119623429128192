import React, { useState, useEffect } from "react";

import { RiArrowUpSLine, RiSearchLine } from "@remixicon/react";
import { Input, Spinner } from "reactstrap";
import PlatformChannel from "./PlatformChannel";

import { CheckedBox } from "../../../../components/Component";
import { RSelect } from "../../../../components/Component";
import styles from "../index.module.scss";
import "../styles.css";
import { useTranslation } from "react-i18next";

const LeftMenu = ({
  isOpenLeftTab,
  listChannel,
  groupChannel,
  channelLoading,
  uniquePlatformsData,
  filterChannelList,
  setFilterChannelList,
  setListChannel,
}) => {
  const [isOpenChannel, setIsOpenChannel] = useState(true);
  const [filterChannel, setFilterChannel] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    const channels = listChannel?.channel?.filter(channel => ![3, 4].includes(channel.platform));
    if (filterChannel?.length > 0) {
      setFilterChannelList(
        channels.filter((item) => item.name.includes(filterChannel))
      );
    } else {
      setFilterChannelList(channels);
    }
  }, [listChannel, filterChannel]);

  const hasCheckAll = (list) => {
    let count = 0;
    list?.forEach((c) => {
      if (c.hasOwnProperty("check") && c.check) {
        count += 1;
      }
    });
    return count === list?.length ? true : false;
  };

  const handleCheckPlatform = (e, platform) => {
    e.stopPropagation();
    if (filterChannelList) {
      let updateChannel = [...filterChannelList];

      if (
        hasCheckAll(
          filterChannelList?.filter((item) => item.platform == platform)
        )
      ) {
        updateChannel.map((item) => {
          if (item.platform == platform) {
            item.check = false;
          }
        });
      } else {
        updateChannel.map((item) => {
          if (item.platform == platform) {
            item.check = true;
          }
        });
      }

      if (listChannel?.channel) {
        const newData = [...listChannel.channel];
        newData.map((item) => {
          filterChannelList?.forEach((fil) => {
            if (fil._id === item._id) {
              item.check = fil.check;
            }
          });
          return item;
        });
        setListChannel({ ...listChannel, channel: newData });
      }
      setFilterChannelList(updateChannel);
    }
  };

  const handleCheckAll = () => {
    if (filterChannelList) {
      let updateChannel = [...filterChannelList];
      if (hasCheckAll(filterChannelList)) {
        updateChannel.map((item) => {
          item.check = false;
        });
      } else {
        updateChannel.map((item) => {
          item.check = true;
        });
      }
      setFilterChannelList(updateChannel);

      if (listChannel?.channel) {
        const newData = [...listChannel.channel];
        newData.map((item) => {
          filterChannelList?.forEach((fil) => {
            if (fil._id === item._id) {
              item.check = fil.check;
            }
          });
          return item;
        });
        setListChannel({ ...listChannel, channel: newData });
      }
    }
  };

  const handleCheckboxChange = (item) => {
    if (listChannel?.channel) {
      let updateChannel = [...listChannel?.channel];

      updateChannel = updateChannel.map((channel) => {
        if (channel._id == item._id && typeof channel.check !== undefined) {
          channel.check = !channel.check;
        } else if (typeof channel.check === undefined) {
          channel.check = true;
        }
        return channel;
      });
      setListChannel({ ...listChannel, channel: updateChannel });
    }
  };

  const countSelectedChannel = () => {
    let count = 0;
    listChannel?.channel?.forEach((item) => {
      if (item.check) {
        count++;
      }
    });
    return count;
  };

  const handleChangeGroupChannel = (e) => {
    const mergedArray = e.reduce((acc, item) => {
      return [...acc, ...item.ids];
    }, []);
    if (listChannel?.channel) {
      const channels = listChannel?.channel
      let updateChannel = [...channels];

      mergedArray?.forEach((element) => {
        updateChannel = updateChannel.map((channel) => {
          if (channel._id == element) {
            channel.check = true;
          }
          return channel;
        });
      });
      setListChannel({ ...listChannel, channel: updateChannel });
    }
  };

  const renderListChannel = (isChangeOpen) => {
    if (channelLoading) {
      return (
        <div className="d-flex justify-content-center mt-4">
          <Spinner />
        </div>
      );
    }
    return uniquePlatformsData?.map((item, index) => (
      <PlatformChannel
        item={item}
        handleCheckPlatform={handleCheckPlatform}
        handleCheckboxChange={handleCheckboxChange}
        isCheckAll={hasCheckAll(item?.data)}
        isChangeOpen={isChangeOpen}
        loading={channelLoading}
      />
    ));
  };

  return (
    <div className={`boxLeft  ${isOpenLeftTab ? "open" : ""}`} >
      {isOpenLeftTab && (
        <div className="px-3 color-border" style={{ padding: "15px", fontSize: 15 }}>
          <p className={"mb-0"}>
            {t('store_post.channel')}
            {!!listChannel?.channel?.length && (
              <span
                className={`${styles.sideMenuHeader__number} ms-1 text-muted`}
              >
                ({countSelectedChannel()}/{listChannel?.channel?.length})
              </span>
            )}
          </p>
        </div>
      )}

      {!!listChannel?.channel?.length && !channelLoading && isOpenLeftTab && (
        <div className={`${styles.leftMenuGroupChannel}`}>
          <RSelect
            options={
              groupChannel &&
              groupChannel.map((group) => ({
                label: group.name,
                value: group._id,
                ids: group.channel_ids,
              }))
            }
            isMulti={true}
            closeMenuOnSelect={false}
            placeholder={t('store_post.group_channel')}
            onChange={(e) => handleChangeGroupChannel(e)}
          />

          <div
            className={`d-flex align-items-center`}
            style={{ marginTop: "20px" }}
          >
            <RiSearchLine size={14} className="text-secondary"></RiSearchLine>
            <Input
              type="text"
              value={filterChannel}
              onChange={(e) => setFilterChannel(e.target.value)}
              placeholder={t('store_post.search_account')}
              className="h-[28px] w-[188px]"
              style={{ margin: "0 12px 0 6px" }}
            />
            <RiArrowUpSLine
              size={14}
              className="text-muted bg-light rounded cursor-pointer mr-2"
              style={{ transform: isOpenChannel ? null : "rotate(180deg)" }}
              onClick={(e) => setIsOpenChannel(!isOpenChannel)}
            />
            <CheckedBox
              checked={hasCheckAll(filterChannelList)}
              onChange={handleCheckAll}
            />
          </div>
        </div>
      )}
      {isOpenLeftTab && (
        <div style={{ overflowY: "auto", height: "calc(100vh - 240px)" }}>
          {renderListChannel(isOpenChannel)}
        </div>
      )}
    </div>
  );
};

export default LeftMenu;
