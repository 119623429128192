import React, { useEffect, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { statusFacebookBackground } from "../../../utils/status";
import StatusCard from "./StatusCard";
import { Img } from "../../../../components/Component";
import MORE_STATUS_ICON from "../../../images/post/status/more-status-icon.svg";

const StatusList = ({
  isDisabledStatus,
  isOpenStatus,
  selectedStatus,
  setSelectedStatus,
}) => {
  const [isOpenStatusListCard, setIsOpenStatusListCard] = useState(false);
  const [dropdownStatusOpen, setDropdownStatusOpen] = useState(false);
  const [itemCount, setItemCount] = useState(4);

  const toggleStatusDropdown = () =>
    setDropdownStatusOpen((prevState) => !prevState);

  const updateItemCount = () => {
    if (window.innerWidth < 760) {
      setItemCount(2);
    } else {
      setItemCount(4);
    }
  };

  useEffect(() => {
    updateItemCount();
    window.addEventListener("resize", updateItemCount);

    return () => window.removeEventListener("resize", updateItemCount);
  }, []);

  const handleSelectStatus = (item) => {
    if (selectedStatus === item) {
      setSelectedStatus(null);
    } else {
      setSelectedStatus(item);
    }
  };

  return (
    !isDisabledStatus &&
    isOpenStatus && (
      <div
        className="d-flex items-center"
        style={{
          height: "36px",
          gap: "6px",
          padding: "4px 8px",
          backgroundColor: "FFFFFF",
          border: "1px solid #CED4DA",
          borderRadius: "2px",
          boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.10)",
        }}
      >
        {statusFacebookBackground.slice(0, itemCount).map((item) => (
          <div
            key={item.id}
            style={{
              width: "28px",
              height: "28px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={() => handleSelectStatus(item)}
          >
            <Img
              src={item.icon}
              style={{ height: "28px" }}
              className="hover:opacity-80"
            ></Img>
          </div>
        ))}

        <Dropdown
          direction="left"
          isOpen={dropdownStatusOpen}
          toggle={toggleStatusDropdown}
        >
          <DropdownToggle tag="div">
            <div
              style={{
                width: "28px",
                height: "28px",
                borderRadius: "4px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                setIsOpenStatusListCard(!isOpenStatusListCard);
              }}
            >
              <Img
                src={MORE_STATUS_ICON}
                style={{ height: "24px" }}
                className="hover:opacity-80"
              ></Img>
            </div>
          </DropdownToggle>

          <DropdownMenu style={{ width: "330px", height: "360px" }}>
            <StatusCard
              toggleStatusDropdown={toggleStatusDropdown}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
            />
          </DropdownMenu>
        </Dropdown>
      </div>
    )
  );
};

export default StatusList;
