export const NUMBER_OF_TRIAL_DAY_PROJECT = 14;

export const SUBCRIPTION_TRIAL = "trial";
export const SUBCRIPTION_MINI = "mini";
export const SUBCRIPTION_STANDARD = "standard";
export const SUBCRIPTION_BUSSINESS = "bussiness";

export const STATUS_BILL_CANCEL = 0;
export const STATUS_BILL_SEND_REQUEST = 1;
export const STATUS_BILL_FINISH = 2;
export const STATUS_BILL_CUSTOMER_CONFIRM_PAYMENT = 3;

export const MAP_STATUS_BILL_TO_TEXT = {
  [STATUS_BILL_CANCEL]: 'project_bill_cancel',
  [STATUS_BILL_SEND_REQUEST]: 'project_bill_send_request',
  [STATUS_BILL_CUSTOMER_CONFIRM_PAYMENT]: 'project_bill_wait_confirm',
  [STATUS_BILL_FINISH]: 'project_bill_finish',
}
