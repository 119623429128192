import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import IconMotitor from "../../images/icons/monitor-drive.svg";
import IconNote from "../../images/icons/note-drive.svg";

const ModalNoteDrive = (props) => {
  const { connectToDrive, modalNoteDrive, setModalNoteDrive } = props;
  return (
    <Modal
      isOpen={modalNoteDrive}
      toggle={() => setModalNoteDrive(!modalNoteDrive)}
      className="modal-md"
    >
      <ModalBody className="modal-body-lg px-4">
        <div
          className="nk-modal-title d-flex flex-row align-items-center justify-content-center "
          // style={{ display: "flex", alignItems: "center" }}
        >
          <img src={IconNote} alt="logo note" style={{ marginRight: "10px" }} />
          <h4>Lưu ý khi đăng video ngắn qua GG Drive</h4>
        </div>
        <p className="mb-3 px-4">
          Tính năng đăng bài qua file từ Google Drive giúp bạn đăng bài với{" "}
          <span style={{ fontWeight: "600" }}>TỐC ĐỘ SIÊU NHANH</span> và{" "}
          <span style={{ fontWeight: "600" }}>ĐỘ ỔN ĐỊNH CAO</span>. Tuy nhiên,
          media của bạn phải đảm bảo 2 yếu tố sau trong lưu trữ:
        </p>
        <div
          className=" d-flex flex-row align-items-start mb-3 px-4"
          // style={{ display: "flex", alignItems: "center" }}
        >
          <img
            src={IconMotitor}
            alt="logo note"
            style={{ marginRight: "10px" }}
          />
          <p>
            Media <span style={{ fontWeight: "600" }}>KHÔNG được xóa</span> trên
            Drive nếu trong lịch vẫn còn bài Chờ đăng dùng Media đó
          </p>
        </div>
        <div
          className=" d-flex flex-row align-items-start mb-5 px-4"
          // style={{ display: "flex", alignItems: "center" }}
        >
          <img
            src={IconMotitor}
            alt="logo note"
            style={{ marginRight: "10px" }}
          />
          <p>
            Media của bạn phải đúng{" "}
            <span style={{ fontWeight: "600" }}>Tỷ lệ chuẩn</span> của nền tảng
            trong trường hợp bạn đăng lên Reels, Short, Tiktok.{" "}
            <a href="#" style={{ color: "#06F", textDecoration: "underline" }}>
              Xem tỷ lệ chuẩn
            </a>
          </p>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-center">
          <Button
            onClick={() => connectToDrive()}
            style={{
              backgroundColor: "#2C4B94",
              color: "#FFFFFF",
              fontWeight: "500",
              border: "0",
            }}
          >
            Đã hiểu và tiếp tục
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalNoteDrive;
