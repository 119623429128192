import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import IconArrow from "../../../../assets/images/icon/icon-arrow.svg";

const SectionSelect = ({
  isOpen,
  setIsOpen,
  direction,
  defaultValue,
  newSection,
  options,
  handleSelect,
  placeholder,
}) => {
  const { t } = useTranslation();
  const selectRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        selectRef.current &&
        !selectRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const option = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const value = options?.find(
    (item) => item.value === (newSection ? newSection : defaultValue)
  )?.label;

  return (
    <div ref={selectRef} className={direction} style={{ minWidth: "90px" }}>
      <div
        className="dropdown-toggle drop-item"
        style={{
          padding: "0 8px 0 0",
          borderRadius: "100px",
          backgroundColor: "#fbfcfc",
        }}
        id="dropdownMenuButton"
        aria-haspopup="true"
        aria-expanded={isOpen}
        onClick={toggle}
      >
        <span style={{ fontSize: "12px", fontWeight: "400" }}>
          {value === ""
            ? t("task_management.default_section")
            : value?.length > 30
            ? value.substring(0, 29) + "... "
            : value}
        </span>
        <img
          src={IconArrow}
          alt="arrow"
          style={{ marginLeft: "6px" }}
          className={`rotate-90`}
          fill={"#000000"}
          width={12}
          height={12}
        />
      </div>
      <div
        className={`dropdown-menu${isOpen ? " show" : ""}`}
        aria-labelledby="dropdownMenuButton"
        style={{ width: "250px", marginTop: "12px" }}
      >
        <div style={{ padding: "16px" }}>
          <input
            type="text"
            className="form-control"
            style={{
              border: "1px solid #ccc",
              borderRadius: "8px",
            }}
            placeholder={placeholder}
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <div style={{ height: "200px", overflowY: "auto" }}>
          {option.length > 0 ? (
            option.map((item) => (
              <div
                className="dropdown-item d-flex items-center"
                style={{ cursor: "pointer", justifyContent: "space-between" }}
                value={item.value}
                onClick={() => handleSelect(item)}
              >
                <span className="ml-2">
                  {item.label === ""
                    ? t("task_management.default_section")
                    : item.label?.length > 30
                    ? item.label.substring(0, 29) + "... "
                    : item.label}
                </span>
              </div>
            ))
          ) : (
            <div
              className="dropdown-item"
              style={{
                height: "36px",
                padding: "10px",
                textAlign: "center",
              }}
            >
              <span style={{ fontSize: "12px", color: "#90959D" }}>
                {t("task_management.detail.no_matching_result")}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionSelect;
