import React from "react";
import { useTranslation } from "react-i18next";

import BlockDataCommonItem from "../../CommonItem/BlockDataCommonItem";
import { ChartType } from "../../constants";

const OverviewVideo = ({ data, isLoading }) => {
  const { t } = useTranslation();

  const labelX = data?.video_views?.dataSet?.map((d) => d?.x);

  const dataChartVideoViews = [
    { name: "", data: data?.video_views?.dataSet?.map((d) => d?.y) },
  ];

  const dataChartLikes = [
    { name: "", data: data?.likes?.dataSet?.map((d) => d?.y) },
  ];

  const dataChartComments = [
    { name: "", data: data?.comments?.dataSet?.map((d) => d?.y) },
  ];

  const dataChartShares = [
    { name: "", data: data?.shares?.dataSet?.map((d) => d?.y) },
  ];

  return (
    <>
      <BlockDataCommonItem
        isLoading={isLoading}
        title={t("views")}
        dataValue={data?.video_views?.value}
        hasChart={true}
        chartType={ChartType.LINE}
        labelX={labelX}
        dataChart={dataChartVideoViews}
        classIcon="ri-eye-fill"
        colorIcon="#4B38B3"
        tooltipLabel={t("general_overview.tooltip.channel.tiktok.views")}
        tooltipId={"about_views"}
      />

      <BlockDataCommonItem
        isLoading={isLoading}
        title={t("comment")}
        dataValue={data?.comments?.value}
        hasChart={true}
        chartType={ChartType.LINE}
        labelX={labelX}
        dataChart={dataChartComments}
        classIcon="ri-message-2-line"
        colorIcon="#4B38B3"
        tooltipLabel={t("general_overview.tooltip.channel.tiktok.comment")}
        tooltipId={"about_comment"}
      />

      <BlockDataCommonItem
        isLoading={isLoading}
        title={t("likes")}
        dataValue={data?.likes?.value}
        chartType={ChartType.LINE}
        labelX={labelX}
        hasChart={true}
        dataChart={dataChartLikes}
        classIcon="ri-heart-3-fill"
        colorIcon="#F06548"
        tooltipLabel={t("general_overview.tooltip.channel.tiktok.likes")}
        tooltipId={"about_likes"}
      />

      <BlockDataCommonItem
        isLoading={isLoading}
        title={t("share")}
        dataValue={data?.shares?.value}
        chartType={ChartType.LINE}
        labelX={labelX}
        hasChart={true}
        dataChart={dataChartShares}
        classIcon="ri-share-forward-fill"
        colorIcon="#F7B84B"
        tooltipLabel={t("general_overview.tooltip.channel.tiktok.share")}
        tooltipId={"about_share"}
      />
    </>
  );
};

export default OverviewVideo;
