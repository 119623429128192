import { Fragment, useEffect, useState } from "react";
import { Card, Offcanvas } from "reactstrap";

import style from "./index.module.scss";
import { Icon, Img } from "../../../components/Component";
import { getNewsFeatures } from "../../../api/overview";
import { t } from "i18next";

const NewsDiscover = ({ currentLanguage }) => {
    const [listFeature, setListFeature] = useState();
    const [loading, setLoading] = useState(false);

    const [isDown, setIsDown] = useState(false);
    const [startX, setStartX] = useState(null);
    const [scrollLeft, setScrollLeft] = useState(null);


    const fetchFeature = async (language) => {
        setLoading(true);
        try {
            const featureRes = await getNewsFeatures({ params: { location: language } });

            if (featureRes) {
                setListFeature(featureRes?.data)
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }


    const handleMouseDown = (e) => {
        setIsDown(true);
        setStartX(e.pageX);
        setScrollLeft(e.currentTarget.scrollLeft);
    };

    const handleMouseUp = () => {
        setIsDown(false);
    };

    const handleMouseMove = (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX;
        const walk = (x - startX) * 1; // Tốc độ cuộn (có thể thay đổi)
        e.currentTarget.scrollLeft = scrollLeft - walk;
    };

    useEffect(() => {
        currentLanguage && fetchFeature(currentLanguage);
    }, [currentLanguage])

    const renderItem = (item) => {
        return (
            <div className={`border rounded mt-3 overflow-hidden ${style.discoverItemBlock}`} >
                <div className={`overflow-hidden border-bottom ${style.discoverItemBlock__image}`}>
                    <Img src={item.image ?? ""} ></Img>
                    {item?.is_new !== "0" && <span className={`badge rounded-pill d-flex justify-content-center align-items-center ${style.newLabel} `}>New</span>}
                </div>

                <div className={`${style.discoverItemContent} cursor-pointer`}
                    onClick={() => { window.open(item?.link, "_blank") }}
                >
                    <p className={`${style.discoverItemContent__title}`}>{item.title ?? ""}</p>
                    <p className={`${style.discoverItemContent__description} text-truncate text-wrap`}>{item.desc ?? ""}</p>

                    {item?.is_complete && (
                        <div className="d-flex align-items-center" style={{ marginTop: "8px" }}>
                            <Icon name="check-circle-fill" style={{ color: "#27AE60" }}></Icon>
                            <span>{t("news.discover.text_item_read")}</span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
    const renderFeatureList = () => {
        return (
            listFeature?.length > 0 &&
            listFeature?.map(item => (
                renderItem(item)
            ))
        )
    }

    return (
        <Fragment>
            {listFeature?.length > 0 &&
                <Card className={`newDiscover w-100 rounded `} style={{ height: "258px" }}>
                    <div className={`${style.discoverHeadBlock}`}></div>
                    <div className={`${style.discoverContentBlock}`}>
                        <p className={`text-uppercase mb-0 ${style.discoverHeader}`}>{t('news.discover.header')}</p>
                        <div className={`${style.scollbarCustom} d-flex gap-4 h-100 flex-nowrap`}
                            style={{ overflowX: "scroll" }}
                            onMouseDown={handleMouseDown}
                            onMouseUp={handleMouseUp}
                            onMouseMove={handleMouseMove}
                        >
                            {!loading ? renderFeatureList() : <></>}
                        </div>
                    </div>
                </Card>}
        </Fragment>
    )
}

export default NewsDiscover;