import { React, useState } from "react";
import { Card } from "reactstrap";
import { Icon } from "../../../components/Component";
import style from "./index.module.scss";

const MediaItemVideo = ({ ...props }) => {

  const [openVideo, setOpenVideo] = useState(true)
  const item = props.item;
  const openMedia = () => {
    setOpenVideo(!openVideo)
    const Elmchevron = document.getElementById("chevron_openVideo");
    const Elmcontent = document.getElementById("content_openVideo");
    if (!openVideo) {
      Elmchevron.classList.add(`${style.openChevronDown}`)
      Elmchevron.classList.remove(`${style.openChevronUp}`)
      Elmcontent.classList.add(`${style.openContentDown}`)
      Elmcontent.classList.remove(`${style.openContentUp}`)
    }
    else {
      Elmchevron.classList.add(`${style.openChevronUp}`)
      Elmchevron.classList.remove(`${style.openChevronDown}`)
      Elmcontent.classList.add(`${style.openContentUp}`)
      Elmcontent.classList.remove(`${style.openContentDown}`)
    }
  }
  const removeMedia = () => {
    props.removeMedia && props.removeMedia();
  };

  const toolStyle = {
    top: 0,
    right: 0,
  };

  const mediaInfoWrapperStyle = {
    gap: "8px",
  };

  const vidStyle = {
    flex: "0 0 68px",
    width: "176px",
    height: "176px",
    borderRadius: "4px",
    objectFit: "cover",
    marginRight: "10px"
  };

  const handleChangeOption = (e, field) => {
    const defaultYoutubeOption = props.defaultYoutubeOption
    const setDefaultYouTubeOption = props.setDefaultYouTubeOption
    let newData = { ...defaultYoutubeOption }
    newData.description = e.target.value
    setDefaultYouTubeOption(newData)
  }

  return (
    <Card className="card-bordered mb-2">
      <div className="d-flex align-items-stretch border-bottom" onClick={openMedia}>
        <div
          className="flex-fill p-2 fw-medium"
          style={{ color: "#333333", fontFamily: "Roboto" }}
        >
          Video
        </div>
      </div>
      <div id="content_openVideo" style={mediaInfoWrapperStyle}>
        <div className="justify-content-between p-2" style={!openVideo ? { display: "none" } : { display: "flex" }}>
          <video src={item.playbackUrl ?? item.url} style={vidStyle} alt={item.altText} preload="metadata" />
          {
            props.channels.find(c => c.platform === 5) &&
            <div className="flex-fill">
              <div style={{ color: "rgb(39, 51, 51)", fontWeight: "600", fontSize: "13px", fontFamily: "Roboto", height: "25%" }}>
                <span>Mô tả video (Dành cho Youtube - Không bắt buộc)</span>
              </div>
              <div className="h-3/4">
                <textarea style={{ height: "100%" }} className="form-control" value={props.defaultYoutubeOption.description} placeholder="Caption" onChange={(e) => {
                  handleChangeOption(e, 'description')
                }}>
                </textarea>
              </div>
            </div>
          }
          <Icon name="trash" onClick={removeMedia} role="button"></Icon>
        </div>
      </div>
      <div id="chevron_openVideo" className={`card-tools position-absolute p-2 ${style.openMedia}`} style={toolStyle} onClick={openMedia}>
        <svg width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" /> </svg>
      </div>
    </Card>
  );
};

export default MediaItemVideo;
