import React, { useState } from "react";
import { VictoryLabel, VictoryPie, VictoryTooltip } from "victory";
import { formatNumberAcronym } from "../../../../../utils/Utils";

export default function ChartPine({ data, info }) {
  const color = data?.map((item) => {
    return item?.color;
  });

  const totalY = data?.reduce((acc, obj) => acc + obj.y, 0);

  return (
    <>
      <VictoryPie
        standalone={false}
        padAngle={2}
        width={300}
        height={300}
        data={data}
        colorScale={data ? color : ["#2C4B94", "#AEBFE7", "#D1D5DB", "#D1D5DB"]}
        innerRadius={150}
        labelRadius={100}
        style={{
          labels: { fill: "#2C4B94", fontSize: 18, fontWeight: "600" },
        }}
        labelComponent={
          <VictoryTooltip
            flyoutStyle={{ stroke: "white", strokeWidth: 0 }}
            cornerRadius={4}
            pointerLength={10}
            flyoutWidth={76}
            flyoutHeight={42}
            text={({ datum }) => `${datum.y}`}
          />
        }
      />
      <VictoryLabel
        textAnchor="middle"
        style={{ fontSize: 48, fontWeight: "bold", fill: "#2C4B94" }}
        x={150}
        y={140}
        text={totalY ? `${formatNumberAcronym(totalY)}` : 0}
      />
      <VictoryLabel
        textAnchor="middle"
        style={{ fontSize: 16, fill: "#4B5563" }}
        x={150}
        y={175}
        text={`${info ? info : ""}`}
      />
    </>
  );
}
