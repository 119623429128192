import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "../../../components/Component";
import DefaultImage from "../../images/avatar/no-image.png";

const ModalWarningSM = ({ ...props }) => {
  const { modalWarning, toggleWarning, channels } = props;
  const handleTranferSM = () => {
    toggleWarning();
    let url = process.env.PUBLIC_URL + "/posts/settingchannel-list";
    window.history.pushState("", "", url);
    window.location.reload();
  }
  return (
    <Modal size={"lg"} isOpen={modalWarning} toggle={toggleWarning}>
      <ModalHeader>
        9 Calendar: Có trang chưa được cài đặt lịch
      </ModalHeader>
      <ModalBody>
        <div className="nk-modal" style={{ height: "400px", overflowY: "auto" }}>
          <p>Để có thể đăng bài trên các kênh này, toàn bộ các trang bạn đang chọn sẽ phải được cài đặt khung giờ đăng trước khi thực hiện thao tác đăng bài. Vui lòng <a href={"#"} target={"_blank"}>cài đặt 9 Calendar</a>  cho các trang sau đây:</p>
          {
            channels.map((item, index) => {
              return (
                <div className="mb-1 text-left" key={index}>
                  <img src={item?.picture || DefaultImage} style={{ width: "2rem", marginRight: ".5rem", borderRadius: "4px" }} alt="fanpage" />
                  <span className="font-weight-bold">{item?.name || ""}</span>
                </div>
              );
            })
          }
        </div>
      </ModalBody>
      <ModalFooter>
     
            <Button size={"sm"} color={"primary"} style={{ color: "#FFFFFF", padding: "9px 28px", backgroundColor: "#58BFDA" }} onClick={toggleWarning}>
            Chọn lại Trang 
            </Button>
            <Button size={"sm"} color={"primary"} style={{ color: "#FFFFFF", padding: "9px 28px", backgroundColor: "#FD1F6D" }} onClick={handleTranferSM}>
              Đến cài đặt lịch
              </Button>
       
        
      </ModalFooter>
    </Modal>
  );
};

export default ModalWarningSM;