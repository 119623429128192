import { groupBy } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardHeader } from "reactstrap";
import ReactApexChart from "react-apexcharts";

import { SalesForecastCharts } from "../ChannelDashboard/Facebook/DashboardCrmCharts";
// import { getSalesChartsData } from "../../slices/thunks";
import { LoadingItem } from "../CommonItem/LoadingItem";
import getChartColorsArray from "../helper";
import { numberWithCommas } from "../../DashboardNew/utils/utils";

const AgeStatistic = ({ data, isYoutube, isLoading, isInstagram }) => {
  const { t } = useTranslation();
  // let color[] = [];
  const labelX = data?.map((e) => e.label);

  const handleDataChart = (data = []) => {
    const result = [];

    const agesRaw = data.map((e) => e?.ages);
    let ageArr = [];
    for (const item of agesRaw) {
      ageArr = [...ageArr, ...item];
    }
    const ageGroup = groupBy(ageArr, "x");

    for (const [key, value] of Object.entries(ageGroup)) {
      let sum = 0;
      value.map((e) => {
        sum = sum + e.y;
        // color = [...color, e.color];
      });
      result.push({
        name: key,
        data: [sum],
      });
    }
    return result;
  };

  if (isInstagram) {
    data = data?.map((d) => ({ label: d.source, y: d.value }));
  }

  const dataChart =
    isYoutube || isInstagram
      ? data?.map((e) => ({ name: e?.label, data: [e?.y] }))
      : handleDataChart(data);

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center bg-slate-50 d-flex">
          <h4
            className="card-title mb-0 flex-grow-1"
            style={{ fontSize: "16px" }}
          >
            {t("age")}
          </h4>
        </CardHeader>
        <div className="card-body ">
          <div id="countries_charts" className="apex-charts" dir="ltr">
            {isLoading ? (
              <LoadingItem />
            ) : (
              <SalesForecastCharts
                series={dataChart}
                // color={colorRes}
                dataColors='["#45CB85", "#ffbe0b", "#4b38b3", "#f06548", "#299cdb"]'
              />
            )}
          </div>
        </div>
      </Card>
      {/* </Col> */}
    </React.Fragment>
  );
};

export const AgeChartFb = ({ data, isYoutube, isLoading }) => {
  const chartColumnStackedColors = getChartColorsArray(
    '["#4b38b3", "#45CB85", "#ffbe0b", "#f3f6f9", "#fff", "#45CB85", "#ffbe0b"]'
  );
  const { t } = useTranslation();
  const handleDataChart = (data = []) => {
    const agesRaw = data.map((e) => e?.ages);
    let ageArr = [];
    for (const item of agesRaw) {
      ageArr = [...ageArr, ...item];
    }
    const ageGroup = groupBy(ageArr, "x");
    const result = [];
    for (const [key, value] of Object.entries(ageGroup)) {
      result.push({
        name: key,
        data: value.map((v) => numberWithCommas(v)),
      });
    }

    return { result, ageGroup };
  };

  const colorRes = isYoutube ? data?.map((e) => e.color) : undefined;

  // const dataChart = isYoutube
  //   ? data?.map((e) => ({ name: "", data: [e?.y] }))
  //   : handleDataChart(data).result;

  const dataChart = data?.map((e) => {
    return { name: e?.gender, data: e.ages.map((age) => age?.y) };
  });
  // const series = handleDataChart(data)
  // [
  //     {
  //         name: "PRODUCT A",
  //         data: [44, 55, 41, 67, 22, 43],
  //     },
  //     {
  //         name: "PRODUCT B",
  //         data: [13, 23, 20, 8, 13, 27],
  //     },
  //     {
  //         name: "PRODUCT C",
  //         data: [11, 17, 15, 15, 21, 14],
  //     },
  //     {
  //         name: "PRODUCT D",
  //         data: [21, 7, 25, 13, 22, 8],
  //     },
  // ];

  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: !1,
      },
      zoom: {
        enabled: !0,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: !1,
        borderRadius: 10,
      },
    },
    xaxis: {
      categories: Object.keys(handleDataChart(data).ageGroup),
      // categories: data?.map((a) => a?.gender) || [],
    },
    legend: {
      position: "right",
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
    colors: colorRes || chartColumnStackedColors,
  };
  return (
    <>
      <React.Fragment>
        <Card>
          <CardHeader className="align-items-center bg-slate-50 d-flex">
            <h4
              className="card-title mb-0 flex-grow-1"
              style={{ fontSize: "16px" }}
            >
              {t("age")}
            </h4>
          </CardHeader>
          <div className="card-body ">
            <div id="countries_charts" className="apex-charts" dir="ltr">
              {isLoading ? (
                <LoadingItem />
              ) : (
                <ReactApexChart
                  dir="ltr"
                  className="apex-charts"
                  series={dataChart || []}
                  options={options}
                  type="bar"
                  height={341}
                />
              )}
            </div>
          </div>
        </Card>
        {/* </Col> */}
      </React.Fragment>

      {/* {isLoading ? (
        <LoadingItem />
      ) : (
        
      )} */}
    </>
  );
};
export default AgeStatistic;
