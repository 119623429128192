import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getDashboardTopContent } from "../../../api/overview";
import NoImage from "../../images/avatar/no-image.png";
import IconAction from "../../images/icons/action.svg";
import NoContent from "../../images/icons/folder-empty.svg";
import { getPlatformIcon, MEDIA_TYPE_IMAGE, PLATFORM_FACEBOOK } from "../../utils/Utils";

const TopContent = (props) => {
  const { t } = useTranslation();
  const { contentType, range } = props;

  const project = useSelector((state) => state.project.project);
  const [content, setContent] = useState([]);

  useEffect(() => {
    if (project?._id) {
      const data = {
        params: {
          project_id: project?._id,
          time: `${range} days`,
        },
      };
      getDashboardTopContent(contentType, data).then((response) => {
        if (response.status) {
          setContent(response.data);
        }
        else {
          setContent([]);
        }
      });
    }
  }, [range, project?._id, contentType]);

  const renderContentItem = (item) => {
    const firstMedia = item.media?.[0];
    let thumb = NoImage;
    if (firstMedia) {
      thumb = firstMedia.type === MEDIA_TYPE_IMAGE ? firstMedia.url : firstMedia.thumb;
    }

    const createdAtFormat = moment.unix(item.created_at).format('D/M/YY H:mA');

    return (
      <li className="nk-support-v2-item" key={item.id}>
        <div className="nk-support-v2-action">
          <img src={IconAction} alt="icon action" />
          &nbsp;{item.action}
        </div>
        <img className="nk-support-v2-image" src={thumb} alt="content media" />
        <div className="nk-support-v2-content">
          <div className="d-flex">
            <img className="nk-support-v2-platform" src={getPlatformIcon(PLATFORM_FACEBOOK)} alt="platform" />
            <div className="flex-1 ml-1">
              <div className="nk-support-v2-time">{createdAtFormat}</div>
              <div className="nk-support-v2-name">{item.channel.name}</div>
            </div>
          </div>
          <div className="nk-support-v2-text">{item.text}</div>
        </div>
      </li>
    )
  };

  return (
    <>
      <div className="card-title-group align-start pb-3 g-2">
        <div className="card-title card-title-sm">
          <h6 className="title">{t(`overview.top_content.title_${contentType}`)}</h6>
        </div>
      </div>

      <ul className="nk-support-v2">
        {
          content.length > 0
            ? content.map(item => renderContentItem(item))
            : (
              <div className="nk-support-v2-no-content">
                <img src={NoContent} alt="icon no content" className="nk-support-v2-no-content-img" />
                <span className="nk-support-v2-no-content-text">{t(`overview.top_content.no_content_${contentType}`)}</span>
              </div>
            )
        }
        { }
      </ul>
    </>
  );
};
export default TopContent;
