import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Block,
    BlockHead,
    BlockTitle,
    BlockBetween,
    BlockHeadContent,
    Icon,
    PaginationCustom
} from "../../../components/Component";
import { getFrequencyStatisticByChannel, getFrequencyStatisticByUser } from '../../../api/channel';
import { Card, DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, Badge, Spinner } from "reactstrap";
import FreqError from "../../images/icons/freq_error.png";
import DefaultImage from "../../images/avatar/no-image.png";
import { useQuery } from "react-query";
import style from "./index.module.scss";

export default function ReportFrequencyByChannel(props) {
    const { t } = useTranslation();
    const { ranges } = props;
    const [rangePage, setRangePage] = useState(7);
    const [currentPage, setCurrentPage] = useState(1);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const userInfor = JSON.parse(localStorage.getItem('userInfor'));
    const itemPerPage = 10;

    const loadListFrequencyByChannel = async () => {
        return await getFrequencyStatisticByChannel({
            params: {
                page: currentPage,
                project_id: userInfor.last_project_active
            }
        });
    }
    const {
        data,
        isError,
        isLoading,
        refetch
    } = useQuery(['loadListFrequencyByChannel', currentPage], loadListFrequencyByChannel, {
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
        staleTime: Infinity
    });

    return (
        <Fragment>
            <Block>
                <div className={``} style={{ background: "white" }}>
                    <table className={`table ${style.freq_table_wrapper}`}>
                        <thead>
                            <tr>
                                <th width={"20%"} scope="col">TÀI KHOẢN KẾT NỐI</th>
                                <th width={"16%"} scope="col">BÀI ĐĂNG / NGÀY</th>
                                <th width={"16%"} scope="col">BÀI THƯỜNG</th>
                                <th width={"16%"} scope="col">THƯỚC PHIM</th>
                                <th width={"16%"} scope="col">VIDEO THƯỜNG</th>
                                <th width={"16%"} scope="col">PHÂN BỔ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                isLoading ? <tr style={{ height: "4rem" }}>
                                    <Spinner size="md" style={{ position: "absolute", top: "50%", left: "50%" }} />
                                </tr>
                                    : data?.data?.map((freqItem, index) => {
                                        const { reel, video, feed, photo } = freqItem.statistic;
                                        const { picture, name } = freqItem.channel;
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <img src={picture || DefaultImage} alt="channel avatar" className={`${style.userAvatar}`} />
                                                    {name || ""}</td>
                                                <td>{((feed || 0) + (photo || 0) + (reel || 0) + (video || 0)).toFixed(2) || ""}</td>
                                                <td>{((feed || 0) + (photo || 0)).toFixed(2) || ""}</td>
                                                {/* <td><img src={FreqError} className="img-fluid" width="16px" /></td> */}
                                                <td>{reel ? reel.toFixed(2) : ""}</td>
                                                <td>{video ? video.toFixed(2) : ""}</td>
                                                <td></td>
                                            </tr>
                                        )
                                    })
                            }
                        </tbody>
                    </table>
                    {/* <div className="mt-3 text-right">
                        <PaginationCustom
                            pageSize={itemPerPage}
                            totalCount={500}
                            siblingCount={1}
                            onPageChange={paginate}
                            currentPage={currentPage}
                        />
                    </div> */}
                </div>
            </Block>
        </Fragment>
    )
}
