import React, { useEffect } from "react";
import style from "./index.module.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import { TooltipComponent } from "../../../components/Component";
import { useDispatch } from "react-redux";
import { setCollapseSidebarLeft } from "../../reducers/layoutSlice";
import BGPostNormal from "../../images/post/bg-post-normal.png";
import BGPostShort from "../../images/post/bg-post-short.png";
const PostBulk = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCollapseSidebarLeft(true));
  }, []);

  const data = [
    {
      title: t("bulk_post_short.title"),
      colorTitle: "#D53784",
      description: t("bulk_post_short.description"),
      titleBtn: t("bulk_post_short.button_name"),
      short: true,
      standard: [
        {
          arrTitle: ["Facebook Reels", "Instagram Reels"],
          reel: "9:16",
          time: "90s",
        },
        {
          arrTitle: ["Tiktok"],
          time: "180s",
        },
        {
          arrTitle: ["Facebook Reels", "Instagram Reels"],
          reel: "9:16",
          time: "90s",
        },
      ],
      img: BGPostShort,
      standardTitle: t("bulk_post_short.title_standard"),
      link: "/posts/bulk/short",
    },
    {
      title: t("bulk_post_normal.title"),
      colorTitle: "#0B7CAF",
      description: t("bulk_post_normal.description"),
      titleBtn: t("bulk_post_normal.button_name"),
      short: false,
      standard: [
        {
          arrTitle: ["Facebook"],
          reel: "20",
          time: "10p",
        },
        {
          arrTitle: ["Instagram"],
          reel: "10",
          time: "90s",
        },
        {
          arrTitle: ["Youtube"],
          time: "∞",
        },
        {
          arrTitle: ["Pinterest"],
          reel: "5",
          time: "5p",
        },
      ],
      img: BGPostNormal,
      link: "/posts/bulk/normal",
      standardTitle: t("bulk_post_normal.title_standard"),
    },
  ];
  const StandardItem = ({ dataText, time, film, short }) => {
    return (
      <div className={style.standard}>
        <div className={style.listText}>
          {dataText?.map((text, _) => (
            <div className={style.text}>{text}</div>
          ))}
        </div>
        <div className={style.reel}>
          {film && (
            <div
              className={style.time}
              id={short ? "video" : "image"}
              style={{ cursor: "pointer" }}
            >
              {short ? (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="12"
                    height="12"
                    fill="rgba(189,189,189,1)"
                  >
                    <path d="M2 3.9934C2 3.44476 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.44495 22 3.9934V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V3.9934ZM8 5V19H16V5H8ZM4 5V7H6V5H4ZM18 5V7H20V5H18ZM4 9V11H6V9H4ZM18 9V11H20V9H18ZM4 13V15H6V13H4ZM18 13V15H20V13H18ZM4 17V19H6V17H4ZM18 17V19H20V17H18Z"></path>
                  </svg>
                  <TooltipComponent id="video" text="Tỷ lệ video" />
                </>
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="12"
                    height="12"
                    fill="rgba(189,189,189,1)"
                  >
                    <path d="M2.9918 21C2.44405 21 2 20.5551 2 20.0066V3.9934C2 3.44476 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.44495 22 3.9934V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918ZM20 15V5H4V19L14 9L20 15ZM20 17.8284L14 11.8284L6.82843 19H20V17.8284ZM8 11C6.89543 11 6 10.1046 6 9C6 7.89543 6.89543 7 8 7C9.10457 7 10 7.89543 10 9C10 10.1046 9.10457 11 8 11Z"></path>
                  </svg>
                  <TooltipComponent id="image" text="Số ảnh tối đa mỗi bài" />
                </>
              )}
              <p>{film}</p>
            </div>
          )}
          {time && (
            <div
              className={style.time}
              id={short ? "vid" : "short"}
              style={{ cursor: "pointer" }}
            >
              {short ? (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="12"
                    height="12"
                    fill="rgba(189,189,189,1)"
                  >
                    <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM13 12H17V14H11V7H13V12Z"></path>
                  </svg>
                  <TooltipComponent id="vid" text={"Độ dài tối đa video"} />
                </>
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="12"
                    height="12"
                    fill="rgba(189,189,189,1)"
                  >
                    <path d="M2 3.9934C2 3.44476 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.44495 22 3.9934V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V3.9934ZM4 5V19H20V5H4ZM10.6219 8.41459L15.5008 11.6672C15.6846 11.7897 15.7343 12.0381 15.6117 12.2219C15.5824 12.2658 15.5447 12.3035 15.5008 12.3328L10.6219 15.5854C10.4381 15.708 10.1897 15.6583 10.0672 15.4745C10.0234 15.4088 10 15.3316 10 15.2526V8.74741C10 8.52649 10.1791 8.34741 10.4 8.34741C10.479 8.34741 10.5562 8.37078 10.6219 8.41459Z"></path>
                  </svg>
                  <TooltipComponent id="short" text={"Độ dài tối đa video"} />
                </>
              )}
              <p>{time}</p>
            </div>
          )}
        </div>
      </div>
    );
  };
  const ItemBulk = ({ data }) => {
    return (
      <div className={`${style.containerBulk}`}>
        <div className={style.bulkLeft}>
          <p className={style.title} style={{ color: data.colorTitle }}>
            {data.title}
          </p>
          <p className={style.description}>{data.description}</p>
          <p className={style.standardTitle}>{data.standardTitle}</p>
          <div className={style.standardContainer}>
            {data?.standard?.map((itemStandard, _) => (
              <StandardItem
                dataText={itemStandard.arrTitle}
                time={itemStandard.time}
                film={itemStandard.reel}
                short={data?.short}
              />
            ))}
          </div>
          <button
            className={style.btn}
            style={{ backgroundColor: data.colorTitle }}
            onClick={() => {
              history.push(data.link);
            }}
          >
            {data.titleBtn}
          </button>
        </div>
        {/* <div className={style.bulkRight}> */}
          <img
            src={data?.img}
            alt=""
            className={style.img}
            //  width="190" height="357"
          />
        {/* </div> */}
      </div>
    );
  };

  return (
    <div
      style={{ marginTop: "72px", backgroundColor: "#F8f7fc" }}
      className={style.bulkPage}
    >
      {data?.map((bulkItem, _) => (
        <ItemBulk data={bulkItem} />
      ))}
    </div>
  );
};

export default PostBulk;
