import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Spinner } from "reactstrap";
import { listTask } from "../../../../api/kanban";
import KanbanCard from "./KanbanCard";
import { LoadingItem } from "../../Dashboard_v2/CommonItem/LoadingItem";

const KanbanCardList = ({
  initialData,
  setInitialData,
  tasks,
  column,
  activeGroup,
  isLoadingTasks,
  type,
  orderBy,
  sort,
  staffs,
  isComplete,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));

  const loading = useRef(false);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(2);

  useEffect(() => {
    if (tasks?.length >= 20) {
      setHasMore(true);
    }
  }, []);

  const loadTask = () => {
    if (loading.current) return;
    loading.current = true;

    listTask(
      userInfo.last_project_active,
      activeGroup._id,
      column._id,
      page,
      type,
      orderBy,
      sort
    )
      .then((response) => {
        const newTasksArray = response.data.tasks;

        if (newTasksArray.length < 20) {
          setHasMore(false);
        }

        setInitialData((prevData) => {
          const updatedTasks = { ...prevData.tasks };
          const updatedColumns = { ...prevData.columns };

          newTasksArray.forEach((task) => {
            updatedTasks[task._id] = task;
            if (updatedColumns[column._id]) {
              updatedColumns[column._id].tasks.push(task._id);
            }
          });

          return {
            ...prevData,
            tasks: updatedTasks,
            columns: updatedColumns,
          };
        });

        setPage((prevPage) => prevPage + 1);
      })
      .catch(() => {})
      .finally(() => {
        loading.current = false;
      });
  };

  const infiniteScrollLoader = (
    <div style={{ textAlign: "center", marginTop: "10%" }}>
      <Spinner color="primary" />
    </div>
  );

  return (
    <>
      {isLoadingTasks ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingItem />
        </div>
      ) : (
        <InfiniteScroll
          loadMore={loadTask}
          hasMore={hasMore}
          loader={tasks?.length > 0 && infiniteScrollLoader}
          useWindow={false}
        >
          {tasks?.length > 0 ? (
            tasks?.map((task, index) => {
              const card = initialData.tasks[task];
              return (
                <KanbanCard
                  key={card._id}
                  index={index}
                  card={card}
                  setInitialData={setInitialData}
                  initialData={initialData}
                  staffs={staffs}
                  activeGroup={activeGroup}
                  isComplete={isComplete}
                />
              );
            })
          ) : (
            <div></div>
          )}
        </InfiniteScroll>
      )}
    </>
  );
};

export default KanbanCardList;
