import { Input, Label } from "reactstrap";
import { RiCalendarEventFill, RiPencilFill } from "@remixicon/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

const ScheduleSetting = ({
  formData,
  setFormData,
  setIsOpenSetting,
}) => {
  const { t } = useTranslation();
  const [whenPostType, setWhenPostType] = useState("now");
  const setPostTime = (time) => {
    setFormData({ ...formData, postTime: time });
  };
  const setPostTimeType = (type) => {
    setPostTime(type);
    setIsOpenSetting(type === "schedule");
    setWhenPostType(type);
  };
  useEffect(() => {

  }, [whenPostType]);

  return (
    <div className="rounded mb-5 p-2">
      <div className="d-flex justify-content-between gap-3">
        <div className="d-flex align-items-center">
          <div
            className="d-flex justify-content-center align-items-center rounded position-relative"
            style={{
              background: "rgba(243,246,249,1)",
              width: "36px",
              height: "36px",
            }}
          >
            <RiCalendarEventFill
              size={16}
              className="text-info"
            ></RiCalendarEventFill>
          </div>
          <p className="fs-13 ms-2 mb-0" style={{ color: "#495057" }}>
            {t('store_post.schedule.title')}
          </p>
        </div>
      </div>

      <div
        className="d-flex justify-content-start gap-4 mt-3 mb-3"
        style={{ marginLeft: "44px" }}
      >
        <p
          className="d-flex text-body fs-13 mb-0"
          style={{ fontWeight: "400" }}
        >
          {t('store_post.schedule.when.title')}
        </p>
        <div className="form-check mb-2">
          <Input
            className="form-check-input"
            onChange={() => setPostTimeType("now")}
            checked={formData.postTime === "now"}
            type="radio"
            name="flexRadioDefault"
            id="flexRadioSchedule1"
            defaultChecked
          />
          <Label
            className="form-check-label text-nowrap fs-13"
            for="flexRadioSchedule1"
          >
            {t('store_post.schedule.when.now')}
          </Label>
        </div>
        <div className="form-check">
          <Input
            className="form-check-input"
            checked={formData.postTime === "schedule"}
            onChange={() => {
              setPostTimeType("schedule");
            }}
            type="radio"
            name="flexRadioDefault"
            id="flexRadioSchedule2"
          />
          <Label
            className="form-check-label text-nowrap fs-13"
            for="flexRadioSchedule2"
          >
            {t('store_post.schedule.when.schedule.title')}
          </Label>
        </div>
        <div className="form-check">
          <Input
            className="form-check-input"
            checked={formData.postTime === "auto"}
            onChange={() => setPostTimeType("auto")}
            type="radio"
            name="flexRadioDefault"
            id="flexRadioSchedule3"
          />
          <Label
            className="form-check-label text-nowrap fs-13"
            for="flexRadioSchedule3"
          >
            {t('store_post.schedule.when.queue.title')}
          </Label>
        </div>

      </div>
      {
        whenPostType === "auto" && (<div
          className="p-1 w-50 rounded"
          style={{ backgroundColor: "#fef7cd", color: "#b68103", marginLeft: "160px" }}
        >
          <p className="fs-12">
            <span className="font-weight-bold">{t('store_post.schedule.when.queue.warning.first_line')}</span>{" "}
            {t('store_post.schedule.when.queue.warning.second_line')}
            <Link target="_blank" rel="noopener noreferrer" to={`${process.env.PUBLIC_URL}/posts/settingchannel-list`} className="link mx-1">
              {t('store_post.schedule.when.queue.warning.third_line')}
            </Link>
          </p>
        </div>)
      }
      {formData.postTime === "schedule" && (
        <div className="w-100 border-top border-1">
          <div
            className="d-flex align-items-center rounded my-3 p-2 gap-2"
            style={{
              width: "fit-content",
              marginLeft: "150px",
              backgroundColor: "rgba(244,246,250,1)",
            }}
            onClick={() => setIsOpenSetting(true)}
          >
            <div style={{ width: "fit-content", marginRight: "32px" }}>
              <p
                className="fs-13 text-body mb-1 fw-medium"
                style={{ marginBottom: "0px" }}
              >
                {t('store_post.schedule.when.schedule.warning.publishing_date')}
              </p>
              {formData.type === "same" && (
                <p
                  className="fs-13 text-body mb-0"
                  style={{ fontWeight: "400" }}
                >
                  {formData.date} {formData.time}
                </p>
              )}
              {formData.type === "random" && (
                <>
                  <p
                    className="fs-13 text-body mb-0"
                    style={{ fontWeight: "400" }}
                  >
                    {formData.date}
                  </p>
                  <p
                    className="fs-13 text-body mb-0"
                    style={{ fontWeight: "400" }}
                  >
                    {t('store_post.schedule.when.schedule.option.random_time.from')} {formData.timeStart} {t('store_post.schedule.when.schedule.option.random_time.to')} {formData.timeEnd}

                  </p>
                </>
              )}
              {formData.type === "custom" && (
                <p
                  className="fs-13 text-body mb-0"
                  style={{ fontWeight: "400" }}
                >
                  {t('store_post.schedule.when.schedule.warning.custom_publishing_time')}
                </p>
              )}
            </div>
            <RiPencilFill
              size={16}
              className="cursor-pointer"
            ></RiPencilFill>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScheduleSetting;
