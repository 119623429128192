import { Button, Progress } from "reactstrap";
import styles from "./index.module.scss"
import { useState, useEffect } from "react";
import { getNewsFeatures } from "../../../api/overview";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading, setData } from "../../reducers/guildebookSlice";

const ExperGuild = ({ currentLanguage }) => {
    const [isopen, setIsopen] = useState(false);
    const [progress, setProgress] = useState(0);
    const dispatch = useDispatch();
    const featureData = useSelector((state) => state.guilde);

    const fetchFeature = async (language) => {
        dispatch(setIsLoading(true));
        const newdata = {
            params: {
                is_get_started: 1,
                location: language
            }
        }
        try {
            const res = await getNewsFeatures(newdata);
            if (res?.status) {
                dispatch(setData(res.data))
                dispatch(setIsLoading(false));

            } else {
                dispatch(setIsLoading(false));
            }
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    }

    const renderGuildeItem = (item) => {
        return (
            <>
                <div className={`${styles.contentItem} cursor-pointer`}
                    onClick={() => { openNewLink(item.link) }}
                    key={`guide${item?.id}`}
                >
                    <div className={`${styles.itemHeader} d-flex align-items-center`}>

                        {item?.is_complete ? (
                            <em class="icon ni ni-check-circle-fill"></em>
                        ) : (<em class="icon ni ni-check-circle"></em>)}
                        <p style={{ color: item?.is_complete ? "rgb(171,157,174)" : "rgba(46, 11, 52, 1)" }}>{item?.title} </p>
                    </div>
                    <p className={`${styles.headerDesc}`}>{item?.desc}</p>
                </div>
            </>
        )
    }

    const openNewLink = (link) => {
        window.open(link, "_blank")
    }

    const getProgresValue = () => {
        let value = 0;
        if (featureData?.data && featureData.data.length > 0) {
            featureData?.data.map((item) => {
                if (item.is_complete === true) {
                    value++;
                }
            })

            if (value > 0) {
                return value / featureData.data.length * 100;
            } else return 100;
        } else return 0;

    }


    useEffect(() => {
        currentLanguage && fetchFeature(currentLanguage);
    }, [currentLanguage])

    useEffect(() => {
        let value = 0;
        if (featureData?.data && featureData.data.length > 0) {
            featureData?.data.map((item) => {
                if (item.is_complete === false) {
                    value++;
                }
            })
            if (value > 0) {
                setProgress(value)
            }
        }
    }, [featureData.data])
    return (
        <>
            {featureData.data &&
                progress > 0 &&
                (<>
                    <div className={`${styles.demoButon} d-flex gap-1 justify-content-center align-items-center position-fixed cursor-pointer`}
                        onClick={() => { setIsopen(!isopen) }}
                    >
                        {isopen ? (
                            <>
                                <em style={{ fontSize: "24px" }} class="icon ni ni-cross"></em>
                            </>
                        ) : (
                            <>
                                <em class="icon ni ni-user-check"></em>
                                <p>Bắt đầu trải nghiệm</p>
                            </>
                        )}

                    </div>
                    {progress > 0 && !isopen && (
                        <span className={`position-fixed ${styles.unreadGuilde}`}>{progress}</span>
                    )}
                </>)
            }
            {isopen && (<>
                <div className={`${styles.contentBlock}`}>
                    <div className={`${styles.contentHeader}`}>
                        <p>Hướng dẫn trải nghiệm</p>
                        <Progress
                            className="my-1"
                            value={getProgresValue()?.toString()}
                            color="success"
                            style={{ height: "10px", borderRadius: "8px" }}
                        >
                            {getProgresValue()}%
                        </Progress>
                    </div>
                    <div className={`${styles.contentDetail}`}>
                        {featureData?.data && featureData.data.length > 0 ? (
                            <>
                                {featureData?.data.map((item) => {
                                    return (
                                        <>
                                            {renderGuildeItem(item)}
                                        </>
                                    )
                                })}
                            </>
                        ) : (
                            <>
                                <h3 className="d-flex justify-content-center align-items-center mt-3">Không có hướng dẫn nào!</h3>
                            </>
                        )}
                    </div>
                </div>
            </>)}

        </>
    )
}
export default ExperGuild;