import { Card, CardBody } from "reactstrap";
import { LoadingItem } from "../../CommonItem/LoadingItem";

import NONE_AVT from "../Image/no-image.jpg";
import { useEffect, useRef, useState } from "react";
import { renderNameField } from "../../helper";
import { Img } from "../../../../../components/Component";

export const CartContent = ({ data, isLoading }) => {
  const postItem = ({ value, backgroundColor, classIcon, colorIcon }) => {
    return (
      <div
        className="d-flex align-items-center"
        style={{ width: "30%", height: "35px" }}
      >
        <div
          style={{
            width: "24px",
            height: "24px",
            background: backgroundColor,
            borderRadius: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <i className={classIcon} style={{ color: colorIcon }}></i>
        </div>
        <div style={{ paddingLeft: "5px" }}>{value || 0}</div>
      </div>
    );
  };

  const elementRef = useRef(null);
  const [isResponsive, setIsResponsive] = useState(false);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width } = entry.contentRect;
        // Perform actions based on the size changes

        if (width < 410) {
          setIsResponsive(true);
          console.log("Element is responsive!: ", width);
        } else {
          setIsResponsive(false);
        }
      }
    });

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div style={{ height: "440px" }}>
      <Card>
        {isLoading ? (
          <LoadingItem />
        ) : (
          <>
            <div
              ref={elementRef}
              className="d-flex justify-content-center"
              style={{ backgroundColor: "#000000" }}
            >
              <Img
                style={{
                  maxHeight: "200px",
                  minHeight: "150px",
                  minWidth: "200px",
                  objectFit: "contain",
                }}
                className="card-img-top img-fluid"
                src={data?.media?.thumb || NONE_AVT}
                alt="Card cap"
              />
            </div>
            <CardBody>
              <>
                <p
                  className="card-text mb-0"
                  style={{
                    fontSize: "14px",
                    lineHeight: "22px",
                    color: "#333333",
                    marginTop: "8px",
                    maxHeight: "40px",
                    minHeight: "40px",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: "2",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {data?.content || "(No Content)"}
                </p>
                <div
                  className="d-flex justify-content-between"
                  style={{ marginTop: "10px" }}
                >

                  {
                    data?.statistic_first_row?.map(item => {
                      return postItem(item)
                    })
                  }

                </div>
                <div className="d-flex justify-content-between">

                  {
                    data?.statistic_second_row?.map(item => {
                      return postItem(item)
                    })
                  }
                </div>
              </>
            </CardBody>
            <div className="card-footer d-flex justify-content-between border-top  bg-white  align-items-center">
              <div className="d-flex align-items-center w-70">
                <Img
                  className="rounded avatar-xxs"
                  width={24}
                  height={24}
                  src={data?.channel?.picture}
                />
                <p
                  style={{
                    marginLeft: "5px",
                    marginBottom: "0",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    display: "block",
                    textOverflow: "ellipsis"
                  }}
                >
                  {data?.channel?.name}
                </p>
              </div>
              <p>{data?.date}</p>
            </div>
          </>
        )}
      </Card>
    </div >
  );
};
