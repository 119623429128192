import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import {
  Card,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
} from "../../../components/Component";
import { useTranslation } from "react-i18next";
import { PasswordFieldAuth } from "../../../commons/TextField/TextFieldAuth";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { changePassword } from "../../../api/user";

const UserProfileSettingPage = ({ sm, updateSm }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [modalForm, setModalForm] = useState(false);
  const toggleForm = () => setModalForm(!modalForm);
  const [modalSuccess, setModelSuccess] = useState(false);
  const toggleSuccess = () => setModelSuccess(!modalSuccess);

  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("validate_field_password_required"))
      .min(6, t("validate_field_password_character_required", { length: 6 })),
    passwordConfirmation: Yup.string()
      .required(t("validate_field_re_password_required"))
      .oneOf(
        [Yup.ref("password")],
        t("validate_field_password_confirm_not_match")
      ),
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const onFormSubmit = async (formData) => {
    setLoading(true);
    formData.email = localStorage.getItem("emailToChangePassword");
    localStorage.removeItem("userInfor");
    changePassword(formData)
      .then((res) => {
        if (res.status) {
          setModelSuccess(true);
          setModalForm(false);
          localStorage.setItem("userInfor", JSON.stringify(res.data));
          localStorage.setItem("accessToken", res.data.access_token);
        } else {
          toast.error(res.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>

      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Security Settings</BlockTitle>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${
                sm ? "active" : ""
              }`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <Card className="card-bordered">
          <div className="card-inner-group">
            <div className="card-inner">
              <div className="between-center flex-wrap g-3">
                <div className="nk-block-text">
                  <h6>Change Password</h6>
                  <p>Set a unique password to protect your account.</p>
                </div>
                <div className="nk-block-actions flex-shrink-sm-0">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                    <li className="order-md-last">
                      <Button color="primary" onClick={toggleForm}>
                        Change Password
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="between-center flex-wrap flex-md-nowrap g-3">
                <div className="nk-block-text">
                  <h6>
                    2 Factor Auth &nbsp;{" "}
                    <span className="badge badge-success ml-0">
                      {t("development")}
                    </span>
                  </h6>
                  <p>
                    Secure your account with 2FA security. When it is activated
                    you will need to enter not only your password, but also a
                    special code using app. You will receive this code via
                    mobile application.{" "}
                  </p>
                </div>
                {/*<div className="nk-block-actions">*/}
                {/*  <Button color="primary">Disable</Button>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </Card>
      </Block>
      <ToastContainer />
      <Modal isOpen={modalForm} toggle={toggleForm}>
        <ModalHeader
          toggle={toggleForm}
          close={
            <button className="close" onClick={toggleForm}>
              <Icon name="cross" />
            </button>
          }
        >
          {t("reset_password_title")}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <FormGroup>
              <PasswordFieldAuth
                field={"password"}
                current={watch("password")}
                register={register}
                setValue={setValue}
                required={true}
                errors={errors.password}
                title={t("new_password")}
                minLength={4}
                placeholder={t("auth_register_password_placeholder")}
              />
            </FormGroup>
            <FormGroup>
              <PasswordFieldAuth
                field={"passwordConfirmation"}
                current={watch("passwordConfirmation")}
                register={register}
                setValue={setValue}
                required={true}
                errors={errors.passwordConfirmation}
                title={t("auth_register_password_confirm")}
                placeholder={t("auth_register_password_confirm_placeholder")}
              />
            </FormGroup>
            <FormGroup>
              <Button color="primary" type="submit" size="lg">
                {loading ? <Spinner size="sm" color="light" /> : t("update")}
              </Button>
            </FormGroup>
          </form>
        </ModalBody>
      </Modal>

      <Modal isOpen={modalSuccess} toggle={toggleSuccess}>
        <ModalBody className="modal-body-lg text-center">
          <div className="nk-modal">
            <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-check bg-success"></Icon>
            <h4 className="nk-modal-title">
              {t("successful_change_password")} !
            </h4>
            <div className="nk-modal-text">
              <div className="caption-text">
                {t("continue_with_new_password")}
              </div>
              {/* <span className="sub-text-sm">
                Learn when you reciveve bitcoin in your wallet.{" "}
                <a href="#link" onClick={(ev) => ev.preventDefault()}>
                  {" "}
                  Click here
                </a>
              </span> */}
            </div>
            <div className="nk-modal-action">
              <Button
                color="primary"
                size="lg"
                className="btn-mw"
                onClick={toggleSuccess}
              >
                {t("continue")}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default UserProfileSettingPage;
