import { createSlice } from "@reduxjs/toolkit";
import { uniqBy } from "lodash";
import moment from "moment";

const initialState = {
  conversationId: null,
  chatList: [],
  chatPage: 1,
  chatLoaded: false,
  chatCreate: {
    text: "",
  },
  isSendingChat: false,
  channelId: null,
  commentList: [],
  commentPage: 1,
  commentLoaded: false,
  replyTo: null,
  focusCreator: 0,
  commentCreate: {
    text: "",
  },
  isSendingComment: false,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setConversationId(state, action) {
      state.conversationId = action.payload;
    },
    appendChatList(state, action) {
      const merged = uniqBy([...state.chatList, ...action.payload], "_id").sort((a, b) => {
        return moment(a.created_at).diff(b.created_at, true);
      });
      state.chatList = merged;
    },
    reset: () => initialState,
    incrementChatPage(state) {
      state.chatPage = state.chatPage + 1;
    },
    setChatLoaded(state, action) {
      state.chatLoaded = action.payload;
    },
    onReceiveWSChat(state, action) {
      const newChat = action.payload;
      if (newChat.conversation_id === state.conversationId) {
        state.chatList = [...state.chatList, newChat];
      }
    },
    setChatCreateText(state, action) {
      state.chatCreate = {
        ...state.chatCreate,
        text: action.payload,
      };
    },
    onStartSendingChat(state, action) {
      state.chatCreate.text = "";
      state.isSendingChat = true;
      state.chatList.push(action.payload);
    },
    onSendingChatSuccess(state, action) {
      const _chatListRemoveFake = state.chatList.filter((item) => item._id !== "fake");
      const merged = uniqBy([..._chatListRemoveFake, ...action.payload], "_id").sort((a, b) => {
        return moment(a.created_at).diff(b.created_at, true);
      });
      state.chatList = merged;
      state.isSendingChat = false;
    },
    onSendingChatFailure(state, action) {
      state.chatList = state.chatList.filter((item) => item._id !== "fake");
      state.isSendingChat = false;
    },
    setContent(state, action) {
      state.content = action.payload;
    },
    appendCommentList(state, action) {
      const merged = uniqBy([...state.commentList, ...action.payload], "_id").sort((a, b) => {
        return moment(a.created_at).diff(b.created_at, true);
      });
      state.commentList = merged;
    },
    updateCommentList(state, action) {
      state.commentList = action.payload;
    },
    incrementCommentPage(state) {
      state.commentPage = state.commentPage + 1;
    },
    setCommentLoaded(state, action) {
      state.commentLoaded = action.payload;
    },
    setReplyTo(state, action) {
      state.replyTo = action.payload;
    },
    setFocusCreator(state) {
      state.focusCreator = state.focusCreator + 1;
    },
    setCommentCreateText(state, action) {
      state.commentCreate = {
        ...state.commentCreate,
        text: action.payload,
      };
    },
    onStartSendingComment(state, action) {
      state.commentList = state.commentList.map((comment) => {
        if (comment.social_network_id === state.replyTo) {
          comment.children = comment.children || [];
          comment.children.push(action.payload);
        }
        return comment;
      });
      state.commentCreate.text = "";
    },
    onSendingCommentSuccess(state, action) {
      state.commentList = state.commentList.map((comment) => {
        if (comment._id === action.payload._id) {
          return action.payload;
        }
        return comment;
      });
      state.isSendingComment = false;
    },
    onSendingCommentFailure(state, action) {
      state.commentList = state.commentList.map((comment) => {
        if (comment.social_network_id === state.replyTo) {
          comment.children = comment.children.filter((reply) => reply.id !== "fake");
        }
        return comment;
      });
      state.isSendingComment = false;
    },
  },
});

export const {
  setConversationId,
  appendChatList,
  updateCommentList,
  reset,
  incrementChatPage,
  setChatLoaded,
  onReceiveWSChat,
  setChatCreateText,
  onStartSendingChat,
  onSendingChatSuccess,
  onSendingChatFailure,
  setContent,
  appendCommentList,
  incrementCommentPage,
  setCommentLoaded,
  setReplyTo,
  setFocusCreator,
  setCommentCreateText,
  onStartSendingComment,
  onSendingCommentSuccess,
  onSendingCommentFailure,
} = chatSlice.actions;

export default chatSlice.reducer;
