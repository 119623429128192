import React from "react";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";
import style from "../../../index.module.scss";
import { Icon } from "../../../../../../components/Component";
import ChartCircle from "../../ChartCircle";
import { formatNumberAcronym } from "../../../../../../utils/Utils";

const OverviewUser = ({ data }) => {
  return (
    <div
      className="d-flex flex-column flex-md-row w-100"
      style={{ gap: "16px" }}
    >
      <div className="d-flex flex-row  w-100" style={{ gap: "16px" }}>
        <div className="w-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Lượt thích Trang
              </CardTitle>
              <CardText className={`${style.titleCard}`}>
                {data && formatNumberAcronym(data?.page_fans?.value)}
              </CardText>
            </CardBody>
          </Card>
        </div>
        <div className="w-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Lượt thích mới
              </CardTitle>
              <div className="d-flex flex-row align-items-center">
                <div className={`${style.titleCard}`}>
                  {data && formatNumberAcronym(data?.page_fan_adds?.value)}
                </div>
                {data?.page_fan_adds?.percent_change > 0 ? (
                  <div className="ml-1" style={{ color: "#10B981" }}>
                    {" "}
                    <Icon name={"arrow-up"}></Icon>{" "}
                    {data ? data?.page_fan_adds?.percent_change : 0}%
                  </div>
                ) : (
                  <div className="ml-1" style={{ color: "#e85347" }}>
                    {" "}
                    <Icon name={"arrow-down"}></Icon>{" "}
                    {data ? data?.page_fan_adds?.percent_change : 0}%
                  </div>
                )}
              </div>
              <CardText className={`${style.detailCard} mt-1`}>{`Trung bình: ${
                data && data?.page_fan_adds?.avg_day
              }/ngày`}</CardText>
            </CardBody>
          </Card>
        </div>
      </div>
      <div
        className="d-flex flex-column flex-md-row w-100"
        style={{ gap: "16px" }}
      >
        <div className="w-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Tương tác bài đăng
              </CardTitle>
              <div className="d-flex flex-row align-items-center">
                <div className={`${style.titleCard}`}>
                  {data &&
                    formatNumberAcronym(data?.page_post_engagements?.value)}
                </div>
              </div>
              <CardText className={`${style.detailCard} mt-1`}>{`Trung bình: ${
                data && data?.page_post_engagements?.avg_day
              }/ngày`}</CardText>
            </CardBody>
          </Card>
        </div>
        <div className="w-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Phân loại
              </CardTitle>
              <ChartCircle data={data?.rate_engage} />
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default OverviewUser;
