import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Container, Modal, ModalBody } from "reactstrap";
import {
  Block,
  Button,
  Col,
  Row,
  Icon,
  Img
} from "../../../components/Component";

import Rectangle from "../../images/icons/Rectangle.svg"
import ImgPluginHeader from "../../images/icons/ImgPlugin-header.svg"
import imgExtension from "../../images/icons/imgExtension.svg"
import imgReup from "../../images/icons/imgReup.svg"
import puzzle_piece_plugin from "../../images/icons/puzzle-piece-plugin.svg"
import BgSinginPlugin from "../../images/icons/Nền_plugin.svg"
import imgScanReup from "../../images/icons/imgCenter-scanReup.png"
import imgDisplay3 from "../../images/icons/imgDisplay3.png"
import imgDisplay2 from "../../images/icons/imgDisplay2.png"
import imgDisplay4 from "../../images/icons/imgDisplay4.png"

import imgMapPlugin from "../../images/icons/mapScan.png"

import IconScan from "../../images/icons/IconScan.png"
import IconSeending from "../../images/icons/IconSeending.png"
import IconSo9 from "../../images/icons/IconSo9.png"
import IconChienThuat from "../../images/icons/IconChienThuat.png"
import IconReup from "../../images/icons/IconReup.png"
import IconCarousel from "../../images/icons/IconCarousel.png"
// import TrafficDougnut from "../../../components/partials/analytics/traffic-dougnut/TrafficDoughnut";
import { useSelector } from "react-redux";
import style from "./index.module.scss";
import Slider from "react-slick";
import "./slick.css";
import "./slick-theme.css";
import { useEffect } from 'react';
import {CreateSignin} from "../../../api/auth"
import FooterTrial from '../Overview/FooterTrial';
export default function Index() {
  const { t } = useTranslation();
  const [isOpenImg, setIsOpen] = useState(false)
  const [imgZoom, setImgZoom] = useState({})
  const userInformation = JSON.parse(localStorage.getItem("userInfor"));
  // check connect channel
  var settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 2,
    adaptiveHeight: true,
    centerMode: true,
    autoplay: true,
    swipe: true,
    swipeToSlide: true,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ],
  };
  const Feature = [
    {
      id: "facebook",
      name: "Quét & Sao chép nội dung",
      content: "Lựa chọn nội dung bất kỳ trên Facebook, Tiktok & sao chép chỉ với 1 click.",
      image: IconScan,
    },
    {
      id: "tiktok",
      name: "Reup & Hẹn giờ đăng tức thời",
      content: "Chọn đăng lại các bài đã sao chép lên hàng trăm kênh khác nhau, có thể hẹn giờ để tự động đăng khi tới thời điểm thích hợp",
      image: IconReup,
    },
    {
      id: "zalo",
      name: "Seeding Bình luận, Bài đăng lên group",
      content: "Sao chép Bài đăng, Bình luận và đăng lại trên group của mình dưới nhiều tài khoản khác nhau.",
      image: IconSeending,
    },
  ];
  const Feature1 = [
    {
      id: "tiktok1",
      name: "Sử dụng dễ dàng, linh hoạt",
      content: "Giao diện thân thiện, dễ dàng sử dụng. Tối ưu thao tác trên cả máy tính và điện thoại.",
      image: IconSo9,
    },
    {
      id: "zalo2",
      name: "Chiến thuật đánh bại đối thủ",
      content: "Sao chép - Tìm điểm yếu để có chiến lược đánh bại đối thủ dễ dàng.",
      image: IconChienThuat,
    },
  ];
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    const initialSignin = {
      name: userInformation.name ?? "",
      phone: userInformation.phone ?? "",
      demand: ""
    }
    reset(initialSignin)
  }, [])
  const handleFormSubmit = (frmData) => {
    const initialSignin = {
      name: userInformation.name ?? "",
      phone: userInformation.phone ?? "",
      demand: ""
    }
    console.log("submit");
    const data = {
      "name": frmData["name"],
      "phone": frmData["phone"],
      "note": frmData["demand"],
    }
    CreateSignin(data).then(res => {
      if (res.status) {
        reset(initialSignin);
        toast.success("Đăng kí thành công!", {
          autoClose: 1000,
          position: "top-right",
        });
      }
      else {
        toast.error("Đăng kí thất bại!", {
          autoClose: 1000,
          position: "top-right",
        });
        console.log("error");
      }
    }).catch(error => {
      toast.error("Tạo đơn thất bại!", {
        autoClose: 1000,
        position: "top-right",
      });
      console.log(error);
    });
  }
  const onFormCancel = () => {
    setIsOpen(false);
  }
  const OnClickZoom = (img, isImg) => {
    setImgZoom({ "img": img, "isImg": isImg })
    setIsOpen(true)
  }
  return (
    <React.Fragment>
      <Block className="nk-block-middle">
        <Container fluid className="p-0 m-0">
          <div style={{ height: "69px" }}></div>
          <div className={style.plugin}>
            <div className={`${style.plugin__header} d-flex justify-between`}>
              <div className={`${style.plugin__header_content}`}>

                <h4 className={style.content__title}>9Extension</h4>
                <p className={style.content__subtitle}>
                  Chỉ với <span >nửa cốc trà đá</span> mỗi ngày giúp bạn
                  <span> tiết kiệm 90% thời gian</span> nuôi kênh
                  (<u onClick={() => window.location.replace(process.env.PUBLIC_URL + "/admin/project/pricing")}>Bảng giá</u>)
                </p>
                <Button className={`${style.content__button}`} onClick={() => { window.open("https://chrome.google.com/webstore/detail/so9-extension/aamahdfkcpdbaoggdmebmmdpjmjglddp?hl=en&authuser=5") }}>
                  <Img src={puzzle_piece_plugin} style={{ marginRight: "12px" }}/>
                  Cài đặt tại đây</Button>
              </div>
              <div className={`${style.plugin__header_image}`}>
                <Img src={ImgPluginHeader} />
              </div>
            </div>
            <div className={`${style.plugin__outstanding}`}>
              <div className="text-center">
                <Img src={Rectangle}/>
                <h4 className={`${style.outstanding__title}`}>Nổi bật!</h4>
              </div>
              <Row>
                {
                  Feature.map((item, index) => {
                    return <Col md="4" key={index}>
                      <div key={index}>
                        <div className=" d-flex justify-content-center mt-5">
                          <Img src={item.image} alt={item.name} style={{ width: "71px", height: "71px" }} />
                          <div style={{ marginLeft: "16px" }}>
                            <h6 className={style.Card__title}>{item.name}</h6>
                            <p className={style.Card__content}>{item.content}</p>
                          </div>

                        </div>

                      </div>
                    </Col>
                  })
                }
              </Row>
              <Row className="justify-center">

                {
                  Feature1.map((item, index) => {
                    return <Col md="4" key={index}>
                      <div key={index}>
                        <div className=" d-flex justify-content-center mt-5">
                          <Img src={item.image} alt={item.name} style={{ width: "71px", height: "71px" }} />
                          <div style={{ marginLeft: "16px" }}>
                            <h6 className={style.Card__title}>{item.name}</h6>
                            <p className={style.Card__content}>{item.content}</p>
                          </div>

                        </div>

                      </div>
                    </Col>
                  })
                }
              </Row>
            </div>
            <div className={`${style.plugin__ScanReup}`}>
              <h4 className={style.ScanReup__title}>Hướng dẫn cách Quét & Reup bài bằng 9Reup</h4>
              <p className={style.ScanReup__subtitle}>Chỉ với 30 giây thực hiện</p>
              <Row>
                <Col md="4" className="text-white">

                  <Img className="tb-image mb-2" src={imgExtension} alt="channel" />
                  <p className={`${style.ScanReup__text}`}>CÀI ĐẶT EXTENSION</p>
                  <p className={`${style.ScanReup__content}`}>
                    Cài đặt nhanh ứng dụng 9Reup của chúng tôi, hỗ trợ trên Chrome.
                    Cực kì đơn giản và nhanh chóng
                  </p>
                  <Button className={`${style.ScanReup__button}`} onClick={() => { window.open("https://chrome.google.com/webstore/detail/so9-extension/aamahdfkcpdbaoggdmebmmdpjmjglddp?hl=vi") }}>
                    ĐĂNG KÝ VÀ CÀI ĐẶT MIỄN PHÍ</Button>

                </Col>
                <Col md="4">
                  <Img src={imgScanReup} />
                </Col>
                <Col md="4" className="text-white">
                  <Img className="tb-image mb-2" src={imgReup} alt="channel" />
                  <p className={`${style.ScanReup__text}`}>BẮT ĐẦU REUP</p>
                  <p className={`${style.ScanReup__content}`}>Tất cả các bài đăng Facebook & Tiktok đều có thể sao chép và đăng lại trên các kênh của bạn chỉ với 1 Click</p>
                  <Button className={`${style.ScanReup__button}`} onClick={() => { window.open("https://chrome.google.com/webstore/detail/so9-extension/aamahdfkcpdbaoggdmebmmdpjmjglddp?hl=en&authuser=5") }}>
                    ĐĂNG KÝ VÀ CÀI ĐẶT MIỄN PHÍ</Button>
                </Col>
              </Row>
            </div>
            <div className={`${style.plugin__Map}`}>
              <Img src={imgMapPlugin} />
            </div>
            <div className={`${style.plugin__Signin}`}>
              {/* <div style={{position: "relative"}}>
              <img src={SinginPlugin} alt="" className={`${style.Signin__img}`}></img> */}

              <div className={`${style.Signin__border}`}>
                <form className="form-validate is-alter" onSubmit={handleSubmit(handleFormSubmit)}>
                  <div className={`${style.Signin__content}`}>
                    <h4 className={`${style.Signin__title}`}>ĐĂNG KÝ TƯ VẤN MIỄN PHÍ</h4>
                    <p className={style.Signin__subtitle}>Miễn phí trọn đời - Không mua không sao</p>
                    <Row className={`${style.input}`}>
                      <Col md="6">
                        <input
                          type="text"
                          id="address"
                          name="address"
                          placeholder="Tên của anh chị là? *"
                          className={`form-control ${style.input__name}`}
                          {...register("name", { required: true })}
                        />
                      </Col>
                      <Col md="6">
                        <input
                          type="text"
                          id="address"
                          name="address"
                          placeholder="Số điện thoại*"
                          className={`form-control ${style.input__num}`}
                          {...register("phone", { required: true })}
                        />
                      </Col>
                      <Col md="12">
                        <input
                          type="text"
                          id="address"
                          name="address"
                          placeholder="Nhu cầu tư vấn của anh chị tại đây... (Không bắt buộc)"
                          className={`form-control ${style.input__cmt} `}
                          {...register("demand", { required: true })}
                        />
                      </Col>
                    </Row>
                    <Button type="submit" className={`${style.Signin__button}`}>
                      YÊU CẦU TƯ VẤN</Button>

                  </div>
                </form>
                <Img src={BgSinginPlugin} className={`${style.Signin__img}`}/>
              </div>
              {/* </div> */}
            </div>
            <div className={`${style.plugin__Display}`}>
              <h4 className={`${style.Display__title}`}>Giao diện sử dụng</h4>
              <p className={`${style.Display__subtitle}`}>Đơn giản - Tối ưu hiệu suất 100%</p>
              <Row className="text-center">
                <Col md="3" className={style.Display__card}>
                  <div className={`${style.Display__demo} mb-2`}>
                    <iframe src="https://www.youtube.com/embed/bAbBmqtfCb4" allowFullScreen onClick={() => OnClickZoom("https://www.youtube.com/embed/bAbBmqtfCb4", 0)}>
                    </iframe>
                  </div>
                  <span className={style.Display__span}>Đăng nhiều kênh </span>
                </Col>
                <Col md="3" className={style.Display__card}>
                  <Img src={imgDisplay2} alt="" className={style.Display__img} onClick={() => OnClickZoom(imgDisplay2, 1)}/>
                  <span className={style.Display__span}>Đăng & Hẹn giờ đăng lên các kênh của bạn</span>
                </Col>
                <Col md="3" className={style.Display__card}>
                  <Img src={imgDisplay3} alt="" className={style.Display__img} onClick={() => OnClickZoom(imgDisplay3, 1)}/>
                  <span className={style.Display__span}>Copy nội dung nhanh chóng với 1 Click</span>
                </Col>
                <Col md="3" className={style.Display__card}>
                  <Img src={imgDisplay4} alt="" className={style.Display__img} onClick={() => OnClickZoom(imgDisplay4, 1)}/>
                  <span className={style.Display__span}>Quản lý dễ dàng tại SO9</span>
                </Col>
              </Row>
            </div>
            <div className={`${style.plugin__story}`}>
              <h4 className={`${style.story__title}`}>Câu chuyện Khách hàng</h4>
              <p className={`${style.story__subtitle}`}>Thành công của khách hàng là sự tự hào của SO9 chúng tôi!</p>
              <Slider {...settings}>
                <div>
                  <Img src={IconCarousel} alt="" style={{ width: "70px", height: "70px", marginLeft: "20px" }}/>
                  <div className={`${style.story__card}`}>

                    <p>Công ty chỉ cần duy trì 1 nhân sự content để  đảm bảo việc nuôi & chăm sóc 19 trang.
                      Một công cụ tự động hóa được phần lớn công việc ngốn nhiều thời gian hàng ngày của team Social.</p>
                    <h4 style={{ marginBottom: 0 }}>Tuấn Vũ</h4>
                    <i style={{ color: "#828282" }}>COO tại Lynafa</i>

                  </div>
                </div>
                <div>
                  <Img src={IconCarousel} alt="" style={{ width: "70px", height: "70px", marginLeft: "20px" }}/>
                  <div className={`${style.story__card}`}>

                    <p>Thời gian nuôi Group, Page trên Facebook của tôi giảm được 80% khi sử dụng 9Reup. Trung bình mỗi sáng tôi chỉ cần dành 20 phút để có thể làm xong toàn bộ công việc chăm sóc 55 kênh.</p>
                    <h4 style={{ marginBottom: 0 }}>Hoài An</h4>
                    <i style={{ color: "#828282" }}>Brand Manager tại Datx</i>
                  </div>
                </div>
                <div>
                  <Img src={IconCarousel} alt="" style={{ width: "70px", height: "70px", marginLeft: "20px" }}/>
                  <div className={`${style.story__card}`}>

                    <p>Tôi đã có nhiều thời gian hơn rất nhiều để tập trung vào sáng tạo nội dung. Các công việc chân tay tốn nhiều thời gian như đăng bài lên hệ thống page đã được tối ưu cực nhanh chóng.</p>
                    <h4 style={{ marginBottom: 0 }}>Trí Quang</h4>
                    <i style={{ color: "#828282" }}>Chuyên viên Social tại Datx</i>

                  </div>
                </div>
                <div>
                  <Img src={IconCarousel} alt="" style={{ width: "70px", height: "70px", marginLeft: "20px" }}/>
                  <div className={`${style.story__card}`}>

                    <p>Tôi có 38 page dùng để chạy quảng cáo, dùng SO9 chúng tôi đã có thể tận dụng được thêm 38 page đó để tạo ra nguồn Organic Lead và lượt khách hàng xem trang website.</p>
                    <h4 style={{ marginBottom: 0 }}>Hoàng Anh</h4>
                    <i style={{ color: "#828282" }}>Marketer tại Lagumi</i>

                  </div>
                </div>
              </Slider>
            </div>
            <FooterTrial></FooterTrial>
          </div>
        </Container>
        <Modal isOpen={isOpenImg} size={"lg"} toggle={onFormCancel}>
          <ModalBody className='p-2'>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            {
              imgZoom.isImg === 1 ? <Img src={imgZoom.img} alt="" style={{ width: "100%" }}/> :
                <div className={`${style.demo}`}>
                  <iframe src="https://www.youtube.com/embed/bAbBmqtfCb4" allowFullScreen style={{ width: "100%" }}>
                  </iframe></div>
            }

          </ModalBody>

        </Modal>
      </Block>
    </React.Fragment>
  )
}
