import React from 'react'
import style from "./indexV2.module.scss";
import { optionContentType } from '../../constants/settingSM'
export default function HoverSetupDateOfWeek({ setting }) {
  return (
    <table className={`table table-bordered`}>
      <thead>
        <tr>
          <th className={`${style.hoverSetup__title}`}>Loại nội dung</th>
          <th className={`${style.hoverSetup__title} text-center`}>Khung giờ (Số bài)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            className={`${style.hoverSetup__typeContent} ${style.Td}`}>
            {optionContentType[0].label}
          </td>
          <td
            className={`${style.viewTime} ${style.noborder} `}>
            <div>
              {setting.objects?.find((item) => (item.type === "reel"))?.times.map((e, i) => (
                <span>{e.from}:00 - {e.to}:00 <span style={{ color: "red" }}>({e.limit})</span>&nbsp;</span>
              ))}
            </div>
          </td>
        </tr>
        <tr>
          <td
            className={`${style.hoverSetup__typeContent} ${style.Td}`}
          >
            {optionContentType[1].label}
          </td>
          <td
            className={`${style.viewTime} ${style.noborder} `}>
            <div>
              {setting.objects?.find((item) => (item.type === "video"))?.times.map((e, i) => (
                <span>{e.from}:00 - {e.to}:00 <span style={{ color: "red" }}>({e.limit})</span>&nbsp;</span>
              ))}
            </div>
          </td>
        </tr>
        <tr>
          <td
            className={`${style.hoverSetup__typeContent} ${style.Td}`}>
            {optionContentType[2].label}
          </td>
          <td
            className={`${style.viewTime} ${style.noborder} `}>
            <div>
              {setting.objects?.find((item) => (item.type === "photo"))?.times.map((e, i) => (
                <span>{e.from}:00 - {e.to}:00 <span style={{ color: "red" }}>({e.limit})</span>&nbsp;</span>
              ))}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
