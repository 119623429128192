import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import style from "./index.module.scss";
import { PaginationCustom } from "../../../components/Component";
import { Spinner } from "reactstrap"
import { getPaymentDetail } from "../../../api/iris";

const fakeData = {
  "status": true,
  "data": [
    {
      "code": "DH1",
      "time": "2023-09-26T08:01:03.243000Z",
      "project_name": "ngockhanh.ftu50",
      "email": "ngockhanh.ftu50@gmail.com",
      "status": "2",
      "type": "pro",
      "connection": "1000",
      "amount": 200000,
      "revenue": 20000
    }
  ],
  "message": "success"
}

const ModalHistoryPayment = ({ ...props }) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [curentModalData, setCurentModalData] = useState();
  const { isOpen, toggle, id } = props;
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setLoading(true);
  };
  const formatCurrency = (value) => {
    return value && value.toLocaleString('vi-VN', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }) + ' đ';
  };

  const getFormatDate = (date) => {
    let dateTime = new Date(date);
    let nam = dateTime.getFullYear();
    let thang = (dateTime.getMonth() + 1).toString().padStart(2, "0");
    let ngay = dateTime.getDate().toString().padStart(2, "0");

    let dateFomat = `${nam}-${thang}-${ngay}`
    return dateFomat
  }

  const getCurentModalData = async () => {
    setLoading(true);
    try {
      if (id) {
        const _data = await getPaymentDetail({ page: currentPage, id: id });
        if (_data?.status) {
          setCurentModalData(_data);
          setLoading(false);
        }
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }
  useEffect(() => {
    getCurentModalData();
    // setCurentModalData(fakeData);
  }, [id])

  useEffect(() => {
    getCurentModalData();
  }, [currentPage])
  return (
    <Modal size="xl" isOpen={isOpen} toggle={toggle}>
      <ModalBody className="m-0 px-4 py-3" style={{ minHeight: "500px" }}>
        <div className="px-2 mb-4" style={{ borderLeft: "4px solid #D53784" }}>
          {curentModalData?.data ? 
          ( <h6>THỐNG KÊ ĐƠN HÀNG ĐƯỢC TẠO TỪ {getFormatDate(curentModalData?.data[curentModalData?.data?.length -1]?.time)} ĐẾN {getFormatDate(curentModalData?.data[0]?.time)} </h6>) 
          : (<h6>THỐNG KÊ ĐƠN HÀNG</h6>) }       
        </div>
        <table className={`table `} style={{ minHeight: "160px" }}>
          <thead style={{ background: "#F1F5FF" }}>
            <tr>
              <th style={{ width: "8%" }} className={`${style.headPost} py-2`}>
                Mã đơn
              </th>
              <th
                style={{ width: "17%" }}
                className={`${style.headPost} py-2 text-center`}
              >
                Thời gian tạo đơn
              </th>
              <th
                style={{ width: "10%" }}
                className={`${style.headPost} py-2 text-right`}
              >
                Dự án
              </th>
              <th
                style={{ width: "10%" }}
                className={`${style.headPost} py-2 text-right`}
              >
                Email
              </th>
              <th
                style={{ width: "15%" }}
                className={`${style.headPost} py-2 text-right`}
              >
                Tình trạng đơn
              </th>
              <th
                style={{ width: "10%" }}
                className={`${style.headPost} py-2 text-right`}
              >
                Gói cước
              </th>
              <th
                style={{ width: "10%" }}
                className={`${style.headPost} py-2 text-right`}
              >
                Số Kênh
              </th>
              <th
                style={{ width: "10%" }}
                className={`${style.headPost} py-2 text-right`}
              >
                Đơn giá
              </th>
              <th
                style={{ width: "10%" }}
                className={`${style.headPost} py-2 text-right`}
              >
                Thu nhập
              </th>
            </tr>
          </thead>
          <tbody style={{ backgroundColor: "#fff", position: "relative" }}>
            {!loading ? (
              curentModalData ?
                (curentModalData?.data?.length ?
                  (curentModalData?.data.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        fontSize: "12px",
                        cursor: "pointer",
                        transition: "background-color 0.1s ease",
                      }}
                      className={`${style.hoveredRow}`}
                    >
                      <td className={`${style.truncateCell} ${style.Td} text-center`}>
                        {item.code}
                      </td>
                      <td
                        className={`${style.truncateCell} ${style.Td} text-right`}
                      >
                        {item.time}
                      </td>
                      <td
                        className={`${style.truncateCell} ${style.Td} text-right`}
                      >
                        {item.project_name}
                      </td>
                      <td
                        className={`${style.truncateCell} ${style.Td} text-right`}
                      >
                        {item.email}
                      </td>
                      <td
                        className={`${style.truncateCell} ${style.Td} text-right`}
                        style={{
                          color:
                            item.status === "2" ? ("#219653") :
                              (item.status === "1" ? ("#0EA5E9") :
                                (item.status === "0" ? ("#E11D48") :
                                  (item.status === "3" ? ("#0EA5E9") :
                                    (item.status === "4" ? "#0EA5E9" : ""))))
                        }}
                      >
                        {
                          item.status === "2" ? ("Hoàn thành") :
                            (item.status === "1" ? ("Gửi yêu cầu") :
                              (item.status === "0" ? ("Hủy") :
                                (item.status === "3" ? ("Chờ xác nhận") :
                                  (item.status === "4" ? "Hoàn tiền" : ""))))
                        }
                      </td>
                      <td
                        className={`${style.truncateCell} ${style.Td} text-right`}
                      >
                        {item.type}
                      </td>
                      <td
                        className={`${style.truncateCell} ${style.Td} text-right`}
                      >
                        {item.connection}
                      </td>
                      <td
                        className={`${style.truncateCell} ${style.Td} text-right`}
                      >
                        {formatCurrency(item.amount)}
                      </td>
                      <td
                        className={`${style.truncateCell} ${style.Td} text-right`}
                      >
                        {formatCurrency(item.revenue)}
                      </td>
                    </tr>
                  ))) : (<>
                    <div style={{ position: "absolute", top: "30%", left: "45%" }}>
                      <p style={{ color: "#9CA3AF" }}>Chưa có dữ liệu</p>
                    </div>
                  </>)) : (<>
                    <div style={{ position: "absolute", top: "30%", left: "45%" }}>
                      <p style={{ color: "#9CA3AF" }}>Chưa có dữ liệu</p>
                    </div>
                  </>)

            ) :
              (<>
                <div style={{ position: "absolute", top: "30%", left: "45%" }}>
                  <Spinner size="32px" color="light" />
                </div>
              </>)
            }
          </tbody>
        </table>
        {/* {data ? (
            data.length > 1 ? ( 
            <PaginationCustom
          pageSize={10}
          totalCount={data.length || 0}
          siblingCount={1}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          className={`manage_post_pagination`}
        />) : (<></>)  
          ) : (<></>)} */}


      </ModalBody>
    </Modal>
  );
};

export default ModalHistoryPayment;
