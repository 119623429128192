import React, { useCallback, useEffect, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle, Spinner, Card, Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap";
import { Row, Col, RSelect, Button, Img, Icon } from "../../../components/Component";
import { components } from 'react-select';
import style from "./index.module.scss";
import { useDispatch } from "react-redux";
import { GetPinterestBoard, createPinterestBoard } from "../../../api/createPost"
import {
    setPinterestOption
} from "../../reducers/createPostSlice";
import pinterestAvatar from "../../images/icons/pinterest-avatar.svg";
import addBoard from "../../images/icons/add-board.svg";
import searchBoard from "../../images/icons/search-board.svg";
import { PLATFORM_PINTEREST, PLATFORM_YOUTUBE } from "../../utils/Utils";
import { toast } from "react-toastify";
import { toastError, toastSuccess } from "../../../libs/toasts";
import { useForm, useFormContext } from "react-hook-form";


export default function ModalPinterestAll({ show, toggle, valid, channels, handleAddPinLink, setPinterestSetting, setValue, getValues }) {

    const [pinterestChannel, setPinterestChannel] = useState([]);
    const [openDropdown, setOpenDropdown] = useState([]);
    const [boardOption, setBoardOption] = useState([]);
    const [boardForm, setBoardForm] = useState({ search: "", create: "" });
    const [boardLoading, setBoardLoading] = useState({ search: false, create: false });



    const userInfo = JSON.parse(localStorage.getItem("userInfor"));
    const [pinterestOption, setPinterestOption] = useState({
        link: null,
        board: [{}]
    });


    const handleOpenDropDown = (e, index) => {
        e.stopPropagation();
        let open = [...openDropdown];
        if (open[index]) {
            open[index] = false;
        } else {
            open[index] = true;
        }
        setOpenDropdown(open);
        setBoardForm({ search: "", create: "" });
    };

    useEffect(() => {

        let newChannels = [];
        channels.forEach(form => {
            newChannels = [...newChannels, ...form.channel];
        })
        const uniqueChannels = newChannels?.filter((item, index, self) =>
            index === self.findIndex(obj => obj._id === item._id)
        );
        const pinChannel = uniqueChannels?.filter(c => c.platform === PLATFORM_PINTEREST)

        if (pinterestChannel?.length !== pinChannel?.length) {
            setPinterestChannel(uniqueChannels?.filter(c => c.platform === PLATFORM_PINTEREST));
        }
    }, [channels])


    const fetchListBoard = async ({ channelId, projectId, bookMark, count }) => {
        if (count === 5) {
            return [];
        }

        let data = {
            params: {
                project_id: projectId,
            }
        }

        if (bookMark) {
            data.params = { ...data.params, bookmark: bookMark }
        }

        try {
            const res = await GetPinterestBoard(channelId, data);
            if (res.status) {
                const newRes = [...res.data.items];
                let finalRes = [];
                if (res.data.bookmark) {
                    finalRes = await fetchListBoard({ channelId, projectId, bookMark: res.data.bookmark, count: count + 1 });
                }
                return [...newRes, ...finalRes];
            } else {
                console.log("get board fail");
                return [];
            }
        } catch (error) {
            console.log("get board fail", error);
            return [];
        }
    };

    useEffect(() => {
        let newPinterestOption = pinterestOption?.board;
        const newArray = newPinterestOption?.filter(option => {
            return pinterestChannel?.some(channel => channel._id === option.userId);
        });

        setPinterestOption({ ...pinterestOption, board: newArray });
        const fetchBoardData = async () => {
            if (!pinterestChannel) {
                return;
            };

            const fulfilledResults = await Promise.allSettled(pinterestChannel.map(async (channel) => {
                const channelId = channel?._id ?? null;
                const projectId = channel?.project_id ?? null;
                const data = await fetchListBoard({ channelId, projectId, bookmark: null, count: 1 });
                return data;
            }));

            const resolvedResults = fulfilledResults
                .filter(result => result.status === 'fulfilled')
                .map(result => result.value);

            setBoardOption(resolvedResults);
            // Tiếp tục xử lý kết quả nếu cần thiết
        };
        fetchBoardData();
    }, [pinterestChannel]);

    const handleKeyDown = (e, index) => {
        if (e.key === "Enter") {
            e.preventDefault();
            onCreateBoard(pinterestOption.board[index].userId, index);
        }
    };
    const handleEnter = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const onCreateBoard = (channelId, index) => {
        const data = {
            name: boardForm.create !== "" ? boardForm.create : "",
        }

        if (data.name.trim() === "") {

            return;
        }

        setBoardLoading({ ...boardLoading, search: true });



        createPinterestBoard({ project_id: userInfo?.last_project_active, channelId: channelId, data: data })
            .then((response) => {
                if (response.status) {
                    let newBoardOption = boardOption;
                    boardOption[index].push(response.data);
                    setBoardOption([...newBoardOption]);
                    toastSuccess("Bạn đã thêm board thành công!");
                    setBoardForm({ ...boardForm, create: "" });
                    setBoardLoading({ ...boardLoading, search: false });
                }
            }).catch((error) => {
                toastError(error.response.data.message);
                setBoardLoading({ ...boardLoading, search: false });
            });
    }

    const handleCreateBoard = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        onCreateBoard(pinterestOption.board[index].userId, index);
    }

    const handleSelectOption = (e, index, item) => {
        e.stopPropagation();
        const optionIndex = boardOption[index].findIndex(element => element.id === item.id);

        let newPinterestBoard = pinterestOption.board.length > 0 ? [...pinterestOption.board] : [];
        newPinterestBoard[index] = { ...boardOption[index][optionIndex], userId: pinterestChannel[index]._id };
        setPinterestOption({ ...pinterestOption, board: newPinterestBoard });
        handleOpenDropDown(e, index);
    }


    const handleSubmit = (e) => {
        e.stopPropagation();
        setPinterestSetting(pinterestOption);

        if (!(pinterestOption?.link === null || pinterestOption?.link?.trim().length === 0)) {
            const formData = getValues();
            
            for (let key in formData) {
                if (key.startsWith('pinlink')) {
                    formData[key] = pinterestOption.link;
                }
            }
            console.log(formData);
            for (let key in formData) {
                setValue(key, formData[key]);
              }
        }
        toggle();
    }
    return (
        <Modal
            isOpen={show}
            toggle={toggle}
            className="modal-md"
        >
            <ModalHeader
                toggle={toggle}
            >
                {valid.board ? <span className="text-danger">Vui lòng chọn Board cho các bài đăng lên Pinterest</span> : "Cài đặt Pinterest hàng loạt"}
            </ModalHeader>
            <ModalBody style={{ minHeight: "300px" }}>
                <div >
                    <Row className={`pb-2 mb-2`}>
                        <Col md="12">
                            <label className={style.OptionYoutube__title}>Thêm link Pin hàng loạt (Không bắt buộc - Bạn có thể thêm link tại từng bài)</label>
                            {valid.link && (pinterestOption?.link === null || pinterestOption?.link?.trim().length === 0) && <span className="text-danger ms-1 fw-medium">(Bắt buộc điền)</span>}
                            <input
                                className={`form-control ${valid.link && (pinterestOption?.link === null || pinterestOption?.link?.trim().length === 0) ? "border border-danger" : ""}`}
                                onChange={(e) => {
                                    let newPinterestOption = { ...pinterestOption, link: e.target.value }
                                    setPinterestOption(newPinterestOption);
                                }}
                                placeholder="Thêm Link đích của Pin" />
                        </Col>
                    </Row>
                    <Row className={`pb-2 mb-2`}>
                        <Col md="7">
                            <label className={style.OptionYoutube__title}>Chọn Board hàng loạt </label>
                            {(valid.board && pinterestOption?.board?.length < pinterestChannel?.length) && <span className="text-danger ms-1 fw-medium">(Bắt buộc điền)</span>}
                            {boardLoading?.create ? <p className={style.pinterestLoading}>Loading...</p>
                                :
                                pinterestChannel?.map((channel, index) => (
                                    <div key={channel?._id} className="d-flex mb-2" style={{ gap: "12px" }}>
                                        <div style={{ position: "relative", cursor: "pointer" }} data-toggle="tooltip" data-placement="top" title={channel?.name}>
                                            <Img src={channel?.picture} size="circle" height="32px" width="32px" />
                                            <Img src={pinterestAvatar} height="12px" width="12px"
                                                style={{ position: "absolute", bottom: "0", left: "0" }} />
                                        </div>
                                        <div style={{ flexGrow: "1" }}>
                                            <Dropdown className="w-100 h-100" isOpen={openDropdown[index]} toggle={(e) => handleOpenDropDown(e, index)}>
                                                <DropdownToggle
                                                    className={`dropdown-toggle ${(valid.board && !pinterestOption.board[index]) ? "border-danger" : ""} btn btn-white btn-dim btn-outline-light ${style.dropDownSelect} d-flex align-items-center justify-content-between`}
                                                    style={{
                                                        background: "#fff",
                                                        color: "#9CA3AF",
                                                        border: "1px solid",
                                                        borderColor: "#D9D9D9",
                                                        width: "100%"
                                                    }}
                                                >
                                                    {pinterestOption?.board[index]?.name ?
                                                        <div className={style.selectToggle}>{pinterestOption?.board[index]?.name}</div>
                                                        :
                                                        <div className={style.defaultToggle}>Chọn Board</div>
                                                    }
                                                    <Icon
                                                        className="d-sm-inline"
                                                        style={{ color: "#AEBFE7", position: "absolute", right: "5px" }}
                                                        name="chevron-down"
                                                    >
                                                    </Icon>
                                                </DropdownToggle>
                                                <DropdownMenu positionFixed={true} className="px-2 my-2 py-2 rounded">
                                                    <div className="h-100 d-flex flex-column" style={{ gap: "8px" }}>
                                                        <div className={style.boardItems}>
                                                            <div
                                                                className={style.boardBttn}
                                                            >
                                                                <Img
                                                                    style={boardForm.search !== "" && { filter: "invert(51%) sepia(100%) saturate(7480%) hue-rotate(222deg) brightness(100%) contrast(107%)" }}
                                                                    src={searchBoard} height="18px" width="18px" />
                                                            </div>
                                                            <input
                                                                type="text"
                                                                value={boardForm.search}
                                                                onKeyDown={e => handleEnter(e)}
                                                                onChange={e => setBoardForm({ ...boardForm, search: e.target.value })}
                                                                className={style.boardInput}
                                                                placeholder="Tìm kiếm board"
                                                            />
                                                        </div>

                                                        <div className={`${style.boardItems}`}>
                                                            <div className={style.boardBttn} onClick={e => handleCreateBoard(e, index)}>
                                                                <Img
                                                                    style={boardForm.create !== "" && { filter: "invert(51%) sepia(100%) saturate(7480%) hue-rotate(222deg) brightness(100%) contrast(107%)" }}
                                                                    src={addBoard} height="18px" width="18px" />
                                                            </div>
                                                            <input
                                                                type="text"
                                                                value={boardForm.create}
                                                                onKeyDown={e => handleKeyDown(e, index)}
                                                                onChange={e => setBoardForm({ ...boardForm, create: e.target.value })}
                                                                className={style.boardInput}
                                                                placeholder="Tạo mới board"
                                                            />
                                                        </div>
                                                    </div>

                                                    {boardLoading.search ? <div className="w-100 mt-4 mb-4 d-flex justify-content-center align-items-center"><Spinner></Spinner></div>
                                                        : (
                                                            <div className={`mt-3 d-flex flex-column ${style.bgDropDown}`}>
                                                                {boardOption[index]?.filter(board => board?.name?.includes(boardForm.search))?.map((item) => (
                                                                    <div
                                                                        className="d-flex align-items-center"
                                                                        key={item?.id}
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={(e) => handleSelectOption(e, index, item)}
                                                                    >
                                                                        <div className={`mb-2 ${style.boardlistItems}`}>
                                                                            {item?.name}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>)}
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>

                                    </div>
                                ))}
                        </Col>
                    </Row>
                </div>

            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end align-items-center gap-2">
                    <Button color="light" onClick={toggle}>Hủy</Button>
                    <Button color="primary" onClick={(e) => handleSubmit(e)}>Lưu lại</Button>
                </div>
            </ModalFooter>
        </Modal>
    );
}
