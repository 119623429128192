import { useTranslation } from "react-i18next";
import { Icon } from "../../../components/Component";

const SummaryItem = ({ item, className, activeItem, translatePrefix = "" }) => {
  const { t } = useTranslation();
  const { key, current } = item;

  const renderChange = () => {
    const { percent_to_prev: percentToPrev, state } = item;
    if (percentToPrev === null) {
      return <div className="change">--</div>;
    }
    const floatPercentToPrev = parseFloat(percentToPrev) / 100;
    if (floatPercentToPrev > 0) {
      return (
        <div className={`change ${state === "good" ? "up" : "down"}`}>
          <Icon name="arrow-long-up" /> {percentToPrev}
        </div>
      );
    }
    return (
      <div className={`change ${state === "good" ? "up" : "down"}`}>
        <Icon name="arrow-long-down" /> {percentToPrev.slice(1)}
      </div>
    );
  };

  return (
    <div className={`analytic-data ${className}`}>
      <div className={`title ${activeItem ? "active" : ""}`}>{t(`${translatePrefix}${key}`)}</div>
      <div className={`amount ${activeItem ? "active" : ""}`}>{current}</div>
      {renderChange()}
    </div>
  );
};

export default SummaryItem;
