import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  Spinner,
  Tooltip,
} from "reactstrap";

import TableToolTip from "./TableToolTop";
import SORT_ARROW_ICON from "../Icon/SortArrow.svg";
import SORT_AZ_ICON from "../Icon/SortAZ.svg";
import styles from "../index.module.scss";
import { numberWithCommas } from "../../../DashboardNew/utils/utils";
import { Img, PaginationCustom } from "../../../../../components/Component";

const ContentStatisticTable = ({
  listData,
  sortTypeOptions,
  headerContentStatistic,
  tooltipTableOpen,
  setTableToolTipOpen,
  selectedOption,
  setSelectedOption,
  tableLoading,
  data,
  setCurrentPage,
  sortType,
  setSortType,
  defaultChannelIcon,
  activeTab,
}) => {
  const { t } = useTranslation();

  const sortOption = [
    { value: "asc", label: "Asc." },
    { value: "desc", label: "Desc." },
  ];

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };
  const handleOptionType = (option) => {
    setSortType(option);
  };

  const renderDropdown = () => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle
          style={{
            backgroundColor: "#FFFFFF",
            border: "0px",
            padding: "2px",
            fontWeight: "600",
          }}
        >
          <span>
            <span
              style={{
                color: "#132243",
                fontWeight: "600",
                whiteSpace: "nowrap",
                minWidth: "fit-content",
                fontSize: "12px",
                padding: "0px",
                lineHeight: "16px",
                fontFamily: `Roboto, sans-serif`,
              }}
            >
              {selectedOption?.label}
            </span>
            <Img
              src={SORT_ARROW_ICON}
              style={{ with: "16px", height: "16px", marginLeft: "6px" }}
            ></Img>
          </span>
        </DropdownToggle>
        <DropdownMenu style={{ maxHeight: "200px", overflowY: "auto" }}>
          {sortTypeOptions.map((option) => (
            <DropdownItem
              key={option.value}
              onClick={() => handleOptionSelect(option)}
            >
              {option.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const renderSorting = () => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle
          style={{
            backgroundColor: "#FFFFFF",
            border: "0px",
            padding: "2px",
            fontWeight: "600",
            marginLeft: "32px",
          }}
        >
          <span>
            <span
              style={{
                color: "#132243",
                fontWeight: "600",
                whiteSpace: "nowrap",
                minWidth: "fit-content",
                fontSize: "12px",
                padding: "0px",
                lineHeight: "16px",
                fontFamily: `Roboto, sans-serif`,
              }}
            >
              {sortType.label}
            </span>
            <Img
              src={SORT_AZ_ICON}
              style={{ width: "16px", height: "16px", marginLeft: "6px" }}
            ></Img>
          </span>
          {/* {selectedOption ? selectedOption.label : 'Select an option'} */}
        </DropdownToggle>
        <DropdownMenu>
          {sortOption.map((option) => (
            <DropdownItem
              key={option.value}
              onClick={() => handleOptionType(option)}
            >
              {option.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  return (
    <>
      <Card className="card-bordered">
        <div className="card-inner-group" style={{ minHeight: "250px" }}>
          <div
            className="card-inner p-0 table-responsive"
            style={{ minHeight: "250px" }}
          >
            <div
              className="table-header d-flex justify-content-between align-items-center bg-slate-50"
              style={{ height: "60px" }}
            >
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "16px",
                  margin: activeTab
                    ? "12px 742px 12px 24px"
                    : "12px 730px 12px 24px",
                  whiteSpace: "nowrap",
                  minWidth: "fit-content",
                  // color: "#111827",
                }}
              >
                {t("content_analytics")}
              </p>

              <div className="d-flex" style={{ margin: "0px 12px 0px 0px" }}>
                {renderDropdown()}
                {renderSorting()}
              </div>
            </div>

            {tableLoading && (
              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  minHeight: "90px",
                }}
                className="d-flex justify-content-center align-items-center"
              >
                <Spinner size="lg" color="primary"></Spinner>
              </div>
            )}

            <table className="table table-hover">
              <thead
                style={{
                  height: "60px",
                  margin: 0,
                  padding: 0,
                  // backgroundColor: "#EBEAED",
                }}
              >
                <tr>
                  {headerContentStatistic &&
                    Object.values(headerContentStatistic).map(
                      (field, index) => {
                        const styleTh =
                          field === t("content")
                            ? {
                                paddingBottom: "20px",
                                width: "23%",
                                minWidth: "360px",
                                // color: "rgba(19, 34, 67,   1)",
                              }
                            : field !== t("engagement")
                            ? {
                                paddingBottom: "20px",
                                textAlign: "end",
                                width: "10%",
                                whiteSpace: "nowrap",
                                minWidth: "100px",
                                // color: "rgba(19, 34, 67, 1)",
                              }
                            : {
                                paddingBottom: "20px",
                                textAlign: "end",
                                width: "10%",
                                whiteSpace: "nowrap",
                                minWidth: "120px",
                                // color: "rgba(19, 34, 67, 1)",
                              };
                        return (
                          <th scope="col" style={styleTh} key={index}>
                            {" "}
                            <span
                              style={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                // color: "rgba(19, 34, 67, 1)",
                              }}
                              id={`tableToolTip${index}`}
                              className={`${styles.tooltipHover}`}
                            >
                              {field?.includes("svg") ? (
                                <img
                                  src={field}
                                  style={{ width: "16px", height: "16px" }}
                                />
                              ) : (
                                field
                              )}
                            </span>
                            <TableToolTip
                              tooltipId={index}
                              tooltipLabel={tooltipTableOpen[index].label}
                            />
                          </th>
                        );
                      }
                    )}
                </tr>
              </thead>
              <tbody>
                {listData.length > 0
                  ? listData.map((item, index) => {
                      return (
                        <tr key={`trTable${index}`}>
                          {Object.keys(headerContentStatistic).map(
                            (fk, tdIndex) => {
                              if (fk === "content") {
                                return (
                                  <td
                                    key={`${fk}-${tdIndex}`}
                                    style={{ height: "50px", width: "25%" }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <div>
                                        <Img
                                          src={
                                            item?.media?.type === 1
                                              ? item?.media?.name
                                              : item?.media?.thumb
                                          }
                                          alt=""
                                          width="68px"
                                          height="68px"
                                          style={{
                                            borderRadius: "4px",
                                            minWidth: "68px",
                                          }}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          marginLeft: "12px",
                                          height: "100%",
                                        }}
                                        className="tb-sub"
                                      >
                                        <div
                                          style={{
                                            fontWeight: "600",
                                          }}
                                        >
                                          <span
                                            className={`${styles.tableContentHeader}`}
                                          >
                                            {item[fk] || "NO CONTENT"}
                                          </span>
                                        </div>
                                        <div
                                          className="d-flex justify-content-start"
                                          style={{
                                            gap: "12px",
                                            marginTop: "8px",
                                            fontSize: "13px",
                                            fontWeight: "400",
                                            color: "rgba(31, 41, 55, 1)",
                                            lineHeight: "15px",
                                          }}
                                        >
                                          <span
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            {item?.date ? item.date : "No Date"}
                                          </span>
                                          <span style={{ lineHeight: "15px" }}>
                                            {item?._post_id ? (
                                              <a
                                                href={`https://www.facebook.com/${item?._post_id}`}
                                                target="_blank"
                                                style={{ whiteSpace: "nowrap" }} rel="noreferrer"
                                              >
                                                Link
                                              </a>
                                            ) : item?.Yt_post_id ? (
                                              <a
                                                href={`https://www.youtube.com/watch?v=${item?.Yt_post_id}`}
                                                target="_blank"
                                                style={{ whiteSpace: "nowrap" }} rel="noreferrer"
                                              >
                                                Link
                                              </a>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                        </div>
                                        <div
                                          className={`d-flex justify-content-start align-items-center`}
                                          style={{ marginTop: "8px" }}
                                        >
                                          <Img
                                            src={item?.channel?.picture}
                                            style={{
                                              width: "12px",
                                              height: "12px",
                                              margin: "0px 8px 0px 0px",
                                            }}
                                            size="circle"
                                            defaultPicture={defaultChannelIcon}
                                          ></Img>
                                          <span
                                            className={`${styles.tableChanelHeader}`}
                                          >
                                            {item?.channel.name
                                              ? item.channel.name
                                              : "No Channel Name data"}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                );
                              } else {
                                return (
                                  <td
                                    key={`${fk}-${tdIndex}`}
                                    style={{
                                      height: "50px",
                                      width: "10%",
                                      textAlign: "end",
                                    }}
                                  >
                                    <div
                                      className="tb-sub d-flex align-items-center justify-content-end"
                                      style={{
                                        height: "100%",
                                        color: "rgba(31, 41, 55, 1)",
                                      }}
                                    >
                                      {numberWithCommas(item[`${fk}`])}
                                    </div>
                                  </td>
                                );
                              }
                            }
                          )}
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
            <div className="card-inner">
              {!tableLoading ? (
                listData?.length > 0 && data ? (
                  data?.last_page > 1 && (
                    <div className="mobile-paginate">
                      <PaginationCustom
                        pageSize={data?.per_page || 9}
                        totalCount={data?.total || 0}
                        siblingCount={1}
                        onPageChange={handlePageChange}
                        currentPage={data?.current_page}
                      />
                    </div>
                  )
                ) : (
                  <div className="text-center">
                    <span className="text-silent">No page found</span>
                  </div>
                )
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default ContentStatisticTable;
