import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import Select from "react-select"
import Dropzone from 'react-dropzone';
import * as Yup from "yup";
import { uploadImage } from "../../../api/createPost";

import { postIrisRegister, getListBank } from "../../../api/iris";
import bg1 from "../../../images/iris/user/background.png";
import notificationIcon from "../../../images/iris/user/notification.svg";
import qr from "../../../images/iris/user/qr.svg";
import IconCheck from "../../../images/iris/user/check.png";
import IconLink from "../../../images/iris/user/link_more.svg";
import IconCopy from "../../../images/iris/user/copy.svg";
import IconAnalystic from "../../../images/iris/user/analystic.svg";
import iconDelete from "../../../So9/images/icons/icon_delete.svg"

import Head from "../../../layout/head/Head";
import styles from "./index.module.scss";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../components/Component";
import {
  Button,
  ButtonGroup,
  Form,
  FormGroup,
  FormText,
  UncontrolledTooltip,
  Tooltip,
} from "reactstrap";

const initForm = {
  email: "",
  phoneNumber: "",
  bankName: "",
  bankNo: "",
  bankAccount: "",
  checkPolicy: false,
};

const UserProfileIrisPage = ({ listBank, setLogin, setListBank }) => {
  const [loading, setLoading] = useState(false);
  const userInfor = JSON.parse(localStorage.getItem('userInfor'));
  const [link, setLink] = useState("https://so9.vn/iris/xYSadsD");
  const [formData, setFormData] = useState(initForm);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [selected, setSelected] = useState();
  const [imageLoading, setImageLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleDrop = (acceptedFiles) => {

    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      uploadFileImage(file);
    }
  };
  const handleClear = (event) => {
    event.stopPropagation();
    setSelectedFile(null);
    setImageUrl(null);
  };

  const uploadFileImage = async (fileMedia) => {
    setImageLoading(true);
    const data = new FormData();
    data.append("image", fileMedia);
    data.append("projectId", userInfor.last_project_active);
    data.append("folder", "affiliate");

    uploadImage(data).then((res) => {
      if (res.status) {
        setImageUrl(res?.data?.url);
        setSelectedFile(fileMedia);
        setImageLoading(false);
      } else {
        console.log("fail");
      }
    }
    ).catch((err) => {
      setImageLoading(false);
      toast.error(`Có lỗi xảy ra. Vui lòng thử lại!`, {
        autoClose: 2000,
        position: "top-right",
      });
    }
    )

  }
  const toggle = () => {
    navigator.clipboard.writeText(link);
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 3000);
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  // useEffect(() => {
  //   getListBank().then((res) => {

  //     const formattedData = res?.data?.map(bank => ({
  //       value: bank.brand_name,
  //       label: `${bank.name} ${bank.brand_name}`
  //     }));
  //     setListBank(formattedData);
  //   }).catch((error) => {

  //   })
  // }, [])


  const register = async (params) => {
    setLoading(true);
    // setFormData(initForm);
    // setLoading(false);
    // setSelectedFile(null);
    // setImageUrl(null);
    postIrisRegister(params).then((res) => {
      if (res.status) {
        console.log(res);
        toast.success("Đăng ký thành công!", {
          autoClose: 1000,
          position: "top-right",
        });
        setFormData(initForm);
        setLoading(false);
        setSelectedFile(null);
        setImageUrl(null);
        setSelected(null);
        setListBank();
        setLogin(true);
      } else {
        toast.error(`Đăng ký thất bại! ${res?.message}`, {
          autoClose: 3000,
          position: "top-right",
        });
        setLoading(false);
      }
    }
    ).catch((err) => {
      if (err.response.status === 422) {
        toast.error(`Đăng ký thất bại! ${err?.response?.data?.message}`, {
          autoClose: 3000,
          position: "top-right",
        });
      } else {
        toast.error(`Có lỗi xảy ra. Vui lòng thử lại sau!`, {
          autoClose: 3000,
          position: "top-right",
        });
      }
      setLoading(false);
    })
  }
  const onSubmit = (data) => {
    if (!formData.checkPolicy) {
      setErrorMessage("Bạn cần đồng ý!");
      const delay = setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return;
    } else {
      if (
        formData.email === "" ||
        formData.phoneNumber === "" ||
        formData.bankName === "" ||
        formData.bankNo === "" ||
        formData.bankAccount === "" 
      ) {
        return;
      } else if (imageUrl) {
        const params = {
          email: formData.email,
          phone: formData.phoneNumber,
          bank_name: formData.bankName,
          bank_account: formData.bankAccount,
          bank_no: formData.bankNo,
          qr_code_payment: imageUrl,
        }
        register(params);
      } else if (!imageUrl) {
        const params = {
          email: formData.email,
          phone: formData.phoneNumber,
          bank_name: formData.bankName,
          bank_account: formData.bankAccount,
          bank_no: formData.bankNo,
        }
        register(params);
      }

    }



    // if (isLogin) {
    //   //Xử lý chỉnh sửa
    //   console.log("edit");
    //   setIsLogin((prev) => !prev);
    // } else {
    //   //Xử lý đăng ký
    //   console.log("login");
    //   setIsLogin((prev) => !prev);
    // }
  };

  const renderPreviewImage = () => {
    return (
      !imageLoading ? (<div className="d-flex justify-content-center align-items-center" style={{ position: "relative" }}>
        <img
          src={URL.createObjectURL(selectedFile)}
          alt="Selected"
          style={{ width: '200px', maxHeight: "300px" }}
        />
        <img src={iconDelete}
          className={`${styles['custom-item__button--delete']}`}
          onClick={handleClear} alt="delete"></img>
      </div>) : (<Spinner size="32px" color="light" style={{ position: "absolute", top: "30%", left: "47%" }} />)
    );
  }
  return (
    <React.Fragment>
      <Head title="Iris - Profile"></Head>

      <div className={styles['header-container']} >
        <div className="d-flex " style={{ padding: "20px" }}>
          <img src={notificationIcon}
            style={{
              width: "16px",
              height: "16px",
              objectFit: "scale-down"
            }} alt="notification" />
          <div style={{ marginLeft: "12px" }}>
            <p style={{ fontWeight: "600", fontSize: "16px", lineHeight: "24px", marginBottom: "6px" }}>
              Chào mừng đến với chương trình SO9 Affiliate</p>
            <p style={{ fontWeight: "400", fontSize: "14px", lineHeight: "20px" }}>
              Vui lòng hoàn thành mẫu đăng ký dưới đây và đảm bảo rằng tất cả các thông tin đều chính xác
              để chúng tôi xét duyệt. Tất cả thông tin đã gửi sẽ được sử dụng hợp pháp và bảo mật theo
              chính sách của chúng tôi.</p>
          </div>
        </div>



      </div>
      <BlockHead>
        <div
          style={{
            backgroundImage: `url(${bg1})`,
            backgroundSize: "100%",
            color: "white",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="px-4 py-3"
        >

          <BlockBetween>
            <BlockHeadContent className={"w-100"}>
              <BlockTitle tag="h4">
                <div style={{ color: "white" }}>SO9 IRIS</div>
              </BlockTitle>

              <div className="d-flex justify-content-between ">
                <div>
                  <div className="d-flex align-items-center">
                    <img
                      className="mr-1"
                      src={IconCheck}
                      alt="check"
                      style={{
                        width: "1.25rem",
                        height: "auto",
                        objectFit: "cover",
                      }}
                    />
                    <p>Cookies ghi nhận lên đến 30 ngày.</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <img
                      className="mr-1"
                      src={IconCheck}
                      alt="check"
                      style={{
                        width: "1.25rem",
                        height: "auto",
                        objectFit: "cover",
                      }}
                    />
                    <p>
                      <b>Nhận 10%</b> toàn bộ hóa đơn phát sinh trong{" "}
                      <b>90 ngày</b> từ khi khách tạo tài khoản.
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    <img
                      className="mr-1"
                      src={IconCheck}
                      alt="check"
                      style={{
                        width: "1.25rem",
                        height: "auto",
                        objectFit: "cover",
                      }}
                    />
                    <p>
                      <b>Hỗ trợ 100% từ SO9.</b> Bạn chỉ cần giới thiệu!
                    </p>
                  </div>
                </div>
                <div className="-mt-4">
                  <Button style={{ background: "#2F80ED" }}>
                    <div className="d-flex align-items-center">
                      <a href="https://so9.vn/affiliates/"
                        target="_blank" className="mr-1"
                        style={{ color: "#FFFFFF" }}
                      >Xem thêm thông tin</a>
                      <img src={IconLink} alt="link" />
                    </div>
                  </Button>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>

        </div>
      </BlockHead>
      <Block className={"px-4"}>
        <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">
              THÔNG TIN ĐĂNG KÝ THAM GIA CHƯƠNG TRÌNH
            </h6>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles['custom-item']} data-item px-0`}>
              <div className="data-col">
                <span className={styles['data-label']}>
                  Số điện thoại liên hệ
                  <span style={{ color: "#E11D48" }}>*</span>
                </span>
                {isLogin ? (
                  <span className="data-value">---</span>
                ) : (
                  <FormGroup className={styles['form-group']}>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      rules={{
                        required: "Bạn phải nhập số điện thoại liên hệ!",
                      }}
                      render={({ field: { onBlur, onChange } }) => (
                        <input
                          className="form-control px-[16px]"
                          type="number"
                          name="phoneNumber"
                          value={formData.phoneNumber}
                          placeholder="Số điện thoại"
                          onChange={(e) => {
                            setValue("phoneNumber", e.target.value);
                            setFormData({
                              ...formData,
                              phoneNumber: e.target.value,
                            });
                          }}
                        />
                      )}
                    />
                    {errors.phoneNumber && (
                      <FormText color="danger">
                        {" "}
                        {errors[`phoneNumber`]?.message}
                      </FormText>
                    )}
                  </FormGroup>
                )}
              </div>
              {/* <div className="data-col data-col-end"> */}
              {/* <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span> */}
              {/* </div> */}
            </div>
            <div className={`${styles['custom-item']} data-item px-0`}>
              <div className="data-col">
                <span className={styles['data-label']}>
                  Email liên hệ<span style={{ color: "#E11D48" }}>*</span>
                </span>
                {isLogin ? (
                  <span className="data-value">---</span>
                ) : (
                  <FormGroup className={styles['form-group']}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: "Bạn phải nhập email!",
                      }}
                      render={({ field: { onBlur, onChange } }) => (
                        <input
                          className="form-control px-[16px]"
                          type="email"
                          name="email"
                          value={formData.email}
                          placeholder="Địa chỉ email"
                          onChange={(e) => {
                            setValue("email", e.target.value);
                            setFormData({
                              ...formData,
                              email: e.target.value,
                            });
                          }}
                        />
                      )}
                    />
                    {errors.email && (
                      <FormText color="danger">
                        {" "}
                        {errors[`email`]?.message}
                      </FormText>
                    )}
                  </FormGroup>
                )}
              </div>
              {/* <div className="data-col data-col-end"> */}
              {/* <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span> */}
              {/* </div> */}
            </div>
            <div className={`${styles['custom-item']} data-item px-0`}>
              <div className="data-col">
                <span className={styles['data-label']}>
                  Ngân hàng của bạn<span style={{ color: "#E11D48" }}>*</span>
                </span>
                {isLogin ? (
                  <span className="data-value">---</span>
                ) : (

                  <FormGroup className={styles['form-group']}>
                    <Controller
                      name="bankName"
                      control={control}
                      rules={{
                        required: "Bạn phải nhập tên ngân hàng!",
                      }}
                      render={({ field: { onBlur, onChange } }) => (
                        <Select
                          classNamePrefix="react-select"
                          styles={{
                            placeholder: (baseStyles) => ({
                              ...baseStyles,
                              opacity: "0.5",
                              color: "#3c4d62 !important",
                            }),
                          }}
                          name="bankName"
                          placeholder="Tên ngân hàng"
                          options={listBank}
                          value={selected}
                          onChange={(selectedOption) => {
                            setValue("bankName", selectedOption?.value);
                            setSelected(selectedOption);
                            setFormData({
                              ...formData,
                              bankName: selectedOption?.value,
                            });
                          }}
                        />
                      )}
                    />
                    {errors.bankName && (
                      <FormText color="danger">
                        {errors[`bankName`]?.message}
                      </FormText>
                    )}
                  </FormGroup>
                )}
              </div>
              {/* <div className="data-col data-col-end"> */}
              {/* <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span> */}
              {/* </div> */}
            </div>
            <div className={`${styles['custom-item']} data-item px-0`}>
              <div className="data-col">
                <span className={styles['data-label']}>
                  Số tài khoản<span style={{ color: "#E11D48" }}>*</span>
                </span>
                {isLogin ? (
                  <span className="data-value">---</span>
                ) : (
                  <FormGroup className={styles['form-group']}>
                    <Controller
                      name="bankNo"
                      control={control}
                      rules={{
                        required: "Bạn phải nhập số tài khoản ngân hàng!",
                      }}
                      render={({ field: { onBlur, onChange } }) => (
                        <input
                          className="form-control px-[16px]"
                          type="number"
                          name="bankNo"
                          placeholder="Số tài khoản"
                          value={formData.bankNo}
                          onChange={(e) => {
                            setValue("bankNo", e.target.value);
                            setFormData({
                              ...formData,
                              bankNo: e.target.value,
                            });
                          }}
                        />
                      )}
                    />
                    {errors.bankNo && (
                      <FormText color="danger">
                        {" "}
                        {errors[`bankNo`]?.message}
                      </FormText>
                    )}
                  </FormGroup>
                )}
              </div>
              {/* <div className="data-col data-col-end"> */}
              {/* <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span> */}
              {/* </div> */}
            </div>
            <div className={`${styles['custom-item']} data-item px-0`}>
              <div className="data-col">
                <span className={styles['data-label']}>
                  Tên tài khoản<span style={{ color: "#E11D48" }}>*</span>
                </span>
                {isLogin ? (
                  <span className="data-value">---</span>
                ) : (
                  <FormGroup className={styles['form-group']}>
                    <Controller
                      name="bankAccount"
                      control={control}
                      rules={{
                        required: "Bạn phải nhập tên tài khoản",
                      }}
                      render={({ field: { onBlur, onChange } }) => (
                        <input
                          className="form-control px-[16px]"
                          type="text"
                          name="bankAccount"
                          placeholder="Tên tài khoản"
                          value={formData.bankAccount}
                          onChange={(e) => {
                            setValue("bankAccount", e.target.value);
                            setFormData({
                              ...formData,
                              bankAccount: e.target.value,
                            });
                          }}
                        />
                      )}
                    />
                    {errors.bankAccount && (
                      <FormText color="danger">
                        {" "}
                        {errors[`bankAccount`]?.message}
                      </FormText>
                    )}
                  </FormGroup>
                )}
              </div>
              {/* <div className="data-col data-col-end"> */}
              {/* <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span> */}
              {/* </div> */}
            </div>
            <div className={`${styles['custom-item']} data-item px-0`}>
              <div className="data-col">
                <span className={styles['data-label']}>QR tài khoản ngân hàng</span>
                <Dropzone onDrop={handleDrop} accept="image/*" multiple={false}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      {selectedFile ? (
                        renderPreviewImage()
                      ) : (
                        <div className="d-flex justify-content-center align-items-center" style={{ width: "135px", height: "40px", borderRadius: "4px", backgroundColor: "#F3F4F6", color: "#2F80ED" }}>
                          <img src={qr} style={{ marginRight: "8px" }} />   Tải ảnh QR
                        </div>
                      )}
                    </div>
                  )}
                </Dropzone>
              </div>
              {/* <div className="data-col data-col-end"> */}
              {/* <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span> */}
              {/* </div> */}
            </div>

            <div className={styles['verify-container']}>
              <div className="custom-control custom-control-sm custom-checkbox mt-2 ">
                <input
                  type="checkbox"
                  className="custom-control-input form-control"
                  id="policy"
                  // checked={formData.checkPolicy}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      checkPolicy: e.target.checked,
                    })
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="policy"
                  style={{ color: "#111827" }}
                >
                  Tôi cam kết rằng thông tin được gửi ở trên là đúng sự thật!
                </label>
              </div>
              <p className="text-danger"
                style={{ fontSize: "12px", marginBottom: "0px", marginLeft: "28px" }}>{errorMessage}</p>
              <div className="mt-5">
                {isLogin ? (
                  <Button
                    type="submit"
                    className="px-4"
                    style={{ background: "#9CA3AF" }}
                  >
                    Chỉnh sửa
                  </Button>
                ) : (
                  <button
                    type="submit"
                    className={`${styles['button--submit']}`}
                    disabled={loading}
                  >
                    Đăng ký  {loading && <Spinner size="sm" color="light" />}
                  </button>
                )}
              </div>
            </div>


          </Form>
        </div>
      </Block>
    </React.Fragment>
  );
};

export default UserProfileIrisPage;
