import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: 0,
    avatar: null
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        incrementByAmount: (state, action) => {
            state.value += action.payload
        },
        updateAvatarUser: (state, action) => {
            state.avatar = action.payload.avatar
        },
    },
})

// Action creators are generated for each case reducer function
export const { incrementByAmount, updateAvatarUser } = userSlice.actions

export default userSlice.reducer