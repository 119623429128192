import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import { Row, Col, RSelect } from "../../../components/Component";
import style from "./index.module.scss";
import { useDispatch } from "react-redux";
import { Getcategories, Getplaylists } from "../../../api/createPost"
import {
  setYoutubeOption
} from "../../reducers/reelsSlice";
const YoutubeVideoOption = ({ channels, youtubeOption, setDefaultYouTubeOption, defaultYoutubeOption }) => {
  const channel = channels.find(c => c.platform === 5) // youtube
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const [tagString ,setTagString] = useState("");
  const Privacy = [
    { value: "public", label: "Công khai" },
    { value: "private", label: "Riêng tư" },
    { value: "unlisted", label: "Không công khai" },
  ]
  const [openOptionYoutube, setOpenOptionYoutube] = useState(true)
  const handleOpenOptionYoutube = () => {
    setOpenOptionYoutube(!openOptionYoutube)
    const Elmchevron = document.getElementById(`chevron_optionTikTok`);
    const Elmcontent = document.getElementById("content_optionTikTok");

    if (!openOptionYoutube) {
      Elmchevron.classList.add(`${style.openChevronDown}`)
      Elmchevron.classList.remove(`${style.openChevronUp}`)
      Elmcontent.classList.add(`${style.openContentDown}`)
      Elmcontent.classList.remove(`${style.openContentUp}`)
    }
    else {
      Elmchevron.classList.add(`${style.openChevronUp}`)
      Elmchevron.classList.remove(`${style.openChevronDown}`)
      Elmcontent.classList.add(`${style.openContentUp}`)
      Elmcontent.classList.remove(`${style.openContentDown}`)
    }
  }

  useEffect(() => {
    channel && loadCategories(channel._id, channel.project_id)
    channel && loadPlaylists(channel._id, channel.project_id)
  }, [channel?._id])
  const loadCategories = async (idChannel, projectId) => {
    const data = {
      project_id: projectId
    }
    const response = await Getcategories(idChannel, data);
    const listCategories = response.data.map((c) => (
      {
        value: c.id,
        label: c?.snippet?.title
      }
    ))
    setCategories(listCategories)
  }
  const handleChangeOption = (e, field) => {
    let newData = { ...defaultYoutubeOption };
    if (field === "title") {
      newData.title = e.value
    } else if (field === "description") {
      newData.description = e.value
    } else {
      newData.visibility = e.value
    }
    setDefaultYouTubeOption(newData)
  }
  const loadPlaylists = async (idChannel, projectId) => {
    const data = {
      project_id: projectId
    }
    const response = await Getplaylists(idChannel, data);
    const listPlaylists = response.data.map((c) => (
      {
        value: c.id,
        label: c?.snippet?.title
      }
    ))
    setPlaylists(listPlaylists)
  }
  useEffect(()=>{
    const tagY = tagString.split(".");
    const youtubeTagOption = {
      ...youtubeOption,
      tags : [...tagY],
    } 

    dispatch(setYoutubeOption(youtubeTagOption));
  },[tagString])
  return (
    <Card className="card-bordered mb-2">
      <div className="d-flex align-items-stretch border-bottom">
        <div
          className="p-2 fw-medium"
          style={{ color: "#333333", fontFamily: "Roboto" }}
          onClick={handleOpenOptionYoutube}
        >
          Tùy chỉnh Youtube
        </div>
      </div>
      <div id="content_optionTikTok" style={{
        transition: "max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s",
        willChange: "max-height",
        position: "relative",
        overflow: "auto"
      }}>
        <div className={style.OptionYoutube}>
          <Row className={`pb-2 mb-2`}>
            <Col md="12">
              <label className={style.OptionYoutube__title}>Tags</label>
              <input
                className="form-control"
                placeholder="Mỗi tags cách nhau bằng dấu chấm (.)" 
                onChange={(e) => {setTagString(e.target.value)}}
                />
            </Col>
          </Row>
          <Row className={`pb-2 mb-2`}>
            <Col md="6">
              <label className={style.OptionYoutube__title}>Chế độ</label>
              <RSelect
                options={Privacy}
                placeholder="Chọn chế độ xem"
                onChange={(e) => handleChangeOption(e, 'visibility')}
              />
            </Col>
            <Col md="6" className="m-auto">
              <label className={style.OptionYoutube__title}>Danh mục</label>
              <RSelect
                options={categories}
                placeholder="Chọn danh mục"
                onChange={(e) => {
                  youtubeOption = { ...youtubeOption, category_id: e.value }
                  dispatch(setYoutubeOption(youtubeOption));
                }}
              />
            </Col>
          </Row>
          {
            channels.filter(c => c.platform === 5).length < 2 &&
            <Row className={`pb-2 mb-2`}>
              <Col md="6">
                <label className={style.OptionYoutube__title}>Playlists</label>
                <RSelect
                  options={playlists}
                  placeholder="Chọn Playlist"
                  onChange={(e) => {
                    youtubeOption = { ...youtubeOption, playlist_id: e.value }
                    dispatch(setYoutubeOption(youtubeOption));
                  }}
                />
              </Col>
            </Row>
          }
          <Row className={`pb-2 mb-2`}>
            <Col md="6">
              <label className={style.OptionYoutube__title}>Khán giả</label>
              <div onClick={
                (e) => {
                  youtubeOption = { ...youtubeOption, made_for_kids: e.target.value === "true" ? true : false }
                  dispatch(setYoutubeOption(youtubeOption));
                }
              }>
                <div className="d-flex">
                  <input
                    value="true"
                    id="audien"
                    name="audien"
                    className="mr-2"
                    type="radio" />
                  <span className={style.OptionYoutube__radio}>Dành cho trẻ em</span>
                </div>
                <div className="d-flex">
                  <input
                    value="false"
                    id="audien"
                    name="audien"
                    className="mr-2"
                    type="radio" />
                  <span className={style.OptionYoutube__radio}>Không dành cho trẻ em </span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div id="chevron_optionTikTok" className={`card-tools position-absolute p-2 top-0 right-0 ${style.openMedia}`} onClick={handleOpenOptionYoutube}>
        <svg width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" /> </svg>
      </div>
    </Card>
  );
};
export default YoutubeVideoOption;
