import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { findUpper } from "../../../utils/Utils";
import { UserAvatar } from "../../../../components/Component";
import UserSelect from "./UserSelect";
import { getRandomTheme } from "./KanbanSectionForm";
import "../styles.css";

const SubcriberDropdown = ({
  listDetail,
  staffOptions,
  selectedSubItems,
  handleSubItemClick,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));

  const { t } = useTranslation();
  const selectRef = useRef(null);

  const [isOpenSubcriber, setIsOpenSubcriber] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  useEffect(() => {
    const handleLeaveOutside = (event) => {
      if (
        isOpenDropdown &&
        selectRef.current &&
        !selectRef.current.contains(event.target)
      ) {
        setIsOpenDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleLeaveOutside);

    return () => {
      document.removeEventListener("mousedown", handleLeaveOutside);
    };
  }, [isOpenDropdown]);

  const toggleDropdown = () => {
    setIsOpenDropdown(!isOpenDropdown);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: listDetail?.subcribers?.length > 3 ? "240px" : "auto",
      }}
    >
      <div
        className={`dropup ${isOpenDropdown ? "show" : ""}`}
        style={{ width: "100%" }}
        onClick={() =>
          // userInfo.id === listDetail?.owner_id &&
          setIsOpenSubcriber(!isOpenSubcriber)
        }
        ref={selectRef}
      >
        <a
          href="#toggle"
          className="dropdown-toggle relative"
          onClick={(ev) => ev.preventDefault()}
        >
          <div className="user-avatar-group">
            {listDetail?.subcribers?.slice(0, 3).map((user, index) => (
              <UserAvatar
                key={index}
                className="xs"
                text={findUpper(user?.name || user?.label)}
                image={user?.avatar}
                // theme={getRandomTheme()}
              />
            ))}

            {listDetail?.subcribers?.length > 3 && (
              <div
                className="xs d-flex items-center absolute"
                style={{
                  backgroundColor: "#eeeeee",
                  fontSize: "10px",
                  color: "#212529",
                  padding: "4px",
                  borderRadius: "100px",
                  left: "56px",
                  border: "2px solid #fff",
                }}
              >
                + {listDetail?.subcribers?.length - 3}{" "}
                {t("task_management.detail.subscribers")}
              </div>
            )}
          </div>
        </a>
        <div className={`dropdown-menu ${isOpenDropdown ? "show" : ""}`}>
          <ul className="link-list-opt no-bdr p-3 g-2">
            {listDetail?.subcribers?.map((user, index) => (
              <li key={index}>
                <div
                  className="user-card"
                  onClick={() => {
                    // userInfo.id === listDetail?.owner_id &&
                    toggleDropdown();
                  }}
                >
                  <UserAvatar
                    className="xs"
                    text={findUpper(user?.name || user?.value)}
                    image={user?.avatar}
                    // theme={getRandomTheme()}
                  />
                  <div className="user-name">
                    <span className="tb-lead">{user?.name || user?.value}</span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <UserSelect
        isOpen={isOpenSubcriber}
        setIsOpen={setIsOpenSubcriber}
        data={listDetail?.owner}
        options={staffOptions}
        handleSelect={handleSubItemClick}
        placeholder={t("task_management.placeholder.add_subscribers")}
        direction={"dropup"}
        selectedItems={selectedSubItems}
        isMulti={true}
        disabled={userInfo.id !== listDetail?.owner_id}
      />
    </div>
  );
};

export default SubcriberDropdown;
