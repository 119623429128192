import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { TabContent, TabPane } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../../components/Component";
import { bulkActionOptions } from "../../../utils/Utils";
import { useTranslation } from "react-i18next";
import { setTabNumber } from "../../reducers/layoutSlice";
import IntegrateList from "../integrate-manage/IntegrateList";
// import ConfigForm from "../integrate-manage/ConfigForm";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

const IntegrateTab = () => {
  const { t } = useTranslation();
  const history = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const url = useQuery();
  const linkUrl = url.get("url");
  //   const dispatch = useDispatch();
  //   const tabSelectedNumber = useSelector((state) => state.layout.tabSelectedNumber);
  //   bulkActionOptions[0].label = t('tags.delete')
  //   bulkActionOptions[1].label = t('tags.in_active')

  //   useEffect(() => {
  //     const newsType = "taskIntegrated";
  //     dispatch(setTabNumber({ newsType: newsType, tabSelectedNumber: "1" }));
  //   }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div style={{ marginTop: "75px", padding: "24px" }}>
      <h3
        style={{
          marginBottom: "12px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "10px",
          cursor: "pointer",
        }}
        onClick={linkUrl && handleBack}
      >
        {linkUrl && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="26"
            height="26"
            fill="currentColor"
          >
            <path d="M7.82843 10.9999H20V12.9999H7.82843L13.1924 18.3638L11.7782 19.778L4 11.9999L11.7782 4.22168L13.1924 5.63589L7.82843 10.9999Z"></path>
          </svg>
        )}
        {t("tags.tag_integrated")}
      </h3>
      <IntegrateList
      //  onChangeTab={(tabNumber) => {
      //             dispatch(setTabNumber({ tabSelectedNumber: tabNumber }));
      //           }}
      />
      {/* <Head title={t('tags.tag_integrated')}></Head> */}
      {/* <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                {tabSelectedNumber === "1" && t('tags.integrate_management')}
                {tabSelectedNumber === "3" && t('tags.config_form')}
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block size="lg">
          <TabContent activeTab={tabSelectedNumber}>
            <TabPane tabId="1">
              <IntegrateList onChangeTab={(tabNumber) => {
                dispatch(setTabNumber({ tabSelectedNumber: tabNumber }));
              }} />
            </TabPane>
            {/* <TabPane tabId="2">
              <ConfigForm />
            </TabPane> */}
      {/* <TabPane tabId="3">
              <p>

              </p>
            </TabPane>
          </TabContent>
        </Block>
      </Content> */}
    </div>
  );
};
export default IntegrateTab;
