import React from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Col } from "reactstrap";

import { LoadingItem } from "../../CommonItem/LoadingItem";

const ContentSpread = (props) => {
  const { chartData, options, isLoading } = props;

  const { t } = useTranslation();
  return (
    <>
      <React.Fragment>
        {/* <Row> */}
        {/* <Col> */}
        <Card className="card-height-100">
          <CardHeader className="border-0 align-items-center bg-slate-50 d-flex">
            <h4
              className="card-title mb-0 flex-grow-1"
              style={{ fontSize: "16px" }}
            >
              {t("overview.content_spread.summary.impressions")}
            </h4>
          </CardHeader>

          <CardBody className="p-0 pb-2">
            <div className="w-100">
              {isLoading ? (
                <LoadingItem />
              ) : (
                <ReactApexChart
                  dir="ltr"
                  options={options}
                  series={chartData}
                  type="line"
                  height="345"
                  className="apex-charts"
                />
              )}
            </div>
          </CardBody>
        </Card>
        {/* </Col> */}
        {/* </Row> */}
      </React.Fragment>
    </>
  );
};
export default ContentSpread;
