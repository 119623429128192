import React from "react";
import PostFastNewReel from "../CreatePost/PostFastNew";

const BulkNormal = () => {
  return (
    <div style={{ marginTop: "75px" }}>
      <PostFastNewReel />
    </div>
  );
};

export default BulkNormal;
