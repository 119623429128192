import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    project: {},
    currentBill: {},
    listPrice: [],
    notifications: [],
    showGiftTrial: false
}

export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        setProject: (state, action) => {
            state.project = action.payload
        },
        setBill: (state, action) => {
            state.currentBill = action.payload
        },
        setListPrice: (state, action) => {
            state.listPrice = action.payload
        },
        setListNotification: (state, action) => {
            state.notifications = action.payload
        },
        setShowGiftTrial: (state, action) => {
            state.showGiftTrial = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setProject, setBill, setListNotification, setShowGiftTrial, setListPrice } = projectSlice.actions

export default projectSlice.reducer