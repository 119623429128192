import { Col, Input, Row, UncontrolledTooltip } from "reactstrap";

import SQUARE_PINTEREST from "../../../images/post/pinterest-square.svg";
import { useEffect } from "react";
import { PLATFORM_PINTEREST } from "../../../utils/Utils";
import { fetchUserInfoLogin } from "../../../../libs/helper";
import { GetPinterestBoard } from "../../../../api/createPost";
import { Img, RSelect } from "../../../../components/Component";
import { useTranslation } from "react-i18next";

const PinterestSetting = ({ formData, setFormData, channels, setChannels }) => {
  const { t } = useTranslation();
  const userInfor = fetchUserInfoLogin();
  const channel = channels?.channel?.find(
    (c) => c.platform === PLATFORM_PINTEREST && c.check === true
  );
  let pinterestChannel = channels?.channel?.filter(
    (c) => c.platform === PLATFORM_PINTEREST && c.check === true
  );

  useEffect(() => {
    pinterestChannel && loadBoard();
  }, [channels]);

  const fetchListBoard = async ({ channelId, bookMark, count }) => {
    if (count === 5) {
      return [];
    }

    const channel = channels?.channel?.find((c) => c._id === channelId);

    if (channel && channel?.listBoard) {
      return;
    }
    let data = {
      params: {
        project_id: userInfor.last_project_active,
        ...(bookMark && { bookmark: bookMark }),
      },
    };

    try {
      const res = await GetPinterestBoard({ channelId: channelId, data });
      if (res.status) {
        const newRes = [...res.data.items];
        let finalRes = [];
        if (res.data.bookmark) {
          finalRes = await fetchListBoard({
            channelId,
            bookMark: res.data.bookmark,
            count: count + 1,
          });
        } else {
          finalRes = [...newRes, ...finalRes];
          const listOption = finalRes.map((c) => ({
            value: c.id,
            label: c?.name,
          }));
          let pinChannels = channels?.channel?.filter(
            (c) => c.platform === PLATFORM_PINTEREST && c.check === true
          );
          pinChannels = pinChannels.map((item) => {
            if (item._id === channelId) {
              item.listBoard = listOption;
            }
            return item;
          });
          let data = [...channels.channel];
          data = data.map((item) => {
            if (item._id === channelId) {
              item.listBoard = listOption;
            }
            return item;
          });
          setChannels({ ...channels, channel: data });
        }
        return [...newRes, ...finalRes];
      } else {
        console.log("get board fail");
        return [];
      }
    } catch (error) {
      console.log("get board fail", error);
      return [];
    }
  };
  const loadBoard = async () => {
    const promises = pinterestChannel.map((channel) =>
      fetchListBoard({ channelId: channel._id, count: 1 })
    );
    const results = await Promise.allSettled(promises);

    results.forEach((result, index) => {
      if (result.status === "fulfilled") {
      } else {
        console.error(`Failed to fetch playlist for channelId${index + 1}`);
      }
    });
  };

  const handleSelectBoard = (e, item) => {
    let newData = [...channels?.channel];
    newData = newData.map((channel) => {
      if (channel._id === item._id) {
        channel.boardId = e.value;
      }
      return channel;
    });
    setChannels({ ...channel, channel: newData });
  };

  return (
    <div className={`rounded  mt-3 p-2 color-border border-1`}>
      <div className="d-flex justify-content-between gap-3">
        <div className="d-flex align-items-center">
          <div
            className="d-flex justify-content-center align-items-center rounded position-relative"
            style={{
              background: "rgba(243,246,249,1)",
              width: "36px",
              height: "36px",
            }}
          >
            <Img src={SQUARE_PINTEREST} height="16px"></Img>
          </div>
          <p className="fs-13 ms-2 mb-0" style={{ color: "#495057", fontWeight: 600 }}>
            {t('store_post.setting.pinterest.title')}
          </p>
        </div>
      </div>

      <Row className="mt-2" style={{ marginLeft: '32px' }}>
        <Col md={12} sm={12} className="mt-2">
          <Row>
            <Col md={2} className="m-auto">
              <p className="text-body fs-13 mb-0">
                {t('store_post.setting.pinterest.pin_title')}
              </p>
            </Col>
            <Col md={10}>
              <div className="rounded bg-[#F06548]">
                <Input
                  type="text"
                  className="border-0 hover:border-0 focus:border-0 bg-[#F3F6F9]"
                  value={formData.title}
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                  style={{
                    marginLeft: formData.title.length === 0 ? "4px" : "0px",
                  }}
                />
              </div>
            </Col>
          </Row>
        </Col>
        {pinterestChannel &&
          pinterestChannel.map((item, key) => (
            <Col md={6} sm={12} className="mt-2">
              <Row>
                <Col md={4}>
                  <div
                    style={{ height: "36px", width: "36px" }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={item.name}
                  >
                    <Img
                      src={item.picture}
                      style={{ height: "36px", width: "36px" }}
                      className="rounded-circle"
                      id={`channel-pinterest-${key}`}
                    ></Img>
                    <UncontrolledTooltip
                      target={`channel-pinterest-${key}`}
                      style={{
                        color: "#ffffff",
                      }}
                    >
                      {item.name}
                    </UncontrolledTooltip>
                  </div>
                </Col>
                <Col md={8}>
                  <RSelect
                    value={item?.listBoard?.find(
                      (c) => c.value === item.boardId
                    )}
                    options={item?.listBoard}
                    onChange={(e) => handleSelectBoard(e, item)}
                    placeholder={t('store_post.setting.pinterest.placeholder_board')}
                  ></RSelect>
                </Col>
              </Row>
            </Col>
          ))}
      </Row>
    </div>
  );
};

export default PinterestSetting;
