import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, ModalBody } from "reactstrap"


import style from "./index.module.scss";
import { Icon, Img } from "../../../components/Component";

import Expried from "../../images/post/expriedProject.png"
import MessageIcon from "../../images/post/mesageIcon.png"
import { Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { t } from "i18next";
const ModalExpied = () => {

    const history = useHistory();
    const location = useLocation();

    const project = useSelector((state) => state.project.project);

    const [modal, setModal] = useState(false);


    const toggle = () => {
        setModal(!modal);
    }
    const handleChangleLink = (link) => {
        history.push(link);
    }
    useEffect(() => {
        if (location.pathname === "/admin/project/pricing" || location.pathname === "/admin/project/list" || location.pathname === "/admin/project/payment") {
            setModal(false);
        } else {

            if (Object.keys(project).length === 0) {
                return;
            }
            const timeNow = moment(new Date()).unix()
            if (project?.expired_at > timeNow) {
                setModal(false)
            }
            else {
                setModal(true);
            }

        }
    }, [project, location])

    // useEffect(() => {
    //     if (location.pathname === "/admin/project/pricing" || location.pathname === "/admin/project/list" || location.pathname === "/admin/project/payment") {
    //         setModal(false);
    //     } else {
    //         const timeNow = moment(new Date()).unix();
    //         if (project && project?.expired_at > timeNow) {
    //             setModal(false)
    //         }
    //         else {
    //             setModal(true);
    //         }

    //     }
    // }, [location])
    return (
        <Modal isOpen={modal} toggle={toggle} backdrop={"static"} size="lg"
            className={style.modalwaper}
        >
            <ModalBody >
                <div className={`${style.connectModalContainer}`}>
                    <div className={`${style.connectModalContainer__header}`}>
                        <div className="d-flex align-items-center">
                            <h3>{t("components.model_expied.header")}</h3>
                        </div>
                        <p>{t("components.model_expied.sub_header")}</p>
                    </div>

                    <div className={`${style.expiredContent} d-flex `}>

                        <img src={Expried} />


                        <div className={`${style.expiredContentButton}`}>
                            <Button
                                onClick={() => handleChangleLink("/admin/project/pricing")}
                                className="d-flex justify-content-center align-items-center "
                            >
                                <em class="icon ni ni-sign-mxn mr-1"></em>
                                <Link to="/admin/project/pricing"
                                >{t("components.model_expied.payment")}</Link>
                            </Button>

                            <Button
                                onClick={() => { handleChangleLink("/admin/project/list") }}
                                className="d-flex justify-content-center align-items-center"
                            >
                                <em class="icon ni ni-swap mr-1"></em>
                                <Link to="/admin/project/list">{t("components.model_expied.change_workspace")}</Link>
                            </Button>
                        </div>
                    </div>
                    <div className={`${style.connectModalFooter} mt-4`}>
                        <Img src={MessageIcon}></Img>
                        <p className="ml-1"
                            onClick={() => {
                                window.open(
                                    "https://www.facebook.com/login.php?next=https%3A%2F%2Fwww.facebook.com%2Fmessages%2Ft%2F103638805810736%2F",
                                    "_blank"
                                );
                            }}
                        >{t("components.model_expied.you_need_support")}</p>
                    </div>

                </div>
            </ModalBody>

        </Modal>
    )
}

export default ModalExpied;