
import { Link, useHistory } from "react-router-dom";
import { Button, Modal, ModalBody, Card, CardBody } from "reactstrap";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
const ModalAlertBusiness = ({ ...props }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const handleClickUpdate = () => {
        history.push("/admin/project/pricing");
    }
    return (
        <div className={`${styles.cardAlertContainer}`}>
            <Card className={`${styles.cardAlerBus}`}>
                <CardBody
                >
                    <div
                    >
                        <div
                            style={{ width: "100%", height: "fit-content", marginBottom: "24px" }}
                        >
                            <div style={{ fontSize: "20px", fontWeight: "600", fontFamily: "Inter,sans-serif", lineHeight: "28px", marginBottom: "8px" }}>
                                <span style={{ color: "#2E0B34" }}>Tính năng dành riêng cho gói  <span style={{ color: "#2C4B94" }}>SO9 Business</span></span>
                            </div>
                            {/* <iframe width="100%" height="339"
                                src="https://www.youtube.com/embed/0jjopJXQvqo"
                                title="🇱🇦 No nê Chợ Đêm ở Luang Prabang |Du lịch ẩm thực Luang Prabang Lào #5"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                                style={{ marginBottom: "8px" }}
                            >
                            </iframe> */}

                            <iframe width="100%" height="339"
                                src="https://www.youtube.com/embed/9qkpn2M5IIc"
                                title="Báo cáo chuyên sâu (Gói Business)"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                                style={{ marginBottom: "8px" }}
                            >

                            </iframe>
                            <p style={{
                                fontWeight: "600",
                                lineHeight: "24px",
                                fontSize: "16px",
                                fontFamily: "Inter,sans-serif",
                                marginBottom: "0px",
                                color: "#2E0B34",
                            }}>
                                {props.type === "content" ? ("  Quản lý nội dung chuyên sâu👇:") :
                                    ("Quản lý nhân sự👇:")}

                            </p>
                            <p
                                style={{
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    fontSize: "16px",
                                    fontFamily: "Inter,sans-serif",
                                    color: "#2E0B34",
                                }}
                            >
                                {props.type === "content" ? (`Top bài đăng được nhiều tương tác, bình luận nhất.
                            Quản lý toàn bộ hiệu quả các bài viết với giao diện cực kỳ đơn giản và thân thiện`) :
                                    (`Top bài đăng được nhiều tương tác, bình luận nhất. Quản lý toàn bộ hiệu quả các bài viết với giao diện cực kỳ đơn giản và thân thiện`)}
                            </p>
                        </div>
                        <div
                            style={{ width: "100%", height: "fit-content" }}
                            className="d-flex justify-content-end "
                        >
                            <Button
                                style={{ backgroundColor: "#185DDC" }}
                                onClick={handleClickUpdate}
                            >
                                {t('common.upgrade_plan')}
                            </Button>
                        </div>

                    </div>


                </CardBody>
            </Card>
        </div>
    );
};

export default ModalAlertBusiness;
