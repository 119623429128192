
import { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import { fetchUserInfoLogin } from "../../../../libs/helper";
import { Img } from "../../../../components/Component";
import { getListPostComment, storePostComment } from "../../../../api/postNew";
import UploadImageInput from "./UploadImagesInput";
import { RiAtLine, RiAttachment2 } from "@remixicon/react";




const Comment = ({ postId }) => {

    const [image, setImage] = useState("");
    const [listComment, setLisComment] = useState([]);
    const [comment, setComment] = useState("");

    const userInfor = fetchUserInfoLogin();

    const handleChange = (e) => {
        const value = e.target.value.replace(/\n/g, "\\n");
        setComment(value);
    };

    const fetchCommentList = async (page) => {
        if (!postId) {
            return;
        }
        let resData = [];
        try {
            while (true) {
                const res = await getListPostComment({ projectId: userInfor.last_project_active, postId: postId, data: { page: page } });
                if (res.status) {
                    resData = [...resData, ...res.data.data]
                    if (res?.data?.data.length === 0) {
                        // Dừng vòng lặp nếu res.data rỗng
                        break;
                    }
                    page++;
                } else {
                    console.log("error comment");
                    break;
                }
            }
            setLisComment(resData);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchCommentList(1);
    }, [])
    console.log(listComment);


    const renderItem = (item) => {
        return (
            <div className="px-2 pt-2 pb-3 justify-content-start d-flex rounded  bg-white border mb-3">
                <Img src={item?.user?.avatar} className={`rounded-circle bg-light position-relative`}
                    style={{ height: "32px", width: "32px" }}>
                </Img>
                <div className="ms-2 w-75">
                    <p className="mb-0 text-body text-truncate fs-10 fw-bold mb-1">{item?.user?.name}<span className="ms-1 text-body fs-10" style={{ fontWeight: "400" }}>9:38, Jan 2 2024</span></p>
                    <p className="mb-0 text-body text-break text-wrap fs-10 mb-1">{item?.message}</p>
                    {
                        item?.image_url && <Img className="rounded" src={item.image_url} height={"36px"} width={"36px"}></Img>
                    }
                </div>
            </div>
        )
    }


    const handleAddComment = async (e) => {
        e.preventDefault();
        try {
            let data = {
                message: comment,
            }

            if (image !== "") {
                data.image_url = image;
            }

            const res = await storePostComment({ projectId: userInfor.last_project_active, postId: postId, data: data });
            if (res.status) {
                setComment("");
                setImage("");
                setLisComment([...listComment, res.data]);
            } else {
                console.log("error channel");
            }

        } catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            {postId &&
                <form className="w-100" onSubmit={(e) => handleAddComment(e)}>
                    <Input type="textarea" value={comment}

                        onChange={handleChange}
                        placeholder="Write a comment..."

                        style={{ resize: "none", height: "72px", whiteSpace: 'pre-line' }} ></Input>
                    <div className="d-flex justify-content-end gap-2" style={{ padding: "10px 0" }}>
                        <label className="mb-0" htmlFor="post-comment-image">
                            <RiAttachment2 className="cursor-pointer" size={16}></RiAttachment2>
                        </label>
                        <RiAtLine size={16} className="cursor-pointer"></RiAtLine>
                    </div>

                    <div className="w-100 d-flex justify-content-end ">
                        <Button type="submit">Add Comment</Button>
                    </div>
                    <UploadImageInput
                        folder="post"
                        setSelectImage={setImage}
                        id={'post-comment-image'}
                        isDisplay={false} />
                    <div className="mt-5">
                        {
                            listComment && listComment?.map((item) => (
                                renderItem(item)
                            ))
                        }
                    </div>
                </form>}
        </>
    )
}

export default Comment;