import React, { useState } from "react";
import { components } from 'react-select';
import { Card } from "reactstrap";
import { Row, Col, RSelect, Img } from "../../../components/Component";
import {
  NGAU_NHIEN,
  THU_CONG,
} from "../../utils/Utils";
import DefaultChannelAvatar from "../../images/avatar/default-channel-avatar.png";
import style from "./index.module.scss";
const ModeFaceGroup = ({ channels, setSelectedUserPostOption, selectedUserPostOption, onUpdateChannel }) => {
  const { SingleValue, Option } = components;
  const typePost = [
    { value: NGAU_NHIEN, label: "Chọn người đăng ngẫu nhiên" },
    { value: THU_CONG, label: "Chọn thủ công người đăng" },
  ]
  const [openFaceGroup, setOpenFaceGroup] = useState(true)
  const [showModeFaceGroup, setShowModeFaceGroup] = useState(false);

  const handelChevronFaceGroup = () => {
    setOpenFaceGroup(!openFaceGroup)
    const Elmchevron = document.getElementById("chevron_modeGroup");
    const Elmcontent = document.getElementById("content_modeGroup");
    if (!openFaceGroup) {
      Elmchevron.classList.add(`${style.openChevronDown}`)
      Elmchevron.classList.remove(`${style.openChevronUp}`)
      Elmcontent.classList.add(`${style.openContentDown}`)
      Elmcontent.classList.remove(`${style.openContentUp}`)
    }
    else {
      Elmchevron.classList.add(`${style.openChevronUp}`)
      Elmchevron.classList.remove(`${style.openChevronDown}`)
      Elmcontent.classList.add(`${style.openContentUp}`)
      Elmcontent.classList.remove(`${style.openContentDown}`)


    }
  }

  const handleModeFaceGroup = (mode) => {
    if (mode.value === NGAU_NHIEN) {
      channels.map((channel, index) => {
        if (channel.platform === 3)
          onUpdateChannel(index, { user_publish_id: undefined });
      })
      setShowModeFaceGroup(false)
    }
    if (mode.value === THU_CONG) {
      setSelectedUserPostOption({})
      setShowModeFaceGroup(true);
      setOpenFaceGroup(true)
      const Elmchevron = document.getElementById("chevron_modeGroup");
      const Elmcontent = document.getElementById("content_modeGroup");
      Elmchevron.classList.add(`${style.openChevronDown}`)
      Elmchevron.classList.remove(`${style.openChevronUp}`)
      Elmcontent.classList.add(`${style.openContentDown}`)
      Elmcontent.classList.remove(`${style.openContentUp}`)
    }
  }

  const StyleInput = ({
    children,
    ...props
  }) => (
    <components.SingleValue {...props}>
      <div className={style.spanDropdown}>
        {children}
      </div>
    </components.SingleValue>
  );
  const CustomOption1 = ({ children, ...props }) => {
    const { data } = props,

      spanStyle = {
        fontSize: "12px",
        fontWeight: 500,
        color: "#526484",
        transition: "all 0.4s",
        lineHeight: "1.3rem"
      }
    return (
      <components.Option {...props}>

        <span style={spanStyle}>{data.label}</span>

      </components.Option>
    );
  };

  const IconSingleValue = ({ data, ...props }) => (
    <SingleValue {...props}>
      <Img className='mr-1 img-fluid' src={data?.picture} width= "20px" size="circle" />
      {data?.name}
    </SingleValue>
  );
  const OptionComponent = ({ data, ...props }) => {
    return (
      <Option {...props}>
        <Img className='mr-1 img-fluid' src={data?.picture} width= "20px" size="circle" />
        {data?.name}
      </Option>
    )
  }
  return (
    <Card className="card-bordered mb-2">
      <div className="d-flex align-items-stretch border-bottom">
        <div
          className={`p-2 fw-medium ${style.postModeSubTitle}`}
          onClick={handelChevronFaceGroup}
        >
          Chế độ hội nhóm Facebook:
        </div>
        <div className={`form-control-wrap ${style.Head__typepost} my-auto w-1/3`}>
          <RSelect
            options={typePost}
            components={{
              SingleValue: StyleInput,
              Option: CustomOption1,
            }}
            defaultValue={
              typePost[0]
            }
            onChange={(e) => {
              handleModeFaceGroup(e);
            }}
          />
        </div>
      </div>
      <div id="content_modeGroup" style={{
        display: showModeFaceGroup ? "block" : "none", transition: "max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s",
        willChange: "max-height",
        position: "relative",
        overflow: "auto"
      }}>
        <div style={{ padding: "16px" }}>
          <Col md="12">
            {
              channels && channels.map((channel, index) => {
                if (channel.platform === 3)
                  return (
                    <Row key={index} className={`pb-2 mb-2`}>
                      <Col md="8">
                        <div className="d-flex justify-content-start">
                          <Img src={channel.picture} width= "32px" size="square" alt="media"
                          />
                          <span className="my-auto ml-2 font-weight-bold">{channel.name}</span>
                        </div>
                      </Col>
                      <Col md="4" className="m-auto">
                        <RSelect
                          name={`userPost_${index}`}
                          onChange={data => {
                            setSelectedUserPostOption({
                              ...selectedUserPostOption,
                              [index]: data
                            })
                            onUpdateChannel(index, { user_publish_id: data.id });
                          }}
                          value={selectedUserPostOption[index] || null}
                          placeholder="Chọn người đăng"
                          options={channel.list_token_allow}
                          className={`react-select`}
                          components={{
                            SingleValue: IconSingleValue,
                            Option: OptionComponent
                          }}
                        />
                      </Col>
                    </Row>
                  )
              })
            }
          </Col>
        </div>
      </div>
      <div id="chevron_modeGroup" className={`card-tools position-absolute p-2 ${style.openMedia}`} style={{
        visibility: showModeFaceGroup ? "visible" : "hidden", top: 0,
        right: 0,
      }} onClick={handelChevronFaceGroup}>
        <svg width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" /> </svg>
      </div>
    </Card>
  );
};
export default ModeFaceGroup;
