import React, { useState, useEffect, Fragment, useRef } from "react";
import { useSelector } from "react-redux";
import style from "./index.module.scss";
import {
  Button, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter
} from "reactstrap";
import DefaultChannelAvatar from "../../images/avatar/default-channel-avatar.png";
import { useTranslation } from "react-i18next";
import { updatePriority } from "../../../api/channel";
import ModalAlertSuccess from "./ModalAlertSuccess";
import ModalAlertFailed from "./ModalAlertFailed";

const standardOrder = (dataListChannelInfo) => {
  let initialOrder = {};
  if (dataListChannelInfo) {
    dataListChannelInfo.map((item, index) => {
      initialOrder[item._id] = {
        id: item._id,
        valid: true,
        priority: item.priority || 1
      }
    });
  }
  return initialOrder;
}

export default function ModalSortingChannel({ openModal, setOpenModal }) {
  const { t } = useTranslation();
  const counterError = useRef(0);
  const userInfor = JSON.parse(localStorage.getItem('userInfor'));
  const dataListChannel = useSelector((state) => state.channel.channelsInformation);
  const [listOrderChannel, setListOrderChannel] = useState({});
  const [isSubmitOrder, setSubmitOrder] = useState(true);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalFailed, setModalFailed] = useState(false);
  const toggleSuccess = () => {
    setModalSuccess(!modalSuccess);
  };
  const toggleFailed = () => {
    setModalFailed(!modalFailed);
  };
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  useEffect(() => {
    setListOrderChannel(standardOrder(dataListChannel));
  }, [dataListChannel]);

  const toggleModalSortingChannel = () => {
    setOpenModal();
  }

  const _handleUpdatePriority = async () => {
    const listChannelPriority = Object.keys(listOrderChannel).map((item, index) => {
      const { id, priority } = listOrderChannel[item];
      return {
        id, priority
      }
    });
    const active = await updatePriority(userInfor.last_project_active, {
      priority_channels: listChannelPriority
    })
    if (active.status) {
      setModalSuccess(true);
    } else {
      setModalFailed(true);
    }
  }

  return (
    <Fragment>
      <Modal isOpen={openModal} toggle={() => toggleModalSortingChannel()} className="modal-lg">
        <ModalHeader toggle={() => toggleModalSortingChannel()}>{t("sorting_channel_title")}</ModalHeader>
        <ModalBody style={{ overflowY: "scroll", maxHeight: "500px", minHeight: "300px" }}>
          <Row className="gx-4 gy-3">
            <Col md="12">
              <Row className="mb-2">
                <Col md="8">
                  <span className="font-weight-bold">KÊNH ĐĂNG</span>
                </Col>
                <Col md="4" className="text-center">
                  <span className="font-weight-bold">THỨ TỰ</span>
                </Col>
              </Row>
              {
                dataListChannel && dataListChannel.map((channel, index) => {
                  return (
                    <Row key={index} className={`${style.captionModal} pb-2 mb-2`}>
                      <Col md="8">
                        <div className="d-flex justify-content-start">
                          <img src={channel.picture || DefaultChannelAvatar} style={{ width: "50px", height: "50px", borderRadius: "4px" }} alt="media"
                          />
                          <span className="my-auto ml-2 font-weight-bold">{channel.name}</span>
                        </div>
                      </Col>
                      <Col md="4" className="m-auto">
                        <input
                          id={`channel_input_order_${index}`}
                          type="number"
                          className="w-100"
                          pattern="[0-9]*"
                          min={1}
                          max={999999999}
                          defaultValue={listOrderChannel[channel._id]?.priority || 1}
                          required
                          placeholder="Nhập thứ tự"
                          onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                          onChange={(e) => {
                            setListOrderChannel({
                              ...listOrderChannel,
                              [channel._id]: {
                                ...listOrderChannel[channel._id],
                                valid: e.target.validity.valid,
                                priority: e.target.validity.valid ? parseInt(e.target.value) : 1
                              }
                            });
                            if (listOrderChannel[channel._id].valid === true && e.target.validity.valid === false) {
                              counterError.current = counterError.current + 1;
                            } else if (listOrderChannel[channel._id].valid === false && e.target.validity.valid === true) {
                              counterError.current = counterError.current - 1;
                            }
                          }}
                        />
                        {listOrderChannel[channel._id] && !listOrderChannel[channel._id].valid && <p className="text-danger text-italic">Giá trị không hợp lệ</p>}
                      </Col>
                    </Row>
                  )
                })
              }
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between flex-nowrap">
          <span className="d-inline-block w-75 pr-5" style={{ lineHeight: "1.45" }}>Ưu tiên hiển thị từ thấp &gt; cao. Bạn có thể đánh chung 1 số thứ tự cho các trang để gom các trang lại gần nhau (VD: 1-1-1-2-3-4-4)</span>
          <div className="w-25">
            <Button style={{ backgroundColor: "#58BFDA", color: "#FFFFFF", marginRight: "1rem", fontWeight: "500", border: "0" }} onClick={(ev) => { ev.preventDefault(); toggleModalSortingChannel(); }}>
              Hủy
            </Button>
            <Button style={{ backgroundColor: "#FD1F6D", color: "#FFFFFF", fontWeight: "500", border: "0" }} disabled={counterError.current !== 0} onClick={(ev) => {
              ev.preventDefault();
              _handleUpdatePriority();
              toggleModalSortingChannel();
            }}>
              Tiếp tục
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <ModalAlertSuccess modalSuccess={modalSuccess} toggleSuccess={toggleSuccess} textNotification={"Bạn đã cập nhật thứ tự kênh thành công"} _handleSubmitModal={() => window.location.reload()} />
      <ModalAlertFailed modalFailed={modalFailed} toggleFailed={toggleFailed} />
    </Fragment>

  )
}