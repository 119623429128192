import React, { useState, useEffect, useMemo } from "react";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  RSelect,
  Img,
} from "../../../components/Component";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setChannelsInformation } from "../../reducers/channelSlice";
import ModalSortingChannel from "../../components/Modal/ModalSortingChannel";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  UncontrolledTooltip,
} from "reactstrap";
import { IconConnectChannel } from "../../components/svgIcon";
import styleConnect from "../../components/ConnectSocialNetwork/index.module.scss";
import style from "./connect.module.scss";
import bigSquaredFacebook from "../../images/icons/big-squared-facebook.svg";
import bigSquaredTiktok from "../../images/icons/big-squared-tiktok.svg";
import bigSquaredPinterest from "../../images/icons/big-squared-pinterest.svg";
import bigThreads from "../../images/icons/big-squared-threads.svg";
import bigInstagram from "../../images/icons/big-squared-instagram.svg";
import zalo from "../../images/icons/zalo.svg";
import Youtube from "../../../images/icons/youtube.png";
import exclamation from "../../images/icons/exclamation.png";
import DefaultVia from "../../images/icons/via-image.svg";
import {
  PLATFORM_FACEBOOK,
  PLATFORM_FACEBOOKGROUP,
  PLATFORM_INSTAGRAM,
  PLATFORM_YOUTUBE,
  PLATFORM_TIKTOK,
  PLATFORM_ZALO,
  PLATFORM_PINTEREST,
  PLATFORM_THREADS,
} from "../../utils/Utils";
import ModalCapChannel from "../../components/Modal/ModalLimitChannel";
import useQuery from "../../../hooks/useQuery";

function useQueryParams() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const ConnectChannel = () => {
  const history = useHistory();
  const [openHandleAcc, setOpenHandleAcc] = useState(false);
  const [openAccGroup, setOpenAccGroup] = useState(false);
  const { t } = useTranslation();
  let params = useQueryParams();
  const dispatch = useDispatch();
  const [sm, updateSm] = useState(false);
  const [trashId, setTrashId] = useState();
  const [token, setToken] = useState({ InfoToken: {}, channel: {} });
  const [openModalAddUserToGroup, setOpenAddUserToGroup] = useState(false);
  const [openModalSortingChannel, setOpenModalSortingChannel] = useState(false);
  const [modalDeleteMultiChannels, setModalDeleteMultiChannels] =
    useState(false);
  const [refretRole, setRefretRole] = useState(false);
  const [showMultiDeleted, setShowMultiDeleted] = useState(false);
  const [tooltipClockCalendarOpen, setTooltipClockCalendarOpen] = useState([]);
  const [listVia, setListVia] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [filterForm, setFilterForm] = useState({
    platforms: [],
    vias: [],
    channelIsError: null,
  });
  const [isCapLimit, setIsCapLimit] = useState(false);
  const [isOpenConnect, setIsOpenConnect] = useState(false);

  const query = useQuery();
  const logged = query.get('logged');

  const onHoverClockCalendar = (index, key) => {
    const newtooltipClockCalendarOpen = [...tooltipClockCalendarOpen];
    newtooltipClockCalendarOpen.map((e, i) => {
      if (e.id === `${index.id}${key}`)
        newtooltipClockCalendarOpen[i].value = true;
    });
    setTooltipClockCalendarOpen(newtooltipClockCalendarOpen);
  };

  const onHoverLeaveClockCalendar = (index, key) => {
    let newtooltipClockCalendarOpen = [...tooltipClockCalendarOpen];
    newtooltipClockCalendarOpen.map((e, i) => {
      if (e.id === `${index.id}${key}`)
        newtooltipClockCalendarOpen[i].value = false;
    });
    setTooltipClockCalendarOpen(newtooltipClockCalendarOpen);
  };

  const toggleTooltipClockCalendarOpen = () => {
    let newtooltipClockCalendarOpen = [...tooltipClockCalendarOpen];
    newtooltipClockCalendarOpen.map((e, i) => {
      newtooltipClockCalendarOpen[i].value = false;
    });
    setTooltipClockCalendarOpen(newtooltipClockCalendarOpen);
  };

  const [modalConnect, setModalConnect] = useState({
    success: false,
    fail: false,
  });

  const OnClickOpennewToken = (Token, channel) => {
    if (channel.platform === 3) {
      setOpenAccGroup(true);
      setToken({ InfoToken: { ...Token }, channel: { ...channel } });
    } else if (!Token.is_active) {
      setOpenHandleAcc(true);
      setToken({ InfoToken: { ...Token }, channel: { ...channel } });
    }
  };

  const platforms = [
    { label: "Facebook", value: PLATFORM_FACEBOOK },
    { label: "Facebook Group", value: PLATFORM_FACEBOOKGROUP },
    { label: "Tiktok", value: PLATFORM_TIKTOK },
    { label: "Zalo", value: PLATFORM_ZALO },
    { label: "Instagram", value: PLATFORM_INSTAGRAM },
    { label: "Youtube", value: PLATFORM_YOUTUBE },
    { label: "Pinterest", value: PLATFORM_PINTEREST },
    { label: "Threads", value: PLATFORM_THREADS },
  ];

  const channelIsErrors = [
    { label: "Lỗi", value: true },
    { label: "Hoạt động", value: false },
  ];

  const handleFilter = ({ value, key }) => {
    let newData = { ...filterForm };
    newData[key] = value;
    setFilterForm(newData);
  };
  const onFilterChannel = () => {
    setIsFirstRender(false);
    let newData = [...channelsInformation];
    if (!newData || !newData.length) {
      return;
    }
    if (filterForm.platforms.length) {
      newData = newData.filter((item) => {
        const platformValues = filterForm.platforms.map(
          (platform) => platform.value
        );
        return platformValues.includes(item.platform);
      });
    }
    if (filterForm.vias.length) {
      newData = newData.filter((item) => {
        return item.list_token_allow.filter((token) =>
          filterForm.vias.map((via) => via.value).includes(token.id)
        ).length;
      });
    }
    if (filterForm.channelIsError !== null) {
      newData = newData.filter(
        (item) => item.is_error === filterForm.channelIsError.value
      );
    }
    newData && setDataListChannel(newData);
  };

  const onCancelFilter = () => {
    setDataListChannel(channelsInformation);
    setFilterForm({ platforms: [], vias: [], channelIsError: null });
  };

  const renderFilter = () => {
    return (
      <>
        {showFilter && (
          <div className={style.containerSearch}>
            <RSelect
              className={style.controlSelect}
              options={platforms}
              value={filterForm.platforms}
              placeholder="Phân loại"
              isSearchable={false}
              isMulti
              onChange={(value) => handleFilter({ value, key: "platforms" })}
            />
            <RSelect
              className={style.controlSelect}
              placeholder="Tình trạng kênh"
              value={filterForm.channelIsError}
              options={channelIsErrors}
              isSearchable={false}
              onChange={(value) =>
                handleFilter({ value, key: "channelIsError" })
              }
            />
            <RSelect
              placeholder="Tài khoản kết nối"
              className={style.controlSelect}
              options={listVia}
              value={filterForm.vias}
              onChange={(value) => handleFilter({ value, key: "vias" })}
              isMulti
            />
            <Button
              className={`${style.btnSearch} ${style.btnFilter}`}
              onClick={onFilterChannel}
            >
              LỌC
            </Button>

            <Button
              className={`${style.btnSearch} ${style.btnRemove} `}
              onClick={onCancelFilter}
            >
              BỎ LỌC
            </Button>
          </div>
        )}
      </>
    );
  };

  const OnClickRefretRole = (channel) => {
    if (channel.platform === 3) {
      let token = {
        id: "",
        name: "VIA mới muốn kết nối",
        is_active: true,
        picture: DefaultVia,
      };
      setToken({ InfoToken: { ...token }, channel: { ...channel } });
    } else {
      setToken({
        InfoToken: { ...channel.list_token_allow[0] },
        channel: { ...channel },
      });
    }
    setOpenHandleAcc(true);
    setRefretRole(true);
  };

  const userInfor = JSON.parse(localStorage.getItem("userInfor"));
  const [modal, setModal] = useState({
    trash: false,
    errorDelete: false,
  });

  useEffect(() => {
    const status = params.get("status");
    if (!status) return;
    if (status === "1") {
      setModalConnect({ ...modalConnect, success: true });
    } else if (status === "-224") {
      history.push("/errors/upgrade-zalo");
    } else {
      setModalConnect({ ...modalConnect, fail: true });
    }
  }, [params]);

  // check connect channel
  const [dataListChannel, setDataListChannel] = useState([]);
  const channelsInformation = useSelector(
    (state) => state.channel.channelsInformation
  );
  const project = useSelector((state) => state.project.project);
  useEffect(() => {
    if (isFirstRender) {
      setDataListChannel(channelsInformation);
    }

    // update render list after delete
    if (modalDeleteMultiChannels) {
      const updatedData = dataListChannel.filter((item) => !item.check);
      setDataListChannel(updatedData);
    }

    if (modal.trash) {
      const updatedData = dataListChannel.filter(
        (item) => item._id !== trashId
      );
      setDataListChannel(updatedData);
    }

    let listTokens = [];
    channelsInformation &&
      channelsInformation.length &&
      channelsInformation.map((channel) => {
        listTokens = [...listTokens, ...channel.list_token_allow];
      });
    if (listTokens.length) {
      listTokens = listTokens.map((token) => {
        return { value: token.id, label: token.name };
      });

      listTokens = [
        ...new Map(listTokens.map((item) => [item.value, item])).values(),
      ];
      setListVia(listTokens);
    }
  }, [channelsInformation]);

  useEffect(() => {
    let newtooltipClockCalendarOpen = [];
    dataListChannel &&
      dataListChannel.length &&
      dataListChannel.map((item, key) => {
        item.list_token_allow.map((index, i) => {
          newtooltipClockCalendarOpen.push({
            id: `${index.id}${key}`,
            value: false,
          });
        });
      });
    setTooltipClockCalendarOpen(newtooltipClockCalendarOpen);
    setShowMultiDeleted(
      dataListChannel?.find((item) => item.check) ? true : false
    );
  }, [dataListChannel]);

  const selectorCheck = (e) => {
    let newData = [...dataListChannel];
    newData = newData.map((item) =>
      Object.assign({}, item, { check: e.target.checked })
    );
    let updatedData = [...channelsInformation];
    newData.forEach((newItem) => {
      const index = updatedData.findIndex((item) => item._id === newItem._id);
      if (index !== -1) {
        updatedData[index] = newItem;
      }
    });
    dispatch(setChannelsInformation(updatedData));
    setDataListChannel(newData);
  };

  const onSelectChange = (e, id) => {
    if (!e.target.checked) {
      document.getElementById("uid_1").checked = false;
    }
    let newData = [...dataListChannel];
    newData = newData.map((item) => {
      if (item._id === id) {
        return Object.assign({}, item, { check: e.target.checked });
      }
      return item;
    });
    let updatedData = [...channelsInformation];
    newData.forEach((newItem) => {
      const index = updatedData.findIndex((item) => item._id === newItem._id);
      if (index !== -1) {
        updatedData[index] = newItem;
      }
    });
    dispatch(setChannelsInformation(updatedData));
    setDataListChannel(newData);
  };

  const onTrashClick = (id) => {
    dataListChannel.forEach((item) => {
      if (item._id === id) {
        setModal({ trash: true });
        setTrashId(id);
      }
    });
  };

  const handleOpenPostId = (item) => {
    let LinkChannelId;
    switch (item.platform) {
      case 1:
        LinkChannelId = item.platform_id;
        window.open(`https://www.facebook.com/${LinkChannelId}`, "_blank");
        break;
      case 2:
        LinkChannelId = item.name.substring(
          item.name?.indexOf("(") + 1,
          item.name?.indexOf(")")
        );
        window.open(`https://www.tiktok.com/${LinkChannelId}`, "_blank");
        break;
      case 3:
        LinkChannelId = item.platform_id;
        window.open(`https://www.facebook.com/${LinkChannelId}`, "_blank");
        break;
      case 5:
        LinkChannelId = item.platform_id;
        window.open(
          `https://www.youtube.com/channel/${LinkChannelId}`,
          "_blank"
        );
        break;
      case 6:
        // LinkChannelId = item.platform_id
        // window.open(`https://www.instagram.com/${LinkChannelId}`, "_blank");
        break;
      case 10:
        LinkChannelId = item.name;
        window.open(`https://www.threads.net/@${LinkChannelId}`, "_blank");
        break;
      default:
    }
  };

  const platform = (index) => {
    let platform;
    switch (index) {
      case 1:
      case 3:
        platform = bigSquaredFacebook;
        break;
      case 2:
        platform = bigSquaredTiktok;
        break;
      case 4:
        platform = zalo;
        break;
      case 5:
        platform = Youtube;
        break;
      case 6:
        platform = bigInstagram;
        break;
      case 8:
        platform = bigSquaredPinterest;
        break;
      case 10:
        platform = bigThreads;
        break;
      default:
    }
    return platform;
  };

  const Type = (index) => {
    let type;
    switch (index) {
      case 1:
        type = "Page";
        break;
      case 2:
        type = "Business account";
        break;
      case 3:
        type = "Group";
        break;
      case 4:
        type = "Official Account";
        break;
      case 5:
        type = "Youtube";
        break;
      case 6:
        type = "Instagram";
        break;
      case 8:
        type = "Pinterest Account";
        break;
      case 10:
        type = "Account";
        break;
      default:
    }
    return type;
  };

  const onClick = (platform) => {
    if (project?.connection_limit > channelsInformation?.length) {
      setIsCapLimit(false);
      let url = process.env.PUBLIC_URL + "/connect-page/" + platform;
      window.history.pushState("", "", url);
      window.location.reload();
    } else {
      setIsCapLimit(true);
    }
  };
  return (
    <React.Fragment>
      <Head title="Connect channels"></Head>
      <Content>
        {userInfor.last_project_active !== "" &&
        ((dataListChannel && dataListChannel.length > 0) || !isFirstRender) ? (
          <>
            <BlockHead size="sm">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle page> {t("connectchannel.channel")}</BlockTitle>
                  <BlockDes className="text-soft">
                    {t("connectchannel.channel_title")} {dataListChannel.length}{" "}
                    {t("connectchannel.sub_title")}
                  </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                  <div className="toggle-wrap nk-block-tools-toggle">
                    <Button
                      className={`btn-icon btn-trigger toggle-expand mr-n1 ${
                        sm ? "active" : ""
                      }`}
                      onClick={() => updateSm(!sm)}
                    >
                      <Icon name="menu-alt-r"></Icon>
                    </Button>
                    <div
                      className="toggle-expand-content"
                      style={{ display: sm ? "block" : "none" }}
                    >
                      <ul className="nk-block-tools g-3">
                        {showMultiDeleted && (
                          <li className="nk-block-tools-opt">
                            <Button
                              style={{ background: "red", color: "white" }}
                              onClick={() => setModalDeleteMultiChannels(true)}
                            >
                              <Icon name="trash"></Icon>
                              <span>{t("deleting_multi_channel")}</span>
                            </Button>
                          </li>
                        )}
                        <li className="nk-block-tools-opt">
                          <Button
                            style={{
                              background: "#ffffff",
                              color: "#6576ff",
                              border: "1px #162cd4 solid",
                            }}
                            onClick={() => setShowFilter(!showFilter)}
                          >
                            <Icon name="filter"></Icon>
                            <span>Filter</span>
                          </Button>
                        </li>
                        <li className="nk-block-tools-opt">
                          <Button
                            style={{ background: "#333333", color: "white" }}
                            onClick={() => setOpenModalSortingChannel(true)}
                          >
                            <Icon name="swap-v"></Icon>
                            <span>{t("sorting_channel")}</span>
                          </Button>
                        </li>
                        {/*
                        <li className="nk-block-tools-opt">
                          <Button style={{ background: "#6A2C62", color: "#E0E0E0" }} onClick={() => setOpenAddUserToGroup(true)}>
                            <Icon name="user"></Icon>
                            <span>{t("group_add_new_user_post")}</span>
                          </Button>
                        </li>
                      */}
                        <li className="nk-block-tools-opt">
                          <Button
                            color="primary"
                            onClick={() => {
                              setIsOpenConnect(true)
                            }}
                          >
                            <Icon name="plus"></Icon>
                            <span>{t("channel_add_new")}</span>
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </BlockHeadContent>
              </BlockBetween>
              {renderFilter()}
            </BlockHead>
            <Block>
              <Card className="card-bordered" style={{ minHeight: "150px" }}>
                <div className="card-inner-group">
                  <div className="card-inner p-0">
                    <DataTableBody>
                      <DataTableHead>
                        <DataTableRow className="nk-tb-col-check">
                          <div className="custom-control custom-control-sm custom-checkbox notext">
                            <input
                              type="checkbox"
                              className="custom-control-input form-control"
                              id="uid_1"
                              onChange={(e) => selectorCheck(e)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="uid_1"
                            ></label>
                          </div>
                        </DataTableRow>
                        <DataTableRow className={`${style.rm_width}`} size="sm">
                          <span>STT</span>
                        </DataTableRow>
                        <DataTableRow className={`${style.rm_width}`} size="sm">
                          <span>{t("connectchannel.Name")}</span>
                        </DataTableRow>
                        <DataTableRow className="text-center">
                          <span>{t("connectchannel.Platform")}</span>
                        </DataTableRow>
                        <DataTableRow className="text-center">
                          <span>{t("connectchannel.Type")}</span>
                        </DataTableRow>
                        <DataTableRow>
                          <span>Kết nối token</span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-actions">
                          <span>Thao tác</span>
                        </DataTableRow>
                      </DataTableHead>
                      {dataListChannel &&
                        dataListChannel.map((item, key) => {
                          return (
                            <DataTableItem key={item._id}>
                              <DataTableRow className="nk-tb-col-check">
                                <div className="custom-control custom-control-sm custom-checkbox notext">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input form-control"
                                    defaultChecked={item.check}
                                    id={item._id + "uid1"}
                                    key={Math.random()}
                                    onChange={(e) =>
                                      onSelectChange(e, item._id)
                                    }
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={item._id + "uid1"}
                                  ></label>
                                </div>
                              </DataTableRow>
                              <DataTableRow style={{ width: 0 }} size="sm">
                                <span className="tb-lead">
                                  {item.priority || ""}
                                </span>
                              </DataTableRow>
                              <DataTableRow style={{ width: 0 }} size="sm">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleOpenPostId(item)}
                                >
                                  <div>
                                    <Img
                                      src={item.picture}
                                      alt="channel"
                                      width="41px"
                                      height="36px"
                                      style={{ borderRadius: "4px" }}
                                    />
                                  </div>
                                  <div
                                    style={{ marginLeft: "4px" }}
                                    className={`${style.line}`}
                                  >
                                    {item.name}
                                  </div>
                                </div>
                              </DataTableRow>
                              <DataTableRow className="text-center">
                                <Img
                                  className="tb-image"
                                  src={platform(item.platform)}
                                  width="16px"
                                  height="16px"
                                />
                              </DataTableRow>
                              <DataTableRow className="text-center">
                                <span className="tb-lead">
                                  {Type(item.platform)}
                                </span>
                              </DataTableRow>
                              <DataTableRow>
                                <div className="icon-text">
                                  {item.list_token_allow !== undefined &&
                                    item.list_token_allow.map((index, i) => {
                                      return (
                                        <div className="d-flex items-center">
                                          <div
                                            key={i}
                                            className={style.divToken}
                                            onMouseEnter={() =>
                                              onHoverClockCalendar(index, key)
                                            }
                                            onMouseLeave={() =>
                                              onHoverLeaveClockCalendar(
                                                index,
                                                key
                                              )
                                            }
                                            id={`tooltip${index.id}${key}`}
                                          >
                                            <Img
                                              className={`tb-image ${style.connectToken}`}
                                              src={index.picture}
                                              alt="channel"
                                              size="circle"
                                              width="32px"
                                              style={{
                                                opacity: index.is_active
                                                  ? "100%"
                                                  : "40%",
                                                border: index.is_active
                                                  ? ""
                                                  : "2px red solid",
                                              }}
                                              //key={index.id}
                                              onClick={() => {
                                                OnClickOpennewToken(
                                                  index,
                                                  item
                                                );
                                              }}
                                            />
                                            <Img
                                              className={`tb-image ${style.errorToken}`}
                                              src={exclamation}
                                              alt="channel"
                                              width="16px"
                                              height="16px"
                                              style={{
                                                position: "absolute",
                                                display: index.is_active
                                                  ? "none"
                                                  : "block",
                                              }}
                                              //key={index.id}
                                              onClick={() => {
                                                OnClickOpennewToken(
                                                  index,
                                                  item,
                                                  index
                                                );
                                              }}
                                            />
                                          </div>
                                          <p className="px-1">{index.name}</p>
                                        </div>
                                      );
                                    })}
                                </div>
                              </DataTableRow>
                              <DataTableRow className="nk-tb-col-tools">
                                <ul className="list-inline nk-tb-actions gx-1 my-n1">
                                  <li className="mr-n1">
                                    <UncontrolledDropdown>
                                      <DropdownToggle
                                        tag="a"
                                        href="#more"
                                        onClick={(ev) => ev.preventDefault()}
                                        className="dropdown-toggle btn btn-icon btn-trigger"
                                      >
                                        <Icon name="more-h"></Icon>
                                      </DropdownToggle>
                                      <DropdownMenu right>
                                        <ul className="link-list-opt no-bdr">
                                          <li>
                                            <DropdownItem
                                              style={{ cursor: "pointer" }}
                                              tag="a"
                                              onClick={() =>
                                                OnClickRefretRole(item)
                                              }
                                            >
                                              <Icon name="repeat"></Icon>
                                              <span>Cấp lại quyền</span>
                                            </DropdownItem>
                                            <DropdownItem
                                              style={{ cursor: "pointer" }}
                                              tag="a"
                                              onClick={() =>
                                                onTrashClick(item._id)
                                              }
                                            >
                                              <Icon name="trash"></Icon>
                                              <span>Xóa</span>
                                            </DropdownItem>
                                          </li>
                                        </ul>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </li>
                                </ul>
                              </DataTableRow>
                            </DataTableItem>
                          );
                        })}
                    </DataTableBody>
                    {dataListChannel.length === 0 && (
                      <div className="d-flex justify-content-center">
                        <span style={{ color: "#8094ae", padding: "24px 0" }}>
                          Không có dữ liệu
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            </Block>
          </>
        ) : (
          <div
            className={`${styleConnect.EmptyImage} ${styleConnect.ImageOpacity}`}
          >
            <div>
              <div className="text-center">
                <Img src={"/images/empty-connection.png"} width={287} alt="" />
              </div>
              <div>
                <p className={`${styleConnect.ImageTitle}`}>
                  Kết nối với tài khoản mạng xã hội để bắt đầu!
                </p>
              </div>
              <div className={`${styleConnect.ConnectArea}`}>
                <div
                  className={`${styleConnect.ButtonConnect}`}
                  onClick={() => {
                    setIsOpenConnect(true)
                  }}
                >
                  <div>
                    <IconConnectChannel />
                  </div>
                  <div>{t("project_connect_channel")}</div>
                </div>
              </div>
            </div>
          </div>
        )}
        <ModalSortingChannel
          openModal={openModalSortingChannel}
          setOpenModal={() => setOpenModalSortingChannel(false)}
        />
      </Content>
      <ModalConnectFail
        openModal={modalConnect}
        setOpenModal={setModalConnect}
      />
      <ModalConnectSucces
        openModal={modalConnect}
        setOpenModal={setModalConnect}
      />
      <ModalDeleteMultiChannels
        openModal={modalDeleteMultiChannels}
        setOpenModal={setModalDeleteMultiChannels}
      />
      <ModalDeleteChannel
        openModal={modal}
        setOpenModal={setModal}
        trashId={trashId}
        setTrashId={setTrashId}
      />
      <ModalHandleAcc
        openModal={openHandleAcc}
        setOpenModal={setOpenHandleAcc}
        data={token}
        setData={setToken}
        setRefretRole={setRefretRole}
        refretRole={refretRole}
      />
      <ModalAccGroup
        openModal={openAccGroup}
        setOpenModal={setOpenAccGroup}
        data={token}
        setData={setToken}
      />
      <ModalAddUserToGroup
        openModal={openModalAddUserToGroup}
        setOpenModal={setOpenAddUserToGroup}
      />
      <ModalErrorConnectChannel />
      <ModalCapChannel
        isOpen={isCapLimit}
        toggle={() => {
          setIsCapLimit(!isCapLimit);
        }}
        numberLimit={project?.connection_limit}
      />

      <ModalConnectPage forceOpenModal={isOpenConnect} setForceOpenModal={setIsOpenConnect} loggedConnect={logged}/>
    </React.Fragment>
  );
};

const ModalConnectFail = React.lazy(() =>
  import("../../components/ModalConnectChannel/ModalConnectFail")
);
const ModalConnectSucces = React.lazy(() =>
  import("../../components/ModalConnectChannel/ModalConnectSucces")
);
const ModalDeleteMultiChannels = React.lazy(() =>
  import("../../components/ModalConnectChannel/ModalDelMultiChannels")
);
const ModalDeleteChannel = React.lazy(() =>
  import("../../components/ModalConnectChannel/ModalDeleteChannel")
);
const ModalAccGroup = React.lazy(() =>
  import("../../components/ModalConnectChannel/ModalAccGroup")
);
const ModalHandleAcc = React.lazy(() =>
  import("../../components/ModalConnectChannel/ModalHandleAcc")
);
const ModalAddUserToGroup = React.lazy(() =>
  import("../../components/Modal/ModalAddUserToGroup")
);
const ModalErrorConnectChannel = React.lazy(() =>
  import("../../components/Modal/ModalErrorConnectChannel")
);
const ModalConnectPage = React.lazy(() =>
  import("../../components/Modal/ModalConnectPage")
);
export default ConnectChannel;
