import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  currentPage: 1,
  totalPages: 1,
  total: 1,
  isLoading: false,
  error: null,
};

export const warehouseSlice = createSlice({
  name: "warehouseManage",
  initialState,
  reducers: {
    fetchDataStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    fetchDataSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload.data;
      state.currentPage = action.payload.currentPage;
      state.totalPages = action.payload.totalPages;
      state.total = action.payload.total;
    },
    fetchDataFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    // Thêm chức năng thêm dữ liệu
    addData(state, action) {
      state.data.push(action.payload); // Thêm dữ liệu vào mảng data
    },
    // Thêm chức năng sửa dữ liệu
    editData(state, action) {
      const editedData = action.payload;
      const index = state.data.findIndex((data) => data.id === editedData.id);
      if (index !== -1) {
        state.data[index] = editedData; // Cập nhật dữ liệu tại vị trí index
      }
    },
    // Thêm chức năng xoá dữ liệu
    deleteData(state, action) {
      const dataIdToDelete = action.payload;
      state.data = state.data.filter((data) => data.id !== dataIdToDelete);
    },
  },
});

export const {
  fetchDataStart,
  fetchDataSuccess,
  fetchDataFailure,
  setCurrentPage,
  addData,
  editData,
  deleteData,
} = warehouseSlice.actions;

export default warehouseSlice.reducer;
