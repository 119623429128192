import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import SendIcon from "../../../../images/icons/send.svg";
import AttachmentIcon from "../../../../images/icons/attachment.svg";
import TagIcon from "../../../../images/icons/tag.svg";
import "../styles.css";

const CommentInput = ({
  handleCommentSubmit,
  handleFileChange,
  commentText,
  setCommentText,
}) => {
  const { t } = useTranslation();
  const inputCommentRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleKeyDownComment = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleCommentSubmit();
      fileInputRef.current.value = "";
    }
  };

  return (
    <div className="comment-box">
      <textarea
        ref={inputCommentRef}
        value={commentText}
        className="comment-input"
        onChange={(event) => setCommentText(event.target.value)}
        onKeyDown={handleKeyDownComment}
        placeholder={t("task_management.placeholder.write_a_comment")}
        rows={3}
      />

      <div className="d-flex align-items-center gap-3">
        <input
          type="file"
          ref={fileInputRef}
          className="file-input"
          onChange={handleFileChange}
          accept="image/*"
          style={{ display: "none" }}
          // multiple
        />
        <img
          className="comment-icon"
          src={AttachmentIcon}
          width={16}
          alt="Attachment"
          onClick={() => fileInputRef.current.click()}
        />
        <img className="comment-icon" src={TagIcon} width={16} alt="Tag" />
        <div className="vertical-divider" style={{ margin: "0" }}></div>
        <div
          className="comment-option"
          onClick={() => {
            handleCommentSubmit();
            fileInputRef.current.value = "";
          }}
        >
          <img className="comment-icon" src={SendIcon} width={16} alt="Send" />
        </div>
      </div>
    </div>
  );
};

export default CommentInput;
