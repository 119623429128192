import React, { useState, useEffect } from "react";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  RSelect,
  Img,
} from "../../../components/Component";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import ModalSortingChannel from "../../components/Modal/ModalSortingChannel";
import HoverSetupDateOfWeek from "./HoverSetupDateOfWeek";
import { Card, Spinner, UncontrolledTooltip } from "reactstrap";
import { useHistory } from "react-router";
import style from "./indexV2.module.scss";
import bigSquaredFacebook from "../../images/icons/big-squared-facebook.svg";
import bigSquaredTiktok from "../../images/icons/big-squared-tiktok.svg";
import bigInstagram from "../../images/icons/big-squared-instagram.svg";
import bigPinterest from "../../images/icons/big-squared-pinterest.svg";
import bigThreads from "../../images/icons/big-squared-threads.svg";
import zalo from "../../images/icons/zalo.svg";
import Youtube from "../../../images/icons/youtube.png";
import NoData from "../../images/noData.png";
import { getChannelsSmartSchedule } from "../../../api/channel";
import {
  DATE_OF_WEEK,
  TYPE_PLATFORM,
  TYPE_CHANNEL_SETTING,
} from "../../utils/Utils";
import _ from "lodash";

const ConnectChannel = () => {
  const history = useHistory();
  const pageNum =
    parseInt(new URLSearchParams(window.location.search).get("page")) || 1;
  const [isLoading, setLoading] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [sm, updateSm] = useState(false);
  const [openModalSortingChannel, setOpenModalSortingChannel] = useState(false);
  const [numberOfsettingChannel, setNumberOfsettingChannel] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(pageNum);
  const [totalItems, setTotalItems] = useState(0);
  const [dataListChannel, setDataListChannel] = useState([]);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalAddMutil, setModalAddMutil] = useState(false);
  const [modalEditMutil, setModalEditMutil] = useState(false);
  const [channelEdit, setChannelEdit] = useState();
  const [channelEditMutil, setChannelEditMutil] = useState();
  const [isHiddenSearch, setIsHiddenSearch] = useState(true);
  const [isEditMutil, setIsEditMutil] = useState(false);
  const [channelType, setChannelType] = useState();
  const [platformType, setPlatformType] = useState();
  const [filterChannelSettingParams, setFilterChannelSettingParams] = useState({
    channel_setting: "",
    platform: "",
  });

  const SettingDateSM = [
    {
      date: "T2",
      num: 0,
    },
    {
      date: "T3",
      num: 0,
    },
    {
      date: "T4",
      num: 0,
    },
    {
      date: "T5",
      num: 0,
    },
    {
      date: "T6",
      num: 0,
    },
    {
      date: "T7",
      num: 0,
    },
    {
      date: "CN",
      num: 0,
    },
  ];
  const userInfor = JSON.parse(localStorage.getItem("userInfor"));
  const projectId = userInfor.last_project_active;

  const paginate = (pageNumber) => {
    let isSettingChannel = filterChannelSettingParams.channel_setting;
    let platform = filterChannelSettingParams.platform;
    getListChannelsSchedule(pageNumber, isSettingChannel, platform);
  };

  const getListChannelsSchedule = async (
    pageNumber = 1,
    is_setting_advance = "",
    platform = ""
  ) => {
    setLoading(true);
    const data = {
      params: {
        is_setting_advance: is_setting_advance,
        platform: platform,
        page: pageNumber,
      },
    };
    await getChannelsSmartSchedule(projectId, data)
      .then((response) => {
        if (response.status) {
          const result = response.data;
          setItemPerPage(result.per_page);
          setCurrentPage(result.current_page);
          let numSettingChannel = result.total_setting || 0;
          setNumberOfsettingChannel(numSettingChannel);
          setTotalItems(result.total);
          let url = `${window.location.pathname}?page=${result.current_page}`;
          history.push(url);
          setDataListChannel([...result.data]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => setLoading(false));
  };

  const selectorCheck = (e) => {
    let newData = [...dataListChannel];
    newData = newData.map((item) =>
      Object.assign({}, item, { check: e.target.checked })
    );
    let indexChecked = newData.findIndex((item) => item.check === true);
    if (indexChecked !== -1) setIsEditMutil(true);
    else setIsEditMutil(false);
    setDataListChannel([...newData]);
  };

  const onSelectChange = (e, id) => {
    let newData = [...dataListChannel];
    let index = newData.findIndex((item) => item._id === id);
    newData[index].check = e.currentTarget.checked;
    let indexChecked = newData.findIndex((item) => item.check === true);
    if (indexChecked !== -1) setIsEditMutil(true);
    else setIsEditMutil(false);
    setDataListChannel([...newData]);
  };

  const OnClickHandleEdit = (item) => {
    setModalEdit(true);
    setChannelEdit({ ...item });
  };

  const OnClickHandleAddMutil = () => {
    setModalAddMutil(true);
  };

  const OnClickHandleEditMutil = () => {
    if (isEditMutil) {
      let newData = [...dataListChannel];
      let listChannel = newData.filter((c) => c.check);
      setChannelEditMutil(listChannel);
      setModalEditMutil(true);
    }
  };

  const platform = (index) => {
    let platform;
    switch (index) {
      case 1:
      case 3:
        platform = bigSquaredFacebook;
        break;
      case 2:
        platform = bigSquaredTiktok;
        break;
      case 4:
        platform = zalo;
        break;
      case 5:
        platform = Youtube;
        break;
      case 6:
        platform = bigInstagram;
        break;
      case 8:
        platform = bigPinterest;
        break;
      case 10:
        platform = bigThreads;
        break;
      default:
    }
    return platform;
  };

  const handleShowSearch = () => {
    setIsHiddenSearch(!isHiddenSearch);
  };

  const handleChangeTypeChannel = (e) => {
    const filterParams = { ...filterChannelSettingParams };
    filterParams.channel_setting = e.value;
    setFilterChannelSettingParams(filterParams);
    setChannelType(e);
  };

  const handleChangeTypePlatform = (e) => {
    const filterParams = { ...filterChannelSettingParams };
    filterParams.platform = e.value;
    setFilterChannelSettingParams(filterParams);
    setPlatformType(e);
  };

  const handleFilter = () => {
    let isSettingChannel = filterChannelSettingParams.channel_setting;
    let platform = filterChannelSettingParams.platform;
    getListChannelsSchedule(1, isSettingChannel, platform);
    setIsFilter(true);
  };

  const handleRemoveFilter = () => {
    getListChannelsSchedule();
    setChannelType(null);
    setPlatformType(null);
    setFilterChannelSettingParams({
      channel_setting: "",
      platform: "",
    });
    setIsFilter(false);
  };

  const renderSearch = () => {
    return (
      <div className={style.containerSearch}>
        <RSelect
          options={TYPE_CHANNEL_SETTING}
          placeholder="Loại kênh"
          className={style.controlSelect}
          value={channelType}
          onChange={handleChangeTypeChannel}
        />
        <RSelect
          options={TYPE_PLATFORM}
          placeholder="Loại nền tảng"
          className={style.controlSelect}
          value={platformType}
          onChange={handleChangeTypePlatform}
        />
        <Button
          className={`${style.btnSearch} ${style.btnFilter}`}
          onClick={() => {
            handleFilter();
          }}
        >
          LỌC
        </Button>
        {_.isEqual(filterChannelSettingParams.channel_setting, "") &&
        _.isEqual(filterChannelSettingParams.platform, "") ? (
          ""
        ) : (
          <Button
            className={`${style.btnSearch} ${style.btnRemove} `}
            onClick={handleRemoveFilter}
          >
            BỎ LỌC
          </Button>
        )}
      </div>
    );
  };

  const totalNumSettingDateOfWeek = (date) => {
    let total = 0;
    date.objects.map((e) => {
      e.times.map((i) => {
        total = total + i.limit;
      });
    });
    return total;
  };

  useEffect(() => {
    getListChannelsSchedule(currentPage);
  }, []);

  useEffect(() => {
    if (!modalEditMutil) {
      document.getElementById("uid_1").checked = false;
      let newData = [...dataListChannel];
      newData = newData.map((item) =>
        Object.assign({}, item, { check: false })
      );
      setDataListChannel([...newData]);
      setIsEditMutil(false);
    }
  }, [modalEditMutil]);

  const renderContent = () => {
    if (dataListChannel.length === 0 && !isFilter) {
      return (
        <tr>
          <th colSpan={4}>
            <div className={`${style.noSearchData}`} colspan={4}>
              <Img src={NoData} alt={NoData} />
              <div className={`${style.headerNoSearchData}`}>
                Không có Kênh cài đặt. Vui lòng thêm kênh để có thể sử dụng tính
                năng này!
              </div>
            </div>
          </th>
        </tr>
      );
    } else {
      return (
        <>
          {dataListChannel && dataListChannel.length > 0 ? (
            dataListChannel.map((item, key) => {
              return (
                <DataTableItem key={item._id}>
                  <DataTableRow className="nk-tb-col-check">
                    <div className="custom-control custom-control-sm custom-checkbox notext">
                      <input
                        type="checkbox"
                        className="custom-control-input form-control"
                        defaultChecked={item.check}
                        id={item._id + "uid1"}
                        key={Math.random()}
                        onChange={(e) => onSelectChange(e, item._id)}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={item._id + "uid1"}
                      ></label>
                    </div>
                  </DataTableRow>
                  <DataTableRow style={{ width: 0 }} size="sm">
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "41px" }}>
                        <Img src={item.picture} width="41px" size="square" />
                      </div>
                      <div
                        style={{ width: "16px" }}
                        className={`${
                          item.user_name ? "-mt-1" : "my-auto"
                        } ml-1`}
                      >
                        <Img
                          className="tb-image"
                          src={platform(item.platform)}
                          width="16px"
                          height="16px"
                        />
                      </div>
                      <div className={`${item.user_name ? "" : "my-auto"}`}>
                        <div
                          className={`${style.nameChannel} ${
                            item.user_name ? "-mt-1" : ""
                          } ml-1`}
                        >
                          <span>{item.name}</span>
                        </div>
                        {item.user_name && (
                          <div className={`${style.titleUserName} ml-1`}>
                            {item.user_name}
                          </div>
                        )}
                      </div>
                    </div>
                  </DataTableRow>
                  <DataTableRow>
                    <div className="row">
                      {SettingDateSM.map((e) =>
                        item.setting.find(
                          (element) =>
                            DATE_OF_WEEK[element.date_of_week] === e.date
                        )
                          ? {
                              ...e,
                              num: totalNumSettingDateOfWeek(
                                item.setting.find(
                                  (element) =>
                                    DATE_OF_WEEK[element.date_of_week] ===
                                    e.date
                                )
                              ),
                            }
                          : { ...e }
                      ).map((index) => {
                        return (
                          <div
                            id={`${index.date}${item._id}`}
                            className={`${style.titleDate} col cursor-pointer`}
                          >
                            <div>{index.date}</div>
                            <div>
                              <span
                                style={
                                  item.setting.length === 0
                                    ? { color: "#9CA3AF" }
                                    : {}
                                }
                              >
                                {item.setting.length === 0 ? "_" : index.num}
                              </span>
                              {item.setting.length === 0 || index.num === 0 ? (
                                <UncontrolledTooltip
                                  autohide={false}
                                  placement={"top"}
                                  target={`${index.date}${item._id}`}
                                >
                                  Chưa cài đặt
                                </UncontrolledTooltip>
                              ) : (
                                <UncontrolledTooltip
                                  autohide={false}
                                  placement={"top"}
                                  target={`${index.date}${item._id}`}
                                  style={{
                                    backgroundColor: "rgb(255, 255, 255)",
                                    color: "#fff",
                                    maxWidth: "fit-content",
                                    fontSize: "10px",
                                    borderRadius: "4px",
                                    boxShadow: "2px 2px 4px rgb(133,128,128)",
                                  }}
                                >
                                  <HoverSetupDateOfWeek
                                    setting={item.setting.find(
                                      (element) =>
                                        DATE_OF_WEEK[element.date_of_week] ===
                                        index.date
                                    )}
                                  />
                                </UncontrolledTooltip>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </DataTableRow>
                  <DataTableRow className="nk-tb-col-tools">
                    <ul className="list-inline nk-tb-actions gx-1 my-n1">
                      <li className="mr-n1">
                        <Button
                          className={`${style.buttonEdit}`}
                          onClick={() => OnClickHandleEdit(item)}
                        >
                          <span>Sửa</span>
                        </Button>
                      </li>
                    </ul>
                  </DataTableRow>
                </DataTableItem>
              );
            })
          ) : (
            <tr>
              <th colSpan={4}>
                <div className={`${style.noSearchData}`} colspan={4}>
                  <Img src={NoData} alt={NoData} />
                  <div className={`${style.headerNoSearchData}`}>
                    Không tìm thấy kết quả nào . Hãy thử lại
                  </div>
                </div>
              </th>
            </tr>
          )}
        </>
      );
    }
  };
  return (
    <React.Fragment>
      <Head title="9Calendar"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Cài đặt Lịch tự động</BlockTitle>
              <BlockDes className="text-soft">
                <span className={style.subTitle}>
                  <span style={{ color: "#374151" }}>
                    Số kênh đã cài đặt:{" "}
                    <span style={{ color: "#0EA5E9" }}>
                      {numberOfsettingChannel}
                    </span>
                    /{totalItems}
                  </span>{" "}
                  (Các kênh chưa cài sẽ không sử dụng được tính năng Lịch tự
                  động)
                </span>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <div
                  className="toggle-expand-content"
                  style={{ display: sm ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li
                      id="plusIcon"
                      className={`nk-block-tools-opt`}
                      onClick={() => OnClickHandleAddMutil()}
                    >
                      <Icon className={style.buttonAdd} name="plus"></Icon>
                      <UncontrolledTooltip
                        autohide={false}
                        placement={"top"}
                        target={"plusIcon"}
                      >
                        Thêm hàng loạt
                      </UncontrolledTooltip>
                    </li>
                    <li className="nk-block-tools-opt">
                      <Button
                        className={`${style.buttonEdit}`}
                        onClick={handleShowSearch}
                      >
                        {!isHiddenSearch ? (
                          <Icon className={style.iconContainer} name="filter">
                            <div className={style.searchIcon}></div>
                          </Icon>
                        ) : (
                          <Icon name="filter"></Icon>
                        )}
                        <span>Bộ lọc</span>
                      </Button>
                    </li>
                    <li className="nk-block-tools-opt">
                      <Button
                        disabled={!isEditMutil}
                        className={`${
                          isEditMutil
                            ? style.buttonEdit
                            : style.buttonEditDisabled
                        }`}
                        onClick={() => OnClickHandleEditMutil()}
                      >
                        <Icon name="edit"></Icon>
                        <span>Sửa hàng loạt</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
          <div>{isHiddenSearch ? "" : renderSearch()}</div>
        </BlockHead>
        <Block>
          <Card className="card-bordered">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                <DataTableBody>
                  <DataTableHead>
                    <DataTableRow className="nk-tb-col-check">
                      <div className="custom-control custom-control-sm custom-checkbox notext">
                        <input
                          type="checkbox"
                          className="custom-control-input form-control"
                          id="uid_1"
                          onChange={(e) => selectorCheck(e)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="uid_1"
                        ></label>
                      </div>
                    </DataTableRow>
                    <DataTableRow size="sm">
                      <span className={`${style.th_title}`}>Kênh cài đặt</span>
                    </DataTableRow>
                    <DataTableRow className="text-left">
                      <span className={`${style.th_title}`}>
                        Bài đăng cài đặt theo ngày
                      </span>
                    </DataTableRow>
                    <DataTableRow className="text-right">
                      <span className={`${style.th_title}`}>Thao tác</span>
                    </DataTableRow>
                  </DataTableHead>
                  {!isLoading ? (
                    renderContent()
                  ) : (
                    <tr>
                      <th colSpan={4}>
                        <div className="text-center p-4">
                          <Spinner color="dark" />
                        </div>
                      </th>
                    </tr>
                  )}
                </DataTableBody>
              </div>
            </div>
          </Card>
        </Block>
        {dataListChannel.length > 0 && (
          <div className="card-inner">
            <PaginationComponent
              itemPerPage={itemPerPage}
              totalItems={totalItems}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        )}

        <ModalSortingChannel
          openModal={openModalSortingChannel}
          setOpenModal={() => setOpenModalSortingChannel(false)}
        />
      </Content>
      <ModalEditSettingChannel
        openModal={modalEdit}
        setOpenModal={setModalEdit}
        channel={channelEdit}
        resetListSetting={() => getListChannelsSchedule(currentPage)}
      />
      <ModalAddMutilSettingChannel
        openModal={modalAddMutil}
        setOpenModal={setModalAddMutil}
        resetListSetting={() => getListChannelsSchedule()}
      />
      <ModalEditMutilSettingChannel
        openModal={modalEditMutil}
        setOpenModal={setModalEditMutil}
        listChannel={channelEditMutil}
        resetListSetting={() => getListChannelsSchedule(currentPage)}
      />
    </React.Fragment>
  );
};

const ModalEditSettingChannel = React.lazy(() =>
  import("../../components/SetupSmart/ModalEditSettingChannel")
);
const ModalAddMutilSettingChannel = React.lazy(() =>
  import("../../components/SetupSmart/ModalAddMutilSettingChannel")
);
const ModalEditMutilSettingChannel = React.lazy(() =>
  import("../../components/SetupSmart/ModalEditMutilSettingChannel")
);
export default ConnectChannel;
