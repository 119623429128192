import { Col } from "reactstrap";

import { CartContent } from "./CartContent";

export const TopPostColumn = ({ data, isLoading, platform = "facebook" }) => {

  const formatData = (post) => {
    switch (platform) {
      case "facebook":
        return {
          media: post.media,
          channel: post.channel,
          post_id: post.post_id,
          channle_id: post.channle_id,
          _id: post._id,
          content: post.content,
          date: post.date,
          statistic_first_row: [
            {
              value: post?.post_impressions,
              classIcon: "ri-eye-fill",
              backgroundColor: "rgba(75, 56, 179, 0.18)",
              colorIcon: "#4B38B3",
            },
            {
              value: post?.post_clicks,
              classIcon: "ri-cursor-fill",
              backgroundColor: "rgba(75, 56, 179, 0.18)",
              colorIcon: "#4B38B3",
            },
            {
              value: post?.post_engagement,
              classIcon: "ri-message-2-line",
              backgroundColor: "rgba(75, 56, 179, 0.18)",
              colorIcon: "#4B38B3",
            }
          ],
          statistic_second_row: [
            {
              value: data?.post_reactions_like_total,
              classIcon: "ri-thumb-up-fill",
              backgroundColor: "rgba(41, 156, 219, 0.18)",
              colorIcon: "#3577F1",
            },
            {
              value: data?.post_reactions_love_total,
              classIcon: "ri-heart-3-fill",
              backgroundColor: "rgba(240, 101, 72, 0.18)",
              colorIcon: "#F06548",
            },
            {
              value: data?.post_reactions_wow_total,
              classIcon: "ri-emotion-line",
              backgroundColor: "rgba(247, 184, 75, 0.18)",
              colorIcon: "#F7B84B",
            }
          ]
        };
      case "youtube":
        return {
          media: post.media,
          channel: post.channel,
          post_id: post.post_id,
          channle_id: post.channle_id,
          _id: post._id,
          content: post.content,
          date: post.date,
          statistic_first_row: [
            {
              value: post?.view_count,
              classIcon: "ri-eye-fill",
              backgroundColor: "rgba(75, 56, 179, 0.18)",
              colorIcon: "#4B38B3",
            },
            {
              value: post?.comment_count,
              classIcon: "ri-message-2-line",
              backgroundColor: "rgba(75, 56, 179, 0.18)",
              colorIcon: "#4B38B3",
            },
            {
              value: data?.like_count,
              classIcon: "ri-thumb-up-fill",
              backgroundColor: "rgba(41, 156, 219, 0.18)",
              colorIcon: "#3577F1",
            },
          ],
          statistic_second_row: [
            {
              value: data?.share_count,
              classIcon: "ri-share-fill",
              backgroundColor: "rgba(240, 101, 72, 0.18)",
              colorIcon: "#F06548",
            },
            {
              value: data?.dislike_count,
              classIcon: "ri-dislike-fill",
              backgroundColor: "rgba(247, 184, 75, 0.18)",
              colorIcon: "#F7B84B",
            }
          ]
        };
      case "tiktok":
        return {
          media: post.media,
          channel: post.channel,
          post_id: post.post_id,
          channle_id: post.channle_id,
          _id: post._id,
          content: post?.post_info?.title,
          date: post.date,
          statistic_first_row: [
            {
              value: post?.view_count,
              classIcon: "ri-eye-fill",
              backgroundColor: "rgba(75, 56, 179, 0.18)",
              colorIcon: "#4B38B3",
            },
            {
              value: post?.comment_count,
              classIcon: "ri-message-2-line",
              backgroundColor: "rgba(75, 56, 179, 0.18)",
              colorIcon: "#4B38B3",
            }
          ],
          statistic_second_row: [
            {
              value: data?.like_count,
              classIcon: "ri-thumb-up-fill",
              backgroundColor: "rgba(41, 156, 219, 0.18)",
              colorIcon: "#3577F1",
            },
            {
              value: data?.share_count,
              classIcon: "ri-share-fill",
              backgroundColor: "rgba(240, 101, 72, 0.18)",
              colorIcon: "#F06548",
            },
          ]
        };
      default: {
        return {}
      }
    }
  }
  return (
    <>
      {(data || [])?.map((post, key) => (
        <Col key={key} sm={6} xl={4} className="my-1">
          <CartContent data={formatData(post)} isLoading={isLoading} />
        </Col>
      ))}
    </>
  );
};
