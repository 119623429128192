import moment from "moment";
import "moment/locale/vi";
import PlatformIconFacebook from "../images/icons/platform-icon-facebook.png";
import PlatformIconFacebookGroup from "../images/icons/FacebookGroup.png";
import PlatformIconYoutube from "../../images/icons/youtube.png";
import PlatformIconPinterest from "../../images/icons/iconPinterest.png";
import PlatformIconThreads from "../../images/icons/iconThreads.svg";
import PlatformIconInstagram from "../../images/icons/instagram.png";
import PlatformIconTiktok from "../images/icons/platform-icon-tiktok.png";
import PlatformIconZalo from "../images/icons/zalo.png";
import PlatformIconOriginal from "../images/icons/default-icon-social-network.png";
import PlatformIconDefault from "../images/icons/default-icon-social-network.png";
import card1 from "../../So9/images/pricelist/card/card-hero-1.png";
import card2 from "../../So9/images/pricelist/card/card-hero-2.png";
import card3 from "../../So9/images/pricelist/card/card-hero-3.png";
import card4 from "../../So9/images/pricelist/card/card-hero-4.png";
import card5 from "../../So9/images/pricelist/card/card-hero-5.png";
import card6 from "../../So9/images/pricelist/card/card-hero-6.png";
import hr1 from "../../So9/images/pricelist/hr-card.jpg";
import bronze from "../../So9/images/seeding/price_bronze.png";
import silver from "../../So9/images/seeding/price_silver.png";
import gold from "../../So9/images/seeding/price_gold.png";

export const imagesPrice = {
  started: card1,
  growth: card2,
  pro: card3,
  business: card4,
  enterprise: card5,
  custom: card6,
  free: hr1,
};
export const imagesPriceSeeding = {
  bronze: bronze,
  silver: silver,
  gold: gold,
};
export const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

export const MEDIA_TYPE_IMAGE = 1;
export const MEDIA_TYPE_VIDEO = 2;
export const MEDIA_TYPE_REEL = 4;
export const MEDIA_TYPE_MULTI_UPLOAD = 3;
export const MAX_POST_UPLOAD = 200;
export const MAX_POST_UPLOAD_CONTENT_WAREHOUSE = 500;
export const MAX_POST_UPLOAD_CHUNK_WAREHOUSE = 50;
export const MAX_POST_UPLOAD_CHUNK = 40;
export const MAX_POST_UPLOAD_CHUNK_WITH_BULK_CHANNEL = 3;

export const CONTENT_STATUS_ACTIVE = 1;
export const CONTENT_STATUS_DRAFT = 2;

export const CONTENT_LIBRARY_SORT_BY_ENGAGEMENT_RATE = "engagementRate";
export const CONTENT_LIBRARY_SORT_BY_LATEST = "latest";
export const CONTENT_LIBRARY_SORT_BY_OLDEST = "oldest";

export const CONTENT_LIBRARY_SORT_BY_OPTIONS = {
  [CONTENT_LIBRARY_SORT_BY_ENGAGEMENT_RATE]:
    "content_library.sort_by_value_engagement_rate",
  [CONTENT_LIBRARY_SORT_BY_LATEST]: "content_library.sort_by_value_latest",
  [CONTENT_LIBRARY_SORT_BY_OLDEST]: "content_library.sort_by_value_oldest",
};

export const CONTENT_LIBRARY_FILTER_ALL = "all";
export const CONTENT_LIBRARY_FILTER_DRAFT = "draft";
export const CONTENT_LIBRARY_FILTER_PUBLISHED = "published";
export const CONTENT_LIBRARY_FILTER_REMOVED = "removed";

export const CONTENT_LIBRARY_FILTER_OPTIONS = {
  [CONTENT_LIBRARY_FILTER_ALL]: "content_library.filter_value_all",
  [CONTENT_LIBRARY_FILTER_DRAFT]: "content_library.filter_value_draft",
  [CONTENT_LIBRARY_FILTER_PUBLISHED]: "content_library.filter_value_published",
  [CONTENT_LIBRARY_FILTER_REMOVED]: "content_library.filter_value_removed",
};

export const CONTENT_LIMIT_SEE_MORE = 50;

export const TYPE_ACCOUNT_BUSINESS = 2;
export const STATUS_ACTIVE = 1;
export const STATUS_INACTIVE = 2;
export const STATUS_WAITING_VERIFICATION = 0;

export const STATUS_SCHEDULED = 1;
export const STATUS_PUBLISH_SUCCESS = 2;
export const STATUS_PUBLISH_ERROR = 0;
export const STATUS_PROCESS = 3;
export const STATUS_HIDE = 4;
export const STATUS_REMOVE = 5;
export const STATUS_PRE_SCHEDULED = -1;
export const STATUS_PROCESS_SCHEDULED = -2;
export const STATUS_FAIL_SCHEDULED = -3;
export const STATUS_PROJECT_EXPIRED = -4;

export const arrayManagePost = {
  [STATUS_PUBLISH_SUCCESS]: "config.status_post.publish_success",
  [STATUS_SCHEDULED]: "config.status_post.scheduled",
  [STATUS_PUBLISH_ERROR]: "config.status_post.publish_error",
  [STATUS_PROCESS]: "config.status_post.process",
  [STATUS_PROCESS_SCHEDULED]: "config.status_post.process_schedule",
  [STATUS_PRE_SCHEDULED]: "config.status_post.pre_schedule",
  [STATUS_HIDE]: "config.status_post.hide",
  [STATUS_REMOVE]: "config.status_post.remove",
};

export const arrayStatus = {
  [STATUS_ACTIVE]: "Hoạt động",
  [STATUS_INACTIVE]: "Ngừng hoạt động",
  [STATUS_WAITING_VERIFICATION]: "Chờ xác nhận",
};

export const SORT_BY_LATEST_CONTENT = "latest_content";
export const SORT_BY_LATEST_ENGAGE = "latest_engage";
export const SORT_BY_LATEST_COMMENT = "latest_comment";

export const CHANNEL_CONTENT_SORT_BY_ARRAY = [
  SORT_BY_LATEST_ENGAGE,
  SORT_BY_LATEST_CONTENT,
  SORT_BY_LATEST_COMMENT,
];

export const CHANNEL_CONTENT_RELOAD_TIME = 15 * 60 * 1000;

export const CONVERSATION_TYPE_COMMENT = 1;
export const CONVERSATION_TYPE_CHAT = 2;
export const CONVERSATION_TYPE_CHAT_ZALO = 3;
export const CONVERSATION_TYPE_COMMENT_TIKTOK = 4;

export const USER_ROLE_OWNER = "owner";
export const USER_ROLE_ADMIN = "admin";
export const USER_ROLE_USER = "user";
export const arrayUserRole = {
  [USER_ROLE_OWNER]: "Owner",
  [USER_ROLE_ADMIN]: "Admin",
  [USER_ROLE_USER]: "User",
};

export const formatTimestamp = (timestamp, lang) => {
  const momentObj = moment(timestamp * 1000);
  if (lang) {
    momentObj.locale(lang);
  }
  return momentObj.fromNow();
};

export const formatTimestampNoSuffix = (timestamp, lang) => {
  const momentObj = moment(timestamp * 1000);
  if (lang) {
    momentObj.locale(lang);
  }
  return momentObj.fromNow(true);
};

export const formatTimestampToDate = (timestamp, lang) => {
  const momentObj = moment(timestamp * 1000);
  if (lang) {
    momentObj.locale(lang);
  }
  return momentObj.format("DD MMM YYYY");
};

export const BOTH_DATE = 2;
export const DATE_WEEK = 1;
export const ALL_WEEK = 3;
export const PLATFORM_FACEBOOK = 1;
export const PLATFORM_TIKTOK = 2;
export const PLATFORM_FACEBOOKGROUP = 3;
export const PLATFORM_ZALO = 4;
export const PLATFORM_YOUTUBE = 5;
export const PLATFORM_INSTAGRAM = 6;
export const PLATFORM_PINTEREST = 8;
export const PLATFORM_THREADS = 10;
export const PLATFORM_ORIGINAL = 0;

export const PLATFORM_WITH_90_SECOND_DURATION_VIDEO = [
  PLATFORM_FACEBOOK,
  PLATFORM_INSTAGRAM,
  PLATFORM_FACEBOOKGROUP,
  PLATFORM_ZALO,
];

export const PLATFORM_WITH_60_SECOND_DURATION_VIDEO = [
  // PLATFORM_TIKTOK,
  PLATFORM_YOUTUBE,
];

export const PLATFORM_WITH_UNLIMIT_900_SECOND_DURATION_VIDEO = [
  PLATFORM_TIKTOK,
  // PLATFORM_YOUTUBE,
];

export const DURATION_VIDEO_BY_90_SECONDS = 90;
export const DURATION_VIDEO_BY_60_SECONDS = 60;
export const DURATION_VIDEO_BY_900_SECONDS = 900;

export const PLATFORM_LABEL = {
  [PLATFORM_FACEBOOK]: "facebookpage",
  [PLATFORM_TIKTOK]: "tiktok",
  [PLATFORM_FACEBOOKGROUP]: "facebookgroup",
  [PLATFORM_YOUTUBE]: "youtube",
  [PLATFORM_ZALO]: "zalo",
  [PLATFORM_ORIGINAL]: "original",
};

export const PLATFORM_LABEL_TYPE = {
  [PLATFORM_FACEBOOK]: "Fanpage",
  [PLATFORM_TIKTOK]: "Tài khoản",
  [PLATFORM_FACEBOOKGROUP]: "Group",
  [PLATFORM_YOUTUBE]: "Kênh",
  [PLATFORM_ZALO]: "Tài khoản",
  [PLATFORM_ORIGINAL]: "Tài khoản",
  [PLATFORM_INSTAGRAM]: "Tài khoản",
};

export const PLATFORM_LABEL_OPTION = {
  [PLATFORM_FACEBOOK]: "Facebook Page",
  [PLATFORM_TIKTOK]: "Tiktok",
  [PLATFORM_FACEBOOKGROUP]: "Facebook Group",
  [PLATFORM_YOUTUBE]: "Youtube Channel",
  [PLATFORM_ZALO]: "Zalo",
  [PLATFORM_PINTEREST]: "Pinterest",
  [PLATFORM_ORIGINAL]: "Original",
};

export const getPlatformIconFromLabel = (label) => {
  switch (label) {
    case "facebook":
      return PlatformIconFacebook;
    case "instagram":
      return PlatformIconInstagram;
    case "tiktok":
      return PlatformIconTiktok;
    case "zalo":
      return PlatformIconZalo;
    case "facebookgroup":
      return PlatformIconFacebookGroup;
    case "youtube":
      return PlatformIconYoutube;
    case "pinterest":
      return PlatformIconPinterest;
    case "threads":
      return PlatformIconThreads;
    default:
      return PlatformIconDefault;
  }
};

export const getPlatformIcon = (platform) => {
  switch (platform) {
    case PLATFORM_FACEBOOK:
      return PlatformIconFacebook;
    // case "instagram":
    //   return PlatformIconInstagram;
    case PLATFORM_TIKTOK:
      return PlatformIconTiktok;
    case PLATFORM_ZALO:
      return PlatformIconZalo;
    case PLATFORM_FACEBOOKGROUP:
      return PlatformIconFacebookGroup;
    case PLATFORM_YOUTUBE:
      return PlatformIconYoutube;
    case PLATFORM_ORIGINAL:
      return PlatformIconOriginal;
    case PLATFORM_INSTAGRAM:
      return PlatformIconInstagram;
    case PLATFORM_PINTEREST:
      return PlatformIconPinterest;
    case PLATFORM_THREADS:
      return PlatformIconThreads;
    default:
      return PlatformIconDefault;
  }
};

export const getColorPlatform = (platform) => {
  switch (platform) {
    case PLATFORM_FACEBOOK:
      return "rgba(24, 119, 242, 0.10)";
    // case "instagram":
    //   return PlatformIconInstagram;
    case PLATFORM_TIKTOK:
      return "#E5E5E5";
    case PLATFORM_ZALO:
      return "rgba(24, 119, 242, 0.10)";
    case PLATFORM_FACEBOOKGROUP:
      return "rgba(24, 119, 242, 0.10)";
    case PLATFORM_YOUTUBE:
      return "#FFE5E5";
    case PLATFORM_ORIGINAL:
      return "rgba(24, 119, 242, 0.10)";
    case PLATFORM_INSTAGRAM:
      return "#EEEEFA";
    case PLATFORM_PINTEREST:
      return "rgba(230, 0, 35, 0.10)";
    default:
      return "rgba(24, 119, 242, 0.10)";
  }
};

export const BILL_STATUS_CANCEL = 0;
export const BILL_STATUS_WAITING = 1;
export const BILL_STATUS_COMPLETE = 2;
export const BILL_STATUS_USER_CONFIRM_PAYMENT = 3;
export const arrayBillStatus = {
  [BILL_STATUS_CANCEL]: "Đã hủy",
  [BILL_STATUS_WAITING]: "Đang đối soát",
  [BILL_STATUS_COMPLETE]: "Thành công",
  [BILL_STATUS_USER_CONFIRM_PAYMENT]: "Đang chờ xác nhận",
};

/**
 * Function that returns the first or first two letters from a name
 * @param {string} string
 * @returns {string}
 */
export const findUpper = (string) => {
  let extractedString = [];

  for (var i = 0; i < string?.length; i++) {
    if (
      string.charAt(i) === string.charAt(i).toUpperCase() &&
      string.charAt(i) !== " "
    ) {
      extractedString.push(string.charAt(i));
    }
  }
  if (extractedString.length > 1) {
    return extractedString[0] + extractedString[1];
  } else {
    return extractedString[0];
  }
};

export const divideArray = (array, numberOfParts, elementsPerPart) => {
  const copyChannel = [...array];
  var result = [];
  for (var i = 0; i < numberOfParts; i++) {
    if (array.length < elementsPerPart) {
      array = [...array, ...copyChannel];
    }
    result[i] = array.splice(0, elementsPerPart);
  }

  return result;
};

export const validPostTitle = (title) => {
  if (title instanceof Object) {
    return title.message || "Lỗi đăng bài chưa xác định";
  }
  return title || "Lỗi đăng bài chưa xác định";
};
export const SEEDING_STATUS_NEW = 0;
export const SEEDING_STATUS_SUCCESS = 1;
export const SEEDING_STATUS_ERROR = 2;
export const SEEDING_STATUS_PROCESSING = 3;

export const MANUAL_POST = "manualPost";
export const AUTO_POST = "autoPost";
export const BULK_POST_TIME = "bulkPostTime";
export const WAREHOUSE_FB = "warehouse_fb";
export const WAREHOUSE_IG = "warehouse_ig";
export const HEN_GIO_DANG = "schedulePost";
export const DANG_NGAY = "nowPost";
export const LICH_TU_DONG = "so9Post";
export const NGAU_NHIEN = "NgauNhien";
export const THU_CONG = "ThuCong";
export const TIME_DURATION_POST = 30; // unit: minutes
export const NUMBER_RETRY_POST = 5;

export const ALL_CHANNELS = "ALL_CHANNELS";
export const DEFAULT_CHANNELS = "DEFAULT_CHANNELS";
export const HEN_GIO_DANG_NGAU_NHIEN = "HenGioNgauNhien";

export const BLUE_VERIFIED = "blue_verified";
export const NOT_VERIFIED = "not_verified";

export const TYPE_POST = [
  {
    label: "config.post_type.photo",
    value: "photo",
  },
  {
    label: "config.post_type.short_form_video",
    value: "reel",
  },
  {
    label: "config.post_type.normal_video",
    value: "video",
  },
];
export const ARRAY_MANAGE_POST = [
  {
    label: "config.status_post.publish_success",
    value: STATUS_PUBLISH_SUCCESS,
  },
  {
    label: "config.status_post.scheduled",
    value: STATUS_SCHEDULED,
  },
  {
    label: "config.status_post.publish_error",
    value: STATUS_PUBLISH_ERROR,
  },
  {
    label: "config.status_post.process",
    value: STATUS_PROCESS,
  },
  {
    label: "config.status_post.process_schedule",
    value: STATUS_PROCESS_SCHEDULED,
  },
  {
    label: "config.status_post.pre_schedule",
    value: STATUS_PRE_SCHEDULED,
  },
  {
    label: "config.status_post.hide",
    value: STATUS_HIDE,
  },
  {
    label: "config.status_post.remove",
    value: STATUS_REMOVE,
  },
];

export const POST_STATUS_POST = {
  "-1": "Chờ lên lịch",
  "-2": "Đang lên lịch",
  "-3": "Lên lịch lỗi",
  "-4": "Hết hạn",
  0: "Có kênh lỗi",
  1: "Chờ đăng",
  2: "Thành công",
  3: "Đang lên lịch",
  4: "Ẩn bài",
  5: "Đã xoá",
};

export const TYPE_PLATFORM = [
  {
    label: "Facebook page",
    value: PLATFORM_FACEBOOK,
  },
  {
    label: "Tiktok",
    value: PLATFORM_TIKTOK,
  },
  {
    label: "Facebook group",
    value: PLATFORM_FACEBOOKGROUP,
  },
  {
    label: "Zalo",
    value: PLATFORM_ZALO,
  },
  {
    label: "Youtube",
    value: PLATFORM_YOUTUBE,
  },
  {
    label: "Instagram",
    value: PLATFORM_INSTAGRAM,
  },
  {
    label: "Threads",
    value: PLATFORM_THREADS,
  },
  {
    label: "Khác",
    value: PLATFORM_ORIGINAL,
  },
];

export const TYPE_CHANNEL_SETTING = [
  {
    label: "Kênh đã cài đặt lịch",
    value: 1,
  },
  {
    label: "Kênh chưa cài đặt lịch",
    value: 0,
  },
];

export const DATE_OF_WEEK = {
  1: "T2",
  2: "T3",
  3: "T4",
  4: "T5",
  5: "T6",
  6: "T7",
  0: "CN",
};

export const NUM_OF_DATE = {
  T2: 1,
  T3: 2,
  T4: 3,
  T5: 4,
  T6: 5,
  T7: 6,
  CN: 0,
};

export const LABEL_OF_DATE = {
  1: "Thứ 2",
  2: "Thứ 3",
  3: "Thứ 4",
  4: "Thứ 5",
  5: "Thứ 6",
  6: "Thứ 7",
  0: "Chủ nhật",
  Weekdays: "Ngày trong tuần",
  Weekends: "Cuối tuần",
  AllWeek: "Cả tuần",
};

export const START_DATE = {
  2: "Weekdays",
  1: NUM_OF_DATE["T2"],
  3: "AllWeek",
};

export const isValidUrl = (urlString) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export const POST_SCHEDULE = "schedule";
export const POST_AUTO = "auto";
export const POST_NOW = "now";

export const POST_TYPE_SCHEDULE_AUTO = "random";
export const POST_TYPE_SCHEDULE_SAME = "same";
export const POST_TYPE_SCHEDULE_CUSTOM = "custom";

export const CUSTOM_THUMB_TYPE_FROM_VIDEO = "from_video";
export const CUSTOM_THUMB_TYPE_FROM_UPLOAD = "upload_image";

export const GOOGLE_DRIVER_SCOPES = [
  "https://www.googleapis.com/auth/drive.file",
  "https://www.googleapis.com/auth/drive.appdata",
  "https://www.googleapis.com/auth/userinfo.profile",
  "https://www.googleapis.com/auth/userinfo.email",
];

export const GOOGLE_DRIVER_MIME_TYPES =
  "image/png,image/jpeg,image/jpg,video/quicktime,video/mp4,application/vnd.google-apps.folder";

export const GOOGLE_DRIVER_MIME_BY_TYPE = {
  photo: "image/png,image/jpeg,image/jpg,vnd.google-apps.folder",
  video: "video/mp4,video/quicktime,vnd.google-apps.folder",
  reel: "video/mp4,video/quicktime,vnd.google-apps.folder",
  video_and_reel: "video/mp4,video/quicktime,vnd.google-apps.folder",
};
