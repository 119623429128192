import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { RiLinkUnlink } from "@remixicon/react";
import { findUpper } from "../../../utils/Utils";
import { Icon, UserAvatar } from "../../../../components/Component";
import { LoadingItem } from "../../Dashboard_v2/CommonItem/LoadingItem";
import { getRandomTheme } from "./KanbanSectionForm";
import "../styles.css";
import { getDomainApp } from "../../../../utils/Utils";

const DetailContent = ({
  isCreateContent,
  setIsCreateContent,
  linkContent,
  isLoadingContent,
  posts,
  setLinkContent,
  handleCreateLinkContent,
  handleRemoveContent,
}) => {
  const { t } = useTranslation();
  const addContentRef = useRef(null);
  const inputContentRef = useRef(null);

  useEffect(() => {
    if (isCreateContent && inputContentRef.current) {
      inputContentRef.current.focus();
    }
  }, [isCreateContent]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isCreateContent &&
        addContentRef.current &&
        !addContentRef.current.contains(event.target)
      ) {
        setIsCreateContent(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isCreateContent]);

  return (
    <div className="w-100">
      <div className="detail-item">
        <Icon
          name="file"
          style={{
            color: posts?.length > 0 ? "#3577f1" : "#90959D",
            fontSize: "16px",
          }}
        />
        <div
          className="item-button"
          style={{
            fontSize: "12px",
            fontWeight: "400",
            color: isCreateContent || posts?.length > 0 ? "#212529" : "#90959D",
          }}
          onClick={() => setIsCreateContent(!isCreateContent)}
        >
          {t("task_management.detail.related_content")}
        </div>
      </div>

      <div
        style={{
          marginTop: "8px",
          display: !isCreateContent && posts?.length === 0 && "none",
        }}
      >
        {posts?.map((item) => {
          const domain = getDomainApp();
          const link = `${domain}/list-post-share/${item?.share_id}?project_id=${item?.project_id}`;

          return (
            <div className="subtask-item">
              <div
                className="d-flex items-center"
                style={{ gap: "12px", color: "#212529" }}
              >
                <img
                  src={
                    item?.media[0]?.type === 1
                      ? item?.media[0]?.name
                      : item?.media[0]?.thumb
                  }
                  alt="content"
                  style={{ objectFit: "cover" }}
                  width={16}
                  height={16}
                />
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  {item?.content}
                </span>
              </div>
              <div
                className="items-center relative d-flex"
                style={{
                  color: "#212529",
                  gap: "12px",
                  marginRight: "8px",
                }}
              >
                <div
                  className="d-flex items-center action-button"
                  onClick={() => handleRemoveContent(item)}
                >
                  <RiLinkUnlink
                    size={16}
                    className="text-muted cursor-pointer"
                  />
                </div>
                <div className="user-avatar-group">
                  <UserAvatar
                    key={item?.user?.name}
                    className="xs"
                    image={item?.user?.avatar}
                    text={findUpper(item?.user?.name)}
                    // theme={getRandomTheme()}
                  ></UserAvatar>
                </div>
                <div className="d-flex items-center">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${link}`}
                    color="#90959D"
                    className="d-flex items-center"
                  >
                    <Icon
                      name="arrow-right"
                      className={"action-button cursor-pointer"}
                      style={{ fontSize: "16px", color: "#90959D" }}
                    />
                  </a>
                </div>
              </div>
            </div>
          );
        })}
        {isCreateContent && (
          <div
            className="w-100 d-flex items-center justify-content-between"
            style={{
              backgroundColor: "#F5F6F7",
              padding: "8px 8px 8px 28px",
              height: "32px",
              borderRadius: "4px",
            }}
            ref={addContentRef}
          >
            <input
              className="subtask-input w-100"
              style={{
                fontSize: "12px",
                fontWeight: "400",
              }}
              type="text"
              placeholder={t("task_management.placeholder.paste_the_link")}
              value={linkContent}
              ref={inputContentRef}
              onChange={(e) => setLinkContent(e.target.value)}
              onKeyDown={handleCreateLinkContent}
            />
          </div>
        )}
        {posts?.length > 0 &&
          (isLoadingContent ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "32px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoadingItem />
            </div>
          ) : (
            <div
              className="item-button"
              style={{
                padding: "8px 8px 8px 28px",
              }}
              onClick={() => setIsCreateContent(!isCreateContent)}
            >
              <Icon
                name="plus"
                style={{
                  color: "#90959D",
                  fontSize: "16px",
                }}
              />
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                {t("task_management.detail.add_content")}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DetailContent;
