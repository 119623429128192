import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
// import Flatpickr from "react-flatpickr";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Button, Col, Nav, NavItem, NavLink, Row, Tooltip } from "reactstrap";
// import "flatpickr/dist/themes/material_blue.css";

import SimpleBar from "simplebar-react";
import { getListGroupChannels } from "../../../../api/channel";
import { getChannels } from "../../../../api/conversation";
import { getStaff } from "../../../../api/dashboard";
import { getUserInfoInLocalStorage } from "../helper";
import {
  PLATFORM_FACEBOOK,
  PLATFORM_FACEBOOKGROUP,
  PLATFORM_INSTAGRAM,
  PLATFORM_THREADS,
  PLATFORM_TIKTOK,
  PLATFORM_YOUTUBE,
} from "../../../utils/Utils";

import RightSidebar from "./RightSlidebar";
import { PLATFORM_NAME } from "../constants";
import classNames from "classnames";
import { ModalNotiUpcomingIns } from "./ModalNotiUpcomingIns";
import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

const HeaderDashboard = (props) => {
  const {
    selectedPlatform,
    setSelectedPlatForm,
    isEnterprise,
    channel,
    setChannels,
    loadingChannelFilter,
    setLoadingChannelFilter,
    loadingRange,
    setLoadingRange,
    staffs,
    setStaffs,
    setLoadingStaffFilter,
    loadingStaffFilter,
    from,
    to,
    setFrom,
    setTo,
  } = props;

  ///// COPY
  const location = useLocation();
  // const navigate = useNavigate();
  // const searchParams = new URLSearchParams(location.search);

  const { t } = useTranslation();
  const userInfo = getUserInfoInLocalStorage();
  const projectId = userInfo.last_project_active;
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenStaff, setIsOpenStaff] = useState(false);

  const [openFilter, setOpenFilter] = useState(false);
  const [modalWarning, setModalWarning] = useState(false);
  //
  const tabStatistic = [
    {
      key: "1",
      icon: "ri-facebook-box-fill",
      title: PLATFORM_NAME.FACEBOOK,
      label: "Facebook",
    },
    {
      key: "2",
      icon: "ri-tiktok-fill",
      title: PLATFORM_NAME.TIKTOK,
      label: "Tiktok",
    },
    {
      key: "3",
      icon: "ri-youtube-fill",
      title: PLATFORM_NAME.YOUTUBE,
      label: "Youtube",
    },
    {
      key: "4",
      icon: "ri-instagram-fill",
      title: PLATFORM_NAME.INSTAGRAM,
      label: "Instagram",
    },
    {
      key: "5",
      icon: "ri-threads-fill",
      title: PLATFORM_NAME.THREADS,
      label: "Threads",
    },
  ];
  const toggleWarning = () => {
    setModalWarning(!modalWarning);
  };
  const [filter, setFilter] = useState({
    channel_ids: [],
  });
  const toggleChanel = () => {
    setIsOpen(!isOpen);
  };

  const toggleStaff = () => {
    setIsOpenStaff(!isOpenStaff);
  };
  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  /**
   * CHANNELS
   */
  const [optionChannels, setOptionChannels] = useState([]);
  const [listChannels, setListChannels] = useState([]);

  // STAFFS
  const [optionStaffs, setOptionStaffs] = useState([]);

  const handleFetchChannel = async () => {
    // dispatch(resetDashboard());

    if (userInfo.last_project_active) {
      // setGettingData(true);
      const data = {};
      const fetchData = async () => {
        return await getChannels(projectId, data);
      };
      const dataResponse = (await fetchData()).data;

      if (dataResponse) {
        const { channel: channels, favorite_channel: _favoriteChannels } =
          dataResponse;
        setListChannels(channels);
      }

      if (isEnterprise) {
        const data = {
          params: {
            project_id: projectId,
            status: 1,
            from,
            to,
          },
        };
        const dataStaff = await getStaff(projectId, data);
        if (dataStaff.data?.length) {
          setOptionStaffs(
            dataStaff.data.map((staff) => ({ ...staff, checked: true }))
          );
        }
      }
    }
  };

  useEffect(() => {
    handleFetchChannel();
  }, [projectId]);

  useEffect(() => {
    const updateOptionChannels = (channels) => {
      return channels.map((channel) => ({
        ...channel,
        checked: true,
      }));
    };

    if (selectedPlatform === PLATFORM_NAME.FACEBOOK) {
      const filteredChannels = listChannels.filter(
        (item) =>
          item.platform === PLATFORM_FACEBOOK ||
          item.platform === PLATFORM_FACEBOOKGROUP
      );
      setOptionChannels(updateOptionChannels(filteredChannels));
    }
    if (selectedPlatform === PLATFORM_NAME.TIKTOK) {
      const filteredChannels = listChannels.filter(
        (item) => item.platform === PLATFORM_TIKTOK
      );
      setOptionChannels(updateOptionChannels(filteredChannels));
    }
    if (selectedPlatform === PLATFORM_NAME.YOUTUBE) {
      const filteredChannels = listChannels.filter(
        (item) => item.platform === PLATFORM_YOUTUBE
      );
      setOptionChannels(updateOptionChannels(filteredChannels));
    }
    if (selectedPlatform === PLATFORM_NAME.INSTAGRAM) {
      const filteredChannels = listChannels.filter(
        (item) => item.platform === PLATFORM_INSTAGRAM
      );
      setOptionChannels(updateOptionChannels(filteredChannels));
    }
    if (selectedPlatform === PLATFORM_NAME.THREADS) {
      const filteredChannels = listChannels.filter(
        (item) => item.platform === PLATFORM_THREADS
      );
      setOptionChannels(updateOptionChannels(filteredChannels));
    }
  }, [selectedPlatform, listChannels]);

  const onClearFilterForm = () => {
    removeSearchParams();
    setChannels([]);
    if (setStaffs) {
      setStaffs([]);
    }
    setFilter({
      ...filter,
      channel_ids: [],
      user_ids: [],
    });
  };
  const removeSearchParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const keys = searchParams.keys();
    for (const key of Array.from(keys)) {
      searchParams.delete(key);
    }
    setIsFilter(false);
    setFilter({
      channel_ids: [],
      user_ids: [],
    });
    setChannels([]);
    if (setStaffs) {
      setStaffs([]);
    }
    // navigate(location.pathname);
  };
  const handleSelectPlatform = (platform) => {
    removeSearchParams();
    setSelectedPlatForm(platform);
  };

  const handleConfirm = (channelSelected, staffSelected) => {
    setLoadingChannelFilter(true);
    setChannels(channelSelected);
    setLoadingChannelFilter(false);
    toggleChanel();
    if (staffSelected) {
      if (setStaffs) {
        setStaffs(staffSelected);
      }
    }
  };

  /**
   * STAFF
   */

  // const [channels, setChannels] = useState([]);
  const [selectAllStaffChecked, setSelectAllStaffChecked] = useState(true);
  const handleCheckStaffAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAllStaffChecked(isChecked);
    setOptionStaffs((prevOptionStaffs) =>
      prevOptionStaffs.map((staff) => {
        return { ...staff, checked: isChecked };
      })
    );
  };
  const handleCheckboxStaffChange = (index) => {
    setOptionStaffs((prevOptionStaffs) => {
      const updatedStaffs = [...prevOptionStaffs];
      updatedStaffs[index].checked = !updatedStaffs[index].checked;
      return updatedStaffs;
    });
  };

  useEffect(() => {
    const allChecked = optionStaffs.every(
      (channel) => channel.checked === true
    );
    if (selectAllStaffChecked !== allChecked) {
      setSelectAllStaffChecked(allChecked);
    }
  }, [optionStaffs, selectAllStaffChecked]);

  const [loading, setLoading] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [groupChannel, setGroupChannel] = useState();
  const [loadingFilter, setLoadingFilter] = useState(false);

  useEffect(() => {
    fetchGroupChannel();
  }, []);

  const userInfor = getUserInfoInLocalStorage();
  const fetchGroupChannel = async () => {
    try {
      const res = await getListGroupChannels(userInfor.last_project_active);
      if (res.status) {
        setGroupChannel(res.data);
      } else {
        console.log("group error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const paramName = channel?.length > 0 ? "channel_ids" : "";
    const paramValue = channel?.length > 0 ? channel : [""];

    searchParams.set("from", from);
    searchParams.set("to", to);

    if (staffs?.length) {
      searchParams.set("staffs", staffs);
    }

    if (searchParams.has(paramName)) {
      searchParams.set(paramName, paramValue);
    } else {
      searchParams.append(paramName, paramValue);
    }

    if (channel?.length || staffs?.length) {
      setIsFilter(true);
    }
  }, [channel, from, to, staffs]);

  ///filter

  const onFilter = async () => {
    if (!filter.channel_ids.length && !filter?.user_ids?.length) {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    handleConfirm(filter.channel_ids || [], filter?.user_ids || []);
    setLoadingFilter(false);
    setLoading(false);
    setOpenFilter(false);
    // }
  };

  const handleDateChange = (selectedDates) => {
    console.log("selectedDates: ", selectedDates);

    const [startTime, endTime] = selectedDates;
    // const endTime = selectedDates[1];
    if (startTime && endTime) {
      setFrom(moment(startTime).format("YYYY-MM-DD"));
      setTo(moment(endTime).format("YYYY-MM-DD"));
    }
  };

  const [rangeDate, setRangeDate] = useState({
    start: new Date(from),
    end: new Date(to),
  });

  const onRangeChange = (dates) => {
    const [start, end] = dates;
    setRangeDate({ start: start, end: end });

    if (start && end) {
      setFrom(moment(start).format("YYYY-MM-DD"));
      setTo(moment(end).format("YYYY-MM-DD"));
    }
  };

  const onClearFilter = () => {
    // removeSearchParams();
    // setChannels([]);
    // if (setStaffs) {
    //   setStaffs([]);
    // }
    // setFilter({
    //   ...filter,
    //   channel_ids: [],
    //   user_ids: [],
    // });
    setOpenFilter(true);
    setIsFilter(true);
  };

  const openSideBar = () => {
    setOpenFilter(true);
  };

  const handleListChange = (selected) => {
    let data = [];
    selected.forEach((item) => {
      data = [...data, item.value];
    });
    setFilter({ ...filter, channel_ids: data });
  };
  const handleGroupChange = (selected) => {
    let data = [];
    selected.forEach((item) => {
      data = [...data, ...item.chanel_ids];
    });
    setFilter({ ...filter, channel_ids: [...data] });
  };

  const handleStaffsChange = (selected) => {
    let data = [];
    selected.forEach((item) => {
      data = [...data, item.value];
    });
    setFilter({ ...filter, user_ids: [...data] });
  };

  const [isOpenUpComingIns, setIsOpenUpComingIns] = useState(false);
  const toggleModalUpcomingIns = () => {
    setIsOpenUpComingIns(!isOpenUpComingIns);
  };

  const toolTipTextTiktok = (
    <div>
      {t("header_dashboard.tooltipHeaderTiktok")}{" "}
      <a
        href="https://www.tiktok.com/feedback?id=7133058093574806018&lang=en&type="
        target="_blank"
      >
        {t("header_dashboard.here")}
      </a>{" "}
      .
    </div>
  );

  const [tooltipOpen, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!tooltipOpen);
  };

  const DatePickerIcon = ({ onClick }) => (
    <div onClick={onClick}>
      <i className="ri-calendar-todo-fill"></i>
    </div>
  );

  const elementRef = useRef(null);
  const [isResponsive, setIsResponsive] = useState(false);
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width } = entry.contentRect;
        // Perform actions based on the size changes

        if (width < 800) {
          setIsResponsive(true);
        } else {
          setIsResponsive(false);
        }
      }
    });

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div ref={elementRef}>
      <Row className="mb-3 pb-1 px-8">
        <Col xs={12}>
          <Row className="d-flex align-items-lg-center flex-lg-row flex-column nav nav-tabs custom-hover-nav-tabs  justify-content-between ">
            <Nav tabs className="nav nav-tabs custom-hover-nav-tabs">
              {tabStatistic.map((item, index) => (
                <NavItem key={item + index}>
                  <NavLink
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className={classNames({
                      active: selectedPlatform === item.title,
                    })}
                    onClick={() => {
                      if (
                        item.title === PLATFORM_NAME.INSTAGRAM &&
                        isEnterprise
                      ) {
                        setIsOpenUpComingIns(true);
                      } else if (item.title === PLATFORM_NAME.THREADS) {
                        setIsOpenUpComingIns(true);
                      } else {
                        handleSelectPlatform(item.title);
                      }
                    }}
                  >
                    <i style={{ fontSize: "18px" }} className={item.icon} />
                    {!isResponsive && (
                      <span style={{ fontSize: "16px", paddingLeft: "6px" }}>
                        {item.label}
                      </span>
                    )}

                    {/* <img src={item.icon} /> */}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <NavItem className="d-flex g-3 mb-0 align-items-center">
              <div className=" mt-3 mt-lg-0">
                <div className="d-flex g-3 mb-0 align-items-center">
                  <div className="col-auto">
                    {!isFilter && (
                      <Button color="info" type="button" onClick={openSideBar}>
                        <i className="ri-filter-line align-bottom me-1"></i>{" "}
                        {!isResponsive && t("TeamFilterBttn")}
                      </Button>
                    )}

                    {isFilter && (
                      <Button
                        color="success"
                        type="button"
                        onClick={onClearFilter}
                      >
                        <i className="ri-filter-off-line align-bottom me-1"></i>{" "}
                        {!isResponsive && t("PostClearFilterBttn")}
                      </Button>
                    )}
                  </div>
                  <div className="col-auto">
                    <div className="input-group">
                      <div
                        className="form-control-wrap"
                        style={{
                          width: isResponsive ? undefined : "185px",
                        }}
                      >
                        <DatePicker
                          selected={rangeDate.start}
                          startDate={rangeDate.start}
                          onChange={onRangeChange}
                          endDate={rangeDate.end}
                          selectsRange
                          dateFormat="dd/MM/yyyy"
                          className="form-control date-picker"
                          customInput={
                            isResponsive ? <DatePickerIcon /> : undefined
                          }
                        />{" "}
                      </div>
                    </div>
                  </div>

                  <div className="col-auto" id="tooltipHeader">
                    <button
                      type="button"
                      className="btn btn-soft-info btn-icon waves-effect waves-light layout-rightside-btn shadow-none"
                      onClick={props.rightClickBtn}
                    >
                      <i className="ri-information-line"></i>
                    </button>
                  </div>

                  {selectedPlatform === PLATFORM_NAME.TIKTOK && (
                    <Tooltip
                      placement="bottom"
                      isOpen={tooltipOpen}
                      target={`tooltipHeader`}
                      autohide={false}
                      toggle={toggle}
                      style={{
                        width: "100%",
                        minWidth: "360px",
                        textAlign: "left",
                      }}
                    >
                      {selectedPlatform === PLATFORM_NAME.TIKTOK
                        ? toolTipTextTiktok
                        : ""}
                    </Tooltip>
                  )}
                </div>
              </div>
            </NavItem>
          </Row>
        </Col>
      </Row>

      <ModalNotiUpcomingIns
        isOpenUpComingIns={isOpenUpComingIns}
        toggleModalUpcomingIns={toggleModalUpcomingIns}
        setIsOpenUpComingIns={setIsOpenUpComingIns}
      />

      <div
        className={`nk-demo-panel nk-demo-panel-4x toggle-slide toggle-slide-right toggle-screen-any p-0 ${
          openFilter ? "content-active" : ""
        }`}
        style={{ maxWidth: "400px", minWidth: "400px" }}
      >
        <RightSidebar
          channel={channel}
          open={openFilter}
          setOpenFilter={setOpenFilter}
          toggle={toggleFilter}
          onClearFilter={onClearFilterForm}
          onFilter={onFilter}
          listChannel={optionChannels}
          groupChannel={groupChannel}
          handleListChange={handleListChange}
          handleGroupChange={handleGroupChange}
          loadingFilter={loadingFilter}
          isEnterprise={isEnterprise}
          staffs={optionStaffs}
          staffSelected={staffs}
          handleStaffsChange={handleStaffsChange}
        ></RightSidebar>
      </div>
      {openFilter && (
        <div className="toggle-overlay" onClick={() => toggleFilter()}></div>
      )}
    </div>
  );
};

export default HeaderDashboard;
