import React, { useState, useEffect } from "react";
// import Logo from "../../images/logo.png";
// import LogoDark from "../../images/logo-so9.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
// import AuthFooter from "./AuthFooter";
// import {
//   Block,
//   BlockContent,
//   BlockDes,
//   BlockHead,
//   BlockTitle,
//   Button,
//   Icon,
//   PreviewCard,
// } from "../../components/Component";
// import { Form, FormGroup, Spinner, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import style from "./Auth.module.scss";
// import { TextFieldAuth, PasswordFieldAuth } from "../../commons/TextField/TextFieldAuth";
import { login } from "../../api/auth";
import { yupResolver } from "@hookform/resolvers/yup";
import { resendEmail } from "../../api/auth";
import * as Yup from "yup";
import { validateEmail } from "../../libs/helper";
import useCheckLogin from "../../hooks/useCheckLogin";
// import ResendCountdown from "./ResendCountdown";
import Cookies from "js-cookie";
import useQuery from "../../hooks/useQuery";
import { updateAffClick } from "../../api/iris";
// import AuthSlider from "../../So9/components/AuthNew/authCarousel";
import CoverSignIn from "../../So9/components/AuthNew/CoverSignIn";
import { getDomainForCookie } from "../../utils/Utils";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [errorVal, setError] = useState("");
  const [reSend, setTimeResend] = useState(true);
  const [userInfor, setUserInfor] = useState();
  const { t } = useTranslation();
  const [timeRemain, setTimeRemain] = useState(0);
  useCheckLogin();

  const redirectToDashboard = () => {
    const userLogin = JSON.parse(localStorage.getItem("userInfor"));
    if (userLogin.is_new_user === 1) {
      window.location.replace('/news');
    } else {
      window.location.replace('/news');
    }
  };

  const onFormSubmit = (formData) => {
    setLoading(true);
    formData.type = 3;
    login(formData).then((res) => {
      if (res.status) {
        localStorage.setItem("accessToken", res.data.access_token);
        localStorage.setItem("is_new_user", res.data.is_new_user);
        localStorage.setItem("userInfor", JSON.stringify(res.data));

        const domain = getDomainForCookie();
        Cookies.set('accessToken', res.data.access_token, { domain, expires: 1 })
        Cookies.set('userInfor', JSON.stringify(res.data), { domain, expires: 365 })

        redirectToDashboard();
      } else {
        const userInfo = {
          email: formData.email
        };
        setUserInfor(userInfo)
        setError(res.message);
        setLoading(false);
        if (localStorage.getItem('resendInfo')) {
          const arrResendInfo = JSON.parse(localStorage.getItem("resendInfo"));
          if (arrResendInfo.length > 0) {
            const userResend = arrResendInfo.find((item) => item.email === formData.email);
            if (userResend) {
              setTimeResend(false);
              const timeStart = userResend.countdown;
              const timeRemain = Math.floor(Math.abs(Date.now() - timeStart));
              setTimeRemain(timeRemain);
            }
            else {
              setTimeResend(true);
            }
          }
        }
      }
    });
    return true;
  };

  const onResend = () => {
    if (reSend) {
      resendEmail(userInfor).then((res) => {
        if (res.status) {
          setTimeResend(false);
          if (localStorage.getItem('resendInfo')) {
            let arrResendInfo = JSON.parse(localStorage.getItem('resendInfo'));
            const dataInfo = {
              email: userInfor.email,
              countdown: Date.now(),
            };
            arrResendInfo.push(dataInfo);
            localStorage.setItem('resendInfo', JSON.stringify(arrResendInfo));
          }
          else {
            let arrResendInfo = [];
            const dataInfo = {
              email: userInfor.email,
              countdown: Date.now(),
            };
            arrResendInfo.push(dataInfo);
            localStorage.setItem('resendInfo', JSON.stringify(arrResendInfo));
          }
          setTimeRemain(0);
        } else {
          setError(res.message);
          setLoading(false);
        }
      }).catch((err) => {
        setError(err?.response?.data?.message);
        setLoading(false);
      })
    }
  }

  Yup.addMethod(Yup.string, "isValidEmail", function (errorMessage) {
    return this.test("is-valid-email", errorMessage, function (value) {
      const { path, createError } = this;
      value = validateEmail(value);
      if (!value) {
        return createError(path, errorMessage);
      }
      return true;
    });
  });
  const formSchema = Yup.object().shape({
    email: Yup.string().required(t("validate_field_email_required")).isValidEmail(t("validate_field_email")),
    password: Yup.string()
      .required(t("validate_field_password_required"))
      .min(4, t("validate_field_password_character_required", { length: 4 })),
  });
  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const query = useQuery();
  const aceptClick = async (ref_code) => {
    const param = {
      ref_code: ref_code,
    }
    try {
      await updateAffClick(param);
    } catch (error) {
      console.log("click=>", error);
    }

  }
  useEffect(() => {
    const refParam = query.get('ref')
    if (refParam) {
      const refCookies = Cookies.get('refCode');
      const domain = getDomainForCookie();
      if (!refCookies) {
        Cookies.set('refCode', refParam, { domain, expires: 30 })
        aceptClick(refParam)
      } else if (refCookies && refCookies !== refParam) {
        Cookies.set('refCode', refParam, { domain, expires: 30 })
        aceptClick(refParam)
      }
    }
  }, [query])
  return (
    <React.Fragment>
      <Head title={t("auth_sign_in")} />
      <PageContainer>
        {/* <Block className="nk-block-middle nk-auth-body wide-xs">
      
          <div className="brand-logo pb-4 text-center">
            <a className="logo-link" href="https://so9.vn/" target="_blank" rel="noreferrer">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </a>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">{t("auth_sign_in")}</BlockTitle>
                <BlockDes>
                  <p>{t("auth_log_in_suggest")}</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errorVal}{"."}
                  {
                    errorVal === 'Kiểm tra email để kích hoạt tài khoản' ? <span className={`${style.ResendButton} ${!reSend && style.waitResend}`} onClick={onResend}>{reSend ? t("auth_resend") : <ResendCountdown timeRemain={timeRemain} setTimeResendParent={() => setTimeResend(true)} userEmail={userInfor.email} />}</span> : ''
                  }
                </Alert>
              </div>
            )}
            <Form onSubmit={handleSubmit(onFormSubmit)}>
              <FormGroup>
                <TextFieldAuth
                  field={"email"}
                  current={watch("email")}
                  register={register}
                  setValue={setValue}
                  isEmail={true}
                  required={true}
                  errors={errors.email}
                  title={t("auth_email")}
                  placeholder={t("auth_placeholder_email")}
                />
              </FormGroup>
              <FormGroup>
                <PasswordFieldAuth
                  field={"password"}
                  current={watch("password")}
                  register={register}
                  setValue={setValue}
                  required={true}
                  errors={errors.password}
                  title={t("auth_password")}
                  isLoginPage={true}
                  placeholder={t("auth_placeholder_password")}
                />
              </FormGroup>
              <FormGroup>
                <Button size="lg" className={`btn-block ${style.LoginButton}`} type="submit">
                  {loading ? <Spinner size="sm" color="light" /> : t("auth_sign_in_button")}
                </Button>
              </FormGroup>
            </Form>
            <div className="form-note-s2 text-center pt-4">
              {" "}
              {t("auth_dont_have_account")} <Link to="/auth-register">{t("auth_create_an_account")}</Link>
            </div>
            <div className="text-center pt-4 pb-3">
              <h6 className="overline-title overline-title-sap">
                <span>OR</span>
              </h6>
            </div>
            <Button size="lg" className={`btn-block ${style.LogFaceButton}`} type="button">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>

                  <img className={`tb-image mr-2 ${style.connectToken}`} src={Facebook} alt=" Facebook" style={{ width: "24px", height: "24px", borderRadius: "50%" }} />
                </div>
                <div style={{ marginLeft: "4px", fontWeight: "700" }}>
                  Sử dụng ngay với Facebook
                </div>
              </div>
            </Button>
            <Button size="lg" className={`btn-block ${style.LogGgButton}`} type="button">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>

                  <img className={`tb-image mr-2 ${style.connectToken}`} src={Google} alt=" Facebook" style={{ width: "24px", height: "24px", borderRadius: "50%" }} />
                </div>
                <div style={{ marginLeft: "4px", fontWeight: "700" }}>
                  Sử dụng ngay với Google
                </div>
              </div>
            </Button>
          </PreviewCard>
        </Block> */}
        <CoverSignIn />
      </PageContainer>
    </React.Fragment>
  );
};
export default Login;
