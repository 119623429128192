import axios from "axios";
import { API } from "../libs/client";

/**
 * @param {{params :{project_id: string, time: string, channel_ids: string[] }}} data
 * @returns
 */
export const getInforUserFacebook = async (data) => {
  const uri = "/projects/dashboard/facebook/overview/user";
  const res = await API.get(uri, data);
  return res.data;
};

export const getPostEngageFacebook = async (data) => {
  const uri = "/projects/dashboard/facebook/overview/post-engage";
  const res = await API.get(uri, data);
  return res.data;
};

export const getEffectiveFacebook = async (data) => {
  const uri = "/projects/dashboard/facebook/overview/effective";
  const res = await API.get(uri, data);
  return res.data;
};

export const getReachFacebook = async (data) => {
  const uri = "/projects/dashboard/facebook/overview/reach";
  const res = await API.get(uri, data);
  return res.data;
};

export const getPostAndEngageFacebook = async (data) => {
  const uri = "/projects/dashboard/facebook/post-and-engage/index";
  const res = await API.get(uri, data);
  return res.data;
};
export const getTopPostFacebook = async (data) => {
  const uri = "/projects/dashboard/facebook/post-and-engage/top-post";
  const res = await API.get(uri, data);
  return res.data;
};
export const getTimeGoldFacebook = async (data) => {
  const uri = "/projects/dashboard/facebook/post-and-engage/time-gold";
  const res = await API.get(uri, data);
  return res.data;
};
export const getEngageAndReachFacebook = async (data) => {
  const uri = "/projects/dashboard/facebook/post-and-engage/engage-and-reach";
  const res = await API.get(uri, data);
  return res.data;
};

export const getReachIndexFacebook = async (data) => {
  const uri = "/projects/dashboard/facebook/reach/index";
  const res = await API.get(uri, data);
  return res.data;
};
export const getReachLocationFacebook = async (data) => {
  const uri = "/projects/dashboard/facebook/reach/location";
  const res = await API.get(uri, data);
  return res.data;
};
export const getReachDemographicFacebook = async (data) => {
  const uri = "/projects/dashboard/facebook/reach/demographic";
  const res = await API.get(uri, data);
  return res.data;
};

// YT

export const getSummaryAudienceYT = async (data) => {
  const uri = "/projects/dashboard/youtube/summary/audience";
  const res = await API.get(uri, data);
  return res.data;
};

export const getSummaryPostAndEngagementYT = async (data) => {
  const uri = "/projects/dashboard/youtube/summary/post-and-engagement";
  const res = await API.get(uri, data);
  return res.data;
};

export const getSummaryVideoPerformanceYT = async (data) => {
  const uri = "/projects/dashboard/youtube/summary/video-performance";
  const res = await API.get(uri, data);
  return res.data;
};

export const getPostAndEngageYT = async (data) => {
  const uri = "/projects/dashboard/youtube/post-and-engage/index";
  const res = await API.get(uri, data);
  return res.data;
};

export const getPostAndEngageTopVideoYT = async (data) => {
  const uri = "/projects/dashboard/youtube/post-and-engage/highlight-video";
  const res = await API.get(uri, data);
  return res.data;
};

export const getVideoPerformanceYT = async (data) => {
  const uri = "/projects/dashboard/youtube/video-performance/index";
  const res = await API.get(uri, data);
  return res.data;
};

export const getVideoPerformanceTopVideoYT = async (data) => {
  const uri = "/projects/dashboard/youtube/video-performance/highlight-video";
  const res = await API.get(uri, data);
  return res.data;
};

export const getAudienceYT = async (data) => {
  const uri = "/projects/dashboard/youtube/audience/demographic";
  const res = await API.get(uri, data);
  return res.data;
};

// TT

export const getSummaryTT = async (data) => {
  const uri = "/projects/dashboard/tiktok/summary";
  const res = await API.get(uri, data);
  return res.data;
};

// FB New

export const getOverviewCustomerFb = async (data) => {
  const uri = "/projects/dashboard/facebook/overview/customer";
  const res = await API.get(uri, data);
  return res.data;
};

export const getOverviewPageReachFb = async (data) => {
  const uri = "/projects/dashboard/facebook/overview/page-reach";
  const res = await API.get(uri, data);
  return res.data;
};

export const getOverviewPageEffectiveFb = async (data) => {
  const uri = "/projects/dashboard/facebook/overview/page-effective";
  const res = await API.get(uri, data);
  return res.data;
};

export const getTimeGoldFacebookNew = async (data) => {
  const uri = "/projects/dashboard/facebook/post-and-engage/time-gold";
  const params = JSON.parse(JSON.stringify(data));
  params.params.v = 2;
  const res = await API.get(uri, params);
  return res.data;
};

export const getFrequencyFacebook = async (data) => {
  const uri = "projects/dashboard/facebook/frequency/index";
  const res = await API.get(uri, data);
  return res.data;
};

export const getViaFacebook = async (data) => {
  const uri = "projects/dashboard/facebook/via-frequency/index";
  const res = await API.get(uri, data);
  return res.data;
};

export const getLogFetchFb = async (data) => {
  const uri = "projects/dashboard/facebook/log-fetch";
  const res = await API.get(uri, data);
  return res.data;
};

//TT New

export const getSummaryTTNew = async (data) => {
  const uri = "/projects/dashboard/tiktok/summary/index";
  const res = await API.get(uri, data);
  return res.data;
};

export const getFanOnlineTT = async (data) => {
  const uri = "projects/dashboard/tiktok/summary/fan-online";
  const res = await API.get(uri, data);
  return res.data;
};

export const getFrequencyTT = async (data) => {
  const uri = "projects/dashboard/tiktok/frequency/index";
  const res = await API.get(uri, data);
  return res.data;
};

export const getLogFetchTT = async (data) => {
  const uri = "projects/dashboard/tiktok/log-fetch";
  const res = await API.get(uri, data);
  return res.data;
};

// Youtube new
export const getSummaryYT = async (data) => {
  const uri = "projects/dashboard/youtube/summary/index";
  const res = await API.get(uri, data);
  return res.data;
};

export const getFrequencyYT = async (data) => {
  const uri = "projects/dashboard/youtube/frequency/index";
  const res = await API.get(uri, data);
  return res.data;
};

export const getAudienceYTNew = async (data) => {
  const uri = "projects/dashboard/youtube/audience/demographic";
  const res = await API.get(uri, data);
  return res.data;
};

export const getLogFetchYT = async (data) => {
  const uri = "projects/dashboard/youtube/log-fetch";
  const res = await API.get(uri, data);
  return res.data;
};

// Instagram

export const getSummaryIns = async (data) => {
  const uri = "projects/dashboard/instagram/summary/index";
  const res = await API.get(uri, data);
  return res.data;
};

export const getFrequencyIns = async (data) => {
  const uri = "projects/dashboard/instagram/frequency/index";
  const res = await API.get(uri, data);
  return res.data;
};

export const getAudienceIns = async (data) => {
  const uri = "projects/dashboard/instagram/summary/audience";
  const res = await API.get(uri, data);
  return res.data;
};

export const getLogFetchIns = async (data) => {
  const uri = "projects/dashboard/instagram/log-fetch";
  const res = await API.get(uri, data);
  return res.data;
};

export const getFanOnlineIns = async (data) => {
  const uri = "projects/dashboard/instagram/summary/online-follower";
  const res = await API.get(uri, data);
  return res.data;
};

// STAFF
export const getStaff = async (projectId, data) => {
  const uri = `projects/${projectId}/list-user`;
  const res = await API.get(uri, data);
  return res.data;
};

// STAFF-FB

export const getCountPost = async (data) => {
  const uri = `projects/dashboard/facebook/employee/count-posts`;
  const res = await API.get(uri, data);
  return res.data;
};

export const getUserStatistic = async (data) => {
  const uri = `projects/dashboard/facebook/employee/user-statictis`;
  const res = await API.get(uri, data);
  return res.data;
};

// STAFF-TT

export const getCountPostTT = async (data) => {
  const uri = `projects/dashboard/tiktok/employee/count-posts`;
  const res = await API.get(uri, data);
  return res.data;
};

export const getUserStatisticTT = async (data) => {
  const uri = `projects/dashboard/tiktok/employee/user-statictis`;
  const res = await API.get(uri, data);
  return res.data;
};
export const getUserStatisticYT = async (data) => {
  const uri = `projects/dashboard/youtube/employee/user-statictis`;
  const res = await API.get(uri, data);
  return res.data;
};

// CONTENT-FB

export const getContentGeneral = async (data) => {
  const uri = `projects/dashboard/facebook/content/general`;
  const res = await API.get(uri, data);
  return res.data;
};

export const getContentChart = async (data) => {
  const uri = `projects/dashboard/facebook/content/impre-engaged`;
  const res = await API.get(uri, data);
  return res.data;
};

export const getContentTopPost = async ({ data, platform }) => {
  const uri = `projects/dashboard/${platform}/content/top-post`;
  const res = await API.get(uri, data);
  return res.data;
};

export const getContentStatistic = async (data, type) => {
  const uri = `projects/dashboard/${type}/content/content-statictis`;
  const res = await API.get(uri, data);
  return res.data;
};

export const getContentGeneralTiktok = async (data) => {
  const uri = `projects/dashboard/tiktok/content/general`;
  const res = await API.get(uri, data);
  return res.data;
};
export const getContentGeneralYoutube = async (data) => {
  const uri = `projects/dashboard/youtube/content/general`;
  const res = await API.get(uri, data);
  return res.data;
};

export const getContentStatisticTiktok = async (data) => {
  const uri = `projects/dashboard/tiktok/content/content-statictis`;
  const res = await API.get(uri, data);
  return res.data;
};

export const getContentStatisticYoutube = async (data) => {
  const uri = `projects/dashboard/youtube/content/content-statictis`;
  const res = await API.get(uri, data);
  return res.data;
};

export const getCountPostYoutube = async (data) => {
  const uri = `projects/dashboard/youtube/employee/count-posts`;
  const res = await API.get(uri, data);
  return res.data;
};

export const getChannelError = async (projectId, data) => {
  const uri = `projects/${projectId}/channels/list-error`;
  const res = await API.get(uri, data);
  return res.data;
};

// dashboard tasklist
export const getListTask = async (projectId, data) => {
  const uri = `projects/${projectId}/task/list-to-do`;
  const res = await API.get(uri, data);
  return res.data;
};

export const getListNotify = async (projectId, page, data) => {
  const uri = `projects/${projectId}/notifications/list?page=${page}`;
  const res = await API.get(uri, data);
  return res.data;
};

export const getStatusStatic = async (projectId, date, data) => {
  const uri = `projects/${projectId}/posts/statistic-status?date=${date}`;
  const res = await API.get(uri, data);
  return res.data;
};
