import { Spinner } from "reactstrap";

export const LoadingItem = (props) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spinner
        // className="bottom-50 start-50"
        animation="border"
        color={!props?.style?.color ? "primary" : ""}
        className="d-flex justify-content-center align-items-center" // Responsive centering
        style={{ ...props.style }}
      />
    </div>
  );
};
