import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
    Card,
    CardBody,
    CardFooter,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Spinner
} from "reactstrap";
import { Icon, Img } from "../../../components/Component";
import {
    MEDIA_TYPE_IMAGE,
    MEDIA_TYPE_VIDEO,
    STATUS_REMOVE
} from "../../utils/Utils";
import {
    ARR_POST_STATUS_POST, POST_STATUS_PUBLISH_SUCCESS, POST_STATUS_SCHEDULED

} from "../../constants/posts";
import style from "./index.module.scss";
import IconConvert from "../../images/icons/icon-convert.svg"
import moment from "moment/moment";
import { IconPost } from "../../../libs/icon";
import IconOpenLink from "../../images/icons/icon-openlink.svg"
import DefaultImage from "../../images/avatar/no-image.png";
import {
    PostCommentSeeding,
} from "../../../api/createPost";
const ColumnContent = ({ data: item, channel, ListUser, clickContent, getListUser, isLoadListUser, loadSeeding, className = "" }) => {
    const initSeeding = {
        user_seeding: null,
        scheduled_at: new Date()
    }
    const { t, i18n } = useTranslation();
    const [isComment, setComment] = useState(false);
    const [isLike, setLike] = useState(false);
    const [comment, setTextComment] = useState("")
    const [formSeeding, setSeeding] = useState(initSeeding);
    const projectId = useSelector((state) => state.project.project?._id);

    const PLATFORM_FACEBOOK = 1;
    const PLATFORM_TIKTOK = 2;
    const PLATFORM_FACEBOOKGROUP = 3;
    const PLATFORM_ZALO = 4;
    const PLATFORM_YOUTUBE = 5;
    const PLATFORM_INSTAGRAM = 6;
    const PLATFORM_ORIGINAL = 0;
    const [showTopRightOption, setShowTopRightOption] = useState(false);

    useEffect(() => {
        setSeeding({ ...formSeeding, user_seeding: { ...ListUser[0] } });
    }, [ListUser])

    const handelchannelUserSeeding = (userSeeding) => {
        //console.log({ userSeeding });
        setSeeding({ ...formSeeding, user_seeding: { ...userSeeding } });
        //set(userSeeding);
    }
    const onFormSubmitComment = async () => {
        const content = {
            "postId": item._id,
            "platformPostId": channel.platform_id,
            "message": comment,
            "platform": channel.platform,
            "scheduleAt": item.status === POST_STATUS_PUBLISH_SUCCESS ? Math.floor((new Date().getTime() - 5 * 60 * 1000) / 1000) : Math.floor((new Date().getTime() + 10 * 60 * 1000) / 1000),
            "seedingSubject": formSeeding.user_seeding._id,
            "image": null
        }
        await PostCommentSeeding(projectId, content).then(res => {
            if (res.status) {
                //resetListSetting();

                setTextComment("")
                toast.success("Thêm comment thành công!", {
                    autoClose: 1000,
                    position: "top-right",
                });
            }
            else {
                //console.log(res)
                toast.error("Thêm comment thất bại!", {
                    autoClose: 1000,
                    position: "top-right",
                });
            }
        }).catch(error => {
            console.log(error)
            toast.error("Thêm comment thất bại!", {
                autoClose: 1000,
                position: "top-right",
            });
        });
    }
    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shift) {
            onFormSubmitComment();
        }
    };

    const handelActionComment = () => {
        getListUser();
        isLoadListUser.current = false;
        setTextComment("");
        setComment(!isComment);
    }
    const handelActionLike = () => {
        setLike(!isLike);
    }
    const handleOpenPostId = () => {
        let LinkChannelId;
        switch (channel.platform) {
            case 1:
                LinkChannelId = item._id
                window.open(`https://www.facebook.com/${LinkChannelId}`, "_blank");
                break;
            case 2:
                LinkChannelId = item._id
                window.open(`https://www.tiktok.com/${LinkChannelId}`, "_blank");
                break;
            case 3:
                LinkChannelId = item._id
                window.open(`https://www.facebook.com/${LinkChannelId}`, "_blank");
                break;
            case 5:
                LinkChannelId = item._id
                window.open(`https://www.youtube.com/watch?v=${LinkChannelId}`, "_blank");
                break;
            case 6:
                LinkChannelId = item._id
                window.open(`https://www.instagram.com/`, "_blank");
                break;
            default:
        }
    }
    const renderFirstMedia = () => {
        const firstMedia = item.media?.[0];
        if (!firstMedia) {
            return null;
        }
        switch (firstMedia.type) {
            case MEDIA_TYPE_IMAGE:
                return (
                    <div className="mt-2 text-center">
                        <Img src={firstMedia.url} alt="" className={`rounded ${firstMedia.url && "h-64"}`} />
                    </div>
                );
            case MEDIA_TYPE_VIDEO:
                return (
                    <div style={{
                        backgroundImage: `url(${firstMedia?.thumb})`,
                        backgroundSize: "contain",
                        //opacity:"0.5"
                    }}>
                        <div className="mt-2  d-flex justify-center" style={firstMedia?.thumb && { background: 'rgba(0, 0, 0, 0.5)', }}>
                            <div className={`position-relative ${style.column__backgroundImage}`} >
                                <Img src={firstMedia?.thumb} className={`rounded ${firstMedia?.thumb && "h-64"}`} />
                                <div className="fs-18px text-primary position-absolute" style={{ bottom: 2, right: 6 }}>
                                    <Icon name="play-fill"></Icon>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    const renderAction = (platform) => {
        switch (platform) {
            case PLATFORM_FACEBOOK:
                return (<div className="d-flex justify-between mt-3">
                    <div className="d-flex">
                        <div
                            className="flex-grow-1 flex-shrink-1 d-flex align-items-center mr-2"
                            onClick={() => {
                                handelActionLike();
                            }}>
                            {/* <Icon className={`fs-18px ${isLike && "text-pink-500"}`} name="thumbs-up" ></Icon>{" "}
                            <span className="fs-12px">{isLike ? "Đã thích" : "Thích"}</span> */}
                            <Icon className={`fs-18px `} name="thumbs-up" ></Icon>
                            <span className="fs-12px"> Thích</span>
                        </div>
                        <div
                            className="flex-grow-1 flex-shrink-1 d-flex align-items-center"
                            onClick={() => {
                                handelActionComment();
                            }}>
                            <Icon className="fs-18px" name="comments"></Icon>{" "}
                            <span className="fs-12px">Bình luận</span>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="flex-grow-1 flex-shrink-1 d-flex align-items-center mr-2">
                            <Icon className="fs-18px" name="thumbs-up"></Icon>{" "}
                            <span className="fs-12px">{item?.statistics?.like || 0}</span>
                        </div>
                        <div className="flex-grow-1 flex-shrink-1 d-flex align-items-center">
                            <Icon className="fs-18px" name="comments"></Icon>{" "}
                            <span className="fs-12px">{item?.statistics?.comment || 0}</span>
                        </div>
                    </div>
                </div>);
            // case "instagram":
            //   return PlatformIconInstagram;
            case PLATFORM_TIKTOK:
                return (<></>);
            case PLATFORM_ZALO:
                return (<></>)
            case PLATFORM_FACEBOOKGROUP:
                return (
                    <div className="d-flex mt-2">
                        <div
                            className="flex-grow-1 flex-shrink-1 d-flex align-items-center"
                            onClick={() => {
                                handelActionComment();
                            }}>
                            <Icon className="fs-18px" name="comments"></Icon>{" "}
                            <span className="fs-12px">Bình luận</span>
                        </div>
                    </div>
                );
            case PLATFORM_YOUTUBE:
                return (<div className="d-flex mt-2 justify-start">
                    <div className="d-flex">
                        <div className="flex-grow-1 flex-shrink-1 d-flex align-items-center mr-2">
                            <Icon className="fs-18px" name="thumbs-up"></Icon>{" "}
                            <span className="fs-12px">{item?.statistics?.like || 0}</span>
                        </div>
                        <div className="flex-grow-1 flex-shrink-1 d-flex align-items-center mr-2">
                            <Icon className="fs-18px" name="thumbs-down"></Icon>{" "}
                            <span className="fs-12px">{item?.statistics?.like || 0}</span>
                        </div>
                        <div className="flex-grow-1 flex-shrink-1 d-flex align-items-center">
                            <Icon className="fs-18px" name="comments"></Icon>{" "}
                            <span className="fs-12px">{item?.statistics?.comment || 0}</span>
                        </div>
                    </div>
                </div>);
            case PLATFORM_ORIGINAL:
                return (<></>)
            case PLATFORM_INSTAGRAM:
                return (<div className="d-flex justify-between mt-2">


                    <div
                        className="flex-grow-1 flex-shrink-1 d-flex align-items-center"
                        onClick={() => {
                            handelActionComment();
                        }}
                    >
                        <Icon className="fs-18px" name="comments"></Icon>{" "}
                        <span className="fs-12px">Bình luận</span>
                    </div>

                    <div className="d-flex">
                        <div className="flex-grow-1 flex-shrink-1 d-flex align-items-center mr-2">
                            <Icon className="fs-18px" name="heart"></Icon>{" "}
                            <span className="fs-12px">{item?.statistics?.like || 0}</span>
                        </div>
                        <div className="flex-grow-1 flex-shrink-1 d-flex align-items-center">
                            <Icon className="fs-18px" name="comments"></Icon>{" "}
                            <span className="fs-12px">{item?.statistics?.comment || 0}</span>
                        </div>
                    </div>
                </div>);
            default:
                return (<></>);
        }
    };

    const onClickContent = (props) => {
        //console.log(props)
        getListUser();
        isLoadListUser.current = false;
        clickContent(props);
    };
    return (
        <Card
            key={item._id}
            className={` bg-white ${className}`}
            style={{ backgroundColor: item.status === STATUS_REMOVE && '#F2F2F2' }}
            role="button"

        >
            <CardBody className="p-3 pt-4 pb-4">
                <div
                    onClick={() =>
                        onClickContent({
                            postId: item._id,
                            facebookPostId: item.post_id,
                            platform: channel.platform,
                            channelId: channel._id,
                            platform_id: channel.platform_id
                        })
                    }>
                    <div className={`d-flex ${style.channelContentHeader}`}>
                        <Img src={channel.picture} alt="avatar" className={`rounded ${style.channelContentHeaderImage}`} style={{ opacity: item.status === STATUS_REMOVE ? '60%' : '100%' }} />
                        <div className={`mx-1 -mt-1 w-100`}>
                            <div className={`fw-medium ${(channel?.name.length > 30) ? style.line : ""}`} style={{ color: item.status === STATUS_REMOVE && '#4F4F4F' }}>
                                <span className="title mr-2">{channel?.name.slice(0, 30)}{channel?.name.length > 30 && "..."}</span>
                                {item.status === POST_STATUS_PUBLISH_SUCCESS &&
                                    <Img
                                        src={IconOpenLink}
                                        alt="IconOpenLink"
                                        onClick={() => handleOpenPostId(item)}
                                    />}
                            </div>
                            <div className="d-flex justify-between">
                                <p className={style.column__timeupdate}>{moment(item?.updated_at).format("HH:mm ")} {moment(item?.updated_at).format("DD/MM/YYYY")} </p>
                                <div className={`d-flex -mt-1`}>
                                    <div>
                                        <IconPost status={item?.status} />
                                    </div>

                                    <span style={{ fontSize: "11px", fontWeight: "bold", margin: "auto" }}>{t(ARR_POST_STATUS_POST[item?.status])}</span>

                                </div>
                            </div>

                        </div>
                    </div>
                    <span className={`mt-3 ${style.channelContentText}`} style={{ color: item.status === STATUS_REMOVE && '#828282' }}>{item.content}</span>
                    {renderFirstMedia()}
                </div>
                {renderAction(channel.platform)}
                {isLike && <span className={style.column__timeupdate}>Tính năng đang phát triển</span>}
                {isComment && <div className="mt-3 w-100">
                    {
                        item?.status === POST_STATUS_PUBLISH_SUCCESS || item.status === POST_STATUS_SCHEDULED ?
                            <>
                                <div className="d-flex">
                                    <Dropdown isOpen={showTopRightOption} toggle={() => setShowTopRightOption(!showTopRightOption)} direction="up">
                                        <DropdownToggle
                                            tag="span"
                                            onClick={() => setShowTopRightOption(false)}
                                            data-toggle="dropdown"
                                            aria-expanded={false}
                                        >
                                            {loadSeeding ? <Spinner size="sm" color="light" /> :
                                                <Img
                                                    className={`w-8 h-8 ${style.sidebarAddChannelItemChannelImage} mr-2`}
                                                    src={formSeeding.user_seeding.picture}
                                                />
                                            }
                                        </DropdownToggle>
                                        <DropdownMenu
                                            modifiers={{
                                                setMaxHeight: {
                                                    enabled: true,
                                                    order: 890,
                                                    fn: (data) => {
                                                        return {
                                                            ...data,
                                                            styles: {
                                                                ...data.styles,
                                                                overflow: 'auto',
                                                                maxHeight: 100,
                                                                width: 354
                                                            },
                                                        };
                                                    },
                                                },
                                            }}>
                                            {ListUser.map((r) => (

                                                <DropdownItem
                                                    key={r._id}
                                                    href="#dropdownitem"
                                                    className="w-100"
                                                    onClick={() => handelchannelUserSeeding(r)}
                                                >
                                                    {r.name}
                                                </DropdownItem>

                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                    <input
                                        value={comment}
                                        placeholder="Nhập bình luận của bạn tại đây"
                                        onChange={(e) => setTextComment(e.target.value)}
                                        className={style.column__commentPost}
                                        onKeyDown={handleKeyDown}
                                    >

                                    </input>
                                </div>
                                <div className="ml-2.5 -mt-1">
                                    <Img
                                        src={IconConvert}
                                        alt="IconConvert"
                                        onClick={() => setShowTopRightOption(!showTopRightOption)}
                                    />
                                </div>
                            </>
                            :
                            <span className={style.column__timeupdate}>Tính năng không hỗ trợ</span>
                    }
                </div>}
            </CardBody>
            {item?.tags?.length > 0 &&
                <CardFooter className="p-2">
                    <div className={`d-flex flex-wrap ${style.channelContentTagList}`}>
                        {item?.tags?.map((tag) => (

                            <div className={`${style.channelContentTagItem}`} style={{ backgroundColor: tag.color }} key={tag._id}>
                                {tag.name}

                            </div>
                        ))}
                    </div>
                    {false && (
                        <>
                            <div className="mt-2 border-top border-white"></div>
                            <div className="d-flex align-items-center mt-2">
                                <Icon className="fs-18px text-pink" name="bell-fill" />
                                <span>
                                    <span className="text-pink">3</span> <span>{t("channel_page.content_new_comments")}</span>
                                </span>
                            </div>
                        </>
                    )}
                </CardFooter>
            }

        </Card>

    );
};

export default ColumnContent;
