import React, { useEffect, useState } from "react";
import IconCopy from "../../../images/iris/user/copy.svg";
import infoIcon from "../../images/icons/Frameinfo-Icon.png"
import {
  Button,
  Spinner
} from "reactstrap";
import style from "./index.module.scss";
import IconPaper from "../../images/aff/paper.svg";
import IconExcel from "../../images/aff/excel.png";
import cardIcon from "../../../images/iris/user/card.svg";
import paymentIcon from "../../../images/iris/user/card_payment.svg";
import {
  Img,
  PaginationCustom,
} from "../../../components/Component";
import ModalHistoryPayment from "./ModallHistoryPayment";
import * as XLSX from 'xlsx';
import { getPaymentHistory, getIrisUser } from "../../../api/iris";

const fakeData = {
  "status": true,
  "data": {
    "current_page": 1,
    "data": [
      {
        "id": "656ee38581fe0cd4f6055802",
        "time": "2023-12-05 16:11",
        "commission": 20000,
        "order": 1,
        "status": 2,
        "image": null
      }
    ],
    "first_page_url": "http://localhost:8000/api/v1/affiliates/payment-history?page=1",
    "from": 1,
    "last_page": 1,
    "last_page_url": "http://localhost:8000/api/v1/affiliates/payment-history?page=1",
    "links": [
      {
        "url": null,
        "label": "&laquo; Previous",
        "active": false
      },
      {
        "url": "http://localhost:8000/api/v1/affiliates/payment-history?page=1",
        "label": "1",
        "active": true
      },
      {
        "url": null,
        "label": "Next &raquo;",
        "active": false
      }
    ],
    "next_page_url": null,
    "path": "http://localhost:8000/api/v1/affiliates/payment-history",
    "per_page": 10,
    "prev_page_url": null,
    "to": 1,
    "total": 1
  },
  "message": "success"
}

const PaymentHistory = () => {
  const [link, setLink] = useState("https://so9.vn/iris/xYSadsD");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tabInfo, setTabInfor] = useState(6);
  const [loadingTb1, setLoadingTb1] = useState(false);
  const [cardData, setCardData] = useState();
  const [modalDetail, setModalDetail] = useState({
    byOrder: false,
    byProject: false,
  });
  const [historyData, setHistoryData] = useState();
  const [loadingDaily, setLoadingDaily] = useState(false);
  const [currentPageByProject, setCurrentPageByProject] = useState(1);
  const [curentModalId, setCureentModalId] = useState();

  const handlePageChangeByProject = (newPage) => {
    setLoadingDaily(true);
    setCurrentPageByProject(newPage);
  };
  const fetchData = async () => {
    setLoadingDaily(true);
    setHistoryData();
    try {
      const historyPromis = getPaymentHistory(currentPageByProject);
      const promises = [historyPromis];
      const results = await Promise.allSettled(promises);
      const responseData = promises.reduce((acc, promise, index) => {
        if (results[index].status === 'fulfilled') {
          acc[index] = results[index].value;
        } else {
          acc[index] = { error: results[index].reason };
        }
        return acc;
      }, []);

      const historyRes = responseData[0];

      if (historyRes.status) {
        const _historyData = historyRes.data;
        setHistoryData(_historyData);
        setLoadingDaily(false);
      }
    } catch (error) {
      setLoadingDaily(false);
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
    getIrisUser().then((res) => {
      if (res.status) {
        setCardData(res.data);
      }
    }
    ).catch((err) => {
      console.log(err);
    })
  }, []);

  useState(() => {
    fetchData();
  }, [currentPageByProject])

  const toggleDetail = (table) => {
    if (table === "byOrder") {
      setModalDetail({
        ...modalDetail,
        byOrder: !modalDetail.byOrder,
        byProject: false,
      });
    } else {
      setModalDetail({
        ...modalDetail,
        byProject: !modalDetail.byProject,
        byOrder: false,
      });
    }
  };

  const toggle = () => {
    navigator.clipboard.writeText(link);
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 3000);
  };

  const handleExportExel = ({ data, filename, sheetname }) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetname);
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  }
  const formatCurrency = (value) => {
    return value && value.toLocaleString('vi-VN', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }) + ' đ';
  };
  return (
    <React.Fragment>
      <div className="m-3 p-3" style={{ backgroundColor: "#F9FAFC" }}>
        <div className="mb-4" >
          <div className={style['header-area']} >
            <div className={style['header-container']} style={{ padding: "20px" }}>
              <Img src={cardIcon}
                style={{
                  width: "16px",
                  height: "16px",
                  objectFit: "scale-down"
                }} alt="notification" />
              <div className={style["header-container__textfield"]} >
                <p style={{ fontWeight: "600", fontSize: "16px", lineHeight: "24px", marginBottom: "6px" }}>
                  Thông tin thanh toán của bạn</p>
                <p style={{ fontWeight: "400", fontSize: "14px", lineHeight: "20px", marginBottom: "0px" }}>
                  Khoản tiền hoa hồng được thanh toán tới bạn vào ngày 09 tháng kế tiếp.
                  Bạn sẽ nhận được 10% cho tất cả các đơn hàng đã được khách hàng thanh toán thành công thỏa mãn điều kiện:
                </p>
                <ul style={{ listStyleType: "disc" }}>
                  <li style={{ marginLeft: "20px" }}>
                    Đơn hàng đã được thành công và kích hoạt sử dụng trong tháng
                  </li>
                  <li style={{ marginLeft: "20px" }}>
                    Đơn hàng được phát sinh trong 90 ngày kể từ khi dự án bạn giới thiệu được tạo
                  </li>
                </ul>


              </div>

              <div className={style['header-container__card-payment']}>
                <div className={`${style['header-container__card-payment__header']}`} >
                  <p style={{ marginTop: "15px", color: "#121212", fontWeight: "500", fontSize: "15px" }}>
                    {cardData?.bank_name || NaN}
                  </p>
                  <Img style={{ height: "16px", width: "16px" }} src={paymentIcon} />
                </div>
                <div className={`${style['header-container__card-payment__content']}`}>
                  <p style={{
                    color: "#C7C7C7", fontSize: "14px", fontWeight: "500",
                    marginBottom: "3px", marginTop: "12px", lineHeight: "16px"
                  }}>
                    {cardData?.bank_no || "NaN"}
                  </p>
                  <p style={{
                    color: "#9D9D9D", fontSize: "10px",
                    fontWeight: "500", lineHeight: "13px"
                  }}>
                    {cardData?.bank_account || "NaN"}
                  </p>
                </div>
              </div>
              <div>

              </div>
            </div>

          </div>

        </div>

        <div className="mb-4">
          <div
            className="p-3 d-flex justify-content-between align-items-center"
            style={{ background: "#EBEAED" }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <Img src={IconPaper} alt="paper" className="mr-1" />
              <b>Lịch sử thanh toán</b>
            </div>

            {historyData ? (
              historyData?.data?.length ? 
              ( <div
                role="button"
                className="rounded-circle d-flex align-items-center justify-content-center"
                style={{ background: "white", width: "2rem", height: "2rem" }}
                onClick={(e) => handleExportExel({ data: historyData?.data, filename: `Lịch sử thanh toán`, sheetname: "History" })}
              >
                <Img src={IconExcel} alt="excel" className="w-50" />
              </div>) : 
              (<div
                role="button"
                className="rounded-circle d-flex align-items-center justify-content-center"
                style={{ background: "white", width: "2rem", height: "2rem" }}
              >
                <Img src={IconExcel} alt="excel" className="w-50" />
              </div>) 
             
            ) :
              (<div
                role="button"
                className="rounded-circle d-flex align-items-center justify-content-center"
                style={{ background: "white", width: "2rem", height: "2rem" }}
              >
                <Img src={IconExcel} alt="excel" className="w-50" />
              </div>)}

          </div>
          <div className="p-3 table-responsive" style={{ background: "white" }}>
            <table className={`${style.table}`}>
              <thead style={{ background: "#F1F5FF" }}>
                <tr>
                  <th
                    style={{ width: "30%" }}
                    className={`${style.headPost} py-2`}
                  >
                    Thời gian
                  </th>
                  <th
                    style={{ width: "16%" }}
                    className={`${style.headPost} py-2 text-right`}
                  >
                    Số tiền thanh toán
                  </th>
                  <th
                    style={{ width: "16%" }}
                    className={`${style.headPost} py-2 text-right`}
                  >
                    Đơn hàng nhận HH
                  </th>
                  <th
                    style={{ width: "14%" }}
                    className={`${style.headPost} py-2 text-right`}
                  >
                    Trạng thái
                  </th>
                  <th
                    style={{ width: "16%" }}
                    className={`${style.headPost} py-2 text-center`}
                  >
                    Ảnh đối soát
                  </th>
                </tr>
              </thead>
              <tbody style={{ backgroundColor: "#fff", position: "relative" }}>
                {historyData ? (
                  historyData?.data?.length ?
                    (historyData?.data?.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          fontSize: "12px",
                          cursor: "pointer",
                          transition: "background-color 0.1s ease",
                        }}
                        className={`${style.hoveredRow}`}
                      >
                        <td
                          className={`${style.truncateCell} ${style.Td} text-left`}

                        >
                          {item?.time}
                        </td>
                        <td
                          className={`${style.truncateCell} ${style.Td} text-right`}

                        >
                          {formatCurrency(item?.commission)}
                        </td>
                        <td
                          className={`${style.truncateCell} ${style.Td} text-right`}

                        >
                          <div
                          >
                            {item?.order} <Img src={infoIcon} style={{ width: "12px", height: "12px", margin: "0px 0px 2px 5px" }}
                              onClick={() => {
                                setCureentModalId(item.id);
                                toggleDetail("byProject");
                              }}
                            ></Img>
                          </div>

                        </td>
                        <td
                          className={`${style.truncateCell} ${style.Td} text-right`}
                          style={{
                            color:
                              item.status === 2 ? ("#219653") :
                                (item.status === 1 ? ("#0EA5E9") :
                                  (item.status === 0 ? ("#E11D48") : ""))

                          }}
                        >
                          {
                            item.status === 2 ? ("Hoàn thành") :
                              (item.status === 1 ? ("Mới") :
                                (item.status === 0 ? ("Hủy") : ""))
                          }
                        </td>
                        <td
                          className={`${style.truncateCell} ${style.Td} text-center`}
                        >
                          {item?.image ?
                            (<Img src={item?.image ? item?.image : ""} style={{ widthL: "24px", height: "24px" }} />) :
                            (<p>-----</p>)
                          }
                        </td>
                      </tr>
                    ))) : (
                      <div style={{ position: "absolute", top: "30%", left: "45%" }}>
                        <p style={{ color: "#9CA3AF" }}>Chưa có dữ liệu</p>
                      </div>)
                ) : (<>
                  {loadingDaily ? (
                    <Spinner size="32px" color="light" style={{ position: "absolute", top: "30%", left: "47%" }} />
                  ) :
                    (
                      <div style={{ position: "absolute", top: "30%", left: "45%" }}>
                      <p style={{ color: "#9CA3AF" }}> Chưa có dữ liệu </p>
                      </div>
                    )}
                </>)}
              </tbody>
            </table>

            {historyData ?
              (historyData?.data?.length ?
                (
                <div className="mobile-paginate">
                <PaginationCustom
                  pageSize={historyData?.per_page}
                  totalCount={historyData?.total || 0}
                  siblingCount={1}
                  onPageChange={handlePageChangeByProject}
                  currentPage={currentPageByProject}
                  className={`manage_post_pagination`}
                />
                </div>              
                ) : (<></>))
              :
              (<></>)}
          </div>
        </div>

      </div>
      <ModalHistoryPayment
        isOpen={modalDetail.byProject}
        toggle={() => toggleDetail("byProject")}
        id={curentModalId}
      />
    </React.Fragment>
  );
};

export default PaymentHistory;
