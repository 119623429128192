import React from "react";
import HeaderDashboard from "../Dashboard/SubPage/PersonnelEfficiency/HeaderDashboard";

const IntensiveEfficiency = () => {
  return (
    <div>
      <div style={{ height: "78px" }}></div>
      <HeaderDashboard />
      <div>index</div>
    </div>
  );
};

export default IntensiveEfficiency;
