import style from "./index.module.scss";
import { Img } from "../../../components/Component";
import { Button } from "reactstrap";
const IntrolChatContent = ({ backColor, isButton, isWait, image, header, desc, index }) => {

    return (
        <>
            <div className={`${style.introlContentContainer} d-flex justify-content-center align-items-center`}
                style={{ backgroundColor: backColor }}
            >
                <div style={{ minWidth: "330px" ,flex:"4"}}>
                    <div className={`${style.numberContent} d-flex justify-content-center align-items-center`}>
                        {index}
                    </div>
                    <p className={`${style.contentHeader}`}>
                        {header}
                        {isWait && (
                            <span className={`${style.moreDetail}`}> (Comming soon)</span>
                        )
                        }
                    </p>
                    <p className={`${style.contentDesc}`}>
                        {desc}
                    </p>
                    {isButton &&
                        <Button
                            color="primary"
                            onClick={()=>{
                                window.open("https://chromewebstore.google.com/detail/9hub/pnggbmbenebnjldiocnpklocljgageeo","_blank")
                            }}
                        >
                            Cài đặt 9HUB
                        </Button>
                    }

                </div>
                <Img src={image} >
                </Img>

            </div>
        </>
    )
}
export default IntrolChatContent;