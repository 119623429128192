import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { TopCity } from "../../Chart/TopCity";
import FrequencyInstagramTable from "./FrequencyTableInstagram";
import OverviewUserInstagram from "./OverviewUserInstagram";
import {
  getAudienceIns,
  getFanOnlineIns,
  getLogFetchIns,
  getSummaryIns,
} from "../../../../../api/dashboard";
import { getUserInfoInLocalStorage } from "../../helper";
import { LogHeader } from "../../CommonItem/LogHeader";
import { PLATFORM_NAME } from "../../constants";
import { FanOnlineChart } from "../../Chart/FansOnlineChart";
import { LoadingItem } from "../../CommonItem/LoadingItem";
import { GenderStatistic } from "../../Chart/GenderStatistic";
import AgeStatistic, { AgeChartFb } from "../../Chart/AgeChart";
import { useTranslation } from "react-i18next";

const Instagram = ({
  channels,
  range,
  setLoadingChannelFilter,
  setLoadingRange,
  from,
  to,
}) => {
  const { t } = useTranslation();

  const userInfor = getUserInfoInLocalStorage();
  const projectId = userInfor.last_project_active;

  const [summaryDataIns, setSummaryDataIns] = useState({});
  const [audienceDataIns, setAudienceDataIns] = useState({});
  const [isLoadingOverviewUser, setIsLoadingOverviewUser] = useState(true);
  const [isLoadingAudience, setIsLoadingAudience] = useState(true);
  const [logInsData, setLogInsData] = useState({});

  const [dataFanOnline, setDataFanOnline] = useState({});
  const [isLoadingFanOnline, setIsLoadingFanOnline] = useState(true);
  const getAPIInstagram = (data) => {
    setSummaryDataIns(true);
    setAudienceDataIns(true);
    setIsLoadingOverviewUser(true);
    setIsLoadingAudience(true);
    setIsLoadingFanOnline(true);
    Promise.allSettled([
      getSummaryIns(data),
      getAudienceIns(data),
      getLogFetchIns(data),
      getFanOnlineIns(data),
    ]).then((responses) => {
      // Destructure the responses to get the data
      const [summaryDataInsRes, audienceInsRes, logInsRes, fanOnlineInsRes] =
        responses;

      if (summaryDataInsRes.status === "fulfilled") {
        const _data = summaryDataInsRes.value.data;
        setSummaryDataIns(_data);
      }
      setIsLoadingOverviewUser(false);

      if (audienceInsRes.status === "fulfilled") {
        const _data = audienceInsRes.value.data;
        setAudienceDataIns(_data);
      }
      setIsLoadingAudience(false);

      if (logInsRes.status === "fulfilled") {
        const logIns = logInsRes.value.data;
        setLogInsData(logIns);
      }

      if (fanOnlineInsRes.status === "fulfilled") {
        const fanOnline = fanOnlineInsRes.value.data;
        setDataFanOnline(fanOnline);
      }
      setIsLoadingFanOnline(false);

      setLoadingChannelFilter(false);
      setLoadingRange(false);
    });
  };

  const handleGetDataStatistic = () => {
    const data = {
      params: {
        project_id: projectId,
        from,
        to,
        channel_ids: channels,
      },
    };

    getAPIInstagram(data);
  };

  useEffect(() => {
    handleGetDataStatistic();
  }, [from, to, channels, projectId]);

  return (
    <>
      <Col lg={12} className="px-8">
        <LogHeader
          cnt_channel_error={logInsData?.cnt_channel_error}
          cnt_channel_success={logInsData?.cnt_channel_success}
          last_time={logInsData?.last_time}
          currentTab={PLATFORM_NAME.INSTAGRAM}
        />
      </Col>

      <div className="px-7">
        <Row xl="5" lg="4" md="3" sm="2" className="px-0">
          <OverviewUserInstagram
            dataAudience={audienceDataIns}
            dataSummary={summaryDataIns}
            isLoadingAudience={isLoadingAudience}
            isLoadingSummary={isLoadingOverviewUser}
          />
        </Row>
        <Row className="pt-3">
          <Col xxl={6} xl={6}>
            <TopCity
              data={audienceDataIns?.city || []}
              isLoading={isLoadingAudience}
            />
          </Col>
          <FanOnlineChart
            series={dataFanOnline}
            isLoading={isLoadingFanOnline}
          />
        </Row>

        <Col lg={12} className="pt-4 px-0">
          <Row>
            <Col xxl={6} xl={6}>
              <Card>
                <CardHeader className="bg-slate-50 align-items-center d-flex">
                  <h4
                    className="card-title mb-0 flex-grow-1"
                    style={{ fontSize: "16px" }}
                  >
                    {t("gender")}
                  </h4>
                </CardHeader>
                <CardBody>
                  {isLoadingAudience ? (
                    <LoadingItem />
                  ) : (
                    <GenderStatistic
                      data={audienceDataIns.gender}
                      dataColors='["--vz-primary", "--vz-light"]'
                    />
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col xxl={6} xl={6}>
              {/* <AgeChartFb
                data={audienceDataIns?.age}
                isLoading={isLoadingAudience}
              /> */}
              <AgeStatistic
                data={audienceDataIns?.age}
                isLoading={isLoadingAudience}
                isInstagram={true}
              />
            </Col>
            {/* <Col xxl={6} xl={6}>
            {isLoadingDemographicData ? (
              <LoadingItem />
            ) : (
              <AgeStatistic data={demographicData.age} />
            )}
          </Col> */}
          </Row>
        </Col>
        <Col lg={12} className="pt-4 px-0">
          <Card>
            <CardHeader className="bg-slate-50 align-items-center d-flex">
              <h4
                className="card-title mb-0 flex-grow-1"
                style={{ fontSize: "16px" }}
              >
                {t("account_analytics")}
              </h4>
            </CardHeader>
            <CardBody>
              <FrequencyInstagramTable
                projectId={projectId}
                from={from}
                to={to}
                range={range}
              />
            </CardBody>
          </Card>
        </Col>
      </div>
    </>
  );
};

export default Instagram;
