import React from "react";
import publishing from "../../images/icons/publishing.png";
import customer360 from "../../images/icons/customer360.png";
import analytics from "../../images/icons/analytics.png";
import engagement from "../../images/icons/engagement.png";
import style from "./index.module.scss";
import { Row, Col, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import AuthFooter from "./AuthFooter";
import PageContainer from "../../layout/page-container/PageContainer";
import {
  Block,
} from "../../components/Component";
import {pingServer} from "../../api/auth";
import {useHistory} from "react-router-dom";

export default function Welcome(prop) {
  const { t } = useTranslation()
  const history = useHistory();
  const platforms = [
    {
      id: "publishing",
      name: "Publishing",
      content:t('Welcome.platforms_Pub'),
      image: publishing,
    },
    {
      id: "analytics",
      name: "Analytics",
      content:t('Welcome.platforms_Ana'),
      image: analytics,
    },
    {
      id: "engagement",
      name: "Engagement",
      content:t('Welcome.platforms_Eng'),
      image: engagement,
    },
    {
      id: "customer360",
      name: "Customer 360",
      content:t('Welcome.platforms_Cus'),
      image: customer360,
    },
];
  const auth = localStorage.getItem("accessToken");
  const onClick = (platform) => {
    pingServer().then(res => {
      if(res.success){
        let url = process.env.PUBLIC_URL+"/connect-page/" + platform;
        window.location.replace(url);
      }
    })
  };

  const styleIgnoreButton = {
    fontSize: "24px",
    lineHeight: "24px",
    fontWeight: 700,
    color: "#2F80ED",
    cursor: "pointer",
    textDecoration: "underline"
  }
  const handleClickIgnore = () => {
    history.push('/')
  }

  return (
      <React.Fragment>
        {
          auth && <PageContainer>
            <Block className="nk-block-middle">
              <h4 className={style.TextTille}>{t('Welcome.title')}</h4>
              <h4 className={style.TextTille2}>{t('Welcome.sub_title')}</h4>
              {
                <Container fluid>
                  <Row>
                    {
                      platforms.map((item,index)=> {
                        return <Col md="3" key={index}><div className={style.card} onClick={() => onClick(item.id)}  role="button" key={index}>
                          <div className=" d-flex justify-content-center mt-5">
                            <img className={style.modalSelectPlatformItemImage} src={item.image} alt={item.name} />
                          </div>
                          <h6 className={style.textCenter}>{item.name}</h6>
                          <p className={style.modalSelectPlatformItemName}>{item.content}</p>
                        </div>
                        </Col>
                      })
                    }
                  </Row>
                  <div className={`d-flex w-full justify-center`} onClick={handleClickIgnore} style={styleIgnoreButton}>{t('ignore_button')}</div>
                </Container>
              }
            </Block>
            <AuthFooter />
          </PageContainer>
        }
    </React.Fragment>
  );
}
