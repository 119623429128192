import React, { useState, useEffect } from "react";
// import { getBalanceChartsData } from "../../slices/thunks";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

// import { getProjectIdInLocalStorage, numberWithCommas } from "common/Utils";
import { getDashboardUser } from "../../../../../api/overview";

import { AudienceCharts } from "./DashboardCrmCharts";
import { LoadingItem } from "../../CommonItem/LoadingItem";
import { numberWithCommas } from "../../../DashboardNew/utils/utils";
import style from "../../index.module.scss";
const AudienceStatistic = (props) => {
  const [chartData, setChartData] = useState([]);

  // COPY

  const { t } = useTranslation();
  const { channels, from, to } = props;
  const userInfor = JSON.parse(localStorage.getItem("userInfor"));

  const projectId = userInfor.last_project_active;
  const [summary, setSummary] = useState([]);
  const [options, setOptions] = useState({});
  // const [chartData, setChartData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (projectId) {
      const data = {
        params: {
          project_id: projectId,
          from,
          to,
          channel_ids: channels,
        },
      };
      setIsLoading(true);
      getDashboardUser(data).then((response) => {
        if (response) {
          const _data = response.data;
          setSummary(_data.summary);
          const dataSets = _data?.dataSet?.dataSets
            ?.filter((d) => d.label === "Current")
            .map((ds) => ({
              name: ds.label,
              // data: ds.data?.map((e) => numberWithCommas(+e)),
              data: ds.data?.map((e) => e)
            }));
          setChartData(dataSets);

          const options = {
            labels: [],
            chart: {
              height: 345,
              type: "line",
              zoom: {
                enabled: false,
              },
              toolbar: {
                show: false,
              },
            },
            colors: ["#45cb85d9", "#4b38b3", "#007bff"],

            dataLabels: {
              enabled: false,
            },
            stroke: {
              width: [3, 4, 3],
              curve: "straight",
              dashArray: [0, 8, 5],
            },
            series: dataSets || [],
            markers: {
              size: 0,
              hover: {
                sizeOffset: 6,
              },
            },
            xaxis: {
              categories: _data?.dataSet?.labels,
              tickAmount: 7,
            },
            grid: {
              borderColor: "#f1f1f1",
            },
          };

          setOptions(options);
        } else {
          setSummary([]);
          setChartData([]);
        }

        setIsLoading(false);
      });
    }
    return () => {
      setSummary([]);
      setChartData([]);
    };
  }, [from, to, projectId, channels]);
  return (
    <React.Fragment>
      {/* <Row> */}
      <Col xl={5}>
        <Card className="card-height-100">
          <CardHeader className="border-0 bg-slate-50 align-items-center d-flex">
            <h4
              className="card-title mb-0 flex-grow-1"
              style={{ fontSize: "16px" }}
            >
              {" "}
              {t(`overview.audience.summary.audience`)}
            </h4>
          </CardHeader>

          <CardHeader className="p-0 border-0 bg-slate-50 bg-light-subtle">
            <Row className="g-0 text-center">
              {isLoading ? (
                <LoadingItem />
              ) : (
                summary?.map((summaryItem, index) => (
                  <Col key={index} xs={6} sm={4}>
                    <div
                      className={`p-3 border  border-start-0 ${style.borderDashed}`}
                    >
                      <h5 className="mb-1">
                        <span
                          className="counter-value"
                          data-target={summaryItem?.current}
                          style={{ fontSize: "16px" }}
                        >
                          {summaryItem?.current}
                        </span>
                      </h5>
                      <p
                        className="text-muted mb-0"
                        style={{ fontSize: "13px" }}
                      >
                        {t(`overview.audience.summary.${summaryItem?.key}`)}
                      </p>
                    </div>
                  </Col>
                ))
              )}
            </Row>
          </CardHeader>
          <CardBody className="p-0 pb-2">
            <div id="revenue-expenses-charts" dir="ltr">
              {isLoading ? (
                <LoadingItem />
              ) : (
                <AudienceCharts
                  series={chartData}
                  options={options}
                  dataColors='["--vz-success", "--vz-danger"]'
                />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
      {/* </Row> */}
    </React.Fragment>
  );
};

export default AudienceStatistic;
