import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import FacebookPlatform from "../../images/dashboard/icon/icon-fb.png";
import InstagramPlatform from "../../images/dashboard/icon/type=Instragam.png";
import moment from "moment";
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import {
  Block,
  PaginationCustom,
  Icon,
  Button,
  RSelect,
  Img,
} from "../../../components/Component";
import { setIdPostMutilDelete } from "../../reducers/managePostSlice";
import { useTranslation } from "react-i18next";
import {
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_VIDEO,
  PLATFORM_YOUTUBE,
  PLATFORM_INSTAGRAM,
  PLATFORM_TIKTOK,
  WAREHOUSE_FB,
  WAREHOUSE_IG,
  getPlatformIcon,
  TYPE_POST,
  ARRAY_MANAGE_POST,
} from "../../utils/Utils";
import { useQuery } from "react-query";
import style from "./index.module.scss";
import {
  deletePost,
  getListManagePost,
  reupPost,
} from "../../../api/createPost";
import {
  ARR_POST_STATUS_POST,
  POST_STATUS_DEFAULT,
  POST_STATUS_MEDIA_ERROR,
  MEDIA_STATUS_ERROR_HAVING_URL,
  POST_STATUS_PROJECT_EXPIRED,
  STATUS_COMPRESS_ERROR,
} from "../../constants/posts";
import {
  IconPost,
  IconTrashPost,
  IconEyePost,
  IconChat,
} from "../../../libs/icon";
import { toastError, toastSuccess } from "../../../libs/toasts";
import parse from "html-react-parser";
import { PLATFORM_FACEBOOK, getPlatformIconFromLabel } from "../../utils/Utils";
import MediaErrorIcon from "../../images/icons/media_error.jpg";
import { AUTO_POST, MANUAL_POST } from "../../utils/Utils";
import NoPostAlert from "../../components/Post/NoPostAlert";
import { useDispatch, useSelector } from "react-redux";
import {
  contentWarehouse,
  getCountActive,
  getPostWithFilter,
} from "../../../api/warehouse";
import FacebookIcon from "../../images/socailNetword/facebook.svg";
import InstaIcon from "../../images/socailNetword/instagram.svg";
import TiktokIcon from "../../images/socailNetword/tiktok.svg";
import YoutubeIcon from "../../images/socailNetword/youtube.svg";
import NoData from "../../images/noData.png";
import _ from "lodash";

import ConfimDelete from "../../components/Modal/ModalConfimDelete";
import PostDetail from "../PostDetail";
export default function ManagePost(props) {
  const dispatch = useDispatch();
  const {
    activeTab,
    tabId,
    managePostTab,
    setPostTabPage,
    setPostDataTab,
    setNumberPost,
    toggle,
    listPostByFetch,
    setListPostByFetch,
    isHiddenSearch,
    isSinglePlatform,
    setIsSinglePlatform,
  } = props;
  const history = useHistory();
  const userInfor = JSON.parse(localStorage.getItem("userInfor"));
  const [activeBottomSheetPostToChannels, setActiveBottomSheetPostToChannels] =
    useState(false);
  const [modalSeeding, setModalSeeding] = useState(false);
  const [countActive, setCountActive] = useState(0);
  const [openModalAlertMediaError, setOpenModalAlertMediaError] =
    useState(false);
  const [typeMediaError, setTypeMediaError] = useState(
    MEDIA_STATUS_ERROR_HAVING_URL
  );

  const [detailPost, setDetailPost] = useState(null);
  const projectId = userInfor.last_project_active;
  const [itemPerPage, setItemPerPage] = useState(10);
  const indexCurrent = useRef(0);
  const [showMultiDeleted, setShowMultiDeleted] = useState(false);
  const { t } = useTranslation();
  const [modalDeleteMultiPosts, setModalDeleteMultiPosts] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [idDeletePost, setIdDeletePost] = useState();
  const [isFilter, setIsFilter] = useState(false);
  const [generalData, setGeneralData] = useState(null);
  const [channelName, setChannelName] = useState("facebook");
  const [totalPage, setTotalPage] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [platformName, setPlatformName] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [channelIds, setChannelIds] = useState([]);
  const [postType, setPostType] = useState();
  const [status, setStatus] = useState();
  const [via, setVia] = useState();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [dataChannel, setDataChannel] = useState([]);
  const [searchChannelInput, setSearchChannelInput] = useState("");
  const dataListChannelAll = useSelector(
    (state) => state.channel.channelsInformation
  );
  const [dataListChannel, setDataListChannel] = useState(dataListChannelAll);
  const [filterPostParams, setFilterPostParams] = useState({
    channel_ids: [],
    post_type: "",
    status: -10,
    user_published: "",
  });

  const [filterPostParams2, setFilterPostParams2] = useState({
    channel_ids: [],
    post_type: "",
    status: -30,
    user_published: "",
  });

  const [openModalPostDetail, setOpenModalPostDetail] = useState(false);

  const inputRef = useRef(null);

  const toggleDelete = () => {
    setModalDelete(!modalDelete);
  };

  const handleOpenModalPostDetail = ({ item }) => {
    setOpenModalPostDetail(!openModalPostDetail);
    setDetailPost(item);
  };

  const loadListManagePost = async () => {
    let data;

    switch (activeTab) {
      case WAREHOUSE_FB:
        data = await contentWarehouse(
          projectId,
          {
            params: {
              page: managePostTab.postTab[activeTab],
            },
          },
          "facebook"
        );
        break;
      case WAREHOUSE_IG:
        data = await contentWarehouse(
          projectId,
          {
            params: {
              page: managePostTab.postTab[activeTab],
            },
          },
          "instagram"
        );
        break;

      case AUTO_POST:
        data = await getListManagePost(projectId, {
          params: {
            page: managePostTab.postTab[activeTab],
            is_auto_scheduled_at: 1,
          },
        });
        break;

      default:
        data = await getListManagePost(projectId, {
          params: {
            page: managePostTab.postTab[activeTab],
          },
        });
        break;
    }

    return data;
  };

  let { data, isLoading, refetch } = useQuery(
    [activeTab, managePostTab.postTab[activeTab]],
    loadListManagePost,
    {
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    if (data) {
      setListPostByFetch(data?.data?.data);
      setTotalPage(data?.data?.total);
    }
  }, [data]);
  const setDelItem = (arr, filter) => {
    var newArray = arr.map(function (post) {
      return {
        id: !filter ? post._id : post.post_id,
        check: false,
      };
    });
    dispatch(setIdPostMutilDelete(newArray));
  };

  useEffect(() => {
    setDataListChannel(dataListChannelAll);
  }, [dataListChannelAll]);
  useEffect(() => {
    if (!activeBottomSheetPostToChannels) {
      //   setDetailPost(null);
    }
  }, [activeBottomSheetPostToChannels]);

  //select check
  const selectorCheck = (e) => {
    document.getElementById("uid_1" + activeTab).checked = e.target.checked;
    let newData = [...managePostTab.idPostMutilDelete];
    newData = newData.map((item) =>
      Object.assign({}, item, { check: e.target.checked })
    );
    dispatch(setIdPostMutilDelete(newData));
  };
  useEffect(() => {
    if (!data) return;
    if (data && data.data && tabId === activeTab) {
      if (data.data.per_page && data.data.per_page !== itemPerPage) {
        setItemPerPage(data.data.per_page);
      }
      //check trạng thái bài đăng có dữ liệu không
      if (data.data.length === 0 || data.data.total === 0) {
        for (const element of document.getElementsByClassName(
          `manage_post_pagination_nav_${tabId}`
        )) {
          element.style.display = "none";
        }
        setPostDataTab({
          tab: activeTab,
          isNoPost: true,
        });
      }
      //gán số lượng bài đăng
      if (data?.data?.total) {
        setNumberPost({
          tab: activeTab,
          total: data?.data?.total,
        });
        document.getElementById("uid_1" + activeTab).checked = false;
        const listIdPost = [];
        data?.data?.data?.map((item) =>
          listIdPost.push({ id: item?._id, check: false })
        );
        setIdPostMutilDelete(listIdPost);
        dispatch(setIdPostMutilDelete(listIdPost));
      }
    }
  }, [data]);

  useEffect(() => {
    setShowMultiDeleted(
      managePostTab.idPostMutilDelete.find((item) => item.check) ? true : false
    );
  }, [managePostTab.idPostMutilDelete]);
  useEffect(async () => {
    // lấy tổng số bài của bài đăng tự động
    if (tabId === MANUAL_POST) {
      const response = await getListManagePost(projectId, {
        params: {
          page: 1,
        },
      });
      if (response && response.status && response.data.total) {
        setNumberPost({
          tab: MANUAL_POST,
          total: response.data.total,
        });
      }
    }
    if (tabId === AUTO_POST) {
      const response = await getListManagePost(projectId, {
        params: {
          page: 1,
          is_auto_scheduled_at: 1,
        },
      });
      if (response && response.status && response.data.total) {
        setNumberPost({
          tab: AUTO_POST,
          total: response.data.total,
        });
      }
    }
    if (tabId === WAREHOUSE_FB) {
      const response = await contentWarehouse(
        projectId,
        {
          params: {
            page: 1,
          },
        },
        "facebook"
      );
      if (response && response.status && response.data.total) {
        setNumberPost({
          tab: tabId === WAREHOUSE_FB,
          total: response.data.total,
        });
      }
    }
    if (tabId === WAREHOUSE_IG) {
      const response = await contentWarehouse(
        projectId,
        {
          params: {
            page: 1,
          },
        },
        "instagram"
      );

      if (response && response.status && response.data.total) {
        setNumberPost({
          tab: WAREHOUSE_IG,
          total: response.data.total,
        });
      }
    }
  }, []);

  useEffect(async () => {
    const res = await getCountActive(projectId, {});
    if (res.status) {
      setCountActive(res?.data);
    }
  }, []);

  const getTimeFirstItemChannel = (channels, scheduled_at) => {
    let timeInitial = scheduled_at;
    if (!channels) return "";
    for (const [key, value] of Object?.entries(channels)) {
      if (channels[key].length > 0) {
        timeInitial = channels[key][0]["scheduled_at"] || scheduled_at;
        break;
      }
    }
    return `<b>${moment.unix(timeInitial).format("HH:mm ")}</b>${moment
      .unix(timeInitial)
      .format("DD/MM/YYYY")}`;
  };

  function countChannelPosted(channels) {
    let count = 0;
    for (const [key, value] of Object?.entries(channels)) {
      count += channels[key].length;
    }
    return count;
  }

  function checkMediaStatus(post) {
    const mediaErrorFlag =
      post &&
      post.media &&
      post.media.some(
        (item) =>
          item.type === MEDIA_TYPE_VIDEO &&
          item.media_is_available === false &&
          item.status_compress === STATUS_COMPRESS_ERROR
      );
    return mediaErrorFlag;
  }

  function getUniqueChannels(channels) {
    let uniqueChannelArr = [];
    for (const [key, value] of Object?.entries(channels)) {
      if (channels[key].length > 0) {
        uniqueChannelArr.push(getPlatformIconFromLabel(key));
      }
    }
    return uniqueChannelArr;
  }

  function checkPlatformSeeding(channels) {
    return (
      (channels &&
        channels["facebook"] &&
        channels["facebook"].length > 0 &&
        channels["facebook"][0]["channel"] &&
        channels["facebook"][0]["channel"]["platform"] === PLATFORM_FACEBOOK) ||
      (channels &&
        channels["youtube"] &&
        channels["youtube"].length > 0 &&
        channels["youtube"][0]["channel"] &&
        channels["youtube"][0]["channel"]["platform"] === PLATFORM_YOUTUBE) ||
      (channels &&
        channels["instagram"] &&
        channels["instagram"].length > 0 &&
        channels["instagram"][0]["channel"] &&
        channels["instagram"][0]["channel"]["platform"] ===
        PLATFORM_INSTAGRAM) ||
      (channels &&
        channels["tiktok"] &&
        channels["tiktok"].length > 0 &&
        channels["tiktok"][0]["channel"] &&
        channels["tiktok"][0]["channel"]["platform"] === PLATFORM_TIKTOK) ||
      channels
    );
  }

  const checkHavePostSuccess = (channel) => {
    if ("status" in channel) {
      return;
    } else {
      let isHavePostSuccess = false;
      for (const [platform, posts] of Object.entries(channel)) {
        for (const post of posts) {
          if (post.status == 2) {
            isHavePostSuccess = true;
            break;
          }
        }
      }
      return isHavePostSuccess;
    }
  };

  function convertStatus(channels) {
    if (channels) {
      if ("status" in channels) {
        return parseInt(channels?.status);
      } else {
        let countChannel = 0;
        const statusCounts = {};
        for (const key in channels) {
          const array = channels[key];

          // Duyệt qua mảng của mỗi item
          for (const item of array) {
            countChannel++;
            const status = item.status;
            // Kiểm tra nếu trạng thái đã tồn tại trong object statusCounts
            if (statusCounts.hasOwnProperty(status)) {
              // Nếu tồn tại, tăng số lần xuất hiện lên 1
              statusCounts[status]++;
            } else {
              // Nếu không tồn tại, khởi tạo số lần xuất hiện là 1
              statusCounts[status] = 1;
            }
          }
        }
        for (const status in statusCounts) {
          if (statusCounts[status] === countChannel) {
            return parseInt(status);
          }
        }
      }
    }
    return POST_STATUS_DEFAULT;
  }
  const paginate = (pageNumber) => {
    if (isSinglePlatform) {
      setPageNum(pageNumber);
    } else {
      setPostTabPage({
        tab: activeTab,
        currentPage: pageNumber,
      });
      history.push(
        `${window.location.pathname}?page=${pageNumber}${(activeTab === MANUAL_POST && "&type=manual") ||
        (activeTab === AUTO_POST && "&type=auto") ||
        (activeTab === WAREHOUSE_FB && "&type=warehouse_fb") ||
        (activeTab === WAREHOUSE_IG && "&type=warehouse_ig")
        }`
      );
    }
  };

  useEffect(() => {
    handleFilterChannel(platformName, pageNum);
  }, [pageNum]);
  const renderActiveTab = () => {
    if (activeTab === MANUAL_POST) return "manual";
    if (activeTab === AUTO_POST) return "auto";
  };

  const renderAutoScheduedAt = () => {
    if (activeTab === MANUAL_POST) return 0;
    if (activeTab === AUTO_POST) return 1;
  };

  const handleViewDetailPost = ({ item }) => {
    setActiveBottomSheetPostToChannels(true);
    setDetailPost(item);
  };
  const handleViewSeedingPost = ({ item }) => {
    setModalSeeding(true);
    setDetailPost(item);
  };
  const confirmToDeletePost = (postId) => {
    // if (window.confirm("Bạn có chắc chắn muốn xóa bài đăng này?")) {
    // handleDeletePost(postId);
    //   return;
    // }
    setIdDeletePost(postId);
    setModalDelete(true);
  };

  const handleDeletePost = async (post, e) => {
    e.stopPropagation();
    if (post) {
      const res = await deletePost(userInfor.last_project_active, post);
      if (res.status) {
        toastSuccess(t("manage_post.tab_content.toast.delele_post_success"));
        refetch();
        toggleDelete();
      } else {
        setModalDelete(false);
        toastError(t("manage_post.tab_content.toast.delele_post_faild"));
        toggleDelete();
        return;
      }
    }
  };

  const handleRetryProcessMedia = (e, post, index) => {
    e.stopPropagation();
    indexCurrent.current = index;

    const data = {
      socialPostId: post._id,
      channelType: post.channel.platform,
    };
    reupPost(data)
      .then((res) => {
        if (res.status) {
          refetch();
          toastSuccess(t("manage_post.tab_content.toast.repost_success"));
        } else {
          toastError(res.message);
        }
      })
      .catch((err) => {
        toastError(
          t("manage_post.tab_content.toast.error_try_again")
        );
        console.log(err.response);
      });
  };

  const handleAlertMediaError = (e) => {
    e.stopPropagation();
    setTypeMediaError(MEDIA_STATUS_ERROR_HAVING_URL);
    setOpenModalAlertMediaError(true);
  };

  // function to change the property of an item of the table
  const onSelectChange = (e, id) => {
    if (!e.target.checked) {
      document.getElementById("uid_1" + activeTab).checked = false;
    }
    let newData = [...managePostTab.idPostMutilDelete];
    newData = newData.map((item) => {
      if (item.id === id) {
        return Object.assign({}, item, { check: e.target.checked });
      }
      return item;
    });
    dispatch(setIdPostMutilDelete(newData));
  };

  const handleEventStatus = (e, post, mediaErrorFlag, statusPost) => {
    if (statusPost === POST_STATUS_PROJECT_EXPIRED) {
      setTypeMediaError(POST_STATUS_PROJECT_EXPIRED);
      setOpenModalAlertMediaError(true);
    } else if (mediaErrorFlag) {
      handleAlertMediaError(e);
    } else {
      handleViewDetailPost({ item: post });
    }
  };

  const renderListVia = () => {
    let arrayVIA = [];
    let formatArrayVIA = [];
    if (filterPostParams?.channel_ids?.length > 0) {
      let arrFirst = [];
      filterPostParams?.channel_ids?.map((filterChannel) => {
        const arrayPostChannel = dataListChannel?.filter(
          (dataChannel) => dataChannel._id === filterChannel
        );
        arrFirst.push(arrayPostChannel);
      });

      arrFirst?.forEach((value) => {
        value?.forEach((element) => {
          element?.list_token_allow?.forEach((tokenElement) => {
            arrayVIA.push(tokenElement);
          });
        });
      });

      Array.from(new Set(arrayVIA))?.forEach((viaElement) => {
        formatArrayVIA.push({
          label: viaElement.name,
          value: viaElement.id,
          picture: viaElement.picture,
        });
      });
      let middleArr = [];
      for (const viaElement of formatArrayVIA) {
        middleArr[viaElement.value] = viaElement;
      }
      let finalArr = [];
      for (const [key, value] of Object.entries(middleArr)) {
        finalArr.push(value);
      }

      return finalArr;
    } else {
      dataListChannelAll?.forEach((element) => {
        element?.list_token_allow?.forEach((tokenElement) => {
          arrayVIA.push(tokenElement);
        });
      });
      Array.from(new Set(arrayVIA))?.forEach((viaElement) => {
        formatArrayVIA.push({
          label: viaElement.name,
          value: viaElement.id,
          picture: viaElement.picture,
        });
      });
      let middleArr = [];
      for (const viaElement of formatArrayVIA) {
        middleArr[viaElement.value] = viaElement;
      }
      let finalArr = [];
      for (const [key, value] of Object.entries(middleArr)) {
        finalArr.push(value);
      }

      return finalArr;
    }
  };

  // const handleChangeStatusChannel = (value) => {
  //   const filterParams = { ...filterPostParams };
  //   filterParams.status = value.value;
  //   setStatus(value);
  //   setFilterPostParams(filterParams);
  // };
  const handleChangeStatusChannel = useCallback(
    (value) => {
      const filterParams = { ...filterPostParams };
      filterParams.status = value.value;
      setStatus(value);
      setFilterPostParams(filterParams);
    },
    [listPostByFetch]
  );

  const handleChangeTypeChannel = (value) => {
    const filterParams = { ...filterPostParams };
    filterParams.post_type = value.value;
    setPostType(value);
    setFilterPostParams(filterParams);
  };

  const handleChangeVIA = (value) => {
    const arrayParams = [];
    const filterParams = { ...filterPostParams };
    // value?.map((element) => {
    //   arrayParams.push(element.value);
    // });
    setVia(value);
    filterParams.user_published = value.value;
    setFilterPostParams(filterParams);
  };

  const handleFilterChannel = async (platform, pageNumber) => {
    let channelIdsArr = [];
    channelIds?.map((element) => {
      channelIdsArr.push(element.value);
    });
    if (platform) {
      setPlatformName(platform);
      setIsFilter(true);
      setIsFetching(true);
      setIsSinglePlatform(true);
      let params = {
        channel_ids:
          filterPostParams.channel_ids.length > 0
            ? filterPostParams.channel_ids
            : undefined,
        post_type:
          filterPostParams.post_type.length > 0
            ? filterPostParams.post_type
            : undefined,
        status:
          filterPostParams.status >= 0 ? filterPostParams.status : undefined,
        user_published:
          filterPostParams.user_published.length > 0
            ? filterPostParams.user_published
            : undefined,
        platform,
        page: pageNum,
        // type: renderActiveTab(),
        is_auto_scheduled_at: renderAutoScheduedAt(),
      };

      const response = await getPostWithFilter(projectId, {
        params,
      });
      setIsFetching(false);

      setGeneralData(response?.data);
      let arr = [];

      for (const [key, value] of Object.entries(response?.data)) {
        if (value?.is_selected) {
          arr = value.posts;
          setTotalPage(value?.posts?.total);
          setChannelName(key);
        }
      }
      setListPostByFetch(arr?.data);
      setDelItem(arr?.data, true);
      setFilterPostParams2(filterPostParams);
    } else {
      if (
        _.isEqual(filterPostParams.channel_ids, []) &&
        _.isEqual(filterPostParams.post_type, "") &&
        _.isEqual(filterPostParams.status, -10) &&
        _.isEqual(filterPostParams.user_published, "")
      ) {
        return;
      } else if (
        _.isEqual(
          filterPostParams2.channel_ids,
          filterPostParams.channel_ids
        ) &&
        _.isEqual(filterPostParams2.post_type, filterPostParams.post_type) &&
        _.isEqual(filterPostParams2.status, filterPostParams.status) &&
        _.isEqual(
          filterPostParams2.user_published,
          filterPostParams.user_published
        )
      ) {
        return;
      } else {
        setIsFilter(true);
        setIsFetching(true);
        setIsSinglePlatform(true);
        let params = {
          channel_ids:
            filterPostParams.channel_ids.length > 0
              ? filterPostParams.channel_ids
              : undefined,
          post_type:
            filterPostParams.post_type.length > 0
              ? filterPostParams.post_type
              : undefined,
          status:
            filterPostParams.status >= 0 ? filterPostParams.status : undefined,
          user_published:
            filterPostParams.user_published.length > 0
              ? filterPostParams.user_published
              : undefined,
          platform,
          page: pageNum,
          // type: renderActiveTab(),
          is_auto_scheduled_at: renderAutoScheduedAt(),
        };

        const response = await getPostWithFilter(projectId, {
          params,
        });
        setIsFetching(false);

        setGeneralData(response?.data);
        let arr = [];
        for (const [key, value] of Object.entries(response?.data)) {
          if (value?.is_selected) {
            arr = value.posts;
            setTotalPage(value?.posts?.total);
            setChannelName(key);
            setPlatformName(value.platform);
          }
        }
        setListPostByFetch(arr?.data);
        setDelItem(arr?.data, true);
        setFilterPostParams2(filterPostParams);
      }
    }
  };


  const handleRemoveFilter = useCallback(() => {
    setListPostByFetch(data?.data?.data);
    setDelItem(data?.data?.data, false);
    setGeneralData(null);
    setIsSinglePlatform(false);
    setIsFilter(false);
    setTotalPage(data?.data?.total);
    setChannelIds([]);
    setPostType(null);
    setStatus(null);
    setVia(null);
    setDataChannel([]);
    setFilterPostParams({
      channel_ids: [],
      post_type: "",
      status: -10,
      user_published: "",
    });
  }, [listPostByFetch]);
  const renderIconPlatform = (platfrom) => {
    if (!platfrom) return;
    return (
      <div className={style.platformContainer}>
        {Object.entries(platfrom).map(([key, value]) => {
          return renderItemPlatform({ key, value });
        })}
      </div>
    );
  };

  const renderTotalPlatfrom = ({
    img,
    total,
    isSelect,
    data,
    name,
    platform,
    nameChannel,
  }) => {
    return (
      <div
        className={isSelect ? style.activePlatForm : style.btPlatform}
        onClick={() => {
          setPageNum(1);
          handleFilterChannel(platform);
          setChannelName(nameChannel);
          setTotalPage(total);
        }}
      >
        <Img src={img} alt={img} className={style.platfromIcon} />
        <span
          style={{
            fontWeight: "600",
            fontSize: "13px",
            color: isSelect ? "#111827" : "#6B7280",
            marginLeft: "10px  ",
          }}
        >
          {nameChannel}({total})
        </span>
      </div>
    );
  };

  const renderItemPlatform = ({ key, value }) => {
    switch (key) {
      case "facebook":
        return renderTotalPlatfrom({
          total: value.cnt,
          isSelect: value.is_selected,
          data: value.posts,
          img: FacebookIcon,
          name: key,
          platform: value.platform,
          nameChannel: "Facebook",
        });
        break;
      case "tiktok":
        return renderTotalPlatfrom({
          total: value.cnt,
          isSelect: value.is_selected,
          data: value.posts,
          img: TiktokIcon,
          name: key,
          platform: value.platform,
          nameChannel: "Tiktok",
        });
        break;

      case "instagram":
        return renderTotalPlatfrom({
          total: value.cnt,
          isSelect: value.is_selected,
          data: value.posts,
          img: InstaIcon,
          name: key,
          platform: value.platform,
          nameChannel: "Instagram",
        });
        break;

      case "youtube":
        return renderTotalPlatfrom({
          total: value.cnt,
          isSelect: value.is_selected,
          data: value.posts,
          img: YoutubeIcon,
          name: key,
          platform: value.platform,
          nameChannel: "Youtube",
        });
        break;

      default:
        break;
    }
  };

  const handleOpenDropDown = (e) => {
    e.stopPropagation();
    setOpenDropdown(!openDropdown);
    inputRef.current.focus();
    setSearchChannelInput("");
  };

  const handleCheckboxChange = (idDataChannel) => {
    const updateIdChannel = [...dataChannel];
    const index = updateIdChannel.indexOf(idDataChannel);

    if (index !== -1) {
      // If the ID is already in the array, remove it
      updateIdChannel.splice(index, 1);
    } else {
      // If the ID is not in the array, add it
      updateIdChannel.push(idDataChannel);
    }

    setDataChannel(updateIdChannel);
  };

  const handleCancelClick = () => {
    setDataChannel([]);
    setOpenDropdown(false);
    setSearchChannelInput("");
  };

  const handleConfirm = () => {
    const filterParams = { ...filterPostParams };
    filterParams.channel_ids = dataChannel;
    setFilterPostParams(filterParams);
    setOpenDropdown(false);
    setSearchChannelInput("");
  };

  const handleSearchChannel = () => {
    if (searchChannelInput !== "") {
      const filteredElements = dataListChannelAll.filter((item) =>
        item.name.toLowerCase().includes(searchChannelInput.toLowerCase())
      );
      setDataListChannel(filteredElements);
    } else if (searchChannelInput === "" || searchChannelInput) {
      setDataListChannel(dataListChannelAll);
    }
  };

  useEffect(() => {
    handleSearchChannel();
  }, [searchChannelInput]);
  const renderSearch = () => {
    return (
      <div className={style.containerSearch}>
        <Dropdown isOpen={openDropdown} toggle={handleOpenDropDown}>
          <DropdownToggle
            className={`dropdown-toggle btn btn-white btn-dim btn-outline-light ${style.dropDownSelect} d-flex align-items-center justify-content-between`}
            style={{
              background: "#fff",
              color: "#9CA3AF",
              border: "1px solid",
              borderColor: "#D9D9D9",
            }}
          >
            <input
              className={`${style.searchInput}`}
              value={searchChannelInput}
              ref={inputRef}
              onChange={(event) => {
                setOpenDropdown(true);
                setSearchChannelInput(event.target.value);
              }}
              placeholder={
                dataChannel && dataChannel.length > 0
                  ? t("manage_post.filter.channel.search_channel", { cnt_channel: dataChannel.length })
                  : t("manage_post.filter.channel.placeholder")
              }
            ></input>
            <Icon
              className="d-sm-inline"
              style={{ color: "#AEBFE7", position: "absolute", right: "5px" }}
              name="chevron-down"
            ></Icon>
          </DropdownToggle>
          <DropdownMenu className="px-2 my-2 rounded">
            <div className={style.bgDropDown}>
              {dataListChannel?.map((itemChannel, index) => (
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "10px", marginBottom: "10px" }}
                  key={itemChannel._id}
                  onClick={() => handleCheckboxChange(itemChannel._id)}
                >
                  <input
                    type="checkbox"
                    checked={dataChannel.includes(itemChannel._id)}
                    onChange={() => handleCheckboxChange(itemChannel._id)}
                    id={`check-for-${index}`}
                  />
                  <div>
                    <Img
                      src={`${itemChannel?.picture}`}
                      alt="avatar"
                      className={`rounded ${style.reactSelectPageCommentItem}`}
                    />
                    {itemChannel?.name}
                  </div>
                </div>
              ))}
            </div>
            <div className="h-100 d-flex flex-row p-2" style={{ gap: "8px" }}>
              <button
                onClick={handleCancelClick}
                className={`w-100 ${style.btnCance}`}
              >
                {t("manage_post.filter.channel.cancel")}
              </button>
              <button
                onClick={handleConfirm}
                className={`w-100 ${style.btnConfirm}`}
              >
                {t("manage_post.filter.channel.apply")}
              </button>
            </div>
          </DropdownMenu>
        </Dropdown>
        <RSelect
          options={
            TYPE_POST.map(item => ({
              label: t(item?.label),
              value: item?.value
            }))
          }
          placeholder={t("manage_post.filter.post_type.placeholder")}
          className={style.controlSelect}
          value={postType}
          onChange={handleChangeTypeChannel}
        />
        <RSelect
          placeholder={t("manage_post.filter.account.placeholder")}
          className={style.controlSelect}
          options={renderListVia()}
          value={via}
          onChange={handleChangeVIA}
          formatOptionLabel={(itemVIA) => {
            return (
              <div>
                <Img
                  src={`${itemVIA?.picture}`}
                  alt="avatar"
                  className={`${style.reactSelectPageCommentItem}`}
                  style={{ borderRadius: "50%" }}
                />
                {itemVIA?.label}
              </div>
            );
          }}
        />
        <RSelect
          options={
            ARRAY_MANAGE_POST.map(
              item => ({
                label: t(item?.label),
                value: item?.value
              })
            )
          }
          placeholder={t("manage_post.filter.status.placeholder")}
          className={style.controlSelectStatus}
          value={status}
          onChange={handleChangeStatusChannel}
        />
        <Button
          className={`${style.btnSearch} ${style.btnFilter}`}
          onClick={() => {
            setPageNum(1);
            handleFilterChannel(null, 1);
          }}
        >
          {t('manage_post.filter.apply_filter')}
        </Button>
        {_.isEqual(filterPostParams.channel_ids, []) &&
          _.isEqual(filterPostParams.post_type, "") &&
          _.isEqual(filterPostParams.status, -10) &&
          _.isEqual(filterPostParams.user_published, "") ? (
          ""
        ) : (
          <Button
            className={`${style.btnSearch} ${style.btnRemove} `}
            onClick={handleRemoveFilter}
          >
            {t('manage_post.filter.remove_filter')}
          </Button>
        )}
      </div>
    );
  };

  return (
    <>
      <Head title={t("manage_post.title")}></Head>
      {isHiddenSearch ? "" : renderSearch()}

      <Content>
        {activeTab !== MANUAL_POST && activeTab !== AUTO_POST && (
          <Block
            className={`d-flex justify-content-between align-items-center`}
          >
            <div className="d-flex">
              <div
                className="mr-5"
                onClick={() => {
                  toggle(WAREHOUSE_FB)
                  setChannelName('facebook')
                }}
                type="button"
                style={{
                  color: activeTab === WAREHOUSE_FB ? "#111827" : "#6B7280",
                }}
              >
                <Img
                  src={FacebookPlatform}
                  alt={"facebook"}
                  style={{
                    width: "2rem",
                    height: "2rem",
                    filter:
                      activeTab === WAREHOUSE_FB
                        ? "grayscale(0)"
                        : "grayscale(100%)",
                  }}
                  className="img-fluid mr-2"
                />{" "}
                <b>
                  Facebook {`(${managePostTab.numberPost[WAREHOUSE_FB] || 0})`}
                </b>
              </div>

              <div
                type="button"
                onClick={() => {
                  toggle(WAREHOUSE_IG)
                  setChannelName('instagram')
                }}
                style={{
                  color: activeTab === WAREHOUSE_IG ? "#111827" : "#6B7280",
                }}
              >
                <Img
                  src={InstagramPlatform}
                  alt={"ig"}
                  style={{
                    width: "2rem",
                    height: "2rem",
                    filter:
                      activeTab === WAREHOUSE_IG
                        ? "grayscale(0)"
                        : "grayscale(100%)",
                  }}
                  className="img-fluid mr-2"
                />
                <b>
                  Instagram {`(${managePostTab.numberPost[WAREHOUSE_IG] || 0})`}
                </b>
              </div>
            </div>
            <div
              style={{ color: "#10B981", fontWeight: "600" }}
              className="d-flex align-items-center"
            >
              <Icon name="dot" width={12} height={12} />
              <span>{t("manage_post.cnt_campaign_running", {
                cnt_campaign: countActive
              })}</span>
            </div>
          </Block>
        )}

        {renderIconPlatform(generalData)}

        <Block className={`pt-0`}>
          {!isLoading &&
            !managePostTab.postDataTab[activeTab] &&
            !isFetching ? (
            <>
              <div
                className={`mt-6`}
                style={{ background: "white", marginTop: "24px" }}
              >
                {showMultiDeleted && (
                  <div className="d-flex p-2 justify-content-end">
                    <Button
                      style={{ background: "red", color: "white" }}
                      onClick={() => setModalDeleteMultiPosts(true)}
                    >
                      <Icon name="trash"></Icon>
                      <span>{t("deleting_multi_channel")}</span>
                    </Button>
                  </div>
                )}

                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>
                        <div className="custom-control custom-control-sm custom-checkbox notext">
                          <input
                            type="checkbox"
                            className="custom-control-input form-control"
                            defaultChecked={false}
                            checked={
                              !managePostTab.idPostMutilDelete.find(
                                (c) => !c.check
                              )
                            }
                            id={`uid_1${activeTab}`}
                            onChange={(e) => selectorCheck(e)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`uid_1${activeTab}`}
                          ></label>
                        </div>
                      </th>
                      <th width={"12.2%"} scope="col">
                        {activeTab === AUTO_POST ? (
                          <b style={{ color: "red" }}>{t("manage_post.tab_content.column.created_at")}</b>
                        ) : (
                          t("manage_post.tab_content.column.scheduled_at")
                        )}
                      </th>
                      <th width={"11.7%"} scope="col">
                        {t("manage_post.tab_content.column.channel")}
                      </th>
                      <th width={"12.4%"} scope="col">
                        {t("manage_post.tab_content.column.status")}
                      </th>
                      <th width={"38.6%"} scope="col">
                        {t("manage_post.tab_content.column.content")}
                      </th>
                      <th width={"15%"} scope="col">
                        {t("manage_post.tab_content.column.media")}
                      </th>
                      <th width={"10.1%"} className={`text-center`} scope="col">
                        {t("manage_post.tab_content.column.action")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {listPostByFetch?.length > 0 ? (
                      listPostByFetch?.map((post, index) => {
                        let statusPost;
                        if (
                          (activeTab === MANUAL_POST && !isSinglePlatform) ||
                          (activeTab === AUTO_POST && !isSinglePlatform)
                        ) {
                          statusPost = convertStatus(post?.channel);
                        } else {
                          statusPost = post?.status;
                        }

                        // const mediaErrorFlag =
                        //   checkMediaStatus(post) &&
                        //   statusPost !== POST_STATUS_PUBLISH_SUCCESS;
                        const mediaErrorFlag = false;
                        return (
                          <tr
                            key={index}
                            style={{ fontSize: "12px", cursor: "pointer" }}
                            onClick={() => {
                              let payload = { ...post };
                              if (isSinglePlatform) {
                                payload = {
                                  ...post,
                                  channel: {
                                    [channelName]: [
                                      {
                                        ...post,
                                        _id: post._id,
                                      },
                                    ],
                                  },
                                };
                              }
                              setDetailPost(payload);
                            }}
                          >
                            <td>
                              <div className="custom-control custom-control-sm custom-checkbox notext">
                                {!isFilter ? (
                                  <input
                                    type="checkbox"
                                    className="custom-control-input form-control"
                                    defaultChecked={
                                      managePostTab.idPostMutilDelete.find(
                                        (c) => c.id === post._id
                                      )?.check
                                    }
                                    id={
                                      !isFilter
                                        ? post._id + "uid1"
                                        : post.post_id + "uid1"
                                    }
                                    key={Math.random()}
                                    onChange={(e) =>
                                      onSelectChange(
                                        e,
                                        !isFilter ? post._id : post.post_id
                                      )
                                    }
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    className="custom-control-input form-control"
                                    defaultChecked={
                                      managePostTab.idPostMutilDelete.find(
                                        (c) => c.id === post.post_id
                                      )?.check
                                    }
                                    id={
                                      !isFilter
                                        ? post._id + "uid1"
                                        : post.post_id + "uid1"
                                    }
                                    key={Math.random()}
                                    onChange={(e) =>
                                      onSelectChange(
                                        e,
                                        !isFilter ? post._id : post.post_id
                                      )
                                    }
                                  />
                                )}
                                <input
                                  type="checkbox"
                                  className="custom-control-input form-control"
                                  defaultChecked={
                                    managePostTab.idPostMutilDelete.find(
                                      (c) => c.id === post.post_id
                                    )?.check
                                  }
                                  id={
                                    !isFilter
                                      ? post._id + "uid1"
                                      : post.post_id + "uid1"
                                  }
                                  key={Math.random()}
                                  onChange={(e) =>
                                    onSelectChange(
                                      e,
                                      !isFilter ? post._id : post.post_id
                                    )
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={
                                    !isFilter
                                      ? post._id + "uid1"
                                      : post.post_id + "uid1"
                                  }
                                ></label>
                              </div>
                            </td>
                            <td
                              className={`${style.Td}`}
                              onClick={() => {
                                handleOpenModalPostDetail({ item: post });
                              }}
                            >
                              {activeTab !== AUTO_POST ? (
                                <>
                                  {parse(
                                    getTimeFirstItemChannel(
                                      post?.channel,
                                      post?.scheduled_at
                                    )
                                  )}
                                </>
                              ) : (
                                <>
                                  <b>
                                    {moment(post?.created_at).format("HH:mm ")}
                                  </b>
                                  {moment(post?.created_at).format(
                                    "DD/MM/YYYY"
                                  )}
                                </>
                              )}
                            </td>
                            <td
                              className={`${style.Td} ${style.TdChannel}`}
                              onClick={() =>
                                handleOpenModalPostDetail({ item: post })
                              }
                            >
                              <div className="d-flex justify-content-between">
                                {post?.channel &&
                                  ((activeTab === MANUAL_POST &&
                                    !isSinglePlatform) ||
                                    (activeTab === AUTO_POST &&
                                      !isSinglePlatform) ? (
                                    <>
                                      <span>
                                        {countChannelPosted(post?.channel)} kênh
                                      </span>
                                      <div>
                                        {getUniqueChannels(post?.channel).map(
                                          (uniqChannel, uniqIndex) => {
                                            return (
                                              <Img
                                                src={uniqChannel}
                                                alt=""
                                                key={uniqIndex}
                                                className="img-fluid mr-1"
                                                style={{ width: "12px" }}
                                              />
                                            );
                                          }
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <span>1 kênh</span>
                                      <div>
                                        <Img
                                          src={getPlatformIcon(
                                            post?.channel?.platform
                                          )}
                                          alt="platform"
                                          className="img-fluid mr-1"
                                          style={{ width: "12px" }}
                                        />
                                      </div>
                                    </>
                                  ))}
                              </div>
                            </td>
                            <td
                              className={`${style.Td}`}
                              onClick={(e) => {
                                indexCurrent.current = index;
                                setDetailPost(post);
                                handleOpenModalPostDetail({ item: post });
                              }}
                            >
                              {
                                <div
                                  className={`d-flex`}
                                  style={{ height: "100%" }}
                                >
                                  <div>
                                    {mediaErrorFlag ? (
                                      <Img
                                        src={MediaErrorIcon}
                                        style={{
                                          width: "12px",
                                          height: "12px",
                                        }}
                                        alt="error image"
                                      />
                                    ) : (
                                      <IconPost status={statusPost} />
                                    )}
                                  </div>
                                  <div>
                                    <div
                                      style={{
                                        fontSize: "11px",
                                        fontWeight: "bold",
                                        marginLeft: "4px",
                                      }}
                                    >
                                      {mediaErrorFlag ? (
                                        <div>
                                          <span className="d-block">
                                            {POST_STATUS_MEDIA_ERROR}
                                          </span>
                                        </div>
                                      ) : (
                                        t(ARR_POST_STATUS_POST[statusPost])
                                      )}
                                    </div>
                                  </div>
                                </div>
                              }
                            </td>
                            <td
                              className={`${style.truncateCell} ${style.Td}`}
                              onClick={() =>
                                handleOpenModalPostDetail({ item: post })
                              }
                            >
                              {post.content}
                            </td>
                            <td>
                              <div className="d-flex" style={{ gap: "6px" }}>
                                {post?.media?.length > 0 &&
                                  post?.media?.slice(0, 2).map((mediaItem) => {
                                    return (
                                      <Img
                                        key={mediaItem._id}
                                        src={
                                          mediaItem.type === MEDIA_TYPE_IMAGE
                                            ? mediaItem.url
                                            : mediaItem.type ===
                                              MEDIA_TYPE_VIDEO
                                              ? mediaItem.thumb
                                              : null
                                        }
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                          borderRadius: "4px",
                                        }}
                                        alt="media"
                                      />
                                    );
                                  })}
                                {post?.media?.length > 2 && (
                                  <div className={`${style.MoreImage}`}>
                                    <div>{"+" + (post.media.length - 2)}</div>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td className={`${style.Td}`}>
                              <div
                                className={`d-flex`}
                                style={{ gap: "8px", justifyContent: "center" }}
                              >
                                {/* <div
                                  onClick={() => {
                                    handleOpenModalPostDetail({ item: post });
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="16"
                                    height="16"
                                    fill="rgba(159,207,248,1)"
                                  >
                                    <path d="M21 3C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H21ZM4 16V19H20V16H4ZM4 14H20V5H4V14Z"></path>
                                  </svg>
                                </div> */}
                                {checkPlatformSeeding(post.channel) && (
                                  <div
                                    onClick={() => {
                                      handleViewSeedingPost({ item: post });
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      position: "relative",
                                    }}
                                  >
                                    <IconChat />
                                    {post.comment_seedings &&
                                      post.comment_seedings.length > 0 && (
                                        <span
                                          className={`${style.comment_seeding_number}`}
                                          id={`comment_number_${post._id}`}
                                        >
                                          {post.comment_seedings.length}
                                        </span>
                                      )}
                                  </div>
                                )}
                                {/* {
                                                                (mediaErrorFlag || statusPost === POST_STATUS_PUBLISH_ERROR) &&
                                                                <Icon style={{ cursor: "pointer" }} name="reply-all-fill" onClick={(e) => {
                                                                    handleRetryProcessMedia(e, post, index);
                                                                }} />
                                                            } */}
                                <div
                                  onClick={() =>
                                    handleOpenModalPostDetail({ item: post })
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <IconEyePost />
                                </div>
                                {activeTab === MANUAL_POST ||
                                  activeTab === AUTO_POST ? (
                                  <div
                                    onClick={() => {
                                      let payload = { ...post };
                                      if (isSinglePlatform) {
                                        payload = {
                                          ...post,
                                          channel: {
                                            [channelName]: [post],
                                          },
                                          _id: !isFilter
                                            ? post._id
                                            : post.post_id,
                                        };
                                      }
                                      confirmToDeletePost(payload._id);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <IconTrashPost />
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <th colSpan={7}>
                          <div className={`${style.noSearchData}`} colspan={7}>
                            <Img
                              // className={}
                              src={NoData}
                              alt={NoData}
                            />
                            <div className={`${style.headerNoSearchData}`}>
                              {t("manage_post.tab_content.not_found_content")}
                            </div>
                          </div>
                        </th>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className={`mt-3 text-right manage_post_pagination_nav_${tabId}`}
              >
                {listPostByFetch && (
                  <PaginationCustom
                    pageSize={itemPerPage}
                    totalCount={totalPage || 0}
                    siblingCount={1}
                    onPageChange={paginate}
                    currentPage={
                      isSinglePlatform
                        ? pageNum
                        : managePostTab.postTab[activeTab]
                    }
                    className={`manage_post_pagination`}
                  />
                )}
              </div>
            </>
          ) : (
            !managePostTab.postDataTab[activeTab] && (
              <div
                className={"d-flex justify-center"}
                style={{ width: "100%" }}
              >
                <Spinner
                  size="lg"
                  color="dark"
                  style={{ width: "3rem", height: "3rem" }}
                />
              </div>
            )
          )}

          {!isLoading && managePostTab.postDataTab[activeTab] && (
            <NoPostAlert statusTypePost={activeTab} />
          )}
        </Block>
      </Content>
      <ModalDetailPost
        openModal={activeBottomSheetPostToChannels}
        setOpenModal={setActiveBottomSheetPostToChannels}
        activeTab={activeTab}
        postDetail={detailPost}
        refetchListPost={refetch}
        isSinglePlatform={isSinglePlatform}
      />
      <ModalSeedingPost
        openModal={modalSeeding}
        setOpenModal={setModalSeeding}
        incrementComment={() => {
          // let valComment = document.getElementById(`comment_number_${detailPost._id}`).innerHTML;
          // if (valComment) {
          //     document.getElementById(`comment_number_${detailPost._id}`).innerHTML = parseInt(valComment || 0) + 1;
          // }
          isSinglePlatform
            ? handleFilterChannel(platformName, pageNum)
            : refetch();
        }}
        postDetail={detailPost}
        statusPost={convertStatus(detailPost?.channel || {})}
        isHavePostSuccess={checkHavePostSuccess(detailPost?.channel || {})}
        isSinglePlatform={isSinglePlatform}
      //refetchListPost={refetch}
      />
      <ConfimDelete
        modalDelete={modalDelete}
        toggleDelete={toggleDelete}
        messeage={t("manage_post.tab_content.message_cofirm_delete_post")}
        handleDelete={(e) => handleDeletePost(idDeletePost, e)}
      />

      <ModalDeleteMultiPosts
        openModal={modalDeleteMultiPosts}
        setOpenModal={setModalDeleteMultiPosts}
        refetch={refetch}
        managePostTab={managePostTab}
        handleRemovePostAfterDelMulti={() => {
          dispatch(setIdPostMutilDelete([]));
        }}
      />
      <Modal
        size="xl"
        style={{
          maxWidth: "1300px",
          width: "80%",
        }}
        isOpen={openModalPostDetail}
        toggle={handleOpenModalPostDetail}
      >
        <ModalBody style={{ padding: "0px", borderRadius: "8px" }}>
          <PostDetail
            closeModal={handleOpenModalPostDetail}
            dataPostDetail={detailPost}
            setOpenModalPostDetail={setOpenModalPostDetail}
            setListPostByFetch={setListPostByFetch}
            listPostByFetch={listPostByFetch}
            refetchListPost={refetch}
          />
        </ModalBody>
      </Modal>
    </>
  );
}

const ModalDetailPost = React.lazy(() =>
  import("../../components/Post/ModalDetailPost")
);
const ModalSeedingPost = React.lazy(() =>
  import("../../components/Post/ModalSeedingPost")
);
const ModalDeleteMultiPosts = React.lazy(() =>
  import("../../components/Modal/ModalDelMultiPosts")
);
// const ModalAlertMediaError = React.lazy(() => import("../../components/Post/ModalAlertMediaError"))
