import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  DropdownItem,
  Spinner,
  Dropdown,
  Modal,
  ModalBody,
} from "reactstrap";
import FacebookPlatform from "../../images/dashboard/icon/icon-fb.png";
import TiktokPlatform from "../../images/dashboard/icon/type=Tiktok.png";
import YoutubePlatform from "../../images/dashboard/icon/type=youtube.png";
import InstagramPlatform from "../../images/dashboard/icon/type=Instragam.png";
import Warning from "../../images/dashboard/warrning.png";
import CommingSoon from "../../images/dashboard/commingsoon.png";
import style from "./index.module.scss";
import { Icon } from "../../../components/Component";
import Overview from "./SubPage/Overview";
import PostAndInteraction from "./SubPage/PostAndInteraction";
import Accessibility from "./SubPage/Accessibility";
import { useDispatch, useSelector } from "react-redux";
import { setTabDashboard, resetDashboard, updateTabDashboard } from "../../reducers/dashboardSlice";
import { getChannels } from "../../../api/createPost";
import Audience from "./SubPage/Audience";
import {
  PLATFORM_FACEBOOK,
  PLATFORM_FACEBOOKGROUP,
  PLATFORM_INSTAGRAM,
  PLATFORM_TIKTOK,
  PLATFORM_YOUTUBE,
} from "../../utils/Utils";

export default function Dashboard() {
  const { t } = useTranslation();
  const ranges = [7, 14, 30, 60, 90];
  const [range, setRange] = useState(14);
  const [activeTab, setActiveTab] = useState("1");
  const [subActiveTab, setSubActiveTab] = useState("1");
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingChannel, setLoadingChannel] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const projectId = userInfo.last_project_active;
  const [isOpen, setIsOpen] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [isChangeFilter, setChangeFilter] = useState(null);


  const dispatch = useDispatch();
  const dashboardList = useSelector((state) => state.dashboard.dashboards);
  const dashboardInit = useSelector((state) => state.dashboard.isInit);

  const [modalWarning, setModalWarning] = useState(false);

  const toggleWarning = () => {
    setModalWarning(!modalWarning);
  };

  const toggleChanel = () => {
    setIsOpen(!isOpen);
  };

  /**
   * CHANNELS
   */
  const [optionChannels, setOptionChannels] = useState([]);
  const [listChannels, setListChannels] = useState([]);
  const optionChannelsEnd = useRef(false);
  useEffect(async () => {
    dispatch(resetDashboard());

    if (userInfo.last_project_active && !optionChannelsEnd.current) {
      // setGettingData(true);
      const data = {};
      const fetchData = async () => {
        return await getChannels(projectId, data);
      };
      const dataResponse = await fetchData();
      if (dataResponse.status) {
        const { channel: channels, favorite_channel: _favoriteChannels } =
          dataResponse.data;
        setListChannels(channels);
      }
    }
  }, []);

  useEffect(() => {
    const updateOptionChannels = (channels) => {
      return channels.map((channel) => ({
        ...channel,
        checked: true,
      }));
    };

    if (activeTab === "1") {
      const filteredChannels = listChannels.filter(
        (item) =>
          item.platform === PLATFORM_FACEBOOK ||
          item.platform === PLATFORM_FACEBOOKGROUP
      );
      setOptionChannels(updateOptionChannels(filteredChannels));
    }
    if (activeTab === "4") {
      const filteredChannels = listChannels.filter(
        (item) => item.platform === PLATFORM_INSTAGRAM
      );
      setOptionChannels(updateOptionChannels(filteredChannels));
    }
    if (activeTab === "3") {
      const filteredChannels = listChannels.filter(
        (item) => item.platform === PLATFORM_YOUTUBE
      );
      setOptionChannels(updateOptionChannels(filteredChannels));
    }
    if (activeTab === "2") {
      const filteredChannels = listChannels.filter(
        (item) => item.platform === PLATFORM_TIKTOK
      );
      setOptionChannels(updateOptionChannels(filteredChannels));
    }
  }, [activeTab, listChannels]);

  const handleRadioChange = () => {
    setIsChecked(!isChecked);
  };
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setSubActiveTab("1");
      setChangeFilter(tab);
      setChannels([]);
    }
  };
  const toggleSub = (tab) => {
    if (subActiveTab !== tab) setSubActiveTab(tab);
  };
  const tabStatistic = [
    {
      key: "1",
      icon: FacebookPlatform,
      title: "Facebook",
    },
    {
      key: "2",
      icon: TiktokPlatform,
      title: "Tiktok",
    },
    {
      key: "3",
      icon: YoutubePlatform,
      title: "Youtube",
    },
    {
      key: "4",
      icon: InstagramPlatform,
      title: "Instagram",
    },
  ];

  const subTab = [
    {
      key: "1",
      title: "Tổng quan",
    },
    {
      key: "2",
      title: "Bài đăng & Tương tác",
    },
    {
      key: "3",
      title: "Khả năng tiếp cận",
    },
  ];

  const subTabYT = [
    {
      key: "1",
      title: "Tổng quan",
    },
    {
      key: "2",
      title: "Bài đăng & Tương tác",
    },
    {
      key: "3",
      title: "Hiệu quả video",
    },
    {
      key: "4",
      title: "Khán giả",
    },
  ];

  const subTabTt = [
    {
      key: "1",
      title: "Tổng quan",
    },
  ];

  const [selectAllChecked, setSelectAllChecked] = useState(true);
  const [channels, setChannels] = useState([]);

  const handleCheckboxChange = (index) => {
    setOptionChannels((prevOptionChannels) => {
      let updatedChannels = [...prevOptionChannels];
      updatedChannels[index].checked = !updatedChannels[index].checked;
      return updatedChannels;
    });
  };

  const handleCheckAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAllChecked(isChecked);
    setOptionChannels((prevOptionChannels) =>
      prevOptionChannels.map((channel) => {
        return { ...channel, checked: isChecked };
      })
    );
  };

  const handleCancelClick = () => {
    toggleChanel();
  };

  const handleConfirm = () => {
    setLoadingChannel(true);
    if (selectAllChecked) {
      setChannels([]);
    } else {
      const listChannelChecked = optionChannels.filter(x => x.checked).map(item => item._id);
      if (listChannelChecked.length === 0) {
        toggleWarning();
        setLoadingChannel(false);
      } else {
        setChannels(listChannelChecked);
      }
    }
    setChangeFilter(null);
    toggleChanel();
  };

  console.log(optionChannels)

  useEffect(() => {
    const allChecked = optionChannels.every(
      (channel) => channel.checked === true
    );
    if (selectAllChecked !== allChecked) {
      setSelectAllChecked(allChecked);
    }
  }, [optionChannels, selectAllChecked]);

  return (
    <React.Fragment>
      <Head title="Analytics Dashboard" />
      <div style={{ height: "69px" }}></div>
      <div
        className={`${style.notification} d-flex flex-row align-items-center`}
      >
        <img src={Warning} alt="warning" className="mr-2" />
        Phiên bản thử nghiệm nâng cấp của gói Business được mở miễn phí cho toàn
        bộ khách hàng đến 02/09/2023.
      </div>
      <Content>
        <Nav tabs className={`${style.navPost}`}>
          <div className="w-100 d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
            <div className="d-flex flex-row ">
              {tabStatistic.map(({ key, icon, title }, index) => {
                return (
                  <NavItem className={`${style.navWrapper}`} key={index}>
                    <NavLink
                      tag="a"
                      href="#tab"
                      className={`${activeTab === key && style.active} ${style.navItem
                        }`}
                      style={{ borderTopLeftRadius: "8px" }}
                      onClick={(ev) => {
                        ev.preventDefault();
                        if (key === "1" || key === "3" || key === "2") {
                          setOpenModel(false);
                          toggle(key);
                        } else {
                          setOpenModel(true);
                        }
                      }}
                    >
                      <img
                        src={icon}
                        alt={title}
                        style={{ width: "2.5rem", height: "2.5rem" }}
                        className="img-fluid"
                      />{" "}
                    </NavLink>
                  </NavItem>
                );
              })}
            </div>
            <div className="d-flex flex-column flex-md-row align-items-md-center p-2">
              <div className={`${style.channel}`}>
                <Dropdown
                  isOpen={isOpen}
                  toggle={toggleChanel}
                  className="w-100"
                >
                  <DropdownToggle
                    tag="a"
                    className="dropdown-toggle btn btn-white btn-dim btn-outline-light w-100"
                    style={{
                      border: "1px solid",
                      borderColor: "#AEBFE7",
                      borderRadius: "8px",
                      padding: "8px 20px",
                      backgroundColor: "transparent",
                    }}
                  >
                    <span className="w-100">
                      <span
                        className="d-md-inline"
                        style={{ color: "#AEBFE7" }}
                      >
                        {selectAllChecked
                          ? "Tất cả các kênh"
                          : `Chọn ${optionChannels?.reduce((count, item) => {
                            // Nếu trường "checked" của item bằng true, tăng biến count lên 1
                            if (item.checked === true) {
                              return count + 1;
                            }
                            return count;
                          }, 0)} kênh`}
                        {loadingChannel && (
                          <Spinner size="sm" color="light" className="ml-1" />
                        )}
                      </span>
                    </span>
                    <Icon
                      className="d-sm-inline"
                      style={{ color: "#AEBFE7" }}
                      name="chevron-down"
                    ></Icon>
                  </DropdownToggle>
                  <DropdownMenu style={{ width: "100%" }}>
                    <div
                      className="px-2 my-2 rounded"
                      style={{ height: "300px", overflow: "auto" }}
                    >
                      <ul className="link-tidy">
                        <li
                          className="d-flex flex-row justify-content-between"
                          style={{ backgroundColor: "#F9FAFB" }}
                        >
                          <div
                            className="custom-control custom-control-sm custom-checkbox p-0 m-0"
                            style={{ order: "2" }}
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input p-0 m-0"
                              checked={selectAllChecked}
                              onChange={handleCheckAllChange}
                              id="check-all"
                            />
                            <label
                              className="custom-control-label p-0 m-0"
                              htmlFor="check-all"
                            ></label>
                          </div>
                          <label
                            htmlFor="check-all-pr"
                            className="p-0 m-0"
                            style={{ order: "1" }}
                          >
                            Chọn tất cả
                          </label>
                        </li>
                        {optionChannels.map((item, index) => (
                          <li
                            key={index}
                            className="d-flex flex-row justify-content-between"
                            style={{ backgroundColor: "#F9FAFB" }}
                          >
                            <div
                              className="custom-control custom-control-sm custom-checkbox p-0 m-0"
                              style={{ order: "2" }}
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input p-0"
                                checked={item.checked}
                                onChange={() => handleCheckboxChange(index)}
                                id={`check-for-${index}`}
                              />
                              <label
                                className="custom-control-label p-0 my-0"
                                htmlFor={`check-for-${index}`}
                              ></label>
                            </div>
                            <label
                              htmlFor={`check-for-${index}`}
                              className="pr-5 my-0"
                              style={{ order: "1" }}
                            >
                              {item.name}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div
                      className="h-100 d-flex flex-row p-2"
                      style={{ gap: "8px" }}
                    >
                      <button
                        onClick={handleCancelClick}
                        className={`w-100 ${style.btnCance}`}
                      >
                        Huỷ
                      </button>
                      <button
                        onClick={handleConfirm}
                        className={`w-100 ${style.btnConfirm}`}
                      >
                        Áp dụng
                      </button>
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div className="mt-2 mt-md-0">
                <UncontrolledDropdown>
                  <DropdownToggle
                    tag="a"
                    className="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                    style={{
                      border: "1px solid",
                      borderColor: "#AEBFE7",
                      borderRadius: "8px",
                      padding: "8px 20px",
                      backgroundColor: "transparent",
                    }}
                  >
                    <Icon
                      className="d-sm-inline"
                      style={{ color: "#AEBFE7" }}
                      name="calendar"
                    ></Icon>
                    <span>
                      <span
                        className="d-md-inline"
                        style={{ color: "#AEBFE7" }}
                      >
                        {t("overview.range_text", { days: range })}
                        {loading && (
                          <Spinner size="sm" color="light" className="ml-1" />
                        )}
                      </span>
                    </span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <ul className="link-list-opt no-bdr">
                      {ranges.map((r) => (
                        <li key={r}>
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault();
                              setLoading(true);
                              setRange(r);
                            }}
                          >
                            {t("overview.range_text", { days: r })}
                          </DropdownItem>
                        </li>
                      ))}
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </div>
          <div className={`${style.navSubPostContainer}`}>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Nav tabs className={`${style.navSubPost}`}>
                  {subTab.map(({ key, title }, index) => {
                    return (
                      <NavItem
                        className={`${style.navSubWrapper}  `}
                        key={index}
                      >
                        <NavLink
                          tag="a"
                          href="#tab"
                          className={`${subActiveTab === key && style.active} ${style.navItem
                            }`}
                          onClick={(ev) => {
                            ev.preventDefault();
                            toggleSub(key);
                          }}
                        >
                          {title}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>
              </TabPane>

              <TabPane tabId="4">
                <Nav tabs className={`${style.navSubPost}`}>
                  {subTab.map(({ key, title }, index) => {
                    return (
                      <NavItem
                        className={`${style.navSubWrapper}  `}
                        key={index}
                      >
                        <NavLink
                          tag="a"
                          href="#tab"
                          className={`${subActiveTab === key && style.active} ${style.navItem
                            }`}
                          onClick={(ev) => {
                            ev.preventDefault();
                            toggleSub(key);
                          }}
                        >
                          {title}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>
              </TabPane>
              <TabPane tabId="3">
                <Nav tabs className={`${style.navSubPost}`}>
                  {subTabYT.map(({ key, title }, index) => {
                    return (
                      <NavItem
                        className={`${style.navSubWrapper}  `}
                        key={index}
                      >
                        <NavLink
                          tag="a"
                          href="#tab"
                          className={`${subActiveTab === key && style.active} ${style.navItem
                            }`}
                          onClick={(ev) => {
                            ev.preventDefault();
                            toggleSub(key);
                          }}
                        >
                          {title}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>
              </TabPane>
              <TabPane tabId="2">
                <Nav tabs className={`${style.navSubPost}`}>
                  {subTabTt.map(({ key, title }, index) => {
                    return (
                      <NavItem
                        className={`${style.navSubWrapper}  `}
                        key={index}
                      >
                        <NavLink
                          tag="a"
                          href="#tab"
                          className={`${subActiveTab === key && style.active} ${style.navItem
                            }`}
                          onClick={(ev) => {
                            ev.preventDefault();
                            toggleSub(key);
                          }}
                        >
                          {title}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>
              </TabPane>
            </TabContent>
          </div>
        </Nav>
        <TabContent activeTab={subActiveTab}>
          <TabPane tabId="1">
            <Overview
              tab={activeTab}
              channel={channels}
              range={range}
              subTab={subActiveTab}
              setLoading={setLoading}
              setLoadingChannel={setLoadingChannel}
              dashboardList={dashboardList}
              setDashboardList={(payload) => dispatch(setTabDashboard(payload))}
              updateTabDashboard={(payload) => dispatch(updateTabDashboard(payload))}
              dashboardInit={dashboardInit}
              isChangeFilter={isChangeFilter}
              setChangeFilter={() => setChangeFilter(null)}
            />
          </TabPane>
          <TabPane tabId="2">
            <PostAndInteraction
              tab={activeTab}
              channel={channels}
              range={range}
              subTab={subActiveTab}
              setLoading={setLoading}
              setLoadingChannel={setLoadingChannel}
              dashboardList={dashboardList}
              setDashboardList={(payload) => dispatch(setTabDashboard(payload))}
              updateTabDashboard={(payload) => dispatch(updateTabDashboard(payload))}
              dashboardInit={dashboardInit}
              isChangeFilter={isChangeFilter}
              setChangeFilter={() => setChangeFilter(null)}
            />
          </TabPane>
          <TabPane tabId="3">
            <Accessibility
              tab={activeTab}
              channel={channels}
              range={range}
              subTab={subActiveTab}
              setLoading={setLoading}
              setLoadingChannel={setLoadingChannel}
              dashboardList={dashboardList}
              setDashboardList={(payload) => dispatch(setTabDashboard(payload))}
              updateTabDashboard={(payload) => dispatch(updateTabDashboard(payload))}
              dashboardInit={dashboardInit}
              isChangeFilter={isChangeFilter}
              setChangeFilter={() => setChangeFilter(null)}
            />
          </TabPane>
          <TabPane tabId="4">
            <Audience
              tab={activeTab}
              channel={channels}
              range={range}
              subTab={subActiveTab}
              setLoading={setLoading}
              setLoadingChannel={setLoadingChannel}
              dashboardList={dashboardList}
              setDashboardList={(payload) => dispatch(setTabDashboard(payload))}
              updateTabDashboard={(payload) => dispatch(updateTabDashboard(payload))}
              dashboardInit={dashboardInit}
              isChangeFilter={isChangeFilter}
              setChangeFilter={() => setChangeFilter(null)}
            />
          </TabPane>
        </TabContent>
      </Content>
      <Modal isOpen={openModel} className="modal-dialog-centered">
        {/* <ModalHeader
          toggle={() => setOpenModel(false)}
          close={
            <button
              className="close"
              style={{
                border: "none",
                background: "transparent",
                outline: "none",
                cursor: "pointer",
              }}
              onClick={() => setOpenModel(false)}
            >
              <Icon name="cross" />
            </button>
          }
        >
          Đang phát triển
        </ModalHeader> */}
        <ModalBody className="modal-body-lg text-center">
          <div className="nk-modal">
            <h4 className="nk-modal-title">Tính năng đang phát triển</h4>
            <div>
              <img
                src={CommingSoon}
                alt="commingsoon"
                style={{ width: "80%", height: "auto" }}
                className="img-fluid"
              />
            </div>
            <div className="nk-modal-action">
              <Button
                size="md"
                color="warning"
                className="btn-mw"
                onClick={() => setOpenModel(false)}
              >
                OK
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ModalWarning
        modalWarning={modalWarning}
        toggleWarning={toggleWarning}
        warningText={`Bạn cần chọn tối thiểu 1 kênh để thống kê!`}
      />
    </React.Fragment>
  );
}
const ModalWarning = React.lazy(() =>
  import("../../components/Modal/ModalWarning")
);
