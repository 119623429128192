import { Button } from "reactstrap";
import style from "./index.module.scss";
const ChatFooter = () => {

    const handleToHub = () => {
        window.open("https://chat.so9.vn/","_blank")
    }
    return (
        <>
            <div className={`${style.footerwaper} d-flex justify-content-center align-items-center`}>
                    <div className={`${style.footerContent}`}>
                        <div className={`${style.footerTitle}`}>SO9 HUB đang phát triển từng ngày</div>
                        <p>
                        Hãy tham gia dùng & đóng góp cho đội ngũ phát triển. Chúng tôi cam kết sẽ mang đến sản phẩm tốt nhất tới bạn!
                        </p>
                    </div>
                    <Button
                    onClick={()=>{handleToHub()}}
                    >

                    <span>Truy cập SO9 HUB  </span> <em class="icon ni ni-arrow-right"></em>
                    </Button>
            </div>
        </>
    )
}

export default ChatFooter