import React, { useState, useEffect } from "react";
import Head from "../../../layout/head/Head";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useDispatch } from "react-redux";
import { Icon, Img } from "../../../components/Component";
import PostMulti from "./PostMulti";
import PostFastNew from "./PostFastNew";
// import PostFastUpdate from "./PostFastUpdate";
import style from "./index.module.scss";
import "./create-post.scss";
import IconWarning from "../../images/icons/IconWarning.svg";
import { getErrorChannel } from "../../../api/channel";
import { setCollapseSidebarLeft } from "../../reducers/layoutSlice";
// import ModalMaintenance from "../../components/Modal/ModalMaintenance";

export default function UpPost() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("1");
  const [numChannels, setNumChannels] = useState(0);
  const userInformation = JSON.parse(localStorage.getItem("userInfor"));
  const projectId = userInformation.last_project_active;
  const listProjectException = [
    "63256ee52fc4b337f308d725",
    "628c7e9ca05a926caa09caa5",
  ];
  useEffect(() => {
    dispatch(setCollapseSidebarLeft(true));
    // const io = window.io();
    if (window.location.href.includes("drive=true")) {
      setActiveTab("2");
    }
    // const socketInstance = io(process.env.REACT_APP_CDN_API, {
    //   transports: ["websocket"],
    //   withCredentials: true,
    // });
    //
    // setSocket(socketInstance);
    // return () => {
    //   socketInstance.disconnect();
    // };
  }, []);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  useEffect(() => {
    getChannelError(projectId);
  }, [projectId]);

  const getChannelError = (projectId) => {
    const fetchData = async () => {
      return await getErrorChannel(projectId);
    };
    fetchData().then((response) => {
      const data = response.data;
      setNumChannels(data);
    });
  };
  return (
    <React.Fragment>
      <Head title={"Create A Post"}></Head>
      <div
        style={{
          marginTop: "65px",
          position: "relative",
          padding: "24px 5px 0px 5px",
        }}
      >
        <div className={`${style.notifiErrorChannel} d-flex`}>
          <Img
            src={IconWarning}
            alt="IconWarning"
            style={{ marginRight: "13px" }}
          />
          <p className={style.notifiText}>
            Có {numChannels} kênh của bạn đang bị lỗi kết nối.{" "}
            <u
              onClick={() => {
                let url =
                  process.env.PUBLIC_URL + "/admin/project/connect-channel";
                window.history.pushState("", "", url);
                window.location.reload();
              }}
            >
              Kiểm tra ngay
            </u>
          </p>
        </div>
        <Nav
          tabs
          style={{ marginBottom: "0px" }}
          className={`mt-n3 ${style.navPost}`}
        >
          <NavItem className={`${style.navWrapper}`}>
            <NavLink
              tag="a"
              href="#tab"
              className={`${activeTab === "1" && style.active} ${
                style.navItem
              }`}
              style={{ borderTopLeftRadius: "8px" }}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("1");
              }}
            >
              <Icon
                name="layout-fill1"
                style={{
                  fontSize: "1em",
                  color: activeTab === "1" ? "#FD1F6D" : "#FAFF00",
                }}
                className="mr-1"
              />{" "}
              Đăng thường
            </NavLink>
          </NavItem>
          <NavItem className={`${style.navWrapper}`}>
            <NavLink
              tag="a"
              href="#tab"
              className={`${activeTab === "2" && style.active} ${
                style.navItem
              }`}
              style={{ borderTopRightRadius: "8px" }}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("2");
              }}
            >
              <Icon
                name="spark-fill"
                style={{
                  fontSize: "1em",
                  color: activeTab === "2" ? "#FD1F6D" : "#FAFF00",
                }}
                className="mr-1"
              />{" "}
              Đăng hàng loạt
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} style={{ marginTop: "0px" }}>
          <TabPane tabId="1" className="postWrapper">
            <PostMulti />
          </TabPane>
          <TabPane tabId="2">
            <PostFastNew />
            {/* {
              (listProjectException.includes(projectId) || true) ? <PostFastNew /> : <PostFastUpdate />
            } */}
          </TabPane>
        </TabContent>
      </div>
      {/*<ModalMaintenance/>*/}
    </React.Fragment>
  );
}
