import { forwardRef, useEffect, useState } from "react";

const ScrollIndicator = ({ dataLength }, ref) => {
  const [dot, setDot] = useState(0);

  const onSwipe = (position) => {
    setDot(position);
  };
  useEffect(() => {
    ref.current = { onSwipe };
  }, []);

  const renderItems = () => {
    let temp = [];
    for (let i = 0; i < dataLength; i++) {
      temp.push(i);
    }
    return temp;
  };
  return (
    <div className="flex-1 flex justify-center items-center mt-3">
      {renderItems()?.map((item, index) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "8px",
              width: "8px",
              backgroundColor: dot === index ? "#3897F0" : "#EFEFEF",
              borderRadius: "5px",
              marginRight: "10px",
            }}
            key={index}
          />
        );
      })}
    </div>
  );
};

export default forwardRef(ScrollIndicator);
