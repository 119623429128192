import { API } from "../libs/client";


export const getListPostComment = async ({ projectId, postId, data }) => {
    const uri = `/projects/${projectId}/posts/${postId}/review-posts/list`;
    const res = await API.get(uri, {params:data});
    return res.data;
};
export const getPostCommentCnt = async ({ projectId, postId }) => {
    const uri = `/projects/${projectId}/posts/${postId}/review-posts/cnt`;
    const res = await API.get(uri);
    return res.data;
};
export const storePostComment = async ({ projectId, postId, data }) => {
    const uri = `/projects/${projectId}/posts/${postId}/review-posts/store`;
    const res = await API.post(uri, data);
    return res.data;
};



export const getListHashTag = async (projectId) => {
    const uri = `/projects/${projectId}/group-hashtags/list`;
    const res = await API.get(uri);
    return res.data;
};



export const createHashTag = async ({ projectId, data }) => {
    const uri = `/projects/${projectId}/group-hashtags`;
    const res = await API.post(uri, data);
    return res.data;
};

export const editHashTag = async ({ projectId,hashTagId, data }) => {
    const uri = `/projects/${projectId}/group-hashtags/${hashTagId}`;
    const res = await API.put(uri, data);
    return res.data;
};

export const deleteHashTag = async ({ projectId,hashTagId}) => {
    const uri = `/projects/${projectId}/group-hashtags/${hashTagId}`;
    const res = await API.delete(uri);
    return res.data;
};


export const getListLabel = async (projectId) => {
    const uri = `/projects/${projectId}/labels/list`;
    const res = await API.get(uri);
    return res.data;
};

export const createLabel = async ({projectId, data}) => {
    const uri = `/projects/${projectId}/labels`;
    const res = await API.post(uri, data);
    return res.data;
};

export const editLabel = async ({projectId, labelId, data}) => {
    const uri = `/projects/${projectId}/labels/${labelId}`;
    const res = await API.put(uri, data);
    return res.data;
};

export const deleteLabel = async ({projectId, labelId}) => {
    const uri = `/projects/${projectId}/labels/${labelId}`;
    const res = await API.delete(uri);
    return res.data;
};

export const createPost = async ({projectId, data}) => {
    const uri = `/projects/${projectId}/posts/create`;
    const res = await API.post(uri, data);
    return res.data;
};

export const getAllChannel = async ({projectId, data}) => {
    const uri = `/projects/${projectId}/channels/list`;
    const res = await API.get(uri, data);
    return res.data;
};

export const getAllGroupChannel = async ({projectId}) => {
    const uri = `/projects/${projectId}/group-channels/list`;
    const res = await API.get(uri);
    return res.data;
};

  
  
  