import React, { useState, useContext } from "react";
import { Card, CardBody, Spinner } from "reactstrap";
import ReFreshContext from "../RefreshContext";
import style from "../index.module.scss";
import EditSVG from "../../../images/edit.svg";
import PurplFolderSVG from "../../../images/purpleFolder.svg";
import BLUE_VERIFIED_ICON from "../../../../images/icons/blue_verified.svg";
import moment from "moment";
import NO_IMAGE from "../../../images/post/no_image.svg";
import LIKE_ICON from "../../../images/post/like-icon.svg";
import COMMENT_ICON from "../../../images/post/comment-icon.svg";
import SHARE_ICON from "../../../images/post/share-icon.svg";
import PLAY_ICON from "../../../images/post/play-icon.svg";
import { MediaType } from "../constants/constants";
import { useSelector } from "react-redux";
import { Tooltip } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { commentPost } from "../../../../api/researchPage";
import { checkExtensionIsExisted } from "../../../utils/checkExtension";
import { Img } from "../../../../components/Component";

const ContentElement = ({
  handleHover,
  handleLeave,
  handleOpenDownExtension,
  Image,
  isHover,
  handleOpenSideBar,
  handleOpenNotifyComment,
  idComment,
  handleComment,
  content,
  avatar,
  idContent,
  index,
  name,
  numberOfLikes,
  numberOfComments,
  numberOfShares,
  createdAt,
  verificationStatus,
  onClickContent,
  data: postItem,
  formSeeding,
  channel,
}) => {
  const [isSelectedSchedule, setIsSelectedSchedule] = useState(false);
  const projectId = useSelector((state) => state.project.project?._id);

  const extensionStatusExisted = checkExtensionIsExisted("reup_so9_vn");

  const [comment, setTextComment] = useState("");
  const [isComment, setIsComment] = useState(false);

  const [data, setData] = useState(postItem);

  const [onHoverPage, setOnHoverPage] = useState(false);

  const [loadingComment, setLoadingComment] = useState(false);

  const [isCommentExpanded, setIsCommentExpanded] = useState([]);

  const isRefresh = useContext(ReFreshContext);

  const handleUpdatingFeature = () => {
    toast.info("Tính năng đang phát triển!", {
      autoClose: 1000,
      position: "top-right",
    });
  };

  const handleHoverPage = () => {
    if (isRefresh) {
      setOnHoverPage(!onHoverPage);
    }
  };

  const onFormSubmitComment = async () => {
    if (!comment) return;
    const content = {
      image: null,

      comment_with_channel_id: formSeeding.user_seeding._id,
      message: comment,
    };

    setLoadingComment(true);
    await commentPost(projectId, channel._id, data.id, content)
      .then((res) => {
        if (res.status) {
          //resetListSetting();

          setTextComment("");
          toast.success("Thêm comment thành công!", {
            autoClose: 1000,
            position: "top-right",
          });

          setData({
            ...data,
            seeding_comments: [
              {
                comment_with_channel_id: formSeeding.user_seeding._id,
                message: comment,
                created_at: new Date().toISOString(),
              },
              ...data.seeding_comments,
            ],
          });
        } else {
          toast.error("Thêm comment thất bại!", {
            autoClose: 1000,
            position: "top-right",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Thêm comment thất bại!", {
          autoClose: 1000,
          position: "top-right",
        });
      })
      .finally(() => {
        setLoadingComment(false);
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shift) {
      onFormSubmitComment();
    }
  };

  const handleClickReUpPost = () => {
    if (extensionStatusExisted) {
      setIsSelectedSchedule(true);
    } else {
      handleOpenDownExtension();
    }
  };

  const handleClickComment = () => {
    handleComment(idContent);
    setIsComment(!isComment);
  };

  const handleExpandComment = () =>
    setIsCommentExpanded((preState) => [...preState, index]);

  const renderCommentCreatedDate = () =>
    index === 0 && loadingComment
      ? "Đang đăng bình luận..."
      : moment(comment.created_at).format("HH:mm DD-MM-yyyy");

  const commentPlaceholder = loadingComment
    ? "Đang đăng bình luận..."
    : "Nhập bình luận của bạn tại đây";

  const loadingCommentValue = loadingComment
    ? "Đang đăng bình luận..."
    : comment;

  const iconLoadingComment = loadingComment && (
    <div
      style={{
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        height: "32px",
        paddingLeft: "2%",
      }}
    >
      <Spinner color="primary" size="sm" />
    </div>
  );

  const CURRENT_PAGE_IMG =
    formSeeding.user_seeding?.picture ||
    "https://nhadepso.com/wp-content/uploads/2023/03/loa-mat-voi-101-hinh-anh-avatar-meo-cute-dang-yeu-dep-mat_3.jpg";

  return (
    <Card
      style={{
        height: "100%",
        breakInside: "avoid",
        marginTop: !index || index === 0 ? "" : "15px",
        backgroundColor: isHover === idContent ? "#F1F5FF" : "",
      }}
      onMouseOver={() => handleHover(idContent)}
      onMouseLeave={handleLeave}
    >
      <CardBody>
        <div className={`d-flex justify-content-between align-items-center`}>
          <div
            className={`d-flex justify-content-center ${style.channelContentHeader}`}
          >
            <div>
              <Img
                src={avatar}
                alt="avatar"
                className={`rounded ${style.channelContentHeaderImage}`}
                style={{
                  marginTop: "4px",
                }}
              />
            </div>
            <div className="ml-1">
              <div className={`${style.namePage}`}>
                {name && name.slice(0, 30)}
                {name && name.length > 30 && "..."}
                {verificationStatus && (
                  <span>
                    {" "}
                    <Img
                      alt=""
                      className={style.imgBlueVerification}
                      src={BLUE_VERIFIED_ICON}
                    ></Img>
                  </span>
                )}
              </div>
              <div className={`${style.timePage}`}>
                {moment(createdAt).format("HH:mm DD/MM/YYYY")}
              </div>
            </div>
          </div>
          <div
            id={`tooltip-${idContent}`}
            className={`${style.posted} jsClonePost`}
            data-object={JSON.stringify(data)}
            onClick={handleClickReUpPost}
          >
            {!isSelectedSchedule && (
              <Img
                src={PurplFolderSVG}
                alt={PurplFolderSVG}
                className={`rounded ${style.channelContentHeaderImage} ${style.colorSVG} ${style.imgUpload} mb-1`}
              />
            )}

            <div
              className={`${style.textHover} ml-2`}
              style={{ pointerEvents: isSelectedSchedule ? "none" : undefined }}
            >
              {isSelectedSchedule && extensionStatusExisted
                ? "Đã chọn đăng"
                : "Lên lịch"}
            </div>
             <Tooltip
             className="compeTooltip"
              style={{backgroundColor:"#ffffff",
               color:"black",
               boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
              maxWidth:"300px"}}
              placement="bottom"
              isOpen={onHoverPage}
              target={`tooltip-${idContent}`}
              toggle={handleHoverPage}
            ><div className="" 
              style={{flexDirection:"column", 
              padding:"5px", 
              textAlign:"left"}}>
               <p style={{fontSize:"16px", marginBottom:"4px",fontWeight:"500"}}>Đăng lại bài đăng</p>
              <span style={{fontSize:"14px"}}>vui lòng bấm Tải lại trang (F5) và Cuộn chuột lên xuống 1 lần trong danh sách 
              nội dung của kênh để bắt đầu sử dụng.
              </span>
            </div>
             
            </Tooltip>
          </div>
        </div>
        <span
          className={`mt-3 ${style.channelContentText}`}
          style={{ maxWidth: "450px", marginBottom: "12px", cursor: "pointer" }}
          onClick={onClickContent}
        >
          {content}
        </span>
        <div className={`position-relative cursor-pointer text-center`}>
          {Image ? (
            <Img
              src={`${Image}`}
              alt="avatar"
              className={`rounded ${style.sidebarImageWrapperImage}`}
              style={{ minWidth: "250px", maxHeight: "400px" }}
            />
          ) : (
            <div className={`${style.emptyImage}`}>
              <Img
                src={`${NO_IMAGE}`}
                alt="avatar"
                className={`rounded ${style.sidebarImageWrapperImage}`}
                style={{
                  minWidth: "72px",
                  maxHeight: "83px",
                }}
              />
            </div>
          )}

          {data?.attachments?.data[0]?.type === MediaType.VIDEO && (
            <div
              className={`${style.videoType} d-flex align-items-center justify-content-around`}
            >
              <Img
                src={`${PLAY_ICON}`}
                alt="avatar"
                className={`rounded ${style.sidebarImageWrapperImage}`}
                style={{
                  minWidth: "72px",
                  maxHeight: "83px",
                }}
              />
            </div>
          )}

          {isHover === idContent ? (
            <div
              className={`${style.stateResearch} d-flex align-items-center justify-content-around`}
              onClick={handleUpdatingFeature}
            >
              <div
                className={`d-flex align-items-center  flex-column justify-content-center`}
              >
                <Img
                  src={EditSVG}
                  alt={EditSVG}
                  className={`rounded ${style.channelContentHeaderImage} ${style.imgHoverToReUp} text-white mb-1`}
                />
                <div className={`${style.textHover} text-white`}>Soạn thảo</div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="d-flex justify-between mt-3">
          <div className="d-flex">
            <div className="flex-grow-1 flex-shrink-1 d-flex align-items-center mr-2">
              <Img className={`${style.iconStyle}`} src={`${LIKE_ICON}`}></Img>
              <span className="fs-15px"> {numberOfLikes}</span>
            </div>
            <div
              className="flex-grow-1 flex-shrink-1 d-flex align-items-center  mr-2"
              onClick={handleClickComment}
              style={{ cursor: "pointer" }}
            >
              <Img
                className={`${style.iconStyle}`}
                src={`${COMMENT_ICON}`}
              ></Img>{" "}
              <span className="fs-15px">{numberOfComments}</span>
            </div>
            <div className="flex-grow-1 flex-shrink-1 d-flex align-items-center">
              <Img className={`${style.iconStyle}`} src={`${SHARE_ICON}`}></Img>{" "}
              <span className="fs-15px">{numberOfShares}</span>
            </div>
          </div>
          <div className="d-flex">
            <div
              className="flex-grow-1 flex-shrink-1 d-flex align-items-center"
              onClick={handleClickComment}
              style={{ cursor: "pointer" }}
            >
              <Img
                className={`${style.iconStyle}`}
                src={`${COMMENT_ICON}`}
              ></Img>{" "}
              <span className="fs-15px">Bình luận</span>
            </div>
          </div>
        </div>
        {isComment && idComment === idContent && (
          <>
            <div className="mt-3 w-100">
              <>
                <div className="d-flex">
                  <Img
                    src={CURRENT_PAGE_IMG}
                    alt="avatar"
                    className={`rounded ${style.channelContentHeaderImage} mr-2`}
                  />
                  <input
                    value={loadingCommentValue}
                    onChange={(e) => setTextComment(e.target.value)}
                    placeholder={commentPlaceholder}
                    className={style.column__commentPost}
                    onKeyDown={handleKeyDown}
                    disabled={loadingComment}
                    style={
                      loadingComment
                        ? { backgroundColor: "#F3F4F6", color: "#E0E0E0" }
                        : {}
                    }
                  />
                  {iconLoadingComment}
                </div>
              </>
            </div>
            {data.seeding_comments.filter(
              (cmt) =>
                cmt.comment_with_channel_id === formSeeding.user_seeding._id
            ).length > 0 &&
              data.seeding_comments
                .filter(
                  (cmt) =>
                    cmt.comment_with_channel_id === formSeeding.user_seeding._id
                )
                .map((comment, index) => (
                  <div className="mt-3 w-100">
                    <>
                      <div className="d-flex">
                        <Img
                          src={CURRENT_PAGE_IMG}
                          alt="avatar"
                          className={`rounded ${style.channelContentHeaderImage} mr-2`}
                        />
                        <div
                          style={
                            index === 0 && loadingComment
                              ? { fontSize: "12px", color: "#9CA3AF" }
                              : {}
                          }
                        >
                          <div style={{ fontWeight: "bold" }}>
                            {formSeeding.user_seeding.name}
                          </div>
                          <div>
                            {isCommentExpanded.includes(index) ||
                              comment.message.length < 100
                              ? comment.message
                              : comment.message.slice(0, 100) + "..."}
                            {!isCommentExpanded.includes(index) &&
                              comment.message.length > 100 && (
                                <a
                                  style={{
                                    cursor: "pointer",
                                    color: "#0EA5E9",
                                  }}
                                  onClick={handleExpandComment}
                                >
                                  {" "}
                                  Xem thêm
                                </a>
                              )}{" "}
                          </div>
                          <div style={{ fontSize: "10px" }}>
                            {renderCommentCreatedDate()}
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                ))}
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default ContentElement;
