import { createSlice } from "@reduxjs/toolkit";
  
  export const guildeBookSlice = createSlice({
    name: "guildeBook",
    initialState : {
        isloading : false,
        data : null,
    },
    reducers: {
      setIsLoading(state, action) {
        state.isloading = action.payload;
      },
      setData(state,action) {
        state.data = action.payload;
      },
    },
  });
  
  export const { setIsLoading , setData} = guildeBookSlice.actions;
  
  export default guildeBookSlice.reducer;