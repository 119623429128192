import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input, Modal, ModalBody, ModalHeader, Spinner, Tooltip } from "reactstrap";
import style from "../index.module.scss"
import { toast } from "react-toastify";
import { Img } from "../../../../components/Component";
import iconSpin from "../../../images/icons/post-fast-spin.svg";
import iconAi from "../../../images/icons/post-fast-ai.svg";
import iconCoppy from "../../../images/icons/icon-coppy.svg";
import { fetchUserInfoLogin } from "../../../../libs/helper";
import { generateAiContent } from "../../../../api/createPost";
import { RiCloseFill } from "@remixicon/react";


const AiModal = ({ show, toggle, handleSelectAiContent }) => {

    const { t } = useTranslation();
    const userInfor = fetchUserInfoLogin();

    const [loadingAi, setLoadingAi] = useState(false);
    const [aiCopyOpen, setAiCopyOpen] = useState(false);
    const [aiFormData, setAiFormData] = useState(
        {
            content: "",
            system: "",
        });


    const onClose = () => {
        toggle();
        setAiFormData({
            system: "",
            content: ""
        });
    }

    const onChangeAiForm = (e) => {
        setAiFormData({ ...aiFormData, content: e.target.value })
    }

    const handleAiCreate = () => {
        if (aiFormData.content === "") {
            return;
        }
        setLoadingAi(true);

        const projectId = userInfor.last_project_active;
        let data = [];
        if (projectId) {
            let data = {
                project_id: projectId,
                messages: [
                    {
                        role: "user",
                        content: aiFormData.content
                    }
                ]
            };
            if (aiFormData.system !== "") {
                data.messages.push({
                    role: "system",
                    content: aiFormData.system
                });
            }

            generateAiContent(data)
                .then((res) => {
                    if (res.status) {
                        setAiFormData({ ...aiFormData, system: res.data.messages.join(" ") });
                        setLoadingAi(false);
                    } else {
                        toast.error("Có lỗi xảy ra! Vui lòng thử lại sau ít phút");
                        setLoadingAi(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setLoadingAi(false);
                    toast.error("Có lỗi xảy ra! Vui lòng thử lại sau ít phút");
                });
        }
    }

    const handlRemoveAiContent = (e) => {
        e.stopPropagation();
        setAiFormData({
            system: "",
            content: ""
        });
    }

    const toggleAi = () => {
        navigator.clipboard.writeText(aiFormData.system);
        setAiCopyOpen(true);
        setTimeout(() => setAiCopyOpen(false), 3000);
    };



    return (
        <Modal isOpen={show} toggle={onClose} centered className="modal-md">
            <ModalHeader toggle={onClose}>
                <div className={style.aiItems} style={{ margin: "0" }} >
                    <Img
                        src={iconAi}
                        style={{ height: "24px" }}
                    />
                    <span>AI Assistant</span>

                </div>
            </ModalHeader>
            <ModalBody>
                {aiFormData.system === "" ?
                    (<>
                        <div className={style.AssitantConatiner}>
                            <div className={style.AssitantdArea}>
                                <div className={style.assitantQuestions}>
                                    <p className="mb-2">What do you want to write about?</p>
                                    <div style={{
                                        border: "1px solid #E5E7EB",
                                        borderRadius: "4px",
                                        overflow: "hidden"
                                    }}>
                                        <Input
                                            type="textarea"
                                            style={{ resize: "none", minHeight: "210px" }}
                                            value={aiFormData.content}
                                            onChange={(e) => onChangeAiForm(e)}
                                            placeholder="Eg. Promote my photography course to get new signups. Registration closes in 3 days."
                                        />
                                    </div>
                                    <div className={style.assitantFooter}>
                                        <p className="my-2" >
                                            <span className="fw-bold">Pro tip:</span>
                                            <span className="ms-1">Include key points, your target audience and your desired outcome for this post.</span>
                                        </p>
                                    </div>
                                    {true && (<div className="d-flex justify-content-end" style={{ marginTop: "24px" }}>
                                        <Button onClick={handleAiCreate}
                                            disabled={aiFormData.content === ""}
                                            className={style.aiCreateButton}>
                                            {loadingAi ? (
                                                <>
                                                    <Spinner size={"sm"} className="me-1"></Spinner>
                                                    Loading
                                                </>
                                            ) : (
                                                <>
                                                    <i className="me-1"></i>
                                                    Generate
                                                </>
                                            )}</Button>
                                    </div>)}
                                </div>

                            </div>
                        </div>
                    </>) : (<>
                        <div className={style.AssitantConatiner}>
                            <div className={style.AssitantdArea}>

                                <div className={style.assitantQuestions}>
                                    <p className="mb-2">What do you want to write about?</p>
                                    <div style={{
                                        border: "1px solid #E5E7EB",
                                        borderRadius: "4px",
                                        overflow: "hidden"
                                    }}>
                                        <div className="d-flex align-items-start justify-content-between bg-light p-2 border">
                                            <span className="text-break">{aiFormData.content}</span>
                                            <RiCloseFill size={16} className="cursor-pointer" onClick={handlRemoveAiContent}></RiCloseFill  >
                                        </div>

                                    </div>
                                    
                                    {!loadingAi ?
                                        (
                                            <div className="d-flex flex-column justify-content-between p-2 my-2 bg-primary-subtle border rounded" style={{minHeight:"210px"}}>
                                                <span>{aiFormData.system}</span>
                                                <div className="d-flex justify-content-between">
                                                    <div style={{ cursor: "pointer" }}
                                                        className="d-flex justify-content-center align-items-center">
                                                        <Img onClick={toggleAi} id="aiCopy" src={iconCoppy} height="24px" width="24px" />
                                                        <Tooltip
                                                            autohide={false}
                                                            placement="top"
                                                            isOpen={aiCopyOpen}
                                                            target="aiCopy"
                                                            trigger="click"
                                                        >
                                                            copied
                                                        </Tooltip>
                                                    </div>

                                                    <div style={{ gap: "36px" }} className="d-flex justify-content-end">
                                                        <div style={{ gap: "8px", cursor: "pointer" }}
                                                            onClick={handleAiCreate}
                                                            className="d-flex justify-content-center align-items-center">
                                                            <Img className={style.aiPreviewSpin} src={iconSpin} height="24px" width="24px" />
                                                            <span style={{ fontWeight: "600", color: "#374151" }}>Retry</span>
                                                        </div>
                                                        <Button onClick={()=>{handleSelectAiContent(aiFormData.system); onClose();}}>Insert</Button>
                                                    </div>
                                                </div>
                                            </div>) :(
                                                <div className="d-flex justify-content-center align-items-center" style={{minHeight:"210px"}}>
                                                    <Spinner></Spinner>
                                                </div>
                                            )}
                                </div>
                                
                            </div>
                        </div>

                    </>)
                }
            </ModalBody>

        </Modal>
    )

}

export default AiModal;