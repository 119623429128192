import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";

import { getUserInfoInLocalStorage } from "../../helper";

import OverviewContent from "./OverviewContent";
import {
  getContentGeneralTiktok,
  getLogFetchTT,
  getContentTopPost,
  getContentStatistic,
} from "../../../../../api/dashboard";
import ContentStatisticTable from "../CommonItem/ContentStatisticTable";
import { TopPostColumn } from "../CommonItem/TopPostColumn";
import defaultChannelIcon from "../Icon/tiktok.svg";
import { LogHeader } from "../../CommonItem/LogHeader";
import { PLATFORM_NAME } from "../../constants";
import Content from "../../../../../layout/content/Content";
import { Block } from "../../../../../components/Component";
const TiktokStaff = ({
  channels,
  range,
  staffs,
  setLoadingStaffFilter,
  setLoadingChannelFilter,
  setLoadingRange,
  from,
  to,
}) => {
  const { t } = useTranslation();
  const userInfor = getUserInfoInLocalStorage();
  const projectId = userInfor.last_project_active;

  const [logTTData, setLogTTData] = useState({});
  const [contentGeneralData, setContentGeneralData] = useState({});
  const [isLoadingGeneralTiktok, setIsLoadingGeneralTiktok] = useState(true);
  const [contentTopPostData, setContentTopPostData] = useState([]);
  const [isLoadingContentTopPost, setIsLoadingContentTopPost] = useState(true);

  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [selectedOption, setSelectedOption] = useState({
    value: "view_count",
    label: "View",
  });
  const [sortType, setSortType] = useState({ value: "desc", label: "Desc." });
  const [tableLoading, setTableLoading] = useState(false);

  const [tooltipTableOpen, setTableToolTipOpen] = useState([
    { isOpen: false, label: "" },
    { isOpen: false, label: "Lượt xem video" },
    { isOpen: false, label: "Số người xem video" },
    { isOpen: false, label: "Lượt Thích video" },
    { isOpen: false, label: "Số Bình luận vào video" },
    { isOpen: false, label: "Lượt Chia sẻ video" },
    { isOpen: false, label: "Thời gian xem trung bình video" },
    { isOpen: false, label: "%Full Watch Rate: Tỷ lệ % video được xem hết" },
  ]);
  const sortTikTokOptions = [
    { value: "view_count", label: "View" },
    { value: "share_count", label: "Share" },
    { value: "date", label: "Date" },
    { value: "like_count", label: "Like" },
    { value: "comment_count", label: "Coment" },
    { value: "average_time_watched", label: "Average time watched" },
    { value: "full_video_watched_rate", label: "Full video watched" },
  ];
  const headerUserStatistic = {
    content: t("content"),
    view_count: t("views"),
    reach: t("channel_analytics_table.reach"),
    like_count: t("channel_analytics_table.like"),
    comment_count: t("comment"),
    share_count: t("share"),
    average_time_watched: t("avg_watch_time"),
    full_video_watched_rate: t("full_video_watched_rate"),
  };

  // const handleOpenPost = (e) => {
  //   e.stopPropagation();
  // };
  const getAPITiktok = (data) => {
    setLoadingStaffFilter(true);
    setIsLoadingGeneralTiktok(true);
    setIsLoadingContentTopPost(true);
    Promise.allSettled([
      getLogFetchTT(data),
      getContentGeneralTiktok(data),
      getContentTopPost({ data: data, platform: "tiktok" }),
    ]).then((responses) => {
      // Destructure the responses to get the data
      const [logTTRes, contentGeneralRes, contentTopPostData] = responses;

      if (logTTRes.status === "fulfilled") {
        const logTT = logTTRes.value.data;
        setLogTTData(logTT);
      }
      if (contentGeneralRes.status === "fulfilled") {
        const contentGeneral = contentGeneralRes.value.data;

        setContentGeneralData(contentGeneral);
      }
      if (contentTopPostData.status === "fulfilled") {
        const contentTopPost = contentTopPostData.value.data;
        setContentTopPostData(contentTopPost);
      }
      setIsLoadingGeneralTiktok(false);
      setLoadingStaffFilter(false);
      setLoadingChannelFilter(false);
      setLoadingRange(false);
      setIsLoadingContentTopPost(false);
    });
  };
  const getdataTable = async (data) => {
    if (!tableLoading) {
      setTableLoading(true);
      try {
        const dataRes = await getContentStatistic(data, "tiktok");
        if (dataRes) {
          const dataFb = dataRes.data;
          setData(dataFb);
          setListData(
            dataFb.data.map((item) => ({
              content: item?.post_info.title,
              view_count: item?.view_count,
              reach: item?.reach,
              like_count: item?.like_count,
              comment_count: item?.comment_count,
              share_count: item?.share_count,
              average_time_watched: item?.average_time_watched,
              full_video_watched_rate: item?.full_video_watched_rate,
              channel: item?.channel,
              date: item?.date,
              media: item?.media,
            }))
          );

          setTableLoading(false);
        }
      } catch (error) {
        console.log("errTableFB", error);
        setTableLoading(false);
      }
    }
  };
  useEffect(() => {
    const data = {
      params: {
        project_id: projectId,
        // time: `${range} days`,
        channel_ids: channels,
        user_ids: staffs,
        from,
        to,
      },
    };
    getAPITiktok(data);

    // if (currentPage === 1) {
    //   const data = {
    //     project_id: projectId,
    //     page: currentPage,
    //     user_ids: staffs,
    //     channel_ids: channels,
    //     order_by: selectedOption.value,
    //     arrange: sortType.value,
    //     from,
    //     to,
    //   };
    //   getdataTable(data);
    // } else {
    //   setCurrentPage(1);
    // }
  }, [range, channels, staffs, from, to]);

  useEffect(() => {
    const data = {
      params: {
        project_id: projectId,
        page: currentPage,
        user_ids: staffs,
        channel_ids: channels,
        order_by: selectedOption.value,
        arrange: sortType.value,
        from,
        to,
      },
    };
    getdataTable(data);
  }, [
    currentPage,
    selectedOption.value,
    sortType.value,
    channels,
    staffs,
    from,
    to,
  ]);

  return (
    <>
      <Col lg={12} className="px-8">
        <LogHeader
          cnt_channel_error={logTTData?.cnt_channel_error}
          cnt_channel_success={logTTData?.cnt_channel_success}
          last_time={logTTData?.last_time}
          currentTab={PLATFORM_NAME.TIKTOK}
        />
      </Col>
      <div style={{ padding: "18px 24px" }}>
        {/* <Block> */}
        <Row className="px-3">
          <OverviewContent
            isLoading={isLoadingGeneralTiktok}
            data={contentGeneralData}
          />
        </Row>
        {/* </Block> */}
        {/* <Block> */}
        <Row className="" style={{ paddingTop: "20px" }}>
          <TopPostColumn
            data={contentTopPostData}
            isLoading={isLoadingContentTopPost}
            platform="tiktok"
          />
        </Row>
        {/* </Block> */}
        {/* <Block> */}
        <Col style={{ paddingTop: "10px" }}>
          <ContentStatisticTable
            listData={listData}
            sortTypeOptions={sortTikTokOptions}
            headerContentStatistic={headerUserStatistic}
            tooltipTableOpen={tooltipTableOpen}
            setTableToolTipOpen={setTableToolTipOpen}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            tableLoading={tableLoading}
            data={data}
            setCurrentPage={setCurrentPage}
            sortType={sortType}
            setSortType={setSortType}
            defaultChannelIcon={defaultChannelIcon}
          />
        </Col>
        {/* </Block> */}
      </div>
    </>
  );
};
export default TiktokStaff;
