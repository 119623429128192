import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";

import AgeStatistic from "../../Chart/AgeChart";
import { GenderStatistic } from "../../Chart/GenderStatistic";
import { TopCity } from "../../Chart/TopCity";
import { LoadingItem } from "../../CommonItem/LoadingItem";

const Audience = ({ data, isLoading }) => {
  const { t } = useTranslation();

  return (
    <Row style={{ paddingTop: "10px" }}>
      <Col xs={12} md={4}>
        <Card>
          <CardHeader className="bg-slate-50 align-items-center d-flex">
            <h4
              className="card-title mb-0 flex-grow-1"
              style={{ fontSize: "16px" }}
            >
              {t("gender")}
            </h4>
          </CardHeader>
          <CardBody>
            {isLoading ? (
              <LoadingItem />
            ) : (
              <GenderStatistic
                data={data?.gender}
                dataColors='["--vz-primary", "--vz-light"]'
              />
            )}
          </CardBody>
        </Card>
      </Col>
      <Col xs={12} md={4}>
        <Card>
          <CardHeader className="bg-slate-50 align-items-center d-flex">
            <h4
              className="card-title mb-0 flex-grow-1"
              style={{ fontSize: "16px" }}
            >
              {t("age")}
            </h4>
          </CardHeader>
          <CardBody>
            {isLoading ? (
              <LoadingItem />
            ) : (
              <GenderStatistic
                data={data?.age}
                // isYoutube={true}
                //  isLoading={isLoading}
                dataColors='["--vz-primary", "--vz-light"]'
              />
            )}
          </CardBody>
        </Card>
      </Col>

      <Col sm={12} md={4}>
        <TopCity
          data={data?.country || []}
          isLoading={isLoading}
          height="341"
        />
      </Col>
    </Row>
  );
};

export default Audience;
