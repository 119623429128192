import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import style from "./index.module.scss";
import ImgHero from "../../images/strategy/hero.png";
import LogoYt from "../../images/dashboard/icon/type=youtube.png";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PaginationCustom,
  RSelect,
  Img
} from "../../../components/Component";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Row,
  Spinner,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import Container from "./Container";
import ModalAdd from "./ModalAdd";
import { getListCampaign } from "../../../api/campaign";
import { useQuery } from "react-query";
import ModalSuccess from "./ModalSuccess";
import { useForm } from "react-hook-form";

const optionTopic = [
  { value: "", label: "Tất cả" },
  { value: "1", label: "Chủ đề 1" },
  { value: "2", label: "Chủ đề 2" },
];

const optionMediaType = [
  { value: "", label: "Tất cả" },
  {
    label: "Ảnh",
    value: "photo",
  },
  {
    label: "Video",
    value: "video",
  },
  {
    label: "Video Ngắn",
    value: "reel",
  },
];

const optionStatus = [
  { value: "", label: "Tất cả" },
  { value: 0, label: "Inactive" },
  { value: 1, label: "Active" },
];
const Campaign = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const [message, setMessage] = useState("");
  const [filter, setFilter] = useState({ type: "", status: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [modal, setModal] = useState({
    edit: false,
    add: false,
    success: false,
  });
  const loadData = async () => {
    return await getListCampaign(userInfo.last_project_active, {
      params: {
        page: currentPage,
        status: filter.status,
        type: filter.type,
      },
    });
  };

  let { data, isLoading, refetch } = useQuery(
    [currentPage, userInfo.last_project_active, filter],
    loadData,
    {
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const toggleModal = (type) => {
    setModal((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };
  const {
    handleSubmit,
    setValue,
  } = useForm();
  const onFilterSubmit = (data) => {
    setFilter({ type: data.type, status: data.status });
  };

  return (
    <React.Fragment>
      <Head title="Chiến dịch" />
      <div style={{ height: "75px" }}></div>
      <div className={`${style.notification}`}>
        <Row className="p-2 px-md-1 py-md-2 mx-0">
          <Col md="8" sm="12">
            <Form className="gy-2" onSubmit={handleSubmit(onFilterSubmit)}>
              <Row>
                <Col md="3" xs="6" className="mb-2 px-1 mb-md-0">
                  <div className="form-wrap">
                    <RSelect
                      options={optionTopic}
                      placeholder="Chủ đề"
                      onChange={(e) => setValue("topic", e.value)}
                    />
                  </div>
                </Col>
                <Col md="3" xs="6" className="mb-2 px-1 mb-md-0">
                  <div className="form-wrap">
                    <RSelect
                      options={optionMediaType}
                      placeholder="Loại bài đăng"
                      onChange={(e) => setValue("type", e.value)}
                    />
                  </div>
                </Col>
                <Col md="3" xs="9" className="mb-2 px-1 mb-md-0">
                  <div className="form-wrap">
                    <RSelect
                      options={optionStatus}
                      placeholder="Trạng thái"
                      onChange={(e) => setValue("status", e.value)}
                    />
                  </div>
                </Col>
                <Col
                  md="3"
                  xs="3"
                  className="d-flex justify-content-end d-md-block  mb-2 px-1 mb-md-0"
                >
                  <Button style={{ background: "#1D3565" }}>
                    <span>LỌC</span>
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col md="4" sm="12">
            <div className="d-flex">
              <input
                type="text"
                className="form-control border-transparent form-focus-none mr-2"
                placeholder="Tìm nội dung, mã bài đăng "
              // value={onSearchText}
              // onChange={(e) => onFilterChange(e)}
              />
              <Button style={{ background: "#1D3565" }}>
                <span>TÌM KIẾM</span>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <div className="my-4 mx-3">
        <BlockHead size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                {data?.data?.campaigns?.data?.length > 0
                  ? `Chiến dịch đang chạy: ${data?.data?.count_active}`
                  : "Quản lý chiến dịch tự động đăng"}
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                {/* <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${
                    sm ? "active" : ""
                  }`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button> */}
                <div
                  className="toggle-expand-content"
                //   style={{ display: sm ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt" id="video">
                      <a
                        href="/"
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          borderRadius: "100%",
                          background: "white",
                          width: "2.2rem",
                          height: "2.2rem",
                          color: "white",
                          pointerEvents: "none",
                        }}
                      >
                        <Img
                          src={LogoYt}
                          alt="yt"
                          style={{ width: "1.5rem" }}
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <UncontrolledTooltip
                      autohide={false}
                      placement={"top"}
                      target={"video"}
                    >
                      Đang phát triển
                    </UncontrolledTooltip>

                    <li className="nk-block-tools-opt">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="dropdown-toggle btn btn-white btn-outline-light d-flex justify-content-center"
                          style={{
                            borderRadius: "100%",
                            background: "#45BEF4",
                            width: "2rem",

                            color: "white",
                          }}
                        >
                          <Icon
                            className="d-none d-sm-inline"
                            name="plus"
                          ></Icon>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem onClick={() => toggleModal("add")}>
                            Tạo mới
                          </DropdownItem>
                          <DropdownItem disabled>Tạo hàng loạt</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>
        <Block>
          {isLoading ? (
            <div className="d-flex justify-content-center w-100">
              <Spinner />
            </div>
          ) : data?.data?.campaigns?.data?.length > 0 ? (
            <div>
              <Container
                data={data?.data?.campaigns?.data}
                modal={modal}
                toggleModal={toggleModal}
                refetch={refetch}
                loading={isLoading}
                setMessage={setMessage}
                toggleSuccess={() => toggleModal("success")}
              />
              <div className={`mt-3 text-right`}>
                <PaginationCustom
                  pageSize={20}
                  totalCount={data?.data?.campaigns?.total || 0}
                  siblingCount={1}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                  className={`manage_post_pagination`}
                />
              </div>
            </div>
          ) : (
            <>
              <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{ color: "#000" }}
              >
                <Img
                  src={ImgHero}
                  alt="hero"
                  style={{ width: "20%", marginTop: "3rem" }}
                />
                <div className="text-center mt-3">
                  <p style={{ fontWeight: "600" }}>
                    <b>BẠN CHƯA CÓ CHIẾN DỊCH NÀO</b>
                  </p>
                  <p>
                    Các chiến dịch sẽ giúp bạn tạo các kịch bản đăng bài phù hợp
                    theo chiến lược mà bạn đề ra ở mỗi kênh.
                  </p>
                </div>
                <div>
                  <Card className="text-start mt-4">
                    <CardBody className="card-inner">
                      <CardTitle>
                        <b>Video hướng dẫn</b>
                      </CardTitle>
                      <Row>
                        <Col md="12">
                          <div className="ratio">
                            <iframe
                              style={{ width: "100%" }}
                              title="Video hướng dẫn"
                              src="https://www.youtube.com/embed/kQUstHl4xi8?list=PL5TqJiJIECYwF_sNFwFvjsqVmebuG5qVK"
                              allowFullscreen
                            ></iframe>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </>
          )}
        </Block>
      </div>
      <ModalAdd
        modal={modal.add}
        toggle={() => toggleModal("add")}
        toggleSuccess={() => toggleModal("success")}
        setMessage={setMessage}
        refetch={refetch}
      />
      <ModalSuccess
        modal={modal.success}
        message={message}
        toggle={() => toggleModal("success")}
      />
    </React.Fragment>
  );
};

export default Campaign;
