import { groupBy } from "lodash";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Col } from "reactstrap";

import getChartColorsArray from "../helper";
import { LoadingItem } from "../CommonItem/LoadingItem";
import { numberWithCommas } from "../../DashboardNew/utils/utils";
const handleDataChart = (data) => {
  const dataGroup = groupBy(data, "y");
  const result = [];

  for (const [key, value] of Object.entries(dataGroup)) {
    const d = {
      name: key,
      data: value.map((e) => ({ x: e.x, y: e.value })),
    };
    result.push(d);
  }

  const arrSorted = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

  return arrSorted.map((e) => ({
    name: e,
    data:
      dataGroup[e]?.map((e) => ({
        x: e?.x,
        y: numberWithCommas(e?.value),
      })) || [],
  }));

  return result;
};

export const FanOnlineChart = ({ series, isLoading }) => {
  const { t } = useTranslation();
  // const chartHeatMapBasicColors = getChartColorsArray(dataColors);
  const chartHeatMapColors = getChartColorsArray(
    '["#45CB85", "#ffbe0b", "#4b38b3", "#f06548"]'
  );

  const data = handleDataChart(series?.data || []);
  const arrayValue = series?.data?.map((d) => d?.value) || [0];
  const maxValue = Math.max(...arrayValue);

  // const colors = series?.data?.map((d: any) => d.color);

  series = [];
  const options = {
    xaxis: {
      tickAmount: 23,
    },
    chart: {
      height: 400,
      type: "heatmap",
      offsetX: 0,
      offsetY: -8,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      horizontalAlign: "center",
      offsetX: 0,
      offsetY: 20,
      markers: {
        width: 20,
        height: 6,
        radius: 2,
      },
      itemMargin: {
        horizontal: 12,
        vertical: 0,
      },
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        radius: 0,
        useFillColorAsStroke: true,
        distributed: true,
        grid: {
          padding: {
            right: 20,
          },
        },
        colorScale: {
          ranges: [
            {
              from: 0,
              to: +Number(maxValue * 0.25).toFixed(),
              name: "Low",
              color: chartHeatMapColors[0],
              strokeColor: "#000000", // Specify the stroke color
              strokeWidth: 2, // Specify the stroke width
            },
            {
              from: +Number(maxValue * 0.25).toFixed() + 1,
              to: +Number(maxValue * 0.5).toFixed(),
              name: "Medium",
              color: chartHeatMapColors[1],
              strokeColor: "#000000", // Specify the stroke color
              strokeWidth: 2, // Specify the stroke width
            },
            {
              from: +Number(maxValue * 0.5).toFixed() + 1,
              to: +Number(maxValue * 0.75).toFixed(),
              name: "High",
              color: chartHeatMapColors[2],
              strokeColor: "#000000", // Specify the stroke color
              strokeWidth: 2, // Specify the stroke width
            },
            {
              from: +Number(maxValue * 0.75).toFixed() + 1,
              to: maxValue + 1,
              name: "Extreme",
              color: chartHeatMapColors[3],
              strokeColor: "#000000", // Specify the stroke color
              strokeWidth: 2, // Specify the stroke width
            },
          ],
        },
      },
    },
    tooltip: {
      y: [
        {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0);
            }
            return y;
          },
        },
      ],
    },
    noData: {
      text: isLoading ? "Loading..." : "No Data present in the graph!",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#000000",
        fontSize: "14px",
        fontFamily: "Helvetica",
      },
    },
  };
  return (
    <>
      <Col xxl={6} xl={6}>
        <Card>
          <CardHeader className="align-items-center d-flex bg-slate-50">
            <h4
              className="card-title mb-0 flex-grow-1"
              style={{ fontSize: "16px" }}
            >
              {series.label || t("page_fans_online")}
            </h4>
          </CardHeader>
          <CardBody>
            {isLoading ? (
              <LoadingItem />
            ) : (
              <ReactApexChart
                dir="ltr"
                options={options}
                series={data}
                type="heatmap"
                height="400"
                className="apex-charts"
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};
