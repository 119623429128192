import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import {
  VictoryArea,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryTheme,
} from "victory";
import style from "../../../index.module.scss";
import { formatNumber } from "../../../../../../utils/Utils";
import ChartPine from "../ChartPine";

const Access = ({ data }) => {
  const dataSample3 = [
    { x: 0, y: 0 },
    { x: 250, y: Math.floor(Math.random() * 9) + 1 },
    { x: 500, y: Math.floor(Math.random() * 9) + 1 },
    { x: 750, y: Math.floor(Math.random() * 9) + 1 },
    { x: 1000, y: Math.floor(Math.random() * 9) + 1 },
  ];
  const dataSample4 = [
    { x: 0, y: 0 },
    { x: 250, y: Math.floor(Math.random() * 9) + 1 },
    { x: 500, y: Math.floor(Math.random() * 9) + 1 },
    { x: 750, y: Math.floor(Math.random() * 9) + 1 },
    { x: 1000, y: Math.floor(Math.random() * 9) + 1 },
  ];

  const [rateImpressionUniqueData, setRateImpressionUniqueData] = useState([]);
  const [rateImpressionData, setRateImpressionData] = useState([]);
  const [rateImpressionViralUniqueData, setRateImpressionViralUniqueData] =
    useState([]);
  const [rateImpressionViralData, setRateImpressionViralData] = useState([]);
  const [impresssionData, setImpresssionData] = useState([]);
  const [impresssionUniqueData, setImpresssionUniqueData] = useState([]);
  const [totalImpersion, setTotalImpresion] = useState(0);
  const [totalImpersionUnique, setTotalImpresionUnique] = useState(0);

  useEffect(() => {
    setRateImpressionUniqueData(data?.rate_impression_unique);
    setRateImpressionData(data?.rate_impression);
    setRateImpressionViralUniqueData(data?.rate_impression_viral_unique);
    setRateImpressionViralData(data?.rate_impression_viral);
    setImpresssionData(
      data?.impression?.map((item) => {
        const dateObj = new Date(item.x);
        const shortYear = dateObj.getFullYear().toString().slice(2);
        const month = (dateObj.getMonth() + 1).toString();
        const day = dateObj.getDate().toString();
        const formattedDate = `${day}/${month}`;
        return { x: formattedDate, y: item.y };
      })
    );
    setImpresssionUniqueData(
      data?.impression_unique?.map((item) => {
        const dateObj = new Date(item.x);
        const shortYear = dateObj.getFullYear().toString().slice(2);
        const month = (dateObj.getMonth() + 1).toString();
        const day = dateObj.getDate().toString();
        const formattedDate = `${day}/${month}`;
        return { x: formattedDate, y: item.y };
      })
    );
    setTotalImpresion(
      data?.impression?.reduce((total, item) => total + item.y, 0)
    );
    setTotalImpresionUnique(
      data?.impression_unique?.reduce((total, item) => total + item.y, 0)
    );
  }, [data]);

  const lableX = impresssionUniqueData?.map((item) => {
    return item.x;
  });

  const getCustomTickLabel = (tick, index) => {
    if (lableX?.length <= 15) {
      // Hiển thị toàn bộ label khi số lượng label ít hơn hoặc bằng 15
      return tick;
    } else {
      // Hiển thị label của phần tử đầu, giữa và cuối
      if (
        index === 0 ||
        index === Math.floor(lableX?.length / 2) ||
        index === lableX?.length - 1
      ) {
        return tick;
      } else {
        return "";
      }
    }
  };

  const maxImpressY = impresssionData?.reduce((maxY, item) => {
    return item.y > maxY ? item.y : maxY;
  }, 0);

  const maxImpressUniY = impresssionUniqueData?.reduce((maxY, item) => {
    return item.y > maxY ? item.y : maxY;
  }, 0);

  return (
    <>
      <Row>
        <Col xs={12} md={6} className="mb-4 mb-md-0">
          <Card className="h-100 ">
            <CardBody className="card-inner w-100">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Lượt tiếp cận & Người tiếp cận
              </CardTitle>
              <div className="w-100">
                <VictoryChart
                  width={800}
                  height={350}
                  domain={{ y: [0, 1.05] }}
                  padding={{ top: 5, left: 50, right: 50, bottom: 40 }}
                >
                  <VictoryBar
                    data={impresssionData}
                    style={{
                      data: {
                        fill: "#2C4B94",
                      },
                    }}
                    y={(datum) => datum.y / maxImpressY}
                  />
                  <VictoryAxis
                    dependentAxis={true}
                    tickValues={[0.25, 0.5, 0.75, 1]}
                    tickFormat={(t) => (t * maxImpressY).toFixed(0)}
                    // tickValues={[0, 250, 500, 750, 1000]}
                    style={{
                      axis: {
                        stroke: "#2C4B94",
                        // transform: "translate(-5px, 0)",
                      },
                      ticks: {
                        stroke: "#2C4B94",
                        size: 5,
                        // transform: "translate(-5px, 0)",
                      },
                      tickLabels: {
                        fill: "#2C4B94",
                        fontSize: 12,
                        padding: 5,
                      },
                    }}
                  />
                  <VictoryArea
                    data={impresssionUniqueData}
                    y={(datum) => datum.y / maxImpressUniY}
                    style={{
                      data: {
                        fill: "#F8C6D1",
                        fillOpacity: 0.3, // Điều chỉnh giá trị fillOpacity để làm màu fill trở nên mờ hơn
                        stroke: "#F8C6D1",
                        strokeWidth: 2,
                      },
                    }}
                  />
                  <VictoryAxis
                    dependentAxis={true}
                    orientation="right"
                    tickValues={[0.25, 0.5, 0.75, 1]}
                    tickFormat={(t) => (t * maxImpressUniY).toFixed(0)}
                    // tickValues={[0, 250, 500, 750, 1000]}
                    style={{
                      axis: {
                        stroke: "#2C4B94",
                        // transform: "translate(-5px, 0)",
                      },
                      ticks: {
                        stroke: "#2C4B94",
                        size: 5,
                        // transform: "translate(-5px, 0)",
                      },
                      tickLabels: {
                        fill: "#2C4B94",
                        fontSize: 12,
                        padding: 5,
                      },
                    }}
                  />
                  <VictoryAxis
                    style={{
                      axis: {
                        stroke: "#2C4B94",
                        // transform: "translate(-5px, 0)",
                      },
                      ticks: { stroke: "#2C4B94", size: 5 },
                      tickLabels: { fill: "#2C4B94", fontSize: 12, padding: 5 },
                    }}
                    tickValues={lableX}
                    tickFormat={(tick, index) =>
                      getCustomTickLabel(tick, index)
                    }
                    labelComponent={<VictoryLabel />}
                    domain={[0, impresssionData?.length + 1]}
                  />
                </VictoryChart>
                <div
                  className="d-flex flex-row justify-content-center"
                  style={{ gap: "16px" }}
                >
                  <div className="d-flex flex-row align-items-center">
                    <div
                      className={`rounded-circle mr-1`}
                      style={{
                        height: "8px",
                        width: "8px",
                        backgroundColor: "#2C4B94",
                        flexShrink: "0",
                      }}
                    ></div>
                    <div className={`${style.contentCard}`}>
                      {formatNumber(totalImpersion)} Lượt tiếp cận
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <div
                      className={`rounded-circle mr-1`}
                      style={{
                        height: "8px",
                        width: "8px",
                        backgroundColor: "#FFD9EB",
                        flexShrink: "0",
                      }}
                    ></div>
                    <div className={`${style.contentCard}`}>
                      {formatNumber(totalImpersionUnique)} Người tiếp cận
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Tiếp cận theo chi phí
              </CardTitle>

              <div className="d-flex flex-row justify-content-center mt-5">
                <svg viewBox="-100 0 500 300">
                  <ChartPine info={"Lượt tiếp cận"} data={rateImpressionData} />
                </svg>
                <svg viewBox="-100 0 500 300">
                  <ChartPine
                    info={"Người tiếp cận"}
                    data={rateImpressionUniqueData}
                  />
                </svg>
              </div>
              <div
                className="d-flex flex-row justify-content-center mt-5 "
                style={{ gap: "16px" }}
              >
                {rateImpressionData?.map((item, index) => (
                  <div
                    className="d-flex flex-row align-items-center"
                    key={index}
                  >
                    <div
                      className={`rounded-circle mr-1`}
                      style={{
                        height: "8px",
                        width: "8px",
                        backgroundColor: `${item?.color}`,
                        flexShrink: "0",
                      }}
                    ></div>
                    <div className={`${style.contentCard}`}>{item?.label}</div>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={12} md={6}>
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Tiếp cận lan tỏa
              </CardTitle>

              <div className="d-flex flex-row justify-content-center mt-5">
                <svg viewBox="-100 0 500 300">
                  <ChartPine
                    info={"Lượt tiếp cận"}
                    data={rateImpressionViralData}
                  />
                </svg>
                <svg viewBox="-100 0 500 300">
                  <ChartPine
                    info={"Người tiếp cận"}
                    data={rateImpressionViralUniqueData}
                  />
                </svg>
              </div>
              <div
                className="d-flex flex-row justify-content-center mt-5 "
                style={{ gap: "16px" }}
              >
                {rateImpressionViralData?.map((item, index) => (
                  <div
                    className="d-flex flex-row align-items-center"
                    key={index}
                  >
                    <div
                      className={`rounded-circle mr-1`}
                      style={{
                        height: "8px",
                        width: "8px",
                        backgroundColor: `${item?.color}`,
                        flexShrink: "0",
                      }}
                    ></div>
                    <div className={`${style.contentCard}`}>{item?.label}</div>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6} className="mt-4 mt-md-0">
          <Card className="h-100 ">
            <CardBody className="card-inner w-100">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Lượt chia sẻ theo loại hình
              </CardTitle>
              <div className="w-100">
                <VictoryChart
                  //   theme={VictoryTheme.material}
                  width={800}
                  height={350}
                  padding={{ top: 5, left: 50, right: 40, bottom: 40 }}
                >
                  <VictoryGroup
                    colorScale={["#2C4B94", "#F8C6D1"]}
                    offset={25}
                    horizontal
                  >
                    <VictoryBar data={dataSample3} barWidth={20} />
                    <VictoryBar data={dataSample4} barWidth={20} />
                  </VictoryGroup>
                  <VictoryAxis
                    dependentAxis={true}
                    tickValues={Array.from({ length: 10 }, (_, index) => index)}
                    style={{
                      axis: {
                        stroke: "#2C4B94",
                      },
                      ticks: {
                        stroke: "#2C4B94",
                        size: 5,
                      },
                      tickLabels: {
                        fill: "#2C4B94",
                        fontSize: 14,
                        padding: 5,
                      },
                    }}
                  />
                  <VictoryAxis
                    style={{
                      axis: {
                        stroke: "#2C4B94",
                      },
                      ticks: { stroke: "#2C4B94", size: 5 },
                      tickLabels: { fill: "#2C4B94", fontSize: 14, padding: 5 },
                    }}
                    tickValues={[0, 250, 500, 750, 1000]}
                  />
                </VictoryChart>
                <div
                  className="d-flex flex-row justify-content-center"
                  style={{ gap: "16px" }}
                >
                  <div className="d-flex flex-row align-items-center">
                    <div
                      className={`rounded-circle mr-1`}
                      style={{
                        height: "8px",
                        width: "8px",
                        backgroundColor: "#2C4B94",
                        flexShrink: "0",
                      }}
                    ></div>
                    <div className={`${style.contentCard}`}>Reactions</div>
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <div
                      className={`rounded-circle mr-1`}
                      style={{
                        height: "8px",
                        width: "8px",
                        backgroundColor: "#FFD9EB",
                        flexShrink: "0",
                      }}
                    ></div>
                    <div className={`${style.contentCard}`}>Reactions</div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Access;
