import React, { useState } from "react";
import { Icon, Img } from "../../../components/Component";
import IconShare from "../../images/icons/preview-facebook-share-video.svg";
import IconLike from "../../images/icons/preview-facebook-like-video.svg";
import IconStar from "../../images/icons/icon-star.svg";
import IconComment from "../../images/icons/preview-facebook-comment-video.svg"
import { CONTENT_LIMIT_SEE_MORE } from "../../utils/Utils";
import style from "./index.module.scss";
import "./_preview-facebook.scss";
import {
  getPlatformIconFromLabel
} from "../../utils/Utils";
const PreviewFacebook = ({ ...props }) => {
  const { channel, content, mediaItems, className } = props;
  const [openPreviewFace, setOpenPreviewFace] = useState(true)
  const displayContent =
    content?.length <= CONTENT_LIMIT_SEE_MORE ? (
      content
    ) : (
      <>
        {content?.slice(0, CONTENT_LIMIT_SEE_MORE)}
        <b>See more...</b>
      </>
    );

  const handleOpenPreviewFace = () => {
    setOpenPreviewFace(!openPreviewFace)
    const Elmchevron = document.getElementById(`chevron_previewFace`);
    const Elmcontent = document.getElementById("content_previewFace");
    if (!openPreviewFace) {
      Elmchevron.classList.add(`${style.openChevronDown}`)
      Elmchevron.classList.remove(`${style.openChevronUp}`)
      Elmcontent.classList.add(`${style.openContentDown}`)
      Elmcontent.classList.remove(`${style.openContentUp}`)
    }
    else {
      Elmchevron.classList.add(`${style.openChevronUp}`)
      Elmchevron.classList.remove(`${style.openChevronDown}`)
      Elmcontent.classList.add(`${style.openContentUp}`)
      Elmcontent.classList.remove(`${style.openContentDown}`)
    }
  }

  return (
    <div className={`previewFacebook ${className || ""}`}>
      <div className="d-flex align-items-stretch border-bottom">
        <div
          className="flex-fill p-2 fw-medium header"
          style={{ color: "#333333", fontFamily: "Roboto" }}
        >
          <Img
            size="circle"
            width="16px"
            src={getPlatformIconFromLabel("facebook")}
            style={{ marginRight: "4px" }}
          />
          Facebook
        </div>
        <div id="chevron_previewFace" className={`card-tools p-2 ${style.openMedia}`} onClick={handleOpenPreviewFace}>
          <svg width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" /> </svg>
        </div>
      </div>
      <div id="content_previewFace" style={{
        transition: "max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s",
        willChange: "max-height",
        position: "relative",
        overflow: "hidden"
      }}>
        {
          mediaItems.length > 0 &&
          <div className="imageWrapper">
            <div className={style.previewInstagram__frame9_16}>

              <Img src={mediaItems[0]?.previewImages.url} alt="something" style={{ objectFit: "cover", margin: "auto" }} />
              <div className="d-flex p-1 justify-between w-full absolute bottom-0 ">
                <div className={`info ${style.previewFacebook__name}`}>
                  <div className={style.item__avarta}>
                    <Img
                      className="avatar"
                      src={channel.picture}
                      alt="avatar"
                      size="circle"
                      width="24px"
                      style={{ marginRight: "4px" }} />
                    @{channel.name} <Icon name="globe"></Icon>
                  </div>
                  {content?.length > 0 && <div>{displayContent}</div>}
                </div>
                <div className={style.previewFacebook__avarta}>
                  <Icon name="more-h"></Icon>
                </div>
              </div>
              <div className={style.previewFacebook__item}>
                <div className={style.item__interact}>
                  <Img src={IconStar} alt="Star" />
                  <div style={{ fontSize: "11px" }}>Tặng</div>
                </div>
                <div className={style.item__interact}>
                  <Img src={IconLike} alt="comment" />
                  <div>2</div>
                </div>
                <div className={style.item__interact}>
                  <Img src={IconComment} alt="comment" />
                  <div>19.3k</div>
                </div>
                <div className={style.item__interact}>
                  <Img
                    src={IconShare}
                    alt="share"
                    width="32px"
                    height="32px"
                  />
                  <div >10.2k</div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default PreviewFacebook;