import { Button, Input, Label } from "reactstrap";





const Task = () => {


    const renderItem = () => {
        return (
            <div className="px-2 pt-2 pb-3 justify-content-start align-items-center d-flex rounded  bg-white border mb-3">
                <Input className="form-check-inut" type="checkbox" id="for1"></Input>
                <Label className="form-check-label ms-2 text-break" for="for1">kha vu task</Label>
            </div>
        )
    }

    return (
        <>
            <div className="w-100">
                <Input type="textarea" placeholder="Write a comment..." style={{ resize: "none", height: "72px" }} ></Input>
              
                <div className="w-100 d-flex justify-content-end mt-3 pb-3 border-bottom border-2">
                    <Button>Add Task</Button>
                </div>
              
                <div className="mt-3">
                    {renderItem()}
                    {renderItem()}
                    {renderItem()}
                    {renderItem()}
                    {renderItem()}
                    {renderItem()}
                    {renderItem()}
                    {renderItem()}
                </div>
            </div>
        </>
    )
}

export default Task;