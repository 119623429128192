import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { useSelector } from "react-redux";
import {
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import { getMedia } from "../../../api/createPost";
import { MEDIA_TYPE_IMAGE, MEDIA_TYPE_VIDEO } from "../../utils/Utils";
import CloudMediaItem from "./CloudMediaItem";

const CloudMedia = ({ ...props }) => {
  const { t } = useTranslation();
  const mediaItems = props.mediaItems;
  const project = useSelector((state) => state.project.project);
  const [isOpenMediaSourceOption, setIsOpenMediaSourceOption] = useState(false);
  const [mediaSourceOptions] = useState([
    {
      value: 1,
      label: "Đã tải lên So9",
    },
  ]);
  const [mediaSource, setMediaSource] = useState(mediaSourceOptions[0]);
  const [mediaFromSources, setMediaFromSources] = useState([]);
  const hasMore = useRef(true);
  const loading = useRef(false);
  const page = useRef(1);
  const controller = useRef();
  const simpleBarRef = useRef();

  const loadMedia = () => {
    if (!controller.current) {
      controller.current = new AbortController();
    }
    if (!hasMore.current || loading.current) return;
    loading.current = true;
    const data = {
      params: {
        page: page.current,
      },
      signal: controller.current.signal,
    };
    const fetchData = async () => {
      return await getMedia(project?._id, data);
    };
    fetchData()
      .then((response) => {
        if (response.data.length > 0) {
          setMediaFromSources((c) => [...c, ...response.data]);
          page.current++;
        } else {
          hasMore.current = false;
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        loading.current = false;
      });
  };

  useEffect(() => {
    return () => {
      controller.current?.abort();
    };
  }, []);

  const close = () => {
    props.close && props.close();
  };
  const isUsed = (m) => {
    const url = m.url;
    const selectedMedia = mediaItems.findIndex((f) => f.url === url);
    return selectedMedia > -1;
  };
  const addMedia = (m) => {
    let newMedia = null;
    switch (m.type) {
      case MEDIA_TYPE_IMAGE:
        newMedia = {
          type: m.type,
          name: m.name,
          url: m.url,
          altText: m.name,
        };
        break;
      case MEDIA_TYPE_VIDEO:
        newMedia = {
          type: m.type,
          name: m.name,
          url: m.url,
          altText: m.name,
          previewImages: [],
        };
        break;
      default:
    }
    if (newMedia && props.addMedia) {
      props.addMedia(newMedia);
    }
  };

  return (
    <Card className={`${props.className} card-bordered`}>
      <CardHeader>
        <Row>
          <div className="col-auto mr-auto ml-2">
            <ButtonDropdown
              isOpen={isOpenMediaSourceOption}
              toggle={() => setIsOpenMediaSourceOption(!isOpenMediaSourceOption)}
            >
              <Button id="caret">{mediaSource.label}</Button>
              <DropdownToggle split className="dropdown-indicator" />
              <DropdownMenu>
                {mediaSourceOptions.map((s) => (
                  <DropdownItem key={s.value} onClick={() => setMediaSource(s)}>
                    {s.label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>
          </div>
          <div className="col-auto" role="button" onClick={close}>
            {t("create_post.media_cloud_close")}
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <SimpleBar ref={simpleBarRef} style={{ height: "100%", overflow: "auto" }}>
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMedia}
            hasMore={hasMore.current}
            useWindow={false}
            getScrollParent={() => simpleBarRef.current?.contentWrapperEl}
            threshold={10}
          >
            <div className="media-wrapper">
              {mediaFromSources.map((m, i) => (
                <CloudMediaItem media={m} index={i} isUsed={isUsed(m)} key={`${i}`} onAdd={() => addMedia(m)} />
              ))}
            </div>
          </InfiniteScroll>
        </SimpleBar>
      </CardBody>
    </Card>
  );
};

export default CloudMedia;
