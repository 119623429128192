import React, { useState } from "react";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {
  Button,
  Icon,
} from "../../components/Component";
import { Spinner, FormGroup, Alert, Row, Container, Card, Col, Label, Input } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PasswordFieldAuth } from "../../commons/TextField/TextFieldAuth";
import style from "./Auth.module.scss"
import { validateEmail } from "../../libs/helper";
import { changePassword } from "../../api/auth";
import useCheckLogin from "../../hooks/useCheckLogin";
import MessSucces from "../../So9/components/AuthNew/MessSuccess";
import AuthSlider from "../../So9/components/AuthNew/authCarousel";
import styles from "../../So9/components/AuthNew/index.module.scss"

const Register = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [errorVal, setError] = useState("");
  const [successVal, setSuccess] = useState("");
  const [loginData, setLoginData] = useState();

  const { t } = useTranslation();
  useCheckLogin();
  Yup.addMethod(Yup.string, "isValidEmail", function (errorMessage) {
    return this.test('is-valid-email', errorMessage, function (value) {
      const { path, createError } = this;
      value = validateEmail(value);
      if (!value) {
        return createError(path, errorMessage);
      }
      return true;
    });
  });
  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('validate_field_password_required'))
      .min(6, t('validate_field_password_character_required', { length: 6 })),
    passwordConfirmation: Yup.string()
      .required(t('validate_field_re_password_required'))
      .oneOf([Yup.ref('password')], t('validate_field_password_confirm_not_match')),
  })

  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
    setValue
  } = useForm(
    {
      resolver: yupResolver(formSchema)
    }
  );

  const handleFormSubmit = async (formData) => {
    setLoading(true);
    formData.email = localStorage.getItem('emailToChangePassword');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userInfor');
    changePassword(formData).then((res) => {
      if (res.status) {
        // setTimeout(() => {
        //   setDataLogin(res.data);
        // }, 5000)
        setLoginData(res.data)
        setSuccess(t('text_success_submit_new_password'));
        setError("");
      } else {
        setError(res.message);
        setLoading(false);
        setSuccess("");
      }
    }).catch((err) => {
      setError(err?.response?.data?.message);
      setLoading(false);
      setSuccess("");
    })
  };

  const setDataLogin = async (data) => {
    localStorage.setItem('userInfor', JSON.stringify(data));
    localStorage.setItem('accessToken', data.token);
  }

  return (
    <React.Fragment>
      <Head title={t('auth_register')} />
      <PageContainer>
        <div className={`${styles.auth_page_wrapper} ${styles.auth_bg_cover} py-5 d-flex justify-content-center align-items-center min-vh-100 `}>
          <div className={`${styles.bg_overlay}`}></div>
          <div className="auth-page-content overflow-hidden pt-lg-5">
            <Container style={{ maxWidth: "1100px" }}>
              <Row>
                <Col lg={12}>
                  <Card className="overflow-hidden card-bg-fill galaxy-border-none" >
                    <Row className="g-0">
                      <AuthSlider />

                      <Col lg={6}>
                        {!successVal ? (
                          <div className="p-lg-5 p-4">
                            <div>
                              <h5 className="text-primary">{t("new_auth.newpass_header")}</h5>
                              <p className="text-muted">{t("new_auth.newpass_mess")}</p>
                            </div>

                            <div className="mt-4">
                              {errorVal && (
                                <div className="mb-3">
                                  <Alert color="danger" className="alert-icon">
                                    {" "}
                                    <Icon name="alert-circle" /> {errorVal}{" "}
                                  </Alert>
                                </div>
                              )}
                              <form onSubmit={handleSubmit(handleFormSubmit)}>
                                <FormGroup>
                                  <PasswordFieldAuth
                                    field={'password'}
                                    current={watch('password')}
                                    register={register}
                                    setValue={setValue}
                                    required={true}
                                    errors={errors.password}
                                    title={t('new_password')}
                                    minLength={4}
                                    placeholder={t('auth_register_password_placeholder')} />
                                </FormGroup>

                                <FormGroup>
                                  <PasswordFieldAuth
                                    field={'passwordConfirmation'}
                                    current={watch('passwordConfirmation')}
                                    register={register}
                                    setValue={setValue}
                                    required={true}
                                    errors={errors.passwordConfirmation}
                                    title={t('auth_register_password_confirm')}
                                    placeholder={t('auth_register_password_confirm_placeholder')} />
                                </FormGroup>
                                <div className="form-check mb-2">
                                  <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                  <Label className="form-check-label" htmlFor="auth-remember-check">{t("new_auth.remember")}</Label>
                                </div>
                                <FormGroup>
                                  <Button size="lg" color={"success"} className={"w-100 d-flex justify-content-center align-items-center"} type="submit">
                                    {loading ? <Spinner size="sm" color="light" /> : t('auth_register_button')}
                                  </Button>
                                </FormGroup>
                              </form>
                            </div>
                            <div className="mt-5 text-center">
                            <p className="mb-0">{t("new_auth.know_pass")} <Link to="/auth-login" className={` ${styles.text_decoration} fw-semibold text-primary text-decoration-underline ml-1`}>{t("new_auth.click_here")}</Link> </p>
                            </div>
                          </div>
                        ) : (<MessSucces
                          message={"Congratulations! You have successfully signed up. Welcome to SO9!"}
                          loginData = {loginData}
                        />)}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
            <div className="w-100 d-flex justify-content-center align-items-center mt-5 gap-4" style={{position:"relative"}}>
              <Link to="/auths/terms" target="_blank" className={"text-muted"}>{t("new_auth.term_service")}</Link>
              <Link to="/auths/policy" target="_blank" className={"text-muted"}>{t("footer_privacy")}</Link>
            </div>
          </div>
        </div>

      </PageContainer>
    </React.Fragment>
  );
};
export default Register;
