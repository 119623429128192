import { Line } from "react-chartjs-2";

const LineChart = ({ data, options, height }) => (
  <Line
    data={data}
    options={options}
    height={height ? height : undefined}
  ></Line>
);

export default LineChart;
