import React, { useEffect, useState } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { Nav, NavItem, NavLink, Badge, Button } from "reactstrap";
import {
  BlockBetween,
  Block,
  BlockContent,
  BlockHead,
  BlockTitle,
  Col,
  Row,
  Img
} from "../../../../components/Component";
import { Card } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { loadListSubscription, doSubscription } from "../../../../api/project";
import { formatNumber, upperFirstLetter } from "../../../../utils/Utils";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setBill } from "../../../../reducers/projectSlice";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import style from "./index.module.scss";
import PlanS1 from "../../../../images/icons/plan-s1.svg";
import PlanS2 from "../../../../images/icons/plan-s2.svg";
import PlanS3 from "../../../../images/icons/plan-s3.svg";

const ListSubscription = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  let history = useHistory();
  const [listPackage, setListPackage] = useState([]);
  const project = useSelector((state) => state.project.project);
  const dispatch = useDispatch();

  const setListPackageData = () => {
    if (status === 'success') {
      setListPackage(data.map((pkg, key) => {
        return {
          duration: pkg.duration,
          unit_duration: pkg.unit,
        }
      }));
    }
  }

  const selectAvailableSub = (fee) => {
    const feeSelected = data[activeTab];
    const dataCreateBill = {
      project_id: project?._id,
      duration: feeSelected.duration,
      unit_duration: feeSelected.unit,
      price_per_employee: fee.pricePerEmployee,
      price_per_connection: fee.pricePerConnect,
      discount: fee.discount,
      employee: fee.employee,
      connection: fee.employee,
      total_amount: fee.price
    }
    try {
      doSubscription(dataCreateBill).then(res => {
        toast.success(t('project_is_creating_bill'), {
          position: toast.POSITION.TOP_RIGHT
        });
        dispatch(setBill(res));
        history.push('/admin/subscription/bill/confirm-payment')
      }).catch(e => {
        toast.error(t('project_is_creating_bill_failed'), {
          position: "top-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: true,
        });
      })
    }
    catch (e) {
      toast.error(t('error_system'), {
        position: "top-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: true,
      });
    }
  }

  const loadSubscription = async () => {
    return await loadListSubscription();
  }
  const {
    data,
    status,
  } = useQuery(['loadListSubscription'], loadSubscription);

  useEffect(() => {
    setListPackageData();
  }, [data]);

  const arrIconPackage = [
    PlanS1, PlanS2, PlanS3, PlanS1
  ];

  return (
    <React.Fragment>
      <Head title={t('project_list_subscription')}></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween className="g-3">
            <BlockContent>
              <BlockTitle>{t('project_upgrade_subscription')}</BlockTitle>
            </BlockContent>
            <BlockContent>
              <Nav tabs className="mt-n3">
                {
                  status === "success" && listPackage.length > 0 && listPackage.map((pkg, key) => {
                    return (
                      <NavItem className={`${style.navWrapper} ${key === 0 && style.borderTopLeftRadius} ${key === (listPackage.length - 1) && style.borderTopRightRadius}`} key={key}>
                        <NavLink
                          tag="a"
                          href="#tab"
                          className={`${activeTab === key && style.active} ${style.navItem} ${key === 0 && style.borderTopLeftRadius} ${key === (listPackage.length - 1) && style.borderTopRightRadius}`}
                          onClick={(ev) => {
                            ev.preventDefault();
                            toggleTab(key);
                          }}
                        >
                          <span>{pkg.duration + ' ' + t('project_' + pkg.unit_duration)}</span>
                        </NavLink>
                      </NavItem>
                    )
                  })
                }
              </Nav>
            </BlockContent>
          </BlockBetween>
        </BlockHead>
        {
          status === "success" && listPackage.length > 0 && (
            <Block>
              <Row className="g-gs">
                {data[activeTab] && data[activeTab].options.length > 0 && data[activeTab].options.map((item, key) => {
                  return (
                    <Col md={6} xxl={3} key={key}>
                      <Card className={`card-bordered pricing text-center`}>
                        <div className="pricing-body">
                          <div className="pricing-media">
                            <Img src={arrIconPackage[key]} alt="" />
                          </div>
                          <div className="pricing-title w-220px mx-auto">
                            <h5 className="title">{upperFirstLetter(item.title)}</h5>
                            <span className="sub-text">{item.description}</span>
                          </div>
                          <div className="pricing-amount">
                            <div className="amount">
                              <span>{formatNumber(item.price || 0)} đ</span>
                            </div>
                            <span className="bill"><b>{item.page}</b> Trang - <b>{item.employee}</b> Nhân viên</span>
                          </div>
                          <div className="pricing-action">
                            <Button
                              style={{ backgroundColor: "#FD1F6D", border: "none" }}
                              disabled={item.type === "free"}
                              onClick={() => selectAvailableSub(item)}
                            >
                              {item.textButton}
                            </Button>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  )
                })}
              </Row>
            </Block>
          )
        }
      </Content>
    </React.Fragment>
  );
};

export default ListSubscription;
