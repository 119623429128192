import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Spinner, Alert } from 'reactstrap';
import styles from "./index.module.scss"
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import AuthSlider from './authCarousel';
import useCheckLogin from '../../../hooks/useCheckLogin';
import { login, resendEmail } from '../../../api/auth';
import Cookies from 'js-cookie';
import useQuery from '../../../hooks/useQuery';
import { updateAffClick } from '../../../api/iris';
import { useTranslation } from 'react-i18next';
import ResendCountdown from '../../../pages/auth/ResendCountdown';
import { Icon } from '../../../components/Component';
import { getDomainForCookie } from '../../../utils/Utils';

const CoverSignIn = () => {

    const [passwordShow, setPasswordShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorVal, setError] = useState("");
    const [reSend, setTimeResend] = useState(true);
    const [userInfor, setUserInfor] = useState();
    const { t } = useTranslation();
    const [timeRemain, setTimeRemain] = useState(0);
    useCheckLogin();

    const redirectToDashboard = () => {
        const userLogin = JSON.parse(localStorage.getItem("userInfor"));
        if (userLogin.is_new_user === 1) {
            window.location.replace('/news');
        } else {
            window.location.replace('/news');
        }
    };

    const onFormSubmit = (formData) => {
        setLoading(true);
        formData.type = 3;
        login(formData).then((res) => {
            if (res.status) {
                localStorage.setItem("accessToken", res.data.access_token);
                localStorage.setItem("is_new_user", res.data.is_new_user);
                localStorage.setItem("userInfor", JSON.stringify(res.data));
                const domain = getDomainForCookie();
                Cookies.set('accessToken', res.data.access_token, { domain, expires: 1 })
                Cookies.set('userInfor', JSON.stringify(res.data), { domain, expires: 365 })

                redirectToDashboard();
            } else {
                const userInfo = {
                    email: formData.email
                };
                setUserInfor(userInfo)
                setError(res.message);
                setLoading(false);
                if (localStorage.getItem('resendInfo')) {
                    const arrResendInfo = JSON.parse(localStorage.getItem("resendInfo"));
                    if (arrResendInfo.length > 0) {
                        const userResend = arrResendInfo.find((item) => item.email === formData.email);
                        if (userResend) {
                            setTimeResend(false);
                            const timeStart = userResend.countdown;
                            const timeRemain = Math.floor(Math.abs(Date.now() - timeStart));
                            setTimeRemain(timeRemain);
                        }
                        else {
                            setTimeResend(true);
                        }
                    }
                }
            }
        });
        return true;
    };

    const onResend = () => {
        if (reSend) {
            resendEmail(userInfor).then((res) => {
                if (res.status) {
                    setTimeResend(false);
                    if (localStorage.getItem('resendInfo')) {
                        let arrResendInfo = JSON.parse(localStorage.getItem('resendInfo'));
                        const dataInfo = {
                            email: userInfor.email,
                            countdown: Date.now(),
                        };
                        arrResendInfo.push(dataInfo);
                        localStorage.setItem('resendInfo', JSON.stringify(arrResendInfo));
                    }
                    else {
                        let arrResendInfo = [];
                        const dataInfo = {
                            email: userInfor.email,
                            countdown: Date.now(),
                        };
                        arrResendInfo.push(dataInfo);
                        localStorage.setItem('resendInfo', JSON.stringify(arrResendInfo));
                    }
                    setTimeRemain(0);
                } else {
                    setError(res.message);
                    setLoading(false);
                }
            }).catch((err) => {
                setError(err?.response?.data?.message);
                setLoading(false);
            })
        }
    }




    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required(t("validate_field_email_required")).isValidEmail(t("validate_field_email")),
            password: Yup.string()
                .required(t("validate_field_password_required"))
                .min(4, t("validate_field_password_character_required", { length: 4 })),
        }),
        onSubmit: (formData) => {
            onFormSubmit(formData)
        }
    });

    const query = useQuery();
    const aceptClick = async (ref_code) => {
        const param = {
            ref_code: ref_code,
        }
        try {
            await updateAffClick(param);
        } catch (error) {
            console.log("click=>", error);
        }

    }
    useEffect(() => {
        const refParam = query.get('ref')
        if (refParam) {
            const refCookies = Cookies.get('refCode');
            const domain = getDomainForCookie();
            if (!refCookies) {
                Cookies.set('refCode', refParam, { domain, expires: 30 })
                aceptClick(refParam)
            } else if (refCookies && refCookies !== refParam) {
                Cookies.set('refCode', refParam, { domain, expires: 30 })
                aceptClick(refParam)
            }
        }
    }, [query])


    return (
        <React.Fragment>
            <div className={`${styles.auth_page_wrapper} ${styles.auth_bg_cover} py-5 d-flex justify-content-center align-items-center min-vh-100 `}>
                <div className={`${styles.bg_overlay}`}></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container style={{ maxWidth: "1100px" }}>
                        <Row>
                            <Col lg={12}>
                                <Card className="overflow-hidden card-bg-fill galaxy-border-none" >
                                    <Row className="g-0">
                                        <AuthSlider />

                                        <Col lg={6}>
                                            <div className="p-lg-5 p-4">
                                                <div>
                                                    <h5 className="text-primary">{t("new_auth.welcome_back")}</h5>
                                                    <p className="text-muted">{t("new_auth.signin_mess")}</p>
                                                </div>

                                                <div className="mt-4">
                                                    {errorVal && (
                                                        <div className="mb-3">
                                                            <Alert color="danger" className="alert-icon">
                                                                {" "}
                                                                <Icon name="alert-circle" /> {errorVal}{"."}
                                                                {
                                                                    errorVal === 'Kiểm tra email để kích hoạt tài khoản' ? <span className={`${styles.ResendButton} ${!reSend && styles.waitResend}`} onClick={onResend}>{reSend ? t("auth_resend") : <ResendCountdown timeRemain={timeRemain} setTimeResendParent={() => setTimeResend(true)} userEmail={userInfor.email} />}</span> : ''
                                                                }
                                                            </Alert>
                                                        </div>
                                                    )}
                                                    <Form
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            validation.handleSubmit();
                                                            return false;
                                                        }}
                                                        action="#">

                                                        <div className="mb-3">
                                                            <Label htmlFor="username" className="form-label">{t("auth_email")}</Label>
                                                            <Input type="text" className="form-control" id="username" placeholder={t("auth_placeholder_email")}
                                                                name="email"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.email || ""}
                                                                invalid={
                                                                    validation.touched.email && validation.errors.email ? true : false
                                                                }
                                                            />
                                                            {validation.touched.email && validation.errors.email ? (
                                                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                            ) : null}
                                                        </div>

                                                        <div className="mb-3">
                                                            <div className="float-end" style={{ float: "right" }}>
                                                                <Link to="/auth-reset" className="text-muted">{t("auth_forgot_password")}</Link>
                                                            </div>
                                                            <Label className="form-label" htmlFor="password-input">{t("auth_password")}</Label>
                                                            <div className="position-relative auth-pass-inputgroup mb-3">
                                                                <Input type={passwordShow ? "text" : "password"} className="form-control pe-5 password-input" placeholder={t("auth_placeholder_password")} id="password-input"
                                                                    name="password"
                                                                    value={validation.values.password || ""}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    invalid={
                                                                        validation.touched.password && validation.errors.password ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.password && validation.errors.password ? (
                                                                    <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                                ) : null}
                                                                <button className="btn btn-link position-absolute right-0 top-0 text-decoration-none text-muted password-addon material-shadow-none"
                                                                    type="button" id="password-addon"
                                                                    style={{ boxShadow: "unset" }}
                                                                    onClick={() => setPasswordShow(!passwordShow)}><em className={!passwordShow ? "icon ni ni-eye-alt-fill" : "icon ni ni-eye-off-fill"}></em></button>
                                                            </div>
                                                        </div>

                                                        <div className="form-check">
                                                            <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                            <Label className="form-check-label" htmlFor="auth-remember-check">{t("new_auth.remember")}</Label>
                                                        </div>

                                                        <div className="mt-4">
                                                            <Button color="success" className="w-100 d-flex justify-content-center align-items-center" type="submit">{loading ? <Spinner size="sm" color="light" /> : t("auth_sign_in_button")}</Button>
                                                        </div>

                                                    </Form>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    <p className="mb-0">{t("auth_dont_have_account")}<a href="/auth-register" className="fw-semibold text-primary text-decoration-underline ml-1">{t("auth_create_an_account")}</a> </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                    <div className="w-100 d-flex justify-content-center align-items-center mt-5 gap-4" style={{ position: "relative" }}>
                        <Link to="/auths/terms" target="_blank" className={"text-muted"}>{t("new_auth.term_service")}</Link>
                        <Link to="/auths/policy" target="_blank" className={"text-muted"}>{t("footer_privacy")}</Link>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CoverSignIn;