import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Icons: [],
};

export const iconSlice = createSlice({
  name: "icon",
  initialState,
  reducers: {
    setIcon(state, action) {
      state.Icons = action.payload;
    },
  },
});

export const { setIcon} = iconSlice.actions;

export default iconSlice.reducer;
