export const listWindowName = [
{
    name : "Đăng bài",
    link : "/posts/create",
},
{
    name : "Đăng bài",
    link : "/posts/reel/create",
},
{
    name : "Quản lý danh sách bài đăng",
    link : "/manage_posts",
},
{
    name : "Quản lý theo lịch",
    link : "/calender/postChannel",
},
{
    name : "Báo cáo kênh",
    link : "home/dashboard/overview",
},
{
    name : "Báo cáo nội dung",
    link : "/home/dashboard/overview-enterprise/content",
},
{
    name : "Báo cáo nhân sự",
    link : "/home/dashboard/overview-enterprise/staff",
},
{
    name : "Chiến dịch đăng tự động",
    link : "/warehouse",
},
{
    name : "Chiến dịch đăng tự động",
    link : "/campaign",
},
{
    name : "Phân tích đối thủ",
    link : "/research-competitor",
},
{
    name : "Kết nối kênh",
    link : "/admin/project/connect-channel", 
},
{
    name : "Tích hợp Google Sheet",
    link : "/integrateds",
},
{
    name : "Nhóm kênh",
    link : "/admin/project/group-channel",
},
{
    name : "Lịch tự động",
    link : "/posts/settingchannel-list?page=1",
},
{
    name : "Quản lý nhân viên",
    link : "/admin/project/users",
},
]