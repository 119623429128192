import { Input, UncontrolledTooltip } from "reactstrap";

import SQUARE_CAROSEL from "../../../images/post/carosel.svg";
import { useEffect } from "react";
import { PLATFORM_FACEBOOK } from "../../../utils/Utils";
import { Img, RSelect } from "../../../../components/Component";
import { getPlatformSquareIcon } from "../../../../libs/helper";
import IconQuestion from "../../../../assets/images/icon/icon-question.svg";
import { useTranslation } from "react-i18next";

const CTA = [
  "NO_BUTTON",
  "BOOK_TRAVEL",
  "CONTACT_US",
  "DONATE",
  "DONATE_NOW",
  "DOWNLOAD",
  "GET_DIRECTIONS",
  "GO_LIVE",
  "INTERESTED",
  "LEARN_MORE",
  "LIKE_PAGE",
  "MESSAGE_PAGE",
  "RAISE_MONEY",
  "SAVE",
  "SHOP_NOW",
  "SIGN_UP",
  "VIEW_INSTAGRAM_PROFILE",
  "INSTAGRAM_MESSAGE",
  "PURCHASE_GIFT_CARDS",
  "PAY_TO_ACCESS",
  "SEE_MORE",
  "TRY_IN_CAMERA",
  "WHATSAPP_LINK",
  "CHECK_AVAILABILITY",
  "ORDER_NOW",
  "GET_MOBILE_APP",
  "INSTALL_MOBILE_APP",
  "USE_MOBILE_APP",
  "INSTALL_APP",
  "USE_APP",
  "PLAY_GAME",
  "WATCH_VIDEO",
  "WATCH_MORE",
  "OPEN_LINK",
  "NO_BUTTON",
  "LISTEN_MUSIC",
  "MOBILE_DOWNLOAD",
  "GET_OFFER",
  "GET_OFFER_VIEW",
  "BUY_NOW",
  "BUY_TICKETS",
  "UPDATE_APP",
  "BET_NOW",
  "ADD_TO_CART",
  "SELL_NOW",
  "GET_SHOWTIMES",
  "LISTEN_NOW",
  "GET_EVENT_TICKETS",
  "REMIND_ME",
  "SEARCH_MORE",
  "PRE_REGISTER",
  "SWIPE_UP_PRODUCT",
  "SWIPE_UP_SHOP",
  "PLAY_GAME_ON_FACEBOOK",
  "VISIT_WORLD",
  "OPEN_INSTANT_APP",
  "GET_PROMOTIONS",
  "SEND_UPDATES",
  "INQUIRE_NOW",
  "VISIT_PROFILE",
  "CHAT_ON_WHATSAPP",
  "EXPLORE_MORE",
  "CONFIRM",
  "JOIN_CHANNEL",
  "MAKE_AN_APPOINTMENT",
  "ASK_ABOUT_SERVICES",
  "BOOK_A_CONSULTATION",
  "GET_A_QUOTE",
  "BUY_VIA_MESSAGE",
  "ASK_FOR_MORE_INFO",
  "CHAT_WITH_US",
  "CALL",
  "APPLY_NOW",
  "GET_QUOTE",
  "SUBSCRIBE",
  "REGISTER_NOW",
  "EVENT_RSVP",
  "REQUEST_TIME",
  "SEE_MENU",
  "DIAL_CODE",
  "FIND_YOUR_GROUPS",
];

const Carosel = ({
  listChannel,
  setListChannel,
  caroselSetting,
  setCaroselSetting,
  previewImages,
  content,
}) => {
  const { t } = useTranslation();
  const channelData = listChannel?.channel?.filter(
    (item) => item.platform === PLATFORM_FACEBOOK && item.check === true
  );

  const transformedCTA = CTA.map((item) => {
    if (typeof item === "string") {
      return {
        label: item.replace(/_/g, " "),
        value: item,
      };
    }
    return null;
  });

  useEffect(() => {
    let newData = caroselSetting.data.map((item) => {
      return {
        ...item,
        desc: content,
      };
    });
    setCaroselSetting({ ...caroselSetting, data: newData });
  }, [content]);

  const handleSelectCta = (e) => {
    setCaroselSetting({ ...caroselSetting, cta: e.value });
  };

  const handleChooseChannel = (id) => {
    const data = [...listChannel.channel];
    const newData = data.map((item) => {
      if (item._id === id) {
        item.isDisableCarosel = !item.isDisableCarosel;
      }
      return item;
    });

    setListChannel({
      ...listChannel,
      channel: newData,
    });
  };

  const onDescChange = (index, e) => {
    let data = [...caroselSetting.data];
    data[index].desc = e.target.value;
    setCaroselSetting({ ...caroselSetting, data: data });
  };

  const onUrlChange = (index, e) => {
    let data = [...caroselSetting.data];
    data[index].url = e.target.value;
    setCaroselSetting({ ...caroselSetting, data: data });
  };
  const onTitleChange = (index, e) => {
    let data = [...caroselSetting.data];
    data[index].title = e.target.value;
    setCaroselSetting({ ...caroselSetting, data: data });
  };

  const onToggleCarosel = () => {
    if (caroselSetting?.data?.length >= 2 && caroselSetting.data?.length <= 5) {
      setCaroselSetting({ ...caroselSetting, isOpen: !caroselSetting.isOpen });
    }
  };

  const isDisable = () => {
    if (channelData?.length === 0 && caroselSetting.data.length < 2) {
      return true;
    }
    return false;
  };

  return (
    <div
      className={`rounded mt-3 p-2 ${caroselSetting.isOpen && "color-border border-1"
        }`}
    >
      <div className="d-flex justify-content-between gap-3">
        <div className="d-flex align-items-center">
          <div
            className="d-flex justify-content-center align-items-center rounded position-relative"
            style={{
              background: "rgba(243,246,249,1)",
              minWidth: "36px",
              minHeight: "36px",
            }}
          >
            <Img src={SQUARE_CAROSEL} height="16px" width="16px"></Img>
          </div>
          <p className="fs-13 ms-2 mb-0" style={{ color: "#495057" }}>
            {t('store_post.carousel.title')}
          </p>
          <img
            src={IconQuestion}
            alt="link"
            id="caroselToolTip"
            className="cursor-pointer ms-2"
            style={{ marginRight: "25px" }}
          />
          <UncontrolledTooltip
            target={"caroselToolTip"}
            style={{
              minWidth: "300px",
              backgroundColor: "#ffffff",
              boxShadow:
                "0 2px 8px 0 rgba(0, 0, 0, 0.3), 0 4px 20px rgba(0, 0, 0, 0.2)",
            }}
          >
            <div style={{ minWidth: "300px" }} className="text-dark fs-13">
              <p className="mb-0 text-left fs-13 text-dark">
                {t('store_post.carousel.tooltip.first_line')}
              </p>
              <p className="mb-0 text-left fs-13 text-dark mt-1">
                {t('store_post.carousel.tooltip.second_line')}
              </p>
              <div className="d-flex align-items-center my-1 ml-1">
                <Img
                  src={getPlatformSquareIcon(PLATFORM_FACEBOOK)}
                  className="mr-1"
                  height={"24px"}
                />
                Pages
              </div>
            </div>
          </UncontrolledTooltip>

          <div class="custom-control-sm custom-switch ml-3" style={{ marginTop: 6 }}>
            <input
              class="custom-control-input"
              checked={caroselSetting.isOpen}
              disabled={isDisable()}
              onChange={() => onToggleCarosel()}
              type="checkbox"
              id="caroselSwitch"
            />
            <label
              className="custom-control-label switchControlCompose"
              for="caroselSwitch"
            ></label>
          </div>
        </div>
        {caroselSetting.isOpen && (
          <div className="d-flex align-items-center gap-2 flex-wrap">
            {channelData &&
              channelData?.map((item) => (
                <div
                  className={`rounded bg-light position-relative cursor-pointer overflow-hidden ${!item.isDisableCarosel && "border border-2 border-success"
                    }`}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={item.name}
                  style={{ height: "32px", width: "32px" }}
                >
                  <Img
                    className="h-100 w-100 rounded"
                    onClick={() => handleChooseChannel(item._id)}
                    style={
                      item.isDisableCarosel ? { filter: "grayscale(100%)" } : {}
                    }
                    src={item.picture}
                  ></Img>
                  <Img
                    className="position-absolute bottom-0 end-0"
                    style={
                      item.isDisableCarosel ? { filter: "grayscale(100%)" } : {}
                    }
                    src={getPlatformSquareIcon(item.platform)}
                    height={"14px"}
                    width="14px"
                  ></Img>
                </div>
              ))}
          </div>
        )}
      </div>

      {caroselSetting.isOpen && (
        <>
          <div className="mt-2 flex flex-row">
            <p className="d-flex text-body fs-13 text-nowrap mb-0 align-items-center mr-2">
              {t('store_post.carousel.link_end_card.title')}
            </p>
            <Input
              placeholder={t('store_post.carousel.link_end_card.placeholder')}
              value={caroselSetting.url}
              onChange={(e) => {
                setCaroselSetting({
                  ...caroselSetting,
                  url: e.target.value,
                });
                let newData = caroselSetting.data.map((item) => {
                  return {
                    ...item,
                    url: e.target.value,
                  };
                });
                setCaroselSetting({
                  ...caroselSetting,
                  data: newData,
                  url: e.target.value,
                });
              }}
            />
          </div>
          <div className="d-flex gap-2">
            {caroselSetting.data.length > 0 &&
              caroselSetting?.data?.map((item, index) => (
                <div
                  className="mt-3 d-flex flex-column min-w-[130px]"
                  style={{ gap: "4px" }}
                >
                  <div
                    className="rounded relative"
                    style={{ maxWidth: "100%" }}
                  >
                    <div class="absolute top-30 left-30 w-6 h-6 justify-center items-center bg-[#3897F0] text-white">
                      {index + 1}
                    </div>
                    <Img
                      src={URL.createObjectURL(item?.image)}
                      style={{ maxHeight: "13rem", aspectRatio: "1" }}
                      className="w-100 rounded"
                    />
                  </div>
                  <Input
                    value={item.title}
                    type="text"
                    placeholder={t('store_post.carousel.card.title')}
                    className="border"
                    onChange={(e) => onTitleChange(index, e)}
                    style={{ minHeight: "1rem" }}
                  />
                  <Input
                    type="text"
                    placeholder={t('store_post.carousel.card.desc')}
                    className="border"
                    value={caroselSetting?.data[index]?.desc}
                    onChange={(e) => onDescChange(index, e)}
                  />
                  <Input
                    value={caroselSetting?.data[index]?.url}
                    invalid={item.url.length === 0}
                    type="text"
                    onChange={(e) => onUrlChange(index, e)}
                    placeholder={t('store_post.carousel.card.url')}
                    className="border"
                  />
                </div>
              ))}
          </div>

          <p className="mt-3 fs-12 text-body mb-2">{t('store_post.carousel.cta')}</p>
          <div className="w-50">
            <RSelect
              parentClass="w-50"
              options={transformedCTA}
              defaultValue={transformedCTA[0]}
              onChange={(e) => handleSelectCta(e)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Carosel;
