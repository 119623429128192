const CheckedBox = ({
  checked,
  onChange,
  className,
  width,
  height,
  classNameParent,
  iconColor = "#0088cc",
}) => {
  if (checked) {
    return (
      <div className={classNameParent} onClick={onChange}>
        <div
          style={{
            border: "1px solid #0088cc",
            width: width || 14,
            height: height || 14,
            borderRadius: 4,
          }}
          className={`check-icon relative cursor-pointer rounded-md flex flex-shrink-0 justify-center items-center focus-within:border-blue-500 ${className}`}
        >
          {/* Checkmark SVG (initially visible) */}
          <svg
            className="fill-current w-2.5 h-2.5 text-blue-500 pointer-events-none"
            version="1.1"
            viewBox="0 0 17 12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="none" fillRule="evenodd">
              <g
                transform="translate(-9 -11)"
                fill={iconColor}
                fillRule="nonzero"
              >
                <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
              </g>
            </g>
          </svg>

          {/* Square SVG (initially hidden) */}
          <span className="hidden">
            <svg
              className="w-5 h-5"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="19" height="19" rx="5" fill={iconColor} />
              <line
                x1="5"
                y1="10"
                x2="15"
                y2="10"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </span>
        </div>
      </div>
    );
  }
  return (
    <div className={classNameParent} onClick={onChange}>
      <div
        className="check-icon relative cursor-pointer rounded-md border w-4 h-4 flex flex-shrink-0 justify-center items-center focus-within:border-blue-500"
        style={{
          border: "1px solid #CED4DA",
          width: width || 14,
          height: height || 14,
          borderRadius: 4,
        }}
      >
        <svg
          className="fill-current hidden w-2.5 h-2.5 text-blue-500 pointer-events-none"
          version="1.1"
          viewBox="0 0 17 12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(-9 -11)" fill="#0088cc" fillRule="nonzero">
              <path d="M25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
            </g>
          </g>
        </svg>

        {/* Square SVG (hidden initially) */}
        <span className="hidden">
          <svg
            className="w-5 h-5"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="19" height="19" rx="5" fill="#0088cc" />
            <line
              x1="5"
              y1="10"
              x2="15"
              y2="10"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </span>
      </div>
    </div>
  );
};

export default CheckedBox;
