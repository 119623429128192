import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

import {
  getFanOnlineTT,
  getLogFetchTT,
  getSummaryTTNew,
} from "../../../../../api/dashboard";
import { getUserInfoInLocalStorage } from "../../helper";

import FrequencyTiktokTable from "./FrequencyTableTikTok";
import { OverviewUserTiktok } from "./OverviewUserTiktok";
import OverviewVideo from "./OverviewVideo";
import { FanOnlineChart } from "../../Chart/FansOnlineChart";
import { TopCity } from "../../Chart/TopCity";
import { LogHeader } from "../../CommonItem/LogHeader";
import { PLATFORM_NAME } from "../../constants";
import Content from "../../../../../layout/content/Content";
import { Block } from "../../../../../components/Component";
const TikTok = ({
  channels,
  range,
  setLoadingChannelFilter,
  setLoadingRange,
  from,
  to,
}) => {
  const { t } = useTranslation();
  const userInfor = getUserInfoInLocalStorage();
  const projectId = userInfor.last_project_active;

  const [dataTiktok, setDataTiktok] = useState({});
  const [dataFanOnline, setDataFanOnline] = useState({});

  const [isLoadingUserData, setIsLoadingUserData] = useState(true);
  const [isLoadingFanOnline, setIsLoadingFanOnline] = useState(true);
  const [logTTData, setLogTTData] = useState({});

  const getAPITiktok = (data) => {
    setIsLoadingUserData(true);
    setIsLoadingFanOnline(true);
    Promise.allSettled([
      getSummaryTTNew(data),
      getFanOnlineTT(data),
      getLogFetchTT(data),
    ]).then((responses) => {
      const [tiktokSummaryData, fanOnlineData, logTTRes] = responses;

      if (tiktokSummaryData.status === "fulfilled") {
        setDataTiktok(tiktokSummaryData.value.data);
      }
      setIsLoadingUserData(false);

      if (fanOnlineData.status === "fulfilled") {
        setDataFanOnline(fanOnlineData.value.data);
      }
      setIsLoadingFanOnline(false);

      if (logTTRes.status === "fulfilled") {
        const logTT = logTTRes.value.data;
        setLogTTData(logTT);
      }
    });

    setLoadingChannelFilter(false);
    setLoadingRange(false);
  };

  useEffect(() => {
    handleGetDataStatistic();
  }, [channels, from, to]);

  const handleGetDataStatistic = () => {
    if (projectId) {
      const data = {
        params: {
          project_id: projectId,
          // time: `${range} days`,
          channel_ids: channels,
          from,
          to,
        },
      };
      getAPITiktok(data);
    }
  };

  return (
    <>
      <Col lg={12} className="px-8">
        <LogHeader
          cnt_channel_error={logTTData?.cnt_channel_error}
          cnt_channel_success={logTTData?.cnt_channel_success}
          last_time={logTTData?.last_time}
          currentTab={PLATFORM_NAME.TIKTOK}
        />
      </Col>

      {/* <Content> */}
      <div>
        {/* <Block> */}
        <Col lg={12}>
          <Row
            xxl="5"
            xl="4"
            lg="3"
            xs="2"
            style={{ padding: "14px 7px 7px 7px" }}
          >
            <OverviewUserTiktok
              data={dataTiktok}
              isLoading={isLoadingUserData}
            />
            <OverviewVideo data={dataTiktok} isLoading={isLoadingUserData} />
          </Row>
        </Col>
        {/* </Block> */}

        {/* <Block> */}
        <Col>
          <Row>
            <Col xxl={6} xl={6}>
              <TopCity
                data={dataTiktok?.countries || []}
                isLoading={isLoadingUserData}
              />
            </Col>
            <FanOnlineChart
              series={dataFanOnline}
              isLoading={isLoadingUserData}
            />
          </Row>
        </Col>
        {/* </Block> */}

        <Block>
          <Col lg={12} style={{ paddingTop: "20px" }}>
            <Card>
              <CardHeader className="align-items-center d-flex bg-slate-50">
                <h4
                  className="card-title mb-0 flex-grow-1"
                  style={{ fontSize: "16px" }}
                >
                  {t("account_analytics")}
                </h4>
              </CardHeader>
              <CardBody>
                <FrequencyTiktokTable
                  projectId={projectId}
                  range={range}
                  from={from}
                  to={to}
                />
              </CardBody>
            </Card>
          </Col>
        </Block>
      </div>

      {/* </Content> */}
    </>
  );
};

export default TikTok;
