import React, { useEffect, useRef, useState } from "react";
import FacebookPlatform from "../../../../images/socailNetword/facebook.svg";
import InstagramPlatform from "../../../../images/socailNetword/instagram.svg";
import TiktokPlatform from "../../../../images/socailNetword/tiktok.svg";
import YoutubePlatform from "../../../../images/socailNetword/youtube.svg";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  DropdownItem,
  Spinner,
  Dropdown,
  Modal,
  ModalBody,
} from "reactstrap";
import style from "../../index.module.scss";
import CommingSoon from "../../../../images/dashboard/commingsoon.png";
import { useDispatch } from "react-redux";
import { resetDashboard } from "../../../../reducers/dashboardSlice";
import { getChannels } from "../../../../../api/createPost";
import { Icon } from "../../../../../components/Component";
import { useTranslation } from "react-i18next";
import {
  PLATFORM_FACEBOOK,
  PLATFORM_FACEBOOKGROUP,
  PLATFORM_INSTAGRAM,
  PLATFORM_TIKTOK,
  PLATFORM_YOUTUBE,
} from "../../../../utils/Utils";

const HeaderDashboard = ({
  selectedPlatform,
  setSelectedPlatForm,
  isEnterprise,
  range,
  setRange,
  setChannels,
  loadingChannelFilter,
  setLoadingChannelFilter,
  loadingRange,
  setLoadingRange,
}) => {
  const [openModel, setOpenModel] = useState(false);
  const { t } = useTranslation();
  const ranges = [7, 14, 30, 60, 90];
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const projectId = userInfo.last_project_active;
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();
  const [modalWarning, setModalWarning] = useState(false);
  const tabStatistic = [
    {
      key: "1",
      icon: FacebookPlatform,
      title: "Facebook",
    },
    {
      key: "2",
      icon: TiktokPlatform,
      title: "Tiktok",
    },
    {
      key: "3",
      icon: YoutubePlatform,
      title: "Youtube",
    },
    {
      key: "4",
      icon: InstagramPlatform,
      title: "Instagram",
    },
  ];
  const toggleWarning = () => {
    setModalWarning(!modalWarning);
  };

  const toggleChanel = () => {
    setIsOpen(!isOpen);
  };

  /**
   * CHANNELS
   */
  const [optionChannels, setOptionChannels] = useState([]);
  const [listChannels, setListChannels] = useState([]);
  const optionChannelsEnd = useRef(false);
  useEffect(async () => {
    dispatch(resetDashboard());

    if (userInfo.last_project_active && !optionChannelsEnd.current) {
      // setGettingData(true);
      const data = {};
      const fetchData = async () => {
        return await getChannels(projectId, data);
      };
      const dataResponse = await fetchData();
      if (dataResponse.status) {
        const { channel: channels, favorite_channel: _favoriteChannels } =
          dataResponse.data;
        setListChannels(channels);
      }
    }
  }, []);

  useEffect(() => {
    const updateOptionChannels = (channels) => {
      return channels.map((channel) => ({
        ...channel,
        checked: true,
      }));
    };
    if (selectedPlatform === "Facebook") {
      const filteredChannels = listChannels.filter(
        (item) =>
          item.platform === PLATFORM_FACEBOOK ||
          item.platform === PLATFORM_FACEBOOKGROUP
      );
      setOptionChannels(updateOptionChannels(filteredChannels));
    }
    if (selectedPlatform === "Tiktok") {
      const filteredChannels = listChannels.filter(
        (item) => item.platform === PLATFORM_TIKTOK
      );
      setOptionChannels(updateOptionChannels(filteredChannels));
    }
    if (selectedPlatform === "Youtube") {
      const filteredChannels = listChannels.filter(
        (item) => item.platform === PLATFORM_YOUTUBE
      );
      setOptionChannels(updateOptionChannels(filteredChannels));
    }
    if (selectedPlatform === "Instagram") {
      const filteredChannels = listChannels.filter(
        (item) => item.platform === PLATFORM_INSTAGRAM
      );
      setOptionChannels(updateOptionChannels(filteredChannels));
    }
  }, [selectedPlatform, listChannels]);

  // const [channels, setChannels] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(true);
  const handleCheckAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAllChecked(isChecked);
    setOptionChannels((prevOptionChannels) =>
      prevOptionChannels.map((channel) => {
        return { ...channel, checked: isChecked };
      })
    );
  };
  const handleCheckboxChange = (index) => {
    setOptionChannels((prevOptionChannels) => {
      let updatedChannels = [...prevOptionChannels];
      updatedChannels[index].checked = !updatedChannels[index].checked;
      return updatedChannels;
    });
  };

  const handleCancelClick = () => {
    toggleChanel();
  };

  const handleSelectPlatform = (platform) => {
    setSelectedPlatForm(platform);
  };

  const handleConfirm = () => {
    setLoadingChannelFilter(true);
    if (selectAllChecked) {
      setChannels([]);
    } else {
      const listChannelChecked = optionChannels
        .filter((x) => x.checked)
        .map((item) => item._id);
      if (listChannelChecked.length === 0) {
        toggleWarning();
        setLoadingChannelFilter(false);
      } else {
        setChannels(listChannelChecked);
      }
    }
    toggleChanel();
  };

  useEffect(() => {
    const allChecked = optionChannels.every(
      (channel) => channel.checked === true
    );
    if (selectAllChecked !== allChecked) {
      setSelectAllChecked(allChecked);
    }
  }, [optionChannels, selectAllChecked]);

  return (
    <div className={style.containerHeader}>
      <div className={style.containerIcon}>
        {tabStatistic?.map((tabName, index) => (
          <div
            className={style.bgIcon}
            style={
              selectedPlatform === tabName.title
                ? { borderBottom: "5px solid #ffc41d" }
                : {}
            }
            onClick={() => {
              if (tabName.title === "Instagram") {
                setOpenModel(true);
              } else {
                handleSelectPlatform(tabName.title);
              }
            }}
          >
            <img
              src={tabName.icon}
              alt={tabName.title}
              className={style.imgIcon}
              style={
                selectedPlatform === tabName.title
                  ? { border: "3px solid #ffc41d" }
                  : {}
              }
            />
          </div>
        ))}
      </div>
      <div className={style.dropDownContainer}>
        {isEnterprise && (
          <div className="d-flex flex-column flex-md-row align-items-md-center p-2">
            <Dropdown
              //   isOpen={isOpen}
              //   toggle={toggleChanel}
              className="w-100"
            >
              <DropdownToggle
                tag="a"
                className={`dropdown-toggle btn btn-white btn-dim btn-outline-light w-100 ${style.dropdownToggle}`}
                style={{
                  border: "1px solid",
                  borderColor: "#AEBFE7",
                  borderRadius: "8px",
                  padding: "8px 20px",
                  backgroundColor: "transparent",
                  width: "200px !important",
                }}
              >
                Nhân viên
              </DropdownToggle>
              <DropdownMenu>Nhân viên</DropdownMenu>
            </Dropdown>
          </div>
        )}
        <div className="d-flex flex-column flex-md-row align-items-md-center p-2">
          <div className={`${style.channel}`}>
            <Dropdown isOpen={isOpen} toggle={toggleChanel} className="w-100">
              <DropdownToggle
                tag="a"
                className="dropdown-toggle btn btn-white btn-dim btn-outline-light w-100"
                style={{
                  border: "1px solid",
                  borderColor: "#AEBFE7",
                  borderRadius: "8px",
                  padding: "8px 20px",
                  backgroundColor: "transparent",
                }}
              >
                <span className="w-100">
                  <span className="d-md-inline" style={{ color: "#AEBFE7" }}>
                    {selectAllChecked
                      ? "Tất cả các kênh"
                      : `Chọn ${optionChannels?.reduce((count, item) => {
                          // Nếu trường "checked" của item bằng true, tăng biến count lên 1
                          if (item.checked === true) {
                            return count + 1;
                          }
                          return count;
                        }, 0)} kênh`}
                    {loadingChannelFilter && (
                      <Spinner size="sm" color="light" className="ml-1" />
                    )}
                  </span>
                </span>
                <Icon
                  className="d-sm-inline"
                  style={{ color: "#AEBFE7" }}
                  name="chevron-down"
                ></Icon>
              </DropdownToggle>
              <DropdownMenu style={{ width: "100%" }}>
                <div
                  className="px-2 my-2 rounded"
                  style={{ height: "300px", overflow: "auto" }}
                >
                  <ul className="link-tidy">
                    <li
                      className="d-flex flex-row justify-content-between"
                      style={{ backgroundColor: "#F9FAFB" }}
                    >
                      <div
                        className="custom-control custom-control-sm custom-checkbox p-0 m-0"
                        style={{ order: "2" }}
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input p-0 m-0"
                          checked={selectAllChecked}
                          onChange={handleCheckAllChange}
                          id="check-all"
                        />
                        <label
                          className="custom-control-label p-0 m-0"
                          htmlFor="check-all"
                        ></label>
                      </div>
                      <label
                        htmlFor="check-all-pr"
                        className="p-0 m-0"
                        style={{ order: "1" }}
                      >
                        Chọn tất cả
                      </label>
                    </li>
                    {optionChannels.map((item, index) => (
                      <li
                        key={index}
                        className="d-flex flex-row justify-content-between"
                        style={{ backgroundColor: "#F9FAFB" }}
                      >
                        <div
                          className="custom-control custom-control-sm custom-checkbox p-0 m-0"
                          style={{ order: "2" }}
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input p-0"
                            checked={item.checked}
                            onChange={() => handleCheckboxChange(index)}
                            id={`check-for-${index}`}
                          />
                          <label
                            className="custom-control-label p-0 my-0"
                            htmlFor={`check-for-${index}`}
                          ></label>
                        </div>
                        <label
                          htmlFor={`check-for-${index}`}
                          className="pr-5 my-0"
                          style={{ order: "1" }}
                        >
                          {item.name}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
                <div
                  className="h-100 d-flex flex-row p-2"
                  style={{ gap: "8px" }}
                >
                  <button
                    onClick={handleCancelClick}
                    className={`w-100 ${style.btnCance}`}
                  >
                    Huỷ
                  </button>
                  <button
                    onClick={handleConfirm}
                    className={`w-100 ${style.btnConfirm}`}
                  >
                    Áp dụng
                  </button>
                </div>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="mt-2 mt-md-0">
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                style={{
                  border: "1px solid",
                  borderColor: "#AEBFE7",
                  borderRadius: "8px",
                  padding: "8px 20px",
                  backgroundColor: "transparent",
                }}
              >
                <Icon
                  className="d-sm-inline"
                  style={{ color: "#AEBFE7" }}
                  name="calendar"
                ></Icon>
                <span>
                  <span className="d-md-inline" style={{ color: "#AEBFE7" }}>
                    {t("overview.range_text", { days: range })}
                    {loadingRange && (
                      <Spinner size="sm" color="light" className="ml-1" />
                    )}
                  </span>
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <ul className="link-list-opt no-bdr">
                  {ranges.map((r) => (
                    <li key={r}>
                      <DropdownItem
                        onClick={(e) => {
                          e.preventDefault();
                          setLoadingRange(r !== range);
                          setRange(r);
                        }}
                      >
                        {t("overview.range_text", { days: r })}
                      </DropdownItem>
                    </li>
                  ))}
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
        {/* <div className="d-flex flex-column flex-md-row align-items-md-center p-2">
          <Dropdown
            //   isOpen={isOpen}
            //   toggle={toggleChanel}
            className="w-100"
          >
            <DropdownToggle
              tag="a"
              className={`dropdown-toggle btn btn-white btn-dim btn-outline-light w-100 ${style.dropdownToggle}`}
              style={{
                border: "1px solid",
                borderColor: "#AEBFE7",
                borderRadius: "8px",
                padding: "8px 20px",
                backgroundColor: "transparent",
                width: "200px !important",
              }}
            >
              Thời gian
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem>Thời gian</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div> */}
      </div>

      <Modal isOpen={openModel} className="modal-dialog-centered">
        <ModalBody className="modal-body-lg text-center">
          <div className="nk-modal">
            <h4 className="nk-modal-title">Tính năng đang phát triển</h4>
            <div>
              <img
                src={CommingSoon}
                alt="commingsoon"
                style={{ width: "80%", height: "auto" }}
                className="img-fluid"
              />
            </div>
            <div className="nk-modal-action">
              <Button
                size="md"
                color="warning"
                className="btn-mw"
                onClick={() => setOpenModel(false)}
              >
                OK
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default HeaderDashboard;
