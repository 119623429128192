import { API } from "../libs/client";

export const getListCalender = async (projectId, data) => {
  const uri = `/projects/${projectId}/posts/schedule`;
  const res = await API.get(uri, data);
  return res.data;
};

export const getListChannel = async (projectId , data) => {
  const uri = `/projects/${projectId}/posts/schedulesV2`;
  const res = await API.get(uri, data);
  return res.data;
};

export const deletePostBulk = async (projectId, data) => {
  const uri = `/projects/${projectId}/channels/post-bulk`;
  const res = await API.delete(uri,
    {
      data: data,
    });
  return res.data;
};

export const updateVersionSM = async (projectId) => {
  const uri = `projects/${projectId}/channels/smart-schedule/migrate-to-advance`;
  const res = await API.post(uri);
  return res.data;
};

export const PostsmartScheduleV2 = async (projectId,data) => {
  const uri = `projects/${projectId}/channels/smart-schedule/advance-setting`;
  const res = await API.post(uri,data);
  return res.data;
};

export const DeletesmartScheduleV2 = async (projectId, channel_id) => {
  const uri = `/projects/${projectId}/channels/smart-schedule/advance/${channel_id}`;
  const res = await API.delete(uri);
  return res.data;
};