import React from "react";
import DateTimeInput from "./DateTimeInput";
import { Icon } from "../../../../components/Component";
import "../styles.css";

const DetailDateSelect = ({
  startDate,
  setStartDate,
  dueDate,
  setDueDate,
  isEndedBeforeToday,
  completed,
}) => {
  return (
    <div className="detail-item">
      <Icon name="calendar" style={{ color: "#90959D", fontSize: "16px" }} />

      <div className="d-flex">
        <DateTimeInput date={startDate} setDate={setStartDate} />

        <span style={{ padding: "0 8px" }}>-</span>
        {dueDate ? (
          <DateTimeInput
            date={dueDate}
            setDate={setDueDate}
            isEndedBeforeToday={isEndedBeforeToday}
            completed={completed}
            started_at={startDate}
          />
        ) : (
          <DateTimeInput
            date={null}
            setDate={setDueDate}
            isIcon={true}
            popperPlacement="right-start"
            started_at={new Date(startDate * 1000)}
          />
        )}
      </div>
    </div>
  );
};

export default DetailDateSelect;
