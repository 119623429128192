import { Button } from "reactstrap";
import style from "./index.module.scss";
import hubDesc from "../../images/IntrolChat/hubDesc.png"
import hubIcon from "../../images/IntrolChat/HubIcon.png"
import { Img } from "../../../components/Component";

const IntrolChatDesc = () => {

    const handleToHub = () => {
        window.open("https://chat.so9.vn/","_blank")
    }
    return (
    <>
        <div className={`${style.hubdescWraper} d-flex align-items-center justify-content-start`}>
            <div className={`${style.hubHeader} d-flex justify-content-center align-items-center gap-2`}>
                <Img src={hubIcon}></Img>
                <p>SO9 HUB</p>
            </div>
            <p className="mb-4 mt-3">Quản lý tin nhắn, bình luận tập trung - Tối ưu tỷ lệ chuyển đổi!</p>
            <Button
            onClick={()=>{
                handleToHub();
            }}
            >TRUY CẬP</Button>
            <Img src={hubDesc}></Img>

        </div>
    </>
    )
}

export default IntrolChatDesc;
