import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { Icon, RSelect, Img } from "../../../components/Component";
import style from "./index.module.scss";
import { Controller, useForm } from "react-hook-form";
import {
  getPlatformIcon,
} from "../../utils/Utils";
import ModalPost from "./ModalPost";
import ModalChannel from "./ModalChannel";
import { editCampaign, showCampaign } from "../../../api/campaign";
import { useSelector } from "react-redux";

const ModalEdit = ({
  modal,
  toggle,
  id,
  refetch,
  toggleSuccess,
  setMessage,
}) => {
  const project = useSelector((state) => state.project.project);
  const [data, setData] = useState({});
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [dataUpdate, setDataUpdate] = useState({});
  const [listChannel, setListChannel] = useState({});
  const [listPost, setListPost] = useState({});
  const optionMediaType = [
    {
      label: "Ảnh",
      value: "photo",
    },
    {
      label: "Video Thường",
      value: "video",
    },
    {
      label: "Video Ngắn",
      value: "reel",
    },
  ];
  const optionStatus = [
    {
      label: "Hoạt động",
      value: 1,
    },
    {
      label: "Không hoạt động",
      value: 0,
    },
  ];

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(async () => {
    if (modal) {
      setData({});
      setLoad(true);
      try {
        const res = await showCampaign(project._id, id, {});
        setData(res?.data);
        setListChannel({
          type: res?.data?.channel_info?.type,
          dataChannel: res?.data?.channel_info?.full_info,
        });
        setListPost({
          type: res?.data?.post_info?.type,
          dataPost: res?.data?.post_info?.ids,
        });
        setDataUpdate({
          name: res?.data?.name,
          status: res?.data?.status,
        });
        setLoad(false);
      } catch (error) {
        console.log(error);
      }
    }
  }, [modal]);
  const onEditSubmit = async () => {
    if (!dataUpdate?.name || listChannel?.dataChannel?.length === 0) {
      return;
    }
    setLoading(true);
    try {
      const requestData = {};

      if (dataUpdate?.name && dataUpdate.name !== data?.name) {
        requestData.name = dataUpdate.name;
      }

      if (dataUpdate?.status !== data?.status) {
        requestData.status = dataUpdate.status;
      }

      if (listChannel.dataChannel.length > 0) {
        requestData.channel_info = {
          type: listChannel.type,
          ids: listChannel.dataChannel.map((item) => item._id || item.id),
        };
      }

      if (listPost) {
        requestData.post_info = {
          type: listPost?.type,
          ids: listPost?.dataPost?.map((item) =>
            item?.value ? item?.value : item
          ),
        };
      }

      const res = await editCampaign(project._id, id, requestData);
      if (res?.status) {
        setMessage("Cập nhật thành công");
        refetch();
        setData({});
        toggle();
        toggleSuccess();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const [modalSub, setModalSub] = useState({
    post: false,
    channel: false,
  });
  const toggleModalSub = (type) => {
    setModalSub((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };
  const handleTypeChange = (selectedOption) => {
    setData({ ...data, type: selectedOption ? selectedOption.value : "" });
  };
  const handleDeleteChannel = (item) => {
    const fullInfo = listChannel?.dataChannel;

    // Lọc bỏ phần tử khỏi mảng full_info
    const fullInfoUpdate = fullInfo?.filter(
      (v) => v?.id !== item?.id || v?._id !== item?._id
    );

    // Cập nhật data với channel_info mới
    setListChannel({ ...listChannel, dataChannel: fullInfoUpdate });
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalHeader
        toggle={toggle}
        close={
          <button className="close" onClick={toggle}>
            <Icon name="cross" />
          </button>
        }
      >
        Sửa chiến dịch
      </ModalHeader>
      <ModalBody>
        {load ? (
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
        ) : (
          <Form className="gy-2" onSubmit={handleSubmit(onEditSubmit)}>
            <Row className="d-flex align-items-center">
              <Col md="3">Tên chiến dịch</Col>
              <Col md="9">
                <FormGroup>
                  <input
                    className="form-control px-[8px]"
                    type="text"
                    name="name"
                    placeholder="Tên chiến dịch"
                    value={dataUpdate?.name}
                    onChange={(e) =>
                      setDataUpdate({ ...dataUpdate, name: e.target.value })
                    }
                  />
                  {!dataUpdate?.name && (
                    <p className="invalid">Bạn phải nhập tên chiến dịch</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="d-flex align-items-center">
              <Col md="3">Loại chiến dịch</Col>
              <Col md="9">
                <FormGroup style={{ width: "40%" }}>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field: { onBlur, onChange } }) => (
                      <RSelect
                        style={{ with: "50%" }}
                        options={optionMediaType}
                        value={optionMediaType.find(
                          (option) => option.value === data?.type
                        )}
                        isDisabled={true}
                        placeholder={"Chọn loại chiến dịch"}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="d-flex align-items-center ">
              <Col md="3">Trạng thái</Col>
              <Col md="9">
                <FormGroup style={{ width: "40%" }}>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field: { onBlur, onChange } }) => (
                      <RSelect
                        style={{ with: "50%" }}
                        options={optionStatus}
                        value={optionStatus.find(
                          (option) => option.value === dataUpdate?.status
                        )}
                        onChange={(e) =>
                          setDataUpdate({ ...dataUpdate, status: e.value })
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div
              style={{
                width: "100%",
                borderBottom: "2px solid #E5E7EB",
                padding: "0px !important",
              }}
            ></div>
            <Row>
              <Col xs="12" md="6">
                <p>Danh sách bài đăng</p>
                <table className={`table table-reponsive postFastTable `}>
                  <thead style={{ background: "#F1F5FF" }}>
                    <tr>
                      <th
                        style={{ width: "95%" }}
                        className={`${style.headPost} px-2`}
                      >
                        {(listPost?.type === "all_post" &&
                          `Tất cả bài trong kho nội dung (${
                            listPost?.total || data?.post_info?.statistic?.total
                          }) `) ||
                          (listPost?.type === "topic" &&
                            `Chủ đề (${listPost?.dataPost?.length}) `) ||
                          (listPost?.type === "special_post" &&
                            `Chọn bài thủ công (${listPost?.dataPost?.length}) `)}
                      </th>
                      <th
                        style={{ width: "5%" }}
                        className={`${style.headPost} text-center`}
                      >
                        <Icon
                          style={{ color: "#0EA5E9", cursor: "pointer" }}
                          name="edit"
                          onClick={() => toggleModalSub("post")}
                        />
                      </th>
                    </tr>
                  </thead>

                  <div className="pl-2 pt-2">
                    <div style={{ color: "#10B981" }}>
                      <Icon name="check-circle-fill" />
                      <span>
                        Đang chạy {data?.post_info?.statistic?.success}
                      </span>
                    </div>
                    <div style={{ color: "#E11D48" }}>
                      <Icon name="alert" />
                      <span>
                        Bài lỗi hệ thống tự loại khỏi danh sách{" "}
                        {data?.post_info?.statistic?.errors}
                      </span>
                    </div>
                  </div>
                </table>
              </Col>
              <Col xs="12" md="6">
                <p>Kênh phân phối</p>
                <div style={{ maxHeight: "250px", overflow: "auto" }}>
                  <table className={`table table-reponsive postFastTable `}>
                    <thead style={{ background: "#F1F5FF" }}>
                      <tr>
                        <th
                          style={{
                            width: "90%",
                          }}
                          className={`${style.headPost} px-2`}
                        >
                          {listChannel?.type === "channel"
                            ? `Kênh ${listChannel?.dataChannel?.length}`
                            : `Nhóm kênh ${listChannel?.dataChannel?.length}`}
                        </th>
                        <th
                          style={{ width: "10%" }}
                          className={`${style.headPost} text-center`}
                        >
                          <Icon
                            style={{ color: "#0EA5E9", cursor: "pointer" }}
                            name="edit"
                            onClick={() => toggleModalSub("channel")}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {listChannel?.dataChannel?.map((item, index) => (
                        <tr
                          key={index}
                          style={{ fontSize: "12px", cursor: "pointer" }}
                          // onClick={() => setDetailPost(post)}
                        >
                          <td
                            className={`${style.truncateCell} ${style.Td}`}
                            style={{
                              fontWeight: "bold",
                            }}
                            // onClick={() => handleViewDetailPost({ item: post })}
                          >
                            {listChannel?.type === "channel" ? (
                              <div className="d-flex align-items-center">
                                <div style={{ position: "relative" }}>
                                  <Img
                                    src={item?.image || item?.picture}
                                    alt="img"
                                    width="2rem"
                                    size="square"
                                    style={{
                                      background: "#D9D9D9",
                                    }}
                                  />
                                  <Img
                                    src={getPlatformIcon(item.platform)}
                                    alt="icon_platform"
                                    className="rounded-circle"
                                    style={{
                                      position: "absolute",
                                      bottom: 0,
                                      right: 0,
                                      height: "0.8rem",
                                      width: "0.8rem",
                                      objectFit: "cover",
                                    }}
                                  />
                                </div>
                                <div className="d-flex flex-column justify-content-center ml-2">
                                  <span>{item?.name}</span>
                                </div>
                              </div>
                            ) : (
                              <span>{item?.name}</span>
                            )}
                          </td>
                          <td
                            className={`${style.truncateCell} ${style.Td} `}
                            style={{
                              fontWeight: "bold",
                            }}
                            // onClick={() => handleViewDetailPost({ item: post })}
                          >
                            <Icon
                              name="trash"
                              onClick={() => handleDeleteChannel(item)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {listChannel?.dataChannel?.length === 0 && (
                    <FormText color="danger">Bạn phải chọn trang</FormText>
                  )}
                </div>
              </Col>
            </Row>
            <div className={style.btnCreatePost}>
              <FormGroup className="d-flex justify-content-end">
                <div>
                  <Button
                    color="light"
                    className="mr-2"
                    onClick={(e) => {
                      e.preventDefault();
                      toggle();
                    }}
                  >
                    <span style={{ textTransform: "uppercase" }}>Huỷ</span>
                  </Button>
                  <Button
                    type="submit"
                    style={{ background: "#2C4B94" }}
                    disabled={loading}
                  >
                    <span style={{ textTransform: "uppercase", color: "#fff" }}>
                      {loading && <Spinner size="sm" />}Lưu lại
                    </span>
                  </Button>
                </div>
              </FormGroup>
            </div>
          </Form>
        )}
      </ModalBody>
      <ModalPost
        modal={modalSub.post}
        toggle={() => toggleModalSub("post")}
        setListPost={setListPost}
        listPost={listPost}
      />
      <ModalChannel
        modal={modalSub.channel}
        setListChannel={setListChannel}
        toggle={() => toggleModalSub("channel")}
        listChannel={listChannel}
      />
    </Modal>
  );
};

export default ModalEdit;
