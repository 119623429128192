import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { Icon, RSelect } from "../../../components/Component";
import style from "./index.module.scss";
import { Controller, useForm } from "react-hook-form";
import { createTopic } from "../../../api/warehouse";
import { useTranslation } from "react-i18next";

const ModalEditMulti = (props) => {
  const { modal, handleEditMulti, loading, toggle, optionTopic, refetchTopic } =
    props;
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const [formData, setFormData] = useState(null);

  const triggerCallEvent = async (inputValue) => {
    try {
      const res = await createTopic(userInfo.last_project_active, {
        name: inputValue,
      });
      if (res.status) {
        refetchTopic();
      }
    } catch (error) {
      console.log(error);
    }
  };

  let inputChangeTimeout;
  const handleInputChange = (inputValue, { action }) => {
    const lowerCasedInputValue = inputValue.toLowerCase();
    const lowerCasedOptions = optionTopic?.map((option) =>
      option.label.toLowerCase()
    );
    clearTimeout(inputChangeTimeout);
    if (
      action === "input-change" &&
      !lowerCasedOptions.some((option) => option.includes(lowerCasedInputValue))
    ) {
      inputChangeTimeout = setTimeout(() => {
        triggerCallEvent(inputValue);
      }, 1000);
    }
  };

  const onEditSubmit = async (formvalue) => {
    handleEditMulti(formvalue);
  };

  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
      <ModalHeader
        toggle={toggle}
        close={
          <button className="close" onClick={toggle}>
            <Icon name="cross" />
          </button>
        }
      >
        {t("ware_house.modal.edit_bulk_posts")}
      </ModalHeader>
      <ModalBody>
        <Form className="gy-2" onSubmit={handleSubmit(onEditSubmit)}>
          <Row className="d-flex align-items-center ">
            <Col md="3">{t("ware_house.modal.topic")}</Col>
            <Col md="9" className="pl-0">
              <FormGroup style={{ width: "40%" }}>
                <Controller
                  name="topic"
                  control={control}
                  rules={{
                    required: t("ware_house.message.you_need_choose_topic"),
                  }}
                  render={({ field: { onBlur, onChange } }) => (
                    <RSelect
                      style={{ with: "50%" }}
                      options={optionTopic}
                      placeholder={t("ware_house.placeholder.choose_topic")}
                      defaultValue={optionTopic[0]}
                      noOptionsMessage={() => (
                        <>
                          <Spinner size="sm" className="mr-2" />
                          <span>{t("ware_house.modal.creating")}</span>
                        </>
                      )}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          topic: e.value,
                        });
                        setValue("topic", e.value);
                      }}
                      onInputChange={handleInputChange}
                    />
                  )}
                />
                {errors.topic && (
                  <FormText color="danger">
                    {" "}
                    {errors[`topic`]?.message}
                  </FormText>
                )}
              </FormGroup>
            </Col>
          </Row>
          <div
            style={{
              width: "100%",
              borderBottom: "2px solid #E5E7EB",
              padding: "0px !important",
            }}
          ></div>
          <div className={style.btnCreatePost}>
            <FormGroup className="d-flex justify-content-end">
              <div>
                <Button
                  color="light"
                  className="mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    toggle();
                  }}
                >
                  <span style={{ textTransform: "uppercase" }}>{t("ware_house.modal.cancel")}</span>
                </Button>
                <Button
                  type="submit"
                  style={{ background: "#2C4B94" }}
                  disabled={loading}
                >
                  <span
                    style={{
                      textTransform: "uppercase",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {loading && <Spinner size="sm" />}{t("ware_house.modal.save")}
                  </span>
                </Button>
              </div>
            </FormGroup>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditMulti;
