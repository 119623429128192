import React, { useMemo } from "react";
import styles from "../PostDetail.module.scss";
import {
  PLATFORM_FACEBOOK,
  PLATFORM_INSTAGRAM,
  PLATFORM_PINTEREST,
  PLATFORM_THREADS,
  PLATFORM_TIKTOK,
  PLATFORM_YOUTUBE,
} from "../../../utils/Utils";
import {
  PreviewFaceBook,
  PreviewInstagram,
  PreviewPinterest,
  PreviewShort,
  PreviewThreads,
  PreviewYouTube,
} from "../../PostCompose/components/Preview";
import { Spinner } from "reactstrap";

const PlatformContent = ({ postDetail, isLoadingPostDetail }) => {
  const faceChannels = useMemo(() => {
    return postDetail?.channel?.platform === PLATFORM_FACEBOOK;
  }, [postDetail]);

  const instaChannels = useMemo(() => {
    return postDetail?.channel?.platform === PLATFORM_INSTAGRAM;
  }, [postDetail]);

  const pinChannels = useMemo(() => {
    return postDetail?.channel?.platform === PLATFORM_PINTEREST;
  }, [postDetail]);
  const youtubeChannels = useMemo(() => {
    return postDetail?.channel?.platform === PLATFORM_YOUTUBE;
  }, [postDetail]);
  const tiktokChannels = useMemo(() => {
    return postDetail?.channel?.platform === PLATFORM_TIKTOK;
  }, [postDetail]);
  const threadsChannels = useMemo(() => {
    return postDetail?.channel?.platform === PLATFORM_THREADS;
  }, [postDetail]);

  return (
    <div className={styles.contentPage}>
      {!isLoadingPostDetail ? (
        <div>
          {faceChannels &&
            postDetail &&
            Object.keys(postDetail)?.length > 0 && (
              <PreviewFaceBook
                images={postDetail?.media ?? []}
                content={postDetail?.content}
                channels={{
                  channel: [
                    {
                      ...postDetail?.channel,
                      check: true,
                      created_at: postDetail?.scheduled_at * 1000,
                    },
                  ],
                }}
                carosel={postDetail?.caroselSetting ?? []}
                isVideo={postDetail?.isVideo ?? false}
                video={postDetail?.selectedVideo ?? []}
                status={postDetail?.text_format_preset_id || null}
              />
            )}
          {instaChannels &&
            postDetail &&
            Object.keys(postDetail)?.length > 0 && (
              <PreviewInstagram
                images={postDetail?.media ?? []}
                video={postDetail?.selectedVideo ?? []}
                content={postDetail?.content}
                channels={{
                  channel: [
                    {
                      ...postDetail?.channel,
                      check: true,
                      created_at: postDetail?.scheduled_at * 1000,
                    },
                  ],
                }}
                isVideo={postDetail?.isVideo ?? false}
              />
            )}
          {pinChannels && postDetail?.media.length > 0 && (
            <PreviewPinterest
              images={postDetail?.media ?? []}
              content={postDetail?.content}
              channels={{
                channel: [
                  {
                    ...postDetail?.channel,
                    check: true,
                    created_at: postDetail?.scheduled_at * 1000,
                  },
                ],
              }}
            />
          )}
          {youtubeChannels && (
            <PreviewYouTube
              images={[]}
              video={postDetail?.media ?? []}
              content={postDetail?.desc ?? "The title"}
              channels={{
                channel: [
                  {
                    ...postDetail?.channel,
                    check: true,
                    created_at: postDetail?.scheduled_at * 1000,
                  },
                ],
              }}
            />
          )}
          {tiktokChannels && (
            <PreviewShort
              title={postDetail?.title}
              isVideo={postDetail?.video_id ? true : false}
              video={postDetail?.media ?? []}
              content={postDetail?.content}
              images={postDetail?.media ?? []}
            />
          )}
          {threadsChannels && (
            <PreviewThreads
              content={postDetail?.content}
              channels={{
                channel: [
                  {
                    ...postDetail?.channel,
                    check: true,
                    created_at: postDetail?.scheduled_at * 1000,
                  },
                ],
              }}
              images={postDetail?.media ?? []}
              isVideo={postDetail?.video_id ? true : false}
              video={postDetail?.media ?? []}
              isManage
            />
          )}
        </div>
      ) : (
        <div
          className={"d-flex align-items-center justify-content-center pb-2"}
          style={{ width: "100%", height: "100%", minHeight: "600px" }}
        >
          <Spinner
            size="lg"
            color="dark"
            style={{ width: "3rem", height: "3rem" }}
          />
        </div>
      )}
    </div>
  );
};

export default PlatformContent;
