import React, { useState } from "react";
import { Col, FormGroup, ModalBody } from "reactstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toastError, toastSuccess } from "../../../../libs/toasts";
import { createSectionTask, updateSectionTask } from "../../../../api/kanban";
import { Button, Icon } from "../../../../components/Component";
import { LoadingItem } from "../../Dashboard_v2/CommonItem/LoadingItem";

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getRandomTheme = () => {
  const themes = [
    "blue-dim",
    "blue",
    "azure-dim",
    "azure",
    "indigo-dim",
    "indigo",
    "purple-dim",
    "purple",
    "pink-dim",
    "pink",
    "orange-dim",
    "orange",
    "teal-dim",
    "teal",
    "primary-dim",
    "primary",
    "secondary-dim",
    "secondary",
    "success-dim",
    "success",
    "info-dim",
    "info",
    "warning-dim",
    "warning",
    "danger-dim",
    "danger",
    "dark-dim",
    "dark",
    "gray-dim",
    "gray",
    "lighter",
    "light",
  ];
  const randomIndex = Math.floor(Math.random() * themes.length);
  return themes[randomIndex];
};

const KanbanSectionForm = ({
  toggle,
  activeGroup,
  section,
  prioritySize,
  setInitialData,
}) => {
  const { t } = useTranslation();
  const userInfo = JSON.parse(localStorage.getItem("userInfor"));

  const [loadingCreateSection, setLoadingCreateSection] = useState(false);

  const initialFormData = {
    name: section ? section?.name : "",
    color: section ? section?.color : getRandomColor(),
    group_id: activeGroup?._id,
  };

  if (!section) {
    initialFormData.priority = prioritySize + 1;
  }

  const [formData, setFormData] = useState(initialFormData);

  const submitForm = async () => {
    const projectId = userInfo.last_project_active;
    setLoadingCreateSection(true);

    try {
      if (section) {
        await updateSectionTask(projectId, section?._id, formData);

        setInitialData((prevData) => {
          const updatedColumns = {
            ...prevData.columns,
            [section?._id]: {
              ...prevData.columns[section._id],
              ...formData,
            },
          };

          return {
            ...prevData,
            columns: updatedColumns,
          };
        });
        toastSuccess(t("task_management.toast.update_section_success"));
      } else {
        const res = await createSectionTask(projectId, formData);

        const newSection = res.data;

        setInitialData((prevData) => {
          const updatedColumns = {
            ...prevData.columns,
            [newSection._id]: { ...newSection, cnt_task: 0, tasks: [] },
          };

          const updatedColumnOrder = [...prevData.columnOrder, newSection._id];

          return {
            ...prevData,
            columns: updatedColumns,
            columnOrder: updatedColumnOrder,
          };
        });

        toastSuccess(t("task_management.toast.create_section_success"));
      }
    } catch (e) {
      setLoadingCreateSection(false);
      toggle();
      if (section) {
        toastError(t("task_management.toast.update_section_failed"));
      } else {
        toastError(t("task_management.toast.create_section_failed"));
      }
      console.error(e);
    } finally {
      setLoadingCreateSection(false);
      toggle();
    }
  };

  const { handleSubmit } = useForm();
  return (
    <ModalBody>
      <Button className="close" onClick={() => toggle()}>
        <Icon name="cross-sm"></Icon>
      </Button>
      <div className="p-2">
        <h5 className="title">
          {" "}
          {section
            ? t("task_management.form.update")
            : t("task_management.form.add")}{" "}
          Section
        </h5>
        <div className="mt-4">
          <form className="row gy-4" onSubmit={handleSubmit(submitForm)}>
            <Col className="col-12">
              <FormGroup>
                <label className="form-label">
                  {t("task_management.form.section_title")}
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      name: e.target.value,
                    })
                  }
                  placeholder={t(
                    "task_management.placeholder.please_type_your_section_title"
                  )}
                  required
                  className="form-control"
                />
              </FormGroup>
            </Col>
            <Col className="col-4">
              <FormGroup>
                <label className="form-label">
                  {t("task_management.form.select_color")}
                </label>
                <input
                  style={{ cursor: "pointer" }}
                  type="color"
                  name="color"
                  value={formData.color}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      color: e.target.value,
                    })
                  }
                  className="form-control"
                />
              </FormGroup>
            </Col>
            <Col className="col-12">
              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                <li>
                  <Button
                    className="btn d-flex justify-content-center align-center"
                    color="primary"
                    size="md"
                    type="submit"
                  >
                    {loadingCreateSection ? (
                      <div
                        style={{
                          display: "flex",
                          marginLeft: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <LoadingItem
                          style={{
                            width: "16px",
                            height: "16px",
                            color: "#fff",
                          }}
                        />
                      </div>
                    ) : section ? (
                      t("task_management.form.edit_section")
                    ) : (
                      t("task_management.form.add_section")
                    )}
                  </Button>
                </li>
                <li>
                  <Button onClick={() => toggle()} className="link link-light">
                    {t("task_management.form.cancel")}
                  </Button>
                </li>
              </ul>
            </Col>
          </form>
        </div>
      </div>
    </ModalBody>
  );
};

export default KanbanSectionForm;
