import React, { useState, useEffect, useRef, useMemo } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { findUpper } from "../../../utils/Utils";
import { filterRole, filterStatus } from "./UserData";
import {
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
  Spinner
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  RSelect,
  TooltipComponent,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { bulkActionOptions } from "../../../utils/Utils";
import { createCustomer, editCustomer, getAllCustomerTag, getCustomers, getDistrict, getProvince } from '../../../api/customer'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IconConnectChannel } from "../../components/svgIcon";
import { toast, ToastContainer } from "react-toastify";
import style from "../../components/ConnectSocialNetwork/index.module.scss";
import ModalSelectChannelPlatform from "../../components/ConnectSocialNetwork/ModalSelectChannelPlatform";

const UserListCompact = () => {
  const [provinceId, setProvinceId] = useState(0)
  const { t } = useTranslation()
  bulkActionOptions[0].label = t('customer.delete_customer')
  bulkActionOptions[1].label = t('customer.tag')
  const [data, setData] = useState([])
  const [customerTag, setCustomerTag] = useState([])
  const [province, setProvince] = useState([])
  const [district, setDistrict] = useState([])
  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [editId, setEditedId] = useState();
  const [formData, setFormData] = useState({
    name: "",
    emails: [""],
    phones: [""],
    tags: [],
  });
  const [tags, setTags] = useState([])
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");
  const [loading, setLoading] = useState(false)
  const optionCustomerPage = useRef(1);
  const optionCustomerEnd = useRef(false);

  // check connect channel
  const dataListChannel = useSelector((state) => state.channel.channelsInformation);
  const [isOpenModalSelectPlatform, setIsOpenModalSelectPlatform] = useState(false);
  const userInformation = JSON.parse(localStorage.getItem("userInfor"));
  const toggleIsOpenModalSelectPlatform = () => {
    setIsOpenModalSelectPlatform((v) => !v);
  };
  // end check connect channel

  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
      setData([...sortedData]);
    }
  };

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfor"));
    if (userInfo.last_project_active && !optionCustomerEnd.current) {
      const projectId = userInfo.last_project_active;
      const data = {
        params: {
          page: optionCustomerPage.current,
        },
      };
      const fetchData = async () => {
        setLoading(true)
        setProvince((await getProvince()).data.map(e => {
          return {
            value: e.id,
            label: e.name
          }
        }))
        getAllCustomerTag(projectId, data).then(res => {
          if (res.status) {
            const cusTag = res.data.map(e => {
              return {
                value: e._id,
                label: e.name
              }
            });
            setCustomerTag(cusTag);
          }
          else {
            setCustomerTag([]);
          }
        });
        return await getCustomers(projectId, data);
      };
      fetchData().then((response) => {
        if (response.status) {
          const data = response.data
          let newData = data.map(item => ({
            ...item,
            phones: item?.phones ? item.phones : [""]
          }))
          setData([...newData])
        }
        else {
          setData([]);
        }
        setLoading(false)
      })
      return () => { setData([]) }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (provinceId !== 0) {
      const fetchData = async () => {
        return await getDistrict(provinceId)
      }
      fetchData().then((res) => {
        const data = res.data.map(e => {
          return {
            value: e.id,
            label: e.name
          }
        })
        setDistrict(data)
      })
      // return () => { setDistrict([]) }
    }
  }, [provinceId])
  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    // if(editId !== undefined) {
    //   setModal({ edit: true }, { add: false });
    // }
    // return () =>{  setEditedId() }
  }, [editId])

  // function to change the selected property of an item
  const onSelectChange = (e, _id) => {
    let newData = data;
    let index = newData.findIndex((item) => item._id === _id);
    newData[index].checked = e.currentTarget.checked;
    setData([...newData]);
  };

  // function to set the action to be taken in table header
  const onActionText = (e) => {
    setActionText(e.value);
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name: "",
      emails: [""],
      phones: [""],
    });
    setTags([])
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };

  // submit function to add a new item
  const onFormSubmit = async (submitData) => {
    const { name, email, second_email, second_phone, phone, address_detail } = submitData;
    // const { tags } = formData
    console.log(tags)
    let submittedData = {
      "source": 1,
      "social_id": "12313123131",
      "name": name,
      "emails": second_email ? [email, second_email] : [email],
      "avatar": "asdad.png",
      "phones": second_phone ? [phone, second_phone] : [phone],
      "type": 1,
      "tags": tags,
      "address": address_detail ? address_detail : 'VN'
    };

    console.log(submittedData)
    const userInfo = JSON.parse(localStorage.getItem("userInfor"));
    const projectId = userInfo.last_project_active;

    createCustomer(projectId, submittedData)
      .then((resp) => {
        submittedData._id = resp.data._id
        setData([submittedData, ...data]);
        setTags([])
        resetForm();
        setModal({ edit: false }, { add: false });
      }).catch(e => console.log(e))

  };

  // submit function to update a new item
  const onEditSubmit = async (submitData) => {
    console.log(tags)
    const { name, email, second_email, second_phone, phone, address_detail } = submitData;
    let submittedData;
    let newitems = data;
    newitems.forEach((item) => {
      if (item._id === editId) {
        submittedData = {
          "source": 1,
          "social_id": "12313123131",
          "name": name,
          "emails": second_email ? [email, second_email] : [email],
          "avatar": "asdad.png",
          "phones": second_phone ? [phone, second_phone] : [phone],
          "type": 1,
          "tags": tags,
          "address": address_detail ? address_detail : "VN"
        };
        console.log(submittedData)
        const userInfo = JSON.parse(localStorage.getItem("userInfor"));
        const projectId = userInfo.last_project_active;
        editCustomer(projectId, editId, submittedData)
          .then((resp) => {
            // let newData = newitems.find(e => e._id = editId)
            // Object.assign(newData, submittedData)
            // console.log(newData)
            const data = newitems.map(e => (e._id === editId ? Object.assign(e, submittedData) : e))
            setData([...data])
            resetForm();
            setModal({ edit: false }, { add: false });
            // window.location.reload()
          }).catch(e => console.log(e))
      }
    });
  };

  // function that loads the want to editted data
  const onEditClick = async (id) => {
    data.forEach((item) => {
      if (item._id === id) {
        setFormData({
          name: item.name,
          emails: item.emails,
          status: item.status,
          phones: item.phones,
          tags: item.tags,
          address: item.address
        });
        setEditedId(id);
        setModal({ edit: true, add: false })
      }
    });
  };

  // function which fires on applying selected action
  const onActionClick = (e) => {
    if (actionText === "suspend") {
      let newData = data.map((item) => {
        if (item.checked === true) item.status = "Suspend";
        return item;
      });
      setData([...newData]);
    } else if (actionText === "delete") {
      let newData;
      newData = data.filter((item) => item.checked !== true);
      setData([...newData]);
    }
  };

  // function which selects all the items
  const selectorCheck = (e) => {
    let newData;
    newData = data.map((item) => {
      item.checked = e.currentTarget.checked;
      return item;
    });
    setData([...newData]);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return formData
    }, [formData])
  });

  useEffect(() => {
    if (modal.add) {
      reset({})
    } else reset(formData)
  }, [formData, modal.add])

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <Head title={t("customer.customer_list")}></Head>
      <Content>
        {userInformation.last_project_active !== "" && dataListChannel && dataListChannel.length > 0 ? (
          <>
            <BlockHead size="sm">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle tag="h3" page>
                    {t("customer.customer_list")}
                  </BlockTitle>
                  <BlockDes className="text-soft">
                    <p>You have tota {data.length} users.</p>
                  </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                  <div className="toggle-wrap nk-block-tools-toggle">
                    <Button
                      className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                      onClick={() => updateSm(!sm)}
                    >
                      <Icon name="menu-alt-r"></Icon>
                    </Button>
                    <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                      <ul className="nk-block-tools g-3">
                        <li>
                          <a
                            href="#export"
                            onClick={(ev) => {
                              ev.preventDefault();
                            }}
                            className="btn btn-white btn-outline-light"
                          >
                            <Icon name="download-cloud"></Icon>
                            <span>Export</span>
                          </a>
                        </li>
                        <li className="nk-block-tools-opt">
                          <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                            <Icon name="plus"></Icon>
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>

            <Block>
              <DataTable className="card-stretch">
                <div className="card-inner position-relative card-tools-toggle">
                  <div className="card-title-group">
                    <div className="card-tools">
                      <div className="form-inline flex-nowrap gx-3">
                        <div className="form-wrap">
                          <RSelect
                            options={bulkActionOptions}
                            className="w-130px"
                            placeholder="Action"
                            onChange={(e) => onActionText(e)}
                          />
                        </div>
                        <div className="btn-wrap">
                          <span className="d-none d-md-block">
                            <Button
                              disabled={actionText !== "" ? false : true}
                              color="light"
                              outline
                              className="btn-dim"
                              onClick={(e) => onActionClick(e)}
                            >
                              Apply
                            </Button>
                          </span>
                          <span className="d-md-none">
                            <Button
                              color="light"
                              outline
                              disabled={actionText !== "" ? false : true}
                              className="btn-dim  btn-icon"
                              onClick={(e) => onActionClick(e)}
                            >
                              <Icon name="arrow-right"></Icon>
                            </Button>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-tools mr-n1">
                      <ul className="btn-toolbar gx-1">
                        <li>
                          <a
                            href="#search"
                            onClick={(ev) => {
                              ev.preventDefault();
                              toggle();
                            }}
                            className="btn btn-icon search-toggle toggle-search"
                          >
                            <Icon name="search"></Icon>
                          </a>
                        </li>
                        <li className="btn-toolbar-sep"></li>
                        <li>
                          <div className="toggle-wrap">
                            <Button
                              className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                              onClick={() => updateTableSm(true)}
                            >
                              <Icon name="menu-right"></Icon>
                            </Button>
                            <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                              <ul className="btn-toolbar gx-1">
                                <li className="toggle-close">
                                  <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                    <Icon name="arrow-left"></Icon>
                                  </Button>
                                </li>
                                <li>
                                  <UncontrolledDropdown>
                                    <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                      <div className="dot dot-primary"></div>
                                      <Icon name="filter-alt"></Icon>
                                    </DropdownToggle>
                                    <DropdownMenu
                                      right
                                      className="filter-wg dropdown-menu-xl"
                                      style={{ overflow: "visible" }}
                                    >
                                      <div className="dropdown-head">
                                        <span className="sub-title dropdown-title">Filter Users</span>
                                        <div className="dropdown">
                                          <DropdownItem
                                            href="#more"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                            className="btn btn-sm btn-icon"
                                          >
                                            <Icon name="more-h"></Icon>
                                          </DropdownItem>
                                        </div>
                                      </div>
                                      <div className="dropdown-body dropdown-body-rg">
                                        <Row className="gx-6 gy-3">
                                          <Col size="6">
                                            <FormGroup>
                                              <label className="overline-title overline-title-alt">{t('customer.tag')}</label>
                                              <RSelect options={filterRole} placeholder={t('customer.tag')} />
                                            </FormGroup>
                                          </Col>
                                          <Col size="6">
                                            <FormGroup>
                                              <label className="overline-title overline-title-alt">{t('customer.address')}</label>
                                              <RSelect options={filterStatus} placeholder={t('customer.address')} />
                                            </FormGroup>
                                          </Col>
                                          <Col size="6">
                                            <FormGroup>
                                              <label className="overline-title overline-title-alt">{t('customer.order_status')}</label>
                                              <RSelect options={filterStatus} placeholder={t('customer.order_status')} />
                                            </FormGroup>
                                          </Col>

                                          <Col size="6">
                                            <FormGroup>
                                              <label className="overline-title overline-title-alt">{t('customer.created_at')}</label>
                                              <RSelect options={filterStatus} placeholder={t('customer.created_at')} />
                                            </FormGroup>
                                          </Col>
                                          <Col size="12">
                                            <FormGroup className="form-group">
                                              <Button color="secondary">Filter</Button>
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                      </div>
                                      <div className="dropdown-foot between">
                                        <a
                                          href="#reset"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                          className="clickable"
                                        >
                                          Reset Filter
                                        </a>
                                        <a
                                          href="#save"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                        >
                                          Save Filter
                                        </a>
                                      </div>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </li>
                                <li>
                                  <UncontrolledDropdown>
                                    <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                      <Icon name="setting"></Icon>
                                    </DropdownToggle>
                                    <DropdownMenu right className="dropdown-menu-xs">
                                      <ul className="link-check">
                                        <li>
                                          <span>Show</span>
                                        </li>
                                        <li className={itemPerPage === 10 ? "active" : ""}>
                                          <DropdownItem
                                            tag="a"
                                            href="#dropdownitem"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                              setItemPerPage(10);
                                            }}
                                          >
                                            10
                                          </DropdownItem>
                                        </li>
                                        <li className={itemPerPage === 15 ? "active" : ""}>
                                          <DropdownItem
                                            tag="a"
                                            href="#dropdownitem"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                              setItemPerPage(15);
                                            }}
                                          >
                                            15
                                          </DropdownItem>
                                        </li>
                                      </ul>
                                      <ul className="link-check">
                                        <li>
                                          <span>Order</span>
                                        </li>
                                        <li className={sort === "dsc" ? "active" : ""}>
                                          <DropdownItem
                                            tag="a"
                                            href="#dropdownitem"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                              setSortState("dsc");
                                              sortFunc("dsc");
                                            }}
                                          >
                                            DESC
                                          </DropdownItem>
                                        </li>
                                        <li className={sort === "asc" ? "active" : ""}>
                                          <DropdownItem
                                            tag="a"
                                            href="#dropdownitem"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                              setSortState("asc");
                                              sortFunc("asc");
                                            }}
                                          >
                                            ASC
                                          </DropdownItem>
                                        </li>
                                      </ul>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                    <div className="card-body">
                      <div className="search-content">
                        <Button
                          className="search-back btn-icon toggle-search active"
                          onClick={() => {
                            setSearchText("");
                            toggle();
                          }}
                        >
                          <Icon name="arrow-left"></Icon>
                        </Button>
                        <input
                          type="text"
                          className="border-transparent form-focus-none form-control"
                          placeholder="Search by user or email"
                          value={onSearchText}
                          onChange={(e) => onFilterChange(e)}
                        />
                        <Button className="search-submit btn-icon">
                          <Icon name="search"></Icon>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <DataTableBody compact>
                  <DataTableHead>
                    <DataTableRow className="nk-tb-col-check">
                      <div className="custom-control custom-control-sm custom-checkbox notext">
                        <input
                          type="checkbox"
                          className="custom-control-input form-control"
                          onChange={(e) => selectorCheck(e)}
                          id="uid"
                        />
                        <label className="custom-control-label" htmlFor="uid"></label>
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">{t("customer.user")}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span className="sub-text">{t("customer.source")}</span>
                    </DataTableRow>
                    <DataTableRow size="sm">
                      <span className="sub-text">{t("customer.email")}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span className="sub-text">{t("customer.phone")}</span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span className="sub-text">{t("customer.address")}</span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span className="sub-text">{t("customer.order")}</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">Nhãn</span>
                    </DataTableRow>
                    <DataTableRow className="nk-tb-col-tools text-right">
                      <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="btn btn-xs btn-outline-light btn-icon dropdown-toggle">
                          <Icon name="plus"></Icon>
                        </DropdownToggle>
                        <DropdownMenu right className="dropdown-menu-xs">
                          <ul className="link-tidy sm no-bdr">
                            <li>
                              <div className="custom-control custom-control-sm custom-checkbox">
                                <input type="checkbox" className="custom-control-input form-control" id="bl" />
                                <label className="custom-control-label" htmlFor="bl">
                                  Balance
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-control-sm custom-checkbox">
                                <input type="checkbox" className="custom-control-input form-control" id="ph" />
                                <label className="custom-control-label" htmlFor="ph">
                                  Phone
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-control-sm custom-checkbox">
                                <input type="checkbox" className="custom-control-input form-control" id="vri" />
                                <label className="custom-control-label" htmlFor="vri">
                                  Verified
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-control-sm custom-checkbox">
                                <input type="checkbox" className="custom-control-input form-control" id="st" />
                                <label className="custom-control-label" htmlFor="st">
                                  Status
                                </label>
                              </div>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </DataTableRow>
                  </DataTableHead>
                  {/*Head*/}
                  {currentItems.length > 0
                    ? currentItems.map((item) => {
                      return (
                        <DataTableItem key={item._id}>
                          <DataTableRow className="nk-tb-col-check">
                            <div className="custom-control custom-control-sm custom-checkbox notext">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                defaultChecked={item.checked}
                                id={item._id}
                                onChange={(e) => onSelectChange(e, item._id)}
                              />
                              <label className="custom-control-label" htmlFor={item._id}></label>
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            <Link to={`${process.env.PUBLIC_URL}/user-details-regular/${item._id}`}>
                              <div className="user-card">
                                <UserAvatar
                                  theme="primary"
                                  text={findUpper(item.name)}
                                // className="xs"
                                // icon="user-alt"
                                ></UserAvatar>
                                <div className="user-info">
                                  <span className="tb-lead">{item.name} </span>
                                </div>
                              </div>
                            </Link>
                          </DataTableRow>
                          <DataTableRow size="md">
                            <span>{item.source}</span>
                          </DataTableRow>
                          <DataTableRow size="sm">
                            <span>{item.emails ? item.emails[0] : item.emails}</span>
                          </DataTableRow>
                          <DataTableRow size="md">
                            <span>{item.phones[0]}</span>
                          </DataTableRow>
                          <DataTableRow size="lg">
                            <span>{item.address}</span>
                          </DataTableRow>
                          <DataTableRow size="lg">
                            {/* <ul className="list-status">
                          <li>
                            <Icon
                              className={`text-${item.emailStatus === "success"
                                ? "success"
                                : item.emailStatus === "pending"
                                  ? "info"
                                  : "secondary"
                                }`}
                              name={`${item.emailStatus === "success"
                                ? "check-circle"
                                : item.emailStatus === "alert"
                                  ? "alert-circle"
                                  : "alarm-alt"
                                }`}
                            ></Icon>{" "}
                            <span>Email</span>
                          </li>
                        </ul> */}
                          </DataTableRow>
                          <DataTableRow size="lg">

                            {/* <span>tag</span> */}
                            <span>{item.tags ? item.tags[0] : 'tags'}</span>
                          </DataTableRow>
                          {/* <DataTableRow>
                          <span
                            className={`tb-status text-${
                              item.status === "Active" ? "success" : item.status === "Pending" ? "warning" : "danger"
                            }`}
                          >
                            {item.status}
                          </span>
                        </DataTableRow> */}
                          <DataTableRow className="nk-tb-col-tools">
                            <ul className="nk-tb-actions gx-1">
                              <li className="nk-tb-action-hidden" onClick={() => onEditClick(item._id)}>
                                <TooltipComponent
                                  tag="a"
                                  containerClassName="btn btn-trigger btn-icon"
                                  id={"edit" + item.id}
                                  icon="edit-alt-fill"
                                  direction="top"
                                  text="Edit"
                                />
                              </li>
                              {/* {item.status !== "Suspend" && (
                            <React.Fragment>
                              <li className="nk-tb-action-hidden" onClick={() => suspendUser(item.id)}>
                                <TooltipComponent
                                  tag="a"
                                  containerClassName="btn btn-trigger btn-icon"
                                  id={"suspend" + item.id}
                                  icon="user-cross-fill"
                                  direction="top"
                                  text="Suspend"
                                />
                              </li>
                            </React.Fragment>
                          )} */}
                              <li>
                                <UncontrolledDropdown>
                                  <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <ul className="link-list-opt no-bdr">
                                      <li onClick={() => onEditClick(item._id)}>
                                        <DropdownItem
                                          tag="a"
                                          href="#edit"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                        >
                                          <Icon name="edit"></Icon>
                                          <span>Edit</span>
                                        </DropdownItem>
                                      </li>
                                      {/* {item.status !== "Suspend" && (
                                    <React.Fragment>
                                      <li className="divider"></li>
                                      <li onClick={() => suspendUser(item.id)}>
                                        <DropdownItem
                                          tag="a"
                                          href="#suspend"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                        >
                                          <Icon name="na"></Icon>
                                          <span>Suspend User</span>
                                        </DropdownItem>
                                      </li>
                                    </React.Fragment>
                                  )} */}
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </li>
                            </ul>
                          </DataTableRow>
                        </DataTableItem>
                      );
                    })
                    : null}
                </DataTableBody>
                <div className="card-inner">
                  {currentItems.length > 0 ? (
                    <PaginationComponent
                      itemPerPage={itemPerPage}
                      totalItems={data.length}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  ) : (
                    loading ?
                      (
                        <div className="text-center">
                          <Spinner color="dark" />
                        </div>) :
                      (
                        <div className="text-center">
                          <span>No data found</span>
                        </div>
                      )
                  )}
                </div>
              </DataTable>
            </Block>
            <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
              <ModalBody>
                <a
                  href="#cancel"
                  onClick={(ev) => {
                    ev.preventDefault();
                    onFormCancel();
                  }}
                  className="close"
                >
                  <Icon name="cross-sm"></Icon>
                </a>
                <div className="p-2">
                  <h5 className="title">{t('customer.add_customer')}</h5>
                  <div className="mt-4">
                    <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
                      <Col md="12">
                        <FormGroup>
                          <label className="form-label">{t('customer.detail.fullname')}</label>
                          <input
                            className="form-control"
                            type="text"
                            name="name"
                            placeholder="Enter name"
                            {...register("name", { required: "This field is required" })}
                          />
                          {errors.name && <span className="invalid">{errors.name.message}</span>}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">{t('customer.email')} </label>
                          <input
                            className="form-control"
                            type="text"
                            name="email"
                            placeholder="Enter email"
                            {...register("email", {
                              required: "This field is required",
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "invalid email address",
                              },
                            })}
                          />
                          {errors.email && <span className="invalid">{errors.email.message}</span>}
                        </FormGroup>
                      </Col>
                      {/* <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t('balance')}</label>
                      <input
                        className="form-control"
                        type="number"
                        name="balance"
                        defaultValue={formData.balance}
                        placeholder="Balance"
                        {...register("balance", { required: "This field is required" })}
                      />
                      {errors.balance && <span className="invalid">{errors.balance.message}</span>}
                    </FormGroup>
                  </Col> */}
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">{t('customer.phone')}</label>
                          <input
                            className="form-control"
                            type="text"
                            name="phone"
                            placeholder="Enter phone"
                            {...register("phone", {
                              pattern: {
                                value: /^((\+)33|0)[1-9](\d{2}){4}$/,
                                message: "invalid phone number",
                              },
                              required: "This field is required",
                            })}
                          />
                          {errors.phone && <span className="invalid">{errors.phone.message}</span>}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">{t('customer.second_email')} </label>
                          <input
                            className="form-control"
                            type="text"
                            name="second_email"
                            placeholder="Enter email"
                            {...register("second_email", {
                              required: false,
                            })}
                          />
                          {/* {errors.second_email && <span className="invalid">{errors.second_email.message}</span>} */}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">{t('customer.second_phone')}</label>
                          <input
                            className="form-control"
                            type="text"
                            name="second_phone"
                            placeholder="Enter phone"
                            {...register("second_phone", {
                              required: false,
                            })}
                          />
                          {/* {errors.second_phone && <span className="invalid">{errors.second_phone.message}</span>} */}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">{t('customer.detail.province')}</label>
                          <div className="form-control-wrap">
                            <RSelect
                              options={province}
                              // defaultValue={{
                              //   value: formData.status,
                              //   label: formData.status,
                              // }}
                              onChange={(e) => {
                                setProvinceId(e.value)
                              }}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">{t('customer.detail.district')}</label>
                          <div className="form-control-wrap">
                            <RSelect
                              options={district}
                            // defaultValue={{
                            //   value: formData.status,
                            //   label: formData.status,
                            // }}
                            // onChange={(e) => setFormData({ ...formData, district: e.value })}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <label className="form-label">{t('customer.detail.address_detail')}</label>
                          <input
                            className="form-control"
                            type="text"
                            name="address_detail"
                            {...register("address_detail", {
                              required: false,
                            })}
                          />
                          {/* {errors.address_detail && <span className="invalid">{errors.address_detail.message}</span>} */}
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <label className="form-label">{t('tag')}</label>
                          <div className="form-control-wrap">
                            <RSelect
                              isMulti
                              options={customerTag}
                              onChange={(e) => setTags(
                                e.map((item) => { return item.value })
                              )}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col size="12">
                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                          <li>
                            <Button color="primary" size="md" type="submit">
                              {t('customer.add_customer')}
                            </Button>
                          </li>
                          <li>
                            <a
                              href="#cancel"
                              onClick={(ev) => {
                                ev.preventDefault();
                                onFormCancel();
                              }}
                              className="link link-light"
                            >
                              {t('customer.cancel')}
                            </a>
                          </li>
                        </ul>
                      </Col>
                    </Form>
                  </div>
                </div>
              </ModalBody>
            </Modal>
            <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
              {/* {console.log(formData)} */}
              <ModalBody>
                <a
                  href="#cancel"
                  onClick={(ev) => {
                    ev.preventDefault();
                    onFormCancel();
                  }}
                  className="close"
                >
                  <Icon name="cross-sm"></Icon>
                </a>
                <div className="p-2">
                  <h5 className="title">{t('customer.edit_customer')}</h5>
                  <div className="mt-4">
                    <Form className="row gy-4" key={editId} onSubmit={handleSubmit(onEditSubmit)}>
                      <Col md="12">
                        <FormGroup>
                          <label className="form-label">{t('customer.detail.fullname')}</label>
                          <input
                            className="form-control"
                            type="text"
                            name="name"
                            defaultValue={formData.name}
                            placeholder="Enter name"
                            {...register("name", { required: "This field is required" })}
                          />
                          {errors.name && <span className="invalid">{errors.name.message}</span>}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">{t('customer.email')} </label>
                          <input
                            className="form-control"
                            type="text"
                            name="email"
                            defaultValue={formData.emails[0]}
                            placeholder="Enter email"
                            {...register("email", {
                              required: "This field is required",
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "invalid email address",
                              },
                            })}
                          />
                          {errors.email && <span className="invalid">{errors.email.message}</span>}
                        </FormGroup>
                      </Col>
                      {/* <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t('balance')}</label>
                      <input
                        className="form-control"
                        type="number"
                        name="balance"
                        defaultValue={formData.balance}
                        placeholder="Balance"
                        {...register("balance", { required: "This field is required" })}
                      />
                      {errors.balance && <span className="invalid">{errors.balance.message}</span>}
                    </FormGroup>
                  </Col> */}
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">{t('customer.phone')}</label>
                          <input
                            className="form-control"
                            type="text"
                            name="phone"
                            defaultValue={formData.phones[0]}
                            {...register("phone", {
                              pattern: {
                                value: /^((\+)33|0)[1-9](\d{2}){4}$/,
                                message: "invalid phone number",
                              },
                              required: "This field is required",
                            })}
                          />
                          {errors.phone && <span className="invalid">{errors.phone.message}</span>}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">{t('customer.second_email')} </label>
                          <input
                            className="form-control"
                            type="text"
                            name="second_email"
                            defaultValue={formData.emails[1]}
                            placeholder="Enter email"
                            {...register("second_email", {
                              required: false,
                              // pattern: {
                              //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              //   message: "invalid email address",
                              // },
                            })}
                          />
                          {errors.second_email && <span className="invalid">{errors.second_email.message}</span>}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">{t('customer.second_phone')}</label>
                          <input
                            className="form-control"
                            type="text"
                            name="second_phone"
                            defaultValue={formData.phones[1]}
                            {...register("second_phone", {
                              // pattern: {
                              //   value: /^((\+)33|0)[1-9](\d{2}){4}$/,
                              //   message: "invalid phone number",
                              // },
                              required: false,
                            })}
                          />
                          {errors.second_phone && <span className="invalid">{errors.second_phone.message}</span>}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">{t('customer.detail.province')}</label>
                          <div className="form-control-wrap">
                            <RSelect
                              options={province}
                              // defaultValue={{
                              //   value: formData.status,
                              //   label: formData.status,
                              // }}
                              onChange={(e) => {
                                setFormData({ ...formData, province: e.value },
                                  setProvinceId(e.value)
                                )
                              }}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">{t('customer.detail.district')}</label>
                          <div className="form-control-wrap">
                            <RSelect
                              options={district}
                              // defaultValue={{
                              //   value: formData.status,
                              //   label: formData.status,
                              // }}
                              onChange={(e) => setFormData({ ...formData, district: e.value })}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <label className="form-label">{t('customer.detail.address_detail')}</label>
                          <input
                            className="form-control"
                            type="text"
                            name="address_detail"
                            defaultValue={formData.address}
                            {...register("address_detail", {
                              required: false,
                            })}
                          />
                          {errors.address_detail && <span className="invalid">{errors.address_detail.message}</span>}
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <label className="form-label">{t('tag')}</label>
                          <div className="form-control-wrap">
                            <RSelect
                              isMulti
                              options={customerTag}
                              onChange={(e) => setTags(
                                e.map((item) => { return item.value })
                              )}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col size="12">
                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                          <li>
                            <Button color="primary" size="md" type="submit">
                              Edit
                            </Button>
                          </li>
                          <li>
                            <a
                              href="#cancel"
                              onClick={(ev) => {
                                ev.preventDefault();
                                onFormCancel();
                              }}
                              className="link link-light"
                            >
                              {t('customer.cancel')}
                            </a>
                          </li>
                        </ul>
                      </Col>
                    </Form>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </>
        ) : (
          <div className={`${style.EmptyImage}`}>
            <div>
              <div className="text-center">
                <img src={"/images/empty-connection.png"} width={287} alt="" />
              </div>
              <div>
                <p className={`${style.ImageTitle}`}>Kết nối với tài khoản mạng xã hội để bắt đầu!</p>
              </div>
              <div className={`${style.ConnectArea}`}>
                <div className={`${style.ButtonConnect}`} onClick={toggleIsOpenModalSelectPlatform}>
                  <div>
                    <IconConnectChannel />
                  </div>
                  <div>{t("project_connect_channel")}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Content>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnHover
      />
      <ModalSelectChannelPlatform
        isOpen={isOpenModalSelectPlatform}
        toggle={toggleIsOpenModalSelectPlatform}
        toast={toast}
      />
    </React.Fragment >
  );
};
export default UserListCompact;
