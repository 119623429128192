import React, { Suspense, useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { UserContextProvider } from "../pages/pre-built/user-manage/UserContext";
import { RedirectAs404 } from "../utils/Utils";
import SmartSchedule from "../So9/pages/SmartSchedule/Index";
import ListSetting from "../So9/pages/SmartSchedule/ListSetting";
import ListSettingV2 from "../So9/pages/SmartSchedule/ListSettingV2";
import CreatePost from "../So9/pages/CreatePost";
import CalendaVer2 from "../So9/pages/calendar/CalendaVer2";
import Overview from "../So9/pages/Overview";
import Dashboard from "../So9/pages/Dashboard";
import TrialOverview from "../So9/pages/Overview/TrialIndex";
import Blank from "../pages/others/Blank";
import Faq from "../pages/others/Faq";
import Terms from "../pages/others/Terms";
import Policy from "../pages/others/Policy";
import ProjectCardPage from "../pages/pre-built/projects/ProjectCard";
import ProjectListPage from "../pages/pre-built/projects/ProjectList";
import UserListCompact from "../So9/pages/user-manage/UserListCompact";
import UserProfileLayout from "../pages/pre-built/user-manage/UserProfileLayout";
import Engagement from "../So9/pages/IntrolChat";
import ManagePostDashboard from "../So9/pages/CreatePost/ManagePostDashboard";
import Project from "../So9/pages/ProjectManagement/Project";
import Subscription from "../So9/pages/ProjectManagement/Subscription";
import ListProject from "../So9/pages/ProjectManagement/ListProject";
import ListSubscription from "../So9/pages/ProjectManagement/ListSubscription";
import ConfirmPayment from "../So9/pages/ProjectManagement/ConfirmPayment";
import TagList from "../So9/pages/tag-manage/TagList";
import PriceList from "../So9/pages/PriceList";
import IntegrateTab from "../So9/pages/integrate-manage/index";
import Members from "../So9/pages/ProjectManagement/Members";
import Channel from "../So9/pages/Channel";
import ConnectChannel from "../So9/pages/ConnectChannel";
import GroupChannel from "../So9/pages/GroupChannel/GroupChannel";
import { useHistory } from "react-router";
import { URL_PATH } from "../config/urlPath";
import ReportAnalysis from "../So9/pages/Analysis/ReportAnalysis";
import ManagementACC from "../So9/pages/managementACC/ManagementACC";
import ScanFace from "../So9/pages/ScanFB-Tiktok/Index";
import Payment from "../So9/pages/Payment";
import PrivateRouterTrial from "./PrivateRouterTrial";
import Reel from "../So9/pages/Reels";
import Warehouse from "../So9/pages/Warehouse";
import Campaign from "../So9/pages/Campaign";
import FastCreate from "../So9/pages/Warehouse/FastCreate";
import ResearchCompetitor from "../So9/pages/ResearchCompetitor";
import IntensiveEfficiency from "../So9/pages/IntensiveEfficiency";
import IrisPage from "../So9/pages/Iris";
import News from "../So9/pages/News";
import CreatePostBeta from "../So9/pages/PostCompose";
import DashboardChannel from "../So9/pages/Dashboard_v2/ChannelDashboard";
import { DashboardContent } from "../So9/pages/Dashboard_v2/ContentDashboard";
import { DashboardEnterprise } from "../So9/pages/Dashboard_v2/EmployeeDashboard";
import PostBulk from "../So9/pages/PostBulk";
import BulkReel from "../So9/pages/PostBulk/BulkReel";
import BulkNormal from "../So9/pages/PostBulk/BulkNormal";
import UserDetailsPage from "../So9/pages/user-manage/UserDetailsRegular";
import TaskManagement from "../So9/pages/TaskManagement";
import Integration from "../So9/pages/Integration";

const Pages = () => {
  const project = useSelector((state) => state.project.project);
  const history = useHistory();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  useEffect(() => {
    const userInfor = JSON.parse(localStorage.getItem("userInfor"));
    if (userInfor && userInfor.last_project_active === "") {
      history.push(URL_PATH.list_project);
    }
  }, [history]);

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/engagement`}
          component={Engagement}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/customers`}
          render={() => (
            <UserContextProvider>
              <UserListCompact />
            </UserContextProvider>
          )}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/_blank`}
          component={Blank}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/posts/create`}
          component={CreatePost}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/posts/compose`}
          component={CreatePostBeta}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/posts/bulk`}
          component={PostBulk}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/posts/bulk/short`}
          component={BulkReel}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/posts/bulk/normal`}
          component={BulkNormal}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/posts/SmartSchedule`}
          component={SmartSchedule}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/posts/settingchannel-list`}
          component={
            project?.post_schedule_version === 2 ? ListSettingV2 : ListSetting
          }
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/calender/postChannel`}
          component={CalendaVer2}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/posts/reel/create`}
          component={Reel}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/warehouse`}
          component={Warehouse}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/warehouse/fastCreate`}
          component={FastCreate}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/campaign`}
          component={Campaign}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/manage_posts/`}
          component={ManagePostDashboard}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/channels/`}
          component={Channel}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/research-competitor/`}
          component={ResearchCompetitor}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/analysis`}
          component={ReportAnalysis}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/managementACC`}
          component={ManagementACC}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/scan`}
          component={ScanFace}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/integrateds`}
          render={() => (
            // <UserContextProvider>
              <IntegrateTab />
            // </UserContextProvider>
          )}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/posts/settingchannel-create`}
          component={SmartSchedule}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/tags`}
          render={() => (
            <UserContextProvider>
              <TagList />
            </UserContextProvider>
          )}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/admin/personnel`}
          render={() => (
            <UserContextProvider>
              <UserListCompact />
            </UserContextProvider>
          )}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/project-card`}
          component={ProjectCardPage}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/project-list`}
          component={ProjectListPage}
        ></Route>
        <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/user-list-compact`}
          render={() => (
            <UserContextProvider>
              <UserListCompact />
            </UserContextProvider>
          )}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-profile-notification/`}
          component={UserProfileLayout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-profile-activity/`}
          component={UserProfileLayout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-profile-setting/`}
          component={UserProfileLayout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-profile-iris/`}
          component={UserProfileLayout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-profile-iris-detail/`}
          component={UserProfileLayout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/pages/terms`}
          component={Terms}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/pages/policy`}
          component={Policy}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/pages/faq`}
          component={Faq}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/trial`}
          component={TrialOverview}
        ></Route>
        <PrivateRouterTrial
          exact
          path={`${process.env.PUBLIC_URL}/`}
          component={News}
        ></PrivateRouterTrial>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/home`}
          component={Overview}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/home/dashboard`}
          component={Dashboard}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/home/dashboard/personnel-efficiency`}
          component={IntensiveEfficiency}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/home/dashboard/overview`}
          component={DashboardChannel}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/home/dashboard/overview-enterprise/content`}
          component={DashboardContent}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/home/dashboard/overview-enterprise/staff`}
          component={DashboardEnterprise}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/admin/project`}
          component={Project}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/admin/subscription`}
          component={Subscription}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/admin/project/list`}
          component={ListProject}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/admin/project/pricing`}
          component={PriceList}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/admin/project/payment`}
          component={Payment}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/admin/subscription/list`}
          component={ListSubscription}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/admin/subscription/bill/confirm-payment`}
          component={ConfirmPayment}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/admin/project/users`}
          component={Members}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/admin/project/connect-channel`}
          component={ConnectChannel}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/admin/iris`}
          component={IrisPage}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/admin/project/group-channel`}
          component={GroupChannel}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/news`}
          component={News}
        ></Route>
        <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/user-details-regular/:id`}
          render={(props) => (
            <UserContextProvider>
              <UserDetailsPage {...props} />
            </UserContextProvider>
          )}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-profile-regular/`}
          component={UserProfileLayout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/task-management`}
          component={TaskManagement}
        ></Route>
         <Route
          exact
          path={`${process.env.PUBLIC_URL}/integration`}
          component={Integration}
        ></Route>
        <Route component={RedirectAs404}></Route>
      </Switch>
    </Suspense>
  );
};
export default Pages;
