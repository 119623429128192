import React, { useState } from "react";
import styles from "../PostDetail.module.scss";
import { Card, CardBody, Collapse } from "reactstrap";
import { useTranslation } from "react-i18next";

const Analyics = ({ postDetail }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const formatData = (post) => {
    switch (post?.platform) {
      case "facebook":
        return {
          media: post.media,
          channel: post.channel,
          post_id: post.post_id,
          channle_id: post.channle_id,
          _id: post._id,
          content: post.content,
          date: post.date,
          statistic_first_row: [
            {
              value: postDetail?.analysis?.post_impressions,
              classIcon: "ri-eye-fill",
              backgroundColor: "rgba(75, 56, 179, 0.18)",
              colorIcon: "#4B38B3",
            },
            {
              value: postDetail?.analysis?.post_clicks,
              classIcon: "ri-cursor-fill",
              backgroundColor: "rgba(75, 56, 179, 0.18)",
              colorIcon: "#4B38B3",
            },
            {
              value: postDetail?.analysis?.post_engagement,
              classIcon: "ri-message-2-line",
              backgroundColor: "rgba(75, 56, 179, 0.18)",
              colorIcon: "#4B38B3",
            },
          ],
          statistic_second_row: [
            {
              value: postDetail?.analysis?.post_reactions_like_total,
              classIcon: "ri-thumb-up-fill",
              backgroundColor: "rgba(41, 156, 219, 0.18)",
              colorIcon: "#3577F1",
            },
            {
              value: postDetail?.analysis?.post_reactions_love_total,
              classIcon: "ri-heart-3-fill",
              backgroundColor: "rgba(240, 101, 72, 0.18)",
              colorIcon: "#F06548",
            },
            {
              value: postDetail?.analysis?.post_reactions_wow_total,
              classIcon: "ri-emotion-line",
              backgroundColor: "rgba(247, 184, 75, 0.18)",
              colorIcon: "#F7B84B",
            },
          ],
        };
      case "youtube":
        return {
          media: post.media,
          channel: post.channel,
          post_id: post.post_id,
          channle_id: post.channle_id,
          _id: post._id,
          content: post.content,
          date: post.date,
          statistic_first_row: [
            {
              value: postDetail?.analysis?.view_count,
              classIcon: "ri-eye-fill",
              backgroundColor: "rgba(75, 56, 179, 0.18)",
              colorIcon: "#4B38B3",
            },
            {
              value: postDetail?.analysis?.comment_count,
              classIcon: "ri-message-2-line",
              backgroundColor: "rgba(75, 56, 179, 0.18)",
              colorIcon: "#4B38B3",
            },
            {
              value: postDetail?.analysis?.like_count,
              classIcon: "ri-thumb-up-fill",
              backgroundColor: "rgba(41, 156, 219, 0.18)",
              colorIcon: "#3577F1",
            },
          ],
          statistic_second_row: [
            {
              value: postDetail?.analysis?.share_count,
              classIcon: "ri-share-fill",
              backgroundColor: "rgba(240, 101, 72, 0.18)",
              colorIcon: "#F06548",
            },
            {
              value: postDetail?.analysis?.dislike_count,
              classIcon: "ri-dislike-fill",
              backgroundColor: "rgba(247, 184, 75, 0.18)",
              colorIcon: "#F7B84B",
            },
          ],
        };
      case "tiktok":
        return {
          media: post.media,
          channel: post.channel,
          post_id: post.post_id,
          channle_id: post.channle_id,
          _id: post._id,
          content: post?.post_info?.title,
          date: post.date,
          statistic_first_row: [
            {
              value: postDetail?.analysis?.view_count,
              classIcon: "ri-eye-fill",
              backgroundColor: "rgba(75, 56, 179, 0.18)",
              colorIcon: "#4B38B3",
            },
            {
              value: postDetail?.analysis?.comment_count,
              classIcon: "ri-message-2-line",
              backgroundColor: "rgba(75, 56, 179, 0.18)",
              colorIcon: "#4B38B3",
            },
          ],
          statistic_second_row: [
            {
              value: postDetail?.analysis?.like_count,
              classIcon: "ri-thumb-up-fill",
              backgroundColor: "rgba(41, 156, 219, 0.18)",
              colorIcon: "#3577F1",
            },
            {
              value: postDetail?.analysis?.share_count,
              classIcon: "ri-share-fill",
              backgroundColor: "rgba(240, 101, 72, 0.18)",
              colorIcon: "#F06548",
            },
          ],
        };
      default: {
        return {};
      }
    }
  };

  const data = formatData(postDetail);
  const toggle = () => setIsOpen(!isOpen);

  const postItem = ({ value, backgroundColor, classIcon, colorIcon }) => {
    return (
      <div
        className="d-flex align-items-center"
        style={{ width: "30%", height: "35px" }}
      >
        <div
          style={{
            width: "24px",
            height: "24px",
            background: backgroundColor,
            borderRadius: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <i className={classIcon} style={{ color: colorIcon }}></i>
        </div>
        <div style={{ paddingLeft: "5px" }}>{value || 0}</div>
      </div>
    );
  };

  return (
    <div className={styles.itemSideRight}>
      <div
        className="d-flex align-items-center justify-content-between"
        onClick={toggle}
      >
        <div className={styles.headerItem}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
            fill="rgba(53,119,241,1)"
          >
            <path d="M12.4142 5H21C21.5523 5 22 5.44772 22 6V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H10.4142L12.4142 5ZM12 9C9.79086 9 8 10.7909 8 13C8 15.2091 9.79086 17 12 17C14.2091 17 16 15.2091 16 13H12V9Z"></path>
          </svg>
          <span>{t("post_detail.side_right.analyics.tilte")}</span>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="16"
          height="16"
          fill="currentColor"
        >
          <path d="M11.9999 13.1714L16.9497 8.22168L18.3639 9.63589L11.9999 15.9999L5.63599 9.63589L7.0502 8.22168L11.9999 13.1714Z"></path>
        </svg>
      </div>
      <Collapse isOpen={isOpen} style={{ visibility: "visible" }}>
        <Card>
          <CardBody
            className={styles.bodyTask}
            style={{ padding: "0", paddingTop: "12px" }}
          >
            <>
              <div
                className="d-flex justify-content-between"
                style={{ marginTop: "10px" }}
              >
                {data?.statistic_first_row?.map((item) => {
                  return postItem(item);
                })}
              </div>
              <div className="d-flex justify-content-between">
                {data?.statistic_second_row?.map((item) => {
                  return postItem(item);
                })}
              </div>
            </>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

export default Analyics;
