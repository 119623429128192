import { API } from "../libs/client";

export const getUrlOAuth = async (data) => {
  const uri = `projects/google_sheet/url`;
  const res = await API.get(uri, data);
  return res.data;
};

export const getListLinkGGs = async (data) => {
  const uri = `projects/google_sheet/list-file`;
  const res = await API.get(uri, data);
  return res.data;
};

export const getListExportGGs = async (data) => {
  const uri = `projects/export`;
  const res = await API.get(uri, data);
  return res.data;
};

export const getPageGGs = async (ggSheet_id, data) => {
  const uri = `projects/google_sheet/${ggSheet_id}/sheets`;
  const res = await API.get(uri, data);
  return res.data;
};

export const exportConfigGGs = async (data) => {
  const uri = `projects/export/setup`;
  const res = await API.post(uri, data);
  return res.data;
};

export const createNewSheet = async (data) => {
  const uri = `projects/google_sheet/create`;
  const res = await API.post(uri, data);
  return res.data;
};


