import { API } from "../libs/client";

export const getComments = async (postId, data = {}) => {
  const uri = `/posts/${postId}/review-posts/list`;
  const res = await API.get(uri, { params: data });
  return res.data;
};

export const getDetailPost = async (postId, data = {}) => {
  const uri = `/posts/${postId}/detail`;
  const res = await API.get(uri, { params: data });
  return res.data;
};

export const getCheckList = async (projectId, data = {}) => {
  const uri = `/projects/${projectId}/task/get-by-post`;
  const res = await API.get(uri, { params: data });
  return res.data;
};

export const addCommentWithAnonymous = async (postId, data) => {
  const uri = `/posts/${postId}/review-posts/store-by-other`;
  const res = await API.post(uri, data);
  return res.data;
};

export const addCommentWithoutAnonymous = async (postId, data) => {
  const uri = `/posts/${postId}/review-posts/store`;
  const res = await API.post(uri, data);
  return res.data;
};

export const countComments = async (postId, data = {}) => {
  const uri = `/posts/${postId}/review-posts/cnt`;
  const res = await API.get(uri, { params: data });
  return res.data;
};

export const editComment = async (postId, commentId, data) => {
  const uri = `/posts/${postId}/review-posts/${commentId}`;
  const res = await API.put(uri, data);
  return res.data;
};

export const deleteComment = async (postID, commentId, projectId) => {
  const uri = `/posts/${postID}/review-posts/${commentId}?project_id=${projectId}`;
  const res = await API.delete(uri);
  return res.data;
};

export const shareListPost = async (projectId, data) => {
  const uri = `/projects/${projectId}/shares/create`;
  const res = await API.post(uri, data);
  return res.data;
};

export const getShareListPost = async (shareId, projectId) => {
  const uri = `/shares/${shareId}-${projectId}`;
  const res = await API.get(uri);
  return res.data;
};

export const getPostOrignWithPostPlatform = async (projectId, postOriginId) => {
  const uri = `/projects/${projectId}/posts/${postOriginId}/all-platform`;
  const res = await API.get(uri);
  return res.data;
}