import React, { useState } from "react";
import { Card, UncontrolledTooltip, Container } from "reactstrap";
import { Col } from "../../../components/Component";
import style from "./index.module.scss";
import iconDelete from "../../images/icons/icon_delete.svg"
import iconEdit from "../../images/icons/icon_edit.svg"
import iconAdd from "../../images/icons/icon_add.svg"
import {
  MEDIA_TYPE_IMAGE,
} from "../../utils/Utils";
const MediaItemImage = ({ ...props }) => {

  const [openImage, setOpenImage] = useState(true)
  const [editImage, setEditImage] = useState();
  const [indexEdit, setIndexEdit] = useState();
  const [modalWarning, setModalWarning] = useState(false);

  const removeMedia = (index) => {
    props.removeMedia && props.removeMedia(index);
  };

  const openMedia = () => {
    setOpenImage(!openImage)
    const Elmchevron = document.getElementById("chevron_openMedia");
    const Elmcontent = document.getElementById("content_openMedia");
    //const Elmchevron3 = document.getElementById("img");
    if (!openImage) {
      Elmchevron.classList.add(`${style.openChevronDown}`)
      Elmchevron.classList.remove(`${style.openChevronUp}`)
      Elmcontent.classList.add(`${style.openContentDown}`)
      Elmcontent.classList.remove(`${style.openContentUp}`)

    }
    else {
      Elmchevron.classList.add(`${style.openChevronUp}`)
      Elmchevron.classList.remove(`${style.openChevronDown}`)
      Elmcontent.classList.add(`${style.openContentUp}`)
      Elmcontent.classList.remove(`${style.openContentDown}`)


    }
  }
  const editMedia = (item, index) => {
    setEditImage(item)
    setIndexEdit(index)
    toggleWarning()
  }
  const updateAltText = (index, newAltText) => {
    props.update && props.update(index, newAltText);
  };
  const onUpdateCaption = (index, caption) => {
    props.onUpdateCaption && props.onUpdateCaption(index, caption);
  };
  const toolStyle = {
    top: 0,
    right: 0,
  };
  const mediaInfoWrapperStyle = {
    transition: "max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s",
    willChange: "max-height",
    position: "relative",
    overflow: "hidden",
    gap: "8px",
  };
  const imgStyle = {
    flex: "0 0 68px",
    borderRadius: "4px",
    objectFit: "cover",
    margin: "auto",
    width: "100%",
    height: "100%"
  };
  const toggleWarning = () => {
    setModalWarning(!modalWarning);
  };
  return (
    <Card className="card-bordered mb-2">
      <div className="d-flex align-items-stretch border-bottom" onClick={openMedia}>
        <div
          className="flex-fill p-2 fw-medium"
          style={{ color: "#333333", fontFamily: "Roboto" }}
        >
          Ảnh đang chọn {props.mediaItems.filter((c) => (c.type === MEDIA_TYPE_IMAGE)).length}
        </div>
      </div>
      <div id="content_openMedia" style={mediaInfoWrapperStyle}>
        <Container>
          <div className="row gy-3 gx-2" style={{ padding: "16px" }}>
            {
              props.mediaItems.map((item, index) => {
                return (
                  <Col md="2" className={style.Col5}>
                    <div style={{ width: "100%", height: "100%", backgroundColor: "rgb(222, 225, 225)", borderRadius: "8px", display: "flex" }}>
                      <img src={item.url} style={imgStyle} alt={item.altText} />
                    </div>
                    <div style={{ display: "flex", marginTop: "-25%", justifyContent: "space-around", textAlign: "center", height: "20%" }}>
                      <div id="edit" className="d-flex align-items-stretch" onClick={() => editMedia(item, index)} style={{ width: "24px", height: "24px", borderRadius: "50%", opacity: "80%", backgroundColor: "#000" }}>
                        <img src={iconEdit} alt="edit" role="button" style={{ width: "70%", height: "100%", margin: "auto" }} />
                        <UncontrolledTooltip autohide={false} placement={"top"} target={"edit"}>
                          Viết caption cho ảnh
                        </UncontrolledTooltip>
                      </div>
                      <div id="delete" className="d-flex align-items-stretch" onClick={() => removeMedia(index)} style={{ width: "24px", height: "24px", borderRadius: "50%", opacity: "80%", backgroundColor: "#000" }}>
                        <img src={iconDelete} alt="delete" role="button" style={{ width: "70%", height: "100%", margin: "auto" }} />
                        <UncontrolledTooltip autohide={false} placement={"top"} target={"delete"}>
                          Xóa ảnh
                        </UncontrolledTooltip>
                      </div>
                    </div>
                  </Col>
                )
              })
            }
            <Col md="2" className={style.Col5}>
              <Card className="card-bordered dashed text-center d-flex align-items-stretch" style={{ width: "100%", height: "100%", minHeight: "85px" }} onClick={() => props.addMedia()}>
                <div className={`d-flex align-items-stretch ${style.addImage}`}>
                  <img src={iconAdd} alt="add" role="button" style={{ width: "80%", height: "80%", margin: "auto" }} />
                </div>
              </Card>
            </Col>
          </div>
        </Container>
      </div>
      <div id="chevron_openMedia" className={`card-tools position-absolute p-2 ${style.openMedia}`} style={toolStyle} onClick={openMedia}>
        <svg width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" /> </svg>
      </div>
      {modalWarning && (
        <ModalEditImage
          modalWarning={modalWarning}
          toggleWarning={toggleWarning}
          item={editImage}
          onUpdateCaption={(caption) => {
            onUpdateCaption(indexEdit, caption);
          }}
          updateAltText={(newAltText) => {
            updateAltText(indexEdit, newAltText);
          }}
        />)}
    </Card>
  );
};
const ModalEditImage = React.lazy(() => import("../../components/Modal/ModalEditImage"));
export default MediaItemImage;