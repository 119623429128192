import { API } from "../libs/client";

/**
 * @param {{params :{project_id: string, time: string}}} data
 * @returns
 */
export const getDashboardAudience = async (data) => {
  const uri = "/projects/dashboard/audience";
  const res = await API.get(uri, data);
  return res.data;
};

/**
 * @param {{params :{project_id: string, time: string}}} data
 * @returns
 */
export const getDashboardUser = async (data) => {
  const uri = "/projects/dashboard/active-user";
  const res = await API.get(uri, data);
  return res.data;
};

/**
 * @param {{params :{project_id: string, time: string}}} data
 * @returns
 */
export const getDashboardFansOnline = async (data) => {
  const uri = "/projects/dashboard/fans-online";
  const res = await API.get(uri, data);
  return res.data;
};

/**
 * @param {{params :{project_id: string, time: string}}} data
 * @returns
 */
export const getDashboardHotPost = async (data) => {
  const uri = "/projects/dashboard/hot-post";
  const res = await API.get(uri, data);
  return res.data;
};

/**
 * @param {{params :{project_id: string, time: string}}} data
 * @returns
 */
export const getDashboardChannelReport = async (data) => {
  const uri = "/projects/dashboard/channel";
  const res = await API.get(uri, data);
  return res.data;
}

export const getDashboardEmployeeReport = async (data) => {
  const uri = "/projects/dashboard/employee";
  const res = await API.get(uri, data);
  return res.data;
}

/**
 * @param {string} contentType
 * @param {{params :{project_id: string, time: string}}} data
 * @returns
 */
export const getDashboardTopContent = async (contentType, data) => {
  const uri = `/projects/dashboard/content/${contentType}`;
  const res = await API.get(uri, data);
  return res.data;
}

/**
 * @param {string} projectId
 * @param {{params :{date: string, page: number}}} data
 * @returns
 */
export const getNewsPostInDays = async (projectId, data) => {
  const uri = `/projects/${projectId}/posts/list-status`;
  const res = await API.get(uri, data);
  return res.data;
}

/**
 * @param {string} projectId
 * @param {{params :{from: string, to: string}}} data
 * @returns
 */
export const getNewsPostPerformance = async (projectId, data) => {
  const uri = `/projects/${projectId}/posts/top-post-performance`;
  const res = await API.get(uri, data);
  return res.data;
}
/**
 * @param {string} projectId
 * @param {{params :{from: string, to: string, limit:number}}} data
 * @returns
 */
export const getNewsTopPostChannel = async (projectId, data) => {
  const uri = `/projects/${projectId}/channels/analysis/performance`;
  const res = await API.get(uri, data);
  return res.data;
}
/**
 * @param {string} projectId
 * @returns
 */
export const getNewsListChannelError = async (projectId) => {
  const uri = `/projects/${projectId}/channels/list-error`;
  const res = await API.get(uri);
  return res.data;
}


export const getNewsFeatures = async (data) => {
  const uri = `/features`;
  const res = await API.get(uri,data);
  return res.data;
}
