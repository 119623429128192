import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SimpleBar from "simplebar-react";
import { getChannels } from "../../../api/createPost";
import { Icon, Img } from "../../../components/Component";
import ContentAlt from "../../../layout/content/ContentAlt";
import Head from "../../../layout/head/Head";
import { setChannels } from "../../reducers/channelSlice";
import Column from "./Column";
import style from "./index.module.scss";
import { IconConnectChannel } from "../../components/svgIcon";
import { toast, ToastContainer } from "react-toastify";
import styleConnect from "../../components/ConnectSocialNetwork/index.module.scss";
import ModalSelectChannelPlatform from "../../components/ConnectSocialNetwork/ModalSelectChannelPlatform";
import InitialColumn from "./InitialColumn"
import { setCollapseSidebarLeft } from "../../reducers/layoutSlice";

const Channel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project.project);
  const channels = useSelector((state) => state.channel.channels);
  useEffect(() => {
    console.log(channels, "tt");
    dispatch(setCollapseSidebarLeft(true));
    const controller = new AbortController();
    if (project?._id) {
      getChannels(project?._id, {
        signal: controller.signal,
      })
        .then((response) => {
          if (response.status) {
            console.log(response.data.channel, "123");
            dispatch(setChannels(response.data.channel));
          }
        })
        .catch((error) => console.error(error));
    }
    return () => {
      controller.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?._id]);
  const [collapseSidebar, setCollapseSidebar] = useState(false);
  const [collapseSidebarAddChannel, setCollapseSidebarAddChannel] = useState(false);
  const [collapseSidebarContent, setCollapseSidebarContent] = useState(false);
  const [loadSeeding, setLoadSeeding] = useState(false);
  const [ListUser, setListUser] = useState([])
  const [sidebarContentProps, setSidebarContentProps] = useState({
    postId: null,
    facebookPostId: null,
    platform: null,
    channelId: null,
    platform_id: null,
  });
  const simpleBarRef = useRef();

  // check connect channel
  const dataListChannel = useSelector((state) => state.channel.channelsInformation);
  const [isOpenModalSelectPlatform, setIsOpenModalSelectPlatform] = useState(false);
  const userInformation = JSON.parse(localStorage.getItem("userInfor"));
  const toggleIsOpenModalSelectPlatform = () => {
    setIsOpenModalSelectPlatform((v) => !v);
  };
  // end check connect channel
  const showCollapseSidebarAddChannel = () => {
    setCollapseSidebar(true);
    setCollapseSidebarContent(false);
    setCollapseSidebarAddChannel(true);
    document.body.classList.add("toggle-shown");
  };
  const refetch = () => {
    const controller = new AbortController();
    if (project?._id) {
      getChannels(project?._id, {
        signal: controller.signal,
      })
        .then((response) => {
          if (response.status) {
            //console.log(response.data.channel);
            dispatch(setChannels(response.data.channel));
          }
        })
        .catch((error) => console.error(error));
    }
    return () => {
      controller.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }
  const showCollapseSideBarContent = (props) => {
    setSidebarContentProps(props);
    setCollapseSidebarContent(true);
    setCollapseSidebar(true);
    if (collapseSidebarAddChannel) {
      setCollapseSidebarAddChannel(false);
    }
    document.body.classList.add("toggle-shown");
  };

  const handleScroll = () => {
  };

  const hideCollapse = () => {
    setCollapseSidebar(false);
    if (collapseSidebarAddChannel) {
      setCollapseSidebarAddChannel(false);
    }
    if (collapseSidebarContent) {
      setCollapseSidebarContent(false);
      setSidebarContentProps({
        postId: null,
        facebookPostId: null,
        platform: null,
        channelId: null,
        platform_id: null
      });
    }
    document.body.classList.remove("toggle-shown");
  };

  return (
    <>
      <Head title={t("menu_managing_channel")} />
      <ContentAlt>
        <>
          {
            userInformation.last_project_active !== "" && dataListChannel && dataListChannel.length > 0 ?
              <></> :
              <div className={`${styleConnect.EmptyImage} ${styleConnect.ImageOpacity}`}>
                <div>
                  <div className="text-center">
                    <Img src={"/images/empty-connection.png"} width={287} alt="" />
                  </div>
                  <div>
                    <p className={`${styleConnect.ImageTitle}`}>Kết nối với tài khoản mạng xã hội để bắt đầu!</p>
                  </div>
                  <div className={`${styleConnect.ConnectArea}`}>
                    <div className={`${styleConnect.ButtonConnect}`} onClick={toggleIsOpenModalSelectPlatform}>
                      <div>
                        <IconConnectChannel />
                      </div>
                      <div>{t("project_connect_channel")}</div>
                    </div>
                  </div>
                </div>
              </div>
          }
          <div style={userInformation.last_project_active !== "" && dataListChannel && dataListChannel.length > 0 ? { opacity: "1" } : { opacity: "0.5" }}>
            {channels && channels.length > 0 ?
              <>
                <div className="d-flex align-items-start" onScroll={handleScroll()}>
                  {channels.map((channel) => (
                    <Column
                      ListUser={ListUser}
                      setListUser={setListUser}
                      setLoadSeeding={setLoadSeeding}
                      loadSeeding={loadSeeding}
                      key={channel._id} openSidebarContent={showCollapseSideBarContent} channel={channel} />
                  ))}
                </div>
                <button
                  className={`rounded-circle text-white bg-pink d-flex align-items-center justify-content-center border-0 ${style.emptyChannelFloatActionAdd}`}
                  onClick={showCollapseSidebarAddChannel}
                >
                  <Icon name="plus" />
                </button>
              </>

              :
              <div className="d-flex align-items-start" >
                <InitialColumn
                  showCollapseSidebarAddChannel={showCollapseSidebarAddChannel}
                />
              </div>
            }

            <div
              className={`nk-demo-panel toggle-slide toggle-slide-right toggle-screen-any p-0 ${style.sidebarAddChannelWrapper
                } ${collapseSidebarAddChannel ? "content-active" : ""}`}
            >
              <SimpleBar style={{ maxHeight: "100%" }}>
                <SidebarAddChannel channels={channels} />
              </SimpleBar>
            </div>
            <div
              className={`nk-demo-panel nk-demo-panel-2x toggle-slide toggle-slide-right toggle-screen-any p-0 ${style.contentItem
                } ${collapseSidebarContent ? "content-active" : ""}`}
            >
              <SimpleBar style={{ maxHeight: "100%" }} ref={simpleBarRef}>
                <SidebarContent ListUser={ListUser} hideCollapse={hideCollapse} simpleBarRef={simpleBarRef} {...sidebarContentProps} loadSeeding={loadSeeding} refetch={refetch} />
              </SimpleBar>
            </div>
            {collapseSidebar && <div className="toggle-overlay" onClick={() => hideCollapse()}></div>}
          </div>
        </>
      </ContentAlt>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnHover
      />
      <ModalSelectChannelPlatform
        isOpen={isOpenModalSelectPlatform}
        toggle={toggleIsOpenModalSelectPlatform}
        toast={toast}
      />
    </>
  );
};
const SidebarContent = React.lazy(() => import("./SidebarContent"))
const SidebarAddChannel = React.lazy(() => import("./SidebarAddChannel"))
export default Channel;
