import React, { useEffect, useState } from "react";
import Select from 'react-select';
import Loading from "./Loading"
import SocialFeature from "./SocialFeature";
import { getPricingData, getOptionsPricing } from "../../../api/pricing";
import { Link } from "react-router-dom";
import { RSelect } from "../../../components/Component";
import { components } from "react-select";

import off10 from "../../images/icons/10off_icon.png";
import off15 from "../../images/icons/15off_icon.png";
import people from "../../images/icons/people-icon.png";
import flagIcon from "../../images/icons/flag-icon.png";
import diamon from "../../images/icons/diamon-icon.png";
const PricingOptionBox = () => {

  const [month, setMonth] = useState(1);
  const [type, setType] = useState("pro");
  const [numberPage, setNumberPage] = useState(10);
  const [activeBlock, setActiveBlock] = useState("1_month");
  const [selectOptionsPage, setSelectOptionsPage] = useState([]);
  const [pageValue, setPageValue] = useState();
  const [durationValue, setDurationValue] = useState();
  const [optionValue, setOptionValue] = useState();

  const [selectOptionsMonth, setSelectOptionsMonth] = useState([]);
  const [selectOptionsType, setSelectOptionsType] = useState([]);
  const [customizeData, setCustomizeData] = useState();

  const [socialData, setSocialData] = useState();
  const [hubData, setHubData] = useState();
  const [socialOptionData, setSocialOptionData] = useState();
  const [hubOptionData, setHubOptionData] = useState();


  async function getAllPricingData() {

    const socialPromis = getPricingData({ type: "social" });
    const hubPromis = getPricingData({ type: "hub" });
    const hubOptionPromis = getOptionsPricing({ type: "hub" });
    const socialOptionPromis = getOptionsPricing({ type: "social" });


    const promises = [socialPromis, hubPromis, hubOptionPromis, socialOptionPromis];

    const results = await Promise.allSettled(promises);

    const responseData = promises.reduce((acc, promise, index) => {
      if (results[index].status === 'fulfilled') {
        acc[index] = results[index].value;
      } else {
        acc[index] = { error: results[index].reason };
      }
      return acc;

    }, []);

    const socialRes = responseData[0];
    const hubRes = responseData[1];
    const hubOptionRes = responseData[2];
    const socialOptionRes = responseData[3];
    results.map((res) => {
      if (res.status !== 'fulfilled') {
        setHubData(null);
        setHubOptionData(null);
        setSocialData(null);
        setSocialOptionData(null);

      }
    })


    setHubData(hubRes.data);
    setSocialData(socialRes.data);
    setHubOptionData(hubOptionRes.data);
    setSocialOptionData(socialOptionRes.data);

  }

  const addtitleSelectOptionsMonth = (label) => {
    if (label === 3)
      return `(Giảm thêm 10%)`
    else if (label === 6)
      return `(Giảm thêm 15%)`
    else if (label === 12)
      return `(Giảm thêm 20%)`
    else
      return ``
  }
  useEffect(() => {
    getAllPricingData();
    if (socialOptionData) {
      const selectOptionsPage = socialOptionData?.optionChannel.map((value) => ({
        value: value,
        label: `${value.toString()} Trang`
      }));

      const selectOptionsMonth = socialOptionData?.optionDuration.map((value) => ({
        value: value,
        label: `${value.toString()} Tháng `
      }));
      const selectOptionsType = socialOptionData?.optionType?.map((value) => ({
        value: value,
        label: `${value.toString().charAt(0).toUpperCase() + value.toString().slice(1)}`
      }));
      const filterDataByNumberPage = socialOptionData?.calculation?.filter(item => item.channel == numberPage);
      const filterDataByType = filterDataByNumberPage[0]?.option?.filter(option => option.type == type);
      const filterDataByDuration = filterDataByType[0]?.pricing?.find(duration => duration.duration === month);
      setCustomizeData(filterDataByDuration);
      console.log(filterDataByNumberPage, filterDataByType, filterDataByDuration);
      setSelectOptionsPage(selectOptionsPage);
      setSelectOptionsMonth(selectOptionsMonth);
      setSelectOptionsType(selectOptionsType);
    }
  }, [])
  useEffect(() => {
    if (socialOptionData) {
      const selectOptionsPage = socialOptionData?.optionChannel?.map((value) => ({
        value: value,
        label: `${value.toString()} Trang`
      }));

      const selectOptionsMonth = socialOptionData?.optionDuration?.map((value) => ({
        value: value,
        label: `${value.toString()} Tháng `
      }));
      const selectOptionsType = socialOptionData?.optionType?.map((value) => ({
        value: value,
        label: `${value.toString().charAt(0).toUpperCase() + value.toString().slice(1)}`
      }));
      setSelectOptionsPage(selectOptionsPage);
      setSelectOptionsMonth(selectOptionsMonth);
      setSelectOptionsType(selectOptionsType);

      setMonth(1);
      setNumberPage(10);
      setType("pro");

      const filterDataByNumberPage = socialOptionData?.calculation?.filter(item => item.channel == numberPage);
      const filterDataByType = filterDataByNumberPage[0]?.option?.filter(option => option.type == type);
      const filterDataByDuration = filterDataByType[0]?.pricing?.find(duration => duration.duration === month);
      setCustomizeData(filterDataByDuration);
    }

  }, [socialOptionData])

  const optionType = [{ value: "pro", label: "Pro" }]

  const CustomOption = ({ children, isDisabled, ...props }) => {
    const { data } = props
    return (
      <components.Option {...props}>
        {data.label}
        <span
          style={{
            color: "rgb(16, 185, 129)",
            fontSize: "13px",
            fontWeight: "500",
            lineHeight: 1,
            fontFamily: "Roboto",
          }}
        >
          {addtitleSelectOptionsMonth(data.value)}
        </span>
      </components.Option>
    );
  };

  const CustomSingleValue = ({ children, isDisabled, ...props }) => {
    const { data } = props
    return (
      <components.SingleValue  {...props}>
        {data.label}
        <span
          style={{
            color: "rgb(16, 185, 129)",
            fontSize: "13px",
            fontWeight: "500",
            lineHeight: 1,
            fontFamily: "Roboto",
          }}
        >
          {addtitleSelectOptionsMonth(data.value)}
        </span>
      </components.SingleValue>
    );
  };

  const handleChangeOption = (blockId) => {
    setActiveBlock(blockId);
  };
  const formatCurrency = (value) => {
    return value && value.toLocaleString('vi-VN', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }) + ' đ';
  };
  useEffect(() => {

    if (socialOptionData) {
      const filterDataByNumberPage = socialOptionData?.calculation?.filter(item => item.channel == numberPage);
      const filterDataByType = filterDataByNumberPage[0]?.option?.filter(option => option.type == type);
      const filterDataByDuration = filterDataByType[0]?.pricing?.find(duration => duration.duration === month);
      setCustomizeData(filterDataByDuration);
    }

  }, [month, type, numberPage])
  const renderContentItem = () => {
    return (
      <>
        {socialData ? (Object.keys(socialData?.options[activeBlock])?.map(key => {
          const item = socialData?.options[activeBlock][key];
          return (
            item.is_popular === true ? (
              <div className="content-item" key={item.type}>
                <div className="box-1 d-flex justify-content-center align-items-center" style={{ backgroundColor: item.color }}>
                  <p style={{ color: "#FFFFFF" }}>Phổ biến</p>
                </div>
                <h6 className="content-item-type">{item?.name?.text}</h6>
                <div className="item-chanel">
                  <p>
                    <img src={flagIcon} alt="icon" />{item.limit_channel} Kênh
                  </p>
                </div>
                <hr style={{ width: "90%", marginTop: "6px", color: "#F3F4F6", opacity: "1" }}></hr>
                <div className="item-chanel-employe">
                  <p>
                    <img src={people} alt="icon" /> {item.limit_employees ? 'Không giới hạn nhân viên' : `Giới hạn nhân viên : ${item.limit_employees}`}
                  </p>
                </div>
                <div className="item-chanel">
                  <h2>{formatCurrency(item.pricing)} </h2>
                  <p style={{ fontSize: "16px", color: "#111827" }} >{item.duration} Tháng</p>
                </div>

                <p className="p-end" >{item.description}</p>

                <Link
                  to={
                    process.env.PUBLIC_URL +
                    `/admin/project/payment?type=transaction&category=${item?.type}&page=${item?.limit_channel}&months=${item?.duration}`
                  }
                >Mua ngay
                </Link>

              </div>
            ) : (
              <div className="content-item" key={item.type}>
                <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: item.color, width: "90%", borderRadius: "0px 0px 8px 8px", height: "8px", marginBottom: "12px", marginLeft: "5%" }}>
                </div>
                <div>
                  <h6 className="content-item-type">{item?.name?.text}
                    {item?.name?.icon ? <img src={diamon} style={{ width: "18px", marginLeft: "5px", position: "absolute", top: "-1px" }} /> : <></>}
                  </h6>

                </div>
                <div className="item-chanel">
                  <p style={{ fontSize: "14px", color: "#000000", }}>
                    <img src={flagIcon} alt="icon" />{item.limit_channel} Kênh
                  </p>
                </div>
                <hr style={{ width: "90%", marginTop: "6px", color: "#F3F4F6", opacity: "1" }}></hr>
                <div className="item-chanel-employe">
                  <p style={{ fontSize: "14px", color: "#000000", }}>
                    <img src={people} alt="icon" /> {item.limit_employees ? 'Không giới hạn nhân viên' : `Giới hạn nhân viên : ${item.limit_employees}`}
                  </p>
                </div>
                <div className="item-chanel">
                  <h2>{formatCurrency(item.pricing)}</h2>
                  <p style={{ fontSize: "16px", color: "#111827" }} >{item.duration} Tháng</p>
                </div>

                <p className="p-end">{item.description}</p>
                <Link
                  to={
                    process.env.PUBLIC_URL +
                    `/admin/project/payment?type=transaction&category=${item?.type}&page=${item?.limit_channel}&months=${item?.duration}`
                  }
                >Mua ngay
                </Link>
              </div>
            )
          );
        })) : (<></>)}


      </>
    )
  }

  const renderPricingAllblockItem = () => {

    return (
      <>
        {socialData ? (socialData?.durations?.map((item) => (
          <div className={`display-selectbox-item ${activeBlock === item.code ? 'active' : ''}`}
            onClick={() => handleChangeOption(item.code)}
            key={item.code}
          >
            {
              item.icon === "" ? (<p >{item.display} </p>) :
                (
                  <p>{item.display}
                    <img src={item.icon === 'DISCOUNT_15_PERCENT' ? off15 :
                      item.icon === 'DISCOUNT_10_PERCENT' ? off10 : null}
                      alt="icon" />
                  </p>
                )
            }
          </div>
        ))) : (<></>)}

      </>
    )
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <div className="pricing-allblock">
      <p className="pricing-title">Gói cước phần mềm SO9 Social</p>
      {hubData && socialOptionData ? (<div className="pricing-container d-flex justify-content-between" style={{ minHeight: "550px", width: "86%" }}>
        <div className="pricing-display">
          <div className="pricing-display-selectbox d-flex justify-content-around align-items-end" style={{ height: "60px" }}>
            {renderPricingAllblockItem()}
          </div>
          <div className="pricing-display-content d-flex justify-content-around" style={{ height: "70%" }}>
            {renderContentItem()}
          </div>
          <div className="pricing-display-desc" >
            <p style={{ marginBottom: "0px" }}>Mua thêm </p>
            <p style={{ display: "inline", fontWeight: "bold", color: "#111827", margin: "0px 5px 0px 5px" }}>{formatCurrency(hubData?.price_per_channel)}</p>
            <p> / Kênh </p>
          </div>
        </div>

        <div className="pricing-option" >
          {socialOptionData ? (
            <div className="pricing-option-info">
              <div>
                <h6 style={{ marginTop: "18px" }} >Các gói khác</h6>
                <p>Linh hoạt, tùy biến phù hợp với nhu cầu sử dụng.</p>
                <div>
                  <p style={{ marginBottom: "2px" }}>Số Kênh</p>

                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    key="SelectBoxNumberPage"
                    value={pageValue}
                    options={selectOptionsPage}
                    defaultValue={selectOptionsPage[0]}
                    onChange={(selectedOption) => {
                      setNumberPage(selectedOption.value);
                      setPageValue(selectedOption);
                    }}
                    isSearchable={true}
                    instanceId="page-select-box"
                    placeholder={`${socialOptionData?.optionChannel[0]} Trang`}
                  />
                </div>
                <div style={{ marginTop: "24px" }}>
                  <p style={{ marginBottom: "2px" }}>Thời hạn</p>
                  <Select
                    id="duration"
                    // closeMenuOnSelect={false}
                    components={{

                      Option: CustomOption,
                      SingleValue: CustomSingleValue,
                      DropdownIndicator:() => null, 
                      IndicatorSeparator:() => null
                    }}
                    classNamePrefix="react-select"
                    className="react-select-container"
                    value={durationValue}
                    options={selectOptionsMonth}
                    onChange={(selectedOption) => {
                      setMonth(selectedOption.value);
                      setDurationValue(selectedOption);
                    }}
                    placeholder={`${socialOptionData?.optionDuration[0]} Tháng`}
                    isSearchable={false}
                  />
                  {/* <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    id="duration"
                    value={durationValue}
                    options={selectOptionsMonth}
                    onChange={(selectedOption) => {
                      setMonth(selectedOption.value);
                      setDurationValue(selectedOption);
                    }}
                    placeholder={`${socialOptionData?.optionDuration[0]} Tháng`}

                    isSearchable={false}
                    //instanceId="time-select-box"
                  /> */}
                </div>
                <div style={{ marginTop: "24px" }}>
                  <p style={{ marginBottom: "2px" }}>Gói cước</p>
                  {socialOptionData?.optionType ?
                    (
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={optionValue}
                        options={selectOptionsType}
                        onChange={(selectedOption) => {

                          setType(selectedOption.value);
                          setOptionValue(selectedOption);

                        }}
                        placeholder={`${capitalizeFirstLetter(socialOptionData?.optionType[0])}`}
                        isSearchable={false}
                        instanceId="combo-select-box"
                      />) :
                    (
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={optionValue}
                        options={optionType}
                        onChange={(selectedOption) => {

                          setType(selectedOption.value);
                          setOptionValue(selectedOption);

                        }}
                        placeholder={`${optionType[0].label}`}
                        isSearchable={false}
                        instanceId="combo-select-box"
                      />
                    )}


                </div>
              </div>
              <div style={{ marginBottom: "60px", marginTop: "12px" }}>
                <div className="money-item">
                  <p>Giá gói</p>
                  <p style={{ color: "#111827" }}>{formatCurrency(customizeData?.amount)}</p>
                </div>
                <div className="money-item">
                  <p>Chiết khấu</p>
                  <p style={{ color: "#10B981" }}> {customizeData ? ("-") : ("")} {formatCurrency(customizeData?.discount)} {customizeData?.discount === 0 ? ("đ") : ("")} </p>
                </div>
                <hr style={{ marginTop: "10px" }}></hr>
                <div className="money-item" style={{ marginBottom: "2px" }}>
                  <p>Thanh toán</p>
                  <p style={{ color: "#2C4B94", fontSize: "20px" }}>{formatCurrency(customizeData?.pricing)}</p>
                </div>
              </div>
              <Link
                to={
                  process.env.PUBLIC_URL +
                  `/admin/project/payment?type=transaction&category=${type}&page=${numberPage}&months=${month}`
                }
              >Mua ngay
              </Link>
            </div>) : <Loading size={"4rem"} />}
        </div>
      </div>) : (<Loading size={"7rem"} />)}

      <SocialFeature />

    </div>
  )
};

export default PricingOptionBox;