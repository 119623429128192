import { useTranslation } from "react-i18next";
import BlockDataCommonItem from "../../CommonItem/BlockDataCommonItem";
import { ChartType } from "../../constants";

export const OverviewUser = ({ data, isLoading }) => {
  const { t } = useTranslation();
  const labelX = data?.page_fan_adds?.dataSet?.map((d) => d?.x) || [];
  const dataChartPageView = [
    {
      name: "Page View",
      data: data?.page_fan_adds?.dataSet?.map((d) => d?.y) || [],
    },
  ];

  const dataChartPagePageAction = [
    {
      name: "Page Post Engagement",
      data: data?.page_post_engagements?.dataSet?.map((d) => d?.y) || [],
    },
  ];

  return (
    <>
      <BlockDataCommonItem
        isLoading={isLoading}
        title={t("total_page_like")}
        dataValue={data?.page_fans?.value}
        classIcon="ri-thumb-up-fill"
        colorIcon="#4B38B3"
        tooltipLabel={t(
          "general_overview.tooltip.channel.facebook.total_page_like"
        )}
        tooltipId={"about_total_page_like"}
      />

      <BlockDataCommonItem
        isLoading={isLoading}
        title={t("new_like")}
        dataValue={data?.page_fan_adds?.value}
        dataChart={dataChartPageView}
        hasChart={true}
        chartType={ChartType.LINE}
        labelX={labelX}
        classIcon="ri-thumb-up-fill"
        colorIcon="#0AB39C"
        tooltipLabel={t("general_overview.tooltip.channel.facebook.new_like")}
        tooltipId={"about_page_fan_adds"}
      />

      <BlockDataCommonItem
        isLoading={isLoading}
        title={t("page_post_engagements")}
        dataValue={data?.page_post_engagements?.value}
        hasChart={true}
        labelX={labelX}
        chartType={ChartType.LINE}
        dataChart={dataChartPagePageAction}
        classIcon="ri-chat-heart-line"
        colorIcon="#F06548"
        tooltipLabel={t(
          "general_overview.tooltip.channel.facebook.page_post_engagements"
        )}
        tooltipId={"about_page_post_engagements"}
      />

      <BlockDataCommonItem
        isLoading={isLoading}
        title={t("fan_source")}
        hasChart={true}
        chartType={ChartType.PIE}
        dataChart={data?.rate_engage}
        classIcon="ri-radar-fill"
        colorIcon="#F7B84B"
        tooltipLabel={t(
          "general_overview.tooltip.channel.facebook.rate_engage"
        )}
        tooltipId={"about_rate_engage"}
      />
    </>
  );
};
