import React, { useEffect, useState } from "react";
import IconCopy from "../../../images/iris/user/copy.svg";
import infoIcon from "../../images/icons/Frameinfo-Icon.png"
import {
  Button,
  UncontrolledTooltip,
  Tooltip,
  Card,
  CardSubtitle,
  CardBody,
  CardText,
  Spinner
} from "reactstrap";
import style from "./index.module.scss";
import IconPaper from "../../images/aff/paper.svg";
import IconExcel from "../../images/aff/excel.png";
import {
  Img,
  LineChartExample,
  PaginationCustom,
  PreviewCard,
} from "../../../components/Component";
import { CustomLineChart } from "./CustomLineChart";
import ModalDetailByOrder from "./ModalDetailByOrder";
import ModalDetailByProject from "./ModalDetailByProject";
import * as XLSX from 'xlsx';
import { getIrisOverViewDb, getIrisDbDaily, getOverViewByDate, getIrisUser } from "../../../api/iris";
import { t } from "i18next";
import { ifError } from "assert";
import { data } from "autoprefixer";

const StatisticalTab = ({ rangeDate }) => {
  const [link, setLink] = useState("Đang lấy thông tin");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tabInfo, setTabInfor] = useState(6);
  const [allData, setAllData] = useState([]);
  const [dailyData, setDailyData] = useState();
  const [loadingDaily, setLoadingDaily] = useState(false);
  const [dataClick, setDataClick] = useState({
    labels: allData?.map(({ date }) => date).reverse(),
    datasets: [
      {
        label: "Total Click",
        lineTension: 0,
        borderColor: "#798bff",
        backgroundColor: "rgba(121, 139, 255, 0.4)",
        pointBorderWidth: 1,
        pointBackgroundColor: "white",
        pointRadius: 4,
        borderRadius: 0,
        fill: true,
        bezierCurve: false,
        data: allData?.map(({ click }) => click).reverse(),
      },
    ],
  })
  const [modalDetail, setModalDetail] = useState({
    byOrder: false,
    byProject: false,
  });
  const [overviewData, setOverViewData] = useState();
  const [curentModalOrderDate, setCurentModalOrderDate] = useState();
  const [curentModalProjectDate, setCurentModalProjectDate] = useState();
  const [currentPageByProject, setCurrentPageByProject] = useState(1);
  const [overViewLoading, setOverViewLoading] = useState(false);
  const handlePageChangeByProject = (newPage) => {
    setLoadingDaily(true);
    setDailyData();
    setCurrentPageByProject(newPage);
  };

  const [currentPageByOrder, setCurrentPageByOrder] = useState(1);
  const handlePageChangeByOrder = (newPage) => {
    setCurrentPageByOrder(newPage);
  };


  const fetchData = async () => {
    setLoadingDaily(true);
    setOverViewLoading(true);
    try {
      const overViewPromis = getIrisOverViewDb();
      const dailyPromis = getIrisDbDaily({ page: 1 });
      const userPromis = getIrisUser();
      const dataPage2 = getIrisDbDaily({ page: 2 });
      const dataPage3 = getIrisDbDaily({ page: 3 });
      const promises = [overViewPromis, dailyPromis, userPromis, dataPage2, dataPage3];
      const results = await Promise.allSettled(promises);

      const responseData = promises.reduce((acc, promise, index) => {
        if (results[index].status === 'fulfilled') {
          acc[index] = results[index].value;
        } else {
          acc[index] = { error: results[index].reason };
        }
        return acc;
      }, []);

      const overViewRes = responseData[0];
      const dailyRes = responseData[1];
      const userRes = responseData[2];
      const page2Res = responseData[3];
      const page3Res = responseData[4];

      if (overViewRes.status) {
        const _overViewData = overViewRes.data;
        const INFORMATION = [
          {
            id: 0,
            header: "Clicks",
            total: _overViewData?.click,
            dataChart: dataClick,
          },
          {
            id: 1,
            header: "Đơn hàng",
            total: _overViewData?.order,
            dataChart: [],
          },
          {
            id: 2,
            header: "Giá trị đơn hàng (đ)",
            total: formatCurrency(_overViewData?.order_amount),
            dataChart: [],
          },
          {
            id: 3,
            header: "Thu nhập dự kiến (đ)",
            total: formatCurrency(_overViewData?.estimate_revenue),
            dataChart: [],
          },
        ];
        setOverViewData(INFORMATION);
        setOverViewLoading(false);
      }
      if (dailyRes.status === true) {
        const _dailyData = dailyRes.data;
        setDailyData(_dailyData);
        setLoadingDaily(false);
      }
      if (userRes.status === true) {
        setLink(`https://so9.vn/products/?ref=${userRes?.data?.ref_code}`)
      }
      if (page2Res.status && page3Res.status && dailyRes.status) {
        const mergedArray = [...dailyRes?.data?.data, ...page2Res?.data?.data, ...page3Res?.data?.data];
        setAllData(mergedArray);
      }

    } catch (error) {
      setLoadingDaily(false);
      setOverViewLoading(false);
      console.log(error);
    }
  }

  const getDailyData = async (page, rangerDate) => {
    setLoadingDaily(true);
    let dailyRes;
    try {
      if (!rangerDate) {
        dailyRes = await getIrisDbDaily({ page: page });
      } else {
        dailyRes = await getIrisDbDaily({ page: page, fromTime: rangeDate?.start, toTime: rangeDate?.end })
      }

      if (dailyRes?.status) {
        const _dailyData = dailyRes.data;
        setDailyData(_dailyData);
        setLoadingDaily(false);
        let repeatArr = [];
        for (let index = 1; index <= dailyRes?.data?.last_page; index++) {
          const mergedArray = await getIrisDbDaily({ page: index, fromTime: rangeDate?.start, toTime: rangeDate?.end })
          repeatArr.push(...mergedArray?.data?.data);
        }
        setAllData(repeatArr);
        repeatArr = [];
      }

    } catch (error) {
      console.log("Data", error);
      setLoadingDaily(true);
    }
  }
  const getOverViewData = async (rangerDate) => {
    setOverViewLoading(true);
    try {
      let Res;
      if (!rangerDate) {
        Res = await getIrisOverViewDb();
      } else {
        Res = await getOverViewByDate({ fromTime: rangeDate?.start, toTime: rangeDate?.end });
      }
      if (Res?.status) {
        const _overViewData = Res?.data;
        const INFORMATION = [
          {
            id: 0,
            header: "Clicks",
            total: _overViewData?.click,
            dataChart: dataClick,
          },
          {
            id: 1,
            header: "Đơn hàng",
            total: _overViewData?.order,
            dataChart: [],
          },
          {
            id: 2,
            header: "Giá trị đơn hàng (đ)",
            total: formatCurrency(_overViewData?.order_amount),
            dataChart: [],
          },
          {
            id: 3,
            header: "Thu nhập dự kiến (đ)",
            total: formatCurrency(_overViewData?.estimate_revenue),
            dataChart: [],
          },
        ];
        setOverViewData(INFORMATION);
        setOverViewLoading(false);
      }
    } catch (error) {
      console.log("OverViewData", error);
      setOverViewLoading(false);
    }
  }

  const changeChart = () => {
    if (allData) {
      const dates = allData?.map(({ date }) => date).reverse();
      const clicks = allData?.map(({ click }) => click).reverse();
      const oders = allData?.map(({ order }) => order).reverse();
      const order_ammounts = allData?.map(({ order_amount }) => order_amount).reverse();
      const estimate_revenues = allData?.map(({ estimate_revenue }) => estimate_revenue).reverse();
      switch (tabInfo) {
        case 0:
          setDataClick({
            labels: dates,
            datasets: [
              {
                label: "Total Click",
                lineTension: 0,
                borderColor: "#798bff",
                backgroundColor: "rgba(121, 139, 255, 0.4)",
                pointBorderWidth: 1,
                pointBackgroundColor: "white",
                pointRadius: 4,
                borderRadius: 0,
                fill: true,
                bezierCurve: false,
                data: clicks,
              },
            ],
          });
          break;
        case 1:
          setDataClick({
            labels: dates,
            datasets: [
              {
                label: "Total Order",
                lineTension: 0,
                borderColor: "#798bff",
                backgroundColor: "rgba(121, 139, 255, 0.4)",
                pointBorderWidth: 1,
                pointBackgroundColor: "white",
                pointRadius: 4,
                borderRadius: 0,
                fill: true,
                bezierCurve: false,
                data: oders,
              },
            ],
          });
          break;
        case 2:
          setDataClick({
            labels: dates,
            datasets: [
              {
                label: "Total amount",
                lineTension: 0,
                borderColor: "#798bff",
                backgroundColor: "rgba(121, 139, 255, 0.4)",
                pointBorderWidth: 1,
                pointBackgroundColor: "white",
                pointRadius: 4,
                borderRadius: 0,
                fill: true,
                bezierCurve: false,
                data: order_ammounts,
              },
            ],
          });
          break;
        case 3:
          setDataClick({
            labels: dates,
            datasets: [
              {
                label: "Total estimate amount",
                lineTension: 0,
                borderColor: "#798bff",
                backgroundColor: "rgba(121, 139, 255, 0.4)",
                pointBorderWidth: 1,
                pointBackgroundColor: "white",
                pointRadius: 4,
                borderRadius: 0,
                fill: true,
                bezierCurve: false,
                data: estimate_revenues,
              },
            ],
          });
          break;

        default:
          break;
      }
    }
  }

  useEffect(() => {
    fetchData();
    setTabInfor(0);
  }, []);


  useEffect(() => {
    changeChart();
  }, [tabInfo, allData]);

  useEffect(() => {
    getDailyData(currentPageByProject, rangeDate);
  }, [currentPageByProject])


  useEffect(() => {
    getDailyData(currentPageByProject, rangeDate);
    getOverViewData(rangeDate);
    setCurrentPageByProject(1);
    changeChart();
  }, [rangeDate])
  const renderOverView = () => {
    return (
      <>
        {overviewData ? (
          overviewData.map((item) => (
            <div
              onClick={() => setTabInfor(item.id)}
              className={style.overViewCard}
              key={item.id}
              role="button"
            >
              <Card
                className={`${item.id === tabInfo && style.cardInfoActive}`}
                style={{ borderRadius: "2px" }}
              >
                <CardBody className="p-3">
                  <CardSubtitle
                    tag="h6"
                    style={{ fontSize: "0.75rem", color: "#374151" }}
                  >
                    {item.header}
                  </CardSubtitle>
                  <CardText
                    className="my-1"
                    style={{ fontSize: "1.25rem", fontWeight: "600" }}
                  >
                    {!overViewLoading ? item.total : <Spinner size="20px" color="light" />}
                  </CardText>
                </CardBody>
              </Card>
            </div>
          ))
        ) :
          (
            INFORMATION.map((item) => (
              <div
                onClick={() => setTabInfor(item.id)}
                className={style.overViewCard}
                key={item.id}
                role="button"
              >
                <Card
                  className={`${item.id === tabInfo && style.cardInfoActive}`}
                  style={{ borderRadius: "2px" }}
                >
                  <CardBody className="p-3">
                    <CardSubtitle
                      tag="h6"
                      style={{ fontSize: "0.75rem", color: "#374151" }}
                    >
                      {item.header}
                    </CardSubtitle>
                    <CardText
                      className="my-1"
                      style={{ fontSize: "1.25rem", fontWeight: "600" }}
                    >
                      {!overViewLoading ? item.total : <Spinner color="light" />}
                    </CardText>
                  </CardBody>
                </Card>
              </div>
            ))
          )}
      </>
    )
  }
  const toggleDetail = (table) => {
    if (table === "byOrder") {
      setModalDetail({
        ...modalDetail,
        byOrder: !modalDetail.byOrder,
        byProject: false,
      });
    } else {
      setModalDetail({
        ...modalDetail,
        byProject: !modalDetail.byProject,
        byOrder: false,
      });
    }
  };
  const toggle = () => {
    navigator.clipboard.writeText(link);
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 3000);
  };

  const handleExportExel = ({ data, filename, sheetname }) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetname);
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  }

  const INFORMATION = [
    {
      id: 0,
      header: "Clicks",
      total: 0,
      dataChart: dataClick,
    },
    {
      id: 1,
      header: "Đơn hàng",
      total: 0,
      dataChart: [],
    },
    {
      id: 2,
      header: "Giá trị đơn hàng (đ)",
      total: 0,
      dataChart: [],
    },
    {
      id: 3,
      header: "Thu nhập dự kiến (đ)",
      total: 0,
      dataChart: [],
    },
  ];

  const formatCurrency = (value) => {

    return value && value.toLocaleString('vi-VN', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }) + ' đ';

  };


  return (
    <React.Fragment>
      <div className="m-3 p-3" style={{ backgroundColor: "#F9FAFC" }}>
        <div className="mb-4">
          <div className="d-flex justify-content-between align-items-center"
            style={{gap:"8px"}}>
            <h4 style={{ color: "#172B4D" }}>
              <b>Dữ liệu tổng quát</b>
            </h4>
            <div className={style.ShareContainer}>
              <b className="mr-1">Link giới thiệu:</b>
              <Button
                style={{ background: "white", border: "none" }}
                className="pr-0 py-0"
                onClick={() => toggle()}
              >
                <div style={{ background: "white" }} className="py-1 mr-3">
                  <p className={style.linkLabel}>{link}</p>
                </div>

                <div
                  style={{
                    background: "#E5E7EB",
                    borderEndEndRadius: "4px",
                    borderTopRightRadius: "4px",
                  }}
                  className="p-1"
                >
                  <span>
                    <Img src={IconCopy} alt="copy" id="copy" />
                    <UncontrolledTooltip
                      autohide={false}
                      placement={"top"}
                      target={"copy"}
                    >
                      copy
                    </UncontrolledTooltip>
                    <Tooltip
                      autohide={false}
                      placement="top"
                      isOpen={tooltipOpen}
                      target="copy"
                      trigger="click"
                    >
                      copied
                    </Tooltip>
                  </span>
                </div>
              </Button>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <div
            className={style.overViewContainer}
          >
            {renderOverView()}
          </div>
        </div>
        <div className="mb-4">
          <Card style={{ borderRadius: "2px" }}>
            <CardBody className="p-3">
              <div className="nk-ck-sm">
                <CustomLineChart legend={false} data={dataClick} />
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="mb-4">
          <div
            className="p-3 d-flex justify-content-between align-items-center"
            style={{ background: "#EBEAED" }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <Img src={IconPaper} alt="paper" className="mr-1" />
              <b>Thống kê theo ngày tạo dự án</b>
            </div>

            {dailyData ? (
              <div
                role="button"
                className="rounded-circle d-flex align-items-center justify-content-center"
                style={{ background: "white", width: "2rem", height: "2rem" }}
                onClick={(e) => handleExportExel({ data: allData, filename: `Thống kê theo ngày tạo dự án từ ${allData[allData?.length - 1]?.date} đến ${allData[0]?.date} `, sheetname: "Daily" })}
              >
                <Img src={IconExcel} alt="excel" className="w-50" />
              </div>
            ) :
              (<div
                role="button"
                className="rounded-circle d-flex align-items-center justify-content-center"
                style={{ background: "white", width: "2rem", height: "2rem" }}
              >
                <Img src={IconExcel} alt="excel" className="w-50" />
              </div>)}

          </div>
          <div className="p-3 table-responsive" style={{ background: "white" }}>
            <table className={`${style.table}`}>
              <thead style={{ background: "#F1F5FF" }}>
                <tr>
                  <th
                    style={{ width: "10%" }}
                    className={`${style.headPost} ${style.headPostDate} py-2`}
                  >
                    Ngày
                  </th>
                  <th
                    style={{ width: "16%" }}
                    className={`${style.headPost} py-2 text-right`}
                  >
                    Clicks
                  </th>
                  <th
                    style={{ width: "16%" }}
                    className={`${style.headPost} py-2 text-right`}
                  >
                    Dự án tạo
                  </th>
                  <th
                    style={{ width: "16%" }}
                    className={`${style.headPost} py-2 text-right`}
                  >
                    Đơn hàng
                  </th>
                  <th
                    style={{ width: "16%" }}
                    className={`${style.headPost} py-2 text-right`}
                  >
                    Giá trị đơn hàng
                  </th>
                  <th
                    style={{ width: "26%" }}
                    className={`${style.headPost} py-2 text-right pr-5`}
                  >
                    Thu nhập dự kiến
                  </th>
                </tr>
              </thead>
              <tbody style={{ backgroundColor: "#fff", position: "relative" }}>
                {dailyData ? (
                  dailyData?.data.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        fontSize: "12px",
                        cursor: "pointer",
                        transition: "background-color 0.1s ease",
                      }}
                      className={`${style.hoveredRow}`}
                    >
                      <td
                        className={`${style.truncateCell} ${style.Td} text-left`}

                      >
                        {item?.date}
                      </td>
                      <td
                        className={`${style.truncateCell} ${style.Td} text-right`}

                      >
                        {item?.click}
                      </td>
                      <td
                        className={`${style.truncateCell} ${style.Td} text-right`}

                      >
                        <div
                        >
                          {item?.project} <Img src={infoIcon} style={{ width: "12px", height: "12px",margin:"0px 0px 2px 5px"}}
                            onClick={(e) => {               
                              setCurentModalProjectDate(item?.date);
                              toggleDetail("byProject");

                            }}
                          ></Img>
                        </div>

                      </td>
                      <td
                        className={`${style.truncateCell} ${style.Td} text-right`}

                      >
                        <div
                        >
                           {item?.order} <Img src={infoIcon} style={{ width: "12px", height: "12px",margin:"0px 0px 2px 5px"}}
                            onClick={(e) => {
                              setCurentModalOrderDate(item?.date);
                              toggleDetail("byOrder");
                            }}
                          ></Img>
                        </div>                      
                      </td>
                      <td
                        className={`${style.truncateCell} ${style.Td} text-right`}
                      >
                        {formatCurrency(item?.order_amount)}
                      </td>
                      <td
                        className={`${style.truncateCell} ${style.Td} text-right `}
                      >
                        <p className="pr-5">{formatCurrency(item?.estimate_revenue)}</p>
                      </td>
                    </tr>
                  ))) : (<>

                    {loadingDaily ? (
                      <Spinner size="32px" color="light" style={{ position: "absolute", left: "47%", marginTop: "70px" }} />
                    ) :
                      (
                        <div style={{ position: "absolute", top: "30%", left: "45%" }}>
                          <p style={{ color: "#9CA3AF" }}>Chưa có dữ liệu</p>
                        </div>
                      )}


                  </>)}
              </tbody>
            </table>

            {dailyData ?
              (
                <div className="mobile-paginate">
                  <PaginationCustom
                    pageSize={dailyData?.per_page}
                    totalCount={dailyData?.total || 0}
                    siblingCount={1}
                    onPageChange={handlePageChangeByProject}
                    currentPage={currentPageByProject}
                    className={`manage_post_pagination`}
                  />
                </div>
              ) :
              (<></>)}
          </div>
        </div>
      </div>
      <ModalDetailByOrder
        isOpen={modalDetail.byOrder}
        toggle={() => toggleDetail("byOrder")}
        date={curentModalOrderDate}
      />
      <ModalDetailByProject
        isOpen={modalDetail.byProject}
        toggle={() => toggleDetail("byProject")}
        date={curentModalProjectDate}
      />
    </React.Fragment>
  );
};

export default StatisticalTab;
