import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryStack,
  VictoryTheme,
} from "victory";
import style from "../../../index.module.scss";
import ChartPine from "../ChartPine";

const YTDemographic = ({ data }) => {
  const [genderData, setGenderData] = useState([]);
  const [ageMData, setAgeMData] = useState([]);
  const [ageFData, setAgeFData] = useState([]);
  const [totalGender, setTotalGender] = useState(0);
  const [color, setColor] = useState([]);
  useEffect(() => {
    setGenderData(data?.gender?.gender);
    setTotalGender(
      data?.gender?.gender?.reduce((total, item) => total + item.y, 0)
    );
    setColor(
      data?.gender?.gender?.map((item) => {
        return item?.color;
      })
    );
    const ageM = data?.age?.find((item) => item?.gender === "M");
    const ageF = data?.age?.find((item) => item?.gender === "F");
    setAgeMData(ageM ? ageM?.ages : []);
    setAgeFData(ageF ? ageF?.ages : []);
  }, [data]);

  // const swapXY = (array) => {
  //   return array.map((item) => {
  //     return { x: item.y, y: item.x };
  //   });
  // };

  return (
    <>
      <Row>
        <Col xs={12} md={6}>
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Giới tính
              </CardTitle>

              <div className="d-none d-md-flex flex-row justify-content-center mt-2">
                <svg viewBox="-350 0 1000 300">
                  <ChartPine
                    data={genderData && genderData}
                    info={"Tổng"}
                    color={color && color}
                  />
                </svg>
              </div>

              <div className="d-flex d-md-none flex-row justify-content-center mt-2">
                <svg viewBox="-150 0 600 300">
                  <ChartPine
                    data={genderData && genderData}
                    info={"Tổng"}
                    color={color && color}
                  />
                </svg>
              </div>

              <div className="d-flex flex-column mt-4" style={{ gap: "8px" }}>
                {genderData &&
                  genderData?.map((item, index) => {
                    const percentage = ((item.y / totalGender) * 100).toFixed(
                      2
                    );
                    return (
                      <div
                        className={`d-flex flex-row align-items-center justify-content-between`}
                        key={index}
                      >
                        <div className="d-flex flex-row align-items-center">
                          <div
                            className={`rounded-circle mr-1`}
                            style={{
                              height: "8px",
                              width: "8px",
                              backgroundColor: item.color,
                              flexShrink: "0",
                            }}
                          ></div>
                          <div className={`${style.contentCard}`}>
                            {item.label}
                          </div>
                        </div>
                        <div
                          className={`${style.contentCard} w-25 d-flex flex-row justify-content-between`}
                          style={{ fontWeight: "600" }}
                        >
                          <div>{item.y}</div>
                          <div>{percentage}%</div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={6} className="mt-4 mt-md-0">
          <Card className="h-100 ">
            <CardBody className="card-inner w-100">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Độ tuổi
              </CardTitle>
              <div className="w-100">
                <VictoryChart
                  //   theme={VictoryTheme.material}
                  width={800}
                  height={400}
                  padding={{ top: 5, left: 50, right: 40, bottom: 40 }}
                >
                  <VictoryStack colorScale={["#2C4B94", "#F8C6D1"]} horizontal>
                    <VictoryBar data={ageMData} />
                    <VictoryBar data={ageFData} />
                  </VictoryStack>
                  <VictoryAxis
                    dependentAxis={true}
                    style={{
                      axis: {
                        stroke: "#2C4B94",
                      },
                      ticks: {
                        stroke: "#2C4B94",
                        size: 5,
                      },
                      tickLabels: {
                        fill: "#2C4B94",
                        fontSize: 14,
                        padding: 5,
                      },
                    }}
                  />
                  <VictoryAxis
                    style={{
                      axis: {
                        stroke: "#2C4B94",
                      },
                      ticks: { stroke: "#2C4B94", size: 5 },
                      tickLabels: { fill: "#2C4B94", fontSize: 14, padding: 5 },
                    }}
                    tickValues={[
                      "13-17",
                      "18-24",
                      "25-34",
                      "35-44",
                      "45-54",
                      "55-64",
                      "65+",
                    ]}
                    domain={[0, ageMData.length + 1]}
                    // tickValues={[0, 250, 500, 750, 1000]}
                  />
                </VictoryChart>
                <div
                  className="d-flex flex-row justify-content-center"
                  style={{ gap: "16px" }}
                >
                  <div className="d-flex flex-row align-items-center">
                    <div
                      className={`rounded-circle mr-1`}
                      style={{
                        height: "8px",
                        width: "8px",
                        backgroundColor: "#2C4B94",
                        flexShrink: "0",
                      }}
                    ></div>
                    <div className={`${style.contentCard}`}>Nam</div>
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <div
                      className={`rounded-circle mr-1`}
                      style={{
                        height: "8px",
                        width: "8px",
                        backgroundColor: "#F8C6D1",
                        flexShrink: "0",
                      }}
                    ></div>
                    <div className={`${style.contentCard}`}>Nữ</div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default YTDemographic;
