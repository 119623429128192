import React from "react";
import style from "./index.module.scss";
import StaticStatus from "./Components/StaticStatus";
import Notification from "./Components/Notification";
import TaskList from "./Components/TaskList";

const FeatureTask = () => {
  return (
    <div className={style.FeatureTask}>
      <StaticStatus />
      <TaskList />
      <Notification />
    </div>
  );
};

export default FeatureTask;
