import { useEffect, useState } from "react";
import { PLATFORM_NAME } from "../constants";
import style from "../index.module.scss";
import { useTranslation } from "react-i18next";
import { ModalChannelError } from "./ChannelErrorModal";
import { getProjectIdInLocalStorage } from "../helper";
import { getChannelError } from "../../../../api/dashboard";
import {
  PLATFORM_FACEBOOK,
  PLATFORM_INSTAGRAM,
  PLATFORM_THREADS,
  PLATFORM_TIKTOK,
  PLATFORM_YOUTUBE,
} from "../../../utils/Utils";

export const LogHeader = ({
  last_time,
  cnt_channel_error,
  cnt_channel_success,
  currentTab,
}) => {
  const renderText = (currentTab) => {
    switch (currentTab) {
      case PLATFORM_NAME.FACEBOOK:
        return {
          text: t("header_dashboard.fanpage"),
          platform: PLATFORM_FACEBOOK,
        };
      case PLATFORM_NAME.TIKTOK:
        return {
          text: t("header_dashboard.account"),
          platform: PLATFORM_TIKTOK,
        };
      case PLATFORM_NAME.YOUTUBE:
        return {
          text: t("header_dashboard.channel"),
          platform: PLATFORM_YOUTUBE,
        };
      case PLATFORM_NAME.INSTAGRAM:
        return {
          text: t("header_dashboard.account"),
          platform: PLATFORM_INSTAGRAM,
        };
      case PLATFORM_NAME.THREADS:
        return {
          text: t("header_dashboard.account"),
          platform: PLATFORM_THREADS,
        };
      default:
        return {
          text: t("header_dashboard.fanpage"),
          platform: PLATFORM_FACEBOOK,
        };
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  // const toolTipTextFB = (
  //   <ul style={{ listStyleType: "circle", listStylePosition: "inside" }}>
  //     <li>
  //       Báo cáo Fanpage <b>chỉ khả dụng</b> cho Fanpage với <b>100 Likes</b>{" "}
  //       hoặc nhiều hơn.
  //     </li>
  //     <li>
  //       Dữ liệu được cập nhật một lần mỗi 24h. Thời gian cập nhật vào 1:00 -
  //       4:00 giờ sáng.
  //     </li>
  //     <li>
  //       Các giá trị lượt tiếp cận được thu thập độc lập
  //       <ul
  //         style={{
  //           listStyleType: "square",
  //           listStylePosition: "inside",
  //           paddingLeft: "20px",
  //         }}
  //       >
  //         <li>
  //           Tổng người tiếp cận không chính xác tuyệt đối bằng tổng của Nguồn
  //           trả phí (Paid) & Nguồn miễn phí (Non-paid).
  //         </li>
  //         <li>
  //           Tổng người tiếp cận không chính xác tuyệt đối bằng tổng của Nguồn
  //           lan tỏa (Viral-Unique) & Nguồn tự nheien (Organic-Unique)
  //         </li>
  //       </ul>
  //     </li>

  //     <li>
  //       Khi chạy quảng cáo trực tiếp từ bài đã đăng trên page (Boosted), dữ liệu
  //       sẽ được ghi nhận cả cho Nguồn trả phí (Paid) & Nguồn miễn phí
  //       (Non-paid).
  //     </li>
  //     <li>
  //       Dữ liệu Nhân khẩu học: <b>Tuổi, Giới tính</b> & <b>Địa điểm</b> chỉ được
  //       trả về khi có nhiều hơn 100 người.
  //     </li>
  //     <li>
  //       Một số dữ liệu liên quan đến video chỉ trả về giá trị chính xác nếu
  //       người yêu cầu số liệu đó là người tạo bài đăng video trên Trang.
  //     </li>
  //     <li>
  //       Nếu bạn chia sẻ lại bài viết video của Trang khác và truy xuất thông tin
  //       chi tiết về bài viết đó thì số liệu trả về giá trị 0.
  //     </li>
  //     <li>Dữ liệu tương tác trên Reels chưa được nền tảng cung cấp.</li>
  //   </ul>
  //   // <span>
  //   // </span>
  // );

  // const toolTipTextTiktok = (
  //   <div>
  //     Để xem thông tin chi tiết và dữ liệu phân tích, chủ tài khoản TikTok trước
  //     tiên cần xuất bản ít nhất một video, sau đó nhấn vào nút "Bật" trên trang
  //     Analytics của ứng dụng TikTok dành cho thiết bị di động của họ. Để biết
  //     thêm thông tin, hãy tham khảo{" "}
  //     <a
  //       href="https://www.tiktok.com/feedback?id=7133058093574806018&lang=en&type="
  //       target="_blank"
  //     >
  //       tại đây
  //     </a>{" "}
  //     .
  //   </div>
  // );

  // const [tooltipOpen, setOpen] = useState(false);

  // const checkPageHasAttention = (currentTab: PLATFORM_NAME) => {
  //   return [PLATFORM_NAME.FACEBOOK, PLATFORM_NAME.TIKTOK].includes(currentTab);
  // };

  // const toggle = () => {
  //   setOpen(!tooltipOpen);
  // };

  const [dataChannelError, setDataChannelError] = useState([]);

  const projectId = getProjectIdInLocalStorage();

  useEffect(() => {
    const platform = renderText(currentTab).platform;

    console.log("platform: ", platform);
    if (isOpen) {
      getChannelError(projectId, { platform }).then((resp) => {
        setDataChannelError(resp.data);
      });
    }
  }, [isOpen]);

  return last_time || cnt_channel_error || cnt_channel_success ? (
    <div
      className={`d-flex  justify-content-space-between ${style.tooltipField}`}
      style={{ justifyContent: "space-between" }}
    >
      <div>
        {last_time !== undefined &&
          `${t("header_dashboard.last_updated_at")} ${last_time}`}{" "}
        <span style={{ color: "#10B981" }}>
          {cnt_channel_success !== undefined &&
            `| ${cnt_channel_success} ${renderText(currentTab).text}`}
        </span>{" "}
        <span> {t("header_dashboard.updated_successfuly")}, </span>
        <span
          style={{ color: "#E11D48", cursor: "pointer" }}
          onClick={() => setIsOpen(true)}
        >
          {cnt_channel_error !== undefined &&
            `${cnt_channel_error} ${t(
              "header_dashboard.fanpage_update_failed"
            )}.`}
        </span>
      </div>

      {/* {checkPageHasAttention(currentTab) && (
        <div className={style.tooltipHeader} id="tooltipHeader">
          <span style={{ paddingLeft: "10px", cursor: "pointer" }}>
            <img src={ABOUT_ICON} alt="" />
            <span style={{ paddingLeft: "10px" }}>
              {`Các lưu ý cho Báo cáo ${currentTab}`}
            </span>
          </span>

          <Tooltip
            placement="bottom"
            isOpen={tooltipOpen}
            target={`tooltipHeader`}
            autohide={false}
            toggle={toggle}
            style={{ width: "100%", minWidth: "360px", textAlign: "left" }}
          >
            {currentTab === "Facebook" ? toolTipTextFB : toolTipTextTiktok}
          </Tooltip>
        </div>
      )} */}
      {isOpen && dataChannelError.length && (
        <ModalChannelError data={dataChannelError} setIsOpen={setIsOpen} />
      )}
    </div>
  ) : (
    <></>
  );
};
