import React from "react";
import { Card, CardBody, CardText, CardTitle, Progress } from "reactstrap";
import style from "../../../index.module.scss";
import { Icon } from "../../../../../../components/Component";
import ChartCircle from "../../ChartCircle";
import { formatNumberAcronym } from "../../../../../../utils/Utils";

const PageReach = ({ data }) => {
  return (
    <div
      className="d-flex flex-column flex-md-row w-100"
      style={{ gap: "16px" }}
    >
      <div
        className="d-flex flex-column flex-md-row w-100"
        style={{ gap: "16px" }}
      >
        <div className="w-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Lượt tiếp cận
              </CardTitle>
              <div className="d-flex flex-row align-items-center">
                <div className={`${style.titleCard}`}>
                  {data && formatNumberAcronym(data?.post_impression?.value)}
                </div>
              </div>
              <CardText className={`${style.detailCard} mt-1`}>{`Trung bình: ${
                data && data?.post_impression?.avg_day
              }/ngày`}</CardText>
            </CardBody>
          </Card>
        </div>
        <div className="w-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Người tiếp cận
              </CardTitle>
              <div className="d-flex flex-row align-items-center">
                <div className={`${style.titleCard}`}>
                  {data &&
                    formatNumberAcronym(data?.page_impressions_unique?.value)}
                </div>
                {data?.page_impressions_unique?.percent_change > 0 ? (
                  <div className="ml-1" style={{ color: "#10B981" }}>
                    {" "}
                    <Icon name={"arrow-up"}></Icon>{" "}
                    {data ? data?.page_impressions_unique?.percent_change : 0}%
                  </div>
                ) : (
                  <div className="ml-1" style={{ color: "#e85347" }}>
                    {" "}
                    <Icon name={"arrow-down"}></Icon>{" "}
                    {data ? data?.page_impressions_unique?.percent_change : 0}%
                  </div>
                )}
              </div>
              <CardText className={`${style.detailCard} mt-1`}>{`Trung bình: ${
                data && data?.page_impressions_unique?.avg_day
              }/ngày`}</CardText>
            </CardBody>
          </Card>
        </div>
      </div>
      <div
        className="d-flex flex-column flex-md-row w-100"
        style={{ gap: "16px" }}
      >
        <div className="w-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Người tiếp cận
              </CardTitle>

              <ChartCircle data={data?.rate_impression} />
            </CardBody>
          </Card>
        </div>
        <div className="w-100">
          <Card className="h-100">
            <CardBody className="card-inner">
              <CardTitle tag="h5" className={`${style.headCard}`}>
                Thành phố
              </CardTitle>
              <div
                className={`${style.headCard} d-flex flex-row align-items-center w-100 pb-2`}
                style={{
                  fontSize: "0.75rem",
                  borderBottom: "1px solid #D1D5DB",
                }}
              >
                <div className="w-35">City</div>
                <div className="w-40">Tiếp cận</div>
                {/* <div className="w-25"></div> */}
                <div className="w-25 d-flex justify-content-end">%</div>
              </div>
              <div className="mt-2 d-flex flex-column" style={{ gap: "8px" }}>
                {data &&
                  data?.page_impressions_by_city_unique?.map((item, index) => {
                    const totalY =
                      data?.page_impressions_by_city_unique?.reduce(
                        (acc, obj) => acc + obj.value,
                        0
                      );
                    return (
                      <div
                        className={`${style.contentCard} d-flex flex-row align-items-center w-100`}
                        key={index}
                      >
                        <div className="w-35">
                          {item?.source
                            ?.substring(0, item?.source?.indexOf(","))
                            .trim()}
                        </div>
                        <div className="w-15">
                          {formatNumberAcronym(item?.value)}
                        </div>
                        <div className="w-25">
                          <Progress
                            value={((item?.value * 100) / totalY).toFixed(0)}
                            barStyle={{ backgroundColor: "#2C4B94" }}
                            style={{
                              backgroundColor: "#F1F5FF",
                            }}
                          />
                        </div>
                        <div
                          className="w-25 d-flex justify-content-end"
                          style={{ fontWeight: "600" }}
                        >
                          {((item?.value * 100) / totalY).toFixed(1)}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default PageReach;
