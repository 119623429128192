import React, { useEffect, useState, useRef } from "react";
import { RiQuestionLine } from "@remixicon/react";
import Dropzone from "react-dropzone";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tooltip,
} from "reactstrap";
import {
  CUSTOM_THUMB_TYPE_FROM_VIDEO,
  CUSTOM_THUMB_TYPE_FROM_UPLOAD,
  PLATFORM_TIKTOK,
} from "../../../utils/Utils";
import { Img } from "../../../../components/Component";
import styles from "../index.module.scss";

const ModalCustomThumbnail = ({
  disableSelectImage,
  selectedVideo,
  setSelectedVideo,
  selectedPlatforms,
  isShowModal,
  onCloseModal,
}) => {
  const [customThumbType, setCustomThumbType] = useState(
    CUSTOM_THUMB_TYPE_FROM_VIDEO
  );

  const [tooltipThumbUpload, setTooltipThumbUpload] = useState(false);
  const toggleThumbUpload = () => {
    setTooltipThumbUpload(!tooltipThumbUpload);
  };

  const [files, setFiles] = useState([]);
  const videoRef = useRef(null);

  const handleDropChange = (acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };

  const onSave = () => {
    if (videoRef.current && customThumbType === CUSTOM_THUMB_TYPE_FROM_VIDEO) {
      const currentTime = Math.floor(videoRef.current.currentTime);
      let data = [...selectedVideo];
      data[0].time = currentTime;

      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const context = canvas.getContext("2d");
      if (context) {
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        const thumbnailDataUrl = canvas.toDataURL();
        if (disableSelectImage) {
          data[0].tiktokThumb = [thumbnailDataUrl];
        } else {
          data[0].thumb = [thumbnailDataUrl];
          data[0].tiktokThumb = [thumbnailDataUrl];
        }
      }
      setSelectedVideo(data);
    } else if (customThumbType === CUSTOM_THUMB_TYPE_FROM_UPLOAD) {
      let data = [...selectedVideo];
      data[0].time = 0;
      const reader = new FileReader();
      reader.onload = function (event) {
        const thumbnailDataUrl = event.target.result;
        data[0].thumb = [thumbnailDataUrl];
        setSelectedVideo(data);
      };
      reader.readAsDataURL(files[0]);
    }
    onCloseModal();
  };

  return (
    <Modal isOpen={isShowModal} toggle={onCloseModal} centered size="lg">
      <ModalHeader toggle={onCloseModal}>Set Custom Thumbnail</ModalHeader>
      <ModalBody
        style={{ height: "33rem" }}
        className="d-flex flex-column align-item-center gap-2"
      >
        <div className={`d-flex postWraper`}>
          <div className="form-check mb-2 pl-0">
            <Input
              className="form-check-input"
              checked={customThumbType === CUSTOM_THUMB_TYPE_FROM_VIDEO}
              onChange={(e) => setCustomThumbType(CUSTOM_THUMB_TYPE_FROM_VIDEO)}
              type="radio"
              name="radioThumbCustom"
              id="ThumbFromVideo"
            />
            <Label className="form-check-label" for="ThumbFromVideo">
              Choose from video
            </Label>
          </div>
          <div className="form-check">
            <Input
              className="form-check-input"
              disabled={
                disableSelectImage ||
                (selectedPlatforms.length === 1 &&
                  selectedPlatforms[0] === PLATFORM_TIKTOK)
              }
              checked={customThumbType === CUSTOM_THUMB_TYPE_FROM_UPLOAD}
              onChange={(e) =>
                setCustomThumbType(CUSTOM_THUMB_TYPE_FROM_UPLOAD)
              }
              type="radio"
              name="radioThumbCustom"
              id="ThumbFromImage"
            />
            <Label className="form-check-label" for="ThumbFromImage">
              Upload image{" "}
              <RiQuestionLine
                className="text-info ml-1 cursor-pointer"
                size={16}
                id="thumbUploadTooltip"
              ></RiQuestionLine>
            </Label>
          </div>

          <Tooltip
            placement="top"
            isOpen={tooltipThumbUpload}
            target={`thumbUploadTooltip`}
            toggle={toggleThumbUpload}
          >
            <div>You can't upload custom thumbnail in Tiktok videos.</div>
          </Tooltip>
        </div>
        {customThumbType === CUSTOM_THUMB_TYPE_FROM_VIDEO && !!selectedVideo[0]?.data && (
          <>
            <video
              ref={videoRef}
              controls
              height={"100%"}
              width={"100%"}
              style={{ maxHeight: "400px" }}
            >
              <source
                src={URL.createObjectURL(selectedVideo[0].data)}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            <p className="mb-0 mt-2 fs-12 text-body text-center">
              Scroll through the video and then click the apply button to
              capture the selection.
            </p>
          </>
        )}
        {customThumbType === CUSTOM_THUMB_TYPE_FROM_UPLOAD && (
          <Dropzone
            onDrop={(acceptedFiles) => handleDropChange(acceptedFiles)}
            multiple={false}
            maxFiles={1}
            accept={["image/*"]}
            height={"100%"}
            width={"100%"}
            style={{ maxHeight: "400px" }}
          >
            {({ getRootProps, getInputProps }) => (
              <section className="h-100">
                <div
                  {...getRootProps()}
                  className="h-100 dropzone upload-zone small bg-lighter my-2 dz-clickable"
                >
                  <input {...getInputProps()} />
                  {files.length === 0 && (
                    <div
                      className="m-auto d-flex align-center justify-content-center h-100"
                      style={{}}
                    >
                      <span>Click to upload or drag and drop image here</span>
                    </div>
                  )}
                  {files.map((file) => (
                    <div
                      key={file.name}
                      className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                    >
                      <div className="dz-image">
                        <Img src={file.preview} alt="preview" />
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            )}
          </Dropzone>
        )}
      </ModalBody>

      <ModalFooter className="d-flex justify-content-end">
        <Button onClick={onSave}>Apply</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalCustomThumbnail;
