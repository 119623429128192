import React, { useState, useEffect } from "react";
import calendaIcon from "../../images/icons/dateIcon.png"
import Head from "../../../layout/head/Head";
import style from "./index.module.scss";
import { Nav, NavItem, NavLink, TabContent, TabPane, Spinner } from "reactstrap";
import moment from 'moment';
import { Icon, Img } from "../../../components/Component";
import DatePicker from "react-datepicker";
import PaymentHistory from "./PaymentHistory";
import StatisticalTab from "./StatisticalTab";
import { getIrisUser } from "../../../api/iris";
import IntroIrisPage from "./IntroPage";
const IrisPage = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [date, setDate] = useState();
  const [isRegister, setIsRegister] = useState(null);
  const [fisrtLoad, setFisrtLoad] = useState(false);

  const changeDate = (fromDate, toDate) => {
    setDate([fromDate, toDate]);
  }

  const [rangeDate, setRangeDate] = useState({
    start: new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate() + 1, new Date().getHours(), new Date().getMinutes(), new Date().getSeconds(), new Date().getMilliseconds()),
    end: new Date(),
  });
  const onRangeChange = (dates) => {
    const [start, end] = dates;
    setRangeDate({ start: start, end: end });
    setDate({ start: formatPreDate(start), end: formatDate(end) });
  };
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };


  const formatDate = (dateString) => {
    if (dateString) {
      const dateObject = new Date(dateString);
      const year = dateObject.getFullYear();
      const month = String(dateObject.getMonth() + 1).padStart(2, '0');
      const day = String(dateObject.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    }

  }
  const formatPreDate = (dateString) => {
    if (dateString) {
      const dateObject = new Date(dateString);
      const year = dateObject.getFullYear();
      const month = String(dateObject.getMonth() + 1).padStart(2, '0');
      const day = String(dateObject.getDate() - 1).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    }

  }

  const renderDashBoash = () => {

    return (
      <>
        <Head title="Sứ giả IRIS" />
        <div style={{ height: "75px" }}></div>
        <div className={`${style.notification}`} style={{ position: "relative" }}>
          <Nav tabs className={` ${style.navPost} px-0 mx-0 `}>
            <NavItem className={`${style.navWrapper}`}>
              <NavLink
                tag="a"
                href="#tab"
                className={`${activeTab === "1" && style.active} ${style.navItem
                  }`}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("1");
                }}
              >
                <Icon
                  name="reports"
                  style={{
                    fontSize: "1em",
                  }}
                  className="mr-1"
                />{" "}
                Thống kê
              </NavLink>
            </NavItem>
            <NavItem className={`${style.navWrapper}`}>
              <NavLink
                tag="a"
                href="#tab"
                className={`${activeTab === "2" && style.active} ${style.navItem
                  }`}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("2");
                }}
              >
                <Icon
                  name="layers"
                  style={{
                    fontSize: "1em",
                  }}
                  className="mr-1"
                />{" "}
                Lịch sử thanh toán
              </NavLink>
            </NavItem>
          </Nav>
          {activeTab === "1" ? (
            <div
            className={`mr-3 py-1 px-2 w-20 ${style.datePickerContainer}`}
              style={{
                background: "#1A3168",
                position: "absolute",
                top: "10px",
                right: "0",
                borderRadius: "4px",
                minWidth: "210px"
              }}
            >
              <div className="form-control-wrap d-flex justify-content-center align-items-center" >
                <Img src={calendaIcon} style={{ width: "16px", height: "16px" }} />
                <DatePicker
                  selected={rangeDate.start}
                  startDate={rangeDate.start}
                  onChange={onRangeChange}
                  endDate={rangeDate.end}
                  selectsRange
                  className={`form-control date-picker ${style.customdate}`}
                />{" "}
              </div>
            </div>) : (<></>)}


        </div>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <StatisticalTab
              rangeDate={date}
            />
          </TabPane>
          <TabPane tabId="2">
            <PaymentHistory />
          </TabPane>
        </TabContent>
      </>

    )
  }

  useEffect(() => {
    getIrisUser().then((res) => {
      if (res.status) {
        setIsRegister(true);
        // setFisrtLoad(true);
      } else {
        setIsRegister(false);
        // setFisrtLoad(true);
      }
    }
    ).catch((err) => {
      setIsRegister(false);
      // setFisrtLoad(true);
    })
  }, []);
  return (
    <React.Fragment>

      {
        isRegister !== null ?
          (isRegister === true ? (
            renderDashBoash()
          ) : (
            <IntroIrisPage />
          )) :
          (<div style={{ marginTop: "200px", textAlign: "center" }}>
            <Spinner size="lg" color="primary"></Spinner>
          </div>)
      }

    </React.Fragment>
  );
};

export default IrisPage;
