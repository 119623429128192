import React from 'react'
import style from "./index.module.scss"
import FooterSo9 from "../../images/icons/FooterSo9.svg"
import budding from "../../images/icons/budding.svg"
import telephone from "../../images/icons/telephone.svg"
import map from "../../images/icons/map.svg"
import ContactFace from "../../images/icons/ContactFace.svg"
import Contactyoutube from "../../images/icons/Contactyoutube.svg"
import {
    Row,
    Col,
    Img
  } from "../../../components/Component";
export default function FooterTrial() {
  return (
    <div className={`${style.Trial__footer}`}>
              <Row>
                <Col md="4">
                  <Img src={FooterSo9} className={style.footer__Logo}></Img>
                  <div className={style.footer__img}>
                    <Img src={budding} className='mr-2'></Img>
                    <span className='font-weight-bold'>Công ty TNHH SO9</span>
                  </div>
                  <div className={style.footer__img}>
                    <Img src={telephone} className='mr-2'></Img>
                    <span>0943.889.033</span>
                  </div>
                  <div className={style.footer__img}>
                    <Img src={map} className='mr-2'></Img>
                    <span>Số 8, Hoàng Ngọc Phách, Đống Đa, Hà Nội</span>
                  </div>
                </Col>
                <Col md="8">
                  <Row>
                    <Col md="3">
                      <div className={style.footer__group}>
                        <span>SẢN PHẨM</span>
                      </div>
                      <div className={style.footer__textPro} 
                      onClick={()=>{window.open("https://so9.vn")}}>
                        <span>Trang chủ</span>
                      </div>
                      <div className={style.footer__textPro}>
                        <span>Social Studio</span>
                      </div>
                      <div className={style.footer__textPro}
                      onClick={()=>{window.open("https://chrome.google.com/webstore/detail/so9-extension/aamahdfkcpdbaoggdmebmmdpjmjglddp?hl=en&authuser=5")}}>
                        <span>9Extension</span>
                      </div>
                      <div className={style.footer__textPro}
                      onClick={()=>{window.open("https://so9.vn/bai-viet")}}>
                        <span>Blog</span>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className={style.footer__group}>
                        <span>TÍNH NĂNG</span>
                      </div>
                      <div className={style.footer__text} 
                      onClick={()=>{
                        let url = process.env.PUBLIC_URL + "/posts/create";
                        window.history.pushState("","",url)
                        window.location.reload();
                      }}>
                        <span>SO9 Fast Posting</span>
                      </div>
                      <div className={style.footer__text}>
                        <span>SO9 Scheduler</span>
                      </div>
                      <div className={style.footer__text}
                      onClick={()=>{
                        let url = process.env.PUBLIC_URL + "/engagement";
                        window.history.pushState("","",url)
                        window.location.reload();
                      }}>
                        <span>SO9 Conversion Hub</span>
                      </div>
                      <div className={style.footer__text}>
                        <span>SO9 Customer 360</span>
                      </div>
                      <div className={style.footer__text}>
                        <span>SO9 Integration</span>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className={style.footer__text} style={{ paddingTop: "42px" }}>
                        <span>SO9 Reup</span>
                      </div>
                      <div className={style.footer__text}>
                        <span>SO9 Analytic</span>
                      </div>
                      <div className={style.footer__text}>
                        <span>SO9 Seeding</span>
                      </div>

                    </Col>
                    <Col md="3">
                      <div className={style.footer__group}>
                        <span>LIÊN HỆ</span>
                      </div>
                      <div className={style.footer__contact}>
                        <span>contact@so9.vn</span>
                      </div>
                      <div className={style.footer__contact}  onClick={()=>{window.open("https://www.facebook.com/profile.php?id=100084936194979")}}>
                        <Img src={ContactFace} className='mr-2'></Img>
                        <span>Fanpage</span>
                      </div>
                      <div className={style.footer__contact}  onClick={()=>{window.open("https://www.youtube.com/channel/UCCL1qaNwNsYDRwJL3_94RGQ/videos")}}>
                        <Img src={Contactyoutube} className='mr-2'></Img>
                        <span>Youtube</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
  )
}
