import React, { useState } from "react";
import ContentElement from "./ResearchContent/ContentElement";

const ColumnContent = ({
  data: item,
  channel,
  handleOpenSideBar,
  formSeeding,
  projectId,
  handleOpenDownExtension,
}) => {
  const [idComment, setIdComment] = useState("");
  const [isNotifyComment, setIsNotifyComment] = useState(false);
  // const [isExtension, setIsExtension] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const onClickContent = () => {
    item.attachments.data[0].url &&
      window.open(item.attachments.data[0].url, "_blank");
  };

  const handleComment = (id) => {
    setIdComment(id);
  };

  const handleOpenNotifyComment = () => {
    setIsNotifyComment(!isNotifyComment);
  };

  const handleHover = (index) => {
    setIsHover(index);
  };

  const handleLeave = () => {
    setIsHover(false);
  };

  return (
    <>
      <ContentElement
        handleHover={handleHover}
        handleLeave={handleLeave}
        handleOpenDownExtension={handleOpenDownExtension}
        handleOpenSideBar={handleOpenSideBar}
        handleOpenNotifyComment={handleOpenNotifyComment}
        handleComment={handleComment}
        content={item.message}
        Image={item?.attachments?.data[0].media?.image?.src || null}
        avatar={channel.avatar}
        isHover={isHover}
        idComment={idComment}
        idContent={item.id}
        numberOfLikes={item.likes.summary.total_count}
        numberOfComments={item.comments.summary.total_count}
        numberOfShares={item.shares?.count ?? 0}
        name={channel?.name}
        verificationStatus={channel.verification_status}
        createdAt={item.created_time}
        onClickContent={onClickContent}
        data={item}
        formSeeding={formSeeding}
        projectId={projectId}
        channel={channel}
        // index,
      />
    </>
  );
};

export default ColumnContent;
