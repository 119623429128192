import { API } from "../libs/client";

export const searchPage = async (projectId, data, after) => {
  let uri = `/projects/research/page?project_id=${projectId}&q=${data}`;
  if (after) {
    uri += `&after=${after}`;
  }
  const res = await API.get(uri, data);
  return res.data;
};

export const getFeedOfPage = async (projectId, pageId, after) => {
  let uri = `/projects/research/${pageId}/feed?project_id=${projectId}`;
  if (after !== "") {
    uri = uri + `&after=${after}`;
  }
  const res = await API.get(uri);
  return res.data;
};

export const followPage = async (data) => {
  const uri = `/projects/research/channel-follow`;
  const res = await API.post(uri, data);
  return res.data;
};

export const getFollowingList = async (projectId) => {
  const uri = `/projects/research/channel-follow?project_id=${projectId}`;
  const res = await API.get(uri);
  return res.data;
};

export const unFollowPage = async (projectId, pageId) => {
  const uri = `/projects/research/channel-follow/${pageId}?project_id=${projectId}`;
  const res = await API.delete(uri);
  return res.data;
};

export const commentPost = async (projectId, pageId, postId, data) => {
  const uri = `/projects/research/page/${pageId}/post/${postId}/comment?project_id=${projectId}`;
  const res = await API.post(uri, data);
  return res.data;
};
