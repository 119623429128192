import React from "react";
import { useTranslation } from "react-i18next";
import { statusFacebookBackground } from "../../../utils/status";
import { Icon, Img } from "../../../../components/Component";
import REMOVE_ICON from "../../../images/post/status/remove-background.svg";

const StatusCard = ({
  toggleStatusDropdown,
  selectedStatus,
  setSelectedStatus,
}) => {
  const { t } = useTranslation();

  const handleSelectStatus = (item) => {
    if (selectedStatus === item.id) {
      setSelectedStatus(null);
    } else {
      setSelectedStatus(item);
    }
    toggleStatusDropdown();
  };

  return (
    <div className="w-100 h-100" style={{ padding: "24px" }}>
      <div
        className="w-100 d-flex items-center justify-content-between"
        style={{ marginBottom: "24px" }}
      >
        <span style={{ fontSize: "13px", fontWeight: "700", color: "#212529" }}>
          {t("store_post.status.choose_background")}
        </span>

        <div className="d-flex items-center" style={{ gap: "8px" }}>
          <div
            className="d-flex items-center cursor-pointer hover:bg-gray-200"
            style={{
              padding: "0 8px",
              gap: "4px",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
            onClick={() => setSelectedStatus(null)}
          >
            <Img src={REMOVE_ICON} height="16px"></Img>
            <span
              style={{
                fontSize: "12px",
                color: "#212529",
                lineHeight: "1.5",
              }}
            >
              {t("store_post.status.remove_background")}
            </span>
          </div>
          <div className="d-flex items-center" onClick={toggleStatusDropdown}>
            <Icon
              name="cross"
              className={"action-button cursor-pointer"}
              style={{ fontSize: "12px" }}
            />
          </div>
        </div>
      </div>

      <div
        className="w-100 grid grid-cols-6 place-items-center"
        style={{ gap: "8px" }}
      >
        {statusFacebookBackground.map((item) => (
          <div
            key={item.id}
            style={{
              width: "32px",
              height: "32px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={() => handleSelectStatus(item)}
          >
            <Img
              src={item.icon}
              style={{ height: "32px" }}
              className="hover:opacity-80"
            ></Img>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatusCard;
