import React from 'react'
import { useState } from 'react'
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import Icon from "../../../components/icon/Icon";
import { Nav, NavItem, NavLink, Row, Col, TabContent, TabPane } from "reactstrap";
import style from '../CreatePost/index.module.scss';
import ReportFacebook from './ReportFacebook';
import ReportZalo from './ReportZalo';
import ReportInstagram from './ReportInstagram';
import FacebookPlatform from "../../images/icons/platform-icon-facebook.png";
import TiktokPlatform from "../../images/icons/platform-icon-tiktok.png";
import YoutubePlatform from "../../images/icons/platform-icon-youtube.png";
import InstagramPlatform from "../../images/icons/platform-icon-instagram.png";
export default function ReportAnalysis() {
    const [activeTab, setActiveTab] = useState("1");
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const tabSocialStatistic = [
        {
            key: "1",
            icon: FacebookPlatform,
            title: "Facebook"
        },
        {
            key: "2",
            icon: TiktokPlatform,
            title: "Tiktok"
        },
        {
            key: "3",
            icon: YoutubePlatform,
            title: "Youtube"
        },
        {
            key: "4",
            icon: InstagramPlatform,
            title: "Instagram"
        }
    ]
    return (
        <React.Fragment>
            <Head title="Phân tích"></Head>
            <Content>
                <Nav tabs className={`${style.navPost}`}>
                    {
                        tabSocialStatistic.map(({ key, icon, title }, index) => {
                            return (
                                <NavItem className={`${style.navWrapper}`}>
                                    <NavLink
                                        tag="a"
                                        href="#tab"
                                        className={`${activeTab === key && style.active} ${style.navItem}`}
                                        style={{ borderTopLeftRadius: "8px" }}
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            toggle(key);
                                        }}
                                    >
                                        <img src={icon} alt={title} style={{ width: "1rem" }} className="img-fluid mr-1" /> {title}
                                    </NavLink>
                                </NavItem>
                            )
                        })
                    }
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1" >   {/*className="postWrapper" */}
                        <ReportFacebook />
                    </TabPane>
                    <TabPane tabId="2">
                        {/* <ReportInstagram /> */}
                    </TabPane>
                    <TabPane tabId="3">
                        {/*  */}
                    </TabPane>
                    <TabPane tabId="4">
                        {/*  */}
                    </TabPane>
                    {/* <TabPane tabId="4">
                        <ReportZalo />
                    </TabPane>
                    <TabPane tabId="5">
                      
                    </TabPane>

                    <TabPane tabId="6">
                     
                    </TabPane> */}
                </TabContent>
            </Content>
        </React.Fragment>
    )
}