import React from "react";
import { Icon } from "../../../../components/Component";

const DetailTitle = ({
  parentTitle,
  parent_id,
  handleTaskDetail,
  isEditing,
  setIsEditing,
  title,
  setTitle,
  handleTitleBlur,
}) => {
  const handleTitleClick = () => {
    setIsEditing(true);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleTitleBlur();
    }
  };

  return (
    <div
      className="d-flex w-100"
      style={{ gap: "24px", flexDirection: "column" }}
    >
      {parent_id && (
        <div
          className="d-flex items-center detail-title"
          style={{ cursor: "pointer" }}
          onClick={() => handleTaskDetail(parent_id)}
        >
          <Icon
            name="arrow-left"
            className={"mr-2 item-icon"}
            style={{ color: "#90959D", fontSize: "16px" }}
          />
          <span>{parentTitle}</span>
        </div>
      )}

      <div className="d-flex items-center gap-2 detail-title h-100 w-100">
        {isEditing ? (
          <textarea
            value={title}
            onChange={handleTitleChange}
            onBlur={handleTitleBlur}
            onKeyPress={handleKeyPress}
            autoFocus
            className="title-textarea"
          />
        ) : (
          <div className="title-text w-100" onClick={handleTitleClick}>
            {title?.charAt(0).toUpperCase() + title?.slice(1)}
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailTitle;
