import { merge } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getDashboardAudience } from "../../../api/overview";
import LineChart from "../../components/Chart/LineChart";
import SummaryItem from "./SummaryItem";

const ContentSpread = (props) => {
  const { t } = useTranslation();
  const { range } = props;
  const project = useSelector((state) => state.project.project);
  const [summary, setSummary] = useState([]);
  const [chartData, setChartData] = useState({});
  const chartOptions = {
    legend: {
      display: false,
      labels: {
        boxWidth: 12,
        padding: 20,
        fontColor: "#6783b8",
      },
    },
    maintainAspectRatio: false,
    tooltips: {
      enabled: true,
      backgroundColor: "#fff",
      borderColor: "#eff6ff",
      borderWidth: 2,
      titleFontSize: 13,
      titleFontColor: "#6783b8",
      titleMarginBottom: 6,
      bodyFontColor: "#9eaecf",
      bodyFontSize: 12,
      bodySpacing: 4,
      yPadding: 10,
      xPadding: 10,
      footerMarginTop: 0,
      displayColors: false,
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].label || "";

          if (label) {
            label += ": ";
          }
          label += Intl.NumberFormat().format(tooltipItem.yLabel);
          return label;
        },
      },
    },
    scales: {
      yAxes: [
        {
          display: true,
          ticks: {
            fontSize: 12,
            fontColor: "#9eaecf",
            padding: 8,
            maxTicksLimit: 5,
            callback: (value) => {
              return Intl.NumberFormat().format(value);
            },
          },
          gridLines: {
            color: "rgba(82, 100, 132, 0.2)",
            tickMarkLength: 0,
            zeroLineColor: "rgba(82, 100, 132,0.2)",
          },
        },
      ],
      xAxes: [
        {
          display: true,
          ticks: {
            padding: 16,
            fontSize: 12,
            fontColor: "#9eaecf",
            source: "auto",
            maxTicksLimit: 2,
            maxRotation: 0,
          },
          gridLines: {
            color: "transparent",
            tickMarkLength: 0,
            zeroLineColor: "transparent",
            offsetGridLines: true,
          },
        },
      ],
    },
  };
  useEffect(() => {
    if (project?._id) {
      const data = {
        params: {
          project_id: project?._id,
          time: `${range} days`,
        },
      };
      getDashboardAudience(data).then((response) => {
        if (response.status) {
          const _data = response.data;
          setSummary(_data.summary);
          const _chartFormatter = [
            {
              color: "#798bff",
              borderWidth: 2,
              lineTension: 0,
              dash: 0,
              borderColor: "#9d72ff",
              backgroundColor: "rgba(121, 139, 255, 0.15)",
              borderCapStyle: "square",
              pointBorderColor: "transparent",
              pointBackgroundColor: "transparent",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "#9d72ff",
              pointBorderWidth: 2,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 2,
              pointRadius: 4,
              pointHitRadius: 4,
            },
            {
              borderDash: [5],
              borderWidth: 2,
              fill: false,
              borderColor: "#9d72ff",
              backgroundColor: "transparent",
              pointBorderColor: "transparent",
              pointBackgroundColor: "transparent",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "#9d72ff",
              pointBorderWidth: 2,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 2,
              pointRadius: 4,
              pointHitRadius: 4,
            },
          ];
          const _chart = {
            ..._data.dataSet,
            datasets: merge(_data.dataSet.dataSets, _chartFormatter),
          };
          setChartData(_chart);
        }
        else {
          setSummary([]);
          setChartData({});
        }
      });
    }
    return () => {
      setSummary([]);
      setChartData({});
    };
  }, [range, project?._id]);

  return (
    <>
      <div className="card-title-group pb-3 g-2">
        <div className="card-title card-title-sm">
          <h6 className="title">{t("overview.content_spread.title")}</h6>
          <p>{t("overview.content_spread.subtitle")}</p>
        </div>
      </div>
      <div className="analytic-ov">
        <div className="analytic-data-group analytic-ov-group g-3">
          {summary.map((summaryItem, index) => (
            <SummaryItem
              key={index}
              item={summaryItem}
              activeItem={index === 0}
              className="analytic-ov-data"
              translatePrefix="overview.content_spread.summary_"
            />
          ))}
        </div>
        <div className="analytic-ov-ck">
          <LineChart data={chartData} options={chartOptions} />
        </div>
      </div>
    </>
  );
};
export default ContentSpread;
